import PropTypes from 'prop-types';
import React from 'react';
import Slider from "react-slick/lib";
import HelpTip from "../../HelpTip";

export default class CSNavigation extends React.Component {
  static propTypes = {
    handleLink: PropTypes.func.isRequired,
    role_1: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    projectType: PropTypes.string,
    community: PropTypes.object,
    userId: PropTypes.number,
    helpStores: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $('.item').css('border-color', this.props.colors.color0[4]);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.helpStores.showNotificationsTip) {
      this.toggleSlickListOverflow('visible');
    }
  }

  toggleSlickListOverflow(value) {
    const slickList = document.querySelector('.slick-list');
    if (slickList) {
      slickList.style.overflow = value;
    }
  }

  render() {
    const {role_1, language, projectType, colors, community, userId, helpStores} = this.props;

    const I18n = {
      en: {general: 'General', bio:'My Bio', welcome: 'Welcome', invitation: 'Invitations', segAndTgs: 'Segments & Tags',
        wordbook: 'Wordbooks', quickComments: 'Quick Comments', social: 'Social', notification: 'Notifications', participants: 'Participants',
        notificationsTip: "Reconfigure welcome message, invitations, email alerts, and add segments."
      },
      es: {general: 'General', bio:'Mi Bio', welcome: 'Bienvenida', invitation: 'Invitaciones', segAndTgs: 'Segmentos & Tags',
        wordbook: 'Wordbooks', quickComments: 'Quick Comments', social: 'Social', notification: 'Notificaciones', participants: 'Participantes',
        notificationsTip: "Cambia tu mensaje de bienvenida, invitaciones, alertas de correo, y añade segmentos."
      }
    }

    const sliderNavSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 3,
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    let menu = <Slider {...sliderNavSettings} className="px-slick-navigation-menu">
      <HelpTip showRequisites={true}
               tipKey={"notificationsTip"}
               text={I18n[language].notificationsTip}
               colors={colors}
               communityId={community.id}
               userId={userId}
               initialHelpStores={helpStores}
               arrowDirection={'up'}
               bottom={'-100px'}
               left={'80px'}
      />
      <div className="item selected" id="General" onClick={() => this.props.handleLink('#General', '#divGeneral')}>
        {I18n[language].general}
      </div>
      <div className="item" id="Welcome" onClick={() => this.props.handleLink('#Welcome', '#divWelcome')}>
        {I18n[language].welcome}
      </div>
      <div className="item" id="Invitations" onClick={() => this.props.handleLink('#Invitations', '#divInvitations')}>
        {I18n[language].invitation}
      </div>
      {projectType !== 'AIChat' && <div className="item" id="Notifications"
                                        onClick={() => this.props.handleLink('#Notifications', '#divNotifications')}>
        {I18n[language].notification}
      </div> }
      { projectType !== 'AIChat' &&
          <div className="item" id="SegsAndTags" onClick={() => this.props.handleLink('#SegsAndTags', '#divSegsAndTags')}>
        {I18n[language].segAndTgs}
      </div> }
      {projectType !== 'AIChat' && <div className="item" id="Maps" onClick={() => this.props.handleLink('#Maps', '#divMaps')}>
        {I18n[language].wordbook}
      </div> }
      {projectType !== 'AIChat' && <div className="item" id="Participants"
                                        onClick={() => this.props.handleLink('#Participants', '#divParticipants')}>
        {I18n[language].participants}
      </div> }
      {projectType !== 'AIChat' && projectType !== 'Mixed' && <div className="item" id="QuickComments"
            onClick={() => this.props.handleLink('#QuickComments', '#divQuickComments')}>
        {I18n[language].quickComments}
      </div> }
      {projectType !== 'AIChat' && <div className="item" id="Social" onClick={() => this.props.handleLink('#Social', '#divSocial')}>
        {I18n[language].social}
      </div> }
      { projectType !== 'AIChat' && <div className="item" id="Bio" onClick={() => this.props.handleLink('#Bio', '#divBio')}>
        {I18n[language].bio}
      </div> }
    </Slider>;

    if(role_1 === 'Participant' ){
      menu = <Slider {...sliderNavSettings} className="px-slick-navigation-menu">
        <div className="item selected" id="Bio" onClick={() => this.props.handleLink('#Bio', '#divBio')}>
          {I18n[language].bio}
        </div>
        <div className="item" id="Notifications" onClick={() => this.props.handleLink('#Notifications', '#divNotifications')}>
          {I18n[language].notification}
        </div>
      </Slider>;
    }

    return (
      <div className="px-slick-navigation-container" style={{textTransform:'uppercase'}}>
        { menu }
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', marginLeft:'15px', borderRadius:'50%'}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', marginRight:'15px', zIndex:'1' , borderRadius:'50%'}}
      onClick={onClick}
    />
  );
}