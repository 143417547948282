import PropTypes from 'prop-types';
import React from 'react';
import Select, {components} from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {showSuccessMsg} from "../../UI/Globals/PX_Funs";
const classNames = require('classnames');

export default class IModalQI extends React.Component {
  static propTypes = {
    updateShowModalQI: PropTypes.func.isRequired,
    community: PropTypes.object.isRequired,
    updateCommunityUsers: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    segments: PropTypes.array.isRequired,
    role2: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    let role_2 = 'Moderator';
    if(props.role2 === 'Moderator'){
      role_2 = 'Observer';
    }

    this.state = {
      email: '',
      flagEmail: false,
      first_name: '',
      last_name: '',
      gender: '',
      dob: '',
      country: '',
      region: '',
      postcode: '',
      userSegments: [],
      role_2: role_2,
      userState: '',
      flagFirstName: '',
      flagLastName: '',
      flagGender: '',
      flagDOB: '',
      flagCountry: '',
      flagRegion: '',
      isInviting: false
    };
  }

  render() {
    const {language, colors} = this.props;

    const i18n = {
      en: {
        title: 'Invite', gender: 'Gender', country: 'Country', region: 'Region', segments: 'Segments',
        role: 'Role', saveDraft: 'Save as Draft', sendInvitation: 'Send Invite', close: 'Close',
        type: this.props.type === 'Participant' ? 'Participant' : 'Collaborator', select: 'Select',
        sltCountry: 'Select Country', sltRegion: 'Select Region', female: 'Female', male: 'Male', other: 'Other'
      },
      es: {
        title: 'Invitar', gender: 'Género', country: 'País', region: 'Región', segments: 'Segmentos',
        role: 'Función', saveDraft: 'Guardar draft', sendInvitation: 'Invitar', close: 'Cerrar',
        type: this.props.type === 'Participant' ? 'Participante' : 'Colaborador', select: 'Selecciona',
        sltCountry: 'Seleccionar País', sltRegion: 'Seleccionar Región', female: 'Mujer', male: 'Hombre', other: 'Otro'
      }
    };

    // TO-DO classNames here? lol, try to change it :)
    const valEmail = classNames({ 'form-group label-floating first': true, 'has-success': this.state.flagEmail });
    const { country, region } = this.state;

    // Custom icon for dropdown selector - React select
    const DropdownIndicator = props => {
      return (
          <components.DropdownIndicator {...props}>
            <FontAwesomeIcon icon={['fas', 'sort']} className="dropdown-icon" data-tooltip-content="" />
          </components.DropdownIndicator>
      );
    };
    // Custom icon for clear selections indicator - React select
    const ClearIndicator = props => {
      return (
          <components.ClearIndicator {...props}>
            <FontAwesomeIcon icon={['fal', 'xmark']} className="clear-indicator-icon" data-tooltip-content="Revert to default" />
          </components.ClearIndicator>
      );
    };
    // Custom icon for value remove - React select
    const MultiValueRemove = props => {
      return (
          <components.MultiValueRemove {...props}>
            <FontAwesomeIcon icon={['fal', 'xmark']} className="clear-indicator-icon" data-tooltip-content="" />
          </components.MultiValueRemove>
      );
    };
    // Custom styles for mandatory select - via React Select
    const customSelectStyles = {
      control: (base, state) => ({
        ...base,
        borderRadius: '0',
        border: 'none',
        borderBottom: state.isFocused ? '2px solid #524fff' : '1px solid #d2d2d2',
        boxShadow: state.isFocused ? 'none' : 'none',
        transitionDuration: '0.3s',
        '&:hover': {
          borderBottom: state.isFocused ? '2px solid #524fff' : '1px solid #d2d2d2'
        }
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: '12px'
      }),
      multiValueLabel: (base) => ({
        ...base,
        backgroundColor: '#AFBDC1',
        color: '#ffffff',
        borderRadius: '2px 0 0 2px',
        textTransform: 'uppercase',
        fontSize: '8px',
        fontFamily: "'Sharp Sans Medium', Helvetica, sans-serif",
        paddingRight: '6px'
      }),
      multiValueRemove: (base) => ({
        ...base,
        backgroundColor: '#AFBDC1',
        color: '#FFFFFF',
        fontSize: '8px',
        borderRadius: '0 2px 2px 0',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#7C8593',
          color: '#FFFFFF'
        }
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
      }),
      clearIndicator: (base) => ({
        ...base,
        cursor: 'pointer'
      }),
      dropdownIndicator: (base) => ({
        ...base,
        fontSize: '12px',
        color: '#7C8593',
        cursor: 'pointer',
        '&:hover': {
          color: '#7C8593',
        }
      }),
      menu: (base) => ({
        ...base,
        background: '#F2F2F2',
        padding: '5px 8px'
      }),
      menuList: (base) => ({
        ...base,
        padding: '8px 0'
      }),
      option: (base, state) => ({
        ...base,
        fontSize: '12px',
        fontFamily: 'Arial, sans-serif',
        padding: '1px 12px',
        backgroundColor: state.isFocused ? '#F2F2F2' : '#F2F2F2',
        '&:hover': {
          backgroundColor: '#5C95F1',
          color: '#FFFFFF'
        }
      }),
    };

    let segmentsOrRoles = <div className="col-md-12">
      <div className="form-group">
        <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].segments}</label>
        <Select className="react-select"
                classNamePrefix="react-select"
                isMulti={true}
                value={this.state.userSegments}
                options={this.props.segments.map(segment => ({value: segment.id, label: segment.name}))}
                onChange={this.segmentChange}
                components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
                styles={customSelectStyles}
                placeholder={i18n[language].select}/>
      </div>
    </div>;
    if(this.props.type === 'Collaborator'){
      if(this.props.role2 === 'Owner'){
        segmentsOrRoles = <div className="col-md-12">
          <div className="form-group">
            <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].role}</label>
            <select name="role_2" onChange={this.handleNotRequired} value={this.state.role_2} className="form-control">
              <option value="Moderator">Moderator</option>
              <option value="Observer">Observer</option>
              <option value="Recruiter">Recruiter</option>
            </select>
          </div>
        </div>;
      } else {
        segmentsOrRoles = <div className="col-md-12">
          <div className="form-group">
            <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].role}</label>
            <select name="role_2" onChange={this.handleNotRequired} value={this.state.role_2} className="form-control">
              <option value="Observer">Observer</option>
              <option value="Recruiter">Recruiter</option>
            </select>
          </div>
        </div>;
      }

    }

    let dateOfBirth = <InputMask value={this.state.dob} name="dob" mask="99/99/9999" maskChar=" "
                                 onChange={this.handleNotRequired} className="form-control" ref="txtDOB"
                                 placeholder="dd/mm/yyyy" disabled={this._validateDisabled(this.state.flagDOB)}/>;
    let countryList = <CountryDropdown value={country}
                                       onChange={(val) => this.selectCountry(val)}
                                       valueType="short"
                                       classes="form-control"
                                       defaultOptionLabel={i18n[language].sltCountry}/>;
    let regionList = <RegionDropdown country={country}
                                     value={region}
                                     onChange={(val) => this.selectRegion(val)}
                                     countryValueType="short"
                                     classes="form-control"
                                     defaultOptionLabel={i18n[language].sltRegion}/>;

    if(this.state.userState !== 'Invited'){
      if(this.state.flagCountry !== ''){
        countryList = <input value={country} className="form-control" disabled={true}/>;
      }
      if(this.state.flagRegion !== ''){
        regionList = <input value={region} className="form-control" disabled={true}/>;
      }
    }

    return(
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button onClick={this.props.updateShowModalQI} type="button" data-dismiss="modal" aria-hidden="true"
                    className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
              <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
            </button>
            <h4 className="px-modal-title">{i18n[language].title}&nbsp;{i18n[language].type}</h4>
          </div>

          <div className="modal-body px-modal-body-add-activity">
            <div className="row">
              <div className="col-md-12">
                <div id="divQIEmail" className={valEmail}>
                  <label className="label-xs" style={{ color: colors.color1[2] }}>Email</label>
                  <input name="email" onBlur={this.handleInputEmailChange} defaultValue={this.state.email}
                         type="text" className="form-control" ref="txtEmail"/>
                  <span className="form-control-feedback">
                    <FontAwesomeIcon icon={['far', 'check']} style={{ fontSize: '16px', color: colors.colorStates[0]  }} />
                  </span>
                  <span ref="spnQIEmail" className='px-label-warning' style={{fontSize: '11px', marginTop: '1px'}}/>
                </div>
              </div>
              {/*
              <div className="col-md-6">
              <div className="form-group" style={{marginTop:'0'}}>
                <label className="label-xs">First Name</label>
                <input name="first_name" onChange={this.handleNotRequired} value={this.state.first_name}
                       type="text" className="form-control" disabled={this._validateDisabled(this.state.flagFirstName)}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group" style={{marginTop:'0'}}>
                <label className="label-xs">Last Name</label>
                <input name="last_name" onChange={this.handleNotRequired} value={this.state.last_name}
                       type="text" className="form-control" disabled={this._validateDisabled(this.state.flagLastName)}/>
              </div>
            </div>
            */}

              <div className="col-md-12">
                <div className="form-group" style={{marginTop:'0'}}>
                  <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].gender}</label>
                  <select name="gender" onChange={this.handleNotRequired} value={this.state.gender}
                          disabled={this._validateDisabled(this.state.flagGender)} className="form-control">
                    <option value="">{i18n[language].select}</option>
                    <option value="Female">{i18n[language].female}</option>
                    <option value="Male">{i18n[language].male}</option>
                    <option value="Other">{i18n[language].other}</option>
                  </select>
                </div>
              </div>

              {/*<div className="col-md-6">
              <div className="form-group">
                <label className="label-xs">Date of Birth</label>
                { dateOfBirth }
              </div>
            </div>*/}

              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].country}</label>
                  { countryList }
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].region}</label>
                  { regionList }
                </div>
              </div>
              {segmentsOrRoles}
            </div>
          </div>

          <div className="modal-footer px-modal-footer-warning">
            <div className="px-footer-container-buttons row">
            <button ref="btnQIDraftInvite" type="button" className="btn px-btn sm btn-turquoise-base"
                onClick={() => this.sendInvite('draft')} disabled={this.state.isInviting} style={{ backgroundColor: colors.color1[3] }}>
                { !this.state.isInviting ? i18n[language].saveDraft : <FontAwesomeIcon icon={['fas','spinner']} spin/>}
              </button>
            <button ref="btnQISendInvite" type="button" className="btn px-btn sm btn-blue-base"
                onClick={() => this.sendInvite('invited')} disabled={this.state.isInviting} style={{ backgroundColor: colors.color1[3] }}>
                { !this.state.isInviting ? i18n[language].sendInvitation : <FontAwesomeIcon icon={['fas','spinner']} spin/>}
              </button>
              <button onClick={this.props.updateShowModalQI} type="button"
                      className="btn px-btn sm btn-gray-darker inverted">{i18n[language].close}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  sendInvite = (option) => {
    if(this.state.flagEmail){
      const { community } = this.props;

      this.refs.btnQIDraftInvite.disabled = true;
      this.refs.btnQISendInvite.disabled = true;

      let subject = community.language === 'en' ? community.participants_invitation_subject : community.participants_invitation_subject_es;
      let body = community.language === 'en' ? community.participants_invitation : community.participants_invitation_es;
      let signature = community.language === 'en' ? community.participants_invitation_signature : community.participants_invitation_signature_es;

      if(this.props.type === 'Collaborator'){
        subject = community.language === 'en' ? community.members_invitation_subject : community.members_invitation_subject_es;
        body = community.language === 'en' ? community.members_invitation : community.members_invitation_es;
        signature = community.language === 'en' ? community.members_invitation_signature : community.members_invitation_signature_es;
      }

      let role2 = '';
      if(this.props.type === 'Collaborator'){
        role2 = this.state.role_2;
      }

      const dob = this.state.dob;
      const dobA =dob.split('/');

      let segments = [];
      if(this.props.type === 'Participant'){
        segments = this.state.userSegments.map((segment) => {
          return segment.value
        });
      }
      //console.log(segments);
      $('#myBlockUI').css('display', 'block');
      this.setState({isInviting: true}, () => {
        $.ajax({
          url: '/users/invitation',
          method: 'POST',
          dataType: 'JSON',
          data: {
            community_id: this.props.community.id,
            email: this.state.email,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            gender: this.state.gender,
            country: this.state.country,
            state_country: this.state.region,
            postcode: this.state.postcode,
            state_community_users: 'Invited',
            dob: dobA[2] + '-' + dobA[1] + '-' + dobA[0],
            role_1: this.props.type,
            role_2: role2,
            subject: subject,
            body: body,
            signature: signature,
            segments: segments,
            option: option,
            type: this.props.type
          },
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: users => {
            console.log('users:', users);
            $('#myBlockUI').css('display', 'none');
            this.setState({ isInviting: false });

            this.props.updateCommunityUsers(users, this.props.type === 'Participant' ? 'participants' : 'collaborators');
            showSuccessMsg('Success!');

            this.refs.btnQIDraftInvite.disabled = false;
            this.refs.btnQISendInvite.disabled = false;
            //Clean & Close Quick Invite
            this._cleanForm('full');
            $('#modalQuickInvite').modal('toggle');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();

            this.props.updateShowModalQI();
          }
        });
      })
    }
  };

  handleNotRequired = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
  };

  handleInputEmailChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const spnQIEmail = this.refs.spnQIEmail;

    if(value !== ''){
      this.setState({[name]: value.trim()}, () => {
        if(this._validateEmailFormat(this.state.email)) {
          this._emailCommunityValidation();
        } else {
          this.setState({flagEmail: false});
          spnQIEmail.style.display = 'block';
          spnQIEmail.textContent = 'Email is invalid'
        }
      });
    } else {
      this.setState({flagEmail: false});
      spnQIEmail.style.display = 'block';
      spnQIEmail.textContent = 'Email is empty'
    }
  };

  _emailCommunityValidation(){
    $.ajax({
      url: '/users/community_email_validation/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      data: {email: this.state.email},
      success: function (obj) {
        if(obj.msg === ''){
          this._cleanForm('partial');
          this.setState({ flagEmail: true });
          this.refs.spnQIEmail.style.display = 'none';
          if(obj.profile !== null){
            const firstName =  obj.profile.first_name ? obj.profile.first_name : '';
            const postCode = obj.profile.postcode ? obj.profile.postcode : '';
            const dob = obj.profile.dob ? obj.profile.dob : '';
            let dobA;
            let dobF;
            if(dob !== ''){
              dobA = dob.split('-');
              dobF = dobA[2] + '/' + dobA[1] + '/' + dobA[0];
            } else {
              dobF = dob;
            }

            this.setState({first_name: firstName, last_name: obj.profile.last_name,
              gender: obj.profile.gender, country: obj.profile.country, region: obj.profile.state_country,
              postcode: postCode, dob: dobF, userState: obj.user_state, flagFirstName: firstName,
              flagLastName: obj.profile.last_name, flagGender: obj.profile.gender, flagDOB: dobF,
              flagCountry: obj.profile.country, flagRegion: obj.profile.state_country});
          }

        } else if(obj.msg === '* Email is taken in this community') {
          this.setState({ flagEmail: false });
          this.refs.spnQIEmail.style.display = 'block';
          this.refs.spnQIEmail.textContent = obj.msg;
        } else{
          this.setState({ flagEmail: false });
          this.refs.spnQIEmail.style.display = 'block';
          this.refs.spnQIEmail.textContent = obj.msg;
        }
      }.bind(this)
    });
  }

  _validateEmailFormat(value) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  }

  _validateDisabled(value){
    let flag = false;
    //console.log(this.state.userState);
    if(this.state.userState !== 'Invited'){
      flag = value !== '';
    }
    return flag;
  }

  _cleanForm = (option) => {
    let role_2 = 'Moderator';
    if(this.props.role2 === 'Moderator'){
      role_2 = 'Observer';
    }

    if(option === 'full'){
      this.refs.txtEmail.value = '';
      this.setState({
        email: '',
        flagEmail: false,
        first_name: '',
        last_name: '',
        gender: '',
        dob: '',
        country: '',
        region: '',
        postcode: '',
        userSegments: [],
        role_2: role_2,
        userState: ''
      });
    } else {
      this.setState({
        flagEmail: false,
        first_name: '',
        last_name: '',
        gender: '',
        dob: '',
        country: '',
        region: '',
        postcode: '',
        userSegments: [],
        role_2: role_2,
        userState: ''
      });
    }

    //this.refs.txtDOB.value = '';
  };

  _uniqueValidation(url, name, value, flagName, ref, msg){
    $.ajax({
      url: url,
      method: 'GET',
      dataType: 'JSON',
      data: {[name]: value },
      success: function (obj) {
        if(!obj.msg){
          this.setState({ [flagName]: true });
          ref.style.display = 'none';
        } else {
          this.setState({ [flagName]: false });
          ref.style.display = 'block';
          ref.textContent = msg;
        }
      }.bind(this)
    });
  }

  _leadingZero (n) {
    return (n < 10) ? ("0" + n) : n;
  }

  selectCountry (val) {
    this.setState({ country: val });
  }

  selectRegion (val) {
    this.setState({ region: val });
  }

  segmentChange = (val) => {
    this.setState({userSegments: val});
  };
}