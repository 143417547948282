import PropTypes from 'prop-types';
import React from 'react';
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import InputMask from "react-input-mask";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"

export default class IVERow extends React.Component {
  static propTypes = {
    invitee: PropTypes.object.isRequired,
    community: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleCountryRegion: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    segments: PropTypes.array.isRequired,
    handleSegmentChange: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {invitee, type, role_2, language, colors} = this.props;
    const i18n = {
      en: {
        select: 'Select', female: 'Female', male: 'Male', other: 'Other',
        observer: 'Observer', recruiter: 'Recruiter', moderator: 'Moderator',
        selectCountry: 'Select country', selectRegion: 'Select region'
      },
      es: {
        select: 'Seleccionar', female: 'Mujer', male: 'Hombre', other: 'Otro',
        observer: 'Observador', recruiter: 'Reclutador', moderator: 'Moderador',
        selectCountry: 'Selecciona país', selectRegion: 'Selecciona región'
      }
    }

    // If the user.state = 'Invited' then you can edit everything
    let flagFN = invitee.flagFirstName;
    let flagLN = invitee.flagLastName;
    let flagGender = invitee.flagGender;
    let flagDOB = invitee.flagDay;
    if(this.props.invitee.userState === 'Invited'){
      flagFN = false;
      flagLN = false;
      flagGender = false;
      flagDOB = false;
    }

    // TO-DO save SHORT NAME of the Country as in Quick Invite
    let countryList = <CountryDropdown value={invitee.country}
                                       valueType="short"
                                       onChange={(val) => this.selectCountry(val)}
                                       classes="form-control"
                                       defaultOptionLabel={i18n[language].selectCountry} />;
    let regionList = <RegionDropdown country={invitee.country}
                                     value={invitee.region}
                                     countryValueType="short"
                                     onChange={(val) => this.selectRegion(val)}
                                     classes="form-control"
                                     defaultOptionLabel={i18n[language].selectRegion} />;

    if(invitee.id !== -1 && invitee.userState !== 'Invited'){
      if(invitee.flagCountry){
        countryList = <input value={invitee.country} className="form-control" disabled={true}/>;
      }
      // it uses the same flagCountry because the Region always change if the Country changes too.
      // But the user can choose only the country without region, so it would be improved with flagRegion
      if(invitee.flagCountry){
        regionList = <input value={invitee.region} className="form-control" disabled={true}/>
      }
    }

    // Custom icon for dropdown selector - React select
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="dropdown-icon" data-tooltip-content="" />
        </components.DropdownIndicator>
      );
    };

    // Custom icon for clear selections indicator - React select
    const ClearIndicator = props => {
      return (
        <components.ClearIndicator {...props}>
          <FontAwesomeIcon icon={['fal', 'rotate-left']} className="clear-indicator-icon" data-tooltip-content="Revert to default" />
        </components.ClearIndicator>
      );
    };

    // Custom icon for value remove - React select
    const MultiValueRemove = (props) => {
      return (
        <components.MultiValueRemove {...props}>
          <FontAwesomeIcon icon={['fal', 'xmark']} className="clear-indicator-icon" data-tooltip-content="" />
        </components.MultiValueRemove>
      );
    };

    // Custom styles for mandatory select - via React Select 
    const customSelectStyles = {
      control: (base) => ({
        ...base,
        // width: '220px',
        borderRadius: '6',
        border: '0.7px solid #DADDE1',
        marginTop: '10px',
        minHeight: '30px',
        '&:hover': {
          border: '0.7px solid #DADDE1'
        }
      }),
      valueContainer: (base) => ({
        ...base,
        paddingTop: '4',
        paddingBottom: '4',
      }),
      multiValue: (base) => ({
        ...base,
        marginTop: '1',
        marginBottom: '1',
      }),
      multiValueLabel: (base) => ({
        ...base,
        backgroundColor: '#AFBDC1',
        color: '#ffffff',
        borderRadius: '2px 0 0 2px',
        textTransform: 'uppercase',
        fontSize: '8px',
        fontFamily: "'Sharp Sans Medium', Helvetica, sans-serif",
        paddingTop: '2px',
        paddingBottom: '1px',
        marginTop: '0',
        marginBottom: '0',
        lineHeight: '15px'
      }),
      multiValueRemove: (base) => ({
        ...base,
        backgroundColor: '#AFBDC1',
        color: '#FFFFFF',
        fontSize: '8px',
        borderRadius: '0 2px 2px 0',
        cursor: 'pointer',
        padding: '0 5px',
        '&:hover': {
          backgroundColor: '#7C8593',
          color: '#FFFFFF'
        }
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
      }),
      input: (base) => ({
        ...base,
        marginTop: '0',
        marginBottom: '0',
        paddingTop: '0',
        paddingBottom: '0',
        display: 'none'

      }),
      clearIndicator: (base) => ({
        ...base,
        cursor: 'pointer',
        padding: '5px 4px',
        fontSize: '11px',
        display: 'none'
      }),
      dropdownIndicator: (base) => ({
        ...base,
        fontSize: '12px',
        color: '#7C8593',
        paddingRigth: '10px',
        padding: '5px',
        cursor: 'pointer',
        '&:hover': {
          color: '#7C8593',
        }
      }),
      menu: (base) => ({
        ...base,
        background: '#F2F2F2',
        padding: '5px 8px'
      }),
      menuList: (base) => ({
        ...base,
        padding: '8px 0'
      }),
      option: (base, state) => ({
        ...base,
        fontSize: '12px',
        fontFamily: 'Arial, sans-serif',
        padding: '1px 12px',
        backgroundColor: state.isFocused ? '#F2F2F2' : '#F2F2F2',
        '&:hover': {
          backgroundColor: '#5C95F1',
          color: '#FFFFFF'
        }
      }),
    };

    let tdSegments = null;
    if(type === 'Participant'){
      const segments = this.props.segments.map((segment) => {
        return { value: segment.id, label: segment.name };
      });

      tdSegments = <td style={{width: '21%'}}>
        <Select className="react-select"
                classNamePrefix="react-select"
                value={invitee.userSegments}
                options={segments}
                onChange={(val) => this.segmentChange(val, invitee.id)}
                isMulti={true}
                components={{ DropdownIndicator, ClearIndicator, MultiValueRemove, MultiValueContainer }}
                placeholder={''}
                styles={customSelectStyles} 
        />
      </td>;
    } else {
      switch(role_2){
        case 'Owner':
          tdSegments = <td style={{width: '15%'}}>
            <div className="form-group select-c">
              <select name="role_2" onChange={(event) => this.props.handleInputChange(event, invitee.id)}
                      onBlur={this.handleOnBlur} value={invitee.role_2}
                      className="form-control">
                <option value="Moderator">{i18n[language].moderator}</option>
                <option value="Observer">{i18n[language].observer}</option>
                <option value="Recruiter">{i18n[language].recruiter}</option>
              </select>
            </div>
          </td>;
          break;
        case 'Moderator':
        case 'Recruiter':
          tdSegments = <td style={{width: '15%'}}>
            <div className="form-group select-c">
              <select name="role_2" onChange={(event) => this.props.handleInputChange(event, invitee.id)}
                      onBlur={this.handleOnBlur} value={invitee.role_2}
                      className="form-control">
                <option value="Observer">{i18n[language].observer}</option>
                <option value="Recruiter">{i18n[language].recruiter}</option>
              </select>
            </div>
          </td>;
          break;
      }
    }

    return (
      <tr>
        <td className="email-cell" >
          <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

          <div className="form-group" >
            <div className="email-cont" data-tooltip-content={invitee.email}>
              {invitee.email}
            </div>
          </div>
          <span ref="spnQIEmail" style={{display: 'none', color: '#f44336', fontSize: '11px'}}/>
        </td>
        {/*<td>
          <div className="form-group">
            <input name="first_name" onChange={(event) => this.props.handleInputChange(event, invitee.id)}
                   onBlur={this.handleOnBlur} value={invitee.first_name} type="text" className="form-control"
                   disabled={flagFN}/>
          </div>
        </td>*/}
        {/*<td>
          <div className="form-group">
            <input name="last_name" onChange={(event) => this.props.handleInputChange(event, invitee.id)}
                   onBlur={this.handleOnBlur} value={invitee.last_name} type="text" className="form-control"
                   disabled={flagLN}/>
          </div>
        </td>*/}
        <td>
          <div className="form-group select-c">
            <select name="gender" onChange={(event) => this.props.handleInputChange(event, invitee.id)}
                    onBlur={this.handleOnBlur} value={invitee.gender} className="form-control" disabled={flagGender}>
              <option value="">{i18n[language].select}</option>
              <option value="Female">{i18n[language].female}</option>
              <option value="Male">{i18n[language].male}</option>
              <option value="Other">{i18n[language].other}</option>
            </select>
          </div>
        </td>
        <td>
          <div className="form-group select-c">
            { countryList }
          </div>
        </td>
        <td>
          <div className="form-group select-c">
            { regionList }
          </div>
        </td>
        {/*<td>
          <div className="form-group">
            <InputMask name="dob" onChange={(event) => this.props.handleInputChange(event, invitee.id)}
                       onBlur={this.handleOnBlur} value={this._formatDOB(invitee.dob, 'dd/mm/yyyy') || ''}
                       mask="99/99/9999" maskChar=" " className="form-control" placeholder="dd/mm/yyyy"
                       disabled={flagDOB}/>
          </div>
        </td>*/}
        { tdSegments }
      </tr>
    );
  }

  segmentChange = (val, inviteeId) => {
    this.props.handleSegmentChange('userSegments', val, inviteeId);
  };

  _formatDOB(dob, format){
    let formatted = '';

    if(dob !== '' && dob){
      switch(format){
        case 'yyyy-mm-dd':
          const dobA = dob.split('/');
          formatted = dobA[2] + '-' + dobA[1] + '-' + dobA[0];
          break;
        case "dd/mm/yyyy":
          const dobB = dob.split('-');
          formatted = dobB[2] + '/' + dobB[1] + '/' + dobB[0];
          break;
      }
    }

    return formatted;
  }

  selectCountry = (val) => {
    this.props.handleCountryRegion(val, 'country', this.props.invitee.id);
  };

  selectRegion = (val) => {
    this.props.handleCountryRegion(val, 'region', this.props.invitee.id);
  }
}

const MultiValueContainer = (props) => {
  //console.log(props);
  return (
    <div>
      <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
      <div content={'Customise your option component!'} data-tooltip-content={props.data.label}>
        <components.MultiValueContainer {...props}/>
      </div>
    </div>
  );
};
