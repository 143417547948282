import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"
import {numberFilterSegment} from "../../../../UI/Globals/PX_Funs";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import {usernamesToDisplay} from "../../../shared_utils";

export default class TBMEExport extends React.Component {
  static propTypes = {
    userFilters: PropTypes.array.isRequired,
    allAvailableActs: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    segments: PropTypes.array.isRequired,
    genderAsSegment: PropTypes.bool.isRequired,
    tags: PropTypes.array.isRequired,
    objExport: PropTypes.object.isRequired,
    updateObjExportActions: PropTypes.func.isRequired,
    dataTypes: PropTypes.array.isRequired,
    updateCFId: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    updateType: PropTypes.func.isRequired,
    filterExportTo: PropTypes.string.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    privacy: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
    updateFiltersOptsExp: PropTypes.func.isRequired,
    updateSltCalendarExp: PropTypes.func.isRequired,
    updateStartExpireDatesExp: PropTypes.func.isRequired,
    filterFocusOn: PropTypes.string.isRequired,
    updateFilterFocusOn: PropTypes.func.isRequired,
    community: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      //type: 'Main',
      //filterExportTo: 'pdf',
      filterFocusOn: 'Participants',
      //filterFocusOn: 'Activities'
      // NEW
      q: '',
      filterAllNoneParticipants: false,
      filterAllNoneSegments: false,
      filterAllNoneActivities: false,
      filterAllNoneTags: false,
      type: 'Participants',
      showCalendarOpts: false
    };
  }

  render() {
    const {userFilters, allAvailableActs, objExport, users, segments, tags, dataTypes, type, genderAsSegment,
      filterExportTo, privacy, language, filterFocusOn, community} = this.props
    const allowAIReport = community.ai_plan === "aiPlan03"

    const i18n = {
      en: {
        acts: 'Activities', parts: 'Participants', segs: 'Segments', tags: 'Tags', data: 'Data to include',
        privacy: 'Privacy', expTo: 'Export to', focus: 'Organize by', pickFilter: 'Pick one or more items',
        rawData: 'Raw Data', creationDate: 'Creation Date', startDate: 'Start date', endDate: 'End date',
        search: 'SEARCH', all: 'All', none: 'None', participants: 'participants', ai: 'AI Report',
        available: 'Available', expired: 'Expired', live: 'Live', tasks: 'Tasks', responses: 'Responses'
      },
      es: {
        acts: 'Actividades', parts: 'Participantes', segs: 'Segmentos', tags: 'Tags', data: 'Datos a incluir',
        privacy: 'Privacidad', expTo: 'Exportar a', focus: 'Ordenar por', pickFilter: 'Selecciona una o más casillas',
        rawData: 'Datos Brutos', creationDate: 'Creación', startDate: 'Inicio', endDate: 'Expiración',
        search: 'BUSCAR', all: 'Todo', none: 'Nada', participants: 'participantes', ai: 'Reporte AI',
        available: 'Disponibles', expired: 'Expiradas', live: 'Live', tasks: 'Tasks', responses: 'Respuestas'
      }
    }

    let searchForm = <div className="form-group xs first">
      <div className="px-input-icon">
        <input name="q" type="text" onChange={this.handleFilters}
               onKeyDown={(event) => this.keyPress(event, type)}
               className="form-control icon-right" placeholder="" />
        <FontAwesomeIcon icon={['fas', 'magnifying-glass']} className="px-icon-right color-gray-base"
                         onClick={() => this.searchTask(type)}/>
      </div>
    </div>;

    let rdoAll = <div className="px-check-cont rad"><label className="container-check radio">
      <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="All"
             checked={this.state.filterAllNone === 'All'}/>
      <span className="checkmark radio"/>
      <span style={{fontSize: '10px'}}>{i18n[language].all}</span>
    </label></div>;
    let rdoNone = <div className="px-check-cont rad"><label className="container-check radio">
      <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="None"
             checked={this.state.filterAllNone === 'None'}/>
      <span className="checkmark radio"/>
      <span style={{fontSize: '10px'}}>{i18n[language].none}</span>
    </label></div>;
    let rdoAvailable = <div className="px-check-cont rad"><label className="container-check radio">
      <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="Available"
             checked={this.state.filterAllNone === 'Available'}/>
      <span className="checkmark radio"/>
      <span style={{fontSize: '10px'}}>{i18n[language].available}</span>
    </label></div>;
    let rdoExpired = <div className="px-check-cont rad"><label className="container-check radio">
      <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="Expired"
             checked={this.state.filterAllNone === 'Expired'}/>
      <span className="checkmark radio"/>
      <span style={{fontSize: '10px'}}>{i18n[language].expired}</span>
    </label></div>;
    let calendarIcon = <FontAwesomeIcon icon={['fal', 'calendar-days']} onClick={this.updateShowCalendarOpts}
                                        className='px-adv-filters-calendar'/>;

    let custom = null;
    let activities = null;
    let participants = null;
    let segmentsC = null;
    let tagsC = null;
    let dataTypesC = null;
    let privacyC = null;
    let calendarOptions = null;

    switch(type){
      case 'Custom':
        rdoAll = null;
        rdoAvailable = null;
        rdoExpired = null;
        calendarIcon = null;

        custom = userFilters.map(filter => {
          const checked = objExport.cfId === filter.id;

          return(
            <div className="px-check-cont rad">
              <label key={filter.id} className="container-check radio">
                <input name="filterAllNone" type="radio" onChange={() => this.props.updateCFId(filter.id)}
                       checked={checked}/>
                <span className="checkmark radio"/>
                <span style={{fontSize: '10px'}}>{filter.name}</span>
              </label>
            </div>
          )
        });
        break;
      case 'Activities':
        if(this.state.showCalendarOpts){
          calendarOptions = <div className="px-date-filters">
            <div className="px-select">
              <select name="slcActDates" value={objExport.actDates.name} onChange={this.props.updateSltCalendarExp}>
                <option value="creation">{i18n[language].creationDate}</option>
                <option value="start">{i18n[language].startDate}</option>
                <option value="expiry">{i18n[language].endDate}</option>
              </select>
            </div>
            <div className="date-container">
              <DateRangePicker
                startDate={objExport.actDates.startDate ? moment(objExport.actDates.startDate) : null}
                startDateId="objExport_start_date_id"
                endDate={objExport.actDates.endDate ? moment(objExport.actDates.endDate) : null}
                endDateId="objExport_end_date_id"
                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate },
                  () => this.showDates())} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                minimumNights={0}
                displayFormat="DD/MM/YYYY"
                isOutsideRange={() => false}
                isDayHighlighted={ day => day.isSame( moment(), 'd' ) }
                hideKeyboardShortcutsPanel={true}
              />
              <FontAwesomeIcon icon={['far', 'xmark']} className="info-dialog"
                               onClick={this.clearDates} style={{cursor:'pointer'}}/>
            </div>
          </div>
        }

        activities = allAvailableActs.map(activity => {
          const checked = objExport.idActs !== undefined ?
            objExport.idActs.find(id => id === activity.id) !== undefined : false;

          return(
            <div key={activity.id} className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div className="px-check-cont">
                <label className="container-check filters-modal">
                  <input type="checkbox" onChange={() => this.props.updateObjExportActions(activity.id, 'idActs')}
                         checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <div className="user-data" style={{overflow: 'hidden'}}>
                    <div style={{fontSize: '12px', color: "#7C8593"}}
                         data-tooltip-content={`${activity.title} - ${activity.questions.length} ${i18n[language].tasks}`}>
                      {activity.title}
                    </div>
                    {/*<div className="txt-email">{activity.questions.length} tasks</div>*/}
                  </div>
                </label>
              </div>
            </div>
          )
        });
        break;
      case 'Participants':
        rdoAvailable = null;
        rdoExpired = null;
        calendarIcon = null;

        rdoAll = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="All"
                   checked={users.length === objExport.idPars.length}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{i18n[language].all}</span>
          </label>
        </div>;
        rdoNone = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="None"
                   checked={objExport.idPars.length === 0}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{i18n[language].none}</span>
          </label>
        </div>;

        participants = users.map(user => {
          const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
          const mainName = availableUsernames[0];
          const alternativeNames = availableUsernames.length > 1 && availableUsernames.slice(1).join(', ')
          const checked = objExport.idPars.find(id => user.id === id) !== undefined;

          return(
            <div key={user.id} className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div className="px-check-cont">
                <label className="container-check filters-modal">
                  <input type="checkbox" onChange={() => this.props.updateObjExportActions(user.id, 'idPars')} checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <div className="user-data" style={{overflow: 'hidden'}}>
                    <div style={{fontSize: '12px', color: "#7C8593"}} data-tooltip-content={alternativeNames ? `a.k.a:  ${alternativeNames}` : '' }>
                      {mainName}
                    </div>
                    {/*<div className="txt-email">{user.email}</div>*/}
                  </div>
                </label>
              </div>
            </div>
          )
        });
        break;
      case 'Segments':
        rdoAvailable = null;
        rdoExpired = null;
        calendarIcon = null;

        rdoAll = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="All"
                   checked={segments.length === objExport.idSegs.length}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{i18n[language].all}</span>
          </label>
        </div>;
        rdoNone = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="None"
                   checked={objExport.idSegs.length === 0}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{i18n[language].none}</span>
          </label>
        </div>;

        segmentsC = segments.map(segment => {
          const checked = objExport.idSegs.find(id => segment.id === id) !== undefined;

          let icon;
          switch(segment.name){
            case 'Female':
              icon = ['fal', 'venus'];break;
            case 'Male':
              icon = ['fal', 'mars'];break;
            case 'Other Gender':
              icon = ['fal', 'transgender-alt'];break;
            default:
              icon = ['fas', 'bookmark'];
          }

          return(
            <div key={segment.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
              <div className="px-check-cont">
                <label className="container-check filters-modal img">
                  <input type="checkbox" onChange={() => this.props.updateObjExportActions(segment.id, 'idSegs')} checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <FontAwesomeIcon icon={icon} className="tag icon" style={{color: segment.color}}/>
                  <div className="user-data" style={{overflow: 'hidden'}}>
                    <div style={{fontSize: '12px', color: "#7C8593"}}
                         data-tooltip-content={`${segment.name} - ${numberFilterSegment(segment.id, users, genderAsSegment)} ${i18n[language].participants}`}>
                      {segment.name}
                    </div>
                    {/*<div className="txt-email">*/}
                    {/*  {numberFilterSegment(segment.id, users, genderAsSegment) } {i18n[language].participants}*/}
                    {/*</div>*/}
                  </div>
                </label>
              </div>
            </div>
          )
        });
        break;
      case 'Tags':
        rdoAvailable = null;
        rdoExpired = null;
        calendarIcon = null;

        rdoAll = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="All"
                   checked={tags.length === objExport.idTags.length}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{i18n[language].all}</span>
          </label>
        </div>;
        rdoNone = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="None"
                   checked={objExport.idTags.length === 0}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{i18n[language].none}</span>
          </label>
        </div>;

        tagsC = tags.map(tag => {
          const checked = objExport.idTags.find(id => tag.id === id) !== undefined;

          return(
            <div key={tag.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
              <div className="px-check-cont">
                <label className="container-check filters-modal img">
                  <input type="checkbox" onChange={() => this.props.updateObjExportActions(tag.id, 'idTags')} checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <FontAwesomeIcon icon={['fas','tag']} className="tag icon" style={{color: tag.color}}/>
                  <div className="user-data" style={{overflow: 'hidden'}}>
                    <div style={{fontSize: '12px', color: "#7C8593"}}
                         data-tooltip-content={`${tag.name} - ${this._numberFilterTag(tag.id, allAvailableActs)} ${i18n[language].tags}`}>
                      {tag.name}
                    </div>
                    {/*<div className="txt-email">*/}
                    {/*  { this._numberFilterTag(tag.id, allAvailableActs) } activities*/}
                    {/*</div>*/}
                  </div>
                </label>
              </div>
            </div>
          )
        });
        break;
      case 'Data Types':
        rdoAvailable = null;
        rdoExpired = null;
        calendarIcon = null;

        rdoAll = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="All"
                   checked={dataTypes.length === objExport.dataTypes.length}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{i18n[language].all}</span>
          </label>
        </div>;
        rdoNone = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="None"
                   checked={objExport.dataTypes.length === 0}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{i18n[language].none}</span>
          </label>
        </div>;

        dataTypesC = dataTypes.map((dataName, index) => {
          const checked = objExport.dataTypes.find(name => name === dataName) !== undefined;

          return(
            <div key={index} className="col-xs-6 col-sm-4 col- md-4 col-lg-4">
              <div className="px-check-cont">
                <label className="container-check filters-modal">
                  <input type="checkbox" onChange={() => this.props.updateObjExportActions(dataName, 'dataTypes')}
                         checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <div className="user-data" style={{overflow: 'hidden'}}>
                    <div style={{fontSize: '12px', color: "#7C8593"}}>{dataName}</div>
                  </div>
                </label>
              </div>
            </div>
          )
        });
        break;
      case 'Privacy':
        rdoAvailable = null;
        rdoExpired = null;
        calendarIcon = null;
        searchForm = null;

        rdoAll = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="All"
                   checked={privacy.length === objExport.privacy.length}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{i18n[language].all}</span>
          </label>
        </div>;
        rdoNone = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="None"
                   checked={objExport.privacy.length === 0}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{i18n[language].none}</span>
          </label>
        </div>;

        privacyC = privacy.map((p, index) => {
          const checked = objExport.privacy.find(name => name === p) !== undefined;

          return(
            <div key={index} className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div className="px-check-cont">
                <label className="container-check filters-modal">
                  <input type="checkbox" onChange={() => this.props.updateObjExportActions(p, 'privacy')}
                         checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <div className="user-data" style={{overflow: 'hidden'}}>
                    <div style={{fontSize: '12px', color: "#7C8593"}}>{p}</div>
                  </div>
                </label>
              </div>
            </div>
          )
        });
        break;
    }

    return (
      <div className="active tab-pane fade in" id="export">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        {
          type !== 'Main' ?
            <React.Fragment>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-custom-filters-title-container">
                    <FontAwesomeIcon icon={['fas', 'circle-arrow-left']} onClick={() => this.props.updateType('Main')}
                                     style={{cursor:'pointer'}}/>
                    <span className="px-lbl-custom-filters">{i18n[language].pickFilter}</span>
                  </div>
                </div>
              </div>
              <div className="px-filter-adv-filter-modal-cont">
                { searchForm }
                <div className="px-right-container"
                     style={{marginLeft: type === 'Activities' ? 0 : 'auto',
                       marginTop: type === 'Activities' ? '10px' : 0}}>
                  { rdoAll }
                  { rdoNone }
                  { rdoAvailable }
                  { rdoExpired }
                  { calendarIcon }
                </div>
              </div>
              { calendarOptions}
              <div className="modal-scroll">
                <div className="row">
                  { custom }
                  { activities }
                  { participants }
                  { segmentsC }
                  { tagsC }
                  { dataTypesC }
                  { privacyC }
                </div>
              </div>
            </React.Fragment> :
            <div className="resume-container">
              {
                (objExport.idActs.length === 0 || objExport.idPars.length === 0) &&
                <span className="px-label-warning"
                      style={{fontSize: '11px', color: 'rgb(235, 120, 16)'}}>*You must select at least 1 Activity and 1 Participant</span>
              }

              {
                userFilters.length !== 0 &&
                <div className="filter-element">
                  <div className="header-element">
                    <span className="title">Custom filters</span>
                    <FontAwesomeIcon onClick={() => this.props.updateType('Custom')} icon={['fas','pencil']} className="icon"
                                     style={{cursor:'pointer'}}/>
                  </div>
                  <p className="selected-filters">{this._formatSelected([], 'cfId')}</p>
                </div>
              }
              <div className="filter-element">
                <div className="header-element">
                  <span className="title">{i18n[language].acts}</span>
                  <FontAwesomeIcon onClick={() => this.props.updateType('Activities')} icon={['fas','pencil']} className="icon"
                                   style={{cursor:'pointer'}}/>
                </div>
                <p className="selected-filters">{ this._formatSelected(objExport.idActs, 'activities') }</p>
              </div>
              <div className="filter-element">
                <div className="header-element">
                  <span className="title">{i18n[language].parts}</span>
                  <FontAwesomeIcon onClick={() => this.props.updateType('Participants')} icon={['fas','pencil']} className="icon"
                                   style={{cursor:'pointer'}}/>
                </div>
                <p className="selected-filters">{ this._formatSelected(objExport.idPars, 'participants') }</p>
              </div>
              <div className="filter-element">
                <div className="header-element">
                  <span className="title">{i18n[language].segs}</span>
                  <FontAwesomeIcon onClick={() => this.props.updateType('Segments')} icon={['fas','pencil']} className="icon"
                                   style={{cursor:'pointer'}}/>
                </div>
                <p className="selected-filters">{ this._formatSelected(objExport.idSegs, 'segments') }</p>
              </div>
              <div className="filter-element">
                <div className="header-element">
                  <span className="title">{i18n[language].tags}</span>
                  <FontAwesomeIcon onClick={() => this.props.updateType('Tags')} icon={['fas','pencil']} className="icon"
                                   style={{cursor:'pointer'}}/>
                </div>
                <p className="selected-filters">{ this._formatSelected(objExport.idTags, 'tags') }</p>
              </div>
              <div className="filter-element">
                <div className="header-element">
                  <span className="title">{i18n[language].data}</span>
                  <FontAwesomeIcon onClick={() => this.props.updateType('Data Types')} icon={['fas','pencil']}
                                   className="icon" style={{cursor:'pointer'}}/>
                </div>
                <p className="selected-filters">{ this._formatSelected(objExport.dataTypes, 'data types') }</p>
              </div>
              <div className="filter-element">
                <div className="header-element">
                  <span className="title">{i18n[language].privacy}</span>
                  <FontAwesomeIcon onClick={() => this.props.updateType('Privacy')} icon={['fas','pencil']}
                                   className="icon" style={{cursor:'pointer'}}/>
                </div>
                <p className="selected-filters">{ this._formatSelected(objExport.privacy, 'privacy') }</p>
              </div>
              <div className="filter-element" style={{marginBottom:'0'}}>
                <div className="header-element">
                  <span className="title">{i18n[language].expTo}</span>
                </div>
                <div style={{display: 'flex'}}>
                  <div className="px-check-cont rad">
                    <label className="container-check radio">
                      <input type="radio" name="filterExportTo" onChange={this.props.handleInputChange} value='pdf'
                             checked={filterExportTo === 'pdf'}/>
                      <span className="checkmark radio" style={{marginRight:'5px'}}/>
                      <span>PDF</span>
                    </label>
                  </div>
                  <div className="px-check-cont rad mg-left-5">
                    <label className="container-check radio">
                      <input type="radio" name="filterExportTo" onChange={this.props.handleInputChange} value='excel'
                             checked={filterExportTo === 'excel'}/>
                      <span className="checkmark radio" style={{marginRight:'5px'}}/>
                      <span>MS EXCEL ({i18n[language].rawData})</span>
                    </label>
                  </div>
                  <div className="px-check-cont rad mg-left-5">
                    <label className="container-check radio">
                      <input type="radio" name="filterExportTo" onChange={this.props.handleInputChange} value='excel-responses'
                             checked={filterExportTo === 'excel-responses'}/>
                      <span className="checkmark radio" style={{marginRight: '5px'}}/>
                      <span>MS EXCEL ({i18n[language].responses})</span>
                    </label>
                  </div>
                  {allowAIReport && <div className="px-check-cont rad mg-left-5">
                    <label className="container-check radio">
                      <input type="radio" name="filterExportTo" onChange={this.props.handleInputChange} value='ai'
                             checked={filterExportTo === 'ai'}/>
                      <span className="checkmark radio" style={{marginRight: '5px'}}/>
                      <span>PDF ({i18n[language].ai})</span>
                    </label>
                  </div>}
                </div>
                <p className="selected-filters"/>
              </div>
              <div className="filter-element" style={{marginBottom:'0'}}>
                <div className="header-element">
                  <span className="title">{i18n[language].focus}</span>
                </div>
                <div style={{display: 'flex'}}>
                  {
                    filterExportTo === 'pdf' &&
                    <div className="px-check-cont rad">
                      <label className="container-check radio">
                        <input type="radio" name="filterFocusOn" onChange={() => this.props.updateFilterFocusOn('Participants')}
                               value={filterFocusOn}
                               checked={filterFocusOn === 'Participants'} />
                        <span className="checkmark radio" style={{ marginRight: '5px' }} />
                        <span>{i18n[language].parts}</span>
                      </label>
                    </div>
                  }
                  <div className="px-check-cont rad mg-left-5">
                    <label className="container-check radio">
                      <input type="radio" name="filterFocusOn" onChange={() => this.props.updateFilterFocusOn('Activities')}
                             value={filterFocusOn}
                             checked={(filterFocusOn === 'Activities')}/>
                      <span className="checkmark radio" style={{marginRight:'5px'}}/>
                      <span>{i18n[language].acts}</span>
                    </label>
                  </div>
                </div>
                <p className="selected-filters"/>
              </div>
            </div>
        }
      </div>
    );
  }

  // PASS to global function
  _numberFilterTag(tagId, tasks){
    let counter = 0;

    tasks.forEach(task => {
      if(task.tag_id){
        if(task.tag_id === tagId){
          ++counter;
        }
      }
    });

    return counter
  }

  handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    //console.log(name, value);
    this.setState({[name]: value});
  };

  /*toExport = (type) => {
    const {objExport} = this.props;

    const images = objExport.dataTypes.find(name => name === 'images') !== undefined;
    const maps = objExport.dataTypes.find(name => name === 'maps') !== undefined;
    const comments = objExport.dataTypes.find(name => name === 'comments') !== undefined;
    const verbatims = objExport.dataTypes.find(name => name === 'verbatims') !== undefined;

    const tasksId = objExport.idActs.length === 1 ? objExport.idTasks.map(id => id) : -1;
    const url = '/exports/to_pdf/show.pdf?activitiesId=['+ objExport.idActs.map(id => id) + ']' +
      '&tasksId=[' + tasksId + ']&usersId=['+ objExport.idPars.map(id => id) +']' +
      '&type=' + type + '&images=' + images + '&moods=' + maps + '&comments=' + comments + '&verbatim=' + verbatims +
      '&focus=' + this.state.filterFocusOn;
    console.log(url);
    if(objExport.idActs.length >= 1){
      window.open(url, '_blank');
    }
  };*/

  updateType = type => {
    this.setState({type});
  };

  _formatSelected(array, type){
    //console.log(array, type);
    const {objExport, userFilters, allAvailableActs, users, segments, tags, dataTypes, privacy, language} = this.props;
    const i18n = { en: { select: 'Select', selectFilter: 'Select a custom filter' }, 
    es: { select: 'Selecciona', selectFilter: 'Selecciona un filtro'}}
    let selected = '';

    if(array !== undefined){
      if(type !== 'cfId'){
        if(array.length === 0){
          selected = `${i18n[language].select} ` + type;
        }

        if(array.length === 1 && array[0]){
          switch(type){
            case 'activities':
              //console.log(allAvailableActs, objExport);
              selected = allAvailableActs.find(a => a.id === objExport.idActs[0]).title; break;
            case 'participants':
              // console.log('objExportedPar',objExport.idPars[0] )
              selected = users.find(u => u.id === objExport.idPars[0]).username; break;
            case 'segments':
              selected = segments.find(s => s.id === objExport.idSegs[0]).name; break;
            case 'tags':
              selected = tags.find(t => t.id === objExport.idTags[0]).name; break;
            case 'data types':
              selected = objExport.dataTypes[0]; break;
            case 'privacy':
              selected = objExport.privacy[0]; break;
          }
        }

        if(array.length > 1){
          switch(type){
            case 'activities':
              selected = this._findAndFormat(allAvailableActs, objExport.idActs, type); break;
            case 'participants':
              selected = this._findAndFormat(users, objExport.idPars, type); break;
            case 'segments':
              selected = this._findAndFormat(segments, objExport.idSegs, type); break;
            case 'tags':
              selected = this._findAndFormat(tags, objExport.idTags, type); break;
            case 'data types':
              selected = this._findAndFormat(dataTypes, objExport.dataTypes, type); break;
            case 'privacy':
              selected = this._findAndFormat(privacy, objExport.privacy, type); break;
          }
        }
      } else {
        if(objExport.cfId){
          selected = userFilters.find(uf => uf.id === objExport.cfId).name;
        } else {
          selected = i18n[language].selectFilter;
        }
      }
    }

    return selected;
  }

  _findAndFormat(mainArray, array, type){
    //console.log(mainArray, array, type);
    let string = '';

    for(let i = 0; i < array.length ; i++) {
      if(i < 3){
        switch(type){
          case 'activities':
            const activity = mainArray.find(a => a.id === array[i]);
            string += activity.title + ', ';
            break;
          case 'participants':
            const user = mainArray.find(u => u.id === array[i]);
            const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
            const mainName = availableUsernames[0];
            const alternativeNames = availableUsernames.length > 1 ? availableUsernames.slice(1).join(', ') : ''

            string += mainName + ', ';
            break;
          case 'segments':
            const segment = mainArray.find(s => s.id === array[i]);
            string += segment.name + ', ';
            break;
          case 'tags':
            const tag = mainArray.find(t => t.id === array[i]);
            string += tag.name + ', ';
            break;
          case 'data types':
            const names = array[i];
            string += names + ', ';
            break;
          case 'privacy':
            const namesP = array[i];
            string += namesP + ', ';
            break;
        }
      } else {
        break;
      }
    }
    string =  string.slice(0, -2);

    if(array.length > 3){
      string = string + ' and ' + (array.length - 3) + ' others';
    }

    return string
  }

  handleFilters = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    //We need to setup the final rule for search, after that then delete this validation
    if(name !== 'q'){
      this.setState({[name]: value}, () => {
        this.props.updateFiltersOptsExp(this.props.type, value);
      });
    }
  }

  // Maybe onDatesChange just needs to send the dates, but this.state is to show dates in the calendar
  showDates = () => {
    const startDate = this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : null;
    const endDate = this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null;
    //console.log(startDate, endDate);
    this.props.updateStartExpireDatesExp(startDate, endDate);
  };

  clearDates = () => {
    this.props.updateStartExpireDatesExp(null, null);
  }

  updateShowCalendarOpts = () => {
    this.setState(state => ({showCalendarOpts: !state.showCalendarOpts}));
  };
}
