import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";

export default class UIGModal00 extends React.Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showModal} contentLabel="Modal Test" shouldCloseOnOverlayClick={true}
                  onRequestClose={() => this.props.updateShowModal('showModal')} className="px-modal-content"
                  overlayClassName="px-modal-overlay">
        <div className="modal-dialog">
          <div className="modal-content">
            <button type="button" className="btn px-btn circle xs btn-gray-lighter color-white px-close-modal">
              <i onClick={() => this.props.updateShowModal('showModal')} className="material-icons">clear</i>
            </button>
            <div className="modal-header">
              <h1 className="px-modal-title">Prueba de titulo</h1>
            </div>

            <div className="modal-body px-modal-body-add-activity">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Cum architecto vel minima id cumque officia magni quos, quibusdam animi?
              Asperiores eum placeat laudantium repudiandae quo aspernatur itaque ducimus qui aliquam.
            </div>

            <div className="separator"/>
            <div className="modal-footer px-modal-footer-gray">
              <div className="px-footer-container-buttons">
                <button className="btn px-btn sm btn-blue-base">Create</button>
                <button onClick={() => this.props.updateShowModal('showModal')} type="button" className="btn px-btn sm btn-gray-darker inverted">Close</button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}
