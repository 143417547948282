import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TPParticipants from "./ToPdf/TPParticipants";
import TPActivities from "./ToPdf/TPActivities";
import {usernamesToDisplay} from "../Community/shared_utils";

export default class ToPdf extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    activity: PropTypes.object.isRequired,
    responses: PropTypes.array.isRequired,
    participants: PropTypes.array.isRequired,
    focus: PropTypes.string.isRequired,
    pEmail: PropTypes.bool.isRequired,
    pAnn: PropTypes.bool.isRequired,
    pAlias: PropTypes.bool.isRequired,
    users_anonymous: PropTypes.array.isRequired,
    export_to: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      responses: props.responses,
      pEmail: props.pEmail,
      pAnn: props.pAnn,
      pAlias: props.pAlias
    }
  }

  componentDidMount() {
    if (this.props.pAnn === true) {
      this.setState({pEmail: false, pAlias: false })
    }
  }

  render() {
    const {participants, focus, community, users_anonymous, export_to} = this.props
    const {responses, pEmail, pAnn, pAlias } = this.state
    const aiWithImages = Boolean(community.ai_plan === 'aiPlan02' && community.ai_allow_pictures)

    const i18n = {
      en: {
        act: 'Activity', acts: 'Activities', par: 'Participant', pars: 'Participants'
      },
      es: {
        act: 'Actividad', acts: 'Actividades', par: 'Participante', pars: 'Participantes'
      }
    }

    let activityCount = null;
    let activitiesSubtitle = null;
    let participantsSubtitle = null;
    let indexActivities = null;
    let indexParticipants = null;

    let content = null;
    let tempUsers;

    if(focus === 'Activities'){
      activityCount = responses.length
      activitiesSubtitle = <div>{activityCount > 1 ? `${activityCount} ${i18n[community.language].acts}` :
        `${activityCount} ${i18n[community.language].act}`} </div>
      participantsSubtitle = <div>{participants.length > 1 ? `${participants.length} ${i18n[community.language].pars}` :
        `${participants.length} ${i18n[community.language].par}`} </div>

      indexActivities = responses.map((activity, index) => {
        return(
          <div key={index}>
            <div className='flex-v-center title-container no-page-break'>
              <span className='circle-indicator'>A</span>
              <span className='title'>{`${index + 1}. ${activity.title}`}</span>
            </div>
            <div  className='item-details'>
              <div>
                <span>
                  <i className="fas fa-circle-info"/>
                  {/*<FontAwesomeIcon icon={['fal', 'circle-info']} />*/}
                </span>
                {activity.dates} / {activity.social} / {activity.period}</div>
              <div>
                <span><i className="fas fa-bookmark"/></span>
                {activity.segments}
              </div>
              <div>
                { activity.tag_name && <span><i className="fas fa-tag"/></span> }
                {activity.tag_name}
              </div>
              <div>{activity.instructions.replace(/(<([^>]+)>)/ig,"")}</div>
            </div>
            <div className='tasks-container'>
              {
                activity.tasks.map((task, indexTask) => {
                  return (
                    <div key={indexTask} className='no-page-break'>
                      <div className='flex-v-center title-container no-page-break'>
                        <span className='circle-indicator'>T</span>
                        <span className='title'>{`${index + 1}.${indexTask + 1} ${task.type}`}</span>
                        <span className='item-details'>{ task.mandatory ? 'mandatory / ' : '' }
                          { task.media ? 'media / ' : '' } { task.maps ? `${task.maps}` : '' }</span>
                      </div>
                      <div className="instructions item-details">
                        {task.title.replace(/(<([^>]+)>)/ig,"")}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      })

      indexParticipants = participants.map((participant, index) => {
        const fetchedUsernames = this.fetchUsernames(pAlias, participant)
        let partUsername = `${index + 1}. ${fetchedUsernames.mainName} ${fetchedUsernames.alternativeNames ? 
          ` (a.k.a: ${fetchedUsernames.alternativeNames})` : ''}`;

        if(pAnn){
          const userAnn = users_anonymous.find(u => u.user_id === participant.id);
          partUsername = userAnn.anonymous;
        }

        return(
          <div key={index}>
            <div className='flex-v-center title-container'>
              <span className='circle-indicator'>P</span>
              <span className='title'>
                {partUsername}
              </span>
            </div>
            <div className="item-details">
              <div>
                {/*{`id: ${participant.id} ${pEmail ? '/ ' : ''}`}*/}
                { pEmail ? <span className='email'>{participant.email}</span> : '' }
              </div>
              <div>{participant.country ? `${participant.country} /` : ''}
                {participant.segments ? participant.segments.join(' / ') : ''}</div>
            </div>
          </div>
        )
      });

      content = <TPActivities comLanguage={community.language}
                              responses={responses}
                              pEmail={pEmail}
                              pAlias={pAlias}
                              pAnn={pAnn}
                              users_anonymous={users_anonymous}
                              export_to={export_to}
                              fetchUsernames={this.fetchUsernames}
                              aiWithImages={aiWithImages}
      />
    } else {
      participantsSubtitle = <div>{responses.length > 1 ? `${responses.length} PARTICIPANTS` :
        `${responses.length} PARTICIPANT`} </div>;

      let activities = [];
      responses.forEach(user => {
        user.activities.forEach(activity => {
          activities.push(activity);
        });
      });
      activities =  activities.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos;
      });
      activitiesSubtitle = <div>{activities.length > 1 ?
        `${activities.length} ACTIVITIES` : `${activities.length} ACTIVITY` }</div>;
      indexParticipants = responses.map((participant, index) => {
        // console.log(JSON.stringify(participant));
        const fetchedUsernames = this.fetchUsernames(pAlias, participant)
        let partUsername = `${index + 1}. ${fetchedUsernames.mainName} ${fetchedUsernames.alternativeNames ?
          ` (a.k.a: ${fetchedUsernames.alternativeNames})` : ''}`;

        if(pAnn){
          const userAnn = users_anonymous.find(u => u.user_id === participant.id);
          partUsername = userAnn.anonymous;
        }

        return(
          <div key={index}>
            <div className='flex-v-center title-container'>
              <span className='circle-indicator'>P</span>
              <span className='title'>
                {partUsername}
              </span>
            </div>
            <div className="item-details">
              <div>
                { pEmail ? <span className='email'>{participant.email}</span> : '' }
              </div>
              <div>{participant.country ? `${participant.country} /` : ''}
                {participant.segments ? participant.segments.join(' / ') : ''}</div>
            </div>
          </div>
        )
      });

      indexActivities = activities.map((activity, index) => {
        return(
          <div key={index}>
            <div className='flex-v-center title-container no-page-break'>
              <span className='circle-indicator'>A</span>
              <span className='title'>{`${index + 1}. ${activity.title}`}</span>
            </div>
            <div  className='item-details'>
              <div>
                <span>
                  <i className="fas fa-circle-info"/>
                  {/*<FontAwesomeIcon icon={['fal', 'circle-info']} />*/}
                </span>
                {activity.dates} / {activity.social} / {activity.period}</div>
              <div>
                <span>
                  <i className="fas fa-tags"/>
                  {/*<FontAwesomeIcon icon={['fal', 'tags']} />*/}
                </span>{activity.segments}
              </div>
              <div>{activity.tag_name ?
                <span>
                  <i className="fas fa-tags"/>
                  {/*<FontAwesomeIcon icon={['fal', 'tag']} />*/}
                </span> : ''}{activity.tag_name}</div>
              <div>{activity.instructions.replace(/(<([^>]+)>)/ig,"")}</div>
            </div>
            <div className='tasks-container'>
              {
                activity.tasks.map((task, indexTask) => {
                  return (
                    <div key={indexTask} className='no-page-break'>
                      <div className='flex-v-center title-container no-page-break'>
                        <span className='circle-indicator'>T</span>
                        <span className='title'>{`${index + 1}.${indexTask + 1} ${task.type}`}</span>
                        <span className='item-details'>{ task.mandatory ? 'mandatory / ' : '' }
                          { task.media ? 'media / ' : '' } { task.maps ? `${task.maps}` : '' }</span>
                      </div>
                      <div className="instructions item-details">
                        {task.title.replace(/(<([^>]+)>)/ig,"")}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      });

      content = <TPParticipants comLanguage={community.language}
                                responses={responses}
                                pEmail={pEmail}
                                pAlias={pAlias}
                                pAnn={pAnn}
                                users_anonymous={users_anonymous}
                                fetchUsernames={this.fetchUsernames}
                                aiWithImages={aiWithImages}
      />
    }

    return (
      <div>
        <div className='index container page-break'>
          <div className="row">
            {
              focus === 'Activities' ?
                <div>
                  <div className="col-xs-12 title-bar primary-subtle center strong no-page-break">
                    { activitiesSubtitle }
                  </div>
                  <div className="col-xs-12">
                    { indexActivities }
                  </div>
                  <div className="col-xs-12 title-bar primary-subtle center strong no-page-break">
                    { participantsSubtitle }
                  </div>
                  <div className="col-xs-12">
                    { indexParticipants }
                  </div>
                </div> :
                <div>
                  <div className="col-xs-12 title-bar primary-subtle center strong no-page-break">
                    { participantsSubtitle }
                  </div>
                  <div className="col-xs-12">
                    { indexParticipants }
                  </div>
                  <div className="col-xs-12 title-bar primary-subtle center strong no-page-break">
                    { activitiesSubtitle }
                  </div>
                  <div className="col-xs-12">
                    { indexActivities }
                  </div>
                </div>
            }
          </div>
        </div>
        { content }
      </div>
    );
  }

  fetchUsernames (includeAlias, user) {
    const usernamesToInclude = includeAlias ? 'all' : ["natural", "username"]
    const usernamePreference = includeAlias ? 'alias' : 'natural_username'
    const availableUsernames = usernamesToDisplay(user, usernamePreference, usernamesToInclude);
    return { mainName: availableUsernames[0],
      alternativeNames: availableUsernames.length > 1 ? availableUsernames.slice(1).join(', ') : ''
    }
  }

  _initRandom(responses){
    return responses.map(r => {
      const activities = r.activities.map(activity => {
        const tasks = activity.tasks.map(task => {
          let comments =  [];

          if(task.response.comments !== undefined){
            comments = task.response.comments.map(comment => {
              //console.log(JSON.stringify(comment.replies))
              const replies = comment.replies.map(reply => {
                return {
                  ...reply, username: 'Anonymous-' + Math.random().toString(20).substr(2, 6),
                }
              });
              return {
                ...comment,
                username: 'Anonymous-' + Math.random().toString(20).substr(2, 6),
                replies
              }
            });
          }

          return {
            ...task,
            response: {...task.response, comments}
          };
        });

        return {...activity, tasks}
      });

      return {
        ...r,
        username: 'Anonymous-' + Math.random().toString(20).substr(2, 6),
        activities
      };
    })
  }
}
