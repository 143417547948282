import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import BackdropTransparent from "../../../UI/BackdropTransparent";

export default class TFAdvanced extends React.Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    objSearch: PropTypes.object.isRequired,
    advancedActions: PropTypes.func.isRequired,
    chkUsers: PropTypes.bool.isRequired,
    chkSegs: PropTypes.bool.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    segments: PropTypes.array.isRequired,
    genderAsSegment: PropTypes.bool.isRequired,
    consolidated: PropTypes.string.isRequired,
    updateConsolidated: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    showAllVerbatim: PropTypes.bool.isRequired,
    updateShowAllVerbatim: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    updateResponseStates: PropTypes.func.isRequired,
    showModalExportHandler: PropTypes.func.isRequired,
    isBlocking: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showBackdropTrans: false
    }
  }

  render() {
    const {users, objSearch, chkUsers, chkSegs, segments, consolidated, language, isBlocking, colors} = this.props;

    const I18n = {
      en: {
        filters: 'Filters', consData: 'Consolidate data', consImages: 'Group images',
        consVideo: 'Group videos', verbatim: 'Show verbatims', comingSoon: 'Coming soon!', shareNexport:'Share or export',
        accepted: 'Accepted', completed: 'Completed', drafted: 'Drafted', blank: 'Blank'
      },
      es: {
        filters: 'Filtros', consData: 'Consolidar datos', consImages: 'Agrupar imágenes',
        consVideo: 'Agrupar videos', verbatim: 'Mostrar verbatims', comingSoon: 'Disponible pronto!',
        shareNexport: 'Compartir o Exportar', accepted: 'Aceptadas', completed: 'Completadas', drafted: 'Borradores',
        blank: 'En blanco'
      }
    }

    const cSegments = segments.map(segment => {
      const isActive = objSearch.idSegs.find(id => id === segment.id) !== undefined;

      let icon = null;
      switch(segment.name){
        case 'Female':
          icon = ['fal', 'venus'];break;
        case 'Male':
          icon = ['fal', 'mars'];break;
        case 'Other Gender':
          icon = ['fal', 'transgender-alt'];break;
        default:
          icon = ['fas', 'bookmark'];
      }

      return(
        <FontAwesomeIcon key={segment.id} onClick={() => this.props.advancedActions(segment.id, 'idSegs')}
                         icon={icon} className="tag icon"
                         style={{color: segment.color, cursor: 'pointer', opacity: isActive ? '1' : '.2'}}
                         data-tooltip-content={segment.name}/>
      )
    });

    const sumObjSearch = objSearch.idActs.length + objSearch.idPars.length + objSearch.idSegs.length +
      objSearch.idTags.length + objSearch.idTasks.length;
    //console.log('sumObjSearch:', sumObjSearch);
    return (
      <div className="px-filters-transc-container">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <BackdropTransparent show={this.state.showBackdropTrans} clicked={this.updateShowBackdropTrans}/>

        <div className="px-filter-consolidate filter-bar-enhanced">
          <div className="left-container">
            <FontAwesomeIcon onClick={this.props.updateShowModal} icon={['fas', 'filter']} id="card-filter-icon"
                             className={sumObjSearch === 0 ? 'icon-left' : "active icon-left"}
                             data-tooltip-content={I18n[language].filters}
                             style={{ color: sumObjSearch === 0 ? colors.color0[5] : colors.color0[3]   }}
                             />
            <div className='response-status-fiter-container'>
              <div className='response-status-fiter' onClick={()=> this.toggleStatusMenu()}>
                <div className={`portion ${objSearch.resStates.accepted && 'active'}`} id="accepted-portion" 
                  style={{ backgroundColor: objSearch.resStates.accepted ? colors.colorStates[0] : colors.colorStates[2]}}/>
                <div className={`portion ${objSearch.resStates.completed && 'active'}`} id="completed-portion" 
                  style={{ backgroundColor: objSearch.resStates.completed ? colors.colorStates[1] : colors.colorStates[4] }}/>
                <div className={`portion ${objSearch.resStates.draft && 'active'}`} id="drafted-portion"/>
                <div className={`portion ${objSearch.resStates.empty && 'active'}`} id="blank-portion"/>
              </div>

                  <ul id="response-status-dropdown-menu" role="menu" aria-labelledby="dropdownMenu"
                      style={{display: this.state.showBackdropTrans ? 'block' :'none', zIndex: '1022'}}>
                    {
                      isBlocking ?
                        <li style={{width: '110px', textAlign: 'center', minHeight: '50px', marginTop: '35px', cursor:'default'}}>
                          <FontAwesomeIcon icon={['fas', 'spinner']} size='lg' spin />
                        </li>
                         :
                        <React.Fragment>
                          <li onClick={isBlocking ? null : () => this.props.updateResponseStates('accepted')}
                              className={`accepted ${objSearch.resStates.accepted && 'active'}`}>
                            <FontAwesomeIcon icon={['fas', 'circle']} style={{ color: objSearch.resStates.accepted ?
                                colors.colorStates[0] : colors.colorStates[2]}}/>
                            <span>{I18n[language].accepted}</span>
                          </li>
                          <li onClick={isBlocking ? null : () => this.props.updateResponseStates('completed')}
                              className={`completed ${objSearch.resStates.completed && 'active'}`}>
                              <FontAwesomeIcon icon={['fas', 'circle']} style={{ color: objSearch.resStates.completed ?
                                  colors.colorStates[1] : colors.colorStates[4] }}/>
                            <span>{I18n[language].completed}</span>
                          </li>
                          <li onClick={isBlocking ? null : () => this.props.updateResponseStates('draft')}
                              className={`drafted ${objSearch.resStates.draft && 'active'}`}>
                            <FontAwesomeIcon icon={['fas', 'circle']} />
                            <span>{I18n[language].drafted}</span>
                          </li>
                          <li onClick={isBlocking ? null : () => this.props.updateResponseStates('empty')}
                              className={`blank ${objSearch.resStates.empty && 'active'}`}>
                            <FontAwesomeIcon icon={['fas', 'circle']} />
                            <span>{I18n[language].blank}</span>
                          </li>
                        </React.Fragment>
                    }
                  </ul>
            </div>
            {/*<FontAwesomeIcon icon={['fas', 'magic']} className="mute-icon"  />*/}
            <FontAwesomeIcon icon={['fas', 'caret-right']} className="separation-icon" style={{fontSize: '40px'}} />
            <FontAwesomeIcon onClick={() => this.props.updateConsolidated('general')} icon={['fas', 'layer-group']}
              className={consolidated === 'general' ? "active icon-right" : "icon-right"} data-tooltip-content={I18n[language].consData}
              style={{ color: consolidated === 'general' ? colors.color2[3] : colors.color2[5]}}/>
            <FontAwesomeIcon onClick={() => this.props.updateConsolidated('images')} icon={['fas', 'images']}
              className={consolidated === 'images' ? "active icon-right" : "icon-right"} data-tooltip-content={I18n[language].consImages}
              style={{ color: consolidated === 'images' ? colors.color2[3] : colors.color2[5] }}/>
            <FontAwesomeIcon onClick={() => this.props.updateConsolidated('videos')} icon={['fas', 'film']}
              className={consolidated === 'videos' ? "active icon-right" : "icon-right"} data-tooltip-content={I18n[language].consVideo}
              style={{ color: consolidated === 'videos' ? colors.color2[3] : colors.color2[5] }}/>
            <FontAwesomeIcon onClick={() => this.props.updateShowAllVerbatim()} icon={['fas', 'quote-right']}
              className={consolidated === '' ?
                this.props.showAllVerbatim ? "active icon-right" : "icon-right" : 'icon-right'} data-tooltip-content={I18n[language].verbatim}
              style={{ color: consolidated === '' && this.props.showAllVerbatim ? colors.color2[3] : colors.color2[5] }}/>
            <FontAwesomeIcon onClick={() => this.props.showModalExportHandler(objSearch.idActs, objSearch.idTasks, objSearch.idPars,
              objSearch.idSegs, objSearch.idTags)}
              icon={['fas', 'share']} className={"icon-right"} data-tooltip-content={I18n[language].shareNexport} 
              style={{ color: colors.color2[5] }}/>
          </div>


          {/* <div className="right-container">
            <div className="separator"/>
          </div> */}

        </div>
      </div>
    );
  }

  updateShowBackdropTrans = () => {
    console.log('updateShowBackdropTrans');
    this.setState(prevState => ({showBackdropTrans: !prevState.showBackdropTrans}));
  };

  toggleStatusMenu = () => {
    this.updateShowBackdropTrans();
    //$('#response-status-dropdown-menu').toggle();
  }

}

function SampleNextArrowUsers(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', borderRadius:'50%', right:'-20px'}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrowUsers(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', zIndex:'1' , borderRadius:'50%', left:'0'}}
      onClick={onClick}
    />
  );
}

const sliderUsersTasks = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  variableWidth: true,
  nextArrow: <SampleNextArrowUsers />,
  prevArrow: <SamplePrevArrowUsers />,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 812,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    }
  ]
};
