import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import { Tooltip as ReactTooltip } from "react-tooltip"
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const classNames = require('classnames');
const _ = require('underscore');

export default class InviteeRow extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    communityId: PropTypes.number.isRequired,
    updateCommunityUsers: PropTypes.func.isRequired,
    segments: PropTypes.array.isRequired,
    role2: PropTypes.string.isRequired,    //Current user in session role2 (Community role)
    updatePM: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      action: '',
      button: 'Save',
      confirmEmail: '',
      showModal: false,
      question: '',
      email: this.props.user.email,
      first_name: this.props.user.first_name,
      last_name: this.props.user.last_name,
      gender: this.props.user.gender,
      dob: this.props.user.dob,
      country: this.props.user.country,
      state_country: this.props.user.state_country,
      role_2: this.props.user.role_2,
      deleteFlag: false,
      userSegments: this.props.user.segments,
      userState: this.props.user.userState
    };
  }

  componentWillMount() {
    ReactModal.setAppElement('body');
  }

  componentWillReceiveProps = (nextProps) => {
    // REMEMBER, we dont have the same numbers of states for this one (16) and nextProps.user
    if(this.props.user !== nextProps.user){
      this.setState({...nextProps.user}, function () {
        //Try to put in the same setState,
        //Remember 'segments' doesn't have the same name as 'userSegments'
        //but they are the same
        this.setState({userSegments: nextProps.user.segments})
      });
    }
  };

  handleOpenModal = (action) => {
    this.setState({ showModal: true });

    switch(action){
      case 'delete':
        this.setState({
          question: <p>Are you sure to <b>Delete</b> this user: <b>{this.props.user.username}</b> ?</p>,
          action: action,
          button: 'Ok'
        });
        break;
      case 'send':
        this.setState({
          question: <p>Are you sure to <b>Send the invitation</b> for this user: <b>{this.props.user.username}</b> ?</p>,
          action: action,
          button: 'Send'
        });
        break;
      case 'reSend':
        this.setState({
          question: <p>Are you sure to <b>Resend the invitation</b> for this user: <b>{this.props.user.username}</b> ?</p>,
          action: action,
          button: 'Send'
        });
        break;
      case 'block':
        this.setState({
          question: <p>Are you sure to <b>Block</b> this user: <b>{this.props.user.username}</b> ?</p>,
          action: action,
          button: 'Save'
        });
        break;
      case 'unblock':
        this.setState({
          question: <p>Are you sure to <b>Unblock</b> this user: <b>{this.props.user.username}</b> ?</p>,
          action: action,
          button: 'Save'
        });
        break;
      case 'edit':
        this.setState({
          action: action,
          button: 'Save'
        });
        break;
      case 'remove':
        this.setState({
          action: action,
          button: 'Ok'
        });
        break;
    }
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleEditInvitation = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //console.log(name, value);

    this.setState({[name]: value});
  };

  selectCountry (val) {
    this.setState({country: val});
  }

  selectRegion (val) {
    this.setState({state_country: val});
  }

  segmentChange = (val) => {
    this.setState({userSegments: val});
  };

  render() {
    let avatar = '/assets/user.png';
    if(this.props.user.avatar !== ''){
      avatar = this.props.user.avatar;
    }
    const mailTo = 'mailto:' + this.props.user.email;
    const btnFollow = this.props.user.is_follow ? 'btn btn-unfollow' : 'btn btn-follow';
    const btnFollowMsg = this.props.user.is_follow ? 'unfollow' : 'follow';

    const follow = this.props.user.user_id !== this.props.user.current_user_id ?
      <li><button onClick={this.setFollow} className={btnFollow}>
      {btnFollowMsg}<div className="ripple-container"/></button></li> : '';
    const email = <li><a href={mailTo}  data-tooltip-content="Send email">
                  <FontAwesomeIcon icon={['fas','envelope']} /></a></li>;
    const send = <li><a onClick={() => this.handleOpenModal('send')} data-tooltip-content="Send Invitation"
                        ><FontAwesomeIcon icon={['fas','share']} /></a></li>;
    const reSend = <li><a onClick={() => this.handleOpenModal('reSend')} data-tooltip-content="Resend Invitation">
                    <FontAwesomeIcon icon={['fas','repeat']} /></a></li>;
    const edit = <li><a onClick={() => this.handleOpenModal('edit')} data-tooltip-content="Edit">
                    <FontAwesomeIcon icon={['fas','pencil']} /></a></li>;
    const deleted = <li><a onClick={() => this.handleOpenModal('delete')} data-tooltip-content="Delete"
                          ><FontAwesomeIcon icon={['fas','trash']} /></a></li>;
    const privateMessage = this.props.user.user_id !== this.props.user.current_user_id ?
      <li><a onClick={() => this.getPM()}  data-tooltip-content="Private Message">
          <FontAwesomeIcon icon={['fas','comments']} /></a></li> : '';
    const block = <li><a onClick={() => this.handleOpenModal('block')} data-tooltip-content="Block"
                        ><FontAwesomeIcon icon={['fas','lock']} /></a></li>;
    const unblock = <li><a onClick={() => this.handleOpenModal('unblock')} data-tooltip-content="Unblock">
                        <FontAwesomeIcon icon={['fas','unlock']} /></a></li>;
    const remove = <li><a onClick={() => this.handleOpenModal('remove')} data-tooltip-content="Remove"
                          ><FontAwesomeIcon icon={['fas', 'xmark']}/></a></li>;

    // LOGIC TO SHOW THE EDIT BUTTONS BASED ON ROLES AND STATES
    let inviteeAction = '';

    //this.props.role2 is the role_2 for the current_user
    if(this.state.role_2 === this.props.role2){
      inviteeAction = <ul className="nav navbar-nav navbar-right">{ follow } { privateMessage }</ul>;
    } else {
      switch(this.props.role2){
        case 'Owner':
          switch(this.props.user.state){
            case 'Draft':
              inviteeAction = <ul className="nav navbar-nav navbar-right">{ send } { edit } { deleted }</ul>;
              break;
            case 'Invited':
              inviteeAction = <ul className="nav navbar-nav navbar-right">{ email } { reSend } { edit } { deleted }</ul>;
              break;
            case 'Enabled':
              inviteeAction = <ul className="nav navbar-nav navbar-right">{ follow }{ privateMessage } { edit }
              { block }{ remove }</ul>;
              break;
            case 'Blocked':
              inviteeAction = <ul className="nav navbar-nav navbar-right">{ privateMessage } { edit } { unblock } { remove }</ul>;
              break;
            case 'Declined':
              inviteeAction = <ul className="nav navbar-nav navbar-right">{ deleted }</ul>;
              break;
          }
          break;
        case 'Moderator':
          if(this.state.role_2 === ''){
            switch(this.props.user.state){
              case 'Draft':
                inviteeAction = <ul className="nav navbar-nav navbar-right">{ send } { edit } { deleted }</ul>;
                break;
              case 'Invited':
                inviteeAction = <ul className="nav navbar-nav navbar-right">{ email } { reSend } { edit } { deleted }</ul>;
                break;
              case 'Enabled':
                inviteeAction = <ul className="nav navbar-nav navbar-right">{ follow } { privateMessage } { edit } { block }{ remove }</ul>;
                break;
              case 'Blocked':
                inviteeAction = <ul className="nav navbar-nav navbar-right">{ privateMessage } { edit } { unblock } { remove }</ul>;
                break;
              case 'Declined':
                inviteeAction = <ul className="nav navbar-nav navbar-right">{ deleted }</ul>;
                break;
            }
          }

          if(this.state.role_2 === 'Observer' || this.state.role_2 === 'Recruiter'){
            switch(this.props.user.state){
              case 'Draft':
                inviteeAction = <ul className="nav navbar-nav navbar-right">{ send } { edit } { deleted }</ul>;
                break;
              case 'Invited':
                inviteeAction = <ul className="nav navbar-nav navbar-right">{ email } { reSend } { edit } { deleted }</ul>;
                break;
              case 'Enabled':
                inviteeAction = <ul className="nav navbar-nav navbar-right">{ follow } { privateMessage } { edit } { block }{ remove }</ul>;
                break;
              case 'Blocked':
                inviteeAction = <ul className="nav navbar-nav navbar-right">{ privateMessage } { edit } { unblock } { remove }</ul>;
                break;
              case 'Declined':
                inviteeAction = <ul className="nav navbar-nav navbar-right">{ deleted }</ul>;
                break;
            }
          } else if(this.state.role_2 === 'Owner'){
            inviteeAction = <ul className="nav navbar-nav navbar-right">{ privateMessage }</ul>;
          }
          break;
        case 'Observer':
          if(this.state.role_2 !== ''){
            inviteeAction = <ul className="nav navbar-nav navbar-right">{ privateMessage }</ul>;
          }
          break;
        case 'Recruiter':
          switch(this.props.user.state){
            case 'Draft':
              inviteeAction = <ul className="nav navbar-nav navbar-right">{ send } { edit } { deleted }</ul>;
              break;
            case 'Invited':
              inviteeAction = <ul className="nav navbar-nav navbar-right">{ email } { reSend } { edit } { deleted }</ul>;
              break;
            case 'Enabled':
              inviteeAction = <ul className="nav navbar-nav navbar-right">{ privateMessage } { edit } { block }{ remove }</ul>;
              break;
            case 'Blocked':
              inviteeAction = <ul className="nav navbar-nav navbar-right">{ privateMessage } { edit } { unblock } { remove }</ul>;
              break;
            case 'Declined':
              inviteeAction = <ul className="nav navbar-nav navbar-right">{ deleted }</ul>;
              break;
          }
          break;
      }
    }

    const modalStyle = {
      overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(0, 0, 0, 0.2)',
        zIndex            : 1050,
      },
      content : {
        position                   : 'absolute',
        top                        : '0',
        left                       : '40px',
        right                      : '0',
        bottom                     : '0',
        border                     : '0',
        background                 : 'rgba(0,0,0,0)',
        overflow                   : 'auto',
        WebkitOverflowScrolling    : 'touch',
        borderRadius               : '4px',
        outline                    : 'none',
        padding                    : '20px'
      }
    };

    let userState = this.props.user.state;
    if(userState === 'Enabled')
      userState = 'Active';

    const origSegments = this.props.user.segments;
    let segments = '';
    // Test if it's necessary, remember in controller we have a double feeder in show and the AJAX
    // for get community users, improve it
    if(typeof origSegments !== 'undefined'){
      segments = origSegments.map((segment, index) => {
        //console.log(segment);
        return <div key={index} data-tooltip-content={segment.label}
                    style={{background: segment.color, width: '20px', height: '20px', borderRadius: '50%', display: 'inline-block'}}/>
      });
    }

    let location = '';
    if(this.props.user.country !== ''){
      if(this.props.user.state_country){
        location = this.props.user.state_country + ', ' + this.props.user.country;
      } else {
        location = this.props.user.country;
      }
    }

    // MODAL UI
    let modalTitle = <h4 className="modal-title">Confirmation</h4>;
    let modalBody = <div className="modal-body">{this.state.question}</div>;

    let countryList = <CountryDropdown value={this.state.country}
                                       onChange={(val) => this.selectCountry(val)}
                                       classes="form-control"/>;
    let regionList = <RegionDropdown country={this.state.country}
                                     value={this.state.state_country}
                                     onChange={(val) => this.selectRegion(val)}
                                     classes="form-control"/>;
    if(this.props.user.country && this.props.user.userState !== 'Invited'){
      countryList = <input value={this.props.user.country} className="form-control" disabled={true}/>;
    }
    if(this.props.user.state_country && this.props.user.userState !== 'Invited'){
      regionList = <input value={this.props.user.state_country} className="form-control" disabled={true}/>;
    }

    let dateOfBirth = <InputMask name="dob" mask="99/99/9999" maskChar=" " onChange={this.handleEditInvitation}
                                 className="form-control" placeholder="dd/mm/yyyy"/>;
    //console.log('props.user.dob:', this.props.user.dob, this.state.dob);
    if(this.props.user.userState !== 'Invited'){
      // To improve!
      if(this.props.user.dob){
        const dob = this.state.dob;
        if(dob != null){
          const dobA = dob.split('-');
          dateOfBirth = <input defaultValue={dobA[2] + '/' + dobA[1] + '/' + dobA[0]} className="form-control"
                               disabled={true}/>
        }
      }
    } else {
      if(this.state.dob){
        const dob = this.state.dob;
        const dobA = dob.split('-');
        dateOfBirth = <InputMask name="dob" mask="99/99/9999" maskChar=" " onChange={this.handleEditInvitation}
                                 className="form-control" placeholder="dd/mm/yyyy"
                                 value={dobA[2] + '/' + dobA[1] + '/' + dobA[0]}/>;
      } else {
        dateOfBirth = <InputMask name="dob" mask="99/99/9999" maskChar=" " onChange={this.handleEditInvitation}
                                 className="form-control" placeholder="dd/mm/yyyy" value=""/>;
      }

    }

    let role2Edit;
    let colSize = 'col-md-12';
    let segmentsEdit;
    if(this.state.role_2){
      switch(this.props.role2){
        case 'Owner':
          role2Edit = <div className="col-md-6">
            <div className="form-group" style={{margin: '0'}}>
              <label className="control-label" style={{fontSize:'14px'}}>Role</label>
              <select name="role_2" onChange={this.handleEditInvitation} value={this.state.role_2} className="form-control">
                <option value="Moderator">Moderator</option>
                <option value="Observer">Observer</option>
                <option value="Recruiter">Recruiter</option>
              </select>
            </div>
          </div>;
          break;
        case 'Moderator':
          role2Edit = <div className="col-md-6">
            <div className="form-group" style={{margin: '0'}}>
              <label className="control-label" style={{fontSize:'14px'}}>Role</label>
              <select name="role_2" onChange={this.handleEditInvitation} value={this.state.role_2} className="form-control">
                <option value="Observer">Observer</option>
                <option value="Recruiter">Recruiter</option>
              </select>
            </div>
          </div>;
          break;
      }

      colSize = 'col-md-6';
    } else {
      segmentsEdit =<div className="col-md-12">
        <div className="form-group" style={{margin: '0'}}>
          <label className="control-label" style={{fontSize:'14px'}}>Segments</label>
          <Select isMulti={true}
                  value={this.state.userSegments}
                  options={this.props.segments}
                  onChange={this.segmentChange}
                  className="form-control"/>
        </div>
      </div>;
    }

    if(this.state.action === 'edit'){
      modalTitle = <h4 className="modal-title">Edit Invitation</h4>;
      modalBody = <div className="modal-body">
        <div className={colSize}>
          <div className="form-group" style={{margin: '0'}}>
            <label className="control-label" style={{fontSize:'14px'}}>Email</label>
            <input name="email" type="text" defaultValue={this.props.user.email} className="form-control" disabled={true}/>
          </div>
        </div>

        { role2Edit }

        <div className="col-md-6">
          <div className="form-group" style={{margin: '0'}}>
            <label className="control-label" style={{fontSize:'14px'}}>First Name</label>
            <input name="first_name" type="text" onChange={this.handleEditInvitation} value={this.state.first_name}
                   className="form-control" disabled={this._validateDisabled(this.props.user.first_name)}/>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group" style={{margin: '0'}}>
            <label className="control-label" style={{fontSize:'14px'}}>Last Name</label>
            <input name="last_name" type="text" onChange={this.handleEditInvitation} value={this.state.last_name}
                   className="form-control" disabled={this._validateDisabled(this.props.user.last_name)}/>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group" style={{margin: '0'}}>
            <label className="control-label" style={{fontSize:'14px'}}>Gender</label>
            <select name="gender" onChange={this.handleEditInvitation} value={this.state.gender}
                    className="form-control" disabled={this._validateDisabled(this.props.user.gender)}>
              <option value="">Select Gender</option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group" style={{margin: '0'}}>
            <label className="control-label" style={{fontSize:'14px'}}>Date of Birth</label>
            { dateOfBirth }
          </div>
        </div>

        { segmentsEdit }

        <div className="col-md-6">
          <div className="form-group" style={{margin: '0'}}>
            <label className="control-label" style={{fontSize:'14px'}}>Country</label>
            { countryList }
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group" style={{margin: '0'}}>
            <label className="control-label" style={{fontSize:'14px'}}>Region</label>
            { regionList }
          </div>
        </div>

      </div>;
    }

    const valDelete = classNames({'form-group has-error': this.state.deleteFlag, 'form-group': !this.state.deleteFlag});
    if(this.state.action === 'remove'){
      modalTitle = <h4 className="modal-title">Remove Invitee</h4>;
      modalBody = <div className="modal-body">
        <p>To confirm, please type the invitee's email (<b>{this.props.user.email}</b>).</p>
        <div className={valDelete} style={{margin: '0'}}>
          <input name="confirmEmail" type="text" onChange={this.handleEditInvitation} className="form-control"/>
          <span className="material-icons form-control-feedback">clear</span>
        </div>
      </div>
    }

    return(
      <div className="user-card">
        <div className="row" style={{width:'100%'}}>
          <div className="col-xs-12 col-sm-12 col-md-1 col-lg-1" style={{marginRight:'0 !important'}}>
            <div className="image-container">
              <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
              <img src={avatar} alt=""/>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3" style={{borderRight:'1px solid #F7F7F7'}}>
            <div className="contact-info-container">
              <span className="username">{this.props.user.username}</span>
              <div className="info-container">
                <div className="role-container Owner">
                    <FontAwesomeIcon icon={['fas','crown']} />
                    <span>{this.props.user.role_2}</span>
                </div>
                <div className="role-container Recruiter" style={{display:'none'}}>
                  <FontAwesomeIcon icon={['fas','handshake']} />
                  <span>{this.props.user.role_2}</span>
                </div>
                <div className="role-container Observer" style={{display:'none'}}>
                  <FontAwesomeIcon icon={['fas','eye']} />
                  <span>{this.props.user.role_2}</span>
                </div>
                <div className="role-container Moderator" style={{display:'none'}}>
                  <FontAwesomeIcon icon={['fas','gavel']} />
                  <span>{this.props.user.role_2}</span>
                </div>
                <div className="tags-container">
                  <FontAwesomeIcon icon={['fas','tag']} className="tag tag-blue" />
                  <FontAwesomeIcon icon={['fas','tag']} className="tag tag-pink" />
                  <FontAwesomeIcon icon={['fas','tag']} className="tag tag-green" />
                </div>
                <div className="genders-container">
                  <div className="gender-container">
                    <FontAwesomeIcon icon={['far','venus']} />
                  </div>
                  <div className="gender-container">
                    <FontAwesomeIcon icon={['far','mars']} />
                  </div>
                </div>
                <div className="country-container">
                  <span>{ location }</span>
                </div>
              </div>

            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="status-general-container">
                  <div className="status-container">
                    <div className={userState === 'Active' ? 'btn status-item active' : 'btn status-item'}>
                      <span>{ userState }</span>
                    </div>
                    <div className="logged-info">
                      Logged 20 hours ago
                    </div>
                  </div>
                  <div className="email-container">
                    <a href={mailTo}>{this.props.user.email}</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div className="actions-container">
              { inviteeAction }
            </div>
          </div>
        </div>
        <ReactModal  isOpen={this.state.showModal}  contentLabel="Actions Invitee"  shouldCloseOnOverlayClick={true}  style={modalStyle}> 
          <div className="modal-dialog"> 
            <div className="modal-content"> 
              <div className="modal-header"> 
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <i onClick={this.handleCloseModal} className="material-icons" style={{color: '#333'}}>clear</i> 
                  asdfasdfklmasdflkasnmdflkasdfnlsfkdn
                </button> 

              </div> 

              <div className="modal-footer">
                <button onClick={this.actionUser} type="button" className="btn btn-success btn-simple"  disabled={false}>{this.state.button}</button> 
                <button onClick={this.handleCloseModal} type="button" className="btn btn-danger btn-simple">Cancel</button> 
              </div> 
            </div> 
          </div> 
        </ReactModal>
      </div>
    )
  }

  setFollow = () => {
    $.ajax({
      url: '/follow/update/'+ this.props.user.user_id +'/' + this.props.communityId,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        role_1: this.props.user.role_1
      },
      success: function (users) {
        this.props.updateCommunityUsers(users);
        $('#snackbar').addClass('show');
        setTimeout(function(){ $('#snackbar').removeClass('show'); }, 3000);
      }.bind(this)
    });
  };

  getPM = () => {
    this.props.updatePM(this.props.user.user_id);
  };

  actionUser = () => {
    //console.log('Hey', this.state.action);
    switch(this.state.action){
      case 'delete':
        this._updateStateInDB();
        break;
      case 'block':
        this._updateStateInDB();
        break;
      case 'unblock':
        this._updateStateInDB();
        break;
      case 'send':
        this._sendReSendInvitation('send');
        break;
      case 'reSend':
        this._sendReSendInvitation('reSend');
        break;
      case 'edit':
        //console.log('To exec ajax', this.state);
        $.ajax({
          url: '/communities_user/update_invitation/',
          method: 'PATCH',
          dataType: 'JSON',
          data: {
            userId: this.props.user.user_id,
            communityId: this.props.communityId,
            user: {
              first_name: this.state.first_name,
              last_name: this.state.last_name,
              gender: this.state.gender,
              dob: this.state.dob,
              userSegments: this.state.userSegments,
              role_2: this.state.role_2,
              country: this.state.country,
              state_country: this.state.state_country,
            }
          },
          success: function (users) {
            //console.log(users);
            this.props.updateCommunityUsers(users);
          }.bind(this)
        });
        this.handleCloseModal();
        break;
      case 'remove':
        if(this.props.user.email === this.state.confirmEmail) {
          this._updateStateInDB();
        }
        break;
    }
  };

  _sendReSendInvitation = (action) => {
    $.ajax({
      url: '/communities_user/resend_invitation/',
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        userId: this.props.user.user_id,
        communityId: this.props.communityId,
        state: this.props.user.state,
        role_1: this.props.user.role_1
      },
      success: function (users) {
        if(action === 'send'){
          this.props.updateCommunityUsers(users);
        }
        $('#snackbar').addClass('show');
        setTimeout(function(){ $('#snackbar').removeClass('show'); }, 3000);
      }.bind(this)
    });
    this.handleCloseModal();
  };

  _updateStateInDB = () => {
    // Why I can't use 'action' as a parameter inside AJAX, good question :S
    $.ajax({
      url: '/communities_user/update_state/',
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        communityId: this.props.communityId,
        cuId: this.props.user.cu_id,
        myAction: this.state.action,
        role_1: this.props.user.role_1
      },
      success: function (users) {
        //console.log(users);
        this.props.updateCommunityUsers(users);
      }.bind(this)
    });
    this.handleCloseModal();
  };

  _validateDisabled(value){
    let flag = false;
    if(this.props.user.userState !== 'Invited'){
      flag = value !== '';
    }

    return flag;
  }
}