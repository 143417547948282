import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setIconGender } from "../../components/UI/Globals/PX_Funs";
import Dotdotdot from "react-dotdotdot";
import ReactModal from "react-modal";
import MRecognize from "../../components/Community/Welcome/MRecognize";

export default class Welcome extends React.Component {
  static propTypes = {
    isPreview: PropTypes.bool.isRequired,
    communityId: PropTypes.number.isRequired,
    communityTitle: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    showCollaborators: PropTypes.bool.isRequired,
    moderators: PropTypes.array.isRequired,
    socialFlag: PropTypes.bool.isRequired,
    role_1: PropTypes.string.isRequired,
    mailto: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    updateOpenPMFromOutside: PropTypes.func.isRequired,
    userAvatar: PropTypes.string.isRequired,
    userUsername: PropTypes.string.isRequired,
    profileId: PropTypes.number.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isCheck01: false,
      isCheck02: false,
      isCheck03: false,
      isBtnSave: true,
      showModal: false,
      userAvatar: props.userAvatar,
      flagUsername: false,
      flagBlob: false,
      isSaving: false
    }
  }

  render() {
    const {profileId, communityTitle, message, showCollaborators, moderators, socialFlag, mailto, language,
      userUsername, colors} = this.props;

    const I18n = {
      en: {
        disclaimerSocial1: "I understand this is a", disclaimerSocial2: "Social Community",
        disclaimerSocial3:"where other participants are able to read some of my comments and responses.",
        disclaimerGeneral1: "I’ve read and I agree to the", disclaimerGeneral2: "Terms of Use",
        disclaimerGeneral3: "Data Use Policy ", disclaimerGeneral4: "including the use of Cookies.",
        and: "and", welcomeTitle: "Welcome to", from: "From", accept: "Accept & Enter",
        haveQuestion: "I’D LIKE TO ASK SOMETHING BEFORE JOINING", iModalTitle: "This is a Social Community",
        iModalCont1: "This community is set as Social. The essence of Social Communities is that participants are encouraged to interact between each other. Therefore, their responses to activities will often be public.",
        iModalCont2: "If you decide to join beware that other participants could be notified when you respond an activity or post a comment." +
        " Also, they may read your responses and comment on them. You may do the same with other participants so keep an eye on what's happening in the community in the section 'Community NOW', in your dashboard, once you join.",
        iModalCont3: "If you would like to learn more about Social Communities visit the ",
        disclaimerAgree1: "I've read, and I agree to the conditions laid out in this welcome screen."
      },
      es: {
        disclaimerSocial1: "Entiendo que ésta es una", disclaimerSocial2: "Comunidad Social",
        disclaimerSocial3: "en la que otros participantes podrían ver algunos de mis comentarios y respuestas.",
        disclaimerGeneral1: "He leído y acepto los", disclaimerGeneral2: "Términos de Servicio",
        disclaimerGeneral3: "Política de Privacidad ", disclaimerGeneral4: "incluyendo el uso de Cookies.",
        and: "y", welcomeTitle: "Bienvenido a", from: "De parte de", accept: "Aceptar y Entrar",
        haveQuestion: "QUISIERA PREGUNTAR ALGO ANTES DE PARTICIPAR", iModalTitle: "Ésta es una Comunidad Social",
        iModalCont1: "Esta comunidad está configurada para ser Social. La esencia de las comunidades Sociales es que los participantes interactúen entre sí, por lo que sus respuestas a las actividades serán, generalmente, públicas.",
        iModalCont2: "Si aceptas participar considera que otros participantes pueden ser notificados cuando respondas una actividad o hagas un comentario. Además, podrían acceder a tus respuestas, darles ‘like’ y comentar sobre ellas." +
                 " Tú podrás hacer lo mismo, así que pon atención a lo que otros participantes hacen en la columna ‘Comunidad Ahora!’, que encontrarás en el Dashboard de la comunidad.",
        iModalCont3: "Si quieres más información sobre las comunidades Sociales, en general, dirígete al",
        disclaimerAgree1: "He leído y acepto las condiciones establecidas en esta pantalla de bienvenida."
      }
    };

    const collaboratorsList = moderators.map(collaborator => {
      if(collaborator.state === 'Enabled') {
        return (
          <div key={collaborator.cu_id}
               onClick={() => this.props.updateOpenPMFromOutside('PM', collaborator.username, collaborator.user_id)}
               className="px-moderator-item" style={{cursor:'pointer'}}>
            <div className="image-container">
              <img src={collaborator.avatar !== '' ? collaborator.avatar : '/assets/user.png'} alt=""
                   className="px-thumb-35 img-circle"/>
              <div className="badge-cont" style={{backgroundColor: colors.color2[3]}}>
                <FontAwesomeIcon icon={['fas', 'gavel']} />
              </div>
            </div>
            <span className="name">
              <Dotdotdot clamp={'auto'}>
                {collaborator.username}
              </Dotdotdot>
            </span>
          </div>
        )
      }
    });

    return (
      <div className="px-welcome-container">
        <div className="px-card px-card-welcome">
          <div className="px-icon-settings welcome" style={{backgroundColor: colors.color2[3]}}>
            <FontAwesomeIcon icon={['fal', 'face-smile']} />
          </div>
          <div className="panel-body">
            <div className="title-container text-center">
              <label className="title">{I18n[language].welcomeTitle}</label>
              <label className="subtitle" style={{color: colors.color1[3]}}>{communityTitle}</label>
            </div>
            {
              showCollaborators &&
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="label-xs">{I18n[language].from}</div>
                </div>
                <div className="px-moderators-container">
                  {collaboratorsList}
                </div>
              </div>
            }
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div dangerouslySetInnerHTML={{ __html: message }} className="body-text">
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-lg-offset-1 col-md-offset-1 col-sm-offset-1 col-xs-offset-1">
                {
                  socialFlag &&
                  <div className="px-check-cont">
                    <label className="container-check" style={{justifyContent:'left !important'}}>
                      <input name="isCheck01" onChange={this.handleInputChange} value={this.state.isCheck01} type="checkbox"
                             className="form-check-input" id="exampleCheck1" />
                      <span className="checkmark"/>
                      <span>{I18n[language].disclaimerSocial1}
                        <a href="#modal-id" style={{ color: colors.color2[3], fontWeight: 'normal' }} data-toggle="modal">
                          &nbsp;{I18n[language].disclaimerSocial2}</a> {I18n[language].disclaimerSocial3}
                      </span>
                    </label>
                  </div>
                }
                <div className="px-check-cont">
                  <label className="container-check" style={{justifyContent:'left !important'}}>
                    <input name="isCheck02" onChange={this.handleInputChange} value={this.state.isCheck02} type="checkbox"
                           className="form-check-input" id="exampleCheck2" />
                    <span className="checkmark"/>
                    <span> {I18n[language].disclaimerGeneral1}&nbsp;
                      <a href="https://www.pixiebob.app/ToS" target="_blank" style={{ color: colors.color2[3] }}>
                        {I18n[language].disclaimerGeneral2}</a> {I18n[language].and}
                      <a href="https://www.pixiebob.app/Privacy" target="_blank" style={{ color: colors.color2[3] }}>
                        &nbsp;{I18n[language].disclaimerGeneral3}</a> {I18n[language].disclaimerGeneral4}</span>
                  </label>
                </div>
                <div className="px-check-cont">
                  <label className="container-check" style={{justifyContent:'left !important'}}>
                    <input name="isCheck03" onChange={this.handleInputChange} value={this.state.isCheck03} type="checkbox"
                           className="form-check-input" id="exampleCheck3"/>
                    <span className="checkmark"/>
                    <span> {I18n[language].disclaimerAgree1}</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <button className="btn px-btn lg btn-blue-base" onClick={this.updateShowModal}
                        disabled={this.state.isBtnSave} style={{backgroundColor: colors.color1[3]}}>{I18n[language].accept}</button>
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <button onClick={() => this.props.updateOpenPMFromOutside('PM', moderators[0].username, moderators[0].user_id)}
                        className="btn px-btn sm btn-gray-darker inverted">
                  {I18n[language].haveQuestion}
                </button>
              </div>
            </div>
          </div>
        </div>
        {
          socialFlag && 
          <div className="modal fade" id="modal-id">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header modal-delete-activity">
                  <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                          data-dismiss="modal" aria-hidden="true">
                    <i className="material-icons">clear</i>
                  </button>
                  
                </div>

                <div className="modal-body px-modal-body-warning" style={{textAlign:'center'}}>
                  <div className="px-title-warning-container" style={{textAlign:'center'}}>
                    <FontAwesomeIcon icon={['fal', 'circle-info']} style={{ color: '$purple-base', fontSize: '50px'}} />
                    <p className="px-title-warning">
                      {I18n[language].iModalTitle}
                    </p>
                  </div>
                  <p>
                    {I18n[language].iModalCont1}
                  </p>
                  <p style={{ marginTop: "10px" }}>
                    {I18n[language].iModalCont2}
                  </p>
                  <p style={{ marginTop: "10px" }}>
                    {I18n[language].iModalCont3}
                      <a href='/help' target='_blank' style={{ color: 'rgb(72, 225, 205)', fontWeight: 'normal'}}> Support Center.</a>
                  </p>
                </div>

                <div className="modal-footer px-modal-footer-warning">
                  <div className="px-footer-container-buttons column">
                    <button data-dismiss="modal" type="button" className="btn px-btn md btn-blue-base">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {
          this.state.showModal &&
            <MRecognize showModal={this.state.showModal}
                        updateShowModal={this.updateShowModal}
                        enterToCommunity={this.enterToCommunity}
                        userAvatar={this.state.userAvatar}
                        userUsername={userUsername}
                        language={language}
                        profileId={profileId}
                        isPreview={this.props.isPreview}
                        isSaving={this.state.isSaving}
                        colors={colors}/>
        }
      </div>
    );
  }

  updateShowModal = () => {
    this.setState(prevState => ({showModal: !prevState.showModal}));
  };

  handleInputChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;

    this.setState({ [name]: value }, () => {
      if(this.props.socialFlag) {
        this.setState({ isBtnSave: !(this.state.isCheck01 && this.state.isCheck02 && this.state.isCheck03) });
      } else {
        this.setState({ isBtnSave: !(this.state.isCheck02 && this.state.isCheck03) });
      }
    });
  };

  enterToCommunity = (username, avatar, isLoadedAvatar) => {
    //console.log('avatar:', avatar, 'isLoadedAvatar:', isLoadedAvatar, 'this.props.isPreview:', this.props.isPreview);
    if(!this.props.isPreview){
      if(isLoadedAvatar){
        //console.log('NEW DEFAULT AVATAR CREATED BY OUR APP');
        const blobBin = atob(avatar.split(',')[1]);
        const array = [];

        for(let i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }

        const file = new Blob([new Uint8Array(array)], {type: 'image/png'});
        const formData = new FormData();
        formData.append("file", file);
        //console.log('SAVE DEFAULT BLOB AVATAR')

        this.setState({isSaving: true}, () => {
          $.ajax({
            url: '/profiles/update_avatar/' + this.props.profileId,
            method:'PATCH',
            data: formData,
            processData: false,
            contentType: false,
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            success: (obj) => {
              //console.log(obj.avatar);
              this.setState({flagBlob: true}, () => {
                //console.log('forward');
                this._setUsernameAndEnter(username);
              });
            }
          });
        });
      } else {
        //console.log('OLD AVATAR OR UPLOADED BY MODAL INTO S3');
        this.setState({flagBlob: true}, () => {
          //console.log('forward');
          this._setUsernameAndEnter(username);
        });
      }
    }
  }

  _setUsernameAndEnter = username => {
    const communityId = this.props.communityId;
    const role_1 = this.props.role_1;

    $.ajax({
      url: '/community_users/enter_community/' + communityId,
      method: 'PATCH',
      data: {username},
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: response => {
        if(response) {
          if(role_1 === 'Collaborator') {
            window.location.href = '/communities/' + communityId + '/Activities';
          } else {
            window.location.href = '/communities/participant/' + communityId;
          }
        }
      }
    });
  }

  /*forward = (role_1, communityId) => {
    if(this.state.flagUsername && this.state.flagBlob){
      if(role_1 === 'Collaborator') {
        window.location.href = '/communities/' + communityId + '/Activities';
      } else {
        window.location.href = '/communities/participant/' + communityId;
      }
    }
  };*/

  sendEmail(mailto){
    window.location = 'mailto:' + mailto + '?subject=Help from Welcome Screen'
  }
}
