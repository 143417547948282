import PropTypes from 'prop-types';
import React from 'react';
import ASCounter from "./ASCounter";

export default class ASwimlane extends React.Component {
  static propTypes = {
    community_id: PropTypes.number.isRequired,
    userIds: PropTypes.array.isRequired,
    activityId: PropTypes.number.isRequired,
    taskId: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    clearForwardUrl: PropTypes.func.isRequired,
    counter: PropTypes.number.isRequired,
    cssClass: PropTypes.string.isRequired,
    color: PropTypes.string
  }

  render() {
    const {community_id, userIds, activityId, taskId, color, total, counter, cssClass} = this.props;

    const percentage = counter * 100 / parseInt(total);
    // With percentage 40px it doesn't 'jump'
    const width = `calc(${percentage}% - ${percentage === 0 ? '10px' : '10px'})`;
    //const width = 'calc('+ percentage + '% - 10px)';
    if(activityId === 179 && taskId === 460){
      //console.log('width:', width);
    }
    const display = percentage > 0 ? 'flex' : 'none';
    const style = color ? {backgroundColor: color, width, display} : {width, margin: counter > 0 ? '0 5px': '0', display}

    return (
      <a onClick={() => this._setSwimLaneURL(community_id, userIds, activityId, taskId)}>
        <div className={`px-progress-bar progress-bar progress-bar-${cssClass}`} style={style}>
          <ASCounter counter={counter} percentage={percentage}/>
        </div>
      </a>
    );
  }

  _setSwimLaneURL = (communityId, usersId, activityId, taskId) => {
    const urlSwim = '/communities/'+ communityId +'/transcript/'+ usersId +'/'+ activityId +'/'+ taskId +'/Activities';
    this.props.clearForwardUrl(null, 'common', urlSwim);
  }
}
