import React from "react";
import PropTypes from "prop-types";
import v4 from "uuid";
import LPRow from "./LisPanel/LPRow"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"
import {formatComments, usernamesToDisplay} from "../../shared_utils";

const ListPanel = ({ responseEmbeddings, language, colors,  activeOptionsMenu,
                     setActiveOptionsMenu, activeChildOptionsMenu,
                     setActiveChildOptionsMenu, setPanel
}) => {

  const I18n = {
    en: {download: 'download', more: 'more', back: 'back'},
    es: {download: 'descargar', more: 'más', back: 'atrás'}
  }

  const downloadCSV = (includeComments=false) => {
    // console.log('includeComments:', includeComments)
    const title = [responseEmbeddings.question];
    let headers = []
    if (includeComments) {
      headers = ['Username', 'Response', 'Comments', 'Sentiment', 'Emotions', 'Relevance', "Kind"];
    } else {
      headers = ['Username', 'Response', 'Sentiment', 'Emotions', 'Relevance', "Kind"];
    }
    const emptyRow = [''];
    const rows = [];

    responseEmbeddings.responses.forEach(r => {
      const response = r.response;
      const availableUsernames = usernamesToDisplay(response?.user, 'alias', 'all', 'string')
      const username = availableUsernames ? availableUsernames : 'Unknown';
      const responseText = `"${response.response.replace(/"/g, '""')}"`; // escape double quotes
      const sentimentValue = response.sentiment;
      const sentiment = sentimentValue === 1 ? 'Positive' : sentimentValue === 0 ? 'Neutral' : 'Negative';
      const emotions = response.emotions && response.emotions.length > 0 ? `"${response.emotions.join(', ')}"` : 'N/A';
      const relevance = response.percentage;
      const kind = response.embedding_kind;

      if (includeComments) {
        const formattedComments = formatComments(response.comments).replace(/\n/g, ' ').replace(/"/g, '""');
        const formattedCommentsText = `"${formattedComments}"`;
        rows.push([`"${username}"`, responseText, formattedCommentsText, `"${sentiment}"`, emotions, `"${relevance}"`, `"${kind}"`]);
      } else {
        rows.push([`"${username}"`, responseText, `"${sentiment}"`, emotions, `"${relevance}"`, `"${kind}"`]);
      }

      if (response.response_children && response.response_children.length > 0) {
        response.response_children.forEach(child => {
          const availableUsernames = usernamesToDisplay(child?.user, 'alias', 'all', 'string')
          const childUsername = availableUsernames ? availableUsernames : 'Unknown';
          const childResponseText = `"${child.response.replace(/"/g, '""')}"`; // escape double quotes
          const childSentimentValue = child.sentiment ? child.sentiment : 0;
          const childSentiment = childSentimentValue === 1 ? 'Positive' : childSentimentValue === 0 ? 'Neutral' : 'Negative';
          const childEmotions = child.emotions && child.emotions.length > 0 ? `"${child.emotions.join(', ')}"` : 'N/A';
          const childRelevance = child.percentage;
          const childKind = child.embedding_kind;

          if (includeComments) {
            const childFormattedComments = formatComments(child.comments).replace(/\n/g, ' ').replace(/"/g, '""');
            const childFormattedCommentsText = `"${childFormattedComments}"`;
            rows.push([`"${childUsername}"`, childResponseText, childFormattedCommentsText, `"${childSentiment}"`, childEmotions, `"${childRelevance}"`, `"${childKind}"`]);
          } else {
            rows.push([`"${childUsername}"`, childResponseText, `"${childSentiment}"`, childEmotions, `"${childRelevance}"`, `"${childKind}"`]);
          }
        });
      }
    });

    const csvContent =
        'data:text/csv;charset=utf-8,' +
        [title, emptyRow, headers, ...rows].map(row => row.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'responses.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
      <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="ai-list-panel-header" style={{ position: 'sticky', top: 0, background: colors.color2[5] }}>
          <div onClick={() => setPanel('chat')}
               style={{position: 'absolute', left: '15px',
            fontSize: '18px', textAlign: 'left',
            cursor: 'pointer', color: '#909b9e'}}>
            <span style={{display:'flex', alignItems:'center', fontSize: '20px'}}>
              <FontAwesomeIcon icon={['fas','arrow-left']} />
              {/*<span style={{marginLeft: '5px', textTransform: 'lowercase',*/}
              {/*  fontFamily: 'Sharp Sans Medium', fontSize: '14px'}}>*/}
              {/*  {I18n[language].back}*/}
              {/*</span>*/}
            </span>
          </div>
          <div className='ai-list-panel-header-title' style={{ textAlign: 'center', color: '#262E37', padding: '0 10%', margin: '0 10px' }}>
            {responseEmbeddings.question}
          </div>
          <div className='ai-list-panel-header-actions' style={{ minWidth: '80px', color: '#909b9e' }}>
            <span id='list-download' onClick={() => downloadCSV(true)} style={{marginRight: '15px'}} data-tooltip-content={I18n[language].download}><FontAwesomeIcon icon={['fas','download']} /></span>
            {/*<span data-tooltip-content={I18n[language].more}><FontAwesomeIcon icon={['fas','ellipsis-vertical']} /></span>*/}
          </div>
        </div>
        <div className="table-container" style={{margin: '0 24px'}}>
          {
            responseEmbeddings.responses?.map((r, index) => {
              return (
                  <LPRow
                      key={v4()}
                      rowIndex={index}
                      response={r.response}
                      language={language}
                      colors={colors}
                      activeOptionsMenu={activeOptionsMenu}
                      setActiveOptionsMenu={setActiveOptionsMenu}
                      activeChildOptionsMenu={activeChildOptionsMenu}
                      setActiveChildOptionsMenu={setActiveChildOptionsMenu}
                  />
              );
            })
          }
        </div>
      </div>
  );
};

ListPanel.propTypes = {
  responseEmbeddings: PropTypes.object.isRequired,
  setPanel: PropTypes.func.isRequired
};

export default ListPanel;