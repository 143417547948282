import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";

export default class UIGModal03 extends React.Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showModal} contentLabel="Modal Test" shouldCloseOnOverlayClick={true}
                  onRequestClose={() => this.props.updateShowModal('sm02')} className="px-modal-content"
                  overlayClassName="px-modal-overlay">
        <div className="modal-dialog" style={{width:'350px'}}>
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                <i onClick={() => this.props.updateShowModal('sm02')} className="material-icons">clear</i>
              </button>
              <h4 className="px-modal-title">How would yout like to be recognized in de communities?</h4>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-xs-8 col-xs-offset-2 text-center">
                  <div className="form-group first" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <label className="label-sm purple">Pick an avatar</label>
                    <img id="imgPreviewMyProfile" src="https://pixiebob.s3.ap-southeast-2.amazonaws.com/user1/profile/blob_1574395681" className="px-thumb-65 img-circle" alt="" style={{cursor: 'pointer'}} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username" className="label-sm purple">Pick a username</label>
                    <input name="username" type="text" className="form-control px-input-settings"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer px-modal-footer-warning" style={{borderTop:'none'}}>
              <div className="px-footer-container-buttons column">
                <button type="button" className="btn px-btn sm btn-blue-base">NEXT</button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}
