import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MediaQuery from "react-responsive";
import ReactModal from "react-modal";

import DMCreateFilter from "./DMCreateFilter";
import R568Search from "../../Responsive/568px/R568Search";
import DFMUpdateDelete from "./Filters/DFMUpdateDelete";

export default class  extends React.Component {
  static propTypes = {
    activeLink: PropTypes.string.isRequired,
    objSearch: PropTypes.object.isRequired,
    updateFilterIsMin: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    showModal: PropTypes.bool,
    updateShowModal: PropTypes.func.isRequired,
    nowFilter: PropTypes.string,
    updateNowFilter: PropTypes.func,
    updateArrayRdoBtnModal: PropTypes.func,  //actually it's not a radio button here, it's useful to clear the array
    searchTerm: PropTypes.string,
    updateSearchTerm: PropTypes.func,
    searchTask: PropTypes.func,
    executeUpdateModal: PropTypes.func.isRequired, //add to isRequired
    updateCustomFilter: PropTypes.func, //add to isRequired
    userFilters: PropTypes.array,  //add to isRequired
    handleChangeSltFilter: PropTypes.func,   //add to isRequired
    updateUserCustomFilters: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      showModal: false,
      showModalMobile: false,
      isMin: true,
      showModalUD: false,
      modalType: ''
    };
  }

  render() {
    const {activeLink, nowFilter, objSearch, from, userFilters, language, colors} = this.props;

    const I18n = {
      en: { search: 'Search', filterBy: 'Filter by', customFilters: 'YOUR FILTERS', participants: 'Participants',
            segments: 'Segments', activities: 'Activities', activity:'Activity', tags: 'Tags', saveFilter: 'Save filter', update: 'Update filter',
            all: 'All', responses: 'Responses', comments: 'Comments', likes: 'Likes', replies: 'Replies',
            advancedFilters: 'Advanced filters'
          }, 
      es: {
        search: 'Buscar', filterBy: 'Filtrar por', customFilters: 'TUS FILTROS', participants: 'Participantes',
        segments: 'Segmentos', activities: 'Actividades', activity: 'Actividad', tags: 'Tags', saveFilter: 'Guardar filtro', update: 'Actualizar filtro',
        all: 'Todo', responses: 'Respuestas', comments: 'Comentarios', likes: 'Likes', replies: 'Contestaciones', advancedFilters: 'Filtros avanzados'}
    }

    const fromModal = from === 'Matrix' ? 'Activities' : from;
    // TO-DO it looks the same as btnDefaultMatrix
    let btnDefaultActivities = this._getLength('idActs') === 0 ?
      <button onClick={() => this.props.updateShowModal(activeLink, from)} className="px-btn-type-select">{from}
        <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
      </button> :
      <span className='btn-filter-wrapper'>
        <button onClick={() => this.props.updateShowModal(from, from)} className="btn-filter">
          {this._singularize(this._getLength('idActs'), from)}
        </button>
        <div className="btn-delete btn-close-float bar-filter" style={{cursor:'pointer'}}>
          <div onClick={() => this.props.executeUpdateModal(activeLink, 'idActs', [])}
               className="btn-container bar-filter">
            <FontAwesomeIcon icon={['fas','xmark']}/>
          </div>
        </div>
      </span>;

    let btnDefaultParticipants = this._getLength('idPars') === 0 ?
      <button onClick={() => this.props.updateShowModal(activeLink, from)} className="px-btn-type-select">{from}
        <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
      </button> :
      <span className='btn-filter-wrapper'>
        <button onClick={() => this.props.updateShowModal(from, from)} className="btn-filter">
          {this._singularize(this._getLength('idPars'), from)}
        </button>
        <div className="btn-delete btn-close-float bar-filter" style={{cursor:'pointer'}}>
          <div onClick={() => this.props.executeUpdateModal(activeLink, 'idPars', [])}
               className="btn-container bar-filter">
            <FontAwesomeIcon icon={['fas','xmark']}/>
          </div>
        </div>
      </span>;

    let btnDefaultMatrix = this._getLength('idActs') === 0 ?
      <button onClick={() => this.props.updateShowModal(activeLink, fromModal)} className="px-btn-type-select">{fromModal}
        <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
      </button> :
      <div style={{height:'30px'}}>
        <button onClick={() => this.props.updateShowModal(from, fromModal)} className="btn-filter">
          {this._getLength('idActs')} {fromModal}
        </button>
        <div className="btn-delete btn-close-float bar-filter" style={{cursor:'pointer'}}>
          <div onClick={() => this.props.executeUpdateModal(activeLink, 'idActs', [])}
               className="btn-container bar-filter">
            <FontAwesomeIcon icon={['fas','xmark']}/>
          </div>
        </div>
      </div>;

    let btnDefaultMatrix2 = this._getLength('idSegs') === 0 ?
        <button style={{marginLeft:'10px'}} onClick={() => this.props.updateShowModal(from, 'Segments')} className="px-btn-type-select">{I18n[language].segments}
          <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
        </button> :
        <div style={{height:'30px', marginLeft:'10px'}}>
          <button onClick={() => this.props.updateShowModal(from, 'Segments')} className="btn-filter">
            {this._singularize(this._getLength('idSegs'), 'Segments')}
          </button>
          <div className="btn-delete btn-close-float bar-filter"
               style={{cursor:'pointer'}}>
            <div onClick={() => this.props.executeUpdateModal(activeLink, 'idSegs', [])}
                 className="btn-container bar-filter">
              <FontAwesomeIcon icon={['fas','xmark']}/>
            </div>
          </div>
        </div>;

    let btnDefaultNow = this._getLength('idPars') === 0 ?
      <button onClick={() => this.props.updateShowModal(activeLink, 'Participants')} className="px-btn-type-select">{'Participants'}
        <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
      </button> :
      <span className='btn-filter-wrapper'>
        <button onClick={() => this.props.updateShowModal(activeLink, 'Participants')} className="btn-filter">
          {this._singularize(this._getLength('idPars'), 'Participants')}
        </button>
        <div className="btn-delete btn-close-float bar-filter"
             style={{cursor:'pointer'}}>
          <div onClick={() => this.props.updateArrayRdoBtnModal('participants', 'None', activeLink, true)}
               className="btn-container bar-filter">
            <FontAwesomeIcon icon={['fas','xmark']}/>
          </div>
        </div>
      </span>;


    let btnActivities = this._getLength('idActs') === 0 ?
      <button onClick={() => this.props.updateShowModal(activeLink, 'Activities')} className="px-btn-type-select">{I18n[language].all}
        <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
      </button> :
      <div style={{height:'30px'}}>
        <button onClick={() => this.props.updateShowModal(activeLink, 'Activities')} className="btn-filter">
          {this._singularize(this._getLength('idActs'), 'Activities')}
        </button>
        <div className="btn-delete btn-close-float bar-filter" style={{cursor: 'pointer'}}>
          <div onClick={() => this.props.executeUpdateModal(activeLink, 'idActs', [])}
               className="btn-container bar-filter">
            <FontAwesomeIcon icon={['fas','xmark']}/>
          </div>
          {/*<div onClick={() => this.props.updateArrayRdoBtnModal('tasks', 'None', activeLink)}
               className="btn-container bar-filter">
            <FontAwesomeIcon icon={['fas','xmark']}/>
          </div>*/}
        </div>
      </div>;

    let btnParticipants = this._getLength('idPars') === 0 ?
      <button onClick={() => this.props.updateShowModal(activeLink, 'Participants')} className="px-btn-type-select">{I18n[language].all}
        <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
      </button> :
      <div style={{height:'30px'}}>
        <button onClick={() => this.props.updateShowModal(activeLink, 'Participants')} className="btn-filter">
          {this._singularize(this._getLength('idPars'), 'Participants')}
        </button>
        <div className="btn-delete btn-close-float bar-filter" style={{cursor:'pointer'}}>
          <div onClick={() => this.props.executeUpdateModal(activeLink, 'idPars', [])}
               className="btn-container bar-filter">
            <FontAwesomeIcon icon={['fas','xmark']}/>
          </div>
        </div>
      </div>;

    let btnSegments = this._getLength('idSegs') === 0 ?
      <button onClick={() => this.props.updateShowModal(from, 'Segments')} className="px-btn-type-select">{I18n[language].all}
        <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
      </button> :
      <div style={{height:'30px'}}>
        <button onClick={() => this.props.updateShowModal(from, 'Segments')} className="btn-filter">
          {this._singularize(this._getLength('idSegs'), 'Segments')}
        </button>
        <div className="btn-delete btn-close-float bar-filter"
             style={{cursor:'pointer'}}>
          <div onClick={() => this.props.executeUpdateModal(activeLink, 'idSegs', [])}
               className="btn-container bar-filter">
            <FontAwesomeIcon icon={['fas','xmark']}/>
          </div>
        </div>
      </div>;

    let btnTags = this._getLength('idTags') === 0 ?
      <button onClick={() => this.props.updateShowModal(from, 'Tags')} className="px-btn-type-select">{I18n[language].all}
        <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
      </button> :
      <div style={{height:'30px'}}>
        <button onClick={() => this.props.updateShowModal(from, 'Tags')} className="btn-filter">
          {this._singularize(this._getLength('idTags'), 'Tags')}
        </button>
        <div className="btn-delete btn-close-float bar-filter"
             style={{cursor:'pointer'}}>
          <div onClick={() => this.props.executeUpdateModal(activeLink, 'idTags', [])}
               className="btn-container bar-filter">
            <FontAwesomeIcon icon={['fas','xmark']}/>
          </div>
        </div>
      </div>;

    let btnDefault = null;
    let btnDefault2 = null;
    switch(activeLink){
      case 'Activities': btnDefault = btnDefaultActivities;break;
      case 'Participants': btnDefault = btnDefaultParticipants;break;
      case 'Matrix': btnDefault = btnDefaultMatrix; btnDefault2 = btnDefaultMatrix2;break;
      case 'Now': btnDefault = btnDefaultNow;break;
    }

    let selectCustomFilter = null;
    //console.log(userFilters);
    if(userFilters !== undefined){
      if(userFilters.length !== 0){
        selectCustomFilter = <div className='px-select mg-left-10'>
          <select name="slcUserFilters" value={objSearch.name}
                  onChange={(event) => this.props.handleChangeSltFilter(event, activeLink)}>
            <option value="">{I18n[language].customFilters}</option>
            {
              userFilters.map(uf => {
                return (
                  <option key={uf.id} value={uf.name}>{uf.name}</option>
                )
              })
            }
          </select>
        </div>
      }
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="px-search-bar-dash-comm-cont dashboard-collaborator" role="search">
            <MediaQuery maxDeviceWidth={568}>
              <div className="dropdown" style={{marginRight:'auto'}}>
                <a onClick={this.updateShowModalMobile} href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <FontAwesomeIcon icon={['fas', 'magnifying-glass']} className="px-icon-search-responsive dashboard-collaborator"/>
                </a>
                <ReactModal isOpen={this.state.showModalMobile} contentLabel="Mobile Dashboard filter"
                            shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalMobile}
                            className="px-modal-content" overlayClassName="px-modal-overlay">
                  <R568Search from={from}
                              activeLink={activeLink}
                              updateSearchTerm={this.props.updateSearchTerm}
                              searchTask={this.props.searchTask}
                              updateShowModalMobile={this.updateShowModalMobile}
                              objSearch={objSearch}
                              updateShowModal={this.props.updateShowModal}
                              executeUpdateModal={this.props.executeUpdateModal}
                              nowFilter={this.props.nowFilter}
                              updateNowFilter={this.props.updateNowFilter}
                              language={language}
                              colors={colors}/>
                </ReactModal>
              </div>
            </MediaQuery>

            <div className="px-filter-left-container dashboard-collaborator">
              {
                from !== 'Now' && from !== 'Matrix' ?
                  <div className="px-input-icon dashboard-collaborator form-group">
                    <MediaQuery minDeviceWidth={569}>
                      <div>
                        <input onChange={(e) => this.props.updateSearchTerm(e, from)} name="searchTerm"
                               value={objSearch.searchTerm} onKeyDown={this.keyPress} type="text"
                               className="form-control icon-right" placeholder={I18n[language].search}/>
                        <FontAwesomeIcon onClick={() => this.props.searchTask(from)} icon={['fas', 'magnifying-glass']}
                                         className="px-icon-right color-gray-base"/>
                      </div>
                    </MediaQuery>
                  </div> :
                from === 'Now' &&
                <div className="px-now-filter-container">
                  <div className="px-now-filters" style={{display: 'flex'}}>
                      <span className={`check-btn ${nowFilter === 'All' ? 'active' : ''}`}
                            onClick={() => this.props.updateNowFilter('All')} >
                        <span>{I18n[language].all}</span>
                      </span>
                      <span className={`check-btn ${nowFilter === 'Responses' ? 'active' : ''}`}
                            onClick={() => this.props.updateNowFilter('Responses')} >
                          <span>{I18n[language].responses}</span>
                      </span>
                      <span className={`check-btn ${nowFilter === 'CommentsReplies' ? 'active' : ''}`}
                        onClick={() => this.props.updateNowFilter('CommentsReplies')} >
                        <span>{I18n[language].comments}</span>
                      </span>
                      {/* <span className={`check-btn ${nowFilter === 'Comments' ? 'active' : ''}`}
                            onClick={() => this.props.updateNowFilter('Comments')} >
                          <span>{I18n[language].comments}</span>
                      </span> */}
                      <span className={`check-btn ${nowFilter === 'Likes' ? 'active' : ''}`}
                            onClick={() => this.props.updateNowFilter('Likes')} >
                          <span>{I18n[language].likes}</span>
                      </span>
                      {/* <span className={`check-btn ${nowFilter === 'Replies' ? 'active' : ''}`}
                            onClick={() => this.props.updateNowFilter('Replies')} >
                          <span>{I18n[language].replies}</span>
                      </span> */}
                  </div>
                  {/* <div className="px-input-icon dashboard-collaborator form-group">
                    <input onChange={this.props.handleChange} name="searchTerm" value={this.state.searchTerm}
                           onKeyDown={this.keyPress} type="text" className="form-control icon-right" placeholder="Search"/>
                    <FontAwesomeIcon icon={['fas', 'magnifying-glass']} className="px-icon-right color-gray-base"/>
                  </div> */}
                </div>
              }
            </div>
            {
              objSearch.isMin ?
                <div className="px-filter-right-container dashboard-collaborator">
                  <span className="label-xs mg-right-10">{I18n[language].filterBy}</span>
                  { btnDefault }
                  { btnDefault2 }
                  { selectCustomFilter }
                  <button type="button" className="btn btn-filter-bar"
                          onClick={() => this.props.updateFilterIsMin(activeLink, !objSearch.isMin)}>
                    <span className="fa-layers fa-fw">
                      <FontAwesomeIcon icon="filter"/>
                      <FontAwesomeIcon icon="circle-plus" style={{fontSize: '12px'}}
                                       transform="shrink-3 up-15 right-10"/>
                    </span>
                  </button>
                </div> :
                <div className="px-filter-right-container dashboard-collaborator">
                  <span className="label-xs mg-right-10" style={{color: colors.color1[3]}}>
                    {selectCustomFilter ? selectCustomFilter : I18n[language].advancedFilters}
                  </span>
                  <button type="button" className="btn btn-filter-bar"
                          onClick={() => this.props.updateFilterIsMin(activeLink, !objSearch.isMin)}
                          style={{ color: colors.color1[3]}}>
                    <span className="fa-layers fa-fw">
                      <FontAwesomeIcon icon="filter"/>
                      <FontAwesomeIcon icon="circle-plus" style={{fontSize: '12px'}}
                                       transform="shrink-3 up-15 right-10"/>
                    </span>
                  </button>
                </div>
            }
          </div>

          <div className="bar-filters start activities mg-top-bottom-20"
               style={{padding: "0px", display: objSearch.isMin ? 'none' : 'flex'}}>
            <div className="filter-margin">
              <span className="label-xs filter-by">{I18n[language].filterBy}:</span>
            </div>
            <div className='filter-wrapper mg-right-15'>
              <span className="label-xs">{I18n[language].activities}</span>
              { btnActivities }
            </div>
            <div className='filter-wrapper mg-right-15'>
              <span className="label-xs">{I18n[language].segments}</span>
              { btnSegments }
            </div>
            <div className='filter-wrapper mg-right-15'>
              <span className="label-xs">{I18n[language].participants}</span>
              { btnParticipants }
            </div>
            <div className='filter-wrapper mg-right-15'>
              <span className="label-xs">{I18n[language].tags}</span>
              { btnTags }
            </div>
            <div className='filter-wrapper mg-bottom-0' style={{alignSelf:'flex-end'}}>
              <button style={{padding: '0 15px', backgroundColor: colors.color1[3]}} onClick={this.updateShowModal} className="btn px-btn sm btn-blue-base" 
                data-tooltip-content={objSearch.id === undefined ? I18n[language].saveFilter : I18n[language].update +' "'+ objSearch.name +'"'}>
                  <FontAwesomeIcon icon={['fas', 'floppy-disk']} style={{fontSize: '12px'}} />
              </button>
              {
                objSearch.name &&
                <button type="button" className="btn btn-filter-bar" onClick={() => this.updateShowModalUD('delete')}>
                  <span className="fa-layers fa-fw"><FontAwesomeIcon icon={['fas','trash']}/></span>
                </button>
              }
            </div>
          </div>
        </div>
        {
          (this.state.showModal && objSearch.id === undefined) &&
          <DMCreateFilter showModal={this.state.showModal}
                          updateShowModal={this.updateShowModal}
                          createCustomFilter={this.createCustomFilter}
                          language={language}
                          colors={colors}/>
        }
        {
          (this.state.showModalUD && objSearch.id) &&
          <DFMUpdateDelete showModalUD={this.state.showModalUD}
                           updateShowModalUD={this.updateShowModalUD}
                           type={this.state.modalType}
                           objSearch={objSearch}
                           updateDeleteUserFilter={this.updateDeleteUserFilter}
                           language={language}
                           colors={colors}/>
        }
      </div>
    );
  }

  updateDeleteUserFilter = (objSearch, action) => {
    this.setState(prevState => ({showModalUD: !prevState.showModalUD}), () => {
      this.props.updateUserCustomFilters(objSearch, action);
    });
  }

  updateShowModalUD = modalType => {
    this.setState(prevState => ({showModalUD: !prevState.showModalUD, modalType}));
  }

  createCustomFilter = name => {
    this.updateShowModal();
    this.props.updateCustomFilter(this.props.from, this.props.objSearch, name)
  };

  updateShowModal = () => {
    if(this.props.objSearch.id === undefined){
      this.setState(state => ({showModal: !state.showModal}));
    } else {
      this.updateShowModalUD('update');
      //this.props.updateCustomFilter(this.props.from, this.props.objSearch);
    }
  };

  updateShowModalMobile = () => {
    this.setState(prevState => ({showModalMobile: !prevState.showModalMobile}));
  };

  keyPress = (event) => {
    if(event.keyCode === 13){
      this.props.searchTask(this.props.from);
    }
  };

  _getLength(arrayName){
    //console.log(this.props.objSearch[arrayName]);
    return this.props.objSearch[arrayName].filter(item => item !== undefined).length;
  }

  _singularize = (count, noun) => {
    const language = this.props.language;
    const I18n = { en: { activities: 'Activities', activity: 'Activity'}, es: { activities: 'Actividades', activity: 'Actividad'}}
    if (noun === I18n[language].activities) {
      return  `${count} ${count === 1 ? I18n[language].activity : noun}`;
    } else {
      return `${count} ${count === 1 ? noun.slice(0, -1) : noun}`;
    }
  };

  updateIsMin = () => {
    this.setState(prevState  => ({isMin: !prevState.isMin}));
  }
}
