import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TagsInput from "react-tagsinput";
import v4 from "uuid";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import ReactModal from "react-modal";
import { Tooltip as ReactTooltip } from "react-tooltip"
import AutosizeInput from 'react-input-autosize';
import _ from 'underscore';

import IVEList from "../../components/Community/InviteViaEmail/IVEList";
import CSEditorWYSIWYG from "../../components/Community/Settings/Utils/CSEditorWYSIWYG";
import CSIPreview from "../../components/Community/Settings/CSInvitations/CSIPreview";
import {emailValidation, showSuccessMsg} from "../../components/UI/Globals/PX_Funs";

export default class InviteViaEmail extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    role_2: PropTypes.string.isRequired,
    maxPapt: PropTypes.number.isRequired,
    user_account: PropTypes.object.isRequired,
    companyName: PropTypes.string.isRequired,
    papt: PropTypes.number,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      emailToggle: true,
      spnEmail: false,
      emailsComplete: [],
      community: this._initCommunity(props.community),
      showPreviewInvitations: false,
      icnNames: false,
      areLinks: false,
      segments: [],
      papt: props.papt,
      isSending: false
    }
  }

  componentDidMount(){
    if(this.props.type === 'Participant'){
      //this._getCommunitySegments();
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.papt !== this.props.papt){
      this.setState({papt: this.props.papt});
    }
  }

  render() {
    const {community, type, role_2, maxPapt, companyName, colors, user_account} = this.props;
    const language = user_account.language;
    const {emailToggle, tags} = this.state;
    const tagsPresent = tags?.length > 0;

    const i18n = {
      en: {
        recipients: 'RECIPIENTS', emailInputPlaceholder: 'Type emails here',
        emailInputPlaceholderWarning: 'You have reached your Active Participants limit',
        subject: 'SUBJECT', message: 'MAIN MESSAGE', messageAction1: 'Click to make this text the default message',
        messageAction2: 'Reset to default message', signatureAction1: 'Click to replace this text with the default message',
        signatureMessage: 'SIGNATURE MESSAGE', dynamicValues: 'Personalize with dynamic values',
        communityTitle: 'Community title', accountHolder: 'Account holder', acceptUrl: 'Accept URL', declineUrl: 'Decline URL',
        preview: 'PREVIEW', saveDrafts: 'SAVE AS DRAFTS', sendInvites: 'SEND INVITES',
        of: 'of', participants: 'Participants', collaborators: 'Collaborators',
        hideDetails: 'less details', showDetails: 'more details'
      },
      es: {
        recipients: 'DESTINATARIOS', emailInputPlaceholder: 'Escribe aquí direcciones de correo',
        emailInputPlaceholderWarning: 'Has alcanzado tu límite de Participantes Activos',
        subject: 'ASUNTO', message: 'MENSAJE PRINCIPAL', messageAction1: 'Clic para hacer este texto el mensaje principal predeterminado',
        messageAction2: 'Clic para reemplazar este texto por el mensaje predeterminado',
        signatureAction1: 'Clic para hacer este texto el mensaje firma predeterminado', signatureMessage: 'MENSAJE FIRMA',
        dynamicValues: 'Personaliza con valores dinámicos', communityTitle: 'Título de la Comunidad',
        accountHolder: 'Titular de la cuenta', acceptUrl: 'URL para aceptar', declineUrl: 'URL para declinar',
        preview: 'MUESTRA', saveDrafts: 'GUARDAR BORRADORES', sendInvites: 'ENVIAR', of: 'de', participants: 'Participantes',
        collaborators: 'Colaboradores',
        hideDetails: 'más detalles', showDetails: 'menos detalles'
      }
    }

    const modalStyle = {
      overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(0, 0, 0, 0.2)',
        zIndex            : 1050,
      },
      content : {
        position                   : 'absolute',
        top                        : '0',
        left                       : '40px',
        right                      : '0',
        bottom                     : '0',
        border                     : '0',
        background                 : 'rgba(0,0,0,0)',
        overflow                   : 'auto',
        WebkitOverflowScrolling    : 'touch',
        borderRadius               : '4px',
        outline                    : 'none',
        padding                    : '20px'
      }
    };
    //console.log(maxPapt - this.state.papt);
    const partPaptFlag = this.state.emailsComplete.length > (maxPapt - this.state.papt - 1);

    return (
      <div className="px-settings-container">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-card px-card-settings px-card-invite-via-email">
                <div className="px-icon-settings"><FontAwesomeIcon icon={['fas','envelope']}/></div>
                <div className="panel-body px-body-padding-welcome">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group first">
                        <span className="label-md"
                              style={{display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'stretch',
                                color: colors.color1[2]}}
                        >
                          <span>
                           {i18n[language].recipients}
                           ({type === 'Participant' ? i18n[language].participants : i18n[language].collaborators})
                            {type === 'Participant' && `(${(this.state.papt + this.state.tags.length)} ${i18n[language].of} ${maxPapt})`}
                          </span>
                          <span className="label-sm"
                                onClick={this._toggleEmail}
                                style={{color: '#7c8593',
                                  cursor: 'pointer',
                                  border: '1px solid #c6c6c6',
                                  borderRadius: '2px',
                                  padding: '0 10px',
                                  display: tagsPresent ? 'flex' : 'none'
                          }}
                          >
                            {this.state.emailToggle ? i18n[language].showDetails : i18n[language].hideDetails}
                            {/*<FontAwesomeIcon icon={['far','circle-caret-down']}*/}
                            {/*                 style={{cursor: 'pointer'}} data-tooltip-content="More details"/>*/}
                          </span>
                        </span>
                        <TagsInput onChange={(tags, changed, changedIndexes) =>
                          this.handleChange(tags.map(t => t.trim()), changed.map(t => t.trim()), changedIndexes)}
                                   value={this.state.tags}
                                   addKeys={[9, 13, 32, 188]} // Tab, Enter, Space, Comma
                                   onlyUnique={true}
                                   renderInput={this.autosizingRenderInput}
                                   inputProps={{placeholder: type === 'Participant' ? partPaptFlag ?
                                      i18n[language].emailInputPlaceholderWarning : i18n[language].emailInputPlaceholder :
                                      i18n[language].emailInputPlaceholder}}
                                   addOnBlur={true}
                                   className="my-react-tagsinput"
                                   renderTag={defaultRenderTag}
                                   disabled={type === 'Participant' ? partPaptFlag : false}/>
                        <span id="spnPart" ref="spnVEemail" className={this.state.spnEmail ?
                          'span-error-show' : 'to-hide'} style={{color: colors.color4[3]}}/>
                      </div>
                    </div>
                  </div>
                  { this.state.tags?.length > 0 && this.state.emailToggle &&
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <IVEList invitees={this.state.emailsComplete}
                              community={community}
                              type={type}
                              handleInputChange={this.handleInputChange}
                              handleCountryRegion={this.handleCountryRegion}
                              role_2={role_2}
                              segments={community.segments}
                              handleSegmentChange={this.handleSegmentChange}
                              language={language}
                              colors={colors}/>
                        </div>
                      </div>
                    </div>
                  }
                  
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                      <span className="label-md" style={{ color: colors.color1[2] }}>{i18n[language].subject}</span>
                        <input name={type === 'Participant' ?
                          community.language === 'en'? 'participants_invitation_subject' :'participants_invitation_subject_es' :
                          community.language === 'en'? 'members_invitation_subject' : 'members_invitation_subject_es'}
                               onChange={this.handleChangeCommunity}
                               value={type === 'Participant' ?
                                 community.language === 'en' ? this.state.community.participants_invitation_subject : this.state.community.participants_invitation_subject_es :
                                 community.language === 'en' ? this.state.community.members_invitation_subject : this.state.community.members_invitation_subject_es}
                               type="text" placeholder="" className="form-control px-input-settings"/>
                      </div>
                    </div>
                  </div>
                  <div className="row px-invitation-container">
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 data-cont">
                      <div className="form-group">
                      <span className="label-md" style={{ color: colors.color1[2] }}>{i18n[language].message}</span>
                        <div className="textarea-bio-container">
                            <CSEditorWYSIWYG
                              name={type === 'Participant' ?
                                community.language === 'en' ? 'participants_invitation' : 'participants_invitation_es' :
                                community.language === 'en' ? 'members_invitation' : 'members_invitation_es'}
                              editorName={type === 'Participant' ? 'editorInvitationPart' : 'editorInvitationColl'}
                              editorState={type === 'Participant' ? this.state.community.editorInvitationPart :
                                this.state.community.editorInvitationColl}
                              onEditorStateChange={this.onEditorStateChange}
                              language = {language}
                            />
                          <span ref="valEditor" className={this.state.areLinks ? 'char-bio-remaining' : 'to-hide'}>
                            {"* No {{acceptance_url}} or {{decline_url}}"} links
                          </span>
                        </div>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center',marginTop:'10px'}}>
                          <p className="blue-research" style={{color: colors.color1[3]}}>
                            <span onClick={this.saveCommunityInvitations} style={{cursor:'pointer', fontSize: '12px'}}>
                            {i18n[language].messageAction1}</span>
                            <br/>
                            <span onClick={this.resetToDefaultInvitation} style={{cursor:'pointer', fontSize: '12px'}}>
                              {i18n[language].messageAction2}</span>
                          </p>
                        </div>
                      </div>

                      <div className="form-group">
                      <span className="label-md" style={{ color: colors.color1[2] }}>{i18n[language].signatureMessage}</span>
                        <div className="textarea-bio-container">
                            <CSEditorWYSIWYG
                              name={type === 'Participant' ?
                                community.language === 'en' ? 'participants_invitation_signature' : 'participants_invitation_signature_es' :
                                community.language === 'en' ? 'members_invitation_signature' : 'members_invitation_signature_es'}
                              editorName={type === 'Participant' ? 'editorInvitationSignaturePart' : 'editorInvitationSignatureColl'}
                              editorState={type === 'Participant' ?
                                this.state.community.editorInvitationSignaturePart :
                                this.state.community.editorInvitationSignatureColl}
                              onEditorStateChange={this.onEditorStateChange}
                              language={language}
                            />
                          <span ref="valEditor" className={this.state.areLinks ? 'char-bio-remaining' : 'to-hide'}>
                            {"* No {{acceptance_url}} or {{decline_url}}"} links
                          </span>
                        </div>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center',marginTop:'10px'}}>
                          <p className="blue-research" style={{ color: colors.color1[3] }}>
                            <span onClick={this.saveCommunityInvitations} style={{cursor:'pointer', fontSize: '12px'}}>
                              {i18n[language].signatureAction1}</span>
                            <br/>
                            <span onClick={this.resetToDefaultInvitation} style={{cursor:'pointer', fontSize: '12px'}}>
                              {i18n[language].messageAction2}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 info-cont">
                      <div className="px-card px-panel-aside-invitations">
                        <div className="panel-body">
                          <label className="title" style={{color: colors.color1[2], fontSize: '12px'}}>{i18n[language].dynamicValues}</label>
                          <div className="info-options-container">
                            <div className="options" style={{fontSize: '12px'}}><strong>{i18n[language].communityTitle}:</strong><br />{'{{community_title}}'}</div>
                            <div className="options" style={{fontSize: '12px'}}><strong>{i18n[language].accountHolder}: </strong><br />{'{{researcher}}'}</div>
                            {/* <div className="options"><strong>Invitee first name:</strong><br />{'{{first_name}}'}</div>
                            <div className="options"><strong>Invitee last name: </strong><br />{'{{last_name}}'}</div>
                            <div className="options"><strong>Account holder email: </strong><br />{'{{researcher_email}}'}</div>
                            <div className="options"><strong>Moderator: </strong><br />{'{{go_to_person}}'}</div>
                            <div className="options"><strong>Moderator Email: </strong><br />{'{{go_to_person_email}}'}</div> */}
                            <div className="options" style={{fontSize: '12px'}}><strong>{i18n[language].acceptUrl}: </strong><br />{'{{acceptance_url}}'}</div>
                            <div className="options" style={{fontSize: '12px'}}><strong>{i18n[language].declineUrl}: </strong><br />{'{{decline_url}}'}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row" style={{marginTop:'30px'}}>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                      <div className="px-row-buttons-action welcome">
                        <button onClick={this.updateShowPreviewInvitations}
                        className="btn px-btn lg btn-gray-base">{i18n[language].preview}</button>
                        <button onClick={() => this.sendInvites('draft')} disabled={this.state.isSending}
                        className="btn px-btn lg btn-blue-base" style={{backgroundColor: colors.color1[3]}}>
                          { this.state.isSending ? <FontAwesomeIcon icon={['fas','spinner']} spin/> : i18n[language].saveDrafts }
                        </button>
                        <button onClick={() => this.sendInvites('invited')} disabled={this.state.isSending}
                        className="btn px-btn lg btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>
                                {this.state.isSending ? <FontAwesomeIcon icon={['fas', 'spinner']} spin /> : i18n[language].sendInvites }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        {
          this.state.showPreviewInvitations &&
          <ReactModal isOpen={this.state.showPreviewInvitations} contentLabel="Email Preview"
                      shouldCloseOnOverlayClick={true} style={modalStyle}>
            <CSIPreview
              community={community}
              subject={type === 'Participant' ?
                community.language === 'en' ? this.state.community.participants_invitation_subject : this.state.community.participants_invitation_subject_es :
                community.language === 'en' ? this.state.community.members_invitation_subject : this.state.community.members_invitation_subject_es}
              body={type === 'Participant' ?
                community.language === 'en' ? this.state.community.participants_invitation : this.state.community.participants_invitation_es :
                community.language === 'en' ? this.state.community.members_invitation : this.state.community.members_invitation_es}
              signature={type === 'Participant' ?
                community.language === 'en' ? this.state.community.participants_invitation_signature : this.state.community.participants_invitation_signature_es :
                community.language === 'en' ? this.state.community.members_invitation_signature : this.state.community.members_invitation_signature_es }
              updateShowPreviewInvitations={this.updateShowPreviewInvitations} companyName={companyName}
              colors={colors}
            />
          </ReactModal>
        }
      </div>
    );
  }

  handleChange = (tags, changed, changedIndexes) => {
    let language = this.props.user_account.language;
    const i18n = { en: { emailInvalid: 'Invalid email' }, es: { emailInvalid: 'Email inválido'}}
    // console.log(tags, changed, changedIndexes);
    const flagDelete = _.find(tags, function (tag) {
      return tag === changed[0].trim();
    });
    // console.log(flagDelete);

    if(flagDelete !== undefined){
      // console.log('changed.length:', changed[0].split(','));
      const changedArray = changed[0].split(',');

      changedArray.forEach(changed => {
        const email = changed.trim();
        // console.log('email:', email);
        if(emailValidation(email)){
          this._emailCommunityValidation(email);
        } else {
          let tags = [...this.state.tags];
          tags.push(email);
          // console.log(tags);
          this.setState({spnEmail: true, tags: tags.map(t => t.trim())}, () => {
            $('#spnPart').text('* ' + i18n[language].emailInvalid + ': ' + email);
          });
        }
      });

      /*if(emailValidation(changed[0].trim())){
        this._emailCommunityValidation(changed[0].trim());
      }else{
        let tags = [...this.state.tags];
        tags.push(changed[0].trim());
        console.log(tags);
        this.setState({spnEmail: true, tags: tags.map(t => t.trim())}, () => {
          $('#spnPart').text('* ' + i18n[language].emailInvalid + ': ' + changed);
        });
      }*/
    } else {
      // Delete in tags, emailComplete when 'x' is clicked in tag
      const tags = _.reject(this.state.tags, function (tag) {
        return tag === changed[0]
      });
      this.setState({tags: tags, spnEmail: false}, function () {
        if(this.state.tags.length === 0){
          this.setState({emailToggle: false});
        }
      });

      const emailsComplete = _.reject(this.state.emailsComplete, function (ec) {
        return ec.email === changed[0];
      });

      this.setState({emailsComplete}, function () {
        this._validationNames();
      });
    }
  };

  handleChangeCommunity = (event) => {
    const community = {...this.state.community};
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;

    community[name] = value;

    this.setState({community});
  };

  handleInputChange = (event, id) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    const emailsComplete = this.state.emailsComplete.map(obj => obj.id === id ? {...obj, [name]: value} : obj);

    this.setState({emailsComplete});
  };

  handleSegmentChange = (name, value, id) => {
    const emailsComplete = this.state.emailsComplete.map(obj => obj.id === id ? {...obj, [name]: value} : obj);

    this.setState({emailsComplete});
  };

  handleCountryRegion = (value, name, id) => {
    const emailsComplete = this.state.emailsComplete.map(obj => obj.id === id ? {...obj, [name]: value} : obj);

    this.setState({emailsComplete});
  };

  sendInvites = (option) => {
    let language = this.props.user_account.language;
    const i18n = { en: { emailInvalid: 'Type an email recipient' }, es: { emailInvalid: 'Escribe un correo electrónico' } }
    const {type} = this.props;
    const {community} = this.state;

    const subject = type === 'Participant' ?
      community.language === 'en' ? community.participants_invitation_subject : community.participants_invitation_subject_es :
      community.language === 'en' ? community.members_invitation_subject : community.members_invitation_subject_es;
    const body = type === 'Participant' ?
      community.language === 'en' ? community.participants_invitation : community.participants_invitation_es :
      community.language === 'en' ? community.members_invitation : community.members_invitation_es;
    const signature = type === 'Participant' ?
      community.language === 'en' ? community.participants_invitation_signature : community.participants_invitation_signature_es :
      community.language === 'en' ? community.members_invitation_signature : community.members_invitation_signature_es;

    // const acceptFlag = body.search('{{acceptance_url}}');
    // const declineFlag = body.search('{{decline_url}}');
    // if(acceptFlag !== -1 && declineFlag !== -1){
      this.setState({areLinks: false});

      if(this.state.emailsComplete.length !== 0){
        //this.refs.btnSendInvites.disabled = true;
        this.state.emailsComplete.forEach(invitee => {
          this.sendInvite(invitee, option, subject, body, signature);
        });
        // Reset the Invitees and Hide Table Emails
        this.setState({emailsComplete: [], tags: [], emailToggle: false});
      } else {
        this.setState({spnEmail: true});
        this.refs.spnVEemail.innerHTML = '*' + i18n[language].emailInvalid;
      }
    // } else {
      // this.setState({areLinks: true});
    // }
  };

  sendInvite = (invitee, option, subject, body, signature) => {
    /*let segments = [];
    if(typeof invitee.userSegments !== 'undefined'){
      if(invitee.userSegments !== ''){
        segments = invitee.userSegments.map((segment, index) => {
          //console.log(segment);
          return segment.value
        });
      }
    }*/
    //console.log(segments);
    let role2 = invitee.role_2;
    if(this.props.type === 'Participant'){
      //Because no matter what Participant so far never has role_2
      role2 = '';
    }

    let dob = '';
    if(invitee.dob){
      dob = invitee.dob.search('-') > 0 ? invitee.dob : this._formatDOB(invitee.dob, 'yyyy-mm-dd');
    }

    this.setState({isSending: true}, () => {
      $.ajax({
        url: '/users/invitation',
        method: 'POST',
        dataType: 'JSON',
        data: {
          community_id: this.props.community.id,
          email: invitee.email,
          first_name: invitee.first_name,
          last_name: invitee.last_name,
          gender: invitee.gender,
          country: invitee.country,
          state_country: invitee.region,
          dob: dob,
          role_1: this.props.type,
          role_2: role2,
          state_community_users: 'Invited',
          subject: subject,
          body: body,
          signature: signature,
          segments: invitee.userSegments.map(segment => segment.value),
          option: option
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: obj => {
          console.log(obj);
          this.setState({isSending: false});
          showSuccessMsg('Success!');
          window.location.href = `/users_list/${this.props.community.id}/${this.props.type}/`;
          /*
          //this.refs.btnSendInvites.disabled = false;
          this.setState({icnNames: false});
          if(this.props.type === 'Participants'){
            this._getPapt();
          }*/
        }
      });
    });
  };

  _getPapt(){
    $.ajax({
      url: '/users/get_papt',
      method: 'GET',
      dataType: 'JSON',
      success: papt => {
        this.setState({papt});
      }
    });
  }

  updateShowPreviewInvitations = () => {
    this.setState(prevState => ({showPreviewInvitations: !prevState.showPreviewInvitations}));
  };

  resetToDefaultInvitation = () => {
    $.ajax({
      url: '/communities/get_subject_body/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      data: {type: this.props.type},
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: community => {
        console.log(community);
        this.setState({community: this._initCommunity(community)});

        showSuccessMsg('Success!');
      }
    });
  };

  saveCommunityInvitations = () => {
    const {type} = this.props;
    const {community} = this.state;

    const subject = type === 'Participant' ?
      community.language === 'en' ? community.participants_invitation_subject : community.participants_invitation_subject_es :
      community.language === 'en' ? community.members_invitation_subject : community.members_invitation_subject_es;
    let body = type === 'Participant' ?
      community.language === 'en' ? community.participants_invitation : community.participants_invitation_es :
      community.language === 'en' ? community.members_invitation : community.members_invitation_es;
    body = body.replace(/"{{decline_url}}" target/g, 'https://{{decline_url}} target');
    let signature = type === 'Participant' ?
      community.language === 'en' ? community.participants_invitation_signature : community.participants_invitation_signature_es :
      community.language === 'en' ? community.members_invitation_signature : community.members_invitation_signature_es;
    signature = signature.replace(/"{{decline_url}}" target/g, 'https://{{decline_url}} target');

    // const acceptFlag = body.search('{{acceptance_url}}');
    // const declineFlag = body.search('{{decline_url}}');

    // if(acceptFlag === -1 && declineFlag === -1){
   if(subject !== '' && body !== ''){
        this.setState({areLinks: false});

        try {
          $.ajax({
            url: '/communities/update/' + this.props.community.id,
            method: 'PATCH',
            dataType: 'JSON',
            data: {
              community: {
                participants_invitation_subject: community.participants_invitation_subject,
                participants_invitation_subject_es: community.participants_invitation_subject_es,
                participants_invitation: community.participants_invitation,
                participants_invitation_es: community.participants_invitation_es,
                participants_invitation_signature: community.participants_invitation_signature,
                participants_invitation_signature_es: community.participants_invitation_signature_es,
                members_invitation_subject: community.members_invitation_subject,
                members_invitation_subject_es: community.members_invitation_subject_es,
                members_invitation: community.members_invitation,
                members_invitation_es: community.members_invitation_es,
                members_invitation_signature: community.members_invitation_signature,
                members_invitation_signature_es: community.members_invitation_signature_es
              }
            },
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            success: community => {
              console.log(community);
              this.setState({community: this._initCommunity(community)});

              showSuccessMsg('Success!');
            }
          });
        }catch(err){
          this.refs.valEditor.innerHTML = '* You have added invalid characters (<span style="color: #000">�</span>), ' +
            'click Preview to see them';
          this.setState({areLinks: true});
        }
      }
    // } else {
    //   this.refs.valEditor.innerHTML = '* No {{acceptance_url}} or {{decline_url}}';
    //   this.setState({areLinks: true});
    // }
  };

  onEditorStateChange = (editor, editorName, name) =>{
    const community = {...this.state.community};

    community[name] = draftToHtml(convertToRaw(editor.getCurrentContent()));
    community[editorName] = editor;

    this.setState({community})
  };

  _emailCommunityValidation = (email) => {
    $.ajax({
      url: '/users/community_email_validation/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      data: {email: email},
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: obj => {
        console.log(obj);
        if(obj.msg === ''){
          const tags = [...this.state.tags];
          console.log(tags, email, tags.find(t => t === email));
          if(tags.find(t => t === email) === undefined){
            tags.push(email);
            this.setState({tags: tags.map(t => t.trim()), spnEmail: false});

            // Update EmailsComplete - This full the Users, it's important!
            const emailsComplete = [...this.state.emailsComplete];
            let ec;
            let defaultRole = this.props.role_2 === 'Owner' ? 'Moderator' : 'Observer';

            if(obj.profile){
              const profile = obj.profile;
              const flagFirstName = profile.first_name !== '';
              const flagLastName = profile.last_name !== '';
              const flagGender = profile.gender !== '';
              const flagCountry = profile.country !== '';

              let flagDay = false;
              let dobA = [1900, '', ''];
              if(profile.dob !== ''){
                if(profile.dob !== null) {
                  dobA = profile.dob.split('-');
                  flagDay = dobA[2] !== ''
                }
              }

              ec = {
                id: profile.id,
                email: email,
                first_name: profile.first_name,
                last_name: profile.last_name,
                gender: profile.gender,
                country: profile.country,
                region: profile.state_country,
                dob: profile.dob,
                day: dobA[2],
                role_1: '',
                role_2: defaultRole,
                flagFirstName: flagFirstName,
                flagLastName: flagLastName,
                flagGender: flagGender,
                flagCountry: flagCountry,
                flagDay: flagDay,
                userState: obj.user_state,
                userSegments: []
              };
            } else {
              ec = {
                id: v4(),
                email: email,
                first_name: '',
                last_name: '',
                gender: '',
                country: '',
                region: '',
                dob: '',
                day: '',
                role_1: '',
                role_2: defaultRole,
                userSegments: []
              };
            }

            //It needs to hide it depends on type
            emailsComplete.push(ec);
            this.setState({emailsComplete: emailsComplete}, () => {
              this._validationNames();
            });
          }
        } else {
          //It needs to hide it depends on type
          this.setState({spnEmail: true});
          this.refs.spnVEemail.innerHTML = obj.msg + ': ' + email
        }
      }
    });
  };

  _initCommunity(community){
    //console.log(community.participants_invitation);
    return {
      ...community,
      editorInvitationPart: this._setEditorState(community.language === 'en' ?
        community.participants_invitation : community.participants_invitation_es),
      editorInvitationColl: this._setEditorState(community.language === 'en' ?
        community.members_invitation : community.members_invitation_es),
      editorInvitationSignaturePart: this._setEditorState(community.language === 'en' ?
        community.participants_invitation_signature : community.participants_invitation_signature_es),
      editorInvitationSignatureColl: this._setEditorState(community.language === 'en' ?
        community.members_invitation_signature : community.members_invitation_signature_es)
    }
    /*return {
      ...community,
      editorInvitationPart: this._setEditorState(community.participants_invitation),
      editorInvitationColl: this._setEditorState(community.members_invitation),
      editorInvitationSignaturePart: this._setEditorState(community.participants_invitation_signature),
      editorInvitationSignatureColl: this._setEditorState(community.members_invitation_signature)
    }*/
  }

  _setEditorState(body){
    let component = null;
    if(body !== ''){
      const contentBlock = htmlToDraft(body);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

      component = EditorState.createWithContent(contentState);
    } else {
      component = EditorState.createEmpty();
    }

    return component;
  }

  _validationNames = () => {
    //console.log(this.state.emailsComplete);

    const body = this.props.type === 'Participant' ? this.state.community.participants_invitation :
      this.state.community.members_invitation;
    const signature = this.props.type === 'Participant' ? this.state.community.participants_invitation_signature :
      this.state.community.members_invitation_signature;
    const bodyFN = body.search('{{first_name}}');
    const bodyLN = body.search('{{last_name}}');
    const signatureFN = signature.search('{{first_name}}');
    const signatureLN = signature.search('{{last_name}}');

    if(bodyFN > 0 || bodyLN > 0 || signatureFN > 0 || signatureLN > 0){
      const FLNames = _.find(this.state.emailsComplete, function (invitee) {
        return invitee.first_name === '' || invitee.last_name === '';
      });

      if(FLNames){
        this.setState({icnNames: true});
      } else {
        this.setState({icnNames: false});
      }
    } else {
      this.setState({icnNames: false});
    }
  };

  _formatDOB(dob, format){
    let formatted = '';

    if(dob !== '' && dob){
      switch(format){
        case 'yyyy-mm-dd':
          const dobA = dob.split('/');
          formatted = dobA[2] + '-' + dobA[1] + '-' + dobA[0];
          break;
        case "dd/mm/yyyy":
          const dobB = dob.split('-');
          formatted = dobB[2] + '-' + dobB[1] + '-' + dobB[0];
          break;
      }
    }

    return formatted;
  }

  autosizingRenderInput = ({addTag, ...props}) => {
    let {onChange, value, ...other} = props;
    return (
      <AutosizeInput type='text' onChange={onChange} value={value} {...other} />
    )
  };

  _toggleEmail = () => {
    if(this.state.tags.length !== 0) {
      this.setState(prevState => ({emailToggle: !prevState.emailToggle}));
    } else {
      let language = this.props.user_account.language;
      const i18n = { en: { emailInvalid: 'Type an email' }, es: { emailInvalid: 'Escribe un correo electrónico' } }
      this.setState({spnEmail: true});
      this.refs.spnVEemail.innerHTML = '* ' + i18n[language].emailInvalid;
    }
  };

  _getCommunitySegments(){
    $.ajax({
      url: '/communities/get_segments/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      success: function (segments) {
        this.setState({segments});
      }.bind(this)
    });
  }
}

function defaultRenderTag (props) {
  let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props;
  //console.log(disabled);

  return (
    <span key={key} {...other} className={emailValidation(tag) ? 'react-tagsinput-tag' : 'react-tagsinput-tag-wrong'}>
      {getTagDisplayValue(tag)}
      <a className={classNameRemove} onClick={(e) => onRemove(key)} />
      {/*!disabled &&
      <a className={classNameRemove} onClick={(e) => onRemove(key)} />
      */}
    </span>
  )
}