import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dotdotdot from "react-dotdotdot";

export default class TransferModal extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    handleToggleTransfer: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      title: '',
      transferFlag: false,
      newOwner: 0
    };
  }

  componentDidMount() {

  }

  render() {
    const language = this.props.language;
    const I18n = {
      en: {
        transferConfirm: 'Transfer ownership of this Community?', transferWarning: 'This action cannot be undone',
        transferInfo: 'You won’t hold owner rights on this community anymore. You won’t be able to Close, Archive or Purge this community.',
        transferTo: 'Transfer Ownership To ...', transfer: 'Transfer', cancel: 'Cancel'
      },
      es: {
        transferConfirm: 'Transferir esta comunidad?', transferWarning: 'Esta acción será irreversible.',
        transferInfo: 'Perderás los derechos de propiedad de esta comunidad. No podrás cerrarla, archivarla o purgarla.',
        transferTo: 'Tranferir propiedad a ...', transfer: 'Transferir', cancel: 'Cancelar'
      }
    }
    return (
      <div className="my-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                <i onClick={this.props.handleToggleTransfer} className="material-icons">clear</i>
              </button>
            </div>
            <div className="modal-body px-modal-body-warning">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-title-warning-container">
                    <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning"/>
                    <p className="px-title-warning">
                      {I18n[language].transferConfirm}
                    </p>
                    <label className="px-message-warning">{I18n[language].transferWarning}</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xs-offset-1 col-sm-offset-1 col-md-offset-1 col-lg-offset-1"
                     style={{padding:'0'}}>
                  <div className="px-check-cont px-checkbox-modal-warning">
                    <label className="container-check">
                      <input type="checkbox" name="delActC1"/><span className="checkmark"/>
                      <span>{I18n[language].transferInfo}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-lg-offset-1 col-md-offset-1">
                  <div className="form-group">
                    <div className="px-select" >
                      <select>
                        <option value="">{I18n[language].transferTo}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer px-modal-footer-warning">
              <div className="px-footer-container-buttons column">
                <button onClick={this.transferCommunity} type="button" className="btn px-btn sm btn-blue-base">{I18n[language].transfer}</button>
                <button onClick={this.props.handleToggleTransfer} type="button" className="btn px-btn sm btn-gray-darker inverted">{I18n[language].cancel}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleBlur = (event) => {
    const username = event.target.value;

    if(username !== ''){
      $.ajax({
        url: '/users/username_validation',
        method: 'GET',
        dataType: 'JSON',
        data: {
          user: {username: username}
        },
        success: function (obj) {
          if(obj.msg){
            this.setState({transferFlag: true});
            $('#divUsername').addClass('has-success');
            this.setState({newOwner: obj.user_id});
          } else {
            this.setState({transferFlag: false});
            $('#divUsername').removeClass('has-success');
          }
        }.bind(this)
      });
    }
  };

  transferCommunity = () => {
    if(this.state.transferFlag){
      $.ajax({
        url: '/communities/transfer/' + this.props.communityId,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          community: {
            new_owner_id: this.state.newOwner
          }
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: () => {
          this.props.handleToggleTransfer();
          window.location.href = '/users/dashboard';
        }
      });
    }
  }
}