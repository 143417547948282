import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class AVTModal extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    required_options: PropTypes.array.isRequired,
    minChar: PropTypes.string.isRequired,
    validationResults: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    mapTitle: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {required_options, minChar, minAnnotations, validationResults, type, language, mapTitle} = this.props;

    const i18n = {
      en: {
        title: 'Something is missing!', subtitle: 'To complete this Task you need to:',
        map01: 'Select at least one word from the', map02: 'list', text01: 'Answer with some ', text02: 'text',
        video01: 'Upload a ', video02: 'video', char01: 'Type at least ', char02: 'character(s)',
        img01: 'Upload at least one ', img02: 'image', ans00: 'Select at least one response',
        micro00: 'You must have at least ', micro01: '3 responses', micro02: ' to the chat',
        annotation01: 'Make at least ', annotation02: 'annotation(s) on the image.'
      },
      es: {
        title: '¡Falta algo!', subtitle: 'Para completar esta tarea necesitas:',
        map01: 'Selecciona cuando menos una palabra del la lista', map02: '', text01: 'Responde con algo de ', text02: 'texto',
        video01: 'Sube un ', video02: 'video', char01: 'Escribe al menos ', char02: 'carácter(es)',
        img01: 'Sube al menos una ', img02: 'imagen', ans00: 'Selecciona al menos una respuesta',
        micro: 'Debes tener al menos ', micro01: '3 respuestas', micro02: ' al chat',
        annotation01: 'Haz cuando menos', annotation02: 'anotaciones sobre la imagen.'
      }
    };

    let labels = null;
    switch(type){
      case 'Open End':
      case 'Multimedia':
        labels = required_options.map((option, index) => {
          let msg = null;
          const result = validationResults.find(validation => validation.name === option);

          switch(option){
            case 'Text':
              msg = !result.value && <span>{i18n[language].text01}<span style={{fontWeight:'bold'}}>{i18n[language].text02}</span></span>;
              break;
            case 'Video':
              msg = !result.value && <span>{i18n[language].video01}<span style={{fontWeight:'bold'}}>{i18n[language].video02}</span></span>;
              break;
            case 'Characters':
              msg = !result.value && <span>{i18n[language].char01}
                <span style={{fontWeight:'bold'}}>{minChar} {i18n[language].char02}</span></span>;
              break;
            case 'Maps':
              msg = !result.value &&
                <span>{i18n[language].map01} <span style={{fontWeight:'bold'}}>{mapTitle[language]}&nbsp;
                  {i18n[language].map02}</span></span>;
              break;
            case 'Images':
              msg = !result.value && <span>{i18n[language].img01}<span style={{fontWeight:'bold'}}>{i18n[language].img02}</span></span>;
              break;
          }

          if(msg){
            return (
              <div key={index}>
                <label style={{ marginBottom: '0', color: '#000', fontSize: '12px' }}>{msg}</label><br />
              </div>
            )
          }
        });
        break;
      case 'Choice':
        labels = <div>
          <label style={{ marginBottom: '0', color: '#000', fontSize: '12px' }}>{i18n[language].ans00}</label><br />
        </div>;
        break;
      case 'Canvas':
        labels = required_options.map((option, index) => {
          let msg = null;
          const result = validationResults.find(validation => validation.name === option);
          switch(option){
            case 'minAnnotations':
              msg = !result.value && <span>{i18n[language].annotation01}
                <span style={{fontWeight:'bold'}}>{minAnnotations} {i18n[language].annotation02}</span></span>;
              break;
          }
          if(msg){
            return (
                <div key={index}>
                  <label style={{ marginBottom: '0', color: '#000', fontSize: '12px' }}>{msg}</label><br />
                </div>
            )
          }
        });
        break;
      case 'MicroChat':
        labels = <div>
          <label style={{ marginBottom: '0', color: '#000', fontSize: '12px' }}>
            {i18n[language].micro00}<span style={{fontWeight:'bold'}}>{i18n[language].micro01}</span>{i18n[language].micro02}
          </label><br />
        </div>
        break
    }

    return (
      <div className="modal-dialog" >
        <div className="modal-content">
          <div className="modal-header modal-delete-activity">
            <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
              <i onClick={this.props.updateShowModal} className="material-icons">clear</i>
            </button>
          </div>
          <div className="modal-body px-modal-body-warning">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <div className="px-title-warning-container">
                  <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning"/>
                  <p className="px-title-warning" style={{marginBottom: '0'}}>
                    {i18n[language].title}
                  </p>
                </div>
                <p className="delete-text"/>
                { labels }
              </div>
            </div>
          </div>
          <div className="modal-footer px-modal-footer-warning">
            <div className="px-footer-container-buttons column">
              <button onClick={this.props.updateShowModal} type="button" className="btn px-btn sm btn-blue-base">OK</button>
            </div>            
          </div>
        </div>
      </div>
    );
  }
}