import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"
import moment from 'moment';
import 'react-dates/initialize';
import {DateRangePicker} from "react-dates";
import {usernamesToDisplay} from "../../Community/shared_utils";

export default class SelectModal extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    modalType: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    executeUpdateModal: PropTypes.func.isRequired,
    objSearch: PropTypes.object,
    activeUsers: PropTypes.array,
    activities: PropTypes.array,
    activeLink: PropTypes.string,
    updateArrayChkModal: PropTypes.func,
    searchModal: PropTypes.func.isRequired,
    handleCalendarSelect: PropTypes.func,
    updateStartExpireDatesModal: PropTypes.func,
    segments: PropTypes.array,
    gender_as_segment: PropTypes.bool,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      q: '',
      filterAllNone: false,
      selectedIdActs: props.objSearch.idActs,
      selectedIdPars: props.objSearch.idPars,
      selectedIdSegs: props.objSearch.idSegs,
      selectedIdTags: props.objSearch.idTags,
      actionSelect: 'creation',
      startDate: null,
      endDate: null,
      showCalendarOpts: false,
      focusedInput: null,
    };
  }

  render() {
    const {modalType, language, activeUsers, objSearch, activeLink, colors} = this.props;

    const i18n = {
      en: {
        btn_save: 'Update', btn_cancel: 'Cancel', search: 'SEARCH', all: 'All', none: 'None', live: 'Live',
        available: 'Available', expired: 'Expired', creationDate: 'Creation Date', startDate: 'Start date',
        endDate: 'End date', tasks: 'tasks', participants: 'participants', activities: 'activities'
      },
      es: {
        btn_save: 'Actualizar', btn_cancel: 'Cancelar', search: 'BUSCAR', all: 'Todo', none: 'Nada', live: 'Live',
        available: 'Disponibles', expired: 'Expiradas', creationDate: 'Creación', startDate: 'Inicio',
        endDate: 'Expiración', tasks: 'tareas', participants: 'participantes', activities: 'actividades'
      }
    };

    let users = [];
    let activities = [];
    let segments = [];
    let tags = [];

    let checkedAll = false;
    let checkedNone = false;
    switch(modalType){
      case 'Participants':
        checkedAll = activeUsers.length === this.state.selectedIdPars.length;
        checkedNone = this.state.selectedIdPars.length === 0;

        users = activeUsers.map(user => {
          const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
          const mainName = availableUsernames[0];
          const alternativeNames = availableUsernames.length > 1 && availableUsernames.slice(1).join(', ')

          //console.log(this.props.from, this._getObjectModVis(this.props.from));
          if(user[this._getObjectModVis(activeLink)].isPar){
            const checked = this.state.selectedIdPars.find(id => user.id === id) !== undefined;
            //const checked = objSearch.idPars.find(id => user.id === id) !== undefined;

            return(
              <div key={user.id} className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
                <div className="px-check-cont mg-bottom-5">
                  <label className="container-check filters-modal img">
                    <input type="checkbox" onChange={() => this.updateSelectedObjsId(user, 'user', activeLink)}
                           checked={checked}/>
                    {/*<input type="checkbox" onChange={() => this.props.updateArrayChkModal(user, 'user')}
                           checked={checked}/>*/}
                    <span className="checkmark filters-modal"/>
                    <img src={user.avatar !== '' ? user.avatar : '/assets/user.png'} className="img-circle px-thumb-20" alt=""/>
                    <div className="user-data" style={{overflow: 'hidden'}}>
                      <div style={{fontSize: '12px', color: "#7C8593"}}
                           data-tooltip-content={alternativeNames ? `a.k.a:  ${alternativeNames}` : '' }>
                        {mainName}
                      </div>
                      {/*<div className="txt-email">{user.email}</div>*/}
                    </div>
                  </label>
                </div>
              </div>
            )
          }
        });
        break;
      case 'Activities':
        checkedAll = this.props.activities.length === this.state.selectedIdActs.length;
        //checkedNone = this.state.selectedIdActs.length === 0;

        activities = this.props.activities.map(activity => {
          if(activity[this._getObjectModVis(activeLink)].isAct){
            const checked = this.state.selectedIdActs.find(id => id === activity.id) !== undefined;

            return(
              <div key={activity.id} className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
                <div className="px-check-cont">
                  <label className="container-check filters-modal">
                    <input type="checkbox" onChange={() => this.updateSelectedObjsId(activity, 'activity', activeLink)}
                           checked={checked}/>
                    <span className="checkmark filters-modal"/>
                    <div className="user-data" style={{overflow: 'hidden'}}>
                      <div style={{fontSize: '12px', color: "#7C8593"}}
                           data-tooltip-content={`${activity.title} - ${activity.tasks.length} ${i18n[language].tasks}`}>
                        {activity.title}
                      </div>
                      {/*<div className="txt-email">{activity.tasks.length} {i18n[language].tasks}</div>*/}
                    </div>
                  </label>
                </div>
              </div>
            )
          }
        });
        break;
      case 'Segments':
        checkedAll = this.props.segments.length === this.state.selectedIdSegs.length;
        checkedNone = this.state.selectedIdSegs.length === 0;

        segments = this.props.segments.map(segment => {
          if(segment[this._getObjectModVis(activeLink)].isSeg){
            const checked =  this.state.selectedIdSegs.find(id => segment.id === id) !== undefined;
            let icon;

            switch(segment.name){
              case 'Female':
                icon = ['fal', 'venus'];break;
              case 'Male':
                icon = ['fal', 'mars'];break;
              case 'Other Gender':
                icon = ['fal', 'transgender'];break;
              default:
                icon = ['fas', 'bookmark'];
            }

            return(
              <div key={segment.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
                <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

                <div className="px-check-cont">
                  <label className="container-check filters-modal img">
                    <input type="checkbox" onChange={() => this.updateSelectedObjsId(segment, 'segment', activeLink)}
                           checked={checked}/>
                    {/*<input type="checkbox" onChange={() => this.props.updateArrayTask(segment, 'segment')}
                           checked={checked}/>*/}
                    <span className="checkmark filters-modal"/>
                    <FontAwesomeIcon icon={icon} className="tag icon" style={{color: segment.color}}/>
                    <div className="user-data" style={{overflow: 'hidden'}}>
                      <div style={{fontSize: '12px', color: "#7C8593"}}
                           data-tooltip-content={`${segment.name} - ${this._numberFilterSegment(segment.id, this.props.activeUsers)} ${i18n[language].participants}`}>
                        {segment.name}
                      </div>
                      {/*<div className="txt-email">*/}
                      {/*  {this._numberFilterSegment(segment.id, this.props.activeUsers)} {i18n[language].participants}*/}
                      {/*</div>*/}
                    </div>
                  </label>
                </div>
              </div>
            )
          }
        });
        break;
      case 'Tags':
        checkedAll = this.props.tags.length === this.state.selectedIdTags.length;
        checkedNone = this.state.selectedIdTags.length === 0;

        tags = this.props.tags.map(tag => {
          if(tag[this._getObjectModVis(activeLink)].isTag){
            const checked =this.state.selectedIdTags.find(id => tag.id === id) !== undefined;

            return(
              <div key={tag.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
                <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

                <div className="px-check-cont">
                  <label className="container-check filters-modal img">
                    <input type="checkbox" onChange={() => this.updateSelectedObjsId(tag, 'tag', activeLink)}
                           checked={checked}/>
                    {/*<input type="checkbox" onChange={() => this.props.updateArrayTask(tag, 'tag')}
                           checked={checked}/>*/}
                    <span className="checkmark filters-modal"/>
                    <FontAwesomeIcon icon={['fas','tag']} className="tag icon" style={{color: tag.color}}/>
                    <div className="user-data" style={{overflow: 'hidden'}}>
                      <div style={{fontSize: '12px', color: "#7C8593"}}
                           data-tooltip-content={`${tag.name} - ${this._numberFilterTag(tag.id, this.props.activities)} ${i18n[language].activities}`}>
                        {tag.name}
                      </div>
                      {/*<span className="txt-email">*/}
                      {/*  {this._numberFilterTag(tag.id, this.props.activities)} {i18n[language].activities}*/}
                      {/*</span>*/}
                    </div>
                  </label>
                </div>
              </div>
            )
          }
        });

        break;
    }

    let searchForm = <div className="form-group xs first" style={{width: '90%', flex: '0 0 auto'}}>
      <div className="px-input-icon">
        <input name="q" type="text" onChange={this.handleFilters}
               onKeyDown={(e) => this.keyPress(e, modalType)}
               className="form-control icon-right" placeholder={''} />
        <FontAwesomeIcon onClick={() => this.props.searchModal(modalType, this.state.q)} icon={['fas', 'magnifying-glass']}
                         className="px-icon-right color-gray-base" />
      </div>
    </div>;
    let rdoAll = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="All"
               checked={checkedAll}/>
        <span className="checkmark radio"/>
        <span style={{fontSize: '10px'}}>{i18n[language].all}</span>
      </label>
    </div>;
    let rdoNone = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="None"
               checked={this.state.filterAllNone === 'None'}/>
        <span className="checkmark radio"/>
        <span style={{fontSize: '10px'}}>{i18n[language].none}</span>
      </label>
    </div>;

    let rdoLive = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="Live"
               checked={this.state.filterAllNone === 'Live'}/>
        <span className="checkmark radio"/>
        <span style={{fontSize: '10px'}}>{i18n[language].live}</span>
      </label>
    </div>;

    let rdoAvailable = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="Available"
               checked={this.state.filterAllNone === 'Available'}/>
        <span className="checkmark radio"/>
        <span style={{fontSize: '10px'}}>{i18n[language].available}</span>
      </label>
    </div>;
    let rdoExpired = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="Expired"
               checked={this.state.filterAllNone === 'Expired'}/>
        <span className="checkmark radio"/>
        <span style={{fontSize: '10px'}}>{i18n[language].expired}</span>
      </label>
    </div>;

    let footerButtons = <div className="px-footer-container-buttons row">
      <button type="button" onClick={() => this.props.executeUpdateModal(activeLink, this._getArrayName(modalType),
        this._getArray(modalType))} className="btn px-btn sm btn-blue-base" style={{backgroundColor: colors.color1[3]}}>
        {i18n[language].btn_save}
      </button>
      <button type="button" onClick={() => this.props.updateShowModal(null, null)}
              className="btn px-btn sm btn-gray-darker inverted">{i18n[language].btn_cancel}</button>
    </div>;

    return (
      <div className="modal-content">
        <div className="modal-header">
          <button onClick={() => this.props.updateShowModal(null, null)} type="button"
                  className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
            <FontAwesomeIcon icon={['fal', 'xmark']} size="2x"/>
          </button>
          <h4 className="px-modal-title">{this._getTitle(modalType)}</h4>
        </div>

        <div className="modal-body px-modal-body-adv-filters">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-filter-adv-filter-modal-cont">
                {searchForm}
                <div className="px-right-container" style={{flex: '1 1 auto', marginTop: '10px', flexWrap: 'wrap'}}>
                  { rdoAll }
                  { rdoNone }
                  { rdoLive }
                  { modalType === 'Activities' && rdoAvailable }
                  { modalType === 'Activities' && rdoExpired }
                  {
                    (modalType === 'Activities' || modalType === 'Pick Required Activity') &&
                    <div className="px-check-cont rad">
                      <FontAwesomeIcon icon={['fal', 'calendar-days']} onClick={this.updateShowCalendarOpts}
                                       className='px-adv-filters-calendar'
                                       style={{cursor: 'pointer'}}/>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {
            this.state.showCalendarOpts &&
            <div className="px-date-filters">
              <div className="px-select">
                <select name="actionSelect" value={this.state.actionSelect} onChange={this.updateActionSelect}>
                  <option value="creation">{i18n[language].creationDate}</option>
                  <option value="start">{i18n[language].startDate}</option>
                  <option value="expiry">{i18n[language].endDate}</option>
                </select>
              </div>
              <div className="date-container">
                <DateRangePicker startDate={this.state.startDate ? moment(this.state.startDate) : null}
                                 startDateId="your_unique_start_date_id"
                                 endDate={this.state.endDate ? moment(this.state.endDate) : null}
                                 endDateId="your_unique_end_date_id"
                                 onDatesChange={({startDate, endDate}) => this.setState({startDate, endDate},
                                   () => this.showDates())}
                                 focusedInput={this.state.focusedInput}
                                 onFocusChange={focusedInput => this.setState({focusedInput})}
                                 minimumNights={0}
                                 displayFormat="DD/MM/YYYY"
                                 isOutsideRange={() => false}
                                 isDayHighlighted={day => day.isSame(moment(), 'd')}
                                 numberOfMonths={1}
                                 daySize={30}
                                 hideKeyboardShortcutsPanel={true}/>
                <FontAwesomeIcon icon={['far', 'xmark']} className="info-dialog"
                                 onClick={this.clearDates} style={{cursor:'pointer'}}/>
              </div>
            </div>
          }
          <div className="modal-scroll">
            <div className="row">
              {users}
              {segments}
              {activities}
              {tags}
            </div>
          </div>
        </div>

        <div className="modal-footer px-modal-footer-gray">
          {footerButtons}
        </div>
      </div>
    );
  }

  _numberFilterTag(tagId, activities){
    let counter = 0;

    activities.forEach(activity => {
      if(activity.tag){
        if(activity.tag.id === tagId){
          ++counter;
        }
      }
    });

    return counter
  }

  _numberFilterSegment(segmentId, activeUsers){
    //console.log(segmentId, activeUsers);
    let counter = 0;

    activeUsers.forEach(user => {
      user.segments.forEach(segment => {
        if(segmentId === segment.id){
          ++counter;
        }
      });

      if(this.props.gender_as_segment){
        if(segmentId === 'Other Gender'){
          if('Other' === user.gender){
            ++counter;
          }
        } else {
          if(segmentId === user.gender){
            ++counter;
          }
        }
      }
    });

    return counter;
  }

  clearDates = () => {
    this.setState({startDate: null, endDate: null});
  }

  showDates = () => {
    const startDate = this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : null;
    const endDate = this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null;

    if(startDate && endDate){
      this.setState({selectedIdActs: []}, () => {
        const selectedIdActs = [...this.state.selectedIdActs];

        // TO-DO the switch would be inside the forEach
        switch(this.state.actionSelect){
          case 'creation':
            this.props.activities.forEach(activity => {
              const createdAt = moment(activity.created_at).format('YYYY-MM-DD');
              //console.log(createdAt, startDate, endDate);
              if(createdAt >= startDate && createdAt <= endDate) {
                selectedIdActs.push(activity.id);
              }
            });
            break;
          case 'start':
            this.props.activities.forEach(activity => {
              if(activity.start){
                const taskStart = moment(activity.start).format('YYYY-MM-DD');

                if(taskStart >= startDate && taskStart <= endDate) {
                  selectedIdActs.push(activity.id);
                }
              }
            });
            break;
          case 'expiry':
            this.props.activities.forEach(activity => {
              if(activity.expire){
                const expireDate = moment(activity.expire).format('YYYY-MM-DD');

                if(expireDate >= startDate && expireDate <= endDate){
                  selectedIdActs.push(activity.id);
                }
              }
            });
            break;
        }

        this.setState({selectedIdActs});
      });
    }
    //this.props.updateStartExpireDatesModal(startDate, endDate);
  };

  updateActionSelect = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value});
  };

  updateShowCalendarOpts = () => {
    this.setState(state => ({showCalendarOpts: !state.showCalendarOpts}));
  };

  updateSelectedObjsId = (obj, objType, activeLink) => {
    let objSearch = null;

    switch(objType){
      case 'user':
        let selectedIdPars = [...this.state.selectedIdPars];
        const isFoundUser = selectedIdPars.find(id => id === obj.id);
        if(isFoundUser === undefined){
          selectedIdPars.push(obj.id);
        } else {
          selectedIdPars = selectedIdPars.filter(id => id !== obj.id);
        }
        this.setState({selectedIdPars});
        break;
      case 'activity':
        // It's the same logic as case: user.
        let selectedIdActs = [...this.state.selectedIdActs];
        const isFoundAct = selectedIdActs.find(id => id === obj.id);
        if(isFoundAct === undefined){
          selectedIdActs.push(obj.id);
        } else {
          selectedIdActs = selectedIdActs.filter(id => id !== obj.id);
        }
        this.setState({selectedIdActs});
        break;
      case 'segment':
        // It's the same logic as case: user.
        let selectedIdSegs = [...this.state.selectedIdSegs];
        const isFoundSeg = selectedIdSegs.find(id => id === obj.id);
        if(isFoundSeg === undefined){
          selectedIdSegs.push(obj.id);
        } else {
          selectedIdSegs = selectedIdSegs.filter(id => id !== obj.id);
        }
        this.setState({selectedIdSegs});
        break;
      case 'tag':
        let selectedIdTags = [...this.state.selectedIdTags];
        const isFoundTag = selectedIdTags.find(id => id === obj.id);
        if(isFoundTag === undefined){
          selectedIdTags.push(obj.id);
        } else {
          selectedIdTags = selectedIdTags.filter(id => id !== obj.id);
        }
        this.setState({selectedIdTags});
        break;
    }
  };

  handleFilters = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value}, () => {
      // console.log('this.state.filterAllNone:', this.state.filterAllNone);
      if(name === 'filterAllNone'){
        let objSearch = null;

        switch(this.props.modalType){
          case 'Participants':
            switch(this.state.filterAllNone){
              case 'All':
                objSearch = this.props.activeUsers.reduce((a, user) =>
                  (user[this._getObjectModVis(this.props.activeLink)].isPar && a.push(user.id), a), []);
                break;
              case 'None':
                objSearch = [];
                break;
            }
            //console.log(objSearch);
            this.setState({selectedIdPars: objSearch});
            break;
          case 'Activities':
            switch(this.state.filterAllNone){
              case 'All':
                objSearch = this.props.activities.reduce((a, act) =>
                  (act[this._getObjectModVis(this.props.activeLink)].isAct && a.push(act.id), a), []);
                break;
              case 'None':
                objSearch = [];
                break;
              case 'Live':
                objSearch = this.props.activities.filter(a => a.visibility === 'enabled').map(a => a.id);
                break;
              case 'Available':
                objSearch = [...new Set(this._setDates('Available', this.props.activities, this.props.activeLink).map(act => act.id))];
                break;
              case 'Expired':
                objSearch = this._setDates('Expired', this.props.activities, this.props.activeLink).map(act => act.id);
                break;
            }
            // console.log('objSearch:', objSearch);
            this.setState({selectedIdActs: objSearch});
            break;
          case 'Segments':
            // console.log(this.props.modalType, this.state.filterAllNone);
            switch(this.state.filterAllNone){
              case 'All':
                // console.log(this.props.segments, this._getObjectModVis(this.props.activeLink));
                objSearch = this.props.segments.reduce((a, segment) =>
                  (segment[this._getObjectModVis(this.props.activeLink)].isSeg && a.push(segment.id), a), []);
                break;
              case 'None':
                objSearch = [];
                break;
            }
            // console.log(objSearch);
            this.setState({selectedIdSegs: objSearch});
            break;
          case 'Tags':
            // console.log(this.props.modalType, this.state.filterAllNone);
            switch(this.state.filterAllNone){
              case 'All':
                objSearch = this.props.tags.reduce((a, tag) =>
                  (tag[this._getObjectModVis(this.props.activeLink)].isTag && a.push(tag.id), a), []);
                break;
              case 'None':
                objSearch = [];
                break;
            }
            // console.log(objSearch);
            this.setState({selectedIdTags: objSearch});
            break;
        }
      }
    });
  };

  // TO-DO: Fix Available logic
  _setDates(type, activities, activeLink){
    const filtered = [];
    const now = moment().format('YYYY-MM-DD');

    if(type === 'Available'){
      activities.forEach(activity => {
        if(activity[this._getObjectModVis(activeLink)].isAct){
          if(activity.expire) {
            const expireDate = moment(activity.expire).format('YYYY-MM-DD');

            if(now <= expireDate){
              filtered.push(activity);
            }
          } else {
            filtered.push(activity);
          }

          if(activity.start){
            const startDate = moment(activity.start).format('YYYY-MM-DD');

            if(now >= startDate){
              //TO-DO improve it, it selects available without to know start date
              //filtered.push(task);
            }
          } else {
            filtered.push(activity);
          }
        }
      });
    } else {
      activities.forEach(activity => {
        if(activity[this._getObjectModVis(activeLink)].isAct){
          if(activity.expire) {
            const expireDate = moment(activity.expire).format('YYYY-MM-DD');

            if(now > expireDate){
              filtered.push(activity);
            }
          } else {
            //filtered.push(task);
          }
        }
      });
    }
    //console.log(filtered);
    return filtered;
  }

  _getArray(modalType){
    let array = null;

    switch(modalType){
      case 'Activities': array = this.state.selectedIdActs;break;
      case 'Participants': array = this.state.selectedIdPars;break;
      case 'Segments': array = this.state.selectedIdSegs;break;
      case 'Tags': array = this.state.selectedIdTags;break;
    }

    return array;
  }

  _getArrayName(modalType){
    let name = null;

    switch(modalType){
      case 'Activities': name = 'idActs';break;
      case 'Participants': name = 'idPars';break;
      case 'Segments': name = 'idSegs';break;
      case 'Tags': name = 'idTags';break;
    }

    return name;
  }

  _getObjectModVis(activeLink){
    //console.log(activeLink);
    let name = null;

    switch(activeLink){
      case 'Activities': name = 'modAct';break;
      case 'Participants': name = 'modPar';break;
      case 'Matrix': name = 'modMat';break;
      case 'Now': name = 'modNow';break;
    }
    //console.log(name);
    return name;
  }

  // TO-DO use one single line return 'Pick ' + modalType
  _getTitle(modalType){
    let title = '';
    const language = this.props.language
    const i18n = {
      en: {
        titleParticipants: 'Pick participants', titleActivities: 'Pick activities',
        titleSegments: 'Pick segments', titleTags: 'Pick tags' },
      es: {
        titleParticipants: 'Selecciona participantes', titleActivities: 'Selecciona actividades',
        titleSegments: 'Selecciona segmentos', titleTags: 'Selecciona tags'
      }
    }

    switch(modalType){
      case 'Participants':
        title = i18n[language].titleParticipants;
        break;
      case 'Segments':
        title = i18n[language].titleSegments;
        break;
      case 'Activities':
        title = i18n[language].titleActivities;
        break;
      case 'Tags':
        title = i18n[language].titleTags;
        break;
    }

    return title;
  }

  keyPress = (event, modalType) => {
    if(event.keyCode === 13){
      this.props.searchModal(modalType, this.state.q)
    }
  };
}
