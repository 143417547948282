import PropTypes from 'prop-types';
import React from 'react';
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import ReactTags from 'react-tag-autocomplete';

export default class TBMEShare extends React.Component {
  static propTypes = {
    activeUsers: PropTypes.array.isRequired,
    community: PropTypes.object.isRequired,
    collaborators: PropTypes.array.isRequired,
    activeShare: PropTypes.bool.isRequired,
    objExport: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    copyToClipboard: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    let editorState;
    const currentUrl = window.location.href;
    const body = '<p>Hey check out this great insight at:</p><p>'+ currentUrl +'</p>';
    const contentBlock = htmlToDraft(body);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    editorState = EditorState.createWithContent(contentState);

    this.state = {
      tags: [],
      editorState,
      body: body,
      suggestions: this._setUsers([...props.collaborators, ...props.activeUsers])
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.url !== this.props.url){
      this.props.copyToClipboard(this.props.url);
    }
  }

  render() {
    const {url, language, colors} = this.props;

    const I18n = {
      en: {shareLink: 'Share this link'},
      es: {shareLink: 'Compartir este link'}
    }

    return (
      <div>
        {/* <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <label className="label-xs color-purple-base">Recipients</label>
            <div className="px-tagsinput-verbatim">
              <ReactTags
                tags={this.state.tags}
                suggestions={this.state.suggestions}
                handleDelete={this.handleDelete.bind(this)}
                handleAddition={this.handleAddition.bind(this)}
                autofocus={false}
                minQueryLength={1}
                placeholder="Add new recipient"/>
            </div>
          </div>
        </div> */}

        <div className="row mg-top-40">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <label className="label-xs" style={{color: colors.color1[2]}}>{I18n[language].shareLink}</label>
            <div className="bio-text-area-container">
              <textarea name="share" cols="30" rows="1" className="px-bio-text-area" defaultValue={url} readOnly/>
            </div>
            {/* <Editor
              editorState={this.state.editorState}
              onEditorStateChange={this.onEditorStateChange}
            /> */}
          </div>
        </div>
        {/* <div className="modal-footer px-modal-footer-warning">
          <div className="px-footer-container-buttons row">
            <button type="button" onClick={this.share} className="btn px-btn sm btn-blue-base">Send</button>
          </div>
        </div> */}
      </div>
    );
  }

  onEditorStateChange = editorState => {
    this.setState({ editorState });
    const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let bodyStr = body.toString();

    bodyStr = bodyStr.replace(/;width: /g, 'px;width: ');
    // TO TEST, it only works if the unique elements with closing tags are img
    // .i.e. <img />
    // It would be better with a more complex regex
    bodyStr = bodyStr.replace(/"\/>/g, 'px"/>');
    // To secure px is the right pattern
    bodyStr = bodyStr.replace(/pxpx/g, 'px');
    //console.log(bodyStr);
    //this.props.updateBody(bodyStr);
    this.setState({body: bodyStr});
  };

  share = () => {
    if(this.state.tags.length !== 0){
      this.state.tags.forEach(tag  => {
        $.ajax({
          url: '/pm/create_share',
          method: 'POST',
          dataType: 'JSON',
          data: {
            user_id_2: tag.id,
            community_id: this.props.community.id,
            body: this.state.body,
            url: window.location.href,
          },
          success: function (users) {

          }.bind(this)
        });
      });
    }
  };

  _setUsers(users){
    return users.map(user => ({id: user.user_id, name: user.username}));
  }

  handleDelete (i) {
    const tags = this.state.tags.slice(0);
    const suggestion = tags[i];
    tags.splice(i, 1);
    this.setState({ tags }, function () {
      const suggestions = [...this.state.suggestions];
      suggestions.push(suggestion);
      this.setState({ suggestions });
    });
  }

  handleAddition (tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags }, function () {
      const suggestions = [...this.state.suggestions];
      const newSuggestions = suggestions.filter(suggestion => suggestion.id !== tag.id);
      this.setState({suggestions: newSuggestions});
    })
  }
}
