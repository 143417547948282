import PropTypes from 'prop-types';
import React from 'react';

import Layout from "../../Layout";
//import Layout from "../../../Layout";
import PSActivity from "../../../containers/Community/Participant/PSActivity";
//import PSActivity from "../../../../containers/Community/Participant/PSActivity";
import Loading from "../../UI/Globals/Loading";
import {getParticipantToDoCounter} from "../../UI/Globals/PX_Funs";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";

export default class PCActivitySocialPage extends React.Component {
  static propTypes = {
    active_users: PropTypes.array.isRequired,
    tasks_filter: PropTypes.array.isRequired,
    task_id: PropTypes.number.isRequired,
    user_id: PropTypes.number.isRequired,
    entity: PropTypes.object.isRequired,
    scroll_id: PropTypes.number.isRequired,
    user_account: PropTypes.object.isRequired,
    sessionLang: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    task: PropTypes.object,
    notifications: PropTypes.array.isRequired,
    rooms: PropTypes.array.isRequired,
    communities: PropTypes.array.isRequired,
    item_scroll: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activeUsers: [],
      communitiesTop: [],
      notifications: [],
      rooms: [],
      toDoCounter: 0,
      openPMFromOutside: {username: '', userId: null, communityId: null, flag: false},
      isLoading: true,
      notificationsCounter: 0,
      isBeaconInit: false //Keeps track of any instances of Head Scout Beacons initialised.
    }
  }

  componentDidMount(){
    this._getActiveUser();
    //this._getTasks();
    this._getCommunitiesTop();
    this._getNotificationsCounter();
    //this._getNotifications();
    //this._getRooms();
  }

  render() {
    const {tasks_filter, task_id, user_id, entity, scroll_id, user_account, sessionLang, community, task,
      item_scroll} = this.props;
    const language = user_account.profile.language ? user_account.profile.language : sessionLang;
    const hsBeaconSuggestions = {
      en: ["5f201d792c7d3a10cbab6a1d", "5f201fed2c7d3a10cbab6a40", "5f200d8e04286306f8077d91", "5f1eb5b32c7d3a10cbab55bf", "5f1eb2b304286306f8076b86", "5f1eb7cf04286306f8076bce", "5f1eb5452c7d3a10cbab55b6", "5f1eb42704286306f8076b9e"],
      es: ["5f201d4d04286306f8077e37", "5f20207c2c7d3a10cbab6a4c", "5f200f2c2c7d3a10cbab695c", "5f1fae5604286306f8077a22", "5f1fb24a2c7d3a10cbab659d", "5f1fac852c7d3a10cbab656d", "5f1faf0304286306f8077a29", "5f1fac1c2c7d3a10cbab656b"]
    };

    return (
      <Layout user_account={user_account}
              profile={user_account.profile}
              sessionLang={sessionLang}
              notifications={this.state.notifications}
              from={'ParticipantActivityPage'}
              community={community}
              active_users={this.state.activeUsers}
              rooms={this.state.rooms}
              communities={this.state.communitiesTop}
              toDoCounter={this.state.toDoCounter}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              notificationsCounter={this.state.notificationsCounter}
              colors={user_account.all_colors}>
        {/*<HelpScoutBeacon beaconId='5ccf9719-22dd-436d-bb9c-5dd130b62052'*/}
        {/*  user_account={user_account}*/}
        {/*  colors={user_account.all_colors}*/}
        {/*  language={language}*/}
        {/*  isBeaconInit={this.state.isBeaconInit}*/}
        {/*  updateIsBeaconInit={this.updateIsBeaconInit} */}
        {/*  suggestions={hsBeaconSuggestions} */}
        {/*  hideFABOnDesktop={!this.props.user_account.profile.is_help}/>*/}
        {
          this.state.isLoading ?
            <Loading/> :
            <PSActivity active_users={this.state.activeUsers}
                        tasks_filter={tasks_filter}
                        task_id={task_id}
                        user_id={user_id}
                        entity={entity}
                        scroll_id={scroll_id}
                        language={language}
                        task={task}
                        currentUserId={user_account.id}
                        role_1={user_account.role_1}
                        item_scroll={item_scroll}
                        isLoading={this.state.isLoading}
                        communityId={community.id}
                        cmntyLanguage={community.language}  // Pass to Context
                        colors={user_account.all_colors}
            />
        }

      </Layout>
    );
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  _getActiveUser = () => {
    $.ajax({
      url: '/communities/social_get_active_part/' + this.props.community.id + '/activitySocial/' + this.props.user_id,
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: activeUsers =>  {
        console.log(activeUsers);
        this.setState({activeUsers, isLoading: false});
      }
    });
  };

  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log(communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };

  _getRooms = () => {
    $.ajax({
      url: '/communities/get_rooms/' + this.props.community.id,
      method: 'GET',
      success: rooms =>  {
        //console.log(rooms);
        this.setState({rooms});
      }
    });
  };

  _getNotifications = () => {
    $.ajax({
      url: '/notifications/get_notifications/-1',
      method: 'GET',
      success: notifications =>  {
        this.setState({notifications});
      }
    });
  };

  updateOpenPMFromOutside = (username, userId, communityId) => {
    console.log(username, userId, communityId);
    this.setState(state => ({openPMFromOutside: {username, userId, communityId, flag: !state.openPMFromOutside.flag}}));
  };

  _getTasks = () => {
    $.ajax({
      url: '/tasks/get/' + this.props.community.id + '/' + this.props.user_account.profile.user_id,
      method: 'GET',
      dataType: 'JSON',
      success: function (tasksDB) {
        //console.log(tasksDB);
        this.setState({toDoCounter: getParticipantToDoCounter(tasksDB)});
      }.bind(this)
    });
  };
}