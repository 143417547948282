import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setBlobToPreview} from "../../../../../UI/Globals/PX_Funs";
import {SortableHandle} from "react-sortable-hoc";

export default class ChAnswer extends React.Component {
  static propTypes = {
    questionId: PropTypes.number.isRequired,
    answer: PropTypes.object.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    deleteAnswer: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      text: props.answer.text
    }
  }

  componentDidMount(){
    if(this.props.answer.blob !== undefined) {
      this.refs.imgPreviewChAnswer.setAttribute('src', this.props.answer.blobPreview);
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.answer !== this.props.answer){
      if(this.props.answer.blob !== undefined) {
        this.refs.imgPreviewChAnswer.setAttribute('src', this.props.answer.blobPreview);
      }
      this.setState({text: this.props.answer.text});
    }
  }

  handleOnChange = event => {
    this.setState({text: event.target.value});
  };

  onBlur = () => {
    console.log('onBlur');
    const {questionId, answer} = this.props;
    this.props.updateAnswer(this.state.text, questionId, 'text', answer.id)
  };

  render() {
    const {questionId, answer, language} = this.props;
    const i18n = {
      en: {
        answerPlaceholder: 'Type answer here ...', addImageToTask: 'Add an image to this answer'
      },
      es: {
        answerPlaceholder: 'Escribe una respuesta aquí ...', addImageToTask: 'Agrega una imagen a esta respuesta.'
      }
    }

    let btnImgClass = 'btn-media have-image';
    let imgUrl = '';
    let imgStyle = null;
    //console.log(answer);
    if(answer.blob !== undefined){

    } else {
      if(answer.img !== ''){
        imgUrl = answer.img;
        imgStyle = {display: 'block'};
      } else {
        btnImgClass = 'btn-media';
        imgStyle = {display: 'none'};
      }
    }

    return (
      <div className="px-answer-actv-builder">
        <DragHandle/>
        <div className="px-btn-media-container">
          <button onClick={() => this.props.updateShowModal('imageAnswer', questionId, answer.id)} className="px-btn-media" data-tooltip-content={i18n[language].addImageToTask}>
            {
              answer.img === '' && answer.blob === undefined ? <FontAwesomeIcon icon={['fas', 'image']}/> : null
            }
            <img ref='imgPreviewChAnswer' src={imgUrl} alt="" style={imgStyle}/>
          </button>
          {
            (answer.img !== '' || answer.blob !== undefined) &&
            <div onClick={() => this.props.deleteMedia(questionId, 'image', answer.id)}
                 className="btn-delete btn-close-float" style={{cursor:'pointer',top:'-10px',left:'-10px'}}>
              <div className="btn-container">
                <FontAwesomeIcon icon={['fas','xmark']}/>
              </div>
            </div>
          }
        </div>
        <input onChange={this.handleOnChange} value={this.state.text}
               type="text" className="input-answer" placeholder={i18n[language].answerPlaceholder} readOnly={answer.img === undefined}
               onBlur={this.onBlur}/>
        {/*<input onChange={(event) => this.props.updateAnswer(event, questionId, 'text', answer.id)} value={answer.text}
               type="text" className="input-answer" placeholder="Type answer here ..." readOnly={answer.img === undefined}/>*/}
        <FontAwesomeIcon onClick={() => this.props.deleteAnswer(questionId, answer.id)} icon={['fas','circle-xmark']}
                         className="remove-icon" style={{cursor:'pointer'}}/>
      </div>
    );
  }
}

const DragHandle = SortableHandle(() => <FontAwesomeIcon icon={['far','bars']} className="px-drag"
                                                         style={{cursor:'move'}}/>);