import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import isBeforeDay from "react-dates/esm/utils/isBeforeDay";
import { Tooltip as ReactTooltip } from "react-tooltip"


export default class ModalQuote extends React.Component {
  static propTypes = {
    addCommunity: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    updateShowQuote: PropTypes.func.isRequired,
    setFalseToShowQuote: PropTypes.func.isRequired,
    num_participants: PropTypes.number.isRequired,
    getCost: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    updateDates: PropTypes.func.isRequired,
    platform: PropTypes.string.isRequired,
    blocking: PropTypes.bool.isRequired,
    subs: PropTypes.string.isRequired,
    cash: PropTypes.string.isRequired,
    papt: PropTypes.number.isRequired,
    sumSubs: PropTypes.number.isRequired,
    sumCash: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    estimated_cost: PropTypes.number.isRequired,
    priceUnitPD: PropTypes.number.isRequired,
    colors: PropTypes.object.isRequired,
    flagNumParts: PropTypes.bool.isRequired,
    subscription: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    default_tz_flag: PropTypes.bool.isRequired,
    default_tz: PropTypes.string.isRequired,
    createTermsPDF: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    handleNumParts: PropTypes.func.isRequired,
    calculatorFlag: PropTypes.number.isRequired,
    changeAiPlanState: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      chk01: true,
      chk02: true,
      chk03: true,
      chk04: false,
      aiPlan00: false,
      aiPlan01: false,
      aiPlan02: false
    }
    this.handleAiPlan = this.handleAiPlan.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { num_participants, participants_end, participants_start } = this.props;

    if (
        prevProps.num_participants !== num_participants ||
        prevProps.participants_end !== participants_end ||
        prevProps.participants_start !== participants_start
    ) {
      this.updateQuote();
    }
  }


  render() {
    const {language, num_participants, participants_start, participants_end, blocking, sumSubs, sumCash, loading,
      estimated_cost, priceUnitPD, colors, flagNumParts, subscription, from, calculatorFlag} = this.props;

    const i18n = {
      en: {
        quoteTitle: 'Estimated cost', create: 'Create', close: 'Close', numParts: 'Participants',
        partsAccess: 'Participants access', maxParts: '50 participants max. Contact support.',
        platform: 'Platform license estimate', settle: 'Settled with', payment: 'Balance to pay',
        partsFor: ' Participants ', days: ' days', priceFor: 'Standard License Cost for ', subsSlot: ' subscription slots per day',
        flex: ' Flexdays', remaining: ' Balance to pay',
        read01: 'I\'ve read and accept the terms and conditions of this ', read02: ' SAAS Agreement',
        numPartsWarn: 'Enter a number between 10 and 100', participantDays: 'Participant*days',
        recalculatePrice: 'Calculate price', aiFeatures: 'AI Features',
        essential: 'Power', advanced: 'Turbo (text-only)', pro: 'Nitro (text and images)',
        enableEssentialAi: 'Essential AI features',
        enableAdvancedAi: 'Premium AI features for text',
        enableProAi: 'Premium AI features for text and images',
        ai_essential_price_disclaimer: '+ US$0.4 for each participant/day',
        ai_advanced_price_disclaimer: '+ US$1.2 for each participant/day',
        ai_pro_price_disclaimer: '+ US$2.2 for each participant/day',
        ai_essential_info: 'Video transcription + AI handling of text responses for the use of WonderChat.',
        ai_advanced_info: 'Video transcription + AI handling of text responses for auto-moderation, WonderChat and MicroChat tasks (x2).',
        ai_pro_info: 'Video transcription + AI handling of text and image responses for auto-moderation, WonderChat and MicroChat tasks (x4).',
        betaWarning: 'Work in progress: You may encounter occasional errors as we continue to improve this feature',
        consider: 'Also, read our ', and: 'and our ',
        termsOfService: 'Terms of Service ', privacyPolicy: 'Privacy Policy'
      },
      es: {
        quoteTitle: 'Costo estimado', create: 'Crear', close: 'Cerrar', numParts: 'Participantes',
        partsAccess: 'Acceso a participantes', maxParts: 'Máximo 50 participantes. Contactar Soporte.',
        platform: 'Estimación de licencia de plataforma', settle: 'Liquidado con', payment: 'Balance a pagar',
        partsFor: ' Participantes ', days: ' días', priceFor: 'Costo Licencia Estándar para ', subsSlot: ' slots de suscripción por día',
        flex: ' Flexdays', remaining: ' Saldo a pagar',
        read01: 'He leído y acepto los términos y condiciones de este ', read02: ' Contrato',
        numPartsWarn: 'Ingresar número entre 10 y 100', participantDays: 'Días*participante',
        recalculatePrice: 'Calcular precio', aiFeatures: 'Funciones AI',
        essential: 'Power', advanced: 'Turbo (solo texto)', pro: 'Nitro (texto e imágenes)',
        enableEssentialAi: 'Funciones AI esenciales',
        enableAdvancedAi: 'Funciones AI premium para texto',
        enableProAi: 'Funciones AI premium para texto e imágenes',
        ai_essential_price_disclaimer: '+ US$0.4 por día/participante',
        ai_advanced_price_disclaimer: '+ US$1.2 por día/participante',
        ai_pro_price_disclaimer: '+ US$2.2 por día/participante',
        ai_essential_info: 'Transcripción de video + Procesamiento en AI de respuestas en texto incluyendo WonderChat.',
        ai_advanced_info: 'Transcripción de video + Procesamiento en AI de respuestas en texto incluyendo Auto-moderación, WonderChat y tareas MicroChat (x2).',
        ai_pro_info: 'Transcripción de video + Procesamiento en AI de respuestas en texto e imágenes incluyendo Auto-moderación, WonderChat y tareas MicroChat (x4).',
        betaWarning: 'En progreso: Puedes encontrar errores ocasionales mientras mejoramos esta función',
        consider: 'Lea también nuestros ', and: 'y nuestras ',
        termsOfService: 'Términos de Servicio ', privacyPolicy: 'Políticas de Privacidad'
      }
    };

    let days = 0;
    if(participants_start && participants_end && num_participants !== '') {
      days = moment(participants_end).add(1, 'days').diff(moment(participants_start), 'days');
    }
    //console.log('priceUnitPD', priceUnitPD);
    const totalPD = num_participants * days;

    const flagCreate = blocking || num_participants === 0 || num_participants === '' ||
      participants_start === null || participants_end === null || !this.state.chk04 || !(calculatorFlag >= 1);
    const flagTerms = blocking || num_participants === 0 || num_participants === '' ||
      participants_start === null || participants_end === null || !(calculatorFlag >= 1);

    let flexDaysPD = 0;
    let finalSumCashPD = sumCash;

    if(sumCash > 0 && subscription.flex_days > 0){
      const initCashPD = totalPD - sumSubs;
      const initFlexDaysPD = initCashPD - subscription.flex_days ;

      flexDaysPD = initFlexDaysPD < 0 ? initCashPD : subscription.flex_days;
      finalSumCashPD = sumCash - subscription.flex_days < 0 ? 0 : sumCash - subscription.flex_days;
    }

    let subsPrice;

    if (this.state.aiPlan02) {
      const standardPrice = (estimated_cost - (sumSubs * priceUnitPD) - (flexDaysPD * priceUnitPD)).toFixed(2);
      const extraAdvancedAi = (totalPD * 2.2).toFixed(2);
      subsPrice = (parseFloat(standardPrice) + parseFloat(extraAdvancedAi)).toFixed(2);
    } else if (this.state.aiPlan01) {
      const standardPrice = (estimated_cost - (sumSubs * priceUnitPD) - (flexDaysPD * priceUnitPD)).toFixed(2);
      const extraAdvancedAi = (totalPD * 1.2).toFixed(2);
      subsPrice = (parseFloat(standardPrice) + parseFloat(extraAdvancedAi)).toFixed(2);
    } else if (this.state.aiPlan00) {
      const standardPrice = (estimated_cost - (sumSubs * priceUnitPD) - (flexDaysPD * priceUnitPD)).toFixed(2);
      const extraAdvancedAi = (totalPD * 0.4).toFixed(2);
      subsPrice = (parseFloat(standardPrice) + parseFloat(extraAdvancedAi)).toFixed(2);
    }
    else {
      subsPrice = (estimated_cost - (sumSubs * priceUnitPD) - (flexDaysPD * priceUnitPD)).toFixed(2);
    }

    return(
      <div className="modal-content">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" place="top" />
        <div className="modal-header">
          <FontAwesomeIcon icon={['fas', 'circle-arrow-left']} onClick={this.props.updateShowQuote}
                           style={{cursor:'pointer', color:'#d8d8d8'}} size='2x'/>
          <button onClick={this.props.setFalseToShowQuote} type="button"
                  className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal" data-dismiss="modal"
                  aria-hidden="true">
            <i className="material-icons">clear</i>
          </button>
          <h4 className="px-modal-title">{i18n[language].quoteTitle}</h4>
        </div>
        <div className="modal-body px-modal-body-add-activity">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
              <div className="row">
                <div className="col-xs-5 col-sm-5 col-md-5 mg-bottom-0">
                  <div className="form-group">
                    <label className="label-sm" style={{color: colors.color0[3]}}>{i18n[language].numParts}</label>
                    <input name="num_participants" onChange={this.props.handleNumParts} onBlur={this.handleQuotingBlur}
                           defaultValue={num_participants || ''}
                           type="number" className="form-control" disabled={loading}/>
                    {
                      flagNumParts &&
                        <span className='px-label-warning' style={{fontSize: '11px', color: colors.color4[3]}}>
                          {i18n[language].numPartsWarn}
                        </span>
                    }
                  </div>
                </div>
                <div className="col-xs-7 col-sm-7 col-md-7 mg-bottom-0">
                  <div className="form-group">
                    <label className="label-sm"
                           style={{color: colors.color0[3]}}>{i18n[language].partsAccess}</label><br></br>
                    <DateRangePicker
                      startDate={participants_start ? moment(participants_start) : null} // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id"
                      endDate={participants_end ? moment(participants_end) : null} // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id"
                      onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate },
                        () => this.showDates())} // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                      minimumNights={0}
                      displayFormat="DD/MM/YYYY"
                      isOutsideRange={day => !this.isInclusivelyAfterDay(day, moment())}
                      isDayHighlighted={ day => day.isSame( moment(), 'd' ) }
                      numberOfMonths={1}
                      daySize={27}
                      hideKeyboardShortcutsPanel={true}
                      startDatePlaceholderText={'From'}
                      endDatePlaceholderText={'To'}
                    />
                  </div>
                </div>
                {/* <div className="col-xs-2 col-sm-2 col-md-2 mg-bottom-15" style={{paddingTop:'25px',textAlign:'center'}}>
                  <div className="form-group">
                    <span type="button" onClick={this.props.getCost} disabled={loading} style={{ fontSize: '26px', cursor: 'pointer', color: colors.color1[3] }}>
                      <FontAwesomeIcon icon={['far','calculator']}/>
                    </span>
                  </div>
                </div> */}
              </div>
              {/*<div className='row'>*/}
              {/*  <div className='col-xs-12 col-sm-12 col-md-12 mg-bottom-0 mg-top-20'>*/}
              {/*    <div onClick={this.props.getCost} disabled={loading} className="mg-bottom-10"*/}
              {/*          style={{ color: colors.color1[3], padding: '4px', textTransform: 'uppercase', fontSize: '12px',*/}
              {/*            cursor: 'pointer', fontFamily: 'Sharp Sans Bold', letterSpacing: '1.2px', border: '3px solid',*/}
              {/*            borderRadius: '4px', textAlign: 'center', fontWeight: 'bold',*/}
              {/*            display: !flagNumParts &&*/}
              {/*                      this.props.participants_end &&*/}
              {/*                      this.props.participants_start ? 'block' : 'none'}}>*/}
              {/*      {i18n[language].recalculatePrice}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="row" style={{marginTop: '15px'}}>
                <div className="col-xs-12 col-sm-12 col-md-12 mg-bottom-15" style={{
                  backgroundColor: colors.color0[3],
                  borderRadius: '10px',
                  paddingLeft: '0',
                  paddingRight: '0',
                  paddingBottom: '10px',
                  paddingTop: '10px'
                }}>
                  <div className="col-xs-6 col-sm-6 col-md-6 px-col-price"
                       style={{textAlign: 'center', paddingLeft: '0', paddingRight: '0'}}>
                    <p>{i18n[language].payment}</p>
                    {/* <p>
                      <span style={{textDecoration: 'underline'}}>{num_participants}</span>{i18n[language].partsFor}
                      <span style={{textDecoration: 'underline'}}>{days}</span>{i18n[language].days}
                    </p> */}
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6 px-col-price"
                       style={{textAlign: 'center', paddingLeft: '0', paddingRight: '0'}}>
                    <p style={{fontSize: '20px', textAlign: 'center', marginBottom: '0'}}>
                      {loading ? <FontAwesomeIcon icon={['fas', 'spinner']} spin/> : `US$ ${subsPrice}`}
                    </p>
                    {/* <p style={{fontSize: '10px', textAlign: 'center'}}>
                      { loading ? <FontAwesomeIcon icon={['fas','spinner']} spin/> : `${totalPD} p*d` }
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-6">
                  <p style={{color: colors.color0[3], textTransform: 'uppercase', fontWeight: 'bold'}}>
                    {i18n[language].priceFor} <span
                    style={{textDecoration: 'underline'}}>{num_participants}</span>{i18n[language].partsFor} X <span
                    style={{textDecoration: 'underline'}}>{days}</span>{i18n[language].days}
                  </p>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6">
                  <p style={{
                    color: colors.color0[3],
                    fontWeight: 'bold',
                    marginBottom: '0',
                    fontSize: '18px',
                    textAlign: 'center'
                  }}>
                    {loading ? <FontAwesomeIcon icon={['fas', 'spinner']} spin/> : `US$ ${estimated_cost.toFixed(2)}`}
                  </p>
                  <p style={{
                    fontSize: '10px',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    marginBottom: '0'
                  }}>
                    <span style={{color: '#909b9e'}}>{totalPD} {i18n[language].participantDays}</span>
                  </p>
                </div>
              </div>

              <hr style={{marginBottom: '5px'}}></hr>


              <div className='row'>
                <div className="col-md-12">
                  <div className="">
                    <label className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].aiFeatures}</label>
                    <br/>
                    <div className="px-notifications-container">
                      <div className="notifications-item" style={{marginBottom: 0}}>
                        <span className="title-header" style={{
                          marginRight: '5px',
                          fontFamily: 'Sharp Sans ExtraBold',
                          textTransform: 'uppercase',
                          fontWeight: 'bold'
                        }}>{i18n[language].essential}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                         data-tooltip-content={i18n[language].ai_essential_info}/>
                        <span className="title-header"
                              style={{marginLeft: '5px'}}>{i18n[language].ai_essential_price_disclaimer}</span>
                        <div className="switch-container">
                          <label className="px-switch" htmlFor="aiPlan00" style={{marginTop: '5px'}}>
                            <input name="ai_plan_0" id="aiPlan00" type="checkbox"
                                   onChange={this.handleAiPlan} checked={this.state.aiPlan00}/>
                            <div className="slider round"
                                 style={this.state.aiPlan00 ? {backgroundColor: colors.color3[3]} : {}}/>
                          </label>
                        </div>
                      </div>
                      <div className="notifications-item" style={{marginBottom: 0}}>
                        <span className="title-header" style={{
                          marginRight: '5px',
                          fontFamily: 'Sharp Sans ExtraBold',
                          textTransform: 'uppercase',
                          fontWeight: 'bold'
                        }}>{i18n[language].advanced}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                         data-tooltip-content={i18n[language].ai_advanced_info}/>
                        <span className="title-header"
                              style={{marginLeft: '5px'}}>{i18n[language].ai_advanced_price_disclaimer}</span>
                        <div className="switch-container">
                          <label className="px-switch" htmlFor="aiPlan01" style={{marginTop: '5px'}}>
                            <input name="ai_plan_1" id="aiPlan01" type="checkbox"
                                   onChange={this.handleAiPlan} checked={this.state.aiPlan01}/>
                            <div className="slider round"
                                 style={this.state.aiPlan01 ? {backgroundColor: colors.color3[3]} : {}}/>
                          </label>
                        </div>
                      </div>
                      <div className="notifications-item" style={{marginBottom: 0}}>
                        {/*<span style={{*/}
                        {/*  borderRadius: '4px',*/}
                        {/*  backgroundColor: colors.color0[3],*/}
                        {/*  padding: '2px 6px',*/}
                        {/*  textAlign: 'center',*/}
                        {/*  color: '#ffffff',*/}
                        {/*  fontSize: '10px',*/}
                        {/*  fontFamily: 'Sharp Sans ExtraBold',*/}
                        {/*  marginRight: '4px',*/}
                        {/*  cursor: 'default'*/}
                        {/*}}*/}
                        {/*      data-tooltip-content={i18n[language].betaWarning}>*/}
                        {/*  BETA*/}
                        {/*</span>*/}

                        <span className="title-header" style={{
                          marginRight: '5px',
                          fontFamily: 'Sharp Sans ExtraBold',
                          textTransform: 'uppercase',
                          fontWeight: 'bold'
                        }}>{i18n[language].pro}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                         data-tooltip-content={i18n[language].ai_pro_info}/>
                        <span className="title-header"
                              style={{marginLeft: '5px'}}>{i18n[language].ai_pro_price_disclaimer}</span>
                        <div className="switch-container">
                          <label className="px-switch" htmlFor="aiPlan02" style={{marginTop: '5px'}}>
                            <input name="ai_plan_2" id="aiPlan02" type="checkbox"
                                   onChange={this.handleAiPlan} checked={this.state.aiPlan02}/>
                            <div className="slider round"
                                 style={this.state.aiPlan02 ? {backgroundColor: colors.color3[3]} : {}}/>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{marginBottom: '5px', marginTop: '5px'}}></hr>

              <div className="row">
                <div className="col-md-12">
                  <label className="label-sm"
                         style={{color: colors.color0[3], marginBottom: '15px'}}>{i18n[language].settle}:</label>
                  {/* <p style={{color:'#656df7', textTransform: 'uppercase', fontWeight: 'bold'}}>{i18n[language].settle}</p> */}
                </div>
              </div>


              {
                subscription.name !== 'No Subs' &&
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-xs-7 col-sm-7 col-md-7"
                         style={{paddingLeft: '0', paddingRight: '0', fontSize: '12px'}}>
                      <input name="chk01" type="checkbox" checked={sumSubs !== 0} onChange={this.handleChange}
                             disabled/>&nbsp;{subscription.papt}
                      <span className="checkmark filters-modal"/>{i18n[language].subsSlot}
                    </div>
                    <div className="col-xs-5 col-sm-5 col-md-5" style={{textAlign: 'center'}}>
                      <p style={{fontWeight: 'bold', marginBottom: '0'}}>
                        (US$ {(sumSubs * priceUnitPD).toFixed(2)})
                      </p>
                      <p style={{
                        fontSize: '10px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        marginBottom: '0',
                        color: '#909b9e'
                      }}>
                        {loading ? <FontAwesomeIcon icon={['fas', 'spinner']}
                                                    spin/> : `${sumSubs} ${i18n[language].participantDays}`}
                      </p>
                    </div>
                  </div>
                </div>
              }
              <div className="row">
                <div className="col-md-12">
                  <div className="col-xs-7 col-sm-7 col-md-7"
                       style={{paddingLeft: '0', paddingRight: '0', fontSize: '12px'}}>
                    <input name="chk02" type="checkbox" checked={flexDaysPD !== 0} onChange={this.handleChange}
                           disabled/>&nbsp;{subscription.flex_days}
                    <span className="checkmark filters-modal"/>{i18n[language].flex}
                  </div>
                  <div className="col-xs-5 col-sm-5 col-md-5" style={{textAlign: 'center'}}>
                    <p style={{fontWeight: 'bold', marginBottom: '0'}}>
                      (US$ {(flexDaysPD * priceUnitPD).toFixed(2)})
                    </p>
                    <p style={{
                      fontSize: '10px',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      marginBottom: '0',
                      color: '#909b9e'
                    }}>
                      {loading ? <FontAwesomeIcon icon={['fas', 'spinner']}
                                                  spin/> : `${flexDaysPD} ${i18n[language].participantDays}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-xs-7 col-sm-7 col-md-7"
                       style={{paddingLeft: '0', paddingRight: '0', fontSize: '12px'}}>
                    <input name="chk03" type="checkbox" checked={parseInt(subsPrice) !== 0} onChange={this.handleChange}
                           disabled/>
                    <span className="checkmark filters-modal"/>{i18n[language].remaining}
                  </div>
                  <div className="col-xs-5 col-sm-5 col-md-5" style={{textAlign: 'center'}}>
                    <p style={{fontWeight: 'bold', marginBottom: '0', textAlign: 'center'}}>
                      US$ {subsPrice}
                    </p>
                    <p style={{
                      fontSize: '10px',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      marginBottom: '0',
                      color: '#909b9e'
                    }}>
                      {loading ? <FontAwesomeIcon icon={['fas', 'spinner']}
                                                  spin/> : `${finalSumCashPD} ${i18n[language].participantDays}`}
                    </p>
                  </div>
                </div>
              </div>
              {!flagTerms &&
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-2"/>
                    <div className="col-md-8" style={{
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      textAlign: 'center',
                      marginTop: '10px'
                    }}>
                      <input name="chk04" type="checkbox" checked={this.state.chk04} onChange={this.handleChange}
                             style={{cursor: 'pointer', verticalAlign: 'top'}}/>&nbsp;
                      <span style={{fontFamily: 'Sharp Sans ExtraBold'}}>{i18n[language].read01}</span>
                      <span onClick={flagTerms ? () => {
                      } : () => this.props.createTermsPDF('view', null, flexDaysPD)}
                            style={{
                              fontFamily: 'Sharp Sans ExtraBold',
                              color: flagTerms ? '#333' : colors.color1[3],
                              cursor: flagTerms ? 'default' : 'pointer'
                            }}>
                        {i18n[language].read02}
                      </span>
                    </div>
                    <div className="col-md-2"/>
                  </div>
                </div>
              }

              {!flagTerms &&
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-2"/>
                    <div className="col-md-12" style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                      textAlign: 'center',
                      marginTop: '10px'
                    }}>
                      <span>{i18n[language].consider}</span>
                      <span onClick={() => window.open('https://www.pixiebob.app/pages/terms_of_service?language=en',
                        '_blank', 'noopener,noreferrer')}
                            style={{
                              color: colors.color1[3],
                              cursor: 'pointer'
                            }}>
                        {i18n[language].termsOfService}
                      </span>
                      <span>{i18n[language].and}</span>
                      <span onClick={() => window.open('https://www.pixiebob.app/pages/privacy_policy?language=en',
                        '_blank', 'noopener,noreferrer')}
                            style={{
                              color: colors.color1[3],
                              cursor: 'pointer'
                            }}>
                        {i18n[language].privacyPolicy}
                      </span>
                    </div>
                    <div className="col-md-2"/>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
        <div className="modal-footer px-modal-footer-gray">
          <div className="px-footer-container-buttons row">
            {
              from === 'AddCommunity' ?
                <React.Fragment>
                  <div className="row">
                    <button ref="btnCreateCommunity" type="button" className="btn px-btn sm btn-blue-base"
                            onClick={() => this.props.addCommunity(flexDaysPD)} disabled={flagCreate}
                            style={{backgroundColor: colors.color1[3]}}>
                      {i18n[language].create}
                    </button>
                    <button onClick={this.props.setFalseToShowQuote} type="button"
                            className="btn px-btn sm btn-gray-darker inverted"
                            data-dismiss="modal">{i18n[language].close}</button>
                  </div>
                </React.Fragment> : ''
            }
          </div>
        </div>
      </div>
    )
  }
  sumCalculatorFlag = () => {

  };

  handleChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;
    //console.log(name, value);
    this.setState({[name]: value})
  }

  handleAiPlan(event) {
    const { name, checked } = event.target;

    let newState = {};

    this.setState(prevState => {
      if (name === 'ai_plan_0') {
        // If ai_plan_0 is being turned off and ai_plan_1 is on, turn ai_plan_1 off as well
        if (!checked && (prevState.aiPlan01 || prevState.aiPlan02)) {
          newState.aiPlan00 = false;
          newState.aiPlan01 = false;
          newState.aiPlan02 = false;
        } else {
          // Otherwise, just toggle ai_plan_0
          newState.aiPlan00 = checked;
        }
      } else if (name === 'ai_plan_1') {
        // If ai_plan_1 is being turned on, turn ai_plan_0 on as well
        if (checked) {
          newState.aiPlan00 = true;
          newState.aiPlan01 = true;
        } else {
          // If ai_plan_1 is being turned off, no changes are made to ai_plan_0 but ai_plan_2 must be off
          newState.aiPlan01 = false;
          newState.aiPlan02 = false;
        }
      } else if (name === 'ai_plan_2') {
        // If ai_plan_2 is being turned on, turn ai_plan_0 and turn ai_plan_1 on as well
        if (checked) {
          newState.aiPlan00 = true;
          newState.aiPlan01 = true;
          newState.aiPlan02 = true;
        } else {
          // If ai_plan_2 is being turned off, no changes are made to ai_plan_0 or ai_plan_1
          newState.aiPlan02 = false;
        }
      }
      return newState;
    }, () => {
      // This is a callback that gets executed after setState is completed
      let aiStateArg = '';
      if (this.state.aiPlan02) {
        aiStateArg = 'aiPlan02';
      } else if (this.state.aiPlan01) {
        aiStateArg = 'aiPlan01';
      } else if (this.state.aiPlan00) {
        aiStateArg = 'aiPlan00';
      }
      this.props.changeAiPlanState(aiStateArg);
    });
  }

  updateQuote = event => {
    if (
        this.props.num_participants && this.props.num_participants !== "" &&
        this.props.participants_start && this.props.participants_start !== "" &&
        this.props.participants_end && this.props.participants_end !== ""
    ) {
      this.props.getCost();
    }
  }


  isInclusivelyAfterDay = (a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return !isBeforeDay(a, b);
  }

  // Maybe onDatesChange just needs to send the dates, but this.state is to show dates in the calendar
  showDates = () => {
    const startDate = this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : null;
    const endDate = this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null;

    this.props.updateDates(startDate, endDate);
    // if (startDate && endDate) {
    //   this.props.getCost();
    // }
  };
}
