import PropTypes from 'prop-types';
import React from 'react';
import Slider from "rc-slider";
import TagsInput from "react-tagsinput";
import Tooltip from "rc-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

export default class TR_PopupVerbatim extends React.Component {
  static propTypes = {
    response: PropTypes.object.isRequired,
    updateSizeResponseSlider: PropTypes.func.isRequired,
    updateResponseKeywords: PropTypes.func.isRequired,
    autosizingRenderInput: PropTypes.func.isRequired,
    updateSizeResponse: PropTypes.func.isRequired,
    hideMenuAndRegenerate: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    handlePopupVerbatim: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {response, colors, language} = this.props;
    const wrapperStyle = { width: '100%', marginLeft: '30px' };
    const i18n = {
      en: {
        generate: 'GENERATE', verbatims: 'Verbatims', inclComments: 'Include comments'
      }, es: {
        generate: 'GENERAR', verbatims: 'Verbalizaciones', inclComments: 'Incluir comentarios'
      }
    }
    //console.log(response);

    // After to change it with a General Generate, response.response.size is not refreshing in the component Slider
    return (
      <div className="dropdown-menu dropdown-menu dropdown-menu-right px-drop-menu verbatim-modal"
           style={{width:'300px',padding:'15px',zIndex:'1030', borderRadius:'4px', right:'-40px'}}>
        <FontAwesomeIcon onClick={this.props.handlePopupVerbatim} className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal" icon={['fas', 'xmark']} style={{height: '15px', width: '15px'}} />
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="dropdown-verbatims">
              <div className="item-verbatim">
                <span className="text">{i18n[this.props.language].verbatims}</span>
                <div style={wrapperStyle}>
                  <Slider min={3}
                          max={9}
                          defaultValue={response.size}
                          step={1}
                          handle={this.handle}
                          onChange={(value) => this.onChangeSlider(value)}/>
                </div>
              </div>
              <div className="item-verbatim">
                <span className="text">Keywords</span>
                <TagsInput value={response.keywords ? response.keywords : []}
                           onChange={(event) => this.props.updateResponseKeywords(event, response.id)}
                           addKeys={[188, 13, 9, 32]}
                           onlyUnique={true}
                           renderInput={this.props.autosizingRenderInput}
                           inputProps={{placeholder: 'add keyword'}}
                           addOnBlur={true}
                           className="px-tagsinput-verbatim"/>
              </div>
              <div className="footer-verbatim">
                <label className="label-ch">
                  <input type="checkbox" name="inc_comments"
                         onChange={(event) => this.props.updateSizeResponse(event, response.id)}
                         checked={response.inc_comments} style={{cursor:'pointer'}}/>
                        &nbsp;{i18n[language].inclComments}
                </label>
                <button className="btn-generate" onClick={() => this.props.hideMenuAndRegenerate(response.id)}
                  style={{ borderRadius: '4px', backgroundColor: colors.color1[3] }}>
                  {i18n[language].generate}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onChangeSlider = (value) => {
    this.props.updateSizeResponseSlider(value, this.props.response.id);
  };

  handle = props => {
    const { value, dragging, index, ...restProps } = props;
    let valueWords = '';
    let language = this.props.language;
    const i18n ={
      en: {
        few: 'A few', many: 'Many', lots: 'A lot'
      }, es: {
        few: 'Algunas', many: 'Muchas', lots: 'Muchísimas'
      }
    }
    switch (value) {
      case 3: valueWords = i18n[language].few;break;
      case 4: valueWords = i18n[language].few;break;
      case 5: valueWords = i18n[language].few;break;
      case 6: valueWords = i18n[language].many;break;
      case 7: valueWords = i18n[language].many;break;
      case 8: valueWords = i18n[language].lots;break;
      case 9: valueWords = i18n[language].lots;break;
    }

    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={valueWords}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };
}
