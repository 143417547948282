import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"

export default class DLegends extends React.Component {
  static propTypes = {
    activeLink: PropTypes.string.isRequired,
    isLegends: PropTypes.bool.isRequired,
    handleIsLegend: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    }
  }

  render() {
    const {activeLink, isLegends, language, colors} = this.props;
    const I18n = {en: {accepted: 'Accepted', completed: 'Completed', drafted: 'Drafted', blank: 'Blank', alert: 'Alert'},
                  es: { accepted: 'Aceptado', completed: 'Completado', drafted: 'Borrador', blank: 'En blanco', alert: 'Alerta'}
                 }

    const classKey = isLegends ? 'item-key' : 'item-key no-margin';
    const classKeyContainer = isLegends ? 'keys-container' : 'keys-container hidden';

    return (
      <div onClick={this.openHelpArticle} className="px-fixed-legend-menu">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className={classKey}>
          <FontAwesomeIcon icon={['fas', 'key']} style={{ color: colors.color1[3] }} />
        </div>
{/* 
        <div className={classKeyContainer}>
          <div className="item-element" data-tooltip-content="A moderator accepted the response to a task">
            <div className="status accepted"/>
            <span>{I18n[language].accepted}</span>
          </div>
          <div className="item-element" data-tooltip-content="A participant completed a task">
            <div className="status completed"/>
            <span>{I18n[language].completed}</span>
          </div>
          <div className="item-element" data-tooltip-content="A participant drafted a task">
            <div className="status drafted"/>
            <span>{I18n[language].drafted}</span>
          </div>
          <div className="item-element" data-tooltip-content="A participant hasn't answered a task">
            <div className="status blank"/>
            <span>{I18n[language].blank}</span>
          </div>
          {
            activeLink !== 'Activities' &&
            <div className="item-element" data-tooltip-content="Red borders require your attention.
            You may have unread comments or overdue responses">
              <div className="status alert-warning"/>
              <span>{I18n[language].alert}</span>
            </div>
          }
          <div className="item-element" onClick={this.updateShowModal}>
            <FontAwesomeIcon icon={['fas', 'circle-info']}/>
          </div>
        </div> */}
      </div>
    );
  }

  // updateShowModal = (event) => {
  //   event.stopPropagation();
  //   this.setState(state => ({showModal: !state.showModalClose}), () => {
  //     if(this.state.showModal){
  //       $('#modal-id-legend').modal();
  //     } else {
  //       $('#modal-id-legend').modal('hide');
  //     }
  //   });
  // };

  openHelpArticle = () => {
    Beacon('article', '5f1fcdf904286306f8077b36', { type: 'modal' })
  }
}
