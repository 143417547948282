import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Tooltip as ReactTooltip} from "react-tooltip";

export default class AVNavigator extends React.Component {
  static propTypes = {
    questions: PropTypes.array.isRequired,
    activeTaskId: PropTypes.number.isRequired,
    setActiveTaskId: PropTypes.func.isRequired,
    responseStates: PropTypes.array.isRequired,
    colors: PropTypes.object.isRequired,
    responses: PropTypes.array,
    language: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {questions, responses, activeTaskId, responseStates, colors, language} = this.props;

    const i18n = {
      en: {unseenAlertTooltip: "You have unread comments in this task"},
      es: {unseenAlertTooltip: "Tienes comentarios no leídos en esta tarea"}
    }

    const  tasks = questions.map((task, index) => {
      const taskClass = task.id === activeTaskId ? 'question-indicator active' : 'question-indicator';
      const taskClassCompleted = task.id === activeTaskId ?
        'question-indicator completed active' : 'question-indicator completed';

      const response = responses.find(response => {
        return response.question_id === task.id
      });

      const unseenAlert = response?.sum_unseen > 0 ?
      <div className="unseen" style={{ backgroundColor: colors.color4[3],
        position: 'absolute',
        top: '-1px',
        right: '-5px',
        width: '16px',
        height: '16px',
        borderRadius: '50%'
      }}/> : ''

      const unseenAlertTooltip = response?.sum_unseen > 0 ?
        i18n[language].unseenAlertTooltip : ""

      // Be careful, if questions change position here, it would be different
      // and I would need to link every question with its response
      if(responseStates[index] === 'Completed' || responseStates[index] === 'Accepted') {
        if((questions.length - 1) === index){
          return (
            <div key={index} onClick={() => this.props.setActiveTaskId(task.id)} className={taskClassCompleted + " last"}
              style={taskClassCompleted === 'question-indicator completed' ?
                { backgroundColor: 'transparent', borderColor: '#45505D' } :
                { backgroundColor: colors.color0[1], borderColor: '#45505D'}}
                 data-tooltip-content={unseenAlertTooltip}>
              {unseenAlert}
              <FontAwesomeIcon icon={['far','check']} style={{color: colors.colorStates[0]}}/>
            </div>
          )
        }else{
          return (
            <div key={index} onClick={() => this.props.setActiveTaskId(task.id)} className={taskClassCompleted}
              style={taskClassCompleted === 'question-indicator completed' ?
                { backgroundColor: 'transparent', borderColor: '#45505D' } :
                { backgroundColor: colors.color0[1], borderColor: '#45505D'}}
                 data-tooltip-content={unseenAlertTooltip}>
              {unseenAlert}
              <FontAwesomeIcon icon={['far', 'check']} style={{ color: colors.colorStates[0] }}/>
            </div>
          )
        }
      } else {
        return (
          <div key={index} onClick={() => this.props.setActiveTaskId(task.id)} className={taskClass}
            style={taskClass === 'question-indicator active' ?
              { backgroundColor: colors.color0[1], borderColor: colors.color0[1] } : {}}
               data-tooltip-content={unseenAlertTooltip}>
            {unseenAlert}
            <span className="number">{index + 1}</span>
          </div>
        )
      }
    });
    const flagCircles = ($(".question-indicator").length) * ($(".question-indicator").width() + 30) >
      $(".question-navigation-container").width();

    return (
      <div className="question-navigation-container"
           style={{justifyContent: flagCircles ? 'flex-start' : 'center'}}
      >
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        { tasks }
      </div>
    );
  }
}