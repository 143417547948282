import PropTypes from 'prop-types';
import React from 'react';
import ParticipantList from "../../components/Community/Invitations/ParticipantList";

export default class Collaborators extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      communityUsers: [],
      segments: [],
      type: 'Collaborator',
    };
  }

  componentDidMount(){
    this.setCommunityUsers();
    this.setCommunitySegments();
  };

  render() {
    const cEnabled = this.state.communityUsers.length;

    return (
      <div>
        <ParticipantList community={this.props.community}
                         community_users={this.state.communityUsers}
                         counter={[cEnabled]}
                         type={this.state.type}
                         segments={this.state.segments}
                         role2={this.props.profile.role_2}/>
      </div>
    );
  }

  setCommunityUsers() {
    $.ajax({
      url: '/communities/get_community_users/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      data: {type: this.state.type},
      success: function (communityUsers) {
        this.updateCommunityUsers(communityUsers);
      }.bind(this)
    });
  };

  setCommunitySegments() {
    $.ajax({
      url: '/communities/get_segments/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      success: function (segments) {
        this.updateSegments(segments);
      }.bind(this)
    });
  };

  updateCommunityUsers = (communityUsers) => {
    this.setState({communityUsers})
  };

  updateSegments = (segments) => {
    this.setState({segments});
  };
}
