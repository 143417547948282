import PropTypes from 'prop-types';
import React from 'react';

//import AgoraRTM from 'agora-rtm-sdk';
import Layout from "../Layout";

//const client = AgoraRTM.createInstance('5fb100974c5e46ddad38476622221d69');
//const channel = client.createChannel('demoChannelName');

export default class AgoraChatPage extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      messages: [],
      channelMessages: [],
      client: null,
      users: [],
      activeId: null
    }
  };

  componentDidMount(){
    /*const {user_account} = this.props;

    client.on('ConnectionStateChanged', (newState, reason) => {
      console.log('on connection state changed to ' + newState + ' reason: ' + reason);
    });

    client.login({ token: null, uid: user_account.id + '' }).then(() => {
      console.log('AgoraRTM client login success');

      this.joinChannel();
    }).catch(err => {
      console.log('AgoraRTM client login failure', err);
    });

    const senderId = '7';
    channel.on('ChannelMessage', ({ text }, senderId) => {
      const channelMessages = [...this.state.channelMessages];
      channelMessages.push(text);

      this.setState({channelMessages});
    });

    channel.on('MemberLeft', memberId => {
      console.log('memberId:', memberId);
      const users = this.state.users.filter(u => u !== memberId + '');
      console.log('users:', users);

      this.setState({users});
    });

    client.on('MessageFromPeer', ({ text }, senderId) => {
      console.log('text:', text, 'senderId:', senderId);
      const messages = [...this.state.messages];
      messages.push(text);

      this.setState({messages});
      // text: text of the received message; peerId: User ID of the sender.
    });*/
  };

  joinChannel = () => {
    /*const {user_account} = this.props;

    channel.join().then(() => {
      console.log('join-channel success.');
      channel.on('MemberJoined', memberId => {
        const users = [...this.state.users]
        users.push(memberId);

        this.setState({users});
      })
    }).catch(error => {
      // Your code for handling the event of a join-channel failure.
    });*/
  };

  render() {
    const {user_account} = this.props;

    return(
      <Layout user_account={user_account}
              profile={user_account.profile}
              sessionLang={user_account.language}
              notifications={[]}
              from={'Dashboard'}
              colors={user_account.all_colors}>
        <div className="row">
          <div className="col-md-6">
            <h1>Agora Chat</h1>
            <input name='msg' value={this.state.msg} onChange={this.handleChange}/>
            <button onClick={this.sendMsg}>Send P2P ({this.state.activeId})</button>
            <button onClick={this.sendChannel}>Send Channel</button>
            <br/>
            <button onClick={this.logout}>Logout</button>
            <h3>Messages to me</h3>
            {
              this.state.messages.map((m, index) => {
                return(
                  <p key={index}>{m}</p>
                )
              })
            }
          </div>
          <div className="col-md-6">
            <h2>Connected Users</h2>
            {
              this.state.users.map((u, index) => {
                return(
                  <p key={index} onClick={() => this.setActiveId(u)} style={{cursor:'pointer'}}>
                    id: {u}
                  </p>
                )
              })
            }
            <br/>
            <h3>Channel Messages</h3>
            {
              this.state.channelMessages.map((m, index) => {
                return(
                  <p key={index}>{m}</p>
                )
              })
            }
          </div>
        </div>
      </Layout>
    )
  }

  setActiveId = activeId => {
    this.setState({activeId});
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value});
  };

  sendMsg = () => {
    /*client.sendMessageToPeer(
      { text: this.state.msg }, // An RtmMessage object.
      this.state.activeId, // The user ID of the remote user.
    ).then(sendResult => {
      if(sendResult.hasPeerReceived) {
        console.log('the remote user receives the message.');
      } else {
        console.log('the message is received by the server but the remote user cannot be reached.');
      }
    }).catch(error => {
      console.log('error:', error);
    });*/
  };

  sendChannel = () => {
    /*channel.sendMessage({ text: this.state.msg }).then(() => {
      // Your code for handling events, such as a channel message-send success.
    }).catch(error => {
      // Your code for handling events, such as a channel message-send failure.
    });*/
  }

  logout = () => {
    //client.logout();
  }
}