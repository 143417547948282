import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"

export default class RemovedBlockedCard extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    updateHide: PropTypes.func.isRequired,
    language: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {community, language} = this.props;

    const I18n = {
      en: { msg1: "You have been", msg2: "removed from", hide: "Hide", unhide: "Unhide", 
            info: "Unfortunately, you can no longer enter this community. If you have any questions contact directly the organization that invited you." },
      es: { msg1: "Has sido removido", msg2: "de la comunidad", hide: "Ocultar", unhide: "Mostrar", 
            info: "Desafortunadamente, no podrás entrar más a esta comunidad. Si tienes alguna pregunta comunícate directamente con la organización que te invitó." }
    }

    return (
      <div className="card px-card-community removed mg-bottom-15">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="card-height-indicator"/>
        <div className="card-content">
          <div className="card-body full-body body-status">
            <FontAwesomeIcon icon={['fal','circle-exclamation']} className="px-icon exclamation"/>
            <span className="title" style={{ color: '#45505d' }}>{I18n[language].msg1} <br /> {I18n[language].msg2}</span>
            <span className="community">"{community.title}"</span>
            <div className="one-button">
              <button type="button" onClick={() => this.props.updateHide(community.id)}
                      className={community.cu_hide ?
                        "btn px-btn hide-t lg-ct sm btn-gray-base" : 'btn px-btn hide-t lg-ct sm btn-gray-base inverted'}>
                {community.cu_hide ? I18n[language].unhide : I18n[language].hide}
              </button>
            </div>
            <FontAwesomeIcon icon={['fal','circle-info']} className="info-c" data-tooltip-content={I18n[language].info}/>
          </div>
        </div>
      </div>
    );
  }
}
