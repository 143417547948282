import PropTypes from 'prop-types';
import React from 'react';
import axios from "axios";
import TRCWordCloud from "../TResponse/TRConsolidated/TRCWordCloud";
import TRCBarChart from "../TResponse/TRConsolidated/TRCBarChart";
import LoadingConsolidated from "../../../UI/Globals/LoadingConsolidated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class TBModalConsolidated extends React.Component {
  static propTypes = {
    handleShowModalConsolidated: PropTypes.func.isRequired,
    objSearch: PropTypes.object.isRequired,
    allUsersId: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
    cmntyLanguage: PropTypes.string,
    index: PropTypes.number.isRequired,
    colors: PropTypes.object.isRequired,
    response: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      graph: null,
      isLoading: false
    }
  }

  componentDidMount() {
    this.getTVResponsesConsolidated();
  }

  render() {
    const {response, allUsersId, objSearch, index, colors, language, cmntyLanguage} = this.props;
    const i18n = {
      en: { noWordcloud: 'Not enough text to create a WordCloud', noChart: 'Not enough data to create a chart'},
      es: { noWordcloud: 'No hay suficiente texto para crear un Wordcloud', noChart: 'No hay suficientes datos para crear una gráfica'}
    }
    const {graph} = this.state;
    let wordCloudChart = null;
    let barChart = null;

    if(this.state.graph){
      switch(response.task.task_type){
        case 'Open End':
          if (graph.responsesConsolidated.length > 1 && graph.responsesConsolidated.join().split('').length > 200 ) {
            wordCloudChart = <TRCWordCloud answers={graph.responsesConsolidated}
                                           type={'single'}
                                           userAnswer={graph.userAnswerConsolidated}
                                           usersId={allUsersId}
                                           username={response.user.user_alias === '' ? response.user.username : response.user.user_alias}
                                           index={index}
                                           isVirtualized={false}
                                           doRecomputeRowHeights={() => {}}
                                           updateSVG={() => {}}
                                           svg={graph.wordCloud}
                                           responseId={response.id}
                                           objSearch={objSearch}
                                           colors={colors}
                                           language={language}/>;
          } else { wordCloudChart = <div className="px-empty-msg" style={{textAlign: 'center', color: colors.color4[3]}}>{i18n[language].noWordcloud}</div>}
          if(graph.mapsConsolidated.length !== 0){
            barChart = <TRCBarChart maps={graph.mapsConsolidated}
                                    type={'single'}
                                    taskType={response.task.task_type}
                                    usersId={allUsersId}
                                    userAnswer={graph.userAnswerConsolidatedMap}
                                    userAvatar={response.user.avatar}
                                    answersLength={graph.responsesConsolidated.length}
                                    index={index}
                                    doRecomputeRowHeights={() => {}}
                                    isVirtualized={false}
                                    updateSVG={() => {}}
                                    svg={graph.barChart}
                                    responseId={response.id}
                                    colors={colors}
                                    language={language}
                                    cmntyLanguage={cmntyLanguage}/>;
          } else { barChart = <div className="px-empty-msg" style={{ textAlign: 'center', color: colors.color4[3] }}>{i18n[language].noChart}</div> }
          break;
        case 'Choice':
          if(graph.mapsConsolidated.length !== 0){
            //console.log(this.state.mapsConsolidated);
            barChart = <TRCBarChart maps={graph.mapsConsolidated}
                                    type={'single'}
                                    taskType={response.task.task_type}
                                    userAnswer={graph.userAnswerConsolidated.answersId}
                                    taskAnswers={response.task.data.answers}
                                    usersId={allUsersId}
                                    answersLength={graph.responsesConsolidated.length}
                                    userAvatar={response.user.avatar}
                                    index={index}
                                    doRecomputeRowHeights={() => {}}
                                    isVirtualized={false}
                                    updateSVG={() => {}}
                                    svg={graph.barChart}
                                    responseId={response.id}
                                    colors={colors}
                                    language={language}
                                    cmntyLanguage={cmntyLanguage}/>;
          } else { barChart = <div className="px-empty-msg" style={{ textAlign: 'center', color: colors.color4[3] }}>{i18n[language].noChart}</div> }
          break;
      }
    }

    return (
      <div className="modal-dialog" style={{width:'800px'}}>
        <div className="px-counter-task" style={{backgroundColor: colors.color0[1]}}><span className="number">{response.cardinal}</span></div>
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal" onClick={() => this.props.handleShowModalConsolidated(null)}>
              <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
            </button>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <p dangerouslySetInnerHTML={{__html: response.task.title}} className="title"/>
              </div>
            </div>
          </div>

          <div className="modal-body px-modal-body-adv-filters">
            {
              this.state.isLoading ?
                <LoadingConsolidated/> :
                <React.Fragment>
                  {wordCloudChart}
                  {barChart}
                </React.Fragment>
            }
          </div>
        </div>
      </div>
    )
  }

  getTVResponsesConsolidated = () => {
    const { response, objSearch, allUsersId, language, cmntyLanguage} = this.props;
    //console.log(response);

    const data = {
      users_id: objSearch.idPars,
      tasks_id: [response.task.id],
      allUsersId: allUsersId,
      consolidated: 'general'
    };
    const config = {
      headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}
    };

    this.setState({isLoading: true}, () => {
      axios.post('/responses/get_tv_responses_consolidated', data, config)
        .then(r =>  {
          console.log('consolidated responses DB: ', r.data);
          let allMapsConsolidated = [];
          let mapsConsolidated = [];
          let userAnswerConsolidatedMap = [];

          const filteredResponses = this._filterResponsesState(r.data[0].responses);
          console.log('filteredResponses:', filteredResponses);

          switch(response.task.task_type){
            case 'Open End':
              allMapsConsolidated = filteredResponses.map(res => res.participantMapItems.map(item => item[cmntyLanguage]));
              mapsConsolidated = [].concat.apply([], allMapsConsolidated);
              userAnswerConsolidatedMap = ((filteredResponses.find(res => res.user_id === response.user_id))
                .participantMapItems).map(item => item[cmntyLanguage]);
              break;
            case 'Choice':
              allMapsConsolidated = filteredResponses.map(res => res.data.answersId.map(item => item));
              mapsConsolidated = [].concat.apply([], allMapsConsolidated);
              userAnswerConsolidatedMap = (filteredResponses.find(res => res.user_id === response.user_id))
                .data.answersId;
              break;
          }

          /*switch(response.task.task_type){
            case 'Open End':
              allMapsConsolidated = r.data[0].responses.map(res => res.participantMapItems.map(item => item[language]));
              mapsConsolidated = [].concat.apply([], allMapsConsolidated);
              userAnswerConsolidatedMap = ((r.data[0].responses.find(res => res.user_id === response.user_id))
                .participantMapItems).map(item => item[language]);
              break;
            case 'Choice':
              allMapsConsolidated = r.data[0].responses.map(res => res.data.answersId.map(item => item));
              mapsConsolidated = [].concat.apply([], allMapsConsolidated);
              userAnswerConsolidatedMap = (r.data[0].responses.find(res => res.user_id === response.user_id))
                .data.answersId;
              break;
          }*/

          //console.log(allMapsConsolidated);
          //console.log(mapsConsolidated);
          //console.log(userAnswerConsolidatedMap);

          const graph = {
            responsesConsolidated: filteredResponses.map(res => res.data),
            userAnswerConsolidated: filteredResponses.find(res => res.user_id === response.user_id).data,
            //responsesConsolidated: r.data[0].responses.map(res => res.data),
            //userAnswerConsolidated: r.data[0].responses.find(res => res.user_id === response.user_id).data,
            mapsConsolidated: mapsConsolidated,
            userAnswerConsolidatedMap: userAnswerConsolidatedMap,
            wordCloud: null,
            barChart: null
          };

          this.setState({graph, isLoading: false});

        }).catch(error => {
        console.log(error);
        this.setState({isLoading: false});
      });
    });
  };

  // Try to pass to Global
  _filterResponsesState = (responsesDB) => {
    let responses = [];
    const resStates = this.props.objSearch.resStates;
    console.log('resStates:', resStates);
    responsesDB.forEach(response => {
      if(response.state !== undefined){
        const responseState = response.state[0].toLowerCase() + response.state.slice(1);
        console.log('responseState:', responseState, 'resStates[responseState]:', resStates[responseState]);
        if(resStates[responseState]){
          responses.push(response);
        }
      } else {
        if(this.props.objSearch.resStates.empty){
          responses.push(response);
        }
      }
    });

    return responses;
  }
}