import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";

export default class MBSubs extends React.Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    company: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired
  };

  render() {
    const {language, subscription, colors} = this.props;

    const i18n = {
      en: {
        subs: 'Subscription', flex: 'Flexdays', exp: 'Expires', papt: 'Free participants at any point in time',
        video: 'video minutes per year', noSubs: 'No active subscription', flexdaysAvailable: 'free participant*days'
      },
      es: {
        subs: 'Suscripcion', flex: 'Flexdays', exp: 'Expira', papt: 'Participantes gratis al mismo tiempo',
        video: 'minutos de video por año', noSubs: 'No hay subscripcion activa', flexdaysAvailable: 'Días*participante grátis'
      }
    }

    return(
      <div className="px-card px-card-settings">
        <div className="px-icon-settings">
          <FontAwesomeIcon icon={['fas','dollar-sign']}/>
        </div>
        <div className="panel-body panel-body px-body-padding-welcome">
          <div className="row">
            <div className="col-xs-6">
              <div className="form-group first">
                <label htmlFor="subs" className="label-sm" style={{color: colors.color1[2],marginBottom:'15px'}}>
                  {i18n[language].subs}
                </label>
              </div>
              <div className="form-group first">
                <label htmlFor="subs" className="label-md" style={{ color: '#262e37', textTransform: 'uppercase', fontFamily: 'Sharp Sans ExtraBold' }}>{subscription.name === 'No Subs' ? i18n[language].noSubs : this.subsNamesHelper(subscription.name)}</label>
              </div>
              {
                subscription.name !== 'No Subs' &&
                  <React.Fragment>
                    <span className='px-label-warning'>{i18n[language].exp} {moment(subscription.end).format('DD/MM/YYYY')}</span><br/><br/>
                    <span>- {subscription.papt} {i18n[language].papt}</span><br/>
                    <span>- {subscription.video_mins} {i18n[language].video}</span><br/>
                  </React.Fragment>
              }
            </div>
            <div className="col-xs-6">
              <div className="form-group first">
                <label htmlFor="flex" className="label-sm" style={{color: colors.color1[2]}}>{i18n[language].flex}</label>
              </div>
              <div className="form-group first">
                <label htmlFor="subs" className="label-md" style={{ color: '#262e37', textTransform: 'uppercase' }}><span style={{ fontFamily: 'Sharp Sans ExtraBold' }}>{subscription.flex_days}</span> {i18n[language].flexdaysAvailable}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  subsNamesHelper = (name) => {
    let mktName = null;
    switch(name) {
      case 'Subs 10':
        mktName = 'Pixiebob 10';
        break;
      case 'Subs 20':
        mktName = 'Pixiebob 20';
        break;
      case 'Subs 30':
        mktName = 'Pixiebob 30';
        break;
      case 'Subs 40':
        mktName = 'Pixiebob40';
        break;
      default:
        mktName = 'No subs'
    }
    return mktName;
  }
}