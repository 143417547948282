import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class TopMenu extends React.Component {
  static propTypes = {
    avatar: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    notifications: PropTypes.array.isRequired,
    notificationsUnseenCounter: PropTypes.number.isRequired,
    notificationsCounterHandler: PropTypes.func.isRequired,
    rightDrawerToggleHandler: PropTypes.func.isRequired,
    messagesCounterHandler: PropTypes.func.isRequired,
    communityTitle: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    commState: PropTypes.string,
    communities: PropTypes.array,
    updateSelectCommunities: PropTypes.func,
    leftDrawerToggleHandler: PropTypes.func,
    role_1: PropTypes.string,
    rooms: PropTypes.array,
    communityId: PropTypes.number,
    language: PropTypes.string,
    pmbCounter: PropTypes.number.isRequired,
    logo: PropTypes.string,
    subdomain: PropTypes.string,
    colors: PropTypes.object.isRequired,
    newsCounterHandler: PropTypes.func.isRequired,
    unreadNewsFlash: PropTypes.bool,
    onceCollaborator: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      broadcasts: {broadcasts: [], counterUnseen: 0}
    }
  }

  componentDidMount(){
    if(this.props.communityId !== -1){
      //this.setState({broadcasts: this._getBroadcasts()})
    }
  }

  render() {
    const {communities, commState, from, username, notificationsUnseenCounter, communityTitle, language, pmbCounter,
      logo, subdomain, colors, unreadNewsFlash, onceCollaborator} = this.props;

    const i18n = {
      en: {
        Active: 'Active Communities', Closed: 'Closed Communities', Archived: 'Archived Communities',
        All: 'All Communities', Hidden: 'Hidden Cards', manage: 'Manage account', logout: 'Logout'
      },
      es: {
        Active: 'Comunidades Activas', Closed: 'Comunidades Cerradas', Archived: 'Comunidades Archivadas',
        All: 'Todas las comunidades', Hidden: 'Tarjetas Ocultas', manage: 'Configurar cuenta', logout: 'Cerrar sesión'
      }
    };
    //console.log(subdomain);
    const companyLogo = logo !== ''  ?
      <div id="divImgSubdomain" className="px-container-img-subdomain">
        <img id="imgCompanyLogo" src={logo} alt=""/>
      </div> :
      <img id={"logo-navbar"} src="/assets/nabvar_brand.png" className="brand-img subdomain" alt=""
           style={{visibility: subdomain === 'www' ? 'inherit' : 'hidden'}}/>;

    const urlManage = '/users/manage/';
    let cActive = 0;
    let cClosed = 0;
    let cArchived = 0;
    let cHidden = 0;
    if(from === 'Dashboard'){
      communities.forEach(community => {
        switch(community.state){
          case 'Enabled': ++cActive; break;
          case 'Closed': ++cClosed; break;
          case 'Archived': ++cArchived; break;
        }

        if(community.cu_hide){
          ++cHidden;
        }
      })
    }

    let pageTitle = null;
    let liCommunities = [];

    if(from !== 'ManagePage'){
      if(from === 'CommunityPage' || from === 'ParticipantCommunityPage' || from === 'CollTranscriptPage' ||
        from === 'ParticipantActivityPage' || from === 'WelcomePage' || 'CollChatPage'){
        pageTitle = communityTitle;
        liCommunities = this._orderCommunities(communities, communityTitle).map(community => {
          return(
            <li key={community.id} style={{color:'black'}}>
              <a href={ community.project_type !== 'AIChat' ? '/communities/' + community.id + '/Activities' : '/community/' + community.id + '/ai/chat'}>
                <img src={community.avatar} alt=""/>
                <span>{community.title}</span>
              </a>
            </li>
          )
        });
      } else {
        pageTitle = i18n[language][commState];
      }
    } else {
      pageTitle = username;
    }

    let communityListMenu = <ul className="dropdown-menu dropdown-menu-list-communities" role="menu"
                                aria-labelledby="dropdownMenu1">{liCommunities}</ul>;

    let downArrow = <FontAwesomeIcon icon={['fal','circle-chevron-down']} className="dropdown-toggle px-chev-down"
                                     id="dropdownMenu1" data-toggle="dropdown"/>;
    if(from !== 'ManagePage'){
      if(from !== 'Dashboard'){
        if(liCommunities.length === 0){
          downArrow = null;
          communityListMenu = null;
        }
      }
    }

    if(from === 'ManagePage'){
      downArrow = null;
    }

    let pmCounter = 0;
    if(this.props.rooms){
      this.props.rooms.forEach(room => {
        pmCounter += room.unseen_size;
      });
      //console.log(pmCounter);
      if(this.props.communityId !== -1 && this.state.broadcasts){
        pmCounter += this.state.broadcasts.counterUnseen;
      }
    }
    //console.log(pmCounter);
    return (
      <div>
        <nav id="navMain" className={`navbar navbar-default px-nav-base ${logo !== '' && 'subdomain'}`}
             style={{backgroundColor: colors.color0[0]}}>
          {/* <ul className="px-icon-container">
            <li>
              <a className="home" href="/users/dashboard">
                <FontAwesomeIcon icon={['fas','house']} style={{fontSize:'24px'}} className="home"/>
              </a>
            </li>
            <li id={"question-navbar"}>
              <a className="help" onClick={displaySupportTooltip}>
                <FontAwesomeIcon icon={['fas','circle-question']} style={{fontSize:'24px'}}/>
                <div className="px-support-tooltip right is_hidden px-support-all">
                  <p>Need more information?</p>
                  <p id='link-to-help' onClick={openSupportTab}>Go to support pages here.</p>
                  <i/>
                </div>
              </a>
            </li>
          </ul> */}
          <div className="dropdown px-drop-comm">
            <div className="title px-home-title dropdown-toggle" data-toggle={from !== 'ManagePage' && "dropdown"}>
              <span id="spnLeftUsername">{pageTitle}</span>
            </div>
            { downArrow }
            {
              from === 'Dashboard' ?
              <ul className="dropdown-menu px-menu-community" role="menu" aria-labelledby="dropdownMenu1">
                <li onClick={() => this.props.updateSelectCommunities('Active')} role="presentation">
                  <a role="menuitem" tabIndex="-1">
                    <div className="item">
                        <FontAwesomeIcon icon={['fas', 'bolt']} className="icon" style={{ color: colors.color2[3]}}/>
                      <span className="text">{i18n[language].Active}</span>
                      <div className="counter">
                        <span>{cActive}</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li onClick={() => this.props.updateSelectCommunities('Closed')} role="presentation">
                  <a role="menuitem" tabIndex="-1">
                    <div className="item">
                        <FontAwesomeIcon icon={['fas', 'xmark']} className="icon" style={{ color: colors.color2[3] }}/>
                      <span className="text">{i18n[language].Closed}</span>
                      <div className="counter">
                        <span>{cClosed}</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li onClick={() => this.props.updateSelectCommunities('Archived')} role="presentation">
                  <a role="menuitem" tabIndex="-1">
                    <div className="item">
                        <FontAwesomeIcon icon={['fas', 'box-archive']} className="icon" style={{ color: colors.color2[3] }}/>
                      <span className="text">{i18n[language].Archived}</span>
                      <div className="counter">
                        <span>{cArchived}</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li onClick={() => this.props.updateSelectCommunities('All')} role="presentation">
                  <a role="menuitem" tabIndex="-1">
                    <div className="item">
                        <FontAwesomeIcon icon={['fas', 'globe']} className="icon" style={{ color: colors.color2[3] }}/>
                      <span className="text">{i18n[language].All}</span>
                      <div className="counter">
                        <span>{cActive + cClosed + cArchived}</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li onClick={() => this.props.updateSelectCommunities('Hidden')} role="presentation">
                  <a role="menuitem" className="last" tabIndex="-1">
                    <div className="item">
                      <FontAwesomeIcon icon={['fas','eye-slash']} className="icon" style={{ color: colors.color4[2]}}/>
                      <span className="text" style={{color:'rgb(217, 91, 44)'}} >{i18n[language].Hidden}</span>
                      <div className="counter">
                        <span id="spnHiddenCounter" style={{color:'rgb(217, 91, 44)'}}>{cHidden}</span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul> :
              communityListMenu
            }
          </div>
          <div id="divTMRightMenu" className="px-right-menu-container">
            <ul className="px-right-nav subdomain">
              <li className="li-subdomain li-nav-bar">
                {logo === '' && <div style={{width: '50px'}}/>}
                {/*<a href="#"><button className="btn px-btn sm btn-gray-darker inverted btn-upgrade">Upgrade</button></a>*/}
              </li>

              {
                this.props.from !== 'ManagePage' &&
                <li className="li-subdomain li-nav-bar" style={{marginLeft:'0'}}>
                  <a href="#" onClick={this.props.notificationsCounterHandler}>
                    <div className="notification-element">
                      <FontAwesomeIcon icon={['far','bell']} className="bell-item"/>
                      {
                        notificationsUnseenCounter !== 0 &&
                        <div className="not-counter" style={{ backgroundColor: colors.color3[3] }}>
                          <span>{notificationsUnseenCounter}</span>
                        </div>
                      }
                    </div>
                  </a>
                </li>
              }
              {
                this.props.from !== 'Dashboard' && this.props.from !== 'ManagePage' &&
                <li className="li-subdomain li-nav-bar">
                  <a href="#" onClick={this.props.messagesCounterHandler}>
                    <div className="notification-element">
                      <FontAwesomeIcon icon={['far','envelope']} className="envelope-item"/>
                      {
                        pmbCounter !== 0 &&
                        <div id="divPMCounter" className="not-counter email" style={{ backgroundColor: colors.color2[3] }}>
                          <span id="spnPMCounter">{pmbCounter}</span>
                        </div>
                      }
                    </div>
                  </a>
                </li>
              }
              { 
              // onceCollaborator === true &&
              // <li className="li-subdomain li-nav-bar" style={{}}>
              //   <a href="#" onClick={this.props.newsCounterHandler}>
              //     <div className="notification-element">
              //         <FontAwesomeIcon icon={['fad', 'sparkles']} className={`bell-item ${unreadNewsFlash === true && 'moving-sparkles'}`} style={{ color: unreadNewsFlash === true ? colors.color5[3] : '#ffffff'}} />
              //     </div>
              //   </a>
              // </li>
              }
              <li className="dropdown li-subdomain li-nav-bar last">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
                    <div className="avatar-dropdown">
                        <img className="px-avatar" id="imgProfileAvatar" src={this.props.avatar} alt=""/>
                        <div className="account-alias"><span id="spnUsername">{this.props.username}</span></div>
                        <FontAwesomeIcon icon={['fal','circle-chevron-down']} style={{marginLeft:'5px'}}/>
                    </div>
                  </a>
                  <ul className="dropdown-menu-right dropdown-menu px-menu-manage" style={{borderRadius: '14px !important'}}>
                    <li>
                    <a href={urlManage}>{i18n[language].manage}</a>
                    </li>
                    {/* <li><a href="#" >Upgrade</a></li> */}
                    <li className="logout">
                      <a className="logout" rel="nofollow" data-method="delete" href="/users/sign_out">{i18n[language].logout}</a>
                    </li>
                  </ul>
              </li>
            </ul>
            { companyLogo }
          </div>
        </nav>
      </div>
    );
  }

  _orderCommunities(communities, communityTitle){
    const enabled = communities.filter(c => c.state === 'Enabled');
    const closed = this.props.role_1 !== 'Participant' && communities.filter(c => c.state === 'Closed');
    const sorted = [...enabled, ...closed].filter(c => c.title !== communityTitle);

    return sorted.filter(c => c.cu_state !== 'Blocked' && c.cu_state !== 'Declined');
  }

  _getBroadcasts = () => {
    $.ajax({
      url: '/broadcasts/show/' + this.props.communityId,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: broadcasts => {
        console.log(broadcasts);
        this.setState({broadcasts})
      }
    });
  };
}
