import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import download from "downloadjs";
const JSZip = require("jszip");
import { saveAs } from 'file-saver';

export default class TRFileDownload extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSavingOneImage: false,
      savedImagesCounter: 0
    }
  };

  render() {
    const {type, action, url} = this.props;

    return (
      <div className="px-media-download" style={{ color: '#AFBDC1', left: type === 'videos' && '95px'}}>
        {
          !this.state.isSavingOneImage ?
            <FontAwesomeIcon onClick={() => this.downloadFiles(type, action, url)} icon={['fas','download']}/> :
            <FontAwesomeIcon icon={['fas','spinner']} spin/>
        }
      </div>
    )
  }

  downloadFiles = async (type, action, urlOne) => {
    console.log(type, action, urlOne);
    this.setState({isSavingOneImage: true, savedImagesCounter: 0}, async() => {
      if(type === 'images'){
        if(action === 'one'){
          const blob = await fetch(decodeURIComponent(urlOne)).then(r => r.blob());
          console.log('blob:', blob);
          download(blob, Date.now(), blob.type);
          this.setState({isSavingOneImage: false});
        } else {
          /*const zip = new JSZip();

          for(const attachment of this.props.response.attachments){
            console.log(decodeURIComponent(attachment.url));
            const blob = await fetch(decodeURIComponent(attachment.url)).then(r => r.blob());
            console.log('blob:', blob);
            let extension = '';
            switch (blob.type) {
              case 'image/jpeg': extension = 'jpg';break;
              case 'image/png': extension = 'png';break;
              default: extension = 'jpg';
            }

            zip.file(Date.now() + '.' + extension, blob, {base64: true});
            this.setState(prevState => ({savedImagesCounter: prevState.savedImagesCounter + 1}));
          }

          zip.generateAsync({type:"blob"}).then((content) => {
            saveAs(content, "example.zip");
            console.log('Ready');
            this.setState({isSavingImages: false});
          });*/
        }
      } else {
        // VIDEOS
        // The same as images
        if(action === 'one'){
          const blob = await fetch(decodeURIComponent(urlOne)).then(r => r.blob());
          console.log('blob:', blob);
          download(blob, Date.now(), blob.type);
          this.setState({isSavingOneImage: false});
        }
      }
    });
  };
}