import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";

import T_ModalVideo from "../../T_ModalVideo";
import {setIconGender} from "../../../../UI/Globals/PX_Funs";
import {usernamesToDisplay} from "../../../shared_utils";

export default class TRCImageVideo extends React.Component {
  static propTypes = {
    media: PropTypes.object.isRequired,
    from: PropTypes.string.isRequired,
    consolidated: PropTypes.string.isRequired,
    user: PropTypes.object,
    colors: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
      showModalVideo: false
    };
  }

  render() {
    const { media, from, consolidated, language, colors} = this.props;
    const availableUsernames = usernamesToDisplay(this.props.media.user, 'alias', 'all');
    const mainName = availableUsernames[0];
    const alternativeNames = availableUsernames.length > 1 ? availableUsernames.slice(1).join(', ') : ''

    let hoverDiv = <div className="px-user-tooltip consolidate">
      <div className="title activity">{media.activityTitle} </div>
      <div className="email">{media.taskTitle.replace(/(<([^>]+)>)/ig,"")}</div>
    </div>;

    if(from === 'multi'){
      hoverDiv = <div className="px-user-tooltip consolidate">
        <div className="title">{mainName}</div>
        <div className='info-container'>
          {
            media.user.gender !== '' &&
            <div className="px-genders-container">
              <div className="gender-container">
                {setIconGender(media.user.gender)}
              </div>
            </div>
          }
          {
            media.country && media.country !== '' &&
            <div className="px-country-container">
              <span>{media.user.country}</span>
            </div>
          }
          <div className='icons-container'>
            {
              media.user.segments.map(segment => {
                return (
                  <FontAwesomeIcon key={segment.id} icon={['fas', 'tag']} className="tag"
                                   style={{color: segment.color}}/>
                )
              })
            }
          </div>
        </div>
        <div className="email">{alternativeNames ? `a.k.a:  ${alternativeNames}` : ''}</div>
        <div className="email">{media.user.email}</div>
      </div>
    }

    return (
      <React.Fragment>
      {
          consolidated === 'images' ?
            <div className="px-image-item" onMouseEnter={() => this.setState({isHovering: true})}
                onMouseLeave={() => this.setState({isHovering: false})}
                style={{cursor:'pointer', position:"relative"}}>
              <img src={media.url_thumbnail || media.url } alt=""/>
              { this.state.isHovering && hoverDiv }
            </div> :
            <React.Fragment>
              <div className="px-video-item" onMouseEnter={() => this.setState({isHovering: true})}
                  onMouseLeave={() => this.setState({isHovering: false})} style={{cursor:'pointer', position:"relative"}}>
                <img src={media.video_thumbnail} alt=""/>
                <div className="overlay-video" onClick={this.updateShowModalVideo}>
                  <div className="play-icon"><FontAwesomeIcon icon={['fas','play']}/></div>
                </div>
                { this.state.isHovering && hoverDiv }
              </div>
              {
                this.state.showModalVideo &&
                <ReactModal isOpen={this.state.showModalVideo} contentLabel="Response Video"
                            shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalVideo}
                            className="my-modal-content" overlayClassName="my-modal-overlay">
                  <T_ModalVideo updateShowModalVideo={this.updateShowModalVideo}
                                url={media.video_url}
                                thumbnail={media.video_thumbnail}
                                videoTranscriptData={media.video_transcript_data || {}}
                                videoTranscript={media.video_transcript || ''}
                                colors={colors}
                                language={language}/>
                </ReactModal>
              }
            </React.Fragment>
        }
      </React.Fragment>
    )
  }

  updateShowModalVideo = () => {
    this.setState({showModalVideo: !this.state.showModalVideo});
  };
}