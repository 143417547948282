import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import { Tooltip as ReactTooltip } from "react-tooltip"

export default class AE_AddTask extends React.Component {
  static propTypes = {
    addQuestion: PropTypes.func.isRequired,
    position: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    cmntyTaskTypeLevel: PropTypes.string,
    cmntyMaxMicroChats: PropTypes.number,
    microChatCount: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddTask: false,
      oldSlide: 0,
      activeSlide: 0,
    };
  }

  render() {
    const {position, language, cmntyTaskTypeLevel, cmntyMaxMicroChats, microChatCount, colors} = this.props

    const i18n = {
      en: {
        essentials: 'Essentials', multimedia: 'Multimedia', group: 'Group', 
        soon:'Coming soon', openEnd: 'Open End', choice: 'Choice',
        canvas: 'Canvas', microChat: 'Micro Chat', addTask: 'Add task',
        maxMicroChatsReached: 'You have reached the limit of MicroChats you can deploy',
        outOf: 'out of', posted: 'Posted'
      },
      es: {
        essentials: 'Esenciales', multimedia: 'Multimedia', group: 'Grupales',
        soon: 'Próximamente', openEnd: 'Abierta', choice: 'Opción múltiple',
        canvas: 'Canvas', microChat: 'Micro Chat', addTask: 'Nueva tarea',
        maxMicroChatsReached: 'Has alcanzado el límite de MicroChats que puedes desplegar',
        outOf: 'de', posted: 'Publicado'
      }
    }

    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow updateCurrentSlide={this.updateCurrentSlide}/>,
      beforeChange: (current, next) => this.setState({oldSlide: current, activeSlide: next})
    };

    let openEnd, multimedia, choice, microChat, canvas;
    let image, video, microphone;
    let lightbulb, clouds;
    let paint, stopwatch, highlighter, pen, map, newspaper, download, list;

    switch(this.state.activeSlide){
      case 0:
        openEnd = <FontAwesomeIcon onClick={() => this.props.addQuestion('Open End', position)}
                                   icon={['fas', 'comment']} style={{cursor:'pointer'}} data-tooltip-content={i18n[language].openEnd}/>;
        multimedia = <FontAwesomeIcon onClick={() => this.props.addQuestion('Multimedia', position)}
                                      icon={['fas', 'image']} style={{ cursor: 'pointer' }}
                                      data-tooltip-content={i18n[language].multimedia}/>;
        choice = <FontAwesomeIcon onClick={() => this.props.addQuestion('Choice', position)}
          icon={['fas', 'square-check']} style={{ cursor: 'pointer' }} data-tooltip-content={i18n[language].choice}/>;
        microChat = cmntyMaxMicroChats != null && cmntyMaxMicroChats > microChatCount ?
            <FontAwesomeIcon onClick={() => this.props.addQuestion('MicroChat', position)}
                                  icon={['fas', 'messages']} style={{ cursor: 'pointer' }} data-tooltip-content={ `${i18n[language].microChat} (${i18n[language].posted} ${microChatCount} ${i18n[language].outOf} ${cmntyMaxMicroChats})` }/> :
            <FontAwesomeIcon icon={['fas', 'messages']} style={{ cursor: 'default', color: colors.color4[3] }} data-tooltip-content={`${i18n[language].maxMicroChatsReached} (${i18n[language].posted} ${microChatCount} ${i18n[language].outOf} ${cmntyMaxMicroChats})`}/>
        canvas = <FontAwesomeIcon onClick={() => this.props.addQuestion('Canvas', position)} icon={['fas', 'location-pen']} style={{ cursor:'pointer' }} data-tooltip-content={i18n[language].canvas}/>;
        break;
      case 1:
        image = <FontAwesomeIcon icon={['fas', 'image']} data-tooltip-content={i18n[language].soon}
                                 style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        video = <FontAwesomeIcon icon={['fas', 'video']} data-tooltip-content={i18n[language].soon}
                                 style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        microphone = <FontAwesomeIcon icon={['fas', 'microphone']} data-tooltip-content={i18n[language].soon}
                                      style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        break;
      case 2:
        lightbulb = <FontAwesomeIcon icon={['fas', 'lightbulb']} data-tooltip-content={i18n[language].soon}
                                     style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        clouds = <FontAwesomeIcon icon={['fas', 'clouds']} data-tooltip-content={i18n[language].soon}
                                  style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        break;
      case 3:
        paint = <FontAwesomeIcon icon={['fas', 'paint-brush-alt']} data-tooltip-content={i18n[language].soon}
                                 style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        stopwatch = <FontAwesomeIcon icon={['fas', 'stopwatch']} data-tooltip-content={i18n[language].soon}
                                     style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        highlighter = <FontAwesomeIcon icon={['fas', 'highlighter']} data-tooltip-content={i18n[language].soon}
                                       style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        pen = <FontAwesomeIcon icon={['fas', 'square-pen']} data-tooltip-content={i18n[language].soon}
                               style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        map = <FontAwesomeIcon icon={['fas', 'map-marker-edit']} data-tooltip-content={i18n[language].soon}
                               style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        newspaper = <FontAwesomeIcon icon={['fas', 'newspaper']} data-tooltip-content={i18n[language].soon}
                                     style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        download = <FontAwesomeIcon icon={['fas', 'download']} data-tooltip-content={i18n[language].soon}
                                    style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        list = <FontAwesomeIcon icon={['fas', 'list-ol']} data-tooltip-content={i18n[language].soon}
                                style={{cursor:'not-allowed',color:'#b1bdc0'}}/>;
        break;
    }

    const task_level_allowed = cmntyTaskTypeLevel ? cmntyTaskTypeLevel : 0

    return (
      <div className="px-quick-actions-activity dropdown-menu"
           style={{left: '30px', bottom: '30px', padding:'0',zIndex:'1030',minHeight:'70px'}}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className="panel-body">
          <Slider {...sliderSettings} className="category-carousel-container">
            <div className="text-center item">
              {i18n[language].addTask}
            </div>
            <div className="text-center item">
              {i18n[language].multimedia}
            </div>
            {/*<div className="text-center item">*/}
            {/*  {i18n[language].group}*/}
            {/*</div>*/}
            {/*<div className="text-center item">*/}
            {/*  Pixiebob*/}
            {/*</div>*/}
          </Slider>

          <div className="actions-category-container">
            { openEnd }{ multimedia }{ choice }{ canvas }{ task_level_allowed > 2 && microChat }
            {/*{ image }{ video }{ microphone }*/}
            {/*{ lightbulb }{ clouds }*/}
            {/*{ paint }{ stopwatch }{ highlighter }{ pen }{ map }{ newspaper }{ list }*/}
          </div>
        </div>
        {/*{<div className="panel-footer">*/}
        {/*  <span className="category">Popular</span>*/}
        {/*  <FontAwesomeIcon icon={['fas', 'comment']} className="footer-icon"/>*/}
        {/*  <FontAwesomeIcon icon={['fas', 'star']} className="footer-icon"/>*/}
        {/*</div>}*/}
      </div>
    );
  }

  handleShowAddTask = () => {
    this.setState(state => ({showAddTask: !state.showAddTask}));
  };

  updateCurrentSlide = (currentSlide) => {
    console.log(currentSlide);
    //this.setState({currentSlide});
  };
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <FontAwesomeIcon icon='caret-up' onClick={onClick} className="special-caret" style={{cursor:'pointer', visibility: 'hidden'}}/>
  );
}

function SampleNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <FontAwesomeIcon icon='caret-down' onClick={onClick} className="special-caret" style={{cursor:'pointer', visibility: 'hidden'}}/>
  );
}