import PropTypes from 'prop-types';
import React from 'react';

import Layout from "../../Layout";
import Settings from "../../../containers/Community/Settings";
import {getParticipantToDoCounter} from "../../UI/Globals/PX_Funs";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";

export default class SettingsPage extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    sessionLang: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    active_users: PropTypes.array.isRequired,
    rooms: PropTypes.array.isRequired,
    communities: PropTypes.array.isRequired,
    cu: PropTypes.object.isRequired,
    originSettings: PropTypes.string.isRequired,
    papt: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      community: props.community,
      communitiesTop: [],
      notifications: [],
      rooms: [],
      activeUsers: [],
      toDoCounter: 0,
      currentMenu: '#' + props.originSettings,
      openPMFromOutside: {username: '', userId: null, flag: false},
      notificationsCounter: 0,
      isBeaconInit: false //Keeps track of any instances of Head Scout Beacons initialised.
    }
  }

  componentDidMount(){
    this.handleLink(this.state.currentMenu, `#div${this.props.originSettings}`);
    if(this.props.profile.role_1 === 'Participant'){
      //this._getTasks();
    }

    this._getCommunitiesTop();
    this._getNotificationsCounter();
    //this._getNotifications();
    //this._getRooms();
    //this._getActiveUsers();
  }

  render() {
    const {community, profile, cu, papt, sessionLang, originSettings, user_account} = this.props;
    const language = profile.language ? profile.language : sessionLang;
    const hsBeaconID = this.defineBeaconID(user_account.role_1);
    let hsBeaconSuggestions = null;
    const hsBeaconSuggestionsColls = {
      en: ["5f1fcbba04286306f8077b27", "5f1eb6322c7d3a10cbab55c5", "5f1ec53f04286306f8076c84", "5f225a1e2c7d3a10cbab8bc3", "5f1ec4fa04286306f8076c80", "5f22666a04286306f8079cf7", "5f2266de04286306f8079cf8", "5f202a9a2c7d3a10cbab6aee", "5f225dac2c7d3a10cbab8bdd", "5f22608a04286306f8079ccf"]
    };
    const hsBeaconSuggestionsCollsInvite = {
      en: ["5f1fcb2504286306f8077b24", "5f223a7a2c7d3a10cbab8a9b", "5f2241082c7d3a10cbab8ae4", "5f223ccd2c7d3a10cbab8ab3", "5f22416404286306f8079be8", "5f2241de2c7d3a10cbab8aef", "5f2238bc2c7d3a10cbab8a88", "5f22395f2c7d3a10cbab8a92", "5f223b6e2c7d3a10cbab8aa5", "5f223c252c7d3a10cbab8aad"] 
    };
    const hsBeaconSuggestionsOthers = {
      en: ["5f2021f904286306f8077e7f", "5f1eb5452c7d3a10cbab55b6", "5f200d8e04286306f8077d91", "5f201fed2c7d3a10cbab6a40", "5f1eba9c04286306f8076bfe", "5f1eb7cf04286306f8076bce", "5f1eb8d02c7d3a10cbab55fa", "5f1eb6322c7d3a10cbab55c5", "5f1eb4cc04286306f8076baa", "5f1fadea2c7d3a10cbab6579"],
      es: ["5f2021f804286306f8077e7e", "5f1faf0304286306f8077a29", "5f200f2c2c7d3a10cbab695c", "5f20207c2c7d3a10cbab6a4c", "5f1ebba904286306f8076c0d", "5f1fac852c7d3a10cbab656d", "5f1ebca52c7d3a10cbab5621", "5f1fadbb2c7d3a10cbab6577", "5f1faf5904286306f8077a33", "5f227e702c7d3a10cbab8d2f"]
    };

    if (user_account.role_1 === 'Collaborator') {
      if (this.state.currentMenu === '#Invitations') {
        hsBeaconSuggestions = hsBeaconSuggestionsCollsInvite;
      } else {
        hsBeaconSuggestions = hsBeaconSuggestionsColls;
      }
    } else {
      hsBeaconSuggestions = hsBeaconSuggestionsOthers;
    }

    return (
      <Layout user_account={user_account}
              profile={profile}
              sessionLang={this.props.sessionLang}
              notifications={this.state.notifications}
              from={user_account.role_1 === 'Collaborator' ? 'CommunityPage' : 'ParticipantCommunityPage'}
              community={this.state.community}
              leftMenuActive={'Settings'}
              active_users={this.state.activeUsers}
              rooms={this.state.rooms}
              communities={this.state.communitiesTop}
              toDoCounter={this.state.toDoCounter}
              currentMenu={this.state.currentMenu}
              handleLink={this.handleLink}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              notificationsCounter={this.state.notificationsCounter}
              colors={user_account.all_colors}>

        {/*<HelpScoutBeacon beaconId={hsBeaconID}*/}
        {/*                 user_account={user_account}*/}
        {/*                 colors={user_account.all_colors}*/}
        {/*                 language={language}*/}
        {/*                 isBeaconInit={this.state.isBeaconInit}*/}
        {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
        {/*                 suggestions={hsBeaconSuggestions}*/}
        {/*                 hideFABOnDesktop={!this.props.user_account.profile.is_help}/>*/}

        <Settings community={community}
                  updateCommunity={this.updateCommunity}
                  role_1={user_account.role_1}
                  cu={cu}
                  handleLink={this.handleLink}
                  communities={this.state.communitiesTop}
                  papt={papt}
                  maxPapt={user_account.company ? user_account.company.papt : -1}
                  language={language}
                  user_email={user_account.email}
                  isBeaconInit={this.state.isBeaconInit}
                  updateIsBeaconInit={this.updateIsBeaconInit}
                  companyName={user_account.company ? user_account.company.name : '-1'}
                  colors={user_account.all_colors}
                  subscription={user_account.subscription}
                  company={user_account.company}
                  userId={user_account.profile.user_id}
                  helpStores={user_account.help_stores}
        />
      </Layout>
    );
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  defineBeaconID = (role) => {
    if (role === 'Collaborator') {
      if (this.state.currentMenu === '#Invitations') {
        return '0103d802-14af-4e84-8ec1-9c5f45542228'
      } else {
        return '9abff3ac-994f-412b-8bd1-be9f6b561b99'
      }
    } else {
      return '47769d19-2ae2-4b49-a39f-28ecc46749a2'
    }
  }

  _getActiveUsers = () => {
    $.ajax({
      url: '/communities/coll_get_active_parts/' + this.props.community.id + '/settings',
      method: 'GET',
      success: activeUsers =>  {
        this.setState({activeUsers});
      }
    });
  };

  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log(communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };

  _getRooms = () => {
    $.ajax({
      url: '/communities/get_rooms/' + this.props.community.id,
      method: 'GET',
      success: rooms =>  {
        //console.log(rooms);
        this.setState({rooms});
      }
    });
  };

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  _getNotifications = () => {
    $.ajax({
      url: '/notifications/get_notifications/-1',
      method: 'GET',
      success: notifications =>  {
        this.setState({notifications});
      }
    });
  };

  updateOpenPMFromOutside = (type, username, userId) => {
    console.log(type, username, userId);
    this.setState(state => ({openPMFromOutside: {username, userId, type, flag: !state.openPMFromOutside.flag}}));
  };

  updateOpenPMFromOutside_ORIG = (username, userId) => {
    console.log(username, userId);
    this.setState(state => ({openPMFromOutside: {username: username, userId: userId, flag: !state.openPMFromOutside.flag}}));
  };

  handleLink = (link, div) => {
    //console.log(link, div);
    this.setState({currentMenu: link}, () => {
      $('.manage').css('display', 'none');
      $(div).css('display', 'block');

      $('.item').removeClass('selected');
      $(this.state.currentMenu).addClass('selected');
    });
  };

  // To Update the community title in Top menu
  updateCommunity = (community) => {
    this.setState({community})
  };

  _getTasks = () => {
    $.ajax({
      url: '/tasks/get/' + this.props.community.id + '/' + this.props.profile.user_id,
      method: 'GET',
      dataType: 'JSON',
      success: function (tasksDB) {
        //console.log(tasksDB);
        this.setState({toDoCounter: getParticipantToDoCounter(tasksDB)});
      }.bind(this)
    });
  };
}
