import PropTypes from 'prop-types';
import React from 'react';
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import v4 from "uuid";
import ReactModal from "react-modal";
import moment from 'moment';
import axios from 'axios';

import AVTask01 from "../../../components/Community/Activities/ActivityView/AVTask01";
import TB_ActivityCard from "../../../components/Community/Transcript/T_Body/TB_ActivityCard";
import {getCSRF, getParticipantToDoCounter, showSuccessMsg} from "../../../components/UI/Globals/PX_Funs";
import AVNavigator from "../../../components/Community/Activities/ActivityView/AVNavigator";
import AE_M_Video from "../../../components/Community/Activities/ActivityEditor/AE_Modal/AE_M_Video";

export default class PCActivity01 extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    cmntyLanguage: PropTypes.string.isRequired,
    updateToDoCounter: PropTypes.func.isRequired,
    scroll: PropTypes.string,
    type: PropTypes.string,
    item_scroll: PropTypes.object,
    from: PropTypes.string,
    data_signature: PropTypes.object,
    colors: PropTypes.object.isRequired,
    helpStores: PropTypes.object.isRequired,
    comunitySocial: PropTypes.bool.isRequired,
    base_url_ai: PropTypes.string.isRequired,
    cmntyAutoModeration: PropTypes.bool.isRequired,
    cmntyAutoModerationCount: PropTypes.number.isRequired,
    cmntyProjectType: PropTypes.string.isRequired,
    cmntyAIPlan: PropTypes.string.isRequired,
    username: PropTypes.string,
    qc: PropTypes.array.isRequired,
    subdomain: PropTypes.string.isRequired,
    communityId: PropTypes.number,
    cmtyAiAllowPictures: PropTypes.bool.isRequired,
    allowImageAutoModeration: PropTypes.bool.isRequired,
    company: PropTypes.object.isRequired,
    naturalUsername: PropTypes.string,
    cu: PropTypes.number,
    updateCurrentUser: PropTypes.func,
    aiAgentName: PropTypes.string
  };

  constructor(props) {
    super(props);
    let activeTaskId = null;
    //console.log(props.item_scroll);
    if(props.item_scroll){
      activeTaskId = (props.item_scroll.type === 'response' || props.item_scroll.type === 'likeResponse') ?
        props.item_scroll.id : props.item_scroll.taskId
    } else {
      //console.log(props.task.questions);
      activeTaskId = props.task.questions[0].id
    }

    this.state = {
      responses: [],
      activeTaskId: activeTaskId,
      showModal: false,
      modalType: null,
      modalQuestionId: null,
      scrollFirstTime: true,
      isSavingResponse: false,
      helpStores: this.props.helpStores,
      isActDone: false,
      aiComment: {questionId: null, comment: ''},
      maAnnotations: {},
      microChatThread: {},
      canvasObjForOPS: {}
    };
  }

  componentDidMount(){
    this._getResponses();
  }

  render() {
    const { task, profile, language, cmntyLanguage, scroll, type, item_scroll, from, colors, comunitySocial,
      cmntyAutoModeration, username, qc, base_url_ai, company, naturalUsername} = this.props
    //console.log('responses:', this.state.responses);
    const questions = task.questions.map((question, index) => {
      //console.log(question);
      const response = this.state.responses.find(response => {
        //console.log('response:',response);
        if(response.response){
          return response.response.question_id === question.id
        } else {
          return response.question_id === question.id
        }
      });

      const now = moment().format('YYYY-MM-DD');
      let blockerExpired = null;
      if(task.expire){
        const expireDate = moment(task.expire).format('YYYY-MM-DD');

        if(now > expireDate){
          blockerExpired = task.overdue;
        } else {
          blockerExpired = false;
        }
      } else {
        blockerExpired = false;
      }

      let avTask = <AVTask01 question={question}
                             cardinal={index + 1}
                             communityId={this.props.communityId}
                             userId={profile.user_id}
                             language={language}
                             cmntyLanguage={cmntyLanguage}
                             participants_view={task.participants_view}
                             response={response}
                             onEditorStateChange={this.onEditorStateChange}
                             saveResponse={this.saveResponse}
                             questionsLength={task.questions.length}
                             updateShowBlocker={this.updateShowBlocker}
                             onDrop={this.onDrop}
                             deleteResponseImage={this.deleteResponseImage}
                             updateShowModal={this.updateShowModal}
                             deleteVideo={this.deleteVideo}
                             updateResponseMapsId={this.updateResponseMapsId}
                             blockerExpired={blockerExpired}
                             updateShowComments={this.updateShowComments}
                             updateResponseComments={this.updateResponseComments}
                             taskSocialStatus={task.social}
                             updateChoiceItems={this.updateChoiceItems}
                             scrollFirstTime={this.state.scrollFirstTime}
                             updateScrollFirstTime={this.updateScrollFirstTime}
                             scroll={scroll}
                             setActiveTaskId={this.setActiveTaskId}
                             type={type}
                             item_scroll={item_scroll}
                             from={from}
                             isSavingResponse={this.state.isSavingResponse}
                             updateIsSavingResponse={this.updateIsSavingResponse}
                             updateOETextAndSave={this.updateOETextAndSave}
                             colors={colors}
                             helpStores={this.state.helpStores}
                             updateHelpStore={this.updateHelpStore}
                             comunitySocial={comunitySocial}
                             cmntyAutoModeration={cmntyAutoModeration}
                             aiComment={this.state.aiComment}
                             username={username}
                             naturalUsername={naturalUsername}
                             qc={qc}
                             maAnnotations={this.state.maAnnotations}
                             updateMaAnnotations={this.updateMaAnnotations}
                             microChatThread={this.state.microChatThread}
                             updateMicroChatThread={this.updateMicroChatThread}
                             updateResponseChat={this.updateResponseChat}
                             avatar={profile.avatar === '' ? '/assets/user.png' : profile.avatar}
                             updateFailedChat={ this.updateFailedChat}
                             base_url_ai={base_url_ai}
                             company={company}
                             addResponseEmbeddingAndModeration={this.addResponseEmbeddingAndModeration}
                             setCanvasObjForOPS={this.setCanvasObjForOPS}
                             aiAgentName={this.props.aiAgentName}/>

      if(task.participants_view !== 'As a list'){
        //console.log(this.state.activeTaskId, question.id);
        if(this.state.activeTaskId !== question.id){
          avTask = null;
        }
      }
      //console.log(avTask);
      return (
        <div key={index} className="row">
          { avTask }
        </div>
      )
    });

    let responseStates = [];
    if(task.participants_view === 'One per screen'){
      responseStates = this.state.responses.map(responseObj => responseObj.response ? responseObj.response.state : '');
    }

    return (
      <div className="px-resp-part-cont">
        <TB_ActivityCard title={task.title}
                         instructions={task.instructions}
                         from='Participant'
                         participant_view = {this.props.task.participants_view}
                         colors={colors}/>
        {
          this.props.task.participants_view === 'One per screen' &&
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <AVNavigator questions={task.questions}
                           activeTaskId={this.state.activeTaskId}
                           setActiveTaskId={this.setActiveTaskId}
                           responseStates={responseStates}
                           colors={colors}
                           responses={this.state.responses}
                           language={language}
              />
            </div>
          </div>
        }
        { questions }
        {
          this.state.modalType &&
          <ReactModal isOpen={this.state.showModal} contentLabel="Participant Activity General Modal"
                      shouldCloseOnOverlayClick={this.state.modalType !== 'video'}
                      onRequestClose={() => this.updateShowModal(null, null)}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            {this._setModalBody()}
          </ReactModal>
        }
      </div>
    );
  }

  updateResponseAttachments = (question_id, newAttachments) => {
    this.setState((prevState) => {
      const responses = prevState.responses.map(response =>
        response.question_id === question_id
          ? { ...response, attachments: newAttachments }
          : response
      );
      return { responses };
    });
  };

  updateFailedChat = (response_id, chat_id) => {
    // console.log('response_id:', response_id, 'chat_id:', chat_id)
    const responses = this.state.responses.map(response => {
      if(response.id === response_id){
        const chats = response.response.chats.map(chat => chat_id === chat.id ? chat.state_1 = 'failed' : chat)
        return { ...response, response: {...response.response, chats}}
      } else {
        return response
      }
    })

    this.setState(responses)
  }

  updateResponseChat = (response_id, chat) => {
    // console.log(response_id, chat)
    const responses = this.state.responses.map(response => {
      if(response.id === response_id){
        return { ...response, response: {...response.response, chats: {...response.response.chats.push(chat)}} }
      } else {
        return response
      }
    })

    this.setState(responses)
  }

  updateHelpStore = (item, value) => {
    $.ajax({
      url: `/community_users/help_stores/${this.props.task.community_id}/${this.props.profile.user_id}/${item}/${value}`,
      method: 'PATCH',
      beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: helpStores => {
        // console.log(helpStores);
        // this.setState({ helpStores} );
        this.setState({ helpStores: helpStores });

      }
    });
  }

  updateIsSavingResponse = () => {
    this.setState(prevState => ({isSavingResponse: !prevState.isSavingResponse}));
  };

  updateScrollFirstTime = (scrollFirstTime) => {
    this.setState({scrollFirstTime});
  };

  updateChoiceItems = (event, questionId, type, answerId) => {
    //console.log(questionId, type, answerId);
    let name = event.target.name;
    name = name !== 'other' ? name.slice(0, -(answerId.length)) : name;
    //console.log('name:', name);
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    //console.log(name, value);

    //console.log(questionId, type, answerId);
    const responses = this.state.responses.map(response => {
      if(response.question_id === questionId){
        if(type === 'Single'){
          const items = [];
          //items[0] = answerId;
          items.push({id: answerId, other: name === 'other' ? value : ''});
          //console.log(items);
          return {...response, selectedItemsId: items}
        } else {
          //console.log(response.selectedItemsId);
          const isFound = response.selectedItemsId.find(item => item.id === answerId);
          //console.log('isFound: ', isFound);
          let items = [...response.selectedItemsId];
          if(isFound !== undefined){
            if(name !== 'other'){
              items = response.selectedItemsId.filter(item => item.id !== answerId);
            } else {
              items = response.selectedItemsId.map(item => item.id === answerId ?
                {...item, other: value} : item);
            }

          } else {
            items.push({id: answerId, other: name === 'other' ? value : ''});
          }
          //console.log(items);
          return {...response, selectedItemsId: items};
        }
      } else {
        return response;
      }
    });

    this.setState({responses});
  };

  /**** COMMENTS ****/
  updateResponseComments = (comments, responseDB, question_id) => {
    // console.log(comments, responseDB, question_id);
    const responseToUpdate = {...this.state.responses.find(response => response.question_id === question_id)};
    //console.log(responseToUpdate);
    let responses;

    if(isNaN(responseDB)){
      //console.log('new response: ', responseDB);
      responses = this.state.responses.map(response => response.question_id === responseToUpdate.question_id ?
        {...response, id: responseDB.id, response: {...responseDB, question_id: question_id}, comments: comments} : {...response});
    } else {
      //console.log('just update comments responseDB is an ID', responseDB);
      responses = this.state.responses.map(response => response.question_id === responseToUpdate.question_id ?
        {...response, comments: comments} : {...response});
    }
    //console.log(responses);

    this.setState({responses});
  };

  updateShowComments = (question_id) => {
    const responseToUpdate = {...this.state.responses.find(response => response.question_id === question_id)};
    //console.log(responseToUpdate);
    const responses = this.state.responses.map(response => response.question_id === responseToUpdate.question_id ?
      {...response, showComments: !responseToUpdate.showComments} : {...response});

    this.setState({responses}, () => {
      if(responseToUpdate.sum_unseen > 0){
        $.ajax({
          url: '/comment/update_alert/' + responseToUpdate.response.id,
          method: 'PATCH',
          dataType: 'JSON',
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: comments => {
            // console.log(comments);
            const responses = this.state.responses.map(response => response.question_id === responseToUpdate.question_id ?
              {...response, comments: comments, sum_unseen: 0} : response);

            this.setState({responses});
          }
        });
      }
    });
  };

  /**** MAPS ****/
  updateResponseMapsId = (mapId, question_id) => {
    // console.log(mapId, question_id);
    const responseToUpdate = {...this.state.responses.find(response => response.question_id === question_id)};
    //console.log(responseToUpdate);
    const isFound = responseToUpdate.selectedItemsId.find(itemId => itemId === mapId);

    if(isFound === undefined){
      responseToUpdate.selectedItemsId.push(mapId);
    } else {
      responseToUpdate.selectedItemsId.splice(responseToUpdate.selectedItemsId.indexOf(mapId), 1);
    }
    // console.log(responseToUpdate.selectedItemsId);

    const responses = this.state.responses.map(response => response.question_id === responseToUpdate.question_id ?
      {...responseToUpdate} : {...response});

    this.setState({responses});
  };

  /**** VIDEO ****/
  deleteVideo = (question_id) => {
    // console.log(question_id);
    const responses = this.state.responses.map(response => {
      if(response.question_id === question_id){
        if(response.response){
          return {...response,
            video: null,
            videoState: undefined,
            response: {...response.response, video_uuid: '', video_thumbnail: '', video_url: ''}}
        } else {
          return {...response,
            video: null,
            videoState: undefined
          }
        }
      } else {
        return response;
      }
    });

    this.setState({responses});
  };

  updateVideo = (video, type, question_id) => {
    // console.log(video, 'type', type, 'question_id', question_id);
    const responses = this.state.responses.map(response => response.question_id === question_id ?
      {...response, videoState: type, video: video} : response);

    this.setState({responses});
  };

  updateShowModal = (modalType, modalQuestionId) => {
    // console.log(modalType, modalQuestionId);
    this.setState(state => ({showModal: !state.showModal, modalType, modalQuestionId}));
  };

  /**** TASK (Question) ****/
  updateShowBlocker = (question_id) => {
    //console.log(question_id);
    const responses = this.state.responses.map(response => response.question_id === question_id ?
      {...response, showBlocker: !response.showBlocker} : response);

    this.setState({responses});
  };

  /**** IMAGES ****/
  deleteResponseImage = (index, attachmentId, question_id) => {
    // console.log(index, attachmentId, question_id);
    const responses = this.state.responses.map(response => {
      if(response.question_id === question_id){
        const responseFiles = response.attachments;
        responseFiles.splice(index, 1);
        // console.log(responseFiles);
        response.attachmentsToDelIds.push(attachmentId);

        return {...response, attachments: responseFiles}
      } else {
        return response;
      }
    });
    this.setState({responses});
  };

  onDrop = (acceptedFiles, question_id) => {
    // console.log(acceptedFiles, question_id);

    const responses = this.state.responses.map(response => {
      if(response.question_id === question_id){
        const responseFiles = response.attachments;
        acceptedFiles.forEach(attachment => {
          Object.assign(attachment, {preview: URL.createObjectURL(attachment)});
          responseFiles.push({attachment, imgState: 'Unsaved', uuid: v4()})
        });

        return {...response, attachments: responseFiles}
      } else {
        return response;
      }
    });

    this.setState({responses});
  };

  /**** RESPONSE ****/
  saveResponse = async (state, question_id, userId, cardinal, from, canvasObj = {}, microChatThread = '') => {
    // console.log('Initiating saveResponse with state:', state, 'question_id:', question_id);
    const {base_url_ai, username, naturalUsername, language} = this.props

    const response = this.state.responses.find(response => response.question_id === question_id);
    const communityId = this.props.communityId;

    // Prepare response JSON differently based on the question type
    const responseJSON = await this.prepareResponseJSON(question_id, response, state, canvasObj);

    // Updating the state to show saving progress
    this.setState({ isSavingResponse: true });

    // Update response in database
    try {
      const responseDB = await this.updateResponseInDB(question_id, responseJSON);
      this._getTasks();
      // console.log('Response updated in DB:', responseDB);

      // Save images associated to responses
      if(response.question_type === 'Canvas' && canvasObj.annotationImg !== '') {
        // Save image with canvas annotations
        await this.handleCanvasImageAttachment(
          canvasObj,
          responseDB,
          response.question_type,
          responseDB.task_tile
        );
      } else if (response?.attachments?.length !== 0 || response?.attachmentsToDelIds?.length > 0) {
        // Save images attached to response
        await this.handleImageAttachments(
          response.attachments,
          responseDB,
          question_id,
          response.question_type,
          responseDB.task_tile,
          response.attachmentsToDelIds
        );
      }

      // Update front end accordingly with finalizeResponseProcessing
      this.finalizeResponseProcessing(from, question_id, state, response, responseDB, userId, cardinal);

      // Try to get natural username and update frontend if necessary
      if (this.props.cmntyAIPlan &&
        (response.question_type === 'Open End' || response.question_type === 'MicroChat') &&
        (!naturalUsername || !naturalUsername.trim())) {
        const response_text = responseDB.response.question_type === 'MicroChat'
          ? responseDB.response.chats.filter(chat => chat.kind !== 'AI').map(chat => chat.body).join('; ')
          : responseDB.response.data.answer;

        try {
          if (response_text) {
            await this.getAndSetName(response_text, 'gpt-4o-mini', { cu_id: this.props.cu });
            const currentUser = await axios.get(`/users/user_account_community/${communityId}`).then(r => r.data);
            this.props.updateCurrentUser(currentUser);
          }
        } catch (error) {
          console.error('Error in getAndSetName or updating user account:', error);
        }
      }

      // Trigger background jobs for AI image processing, auto moderation and retrying natural name proc.
      if (responseJSON.state === 'Completed') {
        // console.log('response DB ', responseDB)
        axios.post(`/responses/post_process`,
          { response_id: responseDB.id,
            question_id: question_id,
            community_id: communityId,
            text_choices: responseJSON?.textChoices || '',
            all_text_choices: responseJSON?.allTexAatChoices || '',
            subdomain: this.props.subdomain,
            moderation_type: 'response'
          }, getCSRF())
          .then(() => console.log("Post-processing job triggered"))
          .catch(error => console.error("Failed to trigger post-processing job:", error));
      }

      // Create or update AI summary for MicroChats
      if (response.question_type === 'MicroChat') {
        this.updateAISummary(base_url_ai, responseDB.id, question_id, username, naturalUsername, language);
      }

    } catch (error) {
      console.error('Failed during response saving:', error);
    }
  }


  prepareResponseJSON = (question_id, response, state, canvasObj) => {
    let responseJSON = null;
    let textChoices = ''

    switch(response.question_type){
      case 'Open End':
      case 'Multimedia':
        const text = response.question_type === 'Open End' ?
          response.editorState.getCurrentContent().getPlainText() : 'multimedia'
        const textHTML = response.question_type === 'Open End' ?
          draftToHtml(convertToRaw(response.editorState.getCurrentContent())) : '<p>multimedia</p>'
        const video_uuid = response.video ? response.video.uuid : response.response ? response.response.video_uuid : '';
        const video_url = response.video ? response.video.medias.mp4 : response.response ? response.response.video_url : '';
        const video_thumbnail = response.video ? response.video.medias.thumb : response.response ?
          response.response.video_thumbnail : '';
        const s3Url = 'https://pixiebob-videos.s3-ap-southeast-2.amazonaws.com/purge/a-96413510-77a7-0135-1986-0a76e15fb04a/' +
          video_uuid;
        const video_url_purge = s3Url + '_mp4.mp4';
        const video_thumb_purge = s3Url + '_thumb.jpg';

        responseJSON = {
          state: state,
          data: { answer: text, answerHTML:  textHTML.replace(/\uFFFD/g, '')},
          map_items_id: response.selectedItemsId.length !== 0 ? response.selectedItemsId : [''],
          video_uuid, video_url, video_thumbnail, video_url_purge, video_thumb_purge
        };
        break;
      case 'Choice':
        let answersId = null;
        answersId = response.selectedItemsId;
        const data = response.selectedItemsId.length !== 0 ? {answersId: response.selectedItemsId} : null
        let choiceOptions = null
        let choiceOptionsInString = null
        let userOptionsArr = []
        this.props.task.questions.forEach(question =>  {
          if(question.id === question_id){
            choiceOptions = question.data.answers.map(a => ({id: a.id, text: a.text}))
            choiceOptionsInString = choiceOptions.map((choice, index) => `${index + 1}. ${choice.text}`)
              .join(', ');
            // console.log('choice options in string', choiceOptionsInString)
          }
        })
        // console.log('choiceOptions:', choiceOptions, 'responseId OBJ:', response.selectedItemsId)
        choiceOptions.forEach(opt => {
          response.selectedItemsId.forEach(userOpt => {
            if(opt.id === userOpt.id){
              userOptionsArr.push(opt.text !== 'Other Option' ? opt.text : userOpt.other)
            }
          })
        })

        textChoices = userOptionsArr.join(", ")
        responseJSON = { state: state, data, textChoices: textChoices, allTextChoices: choiceOptionsInString }
        // console.log('textChoices:', textChoices, 'allTextChoices', choiceOptionsInString)
        break;
      case 'Canvas':
        responseJSON = {
          state,
          data: { canvasObj:  { annotations: canvasObj.annotations } }
        }
        break
      case 'MicroChat':
        responseJSON = {
          state: state,
          data: { answer: '' }
        };
        break;
    }
    return responseJSON;
  }

  updateResponseInDB = async (question_id, responseJSON) => {
    const url = `/responses/update/${question_id}/participant`;
    return $.ajax({
      url: url,
      method: "PATCH",
      dataType: "JSON",
      data: { responseJSON },
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      }
    }).promise();
  }

  finalizeResponseProcessing = (from, question_id, state, response, responseDB, userId, cardinal) => {
    // Process completion UI updates

    if (!response || !response.question_type) {
      console.error("Invalid response object or missing question_type:", response);
      return;
    }

    if(from !== 'navigation'){
      if(response.question_type !== 'MicroChat'){
        showSuccessMsg('Success!')
      }
    }

    const responses = this.state.responses.map(response => response.question_id === responseDB.question_id ?
      { ...response, ...responseDB, attachments: response.attachments, showBlocker: state === 'Completed' } :
      response
    );

    if(from !== 'navigation'){
      this.setState({activeTaskId: question_id, responses, isSavingResponse: false}, () => {
        if(response.question_type !== 'MicroChat'){
          showSuccessMsg('Success!')
        }
        this.updateIsActDone();
        if(state === 'Completed'){
          //if(task.participants_view === 'As a list'){
          this.scrollNexTask(userId, cardinal)
          //}
        }
      });
    } else {
      this.setState({responses, isSavingResponse: false}, () => {
        this.updateIsActDone();
        if(state === 'Completed'){
          this.scrollNexTask(userId, cardinal);
        }
      });
    }
  }

  handleImageAttachments = async (attachments, responseDB, question_id, questionType = '', questionTitle = '', attachmentsToDelIds = []) => {
    // console.log('handling image attachments', attachments);

    if (attachmentsToDelIds.length > 0) {
      await this.disableAttachments(attachmentsToDelIds)
    }

    const responseId = responseDB.id;
    const responseState = responseDB.response.state;
    const promises = attachments.map(async (attachment) => {
      const pre_signed = await this.getPreSignedUrl(attachment, responseId);

      if (attachment.id === undefined && attachment.imgState === 'Unsaved') {
        const newAttachments = await this.saveFile(attachment, responseId, pre_signed, responseState, questionType, questionTitle);
        this.updateResponseAttachments(question_id, newAttachments);
      }
    });

    return Promise.all(promises);
  }

  handleCanvasImageAttachment = (canvasObj, responseDB, questionType = '', questionTitle = '') => {
    const responseId = responseDB.id;
    const responseState = responseDB.response.state;

    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/responses/get_pre_signed/' + responseId,
        method: 'GET',
        dataType: 'JSON',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: async (pre_signed) => {
          if (canvasObj.annotationImg !== undefined) {
            const res = await fetch(canvasObj.annotationImg);
            const buf = await res.arrayBuffer();
            const file = new File([buf], 'canvas', {type: 'image/jpeg'});
            await this.saveFile(file, responseId, pre_signed, responseState, questionType, questionTitle);
            resolve();
          } else {
            resolve();
          }
        },
        error: (err) => reject(err)
      });
    });
  }

  getPreSignedUrl = async (attachment, responseId, questionType = '') => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/responses/get_pre_signed/${responseId}`,
        method: 'GET',
        dataType: 'JSON',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: (data) => {
          // console.log('Pre-signed URL received:', data);
          resolve(data);
        },
        error: (error) => {
          console.error('Error fetching pre-signed URL:', error);
          reject(error);
        }
      });
    });
  }

  updateAISummary = (base_url_ai, responseId, taskId, username, naturalUsername, language) => {
    const url = `${base_url_ai}responses/update_ai_summary/${responseId}/${taskId}/${username}/${naturalUsername}/${language}`
    axios.post(url, {}, getCSRF())
      .then(r => {
        // console.log(r.data)
      })
      .catch(e => console.log(e))
  }

  addResponseEmbeddingAndModeration = (responseId, textChoices, allTextChoices,
                                       state, questionId, question_id, moderation_type) => {
    const {base_url_ai, cmntyAutoModeration, cmntyAutoModerationCount, cmntyLanguage, communityId, subdomain,
      cmntyProjectType, allowImageAutoModeration} = this.props
    const url = `${base_url_ai}response/create_embedding/${responseId}`
    const task = this.props.task.questions.find(question => question.id === question_id)
    const data = {
      text_choices: textChoices || '',
      all_text_choices: allTextChoices || '',
      allow_img_auto_moderation: allowImageAutoModeration || false,
      ai_auto_moderation: cmntyAutoModeration,
      community_moderation_counter: cmntyAutoModerationCount,
      language: cmntyLanguage,
      state,
      subdomain,
      project_type: cmntyProjectType,
      task_ai_auto_moderation: task.ai_auto_moderation,
      moderation_type
    }

    axios.post(url, data, getCSRF())
      .then(r => {
        const obj = r.data
        // console.log('obj:', obj)
        if(obj.comment_id !== -1){
          this.getOneComment(responseId, communityId, obj.comment_id, question_id)
        }
      })
      .catch(e => {
        if (e.response) {
          console.log('Error response:', e.response.data);
        } else {
          console.log('Error:', e.message);
        }
      })
  }

  getOneComment = (responseId, communityId, commentId, questionId) => {
    axios.get(`/comment/show/${responseId}/${communityId}/${commentId}`)
      .then(r => {
        const comment = r.data.comment
        // console.log('comment:', comment)
        const responses = this.state.responses.map(r => r.question_id === questionId ?
          { ...r, comments: r.comments.unshift(comment)} : r
        )
        this.setState(responses, () => {
          this.updateShowComments(questionId)
        })
      }).catch(e => console.log(e))
  }

  saveFile = async (newAttachment, responseId, pre_signed, responseDBState, questionType = "", questionTitle = "") => {
    const { base_url_ai, cmntyLanguage, cmtyAiAllowPictures, question } = this.props;
    const fd = new FormData();
    Object.keys(pre_signed.fields).forEach(key => {
      fd.append(key, pre_signed.fields[key]);
    });
    fd.append('Content-Type', newAttachment.attachment ? newAttachment.attachment.type : newAttachment.type);
    fd.append('file', newAttachment.attachment ? newAttachment.attachment : newAttachment);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'document'
    };

    try {
      // Post the file to the pre-signed URL
      const response = await axios.post(pre_signed.url, fd, config);
      const attachmentUrl = response.data.getElementsByTagName("Location")[0].innerHTML;

      // Create the attachment record in the database
      const attachmentData = {
        entity_id: responseId,
        entity_type: 'Response',
        url: attachmentUrl,
        uuid: newAttachment.uuid ? newAttachment.uuid : v4(),
      };
      const createAttachmentResponse = await axios.post('/attachments/create', attachmentData, {
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}
      })
      return createAttachmentResponse?.data?.attachments
      // const createdAttachmentId = attachmentResponse.data.attachment.id;
    } catch (error) {
      console.error('Error handling file upload and processing:', error);
    }
  }

  disableAttachments = async (attachmentsToDelIds) => {
    try {
      const attachmentsData = {
        attachment_ids: attachmentsToDelIds || []
      };
      const attachmentResponse = await axios.patch('/attachments/disable', attachmentsData, {
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content') }
      });
    } catch (error) {
      console.error('Error disabling attachment:', error);
    }
  }

  /**** TEXT ****/
  updateOETextAndSave = (editorState, question_id, percentage, state, userId, cardinal) => {
    let responses;

    if(this.props.task.participants_view === 'One per screen'){
      responses = this.state.responses.map(response => response.question_id === this.state.activeTaskId ?
        {...response, editorState, percentage: percentage} : response);
    } else {
      responses = this.state.responses.map(response => response.question_id === question_id ?
        {...response, editorState, percentage: percentage} : response);
    }

    this.setState({responses});
  };

  onEditorStateChange = (editorState, question_id) => {
    // console.log(editorState, question_id);
    const body = editorState.getCurrentContent().getPlainText();  //It counts 'Enter' key
    const question = this.props.task.questions.find(question => question.id === this.state.activeTaskId);
    const minChar = parseInt(question.data.minChar);
    const percentage = (100 * body.length) / minChar;
    // console.log( body.length, minChar, percentage);
    let responses = [];
    // Why this if?, If I already have the question_id
    if(this.props.task.participants_view === 'One per screen'){
      responses = this.state.responses.map(response => response.question_id === this.state.activeTaskId ?
        {...response, editorState, percentage: body.length <= minChar ? percentage : 100} : response);
    } else {
      responses = this.state.responses.map(response => response.question_id === question_id ?
        {...response, editorState, percentage: body.length <= minChar ? percentage : 100} : response);
    }
    //console.log(percentage);
    this.setState({responses});
  };

  /**** UTILS ****/
  setActiveTaskId = activeTaskId => {
    const currentActiveTaskId = this.state.activeTaskId;

    this.setState({activeTaskId}, () => {
      const response = this.state.responses.find(response => response.question_id === currentActiveTaskId);
      if(response?.response){
        // console.log('response.response.state:', response.response.state)
        if(response.response.state === 'Draft' || response.response.state === 'Empty'){
          this.saveResponse('Draft', currentActiveTaskId, null, null, 'navigation', this.state.canvasObjForOPS);
        }
      } else {
        this.saveResponse('Draft', currentActiveTaskId, null, null, 'navigation', this.state.canvasObjForOPS);
      }
    });
  };

  scrollNexTask = (userId, cardinal) => {
    //console.log('userId:', userId, 'cardinal:', cardinal);
    const {task} = this.props;
    const questions = task.questions;
    //console.log('questions:', questions, 'this.state.activeTaskId:', this.state.activeTaskId);
    const currentIndexOf = questions.findIndex(question => question.id === this.state.activeTaskId);
    //console.log('currentIndexOf:', currentIndexOf);

    if(task.participants_view === 'One per screen'){
      //const questions = task.questions;
      //const currentIndexOf = questions.findIndex(question => question.id === this.state.activeTaskId);
      //console.log(currentIndexOf);
      if(currentIndexOf < questions.length - 1){
        this.setState({activeTaskId: questions[currentIndexOf + 1].id}, () => {
          (window).scrollTo({top: 0, behavior: 'smooth'});
        })
      }
    } else {
      //console.log('task.questions.length:', task.questions.length, 'cardinal:', cardinal);
      if(cardinal < task.questions.length){
        const nextCardinal = cardinal + 1;
        // 'IF'Just in case, remember sometimes the 'position' of the task can have some repetitive values as 0-1-1-2-2
        // try to review in the Activity Builder, by the way it works!
        if(questions[currentIndexOf + 1] !== undefined){
          const id = '#' + userId + '_' + questions[currentIndexOf + 1].id;
          //console.log(id);
          const cardPosition = $(id).offset().top;
          //console.log(cardPosition);
          (window).scrollTo({top: cardPosition - 75, behavior: 'smooth'});
        }
      }
    }
  };

  _getResponses = () => {
    const tasksId = this.props.task.questions.map(question => question.id);
    //console.log('tasksId:', tasksId)

    $.ajax({
      url: '/responses/get',
      method: 'GET',
      dataType: 'JSON',
      data: {
        questionsId: tasksId
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: responsesDB => {
        const responses = responsesDB.map(responseDB => {
          //console.log('responseDB:', responseDB);
          let responseByType = null;

          switch(responseDB.question_type){
            case 'Open End':
            case 'Multimedia':
              responseByType = {...responseDB,
                editorState: this._setEditorState(responseDB.response),
                percentage: 0,
                showComments: false,
                camera: null, //Why 2 camera and video?
                video: null,
                newVideoThumbnail: null,
                selectedItemsId: responseDB.response ? responseDB.response.map_items_id ?
                  responseDB.response.map_items_id.map(id => id) : [] : [],
                showBlocker: responseDB.response ?
                  (responseDB.response.state === 'Completed' || responseDB.response.state === 'Accepted') : false,
                attachmentsToDelIds: []
              };
              break;
            case 'Choice':
              responseByType = {
                ...responseDB,
                selectedItemsId: responseDB.response ? responseDB.response.data.answersId : [],
                showComments: false,
                showBlocker: responseDB.response ?
                  (responseDB.response.state === 'Completed' || responseDB.response.state === 'Empty' || responseDB.response.state === 'Accepted') : false
              };
              break;
            case 'Canvas':
              responseByType = {
                ...responseDB,
                showComments: false,
                showBlocker: responseDB.response ?
                  (responseDB.response.state === 'Completed' || responseDB.response.state === 'Accepted') : false
              };
              break;
            case 'MicroChat':
              responseByType = {
                ...responseDB,
                showComments: false,
                showBlocker: responseDB.response ?
                  (responseDB.response.state === 'Completed' || responseDB.response.state === 'Accepted') : false
              };
              break;
          }

          //console.log(responseByType);
          return responseByType;
        });

        this.setState({responses}, () => {
          // this.updateIsActDone();
        });
      }
    });
  };

  updateIsActDone = () => {
    const tasksId = this.props.task.questions.map(question => question.id);
    const responsesState = this.state.responses.map(r => r.response).filter(r => r).map(r => r.state);
    //console.log('responsesState:', responsesState);
    const acceptedCompleted = responsesState.filter(s => s === 'Accepted' || s === 'Completed');
    //console.log('acceptedCompleted:', acceptedCompleted);
    // this.setState({isActDone: tasksId.length === acceptedCompleted.length});
    if(tasksId.length === acceptedCompleted.length) {
      this.setState({showModal: true, modalType: 'info'});
    }
  }

  _setEditorState(response){
    //console.log('response:', response)
    let component

    if(response){
      const answer = response.data.answerHTML ? response.data.answerHTML : '';
      const contentBlock = htmlToDraft(answer);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

      component = EditorState.createWithContent(contentState);
    } else {
      component = EditorState.createEmpty();
    }

    return component
  }

  // To update the TO-DO counter at left nav-bar
  _getTasks = () => {
    $.ajax({
      url: '/activities/part_dash_get_activities/' + this.props.task.community_id,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: tasksDB => {
        // console.log('tasksDB:', tasksDB);
        this.props.updateToDoCounter(getParticipantToDoCounter(tasksDB));
      }
    });
  };

  // ANTI-PATTERN FOR MULTI MODAL
  _setModalBody() {
    let content = null;
    let colors = this.props.colors;
    let i18n = {
      en: {activityCompletedTitle1: 'Well done!',
        activityCompletedTitle12: 'You\'ve finished this activity',
        activityCompletedDetail: 'Now you can go back to the Main Panel to see more activities.',
        confirmBtn: 'More activities', cancelBtn: 'Stay here' },
      es: {activityCompletedTitle1: '¡Bien hecho!',
        activityCompletedTitle2: 'Has completado esta actividad',
        activityCompletedDetail: 'Ahora puedes regresar al Panel Principal para ver más actividades.',
        confirmBtn: 'Más actividades', cancelBtn: 'Permanece aquí'}
    }

    switch(this.state.modalType){
      case 'video':
        content = <AE_M_Video updateShowModal={this.updateShowModal}
                              language={this.props.language}
                              title={'Video'}
                              from={'Participant'}
                              updateVideo={this.updateVideo}
                              questionId={this.state.modalQuestionId}
                              data_signature={this.props.data_signature}
                              colors={this.props.colors}/>;
        break;
      case 'info':
        content = <div className="modal-content">
          <div className="modal-header">
            <h4 className="px-modal-title">{i18n[this.props.language].activityCompletedTitle1}</h4>
          </div>
          <div className="modal-body px-modal-body-add-activity">
            <div className="row">
              <div className="col-md-12" style={{textAlign: 'center'}}>
                <img src='/assets/fireworks_color.svg' className="" alt="" style={{height: '100px'}}/>
                <h4 className="px-modal-title" style={{marginTop: '25px'}}>{i18n[this.props.language].activityCompletedTitle2}</h4>
              </div>
            </div>
          </div>
          <div className="modal-footer px-modal-footer-gray">
            <div className="px-footer-container-buttons row">
              <button type="button"
                      className="px-btn sm btn-blue-base" style={{backgroundColor: colors.color1[3], width: 'auto', margin:'0 10px 15px 10px'}}
                      onClick={() => {this._handleActivityInfoModal('confirm')}}>
                {i18n[this.props.language].confirmBtn}
              </button>
              <button type="button"
                      className="px-btn sm btn-gray-darker inverted" style={{ width: 'auto', margin:'0 10px 15px 10px'}}
                      data-dismiss="modal"
                      onClick={this._handleActivityInfoModal}>
                {i18n[this.props.language].cancelBtn}
              </button>
            </div>
          </div>
        </div>
        break;
    }

    return <div className="modal-dialog">
      { content }</div>;
  }

  _handleActivityInfoModal = (from) => {
    let communityId = this.props.task.community_id;
    if (from === 'confirm') {
      window.location.href = '/communities/participant/' + communityId;
    } else {
      this.setState({showModal: false, modalType: null});
    }
  }

  updateMaAnnotations = (annotationsObj) => {
    this.setState({maAnnotations: annotationsObj});
  }

  updateMicroChatThread = (microChatThreadObj) => {
    this.setState({microChatThread: microChatThreadObj});
  }

  getAndSetName = async (text, ai_model, data) => {
    const url = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/getSetName/' :
      'https://px-openai-api.herokuapp.com/getSetName/';
    const requestData = {text, ai_model, data};
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        // console.log('Name processed by Python API');
      } else {
        console.error('Name processing failed');
      }
    } catch (error) {
      console.error('Name processing failed', error);
    }
  }

  setCanvasObjForOPS = (canvasObjForOPS) => {
    this.setState({canvasObjForOPS: canvasObjForOPS});
  }

}
