import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lightbox from 'react-images';
import ReactModal from 'react-modal';
import T_ModalVideo from '../../../../Transcript/T_ModalVideo';

const MicroChatView = ({ question, cardinal, updateQuestionMode, updateShowModal, language, colors }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [showModalVideo, setShowModalVideo] = useState(false);

  useEffect(() => {
    let btnHoverColor = colors.color2[3];
    $('.action-button, .btn-draggable').mouseenter(function () {
      $(this).css({ 'background-color': btnHoverColor });
    });
    $('.action-button, .btn-draggable').mouseleave(function () {
      $(this).css({ 'background-color': '' });
    });
  }, [colors]);

  const closeLightbox = () => {
    setCurrentImage(0);
    setLightboxIsOpen(false);
  };

  const updateShowModalVideo = () => {
    setShowModalVideo((prevShowModalVideo) => !prevShowModalVideo);
  };

  const imgPreviewChViewRef = useRef(null);

  const i18n = {
      en: {
        mandatory: 'Mandatory', noMandatory: 'Optional',
        mandatoryTooltip: 'Participants are forced to respond the Task in order to complete the Activity.',
        noMandatoryTooltip: 'Participants are not forced to respond the Task in order to complete the Activity.',

      },
      es: {
        mandatory: 'Obligatorio', noMandatory: 'Opcional',
        mandatoryTooltip: 'Los participantes están obligados a responder la Tarea para completar la Actividad.',
        noMandatoryTooltip: 'Los participantes no están obligados a responder la Tarea para completar la Actividad.',
      }
    }

    let imgUrl = '';
    let imgStyle = null;
    let images = [];

    if(typeof question.blobPreview !== 'undefined'){
      imgUrl = question.blobPreview;
      imgStyle = {display: 'block',cursor:'pointer'};
      images.push({src: imgUrl});
    } else {
      if(question.attachment.length !== 0){
        imgUrl = question.attachment[0].url;
        imgStyle = {display: 'block',cursor:'pointer'};
        images.push({src: imgUrl});
      }
    }

    let videoThumbnail = null;
    if(question.videoState){
      videoThumbnail = question.videoState === 'processed' ? question.video_thumbnail : '/assets/loader_200x200.gif';
    } else {
      videoThumbnail = question.video_thumbnail;
    }

    return (
        <div className="panel panel-activity-builder-details">
          <div className="px-support-tooltip top is_hidden px-support-builder">
            <p>This is a summary view of a Task. It shows, broadly, what participants are required to post in order to complete it.
              Click on the pencil icon to edit its settings.</p>
            <i/>
          </div>
          <div className="panel-body">
            <div className="info-container">
              <div className="title-task-container">
                <div className="task-number" style={{ backgroundColor: colors.color0[1] }}><span>{cardinal}</span></div>
                <span className="title-task">{question.task_type}</span>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="details-answer">{question.title.replace(/(<([^>]+)>)/ig,"")}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="media-container">
                    {
                        imgUrl !== '' &&
                        <img onClick={() => setLightboxIsOpen(true)} ref={imgPreviewChViewRef} src={imgUrl}
                             className="media-item" alt="" style={imgStyle}/>
                    }
                    {
                        question.video_thumbnail !== '' &&
                        <div className="media-item video">
                          <div className="overlay-video" onClick={updateShowModalVideo}>
                            <div className="play-icon">
                              <FontAwesomeIcon icon={['fas','play']}/>
                            </div>
                          </div>
                          <img src={videoThumbnail} alt="" className="" style={{cursor: 'pointer'}}/>
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="actions-container">
              <div className="action-item">
              <span className="btn-action left" style={{cursor:'auto'}}>
                <span>{question.required ? i18n[language].mandatory : i18n[language].noMandatory}</span>
              </span>
                <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                 data-tooltip-content={[question.required ? i18n[language].mandatoryTooltip : i18n[language].noMandatoryTooltip]} />
              </div>
            </div>
          </div>

          <div className="actions-panel">
            <div className="actions-container">
              <button onClick={() => updateQuestionMode(question.id)} className="action-button">
                <FontAwesomeIcon icon={['fas', 'pencil']}/>
              </button>
              <button onClick={() => updateShowModal('delete task', question.id)} className="action-button">
                <FontAwesomeIcon icon={['fas', 'trash']}/>
              </button>
            </div>
          </div>

          <Lightbox currentImage={currentImage}
                    images={images}
                    isOpen={lightboxIsOpen}
                    onClose={closeLightbox}/>
          {
              question.video_thumbnail &&
              <ReactModal isOpen={showModalVideo} contentLabel="Response Video"
                          shouldCloseOnOverlayClick={true} onRequestClose={updateShowModalVideo}
                          className="my-modal-content" overlayClassName="my-modal-overlay">
                <T_ModalVideo updateShowModalVideo={updateShowModalVideo}
                              thumbnail={question.video_thumbnail}
                              url={question.video_url}
                              language={language}
                              colors={colors}/>
              </ReactModal>
          }
        </div>
    );

};

MicroChatView.propTypes = {
  question: PropTypes.object.isRequired,
  cardinal: PropTypes.number.isRequired,
  communitySocial: PropTypes.bool.isRequired,
  updateQuestionMode: PropTypes.func.isRequired,
  updateShowModal: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  colors: PropTypes.object.isRequired,
};

export default MicroChatView;