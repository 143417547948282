import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dotdotdot from "react-dotdotdot";

export default class CSMAllowance extends React.Component {
  static propTypes = {
    state: PropTypes.string.isRequired,
    updateShowModalClose: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {state, language, colors} = this.props;
    const I18n = { en: {
                    cannotClose: 'You cannot close this community', 
                    cannotOpen: 'You cannot open this community',
                    limitClosed: 'You have reached the allowed limit of Closed communities. Archive some of your already Closed communities and try again.',
                    limitOpen: 'You have reached the allowed limit of Active communities (or participants across your active communities). Close some of your active communities and try again.'  
                  },
                    es: {
                      cannotClose: 'No puedes cerrar esta comunidad',
                      cannotOpen: 'No puedes abrir esta comunidad',
                      limitClosed: 'Has alcanzado el límite de comunidades que puedes mantener cerradas. Archiva algunas de tus comunidades cerradas y trata de nuevo.',
                      limitOpen: 'Has alcanzado el límite de comunidades que puedes mantener activas (o de participantes en tus comunidades activas). Cierra algunas de tus comunidades activas y trata de nuevo.'
                  }
                 }

    return (
      <div className="my-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                      onClick={this.props.updateShowModalClose}>
                <i className="material-icons">clear</i>
              </button>
            </div>
            <div className="modal-body px-modal-body-warning">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-title-warning-container">
                    <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning" style={{color: colors.color4[3]}}/>
                    <p className="px-title-warning">
                      {state === 'Enabled' ? 
                        I18n[language].cannotClose :
                        I18n[language].cannotOpen
                      }
                    </p>
                    <p className="px-checkbox-modal-warning"> 
                      {state === 'Enabled' ?
                        I18n[language].limitClosed :
                        I18n[language].limitOpen
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer px-modal-footer-warning">
              <div className="px-footer-container-buttons column">
                <button onClick={this.props.updateShowModalClose} type="button"
                        className="btn px-btn sm btn-gray-darker inverted">OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  validCloseCommunity = () => {
    if(this.props.state === 'Enabled'){
      if(this.props.title === this.state.title && this.state.isCheck01 && this.state.isCheck02){
        this.props.closeCommunity(this.props.communityId);
      }
    } else {
      if(this.props.title === this.state.title && this.state.isCheck01){
        this.props.closeCommunity(this.props.communityId);
      }
    }

  }
}
