import PropTypes from 'prop-types';
import React from 'react';
import moment from "moment";
import v4 from "uuid";
import { Tooltip as ReactTooltip } from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class MBHRows extends React.Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    i18n: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isFold: false
    }
  }

  render() {
    const {invoice, language, i18n, colors} = this.props;

    const now = moment().format();
    const start = moment(invoice.participants_start);
    const end = moment(invoice.participants_end);

    let sumCashDays = 0;
    invoice.details.map(d => {
      if(now > d.date) {
        sumCashDays += parseFloat(d.cash_day);
      }
    });
    //console.log(parseFloat(sumCashDays), parseFloat(invoice.fc), parseFloat(sumCashDays) === parseFloat(invoice.fc));

    return (
      <React.Fragment>
        <tr className={`view ${this.state.isFold ? 'open' : ''}`}
          style={{ backgroundColor: this.state.isFold && colors.color1[2] }}>
          <td>
            <a href={invoice.terms} target='_blank' style={{ color: this.state.isFold ? '#ffffff' : colors.color1[2] }}
              data-tooltip-content={i18n[language].downloadSaas}>
              {<FontAwesomeIcon icon={['fas', 'file-signature']} />}
            </a><br />
            {
              invoice.invoice_url !== '' &&
              <a href={invoice.invoice_url} target='_blank' style={{ color: this.state.isFold ? '#ffffff' : colors.color1[2] }}
                data-tooltip-content={i18n[language].downloadInvoice}>
                {<FontAwesomeIcon icon={['fas', 'receipt']} />}
              </a>
            }
          </td>
          <td onClick={this.toggleIsFold} data-tooltip-content={invoice.title}>
            <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
            {invoice.title.substring(0, 14)}
          </td>
          <td onClick={this.toggleIsFold} style={{textAlign: 'center'}}>
            <span data-tooltip-content={`${start.format('DD/MM/YYYY')} to ${end.format('DD/MM/YYYY')}`}>{end.diff(start, 'days') + 1} {i18n[language].days}</span>
          </td>
          <td onClick={this.toggleIsFold} style={{textAlign: 'center'}}>{invoice.num_participants}</td>
          <td onClick={this.toggleIsFold} style={{textAlign: 'center'}}>
            US$ {parseFloat(invoice.estimated_cost).toFixed(2)}
          </td>
          {/* <td onClick={this.toggleIsFold} style={{textAlign: 'center'}}>
            <p style={{margin: '0'}}>US$ -{parseFloat(invoice.subs_price).toFixed(2)}</p>
            <span>({invoice.sum_subs} pd)</span>
          </td> */}
          {/* <td onClick={this.toggleIsFold} style={{textAlign: 'center'}}>
            <p style={{margin: '0'}}>US$ {parseFloat(invoice.cash_price).toFixed(2)}</p>
            <span>({invoice.sum_cash} pd)</span>
          </td> */}
          {/* <td onClick={this.toggleIsFold} style={{textAlign: 'center'}}>
            <p style={{margin: '0', fontWeight: 'bold'}}>US$ {parseFloat(invoice.final_cost).toFixed(2)}</p>
            <span>
              {`(${invoice.valid_days * invoice.num_participants} - ${invoice.sum_subs})
               x ${invoice.final_unit_price.toFixed(3)}`}
            </span>
          </td> */}
          <td onClick={this.toggleIsFold} style={{textAlign: 'center'}}>
            US$&nbsp;
            {
              parseFloat(sumCashDays) === parseFloat(invoice.fc) ?
                parseFloat(invoice.fc).toFixed(2) :
                parseFloat(sumCashDays).toFixed(2) + '/' + parseFloat(invoice.fc).toFixed(2)
            }
          </td>
          <td onClick={this.toggleIsFold} style={{textAlign: 'center'}}>0</td>
        </tr>
        <tr className={`fold ${this.state.isFold ? 'open' : ''}`}>
          <td colSpan={10}>
            <table className="table table-bordered table-hover px-details" style={{tableLayout: 'fixed'}}>
              <thead>
                <tr className="label-sm" style={{ color: colors.color1[2] }}>
                  <th style={{ overflow: 'auto', verticalAlign: 'middle', width: "90px", cursor: 'default'}}>{i18n[language].date}</th>
                  <th style={{ overflow: 'auto', verticalAlign: 'middle', width: "90px", cursor: 'default' }} data-tooltip-content={i18n[language].payableDesc}>{i18n[language].payable}</th>
                  <th style={{ overflow: 'auto', verticalAlign: 'middle', width: "115px", cursor: 'default' }}>{i18n[language].parts}</th>
                  <th style={{ overflow: 'auto', verticalAlign: 'middle', width: "90px", cursor: 'default' }} data-tooltip-content={i18n[language].estTotalCostDesc}>{i18n[language].totalEstimatedCost}</th>
                  <th style={{ overflow: 'auto', verticalAlign: 'middle', width: "115px", cursor: 'default' }} data-tooltip-content={i18n[language].estCostDay}>{i18n[language].costPerDay}</th>
                  <th style={{ overflow: 'auto', verticalAlign: 'middle', width: "120px", cursor: 'default' }} data-tooltip-content={i18n[language].settledSubsDesc}>{i18n[language].settledWsubs}</th>
                  <th style={{ overflow: 'auto', verticalAlign: 'middle', width: "120px", cursor: 'default' }} data-tooltip-content={i18n[language].settledFlexDesc}>{i18n[language].settledWFlex}</th>
                  <th style={{ overflow: 'auto', verticalAlign: 'middle', width: "120px", cursor: 'default' }} data-tooltip-content={i18n[language].settledCashDesc}>{i18n[language].settledWCash}</th>
                  {/* <th>{i18n[language].costPerPD}</th> */}
                  <th style={{ overflow: 'auto', verticalAlign: 'middle', width: "115px", cursor: 'default' }} data-tooltip-content={i18n[language].outstandingBalanceDesc}>{i18n[language].outstandingBalance}</th>
                </tr>
              </thead>
              <tbody>
              {
                invoice.details.map(d => {
                  return(
                    <tr key={v4()} style={{
                      backgroundColor: now < d.date ? '#EEEFF1' : 'white',
                      color: now < d.date ? '#909b9e' : d.condition_activities ? '#262E37' : '#909b9e'}}>
                      <td>{moment(d.date).format('DD/MM/YYYY')}</td>
                      <td>{d.condition_activities ? 'Yes' : 'No'}</td>
                      <td>{d.num_participants_day}</td>
                      <td>US$ {parseFloat(d.est_price_day).toFixed(2)}</td>
                      <td>US$ {parseFloat(d.cost_per_day).toFixed(2)}</td>
                      <td>{d.subs_day}</td>
                      <td>{d.flex_pd_day}</td>
                      <td>{d.cash_pd_day}</td>
                      {/* <td>US$ {parseFloat(d.pd_cost_day).toFixed(2)}</td> */}
                      <td>US$ {parseFloat(d.cash_day).toFixed(2)}</td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </td>
        </tr>
      </React.Fragment>
    )
  }

  toggleIsFold = () => {
    this.setState(prevState => ({isFold: !prevState.isFold}))
  }
}