import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from "react-modal";

export default class DFMUpdateDelete extends React.Component {
  static propTypes = {
    showModalUD: PropTypes.bool.isRequired,
    updateShowModalUD: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    objSearch: PropTypes.object.isRequired,
    updateDeleteUserFilter: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  }

  state = {
    objSearch: this.props.objSearch
  }

  render() {
    const { showModalUD, type, objSearch, language, colors } = this.props;
    const I18n = {
      en: { titleDelete: 'Do you want to delete this filter?', titleUpdate: 'Update this filter?', 
            filterNamePlaceholder: 'Name your filter', save: 'Save', cancel: 'Cancel', delete: 'Delete'
          },
      es: { titleDelete: 'Quieres eliminar este filtro?', titleUpdate: 'Quieres actualizar este filtro?',
            filterNamePlaceholder: 'Nombra tu filtro', save: 'Guardar', cancel: 'Cancelar', delete: 'Eliminar'}
          }

    let title;
    if(type === 'delete'){
      title = I18n[language].titleDelete;
    } else {
      title = I18n[language].titleUpdate;
    }

    return(
      <ReactModal isOpen={showModalUD} contentLabel="New Custom Filter" shouldCloseOnOverlayClick={true}
                  onRequestClose={() => this.props.updateShowModalUD('')} className="px-modal-content"
                  overlayClassName="px-modal-overlay">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                <i onClick={() => this.props.updateShowModalUD('')} className="material-icons">clear</i>
              </button>
            </div>

            <div className="modal-body px-modal-body-warning">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="px-title-warning-container">
                    <p className="px-title-warning">{title}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1">
                  <div className="form-group" style={{marginTop:'0'}}>
                    {
                      type === 'delete' ?
                        <input name="activityNameToDelete" type="text" className="form-control px-input-confirmation"
                               defaultValue={objSearch.name} disabled={true}/> :
                        <input type="text" onChange={this.handleChange} value={this.state.objSearch.name}
                          className="form-control" placeholder={I18n[language].filterNamePlaceholder}/>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer px-modal-footer-warning">
              <div className="px-footer-container-buttons column">
                <button onClick={() => this.props.updateDeleteUserFilter(this.state.objSearch, type)} type="button"
                        className="btn px-btn sm btn-blue-base"
                        disabled={type === 'delete' ? false : this.state.objSearch.name === ''}
                        style={{backgroundColor: colors.color1[3]}}>
                  {type === 'delete' ? I18n[language].delete : I18n[language].save }
                </button>
                <button onClick={() => this.props.updateShowModalUD('')} type="button"
                  className="btn px-btn sm btn-gray-darker inverted">{I18n[language].cancel}</button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }

  handleChange = event => {
    const objSearch = {...this.state.objSearch, name: event.target.value}
    this.setState({objSearch});
  };
}