import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";
import { getCountryName, setIconGender } from "./Globals/PX_Funs";
import { Tooltip as ReactTooltip } from "react-tooltip"
import {usernamesToDisplay} from "../Community/shared_utils";

export default class MUserProfile extends React.Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    communityId: PropTypes.number.isRequired,
    role_1: PropTypes.string,
    role_2: PropTypes.string,
    userActions: PropTypes.func,
    alias_from: PropTypes.string,
    modalShowPM: PropTypes.func,
    current_user_id: PropTypes.number,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      user: props.user
    }
  }

  componentDidMount(){
    this._getReceivedObjs();
  }


  render() {
    const {showModal, role_1, role_2, alias_from, current_user_id, language, colors} = this.props;
    const {user} = this.state;

    const i18n = {
      en: {
        popularity: 'my popularity', social: 'my social media', seeResponses: 'See responses',
        lastResponse: 'Last response'
      },
      es: {
        popularity: 'mi popularidad', social: 'mi social media', seeResponses: 'Ver respuestas',
        lastResponse: 'Última respuesta'
      }
    };

    const regionCountryTip = user.state_country !== "" ?
                             user.state_country + ', ' + getCountryName(user.country) :
                             getCountryName(user.country);
    const segments = user.segments.map((segment) => {
      return (
        <FontAwesomeIcon key={segment.value !== undefined ? segment.value : segment.id} icon={['fas', 'bookmark']}
                         className="tag" style={{ color: segment.color }}
                         data-tooltip-content={segment.label !== undefined ? segment.label : segment.name} />
      )
    });

    const userId = user.user_id !== undefined ? user.user_id : user.id;
    //console.log('current_user_id:', current_user_id, 'userId:', userId, current_user_id !== userId);

    const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
    const mainName = availableUsernames[0];
    const alternativeNames = availableUsernames.length > 1 ? availableUsernames.slice(1).join(', ') : ''

    return (
      <ReactModal isOpen={showModal} contentLabel="User Profile" shouldCloseOnOverlayClick={true}
        onRequestClose={() => this.props.updateShowModal(null)} className="px-modal-content"
        overlayClassName="px-modal-overlay">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className="modal-dialog img">
          <div className="modal-content px-modal-content profile">
            <div className="modal-header">
              <img src={user.avatar !== '' ? user.avatar : '/assets/user.png'} alt="" className="img-circle px-thumb-social" />
              <button onClick={() => this.props.updateShowModal(null)} type="button"
                      className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                <i className="material-icons">clear</i>
              </button>
            </div>
            <div className="modal-body px-body-profile">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="px-modal-title">
                    {mainName}
                  </div>
                  <div style={{fontSize: '12px'}}>
                    {alternativeNames && `(a.k.a:  ${alternativeNames})`}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <p className="bio-content">{user.cu_bio}</p>
                </div>
              </div>
              <div className="separator" />
              <div className="data-container">
                <div className="badges-container">
                  <div className="tags">
                    { role_1 === 'Collaborator' && segments }
                  </div>
                  {
                    user.gender !== '' &&
                    <div className="px-genders-container">
                      <div className="gender-container" data-tooltip-content={user.gender}>
                        {setIconGender(user.gender)}
                      </div>
                    </div>
                  }
                  {
                    (user.country !== '' && user.country) &&
                    <div className="px-country-container" data-tooltip-content={regionCountryTip}>
                      <span>{user.country}</span>
                    </div>
                  }
                </div>
                {role_2 === "Observer" || role_2 === "" ? "" : <div className="email">{user.email}</div>}
              </div>
              <div className="row mg-top-20">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="px-legend">{i18n[language].popularity}</div>
                  <div className="social-stats-container">
                    <div className="px-item" data-tooltip-content="Likes received">
                      <span className="like-in"/>
                      <div className="counter">{user.receivedObjs ? user.receivedObjs.counter_likes : 0}</div>
                    </div>
                    <div className="px-item" data-tooltip-content="Likes generated">
                      <span className="like-out"/>
                      <div className="counter">{user.receivedObjs ? user.receivedObjs.likes_sent : 0}</div>
                    </div>
                    <div className="px-item" data-tooltip-content="Comments received">
                      <span className="comment-in"/>
                      <div className="counter">{user.receivedObjs ? user.receivedObjs.counter_comments_replies : 0}</div>
                    </div>
                    <div className="px-item" data-tooltip-content="Comments generated">
                      <span className="comment-out" />
                      <div className="counter">{user.receivedObjs ? user.receivedObjs.comments_replies_sent : 0}</div>
                    </div>
                    {/* <div className="px-item">
                      <span className="start-in"/>
                      <div className="counter">0</div>
                    </div> */}
                  </div>
                </div>
              </div>

              {/* <div className="row mg-top-20">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="px-legend">my badges</div>
                  <div className="social-stats-container">
                    <div className="px-item circle">
                      <FontAwesomeIcon icon={['fas','pencil']} className="icon" />
                    </div>
                    <div className="px-item circle active">
                      <FontAwesomeIcon icon={['fas','pencil']} className="icon" />
                    </div>
                    <div className="px-item circle">
                      <FontAwesomeIcon icon={['fas','pencil']} className="icon" />
                    </div>
                  </div>
                </div>
              </div> */}
              {
                role_2 !== 'Recruiter' &&
                <div className="row mg-top-20">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <div className="px-legend">{i18n[language].social}</div>
                    <div className="social-stats-container">
                      <div onClick={() => this.forwardUrl(user.twitter, 'twitter')}
                           className={`px-item ${user.twitter !== '' && 'active'}`}>
                        <FontAwesomeIcon icon={['fab','square-twitter']} className="icon" />
                      </div>
                      <div onClick={() => this.forwardUrl(user.facebook, 'facebook')}
                           className={`px-item ${user.facebook !== '' && 'active'}`}>
                        <FontAwesomeIcon icon={['fab','square-facebook']} className="icon" />
                      </div>
                      <div onClick={() => this.forwardUrl(user.instagram, 'instagram')}
                           className={`px-item ${user.instagram !== '' && 'active'}`}>
                        <FontAwesomeIcon icon={['fab','instagram']} className="icon" />
                      </div>
                      <div onClick={() => this.forwardUrl(user.linkedin, 'linkedin')}
                           className={`px-item ${user.linkedin !== '' && 'active'}`}>
                        <FontAwesomeIcon icon={['fab', 'linkedin']} className="icon" />
                      </div>
                      <div onClick={() => this.forwardUrl(user.snapchat, 'snapchat')}
                           className={`px-item ${user.snapchat !== '' && 'active'}`}>
                        <FontAwesomeIcon icon={['fab', 'square-snapchat']} className="icon" />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="modal-footer px-footer-profile">
              {
                user.role_1 === 'Participant' ?
                  role_2 === "Recruiter" ? "" :
                    <div className="foot-item">
                      {
                        role_1 === 'Collaborator' ?
                          <div onClick={() => this.props.userActions('see responses', user)} className="label-xs"
                          style={{color: colors.color1[3]}}>
                            {i18n[language].seeResponses}</div> :
                          <div onClick={() => this.props.userActions('last response', user)} className="label-xs"
                            style={{ color: colors.color1[3] }}>
                            {i18n[language].lastResponse}</div>
                      }
                    </div> : null
              }
              {
                (current_user_id !== userId) ?
                role_2 === "Observer" || role_2 === "" ? "" :
                  <div className="foot-item"
                       onClick={() => this.props.modalShowPM('PM', user.username, userId)}>
                      <FontAwesomeIcon icon={['fas', 'envelope']} className="envelope" style={{ color: colors.color1[3] }} />
                  </div> : null
              }

              {/* <div className="foot-item">
                <FontAwesomeIcon icon={['fas','trophy']} className="icon" />
              </div>
              <div className="foot-item">
                <FontAwesomeIcon icon={['fas','trophy']} className="icon" />
              </div> */}
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }

  _getReceivedObjs = () => {
    $.ajax({
      url: '/users/get_received_objs/' + this.state.user.id + '/' + this.props.communityId,
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: receivedObjs =>  {
        // console.log(receivedObjs);
        this.setState({user: {...this.state.user, receivedObjs}});
      }
    });
  };

  forwardUrl = (url, type) => {
    let base_url = '';

    switch(type){
      case 'twitter':
        base_url = url.includes('https://twitter.com') ? url : 'https://twitter.com/' + url.replace('@', '');
        break;
      case 'instagram':
        base_url = url.includes('https://www.instagram.com') ? url : 'https://www.instagram.com/' + url.replace('@', '');
        break;
      case 'linkedin':
        base_url = url.includes('https://www.linkedin.com') ? url : 'https://www.linkedin.com/' + url.replace('@', '');
        break;
      case 'facebook':
        base_url = url.includes('https://www.facebook.com') ? url : 'https://www.facebook.com/' + url.replace('@', '');
        break;
      case 'snapchat':
        base_url = url.includes('snapchat.com') ? url : 'https://story.snapchat.com/s/' + url.replace('@', '');
        break;
    }

    if(url !== ''){
      window.open(base_url, '_blank');
    }
  };
}
