import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select, {components} from "react-select";

import {setBlobToPreview} from "../../../../../UI/Globals/PX_Funs";
import ReactModal from "react-modal";
import T_ModalVideo from "../../../../Transcript/T_ModalVideo";
import EditorWYSIWYGState from "../../../../Settings/Utils/EditorWYSIWYGState";
import ColorPicker from "../../../../Settings/Utils/ColorPicker.jsx"

export default class CanvasEdit extends React.Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    cardinal: PropTypes.number.isRequired,
    cancelEditQuestion: PropTypes.func.isRequired,
    handleChangeQuestion: PropTypes.func.isRequired,
    updateQuestion: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    onEditorStateChangeQuestion: PropTypes.func.isRequired,
    mapsSelect: PropTypes.array.isRequired,
    handleMapsSelected: PropTypes.func.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    handleRequired: PropTypes.func.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    handleCanAttachMedia: PropTypes.func.isRequired,
    communityLang: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    handleQuestionColorsChange: PropTypes.func.isRequired,
    communityProjectType: PropTypes.string.isRequired,
    cmntyAiAutoModeration: PropTypes.bool,
    cmntyAllowImgs: PropTypes.bool,
    cmntyAiPlan: PropTypes.string,
    isSavingTask: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      showModalVideo: false,
      showAiAutoModeration: false
    }
  }

  componentDidMount(){
    if(typeof this.props.question.blob !== 'undefined') {
      setBlobToPreview(this.props.question.blob, this.refs.imgPreviewOEEdit);
    }

    let btnHoverColor = this.props.colors.color2[3];
    $(".action-button, .btn-draggable").mouseenter(function () {
      $(this).css({ 'background-color': btnHoverColor });
    });
    $(".action-button, .btn-draggable").mouseleave(function () {
      $(this).css({ 'background-color': '' });
    });
    this.updateAutoModeration();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.question.video_url !== prevProps.question.video_url) {
      this.updateAutoModeration();
    }
  }

  updateAutoModeration() {
    const isAllowed = this.isTaskAutoModerationAllowed();
    if (!isAllowed) {
      // If auto moderation is not allowed, ensures the checkbox is unchecked
      this.props.handleChangeQuestion({ target: { name: 'ai_auto_moderation', checked: false } }, this.props.question.id, false);
    }
    this.setState({ showAiAutoModeration: isAllowed });
  }

  render() {
    const {question, cardinal, communitySocial, mapsSelect, communityLang, language, colors} = this.props;
    const i18n ={
      en: {
        preview: 'Preview', save: 'Save', cancel: 'Cancel',
        mandatory: 'Mandatory', minAnnotations: 'MIN ANNOTATIONS',
        minAnnotationsTooltip: 'Will guide participants on how many annotations they are expected to add.',
        mandatoryTooltip: 'Participants are forced to annotate on the image.',
        addImageToTask: 'Add an image as an annotation canvas', addVideoToTask: 'Add a video to this task\'s instructions',
        mode: 'Mode', markerTypes: 'Marker types', include: 'Include', highlightMarker: 'Highlighter',
        freeHandMarker: 'Freehand', arrowsMarker: 'Arrows', shapesMarker: 'Shapes', calloutsMarker: 'Call-outs', textMarker: 'Text',
        markerColors: 'Marker Colors', commentsOnMarkers: 'Comments on markers', freeAnnotationsTooltip: '',
        prosNconsTooltip: '', markerTypesTooltip: '', commentsOnMarkersTooltips: '', freeAnnotations: 'Free annotations',
        prosNcons: 'Positives & Negatives', canvasImage: 'Canvas Image', aiAutoMod: 'AI auto moderation'
      },
      es: {
        preview: 'Muestra', save: 'Guardar', cancel: 'Cancelar',
        mandatory: 'Obligatorio', minAnnotations: 'MIN ANOTACIONES',
        minAnnotationsTooltip: 'Guiará a los participantes a decidir la cantidad de anotaciones que se espera que agreguen.',
        mandatoryTooltip: 'Los participantes están obligados a hacer anotaciones sobre la imagen.',
        addImageToTask: 'Agrega una imagen para que los participantes anoten sobre ella.', addVideoToTask: 'Agrega una video en las instrucciones de esta tarea.',
        mode: 'Modo', markerTypes: 'Marcadores disponibles', include: 'Incluir', highlightMarker: 'Marcador',
        freeHandMarker: 'Mano libre', arrowsMarker: 'Flechas', shapesMarker: 'Formas', calloutsMarker: 'Llamadas de texto', textMarker: 'Texto',
        markerColors: 'Colores para marcar', commentsOnMarkers: 'Comentarios en las anotaciones', freeAnnotationsTooltip: '',
        prosNconsTooltip: '', markerTypesTooltip: '', commentsOnMarkersTooltips: '', freeAnnotations: 'Anotaciones libres',
        prosNcons: 'Positivos & Negativos', canvasImage: 'Imagen a anotar', aiAutoMod: 'Auto Moderation AI'
      }
    }
    //console.log(question);
    const minAnnotations = question.data.minAnnotations !== '' ? parseInt(question.data.minAnnotations) : '';

    let btnImgClass = 'btn-media have-image';
    let imgUrl = '';
    let imgStyle = null;
    if(typeof question.blob !== 'undefined'){
      if(this.refs.imgPreviewOEEdit !== undefined){
        setBlobToPreview(question.blob, this.refs.imgPreviewOEEdit);
      }
      btnImgClass = 'btn-media have-image';
      imgStyle = 'block';
    } else {
      if(question.attachment.length !== 0){
        imgUrl = question.attachment[0].url;
        imgStyle = 'block';
      } else {
        btnImgClass = 'btn-media';
        imgStyle = 'none';
      }
    }

    let videoThumbnail = null;
    if(question.videoState){
      videoThumbnail = question.videoState === 'processed' ? question.video_thumbnail : '/assets/loader_200x200.gif';
    } else {
      videoThumbnail = question.video_thumbnail;
    }

    let defaultMandatory = null;
    let defaultMedia = [{value: 'Images', label: 'Images'}, {value: 'Video', label: 'Video'}];

    if(question.required){
      switch(question.question_type){
        case 'Canvas':
          defaultMandatory = [{value: 'minAnnotations', label: 'Min. annotations'}];
          break;
      }
    }

    // Custom icon for dropdown selector - React select
    const DropdownIndicator = props => {
      return (
          <components.DropdownIndicator {...props}>
            <FontAwesomeIcon icon={['fas', 'sort']} className="dropdown-icon" data-tooltip-content="" />
          </components.DropdownIndicator>
      );
    };
    // Custom icon for clear selections indicator - React select
    const ClearIndicator = props => {
      return (
          <components.ClearIndicator {...props}>
            <FontAwesomeIcon icon={['fal', 'xmark']} className="clear-indicator-icon" data-tooltip-content="Revert to default" />
          </components.ClearIndicator>
      );
    };
    // Custom icon for value remove - React select
    const MultiValueRemove = props => {
      return (
          <components.MultiValueRemove {...props}>
            <FontAwesomeIcon icon={['fal', 'xmark']} className="clear-indicator-icon" data-tooltip-content="" />
          </components.MultiValueRemove>
      );
    };

    // Custom styles for mandatory select - via React Select
    const customSelectStyles = {
      control: (base) => ({
        ...base,
        width: 'calc(100% - 25px)',
        borderRadius: '6',
        border: '0.7px solid #DADDE1',
        marginLeft:'25px',
        minHeight: '30px',
        '&:hover': {
          border: '0.7px solid #DADDE1'
        }
      }),
      valueContainer: (base) => ({
        ...base,
        paddingTop: '4px',
        paddingBottom: '4px',
      }),
      multiValue: (base) => ({
        ...base,
        marginTop: '1',
        marginBottom: '1',
      }),
      multiValueLabel: (base) => ({
        ...base,
        backgroundColor: '#AFBDC1',
        color: '#ffffff',
        borderRadius: '2px 0 0 2px',
        textTransform: 'uppercase',
        fontSize: '8px',
        fontFamily: "'Sharp Sans ExtraBold', Helvetica, sans-serif",
        letterSpacing: '1.8px',
        paddingTop: '2px',
        paddingBottom: '1px',
        marginTop: '0',
        marginBottom: '0',
        lineHeight: '15px'
      }),
      multiValueRemove: (base) => ({
        ...base,
        backgroundColor: '#AFBDC1',
        color: '#FFFFFF',
        fontSize: '8px',
        borderRadius: '0 2px 2px 0',
        cursor: 'pointer',
        padding: '0 5px',
        '&:hover': {
          backgroundColor: '#7C8593',
          color: '#FFFFFF'
        }
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
      }),
      input: (base) => ({
        ...base,
        marginTop: '0',
        marginBottom: '0',
        paddingTop: '0',
        paddingBottom: '0',
        //display: 'none'
      }),
      clearIndicator: (base) => ({
        ...base,
        cursor: 'pointer',
        padding: '5px 4px 5px 8px',
        fontSize: '11px'
      }),
      dropdownIndicator: (base) => ({
        ...base,
        fontSize: '12px',
        color: '#7C8593',
        paddingRigth: '10px',
        padding: '5px 10px 5px 8px',
        cursor: 'pointer',
        '&:hover': {
          color: '#7C8593',
        }
      }),
      menu: (base) => ({
        ...base,
        background: '#F2F2F2',
        padding: '5px 8px',
        width: '218px',
        marginLeft: '20px',
        //padding: '0'
      }),
      menuList: (base) => ({
        ...base,
        paddingBottom: '8px'
      }),
      option: (base, state) => ({
        ...base,
        fontSize: '8px',
        fontFamily: 'Arial, sans-serif',
        padding: '0 12px',
        height: '18px',
        textTransform: 'uppercase',
        backgroundColor: state.isFocused ? '#F2F2F2' : '#F2F2F2',
        '&:hover': {
          backgroundColor: '#5C95F1',
          color: '#FFFFFF'
        }
      }),
    };

    const highlightMarker = question.data?.highlightMarker?.toString();
    const freeHandMarker = question.data?.freeHandMarker?.toString();
    const arrowsMarker = question.data?.arrowsMarker?.toString();
    const shapesMarker = question.data?.shapesMarker?.toString();
    const calloutsMarker = question.data?.calloutsMarker?.toString();
    const textMarker = question.data?.textMarker?.toString();
    const commentsOnMarkers = question.data?.commentsOnMarkers?.toString();
    const markerColors = question.data?.markerColors;

    return (
        <div className="panel panel-activity-builder-details edit-mode">
          <div className="px-support-tooltip top is_hidden px-support-builder">
            <p>Customize your Task here, by setting its instructions and what type of data the participant should share.</p>
            <i/>
          </div>
          <div className="top-border"/>
          <div className="bottom-border"/>
          <div className="left-border"/>
          <div className="right-border"/>
          <div className="panel-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="title-task-container edit">
                  <div className="task-number" style={{ backgroundColor: colors.color0[1] }}><span>{cardinal}</span></div>
                  <span className="title-task">{question.task_type}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-task-edit-container">
                <div className="px-left-actv-container">
                  <div className="row">
                    <div className="col-xs-6 col-sm-6 col-md6 col-lg-6">
                      <EditorWYSIWYGState name="title"
                                          editorName="editorQuestion"
                                          editorState={question.editorQuestion}
                                          onEditorStateChange={this.props.onEditorStateChangeQuestion}
                                          questionId={question.id}
                                          language={language}/>

                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="btn-media-container">
                            <div className="image-item">
                              {
                                question.video_thumbnail === '' ?
                                    <button onClick={() => this.props.updateShowModal('video', question.id)} className='btn-media' data-tooltip-content={i18n[language].addVideoToTask}>
                                      <FontAwesomeIcon icon={['fas', 'video']}/>
                                    </button> :
                                    <button onClick={this.updateShowModalVideo} className='btn-media have-image'>
                                      <img src={videoThumbnail} alt=""/>
                                    </button>
                              }
                              {
                                  question.video_thumbnail !== '' &&
                                  <div onClick={() => this.props.deleteMedia(question.id, 'video')} className="px-btn-close-float">
                                    <FontAwesomeIcon icon={['fas','xmark']}/>
                                  </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md6 col-lg-6" style={{textAlign: 'center'}}>
                      <label htmlFor="" className="label-xs" style={{color: colors.color1[2]}}>{i18n[language].canvasImage}</label>
                      <div className="no-top-margin btn-media-container">
                        <div className="image-item" style={{width: '100%'}}>
                          <button style={{width: '100%', flexDirection: 'column', minHeight: '200px'}}
                                  onClick={() => this.props.updateShowModal('image', question.id)} className={btnImgClass} data-tooltip-content={i18n[language].addImageToTask}>
                            {
                              question.attachment.length === 0 && typeof question.blob === 'undefined' ?
                                  <FontAwesomeIcon icon={['fas', 'image']}/> : null
                            }
                            <img ref='imgPreviewOEEdit' src={imgUrl} alt="" style={{height: '80%', width: 'auto', display: imgStyle }}/>
                          </button>
                          {
                              (question.attachment.length !== 0 || question.blob !== undefined) &&
                              <div onClick={() => this.props.deleteMedia(question.id, 'image')} className="px-btn-close-float">
                                <FontAwesomeIcon icon={['fas','xmark']}/>
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  <div className="px-right-actv-container">

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col- md-12 col-lg-12">
                        <label htmlFor="" className="label-xs" style={{color: colors.color1[2]}}>{i18n[language].mode}</label>
                        <div className="px-check-cont rad">
                          <label className="container-check radio">
                            <input onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)}
                                   type="radio" name={"subtype"  + question.id} checked={question.data?.subtype === 'freeAnnotations'}
                                   className="radio-btn" value="freeAnnotations"/>
                            <span className="checkmark radio"/>
                            <span>{i18n[language].freeAnnotations}</span>
                            <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                             data-tooltip-content={i18n[language].freeAnnotationsTooltip}/>
                          </label>
                        </div>
                        {/*<div className="px-check-cont rad">*/}
                        {/*  <label className="container-check radio">*/}
                        {/*    <input onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)}*/}
                        {/*           type="radio" name={"subtype"  + question.id} checked={question.data?.subtype === 'prosNcons'}*/}
                        {/*           className="radio-btn" value="prosNCons" disabled={true}/>*/}
                        {/*    <span className="checkmark radio"/>*/}
                        {/*    <span>{i18n[language].prosNcons}</span>*/}
                        {/*    <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"*/}
                        {/*                     data-tooltip-content={i18n[language].prosNconsTooltip}/>*/}
                        {/*  </label>*/}
                        {/*</div>*/}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <label htmlFor="" className="label-xs" style={{color: colors.color1[2]}}>{i18n[language].markerTypes}</label>
                        <div className="px-check-cont">
                          <label className="container-check">
                            <input name="highlightMarker" type="checkbox" checked={highlightMarker === "true"}
                                   onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)} />
                            <span className="checkmark"/>
                            <span>{i18n[language].highlightMarker}</span>
                            {/*<FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"*/}
                            {/*                 data-tooltip-content={''} />*/}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-check-cont">
                          <label className="container-check">
                            <input name="freeHandMarker" type="checkbox" checked={freeHandMarker === "true"}
                                   onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)} />
                            <span className="checkmark"/>
                            <span>{i18n[language].freeHandMarker}</span>
                            {/*<FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"*/}
                            {/*                 data-tooltip-content={''} />*/}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-check-cont">
                          <label className="container-check">
                            <input name="arrowsMarker" type="checkbox" checked={arrowsMarker === "true"}
                                   onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)} />
                            <span className="checkmark"/>
                            <span>{i18n[language].arrowsMarker}</span>
                            {/*<FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"*/}
                            {/*                 data-tooltip-content={''} />*/}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-check-cont">
                          <label className="container-check">
                            <input name="shapesMarker" type="checkbox" checked={shapesMarker === "true"}
                                   onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)} />
                            <span className="checkmark"/>
                            <span>{i18n[language].shapesMarker}</span>
                            {/*<FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"*/}
                            {/*                 data-tooltip-content={''} />*/}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-check-cont">
                          <label className="container-check">
                            <input name="calloutsMarker" type="checkbox" checked={calloutsMarker === "true"}
                                   onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)} />
                            <span className="checkmark"/>
                            <span>{i18n[language].calloutsMarker}</span>
                            {/*<FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"*/}
                            {/*                 data-tooltip-content={''} />*/}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-check-cont">
                          <label className="container-check">
                            <input name="textMarker" type="checkbox" checked={textMarker === "true"}
                                   onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)} />
                            <span className="checkmark"/>
                            <span>{i18n[language].textMarker}</span>
                            {/*<FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"*/}
                            {/*                 data-tooltip-content={''} />*/}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-check-cont">

                          <div  style={{color: '#7c8593', fontSize:'8px', letterSpacing: '1.2px', textTransform: 'uppercase', fontFamily: "Sharp Sans ExtraBold", cursor: 'pointer'}}>{i18n[language].markerColors}</div>
                          <span style={{ marginRight: '5px'}}>
                          <ColorPicker id={question.id} color={markerColors[0]} colorSet={this.props.question.data.markerColors} colorSetIndex={0} updateColor={this.props.handleQuestionColorsChange}
                                       icon='circle' style={{ marginRight: '5px'}}/>
                        </span>
                          <span style={{ marginRight: '5px'}}>
                          <ColorPicker id={question.id} color={markerColors[1]} colorSet={this.props.question.data.markerColors} colorSetIndex={1} updateColor={this.props.handleQuestionColorsChange}
                                       icon='circle' />
                        </span>
                          <span style={{ marginRight: '5px'}}>
                          <ColorPicker id={question.id} color={markerColors[2]} colorSet={this.props.question.data.markerColors} colorSetIndex={2} updateColor={this.props.handleQuestionColorsChange}
                                       icon='circle' style={{ marginRight: '5px'}}/>
                        </span>
                          <span style={{ marginRight: '5px'}}>
                          <ColorPicker id={question.id} color={markerColors[3]} colorSet={this.props.question.data.markerColors} colorSetIndex={3} updateColor={this.props.handleQuestionColorsChange}
                                       icon='circle' style={{ marginRight: '5px'}}/>
                        </span>
                          <span style={{ marginRight: '5px'}}>
                          <ColorPicker id={question.id} color={markerColors[4]} colorSet={this.props.question.data.markerColors} colorSetIndex={4} updateColor={this.props.handleQuestionColorsChange}
                                       icon='circle' style={{ marginRight: '5px'}}/>
                        </span>

                        </div>
                      </div>
                    </div>

                    <div className="row" style={{display: 'none'}}>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-check-cont">
                          <label className="container-check">
                            <input name="commentsOnMarkers" type="checkbox" checked={commentsOnMarkers === "true"}
                                   onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)} />
                            <span className="checkmark"/>
                            <span>{i18n[language].commentsOnMarkers}</span>
                            <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                             data-tooltip-content={i18n[language].commentsOnMarkersTooltips} />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-check-cont">
                          <label className="container-check">
                            <input name="required" type="checkbox" checked={question.required}
                                   onChange={(event) => this.props.handleChangeQuestion(event, question.id, false)}/>
                            <span className="checkmark"/>
                            <span>{i18n[language].mandatory}</span>
                            <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                             data-tooltip-content={i18n[language].mandatoryTooltip} />
                          </label>
                        </div>
                        {
                            question.required &&
                            <Select className="react-select" classNamePrefix="react-select"
                                    value={question.required_options.length === 0 ? defaultMandatory :
                                        question.required_options.map(opt => ({value: opt, label: opt}))}
                                    onChange={(event) => this.props.handleRequired(event, question.id)}
                                    options={question.requiredOptsView.map(opt => ({value: opt, label: opt}))}
                                    isMulti={true}
                                    components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
                                    styles={customSelectStyles}/>
                        }
                        {
                          this.state.showAiAutoModeration &&
                          <div className="px-check-cont">
                            <label className="container-check">
                              <input name="ai_auto_moderation" type="checkbox" checked={question.ai_auto_moderation}
                                     onChange={(event) => this.props.handleChangeQuestion(event, question.id, false)}/>
                              <span className="checkmark"/><span>{i18n[language].aiAutoMod}</span>
                              <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                               data-tooltip-content={i18n[language].aiAutoModTT} />
                            </label>
                          </div>
                        }

                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="char-container" style={{marginTop: '0px', marginLeft: '0px'}}>
                              <span className="span-char">{i18n[language].minAnnotations}</span>
                              <input name="minAnnotations" type="number" value={minAnnotations} max="20" className="input-char"
                                     onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)}/>
                              <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                               data-tooltip-content={i18n[language].minAnnotationsTooltip} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="px-save-row">
                  <button onClick={() => this.props.updateShowModal('preview task OE', question.id)}
                          className="btn-preview">{i18n[language].preview}</button>
                  <div className="action-button-container">
                    <button onClick={this.props.isSavingTask !== question.id ?
                      () => { this.props.updateQuestion(question.id)} : null}
                            className="btn-save"
                            style={{backgroundColor: colors.color1[3]}}>
                      {this.props.isSavingTask === question.id ?
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin={true} style={{color: '#ffffff'}}/>
                        : i18n[language].save}
                    </button>
                    <button onClick={() => this.props.cancelEditQuestion(question.id)}
                            className="btn-cancel">{i18n[language].cancel}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="actions-panel">
            <div className="actions-container">
              <button onClick={this.props.isSavingTask !== question.id ?
                () => this.props.updateQuestion(question.id) : null}
                      className="action-button edit">
                {this.props.isSavingTask === question.id ?
                  <FontAwesomeIcon icon={['fas', 'spinner']} spin={true} style={{color: '#ffffff'}}/>
                  : <FontAwesomeIcon icon={['far', 'check']}/>}
              </button>
              <button onClick={() => this.props.cancelEditQuestion(question.id)} className="action-button edit">
                <FontAwesomeIcon icon={['far', 'xmark']}/>
              </button>
            </div>
          </div>
          {
              question.video_thumbnail !== '' &&
              <ReactModal isOpen={this.state.showModalVideo} contentLabel="Response Video"
                          shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalVideo}
                          className="my-modal-content" overlayClassName="my-modal-overlay">
                <T_ModalVideo updateShowModalVideo={this.updateShowModalVideo}
                              thumbnail={question.video_thumbnail}
                              url={question.video_url}
                              language={language}
                              colors={colors}/>
              </ReactModal>
          }
        </div>
    );
  }

  updateShowModalVideo = () => {
    this.setState(state => ({showModalVideo: !state.showModalVideo}));
  };

  updateMarkerColor = () => {
    // console.log('update color')
  }

  checkVideo = (question) => {
    return question.video_url !== null && question.video_url !== ""
  }

  isTaskAutoModerationAllowed =() => {
    let taskAutoModerationAllowed = false;
    const {question, cmntyAiAutoModeration, cmntyAllowImgs, cmntyAiPlan, communityProjectType } = this.props
    let isPlanPro = cmntyAiPlan === 'aiPlan01' || cmntyAiPlan === 'aiPlan02'
    let has_video = this.checkVideo(question);

    if (communityProjectType === 'Mixed' && cmntyAiPlan === 'aiPlan02' && cmntyAiAutoModeration && !has_video) {
      taskAutoModerationAllowed = true;
    }

    return taskAutoModerationAllowed
  }

}
