import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import isBeforeDay from "react-dates/esm/utils/isBeforeDay";

import ImageModal from '../ImageModal';
import TransferModal from "../General/TransferModal";
import CSMCloseCMTY from "./CSGeneral/CSMCloseCMTY";
import CSMArchiveCMTY from "./CSGeneral/CSMArchiveCMTY";
import CSMPurgeCMTY from "./CSGeneral/CSMPurgeCMTY";
import CSMTimeZoneCMTY from "./CSGeneral/CSMTimeZoneCMTY";
import CSMAllowance from "./CSGeneral/CSMAllowance";
import {Tooltip as ReactTooltip} from "react-tooltip";

export default class CSGeneral extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    saveCommunity: PropTypes.func.isRequired,
    showModalClose: PropTypes.bool.isRequired,
    updateShowModalClose: PropTypes.func.isRequired,
    closeCommunity: PropTypes.func.isRequired,
    showModalArchive: PropTypes.bool.isRequired,
    archiveCommunity: PropTypes.func.isRequired,
    showModalDelete: PropTypes.bool.isRequired,
    updateShowModalDelete: PropTypes.func.isRequired,
    purgeCommunity: PropTypes.func.isRequired,
    showModalSocial: PropTypes.bool.isRequired,
    updateShowModalSocial: PropTypes.func.isRequired,
    socialCommunity: PropTypes.func.isRequired,
    showModalTimeZone: PropTypes.bool.isRequired,
    updateShowModalTimeZone: PropTypes.func.isRequired,
    updateTimeZone: PropTypes.func.isRequired,
    role_2: PropTypes.string.isRequired,
    updateCommunityAvatar: PropTypes.func.isRequired,
    updateCommunityTitle: PropTypes.func.isRequired,
    updateCommunity: PropTypes.func.isRequired,
    updateShowModalArchive: PropTypes.func.isRequired,
    showModalUnarchive: PropTypes.bool.isRequired,
    updateShowModalUnarchive: PropTypes.func.isRequired,
    updateCommunityState: PropTypes.func.isRequired,
    communities: PropTypes.array.isRequired,
    participants_start: PropTypes.string,
    participants_end: PropTypes.string,
    updateDates: PropTypes.func.isRequired,
    papt: PropTypes.number.isRequired,
    maxPapt: PropTypes.number,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    getQuote: PropTypes.func.isRequired,
    estimate: PropTypes.object.isRequired,
    loadingQuote: PropTypes.bool.isRequired,
    updateShowQuote: PropTypes.func.isRequired,
    updateShowModalQuote: PropTypes.func.isRequired,
    company: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
      avatar: this.props.community.community_avatar,
      sponsorFlag: this.props.community.sponsor_flag,
      socialFlag: this.props.community.social_flag,
      timezoneFlag: this.props.community.default_tz_flag,
      isModalDelete: false,
      isModalTransfer: false,
      category: this.props.community.category,
      subcategory: this.props.community.subcategory,
      subcategories: [],
      timezone: '',
      showAllowanceModal: false,
      focusedInput: null,
      disabledPickers: false,
      flagPartsEnd: moment(props.community.participants_end) >= moment(moment().format('YYYY-MM-DD')),
      aiModerationDelaySetting: this.getAiDelayModerationSetting(props.community.ai_moderation_delay, props.community.ai_moderation_delay_offset)
    };
  }

  componentDidMount(){
    this.setState({subcategories: this._getSubCategories(this.state.category)});
    // console.log(this.state.flagPartsEnd);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.community.ai_moderation_delay !== this.props.community.ai_moderation_delay ||
      prevProps.community.ai_moderation_delay_offset !== this.props.community.ai_moderation_delay_offset) {
      const newSetting = this.getAiDelayModerationSetting(this.props.community.ai_moderation_delay, this.props.community.ai_moderation_delay_offset);
      this.setState({
        aiModerationDelaySetting: newSetting
      });
    }
  }

  render() {
    const {community, role_2, language, colors, loadingQuote, estimate} = this.props;

    const I18n = {
      en: {
        cmntyTitle: 'Community name', category: 'Category', select: 'Select...', government: 'Government', media: 'Media',
        b2b: 'B2B', education: 'Education', foodNdrinks: 'Food & Drinks', utilities: 'Utilities', shopping: 'Shopping',
        hospitality: 'Hospitality', transport: 'Transport', fashion: 'Fashion', travel: 'Travel',
        healthNwellbeing: 'Health & Wellbeing', pets: 'Pets', technology: 'Technology', financial: 'Financial',
        sports: 'Sports', beautyNHigene: 'Beauty & Higene', lifestyle: 'Lifestyle', housing: 'Housing',
        childrenNparenting: 'Children and Parenting', other: 'Other', subCategory: 'Sub-Category', sponsor: 'Sponsor',
        sponsorWarning: 'For your records only.', activityTimeZone: 'Activity ruling time zone',
        activityTimeZoneWarning: "Time Zone used to time activities if 'Use Local Activity Timing' is off",
        localTiming: 'Use local Activity timing', activityTimeZoneInfo: 'Activities start and expire at the \'Activity ruling time zone\'.', 
        localTimingInfo: 'Activities start and expire at participants\' local time.', cmntyAvatar: 'Avatar', save: 'SAVE', 
        socialCommunity: 'Social Community', socialWarning: "When 'ON' participants have access to other participants' responses.", 
        criticalOptions: 'Critical Options', close: 'Close', open: 'Open', transfer: 'Transfer', archive: 'Archive',
        purge: 'Purge', unarchive: 'Unarchive', community: ' Community', cmtyLang: 'Participants speak', optEn: 'English',
        optEs: 'Spanish', numParts: 'Participants', partsAccess: 'Participants access', quoteTitle: 'Costs',
        update: 'Update', estimatedPrice: 'Estimated cost', aiTitle: "AI Features", aiPic: "Process Images", aiVid: "Process Videos",
        autoModeration: "Auto-moderation", projectEntitled: "Features available in this project",
        essentialFeatures: 'Power', advancedFeatures: 'Turbo', proFeatures: 'Nitro',
        ai_essential_info: 'AI handling of text responses for the use of WonderChat.',
        ai_advanced_info: 'AI handling of text responses for auto-moderation, WonderChat and MicroChat tasks.',
        ai_pro_info: 'AI handling of text and image responses for auto-moderation, WonderChat and MicroChat tasks (x4).',
        aiAgentName: 'AI Agent Name',
        moderationDelaySetting: 'Follow-up delay',
        moderationDelayOptionQuick: 'Quick (within the next minute or so)',
        moderationDelayOptionBalanced: 'Balanced (within the next hour or so)',
        moderationDelayOptionThoughtful: 'Slow (after an hour or so)',
        autoModerationTooltip: 'When enabled you can turn-on auto-moderation for some tasks.',
        aiPicTooltip: 'Process and describe images using an AI agent.',
        aiAgentNameTooltip: 'Username participants will see for AI-generated comments.',
        moderationDelaySettingTooltip: 'Delay between a participant’s response and the AI agent’s follow-up comment.',
        autoModerationCount: 'Max. Follow-up Comments',
        autoModerationCountTooltip: 'Maximum number of follow-up comments per response.',
        showEmailAddresses: 'Show email addresses',
        hideEmailInfo: "When turned off, users' email addresses will not be visible throughout the platform.",
        allowToJoinByLink: 'Generic invitation link',
        allowToJoinByLinkInfo: "Allows anonymous participation via a generic link."
      },
      es: {
        cmntyTitle: 'Nombre de la comunidad', category: 'Categoría', select: 'Seleccionar...', government: 'Gobierno',
        media: 'Medios de comunicación', b2b: 'B2B', education: 'Educación', foodNdrinks: 'Alimentos & Bebidas',
        utilities: 'Servicios públicos', shopping: 'Experiencia de compra', hospitality: 'Hospitalidad', transport: 'Transporte',
        fashion: 'Moda', travel: 'Viajes', healthNwellbeing: 'Salud y Bienestar', pets: 'Mascotas', technology: 'Tecnología',
        financial: 'Finanzas', sports: 'Deporte', beautyNHigene: 'Higiene y Belleza', lifestyle: 'Estilo de vida',
        housing: 'Vivienda', childrenNparenting: 'Niñez y paternidad', other: 'Otro', subCategory: 'Subcategoría',
        sponsor: 'Patrocinador', sponsorWarning: 'Solo para tus registros.', activityTimeZone: 'Zona horaria para Actividades',
        activityTimeZoneWarning: "Zona horaria usada para mostrar Actividades si 'Usar Zona horaria del participante' está apagado.",
        localTiming: 'Usar Zona horaria del participante',
        activityTimeZoneInfo: 'Las actividades se muestran usando el ajuste \'Zona horaria para Actividades\'.',
        localTimingInfo: 'Las actividades se muestran usando la Zona Horaria de los participantes.', cmntyAvatar: 'Avatar',
        save: 'GUARDAR', socialCommunity: 'Comunidad Social',
        socialWarning: "Cuando está activado los participantes pueden acceder a las respuestas de otros.",
        criticalOptions: 'Opciones Críticas', close: 'Cerrar', open: 'Abrir', transfer: 'Transferir', archive: 'Archivar',
        purge: 'Purgar', unarchive: 'Desarchivar', community: ' Comunidad', cmtyLang: 'Los participantes hablan',
        optEn: 'Inglés', optEs: 'Español', numParts: 'Participantes', partsAccess: 'Acceso a participantes',
        quoteTitle: 'Costos', update: 'Actualizar', estimatedPrice: 'Costo estimado', aiTitle: "Funciones AI",
        aiPic: "Procesar Imágenes", aiVid: "Procesar Videos", autoModeration: "Automoderación",
        projectEntitled: "Funciones disponibles en el proyecto",
        essentialFeatures: 'Power', advancedFeatures: 'Turbo', proFeatures: 'Nitro',
        ai_essential_info: 'Procesamiento en AI de respuestas en texto para WonderChat.',
        ai_advanced_info: 'Procesamiento en AI de respuestas en texto para auto-moderación, WonderChat y tareas MicroChat.',
        ai_pro_info: 'Procesamiento en AI de respuestas en texto e imágenes para auto-moderación, WonderChat y tareas MicroChat.',
        aiAgentName: 'Nombre de agente AI',
        moderationDelaySetting: 'Tiempo para seguimiento',
        moderationDelayOptionQuick: 'Rápido (dentro del próximo minuto - aprox.)',
        moderationDelayOptionBalanced: 'Moderado (dentro de la próxima hora - aprox.)',
        moderationDelayOptionThoughtful: 'Lento  (después de una hora - aprox.)',
        autoModerationTooltip: 'Permite habilitar la auto-moderación para algunas tareas.',
        aiPicTooltip: 'Procesa y describe imágenes usando un agente AI.',
        aiAgentNameTooltip: 'Nombre de usuario que verán los participantes en comentarios generados por AI.',
        moderationDelaySettingTooltip: 'Tiempo entre la respuesta de un participante y el comentario de seguimiento del agente AI.',
        autoModerationCount: 'Comentarios de seguimiento',
        autoModerationCountTooltip: 'Número máximo de comentarios de seguimiento por respuesta.',
        showEmailAddresses: 'Muestra direcciones email',
        hideEmailInfo: "Si está apagado, los correos de los usuarios no serán visibles.",
        allowToJoinByLink: 'Invitación con enlace genérico',
        allowToJoinByLinkInfo: "Permite participación anónima con un enlace genérico."
      }
    };

    const subCategory = this.state.subcategories.map((sc, index) => { return <option key={index}>{sc}</option>});

    let modalAvatar;
    if(this.state.isModal){
      modalAvatar = <ImageModal objectId={this.props.community.id}
                                url={this.state.avatar}
                                handleToggle={this.handleToggle}
                                updateAvatar={this.updateAvatar}
                                updateCommunityAvatar={this.props.updateCommunityAvatar}
                                colors={colors}/>;
    }

    let modalTransfer;
    if(this.state.isModalTransfer){
      modalTransfer = <TransferModal communityId={this.props.community.id}
                                     handleToggleTransfer={this.handleToggleTransfer}
                                     language={this.props.language}
                                     colors={colors}/>
    }

    let avatar = this.state.avatar;
    if(avatar === '')
      avatar = '/assets/community.png';

    return (
      <div id="divGeneral" className="manage">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        { modalAvatar }
        { modalTransfer }
        <div className="px-settings-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-card px-card-settings">
                <div className="px-icon-settings ">
                  <FontAwesomeIcon icon={['fas','gear']}/>
                </div>
                <div className="panel-body px-body-padding-settings">
                  <div className="container-fluid">
                    <div className="row px-container-general-settings">
                      <div className="px-settings-inner-container">
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group first">
                              <span className="label-sm" style={{color: colors.color1[2]}}>{I18n[language].cmntyTitle}</span>
                              <input name="title" onChange={this.props.handleChange} value={community.title}
                                    type="text" className="form-control px-input-settings"/>
                            </div>
                          </div>
                        </div>

                        { community.project_type !== 'AIChat' && <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group first-level">
                              <span className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].cmtyLang}</span>
                              <select name="language" onChange={this.props.handleChange} value={community.language}
                                className="form-control px-input-settings">
                                <option value="en">{I18n[language].optEn}</option>
                                <option value="es">{I18n[language].optEs}</option>
                              </select>
                            </div>
                          </div>
                        </div> }

                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group first-level">
                                <span className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].category}</span>
                                <select name="category" onChange={this.handleCategory} value={community.category}
                                      className="form-control px-input-settings">
                                  <option value="">{I18n[language].select}</option>
                                  <option value="Government">{I18n[language].government}</option>
                                  <option value="Media">{I18n[language].media}</option>
                                  <option value="B2B">{I18n[language].b2b}</option>
                                  <option value="Education">{I18n[language].education}</option>
                                  <option value="Food & Drinks">{I18n[language].foodNdrinks}</option>
                                  <option value="Utilities">{I18n[language].utilities}</option>
                                  <option value="Shopping">{I18n[language].shopping}</option>
                                  <option value="Hospitality">{I18n[language].hospitality}</option>
                                  <option value="Transport">{I18n[language].transport}</option>
                                  <option value="Fashion">{I18n[language].fashion}</option>
                                  <option value="Travel">{I18n[language].travel}</option>
                                  <option value="Health & Wellbeing">{I18n[language].healthNwellbeing}</option>
                                  <option value="Pets">{I18n[language].pets}</option>
                                  <option value="Technology">{I18n[language].technology}</option>
                                  <option value="Financial">{I18n[language].financial}</option>
                                  <option value="Sports">{I18n[language].sports}</option>
                                  <option value="Beauty & Higene">{I18n[language].beautyNHigene}</option>
                                  <option value="Lifestyle">{I18n[language].lifestyle}</option>
                                  <option value="Housing">{I18n[language].housing}</option>
                                  <option value="Children and Parenting">{I18n[language].childrenNparenting}</option>
                                  <option value="Other">{I18n[language].other}</option>
                              </select>
                                <p className="px-label-information">{I18n[language].sponsorWarning}</p>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group first-level">
                                <span className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].subCategory}</span>
                              <select name="subcategory" onChange={this.props.handleChange} value={community.subcategory}
                                      className="form-control px-input-settings">
                                  <option value="">{I18n[language].select}</option>
                                { subCategory }
                              </select>
                            </div>
                          </div>
                        </div>

                        {community.project_type !== 'AIChat' &&
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group first-level">
                                <label className="label-sm"
                                       style={{color: colors.color1[2]}}>{I18n[language].showEmailAddresses}</label>
                                <br/>
                                <label className="px-switch" htmlFor="hideEmail">
                                  <input name="hide_participants_emails" id="hideEmail" type="checkbox"
                                         onChange={this.props.handleChange}
                                         checked={!community.hide_participants_emails}/>
                                  <div className="slider round"
                                       style={!community.hide_participants_emails ? {backgroundColor: colors.color3[3]} : {}}/>
                                </label>
                                <p className="px-label-information">
                                  {I18n[language].hideEmailInfo}
                                </p>
                              </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group first-level">
                                <label className="label-sm"
                                       style={{color: colors.color1[2]}}>{I18n[language].allowToJoinByLink}</label>
                                <br/>
                                <label className="px-switch" htmlFor="genericLink">
                                  <input name="allow_join_w_generic_link" id="genericLink" type="checkbox"
                                         onChange={this.props.handleChange}
                                         checked={community.allow_join_w_generic_link}/>
                                  <div className="slider round"
                                       style={community.allow_join_w_generic_link ? {backgroundColor: colors.color3[3]} : {}}/>
                                </label>
                                <p className="px-label-information">
                                  {I18n[language].allowToJoinByLinkInfo}
                                </p>
                              </div>
                            </div>
                          </div>
                        }

                        {community.project_type !== 'AIChat' &&
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group first-level">
                                <label className="label-sm"
                                       style={{color: colors.color1[2]}}>
                                  {'Transcribe videos'}
                                </label>
                                <br/>
                                <label className="px-switch" htmlFor="videoTranscript">
                                  <input name="video_transcript"
                                         id="videoTranscript"
                                         type="checkbox"
                                         onChange={this.props.handleChange}
                                         checked={community.video_transcript}
                                  />
                                  <div className="slider round"
                                       style={community.video_transcript ? {backgroundColor: colors.color3[3]} : {}}/>
                                </label>
                              </div>
                            </div>
                          </div>
                        }

                        {community.project_type !== 'AIChat' &&
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group first-level">
                              <label className="label-sm" style={{color: colors.color1[2]}}>{community.default_tz_flag ?
                                  I18n[language].localTiming : I18n[language].localTiming}</label>
                                <br/>
                                <label className="px-switch" htmlFor="timezoneFlag">
                                  <input name="default_tz_flag" id="timezoneFlag" type="checkbox"
                                         onChange={this.props.handleChange} checked={community.default_tz_flag}/>
                                  <div className="slider round"
                                       style={community.default_tz_flag ? {backgroundColor: colors.color3[3]} : {}}/>
                                </label>
                                <p className="px-label-information">{community.default_tz_flag ?
                                  I18n[language].localTimingInfo:
                                    I18n[language].activityTimeZoneInfo}</p>
                              </div>
                            </div>
                            {
                              !community.default_tz_flag &&
                              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="form-group first-level">
                                    <span className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].activityTimeZone}</span>
                                  <select name="default_tz" onChange={this.props.handleChange} value={community.default_tz || ''}
                                          className="form-control px-input-settings">
                                    <option value=''>Select...</option>
                                    <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                                    <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                                    <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                                    <option value="US/Alaska">(GMT-09:00) Alaska</option>
                                    <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                                    <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                                    <option value="US/Arizona">(GMT-07:00) Arizona</option>
                                    <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                    <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                                    <option value="America/Managua">(GMT-06:00) Central America</option>
                                    <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                                    <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                    <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                                    <option value="America/Bogota">(GMT-05:00) Bogota, Quito, Rio Branco</option>
                                    <option value="America/Lima">(GMT-05:00) Lima</option>
                                    <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                                    <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                                    <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                                    <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                                    <option value="America/Manaus">(GMT-04:00) Manaus</option>
                                    <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                    <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                                    <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                    <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                                    <option value="America/Godthab">(GMT-03:00) Greenland</option>
                                    <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                                    <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                                    <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                                    <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                                    <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                                    <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                                    <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                    <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                    <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                    <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                                    <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                                    <option value="Asia/Amman">(GMT+02:00) Amman</option>
                                    <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                                    <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                                    <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                    <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                                    <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                                    <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                                    <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                                    <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                                    <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                                    <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                    <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                                    <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                                    <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                                    <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                                    <option value="Asia/Baku">(GMT+04:00) Baku</option>
                                    <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                                    <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                    <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                                    <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                                    <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                    <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                                    <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                                    <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                                    <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                    <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                                    <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                    <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                    <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                    <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                    <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                    <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                    <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                                    <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                    <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                    <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                    <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                    <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                    <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                    <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne</option>
                                    <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                    <option value="Australia/Sydney">(GMT+10:00) Sydney</option>
                                    <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                                    <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                    <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                                    <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                    <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                    <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                  </select>
                                </div>
                                <p className="px-label-information">{I18n[language].activityTimeZoneWarning} </p>
                              </div>
                            }
                        </div> }

                      </div>
                      <div className="px-image-container text-center">
                        <div className="form-group first">
                            <span className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].cmntyAvatar}</span>
                          <img onClick={this.handleToggle} src={avatar} className="px-thumb-100 img-responsive" alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action general">
                      <button onClick={this.validation} className="btn px-btn lg btn-blue-base"
                              style={{backgroundColor: colors.color1[3]}}>{I18n[language].save}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {
                community.ai_plan && community.ai_plan !== "aiPlan00" &&
                <div className="px-card px-card-settings">
                  <div className="panel-body social-settings">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <div className="px-waring-item-container">
                          <label className="label-options mg-bottom-10">{I18n[language].aiTitle}</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <div>
                          {/*<span style={{marginRight: '5px'}}>*/}
                          {/*  {I18n[language].projectEntitled}:*/}
                          {/*</span>*/}
                          <div style={{
                            color: colors.color4[3],
                            fontFamily: '"Sharp Sans ExtraBold", Helvetica, sans-serif !important',
                            fontSize: '20px',
                            textTransform: 'uppercase',
                            marginRight: '5px'
                          }}>
                            {community.ai_plan === 'aiPlan02' ? I18n[language].proFeatures :
                              community.ai_plan === 'aiPlan01' ? I18n[language].advancedFeatures :
                                I18n[language].essentialFeatures
                            }
                          </div>
                          <div style={{fontStyle: 'italic', padding: '0 10px', color: '#b8b5b5', marginTop: '10px'}}>
                            {community.ai_plan === 'aiPlan02' ? I18n[language].ai_pro_info :
                              community.ai_plan === 'aiPlan01' ? I18n[language].ai_advanced_info :
                                I18n[language].ai_essential_info
                            }
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <div className="row" style={{minHeight: '76px'}}>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group first-level">
                              <span className="label-sm"
                                    style={{
                                      color: "rgb(82, 79, 255)",
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                <span>{I18n[language].autoModeration}</span>
                                <span style={{
                                  fontSize: '14px',
                                  marginLeft: '5px'
                                }}
                                      data-tooltip-content={I18n[language].autoModerationTooltip}
                                >
                                    <FontAwesomeIcon icon={['fal', 'circle-info']}/>
                                  </span>
                              </span>
                              <label className="px-switch" htmlFor="autoModerationFlag">
                                <input name="ai_auto_moderation" id="autoModerationFlag" type="checkbox"
                                       onChange={this.props.handleChange}
                                       checked={community.ai_auto_moderation} disabled={role_2 !== 'Owner'}/>
                                <div className="slider round"
                                     style={community.ai_auto_moderation ? {backgroundColor: colors.color3[3]} : {}}/>
                              </label>
                            </div>
                          </div>
                          {
                            community.ai_plan === "aiPlan02" &&
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group first-level">
                                <span className="label-sm"
                                      style={{
                                        color: "rgb(82, 79, 255)",
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                      }}>
                                  <span>{I18n[language].aiPic}</span>
                                  <span style={{
                                    fontSize: '14px',
                                    marginLeft: '5px'
                                  }}
                                        data-tooltip-content={I18n[language].aiPicTooltip}
                                  >
                                    <FontAwesomeIcon icon={['fal', 'circle-info']}/>
                                  </span>
                                </span>
                                <label className="px-switch" htmlFor="aiAllowPictures">
                                  <input name="ai_allow_pictures" id="aiAllowPictures" type="checkbox"
                                         onChange={this.props.handleChange}
                                         checked={community.ai_allow_pictures} disabled={role_2 !== 'Owner'}/>
                                  <div className="slider round"
                                       style={community.ai_allow_pictures ? {backgroundColor: colors.color3[3]} : {}}/>
                                </label>
                              </div>
                            </div>
                          }
                          {/*<div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">*/}
                          {/*  <div className="form-group first-level">*/}
                          {/*    <label className="label-sm" style={{color: "rgb(82, 79, 255)"}}>*/}
                          {/*      {I18n[language].aiVid}*/}
                          {/*    </label>*/}
                          {/*    <br/>*/}
                          {/*    <label className="px-switch" htmlFor="aiAllowVideos">*/}
                          {/*      <input name="ai_allow_videos" id="aiAllowVideos" type="checkbox" onChange={this.props.handleChange}*/}
                          {/*             checked={community.ai_allow_videos} disabled={role_2 !== 'Owner'}/>*/}
                          {/*      <div className="slider round" style={community.ai_allow_videos ? {backgroundColor: colors.color3[3]} : {}}/>*/}
                          {/*    </label>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                        </div>
                        <div className="row">
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="form-group first"
                                 style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                              <span className="label-sm"
                                    style={{color: colors.color1[2]}}>
                                <span>{I18n[language].aiAgentName}</span>
                                <span style={{
                                  fontSize: '14px',
                                  marginLeft: '5px'
                                }}
                                      data-tooltip-content={I18n[language].aiAgentNameTooltip}
                                >
                                    <FontAwesomeIcon icon={['fal', 'circle-info']}/>
                                  </span>
                              </span>
                              <input name="ai_name" onChange={this.props.handleChange} value={community.ai_name || ''}
                                     style={{textAlign: 'center', width: '60%'}} type="text"
                                     className="form-control px-input-settings"/>
                            </div>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="form-group first"
                                 style={{
                                   display: 'flex',
                                   flexDirection: 'column',
                                   alignItems: 'center',
                                   visibility: community.ai_auto_moderation ? 'visible' : 'hidden'
                                 }}>
                                <span className="label-sm"
                                      style={{color: colors.color1[2], display: 'flex'}}>
                                  <span>{I18n[language].moderationDelaySetting}</span>
                                  <span style={{
                                    fontSize: '14px',
                                    marginLeft: '5px'
                                  }}
                                        data-tooltip-content={I18n[language].moderationDelaySettingTooltip}
                                  >
                                    <FontAwesomeIcon icon={['fal', 'circle-info']}/>
                                  </span>
                                </span>

                              <select name="aiModerationDelaySetting" onChange={this.props.handleChange}
                                      value={this.state.aiModerationDelaySetting}
                                      className="form-control px-input-settings"
                                      style={{textAlign: 'center', width: '90%'}}>
                                <option value="">{I18n[language].select}</option>
                                <option value="quick">{I18n[language].moderationDelayOptionQuick}</option>
                                <option value="balanced">{I18n[language].moderationDelayOptionBalanced}</option>
                                <option value="thoughtful">{I18n[language].moderationDelayOptionThoughtful}</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row" style={{marginTop: '10px'}}>
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="form-group first"
                                 style={{display: 'flex',
                                   flexDirection: 'column',
                                   alignItems: 'center',
                                   visibility: community.ai_auto_moderation ? 'visible' : 'hidden'
                                 }}>
                              <span className="label-sm"
                                    style={{color: colors.color1[2]}}>
                                <span>{I18n[language].autoModerationCount}</span>
                                <span style={{
                                  fontSize: '14px',
                                  marginLeft: '5px'
                                }}
                                      data-tooltip-content={I18n[language].autoModerationCountTooltip}
                                >
                                    <FontAwesomeIcon icon={['fal', 'circle-info']}/>
                                  </span>
                              </span>
                              <input name="ai_auto_moderation_count" onChange={this.props.handleChange}
                                     value={community.ai_auto_moderation_count || ''}
                                     type="number"
                                     style={{textAlign: 'center', width: '60%'}}
                                     className="form-control px-input-settings"/>
                            </div>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="form-group first"
                                 style={{
                                   display: 'flex',
                                   flexDirection: 'column',
                                   alignItems: 'center',
                                   visibility: community.ai_auto_moderation ? 'visible' : 'hidden'
                                 }}>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action general">
                            <button onClick={this.validation} className="btn px-btn lg btn-blue-base"
                                    style={{backgroundColor: colors.color1[3]}}>{I18n[language].save}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {
                community.project_type !== 'AIChat' && <div className="px-card px-card-settings">
                  <div className="panel-body social-settings">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <div className="px-waring-item-container">
                          <label className="label-sm"
                                 style={{color: colors.color1[2]}}>{I18n[language].socialCommunity}</label>
                          <label className="px-switch" htmlFor="socialFlag">
                            <input name="social_flag" id="socialFlag" type="checkbox" onChange={this.props.handleChange}
                                   checked={community.social_flag} disabled={role_2 !== 'Owner'}/>
                            <div className="slider round"
                                 style={community.social_flag ? {backgroundColor: colors.color3[3]} : {}}/>
                          </label>

                          <div className="px-alert-item mg-top-15">
                            <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-alert"
                                             style={{color: colors.color4[3]}}/>
                            <p className="px-label-warning text-left"
                               style={{color: colors.color4[3]}}>{I18n[language].socialWarning}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {
                role_2 === 'Owner' && community.project_type !== 'AIChat' ?
                  <div className="px-card px-card-settings">
                    <div className="panel-body social-settings">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                          <div className="px-waring-item-container">
                            <label className="label-options mg-bottom-10">{I18n[language].quoteTitle}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{textAlign: 'center'}}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="label-sm"
                                  style={{color: colors.color1[2]}}>{I18n[language].numParts}</span><br/>
                            <span>{community.num_participants}</span>
                            {/* <input name="num_participants" onChange={this.props.handleChange} value={community.num_participants}
                                 type="text" className="form-control px-input-settings" readOnly={true}/> */}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group">
                            <span className="label-sm"
                                  style={{color: colors.color1[2]}}>{I18n[language].partsAccess}</span><br/>
                            <span>
                            {`${community.participants_start ? community.participants_start : 'NA'} 
                            to ${community.participants_end ? community.participants_end : 'NA'}`}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <span className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].estimatedPrice}</span><br />
                          <span>US${estimate.estimated_cost}</span>
                          {/* <input name="num_participants" onChange={this.props.handleChange} value={community.num_participants}
                                type="text" className="form-control px-input-settings" readOnly={true}/> */}
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="form-group">
                          <span className="label-sm" style={{color: colors.color1[2]}}>&nbsp;</span><br/>
                          <span className="" onClick={() => this.props.updateShowModalQuote('open')}
                                  data-target="#modalQuote678" style={{color: colors.color1[3], cursor: 'pointer', fontSize: '16px'}}
                                  disabled={!this.state.flagPartsEnd}>
                            {loadingQuote ? <FontAwesomeIcon icon={['fas', 'spinner']} spin /> : <FontAwesomeIcon icon={['fas', 'pencil']} /> }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> : ''
              }
              {
                role_2 === 'Owner' &&
                <div className="px-card px-card-settings">
                  <div className="panel-body px-crit-options-body">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <div className="critical-options-container">
                          <label className="label-options mg-bottom-25">{I18n[language].criticalOptions}</label>
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <div className="px-crit-comm-options-cont">
                                  <div className="px-item-crit">
                                    {
                                      community.state === 'Enabled' &&
                                      <button onClick={this.validationAllowance}
                                              className="btn px-btn lg btn-orange-base btn-comm-options"
                                              style={{ backgroundColor: colors.color4[3]}}>
                                        {I18n[language].close}
                                      </button>
                                    }
                                    {
                                      community.state === 'Closed' &&
                                        <React.Fragment>
                                          {
                                            community.participants_end >= moment().format() &&
                                            <button onClick={this.validationAllowance}
                                                    className="btn px-btn lg btn-orange-base btn-comm-options"
                                                    style={{ backgroundColor: colors.color4[3]}}>
                                              {I18n[language].open}
                                            </button>
                                          }
                                          <button onClick={this.props.updateShowModalArchive}
                                                  className="btn px-btn lg btn-orange-base btn-comm-options"
                                                  style={{ backgroundColor: colors.color4[3] }}>
                                            {I18n[language].archive}
                                          </button>
                                        </React.Fragment>
                                    }
                                    {/* <button onClick={this.handleToggleTransfer}
                                            className="btn px-btn lg btn-orange-base btn-comm-options">
                                      {I18n[language].transfer}
                                    </ button> */}
                                  </div>
                                {
                                  community.state === 'Archived' &&
                                  <div className="px-item-crit">
                                    <button onClick={this.props.updateShowModalDelete}
                                            className='btn px-btn lg btn-orange-base btn-comm-options'
                                            style={{ backgroundColor: colors.color4[3] }}>
                                      {I18n[language].purge}
                                    </button>
                                    <button onClick={() => this.props.updateCommunityState('Closed', community.id)}
                                            className="btn px-btn lg btn-orange-base btn-comm-options"
                                            style={{ backgroundColor: colors.color4[3] }}>
                                      {I18n[language].unarchive}
                                    </button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          {
            this.props.showModalClose &&
              <CSMCloseCMTY communityId={community.id}
                            title={community.title}
                            state={community.state}
                            updateShowModalClose={this.props.updateShowModalClose}
                            closeCommunity={this.props.closeCommunity}
                            language={language}
                            colors={colors}/>
          }
          {
            this.props.showModalArchive &&
              <CSMArchiveCMTY communityId={community.id}
                              title={community.title}
                              state={community.state}
                              updateShowModalArchive={this.props.updateShowModalArchive}
                              archiveCommunity={this.props.archiveCommunity}
                              language={language}
                              colors={colors}/>
          }
          {
            this.props.showModalDelete &&
              <CSMPurgeCMTY communityId={community.id}
                            title={community.title}
                            state={community.state}
                            updateShowModalDelete={this.props.updateShowModalDelete}
                            purgeCommunity={this.props.purgeCommunity}
                            language={language}
                            colors={colors}/>
          }
          {
            this.props.showModalTimeZone &&
              <CSMTimeZoneCMTY communityId={community.id}
                              default_tz={community.default_tz}
                              default_tz_flag={community.default_tz_flag}
                              updateShowModalTimeZone={this.props.updateShowModalTimeZone}
                              updateTimeZone={this.props.updateTimeZone}
                              language={language}
                              colors={colors}/>
          }
          {
            this.state.showAllowanceModal &&
            <ReactModal isOpen={this.state.showAllowanceModal} contentLabel="Settings Card"
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={this.updateShowAllowanceModal}
                        className="px-modal-content" overlayClassName="px-modal-overlay">
              <div className="modal-dialog">
                <CSMAllowance state={community.state}
                              updateShowModalClose={this.updateShowAllowanceModal}
                              language={this.props.language}
                              colors={colors}/>
              </div>
            </ReactModal>
          }
        </div>
      </div>
    )
  }

  isInclusivelyAfterDay = (a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return !isBeforeDay(a, b);
  }

  showDates = () => {
    const startDate = this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : null;
    const endDate = this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null;

    this.props.updateDates(startDate, endDate);
  };

  validation = () => {
    if(this.props.community.default_tz_flag){
      this.props.saveCommunity();
    } else {
      if(this.props.community.default_tz !== ''){
        this.props.saveCommunity();
      } else {
        alert('Please select the Activity Ruling Time Zone.');
      }
    }
  }

  validationAllowance = () => {
    const {communities, community, papt, maxPapt, company} = this.props;

    const counterActiveComm = communities.filter(c => c.state === 'Enabled').length;
    const counterClosedComm = communities.filter(c => c.state === 'Closed').length;
    const nextPapt = 0 + papt;  //TO-DO get the right value of community.num_all_participants, actally it's the papt
    //const nextPapt = community.num_all_participants + papt;
    // console.log(nextPapt);

    if(community.state === 'Enabled'){
      if(counterClosedComm < company.closed_comm){
        this.props.updateShowModalClose();
      } else {
        this.updateShowAllowanceModal();
      }
    } else if(community.state === 'Closed'){
      if(counterActiveComm < 3 && nextPapt <= maxPapt){
        this.props.updateShowModalClose();
      } else {
        this.updateShowAllowanceModal();
      }
    }
  };

  updateShowAllowanceModal = () => {
    this.setState(state => ({showAllowanceModal: !state.showAllowanceModal}));
  };

  handleCategory = (event) => {
    this.props.handleChange(event);
    const category = event.target.value;

    this.setState({
      subcategories: this._getSubCategories(category)
    });
  };

  handleToggle = () => {
    this.setState(prevState => ({ isModal: !prevState.isModal }));
  };

  handleToggleDelete = () => {
    this.setState({ isModalDelete: !this.state.isModalDelete })
  };

  handleToggleTransfer = () => {
    this.setState({isModalTransfer: !this.state.isModalTransfer})
  };

  updateAvatar = (newAvatar) => {
    this.setState({avatar: newAvatar});
  };

  _getSubCategories(category){
    let subs = [];

    switch(category){
      case 'Media':
        subs = ['Entertainment', 'News', 'Radio'];
        break;
      case 'Food & Drinks':
        subs = ['Beers', 'Wine & Spirits', 'Softdrinks & Water', 'Snacks', 'Ready to Eat food'];
        break;
      case 'Utilities':
        subs = ['Gas', 'Electricity'];
        break;
      case 'Shopping':
        subs = ['Online shopping', 'Brick n Mortar shopping'];
        break;
      case 'Hospitality':
        subs = ['Restaurants', 'Hotels'];
        break;
      case 'Transport':
        subs = ['Automobiles', 'Cycles and motorcycles', 'Public transport'];
        break;
      case 'Travel':
        subs = ['Airlines', 'Cruiseships'];
        break;
      case 'Health & Wellbeing':
        subs = ['Pharma', 'Wellbeing', 'Healthcare professionals', 'Medical Services'];
        break;
      case 'Technology':
        subs = ['TV', 'Mobiles', 'Computers', 'Software', 'Telco', 'Gaming', 'Cameras'];
        break;
      case 'Financial':
        subs = ['Credit Cards', 'Savings'];
        break;
      case 'Lifestyle':
        subs = ['Gym and exercise', 'Relationships', 'LGBT', 'Age care'];
        break;
      case 'Children and Parenting':
        subs = ['Toys'];
        break;
    }

    return subs;
  }

  getAiDelayModerationSetting = (delay, offset) => {
    if (delay === 60 && offset === 20) {
      return 'quick';
    } else if (delay === 2700 && offset === 450) {
      return 'balanced';
    } else if (delay === 5400 && offset === 800) {
      return 'thoughtful';
    }
    return '';
  }
}
