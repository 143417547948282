import PropTypes from 'prop-types';
import React from 'react';

export default class TRChAnswer extends React.Component {
  static propTypes = {
    focusOn: PropTypes.string.isRequired,
    subtype: PropTypes.string.isRequired,
    answer: PropTypes.object.isRequired,
    response: PropTypes.object.isRequired,
    openLightBox: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    ansNoImages: PropTypes.bool,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name
    };
  }

  render() {
    const { focusOn, subtype, answer, response, index, ansNoImages, language, colors} = this.props;
    const i18n = {
      en: {
        otherPlaceholder: 'Other? type it here'
      }, es: {
        otherPlaceholder: '¿Otra opción? escribe aquí'
      }
    }
    const image = answer.img !== '' ? answer.img : '/assets/placeholder.png';
    let answerOption;

    if(focusOn === 'Text'){
      // Text - Multiple
      if(answer.img !== undefined){
        let checked = false;
        if(response){
          checked = response.data.answersId.find(item => item.id === answer.id) !== undefined;
        }

        answerOption = <div className={(checked) ? 'px-option-item active' : 'px-option-item'}
          style={{ backgroundColor: checked && colors.color0[6]}}>
          <img src={image} onClick={(e) => this.props.openLightBox(index, e)} alt="" className="px-img-item"
               style={{display: ansNoImages ? 'none' : 'block', cursor:'pointer'}}/>
          <div className="px-check-cont">
            <label className="container-check choice" style={{cursor:'default'}}>
              <input type="checkbox" defaultChecked={checked} disabled={true}/>
              <span className="checkmark  choice"/>
              <div className="choice" style={{marginLeft: '0 15px'}}>{answer.text}</div>
            </label>
          </div>
        </div>;
      } else {
        let other = '';
        let checked = false;
        if(response){
          const item = response.data.answersId.find(item => item.id === answer.id);
          other = item !== undefined ? item.other : '';
          checked = item !== undefined ? item.other !== '' : false;
        }

        answerOption = checked &&
        <div className={(checked) ? 'px-option-item active' : 'px-option-item'}
        style={{ backgroundColor: checked && colors.color0[6] }}>
          <div className="px-check-cont text-focus">
            <input value={other} type="text" placeholder={i18n[language].otherPlaceholder} readOnly={true}
                   className="input-choice text-focus"
              style={{ backgroundColor: 'transparent', border: 'none', borderRadius: '0', fontSize: '14px', color: '#262e37', letterSpacing: '1px' }}/>
            <label className="container-check choice" style={{cursor:'default'}}>
              <input type="checkbox" defaultChecked={checked} disabled={true}/>
              <span className="checkmark choice"/>
            </label>
          </div>
        </div>;
      }
    } else {
      // focusOn Images
      if(answer.img !== undefined){
        let checked = false;
        if(subtype === 'Single') {
          //console.log(response);
          if(response) {
            if(response.data.answersId[0]) {
              if(response.data.answersId[0].id === answer.id) {
                checked = true;
              }
            }
          }
        } else {
          if(response){
            checked = response.data.answersId.find(item => item.id === answer.id) !== undefined;
          }
        }

        answerOption = <div className={(checked) ? 'px-option-item active' : 'px-option-item'}
          style={{ backgroundColor: checked && colors.color0[6] }}>
          <img src={image} onClick={(e) => this.props.openLightBox(index, e)} alt="" className="px-img-item"
               style={{display: ansNoImages ? 'none' : 'block', cursor:'pointer'}}/>
          <div className="px-check-cont">
            <label className="container-check choice" style={{cursor:'default'}}>
              <input type="checkbox" defaultChecked={checked} disabled={true}/>
              <span className="checkmark  choice"/>
              <span className="choice" style={{marginLeft: ansNoImages ? '0' : '20px'}}>{answer.text}</span>
            </label>
          </div>
        </div>;

      } else {
        let other = '';
        let checked = false;

        if(subtype === 'Single') {
          if(response){
            if(response.data.answersId[0]){
              other = response.data.answersId[0].other;
              if(response.data.answersId[0].id === answer.id){
                checked = true;
              }
            }
          }
        } else {
          if(response){
            const item = response.data.answersId.find(item => item.id === answer.id);
            other = item !== undefined ? item.other : '';
            checked = item !== undefined ? item.other !== '' : false;
          }
        }

        answerOption = checked &&
        <div className={(checked) ? 'px-option-item active' : 'px-option-item'}
          style={{ backgroundColor: checked && colors.color0[6] }}>
          <div className="px-check-cont text-focus">
            <input value={other} type="text" placeholder={i18n[language].otherPlaceholder} readOnly={true}
                   className="input-choice text-focus"
                   style={{ backgroundColor: 'transparent', border: 'none', borderRadius: '0', fontSize: '14px', color: '#262e37', letterSpacing: '1px' }}/>
            <label className="container-check choice" style={{cursor:'default'}}>
              <input type="checkbox" defaultChecked={checked} disabled={true}/>
              <span className="checkmark choice"/>
            </label>
          </div>
        </div>;
      }
    }

    return(
      <div className={'wd-100'}>
        {answerOption}
      </div>
    )
  }
}
