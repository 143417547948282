import PropTypes from 'prop-types';
import React from 'react';

import AVTOpenEnd01 from "./AVTask/AVTOpenEnd01";
import AVTChoice from "./AVTask/AVTChoice";
import AVTCanvas from "./AVTask/AVTCanvas";
import AVTMicroChat from "./AVTask/AVTMicroChat";
import Backdrop from "../../../UI/Backdrop";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import spanishStrings from "react-timeago/lib/language-strings/es";
import englishStrings from "react-timeago/lib/language-strings/en";

export default class AVTask01 extends React.Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    cardinal: PropTypes.number.isRequired,
    communityId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    cmntyLanguage: PropTypes.string.isRequired,
    participants_view: PropTypes.string.isRequired,
    response: PropTypes.object,
    onEditorStateChange:PropTypes.func.isRequired,
    saveResponse: PropTypes.func.isRequired,
    questionsLength: PropTypes.number.isRequired,
    updateShowBlocker: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    deleteResponseImage: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    deleteVideo: PropTypes.func.isRequired,
    updateResponseMapsId: PropTypes.func.isRequired,
    blockerExpired: PropTypes.bool.isRequired,
    updateShowComments: PropTypes.func.isRequired,
    updateResponseComments: PropTypes.func.isRequired,
    taskSocialStatus: PropTypes.string.isRequired,
    updateChoiceItems: PropTypes.func.isRequired,
    scrollFirstTime: PropTypes.bool.isRequired,
    updateScrollFirstTime: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    scroll: PropTypes.string,
    setActiveTaskId: PropTypes.func.isRequired,
    type: PropTypes.string,
    item_scroll: PropTypes.object,
    from: PropTypes.string,
    isSavingResponse: PropTypes.bool,
    updateIsSavingResponse: PropTypes.func,
    updateOETextAndSave: PropTypes.func,
    helpStores: PropTypes.object.isRequired,
    updateHelpStore: PropTypes.func.isRequired,
    comunitySocial: PropTypes.bool.isRequired,
    cmntyAutoModeration: PropTypes.bool.isRequired,
    aiComment: PropTypes.object,
    username: PropTypes.string,
    qc: PropTypes.array,
    maAnnotations: PropTypes.object,
    updateMaAnnotations: PropTypes.func,
    updateResponseChat: PropTypes.func,
    avatar: PropTypes.string,
    updateFailedChat: PropTypes.func,
    base_url_ai: PropTypes.string,
    company: PropTypes.object.isRequired,
    addResponseEmbeddingAndModeration: PropTypes.func,
    naturalUsername: PropTypes.string,
    setCanvasObjForOPS: PropTypes.func.isRequired,
    aiAgentName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      backdropOpen: false,
      activeMicroId: -1
    }
  }

  updateActiveMicroId = response_id => {
    //console.log(response_id)
    this.setState({activeMicroId: response_id})
  }

  // After Beta create a new parent for task because they share some components as scrolls and so on

  render() {
    const { question, cardinal, userId, language, cmntyLanguage, participants_view, response, questionsLength,
      blockerExpired, taskSocialStatus, scrollFirstTime, scroll, type, item_scroll, from, isSavingResponse,
      colors, comunitySocial, cmntyAutoModeration, aiComment, username, qc, communityId, updateFailedChat,
      base_url_ai, company, naturalUsername} = this.props
    //console.log(question);
    let taskComponent = null;
    switch(question.task_type) {
      case 'Open End':
      case 'Multimedia':
        taskComponent = <AVTOpenEnd01 question={question}
                                      cardinal={cardinal}
                                      userId={userId}
                                      language={language}
                                      cmntyLanguage={cmntyLanguage}
                                      participants_view={participants_view}
                                      response={response}
                                      onEditorStateChange={this.props.onEditorStateChange}
                                      saveResponse={this.props.saveResponse}
                                      questionsLength={questionsLength}
                                      updateShowBlocker={this.props.updateShowBlocker}
                                      onDrop={this.props.onDrop}
                                      deleteResponseImage={this.props.deleteResponseImage}
                                      updateShowModal={this.props.updateShowModal}
                                      deleteVideo={this.props.deleteVideo}
                                      updateResponseMapsId={this.props.updateResponseMapsId}
                                      blockerExpired={blockerExpired}
                                      updateShowComments={this.props.updateShowComments}
                                      updateResponseComments={this.props.updateResponseComments}
                                      taskSocialStatus={taskSocialStatus}
                                      scrollFirstTime={scrollFirstTime}
                                      updateScrollFirstTime={this.props.updateScrollFirstTime}
                                      scroll={scroll}
                                      setActiveTaskId={this.props.setActiveTaskId}
                                      type={type}
                                      item_scroll={item_scroll}
                                      from={from}
                                      isSavingResponse={isSavingResponse}
                                      updateIsSavingResponse={this.props.updateIsSavingResponse}
                                      updateOETextAndSave={this.props.updateOETextAndSave}
                                      colors={colors}
                                      helpStores={this.props.helpStores}
                                      updateHelpStore={this.props.updateHelpStore}
                                      updateBackdrop={this.updateBackdrop}
                                      backdropVisibility={this.state.backdropOpen}
                                      comunitySocial={comunitySocial}
                                      cmntyAutoModeration={cmntyAutoModeration}
                                      aiComment={aiComment}
                                      username={username}
                                      qc={qc}
                                      company={company}
                                      addResponseEmbeddingAndModeration={this.props.addResponseEmbeddingAndModeration}
                                      aiAgentName={this.props.aiAgentName}
        />
        break;
      case 'Choice':
        taskComponent = <AVTChoice question={question}
                                   cardinal={cardinal}
                                   participants_view={participants_view}
                                   userId={userId}
                                   language={language}
                                   questionsLength={questionsLength}
                                   blockerExpired={blockerExpired}
                                   updateChoiceItems={this.props.updateChoiceItems}
                                   response={response}
                                   saveResponse={this.props.saveResponse}
                                   updateShowComments={this.props.updateShowComments}
                                   updateResponseComments={this.props.updateResponseComments}
                                   updateShowBlocker={this.props.updateShowBlocker}
                                   scrollFirstTime={scrollFirstTime}
                                   updateScrollFirstTime={this.props.updateScrollFirstTime}
                                   scroll={scroll}
                                   setActiveTaskId={this.props.setActiveTaskId}
                                   type={type}
                                   item_scroll={item_scroll}
                                   from={from}
                                   isSavingResponse={isSavingResponse}
                                   colors={colors}
                                   helpStores={this.props.helpStores}
                                   updateHelpStore={this.props.updateHelpStore}
                                   updateBackdrop={this.updateBackdrop}
                                   backdropVisibility={this.state.backdropOpen}
                                   comunitySocial={this.props.comunitySocial}
                                   taskSocialStatus={taskSocialStatus}
                                   company={company}
                                   aiAgentName={this.props.aiAgentName}
        />
        break;
      case 'Canvas':
        taskComponent = <AVTCanvas question={question}
                                   cardinal={cardinal}
                                   userId={userId}
                                   language={language}
                                   cmntyLanguage={cmntyLanguage}
                                   participants_view={participants_view}
                                   response={response}
                                   saveResponse={this.props.saveResponse}
                                   questionsLength={questionsLength}
                                   updateShowBlocker={this.props.updateShowBlocker}
                                   onDrop={this.props.onDrop}
                                   updateShowModal={this.props.updateShowModal}
                                   blockerExpired={blockerExpired}
                                   updateShowComments={this.props.updateShowComments}
                                   updateResponseComments={this.props.updateResponseComments}
                                   taskSocialStatus={taskSocialStatus}
                                   scrollFirstTime={scrollFirstTime}
                                   updateScrollFirstTime={this.props.updateScrollFirstTime}
                                   scroll={scroll}
                                   setActiveTaskId={this.props.setActiveTaskId}
                                   type={type}
                                   item_scroll={item_scroll}
                                   from={from}
                                   isSavingResponse={isSavingResponse}
                                   updateIsSavingResponse={this.props.updateIsSavingResponse}
                                   colors={colors}
                                   helpStores={this.props.helpStores}
                                   updateHelpStore={this.props.updateHelpStore}
                                   updateBackdrop={this.updateBackdrop}
                                   backdropVisibility={this.state.backdropOpen}
                                   comunitySocial={this.props.comunitySocial}
                                   maAnnotations={this.props.maAnnotations}
                                   updateMaAnnotations={this.props.updateMaAnnotations}
                                   company={company}
                                   setCanvasObjForOPS={this.props.setCanvasObjForOPS}
                                   aiAgentName={this.props.aiAgentName}
        />
        break;
      case 'MicroChat':
        const formatter = language === 'es' ? buildFormatter(spanishStrings) :
          buildFormatter(englishStrings)
        taskComponent = <AVTMicroChat question={question}
                                      cardinal={cardinal}
                                      communityId={communityId}
                                      userId={userId}
                                      language={language}
                                      cmntyLanguage={cmntyLanguage}
                                      participants_view={participants_view}
                                      response={response}
                                      saveResponse={this.props.saveResponse}
                                      questionsLength={questionsLength}
                                      updateShowBlocker={this.props.updateShowBlocker}
                                      onDrop={this.props.onDrop}
                                      updateShowModal={this.props.updateShowModal}
                                      blockerExpired={blockerExpired}
                                      updateShowComments={this.props.updateShowComments}
                                      updateResponseComments={this.props.updateResponseComments}
                                      taskSocialStatus={taskSocialStatus}
                                      scrollFirstTime={scrollFirstTime}
                                      updateScrollFirstTime={this.props.updateScrollFirstTime}
                                      scroll={scroll}
                                      setActiveTaskId={this.props.setActiveTaskId}
                                      type={type}
                                      item_scroll={item_scroll}
                                      from={'micro'}
                                      isSavingResponse={isSavingResponse}
                                      updateIsSavingResponse={this.props.updateIsSavingResponse}
                                      colors={colors}
                                      helpStores={this.props.helpStores}
                                      updateHelpStore={this.props.updateHelpStore}
                                      updateBackdrop={this.updateBackdrop}
                                      backdropVisibility={this.state.backdropOpen}
                                      comunitySocial={this.props.comunitySocial}
                                      formatter={formatter}
                                      updateResponseChat={this.props.updateResponseChat}
                                      username={username}
                                      avatar={this.props.avatar}
                                      updateActiveMicroId={this.updateActiveMicroId}
                                      activeMicroId={this.state.activeMicroId}
                                      updateFailedChat={updateFailedChat}
                                      cmntyAutoModeration={cmntyAutoModeration}
                                      aiComment={aiComment}
                                      qc={qc}
                                      base_url_ai={base_url_ai}
                                      company={company}
                                      naturalUsername={naturalUsername}
                                      aiAgentName={this.props.aiAgentName}
        />
        break
    }

    return (
      <div>
        <Backdrop show={this.state.backdropOpen} clicked={() => this.updateBackdrop(false)} styles={{zIndex: '1019'}}/>
        { taskComponent }
      </div>
    );
  }

  updateBackdrop = (visible) => {
    this.setState({ backdropOpen: visible })
  }
}