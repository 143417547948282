import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {getCSRF, showSuccessMsg} from "../../UI/Globals/PX_Funs"
import axios from "axios"
import ChMMain from "./Modal/ChMMain";
import { Tooltip as ReactTooltip } from "react-tooltip"

const NewChatModal = ({ language, colors, currentUserId, community_id, getAiChatRooms,
                        toggleShowNewChatModal, objExport, allAvailableActs, users, segments,
                        updateObjExportActions, genderAsSegment, room, modalAction, projectType, documents,
                        updateObjExpAllNone, aiImageProcessingAllowed, microChatsAllowed }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [title, setTitle] = useState(modalAction === 'update' ? room.title : '')
  const [sources, setSources] = useState({
      activities_ids: [],
      participants_ids: [],
      segments_ids: [],
      type_of_data: [],
      documents_ids: []
    })
  const [type, setType] = useState('Main')

  const toggleModal = () => {
    setIsOpen(isOpen => !isOpen);
    toggleShowNewChatModal();
  }

  const createAiChatRoom = async () => {
    if(title !== ''){
      const url = modalAction === 'create' ? '/ai/create_chatroom/' : '/ai/update_chatroom/' + room.id
      const sourcesFilter = {
        activities_ids: objExport.idActs,
        participants_ids: objExport.idPars,
        segments_ids: objExport.idSegs,
        type_of_data: objExport.dataTypes,
        task_types: objExport.taskTypes,
        documents_ids: objExport.idDocs
      }

      if (sourcesFilter.task_types.includes('Canvas') || sourcesFilter.task_types.includes('Multimedia')) {
        if (!sourcesFilter.type_of_data.includes('image')) {
          sourcesFilter.type_of_data.push('image');
        }
      }

      const data = {
        user_id: currentUserId,
        community_id: community_id,
        title: title,
        sources: JSON.stringify(sourcesFilter),
        state: 'enabled'
      };
      // console.log('data:', data);
      setLoading(true);

      try {
        const response = await axios.post(url, data, getCSRF())
        // console.log('response:', response)
        getAiChatRooms();
        setLoading(false);
        toggleModal();
      } catch (error) {
        setErrorMessage(error.response.data.message)
        setLoading(false)
      }
    }
  }

  const i18n = {
    en: {
      acts: 'Activities', title: 'Title', modalTitle: 'Setup your AI Chat', parts: 'Participants',
      segs: 'Segments', dataToInclude: 'Types of data', container1Title: 'Data from this community',
      container2Title: 'Data from external sources', files: 'Files', btnCancel: 'Cancel', btnSave: 'Add',
      btnUpdate: 'Update', titleMissing: 'Name this chat room before saving'
    },
    es: {
      acts: 'Actividades', title: 'Título', modalTitle: 'Configura tu Chat AI', parts: 'Participantes',
      segs: 'Segmentos', dataToInclude: 'Tipos de data', container1Title: 'Datos de esta comunidad',
      container2Title: 'Datos the fuentes externas', files: 'Archivos', btnCancel: 'Cancelar',
      btnSave: 'Crear', btnUpdate: 'Actualizar', titleMissing: 'Dale un título al chat antes de crearlo'
    }
  }

  const updateType = type => {
    setType(type)
  }

  const fetchAllowedDataTypes = () => {
    let allowedDataTypes = ['text', 'comment', 'wordbook']
    if (aiImageProcessingAllowed) {
      allowedDataTypes.push('image')
    }
    return allowedDataTypes
  }

  const fetchAllowedTaskTypes = () => {
    let allowedTaskTypes = ['Open End', 'Choice']
    if (aiImageProcessingAllowed) {
      allowedTaskTypes.push('Canvas', 'Multimedia')
    }

    if (microChatsAllowed) {
      allowedTaskTypes.push('MicroChat')
    }

    return allowedTaskTypes
  }

  return (
      <div className="modal-dialog">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="modal-content">
          <div className="modal-header modal-delete-activity" >
            <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                    data-dismiss="modal" aria-hidden="true"
                    onClick={toggleModal}>
              <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
            </button>
            <h4 className="px-modal-title">{i18n[language].modalTitle}</h4>
          </div>
          <div className="modal-body px-modal-body-adv-filters" style={{paddingBottom:'0'}}>
            <div className="tab-content active">
              <ChMMain type={type} updateType={updateType} language={language} colors={colors} title={title}
                       setTitle={setTitle} objExport={objExport} allAvailableActs={allAvailableActs} users={users}
                       segments={segments} updateObjExportActions={updateObjExportActions}
                       genderAsSegment={genderAsSegment}
                       dataTypes={fetchAllowedDataTypes()}
                       taskTypes={fetchAllowedTaskTypes()}
                       room={room}
                       projectType={projectType}
                       documents={documents}
                       updateObjExpAllNone={updateObjExpAllNone}/>
            </div>
          </div>
          <div className="px-footer-container-buttons row" style={{paddingBottom:'20px'}}>
            <button type="button" onClick={createAiChatRoom} className="btn px-btn sm btn-blue-base"
                    style={{backgroundColor: colors.color1[3]}}
                    disabled={!title}
                    data-tooltip-content={!title ? i18n[language].titleMissing : '' }>
              {modalAction === 'create' ? i18n[language].btnSave : i18n[language].btnUpdate}
            </button>
            <button type="button" className="btn px-btn sm btn-gray-darker" onClick={toggleModal}>
              {i18n[language].btnCancel}
            </button>
          </div>
        </div>
      </div>
  );
}

NewChatModal.propTypes = {
  language: PropTypes.string.isRequired,
  colors: PropTypes.object.isRequired,
  community_id: PropTypes.number.isRequired,
  toggleShowNewChatModal: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  getAiChatRooms: PropTypes.func.isRequired,
  allAvailableActs: PropTypes.array.isRequired,
  objExport: PropTypes.object.isRequired,
  updateObjExportActions: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  segments: PropTypes.array.isRequired,
  genderAsSegment: PropTypes.bool.isRequired,
  modalAction: PropTypes.string.isRequired,
  projectType: PropTypes.string.isRequired,
  documents: PropTypes.array,
  room: PropTypes.object
};

export default NewChatModal
