import PropTypes from 'prop-types';
import React from 'react';
import {getCountryName, setIconGender} from "../../../UI/Globals/PX_Funs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MUSeeNotes from "../../../UI/MUSeeNotes";
import {usernamesToDisplay} from "../../shared_utils";

export default class TBUserCardMed extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    from: PropTypes.string.isRequired,
    showModalExportHandler: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    activity: PropTypes.object,
    role_2: PropTypes.string,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isMax: true,
      showSeeNotes: false
    }
  }

  render() {
    const {user, from, activity, language, role_2, colors} = this.props;
    const regionCountry = user.state_country === '' ? getCountryName(user.country) :
      user.state_country + ', ' + getCountryName(user.country);

    const activityTitle = activity !== undefined ? activity.title : 'Activity not available';

    const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
    const mainName = availableUsernames[0];
    const alternativeNames = availableUsernames.length > 1 ? availableUsernames.slice(1).join(', ') : ''

    return (
      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-lg-offset-1 col-md-offset-1">
        <div className="panel px-card px-card-profile">
          <div className="panel-body">
            <img src={user.avatar !== '' ? user.avatar : '/assets/user.png'} className="img-profile img-circle" alt=""/>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="title-text-container">
                      <p className="profile-name" style={{marginBottom:'5px'}}>{activityTitle}</p>
                      <p className="user text-center">{activity !== undefined ? 'by ' : 'to '}
                      {mainName}</p>
                      {
                        from === 'Collaborator' &&
                          <React.Fragment>
                            <FontAwesomeIcon onClick={this.updateShowSeeNotes}
                                             className="fixed-share-alt" icon={['fas','note-sticky']}
                                             style={{cursor:'pointer', marginRight: '25px'}}/>
                            <FontAwesomeIcon onClick={() => this.props.showModalExportHandler(null, null, [user.id])}
                                             icon={['fas','share']} className="fixed-share-alt"/>
                          </React.Fragment>

                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
                    <div className="separator mg-bottom-10"/>
                    <div className="row px-profile-info-container">
                      <div className="left-container">
                        <div className="add-container">
                          <div className="px-tags-container">
                            {
                              from === 'Collaborator' &&
                              <div className="tags-container">
                                {
                                  user.segments.map(segment => {
                                    return(
                                      <FontAwesomeIcon key={segment.id} icon={['fas','bookmark']} className="tag"
                                                       style={{color: segment.color}} data-tooltip-content={segment.name}/>
                                    )
                                  })
                                }
                              </div>
                            }
                          </div>
                          {
                            user.gender !== '' &&
                            <div className="px-genders-container">
                              <div className="gender-container" data-tooltip-content={user.gender}>
                                {setIconGender(user.gender)}
                              </div>
                            </div>
                          }
                          {
                            user.country &&
                            <div className="px-country-container" data-tooltip-content={regionCountry}>
                              <span>{user.country}</span>
                            </div>
                          }
                        </div>
                        {
                          (from === 'Collaborator' &&
                            (role_2 === 'Owner' || role_2 === 'Moderator' || role_2 === 'Recruiter')) &&
                          <p className="email mg-top-bottom-5">{user.email}</p>
                        }
                      </div>
                      <div className="right-container">
                        <p className="data-right">{user.cuBio}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showSeeNotes &&
          <MUSeeNotes showSeeNotes={this.state.showSeeNotes}
                      updateShowSeeNotes={this.updateShowSeeNotes}
                      user={user}
                      language={language}
                      colors={colors}/>
        }
      </div>
    );
  }

  updateShowSeeNotes = () => {
    this.setState(prevState => ({showSeeNotes : !prevState.showSeeNotes}));
  }
}
