import PropTypes from 'prop-types';
import React from 'react';

import AEAttributes01 from "./ActivityEditor/AEAttributes01";
import AE_AddTask from "./ActivityEditor/AE_AddTask";
import AETaskList01 from "./ActivityEditor/AETaskList01";
import BackdropTransparent from "../../UI/BackdropTransparent";
import Dotdotdot from "react-dotdotdot";

export default class ActivityEditor01 extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    updateTaskAttributeMode: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    cancelEditTaskAttributes: PropTypes.func.isRequired,
    updateTaskAttributes: PropTypes.func.isRequired,
    updateStartExpireDates: PropTypes.func.isRequired,
    addQuestion: PropTypes.func.isRequired,
    mapsSelect: PropTypes.array.isRequired,
    handleMapsSelected: PropTypes.func.isRequired,
    cancelEditQuestion: PropTypes.func.isRequired,
    updateQuestionMode: PropTypes.func.isRequired,
    onSortEndQuestion: PropTypes.func.isRequired,
    handleChangeQuestion: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    updateQuestion: PropTypes.func.isRequired,
    onEditorStateChange: PropTypes.func.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    onEditorStateChangeQuestion: PropTypes.func.isRequired,
    isGrid: PropTypes.bool.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    handleRequired: PropTypes.func.isRequired,
    showAddTaskBottom: PropTypes.bool.isRequired,
    showAddTaskTop: PropTypes.bool.isRequired,
    showAddTaskMiddle: PropTypes.bool.isRequired,
    handleShowAddTask: PropTypes.func.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    deleteAnswer: PropTypes.func.isRequired,
    updateObjective: PropTypes.func.isRequired,
    deleteObjective: PropTypes.func.isRequired,
    addObjective: PropTypes.func.isRequired,
    addAnswer: PropTypes.func.isRequired,
    onSortEndAnswerList: PropTypes.func.isRequired,
    handleCanAttachMedia: PropTypes.func.isRequired,
    data_signature: PropTypes.object.isRequired,
    communityLang: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    communityPartStart: PropTypes.string.isRequired,
    communityPartEnd: PropTypes.string.isRequired,
    handleQuestionColorsChange: PropTypes.func,
    communityProjectType: PropTypes.string.isRequired,
    cmntyTaskTypeLevel: PropTypes.string,
    cmntyMaxMicroChats: PropTypes.number,
    microChatCount: PropTypes.number,
    cmntyAiAutoModeration: PropTypes.bool,
    cmntyAllowImgs: PropTypes.bool,
    cmntyAiPlan: PropTypes.string,
    isSavingTask: PropTypes.number
  };

  constructor(props) {
    super(props)
  }

  render() {
    const {task, language, communitySocial, isGrid, showAddTaskBottom, showAddTaskTop, showAddTaskMiddle,
      data_signature, communityLang, colors, communityPartStart, communityPartEnd, communityProjectType,
      cmntyTaskTypeLevel, cmntyMaxMicroChats, microChatCount} = this.props

    const i18n = {
      en: {newTask: 'Add New Task'},
      es: {newTask: 'Nueva Tarea'}
    };

    let btnMarginTop = '80px';
    if(task.questions !== undefined){
      if(task.questions.length === 0){
        btnMarginTop = '0';
      }
    }

    const dropUpClass = showAddTaskBottom ? 'dropup open': 'dropup';

    return (
      <div className="px-details-actv-cont" style={{display: isGrid && 'none'}}>
        <AEAttributes01 task={task}
                        language={language}
                        updateTaskAttributeMode={this.props.updateTaskAttributeMode}
                        handleChange={this.props.handleChange}
                        cancelEditTaskAttributes={this.props.cancelEditTaskAttributes}
                        updateTaskAttributes={this.props.updateTaskAttributes}
                        updateStartExpireDates={this.props.updateStartExpireDates}
                        onEditorStateChange={this.props.onEditorStateChange}
                        communitySocial={communitySocial}
                        updateShowModal={this.props.updateShowModal}
                        colors={colors}
                        communityPartStart={communityPartStart}
                        communityPartEnd={communityPartEnd}/>

        <AETaskList01 questions={task.questions}
                      addQuestion={this.props.addQuestion}
                      mapsSelect={this.props.mapsSelect}
                      handleMapsSelected={this.props.handleMapsSelected}
                      cancelEditQuestion={this.props.cancelEditQuestion}
                      updateQuestionMode={this.props.updateQuestionMode}
                      onSortEndQuestion={this.props.onSortEndQuestion}
                      handleChangeQuestion={this.props.handleChangeQuestion}
                      updateShowModal={this.props.updateShowModal}
                      updateQuestion={this.props.updateQuestion}
                      onEditorStateChangeQuestion={this.props.onEditorStateChangeQuestion}
                      deleteMedia={this.props.deleteMedia}
                      handleRequired={this.props.handleRequired}
                      communitySocial={communitySocial}
                      showAddTaskBottom={showAddTaskBottom}
                      showAddTaskTop={showAddTaskTop}
                      showAddTaskMiddle={showAddTaskMiddle}
                      handleShowAddTask={this.props.handleShowAddTask}
                      updateAnswer={this.props.updateAnswer}
                      deleteAnswer={this.props.deleteAnswer}
                      updateObjective={this.props.updateObjective}
                      deleteObjective={this.props.deleteObjective}
                      addObjective={this.props.addObjective}
                      addAnswer={this.props.addAnswer}
                      onSortEndAnswerList={this.props.onSortEndAnswerList}
                      handleCanAttachMedia={this.props.handleCanAttachMedia}
                      data_signature={data_signature}
                      communityLang={communityLang}
                      language={language}
                      colors={colors}
                      handleQuestionColorsChange={this.props.handleQuestionColorsChange}
                      communityProjectType={communityProjectType}
                      cmntyTaskTypeLevel={cmntyTaskTypeLevel}
                      cmntyMaxMicroChats={cmntyMaxMicroChats}
                      microChatCount={microChatCount}
                      cmntyAiAutoModeration ={this.props.cmntyAiAutoModeration}
                      cmntyAllowImgs={this.props.cmntyAllowImgs}
                      cmntyAiPlan={this.props.cmntyAiPlan}
                      isSavingTask={this.props.isSavingTask}
        />

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
            <div className={dropUpClass}>
              <button onClick={() => this.props.handleShowAddTask('showAddTaskBottom')}
                      className="btn px-btn lg dropdown-toggle"
                      style={{ color: colors.color1[3], border: `1px solid ${colors.color1[3]}`}}>
                <div className="px-support-tooltip top is_hidden px-support-builder">
                  <p>An activity must have Tasks, the specific questions participants will be responding.</p>
                  <p>Tasks come in many shapes, and can be added here.</p>
                  <i/>
                </div>
                {i18n[language].newTask}
              </button>

              <AE_AddTask addQuestion={this.props.addQuestion}
                          position={task.questions.length}
                          language={language}
                          colors={colors}
                          cmntyTaskTypeLevel={cmntyTaskTypeLevel}
                          cmntyMaxMicroChats={cmntyMaxMicroChats}
                          microChatCount={microChatCount}/>
            </div>
          </div>

        </div>
        {
          showAddTaskTop &&
          <BackdropTransparent show={this.props.showAddTaskTop}
                               clicked={() => this.props.handleShowAddTask('showAddTaskTop')}/>
        }
        {
          showAddTaskBottom &&
          <BackdropTransparent show={this.props.showAddTaskBottom}
                               clicked={() => this.props.handleShowAddTask('showAddTaskBottom')}/>
        }
      </div>
    );
  }
}
