import PropTypes from 'prop-types';
import React from 'react';

export default class TPActivities extends React.Component {
  static propTypes = {
    responses: PropTypes.array.isRequired,
    pEmail: PropTypes.bool.isRequired,
    pAlias: PropTypes.bool.isRequired,
    pAnn: PropTypes.bool.isRequired,
    comLanguage: PropTypes.string.isRequired,
    users_anonymous: PropTypes.array.isRequired,
    export_to: PropTypes.string.isRequired,
    fetchUsernames: PropTypes.func.isRequired,
    aiWithImages: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    // JOIN TPActivities and TPParticipants
  }

  render() {
    const {responses, pEmail, pAnn, pAlias, comLanguage,
      users_anonymous, export_to, aiWithImages} = this.props

    const activities = responses.map((activity, index) => {
      const tasks = activity.tasks.map((task, index) => {
        const participants = task.participants.map((participant, index) => {
          let video = null
          let images = null

          if(participant.responses[0] !== undefined){
            const response = participant.responses[0];

            if(task.type === 'Open End' || task.type === 'Multimedia'){
              const imagesFlag = task.media_options.find(e => e === 'Images');
              const videoFlag = task.media_options.find(e => e === 'Video');

              if(task.media && imagesFlag !== undefined){
                images = <div className='row attachments'>
                  <div className='col-xs-12 flex-v-center'>
                    <ul className='list-inline'>
                      { response.attachments && response.attachments.length !== 0 ?
                        response.attachments.map((attachment, index) => {
                          return(
                            <li key={index}>
                              {aiWithImages ?
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: '15px'}}>
                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={attachment.url_thumbnail || attachment.url} className='px-thumbnail'
                                         alt=""/>
                                  </div>
                                  <div style={{display: 'flex', alignItems: 'center', marginLeft: '15px'}}>
                                    {attachment.transcript ? attachment.transcript : 'No image description available'}
                                  </div>
                                </div> :
                                <img src={attachment.url_thumbnail || attachment.url} className='px-thumbnail' alt=""
                                     style={{marginBottom: '15px'}}/>
                              }
                            </li>
                          )
                        }) :
                        <div className="px-empty-msg" style={{color: 'rgb(235, 120, 16)'}}>Images not available</div>
                      }
                    </ul>
                  </div>
                </div>;
              }

              if(task.media && videoFlag !== undefined){
                video = response.response.video_thumbnail ?
                  <div className='row attachments'>
                    <div className='col-xs-12 flex-v-center'>
                      <ul className='list-inline'>
                        <li>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            marginBottom: '15px'
                          }}>
                            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                              <div>
                                <img src={'https:' + response.response.video_thumbnail} className='px-thumbnail' alt=''
                                     style={{marginBottom: '15px'}}/>
                              </div>
                              <div>
                                <a href={`https:${response.response.video_url}`} className='font-sm' target="_blank">
                                  click to watch</a>
                              </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', marginLeft: '15px'}}>
                              {response.response.video_transcript ? response.response.video_transcript : 'No video transcript available'}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div> :
                  <div className="px-empty-msg" style={{color: 'rgb(235, 120, 16)', textAlign: 'left'}}>Video not
                    available</div>;
              }
            } else if (task.type === 'Canvas') {
              images =
                <div className='row attachments'>
                  <div className='col-xs-12 flex-v-center'>
                    <ul className='list-inline'>
                      {
                        response.attachments && response.attachments.length !== 0 ?
                          <li>
                            {aiWithImages ?
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: '15px'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                      <img src={response.attachments.slice(-1)[0].url_thumbnail || response.attachments.slice(-1)[0].url}
                                           className='px-thumbnail'
                                           alt=""/>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '15px'}}>
                                      {response.attachments.slice(-1)[0].transcript ? response.attachments.slice(-1)[0].transcript : 'No image description available' }
                                    </div>
                                  </div> :
                                  <img src={response.attachments.slice(-1)[0].url_thumbnail || response.attachments.slice(-1)[0].url}
                                       className='px-thumbnail' alt=""
                                       style={{marginBottom: '15px'}}
                                  />
                                }
                              </li> :
                              <li>
                                <div className="px-empty-msg" style={{color: 'rgb(235, 120, 16)', textAlign: 'left'}}>Image not available</div>
                              </li>
                        }
                      </ul>
                    </div>
                  </div>
            }
          }

          const moods = <div className='row maps no-page-break'>
            <div className='col-xs-12 flex-v-center'>
              <ul className='list-inline'>
                <li className='strong label'>{participant.responses[0] && participant.responses[0].moods.length > 0 ?
                  participant.responses[0].mapTitle[comLanguage] + ': ' : ''}</li>
                {
                  participant.responses[0] ? participant.responses[0].moods.map((mood, index) => {
                    return (
                      <li key={index} className='map-item px-badge highlight'>
                        <label>{mood[comLanguage]}</label>
                      </li>
                    )
                  }) : ''
                }
              </ul>
            </div>
          </div>;

          const comments = participant.responses[0] ? participant.responses[0].comments.map((comment, index) => {
            const fetchedUsernamesForComment = this.props.fetchUsernames(pAlias, comment)
            let commentMainName = fetchedUsernamesForComment.mainName;

            if(pAnn){
              const userAnn = users_anonymous.find(u => u.user_id === comment.comment.user_id);
              commentMainName = userAnn?.anonymous || commentMainName;
            }

            const replies = comment.replies.map((reply, index) => {
              const fetchedUsernamesForReply = this.props.fetchUsernames(pAlias, reply)
              let replyMainName = fetchedUsernamesForReply.mainName;

              if(pAnn){
                const userAnn = users_anonymous.find(u => u.user_id === reply.reply.user_id);
                replyMainName = userAnn?.anonymous || replyMainName;
              }

              return(
                <div key={index} className="comment-wrapper reply container no-page-break">
                  <div className="row comment-details">
                    <div className="col-xs-9 col">
                      <span className='username'>
                        {`${replyMainName} ${reply?.reply?.kind?.from === 'AI' ? '(AI)' : '' }`}
                      </span>
                      <span className='email'>
                        { pEmail ? reply.email : '' }
                        {/*{` (${reply.user_id})`}*/}
                      </span>
                    </div>
                    <div className="col-xs-3 col">
                      <span className='timestamp'>{reply.updated_at}</span>
                    </div>
                  </div>
                  <div className="row comment-body">
                    <p className='comment'>{reply.reply.body}</p>
                  </div>
                </div>
              )
            });

            return(
              <div key={index}>
                <div className="comments">
                  <div className="comment-wrapper container no-page-break">
                    <div className="row comment-details">
                      <div className="col-xs-9 col">
                        <span className='username'>
                          {`${commentMainName} ${comment?.comment?.kind?.from === 'AI' ? '(AI)' : '' }`}
                        </span>
                        <span className='email'>
                          { pEmail ? comment.email : ''}
                          {/*{`(${comment.user_id})`}*/}
                        </span>
                      </div>
                      <div className="col-xs-3 col">
                        <span className='timestamp'>{comment.updated_at}</span>
                      </div>
                    </div>
                    <div className="row comment-body">
                      <p className='comment'>{comment.comment.body}</p>
                    </div>
                  </div>
                  {replies}
                </div>
              </div>
            )
          }) : '';

          const verbatim = participant.responses[0] ?
            participant.responses[0].verbatims.map((verbatim, index) => {
            return(
              <div key={index} className="row verbatim-wrapper no-page-break flex-v-center">
                <div className="col-xs-11">
                  <span className='verbatim'>{verbatim.verbatim}</span>
                </div>
                <div className="col-xs-1 left">
                  <span className='verbatim-icon-wrapper flex-v-center'>
                    <i className="fas fa-quote-right"/>
                    {/*<FontAwesomeIcon icon={['fas', 'quote-right']} />*/}
                  </span>
                </div>
              </div>
            )
          }) : '';
          //console.log('verbatim LALO:', verbatim);

          let answer = 'Response not available';
          let updateAt = '';
          let responseState = '';

          //console.log('participant.responses[0]:', participant.responses[0]);
          if(participant.responses[0]){
            const responseObj = participant.responses[0]
            if(responseObj.response.state !== 'Empty'){
              updateAt = responseObj.updated_at
              switch(task.type){
                case 'Open End':
                  answer = responseObj.summary ? responseObj.summary : responseObj.response.data.answer
                  break
                case 'MicroChat':
                  if(export_to === 'ai') {
                    answer = responseObj.summary ? responseObj.summary : responseObj.response.data.answer
                  } else {
                    answer = responseObj.response.ai_summary ? responseObj.response.ai_summary.text : ''
                  }
                  break
                case 'Choice':
                  if(export_to === 'ai'){
                    answer = responseObj.summary ? responseObj.summary : responseObj.response.data.answer
                  } else {
                    const choices = []
                    if(responseObj.response.data){
                      if(responseObj.response.data.answersId.length !== 0){
                        //console.log('participant.responses[0].response.data.answersId:', responseObj.response.data.answersId);
                        //console.log('task.data:', task.data);
                        const arrResponseDataAnswersId = Object.values(responseObj.response.data.answersId)
                        //console.log('arrResponseDataAnswersId:', arrResponseDataAnswersId);
                        task.data.answers.forEach(ta => {
                          //console.log('ta:', ta);
                          arrResponseDataAnswersId.forEach(ra => {
                            if(ta.id === ra.id){
                              choices.push(ta);
                            }
                          })
                        });

                        if(task.data.focusOn === 'Text'){
                          const items = choices.map((ta, index) => {
                            return (
                              <li key={index}><span>{ta.text}</span></li>
                            )
                          });

                          answer = <ul>{ items }</ul>
                        } else {
                          const items = choices.map((ta, index) => {
                            return (
                              <tr key={index} style={{width:'100%'}}>
                                <td style={{paddingBottom:'15px'}}>
                                  <img src={ta.img !== undefined ? ta.img :
                                    'https://dev-pixiebob.s3-ap-southeast-2.amazonaws.com/pdf-default/placeholder.png'}
                                       className='px-thumbnail' alt='' style={{height: '50px'}}/>
                                  <span className='font-sm' style={{marginLeft:'10px'}}>{ta.text}</span>
                                </td>
                              </tr>
                            )
                          });

                          answer = <div className='row attachments'>
                            <div className='col-xs-12 flex-v-center'>
                              <table>
                                <tbody>
                                { items }
                                </tbody>
                              </table>
                            </div>
                          </div>;
                        }

                      } else {
                        //answer = 'Response with no choice(s) selected'
                      }
                    } else {
                      answer = 'Response with no choice(s) selected'
                    }
                  }
                  break;
                default:
                  answer = ''
              }
            }

            responseState = participant.responses[0].response.state;
          } else {
            responseState = 'Blank';
          }

          const fetchedUsernames = this.props.fetchUsernames(pAlias, participant)
          let partUsername = fetchedUsernames.mainName;

          if(pAnn){
            const userAnn = users_anonymous.find(u => u.user_id === participant.user_id);
            partUsername = userAnn?.anonymous || partUsername
          }

          return (
            <div key={index}>
              <div className="row level-3 participant">
                {
                  export_to !== 'ai' &&
                  <div className="row title-bar secondary-subtle no-page-break">
                    <div className='col-xs-4 avatar-n-username flex-v-center left'>
                      <img src="https://s3-ap-southeast-2.amazonaws.com/pixiebob-website/apple-touch-icon.png" alt="avatar" />
                      <div>{partUsername}</div>
                    </div>
                    <div className='col-xs-4 participant-details center'>
                      <div>{pEmail ? participant.email : ''}</div>
                      {/*<div>id: {participant.user_id}</div>*/}
                    </div>
                    <div className='col-xs-4 response-details right'>
                      <div className='status'>
                      <span className={ responseState.toLowerCase() }>
                        <i className="fas fa-circle"/>

                    </span>{ responseState }
                      </div>
                      {
                        updateAt && <div className='date'><span><i className="fas fa-edit"/></span>{updateAt}</div>
                      }
                    </div>
                  </div>
                }
                <div className='main-answer container'>
                  <div className='verbatims container'>
                    { verbatim }
                  </div>
                  <div className='answer'>
                    { answer }
                  </div>
                  { images }
                  { video }
                  { moods }
                  { comments && comments.length > 0 ?
                    <div className="separator-w-icon">
                      {/* <span><FontAwesomeIcon icon={['fal', 'comments']} /></span> */}
                      <span className='strong'>COMMENTS</span>
                    </div>
                    : ''}
                  { comments }
                </div>
              </div>
            </div>
          )
        });

        return(
          <div>
            <div key={index} className="row level-2">
              <div className="col-xs-12 title-bar secondary no-page-break">
                {`T.${index + 1} ${task.title.replace(/(<([^>]+)>)/ig,"")}`}
              </div>
              <div className='col-xs-12 highlight'>
              </div>
            </div>
            { participants }
          </div>
        )
      });

      return(
        <div key={index} className='container export-content'>
          <div className="row level-1">
            <div className="col-xs-12 title-bar primary no-page-break ">{`${index + 1}. ${activity.title}`}</div>
            <div className='col-xs-12 level-1-text highlight'
                 dangerouslySetInnerHTML={{__html: activity.instructions}}/>
          </div>
          { tasks }
        </div>
      )
    });

    return (
      <div>
        { activities }
      </div>
    );
  }
}
