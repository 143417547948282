import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class ASActivityInfo extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    instructions: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isMax: false
    };
  }

  handleIsMax = () => {
    this.setState(prevState => ({isMax: !prevState.isMax}));
  };

  render() {
    const {title, instructions} = this.props;
    const bodyClass = this.state.isMax ? 'body-text minimized' : 'body-text';

    return (
      <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
        <div className="px-card px-card-actv-instructions">
          <div className="panel-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="title-container">
                  <span>{title}</span>
                  {/*<FontAwesomeIcon icon={['fas','share-alt']} />*/}
                </div>
              </div>
            </div>
            {
              instructions !== '' &&
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <p dangerouslySetInnerHTML={{__html: instructions}} className={bodyClass}/>
                </div>
              </div>
            }
          </div>
          {
            instructions !== '' ?
              <div onClick={this.handleIsMax} className="circle-bottom" style={{cursor:'pointer'}}>
                <FontAwesomeIcon icon={['far','up-down']} />
              </div> : null
          }
        </div>
      </div>
    );
  }
}
