import PropTypes from 'prop-types';
import React from 'react';
import ColorPicker from 'rc-color-picker';
import _ from 'underscore';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class Maps extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    maps: PropTypes.array.isRequired,
    alert: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      maps: props.maps,
      mapSelected: {title:{en:'', es:''}, items:[]},
      title: '',
      items: [],
      language: 'es'
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps !== this.props){
      this.setState({
        maps: nextProps.maps,
        mapSelected: nextProps.maps.length !== 0 ? nextProps.maps[0] : {title:{en:'', es:''}, items:[]},
        title: nextProps.maps.length !== 0 ? nextProps.maps[0].title['en'] : '',
        items: nextProps.maps.length !== 0 ? nextProps.maps[0].items : []
      })
    }
  };

  handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value});
  };

  render() {
    const maps = this.state.maps.map((map, index) => {
      const style = map.id === this.state.mapSelected.id ? 'label label-primary' : 'label label-default';

      return(
        <div key={index}>
          <span onClick={() => this._setMap(map)} className={style}
                style={{padding:'5px 20px',cursor:'pointer', marginRight:'15px'}}>{map.title['en']}</span>
        </div>
      )
    });

    const items = this.state.items.map((item, index) => {
      if(item.state === 'Enabled'){
        return(
          <Items key={index}
                 item={item}
                 updateItem={this.updateItem}
                 language={this.state.language}/>
        )
      }
    });

    const alert = this.props.alert ? 'Alert, some participants have already responded ' : '';

    return (
      <div id="divMaps" style={{display: 'block'}} className="community">
        <div className="row">
          <div className="col-md-12" style={{textAlign:'center'}}>
            <h5 style={{fontFamily:'Sharp Sans No2',marginBottom:'30px'}}>Maps</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12" style={{textAlign:'center'}}>
            <div style={{display:'inline-flex',marginBottom:'15px'}}>
              { maps }
            </div>
          </div>
          <div className="col-md-12" style={{textAlign:'center',marginBottom:'15px'}}>
            <span style={{fontSize:'12px'}}>New map...</span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12" style={{textAlign:'center'}}>
            <span>{alert}</span>
          </div>
          <br/><br/><br/>

          <div className="col-md-12" style={{textAlign:'center'}}>
            <div className="col-md-3 col-md-offset-3" style={{paddingLeft:'0', paddingRight:'0'}}>
              <span>Original Language (English)</span>
            </div>
            <div className="col-md-3">
              <div className="form-group" style={{marginTop: '-10px'}}>
                <select name="language" onChange={this.handleChange} value={this.state.language}
                        className="form-control">
                  <option value="es">Spanish</option>
                  <option value="fr">French</option>
                </select>
                <span className="material-input"/>
              </div>
            </div>
          </div>

          <div className="col-md-12" style={{textAlign:'center'}}>
            <div className="col-md-4 col-md-offset-4">
              <div className="form-group" style={{marginBottom:'20px',margin:'0'}}>
                <label className="control-label">Map title</label>
                <input name="title" type="text" onChange={this.handleChange} value={this.state.title}
                       className="form-control"/>
              </div>
            </div>
          </div>
          <div className="col-md-12" style={{textAlign:'center'}}>
            <div className="col-md-6 col-md-offset-3" style={{paddingLeft:'0', paddingRight:'0'}}>
              { items }
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12" style={{textAlign:'center',marginTop:'30px'}}>
            <button onClick={this.addItem} className="btn btn-success btn-fab btn-fab btn-round"
                    style={{height:'40px',width:'40px',minWidth:'40px', backgroundColor:'#aafb9b', color:'#524fff'}}>
              <i className="material-icons">add</i><div className="ripple-container"/>
            </button>
            <br/><br/>
            <a onClick={this.saveMap} className="btn btn-success">Save<div className="ripple-container"/></a>
            <br/><br/>
            <span onClick={this.restoreMap} style={{color:'#37577b',cursor:'pointer'}}>Restore to default wordbook</span>
            <br/><br/>
          </div>
        </div>

      </div>
    )
  }

  restoreMap = () => {
    $.ajax({
      url: '/maps/restore/' + this.state.mapSelected.id,
      method: "GET",
      success: function(map) {
        //console.log(map);
        _.extend(_.findWhere(this.state.maps, {id: map.id}), map);
        this.setState({maps: this.state.maps});
        this.setState({items: map.items});
        $('#snackbar').addClass('show');
        setTimeout(function(){ $('#snackbar').removeClass('show'); }, 3000);
      }.bind(this)
    });
  };

  addItem = () => {
    let items = this.state.items;
    const newItem = {
      id: Math.random().toString(36),
      en: '',
      es: '',
      color: '',
      isActive: false,
      state: 'Enabled'
    };
    items.push(newItem);
    this.setState({items});
  };

  saveMap = () => {
    let items_a = [];
    this.state.items.forEach(function (item) {
      //console.log(item);
      items_a.push(item);
    });
    //console.log(items_a.length);

    $.ajax({
      url: '/maps/' + this.state.mapSelected.id,
      method: "PATCH",
      dataType: "JSON",
      data: {
        items:items_a,
        title: this.state.title
      },
      success: function() {
        $('#snackbar').addClass('show');
        setTimeout(function(){ $('#snackbar').removeClass('show'); }, 3000);
      }.bind(this)
    });
  };

  updateItem = (updatedItem, action) => {
    //console.log(updatedItem);
    _.extend(_.findWhere(this.state.items, {id: updatedItem.id}), updatedItem);
    if(action === 'delete'){
      this.setState({items: this.state.items});
    }

  };

  _setMap(map){
    this.setState({
      mapSelected: map,
      title: map.title['en'],
      items: map.items
    })
  }
}

/////////////////////////////////////////////////////////////////////////
class Items extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    updateItem: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      name: props.item['en'],
      nameI18n: props.item[props.language],
      color: props.item['color']
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps !== this.props) {
      this.setState({
        name: nextProps.item['en'],
        nameI18n: nextProps.item[nextProps.language],
        color: nextProps.item['color']
      })
    }
  };

  handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value}, function () {
      const newItem = this._buildItem();

      this.props.updateItem(newItem, 'update');
    });
  };

  changeHandler = (colors) => {
    this.setState({color: colors.color}, function () {
      const newItem = this._buildItem();

      this.props.updateItem(newItem, 'update');
    })
  };

  render() {
    return(
      <div className="row">
        <div className="col-md-6">
          <div className="form-group" style={{paddingBottom:'0',margin:'0'}}>
            <input type="text" name="name" onChange={this.handleChange} value={this.state.name} className="form-control"/>
            <span style={{position:'absolute',top:'3px',right:'50px'}}>
              <ColorPicker animation="slide-up" color={this.state.color} onChange={this.changeHandler}>
                <FontAwesomeIcon icon={['fas', 'triangle']}/>
              </ColorPicker>
            </span>
            <span onClick={this.deleteItem} style={{position:'absolute',right:'0',top:'0',cursor:'pointer',color:'#b8b8b8'}}>
              <i className="material-icons">clear</i>
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group" style={{paddingBottom:'0',margin:'0'}}>
            <input type="text" name="nameI18n" onChange={this.handleChange} value={this.state.nameI18n}
                   className="form-control"/>
            <span style={{position:'absolute',top:'3px',right:'50px', border:'1px solid #999',padding:'2px',
              borderRadius:'2px', width:'15px',height:'15px', backgroundColor: this.state.color}}/>
          </div>
        </div>
      </div>

    )
  }

  deleteItem = () => {
    const newItem = {
      en: this.props.item.en, es: this.props.item.es, color: this.props.item.color, isActive: this.props.item.isActive,
      state: 'Disabled', id: this.props.item.id
    };
    this.props.updateItem(newItem, 'delete');
  };

  _buildItem = () => {
    return {
      en: this.state.name,
      es: this.state.nameI18n,
      color: this.state.color,
      isActive: this.props.item.isActive,
      state: this.props.item.state,
      id: this.props.item.id
    }
  }
}