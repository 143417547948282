import PropTypes from 'prop-types';
import React from 'react';
import axios from "axios";

import Account from "../Manage/Account";
import Profile from "../Manage/Profile";
import Notifications from "../Manage/Notifications";
import Layout from "../Layout";
import Appearance from "../Manage/Appearance";
import MNavBar from "../Manage/MNavBar";
import {showSuccessMsg} from "../UI/Globals/PX_Funs";
import Allowances from "../Manage/Allowances";
import HelpScoutBeacon from "../../components/HelpScoutBeacon.jsx";
import Billing from "../Manage/Billing";

export default class ManagePage extends React.Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    user_account: PropTypes.object.isRequired,
    communities: PropTypes.array.isRequired,
    notifications: PropTypes.array.isRequired,
    sessionLang: PropTypes.string,
    colorSetsSample: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      currentMenu: '#Account',
      username: this.props.user_account.username,
      flagUsername: false,
      email: this.props.user_account.email,
      flagEmail: false,
      language: props.profile.language ? props.profile.language : props.sessionLang,
      isModalAvatar: false,
      firstName: props.profile.first_name,
      lastName: props.profile.last_name,
      avatar: props.profile.avatar === '' ? '/assets/user.png' : props.profile.avatar,
      country: props.profile.country ? props.profile.country : '',
      region: props.profile.state_country ? props.profile.state_country : '',
      gender: props.profile.gender,
      dob: this._formatDOB(props.profile.dob, 'ddmmyyyy'),
      postcode: props.profile.postcode ? props.profile.postcode : '',
      //company: props.profile.company ? props.profile.company : '',
      openPMFromOutside: {username: '', userId: null, flag: false},
      twitter: props.profile.twitter,
      facebook: props.profile.facebook,
      instagram: props.profile.instagram,
      snapchat: props.profile.snapchat,
      linkedin: props.profile.linkedin,
      company: props.user_account.company,
      isBeaconInit: false, //Keeps track of any instances of Head Scout Beacons initialised.
      activeColorSetId: this.props.activeColorSetId,
      colors: props.user_account.all_colors,
      invoices: [],
      isLoadingInvoices: false,
      subscription: {}
    };
  }

  render() {
    const {communities, profile, user_account} = this.props;

    const hsBeaconId = profile.role === 'Superadmin' || profile.role === 'Admin' ?
      '6d80dd83-f9d7-4f25-932e-8b3b504e4a2a' : 'f3c5329c-3768-46a1-89af-f0925eb8e388';
    const hsBeaconSuggestionsAdmins = {
      en: ["5f22693f04286306f8079d05", "5f2269a82c7d3a10cbab8c49", "5f226ab804286306f8079d0a", "5f23e0472c7d3a10cbaba2e5",
        "5f1ebff72c7d3a10cbab564f", "5f1ec01f04286306f8076c3a", "5f1ec05104286306f8076c3f", "5f1eba9c04286306f8076bfe",
        "5f1eb71e04286306f8076bbe", "5f1eb9fd04286306f8076bf8"]
    };
    const hsBeaconSuggestionsOthers = {
      en: ["5f1ec5a52c7d3a10cbab56c3", "5f2024542c7d3a10cbab6a7c", "5f1eb5b32c7d3a10cbab55bf", "5f1eb4cc04286306f8076baa",
        "5f1eb7cf04286306f8076bce", "5f1eb71e04286306f8076bbe", "5f1eba9c04286306f8076bfe", "5f1eb42704286306f8076b9e"],
      es: ["5f1fab532c7d3a10cbab6569", "5f20257704286306f8077eaa", "5f1fae5604286306f8077a22", "5f1faf5904286306f8077a33",
        "5f1fac852c7d3a10cbab656d", "5f1facef2c7d3a10cbab6571", "5f1ebba904286306f8076c0d", "5f1fac1c2c7d3a10cbab656b"]
    };
    const hsBeaconSuggestions = profile.role === 'Superadmin' || profile.role === 'Admin' ?
      hsBeaconSuggestionsAdmins : hsBeaconSuggestionsOthers;

    return (
      <Layout user_account={user_account}
              profile={this.props.profile}
              sessionLang={this.props.sessionLang}
              notifications={this.props.notifications}
              from='ManagePage'
              community={{id: -1}}
              communities={communities}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              handleLink={this.handleLink}
              currentMenu={this.state.currentMenu}
              notificationsCounter={0}
              colors={this.state.colors}>

        <div className="container-fluid">
            {/*<HelpScoutBeacon beaconId={hsBeaconId}*/}
            {/*                 user_account={user_account}*/}
            {/*                 colors={this.state.colors}*/}
            {/*                 language={this.state.language}*/}
            {/*                 isBeaconInit={this.state.isBeaconInit}*/}
            {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
            {/*                 suggestions={hsBeaconSuggestions}*/}
            {/*                 hideFABOnDesktop={!this.props.user_account.profile.is_help}/>*/}
          <div className="row">
            <div className="col-md-12 no-pd text-center">
              <div className="px-slick-navigation-container">
                <MNavBar handleLink={this.handleLink}
                         role={profile.role}
                         language={this.state.language}
                         getInvoice={this.getInvoice}
                         colors={this.state.colors}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div id="divAccount" className="manage">
                <Account username={this.state.username}
                         email={this.state.email}
                         handleChange={this.handleChange}
                         saveAccount={this.saveAccount}
                         language={this.state.language}
                         colors={this.state.colors}/>
              </div>
              <div id="divProfile" className="manage" style={{display:'none'}}>
                <Profile profile={profile}
                         user_account={user_account}
                         isModalAvatar={this.state.isModalAvatar}
                         isModalAvatarToggle={this.isModalAvatarToggle}
                         avatar={this.state.avatar}
                         updateAvatar={this.updateAvatar}
                         firstName={this.state.firstName}
                         lastName={this.state.lastName}
                         country={this.state.country}
                         handleCountry={this.handleCountry}
                         region={this.state.region}
                         handleRegion={this.handleRegion}
                         gender={this.state.gender}
                         dob={this.state.dob}
                         postcode={this.state.postcode}
                         company={this.state.company}
                         twitter={this.state.twitter}
                         facebook={this.state.facebook}
                         instagram={this.state.instagram}
                         snapchat={this.state.snapchat}
                         linkedin={this.state.linkedin}
                         handleChange={this.handleChange}
                         language={this.state.language}
                         saveProfile={this.updateInfo}
                         updateIsModalAvatar={this.updateIsModalAvatar}
                         colors={this.state.colors}/>
              </div>
              <div id="divNotifications" className="manage" style={{display:'none'}}>
                <Notifications profile={this.props.profile}
                               user_account={this.props.user_account}
                               colors={this.state.colors}/>
              </div>
              <div id="divAppearance" className="manage" style={{display:'none'}}>
                <Appearance company={this.state.company}
                            language={this.state.language}
                            deleteLogo={this.deleteLogo}
                            user_account={user_account}
                            colors={this.state.colors}
                            colorSetsSample={this.props.colorSetsSample}
                            activeColorSetId={this.state.activeColorSetId}
                            handleSelectColor={this.handleSelectColor}/>
              </div>
              <div id="divBilling" className="manage" style={{display:'none'}}>
                {
                  profile.role === 'Superadmin' &&
                  <Billing language={this.state.language}
                           company={this.state.company}
                           saveBilling={this.saveBilling}
                           invoices={this.state.invoices}
                           isLoadingInvoices={this.state.isLoadingInvoices}
                           subscription={user_account.subscription}
                           colors={this.state.colors}/>
                }
              </div>
              <div id="divAllowances" className="manage" style={{display:'none'}}>
                <Allowances profile={profile}
                            language={this.state.language}
                            communities={communities}
                            maxPapt={user_account.subscription ? user_account.subscription.papt : -1}
                            maxActiveComms={user_account.subscription ? user_account.subscription.max_num_comm : -1}
                            maxClosedComms={user_account.subscription ? user_account.subscription.closed_comm : -1}
                            maxArchivedComms={user_account.subscription ? user_account.subscription.archived_comm : -1}
                            colors={this.state.colors}/>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  getInvoice = companyId => {
    console.log('companyId:', companyId);
    this.setState({isLoadingInvoices: true}, () => {
      axios.get('/companies/get_invoice/' + companyId, {})
        .then(r => {
          console.log(r.data);
          this.setState({invoices: r.data.invoices, isLoadingInvoices: false})
        })
        .catch(error => {
          console.log(error);
        });
    });
  }

  saveBilling = async (company) => {
    console.log('company:', company);
    const config = {headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}};
    const data = {company};
    const response = await axios.patch(`/companies/update/${company.id}`, data, config);
    showSuccessMsg('Success');
    console.log(response.data);
    //this.setState({company})
  };

  handleSelectColor = (selectedColorSetId) => {
    $.ajax({
      url: '/company_colors/color_set/' + selectedColorSetId,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: colors => {
        this.setState({ colors: colors, activeColorSetId: Number(selectedColorSetId) })
      }
    });
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  deleteLogo = () => {
    //console.log(this.state.company);
    //if(this.state.company.logo !== ''){
      $.ajax({
        url: '/companies/delete_logo/' + this.state.company.id,
        method: 'PATCH',
        dataType: 'JSON',
        data: {},
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: (company) => {
          console.log(company);
          this.setState({company});
          const div = document.getElementById("logo-navbar");
          if(div === null){
            $('#navMain').attr('class', 'navbar navbar-default px-nav-base false');
            $('#divImgSubdomain').remove();
            $('#divTMRightMenu').append('' +
              '<img id="logo-navbar" src="/assets/nabvar_brand.png" class="brand-img subdomain" alt="" style="visibility: inherit;">'
            );
          }

          //showSuccessMsg('Success!');
        }
      });
    //}
  }

  updateOpenPMFromOutside = (username, userId) => {
    console.log(username, userId);
    this.setState(state => ({openPMFromOutside: {username: username, userId: userId, flag: !state.openPMFromOutside.flag}}));
  };

  handleLink = (link, div) => {
    //console.log('link:', link, 'div:', div);
    this.setState({currentMenu: link}, () => {
      $('.manage').css('display', 'none');
      $(div).css('display', 'block');

      $('.item').removeClass('selected');
      $(this.state.currentMenu).addClass('selected');

      if(link === '#Billing'){
        this.getInvoice(this.state.company.id);
      }
    });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});
  };

  handleCountry = (country) => {
    this.setState({country});
  };

  handleRegion = (region) => {
    this.setState({region});
  };

  updateInfo = () => {
    $.ajax({
      url: '/profiles/update/' + this.props.profile.id,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        profile: {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          gender: this.state.gender,
          dob: this._formatDOB(this.state.dob, 'yyyymmdd'),
          //company: this.state.company,
          country: this.state.country,
          state_country: this.state.region,
          postcode: this.state.postcode,
          language: this.state.language,
          twitter: this.state.twitter,
          facebook: this.state.facebook,
          instagram: this.state.instagram,
          snapchat: this.state.snapchat,
          linkedin: this.state.linkedin,
        }
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
        showSuccessMsg('Success!');
      }
    });
  };

  updateAvatar = (blob, type) => {
    const reader  = new FileReader();

    reader.onloadend = function () {
      $('#imgProfileAvatar').attr('src', reader.result);
      $('#imgPreviewMyProfile').attr('src', reader.result);
    };

    reader.readAsDataURL(blob);
  };

  updateIsModalAvatar = () => {
    this.setState(prevState => ({isModalAvatar: !prevState.isModalAvatar}));
  };

  // Is it useful?
  isModalAvatarToggle = () => {
    this.setState({isModalAvatar: !this.state.isModalAvatar});
  };

  // Is it useful?
  saveAccount = () => {
    this.changeUsername();
    this.changeEmail();
  };

  // Is it useful?
  changeUsername = () => {
    if(this.state.username !== ''){
      this._validation('/users/username_validation', 'username', this.state.username, 'flagUsername',
        'saveUsername');
    } else {
      this.setState({flagUsername: true});
    }
  };

  // Is it useful?
  changeEmail = () => {
    if(this.state.email !== '' && this._validateEmail(this.state.email)) {
      this._validation('/users/email_validation', 'email', this.state.email, 'flagEmail', 'saveEmail');
    } else {
      this.setState({flagEmail: true});
    }
  };

  // Is it useful?
  _validation(url, name, value, flagName, action){
    $.ajax({
      url: url,
      method: 'GET',
      dataType: 'JSON',
      data: {[name]: value },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: obj => {
        if(!obj.msg) {
          this.setState({[flagName]: false});
          switch (action) {
            case 'saveUsername':
              $.ajax({
                url: '/users/update_username',
                method: 'PATCH',
                dataType: 'JSON',
                data: {username: this.state.username},
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                success: obj => {
                  console.log(obj);
                  $('#spnUsername').text(obj.username);
                  $('#spnLeftUsername').text(obj.username);

                  showSuccessMsg('Success!');
                }
              });
              break;
            case 'saveEmail':
              $.ajax({
                url: '/users/update_email',
                method: 'PATCH',
                dataType: 'JSON',
                data: {email: this.state.email},
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                success: function () {
                  showSuccessMsg('Success!');
                }
              });
              break;
          }
        } else {
          this.setState({[flagName]: true});
        }
      }
    });
  }

  _formatDOB(dob, format){
    let formatted = '';
    if(dob !== '' && dob){

      if(format === 'ddmmyyyy'){
        const dobA = dob.split('-');
        formatted = dobA[2] + '-' + dobA[1] + '-' + dobA[0];
      } else {
        const dobA = dob.split('/');
        formatted = dobA[0] + '-' + dobA[1] + '-' + dobA[2];
      }

    }
    return formatted;
  }

  _validateEmail(value) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  }
}
