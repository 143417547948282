import PropTypes from 'prop-types';
import React from 'react';
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {getCountryName, getIsTaskAlert, getResponseStateLang, sendRandomCommentMatrix, setIconGender,
  showSuccessMsg} from "../../../UI/Globals/PX_Funs";
import DPCStats from "./DPCStats";
import {usernamesToDisplay} from "../../shared_utils";

export default class DPCard extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    activities: PropTypes.array.isRequired,
    communityId: PropTypes.number.isRequired,
    role_2: PropTypes.string.isRequired,
    clearForwardUrl: PropTypes.func.isRequired,
    updateActiveUsers: PropTypes.func.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    updateResponseState: PropTypes.func.isRequired,
  }

  render() {
    const {user, language, colors, activities, communityId, role_2, gender_as_segment} = this.props;

    const i18n = {
      en: {
        pctOfCompleted: '% of tasks Accepted or Completed', likesIn:'Likes received', likesOut: 'Likes given',
        commentsIn: 'Comments received', commentsOut: 'Posted comments', expand: 'Expand panel for details',
        collapse: 'Collapse panel to hide details'
      },
      es: {
        pctOfCompleted: '% de tareas Aceptadas o Completadas', likesIn: 'Likes recibidos', likesOut: 'Likes dados',
        commentsIn: 'Comentarios recibidos', commentsOut: 'Comentarios publicados', expand: 'Expanda el panel para más detalles',
        collapse: 'Contraer panel para ocultar detalles'
      }
    };

    let total = 0;
    let completed = 0;
    let accepted = 0;

    const activitiesUser = activities.map(activity => {
      let segsFlag = true;
      if(activity.segments.length !== 0){
        segsFlag = user.segments.find(us => activity.segments.find(as => as.id === us.id)) !== undefined;
        //console.log('1 segsFlag:', segsFlag, 'activity:', activity.title)
        if(gender_as_segment && !segsFlag){
          //TO-DO In back server, the value of community segment 'Other Gender' must be just 'Other' as DB
          const activitySegs = activity.segments.map(s => s.id === 'Other Gender' ? {...s, id: 'Other'} : s);
          segsFlag = activitySegs.find(as => as.id === user.gender) !== undefined;
          //console.log('2 segsFlag:', segsFlag)
        }
      }
      //console.log('activity.isVisPar:', activity.isVisPar && 'segsFlag:', segsFlag);
      if(activity.isVisPar && segsFlag) {
        const tasks = activity.tasks.map((task, index) => {
          //console.log(task);
          total += 1;
          const response = task.responses.find(response => response.user_id === user.id);
          //console.log(response);
          let dropDown = null;

          if(response !== undefined){
            const isAlert = getIsTaskAlert(activity, response);
            const showMenu = (role_2 === "Owner" || role_2 === "Moderator") &&
              ((response.state !== 'Blank' && response.state !== 'Empty') || isAlert);
            //console.log(showMenu);
            //console.log(response.state, isAlert, showMenu, stateColor);
            //console.log(isAlert);
            if(response.id){
              let stateColor = null;
              let inlineStateColor = null;

              switch(response.state) {
                case 'Accepted':
                  stateColor = 'accepted';
                  inlineStateColor = colors.colorStates[0]
                  accepted += 1;
                  break;
                case 'Completed':
                  stateColor = 'completed';
                  inlineStateColor = colors.colorStates[1]
                  completed += 1;
                  break;
                case 'Draft':
                  stateColor = 'drafted';
                  inlineStateColor = ''
                  break;
                case 'Empty':
                case 'Blank':
                  stateColor = 'blank';
                  inlineStateColor = ''
                  break;
              }

              dropDown = <div className="dropdown dropdown-status-container pull-right">
                <div className={"current-status dropdown-toggle " + stateColor}
                     style={{backgroundColor: inlineStateColor,
                       cursor: showMenu ? 'pointer' : 'default',
                       border: isAlert ? '2px solid  ' + colors.color4[3] : null}}
                     data-toggle="dropdown"/>
                {
                  showMenu &&
                  <ul className="dropdown-menu no-min-width" style={{padding: '2px 10px'}}>
                    <div className="triangle"/>
                    <li>
                      { this.getStateDropAction(response.id, response.pre_state, response.state,'participant',
                        activity.id, task.id) }
                    </li>
                    {
                      isAlert &&
                      <li>
                        <div onClick={() => this.handleFaster(user, response)} className="px-ct-span"
                             style={{cursor:'pointer', paddingLeft: '1px'}}>
                            <span>
                              <FontAwesomeIcon icon={['fas', 'forward']}
                                               style={{fontSize: '15px', marginRight: '5px', marginTop: '5px' }}/> Faster
                            </span>
                        </div>
                      </li>
                    }
                  </ul>
                }
              </div>;
            } else {
              dropDown = <div className="dropdown dropdown-status-container pull-right">
                <div className={"current-status dropdown-toggle blank"}
                     style={{cursor: showMenu ? 'pointer' : 'default', border: isAlert ? '2px solid ' + colors.color4[3] : null}}
                     data-toggle="dropdown"/>
                {
                  showMenu &&
                  <ul className="dropdown-menu no-min-width" style={{padding: '2px 10px'}}>
                    <div className="triangle"/>
                    {
                      isAlert &&
                      <li>
                        <div onClick={() => this.handleFaster(user, response)} className="px-ct-span"
                             style={{cursor:'pointer', paddingLeft: '1px'}}>
                            <span>
                              <FontAwesomeIcon icon={['fas', 'forward']}
                                               style={{fontSize: '15px', marginRight: '5px', marginTop: '5px' }}/> Faster
                            </span>
                        </div>
                      </li>
                    }
                  </ul>
                }
              </div>;
            }
          }

          const urlTask = '/communities/' + activity.community_id + '/transcript/' + user.id + '/' + activity.id
            + '/' + task.id + '/Participants';

          return(
            <div key={task.id} className="px-task-item">
              <div className="counter">{++index}</div>
              <div className="px-task">
                <div className="question-container">
                  <div className="question">
                    <a onClick={(event) => this.props.clearForwardUrl(event, 'common', urlTask)}
                       style={{cursor:'pointer'}}
                       className="task-instructions">
                      {task.title.replace(/(<([^>]+)>)/ig, "")}
                    </a>
                  </div>
                  { dropDown }
                </div>
              </div>
            </div>
          )
        });

        const urlActivity = '/communities/' + activity.community_id + '/transcript/' + user.id + '/' + activity.id
          + '/all/Participants';

        return(
          <div key={activity.id} className="px-activity-item mg-bottom-15">
            <a onClick={(event) => this.props.clearForwardUrl(event, 'common', urlActivity)}
               style={{cursor:'pointer'}}
               className="px-activity-title mg-bottom-10">{activity.title}</a>
            <div className="px-tasks-container">
              { tasks }
            </div>
          </div>
        )
      }
    });

    const percentage = total !== 0 ? ((completed + accepted) * 100) / total : 0;
    const regionCountry = user.state_country === '' ? getCountryName(user.country) :
      user.state_country + ', ' + getCountryName(user.country);
    const activeActIds = activities.filter(act => act.isVisPar).map(a => a.id);
    const actIds = activities.length === activeActIds.length ? 'all' : activeActIds;
    const urlParticipant = '/communities/' + communityId + '/transcript/' + user.id + '/' + actIds + '/all/Participants';

    const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
    const mainName = availableUsernames[0];
    const alternativeNames = availableUsernames.length > 1 ? availableUsernames.slice(1).join(', ') : ''
    const usernameElement = <span style={{display: 'flex'}}>{mainName}</span>
    const usernameTooltip = alternativeNames ? `a.k.a:  ${alternativeNames}` : ''

    return(
      <div className="row participant-card">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="px-card px-panel-activities panel-participant mg-bottom-15">
            <div className="panel-heading panel-title">
              <div className="px-title-container">
                <div className="px-container-percentage" data-tooltip-content={i18n[language].pctOfCompleted} style={{width:'80px'}}>
                  <CircularProgressbarWithChildren value={percentage} text={percentage !== 100 &&`${percentage.toFixed(0)}  %`}
                                                   background={percentage === 100}
                                                   styles={buildStyles({strokeLinecap: 'butt',
                                                     pathColor: colors.color0[0], trailColor: '#ecedf0', textColor: '#262E37'})}>
                    {
                      percentage === 100 &&
                      <span className={'mg-cust'} style={{color: '#fff', fontSize: '20px'}}>
                        <FontAwesomeIcon icon={['far','check']}/>
                      </span>
                    }
                  </CircularProgressbarWithChildren>
                </div>
                <div className="px-title-right-container">
                  <span className="activity-title participant" style={{cursor: role_2 === "Recruiter" || role_2 === "" ? "default" : "pointer"}}
                        onClick={role_2 === "Recruiter" || role_2 === "" ?
                          ()=> null : (event) => this.props.clearForwardUrl(event, 'common', urlParticipant)}
                        data-tooltip-content={usernameTooltip}>
                     {usernameElement}
                  </span>
                  <div className={user.gender !== '' ? "px-icons-container": "px-icons-container empty"}>
                    <div className="px-genders-container">
                      {
                        user.gender !== '' &&
                        <div className="gender-container" data-tooltip-content={user.gender}>
                          {setIconGender(user.gender)}
                        </div>
                      }
                    </div>
                    <div className={user.country ? 'px-country-container': 'px-country-container empty'}
                         data-tooltip-content={regionCountry}>
                      {
                        user.country &&
                        <span>{user.country}</span>
                      }
                    </div>
                    <div className="px-tags-container">
                      {
                        user.segments.map(segment => {
                          return (
                            <FontAwesomeIcon key={segment.id} icon={['fas', 'bookmark']} className="tag"
                                             style={{color: segment.color}} data-tooltip-content={segment.name}/>
                          )
                        })
                      }
                    </div>
                    <FontAwesomeIcon icon={user.isCollapsedPar ? ['fas','up-right-and-down-left-from-center'] : ['fas','down-left-and-up-right-to-center']}
                                     className={'icon-collapse-icons collapse-panel'}
                                     data-tooltip-content={user.isCollapsedPar ? i18n[language].expand : i18n[language].collapse}
                                     onClick={() => this.props.updateActiveUsers(user.id, 'isCollapsedPar', !user.isCollapsedPar)}
                    />
                  </div>
                  <div className="px-container-deadline">
                    {
                      role_2 !== "Observer" && role_2 !== "" && user.email &&
                        <a className="email" href={'mailto:' + user.email}> {user.email} </a>
                    }
                    { role_2 === "Recruiter" || role_2 === "" ? "" :
                      <FontAwesomeIcon icon={user.isCollapsedPar ? ['fas','up-right-and-down-left-from-center'] : ['fas','down-left-and-up-right-to-center']}
                                       className={'icon-collapse collapse-panel'}
                                       data-tooltip-content={user.isCollapsedPar ? i18n[language].expand : i18n[language].collapse}
                                       onClick={() => this.props.updateActiveUsers(user.id, 'isCollapsedPar', !user.isCollapsedPar)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className={`panel-body ${user.isCollapsedPar && 'collapse'}`}>
              <DPCStats communityId={communityId}
                        userId={user.id}
                        userIsCollapsedPar={user.isCollapsedPar}
                        language={language}/>
              { activitiesUser }
            </div>
          </div>
        </div>
      </div>
    )
  }

  getStateDropAction(responseId, pre_state, state, from, activityId, taskId){
    //console.log(responseId, pre_state, state, from, activityId, taskId)
    let message = '';
    const colors = this.props.colors;

    switch(state){
      case 'Draft':
        message = <div onClick={() => this.props.updateResponseState('Accepted', responseId, from, activityId, taskId)}
                       style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
          <div className="px-drop-donut " style={{ backgroundColor: colors.colorStates[0], height: '15px', width:'15px'}}/>
          <span className="px-state-li">{getResponseStateLang('Accepted', this.props.language)}</span>
        </div>;
        break;
      case 'Completed':
        message = <div onClick={() => this.props.updateResponseState('Accepted', responseId, from, activityId, taskId)}
                       style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
          <div className="px-drop-donut" style={{ backgroundColor: colors.colorStates[0], height: '15px', width:'15px'}}/>
          <span className="px-state-li">{getResponseStateLang('Accepted', this.props.language)}</span>
        </div>;
        break;
      case 'Accepted':
        const colorState = pre_state === 'Draft' ? '#47505c' : colors.colorStates[1];
        message = <div onClick={() => this.props.updateResponseState('Rejected', responseId, from, activityId, taskId)}
                       style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
          <div className="px-drop-donut" style={{backgroundColor: colorState, height: '15px', width:'15px'}}/>
          <span className="px-state-li">{getResponseStateLang(pre_state, this.props.language)}</span>
        </div>;
        break;
    }
    //console.log(message);
    return message;
  };

  handleFaster = (user, response) => {
    // console.log(user, response);
    sendRandomCommentMatrix('forget', user.username, [], user.id, response.task_id,
      response.id ? response.id : -1);
    showSuccessMsg('Success!');
  };
}