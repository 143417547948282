import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CSMPurgeCMTY from "../Community/Settings/CSGeneral/CSMPurgeCMTY";
import { lang } from 'moment';
import { Tooltip as ReactTooltip } from "react-tooltip"

export default class ArchivedCard extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    updateCommunityState: PropTypes.func.isRequired,
    showModalDelete: PropTypes.bool.isRequired,
    updateShowModalDelete: PropTypes.func.isRequired,
    purgeCommunity: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showModalPurge: false
    }
  }

  render() {
    const {community, language, colors, subscription} = this.props;

    const i18n = {
      en: {
        msg1: "This community is", msg2: "archived", unarchive: "Unarchive", purge: "Purge",
        info: 'When a community is archived you cannot access it, but you preserve its data for future reference.'
      },
      es: {
        msg1: "Esta comunidad está", msg2: "archivada", unarchive: "Desarchivar", purge: "Purgar",
        info: 'Cuando una comunidad está archivada no puedes acceder a ésta, pero preservas la información para futura referencia.'
      }
    };

    return (
      <div className="card px-card-community archived mg-bottom-15">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="card-height-indicator"/>
        <div className="card-content">
          <div className="card-body full-body body-status">
            <FontAwesomeIcon icon={['far','box-archive']} className="px-icon archived"/>
            <div className="text-cont">
              <span className="title white">{i18n[language].msg1}<br/> {i18n[language].msg2}</span>
              <span className="community invite">{community.title}</span>
            </div>
            {
              community.role_1 === 'Collaborator' &&
              <div className="two-buttons">
                {
                  subscription.papt !== 0 &&
                  <div onClick={() => this.props.updateCommunityState('Closed', community.id)} className="btn-group accept">
                    <button type="button" className="btn btn-action archive"><FontAwesomeIcon icon={['far','rotate-left']}/></button>
                    <button type="button" className="btn px-btn sm btn-gray-darkest">{i18n[language].unarchive}</button>
                  </div>
                }
                <div onClick={this.updateShowModalPurge} className="btn-group">
                  <button type="button" className="btn btn-action decline">
                    <FontAwesomeIcon icon={['fas','trash']}/>
                  </button>
                  <button type="button" className="btn px-btn sm btn-white inverted">{i18n[language].purge}</button>
                </div>
              </div>
            }
            <FontAwesomeIcon icon={['fal', 'circle-info']} className="info-c white" data-tooltip-content={i18n[language].info}/>
          </div>
        </div>
        {
          this.state.showModalPurge &&
          <CSMPurgeCMTY community={community}
                        communityId={community.id}
                        title={community.title}
                        state={community.state}
                        updateShowModalDelete={this.updateShowModalPurge}
                        purgeCommunity={this.props.purgeCommunity}
                        language={this.props.language}
                        colors={colors}/>
        }
      </div>
    );
  }

  updateShowModalPurge = () => {
    this.setState(prevState => ({showModalPurge: !prevState.showModalPurge}));
  };
}

