import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Lightbox from 'react-images';
import { Player, BigPlayButton, ControlBar, VolumeMenuButton, CurrentTimeDisplay, TimeDivider } from 'video-react';
import Comments from "../../../../containers/Community/Comments";
import Dotdotdot from "react-dotdotdot";

export default class ASTask extends React.Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    response: PropTypes.object.isRequired,
    isTaskMinimized: PropTypes.bool.isRequired,
    currentUserId: PropTypes.number.isRequired,
    role_1: PropTypes.string.isRequired,
    showComments: PropTypes.bool.isRequired,
    updateShowComments: PropTypes.func.isRequired,
    updateResponseComments: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    }
  }

  render() {
    const {language, question, response, isTaskMinimized, currentUserId, role_1} = this.props;
    const stateColor = this._getStateColor(response.state);
    const taskPanelHeight = isTaskMinimized ? '150px' : '100%';

    let images = [];
    const attachments = response.attachments.map((attachment, index) => {
      images.push({src: attachment.url});
      return (
        <div key={index} onClick={(e) => this.openLightbox(index, e)} className="image-item"
             style={{cursor:'pointer'}}>
          <img src={attachment.url}/>
        </div>
      )
    });

    const classComments = this.props.showComments ? 'px-n-accordion-open' : 'px-n-accordion-close';

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
          <div className="counter-task">
            <span className="number">{question.position + 1}sadasd</span>
          </div>
          <div className="panel panel-task" style={{borderLeft:'10px solid' + stateColor,height:taskPanelHeight}}>
            <FontAwesomeIcon icon={['fas', 'share']} className="share-icon"/>
            <div className="panel-body">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <p className="title">{question.title}</p>
                </div>
              </div>
              <div className="separator" style={{margin:'65px auto 30px auto'}} />

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="response">
                    <div className="container-answer">
                      <p className="answer">{response.data.answer}</p>
                    </div>
                  </div>
                </div>
              </div>


              <div className="attachments-container" style={{marginTop:'40px'}}>
                {
                  response.attachments.length !== 0 &&
                  <div className="images-container">
                    { attachments }
                  </div>
                }
                {
                  response.video_thumbnail &&
                  <div className="videos-container">
                  <Player poster={response.video_thumbnail} fluid={true} >
                    <source src={response.video_url}/>
                    <BigPlayButton position="center" />
                    <ControlBar autoHide={true} >
                      <VolumeMenuButton vertical />
                    </ControlBar>
                  </Player>
                  </div>
                }
                <Lightbox currentImage={this.state.currentImage}
                          images={images}
                          isOpen={this.state.lightboxIsOpen}
                          onClickPrev={this.gotoPrevious}
                          onClickNext={this.gotoNext}
                          onClickThumbnail={this.gotoImage}
                          onClose={this.closeLightbox}/>
              </div>

              {
                Object.keys(response.maps).length !== 0 &&
                <div className="add-mood-container">
                  <span className="mood">{response.maps.title[language]}</span>
                  <div className="master-mood-container">
                    <ul className="list-inline">
                      {response.maps.items.map((item, index) =>
                        <li key={index}>
                          <div className="mood-container">
                            <span className="btn-mood">
                                {item[language]}
                            </span>
                          </div>
                        </li>)}
                    </ul>

                  </div>
                </div>
              }

              <div className="separator" style={{marginTop:'65px',marginBottom:'55px'}}/>

              <div className="comments-option-container">
                <div className="div_icon">
                  <FontAwesomeIcon icon={['fas', 'heart']} className="chevron"/>
                  <label>10</label>
                </div>
                <div className="div_icon">
                  <FontAwesomeIcon icon={['fas', 'trophy']} className="chevron"/>
                  <label>10</label>
                </div>
                <div className="comments-count" style={{marginLeft:'auto'}}>
                  <div className="btn-group" role="group" aria-label="..." style={{margin:'0'}}>
                    <button onClick={() => this.props.updateShowComments(question.id, this.props.showComments)}
                            type="button" className="btn btn-part btn-part-selected inverted border-white-right">
                      <div className="div_for_flex">
                        <FontAwesomeIcon icon={['fas', 'message']} className="chevron" style={{fontSize:'10px'}}/>
                        <span style={{marginLeft:'0'}}>COMMENTS</span>
                        <div className="number">
                          <span>{response.comments.length}</span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <div className="px-n-accordion-open">
                <div className={classComments}>
                  <Comments comments={response.comments}
                            language={language}
                            userId={currentUserId}
                            username={'Not required for Participants'}
                            questionId={question.id}
                            responseId={response.id}
                            role_1={role_1}
                            from={'PAS'}
                            updateResponseComments={this.props.updateResponseComments}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }

  openLightbox = (index, event) => {
    console.log('openLightbox', index);
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };
  gotoPrevious = () => {
    this.setState({ currentImage: this.state.currentImage - 1 });
  };
  gotoNext = () => {
    console.log('gotoNext', this.state.currentImage);
    this.setState({ currentImage: this.state.currentImage + 1 });
  };
  gotoImage = (index) => {
    console.log('gotoImage', index);
    this.setState({
      currentImage: index,
    });
  };

  _getStateColor(state){
    let color = '';

    switch (state) {
      case 'Accepted':
        color = '#BCF8A5';
        break;
      case 'Completed':
        color = '$turquoise-dark';
        break;
      case 'Draft':
        color = '#47505C';
        break;
      case 'Empty':
        color = '#B1BDC0';
    }
    return color;
  }
}
