import PropTypes from 'prop-types';
import React from 'react';
import Layout from "../../Layout";
import Welcome from "../../../containers/Community/Welcome";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";

export default class WelcomePage extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    sessionLang: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    mailto: PropTypes.string.isRequired,
    active_users: PropTypes.array.isRequired,
    rooms: PropTypes.array.isRequired,
    communities: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activeUsers: [],
      notifications: [],
      rooms: [],
      communitiesTop: [],
      moderators: [],
      openPMFromOutside: {username: '', userId: null, flag: false},
      //openPMFromOutside: {username: '', userId: null, type: null, flag: false},
      notificationsCounter: 0,
      isBeaconInit: false //Keeps track of any instances of Head Scout Beacons initialised.
    }
  }

  componentDidMount(){
    const {user_account, community} = this.props;

    //this._getActiveUsers();
    this._getCommunitiesTop();
    this._getNotificationsCounter();
    //this._getNotifications();
    //this._getRooms();

    if(user_account.role_1 === 'Participant' ? community.show_coll_to_part : community.show_coll_to_coll){
      this._getCollaborators();
    }
  }

  render() {
    const {user_account, profile, sessionLang, community, mailto} = this.props;

    let message;
    if(profile.language){
      if(profile.language === 'en'){
        message = user_account.role_1 === 'Participant' ? community.participants_welcome_message :
          community.members_welcome_message;
      } else {
        message = user_account.role_1 === 'Participant' ? community.participants_welcome_message_es :
          community.members_welcome_message_es;
      }
    } else {
      // LEGACY in case profile.language is null
      if(sessionLang === 'en'){
        message = user_account.role_1 === 'Participant' ? community.participants_welcome_message :
          community.members_welcome_message;
      } else {
        message = user_account.role_1 === 'Participant' ? community.participants_welcome_message_es :
          community.members_welcome_message_es;
      }
    }
    //const language = profile.language ? profile.language : sessionLang;
    const hsBeaconSuggestions = {
      en: ['5f1fbf7c04286306f8077ac8', '5f1eb8d02c7d3a10cbab55fa', '5f1eb42704286306f8076b9e', '5f1fbaaa04286306f8077a9f', '5f1eb9fd04286306f8076bf8', '5f1eb6322c7d3a10cbab55c5', '5f1eb5b32c7d3a10cbab55bf', '5f1ec5a52c7d3a10cbab56c3', '5f1eb7cf04286306f8076bce', '5f1fbc0a2c7d3a10cbab6601'],
      es: ['5f1fbfc504286306f8077acb', '5f1ebca52c7d3a10cbab5621', '5f1fac1c2c7d3a10cbab656b', '5f1fbb192c7d3a10cbab65f9', '5f1ebc1304286306f8076c15', '5f1fadbb2c7d3a10cbab6577', '5f1fae5604286306f8077a22', '5f1fab532c7d3a10cbab6569', '5f1fac852c7d3a10cbab656d', '5f1fbc332c7d3a10cbab6604']
    };

    return (
      <Layout user_account={user_account}
              profile={user_account.profile}
              sessionLang={user_account.language}
              notifications={this.state.notifications}
              from={'WelcomePage'}
              community={community}
              communities={this.state.communitiesTop}
              rooms={this.state.rooms}
              active_users={this.state.activeUsers}
              leftMenuActive={'None'}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              notificationsCounter={this.state.notificationsCounter}
              colors={user_account.all_colors}>

        {/*<HelpScoutBeacon beaconId='493a5df1-6df0-49ec-9268-a372156968f2'*/}
        {/*                 user_account={user_account}*/}
        {/*                 colors={user_account.all_colors}*/}
        {/*                 language={user_account.language}*/}
        {/*                 isBeaconInit={this.state.isBeaconInit}*/}
        {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
        {/*                 suggestions={hsBeaconSuggestions}*/}
        {/*                 hideFABOnDesktop={!this.props.user_account.profile.is_help}/>*/}

        <Welcome isPreview={false}
                 moderators={this.state.moderators}
                 communityId={community.id}
                 communityTitle={community.title}
                 message={message}
                 showCollaborators={user_account.role_1 === 'Participant' ?
                   community.show_coll_to_part : community.show_coll_to_coll}
                 socialFlag={community.social_flag}
                 role_1={user_account.role_1}
                 mailto={mailto}
                 sessionLang={user_account.language}
                 language={user_account.language}
                 updateOpenPMFromOutside={this.updateOpenPMFromOutside}
                 userAvatar={user_account.profile.avatar !== '' ? user_account.profile.avatar : '/assets/user.png'}
                 userUsername={user_account.username}
                 profileId={user_account.profile.id}
                 colors={user_account.all_colors}/>

      </Layout>
    );
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }
  
  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log('communitiesTop:', communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  _getNotifications = () => {
    $.ajax({
      url: '/notifications/get_notifications/-1',
      method: 'GET',
      success: notifications =>  {
        this.setState({notifications});
      }
    });
  };

  _getRooms = () => {
    $.ajax({
      url: '/communities/get_rooms/' + this.props.community.id,
      method: 'GET',
      success: rooms =>  {
        console.log('rooms:', rooms);
        this.setState({rooms});
      }
    });
  };

  _getActiveUsers = () => {
    $.ajax({
      url: '/communities/coll_get_active_parts/' + this.props.community.id + '/welcome',
      method: 'GET',
      success: activeUsers =>  {
        this.setState({activeUsers});
      }
    });
  };

  updateOpenPMFromOutside = (type, username, userId) => {
    //console.log(type, username, userId);
    this.setState(state => ({openPMFromOutside: {username, userId, type, flag: !state.openPMFromOutside.flag}}));
    //this.setState(state => ({openPMFromOutside: {type, username, flag: !state.openPMFromOutside.flag}}));
  };

  _getCollaborators = () => {
    $.ajax({
      url: '/communities_user/welcome_get_users/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      data: {type: 'Collaborator'},
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communityUsers => {
        //console.log('communityUsers:', communityUsers);
        this.setState({moderators: communityUsers.filter(user =>
            (user.role_2 === 'Moderator' || user.role_2 === 'Owner') && user.is_welcome)})
      }
    });
  };
}