import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EditorWYSIWYGState from "../../../../Settings/Utils/EditorWYSIWYGState";
import {setBlobToPreview} from "../../../../../UI/Globals/PX_Funs";
import MicroChatObjectiveList from "./MicroChatObjectiveList";

const MicroChatEdit = ({question, cardinal, cancelEditQuestion, handleChangeQuestion, updateQuestion, onEditorStateChangeQuestion,
                         deleteMedia, updateObjective, deleteObjective, addObjective, handleRequired, onSortEndAnswerList,
                         communitySocial, language, colors, updateShowModal}) => {
  const [showModalVideo, setShowModalVideo] = useState(false);
  const imgPreviewChEditRef = useRef(null);

  useEffect(() => {
    if (question.blob !== undefined) {
      setBlobToPreview(question.blob, imgPreviewChEditRef.current);
    }

    let btnHoverColor = colors.color2[3];
    $('.action-button, .btn-draggable').mouseenter(function () {
      $(this).css({ 'background-color': btnHoverColor });
    });
    $('.action-button, .btn-draggable').mouseleave(function () {
      $(this).css({ 'background-color': '' });
    });
  }, []); // Empty dependency array ensures this effect runs only once

  const i18n = {
    en: {
      addImageToTask: 'Add an image to this task\'s instructions', addVideoToTask: 'Add a video to this task\'s instructions',
      otherOptions: 'Other options', mandatory: 'Mandatory', name: 'Name', avatar: 'Avatar', language: 'Language',
      style: 'Conversation Style', mandatoryTooltip: 'When participants are forced to respond the task in order to complete the activity.',
      objectives: 'Objectives', mainObjectivePlaceholder: 'Type your main objective here', addObjective: 'Add secondary objective',
      remaining: 'Remaining', save: 'Save', cancel: 'Cancel', preview: 'Preview', role: 'Personality',
      interviewees: 'Target profile', lblRole: 'Role/personality',
      lblInt: 'Target profile', lblMain: 'Main Objective', lblSec: 'Secondary Objectives',
      namePlace: 'Ai\'s name', languagePlace: 'Ai\'s language', stylePlace: 'Conversational style',
      aiSettings: 'AI'
    },
    es: {
      addImageToTask: 'Agrega una imagen en las instrucciones de esta tarea.', addVideoToTask: 'Agrega una video en las instrucciones de esta tarea.',
      otherOptions: 'Otras opciones', mandatory: 'Obligatoria', name: 'Nombre', avatar: 'Avatar', language: 'Idioma',
      style: 'Estilo de Conversación', mandatoryTooltip: 'Cuando los participantes están obligados a responder la tarea para completar la actividad.',
      objectives: 'Objetivos', mainObjectivePlaceholder: 'Escribe el objetivo principal aquí', addObjective: 'Agregar objetivo secundario',
      remaining: 'Restantes', save: 'Guardar', cancel: 'Cancelar', preview: 'Muestra', role: 'Personalidad',
      interviewees: 'Perfil del entrevistado', lblRole: 'Personalidad',
      lblInt: 'Perfil del entrevistado', lblMain: 'Objetivo Principal', lblSec: 'Objetivos Secundarios',
      namePlace: 'Nombre', languagePlace: 'Lenguaje del AI', stylePlace: 'Estilo de conversación del AI',
      aiSettings: 'AI'
    }
  }

  let btnImgClass = 'btn-media have-image';
  let imgUrl = '';
  let imgStyle = null;

  if(typeof question.blob !== 'undefined'){
    if(imgPreviewChEditRef.current !== undefined){
      setBlobToPreview(question.blob, imgPreviewChEditRef.current);
    }
    btnImgClass = 'btn-media have-image';
    imgStyle = {display: 'block'};
  } else {
    if(question.attachment.length !== 0){
      imgUrl = question.attachment[0].url;
      imgStyle = {display: 'block'};
    } else {
      btnImgClass = 'btn-media';
      imgStyle = {display: 'none'};
    }
  }

  let videoThumbnail = null;
  if(question.videoState){
    videoThumbnail = question.videoState === 'processed' ? question.video_thumbnail : '/assets/loader_200x200.gif';
  } else {
    videoThumbnail = question.video_thumbnail;
  }

  //console.log('question.data:', question.data)
  const dataSecondaryObjectives = question.data.secondary_objectives;
  let cNormalAns = dataSecondaryObjectives.length;

  const updateShowModalVideo = () => {
    this.setState(state => ({showModalVideo: !state.showModalVideo}));
  };

  return (
      <div className="panel panel-activity-builder-details edit-mode">
        <div className="top-border"/>
        <div className="bottom-border"/>
        <div className="left-border"/>
        <div className="right-border"/>

        <div className="panel-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="title-task-container edit">
                <div className="task-number" style={{ backgroundColor: colors.color0[1] }}><span>{cardinal}</span></div>
                <span className="title-task">{question.task_type}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-task-edit-container">
              <div className="px-left-actv-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <EditorWYSIWYGState name="title"
                                        editorName="editorQuestion"
                                        editorState={question.editorQuestion}
                                        onEditorStateChange={onEditorStateChangeQuestion}
                                        questionId={question.id}
                                        language={language}/>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="btn-media-container">
                          <div className="image-item">
                            <button onClick={() => updateShowModal('image', question.id)} className={btnImgClass}
                                    data-tooltip-content={i18n[language].addImageToTask}>
                              {
                                question.attachment.length === 0 && typeof question.blob === 'undefined' ?
                                    <FontAwesomeIcon icon={['fas', 'image']}/> : null
                              }
                              <img ref={imgPreviewChEditRef} src={imgUrl} alt="" style={imgStyle}/>
                            </button>
                            {
                                (question.attachment.length !== 0 || question.blob !== undefined) &&
                                <div onClick={() => deleteMedia(question.id, 'image')} className="px-btn-close-float">
                                  <div className="btn-container">
                                    <FontAwesomeIcon icon={['fas','xmark']}/>
                                  </div>
                                </div>
                            }
                          </div>
                          <div className="image-item">
                            {
                              question.video_thumbnail === '' ?
                                  <button onClick={() => updateShowModal('video', question.id)} className='btn-media'
                                          data-tooltip-content={i18n[language].addVideoToTask}>
                                    <FontAwesomeIcon icon={['fas', 'video']}/>
                                  </button>
                                  :
                                  <button onClick={updateShowModalVideo} className='btn-media have-image'>
                                    <img src={videoThumbnail} alt=""/>
                                  </button>
                            }
                            {
                                question.video_thumbnail !== '' &&
                                <div onClick={() => deleteMedia(question.id, 'video')} className="px-btn-close-float">
                                  <div className="btn-container">
                                    <FontAwesomeIcon icon={['fas','xmark']}/>
                                  </div>
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-right-actv-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label htmlFor="" className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].otherOptions}</label>
                    <div className="px-check-cont">
                      <label className="container-check">
                        <input type="checkbox" name="required" checked={question.required}
                               onChange={(event) => handleChangeQuestion(event, question.id, false)}/>
                        <span className="checkmark"/>
                        <span>{i18n[language].mandatory}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                         data-tooltip-content={i18n[language].mandatoryTooltip} />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className="inner-separator">
                <div className="separator"/>
                <span className="text">{i18n[language].aiSettings}</span>
                <div className="separator"/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '20px', gap: '40px' }}>
              <div style={{ flex: 0.6 }}>
                <div className="answers-container">
                  <label htmlFor="" className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].lblMain}</label>
                  <div>
                    <div className="px-answer-actv-builder" style={{marginLeft: '-5px'}}>
                      <FontAwesomeIcon icon={['fas', 'star']} className="px-drag" style={{ cursor: 'default' }} />
                      <textarea defaultValue={question.data.main_objective} className="input-answer-w100"
                                placeholder={i18n[language].mainObjectivePlaceholder} style={{height: '60px', marginLeft: '15px', width: '90%'}}
                                onBlur={(event) => updateObjective(event.target.value, question.id, 'text', -1, 'main_objective')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ flex: 0.4 }}>
                <div className="answers-container">
                  <label htmlFor="" className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].lblRole}</label>
                  <textarea defaultValue={question.data.role} className="input-answer-w100"
                            placeholder={i18n[language].role} style={{height: '60px'}}
                            onBlur={(event) => updateObjective(event.target.value, question.id, 'text', -1, 'role')}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px', gap: '40px' }}>
              <div style={{ flex: 0.6}}>
                <div className="answers-container">
                  <label htmlFor="" className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].lblSec}</label>
                  <MicroChatObjectiveList dataObjectives={dataSecondaryObjectives}
                                          questionId={question.id}
                                          updateObjective={updateObjective}
                                          deleteObjective={deleteObjective}
                                          deleteMedia={deleteMedia}
                                          onSortEndObjectiveList={onSortEndAnswerList}
                                          updateShowModal={updateShowModal}
                                          language={language}/>
                  <div style={{marginTop: '20px'}}>
                    <a onClick={() => addObjective(question.id, 'normal')} className="add-answer-item"
                       style={{ color: colors.color1[3] }}>
                      + {i18n[language].addObjective} ({dataSecondaryObjectives.length !== 15 ? 15 - cNormalAns : 0} {i18n[language].remaining})
                    </a>
                  </div>
                </div>
              </div>
              <div style={{ flex: 0.4 }}>
                <div className="answers-container">
                  <label htmlFor="" className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].style}</label>
                  <textarea defaultValue={question.data.conversation_style} className="input-answer-w100"
                            placeholder={i18n[language].stylePlace}
                            onBlur={(event) => updateObjective(event.target.value, question.id, 'text', -1, 'conversation_style')}
                  />
                </div>
                <div className="answers-container" style={{marginTop: '15px'}}>
                  <label htmlFor="" className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].name}</label>
                  <div className="px-check-cont" style={{marginBottom: '0'}}>
                    <textarea defaultValue={question.data.name} className="input-answer-w100"
                    placeholder={i18n[language].namePlace}
                    onBlur={(event) => updateObjective(event.target.value, question.id, 'text', -1, 'name')}/>
                  </div>
                </div>
                <div className="answers-container" style={{marginTop: '5px'}}>
                  <label htmlFor="" className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].language}</label>
                  <div className="px-check-cont" style={{marginBottom: '0'}}>
                    <textarea defaultValue={question.data.language} className="input-answer-w100"
                    placeholder={i18n[language].languagePlace}
                    onBlur={(event) => updateObjective(event.target.value, question.id, 'text', -1, 'language')}/>
                  </div>
                </div>
                <div className="answers-container" style={{marginTop: '5px'}}>
                  <label htmlFor="" className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].lblInt}</label>
                  <textarea defaultValue={question.data.interviewees} className="input-answer-w100"
                            placeholder={i18n[language].interviewees} style={{height: '60px'}}
                            onBlur={(event) => updateObjective(event.target.value, question.id, 'text', -1, 'interviewees')}
                  />
                </div>
              </div>
            </div>
          </div>

          {/*<div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mg-top-20">

            </div>
          </div>*/}
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-save-row">
                <button onClick={() => updateShowModal('preview task MCH', question.id)}
                        className="btn-preview">{i18n[language].preview}</button>
                <div className="action-button-container">
                  <button onClick={() => updateQuestion(question.id)} className="btn-save"
                          style={{ backgroundColor: colors.color1[3] }}>{i18n[language].save}</button>
                  <button onClick={() => cancelEditQuestion(question.id)} className="btn-cancel">
                    {i18n[language].cancel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="actions-panel">
          <div className="actions-container" style={{marginTop:'0'}}>
            <button onClick={() => updateQuestion(question.id)} className="action-button edit">
              <FontAwesomeIcon icon={['far', 'check']}/>
            </button>
            <button onClick={() => cancelEditQuestion(question.id)} className="action-button edit">
              <FontAwesomeIcon icon={['far', 'xmark']}/>
            </button>
          </div>
        </div>
      </div>
  );
};

export default MicroChatEdit
