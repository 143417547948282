import PropTypes from 'prop-types';
import React from 'react';
import TimeAgo from "react-timeago";
import englishStrings from 'react-timeago/lib/language-strings/en';
import spanishStrings from 'react-timeago/lib/language-strings/es';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"

import NewBroadcast from "../../components/Community/Conversations/NewBroadcast";
import Broadcast from "../../components/Community/Conversations/Broadcast";
import {showSuccessMsg} from "../../components/UI/Globals/PX_Funs";
import Loading from "../../components/UI/Globals/Loading";
import {usernamesToDisplay} from "../../components/Community/shared_utils";

export default class Conversations extends React.Component {
  static propTypes = {
    rooms: PropTypes.array.isRequired,
    rightDrawerToggleHandler: PropTypes.func.isRequired,
    active_users: PropTypes.array.isRequired,
    currentUserId: PropTypes.number.isRequired,
    profile: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    communityId: PropTypes.number,
    openPMFromOutside: PropTypes.object,
    role_1: PropTypes.string.isRequired,
    role_2: PropTypes.string,
    from: PropTypes.string,  // Maybe turn to isRequired if we need more specific users in the conversation panel
    colors: PropTypes.object.isRequired,
    segments: PropTypes.array
  };

  constructor(props) {
    //console.log('constructor rooms:', props.rooms);
    super(props);
    this.state = {
      isRooms: true,
      isUsersList: false,
      isConversation: false,
      isNewBroadcast: false,
      isBroadcastList: false,
      objPM: {rooms: this._sortRooms(props.rooms[0]), messages: [], room: {id: -1}},
      body: '',
      bodyBroadcast: '',
      tags: [],
      broadcast_recipients_ids: [],
      collaborators: [], // Temporal, after Beta do it by server
      user_2_username: null,
      broadcasts: {broadcasts: [], counterUnseen: 0},
      sentBroadcasts: {},
      isLoadingSentBroadcast: false,
      userSearch: '',
      pmSearch: '',
      // New
      active_users: [],
      doFirstTimeOutside: true,
      sendBlock: false,
      isSendingBroadcast: false
    };
  }

  componentDidMount(){
    const {openPMFromOutside} = this.props;
    console.log('Conversations.componentDidMount.openPMFromOutside: ', openPMFromOutside);
    this._getActiveUsers();

    if(openPMFromOutside && openPMFromOutside.username !== undefined && openPMFromOutside.flag){
      if(openPMFromOutside.type === 'PM'){
        //console.log(this.state.objPM.rooms);
        const room = this.state.objPM.rooms.find(room => room.user_username === openPMFromOutside.username);
        //console.log(room);
        if(room !== undefined){
          const availableNames = usernamesToDisplay(room);
          this.getPM(room.id, availableNames[0]);
        } else {
          //console.log('updatePM');
          this.updatePM(this.props.openPMFromOutside.userId, openPMFromOutside.username, openPMFromOutside.communityId)
        }
        //this.setState({user_2_username: openPMFromOutside.username});
      } else {
        // The same as _getBroadcasts(), except for the 'this.handleIsUsersList('broadcast list')'
        $.ajax({
          url: '/broadcasts/show/' + this.props.communityId,
          method: 'GET',
          dataType: 'JSON',
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: broadcasts => {
            //console.log(broadcasts);
            this.setState({broadcasts}, () => {
              this.handleIsUsersList('broadcast list');
            })
          }
        });

        this.getRooms();
      }
    } else {
      this._getBroadcasts();
      (this.props.role_1 === 'Collaborator' && this.props.role_2 !== 'Observer') && this._getSentBroadcasts();
    }
  }

  componentDidUpdate(prevProps){
    const {openPMFromOutside, rooms} = this.props;

    if(prevProps.openPMFromOutside !== this.props.openPMFromOutside){
      //console.log('Conversations.componentDidUpdate.openPMFromOutside: ', prevProps.openPMFromOutside, openPMFromOutside);
      const room = this.state.objPM.rooms.find(room => room.user_username === openPMFromOutside.username);
      //console.log('room:', room);
      if(room !== undefined){
        const availableNames = usernamesToDisplay(room);
        this.getPM(room.id, availableNames[0]);
      } else {
        this.updatePM(this.props.openPMFromOutside.userId, openPMFromOutside.username, openPMFromOutside.communityId)
      }
    }

    if(prevProps.rooms !== this.props.rooms){
      //console.log('Conversations.componentDidUpdate.rooms: ', prevProps.rooms, rooms);
      if(this.props.rooms.length !== 0 && !this.props.openPMFromOutside.flag){
        if(this.state.objPM.room.id === -1){
          this.setState({objPM: {rooms: this._sortRooms(rooms[0]), messages: [], room: {id: -1}}});
        } else {
          this.setState({objPM: {...this.state.objPM, rooms: this._sortRooms(rooms[0])}});
        }
      }
    }

    if(this.state.isConversation){
      this.scrollToBottom();
    }
    
  }

  render() {
    const {profile, active_users, role_1, role_2, language, colors} = this.props;
    const formatter = language === 'es' ? buildFormatter(spanishStrings) : buildFormatter(englishStrings);;
    const I18n = {
      en: {
        messages: 'Messages', btnNewConversation: 'New chat', btnSeeAllBRC: 'See all Broadcasts',
        newBRC: 'New Broadcast', searchConv: 'Search Chats', yourMsgHere: 'Your message here...', send: 'Send',
        searchUsers: 'Search users', broadcastsSent: 'Broadcasts history', noBroadcastSent: 'No broadcasts sent',
        broadcasts: 'Broadcasts'
      },
      es: {
        messages: 'Mensajes', btnNewConversation: 'Nuevo chat', btnSeeAllBRC: 'Ver todos los anuncios',
        newBRC: 'Nuevo Anuncio', searchConv: 'Buscar Chats', yourMsgHere: 'Tu mensaje aqui...',
        send: 'Enviar', searchUsers: 'Buscar usuarios', broadcastsSent: 'Anuncios enviados', noBroadcastSent: 'No hay anuncios enviados aún', 
        broadcasts: 'Anuncios'
      }
    };

    const rePM = new RegExp(this.state.pmSearch, 'i');
    const rooms = this.state.objPM.rooms.map(room => {
      console.log('room=', room)
      if(room.user_username.match(rePM) || room.user_email.match(rePM) || room?.alias_from?.match(rePM) || room?.user_natural_username?.match(rePM)){
        const avatar = room.user_avatar !== '' ? room.user_avatar : '/assets/user.png';
        const timeAgo = room.last_msg.length !== 0 && <TimeAgo date={room.last_msg[0].created_at} formatter={formatter} title=""/>;
        //console.log(room.unseen_size);
        const availableNames = usernamesToDisplay(room);
        return(
          <div key={room.id} onClick={() => this.getPM(room.id, availableNames[0])}
               className="message-item"
               style={{cursor:'pointer'}}>
            <div className="message-card">
              <div className="avatar-container">
                <img src={avatar} alt="avatar" className="avatar"/>
                {
                  room.user_role_1 === 'Collaborator' &&
                  <div className="badge-moderator" style={{ backgroundColor: colors.color1[3] }}><FontAwesomeIcon icon={['fas', 'gavel']} /></div>
                }
              </div>
              <div className="info-container">
                {room.unseen_size > 0 && <span className="unseen" style={{ position: 'relative', top: '-6px', left: '270px', backgroundColor: colors.color4[3]}}/> }
                <span className="username">{usernamesToDisplay(room)[0]}</span>
                {
                  (role_1 === 'Collaborator' && role_2 !== 'Observer') &&
                  <div className="mail">{room.user_email}</div>
                }
                <div className="activity" dangerouslySetInnerHTML={{__html: room.last_msg[0] ? room.last_msg[0].body : ''}}/>
              </div>
              <div className="actions-container">
                <span className="date" style={{ color: colors.color1[3] }}>{ timeAgo }</span>
                {
                  // role_1 !== 'Participant' &&
                  // <button className="btn-follow">Follow</button>
                }
              </div>
            </div>
          </div>
        )
      }
    });

    //console.log(active_users);
    let users = [];
    if(role_1 === 'Collaborator'){
      if(role_2 !== 'Observer'){
        users = [...this.state.active_users, ...this.state.collaborators];
        //users = [...active_users, ...this.state.collaborators];
      } else {
        users = [...this.state.collaborators];
      }

    } else {
      users = this.state.collaborators.filter(collaborator => collaborator.role_2 !== 'Observer');
    }

    //console.log(users);
    const re = new RegExp(this.state.userSearch, 'i');
    const participants = users.map((user, index) => {
      //console.log(user);
      if(this.props.currentUserId !== user.user_id && user.is_welcome){
        if(user.username.match(re) || user.email.match(re) || user?.alias_from?.match(re) || user?.natural_username?.match(re)){
          return(
            <div key={index}>
              <div className="message-item">
                <div onClick={() => this.updatePM(user.user_id, usernamesToDisplay(user)[0], this.props.communityId)}
                     className="message-card" style={{cursor:'pointer'}}>
                  <div className="avatar-container">
                    <img src={user.avatar ? user.avatar : '/assets/user.png'} alt="" className="avatar"/>
                    {
                      user.role_1 === 'Collaborator' &&
                      <div className="badge-moderator" style={{ backgroundColor: colors.color1[3] }}>
                        {(user.role_2 === 'Owner' || user.role_2 === 'Moderator') && <FontAwesomeIcon icon={['fas', 'gavel']} />}
                        {user.role_2 === 'Recruiter' && <FontAwesomeIcon icon={['fas', 'handshake']} />}
                        {user.role_2 === 'Observer' && <FontAwesomeIcon icon={['fas', 'eye']} />}
                      </div>
                    }
                  </div>
                  <div className="info-container">
                    <span className="username">{usernamesToDisplay(user)[0]}</span>
                    { role_1 === 'Collaborator' && <div className="mail">{user.email}</div> }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      }
    });

    const messages = this.state.objPM.messages.map((message, index) => {
      const currentUserId = this.state.objPM.current_user_id;
      const avatar = message.user_avatar === '' ? '/assets/user.png' : message.user_avatar;

      if(message.user_id === currentUserId){
        return(
          <div key={index} className="receiver-container">
            <img src={avatar} className="img-receiver" alt=""/>
            <div className="conversation-receiver-container">
              <div dangerouslySetInnerHTML={{ __html: message.body }} className="conversation-item" style={{ backgroundColor: colors.color0[6] }}>
              </div>
              <span className="receiver-date"><TimeAgo date={message.created_at} formatter={formatter} title=""/></span>
            </div>
          </div>
        )
      } else {
        return(
          <div key={index} className="sender-container">
            <img src={avatar} className="img-sender" alt=""/>
            <div className="conversation-sender-container">
              <div dangerouslySetInnerHTML={{ __html: message.body }} className="conversation-item" style={{ backgroundColor: colors.color1[6] }}>
              </div>
              <span className="sender-date"><TimeAgo date={message.created_at} formatter={formatter} title=""/></span>
            </div>
          </div>
        )
      }
    });

    const headerClass = this.state.isRooms ? 'messages-header' : 'messages-header with-back';
    const headerMainContainer = this.state.isConversation ?
      'messages-principal-container conversation' : 'messages-principal-container';

    let lastBroadcast = null;
    let unseenAlert = null;
    if(this.state.broadcasts.broadcasts !== undefined){
      if(this.state.isRooms && this.state.broadcasts.broadcasts){
        if(this.state.broadcasts.broadcasts.length !== 0){
          const broadcast = this.state.broadcasts.broadcasts[0];
          const timeAgo = <TimeAgo date={broadcast.created_at} formatter={formatter} title=""/>;

          if(this.state.broadcasts.counterUnseen > 0){
            unseenAlert = <div className="counter" style={{ backgroundColor: colors.color4[3] }}><span> {this.state.broadcasts.counterUnseen}</span></div>;
          }

          lastBroadcast = <div className="broadcast-display" style={{ backgroundColor: colors.color0[6], borderBottomColor: colors.color0[5] }}>
            <div className="timestamp" style={{ color: colors.color1[3] }}>{timeAgo}</div>
            <div className="content">
              <div className="bullhorn-container">
                <FontAwesomeIcon icon={['fas', 'bullhorn']} className="bullhorn" style={{ color: colors.color1[3] }}/>
                {unseenAlert}
              </div>
              <span className="text">{broadcast.body}</span>
            </div>
            <div onClick={() => this.handleIsUsersList('broadcast list')} className="broadcast-btn"
              style={{ cursor: 'pointer', textTransform: 'uppercase', backgroundColor: colors.color1[3] }} >{I18n[language].btnSeeAllBRC}</div>
          </div>;
        }
      }
    }

    const broadcastHistoryTitle = Object.keys(this.state.sentBroadcasts).length === 0 ?
      I18n[language].noBroadcastSent : I18n[language].broadcastsSent;
    
    return (
      <div className="messages-container">
        <div className={headerClass} style={{ backgroundColor: colors.color0[1] }}>
          <div className="messages-title">
            <span>
              {this.state.isRooms && I18n[language].messages}
              {this.state.isUsersList && I18n[language].btnNewConversation}
              {this.state.isConversation && this.state.user_2_username}
              {this.state.isNewBroadcast && I18n[language].newBRC}
              {this.state.isBroadcastList && I18n[language].broadcasts}
              </span>
          </div>
          <div onClick={this.closeDrawer} className="messages-close" style={{ cursor: 'pointer', backgroundColor: colors.color0[3]}}>
            <FontAwesomeIcon icon={['far', 'xmark']} className="iconClose"/>
          </div>
          {
            !this.state.isRooms &&
            <div onClick={() => this.handleIsUsersList('conversation')} className="messages-back"
              style={{ cursor: 'pointer', backgroundColor: colors.color0[3]}}>
              <FontAwesomeIcon icon={['far', 'arrow-left']} className="iconBack"/>
            </div>
          }
        </div>
        <div className={headerMainContainer}>
          {
            this.state.isRooms &&
            <div className="messages-search">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-input-icon">
                    <div className="form-group xs">
                      <input name="pmSearch" onChange={this.handleInputChange} value={this.state.pmSearch}
                            type="text" className="form-control icon-right" placeholder={''}/>
                    </div>
                    <FontAwesomeIcon icon={['far', 'magnifying-glass']} className="px-icon-right color-gray-base"/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-cont-actions">
                    <button onClick={() => this.handleIsUsersList('rooms')} className="btn-new-conv" style={{ backgroundColor: colors.color1[3] }}>
                      <span style={{textTransform:'uppercase'}}>{I18n[language].btnNewConversation}</span>
                      <FontAwesomeIcon icon={['fas','messages']}/>
                    </button>
                    {
                      role_2 === "Owner" || role_2 === "Moderator" ?
                        <button onClick={() => this.handleIsUsersList('new broadcast')} className="btn-new-conv" style={{ backgroundColor: colors.color1[3] }}>
                        <span style={{textTransform:'uppercase'}}>{I18n[language].newBRC}</span>
                        <FontAwesomeIcon icon={['fas','bullhorn']}/>
                      </button> : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          {
            this.state.isUsersList &&
            <div className="messages-search">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-input-icon">
                    <div className="form-group xs">
                      <input name="userSearch" onChange={this.handleInputChange}
                            onKeyPress={(e) => this.handleKeyPress(e, 'search users')}
                            value={this.state.userSearch} type="text" className="form-control icon-right"
                            placeholder={''}/>
                    </div>
                    <FontAwesomeIcon icon={['fal', 'magnifying-glass']} className="px-icon-right icon-search color-gray-base"/>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="separator"/>
          {
            this.state.isRooms && lastBroadcast
          }

          <div className="messages-items-container">
            {
              this.state.isRooms &&
              <div>
                { rooms }
              </div>
            }
            {
              this.state.isUsersList &&
              <div>
                { participants }
              </div>
            }
            {
              this.state.isConversation &&
                <div className="conversations-container">
                  { messages }
                  <div style={{ float:"left", clear: "both" }} ref={(el) => { this.messagesEnd = el; }}>
                  </div>
                </div>                
            }
            {
              this.state.isNewBroadcast &&
              <span>
                <NewBroadcast bodyBroadcast={this.state.bodyBroadcast}
                              handleInputChange={this.handleInputChange}
                              tags={this.state.tags}
                              handleTagsUsers={this.handleTagsUsers}
                              selectRecipientsIds={this.selectRecipientsIds}
                              saveBroadcast={this.saveBroadcast}
                              language={language}
                              colors={colors}
                              segments={this.props.segments}
                              isSendingBroadcast={this.state.isSendingBroadcast}/>
                  <div className="no-notifications text-center">{broadcastHistoryTitle}</div> 
                </span>
            } 
            { this.state.isLoadingSentBroadcast === false ? 
              this.state.isNewBroadcast &&
              this.state.sentBroadcasts.map(broadcast => {
                return (
                  <Broadcast
                    key={broadcast.id}
                    broadcast={broadcast}
                    language={language}
                    colors={colors}
                  />
                )
              })
              : 
              <Loading />
            }
            {
              this.state.isBroadcastList &&
                this.state.broadcasts.broadcasts.map(broadcast => {
                  return(
                    <div key={broadcast.id} className="broadcast-item">
                      <div className="bullhorn-container">
                        <FontAwesomeIcon icon={['fas', 'bullhorn']} className="bullhorn" style={{ color: colors.color1[3] }}/>
                        <div className="timestamp" style={{ color: colors.color1[3] }}>
                          <TimeAgo date={broadcast.created_at} formatter={formatter} title=""/>
                        </div>
                      </div>
                      <div className="text">{broadcast.body}</div>
                    </div>
                  )
                })
            }
          </div>
        </div>
        {
          this.state.isConversation &&
          <div className="message-container">
            <div className="form-group">
            <textarea name="body" onChange={this.handleInputChange}
                      //onKeyPress={(e) => this.handleKeyPress(e, 'pm')}
                      value={this.state.body} className="message-textarea"
                      placeholder={I18n[language].yourMsgHere} />
            </div>
            <div className="button-send-container">
              <button onClick={this.savePrivateMessage} className="btn send-button" disabled={this.state.sendBlock} style={{ backgroundColor: colors.color1[3] }}>
                { this.state.sendBlock ? <FontAwesomeIcon icon={['fas','spinner']} spin/> : I18n[language].send }
              </button>
            </div>
          </div>
        }
      </div>
    );
  }

  scrollToBottom = () => {
    //console.log('scrollToBottom');
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  closeDrawer = () => {
    this.props.rightDrawerToggleHandler();
    this.setState({isRooms: true, isUsersList: false, isConversation: false, isNewBroadcast: false,
      isBroadcastList: false});
  };

  searchUsers = () => {
    const q = this.state.userSearch;
  };

  _sortRooms(rooms){
    //console.log('_sortRooms rooms:', rooms);
    return rooms.sort(function (a, b) {
      if(a.last_msg.length !== 0 && b.last_msg.length !== 0){
        return new Date(b.last_msg[0].created_at) - new Date(a.last_msg[0].created_at)
      }
    });
  }

  handleTagsUsers = (tags) => {
    //console.log(tags);
    if(tags.length !== 0){
      const allUsers = [...this.state.active_users, ...this.state.collaborators];
      const lastTag = tags[tags.length - 1];
      const re = new RegExp(lastTag, 'i');

      if(allUsers.find(user => user.username.match(re)) !== undefined){
        this.setState({tags}, () => {

        });
      } else {
        if(lastTag === 'participants'){
          this.setState({tags});
        }
      }
    } else {
      this.setState({tags});
    }

  };

  selectRecipientsIds = type => {
    $.ajax({
      url: '/community_users',
      method: 'GET',
      dataType: 'JSON',
      data: {
        community_id: this.props.communityId,
        type: type[0],
        segments: type[1]
      },
      beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: (users) => {
        let broadcast_recipients_ids = []
        users.forEach(user => {
          broadcast_recipients_ids.push(user.user_id);
        });
        this.setState({ broadcast_recipients_ids })
      }
    });
  };

  updateBroadcastVisibility = () => {
    $.ajax({
      url: '/broadcasts/update/' + this.props.communityId,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
        this.setState({broadcasts: {...this.state.broadcasts, counterUnseen: 0}}, () => {
          let pmCounter = 0;
          this.state.objPM.rooms.forEach(room => {pmCounter += room.unseen_size});
          //pmCounter += this.state.broadcasts.counterUnseen;
          console.log(pmCounter);
          if(pmCounter !== 0){
            $('#spnPMCounter').text(pmCounter);
          } else {
            $('#divPMCounter').css('display', 'none');
          }
        });
      }
    });
  };

  saveBroadcast = (body, recipients) => {
    console.log('Save Broadcast', body);
    if(body !== ''){
      const broadcast_recipients_ids = this.state.broadcast_recipients_ids;

      if(broadcast_recipients_ids.length !== 0){
        this.setState({isSendingBroadcast: true}, () => {
          $.ajax({
            url: '/broadcasts/create/' + this.props.communityId,
            method: 'POST',
            dataType: 'JSON',
            data: {
              broadcast: {
                body: body,
                users_id: broadcast_recipients_ids,
                recipients: JSON.stringify(recipients)
              }
            },
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            success: broadcastsObj => {
              console.log(broadcastsObj);
              this.setState({bodyBroadcast: '', tags: [], broadcasts: broadcastsObj, isSendingBroadcast: false});
              this._getSentBroadcasts();
              showSuccessMsg('Success!');
            }
          });
        });
      }
    }
  };

  historyBroadcastHelper = (recipients) => {
    let tooltip = '';
    let iconClass = 'bullhorn';
    let iconLibrary = 'fas';

    if (recipients === null) {
      tooltip = 'Not available';
    } else {
        switch (recipients[0]){
          case 'all':
            tooltip = 'All users';
            break;
          case 'participants':
            tooltip = 'All participants';
            iconClass = 'people';
            iconLibrary = 'material';
            break;
          case 'collaborators':
            tooltip = 'All collaborators';
            iconClass = 'bubble_chart';
            iconLibrary = 'material';
            if (recipients[1].length > 0) {
              tooltip += `<br/>${recipients[1].join("<br/> ")}`;
              iconClass = 'bookmarks';
            }
            break;
          case 'null':
            if (recipients[1].length === 1) {
              tooltip = recipients[1];
              iconClass = 'bookmark';
            } else if (recipients[1].length > 1) {
              tooltip = recipients[1].join("<br/> ");
              iconClass = 'bookmarks';
              iconLibrary = 'material';
            }
            break;
        }
    }

    return { tooltip: tooltip, iconClass: iconClass, iconLibrary: iconLibrary }
    
  }
 
  savePrivateMessage = () => {
    const roomId = this.state.objPM.room.id;
    console.log('roomId:', roomId);

    if(roomId !== -1){
      if(this.state.body !== ''){
        this.setState({sendBlock: true}, () => {
          $.ajax({
            url: '/pm/create/' + roomId,
            method: 'POST',
            dataType: 'JSON',
            data: {
              message: {
                body: this.state.body
              }
            },
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            success: messages => {
              //console.log(messages);
              const lastMsg = {...messages[messages.length - 1]};
              //console.log(lastMsg);
              const rooms = this.state.objPM.rooms.map(room => {
                if(room.id === lastMsg.room_id){
                  //console.log(room.id, lastMsg.room_id);
                  return {...room, last_msg: [{id: lastMsg.id, body: lastMsg.body, created_at: lastMsg.created_at,
                      room_id: lastMsg.room_id, state: lastMsg.state, user_id: lastMsg.user_id}]}
                } else {
                  return room;
                }
              });
              //console.log(rooms);
              this.setState({
                objPM: {...this.state.objPM, messages, rooms: this._sortRooms(rooms)},
                body:'',
                sendBlock: false
              });

              this.handleScrollMessagesTop();
            }
          });
        });
      }
    } else {
      const {openPMFromOutside} = this.props;
      //console.log(openPMFromOutside.userId, openPMFromOutside.username, openPMFromOutside.communityId);
      //this.updatePM();
    }
  };

  getRooms = () => {
    $.ajax({
      url: '/rooms/get_rooms/' + this.props.communityId,
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: rooms =>  {
        //console.log(rooms);
        this.setState(prevState => ({objPM: {...prevState.objPM, rooms}, doFirstTimeOutside: false}));
      }
    });
  };

  updatePM = (user_2_id, user_2_username, communityId) => {
    //console.log('updatePM', user_2_id, user_2_username, communityId);
    this.setState({isUsersList: false, isRooms: false, isConversation: true, user_2_username}, () => {
      const commId = this.props.communityId !== -1 ? this.props.communityId : communityId;
      //console.log('commId:', commId);
      if(user_2_id !== undefined){
        $.ajax({
          url: '/pm/show/' + user_2_id + '/' + commId,
          method: 'GET',
          dataType: 'JSON',
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: objPM => {
            //console.log(objPM);
            this.setState({objPM: {...objPM, rooms: this._sortRooms(objPM.rooms)}}, () => {
              if(this.state.doFirstTimeOutside && this.props.openPMFromOutside.username !== ''){
                this.setState({doFirstTimeOutside: false}, () => {
                  //console.log(this.state.objPM, this.props.openPMFromOutside.username);
                  const room = this.state.objPM.rooms.find(room => room.user_username === this.props.openPMFromOutside.username);
                  //console.log('room:', room);
                  if(room !== undefined){
                    const availableNames = usernamesToDisplay(room);
                    this.getPM(room.id, availableNames[0]);
                  }
                });
              }
            });
          }
        });
      }
    });
  };

  getPM = (roomId, roomUsername) => {
    //console.log('getPM', roomId, roomUsername);
    $.ajax({
      url: '/pm/get_messages/' + roomId + '/' + this.props.communityId,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: obj => {
        //console.log('obj:', obj, 'this.state.objPM:', this.state.objPM);
        const objPM = {...this.state.objPM};
        objPM.messages = obj.messages;
        objPM.from_username = roomUsername;
        objPM.room.id = roomId;
        objPM.current_user_id = obj.currentUserId;
        objPM.rooms = this._sortRooms(obj.rooms);

        this.setState({
            objPM, isUsersList: false, isRooms: false, isConversation: true,
            user_2_username: roomUsername
          },
          () => {
          // TO-DO Maybe after beta to rebuild the whole component and delete this jquery options and counters
          let pmCounter = 0;
          obj.rooms.forEach(room => {pmCounter += room.unseen_size});
          pmCounter += this.state.broadcasts.counterUnseen;
          //console.log(pmCounter);
          if(pmCounter !== 0){
            $('#spnPMCounter').text(pmCounter);
          } else {
            $('#divPMCounter').css('display', 'none');
          }

          this.handleScrollMessagesTop();
        });
      }
    });
  };

  handleScrollMessagesTop = () => {
    //console.log('handleScrollMessagesTop', $('.conversations-container').prop("scrollHeight"));
    $(".conversations-container").animate({ scrollTop: $('.conversations-container').prop("scrollHeight")}, 500);
  };

  handleKeyPress = (event, type) => {
    if(event.key === 'Enter'){
      switch(type){
        case 'pm':
          this.savePrivateMessage();
          break;
        case 'search users':
          this.searchUsers();
          break;
      }
    }
  };

  handleIsUsersList = (from) => {
    switch(from){
      case 'rooms':
        this.setState({isUsersList: true, isRooms: false, isConversation: false, isNewBroadcast: false,
          isBroadcastList: false});
        break;
      case 'usersList':
        this.setState({isUsersList: false, isRooms: true, isConversation: false, isNewBroadcast: false,
          isBroadcastList: false});
        break;
      case 'conversation':
        this.setState({isUsersList: false, isRooms: true, isConversation: false, isNewBroadcast: false,
          isBroadcastList: false});
        break;
      case 'new broadcast':
        this.setState({isUsersList: false, isRooms: false, isConversation: false, isNewBroadcast: true,
          isBroadcastList: false});
        this._getSentBroadcasts();
        break;
      case 'broadcast list':
        this.setState({isUsersList: false, isRooms: false, isConversation: false, isNewBroadcast: false,
          isBroadcastList: true}, () => {
          if(this.state.broadcasts.counterUnseen !== 0){
            this.updateBroadcastVisibility();
          }
        });
        break;
    }
  };

  _getSentBroadcasts = () => {
    this.setState({ isLoadingSentBroadcast: true});
    $.ajax({
      url: '/broadcasts/' + this.props.communityId,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: sentBroadcasts => {
        this.setState({ isLoadingSentBroadcast: false });
        this.setState({ sentBroadcasts });
      }
    });
  }

  _getBroadcasts = () => {
    $.ajax({
      url: '/broadcasts/show/' + this.props.communityId,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: broadcasts => {
        //console.log(broadcasts);
        this.setState({broadcasts})
      }
    });
  };

  _getActiveUsers = () => {
    $.ajax({
      url: '/communities_user/get_users_conversations/' + this.props.communityId + '/' + this.props.from,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: users => {
        console.log('users:', users);
        this.setState({
          active_users: users.filter(user => user.role_1 === 'Participant'),
          collaborators: users.filter(user => user.role_1 === 'Collaborator')
        });
      }
    });
  };

  _getCollaborators = () => {
    $.ajax({
      url: '/communities/get_community_users/' + this.props.communityId,
      method: 'GET',
      dataType: 'JSON',
      data: {type: 'Collaborator'},
      success: communityUsers => {
        //console.log(communityUsers);
        this.setState({collaborators: communityUsers.filter(user => user.state === 'Enabled')});
      }
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  _showSuccessMsg(){
    $('#snackbar').addClass('show');
    setTimeout(function(){ $('#snackbar').removeClass('show'); }, 3000);
  }
}
