import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dotdotdot from "react-dotdotdot";

export default class CSMSocialCMTY extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    social_flag: PropTypes.bool.isRequired,
    updateShowModalSocial: PropTypes.func.isRequired,
    socialCommunity: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isCheck01: false,
      isCheck02: false,
      isBtnSave: true
    }
  }

  handleInputChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;

    this.setState({[name]: value}, () => {
      this.setState({isBtnSave: !(this.state.isCheck01 && this.state.isCheck02)});
    });
  };

  render() {
    const {social_flag, language, colors} = this.props;
    const I18n = {
      en: {
        socialModeOn: 'Turn ON Social Mode?', socialModeOff: 'Turn OFF Social Mode?', 
        socialModeOffWarning1: 'Participants, in this community, won’t have access to each others’ responses anymore.',
        socialModeOffWarning2: 'All existing activities will be set to "Private".',
        socialModeOnWarning1: 'Participants, in this community, will have access to each others’ responses except for those of activities marked as "Private".',
        socialModeOnWarning2: 'All existing activities will remain "Private". Make sure you make "Public" the ones that you want its responses to be available for the whole community.',
        yes: 'Yes'
      },
      es: {
        socialModeOn: 'Activar Modo Social?', socialModeOff: 'Desactivar Modo Social?',
        socialModeOffWarning1: 'Los participantes no podrán acceder más a las respuestas de otros participantes.',
        socialModeOffWarning2: 'Todas las actividades de esta comunidad se convertirán en privadas.',
        socialModeOnWarning1: 'Los participantes podrán acceder a las respuestas de otros participantes, excepto en actividades marcadas como "Privadas".',
        socialModeOnWarning2: 'Todas las actividades existentes se mantendrán siendo "Privadas". Asegúrate de configurar como "Públicas" las que quieras que sus respuestas sean visibles para toda la comunidad.',
        yes: 'Sí'
      }
    }

    return (
      <div className="my-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="close" onClick={this.props.updateShowModalSocial}>
                <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
              </button>
            </div>
            <div className="modal-body px-modal-body-warning">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="px-title-warning-container">
                    <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning" style={{color: colors.color4[3]}}/>
                    <p className="px-title-warning">
                      {social_flag ? I18n[language].socialModeOff : I18n[language].socialModeOn}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xs-offset-1 col-sm-offset-1 col-md-offset-1 col-lg-offset-1"
                     style={{padding:'0'}}>
                  <div className="px-check-cont px-checkbox-modal-warning">
                    <label className="container-check">
                      <input name="isCheck01" type="checkbox" onChange={this.handleInputChange}
                             value={this.state.isCheck01}/>
                      <span className="checkmark"/>
                      <span>
                        {
                          social_flag ?
                            I18n[language].socialModeOffWarning1 : I18n[language].socialModeOnWarning1
                        }
                      </span>
                    </label>
                  </div>
                  <div className="px-check-cont px-checkbox-modal-warning">
                    <label className="container-check">
                      <input name="isCheck02" type="checkbox" onChange={this.handleInputChange}
                        value={this.state.isCheck02} />
                      <span className="checkmark" />
                      <span>
                        {
                          social_flag ?
                            I18n[language].socialModeOffWarning2 : I18n[language].socialModeOnWarning2
                        }
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer px-modal-footer-warning">
              <div className="px-footer-container-buttons column">
                <button onClick={this.validSocialCommunity} type="button" className="btn px-btn sm btn-blue-base"
                        disabled={this.state.isBtnSave} style={{backgroundColor: colors.color1[3]}}>{I18n[language].yes}</button>
                <button onClick={this.props.updateShowModalSocial} type="button"
                        className="btn px-btn sm btn-gray-darker inverted">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  validSocialCommunity = () => {
    if(this.state.isCheck01){
      this.props.socialCommunity(this.props.communityId);
    }
  }
}
