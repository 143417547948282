import PropTypes from 'prop-types';
import React from 'react';
import TRChAnswer from "./TRChAnswer";
import Lightbox from "react-images";

export default class TRChoice extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    response: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      ansNoImages: null,
      currentImage: 0,
      lightBoxIsOpen: false,
    }
  }

  componentDidMount(){
    const justImg = this.props.task.data.answers.map(answer => answer.img !== undefined ? answer.img : '');
    //console.log('componentDidMount', justImg);
    this.setState({ansNoImages: justImg.filter(item => item !== '').length === 0})
  }

  render() {
    const {task, response, language, colors} = this.props;
    //console.log(task);
    task.data.answers.sort((a, b) => (parseInt(a.pos) > parseInt(b.pos) ? 1 : -1));

    const imagesLB = [];
    const answers = task.data.answers.map((answer, index) => {
      if(answer.img !== undefined){
        imagesLB.push({src: answer.img !== '' ? answer.img : '/assets/placeholder.png'});
      }
      //console.log(response);
      return <TRChAnswer key={answer.id}
                         focusOn={task.data.focusOn}
                         subtype={task.data.subtype}
                         answer={answer}
                          //TO-DO Participant Social looks fore response.response, change ti after Beta, it must be as TV from Colls
                         response={response.response ? response.response : response}
                         ansNoImages={this.state.ansNoImages}
                         index={index}
                         openLightBox={this.openLightBox}
                         language={language}
                         colors={colors}/>
    });
    //console.log(imagesLB);

    const answerComp = <div className={"px-options-container"}>
      { answers }
    </div>;

    return(
      <div>
        { answerComp }
        <Lightbox currentImage={this.state.currentImage}
                  images={imagesLB}
                  isOpen={this.state.lightBoxIsOpen}
                  onClickPrev={this.gotoPrevious}
                  onClickNext={this.gotoNext}
                  onClickThumbnail={this.gotoImage}
                  onClose={this.closeLightbox}/>
      </div>
    )
  }

  openLightBox = (index, event) => {
    //console.log(index);
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightBoxIsOpen: true,
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightBoxIsOpen: false,
    });
  };
  gotoPrevious = () => {
    this.setState({ currentImage: this.state.currentImage - 1 });
  };
  gotoNext = () => {
    this.setState({ currentImage: this.state.currentImage + 1 });
  };
  gotoImage = (index) => {
    this.setState({currentImage: index});
  };
}
