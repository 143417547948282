import PropTypes from 'prop-types';
import React from 'react';

export default class LoadingConsolidated extends React.Component {
  static propTypes = {
    //name: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{textAlign:'center'}}>
          <img src="https://dev-pixiebob.s3-ap-southeast-2.amazonaws.com/Loader_potion.gif"
               style={{width:'8%'}} alt=""/>
        </div>
      </div>
    );
  }
}
