import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Cropper from "react-cropper";
import Dropzone from "react-dropzone";

export default class MAModalImage extends React.Component {
  static propTypes = {
    objectId: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    handleToggle: PropTypes.func.isRequired,
    updateAvatar: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    action: PropTypes.string,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      image: this.props.url,
      croppedImage: null,
      language: props.language,
      isSaving: false
    }
  }

  render() {
    let avatar = this.state.image;
    if (avatar === '') {
      avatar = '/assets/community.png';
    }

    const i18n = {
      en: {btn_save: 'Save', btn_cancel: 'Cancel'},
      es: {btn_save: 'Guardar', btn_cancel: 'Cancelar'}
    };
    const colors = this.props.colors;

    return(
      <div className="my-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" style={{margin:'0'}}>
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-white px-close-modal"
                      data-dismiss="modal" aria-hidden="true">
                <i onClick={this.props.handleToggle} className="material-icons"
                   style={{float:'right', cursor:'pointer'}}>clear</i>
              </button>
              <h4 className="px-modal-title">Company Logo</h4>
            </div>

            <div className=" px-modal-body-add-activity">
              <Cropper ref='cropper'
                       src={avatar}
                       style={{height: 200, width: '100%'}}
                       aspectRatio={16 / 9}
                       guides={true}
                       crop={this._crop.bind(this)}
              />
              <Dropzone onDrop={this.onDrop} minSize={0} maxSize={2097152}
                        style={{border:'0 solid', marginTop:'20px'}} accept={'image/jpeg, image/png'}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="btn px-btn circle lg btn-turquoise-base" style={{margin:'10px auto', backgroundColor: colors.color2[3]}}>
                      <i className="material-icons">camera_enhance</i>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>

            <div className=" px-modal-footer-gray">
              <div className="px-footer-container-buttons row">
                <button onClick={this.handleImage} type="button" className="btn px-btn sm btn-blue-base"
                        disabled={this.state.isSaving} style={{backgroundColor: colors.color1[3]}}>{!this.state.isSaving && i18n[this.state.language].btn_save}
                  {this.state.isSaving && <FontAwesomeIcon icon={['fas','spinner']} spin/>}
                </button>
                <button onClick={this.props.handleToggle} type="button" className="btn px-btn sm btn-gray-darker inverted">
                  {i18n[this.state.language].btn_cancel}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleImage = () => {
    this.setState({isSaving: true}, () => {
      this.refs.cropper.getCroppedCanvas({
        fillColor: '#fff',
      }).toBlob(function (blob) {
        const formData = new FormData();
        formData.append("file", blob);

        $.ajax({
          url: '/companies/update_logo/' + this.props.objectId,
          method:'PATCH',
          data: formData,
          processData: false,
          contentType: false,
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: logo => {
            console.log(logo);
            this.setState({isSaving: false});

            // Appearance.updateAvatar()
            this.props.updateAvatar(blob);
            this.props.handleToggle();
          }
        });
      }.bind(this), 'image/jpeg', 0.5);
    });
  };

  onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    Object.assign(acceptedFiles[0], {preview: URL.createObjectURL(acceptedFiles[0])});

    this.setState({image: file.preview});
  };

  _crop(){
    // image in dataUrl
  };
}