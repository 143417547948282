import PropTypes from 'prop-types';
import React from 'react';

import Layout from "../../Layout";
import PCActivity01 from "../../../containers/Community/Participant/PCActivity01";
import {getParticipantToDoCounter} from "../../UI/Globals/PX_Funs";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";

export default class PCActivityPage extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    sessionLang: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    maps: PropTypes.array.isRequired,
    notifications: PropTypes.array.isRequired,
    active_users: PropTypes.array.isRequired,
    rooms: PropTypes.array.isRequired,
    communities: PropTypes.array.isRequired,
    scroll: PropTypes.string,
    type: PropTypes.string,
    item_scroll: PropTypes.object,
    data_signature: PropTypes.object.isRequired,
    base_url_ai: PropTypes.string.isRequired,
    qc: PropTypes.array.isRequired,
    subdomain: PropTypes.string.isRequired,
    company: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      communitiesTop: [],
      notifications: [],
      rooms: [],
      activeUsers: [],
      toDoCounter: 0,
      openPMFromOutside: {username: '', userId: null, flag: false},
      notificationsCounter: 0,
      isBeaconInit: false, //Keeps track of any instances of Head Scout Beacons initialised.
      user_account: props.user_account
    }
    // THIS PAGE IS ONLY CALLED ONE TIME, it looks the error in others is on the community controller
    // TO-DO find the double call, I can't find it, maybe I fixed it
  }

  componentDidMount(){
    this._getTasks()
    this._getNotificationsCounter()
    this._getCommunitiesTop()
  }

  updateCurrentUser = (user_account) => {
    this.setState({user_account})
  }

  render() {
    const {user_account, sessionLang, community, active_users, scroll, type, item_scroll, data_signature,
      base_url_ai, qc, subdomain, company} = this.props
    const language = user_account.profile.language ? user_account.profile.language : community.language;
    const cmntyLanguage = community.language;
    const allowImageAutoModeration = community.ai_allow_pictures && community.ai_auto_moderation && community.ai_plan === 'aiPlan02';
    //const language = user_account.profile.language ? user_account.profile.language : sessionLang;
    const hsBeaconSuggestions = {
      en: ['5f2016c104286306f8077de4', '5f20076a04286306f8077d4d', '5f1ec5d204286306f8076c91', '5f1ec5a52c7d3a10cbab56c3', '5f20190d04286306f8077df8', '5f20087e2c7d3a10cbab691e', '5f201a4604286306f8077e05', '5f201b662c7d3a10cbab69f8', '5f1eb42704286306f8076b9e', '5f1fffdb2c7d3a10cbab68c2'],
      es: ['5f20170804286306f8077de6', '5f2007652c7d3a10cbab6913', '5f1faa9a2c7d3a10cbab6564', '5f1fab532c7d3a10cbab6569', '5f20193e04286306f8077df9', '5f20097404286306f8077d62', '5f201ae92c7d3a10cbab69f1', '5f201ba204286306f8077e1b', '5f1fac1c2c7d3a10cbab656b', '5f20004504286306f8077cff']
    };

    return (
      <Layout user_account={this.state.user_account}
              profile={this.state.user_account.profile}
              sessionLang={sessionLang}
              notifications={this.state.notifications}
              from={'ParticipantActivityPage'}
              community={community}
              active_users={active_users}
              rooms={this.state.rooms}
              communities={this.state.communitiesTop}
              toDoCounter={this.state.toDoCounter}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              notificationsCounter={this.state.notificationsCounter}
              colors={this.state.user_account.all_colors}>

        {/*<HelpScoutBeacon beaconId='b8d4b0dc-2c9c-434f-8e57-06093e1697a4'*/}
        {/*                 user_account={this.state.user_account}*/}
        {/*                 colors={this.state.user_account.all_colors}*/}
        {/*                 language={language}*/}
        {/*                 isBeaconInit={this.state.isBeaconInit}*/}
        {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
        {/*                 suggestions={hsBeaconSuggestions}*/}
        {/*                 hideFABOnDesktop={!this.state.user_account.profile.is_help}/>*/}

        <PCActivity01 task={this.props.task}
                      profile={this.state.user_account.profile}
                      language={language}
                      cmntyLanguage={cmntyLanguage}
                      updateToDoCounter={this.updateToDoCounter}
                      scroll={scroll}
                      type={type}
                      item_scroll={item_scroll}
                      data_signature={data_signature}
                      colors={this.state.user_account.all_colors}
                      helpStores={this.state.user_account.help_stores}
                      comunitySocial={community.social_flag}
                      base_url_ai={base_url_ai}
                      cmntyAutoModeration={community.ai_auto_moderation}
                      cmntyAutoModerationCount={community.ai_auto_moderation_count}
                      username={this.state.user_account.username}
                      naturalUsername={this.state.user_account.natural_username}
                      cu={this.state.user_account.cu}
                      qc={qc}
                      communityId={community.id}
                      subdomain={subdomain}
                      cmntyProjectType={community.project_type}
                      cmntyAIPlan={community.ai_plan || ''}
                      cmtyAiAllowPictures={community.ai_allow_pictures}
                      allowImageAutoModeration={allowImageAutoModeration || false}
                      company={company}
                      updateCurrentUser={this.updateCurrentUser}
                      aiAgentName={community.ai_name}
        />
      </Layout>
    );
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }
  updateOpenPMFromOutside = (type, username, userId) => {
    // console.log(type, username, userId);
    this.setState(state => ({openPMFromOutside: {username: username, userId: userId, type,
        flag: !state.openPMFromOutside.flag}}));
  };

  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log(communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };

  _getRooms = () => {
    $.ajax({
      url: '/communities/get_rooms/' + this.props.community.id,
      method: 'GET',
      success: rooms =>  {
        //console.log(rooms);
        this.setState({rooms});
      }
    });
  };

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  _getNotifications = () => {
    $.ajax({
      url: '/notifications/get_notifications/-1',
      method: 'GET',
      success: notifications =>  {
        this.setState({notifications});
      }
    });
  };

  /*updateOpenPMFromOutside = (username, userId) => {
    console.log(username, userId);
    this.setState(state => ({openPMFromOutside: {username: username, userId: userId, flag: !state.openPMFromOutside.flag}}));
  };*/

  _getTasks = () => {
    $.ajax({
      url: '/activities/part_dash_get_activities/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: tasksDB => {
        //console.log(tasksDB);
        this.setState({toDoCounter: getParticipantToDoCounter(tasksDB)});
        //this.props.updateToDoCounter(getParticipantToDoCounter(tasksDB));
      }
    });
  };

  getToDoCounter(communityId){
    $.ajax({
      url: '/tasks/to_do_counter/' + communityId,
      method: 'GET',
      dataType: 'JSON',
      success: function (toDoCounter) {
        //console.log(toDoCounter);
        this.setState({toDoCounter})
      }.bind(this)
    });
  }

  updateToDoCounter = (toDoCounter) => {
    this.setState({toDoCounter})
  };
}