import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MAModalImage from "./Appearance/MAModalImage";
import {showSuccessMsg} from "../UI/Globals/PX_Funs";

export default class Appearance extends React.Component {
  static propTypes = {
    company: PropTypes.object,
    language: PropTypes.string.isRequired,
    deleteLogo: PropTypes.func.isRequired,
    user_account: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired,
    colorSetsSample: PropTypes.object,
    activeColorSetId: PropTypes.number,
    handleSelectColor: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      is_help: props.user_account.profile.is_help
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.company !== this.props.company){
      //console.log('Logo change:', prevProps.company.logo, this.props.company.logo)
      $('#imgLogo').attr('src', this.props.company.logo !== '' ?
        this.props.company.logo : '/assets/placeholder.png');
    }
  }

  render(){
    const {company, language, user_account, colors, colorSetsSample, activeColorSetId} = this.props;

    const i18n = {
      en: {
        helpLabel: 'Help', help: 'Show help button', customColors: 'Platform colors', save: 'Save'
      },
      es: {
        helpLabel: 'Ayuda', help: 'Mostrar botón de ayuda', customColors: 'Colores de la plataforma', save: 'Guardar'
      }
    };

    const avatar = company ? company.logo !== '' ? company.logo : '/assets/placeholder.png' : '';
    const popover = {position: 'absolute', zIndex: '2', top: '-205px', right: '-235px'};
    const cover = {position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'};
    //console.log(avatar);

    const colorSets = []
    for (let key in colorSetsSample) {
      colorSets.push(
        <li key={key} className = "col-xs-12 col-sm-4 col-md-4 col-lg-4" style = {{ listStyleType: "none" }}>
          <div className={"colored-square " + (`${activeColorSetId}` === key ? 'active' : '')}
            onClick={() => this.props.handleSelectColor(key)}
          style={{
            borderColor: '#AFBDC1', background: `linear-gradient(${colorSetsSample[key][0]} 33%, 
            ${colorSetsSample[key][1]} 33%, ${colorSetsSample[key][1]} 66%, ${colorSetsSample[key][2]} 66%)`
          }}>
          <span><FontAwesomeIcon icon={['far', 'check']} /></span>
          </div>
        </li>
      )
    }

    return(
      <div className="px-settings-container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-card px-card-settings">
              <div className="px-icon-settings">
                <FontAwesomeIcon icon={['fas','palette']}/>
              </div>
              <div className="panel-body px-body-padding-settings">

                <div className="panel-body">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="notifications-title">
                        <label className="label-sm" style={{color: colors.color1[2]}}>{i18n[language].helpLabel}</label>
                      </div>
                      <div className="px-notifications-container">
                        <div className="notifications-item">
                          <span className="title-header">{i18n[language].help}</span>
                          <div className="switch-container">
                            <label className="px-switch" >
                              <input name="is_help" type="checkbox" onChange={this.handleChange} checked={this.state.is_help} />
                                <div className="slider round" style={this.state.is_help ? {backgroundColor: colors.color3[3]} : {}} />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  user_account.profile.role === 'Superadmin' &&
                  <div className="panel-body row" style={{display:'flex',alignItems:'flex-start', justifyContent:'flex-start',flexWrap:'wrap'}}>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                         style={{display: "flex",flexDirection: "column",justifyContent: "center", alignItems: "left"}}>
                      <div className="row">
                        <div className="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4">
                          <label className="label-sm" style={{color: colors.color1[2]}}>Company Logo</label>
                          <img id='imgLogo' onClick={this.updateShowModal} src={avatar} className="px-thumb-100 img-responsive"
                            style={{ height: 'auto', margin: '0', borderColor: colors.color1[2] }} alt=""/>
                          <p className="px-label-information" style={{ cursor: "pointer", color: '#AFBDC1', textAlign: 'left' }}
                            onClick={this.props.deleteLogo}>remove logo</p>
                        </div>
                        <div className="form-group col-xs-12 col-sm-8 col-md-8 col-lg-8">
                          <label className="label-sm"> </label>
                          <div style={{ height: '100px', width: '178px', border: '1px solid #AFBDC1', padding: '5px',
                            fontSize:'10px', fontFamily: 'Sharp Sans ExtraBold, Helvetica, sans-serif', color: '#45505D' }}>
                            <div style={{ borderWidth: '1px', borderColor: colors.color4[3], height: '100%', textAlign: 'center',
                              padding: '5px', lineHeight: '1.2', borderStyle: 'dashed'}}>
                              LOGO DESIGN GUIDELINES
                              <ul style={{ listStyleType: 'none', padding: '5px 0 0 0',
                                fontFamily: 'Sharp Sans medium, Helvetica, sans-serif'}}>
                                <li>
                                  Background:
                                  <span style={{marginLeft: '5px'}}>White</span>
                                </li> 
                                <li>
                                  Ratio:
                                  <span style={{ marginLeft: '5px' }}>16:9</span>
                                </li> 
                                <li>
                                  Min safe area:
                                  <span style={{ marginLeft: '5px' }}>5px</span>
                                </li> 
                                <li>
                                  Ideal size:
                                  <span style={{ marginLeft: '5px' }}>434 x 772 px</span>
                                </li> 
                                <li>
                                  Ideal safe area:
                                  <span style={{ marginLeft: '5px' }}>15px</span>
                                  
                                </li>
                              </ul>
                            </div>                 
                          </div>
                        </div>
                      </div>
                      
                      {/* <div className="form-group">
                        <label className="label-sm">PRIMARY COLOR</label>
                        <div className="px-input-icon">
                          <div className="form-group">
                            <input type="text" className="form-control icon-right" value={this.state.colors[0]} readOnly={true}/>
                          </div>
                          <FontAwesomeIcon onClick={() => this.handleDisplayColorPicker('showPicker01')}
                                           className="px-icon-right" icon={['fas','square']} style={{color:this.state.colors[0]}}/>
                          {
                            this.state.showPicker01 &&
                            <div style={ popover }>
                              <div style={ cover } onClick={ () => this.handleDisplayColorPicker('showPicker01') }/>
                              <ChromePicker color={this.state.colors[0]} onChange={(color) => this.updateColor(color,0)}
                                            disableAlpha={true}/>
                            </div>
                          }
                        </div>
                      </div> */}
                      {/* <div className="form-group">
                        <label className="label-sm">SECONDARY COLOR</label>
                        <div className="px-input-icon">
                          <div className="form-group">
                            <input type="text" className="form-control icon-right" value={this.state.colors[1]} readOnly={true}/>
                          </div>
                          <FontAwesomeIcon onClick={() => this.handleDisplayColorPicker('showPicker02')}
                                           className="px-icon-right" icon={['fas','square']} style={{color:this.state.colors[1]}}/>
                          {
                            this.state.showPicker02 &&
                            <div style={ popover }>
                              <div style={ cover } onClick={ () => this.handleDisplayColorPicker('showPicker02') }/>
                              <ChromePicker color={this.state.colors[1]} onChange={(color) => this.updateColor(color,1)}
                                            disableAlpha={true}/>
                            </div>
                          }
                        </div>
                      </div> */}
                      {/* <div className="form-group">
                        <label className="label-sm">SUCCESS COLOR</label>
                        <div className="px-input-icon">
                          <div className="form-group">
                            <input type="text" className="form-control icon-right" value={this.state.colors[2]} readOnly={true}/>
                          </div>
                          <FontAwesomeIcon onClick={() => this.handleDisplayColorPicker('showPicker03')}
                                           className="px-icon-right" icon={['fas','square']} style={{color:this.state.colors[2]}}/>
                          {
                            this.state.showPicker03 &&
                            <div style={ popover }>
                              <div style={ cover } onClick={ () => this.handleDisplayColorPicker('showPicker03') }/>
                              <ChromePicker color={this.state.colors[2]} onChange={(color) => this.updateColor(color,2)}
                                            disableAlpha={true}/>
                            </div>
                          }
                        </div>
                      </div> */}
                      {/* <div className="form-group">
                        <label className="label-sm">WARNING COLOR</label>
                        <div className="px-input-icon">
                          <div className="form-group">
                            <input type="text" className="form-control icon-right" value={this.state.colors[3]} readOnly={true}/>
                          </div>
                          <FontAwesomeIcon onClick={() => this.handleDisplayColorPicker('showPicker04')}
                                           className="px-icon-right" icon={['fas','square']} style={{color:this.state.colors[3]}}/>
                          {
                            this.state.showPicker04 &&
                            <div style={ popover }>
                              <div style={ cover } onClick={ () => this.handleDisplayColorPicker('showPicker04') }/>
                              <ChromePicker color={this.state.colors[3]} onChange={(color) => this.updateColor(color,3)}
                                            disableAlpha={true}/>
                            </div>
                          }
                        </div>
                      </div> */}
                      {/* <div className="form-group">
                        <label className="label-sm">INFO COLOR</label>
                        <div className="px-input-icon">
                          <div className="form-group">
                            <input type="text" className="form-control icon-right" value={this.state.colors[4]} readOnly={true}/>
                          </div>
                          <FontAwesomeIcon onClick={() => this.handleDisplayColorPicker('showPicker05')}
                                           className="px-icon-right" icon={['fas','square']} style={{color:this.state.colors[4]}}/>
                          {
                            this.state.showPicker05 &&
                            <div style={ popover }>
                              <div style={ cover } onClick={ () => this.handleDisplayColorPicker('showPicker05') }/>
                              <ChromePicker color={this.state.colors[4]} onChange={(color) => this.updateColor(color,4)}
                                            disableAlpha={true}/>
                            </div>
                          }
                        </div>
                      </div> */}
                      {/* <div className="form-group">
                        <label className="label-sm">LIGHT COLOR</label>
                        <div className="px-input-icon">
                          <div className="form-group">
                            <input type="text" className="form-control icon-right" value={this.state.colors[5]} readOnly={true} />
                          </div>
                          <FontAwesomeIcon onClick={() => this.handleDisplayColorPicker('showPicker06')}
                                           className="px-icon-right" icon={['fas', 'square']} style={{ color: this.state.colors[5] }} />
                          {
                            this.state.showPicker06 &&
                            <div style={popover}>
                              <div style={cover} onClick={() => this.handleDisplayColorPicker('showPicker06')} />
                              <ChromePicker color={this.state.colors[5]} onChange={(color) => this.updateColor(color, 5)}
                                            disableAlpha={true} />
                            </div>
                          }
                        </div>
                      </div> */}
                    </div>
                  </div>
                }
                {
                user_account.profile.role === 'Superadmin' &&
                  <div className="panel-body row" style={{ display: 'flex', alignItems: 'flex-start',
                    justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "left" }}>
                      <label className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].customColors}</label>
                      <ul className="row">
                        {colorSets}
                      </ul>
                    </div>
                  </div>
                }
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-row-buttons-action welcome">
                    <button onClick={this.updateCompanyColors} className="btn px-btn lg btn-blue-base"
                            style={{ backgroundColor: colors.color1[3] }}>{i18n[language].save}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showModal &&
            <MAModalImage objectId={company.id}
                          url={avatar}
                          handleToggle={this.updateShowModal}
                          updateAvatar={this.updateAvatar}
                          language={language}
                          colors={colors}/>
        }
      </div>
    )
  }

  updateCompanyColors = () => {
    if(this.props.company) {
      $.ajax({
        url: '/companies/update_colors/' + this.props.company.id,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          color_set_id: this.props.activeColorSetId
        },
        beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
        success: () => {
          this.saveProfile();
        }
      });
    } else {
      this.saveProfile();
    }
  };

  saveProfile = () => {
    $.ajax({
      url: '/profiles/update/' + this.props.user_account.profile.id,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        profile: {
          is_help: this.state.is_help
        }
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
        if(!this.state.is_help){
          Beacon('destroy');
        }
        showSuccessMsg('Success!');
      }
    });
  };

  updateAvatar = blob => {
    const reader  = new FileReader();

    reader.onloadend = function () {
      const div = document.getElementById("divImgSubdomain");
      console.log(div);
      $('#imgLogo').attr('src', reader.result);

      if(div === null){
        //console.log('NEW LOGO ADD DIV');
        $('#navMain').attr('class', 'navbar navbar-default px-nav-base subdomain');
        $('#logo-navbar').remove();
        $('#divTMRightMenu').append("<div id='divImgSubdomain' class='px-container-img-subdomain'>" +
          "<img id='imgCompanyLogo' alt='' src=''/></div>");
        $('#imgCompanyLogo').attr('src', reader.result);
      } else {
        console.log('JUST UPDATE LOGO');
        $('#imgCompanyLogo').attr('src', reader.result);
      }
    };

    reader.readAsDataURL(blob);
  };

  updateShowModal = () => {
    this.setState(prevState => ({showModal: !prevState.showModal}));
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    //console.log(name, value);
    this.setState({[name]: value});
  };

  // saveColors = () => {
  //   if(this.props.company){
  //     $.ajax({
  //       url: '/companies/update_colors/' + this.props.company.id,
  //       method: 'PATCH',
  //       dataType: 'JSON',
  //       data: {
  //         colors: this.state.colors
  //       },
  //       beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
  //       success: () => {
  //         this.saveProfile();
  //       }
  //     });
  //   } else {
  //     this.saveProfile();
  //   }
  // };

  // updateColor = (color, index) => {
  //   //console.log(color, index);
  //   const colors = this.state.colors.map((e, i) => i === index ? color.hex : e);
  //   //console.log(colors);
  //   this.setState({colors})
  // };

  // handleDisplayColorPicker = (name) => {
  //   this.setState(prevState => ({[name]: !prevState[name]}));
  // };
}