import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";
import Select from "react-select";
import InputMask from "react-input-mask";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dotdotdot from "react-dotdotdot";
import Truncate from "react-truncate";
import {usernamesToDisplay} from "../shared_utils";

export default class UL_Modal extends React.Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    actionUser: PropTypes.func.isRequired,
    button: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    segments: PropTypes.array.isRequired,
    updateValidRemove: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      first_name: props.user.first_name,
      last_name: props.user.last_name,
      gender: props.user.gender,
      dob: props.user.dob,
      country: props.user.country,
      state_country: props.user.state_country,
      userSegments:props.user.segments,
      role_2: props.user.role_2,
      confirmEmail: '',
      removeFlag: false,
      alias: props.user.user_alias.substr(1),
      blockFlag: false,
      removeFlagC1: false,
      removeFlagC2: false
    }
  }

  selectCountry (val) {
    this.setState({country: val});
  }

  selectRegion (val) {
    this.setState({state_country: val});
  }

  segmentChange = (val) => {
    this.setState({userSegments: val});
  };

  render() {
    const {user, action, language, colors} = this.props;
    const usernameToShow = usernamesToDisplay(this.props.user)[0];

    const i18n = {
      en: {
        editInfo: 'Edit', editInvitation: 'Edit Invitation', gender: 'Gender', country: 'Country', region: 'Region',
        segments: 'Segments', role: 'role', save: 'Save', saveDraft: 'Save as Draft', sendInvitation: 'Send Invite',
        close: 'Close', cancel: 'Cancel', resendBtn: 'Resend', resend: 'Resend invitation to',
        resendDisclaimer: 'The email invitation will contain the current default subject and content. To change it go to Community Settings.',
        block: 'Block', unblock: 'Unblock', 
        blockDisclaimer: 'won’t be able to access this community while blocked and will be notified.',
        unblockDisclaimer: 'will regain access to this community and will be notified.',
        undone: 'This action cannot be undone',
        removeDisclaimer1: 'won’t be able to access this community anymore and will be notified.',
        removeDisclaimer2: 'All data from this user will be deleted (e.g. responses, comments, etc.). It won’t be visible anymore to collaborators or participants.',
        moderator: 'Moderator', recruiter: 'Recruiter', observer: 'Observer', selectGender: 'Select Gender', female: 'Female',
        male: 'Male', other: 'Other', remove: 'Remove', type: 'Type ', toConfirm: ' to confirm', invite: 'Invite', areYouSure: 'Are you sure?',
        for: 'for'
      },
      es: {
        editInfo: 'Editar', editInvitation: 'Editar Invitación', gender: 'Género', country: 'País', region: 'Región',
        segments: 'Segmentos', role: 'función', save: 'Guardar', saveDraft: 'Guardar borrador', sendInvitation: 'Enviar invitación',
        close: 'Cerrar', cancel: 'Cancelar', resendBtn: 'Reenviar', resend: 'Reenviar invitación a',
        resendDisclaimer: 'La invitación se enviará con el contenido preestablecido en los ajustes de la comunidad.',
        block: 'Bloquear', unblock: 'Desbloqear',
        blockDisclaimer: 'no podrá acceder a esta comunidad mientras esté bloqueado y será notificado.',
        unblockDisclaimer: 'podrá acceder de nuevo a esta comunidad y será notificado.',
        undone: 'Esta acción es irreversible',
        removeDisclaimer1: 'no podrá acceder más a esta comunidad y será notificado.',
        removeDisclaimer2: 'Toda la información de este usuario será eliminada (e.g. respuestas, comentarios, etc.).',
        moderator: 'Moderador', recruiter: 'Reclutador', observer: 'Observador', selectGender: 'Seleccionar género', female: 'Femenino',
        male: 'Masculino', other: 'Otro', remove: 'Remover', type: 'Escribe ', toConfirm: ' para confirmar', invite: 'Invitar', areYouSure: '¿Estás seguro?', 
        a: ' a', for: 'de'
      }
    };

    let question = null;
    let button = null;
    let titleActions= null;

    switch(action){
      case 'delete':
        titleActions = <p className="px-title-warning text-center">{i18n[language].areYouSure} <strong>{i18n[language].remove} {i18n[language].a}</strong>
          &nbsp;<b className="warning">{usernameToShow}</b></p>;
        button = i18n[language].remove;
        break;
      case 'send':
        titleActions = <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <p className="px-title-warning text-center">
            <Truncate lines={1}>{i18n[language].invite}</Truncate><b className="warning"><Truncate lines={1}>&nbsp;{usernameToShow}?</Truncate></b>
          </p>
          <label className="px-message-warning mg-bottom-0 text-center">
            {i18n[language].resendDisclaimer}
          </label>
        </div>;
        question = "";
        button = 'Send Invite';
        break;
      case 'reSend':
        titleActions = <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <p className="px-title-warning text-center">
            <Truncate lines={1}>{i18n[language].resend}</Truncate><b className="warning">
            <Truncate lines={1}>&nbsp;{usernameToShow}?</Truncate></b>
          </p>
          <label className="px-message-warning mg-bottom-0 text-center">
            {i18n[language].resendDisclaimer}
          </label>
        </div>;
        question = "";
        button = i18n[language].resendBtn;
        break;
      case 'block':
        titleActions = <p className="px-title-warning text-center">
          <Truncate lines={1}>{i18n[language].block}</Truncate><b className="warning" style={{color: colors.color4[3]}}>&nbsp;{usernameToShow}</b>
        </p>;
        question = <div>
          <div className="px-check-cont px-checkbox-modal-warning">
            <label className="container-check">   
              <input name="blockFlag" type="checkbox" value={this.state.blockFlag} onChange={this.handleChange}/>
              <span className="checkmark"/>
              <span><b>{usernameToShow}</b> {i18n[language].blockDisclaimer}</span>
            </label>
          </div>
        </div>;
        button = i18n[language].block;
        break;
      case 'unblock':
        titleActions = <p className="px-title-warning text-center">
          <Truncate lines={1}>{i18n[language].unblock}</Truncate><b className="warning" style={{ color: colors.color4[3] }}><Truncate lines={1}>&nbsp;{usernameToShow} ?</Truncate></b>
        </p>;
        question = <div>
          <div className="px-check-cont px-checkbox-modal-warning">
            <label className="container-check">   
              <input name="blockFlag" type="checkbox" value={this.state.blockFlag} onChange={this.handleChange}/>
              <span className="checkmark"/>
              <span><b>{usernameToShow}</b> {i18n[language].unblockDisclaimer}</span>
            </label>
          </div>
        </div>;
        button = i18n[language].unblock;
        break;
      case 'edit':
        button = i18n[language].save;
        break;
      case 'remove':
        button = i18n[language].remove;
        break;
    }

    let modalTitle = "";
    let modalBody = <div className="modal-body px-modal-body-warning">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="px-title-warning-container">
            <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-warning" style={{ color: colors.color4[3] }}/>
            { titleActions }
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1" style={{padding:'0'}}>
              { question }
            </div>
          </div>
        </div>
      </div>
    </div>;

    let role2Edit;
    let colSize = 'col-md-12';
    let segmentsEdit;
    let alias = null;

    if(user.role_2){
      switch(this.props.role_2){
        case 'Owner':
          role2Edit = <div className="col-md-6">
            <div className="form-group first">
              <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].role}</label>
              <select name="role_2" onChange={this.handleEditInvitation} value={this.state.role_2} className="form-control">
                <option value="Moderator">{i18n[language].moderator}</option>
                <option value="Observer">{i18n[language].observer}</option>
                <option value="Recruiter">{i18n[language].recruiter}</option>
              </select>
            </div>
          </div>;
          break;
        case 'Moderator':
          role2Edit = <div className="col-md-6">
            <div className="form-group first">
              <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].role}</label>
              <select name="role_2" onChange={this.handleEditInvitation} value={this.state.role_2} className="form-control">
                <option value="Observer">{i18n[language].observer}</option>
                <option value="Recruiter">{i18n[language].recruiter}</option>
              </select>
            </div>
          </div>;
          break;
      }
      colSize = 'col-md-6';
    } else {
      segmentsEdit = <div className="col-md-6">
        <div className="form-group">
          <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].segments}</label>
          <Select isMulti={true}
                  value={this.state.userSegments}
                  options={this.props.segments.map(segment => ({label: segment.name, value: segment.id}))}
                  onChange={this.segmentChange}
                  className="form-control"/>
        </div>
      </div>;
      alias = <div className="col-md-6">
        <div className="form-group">
          <label className="label-xs" style={{ color: colors.color1[2] }}>Alias</label>
          <input name="alias" type="text" value={this.state.alias} onChange={this.handleEditInvitation}
                 className="form-control"/>
        </div>
      </div>
    }

    let dateOfBirth = <InputMask name="dob" mask="99/99/9999" maskChar=" " onChange={this.handleEditInvitation}
                                 className="form-control" placeholder="dd/mm/yyyy"/>;
    //console.log('props.user.dob:', this.props.user.dob, this.state.dob);
    if(this.props.user.state !== 'Invited'){
      // To improve!
      if(this.props.user.dob){
        const dob = this.state.dob;
        if(dob != null){
          const dobA = dob.split('-');
          dateOfBirth = <input defaultValue={dobA[2] + '/' + dobA[1] + '/' + dobA[0]} className="form-control"
                               disabled={true}/>
        }
      }
    } else {
      if(this.state.dob){
        const dob = this.state.dob;
        const dobA = dob.split('-');
        dateOfBirth = <InputMask name="dob" mask="99/99/9999" maskChar=" " onChange={this.handleEditInvitation}
                                 className="form-control" placeholder="dd/mm/yyyy"
                                 value={dobA[2] + '/' + dobA[1] + '/' + dobA[0]}/>;
      } else {
        dateOfBirth = <InputMask name="dob" mask="99/99/9999" maskChar=" " onChange={this.handleEditInvitation}
                                 className="form-control" placeholder="dd/mm/yyyy" value=""/>;
      }

    }

    let countryList = <CountryDropdown value={this.state.country}
                                       onChange={(val) => this.selectCountry(val)}
                                       valueType="short"
                                       classes="form-control"/>;
    let regionList = <RegionDropdown country={this.state.country}
                                     value={this.state.state_country}
                                     onChange={(val) => this.selectRegion(val)}
                                     countryValueType="short"
                                     classes="form-control"/>;
    if(this.props.user.country && this.props.user.userState !== 'Invited') {
      //TO review if userState is the current user state
      countryList = <input value={this.props.user.country} className="form-control" disabled={true}/>;
    }
    if(this.props.user.state_country && this.props.user.userState !== 'Invited'){
      regionList = <input value={this.props.user.state_country} className="form-control" disabled={true}/>;
    }

    if(this.props.action === 'edit'){
      modalTitle = <h4 className="px-modal-title">
        {user.state === 'Enabled' ? `${i18n[language].editInfo} ${usernameToShow}` :
          i18n[language].editInvitation}</h4>;
      modalBody = <div className="modal-body px-modal-body-add-activity">

        {user.cu_invitation_method !== 'generic_link' && <div className="row">
          <div className={colSize}>
            <div className="form-group first">
              <label className="label-xs" style={{color: colors.color1[2]}}>Email</label>
              <input name="email" type="text" defaultValue={user.email} className="form-control" disabled={true}/>
            </div>
          </div>
          {role2Edit}
        </div>}

        {/*<div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-xs">First Name</label>
              <input name="first_name" type="text" onChange={this.handleEditInvitation} value={this.state.first_name}
                    className="form-control" disabled={this._validateDisabled(user.first_name)}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-xs">Last Name</label>
              <input name="last_name" type="text" onChange={this.handleEditInvitation} value={this.state.last_name}
                    className="form-control" disabled={this._validateDisabled(user.last_name)}/>
            </div>
          </div>
        </div>*/}

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].gender}</label>
              <select name="gender" onChange={this.handleEditInvitation} value={this.state.gender}
                      className="form-control" disabled={this._validateDisabled(user.gender)}>
                <option value="">{i18n[language].selectGender}</option>
                <option value="Female">{i18n[language].female}</option>
                <option value="Male">{i18n[language].male}</option>
                <option value="Other">{i18n[language].other}</option>
              </select>
            </div>
          </div>
          {/*<div className="col-md-6">
            <div className="form-group">
              <label className="label-xs">Date of Birth</label>
              { dateOfBirth }
            </div>
          </div>*/}
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].country}</label>
              { countryList }
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].region}</label>
              { regionList }
            </div>
          </div>
        </div>

        <div className="row">
          { segmentsEdit }
          { alias }
        </div>

      </div>;
    }

    if(this.props.action === 'remove'){
      let shortened_username = user.username.length < 6 ? user.username : user.username.slice(0, 6);
      modalTitle = "";
      modalBody = <div className="modal-body px-modal-body-warning">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
            <div className="px-title-warning-container">
              <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-warning" style={{ color: colors.color4[3] }}/>
              <p className="px-title-warning text-center" style={{width: '100%'}}>
                <Truncate lines={1}>{i18n[language].remove}</Truncate><b className="warning" style={{ color: colors.color4[3] }}><Truncate lines={1}>&nbsp;{usernameToShow}</Truncate></b>
              </p>
              <label className="px-message-warning">{i18n[language].undone}</label>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1" style={{padding:'0'}}>
                <div className="px-check-cont px-checkbox-modal-warning">
                  <label className="container-check">
                    <input type="checkbox" name="removeFlagC1" onChange={this.handleChange} value={this.state.removeFlagC1}/>
                    <span className="checkmark"/>
                    <span><b>{usernameToShow}</b> {i18n[language].removeDisclaimer1}</span>
                  </label>
                </div>
                <div className="px-check-cont px-checkbox-modal-warning">
                  <label className="container-check">
                    <input type="checkbox" name="removeFlagC2" onChange={this.handleChange} value={this.state.removeFlagC2}/>
                    <span className="checkmark"/>
                    <span>{i18n[language].removeDisclaimer2}</span>
                  </label>
                </div>
                <input name="confirmEmail" type="text" onChange={this.handleEditInvitation}
                       className="form-control px-input-confirmation"
                  placeholder={`${i18n[language].type} "delete" ${i18n[language].toConfirm}`}/>
              </div>
            </div>
          </div>
        </div>
      </div>;
    }

    return (
      <ReactModal isOpen={this.props.showModal} contentLabel="Actions Invitee" shouldCloseOnOverlayClick={true}
                  className="px-modal-content" overlayClassName="px-modal-overlay">
        <div className="modal-dialog quick-actions">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              { modalTitle }
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                      onClick={this.props.handleCloseModal} data-dismiss="modal" aria-hidden="true">
                <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
              </button>
            </div>
            { modalBody }
            <div className="modal-footer px-modal-footer-warning">
              <div className="px-footer-container-buttons column">
                {
                  action !== 'remove' ?
                    <button onClick={action !== 'edit' ? this.props.actionUser : this.setUserTU} type="button"
                            className="btn px-btn sm btn-blue-base"
                            style={{ backgroundColor: colors.color1[3] }}
                            disabled={(action === 'delete' || action === 'send' || action === 'edit' || action === 'reSend') ?
                              false : !this.state.blockFlag}>{button}
                    </button> :
                    <button onClick={this.props.actionUser} type="button" className="btn px-btn sm btn-blue-base"
                             style={{ backgroundColor: colors.color4[3] }}
                            disabled={(!this.state.removeFlagC1 || !this.state.removeFlagC2) || !this.state.removeFlag}>
                      {button}
                    </button>
                }
                <button onClick={this.props.handleCloseModal} type="button"
                  className="btn px-btn sm btn-gray-darker inverted">{i18n[language].cancel}</button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    //console.log(name, value);
    this.setState({[name]: value});
  }

  handleEditInvitation = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value}, () => {
      if(name === 'confirmEmail'){
        const usernameHandle = this.props.user.username;
        let condition = this.state.confirmEmail === 'delete'

        // if (usernameHandle.length < 6) {
        //   condition = this.state.confirmEmail === usernameHandle;
        // } else {
        //   condition = this.state.confirmEmail.slice(0, 6) === usernameHandle.slice(0, 6);
        // }

        this.setState({ removeFlag: condition }, () => {
          if (this.state.removeFlag) {
            this.props.updateValidRemove(this.state.removeFlag);
          }
        });
      }
    });
  };

  setUserTU = () => {
    this.props.editUser({
      user_id: this.props.user.user_id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      gender: this.state.gender,
      dob: this.state.dob,
      userSegments: this.state.userSegments,
      role_2: this.state.role_2,
      country: this.state.country,
      state_country: this.state.state_country,
      alias: this.state.alias
    });
  };

  _validateDisabled(value){
    let flag = false;
    if(this.props.user.userState !== 'Invited'){
      flag = value !== '';
    }

    return flag;
  }
}
