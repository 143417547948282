import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class CSSocial extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    role_2: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    saveCommunity: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {community, role_2, language, colors} = this.props;

    const I18n = {
      en: {
        save: 'Save', socialCmnty: 'Social Community', socialCmntyInfo: 'When "ON" participants have access to other participants\' responses.',
        showList: 'Show Participant List', showListInfo: 'Participants can still see an anonymised list of other participants even when Social Mode is off'
      },
      es: {
        save: 'Guardar', socialCmnty: 'Comunidad Social', socialCmntyInfo: 'Cuando está activo este modo, los participantes tendrán acceso a las respuestas de otros participantes.',
        showList: 'Mostrar lista de participantes', showListInfo: 'Cuando está activado los participantes podrán ver una lista anonimizada de otros participantes, aún cuando el Modo Social está desactivado.'
      }
    };

    return (
      <div id="divSocial" className="manage" style={{display:'none'}}>
        <div className="px-settings-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-card px-card-settings">
                {/* <div className="px-icon-settings">
                  <FontAwesomeIcon icon={['fas','users']}/>
                </div> */}
                <div className="panel-body social-settings">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="px-waring-item-container">
                        <label className="label-sm" style={{color: colors.color1[2]}}>{I18n[language].socialCmnty}</label>
                        <label className="px-switch" htmlFor="socialCommunityFlag">
                          <input name="social_flag" onChange={this.props.handleChange} id="socialCommunityFlag"
                                  checked={community.social_flag} disabled={role_2 !== 'Owner'} type="checkbox"/>
                          <div className="slider round" style={community.social_flag ? {backgroundColor: colors.color3[3]} : {}}/>
                        </label>
                        <div className="px-alert-item mg-top-15">
                          <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-alert" style={{color: colors.color4[3]}}/>
                          <p className="px-label-warning text-left" style={{ color: colors.color4[3] }}>{I18n[language].socialCmntyInfo}</p>
                        </div>
                      </div>
                      {
                        !community.social_flag &&
                        <div className="px-waring-item-container mg-top-30">
                          <label className="label-sm" style={{textAlign: 'center', color: colors.color1[2]}}>{I18n[language].showList}</label>
                          <label className="px-switch" htmlFor="electivePrivacyFlag">
                            <input name="show_part_list" onChange={this.props.handleChange} id="electivePrivacyFlag"
                                    type="checkbox" checked={community.show_part_list} disabled={role_2 !== 'Owner'}/>
                            <div className="slider round" style={community.show_part_list ? { backgroundColor: colors.color3[3] } : {}}/>
                          </label>
                          <div className="px-alert-item mg-top-15">
                            <p className="px-label-information" style={{ color: colors.color2[3] }}>
                              {I18n[language].showListInfo}
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action welcome">
                      <button onClick={this.props.saveCommunity} className="btn px-btn lg btn-blue-base" style={{backgroundColor: colors.color1[3]}}>{I18n[language].save}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="modal fade" id="modal-id-show-part-list">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header modal-delete-activity">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                      <i className="material-icons" style={{color: '#333'}}>clear</i>
                    </button>
                    <h4 className="modal-title">Participant List</h4>
                  </div>

                  <div className="modal-body body-delete-activity">
                    <label className="delete-subtitle" style={{marginBottom:'20px'}}>Participants</label>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                      ut aliquip ex ea commodo consequat.
                    </p>
                  </div>

                  <div className="separator"/>
                  <div className="modal-footer" style={{padding:'20px 20px',backgroundColor:'transparent'}}>
                    <button data-dismiss="modal" type="button" className="btn btn-danger btn-simple">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
