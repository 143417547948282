import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroller";

import DMHeader from "./DMHeader";
import DMUser from "./DMUser";
import DMActivity from "./DMActivity";
import Loading from "../../../UI/Globals/Loading";

export default class DMMatrix extends React.Component {
  static propTypes = {
    activeUsers: PropTypes.array.isRequired,
    activities: PropTypes.array.isRequired,
    user_account: PropTypes.object.isRequired,
    columnHeaderActions: PropTypes.func.isRequired,
    userActions: PropTypes.func.isRequired,
    activityActions: PropTypes.func.isRequired,
    viewProfile: PropTypes.func.isRequired,
    updateTaskHeaderMenu: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    isLoadingUnseen: PropTypes.bool.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    clearForwardUrl: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    updateOpenPMFromOutside: PropTypes.func.isRequired,
    communityId: PropTypes.number.isRequired,
    colors: PropTypes.object.isRequired,
    hasMoreUsers: PropTypes.bool.isRequired,
    getResponses: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      colsMin: [],
      zIndex: null,
      indexY: null
    };
  }

  render() {
    const {activeUsers, activities, user_account, isLoadingUnseen, gender_as_segment, language,
      communityId, colors, hasMoreUsers} = this.props;

    const i18n = {
      en: {
        updatingAlerts: 'Updating alerts', activitiesNA: 'No live activities yet',
        usersNA: 'No active participants yet', dataNA: 'No activity in the community yet',
        menu: 'Click for more options'
      },
      es: {
        updatingAlerts: 'Actualizando alertas', activitiesNA: 'No hay actividades publicadas',
        usersNA: 'No hay participantes activos', dataNA: 'No hay actividad aún en la comunidad',
        menu: 'Clic para más opciones'
      }
    }

    const resultUsers = [];
    const resultActs = [];
    activeUsers.forEach(user => resultUsers.push(user.isVisMat));
    activities.forEach(task => resultActs.push(task.isVisMat));
    const usersFlag = resultUsers.filter(user => user === false).length === activeUsers.length;
    const tasksFlag = resultActs.filter(act => act === false).length === activities.length;

    const rowUsers = [...activeUsers];
    rowUsers.unshift({});
    const cellActivities = [...activities];
    cellActivities.unshift({});

    const now = moment().format('YYYY-MM-DD');
    let rowsCounter = 0;

    const header = cellActivities.map((activity, indexX) => {
      let available = true;

      if(activity.start){
        const startDate = moment(activity.start).format('YYYY-MM-DD');
        if(now < startDate){
          available = false;
        }
      }

      if(indexX === 0){
        return(
          <th key={indexX} className="px-mat-cell" style={{backgroundColor:'#fff'}}>
            <div className="px-header-container">
              <div className="upper-container">
                <div className="icons-container" style={{position:'relative', marginRight: '10px'}}>
                  {
                    isLoadingUnseen &&
                    <div className="px-empty-msg" style={{marginTop:'20px', marginLeft: '25px', color: colors.color4[3]}}>
                      {i18n[language].updatingAlerts} <FontAwesomeIcon icon={['fas','spinner']} spin/>
                    </div>
                  }
                  <div onClick={(event) => this.props.updateTaskHeaderMenu(-1, '.ddEllipsis', event)}
                       className="ellipsis-icon" style={{cursor:'pointer'}} data-tooltip-content={i18n[language].menu}>
                    <FontAwesomeIcon style={{fontSize: '24px'}} icon={['fas','circle-plus']}/>
                  </div>
                </div>
              </div>
            </div>
          </th>
        )
      } else {
        if(activity.isVisMat){
          return (
            <th key={indexX} className="px-mat-cell" style={{backgroundColor:'#fff'}}>
              <DMHeader activity={activity}
                        isMin={this._getIsMin(this.state.colsMin, indexX)}
                        role_2={user_account.role_2}
                        columnHeaderActions={this.props.columnHeaderActions}
                        available={available}
                        x={indexX}
                        updateColsX={this.updateColsX}
                        updateTaskHeaderMenu={this.props.updateTaskHeaderMenu}
                        language={language}
                        colors={this.props.colors}/>
            </th>
          )
        }
      }
    });

    const rows = rowUsers.map((user, indexY) => {
      const cells = cellActivities.map((activity, indexX) => {
        if(indexY === 0){
          if(indexX === 0) {} else {}
        } else {
          if(indexX === 0){
            if(user.isVisMat){
              rowsCounter++;

              return(
                <th key={indexX} className="px-mat-cell" style={{backgroundColor: rowsCounter % 2 ? '#f6f6f6' : '#fff',
                  zIndex: this.state.indexY === indexY && this.state.zIndex}}>
                  <DMUser user={user}
                          role_1={user_account.role_1}
                          role_2={user_account.role_2}
                          userActions={this.props.userActions}
                          handleZIndex={this.handleZIndex}
                          indexY={indexY}
                          viewProfile={this.props.viewProfile}
                          updateShowModal={this.props.updateShowModal}
                          language={language}
                          updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                          current_user_id={user_account.id}
                          communityId={communityId}
                          colors={user_account.all_colors}/>
                </th>
              )
            }
          } else {
            // TO-DO Try to pass this one and the validation for segments in Activities, Participants to a function,
            // Maybe the same une in TRNoResponse
            let segsFlag = true;
            if(activity.segments.length !== 0){
              segsFlag = user.segments.find(us => activity.segments.find(as => as.id === us.id)) !== undefined;
              if(gender_as_segment && !segsFlag){
                //TO-DO In back server, the value of community segment 'Other Gender' must be just 'Other' as DB
                const activitySegs = activity.segments.map(s => s.id === 'Other Gender' ? {...s, id: 'Other'} : s);
                segsFlag = activitySegs.find(as => as.id === user.gender) !== undefined;
              }
            }

            if(activity.isVisMat && user.isVisMat ){
              return (
                <td key={indexX} className="px-mat-cell">
                  {
                    segsFlag &&
                    <DMActivity user={user}
                                activity={activity}
                                isMin={this._getIsMin(this.state.colsMin, indexX)}
                                role_2={user_account.role_2}
                                indexY={indexY}
                                activityActions={this.props.activityActions}
                                handleZIndex={this.handleZIndex}
                                updateShowModal={this.props.updateShowModal}
                                clearForwardUrl={this.props.clearForwardUrl}
                                language={language}
                                colors={user_account.all_colors}/>
                  }
                </td>
              )
            }
          }
        }
      });

      return(
        <tr key={indexY} style={{backgroundColor: rowsCounter % 2 ? '#f6f6f6' : '#fff'}}>
          { cells }
        </tr>
      )
    });

    return (
      <div className="px-m-div" style={{maxHeight: '540px'}}>
        {
          !tasksFlag || !usersFlag ?
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={() => this.props.getResponses(false)}
              hasMore={hasMoreUsers}
              useWindow={false}
              threshold={100}
              loader={<Loading key={0}/>}>
              <table className="px-menu-table">
                <thead className="px-m-thead">
                {
                  !tasksFlag ?
                    <tr>{header}</tr> :
                    <tr>
                      <th className="px-mat-cell" style={{backgroundColor:'#fff'}}>&nbsp;</th>
                      <th colSpan={2} style={{padding:'10px'}}>
                        <div className="px-empty-msg" style={{color: colors.color4[3]}}>{i18n[language].activitiesNA}</div>
                      </th>
                    </tr>
                }
                </thead>
                <tbody className="px-m-tbody">
                {
                  !usersFlag ?
                    rows :
                    <tr>
                      <td style={{minWidth:'239px'}}>
                        <div className="px-empty-msg" style={{color: colors.color4[3]}}>{i18n[language].usersNA}</div>
                      </td>
                    </tr>
                }
                </tbody>
              </table>
            </InfiniteScroll> :
            <div className="px-empty-msg" style={{color: colors.color4[3]}}>{i18n[language].dataNA}</div>
        }
      </div>
    );
  }

  updateColsX = (cellX) => {
    const colsMin = [...this.state.colsMin];
    const isFound = colsMin.find(y => y === cellX);

    this.setState({colsMin: isFound === undefined ? colsMin.concat(cellX) : colsMin.filter(y => y !== cellX)});
  };

  handleZIndex = (type, indexY) => {
    this.setState({zIndex: type === 'show' ? 3 : 1, indexY});
  };

  _getIsMin(colsMin, indexX){
    let result = false;

    if(colsMin.length !== 0){
      result = colsMin.filter(y => y === indexX).length !== 0
    }

    return result;
  }
}
