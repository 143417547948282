import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class DMConfirmation extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    columnHeaderActions: PropTypes.func,
    activityActions: PropTypes.func,
    userActions: PropTypes.func,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { from, title, language} = this.props;
    const I18n = {
      en: {
        yes: 'Yes', sure: 'Are you sure?'
      },
      es: {
        yes: 'Sí', sure: 'Estás seguro?'
      }
    }

    let btnYes = null;
    switch (from) {
      case 'fasterHeader':
        btnYes = <button onClick={() => this.props.columnHeaderActions('faster')} type="button"
                         className="btn px-btn sm btn-blue-base">{I18n[language].yes}</button>;
        break;
      case 'allCompletedHeader':
        btnYes = <button onClick={() => this.props.columnHeaderActions('accept all completed')} type="button"
                          className="btn px-btn sm btn-blue-base">{I18n[language].yes}</button>;
        break;
      case 'fasterCell':
        btnYes = <button onClick={() => this.props.activityActions('faster')} type="button"
                          className="btn px-btn sm btn-blue-base">{I18n[language].yes}</button>;
        break;
      case 'allCompletedCell':
        btnYes = <button onClick={() => this.props.activityActions('accept all completed')} type="button"
                        className="btn px-btn sm btn-blue-base">{I18n[language].yes}</button>;
        break;
      case 'fasterUser':
        btnYes = <button onClick={() => this.props.userActions('faster')} type="button"
                        className="btn px-btn sm btn-blue-base">{I18n[language].yes}</button>;
        break;
      case 'allCompletedUser':
        btnYes = <button onClick={() => this.props.userActions('accept all completed')} type="button"
                        className="btn px-btn sm btn-blue-base">{I18n[language].yes}</button>;
        break;
    }

    return (
      <div className="modal-content">
        <div className="modal-header modal-delete-activity">
          <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
            <i onClick={() => this.props.updateShowModal(null, null)} className="material-icons">clear</i>
          </button>
        </div>

        <div className="modal-body px-modal-body-warning">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
              <div className="px-title-warning-container">
                <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-warning" />
                <p className="px-title-warning">
                  {title}
                </p>
                <label className="px-message-warning">{I18n[language].sure}</label>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer px-modal-footer-warning">
          <div className="px-footer-container-buttons column">
            {btnYes}
            <button onClick={() => this.props.updateShowModal(null, null)} type="button"
                    className="btn px-btn sm btn-gray-darker inverted">No</button>
          </div>
        </div>
      </div>
    );
  }
}