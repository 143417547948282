import PropTypes from 'prop-types';
import React from 'react';
import { Editor } from "react-draft-wysiwyg";
import embed from "embed-video";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ParticipantEditor extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    editorName: PropTypes.string.isRequired,
    editorState: PropTypes.object.isRequired,
    onEditorStateChange: PropTypes.func.isRequired,
    questionId: PropTypes.number.isRequired,
    minChar: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    colors: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      editorState: props.editorState,
      percentage: props.percentage,
      characterCount: null
    }
  }

  componentDidMount() {
    const characters = this.countCharacters();
    const percentage = (100 * characters) / this.props.minChar;
    this.setState({
      percentage: percentage >= 100 ? 100 : percentage,
      characterCount: characters
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.editorState !== this.props.editorState){
      this.setState({editorState: this.props.editorState})
    }
  }

  render() {
    const i18n = {
      en: {characters: 'characters', of: 'of'},
      es: {characters: 'caracteres', of: 'de'}
    }
    const barWidth = this.state.percentage + '%';
    const barBGC = this.state.percentage > 80 ? this.props.colors.colorStates[0] : this.props.colors.color4[3];
    let percentageBar = <div className="my-progress-bar">
      <div className="my-progress-bar-color" style={{ width: barWidth, backgroundColor: barBGC }} />
    </div>;
    let characterCountLegend = <span style={{position: 'absolute', right: 0, color: '#AFBDC1', }}>{`${this.state.characterCount} ${i18n[this.props.language].of} ${this.props.minChar} ${i18n[this.props.language].characters}`}</span>;

    return (
      <div className="px-oe-editor" style={{position: 'relative', marginBottom: this.props.minChar > 0 ? '15px' : '0'}}>
        <Editor editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                  options: ['inline', 'emoji', 'embedded'],
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                    bold: { className: 'bordered-option-classname' },
                    italic: { className: 'bordered-option-classname' },
                    underline: { className: 'bordered-option-classname' },
                  },
                  emoji: {
                    icon: '/assets/custom_emoji.png'
                  },
                  embedded: {
                    icon: '/assets/you_tube.png',
                    popupClassName: 'px-embed-video-popup',
                    embedCallback: this.produceEmbedUrl,
                    defaultSize: {
                      height: 'auto',
                      width: 'auto',
                    },
                  }
                }}
                placeholder={'Type text here'}
                editorClassName="px-editor-tv-oe"
                onBlur={this.onBlur}
                stripPastedStyles={true}/>
        { this.props.minChar > 0 && percentageBar }
        { this.props.minChar > 0 && characterCountLegend }
      </div>
    );
  }

  countCharacters = () => {
    const body = this.state.editorState.getCurrentContent().getPlainText();
    return body.length
  }

  produceEmbedUrl = (link) => {
    const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
    return (detectedSrc && detectedSrc[1]) || link;
  }

  onEditorStateChange = editorState => {
    const characters = this.countCharacters();
    const percentage = (100 * characters) / this.props.minChar;
    this.setState({editorState, characterCount: characters, percentage: percentage >= 100 ? 100 : percentage});
  };

  onBlur = () => {
    const {questionId} = this.props;
    this.props.onEditorStateChange(this.state.editorState, questionId, this.state.percentage);
  };
}
