import PropTypes from 'prop-types';
import React from 'react';
import axios from "axios";
import ReactModal from "react-modal";
import moment from 'moment';

import DMenu from "../../components/Community/Dashboards/DMenu";
import DActivities from "../../components/Community/Dashboards/DActivities";
import DParticipants from "../../components/Community/Dashboards/DParticipants";
import DMatrix from "../../components/Community/Dashboards/DMatrix";
import DNow from "../../components/Community/Dashboards/DNow";
import SelectModal from "../../components/UI/Modals/SelectModal";
import DMConfirmation from "../../components/Community/Dashboards/Matrix/DMConfirmation";
import Loading from "../../components/UI/Globals/Loading";
import DLegends from "../../components/Community/Dashboards/DLegends";
import DMLegend from "../../components/Community/Dashboards/DMLegend";
import {
  showSuccessMsg,
  getIsTaskAlert,
  sendRandomCommentMatrix,
  getRandomFasterCommentMatrix
} from "../../components/UI/Globals/PX_Funs";
import MediaQuery from "react-responsive";

export default class Dashboards extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    activeLink: PropTypes.string.isRequired,
    updateActiveLink: PropTypes.func.isRequired,
    user_account: PropTypes.object.isRequired,
    activities: PropTypes.array.isRequired,
    firstTimeCallAlerts: PropTypes.bool.isRequired,
    firstTimeNow: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    updateOpenPMFromOutside: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    // If something goes wrong in Production with the fileStorage then clear the whole localStorage
    // Maybe, add an icon in order the users and reset their fileStorage
    // Maybe is good to clear the localStorage when a log out is executed
    //localStorage.clear();
    let activities;
    let objSearchAct;
    let objSearchPar;
    let objSearchMat;
    let nowFilter = 'All';
    let objSearchNow;
    const keyIds = '_' + this.props.user_account.id + '_' + this.props.community.id;

    if(localStorage.getItem('activities' + keyIds)){
      //console.log('localStorage.getItem(\'activities\' + keyIds):', JSON.parse(localStorage.getItem('activities' + keyIds)));
      if(Array.isArray(JSON.parse(localStorage.getItem('activities' + keyIds)))){
        if(JSON.parse(localStorage.getItem('activities' + keyIds)).length !== 0){
          const activitiesLocalStorage = JSON.parse(localStorage.getItem('activities' + keyIds));
          const activitiesDBInit = this._initActivities(props.activities);

          activities = props.activities.map(activityDB => {
            //console.log('activityDB:', activityDB);
            const activityLS = activitiesLocalStorage.find(a => a.id === activityDB.id);

            if(activityLS !== undefined){
              return {...activityLS, ...activityDB};
            } else {
              return activitiesDBInit.find(actInit => actInit.id === activityDB.id)
            }
          });
        } else {
          activities = this._initActivities(props.activities);
        }
      } else {
        activities = this._initActivities(props.activities);
      }
    } else {
      activities = this._initActivities(props.activities);
    }
    if(localStorage.getItem('objSearchAct'+ keyIds)){
      //console.log('constructor.localStorage.getItem(\'objSearchAct\'):', localStorage.getItem('objSearchAct'));
      objSearchAct = JSON.parse(localStorage.getItem('objSearchAct'+ keyIds));
    } else {
      objSearchAct = this._initObjectsSearch();
    }
    if(localStorage.getItem('objSearchPar'+ keyIds)){
      //console.log('constructor.localStorage.getItem(\'objSearchPar\'):', localStorage.getItem('objSearchPar'));
      objSearchPar = JSON.parse(localStorage.getItem('objSearchPar'+ keyIds));
    } else {
      objSearchPar = this._initObjectsSearch();
    }
    if(localStorage.getItem('objSearchMat'+ keyIds)){
      //console.log('constructor.localStorage.getItem(\'objSearchMat\'):', localStorage.getItem('objSearchMat'));
      objSearchMat = JSON.parse(localStorage.getItem('objSearchMat'+ keyIds));
    } else {
      objSearchMat = this._initObjectsSearch();
    }
    if(localStorage.getItem('nowFilter'+ keyIds)){
      //console.log('constructor.localStorage.getItem(\'nowFilter\'):', localStorage.getItem('nowFilter'));
      nowFilter = JSON.parse(localStorage.getItem('nowFilter'+ keyIds));
    } else {
      nowFilter = 'All';
    }
    if(localStorage.getItem('objSearchNow'+ keyIds)){
      //console.log('constructor.localStorage.getItem(\'objSearchNow\'):', localStorage.getItem('objSearchNow'));
      objSearchNow = JSON.parse(localStorage.getItem('objSearchNow'+ keyIds));
    } else {
      objSearchNow = this._initObjectsSearch();
    }

    //console.log('constructor.activities:', JSON.stringify(activities));
    //console.log('constructor.objSearchAct:', JSON.stringify(objSearchAct));

    this.state = {
      isLoading: false,
      activities: activities,
      activeUsers: [],
      isLoadingNow: true,
      nowItems: [], // Try to move this directly to DNow if the others panels don't need it
      hasMoreItems: true, // Try to move this directly to DNow if the others panels don't need it
      offset: 0,
      objSearchAct: objSearchAct,
      objSearchPar: objSearchPar,
      objSearchMat: objSearchMat,
      objSearchNow: objSearchNow,
      modalActiveTaskId: -1,
      nowFilter: nowFilter,
      showModal: false,
      modalType: '',
      searchTerm: '',
      userFilters: [],
      segments: this._initSegsTags('segments', props.community),
      tags: this._initSegsTags('tags', props.community),
      cellUser: null,
      isLoadingResponses: true,
      setAlertsFirstTime: false,
      isLegends: false,
      isLoadingUnseen: false,
      // NEW
      quickComments: [],
      activeUsersRender: {matrix: [], activities: [], participants: []},
      usersRender: {matrix: [], activities: [], participants: []},
      usersOffset: {matrix: 0, activities: 0, participants: 0},
      usersLimit01: {matrix: 8, activities: 8, participants: 8},
      usersBatch02: {matrix: 2, activities: 2, participants: 2},
      hasMoreUsers: {matrix: false, activities: false, participants: false}
    };
  }

  componentDidMount() {
    //console.log('Dashboards.componentDidMount this.props.activeLink:', this.props.activeLink);
    this._getActiveUsersAndResponses();
    this._getUserFilters();
    this._getQuickComments();

    if(this.props.activeLink === 'Now'){
      this._getTasksNow();
    }

    window.addEventListener("beforeunload", this.onRefresh);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.firstTimeCallAlerts !== this.props.firstTimeCallAlerts){
      //this.updateTasksAlert(this.props.community);
    }
    // When the user returns from TV
    if(this.props.firstTimeCallAlerts && prevState.setAlertsFirstTime !== this.state.setAlertsFirstTime){
      if(this.state.setAlertsFirstTime){
        this.setState({setAlertsFirstTime: false}, () => {
          //this.updateTasksAlert(this.props.community);
        });
      }
    }
    if(prevProps.firstTimeNow !== this.props.firstTimeNow){
      this._getTasksNow();
    }
    if(prevProps.activities !== this.props.activities){
      this.setState({activities: this._initActivities(this.props.activities)});
    }
  }

  componentWillUnmount() {
    this._saveLocalStorage();
    window.removeEventListener("beforeunload", this.onRefresh);
  }

  onRefresh = () => {
    this._saveLocalStorage();
  }

  _saveLocalStorage = () => {
    const keyIds = '_' + this.props.user_account.id + '_' + this.props.community.id;
    console.log('activities' + keyIds);

    const actUsersLocal = this.state.activeUsers.map(user => {
      let {email, username, user_alias, avatar, country, state_country, gender, role_1, role_2, ...userLocal} = user;
      return userLocal;
    });

    localStorage.removeItem('activities' + keyIds);
    localStorage.removeItem('activeUsers' + keyIds);
    localStorage.removeItem('objSearchAct' + keyIds);
    localStorage.removeItem('objSearchPar' + keyIds);
    localStorage.removeItem('objSearchMat' + keyIds);
    localStorage.removeItem('nowFilter' + keyIds);
    localStorage.removeItem('objSearchNow' + keyIds);

    localStorage.setItem('activities' + keyIds, JSON.stringify(this.state.activities));
    localStorage.setItem('activeUsers' + keyIds, JSON.stringify(actUsersLocal));
    localStorage.setItem('objSearchAct' + keyIds, JSON.stringify(this.state.objSearchAct));
    localStorage.setItem('objSearchPar' + keyIds, JSON.stringify(this.state.objSearchPar));
    localStorage.setItem('objSearchMat' + keyIds, JSON.stringify(this.state.objSearchMat));
    localStorage.setItem('nowFilter' + keyIds, JSON.stringify(this.state.nowFilter));
    localStorage.setItem('objSearchNow' + keyIds, JSON.stringify(this.state.objSearchNow));
  }

  render() {
    const {community, user_account, activeLink, isMobile} = this.props;

    const nowProfile = {...user_account.profile, role_1: user_account.role_1};
    const dashboard = activeLink.toLowerCase();

    return (
      <React.Fragment>
        <DMenu activeLink={activeLink}
               updateActiveLink={this.props.updateActiveLink}
               role_2={user_account.role_2}
               language={user_account.language}
               isMobile={isMobile}
               colors={user_account.all_colors}/>
        {
          this.state.isLoadingResponses ?
            <Loading/> :
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <React.Fragment>
                    {
                      (activeLink === 'Matrix' && !isMobile) &&
                        <React.Fragment>
                          <MediaQuery minDeviceWidth={569}>
                            <DMatrix activeLink={activeLink}
                                     activeUsers={this.state.usersRender[dashboard]}
                                     activities={this.state.activities}
                                     user_account={user_account}
                                     columnHeaderActions={this.columnHeaderActions}
                                     userActions={this.userActions}
                                     objSearchMat={this.state.objSearchMat}
                                     updateFilterIsMin={this.updateFilterIsMin}
                                     updateShowModal={this.updateShowModal}
                                     executeUpdateModal={this.executeUpdateModal}
                                     updateCustomFilter={this.updateCustomFilter}
                                     userFilters={this.state.userFilters}
                                     handleChangeSltFilter={this.handleChangeSltFilter}
                                     activityActions={this.activityActions}
                                     viewProfile={this.viewProfile}
                                     updateModalActiveTaskId={this.updateModalActiveTaskId}
                                     isLoadingUnseen={this.state.isLoadingUnseen}
                                     gender_as_segment={community.gender_as_segment}
                                     language={user_account.language}
                                     updateUserCustomFilters={this.updateUserCustomFilters}
                                     clearForwardUrl={this.clearForwardUrl}
                                     updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                                     communityId={community.id}
                                     modalActiveTaskId={this.state.modalActiveTaskId}
                                     hasMoreUsers={this.state.hasMoreUsers[dashboard]}
                                     getResponses={this._getResponses}/>
                          </MediaQuery>
                          <MediaQuery maxDeviceWidth={568}>
                            <div className="px-rotate-container">
                              <div className="px-message">Rotate device to landscape to see content</div>
                              <img src="/assets/rotate_icon.svg" className="px-rotate-image" alt=""/>
                            </div>
                          </MediaQuery>
                        </React.Fragment>
                    }
                    {
                      activeLink === 'Activities' &&
                      <DActivities activeLink={activeLink}
                                   activities={this.state.activities}
                                   activeUsers={this.state.activeUsers}
                                   activeUsersCount={this.state.activeUsers.length}
                                   updateActivities={this.updateActivities}
                                   objSearchAct={this.state.objSearchAct}
                                   showModal={this.state.showModal}
                                   updateShowModal={this.updateShowModal}
                                   updateFilterIsMin={this.updateFilterIsMin}
                                   searchTerm={this.state.searchTerm}
                                   updateSearchTerm={this.updateSearchTerm}
                                   searchTask={this.searchTask}
                                   executeUpdateModal={this.executeUpdateModal}
                                   updateCustomFilter={this.updateCustomFilter}
                                   userFilters={this.state.userFilters}
                                   handleChangeSltFilter={this.handleChangeSltFilter}
                                   gender_as_segment={community.gender_as_segment}
                                   user_account ={user_account}
                                   colors={user_account.all_colors}
                                   updateUserCustomFilters={this.updateUserCustomFilters}
                                   clearForwardUrl={this.clearForwardUrl}
                                   language={user_account.language}
                                   role_2={user_account.role_2}
                                   getResponsesActivity={this.getResponsesActivity}/>
                    }
                    {
                      activeLink === 'Participants' &&
                      <DParticipants activeLink={activeLink}
                                     activeUsers={this.state.usersRender[dashboard]}
                                     activities={this.state.activities}
                                     updateActiveUsers={this.updateActiveUsers}
                                     updateResponseState={this.updateResponseState}
                                     communityId={community.id}
                                     role_2={user_account.role_2}
                                     objSearchPar={this.state.objSearchPar}
                                     updateFilterIsMin={this.updateFilterIsMin}
                                     updateShowModal={this.updateShowModal}
                                     updateSearchTerm={this.updateSearchTerm}
                                     searchTask={this.searchTask}
                                     executeUpdateModal={this.executeUpdateModal}
                                     updateCustomFilter={this.updateCustomFilter}
                                     userFilters={this.state.userFilters}
                                     handleChangeSltFilter={this.handleChangeSltFilter}
                                     gender_as_segment={community.gender_as_segment}
                                     updateUserCustomFilters={this.updateUserCustomFilters}
                                     clearForwardUrl={this.clearForwardUrl}
                                     language={user_account.language}
                                     colors={user_account.all_colors}
                                     hasMoreUsers={this.state.hasMoreUsers[dashboard]}
                                     getUsersRender={this._getResponses}/>
                    }
                    {
                      activeLink === 'Now' &&
                      <DNow activeLink={activeLink}
                            nowItems={this.state.nowItems}
                            language={user_account.language}
                            profile={nowProfile}
                            hasMoreItems={this.state.hasMoreItems}
                            _getTasksNow={this._getTasksNow}
                            objSearch={this.state.objSearchNow}
                            nowFilter={this.state.nowFilter}
                            updateNowFilter={this.updateNowFilter}
                            updateFilterIsMin={this.updateFilterIsMin}
                            updateShowModal={this.updateShowModal}
                            updateArrayRdoBtnModal={this.updateArrayRdoBtnModal}
                            isLoadingNow={this.state.isLoadingNow}
                            communityId={community.id}
                            viewProfile={this.viewProfile}
                            user_account={user_account}
                            userActions={this.userActions}
                            executeUpdateModal={this.executeUpdateModal}
                            userFilters={this.state.userFilters}
                            handleChangeSltFilter={this.handleChangeSltFilter}
                            gender_as_segment={community.gender_as_segment}
                            updateCustomFilter={this.updateCustomFilter}
                            updateUserCustomFilters={this.updateUserCustomFilters}
                            clearForwardUrl={this.clearForwardUrl}
                            updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}/>
                    }
                    {
                      activeLink !== 'Now' &&
                      <React.Fragment>
                        <DLegends activeLink={activeLink}
                                  isLegends={this.state.isLegends}
                                  handleIsLegend={this.handleIsLegend}
                                  language={user_account.language}
                                  colors={user_account.all_colors}
                        />
                        <DMLegend/>
                      </React.Fragment>
                    }
                  </React.Fragment>
                </div>
              </div>
            </div>
        }
        {
          this.state.showModal &&
          <ReactModal isOpen={this.state.showModal} contentLabel="Dashboard Collaborator Modal"
                      shouldCloseOnOverlayClick={true} onRequestClose={() => this.updateShowModal(null)}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            {this._setModalBody()}
          </ReactModal>
        }
      </React.Fragment>
    );
  }

  getResponsesActivity = activityId => {
    //console.log('activityId:', activityId);

    axios.get('/responses/coll_dash_all_responses_comm/' + this.props.community.id, {
      params: {users_id: [], activity_id: activityId}})
      .then(r =>  {
        //console.log('Dashboards._getResponses responsesDB:', r.data);
        const responsesDB = r.data;

        const activities = this.state.activities.map(activity => {
          if(activity.id === activityId){
            const tasks = activity.tasks.map(task => {
              const responses = [];

              this.state.activeUsers.forEach(user => {
                //console.log('Dashboards._getResponses user:', user.username);
                const userResponse = responsesDB.find(r => r.user_id === user.id && r.task_id === task.id);
                //console.log('userResponse:', userResponse);

                if(userResponse !== undefined){
                  const isFound = task.responses.find(r => r.id === userResponse.id);
                  //console.log(isFound);
                  if(isFound === undefined){
                    responses.push(userResponse)
                  }
                } else {
                  const isFound = task.responses.find(r => r.task_id === task.id && r.user_id === user.id
                    && r.state === 'Blank');
                  if(isFound === undefined){
                    responses.push({
                      id: null,
                      task_id: task.id,
                      user_id: user.id,
                      state: 'Blank'
                    })
                  }
                }
              });

              return {...task, responses: [...task.responses, ...responses]};
            });

            return {...activity, tasks};
          } else {
            return activity;
          }
        });

        this.setState({activities}, () => {
          //this.updateTasksAlert(this.props.community);
        });
      });
  };

  // LEGENDS
  handleIsLegend = () => {
    this.setState(prevState => ({isLegends: !prevState.isLegends}));
  };

  updateModalActiveTaskId = (modalActiveTaskId) => {
    console.log(modalActiveTaskId);
    this.setState({modalActiveTaskId})
  };

  viewProfile = (userId) => {
    $.ajax({
      url: '/profiles/view_profile/'+ this.props.community.id +'/' + userId,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
        console.log('success')
      }
    });
  };

  activityActions = (type, user, task) => {
    console.log(type, user, task);
    const {cellUser, cellTask, quickComments} = this.state;
    console.log(cellUser, cellTask);

    switch(type){
      case 'see responses':
        localStorage.clear();
        window.location.href = '/communities/'+ this.props.community.id +'/transcript/'+ user.id +'/' +
          task.id +'/all/ActAndParCol';
        break;
      case 'faster':
        const responsesBulk = [];

        cellTask.tasks.forEach(task => {
          task.responses.forEach(response => {
            if(response.user_id === cellUser.id){
              console.log(response);
              if(response.state === 'Blank' || response.state === 'Empty'){
                responsesBulk.push({user: cellUser, response});
                //const responseId = response.id ? response.id : -1;
                //sendRandomCommentMatrix('forget', cellUser.username, [], cellUser.id, task.id, responseId,
                //  quickComments, this.props.user_account.language);
              }
            }
          });
        });

        console.log('responsesBulk:', responsesBulk);
        this._sendBulkUserAction(responsesBulk);

        this.updateShowModal(null, null);
        showSuccessMsg('Success!');
        break;
      case 'accept all completed':
        cellTask.tasks.forEach(task => {
          task.responses.forEach(response => {
            if(response.user_id === cellUser.id){
              if(response.state === 'Completed'){
                this.updateResponseState('Accepted', response.id, 'activity', cellTask.id, task.id);
              }
            }
          });
        });
        /*cellUser.tasks.forEach(ut => {
          if(ut.id === cellTask.id) {
            cellUser.responses.forEach(response => {
              if(response.question.task_id === cellTask.id){
                if(response.response.state === 'Completed'){
                  this.updateResponseState('Accepted', response.response.id, 'activity');
                }
              }
            });
          }
        });*/

        this.updateShowModal(null, null);
        showSuccessMsg('Success!');
        break;
      case 'view pdf':
        const options = '&type=view&images=true&moods=true&comments=false&verbatim=false&focus=Participants' +
          '&pEmail=false&pAlias=true&pAnn=false'+
          '&pdfName=' + Date.now() + '.pdf' + '&language=' + this.props.user_account.language;
        const params = 'activitiesId=' + task.id + '&tasksId=[-1]&usersId=' + user.id + options;

        window.open('/exports/landing?' + params, '_blank');
        //window.open('/exports/to_pdf/show.pdf?activitiesId='+ task.id +'&tasksId=[-1]&usersId=' +
        //  user.id + options);
        break;
        
    }
  };

  handleChangeSltFilter = (event, from) => {
    const objSearchName = this._getObjectSearchName(from);
    const value = event ? event.target.type === 'checkbox' ? event.target.checked : event.target.value : '';
    //console.log(objSearchName, value);

    if(value !== ''){
      const userFilter = this.state.userFilters.find(uf => uf.name === value);
      //console.log(userFilter);
      this.setState({[objSearchName]: {...this.state[objSearchName], ...userFilter}}, () => {
        // it only needs one time to execute because the objSearch is in this.state with all the values for this custom filter
        this.executeUpdateModal(from, null, null, 'showFilter');
        //this.getDashboardResult(from, objSearch);
      });
    } else {
      console.log('New C. Filter, from:', from);
      this.setState({[objSearchName]: {...this._initObjectsSearch(), name: ''} }, () => {
        const objSearch = this._getObjectSearch(from);
        console.log(objSearch);
        this.executeUpdateModal(from, null, null, 'showFilter');
        //this.getDashboardResult(from, objSearch);
      });
    }
  };

  // CREATE custom filters
  updateCustomFilter = (from, objSearch, name) => {
    console.log(from, objSearch, name);
    if(objSearch.id === undefined && name !== ''){
      $.ajax({
        url: '/user_filters/create/' + this.props.community.id + '/' + this.props.user_account.id,
        method: 'POST',
        dataType: 'JSON',
        data: {
          user_filter: {
            name: name,
            from: 'General',
            actDates: objSearch.actDates,
            idActs: objSearch.idActs,
            idPars: objSearch.idPars,
            idSegs: objSearch.idSegs,
            idTags: objSearch.idTags
          }
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: userFilter => {
          console.log(userFilter);
          this.setState({userFilters: this.state.userFilters.concat(userFilter)}, () => {
            console.log(from);
            const objSearchName = this._getObjectSearchName(from);
            this.setState({[objSearchName]: {...this.state[objSearchName], ...userFilter}});
            //this.setState({[this._getObjectSearchName(from)]: userFilter});
          });
        }
      });
    } else {
      console.log('Update', objSearch);
      this.updateUserCustomFilters(objSearch, 'update');
    }
  };

  updateUserCustomFilters = (objSearch, action) => {
    console.log(objSearch, action)
    let user_filter;
    if(action === 'update'){
      user_filter = {
        name: objSearch.name,
        actDates: objSearch.actDates,
        idActs: objSearch.idActs.length !== 0 ? objSearch.idActs : [''],
        idPars: objSearch.idPars.length !== 0 ? objSearch.idPars : [''],
        idSegs: objSearch.idSegs.length !== 0 ? objSearch.idSegs : [''],
        idTags: objSearch.idTags.length !== 0 ? objSearch.idTags : ['']
      }
    } else {
      user_filter = {state: 'Disabled'}
    }

    $.ajax({
      url: '/user_filters/update/' + objSearch.id,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        user_filter
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: userFilters => {
        console.log('objSearch:', objSearch, 'userFilters:', userFilters);
        // TO-DO after update the name of filter it doesn't update in the select options, it exists but don't change by default
        this.setState({objSearch}, () => {
          this.setState({userFilters}, () => {
            if(action === 'delete'){
              //console.log(this.props.activeLink);
              this.handleChangeSltFilter(null, this.props.activeLink);
            } else {
              //this.setState({objSearch});
            }
          });
        });

        showSuccessMsg('Success!');
      }
    });
  };

  searchTask = (from) => {
    console.log(from);
    const searchTerm = this._getObjectSearch(from).searchTerm;
    console.log(searchTerm);
    if(searchTerm !== ''){
      const searchTermRE = new RegExp(searchTerm, 'i');

      switch(from){
        case 'Activities':
          const activities = this.state.activities.map(activity => {
            const match = activity.title.match(searchTermRE);
            console.log(match);
            const matchesQ = [];
            activity.tasks.forEach(task => {
              console.log(task.title);
              const matchQ = task.title.match(searchTermRE);
              console.log(matchQ);
              if(matchQ){
                if(matchQ.length !== 0){
                  matchesQ.push(true);
                }
              }
            });
            console.log(matchesQ);
            const actTitleFlag = match ? match.length !== 0 : false;
            const tasksTitleFlag = matchesQ.length !== 0;
            console.log(actTitleFlag, tasksTitleFlag);
            return {...activity, isVisAct: actTitleFlag || tasksTitleFlag, isCollapsedAct: !tasksTitleFlag};
          });

          this.setState({
            activities,
            [this._getObjectSearchName(from)]: {...this._initObjectsSearch(), name: '', searchTerm: searchTerm}
          });
          break;
        case 'Participants':
          const activeUsers = this.state.activeUsers.map(user => {
            //console.log(user);
            const matchUsername = user.username.match(searchTermRE);
            const matchEmail = user.email.match(searchTermRE);
            /*const tasks = user.tasks.map(task => {
              const match = task.title.match(searchTermRE);
              return {...task, isVisPar: match ? match.length !== 0 : false};
            });*/

            const parUsernameFlag = matchUsername ? matchUsername.length !== 0 : false;
            const parEmailFlag = matchEmail ? matchEmail.length !== 0 : false;

            return {...user, isVisPar: parUsernameFlag || parEmailFlag}
          });

          this.setState({
            activeUsers,
            [this._getObjectSearchName(from)]: {...this._initObjectsSearch(), name: '', searchTerm: searchTerm}
          });
          break;
        case 'Matrix':
          const tasksM = this.state.tasks.map(task => {
            const match = task.title.match(searchTermRE);
            //console.log(match);
            const matchesQ = [];
            task.questions.forEach(question => {
              //console.log(question.title);
              const matchQ = question.title.match(searchTermRE);
              //console.log(matchQ);
              if(matchQ){
                if(matchQ.length !== 0){
                  matchesQ.push(true);
                }
              }
            });
            //console.log(matchesQ);
            const actTitleFlag = match ? match.length !== 0 : false;
            const tasksTitleFlag = matchesQ.length !== 0;
            //console.log(actTitleFlag, tasksTitleFlag);
            return {...task, isVisMat: actTitleFlag || tasksTitleFlag};
          });

          const activeUsersM = this.state.activeUsers.map(user => {
            //console.log(user);
            const matchUsername = user.username.match(searchTermRE);
            const matchEmail = user.email.match(searchTermRE);
            /*const tasks = user.tasks.map(task => {
              const match = task.title.match(searchTermRE);
              return {...task, isVisPar: match ? match.length !== 0 : false};
            });*/

            const parUsernameFlag = matchUsername ? matchUsername.length !== 0 : false;
            const parEmailFlag = matchEmail ? matchEmail.length !== 0 : false;

            return {...user, isVisMat: parUsernameFlag || parEmailFlag}
          });

          this.setState({
            tasks: tasksM,
            activeUsers: activeUsersM,
            [this._getObjectSearchName(from)]: {...this._initObjectsSearch(), name: '', searchTerm: searchTerm}
          });
          break;
      }
    } else {
      this.setState({
        activities: this._initActivities(this.state.activities),
        activeUsers: this._initActiveUsers(this.state.activeUsers)
      });
    }
  };

  updateSearchTerm = (event, from) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[this._getObjectSearchName(from)]: {...this._getObjectSearch(from), [name]: value}});
  };

  userActions = (type, user) => {
    console.log('type:', type, 'user:', user);
    const { cellUser, quickComments} = this.state;
    console.log(cellUser);

    switch(type) {
      case 'see responses':
        const activeActIds = this.state.activities.filter(act => act.isVisMat).map(a => a.id);
        console.log(this.state.activities.length, activeActIds.length);
        const actIds = this.state.activities.length === activeActIds.length ? 'all' : activeActIds;
        console.log('actIds:', actIds);

        localStorage.clear();
        window.location.href = '/communities/'+ this.props.community.id + '/transcript/' + user.id + '/' + actIds
          + '/all/' + this.props.activeLink;
        break;
      case 'faster':
        const responsesBulk = [];

        if(this.state.objSearchMat.idActs.length !== 0){
          this.state.objSearchMat.idActs.forEach(idAct => {
            const activity = this.state.activities.find(a => a.id === idAct);

            activity.tasks.forEach(task => {
              task.responses.forEach(response => {
                if(response.user_id === cellUser.id){
                  const segsVal = this.segmentsValidation(activity, cellUser, this.props.community.gender_as_segment);

                  if(segsVal){
                    if(response.state === 'Blank' || response.state === 'Empty'){
                      //console.log(response);
                      const responseId = response.id ? response.id : -1;
                      const now = moment().format('YYYY-MM-DD');
                      let available = true;

                      if(activity.start){
                        const startDate = moment(activity.start).format('YYYY-MM-DD');
                        if(now < startDate){
                          available = false;
                        }
                      }
                      //console.log(now, activity.start, available);
                      if(available){
                        responsesBulk.push({user: cellUser, response});
                        //console.log('Send FF:', activity.title, response);
                        //sendRandomCommentMatrix('forget', cellUser.username, [], cellUser.id, task.id, responseId,
                        //  quickComments, this.props.user_account.language);
                      }
                    }
                  }
                }
              });
            });
          })
          console.log('responsesBulk:', responsesBulk);
          this._sendBulkUserAction(responsesBulk);

        } else {
          this.state.activities.forEach(activity => {
            activity.tasks.forEach(task => {
              task.responses.forEach(response => {
                if(response.user_id === cellUser.id){
                  const segsVal = this.segmentsValidation(activity, cellUser, this.props.community.gender_as_segment);
                  //console.log('segsVal:', segsVal);
                  if(segsVal){
                    //console.log(activity.title, response);
                    if(response.state === 'Blank' || response.state === 'Empty'){
                      const responseId = response.id ? response.id : -1;
                      const now = moment().format('YYYY-MM-DD');
                      let available = true;

                      if(activity.start){
                        const startDate = moment(activity.start).format('YYYY-MM-DD');
                        if(now < startDate){
                          available = false;
                        }
                      }
                      //console.log(now, activity.start, available)
                      if(available){
                        //console.log('Send FF:', activity.title, response);
                        responsesBulk.push({user: cellUser, response});
                        /*sendRandomCommentMatrix('forget', cellUser.username, [], cellUser.id, task.id, responseId,
                          quickComments, this.props.user_account.language);*/
                      }
                    }
                  }
                }
              });
            });
          });
          console.log('responsesBulk:', responsesBulk);
          this._sendBulkUserAction(responsesBulk);

          /*if(responsesBulk.length <= 3){
            console.log('Less than 4');
            responsesBulk.forEach(obj => {
              const responseId = obj.response.id ? obj.response.id : -1;
              sendRandomCommentMatrix('forget', obj.user.username, [], obj.user.id, obj.response.task_id, responseId,
                this.state.quickComments , this.props.user_account.language, false);
            });
          } else {
            console.log('More than 3');
            let counter = 0;
            const comments_id = [];
            const config = {
              headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}
            };

            responsesBulk.forEach(obj => {
              const responseId = obj.response.id ? obj.response.id : -1;
              const randomComment = getRandomFasterCommentMatrix('forget', obj.user.username, [],
                obj.user.id, obj.response.task_id, responseId, this.state.quickComments ,
                this.props.user_account.language, true);
              //console.log('randomComment:', randomComment);

              if(randomComment !== '') {
                const data = {
                  comment: {
                    body: randomComment
                  },
                  digest: true, // To know if send normal notification/email or not
                  from: '',  // We don't need to send from to the server
                  task_id: -1 //To get the active_participants and update the Dashboard - it's legacy
                };

                axios.post('/comment/'+ obj.user.id +'/'+ obj.response.task_id +'/' + responseId, data, config)
                  .then(r => {
                    console.log('r.data:', r.data);
                    comments_id.push(r.data.comment.id);
                    ++counter;
                    console.log('counter:', counter);

                    if(counter === responsesBulk.length){
                      console.log('SEND NOTI AND EMAIL');
                      // Send digest notification and email
                      const tasksId = responsesBulk.map(obj => obj.response.task_id);
                      console.log('tasksId:', tasksId);
                      const data = {
                        comment: {
                          tasks_id: tasksId,
                          comments_id
                        }
                      };

                      axios.post('/comment/send_digest/'+ this.props.community.id +'/' + obj.user.id, data, config)
                        .then(r => {
                          //console.log('consolidated responses DB: ', r.data);
                        });
                    }
                  });
              }
            });
          }*/
        }

        this.updateShowModal(null, null);
        showSuccessMsg('Success!');
        break;
      case 'accept all completed':
        if(this.state.objSearchMat.idActs.length !== 0){
          this.state.objSearchMat.idActs.forEach(idAct => {
            const activity = this.state.activities.find(a => a.id === idAct);

            activity.tasks.forEach(task => {
              task.responses.forEach(response => {
                if(response.user_id === cellUser.id){
                  if(response.state === 'Completed'){
                    console.log(response);
                    this.updateResponseState('Accepted', response.id, 'activity', activity.id, task.id);
                  }
                }
              });
            });
          })
          /*cellUser.responses.forEach(response => {
            this.state.objSearchMat.idActs.forEach(taskId => {
              if(response.question.task_id === taskId){
                if(response.response.state === 'Completed'){
                  this.updateResponseState('Accepted', response.response.id, 'activity');
                }
              }
            });
          });*/
        } else {
          this.state.activities.forEach(activity => {
            activity.tasks.forEach(task => {
              task.responses.forEach(response => {
                if(response.user_id === cellUser.id){
                  if(response.state === 'Completed'){
                    console.log(response);
                    this.updateResponseState('Accepted', response.id, 'activity', activity.id, task.id);
                  }
                }
              });
            });
          });
          /*cellUser.responses.forEach(response => {
            if(response.response.state === 'Completed'){
              this.updateResponseState('Accepted', response.response.id, 'activity');
            }
          });*/
        }

        this.updateShowModal(null, null);
        showSuccessMsg('Success!');
        break;
      case 'view pdf':
        let tasksIdPdf = [];
        const now = moment().format('YYYY-MM-DD');

        if(this.state.objSearchMat.idActs.length !== 0){
          this.state.objSearchMat.idActs.forEach(actId => {
            const activity = this.props.activities.find(activity => activity.id === actId);
            //const task = user.tasks.find(task => task.id === taskId);
            let available = true;
            if(activity){
              if(activity.start){
                const startDate = moment(activity.start).format('YYYY-MM-DD');
                if(now < startDate){
                  available = false;
                }
                if(available){
                  tasksIdPdf.push(activity.id);
                }
              } else {
                if(activity.expire === null){
                  tasksIdPdf.push(activity.id);
                }
              }
            }
          });
        } else {
          tasksIdPdf = this.props.activities.filter(activity => {
            let available = true;
            if(activity.start){
              const startDate = moment(activity.start).format('YYYY-MM-DD');
              if(now < startDate){
                available = false;
              }
              if(available){
                return activity;
              }
            } else {
              if(activity.expire === null){
                return activity;
              }
            }
          });
          tasksIdPdf = tasksIdPdf.map(activity => activity.id);
          /*tasksIdPdf = user.tasks.filter(task => {
            let available = true;
            if(task.start){
              const startDate = moment(task.start).format('YYYY-MM-DD');
              if(now < startDate){
                available = false;
              }
              if(available){
                return task;
              }
            }
          });
          tasksIdPdf = tasksIdPdf.map(task => task.id);*/
        }
        //console.log(tasksIdPdf);
        if(tasksIdPdf.length !== 0){
          const options = '&type=view&images=true&moods=true&comments=false&verbatim=false&focus=Participants' +
            '&pEmail=false&pAlias=true&pAnn=false' +
            '&pdfName=' + Date.now() + '.pdf' + '&language=' + this.props.user_account.language;
          const params = 'activitiesId=' + tasksIdPdf + '&tasksId=[-1]&usersId=' + user.id + options;
          window.open('/exports/landing?' + params, '_blank');

          //window.open('/exports/to_pdf/show.pdf?activitiesId='+ tasksIdPdf +'&tasksId=[-1]&usersId=' +
          //  user.id + options);
        }
        break;
      case 'send pm':
        this.props.updateOpenPMFromOutside(user.username, user.user_id);
        break;
    }
  };

  _sendBulkUserAction = (responsesBulk) => {
    if(responsesBulk.length <= 3){
      console.log('Less than 4');
      responsesBulk.forEach(obj => {
        const responseId = obj.response.id ? obj.response.id : -1;
        sendRandomCommentMatrix('forget', obj.user.username, [], obj.user.id, obj.response.task_id, responseId,
          this.state.quickComments , this.props.community.language, false);
      });
    } else {
      console.log('More than 3');
      let counter = 0;
      const comments_id = [];
      const config = {
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}
      };

      responsesBulk.forEach(obj => {
        const responseId = obj.response.id ? obj.response.id : -1;
        const randomComment = getRandomFasterCommentMatrix('forget', obj.user.username, [],
          obj.user.id, obj.response.task_id, responseId, this.state.quickComments ,
          this.props.community.language, true);
        //console.log('randomComment:', randomComment);

        if(randomComment !== '') {
          const data = {
            comment: {
              body: randomComment
            },
            digest: true, // To know if send normal notification/email or not
            from: '',  // We don't need to send from to the server
            task_id: -1 //To get the active_participants and update the Dashboard - it's legacy
          };

          axios.post('/comment/'+ obj.user.id +'/'+ obj.response.task_id +'/' + responseId, data, config)
            .then(r => {
              console.log('r.data:', r.data);
              comments_id.push(r.data.comment.id);
              ++counter;
              console.log('counter:', counter);

              if(counter === responsesBulk.length){
                console.log('SEND NOTI AND EMAIL');
                // Send digest notification and email
                const tasksId = responsesBulk.map(obj => obj.response.task_id);
                console.log('tasksId:', tasksId);
                const data = {
                  comment: {
                    tasks_id: tasksId,
                    comments_id
                  }
                };

                axios.post('/comment/send_digest/'+ this.props.community.id +'/' + obj.user.id, data, config)
                  .then(r => {
                    //console.log('consolidated responses DB: ', r.data);
                  });
              }
            });
        }
      });
    }
  };

  columnHeaderActions = (type, taskId) => {
    console.log('type:', type, 'actId:', taskId);
    const activity = {...this.state.activities.find(a => a.id === this.state.modalActiveTaskId)};

    switch(type){
      case 'see responses':
        localStorage.clear();
        console.log(this._seeResponses('task header', this.props.community.id, this.state.activeUsers,
          taskId !== undefined ? taskId : this.state.modalActiveTaskId, null, 'ActAndParCol'));
        window.location.href = this._seeResponses('task header', this.props.community.id, this.state.activeUsers,
          taskId !== undefined ? taskId : this.state.modalActiveTaskId, null, 'ActAndParCol');
        break;
      case 'faster':
        const usersFaster = this._getUsersFasterCount(activity);
        console.log('usersFaster:', usersFaster);
        usersFaster.forEach(obj => {
          let counter = 0;
          const comments_id = [];

          if(obj.fasterTasks.length <= 3){
            console.log('Less than 4');
            obj.fasterTasks.forEach(i => {
              sendRandomCommentMatrix('forget', obj.user.username, [], obj.user.id, i.taskId, i.responseId,
                this.state.quickComments , this.props.community.language, false);
            });
          } else {
            console.log('More than 3');
            const config = {
              headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}
            };

            obj.fasterTasks.forEach(i => {
              const randomComment = getRandomFasterCommentMatrix('forget', obj.user.username, [],
                obj.user.id, i.taskId, i.responseId, this.state.quickComments , this.props.community.language, true);
              console.log('randomComment:', randomComment);
              if(randomComment !== ''){
                //console.log('obj.user.id:', obj.user.id, 'i.taskId:', i.taskId, 'i.responseId:', i.responseId);
                const data = {
                  comment: {
                    body: randomComment
                  },
                  digest: true, // To know if send normal notification/email or not
                  from: '',  // We don't need to send from to the server
                  task_id: -1 //To get the active_participants and update the Dashboard - it's legacy
                };

                axios.post('/comment/'+ obj.user.id +'/'+ i.taskId +'/' + i.responseId, data, config)
                  .then(r => {
                    console.log('r.data:', r.data);
                    comments_id.push(r.data.comment.id);
                    ++counter;
                    console.log('counter:', counter);
                    if(counter === obj.fasterTasks.length){
                      console.log('SEND NOTI AND EMAIL');
                      // Send digest notification and email
                      const tasksId = obj.fasterTasks.map(obj => obj.taskId);
                      console.log('tasksId:', tasksId);
                      const data = {
                        comment: {
                          tasks_id: tasksId,
                          comments_id
                        }
                      };

                      axios.post('/comment/send_digest/'+ this.props.community.id +'/' + obj.user.id, data, config)
                        .then(r => {
                          //console.log('consolidated responses DB: ', r.data);
                        });
                    }
                  });
              }
            });
          }
        });
        this.updateShowModal(null, null);
        showSuccessMsg('Success!');
        break;
      case 'accept all completed':
        this.state.activeUsers.forEach(user => {
          if(user.isVisMat) {
            activity.tasks.forEach(task => {
              task.responses.forEach(response => {
                if(user.id === response.user_id){
                  if(response.state === 'Completed'){
                    console.log(response);
                    //console.log('accept all completed', user.username, response);
                    this.updateResponseState('Accepted', response.id, 'header', activity.id, task.id);
                  }
                }
              })
            });
          }
        });

        this.updateShowModal(null, null);
        showSuccessMsg('Success!');
        break;
      case 'view pdf':
        const usersId = this.state.activeUsers.filter(user => user.isVisMat).map(user => user.id);
        const currentTaskId = this.state.modalActiveTaskId !== -1 ?
          this.state.modalActiveTaskId : this.state.activities.filter(a => a.isVisMat).map(a => a.id);
        const options = '&type=view&images=true&moods=true&comments=false&verbatim=false&focus=Activities' +
          '&pEmail=false&pAlias=true&pAnn=false' +
          '&pdfName=' + Date.now() + '.pdf' + '&language=' + this.props.user_account.language;

        const params = 'activitiesId=' + currentTaskId + '&tasksId=[-1]&usersId=' + usersId + options;
        //console.log('params:', params);
        window.open('/exports/landing?' + params, '_blank');
        //window.open('/exports/to_pdf/show.pdf?activitiesId='+ currentTaskId +'&tasksId=[-1]&usersId=' + usersId + options);
        break;
    }
  };

  _getUsersFasterCount(activity){
    const usersFaster = [];
    this.state.activeUsers.forEach(user => {
      const fasterTasks = [];

      if(user.isVisMat) {
        const segsVal = this.segmentsValidation(activity, user, this.props.community.gender_as_segment);

        if(segsVal){
          activity.tasks.forEach(task => {
            task.responses.forEach(response => {
              if(user.id === response.user_id){
                //console.log(user.username, response);
                if(response.state === 'Blank' || response.state === 'Empty'){
                  const responseId = response.id ? response.id : -1;
                  const now = moment().format('YYYY-MM-DD');
                  let available = true;

                  if(activity.start){
                    const startDate = moment(activity.start).format('YYYY-MM-DD');
                    if(now < startDate){
                      available = false;
                    }
                  }

                  if(available){
                    //console.log('SEND FF');
                    fasterTasks.push({taskId: task.id, responseId});
                    //sendRandomCommentMatrix('forget', user.username, [], user.id, task.id, responseId,
                      //this.state.quickComments , this.props.user_account.language);
                  }
                }
              }
            })
          });
        }
      }
      const userFaster = {user, fasterTasks};
      usersFaster.push(userFaster);
    });

    return usersFaster;
  }

  _getQuickComments(){
    $.ajax({
      url: '/maps/get_quick_comments/' + this.props.community.id,
      method: 'GET',
      success: quickComments =>  {
        //console.log('Dashboads._getQuickComments', quickComments);
        this.setState({quickComments});
      }
    });
  }

  // PASS to a global function
  segmentsValidation(activity, user, gender_as_segment){
    let segsFlag = true;

    if(activity.segments.length !== 0){
      segsFlag = user.segments.find(us => activity.segments.find(as => as.id === us.id)) !== undefined;
      if(gender_as_segment && !segsFlag){
        //TO-DO In back server, the value of community segment 'Other Gender' must be just 'Other' as DB
        const activitySegs = activity.segments.map(s => s.id === 'Other Gender' ? {...s, id: 'Other'} : s);
        segsFlag = activitySegs.find(as => as.id === user.gender) !== undefined;
      }
    }

    return segsFlag;
  }

  _seeResponses(type, communityId, activeUsers, tasksId, questionsId, origin){
    console.log(type, communityId, activeUsers, tasksId, questionsId, origin);
    let url;

    const activeActIds = this.state.activities.filter(act => act.isVisMat).map(a => a.id);
    const actIds = this.state.activities.length === activeActIds.length ? 'all' : activeActIds;
    console.log('actIds:', actIds);

    if(type === 'task header'){
      const usersId = activeUsers.filter(user => user.isVisMat).map(user => user.id);
      console.log('activeUsersId:', usersId);
      const users_id = usersId.length === 0 ? 'all' : usersId;
      //const users_id = tasksId !== -1 ? usersId.length === 0 ? 'all' : usersId : 'all';
      console.log('users_id:', users_id);
      url = '/communities/'+ communityId +'/transcript/'+ (users_id.length === activeUsers.length ? 'all' : users_id) +
        '/'+ (tasksId !== -1 ? tasksId : actIds) + '/all/' + origin;
    } else {
      url = '';
    }
    console.log('url:', url);
    return url;
  }

  updateStartExpireDatesModal = (startDate, endDate) => {
    const {activeLink} = this.props;
    console.log(startDate, endDate, activeLink, '');
    const objSearch = {...this._getObjectSearch(activeLink)};
    const actDates = objSearch.actDates;

    actDates.startDate = startDate;
    actDates.endDate = endDate;

    this.setState({[this._getObjectSearchName(activeLink)]: {...this._getObjectSearch(activeLink), actDates: actDates}},
      () => {
      if(actDates.startDate && actDates.endDate){
        /*this.setState({tasks: this.state.tasks.map(task => ({...task, required: null}))}, () => {
          this.updateArrayTaskFilters('tasks', 'Dates');
        });*/
      }
    });
  };

  handleCalendarSelect = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    const objSearch = this._getObjectSearch(this.props.activeLink);
    objSearch.actDates.name = value;

    this.setState({[this._getObjectSearchName(this.state.from)]: objSearch});
  };

  searchModal = (modalType, q) => {
    let re = null;
    const name = this._getObjectModVisName(this.props.activeLink);
    console.log(modalType, q, name);
    // TO-DO the code inside the switch would be improved to a few lines and no switch
    switch(modalType){
      case 'Activities':
        let activities = null;

        if(q !== ''){
          re = new RegExp(q, 'i');
          activities = this.state.activities.map(act =>  ({ ...act, [name]: {...act[name], isAct: act.title.match(re)} }));
        } else {
          activities = this.state.activities.map(act =>  ({...act, [name]: {...act[name], isAct: true}}));
        }

        this.setState({activities});
        break;
      case 'Participants':
        let activeUsers = null;

        if(q !== '') {
          re = new RegExp(q, 'i');

          activeUsers = this.state.activeUsers.map(user => {
            const matchUsername = user.username.match(re);
            const matchEmail = user.email.match(re);

            return {...user, [name]: {...user[name], isPar: matchUsername !== null || matchEmail !== null}};
          });
        } else {
          activeUsers = this.state.activeUsers.map(user => ({...user, [name]: {...user[name], isPar: true}}));
        }
        console.log(activeUsers);
        this.setState({activeUsers});
        break;
      case 'Segments':
        let segments = null;

        if(q !== '') {
          re = new RegExp(q, 'i');
          segments = this.state.segments.map(s => ({ ...s, [name]: {...s[name], isSeg: s.name.match(re)} }));
        } else {
          segments = this.state.segments.map(s => ({ ...s, [name]: {...s[name], isSeg: true} }));
        }
        console.log(segments);
        this.setState({segments});
        break;
      case 'Tags':
        let tags = null;

        if(q !== '') {
          re = new RegExp(q, 'i');
          tags = this.state.tags.map(t => ({ ...t, [name]: {...t[name], isTag: t.name.match(re)} }));
        } else {
          tags = this.state.tags.map(t => ({ ...t, [name]: {...t[name], isTag: true} }));
        }
        console.log(tags);
        this.setState({tags});
        break;
    }
  };

  executeUpdateModal = (activeLink, arrayName, array, type) => {
    console.log('activeLink:', activeLink, 'arrayName:', arrayName, 'array:', array, 'type:', type);
    const dashboard = activeLink.toLowerCase();
    let objSearch;

    if(type !== undefined){
      objSearch = this._getObjectSearch(activeLink);
    } else {
      objSearch = this._getObjectSearch(activeLink);
      objSearch[arrayName] = array;
    }
    console.log('objSearch:', objSearch);

    if(activeLink !== 'Now'){
      switch(activeLink){
        case 'Activities':
          /*const activeUsersAct = this.state.activeUsers.map(user => {
            //console.log(this.getSearchResult(objSearch, activeLink).filter(u => u.id === user.id));
            const result = this.getSearchResult(objSearch, activeLink);
            console.log('result:', result);
            return(
              {...user,
                isVisAct: (result.filter(u => u.id === user.id)).length !== 0
              }
            )
          });
          //console.log('activeUsersAct:', activeUsersAct.map(u => ({id:u.id, isVisAct: u.isVisAct})));

          this.setState({
            showModal: false,
            [this._getObjectSearchName(activeLink)]: objSearch,
            activeUsers: activeUsersAct
          });*/

          const activities = this.state.activities.map(activity => (
            {...activity, isVisAct: (this.getSearchResult(objSearch, activeLink).filter(a => activity.id === a.id)).length !== 0}));
          console.log(activities);
          this.setState({
            showModal: false,
            [this._getObjectSearchName(activeLink)]: objSearch,
            activities
          });
          break;
        case 'Participants':
          const activeUsers = this.state.activeUsers.map(user => (
            {...user, isVisPar: (this.getSearchResult(objSearch, activeLink).filter(u => u.id === user.id)).length !== 0}));
          const activitiesPar = this.state.activities.map(act => (
            {...act, isVisPar: (this.getPartActResult(objSearch, activeLink).filter(a => act.id === a.id)).length !== 0}));

          console.log('activeUsers:', activeUsers);
          this.setState({
            [this._getObjectSearchName(activeLink)]: objSearch,
            activeUsers,
            activities: activitiesPar,
            showModal: false,
            usersOffset: {...this.state.usersOffset, [dashboard]: 0}
          }, () => {
            const obj = this.getRendersInf(dashboard);
            const activeUsersRender = {...this.state.activeUsersRender, [dashboard]: obj.activeUsersRender};
            const usersRender = {...this.state.usersRender, [dashboard]: obj.usersRender};
            const hasMoreUsers = {...this.state.hasMoreUsers, [dashboard]: obj.hasMoreUsers};

            this.setState({activeUsersRender, usersRender, hasMoreUsers}, () => {
              console.log('activeUsersRender:', activeUsersRender, 'usersRender:', usersRender, 'hasMoreUsers:', hasMoreUsers);
              this._getResponses(true, dashboard);
            });
          });
          break;
        case 'Matrix':
          const activitiesMat = this.state.activities.map(act => {
            return {...act, isVisMat: (this.getSearchResult(objSearch, activeLink).filter(a => act.id === a.id)).length !== 0};
          });
          const activeUsersMat = this.state.activeUsers.map(user => {
            return {
              ...user,
              isVisMat: (this.getPartActResult(objSearch, activeLink).filter(u => u.id === user.id)).length !== 0,
            };
          });

          this.setState({
            [this._getObjectSearchName(activeLink)]: objSearch,
            activeUsers: activeUsersMat,
            activities: activitiesMat,
            showModal: false,
            usersOffset: {...this.state.usersOffset, [dashboard]: 0}
          }, () => {
            const obj = this.getRendersInf(dashboard);
            const activeUsersRender = {...this.state.activeUsersRender, [dashboard]: obj.activeUsersRender};
            const usersRender = {...this.state.usersRender, [dashboard]: obj.usersRender};
            const hasMoreUsers = {...this.state.hasMoreUsers, [dashboard]: obj.hasMoreUsers};
            //console.log('activeUsersRender:', activeUsersRender, 'usersRender:', usersRender, 'hasMoreUsers:', hasMoreUsers);
            this.setState({activeUsersRender, usersRender, hasMoreUsers}, () => {
              console.log('activeUsersRender:', activeUsersRender, 'usersRender:', usersRender, 'hasMoreUsers:', hasMoreUsers);
              this._getResponses(true, dashboard);
            });
          });
          break;
      }
    } else {
      this.setState({
        [this._getObjectSearchName(activeLink)]: objSearch,
        offset: 0,
        nowItems: [],
        showModal: false,
        isLoadingNow: true,
        hasMoreItems: true
      }, () => {
        this._getTasksNow();
      })
    }
  };

  getSearchResult(objSearch, activeLink){
    //console.log(objSearch, activeLink);
    let result = [];
    let sortedOptions = [];

    switch(activeLink){
      case 'Activities':
        sortedOptions.push(objSearch.idActs, objSearch.idTags, objSearch.idSegs, objSearch.idPars);
        //console.log('sortedOptions:', sortedOptions);
        let actFilter = [];

        sortedOptions.forEach((option, index) => {
          if(index === 0){
            if(option.length !== 0){
              this.state.activities.forEach(task => {
                option.forEach(id => {
                  if(task.id === id){
                    actFilter.push(task);
                  }
                });
              });
            } else {
              actFilter = this.state.activities;
            }
            //console.log('After idActs', actFilter);
          }
          //idTags
          if(index === 1){
            let initAct = actFilter;
            actFilter = [];

            if(option.length !== 0){
              initAct.forEach(task => {
                option.forEach(id => {
                  if(task.tag){
                    if(task.tag.id === id){
                      actFilter.push(task);
                    }
                  }
                });
              });
            } else {
              actFilter = initAct;
            }
            //console.log('After idTags', actFilter);
          }
          //idSegs
          if(index === 2){
            let initActPars = actFilter;
            actFilter = [];

            if(option.length !== 0){
              /*initActPars.forEach(activity => {
                if(activity.segments.length !== 0){
                  user.segments.forEach(userSeg => {
                    const foundSegment = activity.segments.find(s => s.id === userSeg.id);

                    if(foundSegment !== undefined){
                      actFilter.push(activity);
                    }

                    if(this.props.community.gender_as_segment){
                      const foundSegmentGender = activity.segments.find(s => s.id === user.gender);

                      if(foundSegmentGender !== undefined){
                        actFilter.push(activity);
                      }
                    }
                  });
                } else {
                  actFilter.push(activity);
                }
              });*/


              this.state.activeUsers.forEach(user => {
                option.forEach(idSeg => {
                  console.log('user.id:', user.id, 'idSeg:', idSeg);
                  //if(user.id === idPar){
                    initActPars.forEach(activity => {
                      //console.log('user.segments:', user.segments);
                      if(activity.segments.length !== 0){
                        user.segments.forEach(userSeg => {
                          const foundSegment = activity.segments.find(s => s.id === userSeg.id);

                          if(foundSegment !== undefined){
                            actFilter.push(activity);
                          }

                          if(this.props.community.gender_as_segment){
                            const foundSegmentGender = activity.segments.find(s => s.id === user.gender);

                            if(foundSegmentGender !== undefined){
                              actFilter.push(activity);
                            }
                          }
                        });
                      } else {
                        actFilter.push(activity);
                      }
                    });
                  //}
                });
              });

              actFilter = [...new Set(actFilter.map(task => task.id && task))];
            } else {
              actFilter = initActPars;
            }

            //console.log('After idSegs', actFilter);
            /*let initAct = actFilter;
            actFilter = [];
            // TO-DO Maybe in the search I need to add when genders are segments and compare it with user
            if(option.length !== 0){
              initAct.forEach(task => {
                if(task.segments.length !== 0){
                  task.segments.forEach(segment => {
                    option.forEach(id => {
                      if(segment.id === id){
                        actFilter.push(task);
                      }
                    });
                  });
                } else {
                  // It makes 'OR', right now is 'and'
                  //actFilter.push(task);
                }
              });
              actFilter = [...new Set(actFilter.map(task => task.id && task))];
            } else {
              actFilter = initAct;
            }*/
            //console.log('After idSegs', actFilter);
          }
          // idPars
          if(index === 3){
            let initActPars = actFilter;
            actFilter = [];
            //console.log('initActPars:', initActPars);
            if(option.length !== 0){
              this.state.activeUsers.forEach(user => {
                option.forEach(idPar => {
                  //console.log(user.id, idPar);
                  if(user.id === idPar){
                    initActPars.forEach(activity => {
                      //console.log('user.segments:', user.segments);
                      if(activity.segments.length !== 0){
                        user.segments.forEach(userSeg => {
                          const foundSegment = activity.segments.find(s => s.id === userSeg.id);

                          if(foundSegment !== undefined){
                            actFilter.push(activity);
                          }

                          if(this.props.community.gender_as_segment){
                            const foundSegmentGender = activity.segments.find(s => s.id === user.gender);

                            if(foundSegmentGender !== undefined){
                              actFilter.push(activity);
                            }
                          }
                        });
                      } else {
                        actFilter.push(activity);
                      }

                      /*user.segments.forEach(userSeg => {
                        console.log('activity.segments:', activity.segments);
                        if(activity.segments.length !== 0){
                          const foundSegment = activity.segments.find(s => s.id === userSeg.id);

                          if(foundSegment !== undefined){
                            actFilter.push(activity);
                          }

                          if(this.props.community.gender_as_segment){
                            const foundSegmentGender = activity.segments.find(s => s.id === user.gender);

                            if(foundSegmentGender !== undefined){
                              actFilter.push(activity);
                            }
                          }
                        } else {
                          actFilter.push(activity);
                        }
                      });*/
                    });
                  }
                });
              });

              actFilter = [...new Set(actFilter.map(task => task.id && task))];
            } else {
              actFilter = initActPars;
            }
            //console.log('Activities, After idPars', actFilter);
          }
        });
        //console.log(actFilter);
        result = actFilter;
        break;
      case 'Participants':
        sortedOptions.push(objSearch.idSegs, objSearch.idPars, objSearch.idActs, objSearch.idTags);
        //console.log(sortedOptions);
        let usrFilter = [];
        sortedOptions.forEach((option, index) => {
          if(index === 0){
            if(option.length !== 0){
              const optMod = option.map(opt => opt === 'Other Gender' ? 'Other' : opt); // TO-DO in Manage put 'Other Gender'

              this.state.activeUsers.forEach(user => {
                user.segments.forEach(segment => {
                  optMod.forEach(id => {
                    if(segment.id === id){
                      usrFilter.push(user);
                    }
                  });
                });

                if(this.props.community.gender_as_segment){
                  optMod.forEach(id => {
                    if(user.gender === id){
                      usrFilter.push(user);
                    }
                  });
                }
              });

              usrFilter = [...new Set(usrFilter.map(user => user.id && user))];
            } else {
              usrFilter = this.state.activeUsers;
            }
            //console.log('After idSegs', usrFilter);
          }

          if(index === 1){
            let initParPars = usrFilter;
            usrFilter = [];

            if(option.length !== 0){
              initParPars.forEach(user => {
                //console.log(user);
                option.forEach(user_id => {
                  if(user.id === user_id){
                    usrFilter.push(user);
                  } else{
                    //maybe to keep the initParPars
                  }
                });
              });
            } else {
              usrFilter = initParPars;
            }
            //console.log('After idPars', usrFilter);
          }
        });

        result = usrFilter;
        break;
      case 'Matrix':
        sortedOptions.push(objSearch.idActs, objSearch.idTags, objSearch.idSegs, objSearch.idPars);
        //console.log(sortedOptions);
        let actFilter1 = [];

        sortedOptions.forEach((option, index) => {
          if(index === 0){
            if(option.length !== 0){
              this.state.activities.forEach(task => {
                option.forEach(id => {
                  if(task.id === id){
                    actFilter1.push(task);
                  }
                });
              });
            } else {
              actFilter1 = this.state.activities;
            }
          }

          if(index === 1){
            let initAct = actFilter1;
            actFilter1 = [];

            if(option.length !== 0){
              initAct.forEach(task => {
                option.forEach(id => {
                  if(task.tag){
                    if(task.tag.id === id){
                      actFilter1.push(task);
                    }
                  }
                });
              });
            } else {
              actFilter1 = initAct;
            }
            //console.log('After idTags', actFilter);
          }
        });

        result = actFilter1;
        break;
      case 'Now':
        break;
    }

    //console.log('result:', result);
    return result;
  }

  getPartActResult(objSearch, from){
    //console.log(objSearch, from);
    let result = false;
    let sortedOptions = [];

    switch(from){
      case 'Participants':
        sortedOptions.push(objSearch.idActs, objSearch.idTags);

        let actFilter = [];

        sortedOptions.forEach((option, index) => {
          if(index === 0){
            if(option.length !== 0){
              this.state.activities.forEach(task => {
                option.forEach(id => {
                  if(task.id === id){
                    actFilter.push(task);
                  }
                });
              });
            } else {
              actFilter = this.state.activities;
            }
            //console.log('PART or NOW After idActs', actFilter);
          }

          if(index === 1){
            let initAct = actFilter;
            actFilter = [];

            if(option.length !== 0){
              initAct.forEach(task => {
                option.forEach(id => {
                  if(task.tag){
                    if(task.tag.id === id){
                      actFilter.push(task);
                    }
                  }
                });
              });
            } else {
              actFilter = initAct;
            }
            //console.log('PART or NOW After idTags', actFilter);
          }
        });

        result = actFilter;
        break;
      case 'Matrix':
        sortedOptions.push(objSearch.idSegs, objSearch.idPars);

        let parFilter = [];

        sortedOptions.forEach((option, index) => {
          if(index === 0){
            if(option.length !== 0){
              const optMod = option.map(opt => opt === 'Other Gender' ? 'Other' : opt); // TO-DO in Manage put 'Other Gender'

              this.state.activeUsers.forEach(user => {
                user.segments.forEach(segment => {
                  optMod.forEach(id => {
                    if(segment.id === id){
                      parFilter.push(user);
                    }
                  });
                });

                if(this.props.community.gender_as_segment){
                  optMod.forEach(id => {
                    if(user.gender === id){
                      parFilter.push(user);
                    }
                  });
                }
              });

              parFilter = [...new Set(parFilter.map(user => user.id && user))];
            } else {
              parFilter = this.state.activeUsers;
            }
            //console.log('After idSegs', parFilter);
          }

          if(index === 1) {
            let initParPars = parFilter;
            parFilter = [];

            if(option.length !== 0) {
              initParPars.forEach(user => {
                option.forEach(user_id => {
                  if(user.id === user_id){
                    parFilter.push(user);
                  }
                })
              });
            } else {
              parFilter = initParPars
            }
          }
          //console.log('PART After idPars', parFilter);
        });
        result = parFilter;
        break;
      case 'Now':
        break;
    }

    return result;
  }

  updateArrayChkModal = (obj, objType) => {
    const {activeLink} = this.props;
    console.log(obj, objType);
    let objSearch = this._getObjectSearch(activeLink);

    switch(objType){
      case 'user':
        const isFoundUser = this._getObjectSearch(activeLink).idPars.find(id => id === obj.id);

        if(isFoundUser === undefined){
          objSearch.idPars.push(obj.id);
        } else {
          const idPars = this._getObjectSearch(activeLink).idPars;
          objSearch = {...objSearch, idPars: idPars.filter(id => id !== obj.id)};
        }
        break;
    }

    console.log(objSearch);
    this.updateObjSearchModal(activeLink, objSearch, false);
  };

  updateArrayRdoBtnModal = (type, filter, getDataFromDB) => {
    const {activeLink} = this.props;
    console.log(type, filter, activeLink);
    const objSearch = this._getObjectSearch(activeLink);
    switch(type){
      case 'participants':
        switch(filter){
          case 'All':
            objSearch.idPars = this.state.activeUsers.map(user => {
              if(user[this._getObjectModVisName(activeLink)].isPar){
                return user.id
              }
            });
            break;
          case 'None':
            objSearch.idPars = [];
            break;
        }
        break;
    }

    this.updateObjSearchModal(activeLink, objSearch, getDataFromDB);
  };

  updateObjSearchModal = (activeLink, objSearch, getDataFromDB) => {
    console.log(activeLink, objSearch);
    this.setState({[this._getObjectSearchName(activeLink)]: objSearch}, () => {
      if(getDataFromDB){
        this.setState({
          offset: 0,
          nowItems: [],
          isLoadingNow: true}, () => {
          this._getTasksNow();
        })
      }
    });
  };

  updateShowModal = (from, modalType, cellUser, cellTask) => {
    console.log(from, modalType, cellUser, cellTask);
    this.setState(state => ({showModal: !state.showModal, modalType, cellUser, cellTask}));
  };

  // Try to move to DNow if other doesn't need it
  updateNowFilter = value => {
    console.log('updateNowFilter', value);
    this.setState({
      nowFilter: value,
      offset: 0,
      nowItems: [],
      isLoadingNow: true,
      hasMoreItems: true}, () => {
      this._getTasksNow();
    })
  };

  updateFilterIsMin = (activeLink, value) => {
    this.setState({[this._getObjectSearchName(activeLink)]: {...this._getObjectSearch(activeLink), isMin: value}});
  };

  updateTasksAlert = (community, usersId) => {
    if(this.props.user_account.role_2 !== 'Recruiter'){
      //console.log('.updateTasksAlert, community:', community, 'activities:');
      this.setState({isLoadingUnseen: true}, () => {
        axios.get('/communities/coll_dash_get_tasks_alert/' + community.id + '/' + -1, {params: {users_id: usersId}})
          .then(r =>  {
            //console.log('responsesDB with sum_unseen', r.data);
            const responsesSumUnseen = r.data;
            const activities = this.state.activities.map(activity => {
              const tasks = activity.tasks.map(task => {
                const responses = task.responses.map(response => {
                  const foundRSU = responsesSumUnseen.find(rsu => rsu.response_id === response.id);

                  if(foundRSU !== undefined){
                    return {...response, sum_unseen_col: foundRSU.sum_unseen_col}
                  } else {
                    return response;
                  }
                });

                return {...task, responses};
              });

              return {...activity, tasks};
            });

            //console.log(activities);
            this.setState({activities, isLoadingUnseen: false}, () => {
              // TO-DO change the way Participants and Matrix are selected at first time in order to delete this forceUpdate
              // Test if we still need forceUpdate
              //this.forceUpdate()
            });
          }).catch(error => {
          console.log(error);
        });
      });
    }
  };

  updateTasksAlertORIG = community => {
    if(this.props.user_account.role_2 !== 'Recruiter'){
      //console.log('.updateTasksAlert, community:', community, 'activities:');

      this.setState({isLoadingUnseen: true}, () => {
        axios.get('/communities/coll_dash_get_tasks_alert/' + community.id + '/' + -1)
          .then(r =>  {
            //console.log('responsesDB with sum_unseen', r.data);
            const responsesSumUnseen = r.data;

            const activities = this.state.activities.map(activity => {
              const tasks = activity.tasks.map(task => {
                const responses = task.responses.map(response => {
                  const foundRSU = responsesSumUnseen.find(rsu => rsu.response_id === response.id);

                  if(foundRSU !== undefined){
                    return {...response, sum_unseen_col: foundRSU.sum_unseen_col}
                  } else {
                    return response;
                  }
                });

                return {...task, responses};
              });

              return {...activity, tasks};
            });

            /*const arrObjs = r.data;
            const activities = this.state.activities.map(activity => {
              //console.log(activity.id, arrObjs[0].activity_id);
              if(activity.id === arrObjs[0].activity_id){
                const tasks = activity.tasks.map(task => {
                  let responses = [];
                  arrObjs.forEach(obj => {
                    if(task.id === obj.task_id){
                      //console.log(task.id, obj.task_id);
                      responses = task.responses.map(response => {
                        let updatedResponse = {};
                        arrObjs.forEach(obj => {
                          if(response.id === obj.response_id){
                            updatedResponse = {...response, sum_unseen_col: obj.sum_unseen_col}
                          }

                          return response;
                        });

                        return updatedResponse;
                      });
                    }
                  });

                  return {...task, responses};
                });

                return {...activity, tasks};
              }
              //console.log(activity);
              return activity;
            });*/
            //console.log(activities);
            this.setState({activities, isLoadingUnseen: false}, () => {
              // TO-DO change the way Participants and Matrix are selected at first time in order to delete this forceUpdate
              // Test if we still need forceUpdate
              this.forceUpdate()
            });
          }).catch(error => {
          console.log(error);
        });
      });
    }
  };

  updateResponseState = (state, responseId, from, activityId, taskId) => {
    console.log(state, responseId, from, activityId);
    $.ajax({
      url: '/responses/update_coll/' + responseId,
      method: "PATCH",
      dataType: "JSON",
      data: {
        response: {state: state}
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: responseDB => {
        console.log(responseDB);
        if(from === 'task'){
          showSuccessMsg('Success!');
        }

        if(from === 'participant'){
          console.log('Update state.tasks too');
        }

        const activities = this.state.activities.map(activity => {
          if(activity.id === activityId){
            const tasks = activity.tasks.map(task => {
              if(task.id === taskId){
                const responses = task.responses.map(response => {
                  if(response.id === responseId){
                    console.log(response);
                    return {...response, state: responseDB.state, pre_state: responseDB.pre_state};
                  } else {
                    return response;
                  }
                });

                return {...task, responses};
              } else {
                return task;
              }
            });

            return {...activity, tasks};
          } else {
            return activity;
          }
        });
        //console.log(activities.find(a => a.id === activityId).tasks.find(t => t.id === taskId).responses);
        //console.log(activities);
        this.setState({activities});

        /*const activeUsers = this.state.activeUsers.map(user => {
          const responses = user.responses.map(response => {
            if(response.response.id === responseId){
              return {...response, response: {...response.response, state: responseDB.state,
                  pre_state: responseDB.pre_state}};
            } else {
              return response;
            }
          });

          return {...user, responses: responses}
        });

        this.setState({activeUsers});*/
      }
    });
  };

  updateResponseState_TEST = (state, responseId, from, activityId, taskId) => {
    console.log(state, responseId, from, activityId, taskId);

    const activities = this.state.activities.map(activity => {
      if(activity.id === activityId){
        const tasks = activity.tasks.map(task => {
          if(task.id === taskId){
            const responses = task.responses.map(response => {
              if(response.id === responseId){
                return {...response, state: state}
              } else {
                return response;
              }
            });

            return {...task, responses};
          } else {
            return task;
          }
        });

        return {...activity, tasks};
      } else {
        return activity;
      }
    });
    console.log(activities);

    /*$.ajax({
      url: '/responses/update_coll/' + responseId,
      method: "PATCH",
      dataType: "JSON",
      data: {
        response: {state: state}
      },
      success: responseDB => {
        console.log(responseDB);




        if(from === 'task') {
          showSuccessMsg('Success!');
        }

        if(from === 'participant') {
          console.log('Update state.tasks too');
        }
      }
    });*/

    this.setState({activities});
  };

  updateActiveUsers = (userId, name, value) => {
    const dashboard = this.props.activeLink.toLowerCase();
    //console.log(userId, name, value, dashboard);
    const activeUsers = this.state.activeUsers.map(user => user.id === userId ? {...user, [name]: value} : user);
    const usersRender = {
      ...this.state.usersRender,
      [dashboard]: this.state.usersRender[dashboard].map(user => user.id === userId ? {...user, [name]: value} : user)
    };

    this.setState({activeUsers, usersRender});
  };

  updateActiveUsersORIG = (userId, name, value) => {
    const activeUsers = this.state.activeUsers.map(user => user.id === userId ? {...user, [name]: value} : user);

    this.setState({activeUsers});
  };

  updateActivities = (activityId, name, value) => {
    const activities = this.state.activities.map(activity => activity.id === activityId ?
      {...activity, [name]: value} : activity);

    this.setState({activities});
  };

  _getObjectModVisName(activeLink){
    let name = null;

    switch(activeLink){
      case 'Activities': name = 'modAct';break;
      case 'Participants': name = 'modPar';break;
      case 'Matrix': name = 'modMat';break;
      case 'Now': name = 'modNow';break;
    }

    return name;
  }

  _initActivities(activities){
    //console.log('activities:', activities);
    return activities.map(activity => {
      const tasks = activity.tasks.map(task => (
        {...task, isVisAct: true, isVisPar: true, isVisMat: true, isVisNow: true, responses: [...task.responses]})
      );

      // TO-DO just to clean, some key-values in 'mods' are useless, mod is the current Dashboard
      return {
        ...activity, isVisAct: true, isVisPar: true, isVisMat: true, isVisNow: true, isCollapsedAct: true,
        isCollapsedMat: true,
        modAct: {isAct: true, isSeg: true, isTag: true},
        modPar: {isAct: true, isPar: true, isSeg: true, isTag: true},
        modMat: {isAct: true, isPar: true, isSeg: true, isTag: true},
        modNow: {isAct: true, isPar: true, isSeg: true, isTag: true},
        tasks
      }
    });
  }

  _getTasksNow = () => {
    $.ajax({
      url: '/communities/get_now/' + this.props.community.id + '/Collaborator/' + this.state.offset + '/' + this.state.nowFilter,
      method: 'GET',
      data: {
        usersId: this.state.objSearchNow.idPars,
        activitiesId: this.state.objSearchNow.idActs
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: data => {
        console.log('Dashboards._getTasksNow:', data);
        if(data.length !== 0){
          this.setState(prevState => ({
            isLoadingNow: false,
            nowItems: [...this.state.nowItems, ...data.filter(d => d)],
            offset: prevState.offset + 10
            //filterNowPreItems: this.getFilterNowPreItems(data.allPreNow, this.state.nowFilter)
          }))
        } else {
          this.setState({
            isLoadingNow: false,
            hasMoreItems: false
          })
        }
      }
    });
  };

  _getUserFilters = () => {
    $.ajax({
      url: '/user_filters/get_custom_filters/' + this.props.community.id + '/' + this.props.user_account.id,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: userFilters => {
        //console.log('Dashboards._getUserFilters:', userFilters);
        this.setState({userFilters});
      }
    });
  };

  _getActiveUsersAndResponses = () => {
    const {activeLink} = this.props;

     $.ajax({
      url: '/communities/coll_get_dash_users/' + this.props.community.id,
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: activeUsersDB =>  {
        //console.log('_getActiveUsersAndResponses activeUsersDB:', activeUsersDB);
        const keyIds = '_' + this.props.user_account.id + '_' + this.props.community.id;
        let activeUsers;
        if(localStorage.getItem('activeUsers' + keyIds)){
          //console.log('_getActiveUsersAndResponses.localStorage.getItem(\'activeUsers\'' + keyIds +'):',
          //  JSON.parse(localStorage.getItem('activeUsers'+ keyIds)));
          const activeUsersLocalStorage = JSON.parse(localStorage.getItem('activeUsers' + keyIds));
          //activeUsers = JSON.parse(localStorage.getItem('activeUsers' + keyIds));

          if(activeUsersLocalStorage.length === 0){
            activeUsers = this._initActiveUsers(activeUsersDB);
          } else {
            activeUsers = activeUsersDB.map(userDB => {
              const userLS = activeUsersLocalStorage.find(uLS => uLS.id === userDB.id);
              const usersDBInit = this._initActiveUsers(activeUsersDB);

              if(userLS !== undefined){
                return {...userLS, ...userDB};
              } else {
                return usersDBInit.find(usrInit => usrInit.id === userDB.id)
              }
            });
          }
        } else {
          activeUsers = this._initActiveUsers(activeUsersDB);
        }
        //console.log('Dashboards._getActiveUsersAndResponses.activeUsers:', JSON.stringify(activeUsers));
        this.setState({activeUsers}, () => {
          console.log('activeLink:', this.props.activeLink, 'activeUsers(ids):', this.state.activeUsers.map(au => au.id));
          const dashboard = activeLink.toLowerCase();
          //console.log('dashboard:', dashboard);

          const objM = this.getRendersInf('matrix');
          const objP = this.getRendersInf('participants');

          const activeUsersRender = {...this.state.activeUsersRender,
            matrix: objM.activeUsersRender, participants: objP.activeUsersRender};
          const usersRender = {...this.state.usersRender, matrix: objM.usersRender, participants: objP.usersRender};
          const hasMoreUsers = {...this.state.hasMoreUsers, matrix: objM.hasMoreUsers, participants: objP.hasMoreUsers};

          this.setState({activeUsersRender, usersRender, hasMoreUsers}, () => {
            console.log('activeUsersRender:', activeUsersRender, 'usersRender:', usersRender, 'hasMoreUsers:', hasMoreUsers);
            this._getResponses(true, 'matrix');
            this._getResponses(true, 'participants');
          });
        });
      }
    });
  };

  _getResponses = (isFirstTime, from) => {
    const dashboard = from ? from : this.props.activeLink.toLowerCase();
    console.log('dashboard:', dashboard);
    let usersId = [];

    if(isFirstTime){
      usersId = this.state.usersRender[dashboard].map(u => u.id);
    } else {
      const start = this.state.usersOffset[dashboard] + this.state.usersLimit01[dashboard];
      const end = start + this.state.usersBatch02[dashboard];
      console.log('start:', start, 'end:', end, 'activeUsersRender:', this.state.activeUsersRender,
        'usersRender:', this.state.usersRender);
      const nextUsers = this.state.activeUsersRender[dashboard].slice(start, end);
      console.log('nextUsers:', nextUsers);
      usersId = nextUsers.map(u => u.id);

      this.setState({
        usersOffset: {...this.state.usersOffset, [dashboard]: this.state.usersOffset[dashboard] + this.state.usersBatch02[dashboard]}
      }, () => {
        if(nextUsers.length !== 0){
          this.setState({
            usersRender: {...this.state.usersRender, [dashboard]: [...this.state.usersRender[dashboard], ...nextUsers]}},
            () => {
            console.log('usersOffset:', this.state.usersOffset, 'usersRender:', this.state.usersRender);
          });
        } else {
          this.setState({hasMoreUsers: {...this.state.hasMoreUsers, [dashboard]: false}});
        }
      });
    }
    //console.log('usersId:', usersId);

    if(usersId.length !== 0){
      console.log('CALL R for:', usersId);
      axios.get('/responses/coll_dash_all_responses_comm/' + this.props.community.id, {params:{users_id: usersId}})
        .then(r =>  {
          //console.log('Dashboards._getResponses responsesDB:', r.data);
          const responsesDB = r.data;
          const activities = this.state.activities.map(activity => {
            const tasks = activity.tasks.map(task => {
              const responses = [];

              this.state.activeUsers.forEach(user => {
                const isUserFound = usersId.find(id => id === user.id);

                if(isUserFound){
                  const userResponse = responsesDB.find(r => r.user_id === user.id && r.task_id === task.id);

                  if(userResponse !== undefined){
                    const isFound = task.responses.find(r => r.id === userResponse.id);
                    if(isFound === undefined){
                      responses.push(userResponse)
                    }
                  } else {
                    const isFound = task.responses.find(r => r.task_id === task.id && r.user_id === user.id
                      && r.state === 'Blank');
                    if(isFound === undefined){
                      responses.push({
                        id: null,
                        task_id: task.id,
                        user_id: user.id,
                        state: 'Blank'
                      })
                    }
                  }
                }
              });
              return {...task, responses: [...task.responses, ...responses]};
            });
            return {...activity, tasks};
          });

          this.setState({activities, isLoadingResponses: false}, () => {
            // Put conditional is first time to call or not
            this.updateTasksAlert(this.props.community, usersId);
          });
        });
    } else {
      this.setState({isLoadingResponses: false});
    }
  }

  getRendersInf = dashboard => {
    let activeUsersRender;

    switch(dashboard){
      case 'matrix': activeUsersRender = this.state.activeUsers.filter(au => au.isVisMat); break;
      case 'participants': activeUsersRender = this.state.activeUsers.filter(au => au.isVisPar); break;
    }
    //console.log('activeUsersRender:', activeUsersRender);

    const usersRender = activeUsersRender.slice(this.state.usersOffset[dashboard], this.state.usersLimit01[dashboard]);
    const hasMoreUsers = activeUsersRender.length > 8;

    return {activeUsersRender, usersRender, hasMoreUsers}
  };

  _getResponsesMODEL = (communityId, nextUsers) => {
    const usersId = nextUsers.map(u => u.id);
    //console.log('Dashboards._getResponses usersId:', usersId);

    axios.get('/responses/coll_dash_all_responses_comm/' + communityId, {
      params: {users_id: usersId, activity_id: -1}})
      .then(r =>  {
        //console.log('Dashboards._getResponses responsesDB:', r.data);
        const responsesDB = r.data;

        const activities = this.state.activities.map(activity => {
          const tasks = activity.tasks.map(task => {
            const responses = [];

            this.state.activeUsers.forEach(user => {
              //console.log('Dashboards._getResponses user:', user);
              const isUserFound = usersId.find(id => id === user.id);

              if(isUserFound){
                //console.log('isUserFound:', isUserFound);
                const userResponse = responsesDB.find(r => r.user_id === user.id && r.task_id === task.id);

                if(userResponse !== undefined){
                  const isFound = task.responses.find(r => r.id === userResponse.id);
                  //console.log('userResponse isFound:', isFound);
                  if(isFound === undefined){
                    responses.push(userResponse)
                  }
                } else {
                  const isFound = task.responses.find(r => r.task_id === task.id && r.user_id === user.id
                    && r.state === 'Blank');
                  //console.log('isFound:', isFound);
                  if(isFound === undefined){
                    responses.push({
                      id: null,
                      task_id: task.id,
                      user_id: user.id,
                      state: 'Blank'
                    })
                  }
                }
              }
            });
            return {...task, responses: [...task.responses, ...responses]};
          });
          return {...activity, tasks};
        });

        console.log('this.state.usersRender:', this.state.usersRender.map(u => u.id), 'nextUsers:', nextUsers.map(u => u.id));
        const justNextUsers = nextUsers.filter(u => !this.state.usersRender.includes(u));
        console.log('justNextUsers:', justNextUsers.map(u => u.id));

        this.setState({
          usersRender: [...this.state.usersRender, ...justNextUsers],
          activities,
          //isLoadingResponses: false,
          //setAlertsFirstTime: true
        }, () => {
          //this.setState({hasMoreUsers: this.state.usersRender.filter(u => u.isVisMat).length > 7})
          this.updateTasksAlert(this.props.community);
        });
      });
  };

  _getActiveUsersAndResponsesORIG = () => {
    $.ajax({
      url: '/communities/coll_get_dash_users/' + this.props.community.id,
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: activeUsersDB =>  {
        console.log('_getActiveUsersAndResponses activeUsersDB:', activeUsersDB);
        const keyIds = '_' + this.props.user_account.id + '_' + this.props.community.id;
        let activeUsers;
        if(localStorage.getItem('activeUsers' + keyIds)){
          //console.log('_getActiveUsersAndResponses.localStorage.getItem(\'activeUsers\'' + keyIds +'):',
          //  JSON.parse(localStorage.getItem('activeUsers'+ keyIds)));
          const activeUsersLocalStorage = JSON.parse(localStorage.getItem('activeUsers' + keyIds));
          //activeUsers = JSON.parse(localStorage.getItem('activeUsers' + keyIds));

          if(activeUsersLocalStorage.length === 0){
            activeUsers = this._initActiveUsers(activeUsersDB);
          } else {
            activeUsers = activeUsersDB.map(userDB => {
              const userLS = activeUsersLocalStorage.find(uLS => uLS.id === userDB.id);
              const usersDBInit = this._initActiveUsers(activeUsersDB);

              if(userLS !== undefined){
                return {...userLS, ...userDB};
              } else {
                return usersDBInit.find(usrInit => usrInit.id === userDB.id)
              }
            });
          }
        } else {
          activeUsers = this._initActiveUsers(activeUsersDB);
        }
        //console.log('Dashboards._getActiveUsersAndResponses.activeUsers:', JSON.stringify(activeUsers));
        this.setState({activeUsers, isLoadingResponses: true}, () => {
          //console.log('Dashboards._getActiveUsersAndResponses.toExecute _getResponses')
          this._getResponses(this.props.community.id);
        });
      }
    });
  };

  _getResponsesORIG = (communityId) => {
    //console.log('Dashboards._getResponses communityId:', communityId);
    axios.get('/responses/coll_dash_all_responses_comm/' + communityId)
      .then(r =>  {
        //console.log('Dashboards._getResponses responsesDB:', r.data);
        const responsesDB = r.data;
        //console.log('Dashboards._getResponses this.state.activities:', JSON.stringify(this.state.activities));
        const activities = this.state.activities.map(activity => {
          //console.log('Dashboards._getResponses activity:', JSON.stringify(activity));
          const tasks = activity.tasks.map(task => {
            //console.log('Dashboards._getResponses task:', task);
            const responses = [];
            //console.log('Dashboards._getResponses this.state.activeUsers:', JSON.stringify(this.state.activeUsers));
            this.state.activeUsers.forEach(user => {
              //console.log('Dashboards._getResponses user:', user);
              const userResponse = responsesDB.find(r => r.user_id === user.id && r.task_id === task.id);

              if(userResponse !== undefined){
                responses.push(userResponse)
              } else {
                responses.push({
                  id: null,
                  task_id: task.id,
                  user_id: user.id,
                  state: 'Blank'
                })
              }
            });

            return {...task, responses};
          });

          return {...activity, tasks};
        });

        //console.log('Dashboards._getResponses activities:', activities, 'isLoadingResponses:', this.state.isLoadingResponses);
        this.setState({
          activities,
          isLoadingResponses: false,
          setAlertsFirstTime: true
        });
      })
  };

  _initActiveUsers(activeUsers){
    return activeUsers.map(user => ({
      ...user,
      isVisAct: true, isVisPar: true, isVisMat: true, isVisNow: true,
      isCollapsedPar: true,
      modAct: {isPar: true, isSeg: true, isTag: true},
      modPar: {isAct: true, isPar: true, isSeg: true, isTag: true},
      modMat: {isAct: true, isPar: true, isSeg: true, isTag: true},
      modNow: {isAct: true, isPar: true, isSeg: true, isTag: true},
      //tasks: this._initActivities(user.tasks)
    }));
  }

  _getObjectSearchName(activeLink){
    let name = null;

    switch(activeLink){
      case 'Activities':
        name = 'objSearchAct';break;
      case 'Participants':
        name = 'objSearchPar';break;
      case 'Matrix':
        name = 'objSearchMat';break;
      case 'Now':
        name = 'objSearchNow';break;
    }

    return name;
  }

  _getObjectSearch(activeLink){
    let objSearch = null;

    switch(activeLink){
      case 'Activities':
        objSearch = {...this.state.objSearchAct};break;
      case 'Participants':
        objSearch = {...this.state.objSearchPar};break;
      case 'Matrix':
        objSearch = {...this.state.objSearchMat};break;
      case 'Now':
        objSearch = {...this.state.objSearchNow};break;
    }

    return objSearch
  }

  _initObjectsSearch = () => {
    return {
      idActs: [], idPars: [], idSegs: [], idTags: [],
      actStatus: 'All', isMin: true, searchTerm: '',
      actDates: {name: 'creation', startDate: null, endDate: null}
    };
  };

  _initSegsTags = (type, community) => {
    let initArray = [];

    if(type === 'segments'){
      const commSegs = [...community.segments];
      if(community.gender_as_segment){
        commSegs.push({id: 'Female', name: 'Female', color: '#000'}, {id: 'Male', name: 'Male', color: '#000'},
          {id: 'Other Gender', name: 'Other Gender', color: '#000'});
      }

      initArray = commSegs.map(s =>
        ({...s, modAct: {isSeg: true}, modPar: {isSeg: true}, modMat: {isSeg: true}, modNow: {isSeg: true}}));
    } else {
      initArray = community.tags.map(tag => (
        {...tag, modAct: {isTag: true}, modPar: {isTag: true}, modMat: {isTag: true}, modNow: {isTag: true}}));
    }

    return initArray;
  };

  // ANTI-PATTERN FOR MULTI MODAL
  _setModalBody() {
    const {activeLink, user_account, community} = this.props;
    const colors = user_account.all_colors;
    console.log(activeLink, this.state.modalType);
    let comp = null;
    const language = user_account.language;
    const I18n = {
      en: {
        hurryTitle: 'Hurry all blank & overdue tasks?', acceptCompTitle: 'Accept completed tasks?'
      },
      es: {
        hurryTitle: 'Apresurar tareas vacías y atrasadas?', acceptCompTitle: 'Aceptar tareas completadas?'
      }
    };

    switch(this.state.modalType) {
      case 'Participants':
        comp = <div className="modal-dialog">
          <SelectModal updateShowModal={this.updateShowModal}
                       modalType={this.state.modalType}
                       language={user_account.language}
                       activeUsers={this.state.activeUsers}
                       objSearch={this._getObjectSearch(activeLink)}
                       activeLink={activeLink}
                       updateArrayChkModal={this.updateArrayChkModal}
                       executeUpdateModal={this.executeUpdateModal}
                       searchModal={this.searchModal}
                       colors={colors}/>
        </div>;
        break;
      case 'Activities':
        comp = <div className="modal-dialog">
          <SelectModal updateShowModal={this.updateShowModal}
                       modalType={this.state.modalType}
                       language={user_account.language}
                       objSearch={this._getObjectSearch(activeLink)}
                       activities={this.state.activities}
                       activeLink={activeLink}
                       handleCalendarSelect={this.handleCalendarSelect}
                       updateStartExpireDatesModal={this.updateStartExpireDatesModal}
                       executeUpdateModal={this.executeUpdateModal}
                       searchModal={this.searchModal}
                       colors={colors}/>
          </div>;
        break;
      case 'Segments':
        comp = <div className="modal-dialog">
          <SelectModal updateShowModal={this.updateShowModal}
                       modalType={this.state.modalType}
                       language={user_account.language}
                       objSearch={this._getObjectSearch(activeLink)}
                       activeLink={activeLink}
                       segments={this.state.segments}
                       gender_as_segment={community.gender_as_segment}
                       activeUsers={this.state.activeUsers}
                       executeUpdateModal={this.executeUpdateModal}
                       searchModal={this.searchModal}
                       colors={colors}/>
        </div>;
        break;
      case 'Tags':
        const tagsMod = community.tags.map(tag => (
          {...tag, modAct: {isTag: true}, modPar: {isTag: true}, modMat: {isTag: true}, modNow: {isTag: true}}));

        comp = <div className="modal-dialog">
          <SelectModal updateShowModal={this.updateShowModal}
                       modalType={this.state.modalType}
                       language={user_account.language}
                       objSearch={this._getObjectSearch(activeLink)}
                       activeLink={activeLink}
                       tags={this.state.tags}
                       activities={this.state.activities}
                       executeUpdateModal={this.executeUpdateModal}
                       searchModal={this.searchModal}
                       colors={colors}/>
        </div>;
        break;
      case 'header faster':
        comp = <div className="modal-dialog">
          <DMConfirmation updateShowModal={this.updateShowModal}
                          from={'fasterHeader'}
                          title={I18n[language].hurryTitle}
                          columnHeaderActions={this.columnHeaderActions}
                          language={user_account.language}
                          colors={colors}/>
        </div>;
        break;
      case 'header accept':
        comp = <div className="modal-dialog">
          <DMConfirmation updateShowModal={this.updateShowModal}
                          from={'allCompletedHeader'}
                          title={I18n[language].acceptCompTitle}
                          columnHeaderActions={this.columnHeaderActions}
                          language={user_account.language}
                          colors={colors}/>
        </div>;
        break;
      case 'user faster':
        comp = <div className="modal-dialog">
          <DMConfirmation updateShowModal={this.updateShowModal}
                          from={'fasterUser'}
                          title={I18n[language].hurryTitle}
                          userActions={this.userActions}
                          language={user_account.language}
                          colors={colors}/>

        </div>;
        break;
      case 'user accept':
        comp = <div className="modal-dialog">
          <DMConfirmation updateShowModal={this.updateShowModal}
                          from={'allCompletedUser'}
                          title={I18n[language].acceptCompTitle}
                          userActions={this.userActions}
                          language={user_account.language}
                          colors={colors}/>
        </div>;
        break;
      case 'cell faster':
        comp = <div className="modal-dialog">
          <DMConfirmation updateShowModal={this.updateShowModal}
                          from={'fasterCell'}
                          title={I18n[language].hurryTitle}
                          activityActions={this.activityActions}
                          language={user_account.language}
                          colors={colors}/>
        </div>;
        break;
      case 'cell accept':
        comp = <div className="modal-dialog">
          <DMConfirmation updateShowModal={this.updateShowModal}
                          from={'allCompletedCell'}
                          title={I18n[language].acceptCompTitle}
                          activityActions={this.activityActions}
                          language={user_account.language}
                          colors={colors}/>
        </div>;
        break;
    }

    return comp;
  }

  clearForwardUrl(event, type, url){
    //Sometimes the browser cache takes the navigate (0) as reload (1), for that reason we must to clear the localStorage
    //event.preventDefault();
    console.log('type:', type, 'url:', url);
    localStorage.clear();
    if(type === 'common'){
      window.location.href = url;
    } else {
      //window.open(url, '_blank');
    }
  }
}
