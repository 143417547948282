import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dotdotdot from "react-dotdotdot";
import { lang } from 'moment';

export default class CSMCloseCMTY extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    updateShowModalClose: PropTypes.func.isRequired,
    closeCommunity: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      isCheck01: false,
      isCheck02: false,
      isBtnSave: true
    }
  }

  handleInputChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;

    this.setState({[name]: value}, () => {
      if(this.props.state === 'Enabled'){
        this.setState({isBtnSave: !(this.props.title === this.state.title && this.state.isCheck01 && this.state.isCheck02)});
      }else {
        this.setState({isBtnSave: !(this.props.title === this.state.title && this.state.isCheck01)});
      }

    });
  };

  render() {
    const {title, state, language, colors} = this.props;
    const i18n = {
      en: {
        close: 'Close', reopen: 'Reopen', disclaimer1: 'Participants won’\t be able to access this community anymore and will be notified.',
        disclaimer2: 'Participants will regain access to this community and will be notified.',
        disclaimer3: 'All data gathered will be preserved and active collaborators will still have access to this community.',
        type: 'Type "', hereConfirm: '" here to confirm',
        yes: 'YES', no: 'NO'
       },
       es: {
         close: 'Cerrar', reopen: 'Abrir', disclaimer1: 'Los participantes no podrán acceder a la comunidad y serán notificados.',
         disclaimer2: 'Los participantes recuperarán acceso a la comunidad y serán notificados.',
         disclaimer3: 'Toda la información será preservada y los colaboradores podrán acceder a la comunidad.',
         type: 'Escribe aquí "', hereConfirm: '" para confirmar',
         yes: 'SÍ', no: 'NO'
       }
    };

    return (
      <div className="my-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                      onClick={this.props.updateShowModalClose}>
                <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
              </button>
            </div>
            <div className="modal-body px-modal-body-warning">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-title-warning-container">
                    <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning" style={{color: colors.color4[3]}}/>
                    <p className="px-title-warning">
                      {state === 'Enabled' ? i18n[language].close : i18n[language].reopen}
                      &nbsp;<b className="warning" style={{color: colors.color4[3]}}>{title}</b>?
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xs-offset-1 col-sm-offset-1 col-md-offset-1 col-lg-offset-1"
                     style={{padding:'0'}}>
                  <div className="px-check-cont px-checkbox-modal-warning">
                    <label className="container-check">
                      <input type="checkbox" name="isCheck01" onChange={this.handleInputChange}
                             value={this.state.isCheck01}/>
                      <span className="checkmark"/>
                      {
                        state === 'Enabled' ?
                          <span>{i18n[language].disclaimer1}</span> :
                          <span>{i18n[language].disclaimer2}</span>
                      }
                    </label>
                    {
                      state === 'Enabled' &&
                      <label className="container-check">
                        <input type="checkbox" name="isCheck02" onChange={this.handleInputChange}
                               value={this.state.isCheck02}/>
                        <span className="checkmark"/>
                        <span>{i18n[language].disclaimer3}</span>
                      </label>
                    }

                  </div>
                  <input name="title" type="text" onChange={this.handleInputChange}
                           value={this.state.title} className="form-control px-input-confirmation"
                           placeholder={ i18n[language].type  + title + i18n[language].hereConfirm}/>
                </div>
              </div>
            </div>
            <div className="modal-footer px-modal-footer-warning">
              <div className="px-footer-container-buttons column">
                <button onClick={this.validCloseCommunity} type="button" className="btn px-btn sm btn-blue-base"
                  disabled={this.state.isBtnSave} style={{backgroundColor: colors.color1[3]}}>{i18n[language].yes}</button>
                <button onClick={this.props.updateShowModalClose} type="button"
                  className="btn px-btn sm btn-gray-darker inverted">{i18n[language].no}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  validCloseCommunity = () => {
    if(this.props.state === 'Enabled'){
      if(this.props.title === this.state.title && this.state.isCheck01 && this.state.isCheck02){
        this.props.closeCommunity(this.props.communityId);
      }
    } else {
      if(this.props.title === this.state.title && this.state.isCheck01){
        this.props.closeCommunity(this.props.communityId);
      }
    }

  }
}
