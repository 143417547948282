import PropTypes from 'prop-types';
import React from 'react';
import AEAttView01 from "./AE_Attributes/AEAttView01";
import AEAttrEdit01 from "./AE_Attributes/AEAttrEdit01";

export default class AEAttributes01 extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    updateTaskAttributeMode: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    cancelEditTaskAttributes: PropTypes.func.isRequired,
    updateTaskAttributes: PropTypes.func.isRequired,
    updateStartExpireDates: PropTypes.func.isRequired,
    onEditorStateChange: PropTypes.func.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    communityPartStart: PropTypes.string.isRequired,
    communityPartEnd: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {task, language, communitySocial, colors, communityPartStart, communityPartEnd} = this.props;

    return (
      <div>
        {
          task.isAttrEdit ?
            <AEAttrEdit01 task={task}
                          updateTaskAttributeMode={this.props.updateTaskAttributeMode}
                          handleChange={this.props.handleChange}
                          cancelEditTaskAttributes={this.props.cancelEditTaskAttributes}
                          updateTaskAttributes={this.props.updateTaskAttributes}
                          updateStartExpireDates={this.props.updateStartExpireDates}
                          onEditorStateChange={this.props.onEditorStateChange}
                          communitySocial={communitySocial}
                          updateShowModal={this.props.updateShowModal} 
                          language={language}
                          colors={colors}
                          communityPartStart={communityPartStart}
                          communityPartEnd={communityPartEnd}/> :
            <AEAttView01 task={task}
                         language={language}
                         updateTaskAttributeMode={this.props.updateTaskAttributeMode}
                         colors={colors}/>
        }
      </div>
    );
  }
}
