import PropTypes from 'prop-types';
import React from 'react';
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import ChAnswer from "./ChAnswer";

export default class ChAnswerList extends React.Component {
  static propTypes = {
    dataAnswers: PropTypes.array.isRequired,
    questionId: PropTypes.number.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    deleteAnswer: PropTypes.func.isRequired,
    onSortEndAnswerList: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {dataAnswers, questionId, language} = this.props;

    let other = null;
    dataAnswers.forEach(answer => {
      if(answer.img === undefined){
        other = <div className="px-answer-actv-builder" style={{marginBottom:'10px'}}>
          <input value={answer.text} className="input-answer" readOnly={true} style={{marginLeft:'78px'}}/>
          <FontAwesomeIcon onClick={() => this.props.deleteAnswer(questionId, answer.id)} icon={['fas', 'circle-xmark']}
                           className="remove-icon" style={{cursor:'pointer'}}/>
        </div>
      }
    });

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="answers-container mg-top-20">
            <SortableList questionId={questionId}
                          dataAnswers={dataAnswers}
                          updateAnswer={this.props.updateAnswer}
                          deleteAnswer={this.props.deleteAnswer}
                          updateShowModal={this.props.updateShowModal}
                          deleteMedia={this.props.deleteMedia}
                          onSortEnd={this.onSortEnd}
                          useDragHandle={true}
                          lockAxis="y"
                          useWindowAsScrollContainer={true}
                          language={language}/>

            { other }
          </div>
        </div>
      </div>
    );
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.props.onSortEndAnswerList(oldIndex, newIndex, this.props.questionId, 'choice')
  }
}

const SortableList = SortableContainer(({questionId, dataAnswers, updateAnswer, deleteAnswer, updateShowModal,
                                          deleteMedia, language}) => {
  return(
    <div>
      {dataAnswers.map((answer, index) => {
        if(answer.img !== undefined){
          return(
            <SortableItem key={`itemA-${index}`}
                          index={index}
                          questionId={questionId}
                          answer={answer}
                          updateAnswer={updateAnswer}
                          deleteAnswer={deleteAnswer}
                          updateShowModal={updateShowModal}
                          deleteMedia={deleteMedia}
                          language={language}/>
          )
        }
      })}
    </div>
  );
});

const SortableItem = SortableElement(({questionId, answer, updateAnswer, deleteAnswer, updateShowModal,
                                        deleteMedia, language}) => {
  return (
    <ChAnswer questionId={questionId}
              answer={answer}
              updateAnswer={updateAnswer}
              deleteAnswer={deleteAnswer}
              updateShowModal={updateShowModal}
              deleteMedia={deleteMedia}
              language={language}/>
  )
});
