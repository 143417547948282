import React from "react"
import TimeAgo from "react-timeago"
import Typewriter from "typewriter-effect"
import {showSuccessMsg} from "../../UI/Globals/PX_Funs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MessageCard = ({ message, currentUserId, language, avatar, lastMsgId, setLastChatId,
                       formatter, getResponseEmbeddings, from, getAnswerMicro, username,
                       response_id, loadingResponseEmbeddings }) => {
  const mainCss = message.user_id === currentUserId ? 'sender' : 'receiver'
  const humanAvatar = avatar ? avatar : '/assets/user_thin.svg'
  const avatarImg = message.kind === 'AI' ? '/assets/pixie_whiskers_40.svg' : humanAvatar
  const bodyHtml = message.body.replace(/\n/g, "<br />")

 const i18n = {
    en: {copy: 'Copy',
      resubmit: 'Error, tap to try again',
      you: 'You',
      aiAgent: 'Pixiebob Intelligence',
      goToSearch: 'See context details',
      loadingContext: 'Loading context list'
    },
    es: {copy: 'Copiar',
      resubmit: 'Error, reintenta dando clic',
      you: 'Tú',
      aiAgent: 'Pixiebob Intelligence',
      goToSearch: 'Contexto a detalle',
      loadingContext: 'Cargando lista de contexto'
    }
  }

  function parseMarkdownTable(markdownString) {
    const lines = markdownString.trim().split("\n");
    if (lines.length < 3) return null;

    const headers = lines[0]
        .split("|")
        .map((header) => header.trim())
        .filter((header) => header !== "");
    const separator = lines[1]
        .split("|")
        .map((separator) => separator.trim())
        .filter((separator) => separator !== "");
    if (
        separator.some((item) => !item.match(/^[-:]+$/)) ||
        !markdownString.includes("---")
    )
      return null;

    const rows = lines
        .slice(2)
        .map((line) =>
            line.split("|").map((cell) => cell.trim()).filter((cell) => cell !== "")
        );

    return { headers, rows };
  }

  const tableData = parseMarkdownTable(message.body);

  const renderTable = (data) => {
    return (
        <div className="table-container">
          <div className="table-row header">
            {data.headers.map((header, index) => (
                <div key={index} className="table-cell">
                  {header}
                </div>
            ))}
          </div>
          {data.rows.map((row, rowIndex) => (
              <div key={rowIndex} className="table-row">
                {row.map((cell, cellIndex) => (
                    <div key={cellIndex} className="table-cell">
                      {cell}
                    </div>
                ))}
              </div>
          ))}
        </div>
    );
  };

  const convertTableDataToTabSeparated = (headers, rows) => {
    const headerLine = headers.join("\t");
    const rowLines = rows.map((row) => row.join("\t")).join("\n");
    return headerLine + "\n" + rowLines;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
        () => {
          showSuccessMsg('Copied to Clipboard');
        },
        (err) => {
          console.error("Could not copy text: ", err);
        }
    );
  };

  const handleContainerClick = (message) => {
    if (message.kind === "AI") {
      if (tableData) {
        const tabSeparatedTable = convertTableDataToTabSeparated(
            tableData.headers,
            tableData.rows
        );
        copyToClipboard(tabSeparatedTable);
      } else {
        copyToClipboard(message.body);
      }
    } else {
      getResponseEmbeddings(message.child_id);
    }
  };

  return (
    <div onClick={from === 'wonder' ? () => handleContainerClick(message) : () => {}} className={mainCss + '-container'}
         style={{marginBottom:'0', cursor: from === 'wonder' ? 'pointer' : 'default'}}>
      <img src={avatarImg} className={"img-" + mainCss} alt=""
           style={{marginBottom: message.kind === 'AI' && '40px',
             border: '1px solid #d2d2d2',
             boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
           }}
           data-tooltip-content={ message.kind === 'AI' ? i18n[language].aiAgent : i18n[language].you}
      />
      <div className={"conversation-"+ mainCss +"-container"}>
        <div className={`conversation-item ai-conversation-item ${ message.kind !== 'AI' && 'ai-conversation-item-question'}`}
             style={{backgroundColor: message.kind === 'AI' ? 'white' : '#f8f8f8'}}>
          {
            (message.kind === 'AI' && lastMsgId === message.id) ?
                <Typewriter options={{delay: 1}}
                            onInit={(typewriter)=> {
                              typewriter
                                  .typeString(bodyHtml)
                                  .start()
                                  .callFunction(() => setLastChatId(-1))
                            }}
                /> :
                (tableData ?
                  renderTable(tableData) :
                    loadingResponseEmbeddings && message.kind !== 'AI' ? <div><FontAwesomeIcon icon={['fas','spinner']} spin/> {i18n[language].loadingContext}</div>
                      : <div dangerouslySetInnerHTML={{__html: bodyHtml}}/>
                )
          }
          <div style={{display: 'flex'}}>
            { message.kind !== 'AI' && from !== 'micro' &&
              <div id="copy-main"
                   className="status-item"
                   style={{fontSize: "16px", marginRight: "10px", color: "#DADDE1", cursor: "pointer"}}
                   data-tooltip-content={i18n[language].goToSearch}
                   onClick={from === 'wonder' ? () => handleContainerClick(message) : () => {}}>
                <FontAwesomeIcon icon={['fas','magnifying-glass']}/>
                </div>
            }
            { message.kind !== 'AI' && from !== 'micro' &&
              <div id="copy-main"
                   className="status-item"
                   style={{fontSize: "16px", marginRight: "5px", color: "#DADDE1", cursor: "pointer"}}
                   data-tooltip-content={i18n[language].copy}
                   onClick={(event) => {
                     event.stopPropagation();
                     copyToClipboard(message.body ? message.body : "");
                   }}>
                <FontAwesomeIcon icon={["fas", "copy"]}/>
              </div>
            }
            {
              message.state_1 === 'failed' &&
              <div onClick={() => getAnswerMicro(message.body, false, response_id, username, message.id)}
                   className="status-item" style={{fontSize: "16px", marginRight: "5px", color: "#D95B2D", cursor: "pointer"}}
                   data-tooltip-content={i18n[language].resubmit}>
                <span style={{marginRight: "10px", fontSize: "18px"}}><FontAwesomeIcon icon={['fas','triangle-exclamation']}/></span><span style={{fontSize: "18px"}}><FontAwesomeIcon icon={['fas','rotate']}/></span>
              </div>
            }
          </div>
        </div>
        <span className={mainCss + "-date"} style={{marginLeft:'10px'}}>
          {(message.kind === 'AI' && from === 'wonder') && <TimeAgo date={message.updated_at} formatter={formatter} title=""/>}
          &nbsp;{(message.kind === 'AI' && from === 'wonder') && `/ (E:${Math.round(message.elapsed)} secs.)`}
          &nbsp;{(message.kind === 'AI' && from === 'wonder') && `(T: ${message.temperature})`}
        </span>
      </div>
    </div>
  )
}

export default MessageCard