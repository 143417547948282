import PropTypes from 'prop-types';
import React from 'react';
import {Editor} from "react-draft-wysiwyg";
import embed from "embed-video";
import {convertToRaw} from "draft-js";
import draftToHtml from "draftjs-to-html";


export default class EditorWYSIWYGState extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    editorName: PropTypes.string.isRequired,
    editorState: PropTypes.object.isRequired,
    onEditorStateChange: PropTypes.func.isRequired,
    questionId: PropTypes.number,
    minChar: PropTypes.number,
    percentage: PropTypes.number,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      editorState: props.editorState,
      percentage: props.percentage
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.editorState !== this.props.editorState){
      this.setState({editorState: this.props.editorState})
    }
  }

  render() {
    const {name, minChar, language} = this.props;
    const i18n = {
      en: {
        textPlaceholder: 'Add Task instructions here'
      },
      es: {
        textPlaceholder: 'Agrega las instrucciones para la tarea aquí'
      }
    }

    let options = [];
    switch(name){
      case 'participants_welcome_message':
      case 'members_welcome_message':
      case 'instructions':
      case 'title':
        options = ['inline', 'embedded'];
        break;
      case 'participants_invitation':
      case 'members_invitation':
      case 'participants_invitation_signature':
      case 'members_invitation_signature':
        options = ['inline', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji'];
        break;
      case 'participants_response_oe':
        options = ['inline', 'emoji']
        break;
    }

    const barWidth = this.state.percentage + '%';
    const barBGC = this.state.percentage > 80 ? '#AAFB9B' : '#e47b93';

    return (
      <div className="px-oe-editor no-top-margin">
        <Editor editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                  options: options,
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                    bold: { className: 'bordered-option-classname' },
                    italic: { className: 'bordered-option-classname' },
                    underline: { className: 'bordered-option-classname' },
                  },
                  textAlign: {
                    options: ['left', 'center', 'right', 'justify']
                  },
                  embedded: {
                    icon: '/assets/you_tube.png',
                    popupClassName: 'px-embed-video-popup w-size',
                    embedCallback: link => {
                      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                      return (detectedSrc && detectedSrc[1]) || link;
                    },
                    defaultSize: {
                      height: 'auto',
                      width: 'auto',
                    },
                  }
                }}
                placeholder={i18n[language].textPlaceholder}
                editorClassName="px-editor-tv-oe"
                onBlur={this.onBlur}
                stripPastedStyles={true}/>
      </div>
    );
  }

  onEditorStateChange = editorState => {
    if(this.props.name === 'participants_response_oe') {
      const body = editorState.getCurrentContent().getPlainText();  //It counts 'Enter' key
      const percentage = (100 * body.length) / this.props.minChar;
      //console.log('body:', body);
      this.setState({editorState, percentage: percentage >= 100 ? 100 : percentage});
    } else {
      this.setState({editorState});
    }
  };

  onBlur = () => {
    const {editorName, name, questionId} = this.props;
    //console.log('editorName:', convertToRaw(this.state.editorState.getCurrentContent()));
    if(name === 'participants_response_oe'){
      this.props.onEditorStateChange(this.state.editorState, questionId, this.state.percentage);
    } else {
      this.props.onEditorStateChange(this.state.editorState, editorName, name, questionId);
    }
  };
}
