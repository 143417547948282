import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import v4 from 'uuid';

export default class AVTMaps extends React.Component {
  static propTypes = {
    questionId: PropTypes.number.isRequired,
    questionMap: PropTypes.object.isRequired,
    selectedItemsId: PropTypes.array,
    showMap: PropTypes.bool.isRequired,
    updateShowMap: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    updateResponseMapsId: PropTypes.func.isRequired,
    responseState: PropTypes.string.isRequired,
    responseBlocker: PropTypes.bool.isRequired,
    activeTaskId: PropTypes.number.isRequired,
    randomizeMap: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {questionMap, selectedItemsId, language, responseState, responseBlocker, randomizeMap} = this.props;

    let itemsSelected = [];
    const itemsModal = questionMap.items.map((item, index) => {
      let bgColor = null, color = null;
      selectedItemsId.forEach(selectedItemId => {
        if(parseInt(item.id) === parseInt(selectedItemId)){
          bgColor = item.color; color = '#fff';
          itemsSelected.push(
            <li key={v4()} style={{width:'91px',margin: '0 10px 0 10px'}}>
              <div className="mood-container" style={{width:'91px'}}>
                <span className="btn-mood ">
                  {item[language]}
                </span>
                {
                  responseBlocker &&
                  <div onClick={() => this.props.updateResponseMapsId(parseInt(item.id), this.props.questionId)}
                       className="btn-delete" style={{cursor:'pointer'}}>
                    <FontAwesomeIcon icon={['fas','xmark']}/>
                  </div>
                }
              </div>
            </li>
          );
        }
      });

      return(
        <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <button onClick={() => this.props.updateResponseMapsId(parseInt(item.id), this.props.questionId)}
                  className="btn btn-mood" style={{width:'100%',backgroundColor:bgColor,color:color,
            border: 'solid 0.57px' + bgColor}}>
            {item[language]}
          </button>
        </div>
      )
    });

    let addBtn = null;
    if(responseBlocker || responseState === ''){
      addBtn = <div className="mood-container" style={{width:'91px'}}>
        <span className="btn-mood add" onClick={() => this.props.updateShowMap(this.props.questionId)}>
          <div>
            <span>Add</span>
            <FontAwesomeIcon icon={['fas','circle-plus']}/>
          </div>
        </span>
      </div>;
    }

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="add-mood-container">
            {
              (responseBlocker || responseState === 'Completed') && selectedItemsId.length !== 0 &&
              <span className="mood">{questionMap.title[language]}</span>
            }
            <div className="master-mood-container">
              <ul className="list-inline">
                { itemsSelected }
                <li>
                  { addBtn }
                </li>
              </ul>
            </div>
          </div>
        </div>
        {
          this.props.activeTaskId === this.props.questionId &&
            <ReactModal isOpen={this.props.showMap} contentLabel="Activity Map Modal"
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={() => this.props.updateShowMap(this.props.questionId)}
                        className="my-modal-content" overlayClassName="my-modal-overlay">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close">
                    <i onClick={() => this.props.updateShowMap(this.props.questionId)} className="material-icons"
                       style={{color: '#333'}}>clear</i>
                  </button>
                  <h4 className="modal-title">{questionMap.title[language]}</h4>
                </div>
                <div className="modal-body maps-body">
                  <div className="row mood-modal-container">
                    { itemsModal }
                  </div>
                </div>
                <div className="separator"/>
                <div className="modal-footer modal-footer-maps">
                  <button onClick={() => this.props.updateShowMap(this.props.questionId)}
                          type="button" className="btn-delete">Ok</button>
                </div>
              </div>
            </div>
          </ReactModal>
        }
      </div>
    );
  }
}