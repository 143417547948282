import PropTypes from 'prop-types';
import React from 'react';
import PCActivity01 from "../../../../../containers/Community/Participant/PCActivity01";

export default class AEMActivityPreview extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    task: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired,
    communityLang: PropTypes.string.isRequired,
    cmntyAutoModeration: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {task, cmntyAutoModeration, communityLang, language, colors} = this.props
    const response = {
      //editorState: this._setEditorState(false),
      percentage: 0,
      showComments: false,
      camera: null, //Why 2 camera and video?
      video: null,
      newVideoThumbnail: null,
      selectedItemsId: [],
      showBlocker: false,
      attachments: [],
      attachmentsToDelIds: [],
      //question_id: question.id,
      response: { id: -1, chats: [{id: 1, body: 'Hello, nice to meet you!', kind: 'AI'}] }
    }

    const i18n = {
      en: {
        previewDisclaimer: 'This is how participants will see this Activity'
      },
      es: {
        previewDisclaimer: 'Así verán los participantes esta Actividad'
      }
    }

    return (
      <div className="modal-content modal-preview" style={{background: '#f0eff3'}}>
        <div className="modal-header">
          <button type="button" className="close">
            <i onClick={() => this.props.updateShowModal(null, null)} className="material-icons"
               style={{color: '#333'}}>clear</i>
          </button>
        </div>

        <div className="modal-body">
          <div className='px-label-warning' style={{ cursor: 'default', fontSize: '14px', 
              textTransform: 'uppercase', background: colors.color4[3], color: '#ffffff',
              padding:'10px', lineHeight:'1.5', textAlign: 'center' }}>
            {i18n[language].previewDisclaimer}
          </div>
          <PCActivity01 task={task}
                        profile={{user_id: -1}}
                        language={language}
                        updateToDoCounter={() => {}}
                        from={'AB Preview'}
                        colors={colors}
                        cmntyLanguage={communityLang}
                        helpStores={{'showSaveHelp':true}}
                        comunitySocial={false}
                        cmntyAutoModeration={cmntyAutoModeration}
                        base_url_ai={''}
                        response={response}/>
        </div>
      </div>
    );
  }
}
