import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class RSAppDashboard extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    updateShowModalMobile: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    cuState: PropTypes.string.isRequired,
    searchTerm: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {cuState, searchTerm, role_2, language, colors} = this.props;
    const i18n = {
      en: {
        search: 'Search', filterBy: 'Filter by', close: 'Close', all: 'All',
        invited: 'Invited', active: 'Active', removed: 'Removed', declined: 'Declined'
      }, es: {
        search: 'Buscar', filterBy: 'Filtrar por', close: 'Cerrar', all: 'Todas',
        invited: 'Invitado', active: 'Activo', removed: 'Removido', declined: 'Declinado'
      }
    }

    return (
      <div className="modal-body px-modal-body-warning">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-drop-search-section">
              <div className="px-input-icon">
                <div className="form-group">
                  <input type="text" name="searchTerm" onChange={this.props.handleChange} value={searchTerm}
                         className="form-control" placeholder={i18n[language].search}/>
                </div>
                <FontAwesomeIcon onClick={this.props.handleSearch} icon={['fas','search']}
                                 className="px-icon-right color-gray-base"/>
              </div>
              <button onClick={this.props.updateShowModalMobile} className="btn px-btn sm btn-blue-base btn-update" style={{backgroundColor: colors.color1[3]}}>
                {i18n[language].close}</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="mg-top-20 px-label mg-bottom-5">{i18n[language].filterBy}</div>
            <div className="px-drop-filters-container">
              <div className="px-item select">
                <div className="px-select">
                  <select name="cuState" onChange={this.props.handleChange} value={cuState}>
                    <option value="All">{i18n[language].all}</option>
                    <option value="Invited">{i18n[language].invited}</option>
                    <option value="Active">{i18n[language].active}</option>
                    <option value="Removed">{i18n[language].removed}</option>
                    <option value="Declined">{i18n[language].declined}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
