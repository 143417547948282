import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'underscore';

import ModalAddCommunity from "../components/Dashboard/Member/ModalAddCommunity";
import CardList from "../components/AppDashboard/CardList";
import R568Search from "../components/Responsive/568px/R568Search";
import MediaQuery from "react-responsive";
import ReactModal from "react-modal";

export default class AppDashboard extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    communities: PropTypes.array.isRequired,
    notifications: PropTypes.array.isRequired,
    isCollAndPart: PropTypes.bool.isRequired,
    isCollaborator: PropTypes.bool.isRequired,
    isParticipant: PropTypes.bool.isRequired,
    updateIsCollIsPart: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    cuState: PropTypes.string.isRequired,
    updateStateInvitation: PropTypes.func.isRequired,
    updateCropBlob: PropTypes.func.isRequired,
    updateActiveCommunityId: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    onDropImage: PropTypes.func.isRequired,
    handleFavourite: PropTypes.func.isRequired,
    updateHide: PropTypes.func.isRequired,
    commState: PropTypes.string.isRequired,
    updateCommunityState: PropTypes.func.isRequired,
    showModalDelete: PropTypes.bool.isRequired,
    updateShowModalDelete: PropTypes.func.isRequired,
    purgeCommunity: PropTypes.func.isRequired,
    sessionLang: PropTypes.string,
    origCommunities: PropTypes.array.isRequired,
    papt: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      filteredCommunities: props.communities,
      filterRole1: '',
      counter: this._calculateCounter(props.communities),
      checkColl: false,
      checkPart: false,
      showCollaborator: true,
      showParticipant: true,
      language: props.profile.language ? props.profile.language : props.sessionLang,
      showModalMobile: false
    };
  }

  render() {
    const {user_account, communities, profile, origCommunities, papt, language} = this.props;
    const colors = user_account.all_colors;

    const i18n = {
      en: {
        search_comm: 'Search', all: 'All', invited: 'Invited', active: 'Active', removed: 'Removed',
        declined: 'Declined', new: 'NEW', noMatchCommunities: 'No matching communities'
      },
      es: {
        search_comm: 'Buscar', all: 'Todas', invited: 'Invitado', active: 'Activo', removed: 'Removido',
        declined: 'Declinado', new: 'NUEVA', noMatchCommunities: 'No hay comunidades coincidentes'
      }
    };

    const maxNumCommunities = user_account.subscription ? user_account.subscription.max_num_comm : -1;
    //console.log(maxNumCommunities);
    const counterActiveComm = origCommunities.filter(c => c.state === 'Enabled').length;
    const counterClosedComm = origCommunities.filter(c => c.state === 'Closed').length;
    const activeCommFlag = maxNumCommunities !== -1 ? counterActiveComm >= maxNumCommunities : false;
    //console.log('activeCommFlag:', activeCommFlag);

    const modalAddCommunity = user_account.profile.role === 'Superadmin' &&
      <ModalAddCommunity maxNumCommunities={maxNumCommunities}
                         communitiesLength={communities.length}
                         language={this.state.language}
                         papt={user_account.company.papt}
                         companyId={user_account.company.id}
                         colors={colors}
                         profile={profile}
                         subscription={user_account.subscription}
                         company={user_account.company}/>;

    let communitiesCards = [];
    if(communities.length !== 0) {
      if(this.refs.lblNoResults) {
        this.refs.lblNoResults.innerHTML = '';
        this.refs.lblNoResults.style.display = 'none';
      }

      communitiesCards = <CardList communities={communities}
                                   updateStateInvitation={this.props.updateStateInvitation}
                                   updateCropBlob={this.props.updateCropBlob}
                                   updateActiveCommunityId={this.props.updateActiveCommunityId}
                                   updateShowModal={this.props.updateShowModal}
                                   onDropImage={this.props.onDropImage}
                                   handleFavourite={this.props.handleFavourite}
                                   isCollaborator={this.props.isCollaborator}
                                   updateHide={this.props.updateHide}
                                   commState={this.props.commState}
                                   updateCommunityState={this.props.updateCommunityState}
                                   showModalDelete={this.props.showModalDelete}
                                   updateShowModalDelete={this.props.updateShowModalDelete}
                                   purgeCommunity={this.props.purgeCommunity}
                                   counterActiveComm={counterActiveComm}
                                   counterClosedComm={counterClosedComm}
                                   papt={papt}
                                   maxPapt={user_account.company ? user_account.company.papt : -1}
                                   language={this.state.language}
                                   colors={user_account.all_colors}
                                   subscription={user_account.subscription}/>

    } else {
      if(this.refs.lblNoResults) {
        this.refs.lblNoResults.innerHTML = i18n[language].noMatchCommunities;
        this.refs.lblNoResults.style.display = 'block';
      }
    }

    let role1Filters = '';
    let role1FiltersPart = '';
    if(this.props.isCollAndPart) {
      role1Filters = <button onClick={() => this.props.updateIsCollIsPart('isCollaborator')} type="button"
                             className={this.props.isCollaborator ? 'btn px-btn px-btn-collaborator sm active' :
                               'btn px-btn px-btn-collaborator sm '}>Collaborator</button>;
      role1FiltersPart = <button onClick={() => this.props.updateIsCollIsPart('isParticipant')} type="button"
                                 className={this.props.isParticipant ? 'btn px-btn px-btn-participant sm active' :
                                   'btn px-btn px-btn-participant sm '}>Participant</button>;
    }

    //console.log(origCommunities.filter(c => c.state === 'Enabled').length, activeCommFlag);
    let newCommunity = '';
    if(profile.role === 'Superadmin'){
      newCommunity = <button type="button" className="btn px-btn sm btn-blue-base px-btn-new-comm"
                             style={{backgroundColor: colors.color1[3]}}
                             data-toggle="modal"
                             data-target="#modalAddCommunity"
                             disabled={activeCommFlag}>
        {`${i18n[language].new} ${maxNumCommunities !== -1 ? '('+ counterActiveComm + ' of '+ maxNumCommunities +')' : ''}`}
      </button>;
    }
    
    return (
      <div>
        { modalAddCommunity }
        <div className="px-dashboard-comm-container">
          <div className="row">
            <div className="col-md-12">
              <div className="px-search-bar-dash-comm-cont main-dashboard" role="search">
                <div className="px-filter-left-container main-dashboard">
                  <div className="px-input-icon main-dashboard">
                    <input type="text" name="searchTerm" onChange={this.props.handleChange} value={this.props.searchTerm}
                          className="form-control icon-right"
                          placeholder={i18n[language].search_comm}/>
                    <FontAwesomeIcon icon={['fas', 'magnifying-glass']} onClick={this.props.handleSearch}
                                     className="px-icon-right color-gray-base"/>
                  </div>
                  <div className="px-select main-dashboard">
                    <select name="cuState" onChange={this.props.handleChange} value={this.props.cuState}>
                      <option value="All">{i18n[language].all}</option>
                      <option value="Invited">{i18n[language].invited}</option>
                      <option value="Active">{i18n[language].active}</option>
                      <option value="Removed">{i18n[language].removed}</option>
                      <option value="Declined">{i18n[language].declined}</option>
                    </select>
                  </div>
                </div>
                <div className="px-filter-right-container main-dashboard">
                  { role1Filters }
                  { role1FiltersPart }
                  { newCommunity }

                  <MediaQuery maxDeviceWidth={568}>
                    <div onClick={this.updateShowModalMobile} style={{marginLeft:'auto'}}>
                      <FontAwesomeIcon icon={['fas', 'magnifying-glass']} className="px-icon-search-responsive main-dashboard"/>
                    </div>
                    {
                      this.state.showModalMobile &&
                      <ReactModal isOpen={this.state.showModalMobile} contentLabel="Modal"
                                  shouldCloseOnOverlayClick={true} className="my-modal-content"
                                  overlayClassName="my-modal-overlay" onRequestClose={this.updateShowModalMobile}>
                        <R568Search from={'App Dashboard'}
                                    type={profile.role}
                                    updateShowModalMobile={this.updateShowModalMobile}
                                    handleChange={this.props.handleChange}
                                    cuState={this.props.cuState}
                                    searchTerm={this.props.searchTerm}
                                    handleSearch={this.props.handleSearch}
                                    language={language}
                                    colors={colors}/>
                      </ReactModal>
                    }
                  </MediaQuery>
                </div>
              </div>
              <label className="px-empty-msg" style={{marginTop:'100px', color: colors.color4[3]}} ref="lblNoResults"/>
            </div>
          </div>

          <div className="row">
            { communitiesCards }
          </div>
        </div>
      </div>
    )
  }

  updateShowModalMobile = () => {
    console.log('updateShowModalMobile');
    this.setState(prevState => ({showModalMobile: !prevState.showModalMobile}));
  };

  handleSearch = (event) => {
    event.preventDefault();

    if(this.props.searchTerm !== ''){
      $.ajax({
        url: '/communities/search/' + this.props.searchTerm,
        method: 'GET',
        dataType: 'JSON',
        success: function (communities) {
          console.log(communities);
          // I need to reset the array in order the view can refresh all cards, check out why
          // A: Maybe, it's just the componentWillReceiveProps for Child Cards.jsx
          this.setState({filteredCommunities: []});
          /*const filteredCommunities = _.each(communities, function (community) {
            if(this.state.checkColl && community.role_1 === 'Collaborator'){
              return community;
            } else {
              return community;
            }
          }.bind(this));*/
          this.setState({filteredCommunities: communities});
        }.bind(this)
      });
    } else {
      // Empty search returns all Communities
      // To review if there is another way to don't use the callback to update the result Cards
      this.setState({filteredCommunities: []}, function () {
        this.setState({filteredCommunities: this.props.communities});
      });
    }

  };

  updateCommunities = (communities) => {
    //console.log(communities);
    // I need to reset the array in order the view can refresh all cards, check out why
    // A: Maybe, it's just the componentWillReceiveProps for Child Cards.jsx
    this.setState({filteredCommunities: []});
    this.setState({filteredCommunities: communities});
  };

  filterRole1 = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({ [name]: value }, function () {
      this.searchByStatus();
    });
  };

  handleShowCommunities = (role) => {
    switch(role){
      case 'Collaborator':
        this.setState({showCollaborator: !this.state.showCollaborator}, function () {
          this.searchByStatus();
        });
        break;
      case 'Participant':
        this.setState({showParticipant: !this.state.showParticipant}, function () {
          this.searchByStatus();
        });
        break;
    }
  };

  searchByStatus = () => {
    const communities = this.props.communities;
    let filteredCommunities = [];

    //const collaborator = this.state.checkColl ? 'Collaborator' : 'All';
    //const participant = this.state.checkPart ? 'Participant' : 'All';
    const collaborator = this.state.showCollaborator ? 'Collaborator' : 'All';
    const participant = this.state.showParticipant ? 'Participant' : 'All';

    _.each(communities, function (community) {
      if(community.role_1 === collaborator || community.role_1 === participant) {
        filteredCommunities.push(community);
      }
    });

    if(collaborator === 'All' && participant === 'All'){
      this.setState({filteredCommunities: communities});
    } else {
      this.setState({filteredCommunities: filteredCommunities})
    }
  };

  _calculateCounter(communityUsers){
    let cColl = 0, cPart = 0;

    _.each(communityUsers, function (cu) {
      if(cu.role_1 === 'Collaborator')
        cColl++;
      if(cu.role_1 === 'Participant')
        cPart++;
    });

    return [cColl, cPart];
  }
}
