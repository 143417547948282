import PropTypes from 'prop-types';
import React from 'react';
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import spanishStrings from "react-timeago/lib/language-strings/es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";
import MediaQuery from 'react-responsive';
import Masonry from 'react-masonry-component';
import LazyLoad from "react-lazy-load";
import InfiniteScroll from "react-infinite-scroller";
import v4 from 'uuid';
import moment from "moment";

import Loading from "../../../components/UI/Globals/Loading";
import P_TaskCard from "../../../components/Community/Dashboard/Participant/P_TaskCard";
import P_NowCard from "../../../components/Community/Dashboard/Participant/P_NowCard";
import PModal from "../../../components/Community/Dashboard/Participant/PModal";
import DNCard from "../../../components/Community/Dashboards/Now/DNCard";
import { getNowUTC, getParticipantToDoCounter, sort_now, sortCollTasks, sortSortedTasks}
from "../../../components/UI/Globals/PX_Funs";

export default class PCDashboard extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    collaborators: PropTypes.array.isRequired,
    updateToDoCounter: PropTypes.func.isRequired,
    user_account: PropTypes.object.isRequired,
    activeUsers: PropTypes.array.isRequired,
    language: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      tasksDone: [],
      showModal: false,
      clickedTaskId: null,
      type: null,
      activeColumn: 'isCollapsedToDo',
      isCollapsedToDo: true,
      isCollapsedNow: true,
      isCloseNow: false,
      nowFilter: 'All',
      followed: [],
      isResize: false,
      rActiveTab: 'ToDo',
      communityNow: [],
      isLoadingToDo: true,
      isLoadingNow: props.community.social_flag,
      nowItems: [],
      offsetDays: 0,
      allNowPreItems: [],
      filterNowPreItems: [],
      batch: 1,
      nItems: 5,
      addScrollEvent: false,
      isLoadingInfDesktop: false,
      isLoadingInfMobile: false,
      showModalNowFilters: false,
      // NEW
      offset: 0,
      hasMoreItems: true,
      modalActIsRequiredDone: true
    };
  }

  componentDidMount() {
    // TO-DO maybe when I have the tasks, add a visualState: 'Expired', 'Tonight', 'Required' and so on
    // in order to do the validations one time, Why when the animation for expand/close it calls the url again?
    this._getTasks();
    if(this.props.community.social_flag){
      this._getTasksNow();
    }

    //this._getFollowed();
    window.addEventListener("resize", this._handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._handleResize);
    $('#divPanelNow').unbind('scroll');
    $('#divPanelNowMobile').unbind('scroll');
  }

  render() {
    const { community, language, collaborators, user_account, activeUsers } = this.props;
    const { communityNow, isCollapsedToDo, isCollapsedNow, activeColumn, nowFilter } = this.state;
    const colors = user_account.all_colors;

    const dashboard_empty_msg = community.language === 'es' ? community.empty_dashboard_msg_es : community.empty_dashboard_msg
    const dashboard_empty_msg_present = !(dashboard_empty_msg === '' || dashboard_empty_msg === null)

    const now = community.default_tz_flag ? moment().format('YYYY-MM-DD') : getNowUTC(community.default_tz);

    let taskDoneArray = [];
    let toDoUnseenCommentCounter = 0;
    let doneUnseenCommentCounter = 0;
    // TO-DO map without else return 'undefined', if you turn off live all activities, then it will be [undefined]
    // improve this maybe with filter or forEach
    const tasksToDo = this.state.tasks.map((task, index) => {
      //console.log(task);
      if(task.visibility === 'enabled') {
        if(task.isTaskDone) {
          taskDoneArray.push(task)
        } else {
          toDoUnseenCommentCounter += task.sum_comments_replies_unseen;

          return (
            <P_TaskCard key={index}
                        task={task}
                        forwardTask={this.forwardTask}
                        updateShowTask={this.updateShowTask}
                        from={'TODO'}
                        language={language}
                        updateShowModal={this.updateShowModal}
                        now={now}
                        colors={colors}/>
          )
        }
      }
    });

    const taskDone = taskDoneArray.map((task, index) => {
      doneUnseenCommentCounter += task.sum_comments_replies_unseen;
      return (
        <P_TaskCard key={index}
                    task={task}
                    forwardTask={this.forwardTask}
                    updateShowTask={this.updateShowTask}
                    from={'DONE'}
                    language={this.props.language}
                    updateShowModal={this.updateShowModal}
                    now={now}
                    colors={colors}/>
      )
    });

    const i18n = {
      en: {
        to_do: 'To Do', to_do_mobile: 'To Do', done: 'Done!', comm_now: 'Community Now!', comm_now_mobile: 'Now', response_on: 'on',
        reply_on: 'replied on', comment_on: 'commented on', liked: 'liked', noToDo_1: 'There\'s no activities available to complete at this time.',
        noToDo_2: 'You\'ll be notified when new activities become available', noToDo_3: 'If you have any questions contact',
        noDone: 'Activities that you have completed will appear here.', noNow: 'At this moment there’s no activity in this community.',
        f_all: 'All', f_responses: 'Responses', f_comments: 'Comments', f_likes: 'Likes', f_replies: 'Replies', f_followed: 'Followed', 
        nowFilters: 'Now filters', contentNA: 'Content not available for this screen size', loading: 'LOADING', unread_header: 'You have unread comments in some activities',
        unread_card: 'You have unread comments'
      },
      es: {
        to_do: 'Pendientes', done: 'Listas!', comm_now: 'Comunidad Ahora!', response_on: 'en',
        to_do_mobile: 'Pendientes', comm_now_mobile: 'Ahora',
        reply_on: 'respondió a', comment_on: 'comentó en', liked: 'le gustó',
        noToDo_1: 'No hay actividades disponibles para completar en este momento.',
        noToDo_2: 'Se te notificará cuando haya nuevas actividades disponibles.',
        noToDo_3: 'Si tienes alguna duda ponte en contacto con',
        noDone: 'Las actividades que hayas completado aparecerán aquí.',
        noNow: 'En este momento no hay actividad en esta comunidad.',
        f_all: 'Todo', f_responses: 'Respuestas', f_comments: 'Comentarios', f_likes: 'Likes', f_replies: 'Contestaciones',
        f_followed: 'Followed', nowFilters: 'Filtros Comunidad Ahora', contentNA: 'Contenido no disponible para este tamaño de dispositivo',
        loading: 'CARGANDO', unread_header: 'Tiene comentarios no leídos en sus actividades',
        unread_card: 'Tiene comentarios no leídos'
      }
    };

    const toDoWidth = !community.social_flag && 'calc(70%)';
    const doneWidth = !community.social_flag && 'calc(26%)';
    const doneMargin = !community.social_flag && '0';

    let activeColls = [];
    collaborators.forEach(user => {
      if (user.state === 'Enabled' && (user.role_2 === 'Owner' || user.role_2 === 'Moderator')) {
        activeColls.push(<p key={user.user_id} style={{ marginBottom: '0' }} className="px-empty-msg-dash">
          <a href={'mailto:' + user.email + '?subject=Help from To Do List'} className="color-blue-base">{user.username}</a>
        </p>);
      }
    });

    const toDoUnique = [...new Set(tasksToDo)];
    //console.log(toDoUnique.length);
    let toDoFlag = null;
    if (toDoUnique.length === 0) {
      toDoFlag = true;
    } else {
      toDoFlag = toDoUnique.length === 1 && toDoUnique[0] === undefined;
    }
    //console.log(toDoFlag);

    const toDoIcon = isCollapsedToDo ? 'up-right-and-down-left-from-center' : 'down-left-and-up-right-to-center';
    const nowIcon = isCollapsedNow ? 'up-right-and-down-left-from-center' : 'down-left-and-up-right-to-center';
    let classTodo = null;
    let classDone = null;
    let classNow = null;

    switch(activeColumn) {
      case 'isCollapsedToDo':
        if (isCollapsedToDo) {
          classTodo = 'panel panel-default panel-to-do';
          classDone = 'panel panel-default panel-done';
          classNow = 'panel panel-default panel-community-now';
        } else {
          classTodo = 'panel panel-default panel-to-do expand-to-do';
          classDone = 'panel panel-default panel-done expand-to-do';
          classNow = 'panel panel-default panel-community-now expand-to-do';
        }
        break;
      case 'isCollapsedNow':
        if (isCollapsedNow) {
          classTodo = 'panel panel-default panel-to-do';
          classDone = 'panel panel-default panel-done';
          classNow = 'panel panel-default panel-community-now';
        } else {
          classTodo = 'panel panel-default panel-to-do expand-community-now';
          classDone = 'panel panel-default panel-done expand-community-now';
          classNow = 'panel panel-default panel-community-now expand-community-now';
        }
        break;
      case 'Now':
        classTodo = 'panel panel-default panel-to-do expand-community-now';
        classDone = 'panel panel-default panel-done expand-community-now';
        classNow = 'panel panel-default panel-community-now expand-community-now';
        break;
      case 'ToDo':
        classTodo = 'panel panel-default panel-to-do expand-to-do';
        classDone = 'panel panel-default panel-done expand-to-do';
        classNow = 'panel panel-default panel-community-now expand-to-do';
        break;
    }

    const masonryOptions = {
      transitionDuration: 0,
      percentPosition: true
    };

    const isLoading = <div className="px-card-dash-part-cont">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ textAlign: 'center', display: 'flex',
          flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src="https://dev-pixiebob.s3-ap-southeast-2.amazonaws.com/loader_infinity_original.gif"
            style={{ width: '55px', height: '25px' }} alt=""/>
          <span style={{ fontFamily: "Sharp Sans SemiBold", fontSize: '10px', fontWeight: '800', lineHeight: '13px',
            color:'#afbdc1' }}>{i18n[language].loading}</span>
        </div>
      </div>
    </div>;
    let toDoDesktop;
    let todoMobile = null;
    let doneDesktop = null;
    let doneMobile = null;
    let nowDesktop = null;
    let nowMobile = null;

    if(this.state.isLoadingToDo) {
      toDoDesktop = isLoading;
      todoMobile = isLoading;
      doneDesktop = isLoading;
      nowDesktop = isLoading;
    } else {
      if(!toDoFlag) {
        toDoDesktop = <div className="px-card-dash-part-cont">
          {tasksToDo}
        </div>;
        todoMobile = <div className="px-cont-dash-resp" style={{ display: this.state.rActiveTab === 'ToDo' ? 'block' : 'none' }}>
          {tasksToDo}
        </div>;
      } else {
        toDoDesktop = <div className=" px-card-dash-part-cont text-center">
          <p className="px-empty-msg-dash">{dashboard_empty_msg_present ? dashboard_empty_msg : i18n[language].noToDo_1}</p>
          <p className="px-empty-msg-dash">{!dashboard_empty_msg_present && i18n[language].noToDo_2}</p>
          { community.empty_dashboard_img !== null && <img src={community.empty_dashboard_img} alt='' style={{height: '150px', marginTop: '40px', opacity: '0.5'}}/>}
          {/* <p className="px-empty-msg-dash" style={{ marginBottom: '0', marginTop: '25px' }}>{i18n[language].noToDo_3}</p> */}
          {activeColls}
        </div>;
        todoMobile = <div className="px-cont-dash-resp text-center"
          style={{ display: this.state.rActiveTab === 'ToDo' ? 'block' : 'none' }}>
          <p className="px-not-msg-done">{dashboard_empty_msg_present ? dashboard_empty_msg : i18n[language].noToDo_1 }</p>
          <p className="px-not-msg-done">{!dashboard_empty_msg_present && i18n[language].noToDo_2}</p>
          { community.empty_dashboard_img !== null && <img src={community.empty_dashboard_img} alt='' style={{height: '100px', marginTop: '30px', opacity: '0.5'}}/>}
          {/* <p className="px-not-msg-done" style={{ marginBottom: '0', marginTop: '25px' }}>{i18n[language].noToDo_3}</p>
          {activeColls} */}
        </div>;
      }

      if(taskDone.length !== 0) {
        doneDesktop = <div className=" px-card-dash-part-cont">
          {taskDone}
        </div>;
        doneMobile = <div className="px-cont-dash-resp" style={{ display: this.state.rActiveTab === 'Done' ? 'block' : 'none' }}>
          {taskDone}
        </div>;
      } else {
        doneDesktop = <div className=" px-card-dash-part-cont">
          <p className="px-empty-msg-dash">{i18n[community.language].noDone}</p>
        </div>;
        doneMobile = <div className="px-cont-dash-resp" style={{ display: this.state.rActiveTab === 'Done' ? 'block' : 'none' }}>
          <p className="px-not-msg-done text-center">{i18n[community.language].noDone}</p>
        </div>;
      }

      const loadingNow = <div className="px-card-dash-part-cont">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ textAlign: 'center', display: 'flex',
            flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img src="https://dev-pixiebob.s3-ap-southeast-2.amazonaws.com/loader_infinity_original.gif"
              style={{ width: '55px', height: '25px' }} alt=""/>
            <span style={{ fontFamily: "Sharp Sans SemiBold", fontSize: '10px', fontWeight: '800', lineHeight: '13px',
              color:'#afbdc1' }}>{i18n[language].loading}</span>
          </div>
        </div>
      </div>;
      //console.log(communityNow);
      if(communityNow.length !== 0) {
        nowDesktop = <div id="divPanelNow" className="row px-card-dash-part-cont">
          <InfiniteScroll
            pageStart={0}
            loadMore={this._getTasksNow}
            hasMore={this.state.hasMoreItems}
            loader={<Loading key={0}/>}
            useWindow={false}>
            <Masonry>
              { communityNow }
            </Masonry>
          </InfiniteScroll>

          {/*<Masonry enableResizableChildren={this.state.activeColumn === 'ToDo'}
                                   options={masonryOptions}>*/}
          {/*this.state.isLoadingNow ? loadingNow : communityNow*/}
          {/*</Masonry>*/}
          {
            this.state.isLoadingInfDesktop &&
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ textAlign: 'center', display: 'flex',
                flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src="https://dev-pixiebob.s3-ap-southeast-2.amazonaws.com/loader_infinity_original.gif"
                  style={{ width: '55px', height: '25px' }} alt=""/>
                <span style={{ fontFamily: "Sharp Sans SemiBold", fontSize: '10px', fontWeight: '800', lineHeight: '13px',
                  color:'#afbdc1' }}>{i18n[language].loading}</span>
              </div>
            </div>
          }
        </div>;
        nowMobile = <div style={{ display: this.state.rActiveTab === 'Now' ? 'block' : 'none', position: 'relative' }}>
          <div className="px-filter-part-dash dropdown" style={{ boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.07)', backgroundColor: this.state.nowFilter === 'All' ? '#ffffff' : colors.color1[3]}}>
            <div onClick={this.updateShowModalNowFilters} data-toggle="dropdown" style={{
              height: "30px", width: "30px", display: "flex", justifyContent: "center",
              alignItems: "center"
            }}>
              <FontAwesomeIcon icon={['fas', 'filter']} style={{ color: this.state.nowFilter === 'All' ? colors.colorParticipants[0] : '#ffffff'}} />
              <ul className="dropdown-menu dropdown-general-options-no-hide" style={{ left: 'inherit', right: '0' }}>
                <li onClick={() => this.updateNowFilter('All')}><a>{i18n[language].f_all}</a></li>
                <li onClick={() => this.updateNowFilter('Responses')}><a>{i18n[language].f_responses}</a></li>
                <li onClick={() => this.updateNowFilter('CommentsReplies')}><a>{i18n[language].f_comments}</a></li>
                <li onClick={() => this.updateNowFilter('Likes')}><a>{i18n[language].f_likes}</a></li>
                {/* <li onClick={() => this.updateNowFilter('Comments')}><a>{i18n[language].f_comments}</a></li>
                <li onClick={() => this.updateNowFilter('Replies')}><a>{i18n[language].f_replies}</a></li> */}
                <li onClick={() => this.updateNowFilter('Followed')}><a>{i18n[language].f_followed}</a></li>
              </ul>
            </div>
          </div>
          <div id="divPanelNowMobile" className="px-cont-dash-resp">
            <InfiniteScroll
              pageStart={0}
              loadMore={this._getTasksNow}
              hasMore={this.state.hasMoreItems}
              loader={<Loading key={0}/>}
              useWindow={false}>
              {communityNow}
            </InfiniteScroll>
            {/*communityNow*/}
            {
              this.state.isLoadingInfMobile &&
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ textAlign: 'center', display: 'flex',
                  flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <img src="https://dev-pixiebob.s3-ap-southeast-2.amazonaws.com/loader_infinity_original.gif"
                    style={{ width: '55px', height: '25px' }} alt=""/>
                  <span style={{ fontFamily: "Sharp Sans SemiBold", fontSize: '10px', fontWeight: '800', lineHeight: '13px',
                    color:'#afbdc1' }}>{i18n[language].loading}</span>
                </div>
              </div>
            }
          </div>
        </div>;
      } else {
        if(this.state.isLoadingNow) {
          nowDesktop = isLoading;
          nowMobile = isLoading;
        } else {
          nowDesktop = <div className="row px-card-dash-part-cont">
            <p className="px-empty-msg-dash">{i18n[language].noNow}</p>
          </div>;
          nowMobile = <div style={{ display: this.state.rActiveTab === 'Now' ? 'block' : 'none', position: 'relative' }}>
            <div className="px-cont-dash-resp">
              <p className="px-not-msg-done text-center">{i18n[language].noNow}</p>
            </div>
          </div>
        }
      }
    }
    //console.log('doneUnseenCommentCounter:', doneUnseenCommentCounter);
    return (
      <React.Fragment>
        <MediaQuery minDeviceWidth={769}>
          <div className="px-container-participant-dashboard">
            <div className="row">
              <div className="col-lg-12">
                <div className={classTodo} style={{ width: toDoWidth }}>
                  <div className="panel-heading" style={{backgroundColor: colors.colorParticipants[0]}}>
                    <div className="px-support-tooltip right is_hidden px-support-participant"
                      style={{ left: '95%', top: '200%' }}>
                      <p>Each of the cards listed in this first column is an Activity for you to complete.</p>
                      <p>Each activity can have multiple tasks or questions within.</p>
                      <p> Watch out, they can expire! Submit your responses before it's too late.</p>
                      <i />
                    </div>
                    <div className="circle-container">
                      { toDoUnseenCommentCounter > 0 && <div className="px-unseen" style={{backgroundColor: colors.color4[3]}} data-tooltip-content={i18n[language].unread_header}/> }
                      <FontAwesomeIcon icon={['fas', 'pencil']} className="edit" style={{ color: colors.colorParticipants[0] }} />
                    </div>
                    <span className="title">{i18n[this.props.language].to_do}</span>
                    {
                      community.social_flag &&
                      <FontAwesomeIcon onClick={() => this.updateCollapsed('isCollapsedToDo')} icon={['fas', toDoIcon]}
                        className="contract-icon" />
                    }
                  </div>
                  <div onClick={() => this.fullExpand('ToDo')} className="panel-body px-info-regular">
                    { toDoDesktop }
                    <div className="text-expand" onClick={() => this.fullExpand('ToDo')}
                         style={{ color: colors.colorParticipants[0]}}>Expand To Do
                    </div>
                  </div>
                </div>

                <div className={classDone} style={{ width: doneWidth, marginRight: doneMargin }}>
                  <div className="panel-heading" style={{ backgroundColor: colors.colorParticipants[1] }}>
                    <div className="circle-container">
                      {doneUnseenCommentCounter > 0 && <div className="px-unseen" style={{ backgroundColor: colors.color4[3] }} data-tooltip-content={i18n[language].unread_header}/> }
                      <FontAwesomeIcon icon={['far', 'check']} className="edit" style={{ color: colors.colorParticipants[1] }}/>
                    </div>
                    <span className="title">{i18n[language].done}</span>
                  </div>
                  <div className="panel-body px-info-regular" style={{ textAlign: taskDone.length === 0 && 'center' }}>
                    { doneDesktop }
                  </div>
                </div>
                {
                  community.social_flag &&
                  <div className={classNow}>
                    <div className="panel-heading" style={{ backgroundColor: colors.colorParticipants[2] }}>
                      <div className="px-support-tooltip top is_hidden px-support-participant"
                        style={{ left: '75%', top: '-10%' }}>
                        <p>See what other participants are posting in the community by clicking
                          the cards listed in this column.</p><i />
                      </div>
                      <div className="circle-container"> <FontAwesomeIcon icon={['far', 'signal-stream']} style={{ color: colors.colorParticipants[2] }} /></div>
                      <span className="title">{i18n[this.props.language].comm_now}</span>
                      <FontAwesomeIcon icon={['fas', 'filter']} className="contract-icon filter" data-toggle="dropdown"
                        style={{ color: this.state.nowFilter === 'All' ? '#ffffff' : colors.colorParticipants[0] }} />
                      <ul className="dropdown-menu dropdown-general-options-no-hide">
                        <li onClick={() => this.updateNowFilter('All')} ><a style={{ color: this.state.nowFilter === 'All' && colors.colorParticipants[0]}}>{i18n[language].f_all}</a></li>
                        <li onClick={() => this.updateNowFilter('Responses')}><a style={{ color: this.state.nowFilter === 'Responses' && colors.colorParticipants[0]}}>{i18n[language].f_responses}</a></li>
                        <li onClick={() => this.updateNowFilter('CommentsReplies')}><a style={{ color: this.state.nowFilter === 'CommentsReplies' && colors.colorParticipants[0] }}>{i18n[language].f_comments}</a></li>
                        {/* <li onClick={() => this.updateNowFilter('Comments')}><a>{i18n[language].f_comments}</a></li> */}
                        {/* <li onClick={() => this.updateNowFilter('Replies')}><a>{i18n[language].f_replies}</a></li> */}
                        <li onClick={() => this.updateNowFilter('Likes')}><a style={{ color: this.state.nowFilter === 'Likes' && colors.colorParticipants[0] }}>{i18n[language].f_likes}</a></li>
                        <li onClick={() => this.updateNowFilter('Followed')}><a style={{ color: this.state.nowFilter === 'Followed' && colors.colorParticipants[0] }}>{i18n[language].f_followed}</a></li>
                      </ul>
                      <FontAwesomeIcon onClick={() => this.updateCollapsed('isCollapsedNow')} icon={['fas', nowIcon]}
                        className="contract-icon mg-left-10" />
                    </div>

                    <div onClick={() => this.fullExpand('Now')} className="panel-body px-info-regular"
                      style={{ textAlign: communityNow.length === 0 && 'center' }}>
                      { nowDesktop }
                      <div className="text-expand" onClick={() => this.fullExpand('Now')} style={{ color: colors.colorParticipants[2] }}>Expand Community Now</div>
                    </div>
                  </div>
                }
                {
                  this.state.showModal &&
                  <ReactModal isOpen={this.state.showModal} contentLabel="Participant Dashboard Modal"
                    shouldCloseOnOverlayClick={true} onRequestClose={() => this.updateShowModal(null)}
                    className="px-modal-content" overlayClassName="px-modal-overlay">
                    <PModal updateShowModal={this.updateShowModal}
                            clickedTask={this.state.tasks.find(task => task.id === this.state.clickedTaskId)}
                            type={this.state.type}
                            language={language}
                            modalActIsRequiredDone={this.state.modalActIsRequiredDone}
                            colors={colors}/>
                  </ReactModal>
                }
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={768}>
          <div>
            <div className="px-head-content">
              {
                !community.social_flag ?
                  <div onClick={() => this.updateRActiveTab('ToDo')}
                    className={this.state.rActiveTab === 'ToDo' ? 'item to-do no-social active' : 'item to-do no-social'} 
                    style={{ backgroundColor: this.state.rActiveTab !== 'ToDo' && colors.colorParticipants[0] }}>
                    <div className="icon-action-resp" style={{ backgroundColor: this.state.rActiveTab === 'ToDo' && colors.colorParticipants[0] }}>
                      { toDoUnseenCommentCounter > 0 && <div className="px-unseen-mobile" style={{backgroundColor: colors.color4[3]}} data-tooltip-content={i18n[language].unread_header}/> }
                      <FontAwesomeIcon icon={['fas', 'pencil']} style={{ color: this.state.rActiveTab === 'ToDo' ? '#ffffff' : colors.colorParticipants[0] }}/>
                    </div>
                    <span>{i18n[this.props.language].to_do_mobile}</span>
                  </div>
                  :
                  <div onClick={() => this.updateRActiveTab('ToDo')}
                    className={this.state.rActiveTab === 'ToDo' ? 'item to-do active' : 'item to-do'}
                    style={{ backgroundColor: this.state.rActiveTab !== 'ToDo' && colors.colorParticipants[0] }}>
                    <div className="icon-action-resp" style={{ backgroundColor: this.state.rActiveTab === 'ToDo' && colors.colorParticipants[0] }}>
                      {toDoUnseenCommentCounter > 0 && <div className="px-unseen-mobile" style={{ backgroundColor: colors.color4[3] }} data-tooltip-content={i18n[language].unread_header}/> }
                      <FontAwesomeIcon icon={['fas', 'pencil']} style={{ color: this.state.rActiveTab === 'ToDo' ? '#ffffff' : colors.colorParticipants[0] }} />
                    </div>
                    <span>{i18n[this.props.language].to_do_mobile}</span>
                  </div>
              }
              {
                !community.social_flag ?
                  <div onClick={() => this.updateRActiveTab('Done')}
                    className={this.state.rActiveTab === 'Done' ? 'item done active no-social' : 'item done no-social'}
                    style={{ position: 'relative', backgroundColor: this.state.rActiveTab !== 'Done' && colors.colorParticipants[1] }}>
                    {doneUnseenCommentCounter > 0 && <div className="px-unseen-mobile-done" style={{ backgroundColor: colors.color4[3] }} data-tooltip-content={i18n[language].unread_header}/> }
                    <div className="icon-action-resp" style={{ backgroundColor: this.state.rActiveTab === 'Done' && colors.colorParticipants[1] }}>
                      <FontAwesomeIcon icon={['far', 'check']} style={{ color: this.state.rActiveTab === 'Done' ? '#ffffff' : colors.colorParticipants[1] }} />
                    </div>
                    <span>{i18n[language].done}</span>
                  </div>
                  :
                  <div onClick={() => this.updateRActiveTab('Done')}
                    className={this.state.rActiveTab === 'Done' ? 'item done active' : 'item done'}
                    style={{ position: 'relative', backgroundColor: this.state.rActiveTab !== 'Done' && colors.colorParticipants[1] }}>
                    {doneUnseenCommentCounter > 0 && <div className="px-unseen-mobile-done" style={{ backgroundColor: colors.color4[3] }} data-tooltip-content={i18n[language].unread_header}/>}
                    <div className="icon-action-resp" style={{ backgroundColor: this.state.rActiveTab === 'Done' && colors.colorParticipants[1] }}>        
                      <FontAwesomeIcon icon={['far', 'check']} style={{ color: this.state.rActiveTab === 'Done' ? '#ffffff' : colors.colorParticipants[1] }} />
                    </div>
                    <span>{i18n[language].done}</span>
                  </div>
              }

              {
                community.social_flag &&
                <div onClick={() => this.updateRActiveTab('Now')} className={this.state.rActiveTab === 'Now' ?
                  'item comm-now active' : 'item comm-now'} style={{ backgroundColor: this.state.rActiveTab !== 'Now' && colors.colorParticipants[2]}}>
                  <div className="icon-action-resp" style={{ backgroundColor: this.state.rActiveTab === 'Now' && colors.colorParticipants[2] }}>
                    <FontAwesomeIcon icon={['far', 'signal-stream']} style={{ color: this.state.rActiveTab === 'Now' ? '#ffffff' : colors.colorParticipants[2] }}  />
                  </div>
                  <span>{i18n[this.props.language].comm_now_mobile}</span>
                </div>
              }
            </div>

            <div className="px-part-dash-resp-cont">
              <div className="px-cont-main-dash-part-resp">
                { todoMobile }
                { doneMobile }
                { nowMobile }
                {
                  this.state.showModal &&
                  <ReactModal isOpen={this.state.showModal} contentLabel="Activity Editor Modal"
                    shouldCloseOnOverlayClick={true} onRequestClose={() => this.updateShowModal(null)}
                    className="px-modal-content" overlayClassName="px-modal-overlay">
                    <PModal updateShowModal={this.updateShowModal}
                            language={language}
                            clickedTask={this.state.tasks.find(task => task.id === this.state.clickedTaskId)}
                            type={this.state.type}
                            colors={colors} />
                  </ReactModal>
                }
              </div>
            </div>
            {
              this.state.showModalNowFilters &&
              <ReactModal isOpen={this.state.showModalNowFilters} contentLabel="Mobile Now Filters"
                          shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalNowFilters}
                          className="px-modal-content" overlayClassName="px-modal-overlay">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="mg-top-20 px-label mg-bottom-5">{i18n[language].nowFilters}</div>
                      <button onClick={this.updateShowModalNowFilters} type="button"
                              className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                        <i className="material-icons">clear</i>
                      </button>
                    </div>

                    <div className="modal-body px-modal-body-warning">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="px-drop-filters-container">
                            <div className="px-item">
                              <span className={`check-btn ${nowFilter === 'All' && 'active'}`} onClick={() => this.updateNowFilter('All')}>
                                <span>{i18n[language].f_all}</span>
                              </span>
                            </div>
                            <div className="px-item">
                              <span className={`check-btn ${nowFilter === 'Responses' && 'active'}`} onClick={() => this.updateNowFilter('Responses')} >
                                <span>{i18n[language].f_responses}</span>
                              </span>
                            </div>
                            <div className="px-item">
                              <span className={`check-btn ${nowFilter === 'CommentsReplies' && 'active'}`} onClick={() => this.updateNowFilter('CommentsReplies')} >
                                <span>{i18n[language].f_comments}</span>
                              </span>
                            </div>
                            {/* <div className="px-item">
                              <span className={`check-btn `} onClick={() => this.updateNowFilter('Comments')} >
                                <span>{i18n[language].f_comments}</span>
                              </span>
                            </div>
                            <div className="px-item">
                              <span className={`check-btn `} onClick={() => this.updateNowFilter('Replies')} >
                                <span>{i18n[language].f_replies}</span>
                              </span>
                            </div> */}
                            <div className="px-item">
                              <span className={`check-btn ${nowFilter === 'Likes' && 'active'}`} onClick={() => this.updateNowFilter('Likes')} >
                                <span>{i18n[language].f_likes}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ReactModal>
            }
          </div>
        </MediaQuery>
        <div className="px-part-dash-expand">
          <div className="px-message">{i18n[language].contentNA}</div>
          <img src="/assets/expand-arrows.svg" className="px-rotate-image" alt=""/>
        </div>
      </React.Fragment>
    );
  }

  userActions = (type, user) => {
    console.log('type:', type, 'user:', user)
    switch(type){
      case 'last response':
        $.ajax({
          url: '/responses/get_last_response/' + this.props.community.id + '/' + user.id,
          method: 'GET',
          dataType: 'JSON',
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: response => {
            console.log(response);
            if(response){
              window.location.href = '/activities/'+ response.community_id + '/activity/'+ response.activity_id +'/social/' +
                'response/'+ response.id +'/act_user/'+ response.user_id +'/scroll/'+ response.task_id +'/-1';
            } else {

            }
          }
        });
        break;
    }
  };

  updateShowModalNowFilters = () => {
    this.setState(prevState => ({showModalNowFilters: !prevState.showModalNowFilters}));
  };

  _setNowCards = (tasks, nowFilter, followed) => {
    const { community, profile } = this.props;
    const formatter = this.props.language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);

    return sort_now(tasks, nowFilter, followed).map((item, index) => {
      //console.log(item);
      let component = '';
      //console.log(item.type);
      let classForNowColumn = null;
      if (this.state.isCollapsedNow) classForNowColumn = 'col-xs-12'; else classForNowColumn = 'col-xs-6';
      // TO-DO test without switch because the P_NowCard looks identically
      switch (item.type) {
        // TO DO - Show Likes to Replies or Responses currently doesn't have url to Activity Social
        case 'response':
          component = <LazyLoad key={index}>
            <div className={classForNowColumn} alt={this.state.activeColumn}>
              <P_NowCard communityId={community.id}
                item={item}
                formatter={formatter}
                language={this.props.language}
                viewProfile={this.viewProfile}
                profile={profile} />
            </div>
          </LazyLoad>;
          break;
        case 'comment':
          component =
            <LazyLoad key={index}>
              <div className={classForNowColumn} alt={this.state.activeColumn}>
                <P_NowCard communityId={community.id}
                  item={item}
                  formatter={formatter}
                  language={this.props.language}
                  viewProfile={this.viewProfile}
                  profile={profile} />
              </div>
            </LazyLoad>;
          break;
        case 'like':
          component = <LazyLoad key={index}>
            <div className={classForNowColumn}>
              <P_NowCard communityId={community.id}
                item={item}
                formatter={formatter}
                language={this.props.language}
                viewProfile={this.viewProfile}
                profile={profile} />
            </div>
          </LazyLoad>;
          break;
        case 'reply':
          component = <LazyLoad key={index}>
            <div className={classForNowColumn}>
              <P_NowCard communityId={community.id}
                item={item}
                formatter={formatter}
                language={this.props.language}
                viewProfile={this.viewProfile}
                profile={profile} />
            </div>
          </LazyLoad>;
          break;
        default:
          component = <LazyLoad key={index}>
            <div className={classForNowColumn}>
              <P_NowCard communityId={community.id}
                item={item}
                formatter={formatter}
                language={this.props.language}
                viewProfile={this.viewProfile}
                profile={profile} />
            </div>
          </LazyLoad>;
      }

      return component;
    });
  };

  updateRActiveTab = (rActiveTab) => {
    this.setState({ rActiveTab });
  };

  viewProfile = (userId) => {
    $.ajax({
      url: '/profiles/view_profile/' + this.props.community.id + '/' + userId,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
        //console.log('success')
      }
    });
  };

  updateNowFilter = value => {
    console.log('updateNowFilter', value);
    this.setState({
      nowFilter: value,
      offset: 0,
      nowItems: [],
      communityNow: [],
      isLoadingNow: true,
      hasMoreItems: true}, () => {
      this._getTasksNow();
    })
  };

  // it looks not in use
  updateNowFilter_ORIG = value => {
    //console.log(value);
    const filterNowPreItems = this.getFilterNowPreItems(this.state.allNowPreItems, value);

    this.setState({
      nowFilter: value,
      batch: 1,
      filterNowPreItems,
      nowItems: []
    }, () => {
      this.getNowItems(filterNowPreItems, 0, this.state.nItems);
      this.updateShowModalNowFilters();
    });
  };

  // it looks not in use
  updateNowFilter_ORIG = (value) => {
    this.setState({ nowFilter: value }, () => {
      this.setState({ communityNow: this._setNowCards(this.state.tasks, this.state.nowFilter, this.state.followed) });
    });
  };

  fullExpand = (type) => {
    //console.log(type);
    if(type === 'Now' && !this.state.isCollapsedToDo) {
      this.setState({ activeColumn: type }, () => {
        this.setState({ isCollapsedNow: false, isCollapsedToDo: true });
      });
    }

    if(type === 'ToDo' && !this.state.isCollapsedNow) {
      this.setState({ activeColumn: type }, () => {
        this.setState({ isCollapsedNow: true, isCollapsedToDo: false });
      });
    }
  };

  updateCollapsed = (name) => {
    console.log(name);
    event.stopPropagation();
    this.setState({ activeColumn: name }, () => {
      this.setState(state => ({ [name]: !state[name]}), () => {
        console.log('this.state.isCollapsedNow', this.state.isCollapsedNow);
        if(!this.state.isCollapsedNow){
          const cards = document.getElementsByClassName("dnCard");
          console.log('cards.length', cards.length);
          if(cards.length !== 0){
            for(let i = 0; i < cards.length; i++) {
              cards[i].className = 'col-xs-6 dnCard';
            }
            /*cards.forEach(c => {
              c.className = 'col-xs-6';
            });*/
          }

        } else {
          const cards = document.getElementsByClassName("dnCard");
          console.log('cards.length', cards.length);
          if(cards.length !== 0){
            for(let i = 0; i < cards.length; i++) {
              cards[i].className = 'col-xs-12 pd-0 dnCard';
            }
          }
        }
      });
    });
  };

  updateShowModal = (clickedTaskId, type, modalActIsRequiredDone) => {
    this.setState(state => ({ showModal: !state.showModal, clickedTaskId, type, modalActIsRequiredDone }));
  };

  forwardTask = (taskId) => {
    window.location.href = '/activity/' + taskId + '/' + this.props.community.id;
  };

  // Is it useful?
  updateShowTask = () => {
    this.setState({ showTask: !this.state.showTask }) //change to this.props
  };

  _getFollowed = () => {
    $.ajax({
      url: '/follow/get_followed/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      success: followed => {
        this.setState({ followed });
      }
    });
  };

  _handleResize = e => {
    const windowSize = window.innerWidth;
    let changeVisibilitytarget = document.getElementsByClassName("px-part-dash-expand")[0];
    if (windowSize <= 767) {
      if (navigator.userAgent.indexOf("iPhone") > -1 || navigator.userAgent.indexOf("Android") > -1) {
        changeVisibilitytarget.classList.add("hidden");
      }
      else {
        changeVisibilitytarget.classList.remove("hidden");
        //console.log("false")
      }
    } else {

    }
  };

  _getTasks = () => {
    $.ajax({
      url: '/activities/part_dash_get_activities/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: tasksDB => {
        //console.log('tasksDB: ', tasksDB.map(a => a.expire));
        this.props.updateToDoCounter(getParticipantToDoCounter(tasksDB));
        const tasksDone = tasksDB.filter(task => {
          if(task.visibility === 'enabled' && task.isTaskDone) { return { ...task } }
        });

        this.setState({
          tasks: sortSortedTasks(sortCollTasks(tasksDB, this.props.community.sort_part)),
          tasksDone,
          isLoadingToDo: false
        });
      }
    });
  };

  // DONT FORGET TO GET THE TAGS
  _getTasks_ORIG = () => {
    $.ajax({
      url: '/tasks/get/' + this.props.community.id + '/' + this.props.profile.user_id,
      method: 'GET',
      dataType: 'JSON',
      success: tasksDB => {
        //console.log(tasksDB);
        this.props.updateToDoCounter(getParticipantToDoCounter(tasksDB));

        const tasksDone = tasksDB.filter(task => {
          if (task.visibility === 'enabled' && task.isTaskDone) {
            return { ...task }
          }
        });

        this.setState({
          tasks: sortSortedTasks(sortCollTasks(tasksDB, this.props.community.sort_part)),
          tasksDone,
          isLoadingToDo: false
        }, () => {
          this.setState({ communityNow: this._setNowCards(this.state.tasks, this.state.nowFilter, this.state.followed) })
        });
      }
    });
  };

  _getTasksNow = () => {
    //console.log('_getTasksNow, offset:', this.state.offset, 'hasMoreItems:', this.state.hasMoreItems);
    $.ajax({
      url: '/communities/get_now/' + this.props.community.id + '/Participant/' + this.state.offset + '/' + this.state.nowFilter,
      method: 'GET',
      data: {
        usersId: [],
        activitiesId: []
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: data => {
        //console.log('data:', data);
        if(data.length !== 0){
          const formatter = this.props.language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);
          const communityNow = data.map((item, index) => {
            if(item){
              //console.log('this.state.isCollapsedNow:', this.state.isCollapsedNow);
              return (
                <div key={v4()} className={this.state.isCollapsedNow ? 'col-xs-12 pd-0 dnCard' : 'col-xs-6'}>
                  <DNCard item={item}
                          language={this.props.language}
                          communityId={this.props.community.id}
                          profile={this.props.profile}
                          formatter={formatter}
                          viewProfile={this.viewProfile}
                          user_account={this.props.user_account}
                          userActions={this.userActions}
                          clearForwardUrl={this.clearForwardUrl}/>
                </div>
              )
            }
          });

          this.setState(prevState => ({
            isLoadingNow: false,
            nowItems: [...this.state.nowItems, ...data],
            communityNow: [...this.state.communityNow, ...communityNow],
            offset: prevState.offset + 10
            //filterNowPreItems: this.getFilterNowPreItems(data.allPreNow, this.state.nowFilter)
          }));
        } else {
          this.setState({
            isLoadingNow: false,
            hasMoreItems: false
          })
        }
      }
    });
  };

  clearForwardUrl(event, type, url){
    //Sometimes the browser cache takes the navigate (0) as reload (1), for that reason we must to clear the localStorage
    //event.preventDefault();
    console.log('type:', type, 'url:', url, event);
    //localStorage.clear();
    if(type === 'common'){
      window.location.href = url;
    } else {
      //window.open(url, '_blank');
    }
  }

  _getTasksNow_ORIG = () => {
    $.ajax({
      url: '/communities/get_now/' + this.props.community.id + '/Collaborator/' + this.state.offsetDays,
      method: 'GET',
      dataType: 'JSON',
      success: data => {
        console.log(data);
        this.setState({
          allNowPreItems: data.allPreNow,
          filterNowPreItems: this.getFilterNowPreItems(data.allPreNow, this.state.nowFilter)
        }, () => {
          this.getNowItems(this.state.filterNowPreItems, 0, this.state.batch * this.state.nItems)
        })
      }
    });
  };

  getNowItems = (filterNowPreItems, begin, end) => {
    //console.log(filterNowPreItems, begin, end);
    const preItems = filterNowPreItems.slice(begin, end);
    //console.log(preItems);

    if(preItems.length !== 0) {
      const formatter = this.props.language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);

      $.ajax({
        url: '/communities/get_now_items/' + this.props.community.id + '/Participant/',
        method: 'POST',
        dataType: 'JSON',
        data: {
          community: { items: preItems }
        },
        success: nowItemsDB => {
          //console.log(nowItemsDB);
          const nowItems = [...this.state.nowItems, ...nowItemsDB];
          //console.log(nowItems);
          const communityNow = nowItems.map((item, index) => {
            if (item) {
              return (
                <div key={index} className={this.state.isCollapsedNow ? 'col-xs-12' : 'col-xs-6'} alt={this.state.activeColumn}>
                  <P_NowCard communityId={this.props.community.id}
                    item={item}
                    formatter={formatter}
                    language={this.props.language}
                    viewProfile={this.viewProfile}
                    profile={this.props.profile} />
                </div>
              )
            }
          });
          //console.log(communityNow);
          this.setState({
            nowItems,
            communityNow: communityNow,
            batch: this.state.batch + 1,
            isLoadingNow: false,
            isLoadingInfDesktop: false,
            isLoadingInfMobile: false
          });
        }
      });
    } else {
      this.setState({ isLoadingInfDesktop: false, isLoadingInfMobile: false, isLoadingNow: false })
    }
  };

  getFilterNowPreItems(preItems, nowFilter) {
    let filteredPreItems = [];

    if (nowFilter === 'All') {
      filteredPreItems = preItems;
    } else {
      switch (nowFilter) {
        case 'Responses':
          preItems.forEach(item => {
            if (item.question_id) {
              filteredPreItems.push(item);
            }
          });
          break;
        case 'Comments':
          preItems.forEach(item => {
            if (item.response_id) {
              filteredPreItems.push(item);
            }
          });
          break;
        case 'Likes':
          preItems.forEach(item => {
            if (item.entity_type) {
              filteredPreItems.push(item);
            }
          });
          break;
        case 'Replies':
          preItems.forEach(item => {
            if (item.comment_id) {
              filteredPreItems.push(item);
            }
          });
          break;
      }
    }

    return filteredPreItems;
  }
}
