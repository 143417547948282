import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";
import Lightbox from "react-images";

import {setBlobToPreview} from "../../../../../UI/Globals/PX_Funs";
import T_ModalVideo from "../../../../Transcript/T_ModalVideo";

export default class CanvasView extends React.Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    cardinal: PropTypes.number.isRequired,
    updateQuestionMode: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    mapsSelect: PropTypes.array.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    communityLang: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      showModalVideo: false
    }
  }

  // TO-DO Every time it has to convert the blob to a preview (setBlobToPreview), try to find a way just one
  // time (the first time) do the convert and save the preview (from blob) in a question.blobPreview,
  // in order to do not again the conversion because it cause an instant flicker
  componentDidMount(){
    if(typeof this.props.question.blob !== 'undefined') {
      setBlobToPreview(this.props.question.blob, this.refs.imgPreviewOEView);
    }

    let btnHoverColor = this.props.colors.color2[3];
    $(".action-button").mouseenter(function () {
      $(this).css({ 'background-color': btnHoverColor });
    });
    $(".action-button").mouseleave(function () {
      $(this).css({ 'background-color': '' });
    });
  }

  render() {
    const { question, cardinal, mapsSelect, language, communitySocial, communityLang, colors} = this.props;

    const i18n = {
      en: {
        attachMediaTooltip: 'Participants can attach images or video to their response.',
        noAttachMediaTooltip: 'Participants cannot attach images or video to their response.',
        wordbookTooltip: 'Participants can choose words from a predefined list of ',
        noWordbookTooltip: 'No Wordbooks selected',
        mandatoryTooltip: 'Participants are forced to respond with particular types of data.',
        noMandatoryTooltip: 'Participants are not forced to respond with particular types of data.',
        mandatory: 'Mandatory', noMandatory: 'Optional', minChars: 'MIN ANNOTATIONS', noWB: 'No Wordbook',
        noMedia: 'No Media', aiAutoModTT: 'AI moderated'
      },
      es: {
        attachMediaTooltip: 'Los participantes pueden agregar imágenes o video a su respuesta.',
        noAttachMediaTooltip: 'Los participantes no pueden agregar imágenes o video a su respuesta.',
        wordbookTooltip: 'Los participantes pueden elegir palabras de una lista predefinida de ',
        noWordbookTooltip: 'Ningún Wordbook activado',
        mandatoryTooltip: 'Los participantes están obligados a responder con tipos particulares de data.',
        noMandatoryTooltip: 'Los participantes no están obligados a responder con tipos particulares de data.',
        mandatory: 'Obligatorio', noMandatory: 'Opcional', minChars: 'MIN ANNOTATIONS', noWB: 'Sin Wordbook',
        noMedia: 'Sin Media', aiAutoModTT: 'Moderada por AI'
      }
    }

    let imgUrl = '';
    let imgStyle = null;
    let images = [];

    //if(typeof question.blob !== 'undefined'){
    if(typeof question.blobPreview !== 'undefined'){
      /*if(this.refs.imgPreviewOEView !== undefined){
        setBlobToPreview(question.blob, this.refs.imgPreviewOEView);
      }*/
      imgUrl = question.blobPreview;
      imgStyle = {display: 'block',cursor:'pointer'};
      images.push({src: imgUrl});
    } else {
      if(question.attachment.length !== 0){
        imgUrl = question.attachment[0].url;
        imgStyle = {display: 'block',cursor:'pointer'};
        images.push({src: imgUrl});
      }
    }

    let videoThumbnail = null;
    if(question.videoState){
      videoThumbnail = question.videoState === 'processed' ? question.video_thumbnail : '/assets/loader_200x200.gif';
    } else {
      videoThumbnail = question.video_thumbnail;
    }

    //console.log(mapsSelect, parseInt(question.maps_id[0]));
    const mapName = question.maps_state ?
        (mapsSelect.find(map => map.id === parseInt(question.maps_id[0]))).title[communityLang] + ' Wordbook' : i18n[language].noWB;

    return (
        <div className="panel-activity-builder-details">
          <div className="px-support-tooltip top is_hidden px-support-builder">
            <p>This is a summary view of a Task. It shows, broadly, what participants are required to post in order to complete it.
              Click on the pencil icon to edit its settings.</p>
            <i/>
          </div>
          <div className="panel-body">
            <div className="info-container">
              <div className="title-task-container">
                <div className="task-number" style={{backgroundColor: colors.color0[1]}}><span>{cardinal}</span></div>
                <span className="title-task">{question.task_type}</span>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="details-answer">{question.title.replace(/(<([^>]+)>)/ig,"")}</div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="media-container">
                    {
                        imgUrl !== '' &&
                        <img onClick={() => this.setState({lightboxIsOpen: true})} ref='imgPreviewOEView' src={imgUrl}
                             className="media-item" alt="" style={imgStyle}/>
                    }
                    {
                        (question.video_thumbnail !== '' && question.video_thumbnail !== null) &&
                        <div className="media-item video">
                          <div className="overlay-video" onClick={this.updateShowModalVideo}>
                            <div className="play-icon">
                              <FontAwesomeIcon icon={['fas','play']}/>
                            </div>
                          </div>
                          <img src={videoThumbnail} alt="" className="" style={{cursor: 'pointer'}}/>
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="actions-container">
              {/*<div className="action-item">*/}
              {/*<span className="btn-action left" style={{cursor:'auto'}}>*/}
              {/*  <span>{question.media ? 'Media': i18n[language].noMedia}</span>*/}
              {/*</span>*/}
              {/*  <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"*/}
              {/*                   data-tooltip-content={[question.media ? i18n[language].attachMediaTooltip :*/}
              {/*                       i18n[language].noAttachMediaTooltip]} />*/}
              {/*</div>*/}
              {/*
            communitySocial &&
            <div className="action-item">
              <span className="btn-action left" style={{cursor:'auto'}}>
                <span>{question.show ? 'Answer first' : ' No answer first'}</span>
              </span>
              <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                data-tooltip-content={[question.show ? "Participants have to answer this task to see other participants' responses." :
                  "Participants can see other participants' responses even if they haven't completed this task."]}/>
            </div>
          */}
              {/*<div className="action-item">*/}
              {/*<span className="btn-action left" style={{cursor:'auto'}}>*/}
              {/*  <span>{mapName}</span>*/}
              {/*</span>*/}
              {/*  <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"*/}
              {/*                   data-tooltip-content={question.maps_state ? i18n[language].wordbookTooltip + mapName :*/}
              {/*                       i18n[language].noWordbookTooltip} />*/}
              {/*</div>*/}
              <div className="action-item">
              <span className="btn-action left" style={{cursor:'auto'}}>
                <span>{question.required ? i18n[language].mandatory : i18n[language].noMandatory}</span>
              </span>
                <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                 data-tooltip-content={[question.required ? i18n[language].mandatoryTooltip :
                                     i18n[language].noMandatoryTooltip]} />
              </div>
              {/*<div className="action-item">*/}
              {/*  <span className="min-chars">{i18n[language].minChars}: {question.data.minChar}</span>*/}
              {/*</div>*/}
              { question.ai_auto_moderation &&
                <div className="action-item"
                     style={{textTransform: 'uppercase',
                       color: colors.color2[3],
                       fontSize: '8px',
                       fontFamily: "'Sharp Sans ExtraBold',Helvetica, sans-serif",
                       letterSpacing: '2.4px'
                     }}>
                  <span style={{marginRight: '5px'}}><FontAwesomeIcon icon={['fas','bolt']}/></span> <span>{i18n[language].aiAutoModTT}</span>
                </div> }
            </div>
          </div>

          <div className="actions-panel">
            <div className="actions-container">
              <button onClick={() => this.props.updateQuestionMode(question.id)} className="action-button">
                <FontAwesomeIcon icon={['fas', 'pencil']}/>
              </button>
              <button onClick={() => this.props.updateShowModal('delete task', question.id)} className="action-button">
                <FontAwesomeIcon icon={['fas', 'trash']}/>
              </button>
            </div>
          </div>

          <Lightbox currentImage={this.state.currentImage}
                    images={images}
                    isOpen={this.state.lightboxIsOpen}
                    onClose={this.closeLightbox}/>
          {
              question.video_thumbnail &&
              <ReactModal isOpen={this.state.showModalVideo} contentLabel="Response Video"
                          shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalVideo}
                          className="my-modal-content" overlayClassName="my-modal-overlay">
                <T_ModalVideo updateShowModalVideo={this.updateShowModalVideo}
                              thumbnail={question.video_thumbnail}
                              url={question.video_url}
                              language={language}
                              colors={colors}/>
              </ReactModal>
          }
        </div>
    );
  }

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  updateShowModalVideo = () => {
    this.setState(state => ({showModalVideo: !state.showModalVideo}));
  };
}
