import PropTypes from 'prop-types';
import React from 'react';
import v4 from 'uuid';

import {modalVideoi18n} from "../../../../UI/Globals/PX_i18n";

export default class AE_M_Video extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    updateVideo: PropTypes.func.isRequired,
    questionId: PropTypes.number.isRequired,
    expiration: PropTypes.number,
    data_signature: PropTypes.object,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      cameraUuid: v4(),
      isCamReady: false,
      isCamRecording: false,
      isPublished: false
    }
  }

  componentDidMount(){
    this._setupCameraTag();
  }

  render() {
    const {language, data_signature, colors} = this.props;

    const camera = <camera id={this.state.cameraUuid}
                           data-app-id='a-96413510-77a7-0135-1986-0a76e15fb04a'
                           data-signature={data_signature.signature}
                           data-signature-expiration={data_signature.expiration}
                           data-poll-for-processed='true'
                           style={{width:'100%', height:'270px'}}
                           data-maxlength='300'/>;

    let btnAction = <button onClick={() => this.props.updateShowModal(null, null)} type="button"
                            className="btn px-btn sm btn-gray-darker inverted"
                            disabled={this.state.isCamRecording}>{modalVideoi18n[language].cancel}</button>;

    if(this.state.isPublished){
      btnAction = <button onClick={() => this.props.updateShowModal(null, null)} type="button"
                          className="btn px-btn sm btn-blue-base" style={{backgroundColor: colors.color1[3]}}>
        {modalVideoi18n[language].close}
      </button>;
    }

    return (
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="px-modal-title">{this.props.title}</h4>
        </div>
        <div className="modal-body px-modal-body-add-activity">
          <div className="row">
            <div className="col-md-12">
              { camera }
              { this.state.isCamReady ? null : <h4>Loading Camera...</h4> }
            </div>
          </div>
        </div>
        <div className="modal-footer px-modal-footer-gray">
          <div className="px-footer-container-buttons row">
            { btnAction }
          </div>
        </div>
      </div>
    );
  }

  _setupCameraTag = () => {
    CameraTag.setup();

    const {language} = this.props;
    const i18n = {
      en: {
        title: 'choose a method below to submit your video', camera: 'record from camera', upload: 'upload a file',
        tap: 'Tap to get started'
      },
      es: {
        title: 'Elija un método para enviar su video', camera: 'Grabar desde cámara', upload: 'Subir archivo',
        tap: 'Toca para empezar'
      }
    };

    let myCamera = null;
    let video = null;

    CameraTag.observe(this.state.cameraUuid, "initialized", () => {
      this.setState({isCamReady: true}, () => {
        console.log(document.getElementsByClassName("cameratag_select_prompt"));
        const title = document.getElementsByClassName("cameratag_select_prompt")[0];
        const spnCamera = document.getElementsByClassName("cameratag_prompt_label")[0];
        const spnUpload = document.getElementsByClassName("cameratag_prompt_label")[1];
        const spnTap = document.getElementsByClassName("cameratag_prompt_label")[2];

        if(title){
          title.innerHTML = i18n[language].title;
        }
        if(spnCamera){
          spnCamera.innerHTML = i18n[language].camera;
        }
        if(spnUpload){
          spnUpload.innerHTML = i18n[language].upload;
        }
        if(spnTap){
          spnTap.innerHTML = i18n[language].tap;
        }
      });
    });

    CameraTag.observe(this.state.cameraUuid, "recordingStarted", () => {
      this.setState({isCamRecording: true});
    });
    CameraTag.observe(this.state.cameraUuid, "recordingStopped", () => {
      this.setState({isCamRecording: false});
    });

    CameraTag.observe(this.state.cameraUuid, "publishing", () => {
      this.setState({isCamRecording: true});
    });

    CameraTag.observe(this.state.cameraUuid, "published", () => {
      this.setState({isCamRecording: false, isPublished: true});
      myCamera = CameraTag.cameras[this.state.cameraUuid];
      video = myCamera.getVideo();
      // console.log('1. video has been Published', video);
      this.props.updateVideo(video, 'published', this.props.questionId);
    });

    CameraTag.observe(this.state.cameraUuid, 'processed', () => {
      // console.log('2. video has been Processed', video);
      this.props.updateVideo(video, 'processed', this.props.questionId);
    });

    // Fails events
    CameraTag.observe(this.state.cameraUuid, 'serverDisconnected', () => {
      console.log('Server was disconnected');
      this.setState({isCamRecording: false});
    });
    CameraTag.observe(this.state.cameraUuid, 'serverError', () => {
      console.log('Failure to connect to the streaming server');
      this.setState({isCamRecording: false});
    });
    CameraTag.observe(this.state.cameraUuid, 'publishFailed', () => {
      console.log('The video failed to publish');
      this.setState({isCamRecording: false});
    });
    CameraTag.observe(this.state.cameraUuid, 'processingFailed', () => {
      console.log('The video failed to processing (transcoding)');
      this.setState({isCamRecording: false});
    });
  };
}