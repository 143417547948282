import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CSEditorWYSIWYG from "./Utils/CSEditorWYSIWYG";
import CSIPreview from "./CSInvitations/CSIPreview";
import ReactModal from "react-modal";

export default class CSInvitations extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    onEditorStateChange: PropTypes.func.isRequired,
    showPreviewInvitations: PropTypes.bool.isRequired,
    updateShowPreviewInvitations: PropTypes.func.isRequired,
    typeInvitations: PropTypes.string.isRequired,
    saveCommunityInvitations: PropTypes.func.isRequired,
    role_2: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.community.project_type !== 'AIChat' ? 'participants' : 'collaborators'
    }
  }

  componentDidMount() {
    let btnColor = this.props.colors.color0[3];
    $(".nav-tabs > li.active > a").css({ "color": '#ffffff', 'background-color': btnColor, 'border-color': btnColor });
  }

  render() {
    const {community, typeInvitations, role_2, companyName, language, colors} = this.props;

    const I18n = {
      en: {
        participants: 'participants', collaborators: 'collaborators', subject: 'SUBJECT',
        message: 'MESSAGE', signature: 'SIGNATURE MESSAGE',
        invitationInfoParts: 'These will be the default invitation email messages for participants.',
        invitationInfoColls: 'These will be the default invitation email messages for collaborators.',
        preview: 'Preview', save: 'Save', dynamicValues: 'Dynamic values', cmntyTitle: 'Community title',
        accountHolder: 'Researcher', acceptUrl: 'Accept URL', declineUrl: 'Decline URL'
      },
      es: {
        participants: 'participantes', collaborators: 'colaboradores', subject: 'SUBJECT',
        message: 'MENSAJE', signature: 'MENSAJE FINAL',
        invitationInfoParts: 'Estos serán los mensajes predeterminados para las invitaciones de los participantes.',
        invitationInfoColls: 'Estos serán los mensajes predeterminados para las invitaciones de los colaboradores.',
        preview: 'Muestra', save: 'Guardar', dynamicValues: 'Valores dinámicos', cmntyTitle: 'Nombre de la comunidad',
        accountHolder: 'Researcher', acceptUrl: 'URL para aceptar', declineUrl: 'URL para declinar'
      }
    };
    
    const tabStyle = {
      active: { border: `1px solid ${colors.color0[3]}`, color: '#ffffff', backgroundColor: colors.color0[3] },
      inactive: { border: '1px solid #DADDE1', color: '#7C8593', backgroundColor: '#f0eff3' }
    };

    const modalStyle = {
      overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(0, 0, 0, 0.2)',
        zIndex            : 1050,
      },
      content : {
        position                   : 'absolute',
        top                        : '0',
        left                       : '40px',
        right                      : '0',
        bottom                     : '0',
        border                     : '0',
        background                 : 'rgba(0,0,0,0)',
        overflow                   : 'auto',
        WebkitOverflowScrolling    : 'touch',
        borderRadius               : '4px',
        outline                    : 'none',
        padding                    : '20px'
      }
    };

    return (
      <div id="divInvitations" className="manage" style={{display:'none'}}>
        <div className="px-settings-container tabs">
          <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {community.project_type !== 'AIChat' && <ul className="nav nav-tabs px-submenu-settings-navigation" role="tablist">
                  <li className="active">
                    <a onClick={() => this.setActiveTab('participants')} href="#participants_invitations" role="tab" data-toggle="tab"
                       style={this.state.activeTab === 'participants' ? tabStyle.active : tabStyle.inactive}>
                       {I18n[language].participants}</a>
                  </li>
                  <li>
                    <a onClick={() => this.setActiveTab('collaborators')} href="#collaborators_invitations" role="tab" data-toggle="tab"
                       style={this.state.activeTab === 'collaborators' ? tabStyle.active : tabStyle.inactive}>
                         {I18n[language].collaborators}
                    </a>
                  </li>
                </ul>}

                <div className="tab-content" >
                  <div className={`tab-pane fade in ${community.project_type !== 'AIChat' && 'active'}`} id="participants_invitations">
                    <div className="px-card px-card-settings">
                      <div className="px-icon-settings">
                        <FontAwesomeIcon icon={['fas','envelope']}/>
                      </div>
                      <div className="panel-body px-body-padding-welcome">
                        <div className="row px-invitation-container">
                          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 data-cont">
                            <div className="form-group first">
                            <label className="label-sm" style={{color: colors.color1[2]}}>
                              {I18n[language].subject} ({I18n[language].participants})
                            </label>
                              <input name={community.language === 'en' ?
                                'participants_invitation_subject' : 'participants_invitation_subject_es'}
                                     onChange={this.props.handleChange}
                                     value={community.language === 'en' ?
                                       community.participants_invitation_subject : community.participants_invitation_subject_es}
                                     type="text"
                                     placeholder="" className="form-control px-input-settings"/>
                            </div>
                            <div className="form-group">
                              <label className="label-sm" style={{ color: colors.color1[2] }}>
                                {I18n[language].message} ({I18n[language].participants})
                              </label>
                              <div className="bio-text-area-container">
                                <CSEditorWYSIWYG name={community.language === 'en' ?
                                  'participants_invitation' : 'participants_invitation_es'}
                                                 editorName="editorInvitationPart"
                                                 editorState={community.editorInvitationPart}
                                                 onEditorStateChange={this.props.onEditorStateChange}
                                                  language={language}/>
                                {/*<span className="px-label-information char-remaining">140 chars. left</span>*/}
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="label-sm" style={{ color: colors.color1[2] }}>
                                {I18n[language].signature} ({I18n[language].participants})
                              </label>
                              <div className="bio-text-area-container">
                                <CSEditorWYSIWYG name={community.language === 'en' ?
                                  'participants_invitation_signature' : 'participants_invitation_signature_es'}
                                                 editorName="editorInvitationSignaturePart"
                                                 editorState={community.editorInvitationSignaturePart}
                                                 onEditorStateChange={this.props.onEditorStateChange}
                                                 language={language}/>
                                {/*<span className="px-label-information char-remaining">140 chars. left</span>*/}
                              </div>
                              
                              <p className="px-label-information" style={{ color: colors.color2[3] }}>
                                {I18n[language].invitationInfoParts}
                              </p>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 info-cont">
                            <div className="form-group">
                              <div className="px-card px-panel-aside-invitations">
                                <div className="panel-body">
                                  <label className="title" style={{color: colors.color4[3]}}>{I18n[language].dynamicValues}</label>
                                    <div className="info-options-container">
                                    <div className="options"><strong>{I18n[language].cmntyTitle}: </strong><br />{'{{community_title}}'}</div>
                                    <div className="options"><strong>{I18n[language].accountHolder}: </strong><br />{'{{researcher}}'}</div>
                                      {/* <div className="options"><strong>Invitee first name:</strong><br />{'{{first_name}}'}</div>
                                      <div className="options"><strong>Invitee last name: </strong><br />{'{{last_name}}'}</div>
                                      <div className="options"><strong>Account holder email: </strong><br />{'{{researcher_email}}'}</div>
                                      <div className="options"><strong>Moderator: </strong><br />{'{{go_to_person}}'}</div>
                                      <div className="options"><strong>Moderator Email: </strong><br />{'{{go_to_person_email}}'}</div> */}
                                    <div className="options"><strong>{I18n[language].acceptUrl}: </strong><br />{'{{acceptance_url}}'}</div>
                                    <div className="options"><strong>{I18n[language].declineUrl}: </strong><br />{'{{decline_url}}'}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-row-buttons-action welcome">
                            <button onClick={() => this.props.updateShowPreviewInvitations('Participant')}
                              className="btn px-btn lg btn-gray-base">{I18n[language].preview}</button>
                            <button onClick={() => this.validSaveInvitations(community.participants_invitation_subject,
                                    community.participants_invitation, community.participants_invitation_signature )}
                                    className="btn px-btn lg btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{I18n[language].save}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    role_2 !== 'Recruiter' &&
                    <div className={`tab-pane fade ${community.project_type === 'AIChat' && 'in active'}`} id="collaborators_invitations">
                      <div className="px-card px-card-settings">
                        <div className="px-icon-settings">
                          <FontAwesomeIcon icon={['fas','envelope']}/>
                        </div>
                        <div className="panel-body px-body-padding-welcome">
                          <div className="row px-invitation-container">
                            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 data-cont">
                              <div className="form-group first">
                               <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].subject} ({I18n[language].collaborators})</label>
                                <input name={community.language === 'en' ?
                                  'members_invitation_subject' : 'members_invitation_subject_es'}
                                       onChange={this.props.handleChange}
                                       value={community.language === 'en' ?
                                         community.members_invitation_subject : community.members_invitation_subject_es}
                                       type="text"
                                       placeholder="" className="form-control px-input-settings"/>
                              </div>

                              <div className="form-group">
                                <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].message} ({I18n[language].collaborators})</label>
                                <div className="bio-text-area-container">
                                  <CSEditorWYSIWYG name={community.language === 'en' ?
                                    'members_invitation' : 'members_invitation_es'}
                                                   editorName="editorInvitationColl"
                                                   editorState={community.editorInvitationColl}
                                                   onEditorStateChange={this.props.onEditorStateChange}
                                                   language={language}/>
                                  {/*<span className="px-label-information char-remaining">140 chars. left</span>*/}
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].signature} ({I18n[language].collaborators})</label>
                                <div className="bio-text-area-container">
                                  <CSEditorWYSIWYG name={community.language === 'en' ?
                                    'members_invitation_signature' : 'members_invitation_signature_es'}
                                                   editorName="editorInvitationSignatureColl"
                                                   editorState={community.editorInvitationSignatureColl}
                                                   onEditorStateChange={this.props.onEditorStateChange}
                                                   language={language}/>
                                  {/*<span className="px-label-information char-remaining">140 chars. left</span>*/}
                                </div>
                                <p className="px-label-information" style={{ color: colors.color2[3] }}>
                                  {I18n[language].invitationInfoColls}
                                </p>
                              </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 info-cont">
                              <div className="form-group">
                                <div className="px-card px-panel-aside-invitations">
                                  <div className="panel-body">
                                    <label className="title" style={{ color: colors.color4[3] }}>{I18n[language].dynamicValues}</label>
                                    <div className="info-options-container">
                                    <div className="options"><strong>{I18n[language].cmntyTitle}: </strong><br />{'{{community_title}}'}</div>
                                    <div className="options"><strong>{I18n[language].accountHolder}: </strong><br />{'{{researcher}}'}</div>
                                      {/* <div className="options"><strong>Invitee first name:</strong><br />{'{{first_name}}'}</div>
                                      <div className="options"><strong>Invitee last name: </strong><br />{'{{last_name}}'}</div>
                                      <div className="options"><strong>Account holder email: </strong><br />{'{{researcher_email}}'}</div>
                                      <div className="options"><strong>Moderator: </strong><br />{'{{go_to_person}}'}</div>
                                      <div className="options"><strong>Moderator Email: </strong><br />{'{{go_to_person_email}}'}</div> */}
                                    <div className="options"><strong>{I18n[language].acceptUrl}: </strong><br />{'{{acceptance_url}}'}</div>
                                    <div className="options"><strong>{I18n[language].declineUrl}: </strong><br />{'{{decline_url}}'}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action welcome">
                              <button onClick={() => this.props.updateShowPreviewInvitations('Collaborator')}
                                className="btn px-btn lg btn-gray-base">{I18n[language].preview}</button>
                              <button onClick={() => this.validSaveInvitations(community.members_invitation_subject,
                                community.members_invitation, community.members_invitation_signature)}
                                className="btn px-btn lg btn-blue-base" style={{backgroundColor: colors.color1[3]}}>{I18n[language].save}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>

              </div>
            </div>
        </div>
        {
          this.props.showPreviewInvitations &&
          <ReactModal isOpen={this.props.showPreviewInvitations} contentLabel="Email Preview"
                      shouldCloseOnOverlayClick={true} style={modalStyle}>
            <CSIPreview community={community}
                        subject={typeInvitations === 'Participant' ?
                          community.language === 'en' ? community.participants_invitation_subject : community.participants_invitation_subject_es :
                          community.language === 'en' ? community.members_invitation_subject : community.members_invitation_subject_es
                        }
                        body={typeInvitations === 'Participant' ?
                          community.language === 'en' ? community.participants_invitation : community.participants_invitation_es :
                          community.language === 'en' ? community.members_invitation : community.members_invitation_es
                        }
                        signature={typeInvitations === 'Participant' ?
                          community.language === 'en' ? community.participants_invitation_signature : community.participants_invitation_signature_es :
                          community.language === 'en' ? community.members_invitation_signature : community.members_invitation_signature_es
                        }
                        updateShowPreviewInvitations={this.props.updateShowPreviewInvitations}
                        companyName={companyName}
                        colors={colors}/>
          </ReactModal>
        }
      </div>
    );
  }

  validSaveInvitations = (subject, initBody, initSignature) => {
    let body = initBody.replace(/"{{acceptance_url}}" target/g, 'https://{{acceptance_url}} target');
    body = body.replace(/"{{decline_url}}" target/g, 'https://{{decline_url}} target');
    //console.log(body);
    let signature = initSignature.replace(/"{{acceptance_url}}" target/g, 'https://{{acceptance_url}} target');
    signature = signature.replace(/"{{decline_url}}" target/g, 'https://{{decline_url}} target');

    // const acceptFlag = body.search('{{acceptance_url}}');
    // const declineFlag = body.search('{{decline_url}}');
    // if(acceptFlag === -1 && declineFlag === -1){
      if(subject !== '' && body !== ''){
        this.props.saveCommunityInvitations();
      }
    // } else {
    //   alert('* No {{acceptance_url}} or {{decline_url}}')
    // }


  }

  setActiveTab = (tabName) => {
    this.setState({ activeTab: tabName });
  }
}
