import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select, { components } from "react-select";

import {setBlobToPreview} from "../../../../../UI/Globals/PX_Funs";
import CSEditorWYSIWYG from "../../../../Settings/Utils/CSEditorWYSIWYG";
import ReactModal from "react-modal";
import T_ModalVideo from "../../../../Transcript/T_ModalVideo";
import EditorWYSIWYGState from "../../../../Settings/Utils/EditorWYSIWYGState";

export default class OEEdit01 extends React.Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    cardinal: PropTypes.number.isRequired,
    cancelEditQuestion: PropTypes.func.isRequired,
    handleChangeQuestion: PropTypes.func.isRequired,
    updateQuestion: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    onEditorStateChangeQuestion: PropTypes.func.isRequired,
    mapsSelect: PropTypes.array.isRequired,
    handleMapsSelected: PropTypes.func.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    handleRequired: PropTypes.func.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    handleCanAttachMedia: PropTypes.func.isRequired,
    communityLang: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    communityProjectType: PropTypes.string.isRequired,
    cmntyAiAutoModeration: PropTypes.bool,
    cmntyAllowImgs: PropTypes.bool,
    cmntyAiPlan: PropTypes.string,
    isSavingTask: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      showModalVideo: false,
      showAiAutoModeration: false,
      saving: false
    }
  }

  componentDidMount(){
    if(typeof this.props.question.blob !== 'undefined') {
      setBlobToPreview(this.props.question.blob, this.refs.imgPreviewOEEdit);
    }

    let btnHoverColor = this.props.colors.color2[3];
    $(".action-button, .btn-draggable").mouseenter(function () {
      $(this).css({ 'background-color': btnHoverColor });
    });
    $(".action-button, .btn-draggable").mouseleave(function () {
      $(this).css({ 'background-color': '' });
    });

    this.updateAutoModeration();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.question.media !== prevProps.question.media ||
      this.props.question.media_options !== prevProps.question.media_options ||
      this.props.question.video_url !== prevProps.question.video_url ||
      this.props.question.attachment !== prevProps.question.attachment ||
      this.props.question.blob !== prevProps.question.blob ||
      this.props.question.maps_state !== prevProps.question.maps_state) {
      this.updateAutoModeration();
    }
  }

  updateAutoModeration() {
    const isAllowed = this.isTaskAutoModerationAllowed();
    if (!isAllowed) {
      // console.log('auto-moderation not allowed')
      // If auto moderation is not allowed, ensures the checkbox is unchecked
      this.props.handleChangeQuestion({ target: { name: 'ai_auto_moderation', checked: false } }, this.props.question.id, false);
    }
    this.setState({ showAiAutoModeration: isAllowed });
  }

  render() {
    const {question, cardinal, communitySocial, mapsSelect, communityLang, language, colors,
      communityProjectType, cmntyAiAutoModeration} = this.props
    const i18n ={
      en: {
        preview: 'Preview', save: 'Save', cancel: 'Cancel', attachMedia: 'Can attach media', aiAutoMod: 'AI auto moderation',
        enableWB: 'Enable wordbook', mandatory: 'Mandatory', minChars: 'MIN CHARACTERS',
        attachMediaTooltip: 'Participants can attach images or video to their response.',
        wordbookTooltip: 'Participants can choose words from a predefined list. <br> Customize the list of words in Community Settings > Wordbooks.',
        minCharsTooltip: 'Will guide participants on how much text they are expected to type.',
        mandatoryTooltip: 'Participants are forced to respond with particular types of data.',
        aiAutoModTT: 'AI moderated task',
        addImageToTask: 'Add an image to this task\'s instructions', addVideoToTask: 'Add a video to this task\'s instructions'
      },
      es: {
        preview: 'Muestra', save: 'Guardar', cancel: 'Cancelar', attachMedia: 'Pueden agregar media',
        enableWB: 'Habilitar Wordbook', mandatory: 'Obligatorio', minChars: 'MIN CARACTERES', aiAutoMod: 'Auto Moderation AI',
        attachMediaTooltip: 'Los participantes pueden agregar imágenes o video a su respuesta.',
        wordbookTooltip: 'Los participantes pueden elegir palabras de una lista predefinida. <br> Personaliza las listas de palabras en Configuración de la Comunidad > Wordbooks.',
        minCharsTooltip: 'Guiará a los participantes a decidir la cantidad de texto que se espera que agreguen.',
        mandatoryTooltip: 'Los participantes están obligados a responder con tipos particulares de data.',
        aiAutoModTT: 'Tarea moderada por AI',
        addImageToTask: 'Agrega una imagen en las instrucciones de esta tarea.', addVideoToTask: 'Agrega una video en las instrucciones de esta tarea.'
      }
    }
    const minChar = question.data.minChar !== '' ? parseInt(question.data.minChar) : '';

    let btnImgClass = 'btn-media have-image';
    let imgUrl = '';
    let imgStyle = null;
    if(typeof question.blob !== 'undefined'){
      if(this.refs.imgPreviewOEEdit !== undefined){
        setBlobToPreview(question.blob, this.refs.imgPreviewOEEdit);
      }
      btnImgClass = 'btn-media have-image';
      imgStyle = {display: 'block'};
    } else {
      if(question.attachment.length !== 0){
        imgUrl = question.attachment[0].url;
        imgStyle = {display: 'block'};
      } else {
        btnImgClass = 'btn-media';
        imgStyle = {display: 'none'};
      }
    }

    let videoThumbnail = null;
    if(question.videoState){
      videoThumbnail = question.videoState === 'processed' ? question.video_thumbnail : '/assets/loader_200x200.gif';
    } else {
      videoThumbnail = question.video_thumbnail;
    }

    let defaultMandatory = null;
    let defaultMedia = [{value: 'Images', label: 'Images'}, {value: 'Video', label: 'Video'}];

    if(question.required){
      switch(question.question_type){
        case 'Open End':
          defaultMandatory = [{value: 'Text', label: 'Text'}];
          break;
      }
    }

    // Custom icon for dropdown selector - React select
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon icon={['fas', 'sort']} className="dropdown-icon" data-tooltip-content="" />
        </components.DropdownIndicator>
      );
    };
    // Custom icon for clear selections indicator - React select
    const ClearIndicator = props => {
      return (
        <components.ClearIndicator {...props}>
          <FontAwesomeIcon icon={['fal', 'xmark']} className="clear-indicator-icon" data-tooltip-content="Revert to default" />
        </components.ClearIndicator>
      );
    };
    // Custom icon for value remove - React select
    const MultiValueRemove = props => {
      return (
        <components.MultiValueRemove {...props}>
          <FontAwesomeIcon icon={['fal', 'xmark']} className="clear-indicator-icon" data-tooltip-content="" />
        </components.MultiValueRemove>
      );
    };

    // Custom styles for mandatory select - via React Select 
    const customSelectStyles = {
      control: (base) => ({
        ...base,
        width: 'calc(100% - 25px)',
        borderRadius: '6',
        border: '0.7px solid #DADDE1',
        marginLeft:'25px',
        minHeight: '30px',
        '&:hover': {
          border: '0.7px solid #DADDE1'
        }
      }),
      valueContainer: (base) => ({
        ...base,
        paddingTop: '4px',
        paddingBottom: '4px',
      }),
      multiValue: (base) => ({
        ...base,
        marginTop: '1',
        marginBottom: '1',
      }),
      multiValueLabel: (base) => ({
        ...base,
        backgroundColor: '#AFBDC1',
        color: '#ffffff',
        borderRadius: '2px 0 0 2px',
        textTransform: 'uppercase',
        fontSize: '8px',
        fontFamily: "'Sharp Sans ExtraBold', Helvetica, sans-serif",
        letterSpacing: '1.8px',
        paddingTop: '2px',
        paddingBottom: '1px',
        marginTop: '0',
        marginBottom: '0',
        lineHeight: '15px'
      }),
      multiValueRemove: (base) => ({
        ...base,
        backgroundColor: '#AFBDC1',
        color: '#FFFFFF',
        fontSize: '8px',
        borderRadius: '0 2px 2px 0',
        cursor: 'pointer',
        padding: '0 5px',
        '&:hover': {
          backgroundColor: '#7C8593',
          color: '#FFFFFF'
        }
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
      }),
      input: (base) => ({
        ...base,
        marginTop: '0',
        marginBottom: '0',
        paddingTop: '0',
        paddingBottom: '0',
        //display: 'none'
      }),
      clearIndicator: (base) => ({
        ...base,
        cursor: 'pointer',
        padding: '5px 4px 5px 8px',
        fontSize: '11px'
      }),
      dropdownIndicator: (base) => ({
        ...base,
        fontSize: '12px',
        color: '#7C8593',
        paddingRigth: '10px',
        padding: '5px 10px 5px 8px',
        cursor: 'pointer',
        '&:hover': {
          color: '#7C8593',
        }
      }),
      menu: (base) => ({
        ...base,
        background: '#F2F2F2',
        padding: '5px 8px',
        width: '218px',
        marginLeft: '20px',
        //padding: '0'
      }),
      menuList: (base) => ({
        ...base,
        paddingBottom: '8px'
      }),
      option: (base, state) => ({
        ...base,
        fontSize: '8px',
        fontFamily: 'Arial, sans-serif',
        padding: '0 12px',
        height: '18px',
        textTransform: 'uppercase',
        backgroundColor: state.isFocused ? '#F2F2F2' : '#F2F2F2',
        '&:hover': {
          backgroundColor: '#5C95F1',
          color: '#FFFFFF'
        }
      }),
    };

    return (
      <div className="panel panel-activity-builder-details edit-mode">
        <div className="px-support-tooltip top is_hidden px-support-builder">
          <p>Customize your Task here, by setting its instructions and what type of data the participant should share.</p>
          <i/>
        </div>
        <div className="top-border"/>
        <div className="bottom-border"/>
        <div className="left-border"/>
        <div className="right-border"/>
        <div className="panel-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="title-task-container edit">
                <div className="task-number" style={{ backgroundColor: colors.color0[1] }}><span>{cardinal}</span></div>
                <span className="title-task">{question.task_type}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-task-edit-container">
              <div className="px-left-actv-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <EditorWYSIWYGState name="title"
                                        editorName="editorQuestion"
                                        editorState={question.editorQuestion}
                                        onEditorStateChange={this.props.onEditorStateChangeQuestion}
                                        questionId={question.id}
                                        language={language}/>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="btn-media-container">
                          <div className="image-item">
                            <button onClick={() => this.props.updateShowModal('image', question.id)} className={btnImgClass} data-tooltip-content={i18n[language].addImageToTask}>
                              {
                                question.attachment.length === 0 && typeof question.blob === 'undefined' ?
                                  <FontAwesomeIcon icon={['fas', 'image']}/> : null
                              }
                              <img ref='imgPreviewOEEdit' src={imgUrl} alt="" style={imgStyle}/>
                            </button>
                            {
                              (question.attachment.length !== 0 || question.blob !== undefined) &&
                              <div onClick={() => this.props.deleteMedia(question.id, 'image')} className="px-btn-close-float">
                                <FontAwesomeIcon icon={['fas','xmark']}/>
                              </div>
                            }
                          </div>
                          <div className="image-item">
                            {
                              question.video_thumbnail === '' ?
                                <button onClick={() => this.props.updateShowModal('video', question.id)} className='btn-media' data-tooltip-content={i18n[language].addVideoToTask}>
                                  <FontAwesomeIcon icon={['fas', 'video']}/>
                                </button> :
                                <button onClick={this.updateShowModalVideo} className='btn-media have-image'>
                                  <img src={videoThumbnail} alt=""/>
                                </button>
                            }
                            {
                              question.video_thumbnail !== '' &&
                              <div onClick={() => this.props.deleteMedia(question.id, 'video')} className="px-btn-close-float">
                                <FontAwesomeIcon icon={['fas','xmark']}/>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-right-actv-container">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="px-check-cont">
                        <label className="container-check"
                               style={{cursor: question.task_type === 'Open End' ? 'pointer' : 'default'}}>
                          <input name="media" type="checkbox" checked={question.media}
                                 disabled={question.task_type === 'Multimedia'}
                                 onChange={(event) => this.props.handleChangeQuestion(event, question.id, false)}/>
                          <span className="checkmark"/>
                          <span>{i18n[language].attachMedia}</span>
                          <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                           data-tooltip-content={i18n[language].attachMediaTooltip}/>
                        </label>
                      </div>
                      {
                        question.media &&
                        <Select name="attach_media" className="react-select" classNamePrefix="react-select"
                                value={question.media_options.length === 0 ? defaultMedia :
                                  question.media_options.map(opt => ({value: opt, label: opt}))}
                                onChange={(event) => this.props.handleCanAttachMedia(event, question.id)}
                                options={defaultMedia}
                                isMulti={true}
                                components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
                                styles={customSelectStyles}/>
                      }
                      {/*
                        communitySocial &&
                        <div className="px-check-cont">
                          <label className="container-check">
                            <input name="show" type="checkbox" checked={question.show}
                                  onChange={(event) => this.props.handleChangeQuestion(event, question.id, false)}/>
                            <span className="checkmark"/>
                            <span>ANSWER FIRST, THEN SEE</span>
                            <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                             data-tooltip-content="Participants have to complete this task before being able to
                                             see other participant's responses for this same task."/>
                          </label>
                        </div>
                      */}
                      <div className="px-check-cont">
                        <label className="container-check">
                          <input name="maps_state" type="checkbox" checked={question.maps_state}
                                onChange={(event) => this.props.handleChangeQuestion(event, question.id, false)}/>
                          <span className="checkmark"/>
                          <span>{i18n[language].enableWB}</span>
                          <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                           data-tooltip-content={i18n[language].wordbookTooltip}/>
                        </label>
                      </div>
                      {
                        question.maps_state &&
                        <div className="px-select" style={{marginBottom:'15px'}}>
                          <select name="sort_coll" onChange={(event) => this.props.handleMapsSelected(event, question.id)}
                                  value={question.maps_id[0]}>
                            {
                              mapsSelect.filter(map => map.title[communityLang] !== undefined && map.title[communityLang] !== 'pixiebob_qc').map(m => {
                                return(
                                  <option key={m.id} value={m.id}>{m.title[communityLang]}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                      }
                      <div className="px-check-cont">
                        <label className="container-check">
                          <input name="required" type="checkbox" checked={question.required}
                                onChange={(event) => this.props.handleChangeQuestion(event, question.id, false)}/>
                          <span className="checkmark"/>
                          <span>{i18n[language].mandatory}</span>
                          <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                        data-tooltip-content={i18n[language].mandatoryTooltip} />
                        </label>
                      </div>
                      {
                        question.required &&
                          <Select className="react-select" classNamePrefix="react-select"
                                  value={question.required_options.length === 0 ? defaultMandatory :
                                  question.required_options.map(opt => ({value: opt, label: opt}))}
                                  onChange={(event) => this.props.handleRequired(event, question.id)}
                                  options={question.requiredOptsView.map(opt => ({value: opt, label: opt}))}
                                  isMulti={true}
                                  components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
                                  styles={customSelectStyles}/>
                      }
                      {
                        this.state.showAiAutoModeration &&
                        <div className="px-check-cont">
                          <label className="container-check">
                            <input name="ai_auto_moderation" type="checkbox" checked={question.ai_auto_moderation}
                                   onChange={(event) => this.props.handleChangeQuestion(event, question.id, false)}/>
                            <span className="checkmark"/><span>{i18n[language].aiAutoMod}</span>
                            <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                             data-tooltip-content={i18n[language].aiAutoModTT} />
                          </label>
                        </div>
                      }
                      {
                        question.task_type === 'Open End' &&
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="char-container">
                              <span className="span-char">{i18n[language].minChars}</span>
                              <input name="minChar" type="number" value={minChar} className="input-char" min={1}
                                     onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)}/>
                              <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                               data-tooltip-content={i18n[language].minCharsTooltip} />
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-save-row">
                <button onClick={() => this.props.updateShowModal('preview task OE', question.id)}
                  className="btn-preview">{i18n[language].preview}</button>
                <div className="action-button-container">
                  <button onClick={ this.props.isSavingTask !== question.id ?
                    () => {this.props.updateQuestion(question.id)} : null }
                          className="btn-save"
                          style={{backgroundColor: colors.color1[3]}}>
                    {this.props.isSavingTask === question.id ?
                      <FontAwesomeIcon icon={['fas','spinner']} spin={true} style={{color: '#ffffff'}}/>
                      : i18n[language].save}
                  </button>
                  <button onClick={() => this.props.cancelEditQuestion(question.id)}
                    className="btn-cancel">{i18n[language].cancel}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="actions-panel">
          <div className="actions-container">
            <button onClick={this.props.isSavingTask !== question.id ?
              () => this.props.updateQuestion(question.id) : null}
                    className="action-button edit">
              {this.props.isSavingTask === question.id ?
                <FontAwesomeIcon icon={['fas','spinner']} spin={true} style={{color: '#ffffff'}}/>
                : <FontAwesomeIcon icon={['far', 'check']}/>}
            </button>
            <button onClick={() => this.props.cancelEditQuestion(question.id)} className="action-button edit">
              <FontAwesomeIcon icon={['far', 'xmark']}/>
            </button>
          </div>
        </div>
        {
          question.video_thumbnail !== '' &&
          <ReactModal isOpen={this.state.showModalVideo} contentLabel="Response Video"
                      shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalVideo}
                      className="my-modal-content" overlayClassName="my-modal-overlay">
            <T_ModalVideo updateShowModalVideo={this.updateShowModalVideo}
                          thumbnail={question.video_thumbnail}
                          url={question.video_url}
                          language={language}
                          colors={colors}/>
          </ReactModal>
        }
      </div>
    );
  }

  updateShowModalVideo = () => {
    this.setState(state => ({showModalVideo: !state.showModalVideo}));
  };

  isTaskAutoModerationAllowed =() => {
    let taskAutoModerationAllowed = false;
    const {question, cmntyAiAutoModeration, cmntyAllowImgs, cmntyAiPlan, communityProjectType } = this.props
    let canAttachVideo = question.media_options.includes('Video')
    let isPlanPro = cmntyAiPlan === 'aiPlan01' || cmntyAiPlan === 'aiPlan02'
    let has_media = this.checkMedia(question);
    let has_workbook = question.maps_state;

    if (communityProjectType === 'Mixed' && isPlanPro && cmntyAiAutoModeration && !has_media && !has_workbook) {
      if (question.media === false) {
        taskAutoModerationAllowed = true;
      } else if (!canAttachVideo && cmntyAllowImgs && cmntyAiPlan === 'aiPlan02') {
        taskAutoModerationAllowed = true;
      }
    }

    return taskAutoModerationAllowed
  }

  checkMedia = (question) => {
    // Check if a video is attached to the question
    const hasVideo = question.video_url !== null && question.video_url !== "";

    // Check if an image is attached to the question - 'attachment' is not an empty array
    const hasAttachment = question.attachment.length > 0;

    const hasImagePreview = Boolean(question.blobPreview || question.blob);
    return hasVideo || hasAttachment || hasImagePreview
  }
}
