import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"
import {DateRangePicker} from "react-dates";
import moment from 'moment';
import {numberFilterSegment} from "../../../../UI/Globals/PX_Funs";

export default class AEMSelect extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    updateArrayTask: PropTypes.func.isRequired,
    updateArrayTaskFilters: PropTypes.func.isRequired,
    gender_as_segment: PropTypes.bool,
    segments: PropTypes.array,
    task: PropTypes.object,
    tags: PropTypes.array,
    tasks: PropTypes.array,
    activeUsers: PropTypes.array,
    objSearch: PropTypes.object,
    handleChange: PropTypes.func,
    updateStartExpireDates: PropTypes.func,
    from: PropTypes.string,
    searchModal: PropTypes.func,
    actDates: PropTypes.object,
    updateStartExpireDatesModal: PropTypes.func,
    doUpdateShowModal: PropTypes.func,
    updateModalAction: PropTypes.func,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      q: '',
      segments: [],
      tags: [],
      tasks: [],
      filterAllNone: false,
      type: null,
      focusedInput: null,
      showCalendarOpts: false,
      segmentsSel: props.task.segments,
      tagsSel: props.task.tag,
      requiredSel: props.task.required
    }
  }

  // TO-DO It would be better to receive a prop called 'type' with the right modalType to show
  componentDidMount(){
    console.log('Init Search Modal');
    if(this.props.segments !== undefined){
      this.setState({segments: this.props.segments.map(segment => ({...segment, isVisible: true})), type: 'segments'});
    }

    if(this.props.tags !== undefined){
      this.setState({tags: this.props.tags.map(tag => ({...tag, isVisible: true})), type: 'tags'});
    }

    if(this.props.title === 'Pick Required Activity'){
      this.setState({tasks: this.props.tasks.map(task => ({...task, isVisible: true})), type: 'tasks'});
    } else if(this.props.title === 'Pick Activities') {
      this.props.searchModal(this.state.q, 'tasks');
      this.setState({type: 'tasks'});
    }

    if(this.props.title === 'Pick Participants'){
      this.props.searchModal(this.state.q, 'users');
      this.setState({type: 'users'});
    }
    if(this.props.title === 'Pick Segments '){
      this.props.searchModal(this.state.q, 'segments');
      this.setState({type: 'segments'});
    }
    if(this.props.title === 'Pick Tags '){
      this.props.searchModal(this.state.q, 'tags');
      this.setState({type: 'tags'});
    }
  }

  render() {
    const {title, language, task, objSearch, actDates, colors} = this.props;

    const i18n = {
      en: {
        btn_save: 'Update', btn_cancel: 'Close', search: 'SEARCH', all: 'All',
        none: 'None', available: 'Available', expired: 'Expired', participants: 'participants',
        activities: 'activities', tasks: 'tasks', creationDate: 'Creation Date', 
        startDate: 'Start Date', endDate: 'End Date'
      },
      es: {
        btn_save: 'Actualizar', btn_cancel: 'Cerrar', search: 'BUSCAR', all: 'Todo',
        none: 'Nada', available: 'Disponibles', expired: 'Expiradas', participants: 'participantes',
        activities: 'actividades', tasks: 'tareas', creationDate: 'Fecha de creación',
        startDate: 'Fecha de inicio', endDate: 'Fecha de fin'
      }
    };

    let segments = [];
    let tags = [];
    let tasks = [];
    let type = null;
    let activeUsers = [];

    let rdoAll = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="All"
             checked={this.state.filterAllNone === 'All'}/>
        <span className="checkmark radio"/>
        <span>{i18n[language].all}</span>
      </label>
    </div>;
    let rdoNone = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="None"
              checked={this.state.filterAllNone === 'None'}/>
        <span className="checkmark radio"/>
        <span>{i18n[language].none}</span>
      </label>
    </div>;
    let rdoAvailable = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="Available"
              checked={this.state.filterAllNone === 'Available'}/>
        <span className="checkmark radio"/>
        <span>{i18n[language].available}</span>
      </label>
    </div>;
    let rdoExpired = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="Expired"
              checked={this.state.filterAllNone === 'Expired'}/>
        <span className="checkmark radio"/>
        <span>{i18n[language].expired}</span>
      </label>
    </div>;
    let searchForm = <div className="form-group xs first">
      <div className="px-input-icon">
        <input name="q" type="text" onChange={this.handleFilters} onKeyDown={(e) => this.keyPress(e, this.state.type)}
          className="form-control icon-right" placeholder={i18n[language].search} />
        <FontAwesomeIcon icon={['fas', 'magnifying-glass']} className="px-icon-right color-gray-base" />
      </div>
    </div>;
    let updateBtn = null;

    if(this.props.segments !== undefined && title === 'Pick Segments'){
      type = 'segments';
      rdoAvailable = null;
      rdoExpired = null;
      rdoAll = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="All"
                 checked={this.state.segmentsSel.length === this.props.segments.length}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].all}</span>
        </label>
      </div>;
      rdoNone = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="None"
                 checked={this.state.segmentsSel.length === 0}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].none}</span>
        </label>
      </div>;

      updateBtn = <button type="button" onClick={() => this.props.updateModalAction(null, null, null, 'updateActSegments',
          task.id, this.state.segmentsSel)} className="btn px-btn sm btn-blue-base" style={{backgroundColor: colors.color1[3]}}>{i18n[language].btn_save}
      </button>;

      segments = this.props.segments.map(segment => {
        if(segment.modalVisibility){
          const checked = this.state.segmentsSel.find(s => s.value === segment.id) !== undefined;
          let icon = null;

          switch(segment.name){
            case 'Female':
              icon = ['fal', 'venus'];break;
            case 'Male':
              icon = ['fal', 'mars'];break;
            case 'Other Gender':
              icon = ['fal', 'transgender-alt'];break;
            default:
              icon = ['fas', 'bookmark'];
          }

          //if(segment.isVisible){
          return(
            <div key={segment.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
              <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
              <div className="px-check-cont rad">
                <label className="container-check filters-modal img">
                  <input type="checkbox" onChange={() => this.updateSelection(segment, 'segment')}
                         checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <FontAwesomeIcon icon={icon} className="tag icon" style={{color: segment.color}}/>
                  <div className="user-data">
                    <div className="username" data-tooltip-content={segment.name}>{segment.name}</div>
                    <div className="txt-email">
                      {numberFilterSegment(segment.id, this.props.activeUsers, this.props.gender_as_segment)} {i18n[language].participants}
                    </div>
                  </div>
                </label>
              </div>
            </div>
          )
          //}
        }
      });

      if(segments.length === 0 ) {
        segments = this.setInfoMessage(type);
        searchForm = null;
        rdoAll = null;
        rdoNone = null;
        footerButtons = null;
      }
    }

    if(this.props.tags !== undefined && title === 'Pick Activity Tags'){
      type = 'tags';
      rdoAll = null;
      rdoAvailable = null;
      rdoExpired = null;
      rdoNone = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="None"
                 checked={!this.state.tagsSel}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].none}</span>
        </label>
      </div>;
      updateBtn = <button type="button"
                          onClick={() => this.props.updateModalAction(null, null, null, 'updateActTag', task.id, this.state.tagsSel)}
        className="btn px-btn sm btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{i18n[language].btn_save}</button>;

      tags = this.props.tags.map(tag => {
        if(tag.modalVisibility){
          const checked = this.state.tagsSel ? this.state.tagsSel.id === tag.id : false;

          //if(tag.isVisible){
          return(
            <div key={tag.id} className="col-xs-6 col-sm-4 col- md-4 col-lg-4">
              <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
              <div className="px-check-cont">
                <label className="container-check filters-modal radio img">
                  <input  type="radio" onChange={() => this.updateSelection(tag, 'tag')} checked={checked}/>
                  <span className="checkmark radio filters-modal"/>
                  <FontAwesomeIcon icon={['fas','tag']} className="tag icon" style={{color: tag.color}}/>
                  <div className="user-data">
                    <div className="username" data-tooltip-content={tag.name}>{tag.name}</div>
                    <span className="txt-email">
                      {this._numberFilterTag(tag.id, this.props.tasks)} {i18n[language].activities}
                    </span>
                  </div>
                </label>
              </div>
            </div>
          )
          //}
        }
      });

      /*tags = this.props.tags.map(tag => {
        if(tag.modalVisibility){
          const checked = task.tag ? tag.id === task.tag.id : false;

          //if(tag.isVisible){
          return(
            <div key={tag.id} className="col-xs-6 col-sm-4 col- md-4 col-lg-4">
              <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
              <div className="px-check-cont">
                <label className="container-check filters-modal radio img">
                  <input  type="radio" onChange={() => this.props.updateArrayTask(tag, 'tag')} checked={checked}/>
                  <span className="checkmark radio filters-modal"/>
                  <FontAwesomeIcon icon={['fas','tag']} className="tag icon" style={{color: tag.color}}/>
                  <div className="user-data">
                    <div className="username" data-tooltip-content={tag.name}>{tag.name}</div>
                    <span className="txt-email">
                      {this._numberFilterTag(tag.id, this.props.tasks)} activities
                    </span>
                  </div>
                </label>
              </div>
            </div>
          )
          //}
        }
      });*/

      if(tags.length === 0) {
        tags = this.setInfoMessage(type);
        searchForm = null; rdoNone = null; footerButtons = null;
      }
    }

    if(title === 'Pick Required Activity'){
      type = 'tasks';
      rdoAll = null;
      rdoNone = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="None"
                 checked={!this.state.requiredSel}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].none}</span>
        </label>
      </div>;
      updateBtn = <button type="button"
                          onClick={() => this.props.updateModalAction(null, null, null, 'updateActRequired', task.id,
                            this.state.requiredSel)}
                          className="btn px-btn sm btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{i18n[language].btn_save}</button>;

      tasks = this.props.tasks.map(ct => {
        if(ct.modalVisibility){
          const checked = this.state.requiredSel ? ct.id === this.state.requiredSel.value : false;

          //if(ct.isVisible){
          if(ct.id !== task.id){
            return(
              <div key={ct.id} className="col-xs-6 col-sm-4 col- md-4 col-lg-4">
                <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
                <div className="px-check-cont">
                  <label className="container-check filters-modal radio">
                    <input  type="radio" onChange={() => this.updateSelection(ct, 'task')} checked={checked}/>
                    <span className="checkmark radio filters-modal"/>
                    <div className="user-data">
                      <div className="username" data-tooltip-content={ct.title}>{ct.title}</div>
                      <div className="txt-email">{ct.questions.length} {i18n[language].tasks}</div>
                    </div>
                  </label>
                </div>
              </div>
            )
          }
          //}
        }
      });

      /*tasks = this.props.tasks.map(ct => {
        if(ct.modalVisibility){
          const checked = task.required ? ct.id === task.required.value : false;

          //if(ct.isVisible){
            if(ct.id !== task.id){
              return(
                <div key={ct.id} className="col-xs-6 col-sm-4 col- md-4 col-lg-4">
                  <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
                  <div className="px-check-cont">
                    <label className="container-check filters-modal radio">
                      <input  type="radio" onChange={() => this.props.updateArrayTask(ct, 'task')} checked={checked}/>
                      <span className="checkmark radio filters-modal"/>
                      <div className="user-data">
                        <div className="username" data-tooltip-content={ct.title}>{ct.title}</div>
                        <div className="txt-email">{ct.questions.length} tasks</div>
                      </div>
                    </label>
                  </div>
                </div>
              )
            }
          //}
        }
      });*/

      if(tasks.length <= 1) {
        tasks = this.setInfoMessage(type);
        searchForm = null;
        rdoAvailable = null;
        rdoExpired = null;
        rdoNone = null;
        footerButtons = null;
      }
    }

    if(title === 'Pick Activities'){
      rdoAll = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="All"
                checked={this.props.tasks.length === objSearch.idActs.length}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].all}</span>
        </label>
      </div>;
      rdoNone = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="None"
                checked={objSearch.idActs.length === 0}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].none}</span>
        </label>
      </div>;
      rdoAvailable = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="Available"
                checked={this.state.filterAllNone === 'Available'}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].available}</span>
        </label>
      </div>;

      tasks = this.props.tasks.map(ct => {
        if(ct[this._getObjectModVis(this.props.from)].isAct){
          const checked = objSearch.idActs.find(id => id === ct.id) !== undefined;

          return(
            <div key={ct.id} className="col-xs-6 col-sm-4 col- md-4 col-lg-4">
              <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

              <div className="px-check-cont">
                <label className="container-check filters-modal">
                  <input type="checkbox" onChange={() => this.handleCheckbox(ct, 'task')}
                              checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <div className="user-data">
                    <div className="username" data-tooltip-content={ct.title}>{ct.title}</div>
                    <div className="txt-email">{ct.questions.length} {i18n[language].tasks}</div>
                  </div>
                </label>
              </div>
            </div>
          )
        }
      });
    }

    if(title === 'Pick Participants'){
      rdoAll = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="All"
                checked={this.props.activeUsers.length === objSearch.idPars.length}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].all}</span>
        </label>
      </div>;
      rdoNone = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="None"
                checked={objSearch.idPars.length === 0}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].none}</span>
        </label>
      </div>;

      activeUsers = this.props.activeUsers.map(user => {
        //console.log(this.props.from, this._getObjectModVis(this.props.from));
        if(user[this._getObjectModVis(this.props.from)].isPar){
          const checked = objSearch.idPars.find(id => user.user_id === id) !== undefined;

          return(
            <div key={user.user_id} className="col-xs-6 col-sm-4 col- md-4 col-lg-4">
              <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
              <div className="px-check-cont">
                <label className="container-check filters-modal img">
                  <input type="checkbox" onChange={() => this.props.updateArrayTask(user, 'user')}
                           checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <img src={user.avatar !== '' ? user.avatar : '/assets/user.png'} className="img-circle px-thumb-20" alt=""/>
                  <div className="user-data">
                      <div className="username" data-tooltip-content={user.username}>{user.username}</div>
                      <div className="txt-email">{user.email}</div>
                  </div>
                </label>
              </div>
            </div>
          )
        }
      });
    }

    if(title === 'Pick Segments '){
      rdoAll = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="All"
                checked={this.props.segments.length === objSearch.idSegs.length}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].all}</span>
        </label>
      </div>;
      rdoNone = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="None"
                checked={objSearch.idSegs.length === 0}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].none}</span>
        </label>
      </div>;

      segments = this.props.segments.map(segment => {
        if(segment[this._getObjectModVis(this.props.from)].isSeg){
          const checked = objSearch.idSegs.find(id => segment.id === id) !== undefined;

          let icon = null;
          switch(segment.name){
            case 'Female':
              icon = ['fal', 'venus'];break;
            case 'Male':
              icon = ['fal', 'mars'];break;
            case 'Other Gender':
              icon = ['fal', 'transgender-alt'];break;
            default:
              icon = ['fas', 'bookmark'];
          }

          return(
            <div key={segment.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
              <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

              <div className="px-check-cont">
                <label className="container-check filters-modal img">
                  <input type="checkbox" onChange={() => this.props.updateArrayTask(segment, 'segment')}
                           checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <FontAwesomeIcon icon={icon} className="tag icon" style={{color: segment.color}}/>
                  <div className="user-data">
                    <div className="username" data-tooltip-content={segment.name}>{segment.name}</div>
                    <div className="txt-email">
                      {numberFilterSegment(segment.id, this.props.activeUsers, this.props.gender_as_segment)} {i18n[language].participants}
                    </div>
                  </div>
                </label>
              </div>
            </div>
          )
        }
      });
    }

    if(title === 'Pick Tags '){
      rdoAll = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="All"
                checked={this.props.tags.length === objSearch.idTags.length}/>
          <span className="checkmark
          radio"/>
          <span>{i18n[language].all}</span>
        </label>
      </div>;
      rdoNone = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name="filterAllNone" type="radio" onChange={this.handleFilters} value="None"
                checked={objSearch.idTags.length === 0}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].none}</span>
        </label>
      </div>;

      tags = this.props.tags.map(tag => {
        if(tag[this._getObjectModVis(this.props.from)].isTag){
          const checked = objSearch.idTags.find(id => tag.id === id) !== undefined;

          return(
            <div key={tag.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
              <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

              <div className="px-check-cont">
                <label className="container-check filters-modal img">
                  <input type="checkbox" onChange={() => this.props.updateArrayTask(tag, 'tag')}
                           checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <FontAwesomeIcon icon={['fas','tag']} className="tag icon" style={{color: tag.color}}/>
                  <div className="user-data">
                    <div className="username" data-tooltip-content={tag.name}>{tag.name}</div>
                    <span className="txt-email">{this._numberFilterTag(tag.id, this.props.tasks)} {i18n[language].activities}</span>
                  </div>
                </label>
              </div>
            </div>
          )
        }
      });
    }

    let footerButtons = <div className="px-footer-container-buttons row">
      {updateBtn}
      <button type="button" onClick={() => this.props.updateShowModal(null, null)}
              className="btn px-btn sm btn-gray-darker inverted">{i18n[language].btn_cancel}</button>
    </div>;

    return (
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
            <i onClick={() => this.props.updateShowModal(null, null)} className="material-icons">clear</i>
          </button>
          <h4 className="px-modal-title">{title}</h4>
        </div>

        <div className="modal-body px-modal-body-adv-filters">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-filter-adv-filter-modal-cont">
                {searchForm}
                <div className="px-right-container">
                  { rdoAll }
                  { rdoNone }
                  { title === 'Pick Activities' && rdoAvailable }
                  { title === 'Pick Activities' && rdoExpired }
                  {
                    ((title === 'Pick Activities' || title === 'Pick Required Activity') && this.props.tasks.length !== 1 )&&
                      <FontAwesomeIcon icon={['fal', 'calendar-days']} onClick={this.updateShowCalendarOpts}
                                       className='px-adv-filters-calendar' style={{cursor:'pointer'}}/>
                  }
              </div>
              </div>
            </div>
          </div>
          {
            (title === 'Pick Required Activity' && this.state.showCalendarOpts) &&
            <div className="px-date-filters">
              <div className="px-select">
                <select name="slcActDates" value={actDates.name} onChange={this.props.handleChange}>
                  <option value="creation">{i18n[language].creationDate}</option>
                  <option value="start">{i18n[language].startDate}</option>
                  <option value="expiry">{i18n[language].endDate}</option>
                </select>
              </div>
              <div className="date-container">
                <DateRangePicker
                  startDate={actDates.startDate ? moment(actDates.startDate) : null} // momentPropTypes.momentObj or null,
                  startDateId="your_unique_start_date_id"
                  endDate={actDates.endDate ? moment(actDates.endDate) : null} // momentPropTypes.momentObj or null,
                  endDateId="your_unique_end_date_id"
                  onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate },
                    () => this.showDates())} // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  minimumNights={0}
                  displayFormat="DD/MM/YYYY"
                  isOutsideRange={() => false}
                  isDayHighlighted={ day => day.isSame( moment(), 'd' ) }
                  numberOfMonths={1}
                  daySize={30}
                  hideKeyboardShortcutsPanel={true}
                />
              </div>
            </div>
          }
          {
            (title === 'Pick Activities' && this.state.showCalendarOpts) &&
            <div className="px-date-filters">
              <div className="px-select">
                <select name="slcActDates" value={objSearch.actDates.name} onChange={this.props.handleChange}>
                  <option value="creation">Creation Date</option>
                  <option value="start">Start Date</option>
                  <option value="expiry">End Date</option>
                </select>
              </div>
              <div className="date-container">
                <DateRangePicker
                  startDate={objSearch.actDates.startDate ? moment(objSearch.actDates.startDate) : null} // momentPropTypes.momentObj or null,
                  startDateId="your_unique_start_date_id"
                  endDate={objSearch.actDates.endDate ? moment(objSearch.actDates.endDate) : null} // momentPropTypes.momentObj or null,
                  endDateId="your_unique_end_date_id"
                  onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate },
                    () => this.showDates())} // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  minimumNights={0}
                  displayFormat="DD/MM/YYYY"
                  isOutsideRange={() => false}
                  isDayHighlighted={ day => day.isSame( moment(), 'd' ) }
                  numberOfMonths={1}
                  daySize={30}
                  hideKeyboardShortcutsPanel={true}
                />  
              </div>
            </div>
          }
          <div className="modal-scroll">
            <div className="row">
              { segments }
              { tags }
              { tasks }
              { activeUsers }
            </div>
          </div>
        </div>

        <div className="modal-footer px-modal-footer-gray">
            {footerButtons}
        </div>
      </div>
    );
  }

  updateSelection = (obj, type) => {
    console.log(obj, type);
    switch(type){
      case 'segment':
        const isFound = this.state.segmentsSel.find(segment => segment.value === obj.id);
        let segmentsSel;

        if(isFound === undefined){
          segmentsSel = [...this.state.segmentsSel];
          segmentsSel.push({value: obj.id, label: obj.name, color: obj.color});
        } else {
          segmentsSel = this.state.segmentsSel.filter(segment => segment.value !== obj.id)
        }

        this.setState({segmentsSel});
        break;
      case 'tag':
        this.setState({tagsSel: obj});
        break;
      case 'task':
        this.setState({requiredSel: {label: obj.title, value: obj.id}});
        break;
    }
  };

  _getObjectModVis(from){
    let name = null;

    switch(from){
      case 'Activities': name = 'modAct';break;
      case 'Participants': name = 'modPar';break;
      case 'Matrix': name = 'modMat';break;
      case 'Now': name = 'modNow';break;
    }

    return name;
  }

  handleCheckbox = (obj, type) => {
    this.setState({filterAllNone: false}, () => {
      this.props.updateArrayTask(obj, type);
    });
  };

  _numberFilterTag(tagId, tasks){
    let counter = 0;

    tasks.forEach(task => {
      if(task.tag){
        if(task.tag.id === tagId){
          ++counter;
        }
      }
    });

    return counter
  }

  // Maybe onDatesChange just needs to send the dates, but this.state is to show dates in the calendar
  showDates = () => {
    const startDate = this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : null;
    const endDate = this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null;
    //console.log(startDate, endDate);
    this.props.updateStartExpireDatesModal(startDate, endDate);
  };

  updateShowCalendarOpts = () => {
    this.setState(state => ({showCalendarOpts: !state.showCalendarOpts}));
    if (this.state.showCalendarOpts === true){
      document.getElementsByClassName("px-filter-adv-filter-modal-cont")[0].style.margin = '0 0 30px 0';
      document.getElementsByClassName("px-adv-filters-calendar")[0].style.color = '#AFBDC1';
    } else {
      document.getElementsByClassName("px-filter-adv-filter-modal-cont")[0].style.margin = '0 0 15px 0';
      document.getElementsByClassName("px-adv-filters-calendar")[0].style.color = this.props.colors.color1[3];
    }
  };

  handleFilters = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value}, () => {
      switch(this.state.type){
        case 'segments':
          let segmentsSel;
          switch(this.state.filterAllNone){
            case 'All':
              segmentsSel = this.props.segments.map(s => ({value: s.id, label: s.name, color: s.color}));
              break;
            case 'None':
              segmentsSel = [];
              break;
          }
          console.log(segmentsSel);
          this.setState({segmentsSel});
          break;
        case 'tags':
          let tagsSel;
          switch(this.state.filterAllNone){
            case 'None':
              tagsSel = null;
              break;
          }
          this.setState({tagsSel});
          break;
        case 'tasks':
          let requiredSel;
          switch(this.state.filterAllNone){
            case 'None':
              requiredSel = null;
              break;
            case 'Dates':
              const actDates = {...this.state.actDates};

              if(actDates.startDate && actDates.endDate) {
                const startDate = moment(actDates.startDate).format('YYYY-MM-DD');
                const endDate = moment(actDates.endDate).format('YYYY-MM-DD');
                //console.log(startDate, endDate);
                switch(actDates.name){
                  case 'creation':
                    tasks = this.state.tasks.map(task => {
                      const createdAt = moment(task.created_at).format('YYYY-MM-DD');
                      console.log(createdAt, startDate, endDate);
                      if(createdAt >= startDate && createdAt <= endDate) {
                        return {...task, modalVisibility: true}
                      } else {
                        return {...task, modalVisibility: false}
                      }
                    });
                    break;
                  case 'start':
                    tasks = this.state.tasks.map(task => {
                      if(task.start){
                        const taskStart = moment(task.start).format('YYYY-MM-DD');
                        console.log(taskStart, startDate, endDate);
                        if(taskStart >= startDate && taskStart <= endDate) {
                          return {...task, modalVisibility: true}
                        } else {
                          return {...task, modalVisibility: false}
                        }
                      } else {
                        return {...task, modalVisibility: true}
                      }
                    });
                    break;
                  case 'expiry':
                    tasks = this.state.tasks.map(task => {
                      if(task.expire){
                        const expireDate = moment(task.expire).format('YYYY-MM-DD');
                        console.log(expireDate, startDate, endDate);
                        if(expireDate >= startDate && expireDate <= endDate){
                          return {...task, modalVisibility: true}
                        } else {
                          return {...task, modalVisibility: false}
                        }
                      } else {
                        return {...task, modalVisibility: true}
                      }
                    });
                    //console.log(tasks);
                    break;
                }
              }
              break;
          }
          this.setState({requiredSel});
          break;
      }


      /*switch(name){
        case 'filterAllNone':
          this.props.updateArrayTaskFilters(this.state.type, this.state.filterAllNone);
          break;
      }*/
    });
  };

  keyPress = (event, type) => {
    if(event.keyCode === 13){
      this.searchTask(type);
    }
  };

  searchTask = (type) => {
    console.log(this.state.q, type);
    this.props.searchModal(this.state.q, type);
    /*if(this.state.q === ''){
      this.setState({filterAllNone: false});
    }*/
    /*switch(type){
      case 'segments':
        let segments = null;
        if(this.state.q !== ''){
          const q = new RegExp(this.state.q, 'i');
          segments = this.state.segments.map(segment =>  ({...segment, isVisible: segment.name.match(q) !== null}));
        } else {
          segments = this.state.segments.map(segment => ({...segment, isVisible: true}));
        }
        this.setState({segments});
        break;
      case 'tags':
        let tags = null;
        if(this.state.q !== ''){
          const q = new RegExp(this.state.q, 'i');
          tags = this.state.tags.map(tag =>  ({...tag, isVisible: tag.name.match(q)}));
        } else {
          tags = this.state.tags.map(tag => ({...tag, isVisible: true}));
        }
        this.setState({tags});
        break;
      case 'tasks':
        let tasks = null;
        if(this.state.q !== ''){
          const q = new RegExp(this.state.q, 'i');
          tasks = this.state.tasks.map(task =>  ({...task, isVisible: task.title.match(q)}));
        } else {
          tasks = this.state.tasks.map(task => ({...task, isVisible: true}));
        }
        this.setState({tasks});
        break;
    }*/
  };

  setInfoMessage(type) {
    let messageContent = null;
    let language = this.props.language
    const i18n = {
      en: {
        no: 'No',
        set1: ' have been set yet.',
        set2: 'To set',
        set3: 'in this community go to Community Settings > Segments & Tags.',
        clickToSet: 'Click here to set',
        required1: 'There\'s' ,
        required2: 'only one Activity',
        required3: 'in your community.',
        required4: 'Once you create more Activities you\'ll see them listed here.'
      },
      es: {
        no: 'Todavía no hay',
        set1: ' definidos.',
        set2: 'Para definir',
        set3: 'en esta comunidad ve a Ajustes de la Comunidad > Segmentos & Tags.',
        clickToSet: 'Clic aquí para definir',
        required1: 'Hay',
        required2: 'solo una Actividad',
        required3: 'en tu comunidad.',
        required4: 'Al crear más Actividades las verás listadas aquí.'
      }
    };
    if (type === "tasks") {
      messageContent = <div><p>{i18n[language].required1} <span className='highlight'>{i18n[language].required2} </span>{i18n[language].required3}</p>
        <p>{i18n[language].required4}</p></div>;
    } else {
      messageContent = <div><p><span className='highlight'>{i18n[language].no} {type}</span>{i18n[language].set1}</p>
        <p>{i18n[language].set2} {type} {i18n[language].set3}</p>
        <a href={'/communities/settings/' + this.props.task.community_id + '/SegsAndTags'}>{i18n[language].clickToSet} {type}</a></div>;
    }
    return (
      <div className="px-info-regular text-center" style={{ marginTop: '30px' }}>
        {messageContent}
      </div>
    )
  }
}
