import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class R480LeftMenu extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    leftMenuActive: PropTypes.string,
    updateActiveLink: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {communityId} = this.props;

    return (
      <div className="px-responsive-navbar">
        <ul className="px-main-list">
          <li className="px-li">
            <a className="home px-icon-cont-resp" href="/users/dashboard">
              <FontAwesomeIcon icon={['fas','house']} style={{fontSize:'24px'}} className="home"/>
            </a>
          </li>
          <li className="px-li dropup">
            <a href="#" className="dropdown-toggle px-icon-cont-resp" data-toggle="dropdown" role="button" aria-expanded="false">
              <i className="material-icons">dashboard</i>
              <div className="counter-activities"><span>3</span></div>
            </a>
            <ul className="dropdown-menu px-dropdown-navigate">
              <li className="px-header">
                <a className="px-head">More Options</a>
              </li>
              <li className="">
                <a onClick={() => this.props.updateActiveLink('Activities')} className="">Activities</a>
              </li>
              <li className="active">
                <a onClick={() => this.props.updateActiveLink('Participants')} className="">Participants</a>
              </li>
              <li className="">
                <a className="">Panorama</a>
              </li>
              <li className="">
                <a className="">Now</a>
              </li>
            </ul>
          </li>
          <div className="toggle-menus">
            <li className="px-li">
              <a href={'/communities/settings/' + communityId} className="px-icon-cont-resp">
                <i className="material-icons">settings</i>
              </a>
            </li>
            <li className="px-li">
              <a href="" className="px-icon-cont-resp">
                <i className="material-icons">people</i>
              </a>
            </li>
          </div>
          
        </ul>
      </div>
    );
  }
}
