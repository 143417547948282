import PropTypes from 'prop-types';
import React from 'react';
import Layout from "../../Layout";
import ActivityBuilder01 from "../../../containers/Community/ActivityBuilder01";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";

export default class ActivitiesPage extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    sessionLang: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    active_users: PropTypes.array.isRequired,
    rooms: PropTypes.array.isRequired,
    communities: PropTypes.array.isRequired,
    //expiration: PropTypes.number.isRequired,
    data_signature: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeUsers: [],
      communitiesTop: [],
      notifications: [],
      rooms: [],
      openPMFromOutside: {username: '', userId: null, flag: false},
      notificationsCounter: 0,
      isBeaconInit: false //Keeps track of any instances of Head Scout Beacons initialised.
    }
  }

  componentDidMount(){
    this._getActiveUsers();
    this._getCommunitiesTop();
    this._getNotificationsCounter();
    //this._getNotifications();
    //this._getRooms();
  }

  render() {
    const {community, profile, sessionLang, expiration, data_signature} = this.props;
    
    const segments = community.segments.map(s => ({...s, modalVisibility: true}));
    if(community.gender_as_segment){
      segments.push({id: 'Female', name: 'Female', color: '#000'}, {id: 'Male', name: 'Male', color: '#000'},
        {id: 'Other Gender', name: 'Other Gender', color: '#000'});
    }

    const tags = community.tags.map(t => ({...t, modalVisibility: true}));
    const hsBeaconSuggestions = {
      en: ["5f22625204286306f8079cdd", "5f1fcb5504286306f8077b26", "5f22590d04286306f8079c9a", "5f2259a32c7d3a10cbab8bc2", "5f225a1e2c7d3a10cbab8bc3", "5f1ec5d204286306f8076c91", "5f225ae82c7d3a10cbab8bc9", "5f225bff2c7d3a10cbab8bcf", "5f225cc104286306f8079ca9", "5f225d392c7d3a10cbab8bda"] 
    };

    return (
      <Layout user_account={this.props.user_account}
              profile={this.props.profile}
              sessionLang={this.props.sessionLang}
              notifications={this.state.notifications}
              from='CommunityPage'
              community={this.props.community}
              leftMenuActive={'Activities'}
              active_users={this.state.activeUsers}
              rooms={this.state.rooms}
              communities={this.state.communitiesTop}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              notificationsCounter={this.state.notificationsCounter}
              colors={this.props.user_account.all_colors}>

        {/*<HelpScoutBeacon beaconId='d955453c-66a3-4a37-a547-f1d18fa4cc82'*/}
        {/*                 user_account={this.props.user_account}*/}
        {/*                 colors={this.props.user_account.all_colors}*/}
        {/*                 language={profile.language ? profile.language : sessionLang}*/}
        {/*                 isBeaconInit={this.state.isBeaconInit}*/}
        {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
        {/*                 suggestions={hsBeaconSuggestions}*/}
        {/*                 hideFABOnDesktop={!this.props.user_account.profile.is_help}/>*/}

        <ActivityBuilder01 communityId={community.id}
                           communityState={community.state}
                           communitySocial={community.social_flag}
                           communityMaps={community.maps.maps_a.map(map => map)}
                           communitySegs={segments}
                           communityTags={tags}
                           communityPartStart={community.participants_start}
                           communityPartEnd={community.participants_end}
                           gender_as_segment={community.gender_as_segment}
                           sort_coll={community.sort_coll}
                           sort_part={community.sort_part}
                           language={profile.language ? profile.language : sessionLang}
                           active_users={this.state.activeUsers}
                           //expiration={expiration}
                           data_signature={data_signature}
                           user_account={this.props.user_account}
                           isBeaconInit={this.state.isBeaconInit}
                           updateIsBeaconInit={this.updateIsBeaconInit}
                           communityLang={community.language}
                           communityProjectType={community.project_type}
                           cmntyAutoModeration={community.ai_auto_moderation}
                           cmntyTaskTypeLevel={community.task_type_level}
                           cmntyMaxMicroChats={community.max_micro_chat_tasks}
                           cmntyAiAutoModeration={community.ai_auto_moderation}
                           cmntyAllowImgs={community.ai_allow_pictures}
                           cmntyAiPlan={community.ai_plan}
                           hasLiveActivities={community.has_live_activities}
                           hasTasks={community.has_tasks}
        />

      </Layout>
    );
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log(communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };

  _getRooms = () => {
    $.ajax({
      url: '/communities/get_rooms/' + this.props.community.id,
      method: 'GET',
      success: rooms =>  {
        //console.log(rooms);
        this.setState({rooms});
      }
    });
  };

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  _getNotifications = () => {
    $.ajax({
      url: '/notifications/get_notifications/-1',
      method: 'GET',
      success: notifications =>  {
        this.setState({notifications});
      }
    });
  };

  _getActiveUsers = () => {
    $.ajax({
      url: '/communities/coll_get_active_parts/' + this.props.community.id + '/activityBuilder',
      method: 'GET',
      success: activeUsers =>  {
        this.setState({activeUsers});
      }
    });
  };

  updateOpenPMFromOutside = (type, username, userId) => {
    // console.log(type, username, userId);
    this.setState(state => ({openPMFromOutside: {username, userId, type, flag: !state.openPMFromOutside.flag}}));
  };

  updateOpenPMFromOutside_ORIG = (username, userId) => {
    // console.log(username, userId);
    this.setState(state => ({openPMFromOutside: {username: username, userId: userId, flag: !state.openPMFromOutside.flag}}));
  };
}