import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";
import {Editor} from "react-draft-wysiwyg";

import UIGModal00 from "./UIGuidelines/UIGModal00";
import UIGModal01 from "./UIGuidelines/UIGModal01";
import UIGModal02 from "./UIGuidelines/UIGModal02";
import UIGModal03 from "./UIGuidelines/UIGModal03";
import UIGModal04 from "./UIGuidelines/UIGModal04";
import UIGModal05 from "./UIGuidelines/UIGModal05";
import R480LeftMenu from "../Responsive/480px/R480LeftMenu";

export default class UIGuidelines extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      sm00: false,
      sm01: false,
      sm02: false,
      sm03: false,
      sm04: false,
      sm05: false,
      sm06: false
    }
  }

  componentWillMount(){
    ReactModal.setAppElement('body');
  }

  render() {
    return (
      <div>
        <button className="btn" onClick={() => this.updateShowModal('showModal')}>Open modal 1</button>
        <button className="btn" onClick={() => this.updateShowModal('sm00')}>Open modal 2</button>
        <button className="btn" onClick={() => this.updateShowModal('sm01')}>Open modal 3</button>
        <button className="btn" onClick={() => this.updateShowModal('sm02')}>Open modal 4</button>
        <button className="btn" onClick={() => this.updateShowModal('sm03')}>Open modal 5</button>
        <button className="btn" onClick={() => this.updateShowModal('sm04')}>Open modal 6</button>

        <UIGModal00 showModal={this.state.showModal} updateShowModal={this.updateShowModal}/>
        <UIGModal01 showModal={this.state.sm00} updateShowModal={this.updateShowModal}/>
        <UIGModal02 showModal={this.state.sm01} updateShowModal={this.updateShowModal}/>
        <UIGModal03 showModal={this.state.sm02} updateShowModal={this.updateShowModal}/>
        <UIGModal04 showModal={this.state.sm03} updateShowModal={this.updateShowModal}/>
        <UIGModal05 showModal={this.state.sm04} updateShowModal={this.updateShowModal}/>

        <R480LeftMenu communityId={-1} updateActiveLink={() => {}}/>

        <div className="px-oe-editor">
          <Editor
                  toolbar={{
                    options: ['inline', 'emoji'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                      bold: { className: 'bordered-option-classname' },
                      italic: { className: 'bordered-option-classname' },
                      underline: { className: 'bordered-option-classname' },
                    }
                  }}
                  placeholder="test de editro"
                  editorClassName="px-editor-tv-oe"/>
        </div>
        <div className="row mg-top-bottom-25">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1>Elements for choices answers (Activity builder, response participant, trascript view)</h1>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-answer-actv-builder">
                            <FontAwesomeIcon icon={['far','bars']} className="px-drag"/>
                            <div className="px-btn-media-container">
                                <button className="px-btn-media">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image"
                                         className="svg-inline--fa fa-image fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56zM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48z"></path>
                                    </svg>
                                </button>
                            </div>
                            <input type="text" className="input-answer" placeholder="Type answer here ..."/>
                            <FontAwesomeIcon icon={['fas','circle-xmark']} className="remove-icon"/>
                        </div>
                    </div>
                </div>

                <h1/>
                
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-options-container">
                            <div className="px-option-item">
                                <img src="/assets/placeholder.png" alt="" className="px-img-item" />
                                <div className="px-check-cont">
                                    <label className="container-check choice radio">
                                        <input type="checkbox"/>
                                        <span className="checkmark radio choice"/>
                                        <span className="choice">lasdknalsf asdl asfl asflk asfl fadkñ adfñkasj
                                          vdñlasdfj ñasldfj asñdflj asdfñj asdfñas dfñlasdfj asñdlfj asdfñj asdfñlj
                                          asdfljas df</span>
                                    </label>
                                </div>
                            </div>
                            <div className="px-option-item active">
                                <img src="/assets/placeholder.png" alt="" className="px-img-item" />
                                <div className="px-check-cont">
                                    <label className="container-check choice ">
                                        <input type="checkbox" />
                                        <span className="checkmark  choice"/>
                                        <span className="choice">lasdknalsf asdl asfl asflk asfl fadkñ adfñkasj vdñlasdfj ñasldfj asñdflj asdfñj asdfñas dfñlasdfj asñdlfj asdfñj asdfñlj asdfljas df</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
        
        <div className="row mg-top-bottom-25">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="px-choice-image-cont ">
                    <div className="choice-item">
                        <div className="img-container">
                            <img src="http://lorempixel.com/400/200/" alt="" className="" />
                            <div className="check-container">
                                <div className="px-check-cont choice-img">
                                    <label className="container-check radio choice-img">
                                        <input type="radio" />
                                        <span className="checkmark radio choice-img"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.  </div>

                    </div>
                    <div className="choice-item">
                        <div className="img-container">
                            <img src="http://lorempixel.com/400/200/" alt="" className="" />
                            <div className="check-container">
                                <div className="px-check-cont choice-img">
                                    <label className="container-check radio choice-img">
                                        <input type="radio" />
                                        <span className="checkmark radio choice-img"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.  </div>

                    </div>
                    <div className="choice-item">
                        <div className="img-container">
                            <img src="http://lorempixel.com/400/200/" alt="" className="" />
                            <div className="check-container">
                                <div className="px-check-cont choice-img">
                                    <label className="container-check radio choice-img">
                                        <input type="radio" />
                                        <span className="checkmark radio choice-img"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.  </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }

  updateShowModal = (name) => {
    this.setState(state => ({[name] : !state[name]}))
  }
}
