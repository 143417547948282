import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ASTask from "../../../components/Community/Activities/ActivitySocial/ASTask";
import { Tooltip as ReactTooltip } from "react-tooltip"
import axios from "axios";
import _ from "underscore";
import v4 from "uuid";

import ASActivityInfo from "../../../components/Community/Activities/ActivitySocial/ASActivityInfo";
import T_Filters from "../../../components/Community/Transcript/T_Filters";
import TB_PC_Big from "../../../components/Community/Transcript/T_Body/ProfileCards/TB_PC_Big";
import T_Response from "../../../components/Community/Transcript/T_Response";
import {getCSRF, showSuccessMsg} from "../../../components/UI/Globals/PX_Funs";

export default class PSActivity extends React.Component {
  static propTypes = {
    active_users: PropTypes.array.isRequired,
    tasks_filter: PropTypes.array.isRequired,
    task_id: PropTypes.number.isRequired,
    user_id: PropTypes.number.isRequired,
    entity: PropTypes.object.isRequired,
    scroll_id: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    cmntyLanguage: PropTypes.string.isRequired,
    task: PropTypes.object.isRequired,
    currentUserId: PropTypes.number.isRequired,
    role_1: PropTypes.string.isRequired,
    communityId: PropTypes.number.isRequired,
    colors: PropTypes.object.isRequired,
    item_scroll: PropTypes.object
  };

  constructor(props) {
    super(props);

    const users = props.active_users.map(user => {
      const tasks = user.tasks.map(task => {
        const questions = task.questions.map(question => (
          {...question, uuid: v4(), isTaskMinimized: false, showComments: false, active: true})
        );

        return {...task, uuid: v4(), active: props.task_id === task.id, questions}
      });

      return {...user, tasks, active: false}
    });

    const initUsers = users.map(user => user.user_id === props.user_id ? {...user, active: true}
    : {...user, active: false});

    const initSelectedActivities = props.tasks_filter.map(task => ({...task, active: props.task_id === task.id}));

    this.state = {
      objSearch: {idPars: props.user_id},
      users: initUsers,
      selectedActivities: initSelectedActivities,
      response: props.task.response,
      isTaskMinimized: false,
      showComments: false,
      scrollFirstTime: true,
      isSavingLikeResponse: false
    };
  }

  componentDidMount() {
    //console.log('PSActivity.componentDidMount');
    const users = this.props.active_users.map(user => {
      const tasks = user.tasks.map(task => {
        const questions = task.questions.map(question => (
          {...question, uuid: v4(), isTaskMinimized: false, showComments: false, active: true})
        );

        return {...task, uuid: v4(), active: this.props.task_id === task.id, questions}
      });

      return {...user, tasks, active: false}
    });

    const initUsers = users.map(user => user.user_id === this.props.user_id ? {...user, active: true}
      : {...user, active: false});

    const initSelectedActivities = this.props.tasks_filter.map(task => ({...task, active: this.props.task_id === task.id}));

    this.setState({users: initUsers, selectedActivities: initSelectedActivities}, () => {
      this.getResponsesSelectedUsers(this.state.objSearch);
    });
  }

  render() {
    const { currentUserId, language, cmntyLanguage, role_1, item_scroll, colors} = this.props;

    let generalActInfo = null;

    const userResponses = this.state.users.map((user, index) => {
      if(user.user_id === this.state.objSearch.idPars){
        let userCard = <TB_PC_Big user={user}
                                  showModalExportHandler={() => {}}
                                  from='Participant'/>;

        const mainActivities = user.tasks.map((activity, index) => {
          if(activity.active){
            const tasks = activity.questions.map((task, index) => {
              const response = _.find(user.responses, response => {
                if(response.response) {
                  //console.log(task);
                  if(task.id === response.response.question_id){
                    return response;
                  }
                }
              });

              return (
                <div key={index}>
                  <T_Response cardinal={index + 1}
                              taskTitle={task.title}
                              taskId={task.id}
                              task={task}
                              username={user.username}
                              userId={user.user_id}
                              language={language}
                              cmntyLanguage={cmntyLanguage}
                              isFirstFFComment={{}}
                              updateIsFirstFFComment={() => {}}
                              hideTabFilters={false}
                              showAllVerbatim={false}
                              handleVerbatimVisibility={() => {}}
                              role_1={role_1}
                              response={response}
                              updateShowComments={this.updateShowComments}
                              showComments={task.showComments}
                              updateIsTaskMinimized={this.updateIsTaskMinimized}
                              updateResponseComments={this.updateResponseComments}
                              from='PSA'
                              scrollFirstTime={this.state.scrollFirstTime}
                              updateScrollFirstTime={this.updateScrollFirstTime}
                              scroll_id={this.props.scroll_id}
                              entity={this.props.entity}
                              updateShowCommentsScroll={this.updateShowCommentsScroll}
                              saveResponseLike={this.saveResponseLike}
                              isTaskMinimized={task.isTaskMinimized}
                              item_scroll={item_scroll}
                              isSavingLikeResponse={this.state.isSavingLikeResponse}
                              colors={colors}/>
                </div>
              )
            });

            generalActInfo = <ASActivityInfo title={activity.title}
                                             instructions={activity.instructions}/>;

            return(
              <div key={index}>
                { generalActInfo }
                { tasks }
              </div>
            )
          }
        });

        return(
          <div key={index}>
            { userCard }
            { mainActivities }
          </div>
        )
      }
    });

    //console.log(this.state.users);
    return (
      <div>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        {
          this.state.users.length !== 0 &&
          <T_Filters users={this.state.users}
                     tasks_filter={this.state.selectedActivities}
                     showFilters={false}
                     showFiltersHandler={() => {}}
                     handleFilterUsers={() => {}}
                     selectAllUsers={false}
                     handleAllUsers={() => {}}
                     setNewActiveUser={this.setNewActiveUser}
                     toggleActivity={this.toggleActivity}
                     toggleTask={() => {}}
                     handleHideTabFilters={() => {}}
                     from='Participant'
                     currentUserId={currentUserId}
                     objSearch={this.state.objSearch}
                     colors={colors}/>
        }

        <div className="container-fluid px-transcript-container mg-top-50">
          { userResponses }
        </div>
      </div>
    );
  }

  // TO-DO Some functions as saveResponseLike are shared by Transcript.jsx, it would be good to have another *.jsx
  // just to have the common functions for both of them
  saveResponseLike = (entityId, question_id, isLike, userId) => {
    this.setState({isSavingLikeResponse: true}, () => {
      const url = '/is_like/'+ entityId +'/Response/' + this.props.communityId + '/' + this.props.task_id;
      const data = {entity: {isLike: isLike}};

      axios.patch(url, data, getCSRF())
          .then((r) => {
            console.log(r.data);

            const users = this.state.users.map(user => {
              if(user => user.user_id === userId){
                const responses = user.responses.map(response => {
                  if(response.id === r.data.id){
                    response.isLike = isLike;
                    response.likes = isLike ? ++response.likes : --response.likes;

                    return {...response};
                  } else {
                    return {...response}
                  }
                });

                return {...user, responses}
              } else {
                return user;
              }
            });
            console.log(users);
            this.setState({isSavingLikeResponse: false, users});
          }).catch(error => {
        console.log(error);
      });


      /*$.ajax({
        url: '/is_like/'+ entityId +'/Response/' + this.props.communityId + '/' + this.props.task_id,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          entity: {
            isLike: isLike
          }
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: responseDB => {
          console.log(responseDB);
          const users = this.state.users.map(user => {
            if(user => user.user_id === userId){
              const responses = user.responses.map(response => {
                if(response.id === responseDB.id){
                  response.isLike = isLike;
                  response.likes = isLike ? ++response.likes : --response.likes;

                  return {...response};
                } else {
                  return {...response}
                }
              });

              return {...user, responses}
            } else {
              return user;
            }
          });
          console.log(users);
          this.setState({isSavingLikeResponse: false, users});
        }
      });*/
    });
  };

  saveResponseLike_ORIG = (entityId, question_id, isLike, userId) => {
    this.setState({isSavingLikeResponse: true}, () => {
      $.ajax({
        url: '/is_like/'+ entityId +'/Response/' + question_id,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          entity: {
            isLike: isLike
          }
        },
        success: responseDB => {
          console.log(responseDB);
          const users = this.state.users.map(user => {
            if(user => user.user_id === userId){
              const responses = user.responses.map(response => {
                if(response.id === responseDB.id){
                  response.isLike = isLike;
                  response.likes = isLike ? ++response.likes : --response.likes;

                  return {...response};
                } else {
                  return {...response}
                }
              });

              return {...user, responses}
            } else {
              return user;
            }
          });
          console.log(users);
          this.setState({isSavingLikeResponse: false, users});
        }
      });
    });
  };

  updateScrollFirstTime = (scrollFirstTime) => {
    this.setState({scrollFirstTime});
  };

  updateResponseComments = (comments, responseId, questionId) => {
    console.log(comments, responseId, questionId);

    const users = this.state.users.map(user => {
      const responses = user.responses.map(response => {
        if(response.id === responseId){
          response.comments = comments;
          return {...response}
        } else {
          return {...response}
        }
      });
      return {...user, responses}
    });
    console.log(users);
    this.setState({users});

  };

  updateIsTaskMinimized = (user_id, question_id) => {
    console.log(user_id, question_id);
    const users = this._updateUsersTaskToggles(user_id, question_id, 'toggleTaskView');

    this.setState({users});
  };

  updateShowComments = (user_id, question_id) => {
    console.log(user_id, question_id);
    const users = this._updateUsersTaskToggles(user_id, question_id, 'toggleComments');

    this.setState({users});
  };

  updateShowCommentsScroll = (user_id, question_id) => {
    const users = this._updateUsersTaskTogglesScroll(user_id, question_id, 'toggleComments');

    this.setState({users});
  };

  _updateUsersTaskTogglesScroll(user_id, question_id, action){
    return this.state.users.map(user => {
      if(user.user_id === user_id){
        const tasks = user.tasks.map(task => {
          const questions = task.questions.map(question => {
            if(question_id === question.id){
              return action === 'toggleComments' ? {...question, showComments: true} :
                {...question, isTaskMinimized: !question.isTaskMinimized}
            } else {
              return {...question}
            }
          });
          return {...task, questions: questions};
        });
        return {...user, tasks: tasks};
      } else {
        return {...user}
      }
    });
  }

  _updateUsersTaskToggles(user_id, question_id, action){
    return this.state.users.map(user => {
      if(user.user_id === user_id){
        const tasks = user.tasks.map(task => {
          const questions = task.questions.map(question => {
            if(question_id === question.id){
              return action === 'toggleComments' ? {...question, showComments: !question.showComments} :
                {...question, isTaskMinimized: !question.isTaskMinimized}
            } else {
              return {...question}
            }
          });
          return {...task, questions: questions};
        });
        return {...user, tasks: tasks};
      } else {
        return {...user}
      }
    });
  }

  toggleActivity = (taskId) => {
    // TO-DO Maybe I can improve it, because we have 2 arrays for almost the same data:
    // one array for the activities filter, and other for the activities body
    const selectedActivities = this.state.selectedActivities.map(task => task.id === taskId ?
      {...task, active: !task.active} : {...task, active: false});

    const users = this.state.users.map(user => {
      const tasks = user.tasks.map(task => task.id === taskId ?
        {...task, active: !task.active} : {...task, active: false});

      return {...user, tasks};
    });

    this.setState({selectedActivities, users});
  };

  setNewActiveUser = direction => {
    const {users, objSearch} = this.state;

    const currentIndexOf = users.indexOf(users.find(user => user.user_id === objSearch.idPars));
    let nextUser = null;
    if(direction === 'left'){
      nextUser = currentIndexOf !== 0 ? users[currentIndexOf - 1] : users[users.length - 1];
    } else {
      nextUser = currentIndexOf < users.length - 1 ? users[currentIndexOf + 1] : users[0];
    }

    this.setState({objSearch: {...objSearch, idPars: nextUser.user_id}}, () => {
      this.getResponsesSelectedUsers(this.state.objSearch);
    });


    /*const currentIndexOf = users.indexOf(users.find(user => user.active));

    let activeUser = {};
    if(direction === 'left'){
      activeUser = currentIndexOf !== 0 ? users[currentIndexOf - 1] : users[users.length - 1];
    } else {
      activeUser = currentIndexOf < users.length - 1 ? users[currentIndexOf + 1] : users[0];
    }

    const selectedUsers = users.map(user => user.user_id === activeUser.user_id
      ? {...user, active: true} : {...user, active: false});

    this.setState({users: selectedUsers}, () => {
      this.getResponsesSelectedUsers(this.state.users);
    });*/
  };

  getResponsesSelectedUsers = objSearch => {
    console.log(objSearch, this.state.users);
    this.state.users.forEach(user => {
      if(user.user_id === objSearch.idPars){
        const url = '/responses/get_participants_responses/' + user.user_id;
        let allQuestionsId = [];
        user.tasks.map(task => task.questions.map(question => allQuestionsId.push(question.id)));

        axios.get(url, { params: {questionsId: allQuestionsId} }, getCSRF())
          .then(response => {
            console.log(response.data);
            const userUpdated = {...user, responses: this._setUserResponses(user, response.data)};
            const users = this.state.users.map(user => user.user_id === userUpdated.user_id ? userUpdated : {...user});
            console.log(users);
            this.setState({users});
          });
      } else {
        return {...user}
      }
    });
  };

  // Every time a user is selected this function adds some fields for visual features
  // TO-DO when you select/unselect I need to save this fields values
  _setUserResponses(user, responsesDB){
    return responsesDB.map(responseDB => {
      return {...responseDB, showComments: false, showHiddenVerbatims: false, showOriginal: false}
    });
  };
}
