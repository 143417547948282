import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

const RoomCard = ({ title, sources, language, onClickChatCard, ai_chat_room_id, activeChatRoomId,
                    colors, toggleEditChatRoom, id, toggleDelMod }) => {

  const setSourcesLabel = (obj) => {
    const { activities_ids, participants_ids, segments_ids, documents_ids } = obj;

    if(activities_ids.length > 0 || participants_ids.length > 0 || segments_ids.length > 0){
      if(documents_ids.length > 0){
        return language === "es" ? "Comunidad y externo" : "Community & external";
      } else {
        return language === "es" ? "Comunidad" : "Community";
      }
    } else if (documents_ids.length > 0) {
      return language === "es" ? "Externo" : "External";
    } else {
      return language === "es" ? "Ninguna" : "None";
    }
  };

  const sourcesLabel = setSourcesLabel(sources);

  return (
    <div className="card-holder" onClick={() => onClickChatCard(ai_chat_room_id)}>
      <div className={"px-activity-card "}
           style={{borderLeftColor: activeChatRoomId === ai_chat_room_id ? colors.color2[3] : '#dadde1',
             backgroundColor: '#f8f8f8', width: '175px'}}>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-actv-title" data-tooltip-content={title}>{title}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-actv-expire">{sourcesLabel}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-actv-stats-row" style={{marginTop:'10px'}}>
              <div className="px-item-info" onClick={() => toggleEditChatRoom(id, 'update')}>
                <FontAwesomeIcon icon={['fas', 'pencil']} className="px-actv-icon"/>
              </div>
              <div className="px-item-info" onClick={toggleDelMod}>
                <FontAwesomeIcon icon={['fas', 'trash']} className="px-actv-icon"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoomCard