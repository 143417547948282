import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Slider from "react-slick/lib";
import Dotdotdot from "react-dotdotdot";
import Maps from "../Maps";

export default class CSMaps extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    maps: PropTypes.array.isRequired,
    alert: PropTypes.bool.isRequired,
    mapSelected: PropTypes.object,
    updateMapSelected: PropTypes.func.isRequired,
    mapLanguage: PropTypes.string.isRequired,
    updateMapLanguage: PropTypes.func.isRequired,
    updateMap: PropTypes.func.isRequired,
    updateMapTitle: PropTypes.func.isRequired,
    addMapItem: PropTypes.func.isRequired,
    deleteMapItem: PropTypes.func.isRequired,
    saveMap: PropTypes.func.isRequired,
    restoreMap: PropTypes.func.isRequired,
    updateRandomMapItems: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {community, maps, alert, mapSelected, mapLanguage, language, colors} = this.props;

    const I18n = {
      en: {
        Languages:'Languages', english:'English', spanish: 'Spanish', save: 'Save',
        restore: 'Restore defaults', new: 'New',
        randomizeOrder: 'Randomize order', orderWarning: 'If not randomised, participants will see the items in the same order above.',
        editWarning: 'Some participants may have already responded to related activities. When modifying these items, existing responses will take these recently updated values.'
      },
      es: {
        Languages: 'Lenguajes', english: 'Inglés', spanish: 'Español', save: 'Guardar',
        restore: 'Restaurar valores', new: 'Nuevo',
        randomizeOrder: 'Orden aleatorio', orderWarning: 'Si el orden no es aleatorio, los participantes verán las palabras en el mismo order que arriba.',
        editWarning: 'Algunos participantes podrían haber contestado actividades relacionadas. Si edita estas palabras, las respuestas existentes tomarán estos valores recien editados.'}
    };

    const sliderMapItemsSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    let items = [];
    let mapTitle = '';

    const mapButtons = maps.map((map, index) => {
      if(map.id === mapSelected.id){
        mapTitle = map.title[mapLanguage];

        items = map.items.map((item, index) => {
          if(item.state === 'Enabled'){
            return(
              <div key={index} className="map-item form-group map">
                <FontAwesomeIcon icon={['fas','triangle']} className="icon-map" style={{color:item.color}}/>
                <input name="name" type="text" onChange={(event) => this.props.updateMap(event, item.id)}
                       className="form-control px-input-settings" value={item[mapLanguage]} placeholder={item['en']}/>
                <FontAwesomeIcon onClick={() => this.props.deleteMapItem(item.id)} icon={['fas','circle-xmark']}
                                 className="icon-remove" style={{cursor:'pointer'}}/>
              </div>
            )
          }
        })
      }

      return(
        <div key={index} onClick={() => this.props.updateMapSelected(map)}
             className={map.id === mapSelected.id ? 'active item' : 'item'} style={{cursor: 'pointer'}}>
          <span className="text">{map.title[mapLanguage]}</span>
        </div>
      )
    });

    const selectMapOptions = maps.map((map, index) => {
      return(
        <option key={index} value={map.id}>{map.title[mapLanguage]}</option>
      )
    });

    const sliderMapItems = <Slider {...sliderMapItemsSettings} className="px-navigation-maps">
      { mapButtons }
    </Slider>;

    return (
      <div id="divMaps" className="manage" style={{display:'none'}}>
        <div className="px-settings-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-card px-card-settings">
                <div className="px-icon-settings">
                  <FontAwesomeIcon icon={['fas','triangle']}/>
                </div>
                <div className="panel-body px-body-padding-tags-maps">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      { sliderMapItems }
                      <div className="px-select px-select-map-resp">
                        <select onChange={this.handleInputChange}>
                          { selectMapOptions }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group" style={{margin:'0'}}>
                            <label className="label-sm" style={{color: colors.color1[2]}}>Wordbook</label>
                            <input type="text" onChange={this.props.updateMapTitle} value={mapTitle}
                                   className="form-control px-input-settings"/>
                          </div>
                        </div>
                        {/*<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="label-sm">{I18n[language].languages}</label>
                            <select name="mapLanguage" value={mapLanguage} onChange={this.props.updateMapLanguage}
                                    className="form-control px-input-settings">
                              <option value="en">{I18n[language].english}</option>
                              <option value="es">{I18n[language].spanish}</option>
                            </select>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                  <div className="px-master-maps-container">
                    <div className="maps-container">
                      { items }
                    </div>
                  </div>
                  {
                    alert &&
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="px-alert-item mg-top-10 wd-100">
                          <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-alert"/>
                          <p className="px-label-warning text-left" style={{ color: colors.color4[3] }}>{I18n[language].editWarning}</p>
                        </div>
                      </div>
                    </div>
                  }
                  <button onClick={this.props.addMapItem} className="btn px-btn circle lg btn-purple-base"
                          style={{margin:'20px auto 0 auto', backgroundColor: colors.color0[3]}}>
                    <FontAwesomeIcon icon={['far', 'plus']} style={{fontSize:'18px'}} />
                  </button>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-row-buttons-action maps">
                      <button onClick={() => this.props.saveMap('map')} className="btn px-btn lg btn-blue-base" style={{backgroundColor: colors.color1[3]}}>
                        {I18n[language].save}</button>
                      <button onClick={this.props.restoreMap}
                              className="btn px-btn lg btn-gray-light">{I18n[language].restore}</button>
                      <button className="btn px-btn lg btn-gray-light" style={{ display: 'none' }}>{I18n[language].new} <br/> WORDBOOK</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-card px-card-settings">
                <div className="panel-body social-settings">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                      <div className="px-waring-item-container">
                        <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].randomizeOrder}</label>
                        <label className="px-switch" htmlFor="itemsOrderFlag">
                          <input name="randomize_map" onChange={this.props.updateRandomMapItems}
                                  checked={community.randomize_map} id="itemsOrderFlag" type="checkbox"/>
                          <div className="slider round" style={community.randomize_map ? {backgroundColor: colors.color3[3]} : {}}/>
                        </label>
                        <div className="px-alert-item mg-top-15">
                          <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-alert" style={{color: colors.color4[3]}}/>
                          <p className="px-label-warning text-left" style={{color: colors.color4[3]}}>{I18n[language].orderWarning}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleInputChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;
    //console.log(name, value);

    this.props.updateMapSelected(this.props.maps.find(m => m.id === parseInt(value)));
  }
}

function SampleNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', marginLeft:'15px', borderRadius:'50%'}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', marginRight:'15px', zIndex:'1' , borderRadius:'50%'}}
      onClick={onClick}
    />
  );
}
