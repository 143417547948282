import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import TimeAgo from "react-timeago";
import axios from 'axios';
import TextareaAutosize from "react-textarea-autosize";

import Comments from "../../../../containers/Community/Comments";
import {getCountryName, setIconGender, truncateString} from "../../../UI/Globals/PX_Funs";
import ReactModal from "react-modal";
import T_ModalVideo from "../T_ModalVideo";

export default class TRNoResponse extends React.Component {
  static propTypes = {
    cardinal: PropTypes.number.isRequired,
    task: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    role_1: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    communityState: PropTypes.string.isRequired,
    updateIsFirstFFComment: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    isFirstFFComment: PropTypes.object.isRequired,
    updateResponseComments: PropTypes.func.isRequired,
    taskMode: PropTypes.string.isRequired,
    activitySegments: PropTypes.array.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    formatter: PropTypes.func.isRequired,
    cmntyLanguage: PropTypes.string.isRequired,
    commentForceUpdateGrid: PropTypes.func.isRequired,
    qc: PropTypes.array.isRequired,
    activityStart: PropTypes.string,
    colors: PropTypes.object.isRequired,
    projectType: PropTypes.string,
    community: PropTypes.object,
    recalculateRowHeight: PropTypes.func,
    from: PropTypes.string,
    index: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      body: '',
      showCommentHeader: false,
      isSaving: false,
      isSendingFirstFF: false,
      showModalTaskVideo: false
    }
  }

  render() {
    const {cardinal, user, task, role_1, role_2, communityState, language, taskMode, activitySegments,
      gender_as_segment, formatter, activityStart, qc, cmntyLanguage, colors, projectType} = this.props;

    let segmentsFlag = true;
    if(activitySegments.length !== 0){
      segmentsFlag = user.segments.find(userSeg => activitySegments.find(as => as.id === userSeg.id)) !== undefined;

      if(gender_as_segment && !segmentsFlag){
        const activitySegs = activitySegments.map(s => s.id === 'Other Gender' ? {...s, id: 'Other'} : s);
        segmentsFlag = activitySegs.find(as => as.id === user.gender) !== undefined;
      }
    }

    if (!segmentsFlag) {
      return null;
    }

    const i18n = {
      en: {
        noAnswerYet: 'hasn\'t answered this Task yet', notAvailableYet: 'Activity will become available soon',
        noAccess: 'doesn\'t have access to this Task', comments: 'Comments', leaveComment: 'Leave a comment',
        instantReminder: 'Hurry participant sending a comment'
      },
      es: {
        noAnswerYet: 'aún no ha respondido esta Tarea', notAvailableYet: 'La actividad estará disponible pronto',
        noAccess: 'no tiene acceso a esta Tarea', comments: 'Comentarios', leaveComment: 'Deja un comentario',
        instantReminder: 'Apresura al participante con un comentario! '
      }
    };

    let cardHeader = <div className="px-counter-task" style={{ backgroundColor: colors.color0[1] }}>
      <span className="number" >{cardinal}</span></div>;
    let cardTitle = <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
        <p dangerouslySetInnerHTML={{__html: task.title}} className="title"/>
        {
          task.url !== '' &&
          <img src={task.url_thumbnail || task.url}
               onClick={() => this.setState({ lightboxIsOpen: true })}
               style={{height:'50px', width: '50px', borderRadius:'4px', margin: '0 5px 15px', cursor: 'pointer'}}
               alt=""/>
        }
        {
          task.video_thumbnail !== '' &&
          <img onClick={this.updateShowModalTaskVideo}
               src={task.video_thumbnail}
               style={{height:'50px', width: '50px', borderRadius:'4px', margin: '0 5px 15px', cursor: 'pointer'}}
               alt=""/>
        }
      </div>
    </div>;
    let showHeaderSeparator = true;

    if(taskMode === 'nUsers1Task'){
      const avatar = user.avatar !== '' ? user.avatar : '/assets/user.png';
      const regionCountry = user.region === '' || user.region === undefined ?
        getCountryName(user.country) : user.region + ', ' + getCountryName(user.country);

      cardHeader = null;
      cardTitle = <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="title-with-profile">
            <img src={avatar} className="img-profile" alt=""/>
            <div className="title-right-container">
              <div className="text-container">
                <p className="profile-name">{user.user_alias === '' ? user.username : user.user_alias}</p>
                <p className="email">{user.email}</p>
              </div>
              <div className="icon-container">
                <div className="px-tags-container">
                  {
                    role_1 === 'Collaborator' &&
                    <div className="tags-container">
                      {
                        user.segments.map(segment => {
                          return(
                            <FontAwesomeIcon key={segment.id} icon={['fas','bookmark']} className="tag"
                                             style={{color: segment.color}} data-tooltip-content={segment.name}/>
                          )
                        })
                      }
                    </div>
                  }
                </div>
                <div className="add-container">
                  {
                    user.gender !== '' &&
                    <div className="px-genders-container">
                      <div className="gender-container" data-tooltip-content={user.gender}>
                        {setIconGender(user.gender)}
                      </div>
                    </div>
                  }
                  {
                    user.country !== '' &&
                    <div className="px-country-container" data-tooltip-content={regionCountry}>
                      <span>{user.country}</span>
                    </div>
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>;
      showHeaderSeparator = true;
    }

    if(taskMode === 'nUsersNTasks' || taskMode === 'nUsersNActs'){
      const avatar = user.avatar !== '' ? user.avatar : '/assets/user.png';
      const regionCountry = user.region === '' || user.region === undefined ?
        getCountryName(user.country) : user.region + ', ' + getCountryName(user.country);

      cardHeader = <div className="px-task-image-container">
        <div className="px-tooltip-user-container" style={{ position: 'relative' }}>
          <img src={avatar} alt="image" className="image" />
          <div className="px-user-tooltip">
            <div className="title">{user.user_alias === '' ? user.username : user.user_alias}</div>
            <div className='info-container'>
              {
                user.gender !== '' &&
                <div className="px-genders-container">
                  <div className="gender-container" data-tooltip-content={user.gender}>{setIconGender(user.gender)}</div>
                </div>
              }
              {
                user.country !== '' &&
                <div className="px-country-container" data-tooltip-content={regionCountry}>
                  <span>{user.country}</span>
                </div>
              }
              <div className='px-tags-container'>
                {
                  user.segments.map(segment => {
                    return (
                      <FontAwesomeIcon key={segment.id} icon={['fas', 'bookmark']} className="tag"
                                       style={{ color: segment.color }} data-tooltip-content={segment.name} />
                    )
                  })
                }
              </div>
            </div>
            {
              role_2 === "Owner" || role_2 === "Moderator" || role_2 === "Recruiter" ?
                <div className="email">{user.email}</div> : ""
            }
          </div>
        </div>
        <div className="number" style={{ backgroundColor: colors.color0[1] }}><span>{cardinal}</span></div>
      </div>;
    }

    // TO-DO try to move to one global function
    const now = moment().format('YYYY-MM-DD');
    let available = true;

    if(activityStart){
      const startDate = moment(activityStart).format('YYYY-MM-DD');
      if(now < startDate){
        available = false;
      }
    }
    //console.log(now, activityStart, available)

    let ffFast = null;
    if(role_1 === 'Collaborator' && (role_2 === 'Owner' || role_2 === 'Moderator')){
      let quick = null;
      qc.forEach(q => {
        if(q.kind === 'Quick Please'){
          quick = q.items.map((c, index) => {
            //console.log('cmntyLanguage:', c[cmntyLanguage].search('{{username}}'));
            const msg = c[cmntyLanguage].search('{{username}}') === -1 ? c[cmntyLanguage] :
              c[cmntyLanguage].replace('{{username}}', user.username);
            return(
              <li key={index} data-tooltip-content={msg}>
                <a onClick={() => this.autoComment(msg)}>
                  {truncateString(msg, 30)}<div className="ripple-container"/>
                </a>
              </li>
            )
          });
        }
      });

      ffFast = <div className="action-container">
        <div className="btn-group" role="group" aria-label="...">
          <button onClick={communityState !== 'Closed' && !this.state.isSendingFistFF ? () => this.props.updateIsFirstFFComment(true, user.id, task.id) : null}
                  type="button" className="btn btn-icon btn-not-ch"
                  style={{ cursor: this.state.isSendingFistFF ? 'default' : 'pointer',
                    height: '31px',
                    color: colors.color1[3],
                    borderColor: colors.color1[3]}}
                  data-tooltip-content={i18n[language].instantReminder}>
            {
              !this.state.isSendingFistFF ?
                <FontAwesomeIcon icon={['far','forward-fast']} style={{color: colors.color1[3]}}/> :
                <FontAwesomeIcon icon={['fas','spinner']} spin/>
            }
          </button>
          {projectType !== 'Mixed' && <div className="dropdown" style={{display:'inline-block'}}>
            <button className="dropdown-toggle btn-icon btn-not-ch btn-caret"
                    style={{ listStyleType: 'none', marginLeft: '-1px', height: '31px', color: colors.color1[3],
                      borderColor: colors.color1[3]}} data-toggle="dropdown"
                    aria-expanded="false">
              <b className="caret"/>
            </button>
            <ul className="dropdown-menu dropdown-menu-right dropdown-general-options drop-quick-comments third">
              { quick }
            </ul>
          </div>}
        </div>
      </div>;
    }

    return (
      <React.Fragment>
        { cardHeader }
        <div id={-1} className="panel px-panel-task not-answered uncomplete">
          <div className="panel-body">
            { cardTitle }
            { showHeaderSeparator && <div className="separator" style={{marginTop:'15px',marginBottom:'15px'}}/> }
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <div className="profile-name text-center no-answer-container">
                  {
                    segmentsFlag ?
                      <React.Fragment>
                        {
                          available ?
                            <React.Fragment>
                              <div className="no-answer">
                                {user.user_alias === '' ? user.username : user.user_alias} {i18n[language].noAnswerYet}
                              </div>
                              {/*
                                (role_1 === 'Collaborator' && (role_2 === 'Owner' || role_2 === 'Moderator')) &&
                                <span className="fa-layers fa-fw" onClick={communityState !== 'Closed' ?
                                  () => this.props.updateIsFirstFFComment(true, user.id, task.id) : null}>
                                  <FontAwesomeIcon icon="message" className="icon" />
                                  <FontAwesomeIcon icon="forward" color="white" className="inner-icon" transform="shrink-6"/>
                                </span>
                              */}
                            </React.Fragment> :
                            <span className="no-answer">
                              {i18n[language].notAvailableYet} (<TimeAgo date={activityStart} formatter={formatter}/>)
                            </span>
                        }
                      </React.Fragment> :
                      <div className="no-answer">
                        {user.user_alias === '' ? user.username : user.user_alias} {i18n[language].noAccess}
                      </div>
                  }
                </div>
              </div>
            </div>

            <div className="interaction-container" style={{flexDirection: 'row', justifyContent: 'center', marginTop: '15px'}}>
              {
                (segmentsFlag && available) &&
                <button type="button" onClick={communityState !== 'Closed' ? this.updateShowCommentHeader : null}
                  className={this.state.showCommentHeader ? 'btn btn-comments active' : 'btn btn-comments inactive'} 
                  style={ this.state.showCommentHeader ?
                    { marginRight: '10px', color: '#ffffff', borderColor: colors.color1[3], backgroundColor: colors.color1[3] } :
                    { marginRight: '10px', color: colors.color1[3], borderColor: colors.color1[3], backgroundColor: '#ffffff' }
                }>
                  <div className="comments-container">
                    { 0 > 0 && <div className="unseen" /> }
                    <FontAwesomeIcon icon={['fas', 'message']} className="icon-comments"/>
                    <span className="txt-comment">{i18n[language].comments}</span>
                    <div className="number-container">
                      <span className="number">{0}</span>
                    </div>
                  </div>
                </button>
              }
              {(segmentsFlag && available) && ffFast}
            </div>

            {
              this.state.showCommentHeader &&
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="interaction-container">
                    <div className="px-comments-input">
                      <TextareaAutosize name="body" onChange={this.handleChange} value={this.state.body}
                                        onHeightChange={rowHeight => this.onHeightChange(rowHeight)} minRows={1}
                                        className="px-text-area-inner" placeholder={i18n[language].leaveComment}
                                        style={{fontSize:'14px', borderColor: colors.color1[3]}}/>
                      {
                        !this.state.isSaving ?
                          <FontAwesomeIcon icon={['far','paper-plane']} onClick={() => this.saveComment(null, 'user')}
                                           style={{cursor:'pointer', color: colors.color1[3]}}/> :
                          <FontAwesomeIcon icon={['fas','spinner']} spin/>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="row">
            {
              <Comments comments={[]}
                        language={language}
                        userId={user.id}
                        username={user.username}
                        questionId={task.id}
                        responseId={-1}
                        from={'CTV'}
                        role_1={role_1}
                        role_2={role_2}
                        updateResponseComments={this.props.updateResponseComments}
                        isFirstFFComment={this.props.isFirstFFComment}
                        qc={qc}
                        cmntyLanguage={this.props.cmntyLanguage}
                        sendNow={true}
                        colors={colors}
                        taskType={task.task_type}
                        tasks={task}
                        projectType={projectType}
                        user={user}
                        community={this.props.community}
                        setIsSendingFirstFF={this.setIsSendingFirstFF}
              />
            }
          </div>
        </div>
        {
          task.video_thumbnail &&
          <ReactModal isOpen={this.state.showModalTaskVideo} contentLabel="Task Video"
                      shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalTaskVideo}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <T_ModalVideo updateShowModalVideo={this.updateShowModalTaskVideo}
                          thumbnail={task.video_thumbnail}
                          url={task.video_url}
                          language={language}
                          colors={colors} />
          </ReactModal>
        }
      </React.Fragment>
    )
  }

  setIsSendingFirstFF = (state) => {
    this.setState({isSendingFistFF: state})
  }

  updateShowModalTaskVideo = () => {
    this.setState(state => ({ showModalTaskVideo: !state.showModalTaskVideo }));
  };

  saveComment = (kind, type_user) => {
    // console.log('kind:', kind)
    if(this.state.body !== '') {
      this.setState({isSaving: true}, () => {
        const config = {headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}};

        axios.post('/responses/create_empty/'+ this.props.user.id +'/'+ this.props.task.id +'/', {}, config)
          .then(r => {
            const objR = r.data;
            // console.log('objR:', objR);
            //const {isFirstFFComment} = this.props;
            //console.log('isFirstFFComment:', isFirstFFComment);

            $.ajax({
              url: '/comment/'+ this.props.user.id +'/'+ this.props.task.id +'/' + objR.response.id +'/' + type_user,
              method: 'POST',
              dataType: 'JSON',
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              data: {
                comment: {
                  body: this.state.body,
                  kind
                },
                from: this.props.from,  // We don't need it to send from to the server
                task_id: this.props.task.id //To get the active_participants and update the Dashboard - it's legacy
              },
              success: objC => {
                // console.log('objC:', objC);
                this.setState({isSaving: false}, () => {
                  this.props.updateResponseComments(objC.comments, objR.response, true);
                });
                this.onHeightChange()
              }
            });
          });
      });
    }
  };

  createEmptyResponse = () => {
    /*const config = {headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}};

    axios.post('/responses/create_empty/'+ this.props.user.id +'/'+ this.props.task.id +'/', {}, config)
      .then(r => {
        const obj = r.data;
        //console.log('obj:', obj);
        const {isFirstFFComment} = this.props;
        //console.log('isFirstFFComment:', isFirstFFComment);

        this.props.updateResponseComments(obj.comments, obj.response, true);
      });*/
  }

  autoComment = body => {
    this.setState({showCommentHeader: true, body});
    //this.props.updateIsFirstFFComment(true, this.props.user.id, this.props.task.id, body)
  };

  onHeightChange = (rowHeight = 38) => {
    // console.log('rowHeight:', rowHeight, 'this.props.from:', this.props.from );
    if(this.props.from !== 'PAV' && this.props.from !== 'PSA' && rowHeight > 38){
      this.props.recalculateRowHeight(this.props.index)
      //this.props.commentForceUpdateGrid();
    }
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});
  };

  updateShowCommentHeader = () => {
    this.setState(prevState => ({showCommentHeader: !prevState.showCommentHeader}));
  }
}
