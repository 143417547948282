import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class CSQuickComments extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    qcs: PropTypes.array.isRequired,
    qcLanguage: PropTypes.string.isRequired,
    updateMapLanguage: PropTypes.func.isRequired,
    updateQC: PropTypes.func.isRequired,
    updateQCSelected: PropTypes.func.isRequired,
    saveMap: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'quick'
    }
  }

  componentDidMount() {
    let btnColor = this.props.colors.color0[3];
    $(".nav-tabs > li.active > a").css({ "color": '#ffffff', 'background-color': btnColor, 'border-color': btnColor });
  }

  render() {
    const {qcs, qcLanguage, language, colors} = this.props;

    const I18n = {
      en: {quickPlease: 'Hurry', quick:'Hurry', replyLater: 'Will reply', reply:'Reply', thanks: 'Thanks',
           languages: 'Languages', english: 'English', spanish: 'Spanish', commentInfo: '', 
           dynamicValues: 'Dynamic values', participantUsername: 'Participant\'s username', save: 'Save'},
      es: {quickPlease: 'Apresúrate', quick: 'Apres...', replyLater: 'Responderé', reply: 'Respo...', thanks: 'Gracias',
           languages: 'Lenguajes', english: 'Inglés', spanish: 'Español', commentInfo: '',
           dynamicValues: 'Valores dinámicos', participantUsername: 'Nombre de usuario del participante', save: 'Guardar'}
    };

    const tabStyle = {
      active: { border: `1px solid ${colors.color0[3]}`, color: '#ffffff', backgroundColor: colors.color0[3] },
      inactive: { border: '1px solid #DADDE1', color: '#7C8593', backgroundColor: '#f0eff3' }
    };

    let quickPlease = null;
    qcs.forEach(qc => {
      if(qc.kind === 'Quick Please'){
        quickPlease = qc.items.map(item => {
          return(
            <div key={item.id} className="px-input-icon form-group" style={{margin:'0'}}>
              <input onChange={(event) => this.props.updateQC(event, item.id)} value={item[qcLanguage]} type="text"
                     className="form-control px-input-settings icon-right"/>
              {/*<FontAwesomeIcon icon={['fas','circle-xmark']} className="px-icon-right color-gray-base"/>*/}
            </div>
          )
        })
      }
    });

    let replyLater = null;
    qcs.forEach(qc => {
      if(qc.kind === 'Reply Later'){
        replyLater = qc.items.map(item => {
          return(
            <div key={item.id} className="px-input-icon form-group">
              <input onChange={(event) => this.props.updateQC(event, item.id)} value={item[qcLanguage]} type="text"
                     className="form-control px-input-settings icon-right"/>
              {/*<FontAwesomeIcon icon={['fas','circle-xmark']} className="px-icon-right color-gray-base"/>*/}
            </div>
          )
        })
      }
    });

    let thanks = null;
    qcs.forEach(qc => {
      if(qc.kind === 'Thanks'){
        thanks = qc.items.map(item => {
          return(
            <div key={item.id} className="px-input-icon form-group">
              <input onChange={(event) => this.props.updateQC(event, item.id)} value={item[qcLanguage]} type="text"
                     className="form-control px-input-settings icon-right"/>
              {/*<FontAwesomeIcon icon={['fas','circle-xmark']} className="px-icon-right color-gray-base"/>*/}
            </div>
          )
        })
      }
    });

    return (
      <div id="divQuickComments" className="manage" style={{display:'none'}}>
        <div className="px-settings-container tabs">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ul className="nav nav-tabs px-submenu-settings-navigation" role="tablist">
                <li onClick={() => this.handleTabSelection(qcs[0], 'quick')} className="active">
                  <a href="#quick" className="three-opt" role="tab" data-toggle="tab"
                    style={this.state.activeTab === 'quick' ? tabStyle.active : tabStyle.inactive}>
                    <span className="msg-lg">{I18n[language].quickPlease}</span>
                    <span className="msg-sm" style={{fontSize: '20px'}}><FontAwesomeIcon icon={['fas', 'forward']} /></span>
                  </a>
                </li>
                <li onClick={() => this.handleTabSelection(qcs[1], 'reply')} className="">
                  <a href="#reply" className="three-opt" role="tab" data-toggle="tab"
                    style={this.state.activeTab === 'reply' ? tabStyle.active : tabStyle.inactive}>
                    <span className="msg-lg">{I18n[language].replyLater}</span>
                    <span className="msg-sm" style={{ fontSize: '20px' }}><FontAwesomeIcon icon={['fas', 'clock']} /></span>
                  </a>
                </li>
                <li onClick={() => this.handleTabSelection(qcs[2], 'thanks')}>
                  <a href="#thanks" className="three-opt" role="tab" data-toggle="tab"
                    style={this.state.activeTab === 'thanks' ? tabStyle.active : tabStyle.inactive}>
                    <span className="msg-lg">{I18n[language].thanks}</span>
                    <span className="msg-sm" style={{ fontSize: '20px' }}><FontAwesomeIcon icon={['fas', 'face-smile']} /></span>
                    
                  </a></li>
              </ul>

              <div className="tab-content" >
                <div className="active tab-pane fade in" id="quick">
                  <div className="px-card px-card-settings">
                    <div className="px-icon-settings">
                      <FontAwesomeIcon icon={['fas','forward']}/>
                    </div>
                    <div className="panel-body px-body-padding-quick">
                      {/*<div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group first">
                            <label className="label-sm">{I18n[language].languages}</label>
                            <select name="qcLanguage" value={qcLanguage} onChange={this.props.updateMapLanguage}
                                    className="form-control px-input-settings">
                              <option value="en">{I18n[language].english}</option>
                              <option value="es">{I18n[language].spanish}</option>
                            </select>
                            <p className="px-label-information">{I18n[language].commentInfo}</p>
                          </div>
                        </div>
                      </div>*/}
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="px-invitations-container">
                            { quickPlease }
                          </div>
                          <div className="px-panel-invitations">
                            <label className="title" style={{color: colors.color4[3]}}>{I18n[language].dynamicValues}: </label>
                            <label className="subtitle">{I18n[language].participantUsername}: <strong>{'{{username}}'}</strong></label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action welcome">
                          <button onClick={() => this.props.saveMap('qc')}
                            className="btn px-btn lg btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{I18n[language].save}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="reply">
                  <div className="px-card px-card-settings">
                    <div className="px-icon-settings">
                      <FontAwesomeIcon icon={['fas','clock']}/>
                    </div>
                    <div className="panel-body px-body-padding-quick">
                      {/*<div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group first">
                            <label className="label-sm">{I18n[language].languages}</label>
                            <select name="qcLanguage" value={qcLanguage} onChange={this.props.updateMapLanguage}
                                    className="form-control px-input-settings">
                              <option value="en">{I18n[language].english}</option>
                              <option value="es">{I18n[language].spanish}</option>
                            </select>
                            <p className="px-label-information">{I18n[language].commentInfo}</p>
                          </div>
                        </div>
                      </div>*/}
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="px-invitations-container">
                            { replyLater }
                          </div>
                          <div className="px-panel-invitations">
                            <label className="title" style={{ color: colors.color4[3] }}>{I18n[language].dynamicValues}: </label>
                            <label className="subtitle">{I18n[language].participantUsername}: {'{{username}}'}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action welcome">
                          <button onClick={() => this.props.saveMap('qc')}
                            className="btn px-btn lg btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{I18n[language].save}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="thanks"> 
                  <div className="px-card px-card-settings">
                    <div className="px-icon-settings">
                      <FontAwesomeIcon icon={['fas','face-smile']}/>
                    </div>
                    <div className="panel-body px-body-padding-quick">
                      {/*<div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group first">
                            <label className="label-sm">{I18n[language].languages}</label>
                            <select name="qcLanguage" value={qcLanguage} onChange={this.props.updateMapLanguage}
                                    className="form-control px-input-settings">
                              <option value="en">{I18n[language].english}</option>
                              <option value="es">{I18n[language].spanish}</option>
                            </select>
                            <p className="px-label-information">{I18n[language].commentInfo}</p>
                          </div>
                        </div>
                      </div>*/}
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="px-invitations-container">
                            { thanks }
                          </div>
                          <div className="px-panel-invitations">
                            <label className="title" style={{ color: colors.color4[3] }}>{I18n[language].dynamicValues}: </label>
                            <label className="subtitle">{I18n[language].participantUsername}: {'{{username}}'}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action welcome">
                          <button onClick={() => this.props.saveMap('qc')}
                            className="btn px-btn lg btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{I18n[language].save}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
    handleTabSelection = (qcs, activeTabName) => {
      this.setState({ activeTab: activeTabName });
      this.props.updateQCSelected(qcs);
    }
}
