import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"
import InviteeRow from "./InviteeRow";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const classNames = require('classnames');
const _ = require('underscore');

export default class ListFilters extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    communityUsers: PropTypes.array.isRequired,
    usersFiltered: PropTypes.array.isRequired,
    toggleFilter: PropTypes.bool.isRequired,
    updateUsersFiltered: PropTypes.func.isRequired,
    updateCommunityUsers: PropTypes.func.isRequired,
    segments: PropTypes.array.isRequired,
    role2: PropTypes.string.isRequired,
    updatePM: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      usersFiltered: this.props.usersFiltered,
      checkEnabled: false,
      checkInvited: false,
      checkPending: false,
      checkDeclined: false,
      checkBlocked: false,
      counter: this._calculateCounter(this.props.communityUsers),
      flagInvited: false,
      flagDraft: false,
      moderators: []
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props !== nextProps){
      this.setState({
        counter: this._calculateCounter(nextProps.communityUsers),
        usersFiltered: nextProps.usersFiltered,
        moderators: nextProps.role2 === '' ? this.getModerators() : []
      });
    }
  };

  handleByState = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({ [name]: value }, function () {
      this.searchByStatus();
    });
  };

  searchByStatus = () => {
    const users = this.props.communityUsers;
    let filteredUsers = [];

    const enabled = this.state.checkEnabled ? 'Enabled' : 'All';
    const invited = this.state.checkInvited ? 'Invited' : 'All';
    const draft = this.state.checkPending ? 'Draft' : 'All';
    const declined = this.state.checkDeclined ? 'Declined' : 'All';
    const blocked = this.state.checkBlocked ? 'Blocked' : 'All';

    _.each(users, function (user) {
      if(user.state === enabled || user.state === invited || user.state === draft || user.state === declined || user.state === blocked) {
        filteredUsers.push(user);
      }
    }.bind(this));

    if(enabled === 'All' && invited === 'All' && draft === 'All' && declined === 'All' && blocked === 'All'){
      this.props.updateUsersFiltered(users);
      this.setState({flagInvited: false, flagDraft: false});

    } else {
      this.props.updateUsersFiltered(filteredUsers);

      if(filteredUsers.length === 0 && this.state.counter[1] !== 0){
        this.setState({flagInvited: true});
      } else {
        this.setState({flagInvited: false});
      }

      if(filteredUsers.length === 0 && this.state.counter[2] !== 0){
        this.setState({flagDraft: true});
      } else {
        this.setState({flagDraft: false});
      }
    }
  };

  render(){
    const toggleFilters = classNames({'to-hide': !this.props.toggleFilter});
    const flagInvited = classNames({
      'material-icons': this.state.flagInvited,
      'to-hide': !this.state.flagInvited
    });
    const flagDraft = classNames({
      'material-icons': this.state.flagDraft,
      'to-hide': !this.state.flagDraft
    });

    const segments = this.props.segments.map((segment) => {
      const obj = {
        value: segment.id,
        label: segment.name,
        color: segment.color
      };
      return obj
    });

    let users;
    //console.log('this.state.usersFiltered', this.state.usersFiltered);
    if(this.props.communityUsers.length === 0){
      users = <h4>There's no invited yet.</h4>
    } else {
      users = this._setListBasedOnFilters(this.state.usersFiltered).map((user, index) => {
        return(
          <InviteeRow key={index}
                      user={user}
                      communityId={this.props.communityId}
                      updateCommunityUsers={this.props.updateCommunityUsers}
                      segments={segments}
                      role2={this.props.role2}
                      updatePM={this.props.updatePM}/>
        )
      });
    }

    let btnQI = <button className="btn btn-quick-invite" data-toggle="modal" data-target="#modalQuickInvite" >
      Quick Invite<div className="ripple-container"/></button>;
    if(this.props.role2 === 'Observer' || this.props.role2 === ''){
      btnQI = '';
    }

    if(this.props.type === 'Participant'){
      // RECTRUITER QI, maybe not necessary because this role don't see Coll
    }

    let moderators = [];
    if(this.props.role2 === '' && typeof this.state.moderators !== 'undefined'){
      moderators = this.state.moderators.map((moderator, index) => {
        const avatar = moderator.avatar !== '' ? moderator.avatar : '/assets/user.png';
        const faIconGender = moderator.gender === 'Female' ? 'venus' : 'mars'; //TO-DO gender 'Other'

        return(
          <div key={index} className="col-md-3">
            <div className="row">
              <div className="col-md-4">
                <img src={avatar} style={{width:'100%',borderRadius:'50%',border:'3px solid #434e5e'}}/>
              </div>
              <div className="col-md-8">
                <div style={{fontSize:'22px'}}>{moderator.username}</div>
                <div style={{color:'rgb(126,210,196)',fontSize:'12px',display:'flex'}}>
                  {moderator.country}&nbsp;&nbsp;&nbsp;
                  <div><FontAwesomeIcon icon={faIconGender}/></div>
                </div>
                <div onClick={() => this.getPM(moderator.user_id)} style={{fontSize:'20px',cursor:'pointer'}}>
                  <FontAwesomeIcon icon="envelope"/></div>
              </div>
            </div>
          </div>
        )
      });
    }

    let compModerators = '';
    // if(this.props.role2 === ''){
    //   compModerators = <div className="row container" style={{border:'1px solid #f5bb87',
    //     padding:'15px 25px 25px',margin:'25px 0'}}>
    //     <h4 style={{textAlign:'center',margin:'0 0 20px',fontSize:'12px',color:'#f5bb87'}}>MODERATORS</h4>
    //     { moderators }
    //   </div>
    // }

    compModerators =
      <div className="row moderator-container">
        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-lg-offset-2 text-center">
          <div className="text-moderators">
            <span className="text">MODERATORS</span>
            <FontAwesomeIcon icon={['far', 'square-minus']}/>
          </div>
          <div className="moderators-elements-container">
            <div className="moderator-item">
              <img src="https://picsum.photos/200/300?image=0" alt="" className="avatar"/>
              <span className="username">John</span>
              <div className="interactions-container">
                <div className="genders-container">
                  <div className="gender-container">
                    <FontAwesomeIcon icon={['far','venus']} />
                  </div>
                </div>
                <div className="country-container">
                  <span> US </span>
                </div>
                <FontAwesomeIcon className="message" icon={['fas','envelope']} />
              </div>
            </div>
            <div className="moderator-item">
              <img src="https://picsum.photos/200/300?image=0" alt="" className="avatar"/>
              <span className="username">John</span>
              <div className="interactions-container">
                <div className="genders-container">
                  <div className="gender-container">
                    <FontAwesomeIcon icon={['far','venus']} />
                  </div>
                </div>
                <div className="country-container">
                  <span> US </span>
                </div>
                <FontAwesomeIcon className="message" icon={['fas','envelope']} />
              </div>
            </div>
          </div>
        </div>
      </div>;


    return(
      <div className={toggleFilters}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="row">
          <div className="col-sm-12" style={{top: '-10px'}}>
            <div className="search-container">
              <div className="form-group form-white search-form-group">
                <input ref="txtSearch" type="text" className="form-control input-search"
                       placeholder="blah blah"/>
                <button type="submit"  className="btn-search"  onClick={this.handleSearch} >
                  <FontAwesomeIcon icon={['fal', 'magnifying-glass']}/>
                </button>
              </div>
              <div className="custom-select">
                <select>
                  <option value="1">ALL</option>
                  <option value="2">Comedy</option>
                  <option value="3">Gameplays</option>
                  <option value="3">Fitness</option>
                </select>
                <FontAwesomeIcon icon={['fal', 'circle-chevron-down']} className="chevron"/>
              </div>

              { btnQI }
            </div>
          </div>
        </div>

        { compModerators }

        <div className="row" style={{marginTop:'80px'}}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="filter-container">
              <div className="check-btn">
                <input type="checkbox" name="checkEnabled" className="hidden-ch" onClick={this.handleByState}
                       id={"ch-active1"} style={{display:'none'}}/>
                <label htmlFor="ch-active1" className="btn btn-filter-list">
                  <span className="text">Active</span>
                  <div className="counter">
                    <span>{this.state.counter[0]}</span>
                  </div>
                </label>
              </div>
              <div className="check-btn">
                <input type="checkbox" name="checkInvited" className="hidden-ch" onClick={this.handleByState}
                       id={"ch-invited"} style={{display:'none'}}/>
                <label htmlFor="ch-invited" className="btn btn-filter-list">
                  <span className="text">Invited</span>
                  <div className="counter">
                    <span>{this.state.counter[1]}</span>
                  </div>
                </label>
                <i id="icnInvited" className={flagInvited} style={{color: 'red', position:'absolute'}}
                   data-tooltip-content="You have some invitees">
                  report_problem</i>
              </div>
              <div className="check-btn">
                <input type="checkbox" name="checkPending" className="hidden-ch" onClick={this.handleByState}
                       id={"ch-pending"} style={{display:'none'}}/>
                <label htmlFor="ch-pending" className="btn btn-filter-list">
                  <span className="text">Draft</span>
                  <div className="counter">
                    <span>{this.state.counter[2]}</span>
                  </div>
                </label>
                <i id="icnInvited" className={flagDraft} style={{color: 'red', position:'absolute'}}
                   data-tooltip-content="You have some invitees">
                  report_problem</i>
              </div>
              <div className="check-btn">
                <input type="checkbox" name="checkDeclined" className="hidden-ch" onClick={this.handleByState}
                       id={"ch-decline"} style={{display:'none'}}/>
                <label htmlFor="ch-decline" className="btn btn-filter-list">
                  <span className="text">Declined</span>
                  <div className="counter">
                    <span>{this.state.counter[3]}</span>
                  </div>
                </label>
              </div>
              <div className="check-btn">
                <input type="checkbox" name="checkBlocked" className="hidden-ch" onClick={this.handleByState}
                       id={"ch-blocked"} style={{display:'none'}}/>
                <label htmlFor="ch-blocked" className="btn btn-filter-list">
                  <span className="text">Blocked</span>
                  <div className="counter">
                    <span>{this.state.counter[4]}</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{marginTop:'20px'}}>
          <div className="col-md-12">
            <div className="users-container">
              { users }
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="load-more-items">
              <FontAwesomeIcon icon={['far','circle-plus']} className="icon-plus"/>
              <span className="text-more">Load more participants…</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getPM = (user_id) => {
    this.props.updatePM(user_id);
  };

  getModerators = () => {
    $.ajax({
      url: '/communities/get_community_users/' + this.props.communityId,
      method: 'GET',
      dataType: 'JSON',
      data: {type: 'Collaborator'},
      success: function (cu) {
        //console.log(cu);
        this.setState({moderators: cu});
      }.bind(this)
    });
  };

  _setListBasedOnFilters = (users) => {
    //console.log(users);
    let filteredUsers = [];
    const enabled = this.state.checkEnabled ? 'Enabled' : 'All';
    const invited = this.state.checkInvited ? 'Invited' : 'All';
    const draft = this.state.checkPending ? 'Draft' : 'All';
    const declined = this.state.checkDeclined ? 'Declined' : 'All';
    const blocked = this.state.checkBlocked ? 'Blocked' : 'All';

    _.each(users, function (user) {
      if(user.state === enabled || user.state === invited || user.state === draft || user.state === declined || user.state === blocked) {
        filteredUsers.push(user);
      }
    }.bind(this));
    // setState here causes infinite loops
    if(enabled === 'All' && invited === 'All' && draft === 'All' && declined === 'All' && blocked === 'All'){
      //this.setState({flagInvited: false, flagDraft: false});
      return users;
    } else {
      /*if(filteredUsers.length === 0 && this.state.counter[1] !== 0){
        this.setState({flagInvited: true});
      } else {
        this.setState({flagInvited: false});
      }

      if(filteredUsers.length === 0 && this.state.counter[2] !== 0){
        this.setState({flagDraft: true});
      } else {
        this.setState({flagDraft: false});
      }*/
      return filteredUsers;
    }
  };

  _calculateCounter(cu){
    let cE = 0, cI = 0, cDr = 0, cD = 0, cB = 0;

    _.each(cu, function (user) {
      if(user.state === 'Enabled')
        cE++;
      if(user.state === 'Invited')
        cI++;
      if(user.state === 'Draft')
        cDr++;
      if(user.state === 'Declined')
        cD++;
      if(user.state === 'Blocked')
        cB++;
    });

    return [cE, cI, cDr, cD, cB];
  }

}