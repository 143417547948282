import React from 'react';
import PropTypes from 'prop-types';
import UL_User from "./UL_User";
import LazyLoad from "react-lazy-load";

const UL_List = (props) => {
  const {
    communityId,
    communitySocial,
    communityShowPartList,
    segments,
    communityState,
    language,
    role_1,
    first_activity,
    users,
    from,
    followUserId,
    updateCommunityUsers,
    role_2,
    type,
    handleOpenModal,
    setFollow,
    updateOpenPMFromOutside,
    actionUser,
    editUser,
    viewProfile,
    isSavingFollow,
    userActions,
    colors,
    updateShowBackdrop,
    showBackdrop,
    reloadUserCards,
    handleReloadUserCards
  } = props;

  const i18n = {
    en: { usersNA: 'No participants yet' },
    es: { usersNA: 'No hay participantes aún' }
  };

  let usersList;

  if (users.length !== 0) {
    usersList = users.map(user => {
      return (
        <LazyLoad key={user.user_id}>
          <UL_User
            user={user}
            communityId={communityId}
            communitySocial={communitySocial}
            communityShowPartList={communityShowPartList}
            updateCommunityUsers={updateCommunityUsers}
            communitySegments={segments}
            role_1={role_1}
            role_2={role_2}
            type={type}
            handleOpenModal={handleOpenModal}
            setFollow={setFollow}
            from={from}
            communityState={communityState}
            language={language}
            actionUser={actionUser}
            editUser={editUser}
            first_activity={first_activity}
            viewProfile={viewProfile}
            isSavingFollow={isSavingFollow}
            userActions={userActions}
            followUserId={followUserId}
            updateOpenPMFromOutside={updateOpenPMFromOutside}
            colors={colors}
            updateShowBackdrop={updateShowBackdrop}
            showBackdrop={showBackdrop}
            reloadUserCards={reloadUserCards}
            handleReloadUserCards={handleReloadUserCards}
          />
        </LazyLoad>
      );
    });
  } else {
    usersList = <div className="px-empty-msg" style={{ color: colors.color4[3] }}>{i18n[language].usersNA}</div>;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="px-coll-list-users-container">{usersList}</div>
      </div>
    </div>
  );
};

UL_List.propTypes = {
  users: PropTypes.array.isRequired,
  segments: PropTypes.array.isRequired,
  communityId: PropTypes.number.isRequired,
  communitySocial: PropTypes.bool.isRequired,
  communityShowPartList: PropTypes.bool.isRequired,
  updateCommunityUsers: PropTypes.func.isRequired,
  role_1: PropTypes.string.isRequired,
  role_2: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  setFollow: PropTypes.func.isRequired,
  communityState: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  updateOpenPMFromOutside: PropTypes.func.isRequired,
  from: PropTypes.string,
  actionUser: PropTypes.func,
  editUser: PropTypes.func,
  first_activity: PropTypes.object,
  viewProfile: PropTypes.func,
  isSavingFollow: PropTypes.bool,
  userActions: PropTypes.func,
  followUserId: PropTypes.number,
  colors: PropTypes.object.isRequired,
  updateShowBackdrop: PropTypes.func.isRequired,
  showBackdrop: PropTypes.bool.isRequired,
  reloadUserCards: PropTypes.bool.isRequired,
  handleReloadUserCards: PropTypes.func.isRequired
};

export default UL_List;