import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";
import axios from 'axios';

import ImageModal from "../../MyProfile/ImageModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class MRecognize extends React.Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    enterToCommunity: PropTypes.func.isRequired,
    userAvatar: PropTypes.string.isRequired,
    userUsername: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    profileId: PropTypes.number.isRequired,
    isPreview: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    const bgColor = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    console.log('bgColor:', bgColor);

    this.state = {
      bgColor,
      avatar: this._getUserAvatar(props.userAvatar, props.userUsername, bgColor),
      username: props.userUsername,
      //username: props.userUsername,
      validation: false,
      isModalAvatar: false,
      isLoadedAvatar: false
    }
  }

  componentDidMount() {
    if(this.props.userAvatar === '/assets/user.png'){
      this.setState({isLoadedAvatar: true});
    }
  }

  render() {
    const {language, userAvatar, isPreview, isSaving, colors} = this.props;

    const i18n = {
      en: {
        title: 'How will others recognise you?', errorUsername: 'Name is taken.',
        pickAvatar: 'Pick an avatar', pickUsername: 'Pick a name', next: 'Next'
      },
      es: {
        title: 'Cómo quieres que te reconozcan?', errorUsername: 'Ese nombre ya existe.',
        pickAvatar: 'Escoger un avatar', pickUsername: 'Escoger un nombre', next: 'Siguiente'
      }
    }

    const modalAvatar = this.state.isModalAvatar &&
      <ImageModal objectId={this.props.profileId}
                  url={this.state.avatar}
                  handleToggle={this.isModalAvatarToggle}
                  updateAvatar={this.updateAvatar}
                  action='/profiles/update_avatar/'
                  language={language}
                  colors={colors}/>;

    return (
      <ReactModal isOpen={this.props.showModal} contentLabel="Modal Test" shouldCloseOnOverlayClick={true}
                  onRequestClose={this.props.updateShowModal} className="px-modal-content"
                  overlayClassName="px-modal-overlay">
        <div className="modal-dialog" style={{width:'350px'}}>
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                <i onClick={this.props.updateShowModal} className="material-icons">clear</i>
              </button>
              <h4 className="px-modal-title-smaller">{i18n[language].title}</h4>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-xs-8 col-xs-offset-2 text-center">
                  <div className="form-group first" style={{display: 'flex', flexDirection: 'column', alignItems: 'center',
                    justifyContent: 'center'}}>
                    <label className="label-sm" style={{color: colors.color1[2]}}>{i18n[language].pickAvatar}</label>
                    <img id="imgPreviewMyProfile" onClick={isPreview ? () => {} :this.isModalAvatarToggle} src={this.state.avatar}
                         className="px-thumb-65 img-circle" alt="" style={{cursor: 'pointer'}}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="username" className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].pickUsername}</label>
                    <input name="username" onChange={this.handleChange} value={this.state.username} onBlur={this.validate}
                           type="text" className="form-control px-input-settings" style={{textAlign: 'center'}}/>
                    {
                      this.state.validation &&
                      <span className="px-label-warning" style={{ color: colors.color4[3] }}>* {i18n[language].errorUsername}</span>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer" style={{borderTop:'none !important'}}>
              <div className="px-footer-container-buttons column">
                <button style={{backgroundColor: colors.color1[3]}} onClick={isPreview ? () => {} :
                  () => this.props.enterToCommunity(this.state.username, this.state.avatar, this.state.isLoadedAvatar)}
                        type="button" className="btn px-btn sm btn-blue-base"
                        disabled={this.state.username === '' || this.state.validation || isSaving}>
                  { isSaving ? <FontAwesomeIcon icon={['fas','spinner']} spin/> : i18n[language].next }
                </button>
              </div>
            </div>
          </div>
        </div>
        { modalAvatar }
      </ReactModal>
    );
  }

  _getUserAvatar = (userAvatar, userUsername, bgColor) => {
    console.log(bgColor);
    let avatar = userAvatar;

    if(userAvatar === '/assets/user.png'){
      const initials = userUsername.substring(0, 2).toUpperCase();
      const canvas = document.createElement('canvas');
      const radius = 30;
      const margin = 0;
      canvas.width = radius * 2 + margin * 2;
      canvas.height = radius * 2 + margin * 2;

      // Get the drawing context
      const ctx = canvas.getContext('2d');
      ctx.beginPath();
      ctx.arc(radius + margin,radius + margin, radius, 0, 2 * Math.PI, false);
      ctx.closePath();
      ctx.fillStyle = bgColor;
      ctx.fill();
      ctx.fillStyle = "white";
      ctx.font = "bold 30px Arial";
      ctx.textAlign = 'center';
      ctx.fillText(initials, radius,radius * 4 / 3 + margin);

      avatar = canvas.toDataURL();
    }

    return avatar;
  };

  updateAvatar = (blob, type) => {
    //console.log(blob);
    const reader  = new FileReader();

    reader.onloadend = function () {
      $('#imgProfileAvatar').attr('src', reader.result);
      $('#imgPreviewMyProfile').attr('src', reader.result);
    };

    reader.readAsDataURL(blob);
    this.setState({isLoadedAvatar: false});
  };

  validate = async () => {
    if(!this.props.isPreview){
      const response = await axios.get('/users/username_validation', {params: {username: this.state.username}});

      this.setState({validation: this.props.userUsername === this.state.username ? false : response.data.msg});
    }
  }

  isModalAvatarToggle = () => {
    this.setState(prevState => ({isModalAvatar: !prevState.isModalAvatar}));
  };

  handleChange = event => {
    const name = event.target.name;
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value}, () => {
      if(this.state.username.length > 1){
        this.setState({avatar: this._getUserAvatar(this.props.userAvatar, value, this.state.bgColor)})
      }
    });
  };
}
