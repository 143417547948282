import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Notifications extends React.Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    user_account: PropTypes.object.isRequired,
    sessionLang: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="px-settings-container tabs">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <ul className="nav nav-tabs px-submenu-settings-navigation" role="tablist">
              <li className="active"><a href="#notifications" role="tab" data-toggle="tab">NOTIFICATIONS</a></li>
              <li><a href="#emails" role="tab" data-toggle="tab">EMAILS</a></li>
            </ul>
            <div className="tab-content">
              <div className="active tab-pane fade in" id="notifications">
                <div className="px-card px-card-settings">
                  <div className="px-icon-settings">
                    <FontAwesomeIcon icon={['fas','bell']}/>
                  </div>
                  <div className="panel-body panel-body px-body-padding-welcome">
                    <div className="row">
                      <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
                        <div className="notifications-title">
                          <label className="label-sm">ABOUT YOUR COMMUNITIES</label>
                        </div>
                        <div className="px-notifications-container">
                          <div className="notifications-item">
                            <span className="title-header">You have been invited to join a community</span>
                            <div className="switch-container">
                              <label className="px-switch" >
                                <input type="checkbox" value="on"/>
                                <div className="slider round"/>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="emails">                
              <div className="px-card px-card-settings">
                  <div className="px-icon-settings">
                    <FontAwesomeIcon icon={['fas','bell']}/>
                  </div>
                  <div className="panel-body panel-body px-body-padding-welcome">
                    <div className="row">
                      <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
                        <div className="notifications-title">
                          <label className="label-sm">Waiting for content</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
