import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import CSEditorWYSIWYG from "../../../../Settings/Utils/CSEditorWYSIWYG";
import {setBlobToPreview} from "../../../../../UI/Globals/PX_Funs";
import ChAnswerList from "./ChAnswerList";
import EditorWYSIWYGState from "../../../../Settings/Utils/EditorWYSIWYGState";

export default class ChEdit extends React.Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    cardinal: PropTypes.number.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    updateQuestion: PropTypes.func.isRequired,
    cancelEditQuestion: PropTypes.func.isRequired,
    onEditorStateChangeQuestion: PropTypes.func.isRequired,
    handleChangeQuestion: PropTypes.func.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    deleteAnswer: PropTypes.func.isRequired,
    addAnswer: PropTypes.func.isRequired,
    onSortEndAnswerList: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    communityProjectType: PropTypes.string.isRequired,
    cmntyAiAutoModeration: PropTypes.bool,
    cmntyAllowImgs: PropTypes.bool,
    cmntyAiPlan: PropTypes.string,
    isSavingTask: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      showModalVideo: false,
      checkedSingle: props.question.data.subtype === 'Single',
      checkedMultiple: props.question.data.subtype === 'Multiple',
      showAiAutoModeration: false
    };
  }

  componentDidMount(){
    if(this.props.question.blob !== undefined) {
      setBlobToPreview(this.props.question.blob, this.refs.imgPreviewChEdit);
    }

    let btnHoverColor = this.props.colors.color2[3];
    $(".action-button, .btn-draggable").mouseenter(function () {
      $(this).css({ 'background-color': btnHoverColor });
    });
    $(".action-button, .btn-draggable").mouseleave(function () {
      $(this).css({ 'background-color': '' });
    });

    this.updateAutoModeration();

  }

  componentDidUpdate(prevProps){
    // TO-DO Improve radio buttons, it was a weird when selecting different choice in different activity,
    // in edition mode, the radio button checked sometimes just disappeared, this solution fix it (for text-images too)
    if(prevProps.question.data !== this.props.question.data){
      //console.log('componentDidUpdate', this.props.question.data.subtype);
      this.setState({
        checkedSingle: this.props.question.data.subtype === 'Single',
        checkedMultiple: this.props.question.data.subtype === 'Multiple'
      })
    }

    if (this.props.question.data.answers !== prevProps.question.data.answers ||
      this.props.question.video_url !== prevProps.question.video_url ||
      this.props.question.attachment !== prevProps.question.attachment ||
      this.props.question.blob !== prevProps.question.blob) {
      this.updateAutoModeration();
    }
  }

  updateAutoModeration() {
    const isAllowed = this.isTaskAutoModerationAllowed();
    if (!isAllowed) {
      // console.log('isAllowed', isAllowed)
      // If auto moderation is not allowed, ensures the checkbox is unchecked
      this.props.handleChangeQuestion({ target: { name: 'ai_auto_moderation', checked: false } }, this.props.question.id, false);
    }
    this.setState({ showAiAutoModeration: isAllowed });
  }

  render() {
    const {question, cardinal, communitySocial, language, colors} = this.props;
    const i18n = {
      en: {
        addImageToTask: 'Add an image to this task\'s instructions', addVideoToTask: 'Add a video to this task\'s instructions',
        addImageToAnswer: 'Add an image next to this answer',
        choiceType: 'Choice type', single: 'Single', multiple: 'Multiple',
        singleTooltip: 'Participants can choose only one answer.',
        multipleTooltip: 'Participants can choose multiple answers.',
        focus: 'Focus on', text: 'Text', images: 'Images',
        focusTextTooltip: 'Choices will be shown to participants focusing on text rather than images.',
        focusImagesTooltip: 'Choices will be shown to participants focusing on images rather than text.',
        otherOptions: 'Other options', mandatory: 'Mandatory',
        mandatoryTooltip: 'When active participants are forced to respond the Task in order to complete the Activity.',
        randomize: 'Randomize answers',
        randomizeTooltip: 'When active participants will see choices in random order.',
        answers: 'Answers', typePlaceholder: 'Type an answer here', addAnswer: 'Add answer', remaining: 'Remaining',
        addOpenField: 'Add open text field', save: 'Save', cancel: 'Cancel', preview: 'Preview',
        aiAutoMod: 'AI auto moderation', aiAutoModTT: 'AI moderated task',
      },
      es: {
        addImageToTask: 'Agrega una imagen en las instrucciones de esta tarea.', addVideoToTask: 'Agrega una video en las instrucciones de esta tarea.',
        addImageToAnswer: 'Agrega una imagen para esta respuesta.',
        choiceType: 'Tipo', single: 'Única', multiple: 'Múltiple',
        singleTooltip: 'Los participants pueden elegir solo una opción como respuesta.',
        multipleTooltip: 'Los participants pueden elegir múltiples opciones como respuestas.',
        focus: 'Destacar', text: 'Texto', images: 'Imágenes',
        focusTextTooltip: 'Las opciones se mostrarán a los participantes enfatizando el texto sobre las imágenes.',
        focusImagesTooltip: 'Las opciones se mostrarán a los participantes enfatizando las imágenes sobre el texto.',
        otherOptions: 'Otras opciones', mandatory: 'Obligatoria',
        mandatoryTooltip: 'Cuando activo los participantes están obligados a responder la Tarea para completar la Actividad.',
        randomize: 'Orden aleatorio',
        randomizeTooltip: 'Cuando activo los participantes veran las respuestas ordenadas aleatoriamente.',
        answers: 'Respuestas', typePlaceholder: 'Escribe una respuesta aquí', addAnswer: 'Agregar respuesta', remaining: 'Restantes',
        addOpenField: 'Agregar opción abierta', save: 'Guardar', cancel: 'Cancelar', preview: 'Muestra',
        aiAutoMod: 'Auto Moderation AI', aiAutoModTT: 'Tarea moderada por AI'
      }
    }

    const randomStr = question.data.random.toString();
    let btnImgClass = 'btn-media have-image';
    let imgUrl = '';
    let imgStyle = null;

    if(typeof question.blob !== 'undefined'){
      if(this.refs.imgPreviewChEdit !== undefined){
        setBlobToPreview(question.blob, this.refs.imgPreviewChEdit);
      }
      btnImgClass = 'btn-media have-image';
      imgStyle = {display: 'block'};
    } else {
      if(question.attachment.length !== 0){
        imgUrl = question.attachment[0].url;
        imgStyle = {display: 'block'};
      } else {
        btnImgClass = 'btn-media';
        imgStyle = {display: 'none'};
      }
    }

    let videoThumbnail = null;
    if(question.videoState){
      videoThumbnail = question.videoState === 'processed' ? question.video_thumbnail : '/assets/loader_200x200.gif';
    } else {
      videoThumbnail = question.video_thumbnail;
    }

    const dataAnswers = [];
    Object.keys(question.data.answers).forEach(key => {
      dataAnswers.push(question.data.answers[key]);
    });
    let cNormalAns = 0;
    let cOpenAns = 0;
    dataAnswers.forEach(answer => {
      if(answer.img !== undefined){
        cNormalAns++;
      } else {
        cOpenAns++;
      }
    });

    return (
      <div className="panel panel-activity-builder-details edit-mode">
        <div className="top-border"/>
        <div className="bottom-border"/>
        <div className="left-border"/>
        <div className="right-border"/>

        <div className="panel-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="title-task-container edit">
                <div className="task-number" style={{ backgroundColor: colors.color0[1] }}><span>{cardinal}</span></div>
                <span className="title-task">{question.task_type}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-task-edit-container">
              <div className="px-left-actv-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <EditorWYSIWYGState name="title"
                      editorName="editorQuestion"
                      editorState={question.editorQuestion}
                      onEditorStateChange={this.props.onEditorStateChangeQuestion}
                      questionId={question.id}
                      language={language}/>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="btn-media-container">
                          <div className="image-item">
                            <button onClick={() => this.props.updateShowModal('image', question.id)} className={btnImgClass} data-tooltip-content={i18n[language].addImageToTask}>
                              {
                                question.attachment.length === 0 && typeof question.blob === 'undefined' ?
                                  <FontAwesomeIcon icon={['fas', 'image']}/> : null
                              }
                              <img ref='imgPreviewChEdit' src={imgUrl} alt="" style={imgStyle}/>
                            </button>
                            {
                              (question.attachment.length !== 0 || question.blob !== undefined) &&
                              <div onClick={() => this.props.deleteMedia(question.id, 'image')} className="px-btn-close-float">
                                <div className="btn-container">
                                  <FontAwesomeIcon icon={['fas','xmark']}/>
                                </div>
                              </div>
                            }
                          </div>
                          <div className="image-item">
                            {
                              question.video_thumbnail === '' ?
                                <button onClick={() => this.props.updateShowModal('video', question.id)} className='btn-media' data-tooltip-content={i18n[language].addVideoToTask}>
                                  <FontAwesomeIcon icon={['fas', 'video']}/>
                                </button>
                                :
                                <button onClick={this.updateShowModalVideo} className='btn-media have-image'>
                                  <img src={videoThumbnail} alt=""/>
                                </button>
                            }
                            {
                              question.video_thumbnail !== '' &&
                              <div onClick={() => this.props.deleteMedia(question.id, 'video')} className="px-btn-close-float">
                                <div className="btn-container">
                                  <FontAwesomeIcon icon={['fas','xmark']}/>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-right-actv-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col- md-12 col-lg-12">
                    <label htmlFor="" className="label-sm" style={{color: colors.color1[2]}}>{i18n[language].choiceType}</label>
                    <div className="px-check-cont rad">
                      <label className="container-check radio">
                        <input onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)}
                              type="radio" name={"subtype"  + question.id} checked={this.state.checkedSingle}
                              className="radio-btn" value="Single"/>
                        <span className="checkmark radio"/>
                        <span>{i18n[language].single}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                          data-tooltip-content={i18n[language].singleTooltip}/>
                      </label>                      
                    </div>
                    <div className="px-check-cont rad">
                      <label className="container-check radio">
                        <input onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)}
                              type="radio" name={"subtype"  + question.id} checked={this.state.checkedMultiple}
                              className="radio-btn" value="Multiple"/>
                        <span className="checkmark radio"/>
                        <span>{i18n[language].multiple}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                              data-tooltip-content={i18n[language].multipleTooltip}/>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col- md-12 col-lg-12">
                    <label htmlFor="" className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].focus}</label>
                    <div className="px-check-cont rad">
                      <label className="container-check radio">
                        <input onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)}
                              type="radio" name={"focusOn" + question.id} checked={question.data.focusOn === 'Text'}
                              className="radio-btn" value="Text"/>
                        <span className="checkmark radio"/>
                        <span>{i18n[language].text}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                          data-tooltip-content={i18n[language].focusTextTooltip}/>
                      </label>
                    </div>
                    <div className="px-check-cont rad">
                      <label className="container-check radio">
                        <input onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)}
                              type="radio" name={"focusOn" + question.id} checked={question.data.focusOn === 'Images'}
                              className="radio-btn" value="Images"/>
                        <span className="checkmark radio"/>
                        <span>{i18n[language].images}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                          data-tooltip-content={i18n[language].focusImagesTooltip}/>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col- md-12 col-lg-12">
                    <label htmlFor="" className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].otherOptions}</label>
                    <div className="px-check-cont">
                      <label className="container-check">
                        <input type="checkbox" name="required" checked={question.required}
                              onChange={(event) => this.props.handleChangeQuestion(event, question.id, false)}/>
                        <span className="checkmark"/>
                        <span>{i18n[language].mandatory}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                          data-tooltip-content={i18n[language].mandatoryTooltip} />
                      </label>
                    </div>
                    <div className="px-check-cont">
                      <label className="container-check">
                        <input type="checkbox" name="random" checked={randomStr === 'true'}
                              onChange={(event) => this.props.handleChangeQuestion(event, question.id, true)}/>
                        <span className="checkmark"/>
                        <span>{i18n[language].randomize}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                          data-tooltip-content={i18n[language].randomizeTooltip}/>
                      </label>
                    </div>
                    {
                      this.state.showAiAutoModeration &&
                      <div className="px-check-cont">
                        <label className="container-check">
                          <input type="checkbox"  name="ai_auto_moderation" checked={question.ai_auto_moderation}
                                 onChange={(event) => this.props.handleChangeQuestion(event, question.id, false)}/>
                          <span className="checkmark"/><span>{i18n[language].aiAutoMod}</span>
                          <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog icon-right"
                                           data-tooltip-content={i18n[language].aiAutoModTT} />
                        </label>
                      </div>
                    }

                    {/*<div className="px-check-cont">
                      <label className="container-check">
                        <input type="checkbox" name="show" checked={question.show}
                              onChange={(event) => this.props.handleChangeQuestion(event, question.id, false)}/>
                        <span className="checkmark"/>
                        <span>Answer first, then see</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                         data-tooltip-content="Whether participants have to answer this task before being able
                                         to see other participants' responses."/>
                      </label>
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="inner-separator">
                <div className="separator"/>
                <span className="text">{i18n[language].answers}</span>
                <div className="separator"/>
              </div>
            </div>
          </div>

          <ChAnswerList dataAnswers={dataAnswers}
                        questionId={question.id}
                        updateAnswer={this.props.updateAnswer}
                        deleteAnswer={this.props.deleteAnswer}
                        onSortEndAnswerList={this.props.onSortEndAnswerList}
                        updateShowModal={this.props.updateShowModal}
                        deleteMedia={this.props.deleteMedia}
                        language={this.props.language}/>

          <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mg-top-20">
              <a onClick={() => this.props.addAnswer(question.id, 'normal')} className="add-answer-item" style={{color: colors.color1[3]}}>
                +   {i18n[language].addAnswer} ({dataAnswers.length !== 15 ? 15 - cNormalAns : 0} {i18n[language].remaining})</a>
              <a onClick={() => this.props.addAnswer(question.id, 'open')} className="add-answer-item" style={{ color: colors.color1[3] }}>
                +   {i18n[language].addOpenField} ({dataAnswers.length !== 15 ? 1 - cOpenAns : 0} {i18n[language].remaining})</a>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-save-row">
                <button onClick={() => this.props.updateShowModal('preview task CH', question.id)}
                  className="btn-preview">{i18n[language].preview}</button>
                <div className="action-button-container">
                  <button onClick={this.props.isSavingTask !== question.id ?
                    () => { this.props.updateQuestion(question.id)} : null}
                          className="btn-save"
                          style={{backgroundColor: colors.color1[3]}}>
                    {this.props.isSavingTask === question.id ?
                      <FontAwesomeIcon icon={['fas', 'spinner']} spin={true} style={{color: '#ffffff'}}/>
                      : i18n[language].save}
                  </button>
                  <button onClick={() => this.props.cancelEditQuestion(question.id)} className="btn-cancel">
                    {i18n[language].cancel}
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="actions-panel">
          <div className="actions-container" style={{marginTop: '0'}}>
            <button onClick={this.props.isSavingTask !== question.id ?
              () => this.props.updateQuestion(question.id) : null}
                    className="action-button edit">
              {this.props.isSavingTask === question.id ?
                <FontAwesomeIcon icon={['fas', 'spinner']} spin={true} style={{color: '#ffffff'}}/>
                : <FontAwesomeIcon icon={['far', 'check']}/>}
            </button>
            <button onClick={() => this.props.cancelEditQuestion(question.id)} className="action-button edit">
              <FontAwesomeIcon icon={['far', 'xmark']}/>
            </button>
          </div>
        </div>
      </div>

    );
  }

  updateShowModalVideo = () => {
    this.setState(state => ({showModalVideo: !state.showModalVideo}));
  };

  isTaskAutoModerationAllowed = () => {
    let taskAutoModerationAllowed = false;
    const {question, cmntyAiAutoModeration, cmntyAllowImgs, cmntyAiPlan, communityProjectType} = this.props
    let isPlanPro = cmntyAiPlan === 'aiPlan01' || cmntyAiPlan === 'aiPlan02'
    let has_media = this.checkMedia(question);

    if (communityProjectType === 'Mixed' && isPlanPro && cmntyAiAutoModeration) {
      if (has_media === false) {
        taskAutoModerationAllowed = true
      }
    }
    // console.log('taskAutoModerationAllowed', taskAutoModerationAllowed)
    return taskAutoModerationAllowed
  }

  checkMedia = (question) => {
    // Check if images are associated to choices - if any 'img' attributes in 'data.answers' are not null or empty
    const hasImageInAnyAnswer = question.data.answers.some(answer =>
      (answer.img !== undefined && answer.img !== "") ||
      answer.blob !== undefined ||
      answer.blobPreview !== undefined ||
      answer.previewImg !== undefined
    )

    // Check if a video is attached to the question
    const hasVideo = question.video_url !== null && question.video_url !== "";

    // Check if an image is attached to the question - 'attachment' is not an empty array
    const hasAttachment = question.attachment.length > 0;

    const hasImagePreview = Boolean(question.blobPreview || question.blob);

    // Return true if any condition is met
    // console.log('hasImageInAnyAnswer', hasImageInAnyAnswer)
    // console.log('checkMedia ----', hasImageInAnyAnswer || hasVideo || hasAttachment || hasImagePreview)
    return hasImageInAnyAnswer || hasVideo || hasAttachment || hasImagePreview
  }
}
