import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TagsInput from "react-tagsinput";
import AutosizeInput from "react-input-autosize";
import { Tooltip as ReactTooltip } from "react-tooltip"
import Select, { components } from "react-select";

export default class NewBroadcast extends React.Component {
  static propTypes = {
    bodyBroadcast: PropTypes.string.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    handleTagsUsers: PropTypes.func.isRequired,
    selectRecipientsIds: PropTypes.func.isRequired,
    saveBroadcast: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    segments: PropTypes.array.isRequired,
    isSendingBroadcast: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      recipients: []
    }
  }

  render() {
    const {bodyBroadcast, tags, language, colors, segments, isSendingBroadcast} = this.props;

    const I18n = {
      en: { typeTheirUsr: 'No recipients selected yet', yourMsgHere: 'Your message here...', send: 'Send',
            selectRecipients: 'Select recipients'},
      es: { typeTheirUsr: 'No hay destinatarios seleccionados aún', yourMsgHere: 'Tu mensaje aquí...', send: 'Enviar',
            selectRecipients: 'Elige destinatarios'}
    };

    // Custom icon for dropdown selector - React select
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon icon={['fas', 'sort']} className="dropdown-icon" data-tooltip-content="" />
        </components.DropdownIndicator>
      );
    };
    // Custom icon for clear selections indicator - React select
    const ClearIndicator = props => {
      return (
        <components.ClearIndicator {...props}>
          <FontAwesomeIcon icon={['fal', 'xmark']} className="clear-indicator-icon" data-tooltip-content="Revert to default" />
        </components.ClearIndicator>
      );
    };
    // Custom icon for value remove - React select
    const MultiValueRemove = props => {
      return (
        <components.MultiValueRemove {...props}>
          <FontAwesomeIcon icon={['fal', 'xmark']} className="clear-indicator-icon" data-tooltip-content="" />
        </components.MultiValueRemove>
      );
    };

    // Custom styles for mandatory select - via React Select 
    const customSelectStyles = {
      control: (base) => ({
        ...base,
        width: '100%',
        borderRadius: '4px',
        border: '1px solid #DADDE1',
        minHeight: '30px',
        '&:hover': {
          border: '1x solid #DADDE1'
        }
      }),
      valueContainer: (base) => ({
        ...base,
        paddingTop: '4px',
        paddingBottom: '4px',
      }),
      multiValue: (base) => ({
        ...base,
        marginTop: '1',
        marginBottom: '1',
      }),
      multiValueLabel: (base) => ({
        ...base,
        backgroundColor: '#AFBDC1',
        color: '#ffffff',
        borderRadius: '2px 0 0 2px',
        textTransform: 'uppercase',
        fontSize: '8px',
        fontFamily: "'Sharp Sans ExtraBold', Helvetica, sans-serif",
        letterSpacing: '1.8px',
        paddingTop: '2px',
        paddingBottom: '1px',
        marginTop: '0',
        marginBottom: '0',
        lineHeight: '15px'
      }),
      multiValueRemove: (base) => ({
        ...base,
        backgroundColor: '#AFBDC1',
        color: '#FFFFFF',
        fontSize: '8px',
        borderRadius: '0 2px 2px 0',
        cursor: 'pointer',
        padding: '0 5px',
        '&:hover': {
          backgroundColor: '#7C8593',
          color: '#FFFFFF'
        }
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none'
      }),
      input: (base) => ({
        ...base,
        marginTop: '0',
        marginBottom: '0',
        paddingTop: '0',
        paddingBottom: '0',
        //display: 'none'
      }),
      clearIndicator: (base) => ({
        ...base,
        cursor: 'pointer',
        padding: '5px 4px 5px 8px',
        fontSize: '11px'
      }),
      dropdownIndicator: (base) => ({
        ...base,
        fontSize: '12px',
        color: '#7C8593',
        paddingRigth: '10px',
        padding: '5px 10px 5px 8px',
        cursor: 'pointer',
        '&:hover': {
          color: '#7C8593',
        }
      }),
      menu: (base) => ({
        ...base,
        background: '#F2F2F2',
        padding: '5px 8px',
        width: '218px',
        marginLeft: '20px',
        zIndex: '4'
        //padding: '0'
      }),
      menuList: (base) => ({
        ...base,
        paddingBottom: '8px'
      }),
      option: (base, state) => ({
        ...base,
        fontSize: '8px',
        fontFamily: 'Arial, sans-serif',
        padding: '0 12px',
        height: '18px',
        textTransform: 'uppercase',
        backgroundColor: state.isFocused ? '#F2F2F2' : '#F2F2F2',
        '&:hover': {
          backgroundColor: '#5C95F1',
          color: '#FFFFFF'
        }
      }),
    };

    const activeSegments = segments.filter(v => v.state === 'Enabled');

    return (
      <React.Fragment>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="broadcast-search">
          <Select name="select_recipients" className="react-select" classNamePrefix="react-select"
            // value={}
            onChange={(event) => { this.handleSelectRecipients(event)}}
            options={this._buildRecipientSelectOptions(activeSegments)}
            isMulti={true}
            components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
            placeholder={I18n[language].selectRecipients}
            styles={customSelectStyles} />
          {/* <TagsInput onChange={this.props.handleTagsUsers} value={tags}  addKeys={[188, 13, 9, 32]}
                    onlyUnique={true} renderInput={this.autosizingRenderInput} addOnBlur={true}
            inputProps={tags.length > 0 ? { placeholder: '' } : { placeholder: I18n[language].typeTheirUsr } } className="px-message-tags-input"/>
          <div className="broadcast-actions-container">
            <div className='label-xs'>SELECT RECIPIENT</div>
            <span className='actions'>
              <i className="material-icons" onClick={() => this.props.selectRecipientsIds('collaborators')} data-tooltip-content={'All collaborators'} style={{ color: colors.color1[3] }}>bubble_chart</i>
              <i className="material-icons" onClick={() => this.props.selectRecipientsIds('participants')} data-tooltip-content={'All participants'} style={{ color: colors.color1[3] }}>people</i>
              <i className="material-icons" onClick={() => this.props.selectRecipientsIds('all')} data-tooltip-content={'All users'} style={{ color: colors.color1[3] }}>done_all</i>
            </span>
          </div> */}
        </div>
        <div className="message-container">
          <div className="form-group">
            <textarea name="bodyBroadcast" onChange={this.props.handleInputChange}
                      //onKeyPress={this.handleKeyPress}
                      value={bodyBroadcast} className="message-textarea" placeholder={I18n[language].yourMsgHere} />
          </div>
          <div className="button-send-container">
            <button onClick={() => this.props.saveBroadcast(bodyBroadcast, this.state.recipients)} className="btn send-button"
                    style={{ backgroundColor: colors.color1[3]}} disabled={isSendingBroadcast}>
              {isSendingBroadcast ? <FontAwesomeIcon icon={['fas','spinner']} spin/> : I18n[language].send}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  _buildRecipientSelectOptions = (segments) => {
    let recipientsSelectOptions = [{ value: 'collaborators', label: 'All collaborators' },
      { value: 'participants', label: 'All participants' }, { value: 'all', label: 'Everyone' }]
    if(segments.length > 0) {
      segments.forEach(element => {
        recipientsSelectOptions.push( { value: element.id, label: element.name} )
      });
    }
    return recipientsSelectOptions
  }

  handleSelectRecipients = (val) => {
    let recipients = [];
    if(val !== null) {

      let selectedRecipients = val.map(item => item.value);
      let allIncluded = selectedRecipients.includes("all");
      let allCollaboratorsIncluded = selectedRecipients.includes("collaborators");
      let allParticipantsIncluded = selectedRecipients.includes("participants");

      if(allIncluded || (allCollaboratorsIncluded && allParticipantsIncluded)) {
          recipients = ['all', 'null'];
      } else if (allParticipantsIncluded) {
          recipients = ['participants', 'null'];
      } else {
        allCollaboratorsIncluded ? recipients.push('collaborators') : recipients.push('null') ;
        let segmentsIds = selectedRecipients.filter(v => v !== 'collaborators');
        segmentsIds.length > 0 ? recipients.push(segmentsIds) : recipients.push('null');
      }
      this.props.selectRecipientsIds(recipients);
      this.setState({recipients});
      console.log(recipients);

    }
    this.props.selectRecipientsIds(recipients);
    this.setState({ recipients });
  }


  autosizingRenderInput = ({addTag, ...props}) => {
    let {onChange, value, ...other} = props;
    return (
      <AutosizeInput type='text' onChange={onChange} value={value} {...other} />
    )
  };

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.props.saveBroadcast(this.props.bodyBroadcast);
    }
  };
}
