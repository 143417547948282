import React from "react";

const translate = async (content, locales, data) => {
  const url =
      process.env.NODE_ENV === 'development'
          ? 'http://127.0.0.1:8000/getTranslation/'
          : 'https://px-openai-api.herokuapp.com/getTranslation/';

  const requestData = {
    content,
    locales: Array.isArray(locales) ? locales : [locales],
    data,
  };
  // console.log('REQUEST DATA 1-----> ', requestData);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      body: JSON.stringify(requestData),
    });

    console.log('REQUEST DATA 2-----> ', requestData);

    if (response.ok) {
      console.log('Translation done by Python API');
    } else {
      console.error('Translation request failed');
    }
  } catch (error) {
    console.error('Translation request failed', error);
  }
};

export default translate;