import PropTypes from 'prop-types';
import React from 'react';

const ASCounter = (props) => {
  let width = null;

  switch(props.counter){
    case 1: if(props.percentage <= 2) width = 0;break;
    case 2: width = 4;break;
    case 3: width = 5;break;
  }

  return(
    <div className="px-ov-line-counter" style={{margin: width}}>
      <span>{props.counter}</span>
    </div>
  )
};

ASCounter.propTypes = {
  counter: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired
};

export default ASCounter;