import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import v4 from 'uuid';

import {getIsTaskAlert} from "../../../UI/Globals/PX_Funs";
import moment from "moment";

export default class DMActivity extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    activity: PropTypes.object.isRequired,
    isMin: PropTypes.bool.isRequired,
    role_2: PropTypes.string.isRequired,
    indexY: PropTypes.number.isRequired,
    activityActions: PropTypes.func.isRequired,
    handleZIndex: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    clearForwardUrl: PropTypes.func.isRequired, 
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    //this.state = { name: this.props.name };
  }

  render() {
    const {user, activity, isMin, role_2, indexY, task, language, colors} = this.props;
    const i18n = {
      en: {
        seeResponses: 'See responses', hurry: 'Hurry (blank & overdue tasks)', acceptCompleted: 'Accept completed tasks',
        export: 'Export responses', menu: 'Click for more options'
      },
      es: {
        seeResponses: 'Ver respuestas', hurry: 'Apresurar (tareas vacías y atrasadas)', acceptCompleted: 'Aceptar tareas completadas',
        export: 'Exportar respuestas', menu: 'Clic para más opciones'
      }
    }

    const tasks = activity.tasks.map(task => {
      const userResponses = task.responses.filter(response => response.user_id === user.id);
      const responses = userResponses.map(response => {

        if(response.user_id === user.id){
          const isAlert = getIsTaskAlert(activity, response);
          //console.log(isAlert);
          const taskTitleForTooltip = task.title.length > 140 ?
            task.title.substring(0, 140) + "..." : task.title.substring(0, 140);
          const urlTask = '/communities/' + activity.community_id + '/transcript/' + user.id + '/'
            + activity.id + '/' + task.id + '/ActAndParCell';

          return(
            <a key={response.id}
               onClick={role_2 !== "Recruiter" ? (event) => this.props.clearForwardUrl(event, 'common', urlTask) : null}>
              <div  style={{
                backgroundColor: this._getStateColor(response.state, true),
                border: isAlert ? '2px solid ' + colors.color4[3] : null, 
                cursor: role_2 !== "Recruiter" ? 'pointer' : 'default'
              }}
                   className={"status-item  " + this._getClassSateColor(response.state, true)}
                   data-tooltip-content={taskTitleForTooltip.replace(/(<([^>]+)>)/ig,"")}>
              </div>
            </a>
          )
        } else {
          return(
            <div key={task.id}>No response</div>
          )
        }
      });

      return(
        <div key={task.id}>{responses}</div>
      )
    });

    const now = moment().format('YYYY-MM-DD');
    let available = true;

    if(activity.start){
      const startDate = moment(activity.start).format('YYYY-MM-DD');
      if(now < startDate){
        available = false;
      }
    }

    const cellMenuId = v4();
    const liResponses = <li><a onClick={() => this.props.activityActions('see responses', user, activity)}>{i18n[language].seeResponses}</a></li>;
    const liFaster = <li><a onClick={() => this.props.updateShowModal('Matrix', 'cell faster', user, activity)}>{i18n[language].hurry}</a></li>;
    const liAccept = <li><a onClick={() => this.props.updateShowModal('Matrix', 'cell accept', user, activity)}>{i18n[language].acceptCompleted}</a></li>;
    const liExport = <li><a onClick={() => this.props.activityActions('view pdf', user, activity)}>{i18n[language].export}</a></li>;

    let dropdownAction = "";
    if (role_2 === "Owner" || role_2 === "Moderator") {
      dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide show" style={{ display: 'block' }}>
        {liResponses}{/*liFaster*/}{liAccept}{/*liExport*/}
      </ul>
    } else if (role_2 === "Observer") {
      dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide show" style={{ display: 'block' }}>
        {liResponses}
      </ul>
    }

    return (
      <div className="px-status-container" style={{display: isMin ? 'none' : ''}}>
        {
          available &&
          <ContextMenuTrigger id={cellMenuId} holdToDisplay={1}>
            { role_2 !== "Recruiter" && role_2 !== "" ?
              <div className="submenu-item" data-tooltip-content={i18n[language].menu} style={{fontSize: '20px', height: '22px', width: '22px'}}>
                <FontAwesomeIcon icon={['fas', 'circle-plus']} style={{fontSize: '20px'}}/>
              </div> : ""
            }
          </ContextMenuTrigger>
        }
        <ContextMenu id={cellMenuId} onShow={() =>this.props.handleZIndex('show', indexY)}
                     onHide={() =>this.props.handleZIndex('hide', indexY)} style={{zIndex:'2'}}>
          { available && dropdownAction }
        </ContextMenu>

        {tasks}
      </div>
    );
  }

  _getClassSateColor(state, available){
    let className = '';

    switch (state) {
      case 'Accepted':
        className = 'accepted';
        break;
      case 'Completed':
        className = 'completed';
        break;
      case 'Draft':
        className = 'drafted';
        break;
      case 'Empty':
        className = 'empty';
        break;
      default:
        if(available){
          className = 'empty';
        } else {
          className = 'not-available';
        }
        break;
    }

    return className;
  };
  
  _getStateColor(state, available) {
    const colors = this.props.colors;
    let color = '';

    switch (state) {
      case 'Accepted':
        color = colors.colorStates[0];
        break;
      case 'Completed':
        color = colors.colorStates[1];
        break;
      case 'Draft':
        color = ''; // this color is managed by CSS class since they are not dynamic
        break;
      case 'Empty':
        color = ''; // this color is managed by CSS class since they are not dynamic
        break;
      default:
        if (available) {
          color = ''; // this color is managed by CSS class since they are not dynamic
        } else {
          color = ''; // this color is managed by CSS class since they are not dynamic
        }
        break;
    }

    return color;
  };
  







}
