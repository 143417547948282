import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from 'underscore';
import { Tooltip as ReactTooltip } from "react-tooltip"

import ColorPicker from "./Utils/ColorPicker";

export default class CSSegmentsTags extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    segments: PropTypes.array.isRequired,
    addSegment: PropTypes.func.isRequired,
    updateSegment: PropTypes.func.isRequired,
    deleteSegment: PropTypes.func.isRequired,
    isSavingSegments: PropTypes.bool.isRequired,
    saveSegment: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    addTag: PropTypes.func.isRequired,
    updateTag: PropTypes.func.isRequired,
    deleteTag: PropTypes.func.isRequired,
    saveTag: PropTypes.func.isRequired,
    saveCommunity: PropTypes.func.isRequired,
    isSavingTags: PropTypes.bool.isRequired,
    saveSegments: PropTypes.func.isRequired,
    saveTags: PropTypes.func.isRequired,
    updateColorSegment: PropTypes.func.isRequired,
    updateColorTag: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      activeTab: 'segments' 
    }
  }

  componentDidMount() {
    let btnColor = this.props.colors.color0[3];
    $(".nav-tabs > li.active > a").css({ "color": '#ffffff', 'background-color': btnColor, 'border-color': btnColor });
  }

  render() {
    const {community, isSavingSegments, isSavingTags, language, colors} = this.props;

    const I18n = {
          en: { segments: 'Segments', tags: 'Tags', gendersToSegment: 'Treat genders as segments',
                save: 'Save', name: 'Name...', gendersToSegmentTooltip: 'We\'ll use the participants\' profiles to create gender segments automatically.'
                },
            es: { segments: 'Segmentos', tags: 'Tags', gendersToSegment: 'Usar géneros como segmentos',
              save: 'Guardar', name: 'Nombre...', gendersToSegmentTooltip: 'Usaremos el perfil de tus participants para crear segmentos por género automáticamente.'
                }
          };

    const tabStyle = {
      active: { border: `1px solid ${colors.color0[3]}`, color: '#ffffff', backgroundColor: colors.color0[3] },
      inactive: { border: '1px solid #DADDE1', color: '#7C8593', backgroundColor: '#f0eff3' }
    };

    const segments = this.props.segments.map((segment, index) => {
      return(
        <div key={index} className='segment-item form-group'>
          <ColorPicker id={segment.id} color={segment.color} updateColor={this.props.updateColorSegment}
                       icon='bookmark'/>
          <input name="name" onChange={(event) => this.props.updateSegment(event, segment.id)} value={segment.name}
                 type="text" className="form-control px-input-settings" placeholder={I18n[language].name}/>
          <FontAwesomeIcon onClick={() => this.props.deleteSegment(segment.id)} icon={['fas', 'circle-xmark']}
                           className="remove-icon" style={{cursor:'pointer'}}/>
        </div>
      )
    });

    const tags = this.props.tags.map((tag, index) => {
      return(
        <div key={index} className="segment-item form-group">
          <ColorPicker id={tag.id} color={tag.color} updateColor={this.props.updateColorTag} icon='tag'/>
          <input name="name" onChange={(event) => this.props.updateTag(event, tag.id)} value={tag.name}
            type="text" className="form-control px-input-settings" placeholder={I18n[language].name}/>
          <FontAwesomeIcon onClick={() => this.props.deleteTag(tag.id)} icon={['fas', 'circle-xmark']}
                           className="remove-icon" style={{cursor:'pointer'}}/>
        </div>
      )
    });

    return (
      <div id="divSegsAndTags" className="manage" style={{display:'none'}}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="px-settings-container tabs">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <ul className="nav nav-tabs px-submenu-settings-navigation" role="tablist">
                    <li className="active">
                      <a onClick={() => this.setActiveTab('segments')} href="#segments" role="tab" data-toggle="tab"
                        style={this.state.activeTab === 'segments' ? tabStyle.active : tabStyle.inactive}>
                          {I18n[language].segments}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.setActiveTab('tags')} href="#tags" role="tab" data-toggle="tab"
                         style={this.state.activeTab === 'tags' ? tabStyle.active : tabStyle.inactive}>
                           {I18n[language].tags}
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div className="active tab-pane fade in" id="segments">
                      
                      <div className="px-card px-card-settings">
                        <div className="px-icon-settings">
                      <FontAwesomeIcon icon={['fas','bookmark']}/>
                        </div>
                        <div className="panel-body px-body-padding-tags-maps">
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            {/*<div className="px-check-cont">*/}
                            {/*  <label className="container-check">*/}
                            {/*    <input name="gender_as_segment" onChange={this.props.handleChange}*/}
                            {/*        checked={community.gender_as_segment} type="checkbox"/>*/}
                            {/*    <span className="checkmark"/>*/}
                            {/*    <span data-tooltip-content={I18n[language].gendersToSegmentTooltip}>{I18n[language].gendersToSegment}</span>*/}
                            {/*  </label>*/}
                            {/*</div>*/}
                              <div className="master-segments-container">
                                <div className="segments-container">
                                  { segments }
                                <span id="spnSegments" className="span-error-show px-label-warning"/>
                                </div>

                                <div className="row">
                                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                    <button onClick={this.props.addSegment} className="btn px-btn circle lg btn-purple-base"
                                            style={{margin:'20px auto 0px', backgroundColor: colors.color0[3]}}>
                                      <FontAwesomeIcon icon={['far', 'plus']} style={{fontSize:'18px'}}/>
                                    </button>
                                  </div>
                                </div>
                                <div className="row px-row-buttons-action welcome">
                                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                    <button onClick={this.validSegments} className="btn px-btn lg btn-blue-base"
                                            disabled={isSavingSegments} style={{backgroundColor: colors.color1[3]}}>
                                      { isSavingSegments ? <FontAwesomeIcon icon={['fas', 'spinner']} size="2x" spin/> : I18n[language].save }
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="tab-pane fade" id="tags">
                      <div className="px-card px-card-settings">
                        <div className="px-icon-settings">
                          <FontAwesomeIcon icon={['fas','tag']}/>
                        </div>
                        <div className="panel-body px-body-padding-tags-maps">
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <div className="master-segments-container">
                                <div className="segments-container">
                                  { tags }
                                 <span id="spnTags" className="span-error-show px-label-warning"/>
                                </div>

                                <button onClick={this.props.addTag} className="btn px-btn circle lg btn-purple-base"
                                  style={{ margin: '20px auto 0px', backgroundColor: colors.color0[3]}}>
                                  <FontAwesomeIcon icon={['far', 'plus']} style={{fontSize:'18px'}}/>
                                </button>
                                <div className="row px-row-buttons-action welcome">
                                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                    <button onClick={this.validTags} className="btn px-btn lg btn-blue-base"
                                            disabled={isSavingTags} style={{backgroundColor: colors.color1[3]}}>
                                      {isSavingTags ? <FontAwesomeIcon icon={['fas', 'spinner']} size="2x" spin /> : I18n[language].save }
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    );
  }

  validTags = () => {
    const tags = [...this.props.tags];
    const i18n = {
      en: {
        duplicated: 'There\'s some duplicated or empty Tags'
      },
      es: {
        duplicated: 'Hay algunas Tags duplicadas o sin nombre'
      }
    }
    const language = this.props.language;

    //if(tags.length !== 0){
      const uniqA = _.uniq(tags, function (tag) {
        return tag.name;
      });

      if(tags.length === uniqA.length){
        $('#spnTags').text('');
        this.props.saveTags();
        /*_.each(tags, function (tag) {
          if(tag.name !== ''){
            this.props.saveTag(tag);
          }
        }.bind(this))*/
      } else {
        $('#spnTags').text(i18n[language].duplicated);
        this.setState({isSaving: false});
      }
    //}
  };

  validSegments = () => {
    const segments = [...this.props.segments];
    const i18n = {
      en: {
        duplicated: 'There\'s some duplicated or empty segments'
      },
      es: {
        duplicated: 'Hay algunos segmentos duplicados o sin nombre'
      }
    }
    const language = this.props.language;

    //if(segments.length !== 0){
      const uniqA = _.uniq(segments, function (segment) {
        return segment.name;
      });

      if(segments.length === uniqA.length){
        $('#spnSegments').text('');
        this.props.saveSegments();
        /*_.each(segments, function (segment) {
          if(segment.name !== ''){
            this.props.saveSegment(segment);
          }
        }.bind(this))*/
      } else {
        $('#spnSegments').text(i18n[language].duplicated);
        this.setState({isSaving: false});
      }
    /*} else {
      this.props.saveCommunity();
    }*/
  };

  setActiveTab = (tabName) => {
    this.setState({ activeTab: tabName });
  }
}
