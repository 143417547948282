import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class EmptyDashImageModal extends React.Component {
  static propTypes = {
    objectId: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    handleToggle: PropTypes.func.isRequired,
    updateEmptyDashboardImage: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      image: this.props.url,
      croppedImage: null
    };
  }

  render() {
    const {language, colors} = this.props;
    let emptyDashImage = this.state.image;
    // if(emptyDashImage === '')
    //   emptyDashImage = '/assets/community.png';

    const i18n = {
      en: {
        noActivityImg: 'Empty dashboard image', save: 'Save', cancel: 'Cancel'
      },
      es: {
        noActivityImg: 'Imagen para panel vacío', save: 'Guardar', cancel: 'Cancelar'
      }
    }

    return (
      <div className="my-modal">
        <div className="modal-dialog">
          <div className="modal-content" style={{marginTop: '30px'}}>
            <div className="modal-header" style={{margin:'0'}}>
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                      data-dismiss="modal" aria-hidden="true" onClick={this.props.handleToggle}>
                <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px', float: 'right', cursor: 'pointer' }} />
              </button>
              <h4 className="px-modal-title">{i18n[language].noActivityImg}</h4>
            </div>
            <div className=" px-modal-body-add-activity ">
              <Cropper
                ref='cropper'
                src={emptyDashImage}
                style={{height: 200, width: '100%'}}
                // Cropper.js options
                aspectRatio={1 / 1}
                guides={true}
                crop={this._crop.bind(this)} />

              <Dropzone onDrop={this.onDrop} minSize={0} maxSize={2097152}
                        style={{border:'0 solid', marginTop:'20px'}} accept={'image/jpeg, image/png'}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="btn px-btn circle lg btn-turquoise-base" style={{margin:'10px auto', backgroundColor: colors.color2[3]}}>
                      <FontAwesomeIcon icon={['fas', 'camera']} style={{ fontSize: '16px' }} />
                    </div>
                  </div>
                )}
              </Dropzone>

              {/*<Dropzone onDrop={this.onDrop} style={{border:'0 solid', marginTop:'20px'}}>
                <div className="btn px-btn circle lg btn-turquoise-base" style={{margin:'0 auto'}}>
                  <i className="material-icons">camera_enhance</i>
                </div>
                </Dropzone>*/}
            </div>
            <div className="px-modal-footer-gray">
              <div className="px-footer-container-buttons row">
                <button ref="btnUpdateDashImage" onClick={this.handleImage} type="button"
                        className="btn px-btn sm btn-blue-base" disabled={false}
                        style={{backgroundColor: colors.color1[3]}}>{i18n[language].save}
                </button>
                <button onClick={this.props.handleToggle} type="button"
                        className="btn px-btn sm btn-gray-darker inverted">{i18n[language].cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    Object.assign(file, {
      preview: URL.createObjectURL(file)
    });

    this.setState({image: file.preview});
  };

  _crop(){
    // image in dataUrl
  };

  handleImage = () => {
    this.refs.cropper.getCroppedCanvas({
      fillColor: '#fff',
    }).toBlob(function (blob) {
      this.refs.btnUpdateDashImage.disabled = true;

      const formData = new FormData();
      formData.append("file", blob);
      formData.append("origin", "emptyDashImage");
      $('#imgLoading').css('display', 'inline');
      $.ajax({
        url: '/communities/update_avatar/' + this.props.objectId,
        method:'PATCH',
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: obj => {
          this.refs.btnUpdateDashImage.disabled = false;
          console.log(obj.avatar);
          $('#imgLoading').css('display', 'none');
          this.props.handleToggle();

          // this.props.updateImage(obj.avatar);
          this.props.updateEmptyDashboardImage(obj.avatar);
        }
      });

    }.bind(this), 'image/jpeg', 0.5);
  };

}
