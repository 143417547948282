import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dotdotdot from "react-dotdotdot";
import Truncate from "react-truncate";

export default class AEMConfirmation extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    activeTaskId: PropTypes.number,
    activityName: PropTypes.string,
    activityNameToDelete: PropTypes.string,
    updateConditionsDelActivity: PropTypes.func,
    deleteTask: PropTypes.func,
    questionId: PropTypes.number,
    questionName: PropTypes.string,
    deleteQuestion: PropTypes.func,
    delActC1: PropTypes.bool,
    delActC2: PropTypes.bool,
    delTaskC1: PropTypes.bool,
    copyActC1: PropTypes.bool,
    copyActC2: PropTypes.bool,
    copyActivity: PropTypes.func,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {from, activityNameToDelete, activityName, delActC1, delActC2, delTaskC1, copyActC1, copyActC2, language, colors} = this.props;

    const deleteDisabled = !(activityNameToDelete === "DELETE" && delActC1 && delActC2);

    const i18n = {
      en: {
        delete: 'Delete', notUndone: 'This action cannot be undone',
        disclaimer1PartA: 'Some participants may have already responded to this Activity.',
        disclaimer1PartB: ' All data associated to this Activity will be deleted',
        disclaimer1PartC: '(i.e. responses, comments, etc.).',
        disclaimer2: 'All Tasks within this Activity will be deleted.',
        placeholder: "Type 'DELETE' to confirm",
        yes: 'YES', no: 'NO', clone: 'Clone', inheritTitle: 'Inherit Activity title',
        inheritDates: 'Inherit available and expiry dates',
        task: 'Task',
        disclaimer3PartA: 'Some participants may have already responded to this Task. The Task along with ',
        disclaimer3PartB: 'all its associated data',
        disclaimer3PartC: ' (i.e. responses, comments, etc.) will be deleted.',
        cancel: 'Cancel'
      },
      es: {
        delete: 'Eliminar', notUndone: 'Esta acción es irreversible',
        disclaimer1PartA: 'Algunos participantes pueden haber contestado ya esta Actividad.',
        disclaimer1PartB: ' Toda la información asociada a esta Actividad será eliminada',
        disclaimer1PartC: '(i.e. respuestas, comentarios, etc.).',
        disclaimer2: 'Todas las Tareas dentro de esta Actividad serán eliminadas.',
        placeholder: "Escribe 'DELETE' para confirmar",
        yes: 'SÍ', no: 'NO', clone: 'Clonar', inheritTitle: 'Heredar título de Actividad',
        inheritDates: 'Heredar fechas de disponibilidad y expiración',
        task: 'Tarea',
        disclaimer3PartA: 'Algunos participantes pueden haber contestado ya esta Tarea. La Tarea junto con ',
        disclaimer3PartB: 'toda la información asociada',
        disclaimer3PartC: ' (i.e. respuestas, comentarios, etc.) serán eliminadas.',
        cancel: 'Cancelar'
      }
    }

    let body = null;
    let footer = null;
    switch(from){
      case 'Activity':
        body = <div className="modal-body px-modal-body-warning">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
              <div className="px-title-warning-container">
                <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-warning"
                                 style={{ color: colors.color4[3] }}/>
                <p className="px-title-warning" style={{marginBottom: '0'}}>
                  {i18n[language].delete} <b className="warning" style={{ color: colors.color4[3] }}>
                  <Truncate lines={1}>{activityName}</Truncate>?</b>
                </p>
                <label className="px-message-warning">{i18n[language].notUndone}</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
              <div className="px-check-cont px-checkbox-modal-warning">
                <label className="container-check">
                  <input type="checkbox" name="delActC2" onChange={this.props.updateConditionsDelActivity}
                    value={this.props.delActC2} />
                  <span className="checkmark" />
                  <span>{i18n[language].disclaimer2}</span>
                </label>
              </div>
              <div className="px-check-cont px-checkbox-modal-warning">
                <label className="container-check">   
                  <input type="checkbox" name="delActC1" onChange={this.props.updateConditionsDelActivity}
                            value={this.props.delActC1}/>  
                  <span className="checkmark"/>
                  <span>{i18n[language].disclaimer1PartA}<strong>
                    {i18n[language].disclaimer1PartB}</strong>
                    &nbsp;{i18n[language].disclaimer1PartC}</span>
                </label>
              </div>
              <input name="activityNameToDelete" type="text" onChange={this.props.updateConditionsDelActivity}
                     value={this.props.activityNameToDelete} className="form-control px-input-confirmation"
                     placeholder={i18n[language].placeholder} style={{marginTop:'15px'}}/>
            </div>
          </div>
        </div>;
        footer = <div className="modal-footer px-modal-footer-warning">
            <div className="px-footer-container-buttons column">
              <button onClick={() => this.props.deleteTask(this.props.activeTaskId)} type="button"
                className="btn px-btn sm btn-blue-base" disabled={deleteDisabled} style={{ backgroundColor: colors.color4[3] }}>{i18n[language].delete}
              </button>
              <button onClick={() => this.props.updateShowModal(null, null)} type="button"
                className="btn px-btn sm btn-gray-darker inverted">{i18n[language].cancel}
              </button>
            </div>
          </div>;
        break;
      case 'Task':
        body = <div className="modal-body px-modal-body-warning">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-title-warning-container">
                <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-warning" style={{ color: colors.color4[3] }}/>
                <p className="px-title-warning" style={{ marginBottom: '0' }}>{i18n[language].delete} <b className="warning" style={{ color: colors.color4[3] }}>{i18n[language].task}?</b></p>
                  <label className="px-message-warning">{i18n[language].notUndone}</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
              <div className="px-check-cont px-checkbox-modal-warning">
                <label className="container-check">   
                  <input type="checkbox" name="delTaskC1" onChange={this.props.updateConditionsDelActivity}
                          value={delTaskC1}/>
                  <span className="checkmark"/>
                  <span>{i18n[language].disclaimer3PartA}<strong>
                    {i18n[language].disclaimer3PartB}</strong>{i18n[language].disclaimer3PartC}</span>
                </label>
              </div>
            </div>
          </div>
        </div>;
        footer = <div className="modal-footer px-modal-footer-warning">
          <div className="px-footer-container-buttons column">
            <button type="button" onClick={() => this.props.deleteQuestion(this.props.questionId)}
              className="btn px-btn sm btn-blue-base" disabled={!delTaskC1} style={{ backgroundColor: colors.color1[3] }}>{i18n[language].yes}
            </button>
            <button onClick={() => this.props.updateShowModal(null, null)} type="button"
                className="btn px-btn sm btn-gray-darker inverted">{i18n[language].no}
            </button>
          </div>
        </div>;
        break;
      case 'Copy Activity':
        body = <div className="modal-body px-modal-body-warning">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-title-warning-container">
                <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-warning" style={{ color: colors.color4[3] }}/>
                <p className="px-title-warning">
                  {i18n[language].clone} <b className="warning" style={{ color: colors.color4[3] }}><Truncate lines={1}>{activityName}</Truncate>?</b>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
              <div className="px-check-cont px-checkbox-modal-warning">
                <label className="container-check">   
                  <input type="checkbox" name="copyActC1" onChange={this.props.updateConditionsDelActivity}
                          checked={copyActC1}/>
                  <span className="checkmark"/>
                  <span>{i18n[language].inheritTitle}</span>
                </label>
              </div>
              <div className="px-check-cont px-checkbox-modal-warning">
                <label className="container-check">   
                  <input type="checkbox" name="copyActC2" onChange={this.props.updateConditionsDelActivity}
                          checked={copyActC2}/>
                  <span className="checkmark"/>
                  <span>{i18n[language].inheritDates}</span>
                </label>
              </div>
            </div>
          </div>
        </div>;
        footer = 
          <div className="modal-footer px-modal-footer-warning">
            <div className="px-footer-container-buttons column">
              <button type="button" onClick={() => this.props.copyActivity(copyActC1, copyActC2)}
              className="btn px-btn sm btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{i18n[language].clone}
              </button>
              <button onClick={() => this.props.updateShowModal(null, null)} type="button"
                className="btn px-btn sm btn-gray-darker inverted">{i18n[language].cancel}
              </button>
            </div>
        </div>;
        break;
    }

    return (
      <div className="modal-content">
        <div className="modal-header modal-delete-activity">
          <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                  onClick={() => this.props.updateShowModal(null, null)}>
            <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
          </button>
        </div>
        { body }
        { footer }
      </div>
    );
  }
}