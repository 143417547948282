import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import axios from "axios"

import {taskI18n} from "../../../../UI/Globals/PX_i18n"
import AVTMChPanel from "./AVTMChPanel"
import TRMicroChat from "../../../Transcript/T_Response/TRMicroChat"
import {getCSRF, showSuccessMsg} from "../../../../UI/Globals/PX_Funs"
import {useMediaQuery} from "react-responsive/src"
import Comments from "../../../../../containers/Community/Comments"
import ReactModal from "react-modal";
import AVTModal from "./AVTModal";

const AVTMicroChat = ({ question, cardinal, communityId, userId, language, cmntyLanguage, participants_view, response,
                        saveResponse, questionsLength, updateShowBlocker, onDrop, updateShowModal, blockerExpired,
                        updateShowComments, updateResponseComments, taskSocialStatus, scrollFirstTime, updateScrollFirstTime,
                        scroll, setActiveTaskId, type, item_scroll, from, isSavingResponse, updateIsSavingResponse, colors,
                        helpStores, updateHelpStore, updateBackdrop, backdropVisibility, comunitySocial, formatter,
                        updateResponseChat, username, avatar, activeMicroId, updateActiveMicroId, updateFailedChat,
                        base_url_ai, cmntyAutoModeration, aiComment, qc, naturalUsername, aiAgentName, company }) => {

  const [showSaveHelpPopup, setShowSaveHelpPopup] = useState(false)
  const [timerOn, setTimerOn] = useState(false)
  const [intervalId, setIntervalId] = useState(0)
  const [query, setQuery] = useState('')
  const [parentChatId, setParentChatId] = useState(null)
  const [isRefreshBtn, setIsRefreshBtn] = useState(false)
  const [isChatModalOpen, setIsChatModalOpen] = useState(false)
  const [validationResults, setValidationResults] = useState([])
  const [showModalValidation, setShowModalValidation] = useState(false)
  const [counter, setCounter] = useState(0)

  const isSmallScreen = useMediaQuery({ maxDeviceWidth: 480 })
  const I18n = {
    en: {
      msgPlaceholder: 'What can I do for you?...',
      msgPlaceholderMicroChat: 'Send a message.',
      msgPlaceholderTimerOn: 'Please wait...',
      noAiChatPlaceholder: 'Select a Chat Room before submitting your request',
      noAiChatWarning: 'Select or create a chat room on your left',
      cantSendMessage: 'Cannot submit query right now',
      startChat: "Tap to chat!",
      continueChat: "Continue chatting",
      continue: 'Continue...',
      expiredChat: "Chat expired",
      errorInit: 'Something went wrong, please refresh the page',
      error: 'Something went wrong, please try again',
      taskCompleted: 'You have completed this task',
      taskExpired: 'This task has expired and you can\'t edit it anymore',
      comments: 'Comments',
      endChat: 'End chat',
      refresh: 'Oops: Click here',
      savingResponse: 'Wait, saving responses'
    },
    es: {
      msgPlaceholder: 'Qué puedo hacer por ti?...',
      msgPlaceholderMicroChat: 'Manda un mensaje.',
      msgPlaceholderTimerOn: 'Por favor espera...',
      noAiChatPlaceholder: 'Selecciona un Chat antes de enviar tu solicitud',
      noAiChatWarning: 'Selecciona o crea una sala de chat a su izquierda',
      cantSendMessage: 'No se puede enviar la solicitud en este momento',
      startChat: 'Clic para chatear!',
      continueChat: "Continúa chateando",
      continue: 'Continúa...',
      expiredChat: "Chat ha expirado",
      errorInit: 'Algo salió mal, por favor refresca la pantalla',
      error: 'Algo salió mal, por favor intenta de nuevo',
      taskCompleted: 'Has completado esta tarea',
      taskExpired: 'Esta tarea ha expirado y no se puede editar',
      comments: 'Comentarios',
      endChat: 'Terminar chat',
      refresh: 'Oops, click aquí',
      savingResponse: 'Espera, guardando respuestas'
    }
  }

  useEffect(() => {
    if (isChatModalOpen && !(response && response.response) && isSmallScreen) {
      startMicroChat();
    }
  }, [isChatModalOpen, isSmallScreen, response]);
  useEffect(() => {
    if(response){
      if(response.id !== null){
        if(response.response.chats.length === 0){
          // console.log('create INIT AI chat')
          const doublePrompt = _createInitPrompt()
          // console.log('doublePrompt:', doublePrompt)
          const data = { body: doublePrompt.system, model: doublePrompt.assistant, community_id: communityId, kind: 'AI',
            temperature: 0.7, response_id: response.id, state_1: 'init' }
          axios.post('/ai/save_chat/first', data, getCSRF())
            .then(r => {
              const last_chat = r.data.chat
              // console.log('last_chat:', last_chat)
              getAnswerMicro('', true, response.id, 'AI', last_chat.id)
            })
        }
      }
    }
  }, [response])
  useEffect(() => {
    if(timerOn){
      const newIntervalId = setInterval(() => {
        getLastResponse()
      }, 2000)
      // console.log('newIntervalId:', newIntervalId)
      setIntervalId(newIntervalId)

      /*const timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1)
      }, 1000)
      setIntervalTimerId(timer)*/
    } else {
      //setSeconds(0)

      if(intervalId){
        // console.log('clear interval intervalId:', intervalId)
        clearInterval(intervalId)
        setIntervalId(0)
      }
      /*if(intervalTimerId){
        clearInterval(intervalTimerId)
        setIntervalTimerId(0)
      }*/
    }
    return () => {
      clearInterval(intervalId)
      //clearInterval(intervalTimerId)
    }
  }, [timerOn])

  const startMicroChat = () => {
    if(response){
      if(response.id === null){
        saveResponse('Draft', question.id, userId, cardinal, from, '', '')
      }
    }
  }

  const closeChatModal = () => {
    setIsChatModalOpen(false);
  }

  const getLastResponse = () => {
    setCounter(prev => prev + 1)
    axios.get('/ai/get_last_chat/' + response.id)
      .then(r => {
        const last_chat = r.data.chat;
        // console.log('last_chat:', last_chat);

        if(last_chat){
          setTimerOn(false)
          if(last_chat.state_1 === 'enabled'){
            updateResponseChat(response.id, last_chat)
          } else {
            // console.log('response.response.chats:', response.response.chats)
            if(response.response.chats.length === 0){
              showSuccessMsg(I18n[language].errorInit)
              setIsRefreshBtn(true)
            } else {
              updateFailedChat(response.id, parentChatId)
              showSuccessMsg(I18n[language].error)
            }
          }
        }
      }).catch(e => console.log(e))
  }

  const getAnswerMicro = (prompt, isInit, response_id, kind, parent_chat_id) => {
    // console.log(isInit, response_id, kind, userId, communityId, parent_chat_id)
    if(isInit){
      getCompletion(isInit, prompt, response_id, 'AI', parent_chat_id)
    } else {
      if(prompt !== ''){
        const parent_chat = response.response.chats.find(chat => parent_chat_id === chat.id)
        // console.log('parent_chat:', parent_chat)
        if(parent_chat){
          if(parent_chat.state_1 === 'failed'){
            getCompletion(isInit, prompt, response_id, username, parent_chat_id)
          } else {
            saveChatDBAndAnswer(prompt, response_id, isInit, parent_chat)
          }
        } else {
          saveChatDBAndAnswer(prompt, response_id, isInit, parent_chat)
        }
      }
    }
  }

  const saveChatDBAndAnswer = (prompt, response_id, isInit, parent_chat) => {
    // console.log('save prompt to DB')
    const data = { body: prompt, community_id: communityId, kind: username, temperature: 0.7,
      response_id, is_init: isInit, state_1: 'enabled' }
    axios.post('/ai/save_chat/common', data, getCSRF())
      .then(r => {
        const last_chat = r.data.chat
        // console.log('last_chat:', last_chat)
        setQuery('')
        saveEmbedding(parent_chat.id, last_chat.id)
        updateResponseChat(response_id, last_chat)
        setParentChatId(last_chat.id)
        getCompletion(isInit, prompt, response_id, username, last_chat.id)
      })
  }

  const saveEmbedding = (parent_chat_id, chat_id) => {
    const url = `${base_url_ai}micro_chat/create_embedding/${chat_id}`
    // console.log('url:', url)
    const data = {
      parent_chat_id, chat_id, community_id: communityId, user_id: userId, task_id: question.id,
      activity_id: question.task_id, language
    }

    axios.post(url, data, getCSRF())
      .then(r => {
        const obj = r.data
        // console.log('obj:', obj)
      })
      .catch(e => console.log(e))
  }

  const getCompletion = (isInit, prompt, response_id, kind, parent_chat_id) => {
    // console.log('parent_chat_id:', parent_chat_id, 'isInit:', isInit)
    const data = { prompt, model: 'gpt-4o', user_id: userId, community_id: communityId, kind, temperature: 0.7,
      response_id, parent_chat_id, is_init: isInit }
    axios.post('/ai/get_answer_micro_chat/', data, getCSRF())
      .then(r => {
        // console.log('answerRails:', r.data)
        setTimerOn(true)
      }).catch(e => console.log(e))
  }

  const validationCompleted = () => {
    updateBackdrop(false)
    if(question.required){
      if(from !== 'AB Preview'){
        let results = [];
        // console.log('question.required_options:', question.required_options);
        question.required_options.forEach(option => {
          results.push(_getValidation(option))
        });
        // console.log('results:', results)
        const resultValues = results.map(result => result.value)
        // console.log('resultValues:', resultValues)
        setValidationResults(resultValues)

        if([...new Set(resultValues)].length === 1) {
          if([...new Set(resultValues)][0]) {
            saveResponse('Completed', question.id, userId, cardinal)
          } else {
            updateShowModalValidation()
          }
        } else {
          updateShowModalValidation()
        }
      }
    } else {
      if(from !== 'AB Preview'){
        saveResponse('Completed', question.id, userId, cardinal)
      }
    }
    if (isChatModalOpen) {
      closeChatModal();
    }
  }

  const _getValidation = type => {
    let result

    switch(type){
      case 'Text':
        const chats = response.response.chats.filter(m => m.role === 'user')
        // console.log('chats.role = user', chats)
        result = { name: 'Text', value: chats ? chats.length > 2 : false }
        break
    }

    return result
  }

  let responseButtons = null
  responseButtons = <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-buttons-response btn-end-chat">
    <button onClick={from !== 'AB Preview' ? validationCompleted : () => {}} className="btn px-btn sm btn-turquoise-base"
            style={showSaveHelpPopup === true ?
                { backgroundColor: colors.color2[3], position: 'absolute', zIndex: '1019' } : { backgroundColor: colors.color2[3] } }
            disabled={isSavingResponse}>
      {!isSavingResponse && I18n[language].endChat}
      {!isSavingResponse ? cardinal < questionsLength &&
        <FontAwesomeIcon icon={['fas', 'circle-chevron-right']} style={{marginLeft: '5px'}}/> :
        <span style={{color: '#000000'}}>{I18n[language].savingResponse}
          <FontAwesomeIcon icon={['fas', 'spinner']} spin style={{marginLeft: '5px'}}/>
        </span>
    }
    <div className="ripple-container"/>
  </button>
  </div>;
  if(response && response.response) {
    if((response.response.state === 'Completed' || response.response.state === 'Accepted') && response.showBlocker) {
      responseButtons = <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mg-top-15">
        <button onClick={() => handleEditClick()}
                className="btn px-btn sm btn-blue-base" style={{ width: '120px', backgroundColor: colors.color1[3] }}>
          {taskI18n[language].edit_btn}
          <div className="ripple-container" />
        </button>
      </div>;
    }
  }

  const _createInitPrompt = () => {
    let secondary = ''
    let prompt_username = naturalUsername ? naturalUsername : username
    let question_text = question?.title
    let specified_language = question.data.language
    let language_prompt = specified_language ? `Assistant will always respond in ${specified_language}` :
      'Assistant will always respond in the language the user is querying in unless instructed otherwise.'

    question.data.secondary_objectives.forEach(s  => secondary += '- ' + s.text + '\n')
    const systemPrompt = 'Description of the assistant: """\n' +
      '\n' +
      'Assistant is an excellent interviewer that mimics and behaves as an assigned Persona while being an expert empathising and ' +
      'obtaining insights that explain the reasons behind his or her interviewee\'s beliefs, attitudes and behaviours.\n' +
      '\n' +
      '"""' +
      'Persona of the assistant: """\n' +
      '\nAssistant will act as ' +
      question.data.role +
      '\n' +
      '"""' +
      'Conversational style of the assistant: """\n' +
      '\n' +
      'Assistant should mimic the communication style of a '+ question.data.conversation_style +'. \n' +
      '\n' +
      '"""' +
      '"""\n' +
      '\n' +
      'Language of the assistant: """\n' +
      '\n' +
      language_prompt +
        +'\n' +
      '\n' +
      '"""' +
      'Task of the assistant: """\n' +
      '\n' +
      'Assistant will engage in a conversation with a User with the goal of exploring and understanding one main objective and a number of secondary objectives.\n' +
      '\n' +
      '"""' +
      'Instructions for the assistant: """\n' +
      '\n' +
      '-Will begin the chat by greeting the User, asking a first question and then staying idle. The question should invite the User to share their thoughts or experiences in detail.\n' +
      '\n' +
      '-Will ask about only one topic, one aspect, one objective at a time to avoid overwhelming the User.\n' +
      '\n' +
      '-Will strive to understand each topic, aspect or objective in-depth before moving on to explore the next one. To achieve this understanding, will ask for examples or experiences to illustrate.\n' +
      '\n' +
      '-In case of vague or unclear responses, will ask follow-up questions to probe deeper and clarify the Users\'s ideas. This could involve asking the User to explain why they feel a certain way, or how a specific experience demonstrates a particular value or belief.\n' +
      '\n' +
      '-Will avoid making assumptions or expressing personal judgements about the topics being discussed. The Assistant\'s role is to understand the Users\'s perspectives, not to influence or shape them.\n' +
      '\n' +
      '-Will express empathy and validation in response to what the Users\'s sharing, making them feel heard and valued.\n' +
      '\n' +
      '-Will reflect back the information shared by the User, indicating its understanding and engagement in the conversation. It will also summarize briefly the main points from the Users\'s responses to ensure its comprehension.\n' +
      '\n' +
      '-Will use open-ended questions as much as possible to invite more detailed and comprehensive responses.\n' +
      '\n' +
      '-Will end the conversation by summarising its learnings, thanking the User, and prompting them to click the \'Done/Terminar\' button below.\n' +
      '\n' +
      '-Complying with all these instructions is crucial to reach an adequate performance of the assistant.\n' +
      '\n' +
      '"""' +
      'Role-play for the assistant: """\n' +
      '\n' +
      'Assistant will always stay in character while chatting, meaning it\'ll always act according to the Persona assigned; it\'ll communicate with the style assigned, and in the language assigned.  \n' +
      '\n' +
      '"""'
    const assistantPrompt = 'Given the Main Objective \'MO\', the Secondary Objectives \'SOs\' and the detailed specifications provided to System, start the interview with '+ prompt_username +'.\n' +
      'MO: """'+ question.data.main_objective +'"""\n' +
      'SOs: """'+ secondary +'"""'

    return { system: systemPrompt, assistant: assistantPrompt }
  }

  const textAreaPlaceHolder =
      from !== 'micro' ?
          (timerOn ? I18n[language].msgPlaceholderTimerOn : I18n[language].msgPlaceholder ) : I18n[language].msgPlaceholderMicroChat

  const handleKeyDown = event => {
    const enterPressed = event.key === 'Enter'
    const ctrlOrCmdPressed = event.ctrlKey || event.metaKey
    const shiftPressed = event.shiftKey
    const altPressed = event.altKey

    if(enterPressed && (ctrlOrCmdPressed || shiftPressed || altPressed)) {
      event.preventDefault();
      setQuery(query + '\n')
    } else if (enterPressed && !ctrlOrCmdPressed && !shiftPressed && !altPressed) {
      event.preventDefault(); // Prevent line breaks when Enter is pressed
      if(!timerOn){
        //getAnswer();
      } else if (timerOn){
        showSuccessMsg(I18n[language].msgPlaceholderTimerOn);
      } else {
        showSuccessMsg(I18n[language].noAiChatPlaceholder);
      }
    }
  }

  const renderChatPanel = () => (
      <AVTMChPanel getResponseEmbeddings={() => {}}
                   colors={colors}
                   setLastChatId={() => {}}
                   currentUserId={userId}
                   lastMsgId={0}
                   messages={response.response.chats ? response.response.chats : []}
                   avatar={avatar}
                   language={language}
                   formatter={formatter}
                   query={query}
                   setQuery={setQuery}
                   textAreaPlaceHolder={textAreaPlaceHolder}
                   handleKeyDown={handleKeyDown}
                   timerOn={timerOn}
                   getAnswerMicro={getAnswerMicro}
                   response={response}
                   username={username}
                   startMicroChat={startMicroChat}
                   from={'micro'}
                   activeMicroId={activeMicroId}
                   updateActiveMicroId={updateActiveMicroId}
                   isSmallScreen={isSmallScreen}
                   aiName={question.data && question.data.name}
                   blockerExpired={blockerExpired}
                   responseButtons={responseButtons}
                   counter={counter}
      />
  );

  const renderCompletedChat = () => (
      <TRMicroChat messages={response.response.chats ? response.response.chats : []}
                   currentUserId={userId}
                   avatar={avatar !== '' ? avatar : '/assets/user.png'}
                   from='participant'
                   blockerExpired={blockerExpired}
                   colors={colors}
                   language={language}/>
  )

  const handleEditClick = () => {
    updateShowBlocker(question.id);
    setIsChatModalOpen(true);
  };

  const handleRefresh = () => {
    location.reload()
  }

  let startChatButton
  let refreshButton
  if(blockerExpired){
    startChatButton = <button className="btn px-btn btn-turquoise-base"
                              style={{backgroundColor: colors.color2[3], fontFamily: 'Sharp Sans ExtraBold', }}>
      <span style={{marginRight: '10px'}}><FontAwesomeIcon className={'fa-rotate-90'}  icon={['fas', 'hand-pointer']}/></span>
      {I18n[language].expiredChat}
    </button>
  } else {
    if(isSmallScreen){
      startChatButton = <button onClick={() => setIsChatModalOpen(true)} className="btn px-btn btn-turquoise-base"
                                 style={{backgroundColor: colors.color2[3], fontFamily: 'Sharp Sans ExtraBold', }}>
        <span style={{marginRight: '10px'}}><FontAwesomeIcon className={'fa-rotate-90'}  icon={['fas', 'hand-pointer']}/></span>
        { response && response.response ? I18n[language].continueChat : I18n[language].startChat }
      </button>
      refreshButton = <button onClick={handleRefresh} className="btn px-btn btn-turquoise-base"
                              style={{backgroundColor: '#e98f20', fontFamily: 'Sharp Sans ExtraBold'}}>
        <span style={{marginRight: '10px'}}><FontAwesomeIcon className={'fa-rotate-90'}  icon={['fas', 'arrows-rotate']}/></span>
        {I18n[language].refresh}
      </button>
    } else {
      startChatButton = <button onClick={from !== 'AB Preview' ? startMicroChat : () => {}} className="btn px-btn btn-turquoise-base"
                                style={{backgroundColor: colors.color2[3], fontFamily: 'Sharp Sans ExtraBold', }}>
        <span style={{marginRight: '10px'}}><FontAwesomeIcon className={'fa-rotate-90'}  icon={['fas', 'hand-pointer']}/></span>
        {response && response.response ? I18n[language].continueChat : I18n[language].startChat}
      </button>
      refreshButton = <button onClick={handleRefresh} className="btn px-btn btn-turquoise-base"
                              style={{backgroundColor: '#e98f20', fontFamily: 'Sharp Sans ExtraBold'}}>
        <span style={{marginRight: '10px'}}><FontAwesomeIcon className={'fa-rotate-90'}  icon={['fas', 'arrows-rotate']}/></span>
        {I18n[language].refresh}
      </button>
    }
  }

  let btnComments
  let commentsComp
  let commentsHelpTooltip = ''

  if(response !== undefined) {
    let sumAllCommentsReplies = 0
    response.comments ? response.comments.forEach(c => {++sumAllCommentsReplies;
      c.replies.forEach(r => {++sumAllCommentsReplies})}) : 0
    btnComments = <button onClick={from !== 'AB Preview' ? () => updateShowComments(question.id) : () => { }}
                          type="button" className="btn btn-comments"
                          style={ response.showComments ?
                            { color: '#ffffff', borderColor: colors.color1[3], backgroundColor: colors.color1[3] } :
                            { color: colors.color1[3], borderColor: colors.color1[3], backgroundColor: '#ffffff' } }>
      <div className="comments-container">
        {response.sum_unseen > 0 && <div className="unseen" style={{backgroundColor: colors.color4[3]}}/>}
        <FontAwesomeIcon icon={['fas', 'message']} className="icon-comments"
                         style={{ color: response.showComments ? '#ffffff' : colors.color1[3]}}/>
        <span className="txt-comment" style={{ marginLeft: '0' }}>{I18n[language].comments}</span>
        <div className="number-container"><span className="number">{sumAllCommentsReplies}</span></div>
      </div>
      <div className="px-support-tooltip top is_hidden px-support-response">
        <p>{commentsHelpTooltip}</p><i />
      </div>
    </button>
    commentsComp = <div className={response.showComments ? 'px-n-accordion-open' : 'px-n-accordion-close'}>
      <Comments comments={response.comments ? response.comments : []}
                language={language}
                userId={userId}
                username={username}
                cmntyLanguage={cmntyLanguage}
                questionId={response.question_id}
                responseId={response.response ? response.response.id : -1}
                role_1={'Participant'}
                updateResponseComments={updateResponseComments}
                from={'PAV'}
                item_scroll={item_scroll}
                updateScrollFirstTime={updateScrollFirstTime}
                scrollFirstTime={scrollFirstTime}
                colors={colors}
                cmntyAutoModeration={cmntyAutoModeration}
                aiComment={aiComment}
                qc={qc}
                aiAgentName={aiAgentName}
                company={company}
      />
    </div>
  }

  const updateShowModalValidation = () => {
    setShowModalValidation(prev => !prev)
  }

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 microchat-main">
      {
        participants_view === 'As a list' &&
        <div className="px-counter-task" style={{ backgroundColor: colors.color0[1] }}>
          <span className="number">{cardinal}</span></div>
      }
      <div id={userId + '_' + question.id} className="panel px-card px-panel-task response">
        <div className="panel-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
              <div dangerouslySetInnerHTML={{ __html: question.title }} className="title" />
            </div>
          </div>
          <div className="row">
              {
                // Code for larger screens
                response && response.showBlocker ?
                    // when Micro Chat has been marked as Done
                    <div>
                      {renderCompletedChat()}
                      <div style={{marginTop: '15px'}}>{ (!blockerExpired && response && response.response) && responseButtons }</div>
                    </div>
                :
                    // when Micro Chat has not been marked as Done
                    (response && response.response) ?
                        // when a chat already has started
                      <div>
                        { isRefreshBtn && refreshButton}
                        {renderChatPanel()}
                        {(!blockerExpired && response && response.response) && responseButtons}
                      </div>
                       :
                        // when there's no chat yet
                      <div style={{textAlign: 'center', marginTop: '15px'}}>
                        {startChatButton}
                      </div>
              }

          </div>
          <div className="separator separator-response" />
          <div className="comments-option-container">
            {btnComments}
          </div>
          { commentsComp }
        </div>
        {
          showModalValidation &&
          <ReactModal isOpen={showModalValidation} contentLabel="Activity Validation Modal"
                      shouldCloseOnOverlayClick={true} onRequestClose={updateShowModalValidation}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <AVTModal updateShowModal={updateShowModalValidation}
                      required_options={question.required_options}
                      minChar={'0'}
                      validationResults={validationResults}
                      type={question.task_type}
                      language={language}
                      mapTitle={question.map_title} />
          </ReactModal>
        }
      </div>
    </div>
  )
}

export default AVTMicroChat
