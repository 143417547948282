import PropTypes from 'prop-types';
import React from 'react';
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {formatExpireDate} from "../../../UI/Globals/PX_Funs";
import ASwimlane from "./Swimlane/ASwimlane";

export default class DACard extends React.Component {
  static propTypes = {
    activity: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    activeUsers: PropTypes.array.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    objSearchAct: PropTypes.object.isRequired,
    clearForwardUrl: PropTypes.func.isRequired,
    updateActivities: PropTypes.func.isRequired,
    activeUsersCount: PropTypes.number.isRequired
  };

  componentDidMount() {
    const {activity} = this.props;

    if(!activity.isCollapsedAct){
      this.props.getResponsesActivity(activity.id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {activity} = this.props;

    if(prevProps.activity.isCollapsedAct !== activity.isCollapsedAct){
      //console.log(this.props.userIsCollapsedPar);
      if(!activity.isCollapsedAct){
        this.props.getResponsesActivity(activity.id);
      }
    }
    
  }

  render() {
    const {activity, language, role_2, colors, activeUsers, gender_as_segment, objSearchAct,
      activeUsersCount} = this.props;

    const i18n = {
      en: { pctOfCompleted: '% of tasks Accepted or Completed', activitiesNA: 'No live activities yet',
            liveTooltip: 'Participants can see this activity', expand: 'Expand panel for details',
            collapse: 'Collapse panel to hide details'},
      es: { pctOfCompleted: '% de tareas Aceptadas o Completadas', activitiesNA: 'No hay actividades publicadas',
            liveTooltip: 'Los participantes pueden ver esta actividad', expand: 'Expanda el panel para más detalles',
            collapse: 'Contraer panel para ocultar detalles'}
    };

    let totalPercentage = 0;
    let percentage = 0;
    let taskSegFlag = true;
    let sumTotal = 0;
    let sumCompleted = 0;
    let sumAccepted = 0;
    const userIds = this._getUsersBySegs(activity, activeUsers, gender_as_segment, objSearchAct);

    const tasks = activity.tasks.map((task, index) => {
      const cardinal = index + 1;

      // TO-DO just active the calculated is the card is open
      //const responses = activeUsersCount !== 0 && !activity.isCollapsedAct ? this._calculatedResponsesTask(task, activeUsersCount) : [];
      const responses = activeUsersCount !== 0 ? this._calculatedResponsesTask(task, activity.segments, gender_as_segment) : [];
      //console.log(activeUsersCount !== 0 && responses);

      responses.forEach(obj => {
        if(obj.acceptedUserIds.length === 0 && obj.blankUserIds.length === 0 && obj.completedUserIds.length === 0
          && obj.draftUserIds.length === 0){
          taskSegFlag = false
        }
      });

      const swimLane = responses.map((response, index) => {
        //console.log(response.completed);
        sumTotal = sumTotal + response.total;
        sumCompleted = sumCompleted + response.completed;
        sumAccepted = sumAccepted + response.accepted;

        if(task.id === response.task_id){
          const total = response.total;
          const pAccepted = response.accepted * 100 / parseInt(total);
          const pCompleted = response.completed * 100 / parseInt(total);
          const pDrafted = response.draft * 100 / parseInt(total);
          const pBlank = response.blank * 100 / parseInt(total);
          if(activity.id === 179){
            //console.log(pAccepted, pCompleted, pDrafted, pBlank, pAccepted + pCompleted + pDrafted + pBlank);
          }

          return (
            <div key={index} className="px-progress progress-line-danger">
              <ASwimlane community_id={activity.community_id} userIds={response.acceptedUserIds} color={colors.colorStates[0]}
                         activityId={activity.id} taskId={task.id} counter={response.accepted}
                         total={total} clearForwardUrl={this.props.clearForwardUrl} cssClass={'accept'}/>
              <ASwimlane community_id={activity.community_id} userIds={response.completedUserIds} color={colors.colorStates[1]}
                         activityId={activity.id} taskId={task.id} counter={response.completed}
                         total={total} clearForwardUrl={this.props.clearForwardUrl} cssClass={'accept'}/>
              <ASwimlane community_id={activity.community_id} userIds={response.draftUserIds}
                         activityId={activity.id} taskId={task.id} counter={response.draft}
                         total={total} clearForwardUrl={this.props.clearForwardUrl} cssClass={'draft'}/>
              <ASwimlane community_id={activity.community_id} userIds={response.blankUserIds}
                         activityId={activity.id} taskId={task.id} counter={response.blank}
                         total={total} clearForwardUrl={this.props.clearForwardUrl} cssClass={'blank'}/>
            </div>
          )
        }
      });

      const totalAcceptedCompleted = sumCompleted + sumAccepted;
      //console.log('sumCompleted:', sumCompleted, 'sumAccepted:', sumAccepted, 'sumTotal:', sumTotal);
      percentage = totalAcceptedCompleted !== 0 ? (totalAcceptedCompleted * 100) / sumTotal : 0;
      const urlTask = '/communities/'+ activity.community_id +'/transcript/'+ userIds  +'/'+ activity.id +'/'+ task.id
        +'/Activities';
      totalPercentage = activity.sum_res_accepted_completed !== 0 ?
        (activity.sum_res_accepted_completed * 100) / (activeUsers.length * activity.tasks.length) : 0;
      //console.log('totalPercentage:', totalPercentage);

      return(
        <div key={index} className="row px-item-activity-container mg-bottom-10">
          <div className="px-item-activity">
            <div className="px-activity-serial">
              <span>{cardinal}</span>
            </div>
            <div className="px-activity-details-container">
              <a onClick={(event) => this.props.clearForwardUrl(event, 'common', urlTask)}
                 className="px-instructions" style={{cursor:'pointer'}}>
                {task.title.replace(/(<([^>]+)>)/ig,"")}
              </a>
              { swimLane }
            </div>
          </div>
        </div>
      )
    });

    const urlActivity = '/communities/'+ activity.community_id +'/transcript/'+ userIds +'/'+ activity.id +'/all/Activities';
    const aTitle = <div className="px-title-container">
      <div className="px-container-percentage" data-tooltip-content={i18n[language].pctOfCompleted} style={{width:'80px'}}>
        <CircularProgressbarWithChildren value={totalPercentage}
                                         text={totalPercentage !== 100 &&`${totalPercentage.toFixed(0)}  %`}
                                         background={totalPercentage === 100}
                                         styles={buildStyles({strokeLinecap: 'butt',
          pathColor: colors.color0[0], trailColor: '#ecedf0', textColor: '#262E37'})}>
          {
            totalPercentage === 100 &&
            <span className={'mg-cust'} style={{color: '#ffffff', fontSize: '20px'}}>
              <FontAwesomeIcon icon={['far','check']}/>
            </span>
          }
        </CircularProgressbarWithChildren>
      </div>
      <div className="px-title-right-container">
        {
          role_2 === "Recruiter" || role_2 === "" ?
            <span className="activity-title" style={{ cursor: "default" }}>{activity.title}</span> :
            <a onClick={(event) => this.props.clearForwardUrl(event, 'common', urlActivity)}
               style={{cursor:'pointer'}}>
              <span className="activity-title">{activity.title}</span>
            </a>
        }
        <div className="px-icons-container activities">
          {
            activity.visibility === 'enabled' &&
            <span className='live-tag' style={{color: colors.color4[3], marginRight: '5px'}} data-tooltip-content={i18n[language].liveTooltip}>
             LIVE
            </span>
          }
          {
            activity.tag &&
            <FontAwesomeIcon icon={['fas','tag']} className="tag" style={{color: activity.tag.color}}
                             data-tooltip-content={activity.tag.name}/>
          }
        </div>
        <div className="px-container-deadline">
          <span className="deadline">{formatExpireDate(activity.expire, language)}</span>
          {
            role_2 === "Recruiter" || role_2 === "" ? "" :
              <FontAwesomeIcon icon={activity.isCollapsedAct ? ['fas','up-right-and-down-left-from-center'] : ['fas','down-left-and-up-right-to-center']}
                               className={'icon-collapse collapse-panel'}
                               data-tooltip-content={activity.isCollapsedAct ? i18n[language].expand : i18n[language].collapse}
                               onClick={() => this.props.updateActivities(activity.id, 'isCollapsedAct', !activity.isCollapsedAct)}
              />
          }
        </div>
      </div>
      {
        activity.tag &&
        <FontAwesomeIcon icon={['fas','tag']} className="px-tag icon-responsive top" style={{color: activity.tag.color}}
                         data-tooltip-content={activity.tag.name} />
      }
      <FontAwesomeIcon icon={activity.isCollapsedAct ? ['fas','up-right-and-down-left-from-center'] : ['fas','down-left-and-up-right-to-center']}
                       className={'icon-responsive bottom activities icon-collapse collapse-panel'}
                       data-tooltip-content={activity.isCollapsedAct ? i18n[language].expand : i18n[language].collapse}
                       onClick={() => this.props.updateActivities(activity.id, 'isCollapsedAct', !activity.isCollapsedAct)}
      />
    </div>;

    if(taskSegFlag){
      return(
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className=" px-panel-activities mg-bottom-15">
              <div className="panel-heading panel-title activities">
                { aTitle }
              </div>
              <div className={`panel-body ${activity.isCollapsedAct && 'collapse'}`}>
                { !activity.isCollapsedAct && tasks }
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  // TO-DO it's called every time a render is executed, try to move to component did mount or component did updated
  _calculatedResponsesTask(task, segments, gender_as_segment){
    const {objSearchAct} = this.props;
    //console.log(task, segments);
    const responsesPerTask = [];
    let accepted = 0;
    let completed = 0;
    let draft = 0;
    let blank = 0;  // Blank is not a state, it's a sum with Empty state to show the swim lane

    const acceptedUserIds = [];
    const completedUserIds = [];
    const draftUserIds = [];
    const blankUserIds = [];
    //console.log(this.props.objSearchAct);
    task.responses.map(response => {
      //console.log(response);
      this.props.activeUsers.forEach(user => {
        const found = objSearchAct.idSegs.length !== 0 ? objSearchAct.idSegs.some(s => user.segments.map(us => us.id).includes(s)) : true;
        //console.log(objSearchAct.idSegs, user.segments.map(us => us.id), found);

        if(found){
          const isFoundIdPar = this.props.objSearchAct.idPars.find(idPar => idPar === user.id);
          //console.log(isFoundIdPar);
          if(isFoundIdPar !== undefined || this.props.objSearchAct.idPars.length === 0){
            if(response.user_id === user.id){
              if(segments.length !== 0){
                let segsFlag = user.segments.find(userSeg => segments.find(as => as.id === userSeg.id)) !== undefined;

                if(gender_as_segment && !segsFlag){
                  //TO-DO In back server, the value of community segment 'Other Gender' must be just 'Other' as DB
                  const activitySegs = segments.map(s => s.id === 'Other Gender' ? {...s, id: 'Other'} : s);
                  segsFlag = activitySegs.find(as => as.id === user.gender) !== undefined;
                }

                if(segsFlag){
                  switch(response.state){
                    case 'Accepted':
                      accepted = accepted + 1;
                      acceptedUserIds.push(response.user_id);
                      break;
                    case 'Completed':
                      completed = completed + 1;
                      completedUserIds.push(response.user_id);
                      break;
                    case 'Draft':
                      draft = draft + 1;
                      draftUserIds.push(response.user_id);
                      break;
                    case 'Blank':
                      //Blank means no response in DB
                      blank = blank + 1;
                      blankUserIds.push(response.user_id);
                      break;
                    case 'Empty':
                      //Empty means an Open End response in DB without text, it can have pics, moods or video.
                      blank = blank + 1;
                      blankUserIds.push(response.user_id);
                      break;
                  }
                }

              } else {
                switch(response.state){
                  case 'Accepted':
                    accepted = accepted + 1;
                    acceptedUserIds.push(response.user_id);
                    break;
                  case 'Completed':
                    completed = completed + 1;
                    completedUserIds.push(response.user_id);
                    break;
                  case 'Draft':
                    draft = draft + 1;
                    draftUserIds.push(response.user_id);
                    break;
                  case 'Blank':
                    //Blank means no response in DB
                    blank = blank + 1;
                    blankUserIds.push(response.user_id);
                    break;
                  case 'Empty':
                    //Empty means an Open End response in DB without text, it can have pics, moods or video.
                    blank = blank + 1;
                    blankUserIds.push(response.user_id);
                    break;
                }
              }
            }
          }
        }
      });
    });

    const obj = {
      task_id: task.id,
      accepted: accepted,
      completed: completed,
      draft: draft,
      blank: blank,
      total: accepted + completed + draft + blank,
      acceptedUserIds, completedUserIds, draftUserIds, blankUserIds
    };
    //console.log(obj);
    responsesPerTask.push(obj);

    return responsesPerTask;
  }

  _setSwimLaneURL(communityId, usersId, activityId, taskId){
    const urlSwim = '/communities/'+ communityId +'/transcript/'+ usersId +'/'+ activityId +'/'+ taskId +'/Activities';
    this.props.clearForwardUrl(null, 'common', urlSwim);
    //return '/communities/'+ communityId +'/transcript/'+ usersId +'/'+ activityId +'/'+ taskId +'/Activities'
  }

  _getUsersBySegs(activity, users, gender_as_segment, objSearchAct){
    //console.log('gender_as_segment:', gender_as_segment, 'objSearchAct:', objSearchAct);
    let userIds = [];

    if(activity.segments.length !== 0){
      console.log('activity:', activity);
      // REVIEW MIX SEGMENTS AND GENDERS!!!
      activity.segments.forEach(actSegment => {
        users.forEach(user => {
          if(gender_as_segment){
            if(actSegment.id === 'Other Gender'){
              if('Other' === user.gender){
                userIds.push(user.id);
              }
            } else {
              if(actSegment.id === user.gender){
                userIds.push(user.id);
              }
            }
          }
          const found = objSearchAct.idSegs.length !== 0 ?
            objSearchAct.idSegs.some(s => user.segments.map(us => us.id).includes(s)) : true;
          console.log(found);

          if(found){
            user.segments.forEach(userSeg => {
              console.log('actSegment:', actSegment, 'userSeg:', userSeg);
              if(actSegment.id === userSeg.id){
                userIds.push(user.id);
              }

              if(gender_as_segment){
                if(actSegment.id === 'Other Gender'){
                  if('Other' === user.gender){
                    userIds.push(user.id);
                  }
                } else {
                  if(actSegment.id === user.gender){
                    userIds.push(user.id);
                  }
                }
              }
            });
          }
        });
      });
    } else {
      if(objSearchAct.idSegs.length === 0){
        userIds = objSearchAct.idPars.length !== 0 ? objSearchAct.idPars : 'all';
        //console.log('userIds:', userIds);
      } else {
        users.forEach(user => {
          const found = objSearchAct.idSegs.some(s => user.segments.map(us => us.id).includes(s));
          //console.log(found);

          if(found){
            userIds.push(user.id);
          }

          objSearchAct.idSegs.forEach(idSeg => {
            //console.log('idSeg:', idSeg);
            if(gender_as_segment){
              if(idSeg === 'Other Gender'){
                if('Other' === user.gender){
                  userIds.push(user.id);
                }
              } else {
                if(idSeg === user.gender){
                  userIds.push(user.id);
                }
              }
            }
          })
        });

        // REVIEW MIX SEGMENTS AND GENDERS!!!
        /*activity.segments.forEach(actSegment => {
          users.forEach(user => {
            const found = objSearchAct.idSegs.length !== 0 ? objSearchAct.idSegs.some(s => user.segments.map(us => us.id).includes(s)) : true;
            console.log(found);

            if(found){
              user.segments.forEach(userSeg => {
                if(actSegment.id === userSeg.id){
                  userIds.push(user.id);
                }

                if(gender_as_segment){
                  if(actSegment.id === 'Other Gender'){
                    if('Other' === user.gender){
                      userIds.push(user.id);
                    }
                  } else {
                    if(actSegment.id === user.gender){
                      userIds.push(user.id);
                    }
                  }
                }
              });
            }
          });
        });*/
      }
      //userIds = objSearchAct.idPars.length !== 0 ? objSearchAct.idPars : 'all';
      //userIds = 'all';
    }
    //console.log('userIds:', userIds);
    return userIds === 'all' ? userIds : [...new Set(userIds)];
  }
}
