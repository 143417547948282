import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaQuery from "react-responsive";
import R568Search from "../../Responsive/568px/R568Search";
import ReactModal from "react-modal";
import { Tooltip as ReactTooltip } from "react-tooltip"

export default class ActivityFilter extends React.Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    searchTask: PropTypes.func.isRequired,
    sort_coll: PropTypes.string.isRequired,
    sort_part: PropTypes.string.isRequired,
    handleChangeFilters: PropTypes.func.isRequired,
    isFilterLock: PropTypes.bool,
    handleIsFilterLock: PropTypes.func,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showModalMobile: false
    }
  }

  render() {
    const {language, isFilterLock, colors} = this.props;

    const i18n = {
      en: {
        search: 'Search', sortBy: 'Activities sorted by', showBy: 'Show to participants by', sortByShort: 'Sort by', showByShort: 'Show by',
          custom: 'your order', endDate: 'expiry date', startDate: 'available date', creationDate: 'creation date', title: 'title',
          lockTooltip: 'When locked, you and participants see activities in the same order'
    },
      es: { search: 'Buscar', sortBy: 'Ordernar actividades por', showBy: 'Mostrar a los participantes por', sortByShort: 'Ordenar por', showByShort: 'Mostrar por',
        custom: 'tu orden',endDate: 'expiración', startDate: 'disponibilidad', creationDate: 'fecha de creación', title: 'título',
        lockTooltip: 'Al bloquearlo, tú y tus participantes verán las actividades en el mismo orden'
    }
    };

    return (
      <div className="row">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="col-md-12" >
          <div className="px-search-bar-dash-comm-cont actv-builder">
            <div className="px-input-icon actv-builder">
              <input name="q" type="text" onChange={this.props.handleChangeFilters} onKeyDown={this.keyPress}
                     className="form-control icon-right" placeholder={i18n[language].search}/>
             <FontAwesomeIcon icon={['fas', 'magnifying-glass']} onClick={this.props.searchTask} className="px-icon-right color-gray-base"/>
            </div>
            <div>
              <FontAwesomeIcon onClick={this.updateShowModalMobile} icon={['fas', 'magnifying-glass']}
                               className="px-icon-search-responsive actv-builder"/>
              {
                this.state.showModalMobile &&
                <ReactModal isOpen={this.state.showModalMobile} contentLabel="Modal Search"
                            shouldCloseOnOverlayClick={true} className="my-modal-content" overlayClassName="my-modal-overlay"
                            onRequestClose={this.updateShowModalMobile}>
                  <R568Search from={'Activity Builder'}
                              updateShowModalMobile={this.updateShowModalMobile}
                              handleChangeFilters={this.props.handleChangeFilters}
                              searchTask={this.props.searchTask}
                              sort_coll={this.props.sort_coll}
                              handleIsFilterLock={this.props.handleIsFilterLock}
                              isFilterLock={isFilterLock}
                              sort_part={this.props.sort_part}
                              language={language}
                              colors={colors}/>
                </ReactModal>
              }
            </div>

            <div className="px-filter-right-container actv-builder">
              <span className="label-xs">{i18n[language].sortBy}</span>
              <span className="label-xs responsive">{i18n[language].sortByShort}</span>
              <div className="px-select">
                <select name="sort_coll" onChange={this.props.handleChangeFilters} value={this.props.sort_coll}>
                  <option value="custom">{i18n[language].custom}</option>
                  <option value="end date">{i18n[language].endDate}</option>
                  <option value="start date">{i18n[language].startDate}</option>
                  <option value="creation date">{i18n[language].creationDate}</option>
                  <option value="tag">tag</option>
                  <option value="title">{i18n[language].title}</option>
                </select>
              </div>

              <button onClick={this.props.handleIsFilterLock}
                className={`mg-left-right-10 btn-lock ${isFilterLock ? 'color-on' : 'color-off'}`} data-tooltip-content={i18n[language].lockTooltip}>
                <span className="arrow-left" />
                <FontAwesomeIcon icon={['fas', isFilterLock ? 'lock' : 'unlock']} className="chevron"/>
                <span className="arrow-right" />
                {/* <div className="px-support-tooltip top is_hidden px-support-builder">
                  <p>Here you can set the order in which you and participants see activities.</p>
                  <p>When locked you and the participants will see activities in the same order.</p>
                  <i/>
                </div> */}
              </button>

              <span className="label-xs">{i18n[language].showBy}</span>
              <span className="label-xs responsive">{i18n[language].showByShort}</span>
              <div className="px-select">
                <select name="sort_part" onChange={this.props.handleChangeFilters} value={this.props.sort_part}>
                  <option value="custom">{i18n[language].custom}</option>
                  <option value="end date">{i18n[language].endDate}</option>
                  <option value="start date">{i18n[language].startDate}</option>
                  <option value="creation date">{i18n[language].creationDate}</option>
                  <option value="tag">tag</option>
                  <option value="title">{i18n[language].title}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  keyPress = (event) => {
    if(event.keyCode === 13){
      this.props.searchTask();
    }
  };

  updateShowModalMobile = () => {
    this.setState(prevState => ({showModalMobile: !prevState.showModalMobile}));
  };
}
