import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortableHandle } from "react-sortable-hoc";

export default function MicroChatObjective(props) {
  const { questionId, objective, updateObjective, deleteObjective, updateShowModal, language } = props;
  const [text, setText] = useState(objective.text);

  useEffect(() => {
    setText(objective.text);
  }, [objective.text]);

  const onBlur = () => {
    //console.log('onBlur');
    console.log(text, questionId, 'text', objective.id);
    updateObjective(text, questionId, 'text', objective.id, 'secondary_objectives');
  };

  const i18n = {
    'en': {
      'secondaryObjectivePlaceholder': 'Type secondary objective here'
    },
    'es': {
      'secondaryObjectivePlaceholder': 'Escribe objetivo secundario aquí'
    }
  }

  return (
      <div className="px-answer-actv-builder">
        <DragHandle />
        <input onChange={(event) => setText(event.target.value)} value={text} type="text"
               className="input-answer-w100" placeholder={i18n[language].secondaryObjectivePlaceholder}
               onBlur={onBlur} style={{height: '30px', marginLeft: '15px'}}/>
        <FontAwesomeIcon onClick={() => deleteObjective(questionId, objective.id)} icon={['fas', 'circle-xmark']}
                         className="remove-icon" style={{ cursor: 'pointer' }}/>
      </div>
  );
}

MicroChatObjective.propTypes = {
  questionId: PropTypes.number.isRequired,
  objective: PropTypes.object.isRequired,
  updateObjective: PropTypes.func.isRequired,
  deleteObjective: PropTypes.func.isRequired,
  updateShowModal: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired
};

const DragHandle = SortableHandle(() => (
    <FontAwesomeIcon icon={['far', 'bars']} className="px-drag" style={{ cursor: 'move' }} />
));
