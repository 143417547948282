import PropTypes from 'prop-types';
import React from 'react';
import v4 from 'uuid';

export default class TPParticipants extends React.Component {
  static propTypes = {
    responses: PropTypes.array.isRequired,
    pEmail: PropTypes.bool.isRequired,
    pAlias: PropTypes.bool.isRequired,
    comLanguage: PropTypes.string.isRequired,
    pAnn: PropTypes.bool.isRequired,
    users_anonymous: PropTypes.array.isRequired,
    fetchUsernames: PropTypes.func.isRequired,
    aiWithImages: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {responses, pEmail, pAlias, comLanguage, pAnn, users_anonymous, aiWithImages} = this.props;

    const users = responses.map(user => {
      const activities = user.activities.map((activity, indexA) => {
        const tasks = activity.tasks.map((task, indexT) => {

          let answer = 'Response not available';
          let updateAt = '';
          let responseState = '';
          let images = null;

          if(task.response){
            if(task.response.response){
              if(task.response.response.state !== 'Empty'){
                updateAt = task.response.updated_at;
                //console.log('task.response.response:', task.response.response);
                if(task.type === 'Open End' || task.type === 'Multimedia'){
                  answer = task.type === 'Open End' ?
                      <div className='answer'>{ task.response.response.data.answer }</div> :
                      <div className='answer'></div>

                  const imagesFlag = task.media_options.find(e => e === 'Images');

                  if(task.media && imagesFlag !== undefined){
                    images = <div className='row attachments'>
                      <div className='col-xs-12 flex-v-center'>
                        <ul className='list-inline'>
                          {
                            task.response.attachments &&
                            task.response.attachments.length !== 0 ?
                              task.response.attachments.map((attachment, index) => {
                                // To review: Why are some files saved with that encode in dB? Maybe in the S3 uploader
                                // It only affects the PDF links
                                const imageURL = attachment.url.replace(/%2F/g, "/");
                                return(
                                  <li key={index}>
                                    {aiWithImages ?
                                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        width: '100%', marginBottom: '15px'
                                      }}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                          <img src={attachment.url_thumbnail || attachment.url} className='px-thumbnail'
                                               alt=""/>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginLeft: '15px'}}>
                                          {attachment.transcript ? attachment.transcript : 'No image description available'}
                                        </div>
                                      </div> :
                                      <img src={attachment.url_thumbnail || attachment.url} className='px-thumbnail' alt=""
                                           style={{marginBottom: '15px'}}/>
                                    }
                                  </li>
                                )
                              }) :
                              <div className="px-empty-msg" style={{color: 'rgb(235, 120, 16)'}}>Images not available</div>
                          }
                        </ul>
                      </div>
                    </div>
                  }
                }
                else if (task.type === 'Canvas') {
                  answer = <div className='answer'></div>;
                  images =
                  <div className='row attachments'>
                    <div className='col-xs-12 flex-v-center'>
                      <ul className='list-inline'>
                        {
                          task.response.attachments && task.response.attachments.length !== 0 ?
                            <li>
                              {aiWithImages ?
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: '15px'}}>
                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img
                                      src={task.response.attachments.slice(-1)[0].url_thumbnail || task.response.attachments.slice(-1)[0].url}
                                      className='px-thumbnail'
                                      alt=""/>
                                  </div>
                                  <div style={{display: 'flex', alignItems: 'center', marginLeft: '15px'}}>
                                    {task.response.attachments.slice(-1)[0].transcript ? task.response.attachments.slice(-1)[0].transcript : 'No image description available'}
                                  </div>
                                </div> :
                                <img
                                  src={task.response.attachments.slice(-1)[0].url_thumbnail || task.response.attachments.slice(-1)[0].url}
                                     className='px-thumbnail' alt=""
                                     style={{marginBottom: '15px'}}/>
                              }
                            </li> :
                            <li>
                            <div className="px-empty-msg" style={{color: 'rgb(235, 120, 16)'}}>Image not available</div>
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                }
                else if (task.type === 'MicroChat') {
                  answer = (task.response.response.ai_summary || task.response.response.ai_summary === '' ) ?
                    <div className='answer'>
                      { task.response.response.ai_summary.text }
                    </div> :
                    <div className='answer'>
                      No response available
                    </div>
                }
                else {
                  // Choice
                  const choices = [];
                  if(task.response.response.data.answersId.length !== 0){
                    // console.log('task.response.response.data.answersId:', task.response.response.data.answersId);
                    task.data.answers.forEach(ta => {
                      task.response.response.data.answersId.forEach(ra => {
                        if(ta.id === ra.id){
                          choices.push(ta);
                        }
                      })
                    });

                    if(task.data.focusOn === 'Text'){
                      const items = choices.map((ta, index) => {
                        return(
                          <li key={index}>
                            <span>{ta.text}</span>
                          </li>
                        )
                      });

                      answer = <ul>{ items }</ul>
                    } else {
                      const items = choices.map((ta, index) => {
                        return(
                          <tr key={index} style={{width:'100%'}}>
                            <td style={{paddingBottom:'15px'}}>
                            <img src={ta.img !== undefined ? ta.img :
                              'https://dev-pixiebob.s3-ap-southeast-2.amazonaws.com/pdf-default/placeholder.png'}
                                 className='px-thumbnail' alt='' style={{height: '50px'}}/>
                            <span className='font-sm' style={{marginLeft:'10px'}}>{ta.text}</span>
                            {/*<a href={ta.img} className='font-sm' target="_blank">{ta.img !== undefined ? 'download':'no img'}</a>*/}
                            </td>
                          </tr>
                        )
                      });

                      answer = <div className='row attachments'>
                        <div className='col-xs-12 flex-v-center'>
                          <table>
                            <tbody>
                            { items }
                            </tbody>
                          </table>
                        </div>
                      </div>;
                    }
                  } else {
                    answer = 'Response with no choice(s) selected'
                  }
                }
              }

              responseState = task.response.response.state;
            }
          } else {
            responseState = 'Blank';
          }

          const comments = task.response.comments && task.response.comments.map((comment, index) => {
            const fetchedUsernamesForComment = this.props.fetchUsernames(pAlias, comment)
            let commentMainName = fetchedUsernamesForComment.mainName;

            if(pAnn){
              const userAnn = users_anonymous.find(u => u.user_id === comment.comment.user_id);
              commentMainName = userAnn?.anonymous || commentMainName;
            }

            //console.log(JSON.stringify(comment));
            const replies = comment.replies.map((reply, index) => {
              //console.log(JSON.stringify(reply));
              const fetchedUsernamesForReply = this.props.fetchUsernames(pAlias, reply)
              let replyMainName = fetchedUsernamesForReply.mainName;

              if(pAnn){
                const userAnn = users_anonymous.find(u => u.user_id === reply.reply.user_id);
                replyMainName = userAnn?.anonymous || replyMainName;
              }

              return(
                <div key={index} className="comment-wrapper reply container no-page-break">
                  <div className="row comment-details">
                    <div className="col-xs-9 col">
                      <span className='username'>
                        {`${replyMainName} ${reply?.reply?.kind?.from === 'AI' ? '(AI)' : '' }`}
                      </span>
                      <span className='email'>
                        { pEmail ? reply.email : '' }
                        {/*{` (${reply.user_id})`}*/}
                      </span>
                    </div>
                    <div className="col-xs-3 col">
                      <span className='timestamp'>{reply.updated_at}</span>
                    </div>
                  </div>
                  <div className="row comment-body">
                    <p className='comment'>{reply.reply.body}</p>
                  </div>
                </div>
              )
            });

            return (
              <div key={index}>
                <div className="comments">
                  <div className="comment-wrapper container no-page-break">
                    <div className="row comment-details">
                      <div className="col-xs-9 col">
                        <span className='username'>
                          {`${commentMainName} ${comment?.comment?.kind?.from === 'AI' ? '(AI)' : '' }`}
                        </span>
                        <span className='email'>
                          { pEmail ? comment.email : '' }
                          {/*{` (${comment.user_id})`}*/}
                        </span>
                      </div>
                      <div className="col-xs-3 col">
                        <span className='timestamp'>{comment.updated_at}</span>
                      </div>
                    </div>
                    <div className="row comment-body">
                      <p className='comment'>{comment.comment.body}</p>
                    </div>
                  </div>
                  {replies}
                </div>
              </div>
            )
          });

          let video = null;
          if(task.type === 'Open End'){
            if(task.media){
              const videoFlag = task.media_options.find(e => e === 'Video');

              if(videoFlag !== undefined){
                if(task.response.response !== undefined){
                  video = task.response.response.video_thumbnail ?
                    <div className='row attachments'>
                      <div className='col-xs-12 flex-v-center'>
                        <ul className='list-inline'>
                          <li>
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100%',
                              marginBottom: '15px'
                            }}>
                              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                <div>
                                  <img src={'https:' + task.response.response.video_thumbnail} className='px-thumbnail'
                                       alt=''
                                       style={{marginBottom: '15px'}}/>
                                </div>
                                <div>
                                  <a href={`https:${task.response.response.video_url}`} className='font-sm' target="_blank">
                                    click to watch</a>
                                </div>
                              </div>
                              <div style={{display: 'flex', alignItems: 'center', marginLeft: '15px'}}>
                                {task?.response?.response?.video_transcript ? task.response.response.video_transcript : 'No video transcript available'}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div> :
                    <div className="px-empty-msg" style={{color: 'rgb(235, 120, 16)', textAlign: 'left'}}>Video not
                      available</div>
                }
              }
            }
          }

          const fetchedUsernames = this.props.fetchUsernames(pAlias, user)
          let mainName = fetchedUsernames.mainName;

          if(pAnn){
            const userAnn = users_anonymous.find(u => u.user_id === user.id);
            mainName = userAnn?.anonymous || mainName;
          }

          return(
            <div key={v4()} className="row level-3 participant">
              <div className="row title-bar secondary-subtle no-page-break">
                <div className='col-xs-12'>
                  <span>{ indexA + 1 }. { indexT + 1 }</span>
                  <span dangerouslySetInnerHTML={{__html: task.title}}/>
                </div>
                <div className='col-xs-4 avatar-n-username flex-v-center left'>
                  <strong>{ mainName }</strong>
                </div>
                <div className='col-xs-4 participant-details center'>
                  {
                    updateAt &&
                    <div className='date'>
                      <span>
                        <i className="fas fa-edit"/>
                        {/*<FontAwesomeIcon icon={['fal', 'edit']} />*/}
                      </span>{updateAt}
                    </div>
                  }
                </div>
                <div className='col-xs-4 response-details right'>
                  <div className='status'>
                    <span className={ responseState.toLowerCase()}>
                      <i className="fas fa-circle"/>
                      {/*<FontAwesomeIcon icon={['fas', 'circle']} />*/}
                    </span>{ responseState }
                  </div>
                </div>
              </div>

              <div className='main-answer container'>
                <div className='verbatims container'>
                  {
                    task.response.verbatims &&
                    task.response.verbatims.map((verbatim, index) => {
                      return(
                        <div key={index} className="row verbatim-wrapper no-page-break flex-v-center">
                          <div className="col-xs-11">
                            <span className='verbatim'>{verbatim.verbatim}</span>
                          </div>
                          <div className="col-xs-1 left">
                                <span className='verbatim-icon-wrapper flex-v-center'>
                                  <i className="fas fa-quote-right"/>
                                  {/*<FontAwesomeIcon icon={['fas', 'quote-right']} />*/}
                                </span>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                { answer }
                { images}
                { video }

                <div className='row maps no-page-break'>
                  <div className='col-xs-12 flex-v-center'>
                    <ul className='list-inline'>
                      <li className='strong label'>{
                        task.response.moods && task.response.moods.length > 0 ?
                          task.response.mapTitle[comLanguage] + ': ' : ''}
                      </li>
                      {
                        task.response.moods && task.response.moods.map((mood, index) => {
                          return (
                            <li key={index} className='map-item px-badge highlight'>
                              <label>{mood[comLanguage]}</label>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
                {
                  comments && comments.length > 0 &&
                  <div className="separator-w-icon">
                    <span className='strong'>COMMENTS</span>
                  </div>
                }
                { comments }
              </div>
            </div>
          );
        });

        return(
          <div key={activity.id}>
            <div className="row level-2">
              <div className="col-xs-12 title-bar secondary no-page-break">
                {activity.title}
              </div>
              <div className='col-xs-12 highlight' dangerouslySetInnerHTML={{__html: activity.instructions}}/>
            </div>
            { tasks }
          </div>
        )
      });

      const fetchedUsernames = this.props.fetchUsernames(pAlias, user)
      let partUsername = fetchedUsernames.mainName;

      if(pAnn){
        const userAnn = users_anonymous.find(u => u.user_id === user.id);
        partUsername = userAnn.anonymous;
      }

      return(
        <div key={user.id} className='container export-content'>
          <div className="row level-1">
            <div className="col-xs-12 title-bar primary no-page-break ">
              <img src={user.avatar !== '' ? user.avatar :
                'https://s3-ap-southeast-2.amazonaws.com/dev-pixiebob/pdf-default/user.png'} style={{width:'50px', marginRight: '20px'}} alt=""/>
              {partUsername}
              {pEmail && ', ' + user.email}
            </div>
          </div>
          { activities }
        </div>
      )
    });

    return (
      <div>
        { users }
      </div>
    );
  }
}
