import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class RSDashboards extends React.Component {
  static propTypes = {
    from: PropTypes.string.isRequired,
    updateSearchTerm: PropTypes.func.isRequired,
    searchTask: PropTypes.func.isRequired,
    updateShowModalMobile: PropTypes.func.isRequired,
    objSearch: PropTypes.object.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    executeUpdateModal: PropTypes.func.isRequired,
    activeLink: PropTypes.string.isRequired,
    nowFilter: PropTypes.string,
    updateNowFilter: PropTypes.func,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {from, nowFilter, activeLink, language, colors} = this.props;
    const i18n = {
      en: {
        search: 'Search', close: 'Close', filterBy: 'Filter by', participants: 'Participants',
        segments: 'Segments', activities: 'Activities', tags: 'Tags', all: 'All', responses: 'Responses',
        comments: 'Comments', likes: 'Likes', replies: 'Replies', nowFilters: 'Now filters'
      },
      es: {
        search: 'Buscar', close: 'Cerrar', filterBy: 'Filtrar por', participants: 'Participantes',
        segments: 'Segmentos', activities: 'Actividades', tags: 'Tags', all: 'Todos', responses: 'Respuestas',
        comments: 'Comentarios', likes: 'Likes', replies: 'Contestaciones', nowFilters: 'Filtros Comunidad Ahora'
      }
    }
    const idParsLength = this._getLength('idPars');
    const idSegsLength = this._getLength('idSegs');
    const idActsLength = this._getLength('idActs');
    const idTagsLength = this._getLength('idTags');

    return (
      <div className="modal-body px-modal-body-warning">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-drop-search-section">
              {
                from !== 'Now' &&
                <div className="px-input-icon">
                  <div className="form-group">
                    <input onChange={(e) => this.props.updateSearchTerm(e, from)} name="searchTerm" type="text"
                           className="form-control" placeholder={i18n[language].search} onKeyDown={this.keyPress}/>
                  </div>
                  <FontAwesomeIcon onClick={() => this.props.searchTask(from)} icon={['fas','search']}
                                   className="px-icon-right color-gray-base"/>
                </div>
                
              }
              <button onClick={this.props.updateShowModalMobile} className="btn px-btn sm btn-blue-base btn-update"
              style={{backgroundColor: colors.color1[3]}}>{i18n[language].close}</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="mg-top-20 px-label mg-bottom-5">{i18n[language].filterBy}</div>
            <div className="px-drop-filters-container">
              {
                idParsLength === 0 ?
                  <div className="px-item">
                    <span className="label-xs" style={{color: colors.color1[3]}}>{i18n[language].participants}</span>
                    <button onClick={() => this.props.updateShowModal(from, 'Participants')}
                      className="px-btn-type-select">{i18n[language].all}
                      <FontAwesomeIcon icon={['fas','circle-chevron-down']} />
                    </button>
                  </div> :
                  <div className="px-item">
                    <span className="label-xs" style={{ color: colors.color1[3] }}>{i18n[language].participants}</span>
                    <button onClick={() => this.props.updateShowModal(from, 'Participants')}
                            className="btn-filter">{this._singularize(this._getLength('idPars'), 'Participants')}</button>
                    <div className="btn-delete btn-close-float bar-filter" style={{cursor: 'pointer'}}>
                      <div onClick={() => this.props.executeUpdateModal(activeLink, 'idPars', [])}
                           className="btn-container bar-filter">
                        <FontAwesomeIcon icon={['fas','xmark']} />
                      </div>
                    </div>
                  </div>
              }
              {
                idSegsLength === 0 ?
                  <div className="px-item">
                    <span className="label-xs" style={{ color: colors.color1[3] }}>{i18n[language].segments}</span>
                    <button onClick={() => this.props.updateShowModal(from, 'Segments')}
                      className="px-btn-type-select">{i18n[language].all}
                      <FontAwesomeIcon icon={['fas','circle-chevron-down']} />
                    </button>
                  </div> :
                  <div className="px-item">
                    <span className="label-xs" style={{ color: colors.color1[3] }}>{i18n[language].segments}</span>
                    <button className="btn-filter">{this._singularize(this._getLength('idSegs'), 'Segments')}</button>
                    <div className="btn-delete btn-close-float bar-filter" style={{cursor: 'pointer'}}>
                      <div onClick={() => this.props.executeUpdateModal(activeLink, 'idSegs', [])}
                           className="btn-container bar-filter">
                        <FontAwesomeIcon icon={['fas','xmark']} />
                      </div>
                    </div>
                  </div>
              }
              {
                idActsLength === 0 ?
                  <div className="px-item">
                    <span className="label-xs" style={{ color: colors.color1[3] }}>{i18n[language].activities}</span>
                    <button onClick={() => this.props.updateShowModal(from, 'Activities')}
                      className="px-btn-type-select">{i18n[language].all}
                      <FontAwesomeIcon icon={['fas','circle-chevron-down']} />
                    </button>
                  </div> :
                  <div className="px-item">
                    <span className="label-xs" style={{ color: colors.color1[3] }}>{i18n[language].activities}</span>
                    <button onClick={() => this.props.updateShowModal(from, 'Activities')} className="btn-filter">
                      {this._singularize(this._getLength('idActs'), 'Activities')}</button>
                    <div className="btn-delete btn-close-float bar-filter" style={{cursor: 'pointer'}}>
                      <div onClick={() => this.props.executeUpdateModal(activeLink, 'idActs', [])}
                           className="btn-container bar-filter">
                        <FontAwesomeIcon icon={['fas','xmark']} />
                      </div>
                    </div>
                  </div>
              }

              
              {
                idTagsLength === 0 ?
                  <div className="px-item">
                    <span className="label-xs" style={{ color: colors.color1[3] }}>{i18n[language].tags}</span>
                    <button onClick={() => this.props.updateShowModal(from, 'Tags')} className="px-btn-type-select">{i18n[language].all}
                      <FontAwesomeIcon icon={['fas','circle-chevron-down']} />
                    </button>
                  </div> :
                  <div className="px-item">
                    <span className="label-xs" style={{ color: colors.color1[3] }}>{i18n[language].tags}</span>
                    <button onClick={() => this.props.updateShowModal(from, 'Tags')} className="btn-filter">
                      {this._singularize(this._getLength('idTags'), 'Tags')}</button>
                    <div className="btn-delete btn-close-float bar-filter" style={{cursor: 'pointer'}}>
                      <div onClick={() => this.props.executeUpdateModal(activeLink, 'idTags', [])}
                           className="btn-container bar-filter">
                        <FontAwesomeIcon icon={['fas','xmark']} />
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>

        {
          from === 'Now' &&
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="mg-top-20 px-label mg-bottom-5">{i18n[language].nowFilters}</div>
              <div className="px-drop-filters-container">
                <div className="px-item">
                  <span className={`check-btn ${nowFilter === 'All' && 'active' }`}
                        onClick={() => this.props.updateNowFilter('All')} >
                    <span>{i18n[language].all}</span>
                  </span>
                </div>
                <div className="px-item">
                  <span className={`check-btn ${nowFilter === 'Responses' && 'active'}`}
                        onClick={() => this.props.updateNowFilter('Responses')} >
                    <span>{i18n[language].responses}</span>
                  </span>
                </div>
                <div className="px-item">
                  <span className={`check-btn ${nowFilter === 'CommentsReplies' && 'active' }`}
                    onClick={() => this.props.updateNowFilter('CommentsReplies')} >
                    <span>{i18n[language].comments}</span>
                  </span>
                </div>
                <div className="px-item">
                  <span className={`check-btn ${nowFilter === 'Likes' && 'active' }`}
                    onClick={() => this.props.updateNowFilter('Likes')} >
                    <span>{i18n[language].likes}</span>
                  </span>
                </div>
                {/* <div className="px-item">
                  <span className={`check-btn ${nowFilter === 'Comments' ? 'active' : ''}`}
                        onClick={() => this.props.updateNowFilter('Comments')} >
                    <span>{i18n[language].comments}</span>
                  </span>
                </div>
                <div className="px-item">
                  <span className={`check-btn ${nowFilter === 'Replies' ? 'active' : ''}`}
                    onClick={() => this.props.updateNowFilter('Replies')} >
                    <span>{i18n[language].replies}</span>
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

  _singularize = (count, noun) => {
    if(noun === 'Activities'){
      return  `${count} ${count === 1 ? 'Activity' : noun}`;
    } else {
      return `${count} ${count === 1 ? noun.slice(0, -1) : noun}`;
    }
  };

  _getLength(arrayName){
    //console.log(this.props.objSearch[arrayName]);
    return this.props.objSearch[arrayName].filter(item => item !== undefined).length;
  }

  keyPress = (event) => {
    if(event.keyCode === 13){
      this.props.searchTask(this.props.from);
    }
  };
}
