import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getCSRF, showSuccessMsg } from "../../UI/Globals/PX_Funs"
import axios from 'axios';
import MediaQuery from 'react-responsive';
import {Tooltip as ReactTooltip} from "react-tooltip";

export default class GenericInvitationLandingPage extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    segment_ids: PropTypes.array,
    invitation_url_id: PropTypes.string,
    subdomain: PropTypes.string,
    generic_link_token: PropTypes.string,
    company_logo: PropTypes.string,
    company_colors: PropTypes.object,
    browser_language: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      errorMessage: null,
      successMessage: null,
      registrationMessage: null,
      showRegistrationForm: true,
      showCheckYourMailView: false,
      showCheckInvitationView: false,
      showSignInForm: false,
      isProcessingRegistration: false,
      isTimerActive: false,
      timeRemaining: 60,
      showPassword: false
    };

    this.timerInterval = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.showCheckYourMailView && this.state.showCheckYourMailView) {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const { community } = this.props;

      const newUrl = `${baseUrl}/you_are_invited/${community.id}`;
      window.history.replaceState(null, null, newUrl);
    }
  }

  componentWillUnmount() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleRegistrationSubmit = async (event) => {
    event.preventDefault();
    if (this.state.isProcessingRegistration) return;

    this.setState({ isProcessingRegistration: true });

    const { email, password, passwordConfirmation } = this.state;
    const { community, segment_ids, subdomain, generic_link_token, invitation_url_id } = this.props;

    const baseUrl = `${window.location.protocol}//${window.location.host}`;

    try {
      const response = await axios.post('/users/create_for_generic_invitation', {
        user: { email, password, password_confirmation: passwordConfirmation },
        community_id: community.id,
        segment_ids,
        invitation_url_id,
        subdomain,
        generic_link_token,
        base_url: baseUrl
      }, getCSRF());

      if (response.data?.error) {
        this.setState({ errorMessage: "Registration failed. Please try again.", successMessage: null });
      } else if (response.status === 200) {
        const redirectUrl = response.data?.redirect_url || '';
        const showConfirmation = response.data?.show_confirmation || '';
        const showSignIn = response.data?.show_sign_in || '';
        const registrationMessage = response.data?.registration_message || null;
        const showCheckInvitationView = response.data?.show_invitation || null;

        if (showConfirmation) {
          this.setState({
            showRegistrationForm: false,
            showSignInForm: false,
            showCheckYourMailView: true,
            registrationMessage: registrationMessage,
            successMessage: "Registration successful!",
            errorMessage: null });
        } else if (showCheckInvitationView) {
          this.setState({
            showCheckInvitationView: true,
            showRegistrationForm: false,
            showCheckYourMailView: false,
            showSignInForm: false,
            registrationMessage: registrationMessage,
            successMessage: "Registration successful!",
            errorMessage: null });
        } else if (showSignIn) {
          this.setState({
            email: '',
            password: '',
            showSignInForm: true,
            showRegistrationForm: false,
            showCheckYourMailView: false,
            registrationMessage: registrationMessage,
            errorMessage: null,
          });
        } else if (redirectUrl) {
          this.setState({
            showSignInForm: false,
            showRegistrationForm: false,
            showCheckYourMailView: false,
            registrationMessage: registrationMessage,
            errorMessage: null });
          window.location.href = window.location.origin + redirectUrl;
        }
      }
    } catch (error) {
      this.setState({ errorMessage: "Registration failed. Please try again.", successMessage: null });
    } finally {
      this.setState({ isProcessingRegistration: false });
    }
  };


  handleSignInSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = this.state;
    const { community, subdomain } = this.props;

    this.setState({ isProcessingRegistration: true });

    try {
      const csrfHeaders = getCSRF();
      const response = await axios.post('/users/sign_in_for_react', {
        user: { email, password },
        community_id: community.id,
        subdomain
      }, {
        headers: {
          ...csrfHeaders.headers,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });

      console.log('SIGN IN RESPONSE', response.data?.error)

      if (response.data?.success) {
        const redirectUrl = response.data?.redirect_url || '/users/dashboard';
        window.location.href = window.location.origin + redirectUrl;
      } else {
        this.setState({
          password: '',
          email: '',
          errorMessage: response.data?.error || 'Sign in failed. Please try again.',
          successMessage: null,
          isProcessingRegistration: false,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized (invalid credentials)
        this.setState({
          password: '',
          email: '',
          errorMessage: 'Invalid email or password. Please try again.',
          successMessage: null,
          isProcessingRegistration: false,
        });
      } else {
        // Handle other possible errors (network issues, etc.)
        this.setState({
          password: '',
          email: '',
          errorMessage: 'An unexpected error occurred. Please try again.',
          successMessage: null,
          isProcessingRegistration: false,
        });
      }
    }
  };


  handlePasswordVisibilityToggle = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleResendConfirmation = async () => {
    const { email } = this.state;
    const { community, segment_ids, subdomain, invitation_url_id } = this.props;

    const baseUrlProtocol = window.location.protocol;
    const baseUrlHost = window.location.host;
    const baseUrl = `${baseUrlProtocol}//${baseUrlHost}`;

    try {
      const response = await axios.post('/registrations/resend_confirmation', {
        email: email,
        community_id: community.id,
        invitation_url_id: invitation_url_id,
        segment_ids: segment_ids,
        subdomain: subdomain,
        base_url: baseUrl
      },
        {
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content'),
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: true, // Include this line
        }
      );

      if (response.data.success) {
        this.setState({ successMessage: 'Confirmation email has been resent successfully.' });
        this.startResendTimer();
      } else if (response.data.error) {
        this.setState({ errorMessage: response.data.error });
      }
    } catch (error) {
      console.log(error)
      this.setState({ errorMessage: 'An error occurred while resending the confirmation email.' });
    }
  };

  startResendTimer = () => {
    this.setState({ isTimerActive: true, timeRemaining: 60 });

    // Clear any existing interval
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }

    // Start a new countdown interval
    this.timerInterval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timeRemaining <= 1) {
          clearInterval(this.timerInterval);
          return { isTimerActive: false, timeRemaining: 0 };
        } else {
          return { timeRemaining: prevState.timeRemaining - 1 };
        }
      });
    }, 1000);
  };

  getSignInText = () => {
    const i18n = {
      en: {signInToJoinDisclaimer: "Enter your email and password to continue."},
      es: {signInToJoinDisclaimer: "Ingresa tu email y contraseña para continuar."}
    }

    if (this.state.registrationMessage) {
      return this.state.registrationMessage;
    } else {
      return i18n[this.props.browser_language].signInToJoinDisclaimer;
    }
  }

  render() {
    const { email, password, errorMessage, successMessage, showSignInForm, showCheckYourMailView, showCheckInvitationView } = this.state;
    const { company_colors, company_logo, browser_language, community, subdomain } = this.props;

    const i18n = {
      en: {
        welcomeTo: 'Welcome to', registerToJoinDisclaimer: "Enter your email and create a new password to join.",
        signInToEnter: 'Sign in to enter',
        emailNotVisibleDisclaimer: "Your email will remain private and won't be visible to others, including project moderators.",
        showPassword: 'Show password', hidePassword: 'Hide password',
        signUp: 'Sign Up', signIn: 'Sign In',
        forgotYourPassword: 'Forgot your password?',
        checkYourEmail: 'Check your email',
        followTheLink: 'Follow the link in the confirmation email we sent to ',
        followTheInvitationLink: 'Follow the link in the invitation email we sent to ',
        emailCanTakeAMinute: '. The email can take up to one minute to arrive.',
        termsOfService: 'Terms of Service', privacyPolicy: 'Privacy Policy',
        emailPlaceholder: 'Your Email',
        passwordPlaceholderRegister: 'New Password',
        passwordPlaceholderSignIn: 'Your Password'
      },
      es:{
        welcomeTo: 'Bienvenido a', registerToJoinDisclaimer: "Ingresa tu email y crea una nueva contraseña para unirte.",
        signInToEnter: 'Inicia sesión para entrar a',
        emailNotVisibleDisclaimer: "Tu dirección email se mantendrá privada y no será visible para otros, incluidos los moderadores del proyecto.",
        showPassword: 'Mostrar contraseña', hidePassword: 'Ocultar contraseña',
        signUp: 'Registrar', signIn: 'Iniciar',
        forgotYourPassword: 'Olvidaste tu contraseña?',
        checkYourEmail: 'Revisa tu correo',
        followTheLinkTo: 'Sigue el enlace en el email que enviamos a ',
        followTheInvitationLink: 'Sigue el enlace en el email de invitación que enviamos a ',
        emailCanTakeAMinute: '. El correo puede tardar hasta un minuto en llegar.',
        termsOfService: 'Términos de servicio', privacyPolicy: 'Política de privacidad',
        emailPlaceholder: 'Tu Email',
        passwordPlaceholderRegister: 'Nueva Contraseña',
        passwordPlaceholderSignIn: 'Tu Contraseña'
      }
    }

    const passwordPlaceholder =  showSignInForm ?
      i18n[browser_language].passwordPlaceholderSignIn :
      i18n[browser_language].passwordPlaceholderRegister;

    return (
      <div className="px-login-container" style={{backgroundColor: company_colors?.color0[1]}}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip"/>
        {showCheckYourMailView ?
          <div className={`content ${subdomain ? 'subdomain' : ''}`}>
            <div className="panel-login-container">
              <div className="login-panel">
                <div className="panel-body" style={{padding: '30px 40px 30px'}}>
                  <div style={{color: company_colors?.color2[2],
                    textAlign: 'center',
                    fontSize: '70px'}}>
                    <FontAwesomeIcon icon={['fad', 'envelope-dot']} />
                  </div>
                  <h2 style={{textAlign: 'center'}}>{i18n[browser_language].checkYourEmail}</h2>
                  <div style={{display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    borderRadius: '4px',
                    backgroundColor: '#e5e7e4',
                    marginBottom: '25px'
                  }}>
                    <div style={{fontSize: '30px', paddingRight: '20px'}}>
                      <FontAwesomeIcon icon={['fal', 'circle-info']}/>
                    </div>
                    <div>{i18n[browser_language].followTheLink}<span>{email}</span>
                      {i18n[browser_language].emailCanTakeAMinute}
                    </div>
                  </div>
                  <div style={{width: '100%', textAlign: 'center'}}>
                    <button className="px-btn"
                            style={{backgroundColor: '#ffffff',
                              color: '#262E37 !important',
                              width: '100%',
                              border: '1px solid #262E37',
                              borderRadius: '6px',
                              paddingTop: '6px',
                              paddingBottom: '6px'
                            }}
                            onClick={this.handleResendConfirmation}
                            disabled={this.state.isTimerActive}>
                      {this.state.isTimerActive ? `Resend in ${this.state.timeRemaining}s` : 'Resend Confirmation Email'}
                    </button>
                  </div>
                  {errorMessage && showSuccessMsg(errorMessage)}

                </div>
              </div>
            </div>
          </div> : showCheckInvitationView ?
            <div className={`content ${subdomain ? 'subdomain' : ''}`}>
              <div className="panel-login-container">
                <div className="login-panel">
                  <div className="panel-body" style={{padding: '30px 40px 30px'}}>
                    <div style={{
                      color: company_colors?.color2[2],
                      textAlign: 'center',
                      fontSize: '70px'
                    }}>
                      <FontAwesomeIcon icon={['fad', 'envelope-dot']}/>
                    </div>
                    <h2 style={{textAlign: 'center'}}>{i18n[browser_language].checkYourEmail}</h2>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                      borderRadius: '4px',
                      backgroundColor: '#e5e7e4',
                      marginBottom: '25px'
                    }}>
                      <div style={{fontSize: '30px', paddingRight: '20px'}}>
                        <FontAwesomeIcon icon={['fal', 'circle-info']}/>
                      </div>
                      <div>{i18n[browser_language].followTheInvitationLink}<span>{email}</span>
                        {i18n[browser_language].emailCanTakeAMinute}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> :
            <div className={`content ${subdomain ? 'subdomain' : ''}`}>
              <div className="panel-login-container">
                <div className="login-panel">
                  <div className="panel-body" style={{padding: '30px 40px 20px'}}>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 text-center">
                        <div className="logo-subdomain">
                          {company_logo && <img src={company_logo || ''} alt="Logo"/>}
                          <div className="title"
                               style={{color: company_colors?.color0[1], fontSize: '30px'}}>
                            {showSignInForm ?
                              `${i18n[browser_language].signInToEnter} ${community.title}` :
                              `${i18n[browser_language].welcomeTo} ${community.title}`
                            }
                          </div>

                          <div className="subtitle"
                               style={{
                                 color: showSignInForm ? company_colors?.color4[1] : company_colors?.color2[2],
                                 fontSize: '16px',
                                 fontFamily: "'Sharp Sans SemiBold', Helvetica, sans-serif",
                                 marginTop: '20px',
                                 marginBottom: '25px',
                                 lineHeight: '25px',
                               }}>
                            {showSignInForm ?
                              this.getSignInText() :
                              i18n[browser_language].registerToJoinDisclaimer}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12">
                        <form onSubmit={showSignInForm ? this.handleSignInSubmit : this.handleRegistrationSubmit}>
                          <div className="form-group" style={{position: 'relative'}}>
                            <input
                              type="email"
                              name="email"
                              value={email}
                              onChange={this.handleInputChange}
                              placeholder={i18n[browser_language].emailPlaceholder}
                              className="input-login"
                              style={{color: company_colors?.color2[3]}}
                              required
                            />
                            {community.hide_participants_emails &&
                              <span style={{
                                position: 'absolute', right: '10px',
                                top: '50%', transform: 'translate(-50%, -50%)',
                                color: company_colors?.color0[1]
                              }}
                                    data-tooltip-content={i18n[browser_language].emailNotVisibleDisclaimer}>
                              <FontAwesomeIcon icon={['fas', 'shield-check']}/>
                            </span>}
                          </div>
                          <div className="form-group" style={{position: 'relative'}}>
                            <input
                              type={this.state.showPassword ? "text" : "password"}
                              name="password"
                              value={password}
                              onChange={this.handleInputChange}
                              placeholder={passwordPlaceholder}
                              className="input-login"
                              style={{color: company_colors?.color2[3]}}
                              required
                            />
                            <span style={{
                              position: 'absolute', right: '10px',
                              top: '50%', transform: 'translate(-50%, -50%)',
                              color: company_colors?.color0[1],
                              cursor: 'pointer'
                            }}
                                  onClick={this.handlePasswordVisibilityToggle}
                                  data-tooltip-content={this.state.showPassword ? i18n[browser_language].hidePassword : i18n[browser_language].showPassword}>
                              <FontAwesomeIcon icon={this.state.showPassword ? ['fas', 'eye-slash'] : ['fas', 'eye']}/>
                            </span>
                          </div>
                          {errorMessage &&
                            <div style={{
                              color: company_colors?.color4[2],
                              paddingBottom: '24px',
                              textAlign: 'center'
                            }}>
                              {errorMessage}
                            </div>
                          }

                          {showSignInForm &&
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <div className="remember">
                                <div className="px-check-cont">
                                </div>
                                <a className="forgot" style={{
                                  color: "#2a2a32",
                                  fontFamily: "'Sharp Sans SemiBold', Helvetica, sans-serif",
                                  fontSize: '14px'
                                }}
                                   href={`${window.location.origin}/users/password/new`}>
                                  {i18n[browser_language].forgotYourPassword}
                                </a>
                                <br/>
                              </div>
                            </div>
                          }

                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <button type="submit" className="btn px-btn lg btn-blue-base wd-100"
                                    style={{backgroundColor: company_colors?.color0[1]}}>
                              {this.state.isProcessingRegistration ?
                                <FontAwesomeIcon icon="spinner" spin/> :
                                showSignInForm ?
                                  i18n[browser_language].signIn :
                                  i18n[browser_language].signUp}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="col-xs-12 text-center">
                      <div style={{
                        fontFamily: "'Sharp Sans Book', Helvetica, sans-serif",
                        fontSize: '11px',
                        textAlign: 'center',
                        color: '#262E37',
                        marginTop: '10px'
                      }}>
                        <div>Copyright Pixiebob Pty. Ltd.</div>
                        <div>
                          <a style={{color: '#262E37', textDecoration: 'none'}}
                             href="/pages/terms_of_service?language=en" target="_blank">
                            {i18n[browser_language].termsOfService}
                          </a>
                        </div>
                        <div>
                          <a style={{color: '#262E37', textDecoration: 'none'}}
                             href="/pages/privacy_policy?language=en" target="_blank">
                            {i18n[browser_language].privacyPolicy}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-footer-master">
                <span className="px-time">© {new Date().getFullYear()} Pixiebob</span>
                <span className="px-message" style={{fontSize: '13px'}}>
                  The simple yet powerful community platform
                </span>
              </div>
            </div>
        }
      </div>
    );
  }
}
