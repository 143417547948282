import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class TR_Verbatims extends React.Component {
  static propTypes = {
    responseId: PropTypes.number.isRequired,
    verbatim: PropTypes.object.isRequired,
    isVisible: PropTypes.string.isRequired,
    handleVerbatimVisibility: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      hoverV: false
    };
  }

  hoverOn = () => {
    this.setState({hoverV : true})
  };

  hoverOff = () => {
    this.setState({hoverV : false})
  };

  render() {
    const {verbatim, responseId, isVisible} = this.props;
    const {hoverV} = this.state;

    return (
      <div className="container-verbatim-mode">
        <p className="verbatim">{verbatim.verbatim}</p>
        <div onClick={() => this.props.handleVerbatimVisibility(verbatim.id, verbatim.visibility, responseId)}
             onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}
             className={hoverV ? 'verbatim-icon active' : 'verbatim-icon'}
             style={{cursor:'pointer'}}>
          <FontAwesomeIcon icon={['fas', hoverV ? 'eye-slash' : isVisible]}/>
        </div>
      </div>
    );
  }
}
