import PropTypes from 'prop-types';
import React from 'react';
import axios from "axios";

import Layout from "../../Layout";
import Transcript02 from "../../../containers/Community/Transcript02";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";

export default class TranscriptPage extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    sessionLang: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    participants: PropTypes.array.isRequired,
    active_users: PropTypes.array.isRequired,
    user_id: PropTypes.string.isRequired, //Legacy, delete it after to finish the new Transcript
    users_id: PropTypes.string.isRequired,
    activities_id: PropTypes.string.isRequired,
    tasks_id: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
    collaborators: PropTypes.array.isRequired,
    tasks_filter: PropTypes.array.isRequired,
    rooms: PropTypes.array.isRequired,
    communities: PropTypes.array.isRequired,
    qc: PropTypes.array.isRequired,
    scroll: PropTypes.bool.isRequired,
    item_scroll: PropTypes.object,
    company: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activeUsers: [],
      qc: [],
      notifications: [],
      rooms: [],
      communitiesTop: [],
      isLoading: true,
      isFixed: false,
      openPMFromOutside: {username: '', userId: null, type:'', flag: false},
      notificationsCounter: 0,
      isBeaconInit: false //Keeps track of any instances of Head Scout Beacons initialised.
    }
  }

  componentDidMount(){
    this._getNotificationsCounter();
    this._getCommunitiesTop();
    this._getQuickComments();
    //this._getFilterParticipants();
    //this._getActiveUsers();
    //this._getNotifications();
    //this._getRooms();

    // TO-DO try to change a way without to use setState
    $(".menu-chev-up").click(function(){
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });
    $(".menu-chev-up-mobile").click(function(){
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });

    window.addEventListener('scroll', this.handleScrollTEST, { passive: true })
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScrollTEST);
  }

  handleScrollTEST = () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      //console.log('Mobile');
      const header = document.getElementById("divFiltersMobile");
      const sticky = header.offsetTop;
      //console.log(window.pageYOffset, sticky);
      if(window.pageYOffset > sticky) {
        header.classList.add("sticky-new");
      } else {
        header.classList.remove("sticky-new");
      }
    } else {
      if(window.pageYOffset > 120) {
        //header.classList.add("sticky");
        this._fixNavBar();
      } else {
        this._unfixNavBar();
        //header.classList.remove("sticky");
        //document.getElementsByClassName('px-transcript-container')[0].classList.remove('mg-top-230');
      }
    }
  }

  render() {
    const {user_account, community, participants, origin, tasks_filter, users_id, activities_id, tasks_id, collaborators,
      scroll, item_scroll, company} = this.props
    const hsBeaconSuggestions = {
      en: ["5f1fcae42c7d3a10cbab6662", "5f2028ec04286306f8077ee0", "5f1ff75b2c7d3a10cbab681f", "5f1fd2f104286306f8077b64",
        "5f2029f32c7d3a10cbab6ae6", "5f202a9a2c7d3a10cbab6aee", "5f202b742c7d3a10cbab6b00", "5f202c9504286306f8077f53",
        "5f1ffb7304286306f8077cbe", "5f1ffbbd04286306f8077cc3"],
      es: ["5f1fcae42c7d3a10cbab6662", "5f2028ec04286306f8077ee0", "5f1ff75b2c7d3a10cbab681f", "5f1fd2f104286306f8077b64",
        "5f2029f32c7d3a10cbab6ae6", "5f202a9a2c7d3a10cbab6aee", "5f202b742c7d3a10cbab6b00", "5f202c9504286306f8077f53",
        "5f1ffb7304286306f8077cbe", "5f1ffbbd04286306f8077cc3"]
    };

    return (
      <Layout user_account={user_account}
              profile={user_account.profile}
              sessionLang={user_account.language}
              notifications={this.state.notifications}
              from='CollTranscriptPage'
              community={community}
              leftMenuActive={'Transcript'}
              origin={origin}
              active_users={this.state.activeUsers}
              rooms={this.state.rooms}
              communities={this.state.communitiesTop}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              notificationsCounter={this.state.notificationsCounter}
              colors={user_account.all_colors}>

        {/*<HelpScoutBeacon beaconId='036c2b9f-c6fb-4d01-bd37-38c07279a438'*/}
        {/*                 user_account={user_account}*/}
        {/*                 colors={user_account.all_colors}*/}
        {/*                 language={user_account.language}*/}
        {/*                 isBeaconInit={this.state.isBeaconInit}*/}
        {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
        {/*                 suggestions={hsBeaconSuggestions}*/}
        {/*                 hideFABOnDesktop={!this.props.user_account.profile.is_help} />*/}

        <Transcript02 community={community}
                      active_users={this.state.activeUsers}
                      tasks_filter={tasks_filter}
                      users_id={users_id}
                      activities_id={activities_id}
                      tasks_id={tasks_id}
                      role_1={user_account.role_1}
                      role_2={user_account.role_2}
                      language={user_account.language}
                      qc={this.state.qc}
                      profile={user_account.profile}
                      collaborators={collaborators}
                      scroll={scroll}
                      isLoading={this.state.isLoading}
                      item_scroll={item_scroll}
                      users={participants}
                      user_id={user_account.id}
                      user_account={user_account}
                      company={company}/>
      </Layout>
    );
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  updateOpenPMFromOutside = (type, username, userId) => {
    //console.log(type, username, userId);
    this.setState(state => ({openPMFromOutside: {username, userId, type, flag: !state.openPMFromOutside.flag}}));
  };

  _getQuickComments(){
    $.ajax({
      url: '/maps/get_quick_comments/' + this.props.community.id,
      method: 'GET',
      success: qc =>  {
        //console.log('qc:', qc);
        this.setState({qc});
      }
    });
  }

  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log(communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  _getFilterParticipants = () =>{
    axios.get('/communities/coll_get_tv_filter_parts/' + this.props.community.id)
      .then(response =>  {
        console.log(response.data);
        this.setState({activeUsers: response.data, isLoading: false});
      }).catch(error => {
      console.log(error);
    });
  };

  _getActiveUsers = () => {
    $.ajax({
      url: '/communities/coll_get_active_parts/' + this.props.community.id + '/transcript',
      method: 'GET',
      success: activeUsers =>  {
        console.log(activeUsers);
        this.setState({activeUsers, isLoading: false});
      }
    });
  };

  // TO-DO !!!WARNING - this setState re-draw the Charts, try to move to Jquery listeners
  handleScroll = (event) => {
    console.log('window.scrollY:', window.scrollY);
    if(window.scrollY > 50 && !this.state.isFixed){
      //console.log('launch setState');
      this.setState({isFixed: true}, () => {
        this._fixNavBar();
      })
    }

    if(window.scrollY < 50 && this.state.isFixed){
      this.setState({isFixed: false}, () => {
        this._unfixNavBar();
      })
    }
  };

  _fixNavBar(){
    document.getElementsByClassName('px-nav-transcript-container')[0].classList.add("fixed");
    document.getElementsByClassName('menu-chev-up')[0].style.opacity = '1';
    document.getElementsByClassName('px-transcript-container')[0].classList.remove('mg-top-50');
    document.getElementsByClassName('px-transcript-container')[0].classList.add('mg-top-230');
  }

  _unfixNavBar(){
    const pxTranscriptContainer = document.getElementsByClassName('px-transcript-container')[0];
    //console.log(pxTranscriptContainer);
    document.getElementsByClassName('px-nav-transcript-container')[0].classList.remove("fixed");
    document.getElementsByClassName('menu-chev-up')[0].style.opacity = '0';
    pxTranscriptContainer !== undefined && pxTranscriptContainer.classList.add('mg-top-50');
    //document.getElementsByClassName('px-transcript-container')[0].classList.add('mg-top-50');
    pxTranscriptContainer !== undefined && pxTranscriptContainer.classList.remove('mg-top-230');
    //document.getElementsByClassName('px-transcript-container')[0].classList.remove('mg-top-230');
  }
}
