import PropTypes from 'prop-types';
import React from 'react';
import {arrayMove, SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import AL_ActivityCard01 from "./ActivityList/AL_ActivityCard01";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import spanishStrings from "react-timeago/lib/language-strings/es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {calcUsersSegments} from "../../UI/Globals/PX_Funs";

export default class ActivityGrid01 extends React.Component {
  static propTypes = {
    isGrid: PropTypes.bool.isRequired,
    tasks: PropTypes.array.isRequired,
    activeTaskId: PropTypes.number,
    updateActiveTaskId: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    sort_coll: PropTypes.string.isRequired,
    onSortEndTask: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    active_users: PropTypes.array.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    updateLive: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    const tasks = arrayMove(this.props.tasks, oldIndex, newIndex);
    //console.log(this.props.tasks, tasks);
    this.props.updateTasks(tasks);
    this.props.saveTasksPositions();
  };

  render() {
    const {isGrid, tasks, activeTaskId, language, sort_coll, active_users, gender_as_segment, colors} = this.props;

    const formatter = language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);

    return (
      <div style={{width:'100%'}}>
        
          <SortableList tasks={tasks}
                        activeTaskId={activeTaskId}
                        updateActiveTaskId={this.props.updateActiveTaskId}
                        locale={language === 'en' ? 'en-au' : language}
                        formatter={formatter}
                        active_users={active_users}
                        gender_as_segment={gender_as_segment}
                        sort_coll={sort_coll}
                        onSortEnd={this.props.onSortEndTask}
                        updateShowModal={this.props.updateShowModal}
                        updateLive={this.props.updateLive}
                        useDragHandle={true}
                        axis="xy"
                        language={language}
                        colors={colors}/>

        
      </div>
    );
  }
}

const SortableList = SortableContainer(({tasks, activeTaskId, updateActiveTaskId, locale, formatter, sort_coll,
                                          updateShowModal, active_users, gender_as_segment, updateLive, language, colors}) => {
  return (
    <div className="cards-holder-container grid">
      {
        tasks.map((task, index) => {
          if(task.filterVisibility){
            return (
              <SortableItem key={`item-${index}`}
                index={index}
                task={task}
                activeTaskId={activeTaskId}
                updateActiveTaskId={updateActiveTaskId}
                locale={locale}
                formatter={formatter}
                sort_coll={sort_coll}
                updateShowModal={updateShowModal}
                cUsers={calcUsersSegments(active_users, gender_as_segment, task)}
                updateLive={updateLive}
                language={language}
                colors={colors}/>
            )
          }
        })
      }
    </div>
  );
});

const DragHandle = SortableHandle(() => <div className="grab-actv-card">
  <FontAwesomeIcon icon={['far', 'bars']}/>
</div>);

const SortableItem = SortableElement(({task, activeTaskId, updateActiveTaskId, locale, formatter, sort_coll,
                                        updateShowModal, cUsers, updateLive, language, colors}) =>
  <div className="card-holder grid">
    <DragHandle/>
    <AL_ActivityCard01 task={task}
                       activeTaskId={activeTaskId}
                       updateActiveTaskId={updateActiveTaskId}
                       locale={locale}
                       formatter={formatter}
                       sort_coll={sort_coll}
                       updateShowModal={updateShowModal}
                       cUsers={cUsers}
                       updateLive={updateLive}
                       language={language}
                       colors={colors}/>
  </div>
);