import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TimeAgo from "react-timeago";
import Dotdotdot from "react-dotdotdot";
import { Tooltip as ReactTooltip } from "react-tooltip"

import {getCountryName, sendRandomCommentMatrix, setIconGender, showSuccessMsg} from "../../../UI/Globals/PX_Funs";
import MUserProfile from "../../../UI/MUserProfile";

export default class P_NowCard extends React.Component {
  static propTypes = {
    communityId: PropTypes.number,  //It's required, but I will add it when I ll convert the switch in PCDashboard to one
    item: PropTypes.object.isRequired,
    formatter: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    viewProfile: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showModalProfile: false,
      userProfile: null
    }
  }

  componentDidMount(){
    //console.log('P_NowCard.componentDidMount', this.props.item.id);
  }

  // TO-DO Standard names of items in json objects
  render() {
    const {communityId, item, formatter, language, profile} = this.props;

    const i18n = {
      en: {to_do: 'To Do', done: 'Done!', comm_now: 'Community Now!', response_on: 'on',
        reply_on: 'replied', comment_on: 'commented on', liked: 'liked', like_comment: 'comment at',
        like_response: '', like_reply: 'comment at'},
      es: {to_do: 'Pendientes', done: 'Listas!', comm_now: 'Comunidad Ahora!', response_on: 'sobre',
        reply_on: 'respondió en', comment_on: 'comentó en', liked: 'le gustó', like_comment: 'comentario en',
        like_response: 'respuesta a', like_reply: 'respuesta en'}
    };

    let avatar = null;
    let iconCard = null;
    let iconGender = null;
    let country = null;
    let username = null;
    let response = null;
    let typeObj = null;
    let url = null;
    let body = null;
    let question = null;
    let usernameOwner = null;

    // TO-DO this switch can be one because many fields are duplicated, at least for likes
    switch(item.type) {
      case 'response':
        avatar = item.avatar === '' ? '/assets/user.png': item.avatar;
        iconCard = item.question_type === 'Open End' ? 'quote-right' : 'square-check';
        iconGender = item.gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.gender}>{setIconGender(item.gender)}</div></div>;
        const regionCountryResponse = item.state_country !== "" ? 
                                      item.state_country + ', ' + getCountryName(item.country) :  
                                      getCountryName(item.country);
        country = (item.country !== '' && item.country) &&
          <div className="px-country-container" data-tooltip-content={regionCountryResponse}><span>{item.country}</span></div>;
        username = item.username;
        response = i18n[language].response_on;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.user_id +'/' + item.task_id +'/'+  item.question_id +
          '/Now/?flag=true':
          '/community/' + communityId + '/activity/' + item.task_id + '/social/response/' + item.id + '/act_user/' +
          item.active_user_id + '/scroll/' + item.scroll_id + '/-1';
        question = item.question_title;

        if(item.question_type === 'Open End'){
          body = item.response_body;
        } else {
          body = item.response_body.map((answer, index) => {
            return(
              <p key={index} style={{marginBottom:'5px'}}>- {answer}</p>
            )
          });
        }
        break;
      case 'comment':
        avatar = item.comment_avatar === '' ? '/assets/user.png': item.comment_avatar;
        iconCard = 'message';
        iconGender = item.comment_gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.comment_gender}>{setIconGender(item.comment_gender)}</div></div>;
        const regionCountry = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.comment_country) :
          getCountryName(item.comment_country);
        country = (item.comment_country !== '' && item.comment_country) &&
          <div className="px-country-container" data-tooltip-content={regionCountry}>
            <span>{item.comment_country}</span></div>;
        username = item.comment_username;
        response = i18n[language].comment_on;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.active_user_id +'/' + item.task_id +'/' +
          item.question_id +'/Now/?flag=true&itemId=' + item.id + '&itemtype=' + item.type :
          '/community/' + communityId + '/activity/' + item.task_id + '/social/comment/' + item.id + '/act_user/' +
          item.active_user_id + '/scroll/' + item.scroll_id + '/-1';
        question = item.question_title;
        body = item.comment_body;
        usernameOwner = item.response_username;
        break;
      case 'reply':
        avatar = item.reply_avatar === '' ? '/assets/user.png': item.reply_avatar;
        iconCard = 'reply';
        iconGender = item.reply_gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.reply_gender}>{setIconGender(item.reply_gender)}</div></div>;
        const regionCountryReply = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.reply_country) :
          getCountryName(item.reply_country);          
        country = (item.reply_country !== '' && item.reply_country) &&
          <div className="px-country-container" data-tooltip-content={regionCountryReply}>
          <span>{item.reply_country}</span></div>;
        username = item.reply_username;
        response = i18n[language].reply_on;
        question = item.question_reply;
        body = item.reply_body;
        usernameOwner = item.replied_username;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.active_user_id +'/' + item.task_id +'/' +
          item.question_id +'/Now/?flag=true&itemId=' + item.id + '&itemtype=' + item.type :
          '/community/' + communityId + '/activity/' + item.task_id + '/social/reply/' + item.id
          + '/act_user/' + item.active_user_id + '/scroll/' + item.scroll_id + '/-1';
        break;
      case 'likeResponse':
        avatar = item.like_avatar === '' ? '/assets/user.png': item.like_avatar;
        iconCard = 'heart';
        iconGender = item.like_gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.like_gender}>{setIconGender(item.like_gender)}</div></div>;
        const regionCountryLikeResponse = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.like_country) :
          getCountryName(item.like_country);        
        country = (item.like_country !== '' && item.like_country) &&
          <div className="px-country-container" data-tooltip-content={regionCountryLikeResponse}>
            <span>{item.like_country}</span></div>;
        username = item.like_username;
        response = i18n[language].liked;
        typeObj = i18n[language].like_response;
        question = item.question_title;
        usernameOwner = item.comment_username;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.active_user_id +'/' + item.task_id +'/'+  item.question_id +
          '/Now/?flag=true' :
          '/community/' + communityId + '/activity/' + item.task_id + '/social/likeResponse/' + item.id + '/act_user/' +
          item.active_user_id + '/scroll/' + item.question_id + '/-1';
        break;
      case 'like':  // Likes to comments
        avatar = item.like_avatar === '' ? '/assets/user.png': item.like_avatar;
        iconCard = 'heart';
        iconGender = item.like_gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.like_gender}>{setIconGender(item.like_gender)}</div></div>;
        const regionCountryLike = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.like_country) :
          getCountryName(item.like_country);        
        country = (item.like_country !== '' && item.like_country) &&
          <div className="px-country-container" data-tooltip-content={regionCountryLike}>
          <span>{item.like_country}</span></div>;
        username = item.like_username;
        response = i18n[language].liked;
        typeObj = i18n[language].like_comment;
        question= item.question_title;
        usernameOwner = item.comment_username;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.active_user_id +'/' + item.task_id +'/' +
          item.question_id +'/Now/?flag=true&itemId=' + item.comment_liked_id + '&itemtype=' + item.type :
          '/community/' + communityId + '/activity/' + item.task_id + '/social/like/' + item.id + '/act_user/' +
          item.active_user_id + '/scroll/' + item.scroll_id + '/' + item.comment_liked_id;
        break;
      case 'likeReply':
        avatar = item.like_avatar === '' ? '/assets/user.png': item.like_avatar;
        iconCard = 'heart';
        iconGender = item.like_gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.like_gender}>{setIconGender(item.like_gender)}</div></div>;
        const regionCountryLikeReply = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.like_country) :
          getCountryName(item.like_country);       
        country = (item.like_country !== '' && item.like_country) &&
          <div className="px-country-container" data-tooltip-content={regionCountryLikeReply}><span>{item.like_country}</span></div>;
        username = item.like_username;
        response = i18n[language].liked;
        typeObj = i18n[language].like_reply;
        question = item.question_title;
        usernameOwner = item.comment_username;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.active_user_id +'/' + item.task_id +'/' +
          item.question_id +'/Now/?flag=true&itemId=' + item.comment_liked_id + '&itemtype=' + item.type :
          '/community/' + communityId + '/activity/' + item.task_id + '/social/likeReply/' + item.id + '/act_user/' +
          item.active_user_id + '/scroll/' + item.question_id + '/' + item.comment_liked_id;
        break;
    }

    if(!item.show_gender){
      iconGender = null;
    }
    if(!item.show_location){
      country = null;
    }

    const urlSeeResponse = profile.role_1 === 'Collaborator'
      ? '/communities/'+ communityId +'/transcript/'+ item.user_id +'/' + item.task_id +'/'+  item.question_id +'/Now' :
      '';

    return (
      <div className="px-card-social">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className="master-container">
          <div className="left-container">
            <div className="upper-container">
              <div className="avatar-container text-center dropdown">
                <img src={avatar} className="px-thumb-40 img-circle"/>
                <div className="overlay-avatar" data-toggle="dropdown">
                  <FontAwesomeIcon icon={['fas','ellipsis-vertical']}/>
                  <ul className="dropdown-menu dropdown-general-options-no-hide">
                    <li><a onClick={() => this.updateShowModal(item.user_id)}>profile</a></li>
                    <li><a href={urlSeeResponse}>see response</a></li>
                    <li><a href="#">send message</a></li>
                    <li><a href={'mailto:' + item.email}>send email</a></li>
                  </ul>
                </div>
                {
                  item.role_1 === 'Collaborator' &&
                  <div className="moderator-badge">
                  <FontAwesomeIcon icon={['fas','gavel']} data-toggle="dropdown"/>
                  </div>
                }
              </div>
            </div>
            <div className="bottom-container">
              <div className="poblation-container">
                { iconGender }
                { country }
              </div>
            </div>
          </div>

          <div className="right-container">
            <FontAwesomeIcon icon={['fas', iconCard]} className="icon-now"/>
            <span className="date">
              <TimeAgo date={item.created_at} formatter={formatter}/>
            </span>
            <p className="activity-title">
              <a onClick={() => this.updateShowModal(item.user_id)} className="username" style={{cursor:'pointer'}}>
                {username}&nbsp;</a>
              <span className="response">{response}&nbsp;</span>
              {
                item.type !== 'response' &&
                <span>
                  <a onClick={() => this.updateShowModal(item.user_id_to)} className="username-owner"
                     style={{cursor:'pointer'}}>{usernameOwner}'s
                  </a>&nbsp;
                  <span>{item.type === 'reply' && 'comment on'}</span>
                </span>
              }

              <span>{typeObj}&nbsp;</span>
              <a className="activity-name" href={url}>{question.replace(/(<([^>]+)>)/ig,"")}</a>
            </p>
            {
              (item.type === 'response' || item.type === 'comment' || item.type === 'reply') &&
              <div className="response-container">
                <a href={url}>
                  <span className="response-activity">{body}</span>
                  {
                    item.type === 'response' &&
                    <div className="image-container" style={{marginTop:"10px"}}>
                      {
                        item.responseFiles.map(file => {
                          return(
                            <img key={file.id} src={file.url_thumbnail} alt=""/>
                          )
                        })
                      }
                    </div>
                  }
                  <div className="social-stats-container">
                    <div className="likes-container" data-tooltip-content="Likes">
                      <FontAwesomeIcon icon={['fas', 'heart']}/>
                      <div className="counter">{item.like_counter}</div>
                    </div>
                    {
                      item.type !== 'reply' &&
                      <div className="comments-container" data-tooltip-content="Comments">
                        <FontAwesomeIcon icon={['fas', 'message']} className=""/>
                        <div className="counter">{item.comment_counter}</div>
                      </div>
                    }
                  </div>
                </a>
              </div>
            }
          </div>
        </div>
        {
          this.state.showModalProfile &&
            <MUserProfile showModal={this.state.showModalProfile}
                          updateShowModal={this.updateShowModal}
                          user={this.state.userProfile}
                          role_1={profile.role_1}
                          role_2={profile.role_2}
                          language={language}
                          communityId={communityId}/>
        }
      </div>
    );
  }

  updateShowModal = (user_id) => {
    if(user_id){
      const {item} = this.props;
      console.log(user_id);
      this.setState({userProfile: user_id === item.user_id ? item.user_from : item.user_to}, () => {
        this.setState(state => ({showModalProfile: !state.showModalProfile}), () => {
          //if(user_id){
            this.props.viewProfile(user_id);
          //}
        });
      });
    } else {
      this.setState({showModalProfile: false, userProfile: {}})
    }
  };
}
