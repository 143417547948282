import PropTypes from 'prop-types';
import React from 'react';

import Backdrop from "../../UI/Backdrop";
import Notifications from "../../Notifications";
import Conversations from "../../../containers/Community/Conversations";
import News from "../../News";
import InfiniteScroll from "react-infinite-scroller";

export default class RightDrawer extends React.Component {
  static propTypes = {
    showRightDrawer: PropTypes.bool.isRequired,
    rightDrawerToggleHandler: PropTypes.func.isRequired,
    rightDrawerType: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    notifications: PropTypes.array.isRequired,
    currentUserId: PropTypes.number.isRequired,
    profile: PropTypes.object.isRequired,
    updateRightDrawerType: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    notificationsHasMore: PropTypes.bool.isRequired,
    notificationsDate: PropTypes.string.isRequired,
    updateNotificationsDate: PropTypes.func.isRequired,
    profileLang: PropTypes.string,
    sessionLang: PropTypes.string,
    rooms: PropTypes.array,
    active_users: PropTypes.array,
    collaborators: PropTypes.array,
    communityId: PropTypes.number,
    communities: PropTypes.array,
    openPMFromOutside: PropTypes.object,
    updateOpenPMFromOutside: PropTypes.func,
    isLoadingNotifications: PropTypes.bool,
    role_1: PropTypes.string,
    role_2: PropTypes.string,
    colors: PropTypes.object.isRequired,
    segments: PropTypes.array,
    news: PropTypes.array,
    isLoadingNews: PropTypes.bool,
    newsLastVisit: PropTypes.string,
    setLastNewsVisit: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {profile, active_users, collaborators, currentUserId, isLoadingNotifications, role_1, role_2,
      colors, from, rightDrawerType} = this.props;

    const language = this.props.profileLang ? this.props.profileLang : this.props.sessionLang;
    const cssClass = this.props.showRightDrawer ?
      'right-drawer px-notifications-drawer px-messages-drawer right-drawer-open' :
      ' px-notifications-drawer px-messages-drawer right-drawer right-drawer-close';
    let content;
    switch(rightDrawerType) {
        case 'Notifications':
          content = <Notifications notifications={this.props.notifications}
                                   language={language}
                                   rightDrawerToggleHandler={this.props.rightDrawerToggleHandler}
                                   currentUser={active_users !== undefined ?
                                     active_users.find(user => user.user_id === currentUserId) : {}}
                                   communities={this.props.communities}
                                   profile={profile}
                                   communityId={this.props.communityId}
                                   updateRightDrawerType={this.props.updateRightDrawerType}
                                   updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                                   isLoadingNotifications={isLoadingNotifications}
                                   colors={colors}
                                   getNotifications={this.props.getNotifications}
                                   notificationsHasMore={this.props.notificationsHasMore}
                                   notificationsDate={this.props.notificationsDate}
                                   updateNotificationsDate={this.props.updateNotificationsDate}/>
          break;
        case 'News':
          content = <News language={language}
                          rightDrawerToggleHandler={this.props.rightDrawerToggleHandler}
                          colors={colors}
                          news={this.props.news}
                          isLoadingNews={this.props.isLoadingNews}
                          newsLastVisit={this.props.newsLastVisit}
                          setLastNewsVisit={this.props.setLastNewsVisit}/>
          break;
        default:           
          content = <Conversations rooms={[this.props.rooms]}
                                   rightDrawerToggleHandler={this.props.rightDrawerToggleHandler}
                                   active_users={active_users}
                                   collaborators={collaborators}
                                   communityId={this.props.communityId}
                                   currentUserId={currentUserId}
                                   profile={profile}
                                   openPMFromOutside={this.props.openPMFromOutside}
                                   role_1={role_1}
                                   role_2={role_2}
                                   language={language}
                                   from={from}
                                   colors={colors}
                                   segments={this.props.segments}/>
          break;
      }

    return (
      <div>
        <Backdrop show={this.props.showRightDrawer} clicked={this.props.rightDrawerToggleHandler}/>
        <div className={cssClass}>
          { content }
        </div>
      </div>
    );
  }
}
