import PropTypes from 'prop-types';
import React from 'react';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import spanishStrings from "react-timeago/lib/language-strings/es";

import AL_ActivityCard01 from "./ActivityList/AL_ActivityCard01";
import {calcUsersSegments} from "../../UI/Globals/PX_Funs";

export default class ActivityList01 extends React.Component {
  static propTypes = {
    isGrid: PropTypes.bool.isRequired,
    tasks: PropTypes.array.isRequired,
    activeTaskId: PropTypes.number,
    updateActiveTaskId: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    sort_coll: PropTypes.string.isRequired,
    onSortEndTask: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    active_users: PropTypes.array.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    updateLive: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    helpStores: PropTypes.object,
    hasLiveActivities: PropTypes.bool,
    hasTasks: PropTypes.bool,
    communityId: PropTypes.number,
    userId: PropTypes.number
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {isGrid, tasks, activeTaskId, language, sort_coll, active_users,
      gender_as_segment, colors, communityId, userId, hasLiveActivities} = this.props;

    const formatter = language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);
    const tasks_count = tasks.length;

    return (
      <div className="px-list-actv-container">
        <div id="divCardsContainer1" className="principal-card-container" style={{display: isGrid && 'none', overflowY: tasks_count > 1 ? 'scroll' : 'visible' }}>
          <div className="px-support-tooltip top is_hidden px-support-builder">
            <p>This list shows all the activities that you can post to participants.</p>
            <p> Click on any of these cards and edit the corresponding activity in the right side of the screen.</p>
            <i/>
          </div>
          <div className="cards-container">
            <SortableList tasks={tasks}
                          activeTaskId={activeTaskId}
                          updateActiveTaskId={this.props.updateActiveTaskId}
                          locale={language === 'en' ? 'en-au' : language}
                          formatter={formatter}
                          active_users={active_users}
                          gender_as_segment={gender_as_segment}
                          sort_coll={sort_coll}
                          onSortEnd={this.props.onSortEndTask}
                          updateShowModal={this.props.updateShowModal}
                          updateLive={this.props.updateLive}
                          useDragHandle={true}
                          lockAxis="y"
                          useWindowAsScrollContainer={true}
                          language={language}
                          colors={colors}
                          helpStores={this.props.helpStores}
                          hasLiveActivities={hasLiveActivities}
                          hasTasks={this.props.hasTasks}
                          communityId={communityId}
                          userId={userId}
            />
          </div>
        </div>
        <div className="blur" />
        <div className="blur-bottom" />
        <div className="px-support-tooltip right is_hidden px-support-builder" style={{left:'250px', top:'40px'}}>
          <p>Participants will see the Activities listed here only if they are set to live (live button is orange).</p>
          <p>Pre-set any number of Activities here and set them to live only when you are ready for participants to respond it.</p>
          <i/>
        </div>
      </div>
    );
  }
}

const SortableList = SortableContainer(({tasks, activeTaskId, updateActiveTaskId, locale, formatter, sort_coll,
                                          updateShowModal, active_users, gender_as_segment, updateLive, language, colors, helpStores,
                                          hasLiveActivities, hasTasks, communityId, userId
                                        }) => {
  return (
    <div className="cards-holder-container">
      {
        tasks.map((task, index) => {
          if(task.filterVisibility){
            return (
              <SortableItem key={`item-${index}`}
                            index={index}
                            task={task}
                            activeTaskId={activeTaskId}
                            updateActiveTaskId={updateActiveTaskId}
                            locale={locale}
                            formatter={formatter}
                            sort_coll={sort_coll}
                            updateShowModal={updateShowModal}
                            cUsers={calcUsersSegments(active_users, gender_as_segment, task)}
                            updateLive={updateLive}
                            language={language}
                            colors={colors}
                            helpStores={helpStores}
                            hasLiveActivities={hasLiveActivities}
                            hasTasks={hasTasks}
                            communityId={communityId}
                            userId={userId}
                            isFirstItem={index === 0}
              />
            )
          }
        })
      }
    </div>
  );
});

const DragHandle = SortableHandle(() => <div className="grab-actv-card">
  <FontAwesomeIcon style={{height: 'auto', fontSize:'16px'}} icon={['far', 'bars']}/>
</div>);

const SortableItem = SortableElement(({task, activeTaskId, updateActiveTaskId, locale, formatter, sort_coll,
                                        updateShowModal, cUsers, updateLive, language, colors, helpStores, hasLiveActivities, hasTasks,
                                        communityId, userId, isFirstItem}) =>
  <div className="card-holder">
    <DragHandle/>
    <AL_ActivityCard01 task={task}
                       activeTaskId={activeTaskId}
                       updateActiveTaskId={updateActiveTaskId}
                       locale={locale}
                       formatter={formatter}
                       sort_coll={sort_coll}
                       updateShowModal={updateShowModal}
                       cUsers={cUsers}
                       updateLive={updateLive}
                       language={language}
                       colors={colors}
                       helpStores={helpStores}
                       hasLiveActivities={hasLiveActivities}
                       hasTasks={hasTasks}
                       communityId={communityId}
                       userId={userId}
                       isFirstItem={isFirstItem}
    />
  </div>
);