import PropTypes from 'prop-types';
import React from 'react';
import moment from "moment";

export default class TermsPDF extends React.Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      num_participants: 0,
      part_start: 0,
      part_end: 0
    }
  }

  getAiPlanFeatures(aiPlan) {
    switch (aiPlan) {
      case 'aiPlan02':
        return <li>AI Pro features: G-AI processing of text and images responses, which powers
          WonderChat, WonderSearch, Auto-moderation (in eligible tasks) and 4 MicroChat tasks.</li>;
      case 'aiPlan01':
        return <li>AI Advanced features: G-AI processing of text responses, which powers
          WonderChat, WonderSearch, Auto-moderation (in eligible tasks) and 2 MicroChat tasks.</li>;
      case 'aiPlan00':
        return <li>AI Essential features: G-AI processing of text responses, which powers
          WonderChat and WonderSearch.</li>;
      default:
        return '';
    }
  }

  render() {
    const {company, user, subscription, url, data} = this.props;

    return(
      <div>
        <div className="row" style={{marginBottom:'60px'}}>
          <div className="col-xs-12" style={{textAlign:'center'}}>
            <img src="https://pixiebob.s3-ap-southeast-2.amazonaws.com/_invoice/Pixiebob_Logotype_Core_Black.png"
                 className="logo" style={{height:'130px'}} alt=''/>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12" style={{textAlign:'center'}}>
            <p style={{color:'#f3712e',fontWeight:'bolder'}}>SAAS TERMS AND CONDITIONS</p>
            <p style={{color:'#f3712e',fontWeight:'bolder'}}>SCHEDULE</p>
          </div>
        </div>
        <div className="row" style={{marginBottom:'50px'}}>
          <div className="col-xs-12">
            <table className="table">
              <thead>
              <tr style={{textTransform:'uppercase',backgroundColor:'#d9d9d9',color:'#f3712e'}}>
                <th width="20%">TERM</th>
                <th>MEANING</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <span style={{fontWeight:'bolder'}}>we, us</span> or <span style={{fontWeight:'bolder'}}>our</span>
                </td>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <p style={{fontWeight:'bolder'}}>PIXIEBOB PTY LTD (ABN 56 618 250 785)</p>
                  <p>Address: 127 Kent St, Millers Point, 2000, NSW, Australia</p>
                  <p>Email: <a href="mailto:support@pixiebob.io">support@pixiebob.io</a></p>
                </td>
              </tr>
              <tr>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <span style={{fontWeight:'bolder'}}>you</span> or <span style={{fontWeight:'bolder'}}>your</span>
                </td>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <p style={{fontWeight:'bolder'}}>{company.billing_name}</p>
                  <p>Address: {company.billing_address_1}, {company.billing_address_2}, {company.billing_province},
                    &nbsp;{company.billing_country}, {company.billing_postal_code}</p>
                  <p>Phone: {company.billing_telephone}</p>
                  <p>Email: <a href={"mailto:" + user.email}>{user.email}</a></p>
                </td>
              </tr>
              <tr>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <span style={{fontWeight:'bolder'}}>Commencement Date</span>
                </td>
                <td style={{borderBottom:'1px solid #f3712e'}}>{moment().format('DD MMMM YYYY')}</td>
              </tr>
              <tr>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <span style={{fontWeight:'bolder'}}>End Date</span>
                </td>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  The date this Agreement is terminated in accordance with its terms.
                </td>
              </tr>
              <tr>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <span style={{fontWeight:'bolder'}}>Services</span>
                </td>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <p>The Services include the following:</p>
                  <ul>
                    <li>The provision of the Pixiebob platform (available on the Site) in accordance with the
                      Limitations;</li>
                    <li>Site address: <a href={url}>{url}</a></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <span style={{fontWeight:'bolder'}}>Limitations</span>
                </td>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <ul>
                    <li>1 Authorised Admin User</li>
                    <li>{data.num_participants} Participants</li>
                    <li>Unlimited Collaborators</li>
                    <li>Unlimited Activities (i.e. questions you can post)</li>
                    <li>1 Active Community (project where participants are actively engaging with the platform).</li>
                    <li><span style={{fontWeight:'bolder'}}>Admins can access</span>
                      &nbsp;from {data.col_start} to {data.col_end} {data.tz_flag === 'true' ? '(Your local Time Zone)' : data.tz}.
                    </li>
                    <li>
                      <span style={{fontWeight:'bolder'}}>Participants can access</span>
                      &nbsp;from {data.part_start} to {data.part_end} {data.tz_flag === 'true' ? '(Your local Time Zone)' : data.tz}.
                    </li>
                    <li>
                      {/* {subscription.name !== 'No Subs' ? subscription.video_mins : 180} video minutes. */}
                      180 minutes of video (in standalone videos of no more than 5 minutes in length).
                    </li>
                    {this.getAiPlanFeatures(data.ai_plan)}
                  </ul>
                </td>
              </tr>
              <tr>
                <td style={{borderBottom: '1px solid #f3712e'}}>
                  <span style={{fontWeight:'bolder'}}>Fees</span>
                </td>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  For the Services Pixiebob will charge a fixed fee amount of <span style={{fontWeight:'bolder'}}>
                  US$ {data.total_cost_w_ai} {data.country === 'AU' ? '(ex GST)' : ''}</span>
                  &nbsp;for the one-off project detailed in this agreement.
                  <br/>
                  {data.ai_plan  && data.ai_plan !== '' &&
                    <span>
                      This total cost corresponds to <span style={{fontWeight: 'bolder'}}> US$ {data.cash} </span>
                       for the standard platform usage plus <span style={{fontWeight: 'bolder'}}> US$ {data.ai_cost} </span>
                       for our AI features.
                    </span>
                  }

                  {
                    subscription.papt !== 0 && (parseInt(data.assigned_flex) > 0 || parseInt(data.subs_pd) > 0)
                        ? (
                            <span>
                              {" (This fee is offset, in whole or in part, by using: "}
                              {parseInt(data.assigned_flex) > 0 && `${data.assigned_flex} Flexdays, which you have accrued in your account`}
                              {parseInt(data.assigned_flex) > 0 && parseInt(data.subs_pd) > 0 && '; and '}
                              {parseInt(data.subs_pd) > 0 && `${data.subs_pd} Participant*days, available as part of your subscription`}.
                            </span>
                        )
                        : (parseInt(data.assigned_flex) > 0 &&
                            <span>
                              {" (This fee is offset, in whole or in part, by using "} {data.assigned_flex} {" Flexdays, which you have accrued in your account)."}
                            </span>
                        )
                  }
                </td>
              </tr>
              <tr>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  <span style={{fontWeight:'bolder'}}>Payment Terms</span>
                </td>
                <td style={{borderBottom:'1px solid #f3712e'}}>
                  Invoice will be sent when Participants Access ends. Please make payment within 15 days after invoice date.
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}