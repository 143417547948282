import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"
import ReactModal from "react-modal";

import UL_Filters from "../../components/Community/UsersList/UL_Filters";
import UL_List from "../../components/Community/UsersList/UL_List";
import ModalQuickInvite from "../../components/Community/Invitations/ModalQuickInvite";
import UL_Modal from "../../components/Community/UsersList/UL_Modal";
import Loading from "../../components/UI/Globals/Loading";
import BackdropTransparent from "../../components/UI/BackdropTransparent";
import IModalQI from "../../components/Community/Invitations/IModalQI";
import IModalGI from "../../components/Community/Invitations/IModalGI";
import {getCSRF, showSuccessMsg} from "../../components/UI/Globals/PX_Funs";
import axios from "axios";

export default class UsersList extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    from: PropTypes.string,
    papt: PropTypes.number,
    updateActiveUsers: PropTypes.func.isRequired,
    first_activity: PropTypes.object,
    user_account: PropTypes.object,
    updateOpenPMFromOutside: PropTypes.func,
    colors: PropTypes.object.isRequired,
    getPaptCommunity: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      collaborators: [],
      participants: [],
      counter: [],
      filteredUsers: [],
      flagInvited: false,
      flagDraft: false,
      checkEnabled: false,
      checkInvited: false,
      checkPending: false,
      checkDeclined: false,
      checkBlocked: false,
      showModal: false,
      showModalQI: false,
      showModalGI: false,
      question: '',
      action: '',
      button: 'Save',
      selectedUser: {},
      validRemove: false,
      searchTerm: '',
      sortBy: 'Status',
      segments: [],  // Community Segments
      papt: props.papt,
      isLoading: true,
      isSavingFollow: false,
      followUserId: null,
      showModalMobile: false,
      showBackdrop: false,
      reloadUserCards: false,
      invitationUrls: [],
      loadingInvitationUrls: false
    };
  }

  componentDidMount(){
    if(this.props.type === 'Participant'){
      if(this.props.user_account.role_1 === 'Collaborator'){
        this.getParticipants(true);
        this.getInvitationUrls(this.props.community.id)
      } else {
        this.getCollaborators(false);
        if(this.props.community.social_flag || this.props.community.show_part_list){
          this.getParticipants(true);
        }
      }
      //this._getCommunitySegments();
    } else {
      this.getCollaborators(true);
      //this.getCollaborators(this.props.type, true);
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.papt !== this.props.papt){
      this.setState({papt: this.props.papt});
    }
  }

  getInvitationUrls = (community_id) => {
    this.setState({loadingInvitationUrls: true});
    axios.get(`/community_invitation_urls/${community_id}`, getCSRF())
      .then((response) => {
        this.setState({invitationUrls: response.data });
        this.setState({loadingInvitationUrls: false});
      })
      .catch((error) => {
        this.setState({loadingInvitationUrls: false});
        console.log('Failed to fetch invitation URLs', error);
      });
  }

  updateInvitationUrl = (updatedInvitationUrl) => {
    this.setState((prevState) => ({
      invitationUrls: prevState.invitationUrls.map(invUrl =>
        invUrl.id === updatedInvitationUrl.id ? updatedInvitationUrl : invUrl
      )
    }));
  };

  render() {
    const {community, from, profile, language, papt, first_activity, user_account} = this.props;

    if(this.state.isLoading){

    }

    let usersList = <UL_List users={this.state.filteredUsers}
                             segments={community.segments}
                             communityId={community.id}
                             communitySocial={community.social_flag}
                             communityShowPartList={community.show_part_list}
                             updateCommunityUsers={this.updateCommunityUsers}
                             role_1={user_account.role_1}
                             role_2={user_account.role_2}
                             type={this.props.type}
                             handleOpenModal={this.handleOpenModal}
                             setFollow={this.setFollow}
                             from={this.props.from}
                             communityState={community.state}
                             language={language}
                             actionUser={this.actionUser}
                             editUser={this.editUser}
                             first_activity={first_activity}
                             viewProfile={this.viewProfile}
                             isSavingFollow={this.state.isSavingFollow}
                             userActions={this.userActions}
                             followUserId={this.state.followUserId}
                             updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                             colors={this.props.colors}
                             updateShowBackdrop={this.updateShowBackdrop}
                             showBackdrop={this.state.showBackdrop}
                             reloadUserCards={this.state.reloadUserCards}
                             handleReloadUserCards={this.handleReloadUserCards}/>;

    /*const modalQuickInvite = <ModalQuickInvite community={community}
                                               updateCommunityUsers={this.updateCommunityUsers}
                                               type={this.props.type}
                                               segments={community.segments}
                                               role2={user_account.role_2}
                                               language={language}/>;*/

    if(from === 'ParticipantUsersList'){
      if(community.social_flag || community.show_part_list){

      } else {
        usersList = null;
      }
    }

    return (
      <div className="px-coll-list-container">
        <BackdropTransparent show={this.state.showBackdrop} clicked={this.updateShowBackdrop}/>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <UL_Filters role_2={user_account.role_2}
                    type={this.props.type}
                    counter={this.state.counter}
                    flagInvited={this.state.flagInvited}
                    flagDraft={this.state.flagDraft}
                    collaborators={this.state.collaborators}
                    handleChange={this.handleChange}
                    checkEnabled={this.state.checkEnabled}
                    searchTerm={this.state.searchTerm}
                    search={this.search}
                    sortBy={this.state.sortBy}
                    sortByAlphabetically={this.sortByAlphabetically}
                    from={this.props.from}
                    communityId={community.id}
                    social_flag={community.social_flag}
                    show_part_list={community.show_part_list}
                    communityState={community.state}
                    language={language}
                    participants={this.state.participants}
                    maxPapt={community.num_participants}
                    papt={papt}
                    updateShowModalQI={this.updateShowModalQI}
                    updateShowModalGI={this.updateShowModalGI}
                    updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                    showModalMobile={this.state.showModalMobile}
                    updateShowModalMobile={this.updateShowModalMobile}
                    colors={this.props.colors}
                    hasParticipants={community.has_participants}
                    hasCollaborators={community.has_collaborators}
                    helpStores={user_account.help_stores}
                    userId={user_account.profile.user_id}
                    genericLinkToken={community.generic_link_token}
                    allowJoinWGenericLink={community.allow_join_w_generic_link}
        />

        { this.state.isLoading ? <Loading/> : this.state.filteredUsers !== 0 ? usersList : 'No data' }

        {
          this.state.showModal &&
            <UL_Modal showModal={this.state.showModal}
                      user={this.state.selectedUser}
                      handleCloseModal={this.handleCloseModal}
                      actionUser={this.actionUser}
                      question={this.state.question}
                      button={this.state.button}
                      action={this.state.action}
                      role_2={user_account.role_2}
                      segments={community.segments}
                      updateValidRemove={this.updateValidRemove}
                      editUser={this.editUser}
                      language={language}
                      colors={this.props.colors}/>
        }
        {
          this.state.showModalQI &&
          <ReactModal isOpen={this.state.showModalQI} contentLabel="Modal QI" shouldCloseOnOverlayClick={true}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <IModalQI updateShowModalQI={this.updateShowModalQI}
                      community={community}
                      updateCommunityUsers={this.updateCommunityUsers}
                      type={this.props.type}
                      segments={community.segments}
                      role2={user_account.role_2}
                      language={language}
                      colors={this.props.colors}/>
          </ReactModal>
        }
        {
          this.state.showModalGI &&
          <ReactModal isOpen={this.state.showModalGI} contentLabel="Modal GI" shouldCloseOnOverlayClick={true}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <IModalGI updateShowModalGI={this.updateShowModalGI}
                      community={community}
                      updateCommunityUsers={this.updateCommunityUsers}
                      type={this.props.type}
                      segments={community.segments}
                      role2={user_account.role_2}
                      language={language}
                      invitationUrls={this.state.invitationUrls}
                      getInvitationUrls={this.getInvitationUrls}
                      loadingInvitationUrls={this.state.loadingInvitationUrls}
                      updateInvitationUrl={this.updateInvitationUrl}
                      colors={this.props.colors}/>
          </ReactModal>
        }


        {/* modalQuickInvite */}
      </div>
    );
  }

  updateShowBackdrop = () => {
    this.setState(prevState => ({ showBackdrop: !prevState.showBackdrop }));
  }

  updateShowModalMobile = () => {
    this.setState(prevState => ({showModalMobile: !prevState.showModalMobile}));
  };

  updateShowModalQI = () => {
    //console.log('updateShowModalQI', this.state.showModalQI)
    this.setState(prevState => ({showModalQI: !prevState.showModalQI}));
  };

  updateShowModalGI = () => {
    //console.log('updateShowModalQI', this.state.showModalQI)
    this.setState(prevState => ({showModalGI: !prevState.showModalGI}));
  };

  userActions = (type, user) => {
    console.log(type, user);

    switch(type) {
      case 'see responses':
        window.location.href = '/communities/'+ this.props.community.id +'/transcript/'+ user.id +'/all/all/ActAndParCol';
        break;
      case 'send pm':
        //this.props.updateOpenPMFromOutside(user.username, user.user_id);
        break;
      case 'last response':
        $.ajax({
          url: '/responses/get_last_response/' + this.props.community.id + '/' + user.user_id,
          method: 'GET',
          dataType: 'JSON',
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: response => {
            //console.log(response.id);
            if(response && response.id !== undefined){
              window.location.href = '/activities/'+ response.community_id + '/activity/'+ response.activity_id +'/social/' +
                'response/'+ response.id +'/act_user/'+ response.user_id +'/scroll/'+ response.task_id +'/-1';
            } else {

            }
          }
        });
        break;
    }
  };

  updateIsSavingFollow = () => {
    this.setState(prevState => ({isSavingFollow: !prevState.isSavingFollow}));
  };

  viewProfile = userId => {
    $.ajax({
      url: '/profiles/view_profile/'+ this.props.community.id +'/' + userId,
      method: 'GET',
      dataType: 'JSON',
      success: () => {
        console.log('success')
      }
    });
  };

  sortByAlphabetically = (type) => {
    console.log(type);
    let filteredUsers = [...this.state.filteredUsers];

    switch(type){
      case 'Status':
        filteredUsers = this._sortByStatus(filteredUsers);
        break;
      case 'Username':
        filteredUsers = filteredUsers.sort(function (a, b) {
          return (a.username > b.username) ? 1 : ((b.username > a.username) ? -1 : 0);
        });
        break;
      case 'Email':
        filteredUsers = filteredUsers.sort(function (a, b) {
          return (a.email > b.email) ? 1 : ((b.email > a.email) ? -1 : 0);
        });
        break;
      case 'Gender':
        filteredUsers = filteredUsers.sort(function (a, b) {
          return (a.gender > b.gender) ? 1 : ((b.gender > a.gender) ? -1 : 0);
        });
        break;
      case 'Location':
        filteredUsers = filteredUsers.sort(function (a, b) {
          return (a.country > b.country) ? 1 : ((b.country > a.country) ? -1 : 0);
        });
        break;
      case 'Role':
        const collaborators = this.state.filteredUsers.filter(u => u.role_1 === 'Collaborator');
        console.log(collaborators);
        const owners = collaborators.filter(user => user.role_2 === 'Owner');
        const moderators = collaborators.filter(user => user.role_2 === 'Moderator');
        const recruiters = collaborators.filter(user => user.role_2 === 'Recruiter');
        const observers = collaborators.filter(user => user.role_2 === 'Observer');

        filteredUsers = [...owners, ...moderators, ...recruiters, ...observers];
        break;
      case 'Segment':
        const usersSegments = filteredUsers.filter(u => u.segments.length !== 0);
        const usersNoSegments = filteredUsers.filter(u => u.segments.length === 0);

        filteredUsers = [...usersSegments, ...usersNoSegments];
        break;
    }
    console.log(filteredUsers);
    const currentUser = [filteredUsers.find(user => user.user_id === this.props.profile.id)];
    //console.log(currentUser);
    let noCurrentUser = filteredUsers.filter(user => user.user_id !== this.props.profile.id);
    //console.log([...currentUser, ...noCurrentUser]);

    this.setState({filteredUsers: currentUser[0] !== undefined ? [...currentUser, ...noCurrentUser] : filteredUsers});
  };

  search = (type) => {
    const {community, from} = this.props;
    //console.log(type, community.social_flag, community.show_part_list);
    const users = type === 'Participant' ? this._sortByStatus(this.state.participants) :
      this._sortByStatus(this.state.collaborators);
    let filteredUsers = [];

    if(this.state.searchTerm !== '') {
      const q = new RegExp(this.state.searchTerm, 'i');

      if(from === 'ParticipantUsersList') {
        if(community.social_flag){
          filteredUsers = users.filter(user => user.username.match(q));
        } else if (community.show_part_list){
          //console.log('search by pseudo');
          const usersPseudo = users.map(user => ({...user, userPseudo_id: ((user.user_id * 9301 + 49297) % 233280)
              .toString()}));
          //console.log(usersPseudo);
          filteredUsers = usersPseudo.filter(user => user.userPseudo_id.match(q));
        }
      } else {
        filteredUsers = users.filter(user => user.username.match(q));
      }
    } else {
      // Maybe I need to do a sortByAlpha, the last selected in Sort By
      filteredUsers = users;
    }

    this.setState({filteredUsers}, () => {
      this.updateShowModalMobile();
    });
  };

  setFollow = user => {
    this.setState({isSavingFollow: true, followUserId: user.user_id}, () => {
      $.ajax({
        url: '/follow/update/'+ user.user_id +'/' + this.props.community.id,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          role_1: user.role_1
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: users => {
          console.log(users);
          this.updateCommunityUsers(users, this.props.type === 'Participant' ? 'participants' : 'collaborators');

          this.setState({isSavingFollow: false, followUserId: null});
          showSuccessMsg('Success!');
        }
      });
    });
  };

  updateValidRemove = (state) => {
    this.setState({validRemove: state});
  };

  _updateStateInDB = () => {
    // Why I can't use 'action' as a parameter inside AJAX, good question :S
    $.ajax({
      url: '/communities_user/update_state/',
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        communityId: this.props.community.id,
        cuId: this.state.selectedUser.cu_id,
        myAction: this.state.action,
        role_1: this.state.selectedUser.role_1
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: users => {
        console.log(users);
        this.updateCommunityUsers(users, this.props.type === 'Participant' ? 'participants' : 'collaborators');
      }
    });

    this.handleCloseModal();
  };

  _sendReSendInvitation = (action) => {
    $.ajax({
      url: '/communities_user/resend_invitation/',
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        userId: this.state.selectedUser.user_id,
        communityId: this.props.community.id,
        state: this.state.selectedUser.state,
        role_1: this.state.selectedUser.role_1
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: users => {
        console.log(users);
        if(action === 'send'){
          this.updateCommunityUsers(users, this.props.type === 'Participant' ? 'participants' : 'collaborators');
        }
        showSuccessMsg('Success!');
        this.setState({ reloadUserCards: true });
        //$('#snackbar').addClass('show');
        //setTimeout(function(){ $('#snackbar').removeClass('show'); }, 3000);
      }
    });

    this.handleCloseModal();
  };

  actionUser_TO_DELETE = action => {
    console.log('Hey', action);
    switch(action){
      case 'delete':
        this._updateStateInDB();
        break;
      case 'block':
        this._updateStateInDB();
        break;
      case 'unblock':
        this._updateStateInDB();
        break;
      case 'send':
        this._sendReSendInvitation('send');
        break;
      case 'reSend':
        this._sendReSendInvitation('reSend');
        break;
      case 'edit':// No more in use, changed to editUser function
        //console.log('To exec ajax', this.state);
        /*$.ajax({
          url: '/communities_user/update_invitation/',
          method: 'PATCH',
          dataType: 'JSON',
          data: {
            userId: this.state.selectedUser.user_id,
            communityId: this.props.community.id,
            user: {
              first_name: this.state.first_name,
              last_name: this.state.last_name,
              gender: this.state.gender,
              dob: this.state.dob,
              userSegments: this.state.userSegments,
              role_2: this.state.role_2,
              country: this.state.country,
              state_country: this.state.state_country,
            }
          },
          success: function (users) {
            //console.log(users);
            this.updateCommunityUsers(users);
          }.bind(this)
        });*/
        this.handleCloseModal();
        break;
      case 'remove':
        if(this.state.validRemove) {
          this._updateStateInDB();
        }
        break;
    }
  };

  actionUser = () => {
    console.log('Hey', this.state.action);
    switch(this.state.action){
      case 'delete':
        this._updateStateInDB();
        break;
      case 'block':
        this._updateStateInDB();
        break;
      case 'unblock':
        this._updateStateInDB();
        break;
      case 'send':
        this._sendReSendInvitation('send');
        break;
      case 'reSend':
        this._sendReSendInvitation('reSend');
        break;
      case 'edit':// No more in use, changed to editUser function
        //console.log('To exec ajax', this.state);
        $.ajax({
          url: '/communities_user/update_invitation/',
          method: 'PATCH',
          dataType: 'JSON',
          data: {
            userId: this.state.selectedUser.user_id,
            communityId: this.props.community.id,
            user: {
              first_name: this.state.first_name,
              last_name: this.state.last_name,
              gender: this.state.gender,
              dob: this.state.dob,
              userSegments: this.state.userSegments,
              role_2: this.state.role_2,
              country: this.state.country,
              state_country: this.state.state_country,
            }
          },
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: users => {
            console.log(users);
            this.props.updateCommunityUsers(users);
          }
        });
        this.handleCloseModal();
        break;
      case 'remove':
        if(this.state.validRemove) {
          this._updateStateInDB();
        }
        break;
    }
  };

  editUser = (userUpdated) => {
    //console.log(userUpdated);
    $.ajax({
      url: '/communities_user/update_invitation/',
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        userId: userUpdated.user_id,
        //userId: this.state.selectedUser.user_id,
        communityId: this.props.community.id,
        alias: userUpdated.alias,
        user: {
          first_name: userUpdated.first_name,
          last_name: userUpdated.last_name,
          gender: userUpdated.gender,
          dob: userUpdated.dob,
          userSegments: userUpdated.userSegments,
          role_2: userUpdated.role_2,
          country: userUpdated.country,
          state_country: userUpdated.state_country
        }
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: users => {
        //console.log(users);
        this.updateCommunityUsers(users, this.props.type === 'Participant' ? 'participants' : 'collaborators');
      }
    });
    this.handleCloseModal();
  };

  handleOpenModal = (action, selectedUser) => {
    this.setState({selectedUser, action}, () => {
      this.setState({showModal: true});
    })
  };

  handleCloseModal = () => {
    this.setState({showModal: false});
  };

  updateCommunityUsers = (communityUsers, type) => {
    //console.log(communityUsers, type);
    this.setState({
      [type]: communityUsers,
      filteredUsers: communityUsers,
      counter: this._calculateCounter(communityUsers)
    }, () => {
      this.props.getPaptCommunity(this.props.community.id);
      //this._getPapt();
      this.searchByStatus(this.props.type);
    });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log(name, value);
    this.setState({[name]: value }, () => {
      if(name !== 'searchTerm'){
        this.searchByStatus(this.props.type);
      }

      if(name === 'sortBy'){
        this.sortByAlphabetically(value);
        this.updateShowModalMobile();
      }
    });
  };

  searchByStatus = (type) => {
    //console.log(type);
    const users = type === 'Participant' ? this._sortByStatus(this.state.participants) :
      this._sortByStatus(this.state.collaborators);

    const enabled = this.state.checkEnabled ? 'Enabled' : 'All';
    const draft = this.state.checkPending ? 'Draft' : 'All';
    const blocked = this.state.checkBlocked ? 'Blocked' : 'All';
    const declined = this.state.checkDeclined ? 'Declined' : 'All';
    const invited = this.state.checkInvited ? 'Invited' : 'All';
    //console.log(enabled, draft, blocked, declined, invited );

    const filteredUsers = users.filter(user => user.state === enabled || user.state === draft
      || user.state === blocked || user.state === declined || user.state === invited);
    //console.log(filteredUsers);

    if(enabled === 'All' && invited === 'All' && draft === 'All' && declined === 'All' && blocked === 'All'){
      //console.log('Show all users');
      this.setState({filteredUsers: users, flagInvited: false, flagDraft: false});
    } else {
      this.setState({filteredUsers});

      if(filteredUsers.length === 0 && this.state.counter[1] !== 0){
        this.setState({flagInvited: true});
      } else {
        this.setState({flagInvited: false});
      }

      if(filteredUsers.length === 0 && this.state.counter[2] !== 0){
        this.setState({flagDraft: true});
      } else {
        this.setState({flagDraft: false});
      }
    }
  };

  _sortByStatus(users){
    //console.log(users);
    if(this.props.from === 'CollaboratorUsersList'){
      let active = users.filter(user => user.state === 'Enabled');
      let invited = users.filter(user => user.state === 'Invited');
      let draft = users.filter(user => user.state === 'Draft');
      let declined = users.filter(user => user.state === 'Declined');
      let blocked = users.filter(user => user.state === 'Blocked');
      //console.log([...active, ...invited, ...draft, ...declined, ...blocked]);
      return [...active, ...invited, ...draft, ...declined, ...blocked];
    } else {
      return users.filter(user => user.state === 'Enabled');
    }
  }

  _getPapt(){
    $.ajax({
      url: '/users/get_papt',
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: papt => {
        //console.log(papt);
        this.setState({papt});
      }
    });
  }

  getCollaborators = fillCounter => {
    const {user_account} = this.props;

    $.ajax({
      url: '/communities/get_community_users/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      data: {type: 'Collaborator'},
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communityUsers => {
        //console.log('collaborators:', communityUsers);
        let collaborators;
        if(user_account.role_2 === 'Owner' || user_account.role_2 === 'Moderator'){
          collaborators = communityUsers;
        } else {
          collaborators = communityUsers.filter(user => user.state === 'Enabled');
        }
        //console.log('collaborators:', collaborators);

        this.setState({
          isLoading: false,
          //isLoading: this.props.user_account.role_1 !== 'Collaborator',
          collaborators,
          //collaborators: collaborators.filter(collaborator => collaborator.is_welcome)
          //collaborators: communityUsers.filter(collaborator => collaborator.is_welcome)
        }, () => {
          if(this.props.type === 'Collaborator'){
            this.setState({
              filteredUsers:  this._sortByStatus(collaborators),
              counter: fillCounter ? this._calculateCounter(collaborators) : []
            });
          }
        });

        this.props.updateActiveUsers(this._sortByStatus(collaborators));

        /*$.ajax({
          url: '/communities/get_community_users/' + this.props.community.id,
          method: 'GET',
          dataType: 'JSON',
          data: {type: 'Participant'},
          success: communityUsers => {
            //console.log(communityUsers);
            this.props.updateActiveUsers(this._sortByStatus(communityUsers));
          }
        });*/
      }
    });
  };

  getParticipants = fillCounter => {
    this.setState({isLoading: true}, () => {
      $.ajax({
        url: '/communities/get_community_users/' + this.props.community.id,
        method: 'GET',
        dataType: 'JSON',
        data: {type: 'Participant'},
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: communityUsers => {
          //console.log('participants:', communityUsers);
          let participants = communityUsers;
          if(this.props.user_account.role_2 === 'Observer'){
            participants = communityUsers.filter(user => user.state === 'Enabled');
          }

          const sortedUsers = this._sortByStatus(participants);

          this.setState({
            isLoading: false,
            participants: sortedUsers,
            filteredUsers: sortedUsers,
            counter: fillCounter ? this._calculateCounter(participants) : []
          }, () => {
            this.props.updateActiveUsers(this.state.participants);
          });
        }
      });
    })
  };

  _getCommunitySegments(){
    $.ajax({
      url: '/communities/get_segments/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      success: function (segments) {
        this.setState({segments});
      }.bind(this)
    });
  }

  _calculateCounter(cu){
    let cE = 0, cI = 0, cDr = 0, cD = 0, cB = 0;

    cu.forEach(user => {
      if(user.state === 'Enabled')
        cE++;
      if(user.state === 'Invited')
        cI++;
      if(user.state === 'Draft')
        cDr++;
      if(user.state === 'Declined')
        cD++;
      if(user.state === 'Blocked')
        cB++;
    });

    return [cE, cI, cDr, cD, cB];
  }

  handleReloadUserCards = (state) => {
    this.setState({ reloadUserCards: state});
  }
}
