import PropTypes from 'prop-types';
import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MicroChatObjective from "./MicroChatObjective";

export default function MicroChatObjectiveList(props) {
  const { dataObjectives, questionId, language, updateObjective, deleteObjective, updateShowModal, deleteMedia } = props;

  return (
    <SortableList questionId={questionId}
                  dataObjectives={dataObjectives}
                  updateObjective={updateObjective}
                  deleteObjective={deleteObjective}
                  updateShowModal={updateShowModal}
                  deleteMedia={deleteMedia}
                  onSortEnd={onSortEnd}
                  useDragHandle={true}
                  lockAxis="y"
                  useWindowAsScrollContainer={true}
                  language={language} />
  )

  function onSortEnd({ oldIndex, newIndex }) {
    props.onSortEndObjectiveList(oldIndex, newIndex, questionId, 'micro')
  }
}

MicroChatObjectiveList.propTypes = {
  dataObjectives: PropTypes.array.isRequired,
  questionId: PropTypes.number.isRequired,
  updateObjective: PropTypes.func.isRequired,
  deleteObjective: PropTypes.func.isRequired,
  onSortEndObjectiveList: PropTypes.func.isRequired,
  updateShowModal: PropTypes.func.isRequired,
  deleteMedia: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired
};

const SortableList = SortableContainer(({ questionId, dataObjectives, updateObjective, deleteObjective, updateShowModal,
                                          deleteMedia, language }) => {
  return (
      <div>
        {dataObjectives.map((objective, index) => {
          return (
              <SortableItem key={`itemO-${objective.id}`}
                            index={index}
                            questionId={questionId}
                            objective={objective}
                            updateObjective={updateObjective}
                            deleteObjective={deleteObjective}
                            updateShowModal={updateShowModal}
                            deleteMedia={deleteMedia}
                            language={language} />
          )
        })}
      </div>
  );
});

const SortableItem = SortableElement(({ questionId, objective, updateObjective, deleteObjective, updateShowModal,
                                        deleteMedia, language }) => {
  return (
      <MicroChatObjective questionId={questionId}
                          objective={objective}
                          updateObjective={updateObjective}
                          deleteObjective={deleteObjective}
                          updateShowModal={updateShowModal}
                          deleteMedia={deleteMedia}
                          language={language} />
  )
});
