import PropTypes from 'prop-types';
import React from 'react';
import { List, WindowScroller, InfiniteLoader, AutoSizer, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import spanishStrings from "react-timeago/lib/language-strings/es";
import v4 from "uuid";
import ResizeObserver from 'rc-resize-observer'

import ResponseT from "./ResponseT";
import TRGeneral from "../TResponse/TRGeneral";
import TRMedia from "../TResponse/TRMedia";
import TBUserCardBig from "../TBody/TBUserCardBig";
import TBUserCardMed from "../TBody/TBUserCardMed";
import TBActivityHeader from "../TBody/TBActivityHeader";
import TBInfiniteItem from "../TBody/TBInfiniteItem";
import TRNoResponse from "../TResponse/TRNoResponse";

export default class BodyT00 extends React.Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    community: PropTypes.object.isRequired,
    allAvailableActs: PropTypes.array.isRequired,
    responses: PropTypes.array.isRequired,
    updateResponse: PropTypes.func.isRequired,
    saveResponseLike: PropTypes.func.isRequired,
    isSavingLikeResponse: PropTypes.bool.isRequired,  // Maybe move it to ResponseT
    language: PropTypes.string.isRequired,
    cmntyLanguage: PropTypes.string.isRequired,
    consolidated: PropTypes.string.isRequired,
    objSearch: PropTypes.object.isRequired,
    responsesConsolidated: PropTypes.array.isRequired,
    role_1: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    toggleTaskIsMax: PropTypes.func.isRequired,
    getResponsesConsolidatedSingle: PropTypes.func.isRequired,
    toggleUserIsMax: PropTypes.func.isRequired,
    toggleActivityIsMax: PropTypes.func.isRequired,
    hasMoreItems: PropTypes.bool.isRequired,
    updateSVG: PropTypes.func.isRequired,
    handleShowLoadingConsolidated: PropTypes.func.isRequired,
    hasMoreItemsConsolidated: PropTypes.bool.isRequired,
    getResponsesConsolidated_01: PropTypes.func.isRequired,
    handleShowModalConsolidated: PropTypes.func.isRequired,
    updateSVGMulti: PropTypes.func.isRequired,
    isLoadingResponsesConsolidated: PropTypes.bool.isRequired,
    showAllVerbatim: PropTypes.bool.isRequired,
    handleVerbatimVisibility: PropTypes.func.isRequired,
    updateSizeResponseSlider: PropTypes.func.isRequired,
    updateSizeResponse: PropTypes.func.isRequired,
    updateResponseKeywords: PropTypes.func.isRequired,
    autosizingRenderInput: PropTypes.func.isRequired,
    handleVerbatimShows: PropTypes.func.isRequired,
    regenerateResponseVerbatim: PropTypes.func.isRequired,
    updateShowComments: PropTypes.func.isRequired,
    qc: PropTypes.array.isRequired,
    updateResponseComments: PropTypes.func.isRequired,
    updateUserResponses: PropTypes.func.isRequired,
    scrollFirstTime: PropTypes.bool.isRequired,
    updateScrollFirstTime: PropTypes.func.isRequired,
    communityState: PropTypes.string.isRequired,
    isFirstFFComment: PropTypes.object.isRequired,
    updateIsFirstFFComment: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    getResponses: PropTypes.func.isRequired,
    showModalExportHandler: PropTypes.func.isRequired,
    communitySegments: PropTypes.array.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    communityStart: PropTypes.string,
    item_scroll: PropTypes.object,
    getUrlsToDownload: PropTypes.func,
    resShowCommentId: PropTypes.number.isRequired,
    headerShowUserId: PropTypes.number.isRequired,
    headerShowActId: PropTypes.number.isRequired,
    resetResizeIds: PropTypes.func.isRequired,
    resMaxMinId: PropTypes.number.isRequired,
    lastDeleted: PropTypes.bool.isRequired,
    updateLastDeleted: PropTypes.func.isRequired,
    showVerOriginal: PropTypes.bool.isRequired,
    updateShowVerOriginal: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    projectType: PropTypes.string
  };

  constructor(props) {
    super(props)
    this.state = {
      cache: new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {responses, resShowCommentId, headerShowUserId, headerShowActId, resMaxMinId, lastDeleted,
      showVerOriginal} = this.props;

    if(prevProps.showVerOriginal !== this.props.showVerOriginal){
      if(showVerOriginal){
        this.commentForceUpdateGrid();
        this.props.updateShowVerOriginal();
      }
    }

    if(prevProps.lastDeleted !== lastDeleted){
      // console.log('lastDeleted:', lastDeleted);
      if(lastDeleted){
        this.commentForceUpdateGrid();
        // console.log('return lastDeleted to false');
        this.props.updateLastDeleted();
      }
    }

    if(prevProps.responses !== responses){
      if(responses.length !== 0){
        //console.log('BodyT00.componentDidUpdate RESIZE VIRTUAL, resShowCommentId:', resShowCommentId)
        //const responseToResize = responses.find(r => r.id === resShowCommentId);
        //console.log('responseToResize:', responseToResize)

        //if(responseToResize !== undefined){
          //this.recalculateRowHeight(20)
          //this.commentForceUpdateGrid()
          //console.log('RE CALCULATE')
          //this.props.resetResizeIds()
          //this.state.cache.clearAll()

          /*this.windowScroller.updatePosition()
          if(this._list){
            console.log('Exec forceUpdateGrid ')
            this._list.forceUpdateGrid();
          }*/
          //window.scrollTo(window.scrollX, window.scrollY + 5);
          //window.scrollTo(window.scrollX, window.scrollY - 5);
          //this.setState({recalculate: true})
          //this.setState({counter: 1})
          //this.props.resetResizeIds();
          //this.state.cache.clearAll();
          //console.log('this._list:', this._list)
          /*if(this._list){
            console.log('Exec forceUpdateGrid ')
            this._list.forceUpdateGrid();
          }*/
        //}



        /*if(responseToResize !== undefined){
          console.log('responseToResize.showComments:', responseToResize.showComments);
          if(!responseToResize.showComments){
            console.log('Comment resize');
            this.state.cache.clearAll();
            if(this._list){
              this._list.forceUpdateGrid();
            }
          } else {
            const headerCard = responses.filter(r => r.type === 'user').find(r => r.user.id === headerShowUserId);
            const headerActivity = responses.filter(r => r.type === 'activity').find(r => r.activity.id === headerShowActId);
            console.log('headerCard:', headerCard, 'headerActivity:', headerActivity);

            //this.props.resetResizeIds()
            this.state.cache.clearAll();
            if(this._list){
              this._list.forceUpdateGrid();
            }

            if(headerCard !== undefined) {
              this.commentForceUpdateGrid();
            }
          }
        } else {
          const headerCard = responses.filter(r => r.type === 'user').find(r => r.user.id === headerShowUserId);
          const headerActivity = responses.filter(r => r.type === 'activity').find(r => r.activity.id === headerShowActId);
          const responseToResize = responses.filter(r => r.type === 'response').find(r => r.id === resMaxMinId);
          console.log('headerCard:', headerCard, 'headerActivity:', headerActivity, 'responseToResize:', responseToResize)

          if(headerCard !== undefined) {
            this.commentForceUpdateGrid();
          }

          if(headerActivity !== undefined) {
            this.commentForceUpdateGrid();
          }

          if(responseToResize !== undefined) {
            this.commentForceUpdateGrid();
          }
        }*/
      }
    }

    if(prevProps.showAllVerbatim !== this.props.showAllVerbatim){
      // console.log('showAllVerbatim recompute');
      this.state.cache.clearAll();
      if(this._list){
        this._list.forceUpdateGrid();
      }
    }
  }

  render(){
    const { users, allAvailableActs, responses, consolidated, objSearch, responsesConsolidated, language, cmntyLanguage,
      colors, hasMoreItems, role_2, hasMoreItemsConsolidated, projectType} = this.props

    let responsesView;
    let flagDivClass = false;
    let normalBody = true;

    if(consolidated === ''){
      if(consolidated === '' || consolidated === 'general'){
        normalBody = false;
        responsesView = <InfiniteLoader
          isRowLoaded={this.isRowLoaded}
          loadMoreRows={hasMoreItems ? this.loadMoreRows : () => {}}
          rowCount={10000000}
          threshold={10}
          minimumBatchSize={10}
          ref={ref => (this.infiniteLoaderRef = ref)}
        >
          {({ onRowsRendered, registerChild }) => (
            <WindowScroller>
              {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <AutoSizer disableHeight>
                  {({ width }) => {
                    this._registerList = registerChild;
                    return(
                      <List
                        autoHeight
                        width={width}
                        height={height}
                        deferredMeasurementCache={this.state.cache}
                        rowHeight={this.state.cache.rowHeight}
                        rowRenderer={this.renderRow}
                        rowCount={responses.length}
                        ref={this._setListRef}
                        onRowsRendered={onRowsRendered}
                        //ref={registerChild}
                        style={{paddingTop: '50px',boxSizing: 'content-box'}}
                        containerStyle={{position: 'relative', overflow: 'visible'}}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        scrollTop={scrollTop}
                      />
                    );}}
                </AutoSizer>
              )}
            </WindowScroller>
          )}
        </InfiniteLoader>


        /*responsesView = <React.Fragment>
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={hasMoreItems ? this.loadMoreRows : () => {}}
            rowCount={10000000}
            threshold={10}
            minimumBatchSize={10}
            ref={ref => (this.infiniteLoaderRef = ref)}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop }) => (
                  <AutoSizer disableHeight>
                    {({ width }) => {
                      this._registerList = registerChild;
                      return(
                        <List
                          autoHeight
                          width={width}
                          height={height}
                          deferredMeasurementCache={this.state.cache}
                          rowHeight={this.state.cache.rowHeight}
                          rowRenderer={this.renderRow}
                          rowCount={responses.length}
                          ref={this._setListRef}
                          onRowsRendered={onRowsRendered}
                          //ref={registerChild}
                          style={{paddingTop: '50px',boxSizing: 'content-box'}}
                          containerStyle={{position: 'relative', overflow: 'visible'}}
                          isScrolling={isScrolling}
                          onScroll={onChildScroll}
                          scrollTop={scrollTop}
                        />
                      );}}
                </AutoSizer>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        </React.Fragment>;*/
      } else {
        normalBody = true;
        // IMAGES OR VIDEOS - 1 user
        let allMedias = [];
        allAvailableActs.map(activity => {
          const foundActivityId = objSearch.idActs.find(actId => actId === activity.id) ;

          if(foundActivityId !== undefined){
            const tasks = activity.questions.map((task, index) => {
              const taskFoundId = objSearch.idTasks.find(idTask => idTask === task.id);

              if(taskFoundId !== undefined){
                if(responsesConsolidated.length !== 0){
                  const isFound = responsesConsolidated.find(c => c.task_id === task.id); // responses consolidated
                  //console.log(isFound);
                  if(isFound !== undefined){
                    let arrayResponses = [];

                    switch(task.task_type){
                      case 'Open End':
                      case 'Canvas':
                        arrayResponses = isFound.responses;
                        break;
                      case 'Choice':
                        arrayResponses = isFound.responses.map(response => response.data.answersId);
                        break;
                    }

                    if(isFound.task_type !== 'Choice'){
                      const mediaArrays = [];

                      arrayResponses.forEach(response => {
                        //console.log(response.data);
                        if(consolidated === 'images'){
                          response.data.forEach(attachment => {
                            //console.log(attachment);
                            const user = users.find(user => user.id === attachment.user_id);

                            mediaArrays.push({...attachment, activityTitle: activity.title, taskTitle: task.title, user: user});
                          });
                        } else {
                          //console.log(response.data);
                          if(response.data.video_thumbnail && response.data.video_url){
                            const user = users.find(user => user.id === response.data.user_id);

                            mediaArrays.push({...response.data, activityTitle: activity.title, taskTitle: task.title, user: user});
                          }
                        }
                      });
                      //console.log(mediaArrays);
                      if(mediaArrays.length !== 0){
                        mediaArrays.forEach(media => allMedias.push(media));
                      }
                    }
                  }
                }
              }
            });

            const user = users.find(user => user.id === objSearch.idPars[0]);

            return(
              <div key={activity.id} style={{marginTop:'50px'}}>
                <TBUserCardBig user={user}
                               showModalExportHandler={() => {}}
                               from={'Collaborator'}
                               isVirtualized={false}
                               language={language}
                               getUrlsToDownload={this.props.getUrlsToDownload}
                               colors={colors}/>
                <TRMedia key={v4()}
                         cardinal={-1}
                         objSearch={objSearch}
                         from={'single'}
                         consolidated={consolidated}
                         taskType={'Open End'}
                         media={allMedias}
                         taskTitle={''}
                         language={language}
                         colors={colors}/>
                {/*tasks*/}
              </div>
            )
          }
        });

        const user = users.find(user => user.id === objSearch.idPars[0]);

        responsesView = <div style={{marginTop:'50px'}}>
          <TBUserCardBig user={user}
                         showModalExportHandler={() => {}}
                         from={'Collaborator'}
                         isVirtualized={false}
                         language={language}
                         getUrlsToDownload={this.props.getUrlsToDownload}
                         colors={colors}/>
          <TRMedia key={v4()}
                   cardinal={-1}
                   objSearch={objSearch}
                   from={'single'}
                   consolidated={consolidated}
                   taskType={'Open End'}
                   media={allMedias}
                   taskTitle={''}
                   language={language}
                   colors={colors}/>
        </div>
      }
    } else {
      // GENERAL CONSOLIDATED - 2 or more users
      flagDivClass = true;
      normalBody = true;

      responsesView = responsesConsolidated.map(item => {
        //console.log('item:', item)
        let comp

        if(item.type === 'activity'){
          comp = <TBActivityHeader key={v4()}
                                   title={item.title}
                                   activityId={item.id}
                                   instructions={item.instructions}
                                   from='Collaborator'
                                   showModalExportHandler={() => {}}
                                   role_2={role_2}
                                   isVirtualized={false}
                                   colors={colors}/>;
        } else {
          if(consolidated === 'general'){
            const isFound = responsesConsolidated.find(c => c.task_id === item.task.id); // responses consolidated
            let arrayResponses = [];

            switch(item.task.task_type){
              case 'Open End':
              case 'Multimedia':
              case 'Canvas':
                arrayResponses = isFound.responses;
                break;
              case 'Choice':
                arrayResponses = isFound.responses.map(response => response.data.answersId);
                break;
            }
            //console.log('isFound:', isFound);
            comp = <TRGeneral key={v4()}
                              cardinal={1}
                              usersId={objSearch.idPars.length === 1 ? this.props.users.map(u => u.id) : objSearch.idPars}
                              responsesConsolidated={responsesConsolidated}
                              taskTile={item.task.title}
                              taskType={item.task.task_type}
                              responses={arrayResponses}
                              language={language}
                              cmntyLanguage={cmntyLanguage}
                              taskChoices={(item.task.task_type === 'Choice') ? item.task.data.answers : []}
                              taskId={item.task.id}
                              isVirtualized={false}
                              graph={isFound.graph}
                              updateSVGMulti={this.props.updateSVGMulti}
                              task_id={isFound.task_id}
                              colors={colors}/>
          }
        }

        return comp
      });
    }

    if(normalBody){
      //const isFound = responsesConsolidated.find(c => c.task_id === item.task.id);

      responsesView = <TBInfiniteItem items={responsesConsolidated}
                                      role_2={role_2}
                                      consolidated={consolidated}
                                      objSearch={objSearch}
                                      users={users}
                                      language={language}
                                      updateSVGMulti={this.props.updateSVGMulti}
                                      getResponsesConsolidated_01={this.props.getResponsesConsolidated_01}
                                      isLoadingResponsesConsolidated={this.props.isLoadingResponsesConsolidated}
                                      hasMoreItemsConsolidated={hasMoreItemsConsolidated}
                                      colors={colors}
                                      cmntyLanguage={cmntyLanguage}/>
    }

    return (
      <div className={`container-fluid px-transcript-container ${flagDivClass && 'mg-top-50'}`}>
        { responsesView }
      </div>
    )
  }

  recalculateRowHeight = (index) => {
    // console.log('recalculateRowHeight, index:', index, this.cellMeasurerRef);
    this.state.cache.clear(index);
    this._list.recomputeRowHeights(index);
    //this._list.forceUpdateGrid();
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    this.props.getResponses('InfiniteLoader', startIndex, stopIndex);
    //console.log('BodyT00.loadMoreRows', startIndex, stopIndex);
    //this.props.getResponsesSelectedUsersTEST(this.props.objSearch, startIndex, stopIndex);
    /*return fetch(`path/to/api?startIndex=${startIndex}&stopIndex=${stopIndex}`)
      .then(response => {
        // Store response data in this.listData
      });*/
  };

  isRowLoaded = ({ index }) => {
    return !!this.props.responses[index];
  };

  renderRow = ({ index, key, style, parent }) => {
    const {responses, objSearch, role_2, cmntyLanguage, colors, company} = this.props
    //console.log(this.props.responses[index]);
    let userCard = null;
    if(responses[index].type === 'user'){
      // Is TBUserCardMed still useful?
      userCard = <TBUserCardMed user={responses[index].user}
                                from='Collaborator'
                                showModalExportHandler={this.props.showModalExportHandler}
                                activity={responses[index].activity}
                                language={this.props.language}
                                role_2={role_2}
                                colors={colors}/>;

      if(objSearch.idPars.length === 1){
        if(objSearch.idActs.length === 1){

        } else {
          userCard = <TBUserCardBig user={responses[index].user}
                                    showModalExportHandler={this.props.showModalExportHandler}
                                    from={'Collaborator'}
                                    isVirtualized={true}
                                    isMax={responses[index].isMax}
                                    toggleUserIsMax={this.props.toggleUserIsMax}
                                    language={this.props.language}
                                    getUrlsToDownload={this.props.getUrlsToDownload}
                                    role_2={this.props.role_2}
                                    colors={this.props.colors}
                                    doRecomputeRowHeights={this.doRecomputeRowHeights}
                                    index={index}
          />
        }
      } else {
        if(objSearch.idActs.length === 1){
          if(objSearch.idTasks.length === 1){
            userCard = null;
          } else {
            userCard = <TBUserCardBig user={responses[index].user}
                                      showModalExportHandler={this.props.showModalExportHandler}
                                      from={'Collaborator'}
                                      isVirtualized={true}
                                      isMax={responses[index].isMax}
                                      toggleUserIsMax={this.props.toggleUserIsMax}
                                      language={this.props.language}
                                      getUrlsToDownload={this.props.getUrlsToDownload}
                                      role_2={this.props.role_2}
                                      colors={this.props.colors}
                                      doRecomputeRowHeights={this.doRecomputeRowHeights}
                                      index={index}/>
          }
        } else {
          userCard = <TBUserCardBig user={responses[index].user}
                                    showModalExportHandler={this.props.showModalExportHandler}
                                    from={'Collaborator'}
                                    isVirtualized={true}
                                    isMax={responses[index].isMax}
                                    toggleUserIsMax={this.props.toggleUserIsMax}
                                    language={this.props.language}
                                    getUrlsToDownload={this.props.getUrlsToDownload}
                                    role_2={this.props.role_2}
                                    colors={this.props.colors}
                                    doRecomputeRowHeights={this.doRecomputeRowHeights}
                                    index={index}/>
        }
      }
    }

    let activityCard = null;
    if(responses[index].type === 'activity'){
      activityCard = <TBActivityHeader title={this.props.responses[index].activity.title}
                                       activityId={this.props.responses[index].activity.id}
                                       instructions={this.props.responses[index].activity.instructions}
                                       from='Collaborator'
                                       isVirtualized={true}
                                       showModalExportHandler={() => {}}
                                       role_2={this.props.role_2}
                                       isMax={this.props.responses[index].isMax}
                                       toggleActivityIsMax={this.props.toggleActivityIsMax}
                                       colors={this.props.colors}/>;

      if(objSearch.idPars.length === 1){
        if(objSearch.idActs.length === 1){
          activityCard = null;
        } else {

        }
      } else {
        if(objSearch.idActs.length === 1){
          if(objSearch.idTasks.length === 1){

          }
        } else {

        }
      }
    }

    let taskMode = 'common';
    if(responses[index].type === 'response'){
      if(objSearch.idPars.length === 1){
        if(objSearch.idActs.length === 1){

        }
      } else {
        if(objSearch.idActs.length === 1){
          if(objSearch.idTasks.length === 1){
            taskMode = 'nUsers1Task';
          } else {
            taskMode = 'nUsersNTasks';
          }
        } else {
          taskMode = 'nUsersNActs';
        }
      }
    }

    let taskCard = null;
    if(responses[index].type === 'taskTitle'){
      taskCard = <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2">
        <div className="task-title-container">
          <div className="task-counter" style={{backgroundColor: colors.color0[1] }}>
            <span className="number">1</span>
          </div>
          <div className="title-task" style={{ color: colors.color0[1] }}>
            <span>{responses[index].title.replace(/(<([^>]+)>)/ig,"")}</span>
          </div>
        </div>
      </div>;
    }

    const formatter = this.props.language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);

    return(
      <CellMeasurer
        key={key}
        cache={this.state.cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
        ref={(ref) => this.cellMeasurerRef = ref}
      >
        {
          ({ measure, registerChild }) => (
            <div ref={registerChild}
                 style={style} className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ResizeObserver onResize={measure}>
                <div>
                { this.props.responses[index].type === 'user' && userCard }
                { this.props.responses[index].type === 'activity' && activityCard }
                { this.props.responses[index].type === 'taskTitle' && taskCard }
                { this.props.responses[index].type === 'response' &&
                this.props.responses[index].state !== 'Blank' ? (
                  <ResponseT cardinal={this.props.responses[index].task.position + 1}
                             index={index}
                             usersId={this.props.users.map(user => user.id)}
                             user={this.props.responses[index].user}
                             task={this.props.responses[index].task}
                             role_1={this.props.role_1}
                             role_2={this.props.role_2}
                             response={this.props.responses[index]}
                             communityState={this.props.communityState}
                             updateResponse={this.props.updateResponse}
                             saveResponseLike={this.props.saveResponseLike}
                             isSavingLikeResponse={this.props.isSavingLikeResponse}
                             doRecomputeRowHeights={this.doRecomputeRowHeights}
                             language={this.props.language}
                             toggleTaskIsMax={this.props.toggleTaskIsMax}
                             getResponsesConsolidatedSingle={this.props.getResponsesConsolidatedSingle}
                             taskMode={taskMode}
                             updateSVG={this.props.updateSVG}
                             recalculateRowHeight={this.recalculateRowHeight}
                             consolidated={this.props.consolidated}
                             handleShowLoadingConsolidated={this.props.handleShowLoadingConsolidated}
                             objSearch={this.props.objSearch}
                             handleShowModalConsolidated={this.props.handleShowModalConsolidated}
                             showAllVerbatim={this.props.showAllVerbatim}
                             handleVerbatimVisibility={this.props.handleVerbatimVisibility}
                             updateSizeResponseSlider={this.props.updateSizeResponseSlider}
                             updateSizeResponse={this.props.updateSizeResponse}
                             updateResponseKeywords={this.props.updateResponseKeywords}
                             autosizingRenderInput={this.props.autosizingRenderInput}
                             handleVerbatimShows={this.props.handleVerbatimShows}
                             regenerateResponseVerbatim={this.props.regenerateResponseVerbatim}
                             updateShowComments={this.props.updateShowComments}
                             qc={this.props.qc}
                             updateResponseComments={this.props.updateResponseComments}
                             updateUserResponses={this.props.updateUserResponses}
                             scrollFirstTime={this.props.scrollFirstTime}
                             updateScrollFirstTime={this.props.updateScrollFirstTime}
                             item_scroll={this.props.item_scroll}
                             showModalExportHandler={this.props.showModalExportHandler}
                             colors={colors}
                             cmntyLanguage={cmntyLanguage}
                             commentForceUpdateGrid={this.commentForceUpdateGrid}
                             company={company}
                             projectType={this.props.projectType}
                             community={this.props.community}
                             from={'CTV'}
                /> ) : (
                  this.props.responses[index].type === 'response' ? (
                  <TRNoResponse cardinal={this.props.responses[index].task.position + 1}
                                task={this.props.responses[index].task}
                                user={this.props.responses[index].user}
                                role_1={this.props.role_1}
                                role_2={this.props.role_2}
                                communityState={this.props.communityState}
                                isFirstFFComment={this.props.isFirstFFComment}
                                updateIsFirstFFComment={this.props.updateIsFirstFFComment}
                                language={this.props.language}
                                updateResponseComments={this.props.updateResponseComments}
                                taskMode={taskMode}
                                activitySegments={this.props.responses[index].activitySegments}
                                gender_as_segment={this.props.gender_as_segment}
                                formatter={formatter}
                                activityStart={this.props.responses[index].activityStart}
                                qc={this.props.qc}
                                cmntyLanguage={cmntyLanguage}
                                commentForceUpdateGrid={this.commentForceUpdateGrid}
                                colors={colors}
                                projectType={this.props.projectType}
                                community={this.props.community}
                                recalculateRowHeight={this.recalculateRowHeight}
                                from={'CTV'}
                                index={index}
                  /> ) : (
                    <div style={{display: 'none'}}></div>
                  )
                )}
                </div>
              </ResizeObserver>
            </div>
          )
        }
      </CellMeasurer>
    )
  };

  _setListRef = ref => {
    this._list = ref;
    this._registerList(ref);
  };

  commentForceUpdateGrid = () => {
    //console.log('this.props.isFirstFFComment:', this.props.isFirstFFComment)
    //if(this.props.resShowCommentId !== -1){
      /*console.log('RESIZE');
      this.props.resetResizeIds();
      this.state.cache.clearAll();
      if(this._list){
        this._list.forceUpdateGrid();
      }*/
    //}
  }

  doRecomputeRowHeights = (index) => {
    // console.log('doRecomputeRowHeights', this._list, index);
    if(this._list){
      this.state.cache.clearAll();
      this._list.recomputeRowHeights(index);
    }
  };
}
