import PropTypes from 'prop-types';
import React from 'react';

import Layout from "../../Layout";
import Dashboards from "../../../containers/Community/Dashboards";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";

export default class DashboardPage extends React.Component {
  static propTypes = {
    origin: PropTypes.string.isRequired,
    user_account: PropTypes.object.isRequired,
    community: PropTypes.object.isRequired,
    activities: PropTypes.array.isRequired,
    outside: PropTypes.object
  };

  constructor(props) {
    super(props);
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    //console.log('DashboardPage props.origin:', props.origin);
    let activeLink = props.origin !== 'ActAndPar' ? props.origin : isMobile ? 'Activities' : 'Matrix';
    //console.log('DashboardPage window.performance:', window.performance);
    const keyActiveLink = 'activeLink_' + this.props.user_account.id + '_' + this.props.community.id;

    if(localStorage.getItem(keyActiveLink)){
      //console.log('constructor.localStorage.getItem(\'activeLink\'):', localStorage.getItem(keyActiveLink));
      activeLink = localStorage.getItem(keyActiveLink);
    }

    this.state = {
      isMobile,
      activeLink,
      firstTimeCallAlerts: false,
      firstTimeNow: false,
      communitiesTop: [],
      notificationsCounter: 0,
      openPMFromOutside: {username: '', userId: null, type: null, flag: false},
      isBeaconInit: false //Keeps track of any instances of Head Scout Beacons initialised.
    };
  }

  componentDidMount() {
    const {outside} = this.props;
    //TO-DO pass this method to Layout
    this._getCommunitiesTop();
    this._getNotificationsCounter();

    if(this.state.activeLink === 'Participants' || this.state.activeLink === 'Matrix'){
      this.setState({firstTimeCallAlerts: true});
    }

    if(outside){
      if(outside.isOpen === 'true'){
        this.updateOpenPMFromOutside(outside.type, outside.username, outside.userId);
      }
    }

    window.addEventListener("beforeunload", this.onRefreshPage);
  }

  componentWillUnmount() {
    this.onRefreshPage();
    window.removeEventListener("beforeunload", this.onRefreshPage);
  }

  onRefreshPage = () => {
    const key = 'activeLink_' + this.props.user_account.id + '_' + this.props.community.id;
    localStorage.removeItem(key);
    localStorage.setItem(key, this.state.activeLink);
  }

  //TO-DO actually from should be 'DashboardPage' or 'CommunityDashboardPage'
  render() {
    const {user_account, community, activities, origin} = this.props;
    const hsBeaconId = this.state.activeLink === 'Matrix' ?
      '9e42d81a-a659-4b78-bd73-b40293e93a8d' : '5da91d2e-98da-40b2-9c9b-b5d0e45bd424';
    const hsBeaconSuggestionsGeneral = {
      en: ['5f1fca1f04286306f8077b16', '5f1fcdf904286306f8077b36', '5f1fd2f104286306f8077b64', '5f1fd36d2c7d3a10cbab66b5',
        '5f1fd5162c7d3a10cbab66c9', '5f1ff4232c7d3a10cbab67ff', '5f1ff5de04286306f8077c6e', '5f1ff6c92c7d3a10cbab6816',
        '5f1ff75b2c7d3a10cbab681f', '5f1ff81d04286306f8077c86']
    };

    const hsBeaconSuggestionsMatrix = {
      en: ['5f1fca1f04286306f8077b16', '5f1fcdf904286306f8077b36', '5f1ff8c92c7d3a10cbab683f', '5f1ffaa42c7d3a10cbab6871',
        '5f1ff9dc04286306f8077c9d', '5f1fd2f104286306f8077b64', '5f1fd36d2c7d3a10cbab66b5', '5f1fd5162c7d3a10cbab66c9',
        '5f1ff4232c7d3a10cbab67ff', '5f1ff5de04286306f8077c6e']
    };

    const hsBeaconSuggestions = this.state.activeLink === 'Matrix' ? hsBeaconSuggestionsMatrix : hsBeaconSuggestionsGeneral

    return (
      <Layout from="CommunityPage"
              user_account={user_account}
              leftMenuActive={'Dashboard'}
              community={community}
              profile={user_account.profile}
              sessionLang={user_account.language}
              notifications={[]}
              communities={this.state.communitiesTop}
              notificationsCounter={this.state.notificationsCounter}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              updateActiveLink={this.updateActiveLink}
              colors={user_account.all_colors}>

        {/*<HelpScoutBeacon beaconId={hsBeaconId}*/}
        {/*                 user_account={user_account}*/}
        {/*                 colors={user_account.all_colors}*/}
        {/*                 language={user_account.language}*/}
        {/*                 isBeaconInit={this.state.isBeaconInit}*/}
        {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
        {/*                 suggestions={hsBeaconSuggestions}*/}
        {/*                 hideFABOnDesktop={!this.props.user_account.profile.is_help}/>*/}

        <Dashboards community={community}
                    activeLink={this.state.activeLink}
                    updateActiveLink={this.updateActiveLink}
                    user_account={user_account}
                    activities={activities}
                    firstTimeCallAlerts={this.state.firstTimeCallAlerts}
                    firstTimeNow={this.state.firstTimeNow}
                    origin={origin}
                    isBeaconInit={this.state.isBeaconInit}
                    updateIsBeaconInit={this.updateIsBeaconInit}
                    isMobile={this.state.isMobile}
                    updateOpenPMFromOutside={this.updateOpenPMFromOutside}/>
      </Layout>
    );
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  updateOpenPMFromOutside = (type, username, userId) => {
    console.log(type, username, userId);
    this.setState(state => ({openPMFromOutside: {username, userId, type, flag: !state.openPMFromOutside.flag}}));
    //this.setState(state => ({openPMFromOutside: {type, username, flag: !state.openPMFromOutside.flag}}));
  };

  updateActiveLink = activeLink => {
    const {firstTimeCallAlerts, firstTimeNow} = this.state;

    this.setState({activeLink}, () => {
      if(!firstTimeCallAlerts && activeLink === 'Participants' || !firstTimeCallAlerts && activeLink === 'Matrix'){
        this.setState({firstTimeCallAlerts: true});
      }

      if(!firstTimeNow && activeLink === 'Now'){
        this.setState({firstTimeNow: true});
      }
    })
  };

  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log(communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };
}
