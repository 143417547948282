import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";

export default class UIGModal04 extends React.Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showModal} contentLabel="Modal Test" shouldCloseOnOverlayClick={true}
                  onRequestClose={() => this.props.updateShowModal('sm03')} className="px-modal-content"
                  overlayClassName="px-modal-overlay">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                <i onClick={() => this.props.updateShowModal('sm03')} className="material-icons">clear</i>
              </button>
              <h4 className="modal-title">Title 05</h4>
            </div>

            <div className="modal-body">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Cum architecto vel minima id cumque officia magni quos, quibusdam animi?
              Asperiores eum placeat laudantium repudiandae quo aspernatur itaque ducimus qui aliquam.
            </div>

            <div className="separator"/>
            <div className="modal-footer">
              <button onClick={() => this.props.updateShowModal('sm03')} type="button"
                      className="btn btn-danger btn-simple">Close</button>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}
