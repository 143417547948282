import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";
import MediaQuery from "react-responsive";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faThumbsUp as fasThumbsUp, faBolt as fasBolt, faXmark as fasXmark, faBoxArchive, faStar as fasStar ,faUser as fasUser,
  faBell as fasBell,faCircleChevronLeft as fasCircleChevronLeft , faCircleChevronRight as fasCircleChevronRight,
  faCircleChevronUp as fasCircleChevronUp, faWandMagicSparkles as fasWandMagicSparkles, faQuoteRight as fasQuoteRight, faComment as fasComment,
  faCircle, faTag as fasTag, faPaintBrush, faBed, faFlagCheckered, faHeart as fasHeart, faTrophy as fasTrophy,
  faShareAlt as fasShareAlt, faUpDown, faMessageLines, faCircleChevronDown as fasCircleChevronDown,
  faChevronDown as fasChevronDown, faForward as fasForward, faShare as fasShare, faEyeSlash as fasEyeSlash,
  faCirclePlus as fasCirclePlus, faCircleXmark as fasCircleXmark,faArrowLeft as fasArrowLeft, faCrown as fasCrown,
  faGavel as fasGavel, faEye as fasEye, faHandshake as fasHandshake, faComments as fasComments,
  faEnvelope as fasEnvelope, faRepeat as fasRepeat, faPencil as fasPencil, faUnlockAlt as fasUnlockAlt,
  faLock as fasLock, faTrash as fasTrash, faMessage as fasMessage, faImage as fasImage, faUsers as fasUsers,
  faVideo as fasVideo, faFilter as fasFilter, faChevronUp as fasChevronUp, faCaretUp as fasCaretUp,
  faCaretDown as fasCaretDown, faSquareCheck as fasSquareCheck, faDownload as fasDownload, faListOl as fasListOl,
  faReply as fasReply, faKey as fasKey, faFolder as fasFolder, faTriangle as fasTriangle, faGear as fasGear,
  faLeaf as fasLeaf, faHandshakeSimple as fasHandshakeSimple, faHexagon as fasHexagon, faFaceSmile as fasFaceSmile,
  faPlay as fasPlay, faGrid2 as fasGrid2, faList as fasList, faCaretRight as fasCaretRight, faCaretLeft as fasCaretLeft,
  faCircleArrowUp as fasCircleArrowUp, faEllipsis as fasEllipsis, faExchangeAlt as fasExchangeAlt,
  faFlask as fasFlask, faScroll as fasScroll, faArrowRight as fasArrowRight,
  faCircleInfo as fasCircleInfo, faEllipsisVertical as fasEllipsisVertical, faGlobe as fasGlobe, faMagnifyingGlass as fasMagnifyingGlass,
  faUserGear as fasUserGear, faBullhorn as fasBullhorn, faPenNib as fasPenNib, faClock as fasClock, faGrinAlt as fasGrinAlt,
  faSurprise as fasSurprise, faCopy as fasCopy, faMicrophone as fasMicrophone, faLightbulb as fasLightbulb,
  faClouds as fasClouds, faPaintBrushAlt as fasPaintBrushAlt, faStopwatch as fasStopwatch,
  faHighlighter as fasHighlighter, faSquarePen as fasSquarePen, faMapMarkerEdit as fasMapMarkerEdit,
  faNewspaper as fasNewspaper, faUpRightAndDownLeftFromCenter as fasUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter as fasDownLeftAndUpRightToCenter, faUsersMedical as fasUsersMedical,
  faSort as fasSort, faCircle as fasCircle, faClone as fasClone, faCircleArrowLeft as fasCircleArrowLeft,
  faLayerGroup as fasLayerGroup, faCheck as fasCheck, faSquare as fasSquare, faPaintBrush as fasPaintBrush, faBed as fasBed, 
  faMessages as fasMessages, faUserGroup as fasUserGroup, faBookOpen as fasBookOpen, faSpinner as fasSpinner,
  faUserPlus as fasUserPlus, faPenClip as fasPenClip, faTasks as fasTasks, faAlarmExclamation as fasAlarmExclamation,
  faDoNotEnter as fasDoNotEnter, faBookmark as fasBookmark, faGears as fasGears, faUnlock as fasUnlock, faInfinity as fasInfinity,
  faPaperPlane as fasPaperPlane, faArrowsV as fasArrowsV, faNoteSticky as fasNoteSticky, faPalette as fasPalette,
  faIdCardClip as fasIdCardClip, faRobot as fasRobot, faFloppyDisk as fasFloppyDisk, faIdBadge as fasIdBadge, faCircleUser as fasCircleUser,
  faImages as fasImages, faFilm as fasFilm, faDollarSign as fasDollarSign, faTriangleExclamation as fasTriangleExclamation,
  faGrid as fasGrid, faShapes as fasShapes, faUsersGear as fasUsersGear, faTableList as fasTableList,
  faTasksAlt as fasTasksAlt, faCamera as fasCamera, faFileInvoiceDollar as fasFileInvoiceDollar, faReceipt as fasReceipt,
  faUserTag as fasUserTag, faFileSignature as fasFileSignature, faCalculator as fasCalculator, faSiren as fasSiren,
  faFileUpload as fasFileUpload, faFaceSmileBeam as fasFaceSmileBeam, faTemperatureLow as fasTemperatureLow, faStars as fasStars,
  faScribble as fasScribble, faRainbow as fasRainbow, faHouse as fasHouse, faCircleQuestion as fasCircleQuestion,
  faMap as fasMap, faListTree as fasListTree, faListTimeline as fasListTimeline, faLocationPen as fasLocationPen,
  faHandPointer as fasHandPointer, faArrowPointer as fasArrowPointer, faRotate as fasRotate, faArrowsRotate as fasArrowsRotate,
  faTransgenderAlt as fasTransgenderAlt, faArrowUp as fasArrowUp, faArrowDown as fasArrowDown,
  faClosedCaptioning as fasClosedCaptioning, faLink as fasLink, faWavePulse as fasWavePulse,
  faReplyClock as fasReplyClock, faBan as fasBan, faShieldHalved as fasShieldHalved, faShieldCheck as fasShieldCheck
}
  from '@fortawesome/pro-solid-svg-icons';
import {faQuestionCircle, faXmark as farXmark, faComment, faBell, faChevronLeft, faChevronRight, faPaperPlane, faClock,
  faVenus as farVenus, faMars as farMars, faPlus as farPlus, faBars as farBars, faCheck as farCheck, faGrid2 as farGrid2,
  faHeart as farHeart, faCirclePlus as farCirclePlus, faEnvelope as farEnvelope, faThumbsUp as farThumbsUp,
  faHexagon as farHexagon, faCircleNotch as farCircleNotch, faArrowLeft as farArrowLeft,
  faTriangleExclamation as farTriangleExclamation, faCircleInfo as farCircleInfo, faFaceSmile as farFaceSmile,
  faCircleExclamation as farCircleExclamation, faSquareMinus as farSquareMinus, faUpDown as farUpDown,
  faClone as farClone, faEdit as farEdit, faSpinner as farSpinner, faForwardFast as farForwardFast,
  faExpandArrows as farExpandArrows, faRotateLeft as farRotateLeft, faSquarePlus as farSquarePlus, faMagnifyingGlass as farMagnifyingGlass,
  faCircleChevronDown as farCircleChevronDown, faCircleChevronUp as farCircleChevronUp, faShareAlt as farShareAlt,
  faBoxArchive as farBoxArchive, faCircleCaretDown as farCircleCaretDown, faNoteSticky as farNoteSticky,
  faUpRightAndDownLeftFromCenter as farUpRightAndDownLeftFromCenter, faSignalStream as farSignalStream, faSparkles as farSparkles, faSignIn as farSignIn,
  faCalculator as farCalculator, faCircleQuestion as farCircleQuestion, faTimer as farTimer}
  from '@fortawesome/pro-regular-svg-icons';
import {faEnvelopeOpen as falEnvelopeOpen, faEnvelope as falEnvelope, faCircleChevronDown, faMagnifyingGlass as  falMagnifyingGlass,
  faStar, faBars, faUsers, faUser, faAngleDoubleDown, faArrowLeft, faList, faSlidersH, faCircleArrowLeft, faCircleArrowRight,
  faShareAlt, faMars, faTransgenderAlt as falTransgenderAlt, faVenus, faAngleDown, faAngleUp, faThumbsUp, faReply, faPlus as falPlus,
  faCircleQuestion as falCircleQuestion, faCircleInfo as falCircleInfo, faCirclePlus as falCirclePlus,
  faTriangleExclamation as falTriangleExclamation, faTrophy as falTrophy, faArrowRight as falArrowRight,
  faUpDown as falUpDown, faMedal as falMedal, faFileUpload as falFileUpload, faPlusHexagon as falPlusHexagon,
  faEdit as falEdit, faXmark as falXmark, faRotateLeft as falRotateLeft, faFaceSmile as falFaceSmile, faCalendarDays as falCalendarDays,
  faTags as falTags, faHexagon as falHexagon, faComments as falComments, faCircleExclamation as falCircleExclamation,
  faSquarePlus as falSquarePlus, faUserGroup as falUserGroup, faTag as falTag, faShare as falShare,
  faUpRightAndDownLeftFromCenter as falUpRightAndDownLeftFromCenter, faClosedCaptioning as falClosedCaptioning,
  faRobot as falRobot, faMicrochipAi as falMicrochipAi }
  from '@fortawesome/pro-light-svg-icons';
import {faSquareTwitter as fabSquareTwitter, faSquareFacebook as fabSquareFacebook, faSquareSnapchat as fabSquareSnapchat,
  faInstagram as fabInstagram, faLinkedin as fabLinkedin} from '@fortawesome/free-brands-svg-icons';
import {faRepeat as fadRepeat, faCrosshairs as fadCrosshairs, faRotateLeft as fadRotateLeft, faSparkles as fadSparkles,
  faBookmark as fadBookmark, faCircle as fadCircle, faGrid as fadGrid, faStars as fadStars,
  faWandMagicSparkles as fadWandMagicSparkles, faEnvelopeDot as fadEnvelopeDot} from '@fortawesome/pro-duotone-svg-icons';

library.add( faQuestionCircle, farXmark, faComment, faBell, falEnvelopeOpen, faCircleChevronDown, falMagnifyingGlass,
  faStar, faBars, faUsers, faUser, faAngleDoubleDown, faArrowLeft, faList, faSlidersH, faCircleArrowLeft,
  faCircleArrowRight, faShareAlt, faMars, faVenus, faAngleDown, faAngleUp, faThumbsUp, fasThumbsUp, faReply, fasBolt,
  fasXmark, faBoxArchive, fasStar, fasUser, fasBell,fasCircleChevronLeft, fasCircleChevronRight , faChevronLeft,
  faChevronRight, fasCircleChevronUp, fasWandMagicSparkles,fadWandMagicSparkles, fasQuoteRight, fasComment, faCircle,fasTag, faPaintBrush, faBed,
  faFlagCheckered, fasHeart, fasTrophy, fasShareAlt, faUpDown,faMessageLines,fasCircleChevronDown,faPaperPlane,
  fasChevronDown,fasForward, faClock, fasShare,fasEyeSlash,fasCirclePlus, fasCircleXmark,falPlus,falCircleQuestion,
  fasArrowLeft,farVenus,farMars, fasCrown, fasGavel, fasEye, fasHandshake, fasComments, fasEnvelope, fasRepeat,
  fasPencil, fasUnlockAlt,fasLock, fasTrash, fasMessage, fasImage, fasUsers, farPlus, falCircleInfo,
  farBars,farCheck,fasVideo,fasFilter, fasChevronUp, fasCaretUp, fasCaretDown, fasSquareCheck, farGrid2, fasDownload,
  fasListOl, falTransgenderAlt, fasReply, farHeart, fasKey, fasFolder, fasTriangle, farCirclePlus, farEnvelope, falEnvelope,
  farThumbsUp,falCirclePlus, fasGear, falTriangleExclamation, fasLeaf, fasHandshakeSimple, fasHexagon, farHexagon,
  fasFaceSmile, farCircleNotch, farArrowLeft, farTriangleExclamation, fasPlay, farCircleInfo, fasGrid2, fasList, fasCaretLeft,
  fasCaretRight, fasCircleArrowUp, fasEllipsis, farFaceSmile, farCircleExclamation, fasExchangeAlt,farSquareMinus,
  fasCircleQuestion, fasFlask, falTrophy, fasScroll, fasArrowRight, falArrowRight, falUpDown, farUpDown,
  fasCircleInfo, fasEllipsisVertical, fasGlobe, fasMagnifyingGlass,falMedal, falFileUpload, fasUserGear, fasBullhorn, fasPenNib, fasClock,
  fasGrinAlt, fasSurprise, fasCopy, fasMicrophone, fasLightbulb, fasClouds, fasPaintBrushAlt, fasStopwatch,
  fasHighlighter, fasSquarePen, fasMapMarkerEdit, fasNewspaper, fasUpRightAndDownLeftFromCenter, fasDownLeftAndUpRightToCenter, falXmark, falRotateLeft,
  fasUsersMedical, fasSort, falFaceSmile, fasCircle, farClone, fasClone, falCalendarDays, falEdit, falTags, falHexagon,
  falComments, farEdit, fasCircleArrowLeft, fasLayerGroup, farSpinner, farForwardFast, farExpandArrows,
  falCircleExclamation, farRotateLeft, fasCheck, fasSquare, farSquarePlus, falSquarePlus, fasPaintBrush, fasBed,
  falUserGroup,farSquarePlus,falSquarePlus,fasPaintBrush,fasBed,farMagnifyingGlass, fasMessages,fasUserGroup,
  fasBookOpen, fasSpinner, fasUserPlus, fasPenClip, fasTasks, fasAlarmExclamation, fasDoNotEnter, falTag,
  fasBookmark, farCircleChevronDown, farShareAlt, farBoxArchive, fasGear, fasUnlock, fasInfinity, fabSquareTwitter,
  fabSquareFacebook, fabSquareSnapchat, fabInstagram, fabLinkedin, fasPaperPlane, farCircleChevronUp, fasArrowsV,
  farCircleCaretDown, farNoteSticky, fasNoteSticky, fasPalette, fasIdCardClip, fasRobot, fadRepeat, fasFloppyDisk, falShare,
  fasIdBadge, fasCircleUser, fasImages, fasFilm, falUpRightAndDownLeftFromCenter, farUpRightAndDownLeftFromCenter, fasDollarSign,
  fadCrosshairs, fadRotateLeft, farSignalStream, fadSparkles, farSparkles, fadBookmark, fasTriangleExclamation, farSignIn, fasGrid,
  fasShapes, fasUsersGear, fasTableList, fasTasksAlt, fasCamera, farCalculator, fasFileInvoiceDollar, fasReceipt, fasUserTag,
  fasFileSignature, fasCalculator, fasSiren, fadCircle, fasFileUpload, fasFaceSmileBeam, fasTemperatureLow, fasStars, fasScribble,
  fasRainbow, fasHouse, farCircleQuestion, falCircleQuestion, fadGrid, fadStars, fasMap, fasListTree, fasListTimeline, fasLocationPen,
  fasHandPointer, fasArrowPointer, fasRotate, fasArrowsRotate, fasTransgenderAlt, fasArrowUp, fasArrowDown,
  falClosedCaptioning, fasClosedCaptioning, farTimer, falRobot, falMicrochipAi, fasLink, fasWavePulse,
  fasReplyClock, fasBan, fasShieldHalved, fasShieldCheck, fadEnvelopeDot)

import TopMenu from "./Navigation/TopMenu";
import RightDrawer from "./Navigation/Drawers/RightDrawer";
import LeftMenu from "./Navigation/LeftMenu";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";

export default class Layout extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    sessionLang: PropTypes.string.isRequired,
    notifications: PropTypes.array.isRequired,
    from: PropTypes.string.isRequired,
    communities: PropTypes.array,
    community: PropTypes.object,
    leftMenuActive: PropTypes.string,
    rooms: PropTypes.array,
    active_users: PropTypes.array,
    origin: PropTypes.string,
    commState: PropTypes.string,
    updateSelectCommunities: PropTypes.func,
    toDoCounter: PropTypes.number,
    openPMFromOutside: PropTypes.object,
    updateOpenPMFromOutside: PropTypes.func,
    activeLink: PropTypes.string,
    updateActiveLink: PropTypes.func,
    currentMenu: PropTypes.string,
    handleLink: PropTypes.func,
    notificationsCounter: PropTypes.number,
    colors: PropTypes.object.isRequired 
  };

  constructor(props) {
    super(props);
    this.state = {
      notificationsUnseenCounter: props.notificationsCounter,
      showRightDrawer: false,
      leftMenuActive: props.leftMenuActive,
      showLeftDrawer: false,
      submenuTitle: '',
      rightDrawerType: 'Notifications',
      active_users: [],
      collaborators: [],
      rooms: [],
      broadcast: [],
      notifications: [],
      isLoadingNotifications: false,
      isLoadingRooms: false,
      pmbCounter: 0, //Private Messages and Broadcasts unseen counter
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      news: [],
      isLoadingNews: false,
      unreadNewsFlash: false,
      newsLastVisit: '',
      notificationOffset: 0,
      notificationsHasMore: false,
      notificationsDate: 'today',
      segments: []
    };
  }

  componentDidMount() {
    ReactModal.setAppElement('body');

    if(this.props.from !== 'Dashboard' && this.props.from !== 'ManagePage'){
      this._getUnseenPMBCounter();
    }

    if(this.props.user_account.once_collaborator === true) {
      this._getUnreadNewsflag();
      this._getNewsLastVisit();
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.notificationsCounter !== this.props.notificationsCounter){
      this.setState({notificationsUnseenCounter: this.props.notificationsCounter});
    }
    if(prevProps.rooms !== this.props.rooms){
      this.setState({rooms: this.props.from === 'Dashboard' ? [] : this.props.rooms})
    }
    if(prevProps.active_users !== this.props.active_users){
      this.setState({active_users: this.props.from === 'Dashboard' ? [] : this.props.active_users})
    }
  }

  render() {
    const {profile, from, community, openPMFromOutside, sessionLang, user_account, colors, notificationsCounter} = this.props;
    //console.log('Layout render - openPMFromOutside:', openPMFromOutside);
    const language = profile.language ? profile.language : sessionLang;
    const avatar = profile.avatar === '' ? '/assets/user.png' : profile.avatar;
    const communityTitle = from === 'Dashboard' || from === 'ManagePage' ? 'My Communities' : community.title;
    const communityId = from === 'Dashboard' || from === 'ManagePage' ? -1 : community.id;

    let leftMenu = <LeftMenu from={this.props.from}
                             leftMenuActive={this.state.leftMenuActive}
                             setLeftMenuActive={this.setLeftMenuActive}
                             communityId={this.props.community ? this.props.community.id : -1}
                             projectType={this.props.community.project_type}
                             socialFlag={this.props.community.social_flag}
                             showPartList={this.props.community.show_part_list}
                             showLeftDrawer={this.state.showLeftDrawer}
                             leftDrawerToggleHandler={this.leftDrawerToggleHandler}
                             subMenuTitleHandler={this.subMenuTitleHandler}
                             origin={this.props.origin}
                             role={this.props.profile.role}
                             role_1={user_account.role_1}
                             role_2={user_account.role_2}
                             toDoCounter={this.props.toDoCounter}
                             activeLink={this.props.activeLink}
                             updateActiveLink={this.props.updateActiveLink}
                             currentMenu={this.props.currentMenu}
                             handleLink={this.props.handleLink}
                             language={language}
                             isMobile={this.state.isMobile}
                             colors={colors}
                             subdomain={user_account.subdomain}
                             hasParticipants = {community.has_participants}
                             hasCollaborators = {community.has_collaborators}
                             hasTasks = {community.has_tasks}
                             helpStores = { user_account.help_stores }
                             user_id = { profile.user_id }
    />;

    const showRightDrawer = openPMFromOutside !== undefined ?
      (openPMFromOutside.flag || this.state.showRightDrawer) : this.state.showRightDrawer;
    const rightDrawerType = openPMFromOutside !== undefined ?
      (openPMFromOutside.flag && openPMFromOutside.username !== undefined) ?
        'Conversations' : this.state.rightDrawerType : this.state.rightDrawerType;

    return (
      <React.Fragment>
        <RightDrawer showRightDrawer={showRightDrawer}
                     rightDrawerToggleHandler={this.rightDrawerToggleHandler}
                     from={from}
                     rightDrawerType={rightDrawerType}
                     notifications={this.state.notifications}
                     profileLang={this.props.profile.language}
                     sessionLang={this.props.sessionLang}
                     rooms={this.state.rooms}
                     active_users={this.state.active_users}
                     collaborators={this.state.collaborators}
                     currentUserId={this.props.user_account.id}
                     communityId={this.props.community.id}
                     profile={profile}
                     communities={this.props.communities}
                     openPMFromOutside={openPMFromOutside}
                     updateRightDrawerType={this.updateRightDrawerType}
                     isLoadingNotifications={this.state.isLoadingNotifications}
                     role_1={from !== 'Dashboard' ? user_account.role_1 : openPMFromOutside.role_1}
                     role_2={from !== 'Dashboard' ? user_account.role_2: openPMFromOutside.role_2}
                     colors={colors}
                     segments={this.state.segments}
                     news={this.state.news}
                     isLoadingNews={this.state.isLoadingNews}
                     newsLastVisit={this.state.newsLastVisit}
                     setLastNewsVisit={this.setLastNewsVisit}
                     getNotifications={this.getNotifications}
                     notificationsHasMore={this.state.notificationsHasMore}
                     notificationsDate={this.state.notificationsDate}
                     updateNotificationsDate={this.updateNotificationsDate}/>

        <div className="container-principal">
          <MediaQuery minDeviceWidth={480}>
            { leftMenu }
          </MediaQuery>
          <MediaQuery maxDeviceWidth={480}>
            { from !== 'Dashboard' && leftMenu }
          </MediaQuery>

          <div className="container-main">
            <TopMenu avatar={avatar}
                     username={user_account.username}
                     notifications={this.props.notifications}
                     notificationsUnseenCounter={this.state.notificationsUnseenCounter}
                     notificationsCounterHandler={this.notificationsCounterHandler}
                     rightDrawerToggleHandler={this.rightDrawerToggleHandler}
                     messagesCounterHandler={this.messagesCounterHandler}
                     communityTitle={communityTitle}
                     from={this.props.from}
                     commState={this.props.commState}
                     communities={this.props.communities}
                     updateSelectCommunities={this.props.updateSelectCommunities}
                     leftDrawerToggleHandler={this.leftDrawerToggleHandler}
                     role_1={this.props.profile.role_1}
                     rooms={this.state.rooms}
                     communityId={communityId}
                     language={language}
                     pmbCounter={this.state.pmbCounter}
                     logo={user_account.logo}
                     subdomain={user_account.subdomain}
                     colors={colors}
                     newsCounterHandler={this.newsCounterHandler}
                     unreadNewsFlash={this.state.unreadNewsFlash}
                     onceCollaborator={user_account.once_collaborator}/>
            { this.props.children }
          </div>
        </div>
      </React.Fragment>
    );
  }

  updateRightDrawerType =(type, communityId, username, userId) => {
    console.log('updateRightDrawerType:', type, communityId, username, userId, this.props.from);
    if(this.props.from === 'Dashboard'){
      // Almost the same as Conversations._getActiveUsers()
      $.ajax({
        url: '/communities_user/get_users_conversations/' + communityId + '/' + this.props.from,
        method: 'GET',
        dataType: 'JSON',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: users => {
          console.log(users);
          this.setState({
            active_users: users.filter(user => user.role_1 === 'Participant'),
            collaborators: users.filter(user => user.role_1 === 'Collaborator')
          }, () => {
            this.props.updateOpenPMFromOutside(type, username, userId, communityId);
          });
        }
      });

      /*$.ajax({
        url: '/community/get_act_pars_and_rooms/' + communityId,
        method: 'GET',
        success: obj => {
          console.log(obj);
          this.setState({active_users: obj.active_users, rooms: obj.rooms}, () => {
            //this.setState({rightDrawerType: 'Messages'});
            this.props.updateOpenPMFromOutside(type, username, userId);
          })
        }
      });*/
    } else {
      this.props.updateOpenPMFromOutside(type, username, userId);
    }
  };

  subMenuTitleHandler = (value) => {
    this.setState({submenuTitle: value});
  };

  setLeftMenuActive = (value) => {
    this.setState({leftMenuActive: value});
  };

  leftDrawerToggleHandler = (showLeftDrawer) => {
    this.setState({showLeftDrawer});
  };

  rightDrawerToggleHandler = () => {
    //console.log(this.state.showRightDrawer, this.props.openPMFromOutside);
    if(this.props.openPMFromOutside && this.props.openPMFromOutside.flag){
      this.props.updateOpenPMFromOutside();
    } else {
      this.setState(prevState => ({showRightDrawer: !prevState.showRightDrawer}), () => {
        //console.log('showRightDrawer:', this.state.showRightDrawer);
        if(!this.state.showRightDrawer){
          //console.log("remover clase");
          document.body.className = document.body.classList.remove("stop-scrolling");
        }else{
          document.body.classList.add("stop-scrolling");
          document.getElementsByClassName('right-drawer')[0].scrollTop = 0;
        }
      });
    }
  };

  messagesCounterHandler = (e) => {
    e.preventDefault();
    this.setState({rightDrawerType: 'Messages', isLoadingRooms : true}, () => {
      $.ajax({
        url: '/rooms/get_rooms/' + this.props.community.id,
        method: 'GET',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: rooms =>  {
          //console.log('rooms:', rooms);
          this.setState({
            rooms,
            isLoadingRooms: false
          });
        }
      });
      this._getSegments();
      this.rightDrawerToggleHandler();
    })
  };

  _getSegments = () => {
    axios.get('/segments/get_per_community/' + this.props.community.id, {})
      .then(r => {
        const segments = r.data
        //console.log('segments:', segments);
        this.setState({segments});
      })
      .catch(error => {console.log(error)});
  };

  notificationsCounterHandler = e => {
    e.preventDefault();

    this.setState({rightDrawerType: 'Notifications'}, () => {
      this.getNotifications();
      this.rightDrawerToggleHandler();
    });
  };

  getNotifications = () => {
    axios.get('/notifications/get_notifications/' + this.state.notificationOffset + '/' +
      this.state.notificationsDate, {})
      .then(response => {
        //console.log('DB notifications:', response.data.map(n => n.notification.id));
        this.setState({
          notifications: [...this.state.notifications, ...response.data],
          isLoadingNotifications: false,
          notificationOffset: this.state.notificationOffset + response.data.length,
          notificationsHasMore: response.data.length !== 0
        }, () => {
          console.log('this.state.notifications:', this.state.notifications.map(n => n.notification.id));
          if(this.state.notificationsUnseenCounter !== 0){
            this.updateVisibilityCounter();
          }
        });
      }).catch(error => {console.log(error)});
  }

  updateNotificationsDate = type => {
    this.setState({notificationsDate: type, notifications: [], notificationOffset: 0, notificationsHasMore: false,
        isLoadingNotifications: true},
      () => {
      this.getNotifications();
    });
  };

  updateVisibilityCounter = () => {
    $.ajax({
      url: '/notifications/update_state/',
      method: 'POST',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
        //Optimistic UI
        this.setState({notificationsUnseenCounter: 0});
      }
    });
  };

  _getSortedNotifications = (notifications, type) => {
    let todayNotifications = [];
    let oldNotifications = [];
    let sortedNotifications = [];

    notifications.map(notification => {
      if(notification.user_to_roles){
        if(notification.user_to_roles.role_1 === 'Collaborator'){
          if(new Date(notification.notification.created_at).getDate() === new Date().getDate()){
            todayNotifications.push(notification);
          } else {
            oldNotifications.push(notification);
          }
        } else {
          if(notification.community_state === 'Enabled'){
            if(new Date(notification.notification.created_at).getDate() === new Date().getDate()){
              todayNotifications.push(notification);
            } else {
              oldNotifications.push(notification);
            }
          }
        }
      }
    });

    //sortedNotifications.push(todayNotifications);
    //sortedNotifications.push(oldNotifications);

    if(type === 'today'){
      return todayNotifications;
    } else {
      return oldNotifications;
    }
  };

  notificationsCounterHandlerORIG = (e) => {
    e.preventDefault();
    this.setState({rightDrawerType: 'Notifications', isLoadingNotifications: true}, () => {
      $.ajax({
        url: '/notifications/get_notifications/-1',
        method: 'GET',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: notifications =>  {
          console.log(notifications.map(n => n.notification));
          this.setState({
            notifications,
            isLoadingNotifications: false
          });

          if(this.state.notificationsUnseenCounter !== 0){
            $.ajax({
              url: '/notifications/update_state/',
              method: 'POST',
              dataType: 'JSON',
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              success: () => {
                //Optimistic UI
                this.setState({notificationsUnseenCounter: 0});
              }
            });
          }
        }
      });

      this.rightDrawerToggleHandler();
    });

    /*this.setState({rightDrawerType: 'Notifications'}, () => {
      if(this.state.notificationsUnseenCounter !== 0){
        $.ajax({
          url: '/notifications/update_state/',
          method: 'POST',
          dataType: 'JSON',
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: () => {
            //Optimistic UI
            this.setState({notificationsUnseenCounter: 0});
          }
        });
      }

      this.rightDrawerToggleHandler();
    });*/
  };

  newsCounterHandler = (e) => {
    e.preventDefault();
    this.setState({ rightDrawerType: 'News', isLoadingNews: true }, () => {
      $.ajax({
        url: '/news',
        method: 'GET',
        beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
        success: news => {
          this.setState({
            news: news,
            isLoadingNews: false
          });
        }
      });
      this.rightDrawerToggleHandler();
    })
  }

  _getUnseenPMBCounter = () => {
    $.ajax({
      url: '/rooms/get_pm_broadcast_counter/' + this.props.community.id,
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: pmbCounter => {
        //console.log(pmbCounter);
        this.setState({pmbCounter});
      }
    });
  };

  _getNotificationsCounter(){
    let counter = 0;

    this.props.notifications.forEach(notification => {
      if(notification.notification.state === 'unseen'){
        counter++;
      }
    });

    return counter;
  }

  _getUnreadNewsflag = () => {
    $.ajax({
      url: '/news/unreads/' + this.props.user_account.id,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: has_unreads => {
        this.setState({ unreadNewsFlash: has_unreads });
      }
    });
  }

  _getNewsLastVisit = () => {
    $.ajax({
      url: '/news/last_visit/' + this.props.user_account.id,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: last_visit => {
        this.setState({ newsLastVisit: last_visit });
      }
    });
  }

  setLastNewsVisit = () => {
    $.ajax({
      url: '/news/read/' + this.props.user_account.id,
      method: this.state.newsLastVisit === '' ? 'POST' : 'PATCH',
      dataType: 'JSON',
      beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: news_visibility => {
        this.setState({ newsLastVisit: news_visibility.last_visit, unreadNewsFlash: false });
      }
    });
  }
}
