import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from 'axios';
import {showSuccessMsg} from "../../UI/Globals/PX_Funs";

export default class CSBioColl extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    cu: PropTypes.object.isRequired,
    cuHandleChange: PropTypes.func.isRequired,
    saveBio: PropTypes.func.isRequired,
    user_email: PropTypes.string.isRequired,
    initCommunityEmail: PropTypes.string.isRequired,
    isValidCommunityEmail: PropTypes.bool.isRequired,
    updateIsValidCommunityEmail: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {community, cu, user_email, initCommunityEmail, isValidCommunityEmail, language, colors} = this.props;

    const I18n = {
            en: { bioLabel: 'IN 240 CHARACTERS, TELL THE COMMUNITY SOMETHING ABOUT YOU',
              charsLeft: 'chars. left', bioInfo: 'This Bio will only be visible in ',
              save: 'SAVE'
            },
            es: { bioLabel: 'EN 240 CARACTERES, DINOS ALGO SOBRE TI',
              charsLeft: 'chars. restantes', bioInfo: 'Esta bio solo será visible en ',
              save: 'GUARDAR'
            }
        };

    const charsLeft = cu.bio ? (240 -  cu.bio.length) : 240;

    return (
      <div id="divBio" className="manage" style={{display:'none'}}>
        <div className="px-settings-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-card px-card-settings">
                <div className="px-icon-settings">
                  <FontAwesomeIcon icon={['fas','pen-nib']}/>
                </div>
                <div className="panel-body px-body-padding-bio">
                  {/*
                    cu.role_2 !== 'Observer' &&
                    <div className="row">
                      <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                        <div className="form-group first">
                          <label className="label-sm">Your community email</label>
                          <input name="community_email" onChange={this.props.cuHandleChange} value={cu.community_email}
                                 type="text" className="form-control px-input-settings"
                                 onBlur={() => this._validateEmailFormat(cu.community_email)}/>
                          <p className="px-label-information">
                            This email address will be visible to other users in {community.title} and it may be shared with them
                            so they can contact you.
                          </p>
                          <p className="px-label-information">
                            If no email is set here your account email {user_email} will be used instead.
                          </p>
                          {
                            cu.community_email !== '' &&
                            <span id="spnErrorEmail" className="px-label-warning"
                                  style={{fontSize: '11px', display: isValidCommunityEmail ? 'none' : 'block'}}>
                          * Wrong email format</span>
                          }

                        </div>
                      </div>
                      <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                        <label className="label-sm">&nbsp;</label>
                        {
                          initCommunityEmail === cu.community_email &&
                          <p className="px-label-information">
                            {
                              cu.community_email !== '' ? cu.is_comm_email_confirmed ?
                                'Email confirmed' :
                                <React.Fragment>
                                  <span>Email not confirmed.</span><br/>
                                  <span onClick={this.resendConfirmationEmail}
                                        style={{cursor:'pointer', textDecoration:'underline'}}>
                                    Resend confirmation email.
                                  </span>
                                </React.Fragment> : ''
                            }
                          </p>
                        }
                      </div>
                    </div>
                  */}
                  <br/>
                  <div className="form-group first">
                    <label className="label-sm" style={{color: colors.color1[2]}}>{I18n[language].bioLabel}</label>
                    <div className="bio-text-area-container">
                      <textarea name="bio" id="bio" onChange={this.props.cuHandleChange} value={cu.bio}
                                cols="30" rows="5" className="px-bio-text-area"/>
                      <span className="px-label-information char-remaining" style={{color: colors.color2[3]}}>{charsLeft} {I18n[language].charsLeft}</span>
                    </div>
                    <p className="px-label-information" style={{color: colors.color2[3]}}>
                    <FontAwesomeIcon icon={['fal','circle-info']} style={{fontSize:'12px', marginRight:'5px', color: colors.color2[3]}}/>
                      {I18n[language].bioInfo} "{community.title}"
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                      <button onClick={this.saveBio} className="btn px-btn lg btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{I18n[language].save}</button>
                    </div>
                  </div>
                </div>
              </div>

              {/*<div className="px-card px-card-settings">
                <div className="panel-body px-crit-options-body">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                      <label className="label-options mg-bottom-25">Privacy Options</label>
                    </div>
                  </div>
                  <div className="px-alert-item lg">
                      <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-alert"/>
                      <p className="px-label-warning text-left">Personal details in your profile, like your name and email address,
                        won’t be ever disclosed to other participants.</p>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-switch-item-container">
                      <div className="px-switch-item-conf">
                        <label className="label-sm text-center">DISCLOSE YOUR <br/>GENDER</label>
                        <label className="px-switch" htmlFor="discloseGenderFlag">
                          <input name="show_gender" type="checkbox" id="discloseGenderFlag"
                                onChange={this.props.cuHandleChange} checked={cu.show_gender}/>
                          <div className="slider round"/>
                        </label>
                      </div>
                      <div className="px-switch-item-conf">
                        <label className="label-sm text-center">DISCLOSE YOUR <br/> COUNTRY-STATE</label>
                        <label className="px-switch" htmlFor="discloseCityFlag">
                          <input name="show_location" type="checkbox" id="discloseCityFlag"
                                onChange={this.props.cuHandleChange} checked={cu.show_location}/>
                          <div className="slider round"/>
                        </label>
                      </div>
                      <div className="px-switch-item-conf">
                        <label className="label-sm text-center">DISCLOSE YOUR <br/>SOCIAL MEDIA LINKS</label>
                        <label className="px-switch" htmlFor="discloseSocialFlag">
                          <input name="show_media" type="checkbox" id="discloseSocialFlag"
                                onChange={this.props.cuHandleChange} checked={cu.show_media}/>
                          <div className="slider round"/>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>*/}

            </div>
          </div>
        </div>  
      </div>
    );
  }

  resendConfirmationEmail = async() => {
    await axios.get(`/communities_user/resend_community_email/${this.props.cu.id}`);
    showSuccessMsg('Success!');
  };

  saveBio = () => {
    this.props.saveBio();
  }

  _validateEmailFormat(value) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log(re.test(value))
    this.props.updateIsValidCommunityEmail(re.test(value));
  }
}
