import PropTypes from 'prop-types';
import React from 'react';
import debounce from "lodash.debounce";
import v4 from "uuid";
import TBActivityHeader from "./TBActivityHeader";
import TRGeneral from "../TResponse/TRGeneral";
import TRMedia from "../TResponse/TRMedia";
import TBUserCardBig from "./TBUserCardBig";
import Loading from "../../../UI/Globals/Loading";

export default class TBInfiniteItem extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    role_2: PropTypes.string.isRequired,
    consolidated: PropTypes.string.isRequired,
    objSearch: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
    updateSVGMulti: PropTypes.func.isRequired,
    getResponsesConsolidated_01: PropTypes.func.isRequired,
    isLoadingResponsesConsolidated: PropTypes.bool.isRequired,
    hasMoreItemsConsolidated: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired,
    cmntyLanguage: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      hasMore: true,
      isLoading: false,
      users: [],
      consolidated: this.props.consolidated //Is it still useful?
    };

  }

  componentDidMount() {
    if(this.props.consolidated === 'general'){
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log(prevProps.hasMoreItemsConsolidated, this.props.hasMoreItemsConsolidated);
    if(prevProps.hasMoreItemsConsolidated !== this.props.hasMoreItemsConsolidated) {
      this.setState({hasMore: this.props.hasMoreItemsConsolidated});
    }

    if(prevProps.consolidated !== this.props.consolidated){
      this.setState({consolidated: this.props.consolidated});
    }
  }

  componentWillUnmount() {
    //console.log('Unmount');
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { items, role_2, consolidated, objSearch, users, language, cmntyLanguage, hasMoreItemsConsolidated, colors,
      isLoadingResponsesConsolidated} = this.props;
    //console.log('hasMoreItemsConsolidated:', hasMoreItemsConsolidated);
    let itemsView;
    const allMedias = [];

    itemsView = items.map(item => {
      //console.log(item);
      let comp;

      if(item.type === 'activity'){
        comp = <TBActivityHeader key={v4()}
                                 title={item.title}
                                 activityId={item.id}
                                 instructions={item.instructions}
                                 from='Collaborator'
                                 showModalExportHandler={() => {}}
                                 role_2={role_2}
                                 isVirtualized={false}
                                 colors={colors}/>;
        if(consolidated !== 'general' && objSearch.idPars.length === 1){
          comp = null;
        }
      } else {
        const isFound = items.find(c => c.task_id === item.task.id); // responses consolidated
        //console.log('isFound:', isFound);
        let arrayResponses = [];

        switch(item.task.task_type){
          case 'Open End':
          case 'Multimedia':
          case 'Canvas':
            arrayResponses = isFound.responses;
            break;
          case 'Choice':
            arrayResponses = isFound.responses.map(response => response.data.answersId);
            break;
        }

        if(consolidated === 'general'){
          const isFound = items.find(c => c.task_id === item.task.id); // responses consolidated

          comp = <TRGeneral key={v4()}
                            cardinal={item.cardinal}
                            usersId={objSearch.idPars.length === 1 ? users.map(u => u.id) : objSearch.idPars}
                            responsesConsolidated={items}
                            taskTile={item.task.title}
                            taskType={item.task.task_type}
                            responses={arrayResponses}
                            language={language}
                            taskChoices={item.task.task_type === 'Choice' ? item.task.data.answers : []}
                            taskId={item.task.id}
                            isVirtualized={false}
                            graph={isFound.graph}
                            updateSVGMulti={this.props.updateSVGMulti}
                            task_id={isFound.task_id}
                            setCurrentHeight={this.setCurrentHeight}
                            colors={colors}
                            cmntyLanguage={cmntyLanguage}
                            resConsolidated={isFound}/>
        } else {
          //console.log(arrayResponses);
          if(isFound.task.task_type !== 'Choice'){
            const mediaArrays = this._getMediaArrays(arrayResponses, consolidated, item, users, isFound.task.task_type);
            //console.log(mediaArrays);

            if(objSearch.idPars.length === 1){
              if(mediaArrays.length !== 0){
                mediaArrays.forEach(media => allMedias.push(media));
              }
            } else {
              return(
                <TRMedia key={v4()}
                         cardinal={item.cardinal}
                         objSearch={objSearch}
                         from={'multi'}
                         consolidated={consolidated}
                         taskType={item.task.task_type}
                         media={mediaArrays}
                         taskTitle={item.task.title}
                         colors={colors}
                         language={language}/>
              )
            }
          } else {
            return(
              <TRMedia key={v4()}
                       cardinal={item.cardinal}
                       objSearch={objSearch}
                       from={'multi'}
                       consolidated={consolidated}
                       taskType={item.task.task_type}
                       media={[]}
                       taskTitle={item.task.title}
                       colors={colors}
                       language={language}/>
            )
          }
        }
      }

      return comp;
    });

    if(objSearch.idPars.length === 1 && consolidated !== 'general'){
      const user = users.find(user => user.id === objSearch.idPars[0]);

      itemsView = <React.Fragment>
        <TBUserCardBig user={user}
                       showModalExportHandler={() => {}}
                       from={'Collaborator'}
                       isVirtualized={false}
                       language={language}
                       colors={colors}/>
        <TRMedia key={v4()}
                 cardinal={-1}
                 objSearch={objSearch}
                 from={'single'}
                 consolidated={consolidated}
                 taskType={'Open End'}
                 media={allMedias}
                 taskTitle={''}
                 colors={colors}
                 language={language}/>
      </React.Fragment>;
    }

    return(
      <React.Fragment>
        { itemsView }
        {isLoadingResponsesConsolidated && <Loading/>}
      </React.Fragment>
    )
  }

  _getMediaArrays(arrayResponses, consolidated, item, users, taskType){
    const mediaArrays = [];

    arrayResponses.forEach(response => {
      //console.log(response.data);
      if(consolidated === 'images'){
        if (taskType === 'Canvas') {
          const latestAttachment = response.data.slice(-1)[0];
          if (latestAttachment) {
            mediaArrays.push({...latestAttachment, activityTitle: item.activity_title,
              taskTitle: item.task.title, user: users.find(user => user.id === latestAttachment.user_id)
            });
          }
        } else {
          response.data.forEach(attachment => {
            //console.log(attachment);
            const user = users.find(user => user.id === attachment.user_id);
            mediaArrays.push({...attachment, activityTitle: item.activity_title, taskTitle: item.task.title, user: user});
          });
        }
      } else {
        //console.log(response.data);
        if(response.data.video_thumbnail && response.data.video_url){
          const user = users.find(user => user.id === response.data.user_id);

          mediaArrays.push({...response.data, activityTitle: item.activity_title, taskTitle: item.task.title, user: user});
        }
      }
    });

    return mediaArrays;
  }

  handleScroll = debounce(() => {
    //console.log(this.props.consolidated, this.state.consolidated);
    //if(this.state.error || this.props.isLoading || !this.state.hasMore) return;
    /*console.log('hasMoreItemsConsolidated:', this.props.hasMoreItemsConsolidated,
      'isLoadingResponsesConsolidated:', this.props.isLoadingResponsesConsolidated, 'hasMore:', this.state.hasMore);*/
    if(this.props.hasMoreItemsConsolidated && this.state.consolidated === 'general'){
      //console.log(window.innerHeight + document.documentElement.scrollTop, document.body.scrollHeight);
      if(window.innerHeight + document.documentElement.scrollTop > (document.body.scrollHeight - 100) &&
        !this.props.isLoadingResponsesConsolidated) {
        console.log('call more');
        this.props.getResponsesConsolidated_01();
      }
    }
  }, 200);

  setCurrentHeight = () => {
    console.log(document.body.scrollHeight)
  };
}