import PropTypes from 'prop-types';
import React, { Component }from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dropzone from "react-dropzone";
import Slider from 'react-slick';
import * as markerjs2 from "markerjs2";
import * as mjslive from 'markerjs-live';

import AVVideo from "../AVVideo";
import Comments from "../../../../../containers/Community/Comments";
import ReactModal from "react-modal";
import AVTModal from "./AVTModal";
import AVTMaps from "./AVTMaps";
import {taskI18n} from "../../../../UI/Globals/PX_i18n";
import T_ModalVideo from "../../../Transcript/T_ModalVideo";

export default class AVTCanvas extends React.Component {
  imgRef = React.createRef();

  static propTypes = {
    question: PropTypes.object.isRequired,
    cardinal: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    cmntyLanguage: PropTypes.string.isRequired,
    participants_view: PropTypes.string.isRequired,
    response: PropTypes.object,
    saveResponse: PropTypes.func.isRequired,
    questionsLength: PropTypes.number.isRequired,
    updateShowBlocker: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    blockerExpired: PropTypes.bool.isRequired,
    updateShowComments: PropTypes.func.isRequired,
    updateResponseComments: PropTypes.func.isRequired,
    taskSocialStatus: PropTypes.string.isRequired,
    comunitySocial: PropTypes.bool,
    scrollFirstTime: PropTypes.bool.isRequired,
    updateScrollFirstTime: PropTypes.func.isRequired,
    setActiveTaskId: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    scroll: PropTypes.string,
    type: PropTypes.string,
    item_scroll: PropTypes.object,
    isSavingResponse: PropTypes.bool,
    updateIsSavingResponse: PropTypes.func,
    helpStores: PropTypes.object.isRequired,
    updateHelpStore: PropTypes.func.isRequired,
    updateBackdrop: PropTypes.func,
    backdropVisibility: PropTypes.bool,
    from: PropTypes.string,
    maAnnotations: PropTypes.object.isRequired,
    updateMaAnnotations: PropTypes.func.isRequired,
    setCanvasObjForOPS: PropTypes.func.isRequired,
    company: PropTypes.object,
    aiAgentName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      showSaveHelpPopup: false,
      showModalValidation: false,
      annotations: '',
      annotationImage: '',
      showModalQuestionVideo: false,
    }
  };

  componentDidMount() {
    this.handleAnnotationUpdates();
  }

  componentDidUpdate(prevProps,prevState) {
    const currentAnnotations = this.getAnnotationsFromProps(this.props);
    const prevAnnotations = this.getAnnotationsFromProps(prevProps);

    if (currentAnnotations !== prevAnnotations) {
      this.handleAnnotationUpdates();
    }
  }

  render() {
    const {question, cardinal, userId, language, cmntyLanguage, participants_view, response, questionsLength,
      blockerExpired, taskSocialStatus, comunitySocial, item_scroll, scrollFirstTime, from, isSavingResponse,
      colors, maAnnotations, company} = this.props;

    const i18n = {
      en: {
        taskPrivate: {
          saveHelpTooltip: "Saving will preserve your drafted response.",
          doneHelpTooltip: "Clicking here will save your task and notify moderators that you have finished.",
          commentsHelpTooltip: "Anything to say to the moderators about this tasks? Post a comment here any time."
        },
        taskPublic: {
          saveHelpTooltip: "Saving will preserve your drafted response but other participants won't be able to see it.",
          doneHelpTooltip: "Clicking here will notify moderators that you have finished and other participants may be able to see your response.",
          commentsHelpTooltip: "Anything to say to the moderators or other participants about this tasks? Post a comment here any time."
        },
        taskCompleted: 'You have completed this task', taskExpired: 'This task has expired and you can\'t edit it anymore',
        comments: 'Comments', clickImageToAnnotate: 'Click image to annotate',
        savingResponse: 'Wait, saving responses'
      },
      es: {
        taskPrivate: {
          saveHelpTooltip: "Recuerda guardar tu avance dando click aquí.",
          doneHelpTooltip: "Cuando estés listo da click aquí para notificar a los moderadores que has terminado esta tarea.",
          commentsHelpTooltip: "Algo que decir a los moderadores sobre esta tarea? Commenta aquí."
        },
        taskPublic: {
          saveHelpTooltip: "Guarda tu avance aquí tomando en cuenta que mientras no completes la respuesta otros participantes no podrán verla.",
          doneHelpTooltip: "Cuando estés listo da click aquí para notificar a los moderadores y a otros participantes que has completado esta tarea.",
          commentsHelpTooltip: "Algo que decir a los moderadores o a otros participantes sobre esta tarea? Commenta aquí."
        },
        taskCompleted: 'Has completado esta tarea', taskExpired: 'Esta tarea ha expirado y no se puede editar',
        comments: 'Comentarios', clickImageToAnnotate: 'Clic en la imagen para anotar',
        savingResponse: 'Espera, guardando respuestas'
      }
    };

    let saveHelpTooltip = null;
    let saveHelpPopupClass = this.state.showSaveHelpPopup === true && 'open';
    let saveHelpMessage = comunitySocial && taskSocialStatus === 'Public' ? taskI18n[language].saveHelp2Social : taskI18n[language].saveHelp2
    let doneHelpTooltip = null;
    let commentsHelpTooltip = null;

    if(taskSocialStatus === "Public") {
      saveHelpTooltip = language === 'es' ? i18n.es.taskPublic.saveHelpTooltip : i18n.en.taskPublic.saveHelpTooltip;
      doneHelpTooltip = language === 'es' ? i18n.es.taskPublic.doneHelpTooltip : i18n.en.taskPublic.doneHelpTooltip;
      commentsHelpTooltip = language === 'es' ? i18n.es.taskPublic.commentsHelpTooltip : i18n.en.taskPublic.commentsHelpTooltip
    } else {
      saveHelpTooltip = language === 'es' ? i18n.es.taskPrivate.saveHelpTooltip : i18n.en.taskPrivate.saveHelpTooltip;
      doneHelpTooltip = language === 'es' ? i18n.es.taskPrivate.doneHelpTooltip : i18n.en.taskPrivate.doneHelpTooltip;
      commentsHelpTooltip = language === 'es' ? i18n.es.taskPrivate.commentsHelpTooltip : i18n.en.taskPrivate.commentsHelpTooltip
    }

    const answers =  question.attachment.length !== 0  &&
        <div className="canvasImageContainer text-center" id={`canvasImgContainer${question.id}`}
             onClick={() => this.showMarkerArea(question.id)}
             style={{cursor: this.props.response?.state !== 'Completed' && !blockerExpired && this.props.response?.showBlocker === false ? 'pointer' : 'default'}}>
          <img
              ref={this.imgRef}
              src={ question.attachment[0].url }
              alt="canvas_image"
              crossOrigin="anonymous"
              style={{ width: 'auto', height: '300px', borderRadius: '4px' }}
              onLoad={ ev => this.displayMarkers(ev.target, this.state.annotations, question.id) }
          />
          {this.props.response?.state !== 'Completed' && !blockerExpired && this.props.response?.showBlocker === false &&
              <div style={{
                marginTop: '5px', fontSize: '12px', fontFamily: 'Sharp Sans Medium, Helvetica, sans-serif',
                cursor: this.props.response?.state !== 'Completed' && !blockerExpired && this.props.response?.showBlocker === false ? 'pointer' : 'default'}}>
                  <FontAwesomeIcon icon={['fas','circle-arrow-up']} style={{marginRight: '5px'}}/>
                  {i18n[language].clickImageToAnnotate}
              </div>
              }
        </div>

    let responseButtons =
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-buttons-response">
          { !isSavingResponse && <div className={`dropup ${saveHelpPopupClass}`} style={{display: 'inline'}}>
            <button onClick={from !== 'AB Preview' ? () => this.saveAndNotify('Draft', question.id) : () => {}}
                    className="btn px-btn sm btn-save-test" disabled={isSavingResponse}>
              { !isSavingResponse ? taskI18n[language].save_btn : <FontAwesomeIcon icon={['fas','spinner']} spin/> }
              <div className="ripple-container" />
            </button>
            <div className="dropdown-menu dropdown-menu px-drop-menu btn-save-popup">
              <span style={{color: colors.color4[3]}}>{taskI18n[language].saveHelp1} </span>{saveHelpMessage}
              <div className="clickable" style={{ color: colors.color1[3] }}><span onClick={() => this.stopSaveHelpPopup()}>{taskI18n[language].dontShowAgain}</span></div>
            </div>
          </div>
          }
          <button onClick={this.validationCompleted} className={`btn px-btn sm btn-turquoise-base ${isSavingResponse ? '' : 'mg-left-15'}`}
                  style={this.state.showSaveHelpPopup === true ?
                      { backgroundColor: colors.color2[3], position: 'absolute', zIndex: '1024' } : { backgroundColor: colors.color2[3] }}
                  disabled={isSavingResponse}>{!isSavingResponse && taskI18n[language].complete_btn}
            {
              !isSavingResponse ? cardinal < questionsLength &&
                <FontAwesomeIcon icon={['fas', 'circle-chevron-right']} className="mg-left-10"
                                 style={{marginLeft: '5px'}}/> :
                <span style={{color: '#000000'}}>{i18n[language].savingResponse}
                  <FontAwesomeIcon icon={['fas', 'spinner']} spin style={{marginLeft: '5px'}}/>
                </span>
            }
            <div className="ripple-container"/>
          </button>
        </div>;

    let btnComments = null;
    let commentsComp = null;
    let responseLikes = null;

    let answerComp = <div className={""}>
      { answers }
    </div>;

    if(response !== undefined) {
      // const minAnnotations = parseInt(question.data.minAnnotations);


      let sumAllCommentsReplies = 0;
      response.comments ?
          response.comments.forEach(c => {
            ++sumAllCommentsReplies;
            c.replies.forEach(r => {
              ++sumAllCommentsReplies
            })
          }) : 0;

      btnComments =
          <button onClick={from !== 'AB Preview' ? () => this.props.updateShowComments(question.id) : () => {
          }}
                  type="button" className="btn btn-comments"
                  style={response.showComments ?
                      {color: '#ffffff', borderColor: colors.color1[3], backgroundColor: colors.color1[3]} :
                      {color: colors.color1[3], borderColor: colors.color1[3], backgroundColor: '#ffffff'}}>
            <div className="comments-container">
              {response.sum_unseen > 0 && <div className="unseen" style={{backgroundColor: colors.color4[3]}}/>}
              <FontAwesomeIcon icon={['fas', 'message']} className="icon-comments"
                               style={{color: response.showComments ? '#ffffff' : colors.color1[3]}}/>
              <span className="txt-comment" style={{marginLeft: '0'}}>{i18n[language].comments}</span>
              <div className="number-container">
          <span className="number">
            {sumAllCommentsReplies}{/*response.comments ? response.comments.length : 0*/}
          </span>
              </div>
            </div>
            <div className="px-support-tooltip top is_hidden px-support-response" style={{}}>
              <p>{commentsHelpTooltip}</p><i/>
            </div>
          </button>;
      commentsComp = <div className={response.showComments ? 'px-n-accordion-open' : 'px-n-accordion-close'}>
        <Comments comments={response.comments ? response.comments : []}
                  language={language}
                  userId={userId}
                  username={''}
                  questionId={response.question_id}
                  responseId={response.response ? response.response.id : -1}
                  role_1={'Participant'}
                  updateResponseComments={this.props.updateResponseComments}
                  from={'PAV'}
                  item_scroll={item_scroll}
                  updateScrollFirstTime={this.props.updateScrollFirstTime}
                  scrollFirstTime={scrollFirstTime}
                  colors={this.props.colors}
                  aiAgentName={this.props.aiAgentName}
                  company={company}
        />
      </div>;

      if (response.response) {
        if ((response.response.state === 'Completed' || response.response.state === 'Accepted') && response.showBlocker) {
          responseButtons = <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mg-top-15">
            <button onClick={() => this.props.updateShowBlocker(question.id)}
                    className="btn px-btn sm btn-blue-base"
                    style={{width: '120px', backgroundColor: colors.color1[3]}}>
              {taskI18n[language].edit_btn}
              <div className="ripple-container"/>
            </button>
          </div>;

          answerComp = <div className="panel activity-response-completed-container" style={{marginBottom:'20px'}}>
            {
                !blockerExpired &&
                <div className="panel-heading" style={{ backgroundColor: colors.color3[5] }}>
                  <h3 className="panel-title">{i18n[language].taskCompleted}</h3>
                </div>
            }
            <div className="panel-body">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className={"wd-100"} style={{borderTop: '0'}}>
                    { answers }
                  </div>
                </div>
              </div>
            </div>
          </div>

        } else {
          if(blockerExpired){
            answerComp = <div className="panel activity-response-completed-container" style={{marginBottom:'20px'}}>
              <div className="panel-body">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className={"wd-100"}>
                      { answers }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        }
      } else {
        if(blockerExpired){
          answerComp = <div className="panel activity-response-completed-container" style={{marginBottom:'20px'}}>
            <div className="panel-body">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className={"wd-100"} style={{borderTop: '0'}}>
                    { answers }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      }

      responseLikes = response.likes;
    }

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" ref='myMainDiv' tabIndex="1">
          {
              this.props.participants_view === 'As a list' &&
              <div className="px-counter-task" style={{backgroundColor: colors.color0[1]}}>
                <span className="number">{this.props.cardinal}</span>
              </div>
          }
          <div id={userId + '_' + question.id} className="panel px-card px-panel-task response">
            <div className="panel-body">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div dangerouslySetInnerHTML={{__html: question.title}} className="title"/>
                </div>
              </div>
              {
                  (question.attachment.length !== 0 || question.video_thumbnail !== '' || question.blobPreview) &&
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="px-answer-media-container" >
                        {
                            question.video_thumbnail !== '' &&
                            <div className="px-media-img">
                              <FontAwesomeIcon onClick={this.updateShowModalQuestionVideo} icon={['far','expand-alt']}
                                               size='lg' style={{position:'absolute', left:'102px', top: '5px',
                                backgroundColor:'white',padding: '0 2px', borderRadius: '4px', cursor: 'pointer'}}/>
                              <img onClick={this.updateShowModalQuestionVideo}
                                   src={question.video_thumbnail} style={{ cursor: 'pointer' }} alt=""/>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
              }

              { (question.attachment.length !== 0 || question.video_thumbnail !== '' || question.blobPreview) &&
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                      {
                        blockerExpired ?
                            <div className="panel activity-response-completed-container" style={{marginBottom:'0'}}>
                              <div className="panel-heading" style={{ backgroundColor: blockerExpired ? colors.color4[6] : colors.color3[5] }}>
                                <h3 className="panel-title" style={{ color: blockerExpired ? colors.color4[3] : ''}}>
                                  {blockerExpired ? i18n[language].taskExpired : i18n[language].taskCompleted}</h3>
                              </div>
                              { answerComp }
                            </div> :
                            answerComp
                      }

                    </div>
                  </div>
              }

              <div className="row">
                { !blockerExpired && responseButtons }
              </div>

              <div className="separator" style={{marginTop:'15px',marginBottom:'25px'}}/>
              <div className="comments-option-container">
                <div className="comments-count" style={{marginLeft:'auto'}}>
                  <div className="btn-group" role="group" aria-label="..." style={{margin:'0'}}>
                    { btnComments }
                  </div>
                </div>
              </div>
              <div className="px-n-accordion-open" style={{marginTop:'50px'}}>
                { commentsComp }
              </div>
            </div>
          </div>

          {
              question.video_thumbnail &&
              <ReactModal isOpen={this.state.showModalQuestionVideo} contentLabel="Response Video"
                          shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalQuestionVideo}
                          className="px-modal-content" overlayClassName="px-modal-overlay">
                <T_ModalVideo updateShowModalVideo={this.updateShowModalQuestionVideo}
                              thumbnail={question.video_thumbnail}
                              url={question.video_url}
                              language={language}
                              colors={colors} />
              </ReactModal>
          }
          {
              this.state.showModalValidation &&
              <ReactModal isOpen={this.state.showModalValidation} contentLabel="Activity Validation Modal"
                          shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalValidation}
                          className="px-modal-content" overlayClassName="px-modal-overlay">
                <AVTModal updateShowModal={this.updateShowModalValidation}
                          required_options={question.required_options}
                          minAnnotations={question.data.minAnnotations}
                          minChar={'1'}
                          validationResults={this.state.validationResults}
                          type={question.task_type}
                          language={language}/>
              </ReactModal>
          }
        </div>
    );
  }

  stopSaveHelpPopup = () => {
    this.setState({ showSaveHelpPopup: false });
    this.props.updateBackdrop(false);
    this.props.updateHelpStore('showSaveHelp', false);
  }

  showMarkerArea(question_id) {
    let isBlocked = ((this.props.response?.response?.state === 'Completed' || this.props.response?.response?.state === 'Accepted') && this.props.response?.showBlocker);
    let isExpired = this.props.blockerExpired;
    let colors = this.props.question.data?.markerColors;
    let colorSet = [colors[0],colors[1],colors[2],colors[3],colors[4]];
    let availableMarkers = this._buildMarkersArray();
    if (this.imgRef.current !== null && !isBlocked && !isExpired) {
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(this.imgRef.current);
      // set interface to show as modal
      markerArea.settings.displayMode = 'popup';
      markerArea.settings.popupMargin = 50;
      markerArea.settings.defaultColorSet = colorSet;
      markerArea.settings.defaultColorsFollowCurrentColors = true;
      markerArea.settings.defaultColor = colorSet[0];
      markerArea.settings.defaultFillColor = colorSet[0];
      markerArea.settings.defaultHighlightColor = colorSet[0];
      markerArea.settings.defaultStrokeColor = colorSet[0];
      markerArea.uiStyleSettings.zIndex = '1500';
      markerArea.uiStyleSettings.toolbarBackgroundColor = this.props.colors.color0[4];
      markerArea.uiStyleSettings.toolbarBackgroundHoverColor = this.props.colors.color1[6];
      markerArea.uiStyleSettings.toolbarColor = this.props.colors.color1[6];
      markerArea.uiStyleSettings.toolbarBackgroundHoverColor = this.props.colors.color1[2];
      markerArea.uiStyleSettings.toolbarHeight = '50';
      markerArea.uiStyleSettings.okButtonColor = this.props.colors.color2[3];
      markerArea.availableMarkerTypes = availableMarkers;
      // if don't want a modal and do de annotations inline set the targetRoot below to the element id of the parent of the image to annotate
      // markerArea.targetRoot = document.getElementById(`canvasImgContainer${question_id}`);
      markerArea.renderAtNaturalSize = true;
      // remove marker.js logo
      let markerjs_key = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')  ? process.env.markerjs_license_key : process.env.REACT_APP_MARKERJS_LICENSE_KEY;
      markerjs2.Activator.addKey(markerjs_key);
      // attach event handlers to assign annotated image back to our image element
      markerArea.addEventListener("render", (event) => {
        if (this.imgRef.current) {
          // this.imgRef.current.src = event.dataUrl; // this would substitute the original image with a new annotated image
          this.setState({
            annotations: JSON.stringify(event.state),
            annotationImage: event.dataUrl
          });
          this.props.setCanvasObjForOPS({annotations: JSON.stringify(event.state), annotationImg: event.dataUrl});
        }
      });

      let annotations = this.state.annotations;

      // launch marker.js
      markerArea.show();

      // if annotations are present - restore them in the marking area
      if (annotations) {
        markerArea.restoreState(JSON.parse(this.state.annotations));
      }

      markerArea.addEventListener( "close", (event) => {
        // when closing marking area display annotations in preview image
        this.displayMarkers(this.imgRef.current, this.state.annotations, question_id, true);
      });
    }
  }


  displayMarkers(target, annotations, question_id, clearPrevAnnotations = true) {
    if (clearPrevAnnotations) {
      this.removeMarkersFromImage(question_id);
    }
    if (annotations) {
      const markerView = new mjslive.MarkerView(target);
      markerView.targetRoot = document.getElementById(`canvasImgContainer${question_id}`);
      let mjsLive_key = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')  ? process.env.mjsLive_license_key : process.env.REACT_APP_MJSLIVE_LICENSE_KEY;
      mjslive.Activator.addKey(mjsLive_key);
      markerView.show(JSON.parse(annotations));
    }
  }

  removeMarkersFromImage(question_id) {
    let markerLiveElements = document.getElementById(`canvasImgContainer${question_id}`).querySelectorAll('.__markerjslive_');
    if (markerLiveElements.length > 0) {
      markerLiveElements.forEach(el => { el.remove() });
    }
  }

  saveAndNotify = (state, question_id, user_id, cardinal, from ) => {
    let canvasObj = {annotations: this.state.annotations, annotationImg: this.state.annotationImage}

    this.props.saveResponse(state, question_id, user_id, cardinal, from, canvasObj);

    if(this.props.helpStores.showSaveHelp === true){
      this.props.updateBackdrop(true);
      this.setState({ showSaveHelpPopup: true });
    }
  }

  validationCompleted = () => {
    // console.log('this.props.question.required:', this.props.question.required);
    this.props.updateBackdrop(false);
    if(this.props.question.required){
      let results = [];
      this.props.question.required_options.forEach(option => {
        results.push(this._getValidation(option));
      });
      const resultValues = results.map(result => result.value);
      this.setState({ validationResults: results }, () => {
        if([...new Set(resultValues)].length === 1) {
          if([...new Set(resultValues)][0]) {
            let canvasObj = {annotations: this.state.annotations, annotationImg: this.state.annotationImage};
            this.props.saveResponse('Completed', this.props.question.id, this.props.userId, this.props.cardinal, null, canvasObj);
          } else {
            this.updateShowModalValidation();
          }
        } else {
          this.updateShowModalValidation();
        }
      });
    } else {
      if(this.props.from !== 'AB Preview') {
        let canvasObj = {annotations: this.state.annotations, annotationImg: this.state.annotationImage};
        this.props.saveResponse('Completed', this.props.question.id, this.props.userId, this.props.cardinal, null, canvasObj);
      }
    }
  };

  _getValidation = (type) => {
    const { response } = this.props;
    let result = null;

    switch(type) {
      case 'minAnnotations':
        let annotations = this.state.annotations;
        let numberOfAnnotations = 0
        if (annotations !== undefined && annotations !== ''  ) {
          annotations = JSON.parse(annotations);
          numberOfAnnotations = annotations.markers?.length;
        }

        result = { name: 'minAnnotations', value: numberOfAnnotations >= this.props.question.data.minAnnotations }
        break;
    }
    // console.log('result:', result);
    return result;
  };

  updateShowModalValidation = () => {
    this.setState(state => ({ showModalValidation: !state.showModalValidation }));
  };

  updateShowModalQuestionVideo = () => {
    this.setState(state => ({ showModalQuestionVideo: !state.showModalQuestionVideo }));
  };

  _buildMarkersArray = () => {
    let availableMarkerHash = { arrowsMarker: this.props.question.data?.arrowsMarker,
      calloutsMarker: this.props.question.data?.calloutsMarker,
      freeHandMarker: this.props.question.data?.freeHandMarker,
      highlightMarker: this.props.question.data?.highlightMarker,
      shapesMarker: this.props.question.data?.shapesMarker,
      textMarker: this.props.question.data?.textMarker,
      commentsOnMarkers: this.props.question.data?.commentsOnMarkers
    }
    let availableMarkers = [];

    if (availableMarkerHash.highlightMarker === 'true') {
      availableMarkers.push('HighlightMarker');
    }

    if (availableMarkerHash.arrowsMarker === 'true') {
      availableMarkers.push('ArrowMarker');
    }

    if (availableMarkerHash.shapesMarker === 'true') {
      availableMarkers.push('EllipseMarker', 'RectangleMarker', 'LineMarker', 'CurveMarker' );
    }

    if (availableMarkerHash.freeHandMarker === 'true') {
      availableMarkers.push('FreehandMarker');
    }

    if (availableMarkerHash.calloutsMarker === 'true') {
      availableMarkers.push('CalloutMarker');
    }

    if (availableMarkerHash.textMarker === 'true') {
      availableMarkers.push('TextMarker');
    }

    return availableMarkers;

  }

  getAnnotationsFromProps(props) {
    return props.response?.response?.data?.canvasObj?.annotations;
  }

  handleAnnotationUpdates() {
    const fetched_annotations = this.getAnnotationsFromProps(this.props);
    this.setState({ annotations: fetched_annotations }, () => {
      this.props.setCanvasObjForOPS({annotations: this.state.annotations, annotationImg: this.state.annotationImage});
    });
    this.displayMarkers(this.imgRef.current, fetched_annotations, this.props.question.id);

  }

}

function SampleNextArrow(props) {
  const {className, style, onClick} = props;
  return (
      <div
          className={className}
          style={{...style, display: 'block', marginRight:'30px', borderRadius:'50%'}}
          onClick={onClick}
      />
  );
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props;
  return (
      <div
          className={className}
          style={{...style, display: 'block', marginLeft:'30px', zIndex:'1' , borderRadius:'50%'}}
          onClick={onClick}
      />
  );
}
