import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from "underscore";

export default class Allowances extends React.Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    communities: PropTypes.array.isRequired,
    maxPapt: PropTypes.number.isRequired,
    maxActiveComms: PropTypes.number,
    maxClosedComms: PropTypes.number,
    maxArchivedComms: PropTypes.number,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      pdpc: [],
      videoAllowance: [],
      ctTotalPages: 0,
      papt: 0
    }
  }

  componentDidMount(){
    console.log();
    if(this.props.profile.role === 'Superadmin'){
      this._getPapt();
      //this.getPDPC();
      //this.getVideoAllowance();
    }
  }

  render() {
    const {profile, language, communities, colors} = this.props;

    const i18n = {
      en: {
        papt: 'Participant subscription slots', adminAcc: 'Admin access', activeComm: 'Active communities',
        closedComm: 'Closed communities', archivedComm: 'Archived communities', pd: 'Participant/ days', videoAllow:'Cumulative video',
        activityLimit:'Activity/ Task limit', moreBtn: 'Need more?',
        paptInfo: "Participants slots is the number of Active, Invited and Blocked participants that you are allowed to have, at the same time, across all your active communities.",
        paptWarning: "You don't have any slots to invite additional participants in your communities. Request more slots, remove some participants from your communities or close active communities to invite new participants.",
        activeInfo: "Remain open for participants so they can respond to Activities and interact with other members.",
        activeWarning: "You cannot run more communites at this time. Request more slots or close some to create new ones.",
        closedInfo: "Participants have no longer access to them. However, Collaborators can still explore and analyse Participants' posted data.",
        closedWarning: "You cannot close more communities at this time. Request more slots or archive some closed communities and try again.",
        archivedInfo: "Neither Participants nor Collaborators can access them, but Pixiebob preserves the data in your account as a backup.",
        archivedWarning: "You cannot archive more communities at this time. Request more slots or purge some archived communities and try again.",
        from: 'from'
      },
      es: {
        papt: 'Slots de subscripción de participantes', adminAcc: 'Acceso Administradores', activeComm: 'Comunidades activas',
        closedComm: 'Comunidades cerradas', archivedComm: 'Comunidades archivadas', pd: 'Días/ participante',
        videoAllow: 'Video acumulado', activityLimit: 'Límite de Actividades/ Tareas', moreBtn: 'Quieres más?',
        paptInfo: "Participantes disponibles representa el número de participantes Activos, Invitados y Bloqueados que pueden soportar, al mismo tiempo, todas sus comunidades activas.",
        paptWarning: "No tienes espacio para invitar más participantes. Solicita más espacio o remueve participantes de otras comunidades activas y trata de nuevo.",
        activeInfo: "Comunidades Activas son aquellas que están abiertas para que los participantes contesten actividades e interactúen con otros miembros. Una vez cerradas solo los colaboradores tendrán acceso.",
        activeWarning: "No puedes tener más comunidades activas. Solicita más espacio o cierra alguna comunidad y trata de nuevo.",
        closedInfo: "Comunidades Cerradas son aquellas en las que los participantes no pueden interactuar más. Los colaboradores puden aún acceder a éstas.",
        closedWarning: "No puedes tener más comunidades cerradas. Solicita más espacio o archiva alguna comunidad cerrada y trata de nuevo.",
        archivedInfo: "Comunidades Archivadas son aqullas en las que ni participantes ni colaboradores tienen acesso. Sin embargo, el contenido de éstas aún se guardará en tu cuenta a modo de respaldo.",
        archivedWarning: "No puedes tener más comunidades archivadas. Solicita más espacio o purga alguna comunidad archivada y trata de nuevo.",
        from: 'de'
      }
    };

    const infinityIcon = < span style={{ paddingLeft: '4px', fontSize: '10px' }}><FontAwesomeIcon icon={['fas', 'infinity']} /></span>;
    const moreBtn = <button data-beacon-article="5f60da254cedfd0017634c21" type="button"
                            className="btn px-btn sm btn-blue-base btn-need"
                            style={{backgroundColor: colors.color1[3]}}>{i18n[language].moreBtn}</button>;

    const counterActiveComm = communities.filter(c => c.state === 'Enabled').length;
    const counterArchivedComm = communities.filter(c => c.state === 'Archived').length;
    const counterClosedComm = communities.filter(x => x.state === "Closed").length;

    const currentPapt = this.state.papt;
    // Check if is better to retive currentPapt form props
    //const currentPapt = communities.map(x => x.num_all_participants).reduce((a, b) => a + b, 0);

    const pdpc = this.state.pdpc.map(pc => {
      return(
        <div key={pc.community_id} className="px-media-allw-item">
          <div className="text">{pc.community_title}</div>
          <div className="px-counter-allw">{pc.pdpc}</div>
        </div>
      )
    });
    
    const videoAllowance = this.state.videoAllowance.map(vc => {
      return(
        <div key={vc.community_id} className="px-media-allw-item">
          <div className="text">{vc.community_title}</div>
          <div className={`px-counter-allw ${profile.video_mins !== null && vc.sumVideoRoundedMins >= profile.video_mins && 'warning'}`}
               style={{ backgroundColor: colors.color4[3] }}>
            {vc.sumVideoRoundedMins} from {profile.video_mins !== null ? profile.video_mins : infinityIcon}
          </div>
        </div>
      )
    });

    const maxPapt = this.props.maxPapt;
    const maxComms = this.props.maxActiveComms;
    const maxClosedComms = this.props.maxClosedComms;
    const maxArchivedComms = this.props.maxArchivedComms;
    const maxActivities = profile.activities_per_comm;
    const maxTasks = profile.tasks_per_acti;

    const paptWarning = <div className="px-allw-message warning" style={{ color: colors.color4[3] }}>
                        <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} style={{ color: colors.color4[3] }} />
                          <span className="text" style={{color: colors.color4[3]}}>
                            {i18n[language].paptWarning}
                          </span>
                        </div>;
    
    const actCommsWarning = <div className="px-allw-message warning" style={{ color: colors.color4[3] }}>
                              <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} style={{ color: colors.color4[3] }}/>
                              <span className="text" style={{color: colors.color4[3]}}>
                                {i18n[language].activeWarning}
                              </span>
                            </div>;

    const closedCommsWarning = <div className="px-allw-message warning" style={{ color: colors.color4[3] }}>
                                  <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} style={{ color: colors.color4[3] }}/>
                                  <span className="text" style={{color: colors.color4[3]}}>
                                    {i18n[language].closedWarning}
                                  </span>
                                </div>
    
    const archivedCommsWarning = <div className="px-allw-message warning" style={{ color: colors.color4[3] }}>
                                    <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} style={{ color: colors.color4[3] }}/>
                                    <span className="text" style={{color: colors.color4[3]}}>
                                      {i18n[language].archivedWarning}
                                    </span>
                                  </div>

    const videoWarning = <div className="px-allw-message warning" style={{ color: colors.color4[3] }}>
                                    <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} style={{ color: colors.color4[3] }}/>
                                    <span className="text" style={{color: colors.color4[3]}}>
                                      One of your communities has reached the max video recording allowance.
                                      Users won’t be able to upload video until you upgrade.
                                    </span>
                                  </div>                        

    return (
      <div className="px-settings-container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-card px-card-settings">
              <div className="px-icon-settings">
                <FontAwesomeIcon icon={['fas','key']}/>
              </div>

              <div className="panel-body px-body-allowances">
                {/*<div className="px-allw-item">
                  <span className="px-allw-title">{i18n[language].adminAcc}</span>
                  <div className="px-allw-container">
                    <div className="px-left-cont-allw">
                      <div className="px-counter-allw image">2 from 3</div>
                      <div className="px-img-cont-allw">
                        <img src="/assets/placeholder.png" className="px-thumb-45 img-circle" alt=""/>
                        <img src="/assets/placeholder.png" className="px-thumb-45 img-circle" alt=""/>
                        <div className="px-add-user-allw">
                          <FontAwesomeIcon icon={['far','plus']}/>
                        </div>
                      </div>
                      <button type="button" className="btn px-btn sm btn-blue-base btn-need">need more?</button>
                    </div>
                    <div className="px-right-cont-allw">
                      <div className="px-allw-message">
                        <FontAwesomeIcon icon={['fal','circle-info']}/>
                        <span className="text">
                          Only Admins can create, close, archive and delete communities under The Research
                          Company account. Only you, however, will be able to adjust Allowance and Billing settings.
                          <br/>
                          <br/>
                          Admin access can only be provided to people working for the billing company as
                          per our terms and conditions. Pixiebob reserves the right to deny admin rights if
                          the Terms of Service are violated.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>*/}

                <div className="px-allw-item">
                  <span className="px-allw-title">{i18n[language].papt}</span>
                  <div className="px-allw-container">
                    <div className="px-left-cont-allw">
                      <div className={`px-counter-allw button ${maxPapt !== null && currentPapt >= maxPapt && 'warning'}`}
                        style={{ color: maxPapt !== null && currentPapt >= maxPapt && colors.color4[3]}}>
                        {currentPapt > maxPapt ? maxPapt : currentPapt} {i18n[language].from} {maxPapt !== null ? maxPapt : infinityIcon }
                      </div>
                      {moreBtn}
                    </div>
                    <div className="px-right-cont-allw">
                      { maxPapt !== null && currentPapt >= maxPapt ? paptWarning : '' }
                      <div className="px-allw-message">
                        <FontAwesomeIcon icon={['fal', 'circle-info']} />
                        <span className="text">
                          {i18n[language].paptInfo}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="px-allw-item">
                  <span className="px-allw-title">{i18n[language].pd}</span>
                  <div className="px-allw-container">
                    <div className="px-left-cont-allw">
                      <div className="px-media-allw-cont">
                        {pdpc}
                      </div>
                      <button type="button" className="btn px-btn sm btn-blue-base btn-need">need more?</button>
                    </div>
                    <div className="px-right-cont-allw">
                      <div className="px-allw-message warning">
                        <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} />
                        <span className="text">
                          One of your communities has reached the limit of ‘participant/days. Close it or
                          increase your quota.
                        </span>
                      </div>
                      <div className="px-allw-message">
                        <FontAwesomeIcon icon={['fal', 'circle-info']} />
                        <span className="text">
                          A “participant/day” is counted when a participant logs in to a community and
                          responds to one or more tasks.
                          <br />

                          As a guide consider that 75 “participant/days” will be enough to run a community
                          of 25 participants for 3 days, assuming all participants log in and respond to
                          tasks every day.
                          <br />

                          Plan carefully. If your quota reaches zero your community will be automatically
                          closed.
                        </span>
                      </div>
                    </div>
                  </div>
              </div> */}

              <div className="px-allw-item">
                <span className="px-allw-title">{i18n[language].activeComm}</span>
                <div className="px-allw-container">
                  <div className="px-left-cont-allw">
                      <div className={`px-counter-allw button ${maxComms !== null && counterActiveComm >= maxComms && 'warning'}`}
                        style={{ color: maxComms !== null && counterActiveComm >= maxComms && colors.color4[3] }}>
                        {counterActiveComm} {i18n[language].from} {maxComms !== null ? maxComms : infinityIcon}</div>
                      { moreBtn }
                  </div>
                  <div className="px-right-cont-allw">
                    {maxComms !== null && counterActiveComm >= maxComms ? actCommsWarning : '' }
                    <div className="px-allw-message">
                      <FontAwesomeIcon icon={['fal','circle-info']}/>
                      <span className="text">
                          {i18n[language].activeInfo}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

                <div className="px-allw-item">
                  <span className="px-allw-title">{i18n[language].closedComm}</span>
                  <div className="px-allw-container">
                    <div className="px-left-cont-allw">
                      <div className={`px-counter-allw button ${maxClosedComms !== null && counterClosedComm >= maxClosedComms && 'warning'}`}
                        style={{ color: maxClosedComms !== null && counterClosedComm >= maxClosedComms && colors.color4[3] }}>
                        {counterClosedComm} {i18n[language].from} {maxClosedComms !== null ? maxClosedComms : infinityIcon}</div>
                      {moreBtn}
                    </div>
                    <div className="px-right-cont-allw">
                      {maxClosedComms !== null && counterClosedComm >= maxClosedComms ? closedCommsWarning : ''}
                      <div className="px-allw-message">
                        <FontAwesomeIcon icon={['fal', 'circle-info']} />
                        <span className="text">
                          {i18n[language].closedInfo}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-allw-item">
                  <span className="px-allw-title">{i18n[language].archivedComm}</span>
                  <div className="px-allw-container">
                    <div className="px-left-cont-allw">
                      <div className={`px-counter-allw button ${maxArchivedComms !== null && counterArchivedComm >= maxArchivedComms && 'warning'}`}
                        style={{ color: maxArchivedComms !== null && counterArchivedComm >= maxArchivedComms && colors.color4[3] }}>
                        {counterArchivedComm} {i18n[language].from} {maxArchivedComms !== null ? maxArchivedComms : infinityIcon}</div>
                      {moreBtn}
                    </div>
                    <div className="px-right-cont-allw">
                      {maxArchivedComms !== null && counterArchivedComm >= maxArchivedComms ? archivedCommsWarning : ''}
                      <div className="px-allw-message">
                        <FontAwesomeIcon icon={['fal', 'circle-info']} />
                        <span className="text">
                          {i18n[language].archivedInfo}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="px-allw-item">
                  <span className="px-allw-title">{i18n[language].activityLimit}</span>
                  <div className="px-allw-container">
                    <div className="px-left-cont-allw">
                      <div className={`px-counter-allw button ${counterActiveComm >= 3 && 'warning'}`}>
                        {counterActiveComm} from {profile.max_num_communities}</div>
                      <button type="button" className="btn px-btn sm btn-blue-base btn-need">need more?</button>
                    </div>
                    <div className="px-right-cont-allw">
                      <div className="px-allw-message warning">
                        <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} />
                        <span className="text">
                          You cannot run more communites at this time. Close some to create new ones.
                        </span>
                      </div>
                      <div className="px-allw-message">
                        <FontAwesomeIcon icon={['fal', 'circle-info']} />
                        <span className="text">
                          Active Communities are those that are still open for participants to respond
                          activities, once they are closed, only Colaborators will be allowed to access to analize data.
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/*<div className="px-allw-item">
                  <span className="px-allw-title">{i18n[language].archivedComm}</span>
                  <div className="px-allw-container">
                    <div className="px-left-cont-allw">
                      <div className={`px-counter-allw button ${counterArchivedComm >= 3 && 'warning'}`}>
                        {`${counterArchivedComm} from ${profile.archived_comm}`}
                      </div>
                      <button type="button" className="btn px-btn sm btn-blue-base btn-need">need more?</button>
                    </div>
                    <div className="px-right-cont-allw">
                      <div className="px-allw-message">
                        <FontAwesomeIcon icon={['fal','circle-info']}/>
                        <span className="text">
                          You can archive your projects so they are not purged after 30 days of being closed.
                          <br/>
                          <br/>
                          Archived communities, although closed, are saved for unlimited time for future reference.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>*/}

                {/* <div className="px-allw-item">
                  <span className="px-allw-title">{counterVideoCumulative}</span>
                  <div className="px-allw-container">
                    <div className="px-left-cont-allw">
                      <div className="px-media-allw-cont">
                        { videoAllowance }
                      </div>
                      <button type="button" className="btn px-btn sm btn-blue-base btn-need">need more?</button>
                    </div>
                    <div className="px-right-cont-allw">
                      {videoWarning}
                      <div className="px-allw-message">
                        <FontAwesomeIcon icon={['fal','circle-info']}/>
                        <span className="text">
                          This shows the total video recording time you have available for each of your
                          communities. Every participant that uploads or records a
                          video in a community reduces your allowance. 
                          <br/>
                          Plan carefully. If your quota reaches zero participants in your communities won’t be
                          able to upload or record videos.
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/*<div className="px-allw-item">
                  <span className="px-allw-title">Video allowance - limit per video</span>
                  <div className="px-allw-container">
                    <div className="px-left-cont-allw">
                      <div className="px-counter-allw button">{profile.video_mins} minutes</div>
                      <button type="button" className="btn px-btn sm btn-blue-base btn-need">need more?</button>
                    </div>
                    <div className="px-right-cont-allw">
                      <div className="px-allw-message">
                        <FontAwesomeIcon icon={['fal','circle-info']}/>
                        <span className="text">
                                    This shows the maximum number of recording minutes per video.
                                    <br/>
                                    Users in your communities won’t be able to upload or record videos longer than
                                    this allowance.
                                  </span>
                      </div>
                    </div>
                  </div>
                </div>*/}

                {/*<div className="px-allw-item">
                  <span className="px-allw-title">White labeling</span>
                  <div className="px-allw-container">
                    <div className="px-left-cont-allw">
                      <div className="px-counter-allw button warning">
                        disabled
                      </div>
                      <button type="button" className="btn px-btn sm btn-blue-base btn-need">need more?</button>
                    </div>
                    <div className="px-right-cont-allw">
                      <div className="px-allw-message">
                        <FontAwesomeIcon icon={['fal','circle-info']}/>
                        <span className="text">
                                   This shows if you are allowed to show your brand accross the platform screens
                                    instead of Pixiebob’s standard branding.
                                  </span>
                      </div>
                      <div className="px-allw-message warning">
                        <FontAwesomeIcon icon={['fal','triangle-exclamation']}/>
                        <span className="text">
                                    At the moment your communities will have the standard Pixiebob look.
                                  </span>
                      </div>
                    </div>
                  </div>
                </div>*/}

              </div>
            </div>
            {/* <div className="px-card px-card-settings">
              <div className="panel-body px-crit-options-body">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <div className="critical-options-container">
                      <label className="label-options mg-bottom-25">Transfer superadmin access</label>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="px-crit-comm-options-cont">
                            <button className="btn px-btn lg btn-orange-base">Transfer</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }

  getVideoAllowance = () => {
    console.log('getVideoAllowance');
    $.ajax({
      url: '/users/get_responses_video_allowance',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: videoAllowance => {
        let cameraTag = [];
        const url = 'https://cameratag.com/api/v12/apps/a-20ecc5c0-e390-0135-cbb1-0adeb7a592e0/assets.json';
        const params = {api_key: 'yjJ5stjQJxecPeX2tb3J', state: 'processed', type: 'Video'};

        axios.get(url, {params: params})
          .then(response => {
            cameraTag = response.data.assets;
            console.log(response.data);
            const pages = response.data.total_pages;
            this.setState({ctTotalPages: response.data.total_pages}, () => {
              for(let x = 2; x <= pages; x++){
                axios.get(url, {params: {...params, page: x}})
                  .then(response => {
                    cameraTag = [...cameraTag, ...response.data.assets];
                    console.log(response.data);
                    this.setState(prevState => ({ctTotalPages: prevState.ctTotalPages - 1}), () => {
                      console.log(this.state.ctTotalPages);
                      if(this.state.ctTotalPages === 1){
                        console.log(videoAllowance, cameraTag);
                        const commVideos = videoAllowance.map(comm => {
                          let sumVideoSeconds = 0;
                          comm.sum_responses.forEach(video_uuid => {
                            const videoObj = cameraTag.find(video => video.uuid === video_uuid);
                            if(videoObj !== undefined){
                              console.log(videoObj.medias.mp4.length);
                              sumVideoSeconds += videoObj.medias.mp4.length;
                            }
                          });

                          return {...comm, sumVideoRoundedMins: Math.round(sumVideoSeconds / 60)};
                        });
                        console.log(commVideos);
                        this.setState({videoAllowance: commVideos});
                      }
                    });
                  });
              }
            });
          });
      }
    });
  };

  _getPapt = () => {
    $.ajax({
      url: '/users/get_papt',
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: papt => {
        //console.log(papt);
        this.setState({papt});
      }
    });
  };

  getPDPC = () => {
    $.ajax({
      url: '/users/get_part_days_comm',
      method: 'GET',
      success: pdpc => {
       this.setState({pdpc});
      }
    });
  }
}
