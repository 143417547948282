import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";

import TFM_Advanced from "../T_Filters/TFM_Advanced";
import {usernamesToDisplay} from "../../shared_utils";

export default class TFUsers extends React.Component {
  static propTypes = {
    from: PropTypes.string.isRequired,
    objSearch: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    // updateShowPills: PropTypes.func.isRequired,
    handleUserArrows: PropTypes.func.isRequired,
    advancedActions: PropTypes.func.isRequired,
    // updatePills: PropTypes.func.isRequired,
    allAvailableActs: PropTypes.array.isRequired,
    segments: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    updateFiltersOpts: PropTypes.func.isRequired,
    updateSltCalendar: PropTypes.func.isRequired,
    updateStartExpireDates: PropTypes.func.isRequired,
    updateFromModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    executeUpdateModal: PropTypes.func.isRequired,
    updateShowTaskPicker: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    isLoadingResponsesConsolidated: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired,
    isBlocking: PropTypes.bool.isRequired,
    searchModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.objSearch !== this.props.objSearch){
      //console.log('Cambio objsearch');
    }
  }

  render() {
    const {objSearch, users, allAvailableActs, segments, tags, showModal, language,
      isLoadingResponsesConsolidated, colors, isBlocking} = this.props;

    const i18n = {
      en: { taskPicker: 'Task Picker'},
      es: { taskPicker: 'Selector de tareas' }
    };

    let btnUserArrowLeft = null;
    let btnUserArrowRight = null;
    let imgUserAvatar;

    if(objSearch.idPars.length === 1){
      const user = users.find(user => user.id === objSearch.idPars[0]);
      const availableUsernames = usernamesToDisplay(user, 'alias', 'all', 'string');
      //console.log('isBlocking:', isBlocking, 'isLoadingResponsesConsolidated:', isLoadingResponsesConsolidated);

      btnUserArrowRight = <FontAwesomeIcon onClick={(isBlocking || isLoadingResponsesConsolidated) ? null : () => this.props.handleUserArrows('right')}
                                           icon={['fas','caret-right']} className="cursor right"/>;
      btnUserArrowLeft = <FontAwesomeIcon onClick={(isBlocking || isLoadingResponsesConsolidated) ? null : () => this.props.handleUserArrows('left')}
                                          icon={['fas','caret-left']} className="cursor left" />;

      //btnUserArrowRight = <FontAwesomeIcon onClick={(user !== undefined && !isLoadingResponsesConsolidated) ?
      //  () => this.props.handleUserArrows('right') : null} icon={['fas','caret-right']} className="cursor right"/>;
      //btnUserArrowLeft = <FontAwesomeIcon onClick={(user !== undefined && !isLoadingResponsesConsolidated) ?
      //  () => this.props.handleUserArrows('left') : null} icon={['fas','caret-left']} className="cursor left" />;
      imgUserAvatar = <img src={user !== undefined ? user.avatar !== '' ? user.avatar : '/assets/user.png' : '/assets/user.png'}
                           className="px-thumb-45 img-circle" alt="" data-tooltip-content={`a.k.a: ${availableUsernames}`}/>;

    } else {
      imgUserAvatar = <div className="multiple-users" style={{backgroundColor: colors.color1[1]}}>
        <FontAwesomeIcon icon={['fas', 'user-group']} style={{fontSize: '16px'}}/>
        <div className="counter-users" style={{ backgroundColor: colors.color0[3] }}>
          <span>{objSearch.idPars.length}</span>
        </div>
      </div>;
    }

    return (
      <div className="card-profile">
        <FontAwesomeIcon onClick={this.props.updateShowTaskPicker} icon={['fad', 'crosshairs']} id="card-filter-icon"
                         data-tooltip-content={i18n[language].taskPicker}/>
        <div className="users-container" style={{padding: objSearch.idPars.length !== 1 && '0 15px'}}>
          { btnUserArrowLeft }
          { imgUserAvatar }
          { btnUserArrowRight }
        </div>
        {
          // from === 'Collaborator' &&
          // <i onClick={this.props.updateShowPills} className="mdi mdi-dots-horizontal-circle icon-circle"/>
        }
        {
          showModal &&
          <ReactModal isOpen={showModal} contentLabel="Dashboard Collaborator Modal"
                      shouldCloseOnOverlayClick={true} onRequestClose={this.props.updateShowModal}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <TFM_Advanced updateShowModal={this.props.updateShowModal}
                          users={users}
                          objSearch={objSearch}
                          advancedActions={this.props.advancedActions}
                          updatePills={this.props.updatePills}
                          allAvailableActs={allAvailableActs}
                          segments={segments}
                          tags={tags}
                          updateFiltersOpts={this.props.updateFiltersOpts}
                          updateSltCalendar={this.props.updateSltCalendar}
                          updateStartExpireDates={this.props.updateStartExpireDates}
                          gender_as_segment={this.props.gender_as_segment}
                          updateFromModal={this.props.updateFromModal}
                          executeUpdateModal={this.props.executeUpdateModal}
                          language={language}
                          colors={colors}
                          searchModal={this.props.searchModal}/>
          </ReactModal>
        }
      </div>
    );
  }
}
