import PropTypes from 'prop-types';
import React from 'react';

import Layout from "../../Layout";
import InviteViaEmail from "../../../containers/Community/InviteViaEmail";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";
import axios from "axios";

export default class InviteViaEmailPage extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    sessionLang: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    active_users: PropTypes.array.isRequired,
    rooms: PropTypes.array.isRequired,
    communities: PropTypes.array.isRequired,
    papt: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      communitiesTop: [],
      notifications: [],
      rooms: [],
      activeUsers: [],
      comPapt: 0,
      papt: 0,
      openPMFromOutside: {username: '', userId: null, flag: false},
      notificationsCounter: 0,
      isBeaconInit: false //Keeps track of any instances of Head Scout Beacons initialised.
    }
  }

  componentDidMount(){
    if(this.props.type === 'Participant') {
      this.getPaptCommunity(this.props.community.id);
      //this._getPapt();
    }
    this._getCommunitiesTop();
    this._getNotificationsCounter();
  }

  render() {
    const {user_account, profile, sessionLang, community, type} = this.props;

    return (
      <Layout user_account={user_account}
              profile={profile}
              sessionLang={sessionLang}
              from={'CommunityPage'}
              community={community}
              communities={this.state.communitiesTop}
              notifications={this.state.notifications}
              rooms={this.state.rooms}
              active_users={this.state.activeUsers}
              leftMenuActive={type === 'Participant' ? 'Participants' : 'Collaborators'}
              openPMFromOutside={this.state.openPMFromOutside}
              notificationsCounter={this.state.notificationsCounter}
              colors={user_account.all_colors}>

        {/*<HelpScoutBeacon beaconId='0103d802-14af-4e84-8ec1-9c5f45542228'*/}
        {/*                 user_account={user_account}*/}
        {/*                 colors={user_account.all_colors}*/}
        {/*                 isBeaconInit={this.state.isBeaconInit}*/}
        {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
        {/*                 hideFABOnDesktop={!this.props.user_account.profile.is_help}/>*/}

        <InviteViaEmail type={type}
                        community={community}
                        role_2={profile.role_2}
                        companyName={user_account.company.name}
                        maxPapt={community.num_participants}
                        papt={this.state.comPapt}
                        user_account={this.props.user_account}
                        isBeaconInit={this.state.isBeaconInit}
                        updateIsBeaconInit={this.updateIsBeaconInit}
                        colors={user_account.all_colors}/>
      </Layout>
    );
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  getPaptCommunity = communityId => {
    axios.get('/users/get_papt_community/' + communityId, {})
      .then(response =>  {
        console.log(response.data);
        const comPapt = response.data;
        this.setState({comPapt});
      }).catch(error => {console.log(error)});
  };

  _getPapt(){
    $.ajax({
      url: '/users/get_papt',
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: papt => {
        //console.log(papt);
        this.setState({papt});
      }
    });
  }

  _getActiveUsers = () => {
    $.ajax({
      url: '/communities/coll_get_active_parts/' + this.props.community.id + '/settings',
      method: 'GET',
      success: activeUsers =>  {
        this.setState({activeUsers});
      }
    });
  };

  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log(communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  _getRooms = () => {
    $.ajax({
      url: '/communities/get_rooms/' + this.props.community.id,
      method: 'GET',
      success: rooms =>  {
        //console.log(rooms);
        this.setState({rooms});
      }
    });
  };

  _getNotifications = () => {
    $.ajax({
      url: '/notifications/get_notifications/-1',
      method: 'GET',
      success: notifications =>  {
        this.setState({notifications});
      }
    });
  };
}