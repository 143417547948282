import React from 'react'
import PropTypes from 'prop-types'

import {parseMarkdownTable} from "../../../../UI/Globals/PX_Funs"

const TRMChatCard = ({ message, currentUserId, avatar }) => {
  const mainCss = message.user_id === currentUserId ? 'sender' : 'receiver'
  const bodyHtml = message.body.replace(/\n/g, "<br />")
  const avatarImg = message.kind === 'AI' ? '/assets/fas-robot.png' : avatar
  const tableData = parseMarkdownTable(message.body)

  const renderTable = data => {
    return (
      <div className="table-container">
        <div className="table-row header">
          {data.headers.map((header, index) => (
            <div key={index} className="table-cell">{header}</div>
          ))}
        </div>
        {data.rows.map((row, rowIndex) => (
          <div key={rowIndex} className="table-row">
            {row.map((cell, cellIndex) => (
              <div key={cellIndex} className="table-cell">{cell}</div>
            ))}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={mainCss + '-container ' +  message.kind.toLowerCase()} style={{marginBottom:'0'}}>
      <img src={avatarImg} className={"img-" + mainCss} alt=""
           style={{marginBottom: message.kind === 'AI' && '40px', height: message.kind === 'AI' && '35px'}}/>
      <div className={"conversation-"+ mainCss +"-container"}>
        <div className={`conversation-item ai-conversation-item ${ message.kind !== 'AI' && 'ai-conversation-item-question'}`}
             style={{backgroundColor: '#f6f6f6'}}>
          {
            tableData ? renderTable(tableData) : <div dangerouslySetInnerHTML={{__html: bodyHtml}}/>
          }
        </div>
      </div>
    </div>
  )
}

TRMChatCard.propTypes = {
  message: PropTypes.object.isRequired,
  currentUserId: PropTypes.number.isRequired,
  avatar: PropTypes.string.isRequired
}

export default TRMChatCard
