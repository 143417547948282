import PropTypes from 'prop-types';
import React from 'react';
import Lightbox from "react-images";
import v4 from "uuid";
const JSZip = require("jszip");
import { saveAs } from 'file-saver';
import download from "downloadjs";

import TRCImageVideo from "./TRConsolidated/TRCImageVideo";
import TRFileDownload from "./TRFileDownload";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class TRMedia extends React.Component {
  static propTypes = {
    objSearch: PropTypes.object.isRequired,
    from: PropTypes.string.isRequired,
    consolidated: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
    media: PropTypes.array.isRequired,
    cardinal: PropTypes.number,
    taskId: PropTypes.number,
    taskTitle: PropTypes.string,
    user: PropTypes.object,
    users: PropTypes.array,
    colors: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      isSavingImages: false,
      savedImagesCounter: 0,
      showCC: false
    }
  }

  render() {
    const {from, consolidated, cardinal, taskId, taskTitle, taskType, media, language, colors} = this.props
    const i18n = {
      en: { noMedia: 'No multimedia available for this task',
        ccTip: 'Image closed captioning',
        ccNotAvailable: 'Not available'
      },
      es: { noMedia: 'No hay multimedia disponible en esta tarea',
        ccTip: 'Descripción de las imágenes en texto',
        ccNotAvailable: 'No disponible'
      }
    }
    let cardHeader = null
    let cardTitle = null
    let imagesLB = [];

    if(from === 'single'){

    } else {
      cardHeader = <div className="px-counter-task" style={{backgroundColor: colors.color0[1]}}>
        <span className="number">{cardinal}</span></div>
      cardTitle = <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
          <p dangerouslySetInnerHTML={{__html: taskTitle}} className="title"/>
        </div>
      </div>;
    }

    if(media.length !== 0){
      return(
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          { cardHeader }
          <div className="panel px-card px-panel-task">
            <div className="panel-body">
              {cardTitle}
              {
                (taskType === 'Open End' || taskType === 'Multimedia' || taskType === 'Canvas') ?
                  <div className="px-attachments-container">
                    {
                      media.map((m, index) => {
                        {
                          consolidated === 'images' && imagesLB.push({src: m.url})
                        }

                        return (
                          <div key={v4()} className="px-media-img">
                            <TRFileDownload type={consolidated}
                                            action={'one'}
                                            url={consolidated === 'images' ?
                                              m.url !== undefined ? m.url : '' : m.video_url !== undefined ? m.video_url : ''}/>
                            <div onClick={consolidated === 'images' ?
                              (e) => this.openLightbox(index, e) : null}>
                              <TRCImageVideo media={m}
                                             from={from}
                                             consolidated={consolidated}
                                             colors={colors}
                                             language={language}/>
                            </div>
                          </div>
                        )
                      })
                    }
                    {
                      !this.state.isSavingImages ?
                        media.length !== 0 &&
                        <span style={{color: '#AFBDC1'}}>
                          <FontAwesomeIcon onClick={() => this.downloadFiles(consolidated, 'all', null)}
                                           icon={['fas', 'download']} style={{cursor: 'pointer'}}/>
                        </span> :
                        <span style={{color: '#AFBDC1'}}>
                          <FontAwesomeIcon icon={['fas', 'spinner']} spin/>&nbsp;
                          {Math.round((this.state.savedImagesCounter * 100) / media.length)}%
                        </span>
                    }
                    { consolidated === "images" && <span style={{color: '#AFBDC1'}}>
                          <span style={{marginLeft: '5px'}} data-tooltip-content={i18n[language].ccTip}>
                            <FontAwesomeIcon onClick={() => this.toggleShowCC()}
                                             icon={this.state.showCC ? ['fas', 'closed-captioning'] : ['fal', 'closed-captioning']}
                                             style={{cursor: 'pointer'}}/>
                          </span>
                    </span>}
                  </div> :
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <div className="profile-name text-center no-answer-container">
                      <div className="no-answer">
                        Consolidated {consolidated} not available for this task.
                      </div>
                    </div>
                  </div>
              }
              <div style={{
                marginTop: '10px',
                backgroundColor: '#e9e9e9',
                borderRadius: '6px',
                padding: '14px',
                fontSize: '12px',
                fontStyle: 'italic',
                display: this.state.showCC ? 'block' : 'none'
              }}>
                {this.joinedTranscripts(media)}
              </div>
            </div>
          </div>
          {
            consolidated === 'images' &&
            <Lightbox currentImage={this.state.currentImage}
                      images={imagesLB}
                      isOpen={this.state.lightboxIsOpen}
                      onClickPrev={this.gotoPrevious}
                      onClickNext={this.gotoNext}
                      onClickThumbnail={this.gotoImage}
                      onClose={this.closeLightbox}/>
          }
        </div>
      )
    } else {
      return(
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          { cardHeader }
          <div className="panel px-card px-panel-task">
            <div className="panel-body">
              { cardTitle }
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <h4 className='px-empty-msg' style={{color: colors.color4[3]}}>{i18n[language].noMedia}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  toggleShowCC = () => {
    this.setState(prevState => ({
      showCC: !prevState.showCC
    }));
  }

  joinedTranscripts = (media) => {
    const i18n = {
      en: { ccNotAvailable: 'Not available' },
      es: { ccNotAvailable: 'No disponible' }
    }

    return media.map((item, index) =>
      (<span key={index}>
        <strong>Image {index + 1}:</strong>
        <span style={{marginLeft: '5px', marginRight: '10px'}}>{item.transcript ? item.transcript : i18n[this.props.language].ccNotAvailable}</span>
      </span>))
  }

  downloadFiles = async (consolidated, action, urlOne) => {
    // console.log(consolidated, action);
    this.setState({isSavingImages: true, savedImagesCounter: 0}, async() => {
      if(consolidated === 'images'){
        if(action === 'one'){
          /*const blob = await fetch(decodeURIComponent(urlOne)).then(r => r.blob());
          console.log('blob:', blob);
          download(blob, Date.now(), blob.type);
          this.setState({isSavingOneImage: false});*/
        } else {
          const zip = new JSZip();

          for(const attachment of this.props.media){
            // console.log(decodeURIComponent(attachment.url));
            const blob = await fetch(decodeURIComponent(attachment.url)).then(r => r.blob());
            // console.log('blob:', blob);
            let extension = '';
            switch (blob.type) {
              case 'image/jpeg': extension = 'jpg';break;
              case 'image/png': extension = 'png';break;
              default: extension = 'jpg';
            }

            zip.file(Date.now() + '.' + extension, blob, {base64: true});
            this.setState(prevState => ({savedImagesCounter: prevState.savedImagesCounter + 1}));
          }

          zip.generateAsync({type:"blob"}).then((content) => {
            const actTitle = this.props.taskTitle.replace(/(<([^>]+)>)/ig,"").substr(0, 10);
            saveAs(content, actTitle + '_Consolidated.zip');
            // console.log('Ready');
            this.setState({isSavingImages: false});
          });
        }
      } else {
        // VIDEOS
        const zip = new JSZip();

        for(const attachment of this.props.media){
          // console.log(decodeURIComponent(attachment.video_url));
          const blob = await fetch(decodeURIComponent(attachment.video_url)).then(r => r.blob());
          // console.log('blob:', blob);
          let extension = '';
          switch (blob.type) {
            case 'video/mp4': extension = 'mp4';break;
            default: extension = 'mp4';
          }

          zip.file(Date.now() + '.' + extension, blob, {base64: true});
          this.setState(prevState => ({savedImagesCounter: prevState.savedImagesCounter + 1}));
        }

        zip.generateAsync({type:"blob"}).then((content) => {
          const actTitle = this.props.taskTitle.replace(/(<([^>]+)>)/ig,"").substr(0, 10);
          saveAs(content, actTitle + '_Consolidated.zip');
          // console.log('Ready');
          this.setState({isSavingImages: false});
        });
      }
    });
  };

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };
  gotoPrevious = () => {
    this.setState({ currentImage: this.state.currentImage - 1 });
  };
  gotoNext = () => {
    this.setState({ currentImage: this.state.currentImage + 1 });
  };
  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    });
  };
}
