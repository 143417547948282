import React from "react"
import PropTypes from 'prop-types'
import RoomCard from "./RoomCard"

const CardList = ({ toggleShowNewChatModal, colors, aiChatRooms, handleInterview, interviews,
                    language, onClickChatCard, activeChatRoomId, toggleEditChatRoom, toggleDelMod }) => {

  const i18n = {
    en: { new: 'New' },
    es: { new: 'Nuevo'}
  }

  return (
    <div className="ai-chat-cards-list" style={{height:'100%', overflowY: 'scroll'}} >
      <button onClick={() => toggleShowNewChatModal('create')}
              className="btn px-btn lg btn-turquoise-base"
              style={{minWidth: '175px', minHeight: '35px', marginBottom: '15px',
                backgroundColor: colors.color2[3]}}>
        {i18n[language].new}
      </button>
      <div className="px-list-actv-container">
        <div className="cards-container">
          <div className="cards-holder-container">
            {aiChatRooms && aiChatRooms.map(room => (
              <RoomCard key={room.id}
                        id={room.id}
                        ai_chat_room_id={room.id}
                        title={room.title}
                        sources={room.sources}
                        handleInterview={handleInterview}
                        interviews={interviews}
                        language={language}
                        onClickChatCard={onClickChatCard}
                        colors={colors}
                        activeChatRoomId={activeChatRoomId}
                        toggleEditChatRoom={toggleEditChatRoom}
                        toggleDelMod={toggleDelMod}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

CardList.propTypes = {
  toggleShowNewChatModal: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired,
  handleInterview: PropTypes.func.isRequired,
  interviews: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  onClickChatCard: PropTypes.func.isRequired,
  toggleEditChatRoom: PropTypes.func.isRequired,
  toggleDelMod: PropTypes.func.isRequired,
  aiChatRooms: PropTypes.array,
  activeChatRoomId: PropTypes.number
}

export default CardList