import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getCSRF, showSuccessMsg} from "../../UI/Globals/PX_Funs";
import axios from 'axios';
import InvitationUrlRow from "../Invitations/InvitationUrlRow"
const classNames = require('classnames');

export default class IModalGI extends React.Component {
  static propTypes = {
    updateShowModalGI: PropTypes.func.isRequired,
    community: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    segments: PropTypes.array.isRequired,
    invitationUrls: PropTypes.array,
    getInvitationUrls: PropTypes.func.isRequired,
    loadingInvitationUrls: PropTypes.bool.isRequired,
    updateInvitationUrl: PropTypes.func,
    role2: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    let role_2 = 'Moderator';
    if (props.role2 === 'Moderator') {
      role_2 = 'Observer';
    }

    this.state = {
      userSegments: [],
      role_2: role_2,
      selectedSegments: [],
      showCustomLinkModal: false,
      customLinks: [],
      editingQuotaUrlId: null,
      newQuota: '',
      usedParticipantSlots: null
    };
  }

  componentDidMount(prevProps){
    this.getUsedParticipantSlots();
  }

  componentDidUpdate(prevProps, prevState){
    if (
      prevProps.community.id !== this.props.community.id ||
      prevProps.invitationUrls !== this.props.invitationUrls ||
      prevState.customLinks !== this.state.customLinks
    ) {
      this.getUsedParticipantSlots();
    }
  }

  getUsedParticipantSlots() {
    axios.get(`/used_participant_slots/${this.props.community.id}`,
      {}, getCSRF()
    ).then((r) => {
      this.setState({usedParticipantSlots: r.data})
    })
      .catch((error) => console.log('Participant Slots Fetching Error', error))
  }

  getGenericUrl(communityId, segmentIds = []) {
    const baseUrlProtocol = window.location.protocol;
    const baseUrlHost = window.location.host;
    const segmentPath = segmentIds.length ? `/${segmentIds.join(',')}` : '';
    return `${baseUrlProtocol}//${baseUrlHost}/you_are_invited/${communityId}${segmentPath}/${this.props.community.generic_link_token}`;
  }

  handleToggleSegment = (segmentId) => {
    this.setState((prevState) => {
      const { selectedSegments } = prevState;
      if (selectedSegments.includes(segmentId)) {
        return {
          selectedSegments: selectedSegments.filter(id => id !== segmentId)
        };
      } else {
        return {
          selectedSegments: [...selectedSegments, segmentId]
        };
      }
    });
  };

  handleCreateCustomLink = () => {
    const { community, segments } = this.props;
    const { selectedSegments, customLinks, usedParticipantSlots } = this.state;

    const newLink = this.getGenericUrl(community.id, selectedSegments);

    // Get segment names based on selected segment IDs
    const segmentNames = segments
      .filter(segment => selectedSegments.includes(segment.id))
      .map(segment => segment.name);

    this.setState({
      customLinks: [...customLinks, { segments: segmentNames, url: newLink }],
      selectedSegments: [],
      showCustomLinkModal: false
    });

    axios.post('/invitation_urls', { invitation_url: {
        name: segmentNames.join('+'),
        community_id: community.id,
        segment_ids: selectedSegments,
        url: newLink,
      }
    }, getCSRF()).then((r) => {
      this.props.getInvitationUrls(community.id)
      showSuccessMsg('Custom link saved successfully');
    }).catch(() => {
      showSuccessMsg('Failed to save custom link');
    });
  };

  renderHeader() {
    const { language, updateShowModalGI } = this.props;
    const i18n = {
      en: { title: 'Generic Invitation Links' },
      es: { title: 'Links genéricos para invitar' }
    };

    return (
      <div className="modal-header">
        <button onClick={updateShowModalGI} type="button" data-dismiss="modal" aria-hidden="true"
                className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
          <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
        </button>
        <h4 className="px-modal-title">{i18n[language].title}</h4>
      </div>
    );
  }

  renderTableColumnHeadersRow(showName = false,
                             showUrl = false,
                             showQuota = true,
                             showCount = true,
                             showExpiration = true,
                             fontSize = '12px',
                             color = '#262E37') {

    const { language } = this.props;
    const i18n = {
      en: {
        name: 'Name', quota: 'Quota', count: 'Count', expiration: 'Expire by',
        shareThisLink: 'Share this link to invite participants according to their segments',
        maxParticipantsWhoCanJoinWithLink: 'Maximum participants who can join with this link',
        participantsJoinedWithLink: 'Participants who have successfully joined with this link',
        cantJoinAfterDate: 'Participants can not join after this date',
        inactiveLink: 'When link is inactive participants cannot join with it'
      },
      es: {
        name: 'Nombre', quota: 'Cuota', count: 'Usos', expiration: 'Expira',
        shareThisLink: 'Comparte algún enlace para invitar según sus segmentos',
        maxParticipantsWhoCanJoinWithLink: 'Máximo de participantes que pueden unirse con el enlace',
        participantsJoinedWithLink: 'Participantes que se unieron con el enlace',
        cantJoinAfterDate: 'Los participantes no podrás unirse después de esta fecha',
        inactiveLink: 'Cuando el enlace está desactivado los participantes no pueden unirse con él'
      }
    };

    return (
      <div style={{display: 'flex', padding: '8px', borderBottom: '1px solid #ccc', alignItems: 'center'}}>
        <div style={{flex: 1, maxWidth: '50px', padding: '8px', textAlign: 'center', cursor: 'default'}}
          data-tooltip-content={i18n[language].inactiveLink}
        >
          {''}
        </div>
        <div style={{flex: 1, maxWidth: '150px', padding: '8px', textAlign: 'center', cursor: 'default'}}>
          {/*{i18n[language].name}*/}
        </div>
        <div style={{flex: 3, maxWidth: '300px', padding: '8px', textAlign: 'center', cursor: 'default'}}
             data-tooltip-content={i18n[language].shareThisLink}>
          URL
        </div>
        <div style={{flex: 1, maxWidth: '100px', padding: '8px', textAlign: 'center', cursor: 'default'}}
             data-tooltip-content={i18n[language].maxParticipantsWhoCanJoinWithLink}>
          {i18n[language].quota}
        </div>
        <div style={{flex: 1, maxWidth: '100px', padding: '8px', textAlign: 'center', cursor: 'default'}}
             data-tooltip-content={i18n[language].participantsJoinedWithLink}>
          {i18n[language].count}
        </div>
        <div style={{flex: 1, maxWidth: '150px', padding: '8px', textAlign: 'center', cursor: 'default'}}
             data-tooltip-content={i18n[language].cantJoinAfterDate}>
          {i18n[language].expiration}
        </div>
        <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', maxWidth: '100px', padding: '8px'}}>
          {/* QR Code component can be placed here */}
        </div>
      </div>
    );
  }


  renderCustomLinkModal() {
    const {segments, language} = this.props;
    const {selectedSegments} = this.state;

    const i18n = {
      en: {
        selectSegments: 'Select segments for custom link',
        createBtnLabel: 'Create'
      },
      es: {
        selectSegments: 'Selecciona segmentos para tu nuevo Link',
        createBtnLabel: 'Crear'
      }
    }

    return (
      <div className={'modal-dialog'}
           style={{position: 'absolute',
             top: '20%',
             left: '20%',
             backgroundColor: '#fff', padding: '20px', borderRadius: '8px', zIndex: 1000
      }}>
        <div className={'modal-content'}>
          <div className={'modal-header'}>
            <button onClick={() => this.setState({showCustomLinkModal: false})}
                    type="button" data-dismiss="modal" aria-hidden="true"
                    className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
              <FontAwesomeIcon icon={['fal', 'xmark']} style={{fontSize: '16px'}}/>
            </button>
            <h4 className="px-modal-title">{i18n[language].selectSegments}</h4>
          </div>

          <div className="modal-body px-modal-body-add-activity">
            <div style={{display: 'flex', flexDirection:'column', overflowX: 'scroll'}}>
              <div className={'row'}
                   style={{display: 'flex',
                     overflowY: 'scroll',
                     maxHeight: '100px',
                     alignItems: 'center',
                     alignContent: 'center',
                     flexDirection: 'row',
                     flexWrap: 'wrap'

                   }}>
                {segments.map(segment => (
                  <div key={segment.id}
                       className="px-check-cont mg-bottom-5 col-xs-6 col-sm-4 col-md-4 col-lg-4">
                    <label htmlFor={`segment-${segment.id}`}
                           className="container-check filters-modal img"
                           style={{justifyContent: 'left'}}
                    >
                      <input
                        type="checkbox"
                        id={`segment-${segment.id}`}
                        checked={selectedSegments.includes(segment.id)}
                        onChange={() => this.handleToggleSegment(segment.id)}
                      />
                      <span className="checkmark filters-modal"/>
                      <FontAwesomeIcon icon={['fas', 'bookmark']} className="tag icon" style={{color: segment.color}}/>
                      <div style={{fontSize: '12px', color: "#7C8593"}}>
                        {segment.name}
                      </div>
                    </label>
                  </div>
                ))}
              </div>
              <div style={{marginTop: '16px', textAlign: 'center'}}>
                <button onClick={this.handleCreateCustomLink}
                        className="btn px-btn sm btn-blue-base"
                        style={{backgroundColor: this.props.colors.color1[3]}}
                >
                  {i18n[language].createBtnLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      updateShowModalGI, community, segments, language,
      role2, invitationUrls, updateInvitationUrl, colors
    } = this.props;
    const {customLinks, showCustomLinkModal} = this.state;
    const i18n = {
      en: {createCustomLink: 'Create Custom Link'},
      es: {createCustomLink: 'Nuevo Link Personalizado'}}

    const sortedInvitationUrls = [...invitationUrls].sort((a, b) => {
      return a.segment_ids.length - b.segment_ids.length;
    });

    return (
      <div className="modal-dialog" style={{width: '80%'}}>
        <div className="modal-content">
          {this.renderHeader()}

          <div className="modal-body px-modal-body-add-activity">
            { sortedInvitationUrls.length > 0 && !this.props.loadingInvitationUrls ?
              <div style={{display: 'flex', flexDirection: 'column', overflowX: 'scroll'}}>
                <div style={{overflowY: 'scroll', maxHeight: '70vh'}}>
                  {this.renderTableColumnHeadersRow(false, false, true, true, true)}
                  {sortedInvitationUrls.map(invitationUrl =>
                    <InvitationUrlRow key={invitationUrl.id}
                                      invitationUrl={invitationUrl}
                                      community={community}
                                      segments={segments}
                                      language={language}
                                      updateInvitationUrl={updateInvitationUrl}
                                      usedParticipantSlots={this.state.usedParticipantSlots}
                    />
                  )}
                </div>
                <div style={{marginTop: '16px', textAlign: 'center'}}>
                  { this.props.segments && this.props.segments.length > 0 &&
                    <button onClick={() => this.setState({showCustomLinkModal: true})}
                           className="btn px-btn sm btn-blue-base"
                           style={{backgroundColor: this.props.colors.color1[3]}}
                  >
                    {i18n[language].createCustomLink}
                  </button>}
                </div>
              </div> :
              <div style={{display: 'flex', flexDirection: 'column', overflowX: 'scroll'}}>
                <span>Loading <FontAwesomeIcon icon={['fas', 'spinner']} spin /></span>
              </div>
              }


          </div>
          {showCustomLinkModal && this.renderCustomLinkModal()}
        </div>
      </div>
    );
  }
}
