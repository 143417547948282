import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class AVTChAnswer extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    answer: PropTypes.object.isRequired,
    updateChoiceItems: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    responseState: PropTypes.string.isRequired,
    showBlocker: PropTypes.bool.isRequired,
    openLightBox: PropTypes.func.isRequired,
    ansNoImages: PropTypes.bool,
    checked: PropTypes.bool,
    isCompleted: PropTypes.string,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  componentDidMount() {
    //console.log('AVTChAnswer.componentDidMount');
  }

  render() {
    const {type, question, answer, index, ansNoImages, responseState, showBlocker, checked, isCompleted, colors} = this.props;

    const image = answer.blobPreview ? answer.blobPreview : answer.img !== '' ? answer.img : '/assets/placeholder.png';

    let answerOption = null;
    if(question.data.focusOn === 'Text'){
      switch(type){
        case 'Single':
          //console.log('responseState:', responseState, 'showBlocker:', showBlocker);
          if((responseState === 'Completed' || responseState !== 'Empty') && showBlocker){
            answerOption = <div key={answer.id}
                                className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                                style={{ backgroundColor: (checked || this.state.active) && colors.color0[6]}}>
              <img src={image} alt="" className="px-img-item" style={{display: ansNoImages ? 'none' : 'block'}}/>
              <div className="px-check-cont">
                <label className="container-check choice" style={{cursor:'default'}}>
                  <input type="checkbox" defaultChecked={checked} disabled={true}/>
                  <span className="checkmark  choice"/>
                  <span className="choice" style={{marginLeft: ansNoImages ? '0' : '20px'}}>{answer.text}</span>
                </label>
              </div>
            </div>;
          } else {
            answerOption = <div key={answer.id} onMouseEnter={() => this.optionHover('enter')}
                                onMouseLeave={() => this.optionHover('leave')}
                                className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                                style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
              <img onClick={() => this.props.openLightBox(index)} src={image} alt="" className="px-img-item"
                   style={{display: ansNoImages ? 'none' : 'block'}}/>
              <div className="px-check-cont">
                <label className="container-check choice radio">
                  <input type="radio" name={"answer_item_rdo" + answer.id} onChange={(event) => this.props.updateChoiceItems(
                    event, question.id, question.data.subtype, answer.id)} checked={checked}/>
                  <span className="checkmark radio choice"/>
                  <span style={{marginLeft: ansNoImages ? '0' : '20px'}} className="choice">{answer.text}</span>
                </label>
              </div>
            </div>;
          }

          break;
        case 'Multiple':
          if((responseState === 'Completed' || responseState !== 'Empty') && showBlocker){
            answerOption = <div key={answer.id}
                                className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                                style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
              <img src={image} alt="" className="px-img-item" style={{display: ansNoImages ? 'none' : 'block'}}/>
              <div className="px-check-cont">
                <label className="container-check choice" style={{cursor:'default'}}>
                  <input type="checkbox" defaultChecked={checked} disabled={true}/>
                  <span className="checkmark  choice"/>
                  <span className="choice" style={{marginLeft: ansNoImages ? '0' : '20px'}}>{answer.text}</span>
                </label>
              </div>
            </div>;
          } else {
            answerOption = <div key={answer.id} onMouseEnter={() => this.optionHover('enter')}
                                onMouseLeave={() => this.optionHover('leave')} style={{cursor:'pointer'}}
                                className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                                style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
            <img onClick={() => this.props.openLightBox(index)} src={image} alt="" className="px-img-item"
                 style={{display: ansNoImages ? 'none' : 'block', cursor:'default'}}/>
            <div className="px-check-cont">
              <label className="container-check choice ">
                <input type="checkbox" onChange={(event) => this.props.updateChoiceItems(event, question.id,
                  question.data.subtype, answer.id)} name="answer_item" checked={checked}/>
                <span className="checkmark  choice"/>
                <span className="choice" style={{marginLeft: ansNoImages ? '0' : '20px'}}>{answer.text}</span>
              </label>
            </div>
          </div>;
          }
          break;
      }
    } else {
      if((responseState === 'Completed' || responseState !== 'Empty') && showBlocker){
        answerOption = <div key={answer.id}
                            className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                            style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
          <img src={image} alt="" className="px-img-item" style={{display: ansNoImages ? 'none' : 'block'}}/>
          <div className="px-check-cont">
            <label className="container-check choice" style={{cursor:'default'}}>
              <input type="checkbox" defaultChecked={checked} disabled={true}/>
              <span className="checkmark  choice"/>
              <span className="choice" style={{marginLeft: ansNoImages ? '0' : '20px'}}>{answer.text}</span>
            </label>
          </div>
        </div>;
      } else {
        answerOption = <div className={checked ? 'choice-item active' : "choice-item"}>
          <div className="img-container">
            <FontAwesomeIcon onClick={() => this.props.openLightBox(index)} icon={['far','expand-arrows']}
                             className="px-expand-image"/>
            <img src={image} className="" alt=""/>
            <div className="check-container">
              <div className="px-check-cont choice-img">
                <label className="container-check radio choice-img">
                  <input onChange={(event) => this.props.updateChoiceItems(event, question.id, question.data.subtype,
                    answer.id)} type="checkbox" checked={checked}/>
                  <span className="checkmark radio choice-img"/>
                </label>
              </div>
            </div>
          </div>
          <div className="text">{answer.text}</div>
        </div>;
      }
    }

    let classOpt = null;
    if(type === 'Single'){
      if(question.data.focusOn === 'Text'){
        classOpt = 'px-check-cont wd-100';
      } else {
        //classOpt = this.showBlocker ? 'wd-100': '';
        classOpt = showBlocker ? 'wd-100': '';
      }
    } else {
      if(question.data.focusOn === 'Text'){
        classOpt = 'wd-100';
      } else {
        classOpt = showBlocker ? 'wd-100': '';
        //classOpt = '';
      }
    }

    return (
      <div className={classOpt}>
        {answerOption}
      </div>
    );
  }

  optionHover = (type) => {
    this.setState({active: type === 'enter'})
  }
}
