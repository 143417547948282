import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TimeAgo from "react-timeago";

import MUserProfile from "../../../UI/MUserProfile";
import MUSeeNotes from "../../../UI/MUSeeNotes";
import {setIconGender, getCountryName} from "../../../UI/Globals/PX_Funs";

export default class DNCard extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    communityId: PropTypes.number.isRequired,  //It's required, but I will add it when I'll convert the switch in PCDashboard to one
    profile: PropTypes.object.isRequired,
    formatter: PropTypes.func.isRequired,
    viewProfile: PropTypes.func.isRequired,
    user_account: PropTypes.object.isRequired,
    clearForwardUrl: PropTypes.func.isRequired,
    updateOpenPMFromOutside: PropTypes.func,
    userActions: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      avatar: '',
      iconGender: '',
      country: '',
      iconCard: '',
      username: '',
      url: '',
      response: '',
      usernameOwner: '',  //it would be all the jsx component
      typeObj: '',
      question: '', //question title, it would be just one item.questionTitle, just different in obj reply
      body: '',
      urlSeeResponse: '',
      showModalProfile: false,
      userProfile: null,
      showSeeNotes: false
    }
  }

  componentDidMount(){
    //console.log('N_Card.componentDidMount');
    this.prepareObjects(this.props.item, this.props.language, this.props.communityId, this.props.profile);
  }

  render() {
    const {item, formatter, user_account, language, communityId} = this.props;
    const colors = user_account.all_colors;

    return (
      <div className="px-card-social">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className="master-container">
          <div className="left-container">
            <div className="upper-container">
              <div className="avatar-container text-center dropdown">
                <img src={this.state.avatar} className="px-thumb-40 img-circle" alt=""/>
                <div className="overlay-avatar" data-toggle="dropdown">
                  <FontAwesomeIcon icon={['fas','ellipsis-vertical']}/>
                  <ul className="dropdown-menu dropdown-general-options-no-hide">
                    <li><a onClick={() => this.updateShowModal(item.user_id)}>profile</a></li>
                    {
                      user_account.role_1 === 'Collaborator' ?
                        <li><a onClick={() => this.props.userActions('see responses', item.user_from)}>see responses</a></li> :
                        item.user_from.role_1 === 'Participant' &&
                        <li><a onClick={() => this.props.userActions('last response', item.user_from)}>last response</a></li>
                    }
                    {/*<li><a href="#">send message</a></li>*/}

                    {
                      user_account.role_1 === 'Collaborator' &&
                      <React.Fragment>
                        {/*<li><a href={'mailto:' + item.email}>send email</a></li>*/}
                        <li><a onClick={this.updateShowSeeNotes}>See Notes</a></li>
                      </React.Fragment>
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className="bottom-container">
              <div className="poblation-container">
                {this.state.iconGender}
                {this.state.country}
              </div>
            </div>
          </div>

          <div className="right-container">
            {
              this.state.iconCard !== '' &&
              <FontAwesomeIcon icon={['fas', this.state.iconCard]} className="icon-now" style={{color: colors.color1[3]}}/>
            }
            <span className="date">
              <TimeAgo date={item.created_at} formatter={formatter}/>
            </span>
            <p className="activity-title">
              <a className="username" style={{color: colors.color1[3], cursor:'default'}}>
                {this.state.username}&nbsp;
              </a>
              <span className="response" style={{ color: colors.color1[4], cursor: 'default' }}>{this.state.response}&nbsp;</span>
              {
                item.type !== 'response' &&
                <span style={{color: colors.color1[4]}}>
                  <a className="username-owner" style={{ color: colors.color1[3], cursor:'default'}}>
                    { language === 'en' ? `${this.state.usernameOwner}'s` : this.state.usernameOwner }
                  </a>&nbsp;
                  <span>{item.type === 'reply'}</span>
                </span>
              }
              <span>{this.state.typeObj}&nbsp;</span>
              <a onClick={(event) => this.props.clearForwardUrl(event, 'common', this.state.url)}
                 style={{cursor:'pointer'}}
                 className="activity-name" >
                {this.state.question && this.state.question.replace(/(<([^>]+)>)/ig,"")}
              </a>
            </p>
            {
              (item.type === 'response' || item.type === 'comment' || item.type === 'reply') &&
              <div className="response-container">
                <a onClick={(event) => this.props.clearForwardUrl(event, 'common', this.state.url)}
                   style={{cursor:'pointer'}}>
                  <span className="response-activity">{this.state.body}</span>
                  {
                    item.type === 'response' &&
                    <div className="image-container" style={{marginTop:"10px"}}>
                      {
                        item.responseFiles.map(attachment => {
                          return(
                            <img key={attachment.id} src={attachment.url_thumbnail !== '' ?
                              attachment.url_thumbnail : attachment.url} alt=""/>
                          )
                        })
                      }
                    </div>
                  }
                  <div className="social-stats-container">
                    <div className="likes-container" data-tooltip-content="Likes">
                      <FontAwesomeIcon icon={['fas', 'heart']}/>
                      <div className="counter">{item.like_counter}</div>
                    </div>
                    {
                      item.type !== 'reply' &&
                      <div className="comments-container" data-tooltip-content="Comments">
                        <FontAwesomeIcon icon={['fas', 'message']} className=""/>
                        <div className="counter">{item.comment_counter}</div>
                      </div>
                    }
                  </div>
                </a>
              </div>
            }
          </div>
        </div>
        {
          this.state.showModalProfile &&
          <MUserProfile showModal={this.state.showModalProfile}
                        updateShowModal={this.updateShowModal}
                        user={item.user_from}
                        role_1={user_account.role_1}
                        role_2={user_account.role_2}
                        userActions={this.props.userActions}
                        alias_from={item.alias_from}
                        modalShowPM={this.modalShowPM}
                        current_user_id={user_account.id}
                        language={language}
                        communityId={communityId}
                        colors={colors}
          />

        }
        {
          this.state.showSeeNotes &&
          <MUSeeNotes showSeeNotes={this.state.showSeeNotes}
                      updateShowSeeNotes={this.updateShowSeeNotes}
                      user={item.user_from}
                      language={language}
                      colors={colors}
          />
        }
      </div>
    );
  }

  modalShowPM = (type, username, user_id) => {
    this.setState({showModalProfile: false}, () => {
      this.props.updateOpenPMFromOutside(type, username, user_id);
    })
  };

  updateShowSeeNotes = () => {
    this.setState(prevState => ({showSeeNotes : !prevState.showSeeNotes}));
  }

  updateShowModal = (user_id) => {
    // console.log(user_id);
    this.setState(state => ({showModalProfile: !state.showModalProfile}), () => {
      if(user_id){
        this.props.viewProfile(user_id);
      }
    });
  };

  prepareObjects = (item, language, communityId, profile) => {
    //console.log(item, language, communityId, profile);
    let avatar = null;
    let iconGender = null;
    let country = null;
    let iconCard = null;
    let username = null;
    let response = null;
    let typeObj = null;
    let url = null;
    let question = null;
    let body = null;
    let usernameOwner = null;

    const i18n = {
      en: {to_do: 'To Do', done: 'Done!', comm_now: 'Community Now!', response_on: 'responded',
        reply_on: 'replied', comment_on: 'commented on', liked: 'liked', like_comment: 'comment at',
        like_response: '', like_reply: 'comment at'},
      es: {to_do: 'Pendientes', done: 'Listas!', comm_now: 'Comunidad Ahora!', response_on: 'contestó',
        reply_on: 'comentó en', comment_on: 'comentó en', liked: 'le gustó', like_comment: 'comentario en',
        like_response: 'respuesta a', like_reply: 'respuesta en'}
    };

    switch (item.type) {
      case 'response':
        avatar = item.avatar === '' ? '/assets/user.png' : item.avatar;
        iconGender = item.gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.gender}>{setIconGender(item.gender)}</div></div>;
        const regionCountryResponse = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.country) :
          getCountryName(item.country);
        country = (item.country !== '' && item.country) &&
          <div className="px-country-container" data-tooltip-content={regionCountryResponse}><span>{item.country}</span></div>;
        iconCard = item.task_type === 'Open End' ? 'quote-right' : 'square-check';
        username = item.alias_from || item?.user_from?.natural_username || item.username
        response = i18n[language].response_on;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.user_id +'/' + item.activity_id +'/'+  item.task_id +
          '/Now/?flag=true':
          '/activities/' + communityId + '/activity/' + item.activity_id + '/social/response/' + item.id + '/act_user/' +
          item.active_user_id + '/scroll/' + item.scroll_id + '/-1';
        question = item.task_title;
        if(item.task_type === 'Open End'){
          body = item.response_body;
        } else {
          body = item.response_body.map((answer, index) => {
            return(
              <p key={index} style={{marginBottom:'5px'}}>- {answer}</p>
            )
          });
        }
        break;
      case 'comment':
        avatar = item.comment_avatar === '' ? '/assets/user.png' : item.comment_avatar;
        iconCard = 'message';
        iconGender = item.comment_gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.comment_gender}>{setIconGender(item.comment_gender)}</div></div>;
        const regionCountry = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.comment_country) :
          getCountryName(item.comment_country);
        country = (item.comment_country !== '' && item.comment_country) &&
          <div className="px-country-container" data-tooltip-content={regionCountry}>
            <span>{item.comment_country}</span></div>;
        username = item.alias_from || item?.user_from?.natural_username || item.comment_username
        response = i18n[language].comment_on;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.active_user_id +'/' + item.activity_id +'/' +
          item.task_id +'/Now/?flag=true&itemId=' + item.id + '&itemtype=' + item.type :
          '/activities/' + communityId + '/activity/' + item.activity_id + '/social/comment/' + item.id + '/act_user/' +
          item.active_user_id + '/scroll/' + item.scroll_id + '/-1';
        question = item.task_title;
        body = item.comment_body;
        usernameOwner = item.alias_to || item?.user_to?.natural_username || item.response_username
        break;
      case 'reply':
        avatar = item.reply_avatar === '' ? '/assets/user.png' : item.reply_avatar;
        iconCard = 'message';
        iconGender = item.reply_gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.reply_gender}>{setIconGender(item.reply_gender)}</div></div>;
        const regionCountryReply = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.reply_country) :
          getCountryName(item.reply_country);
        country = (item.reply_country !== '' && item.reply_country) &&
          <div className="px-country-container" data-tooltip-content={regionCountryReply}>
            <span>{item.reply_country}</span></div>;
        username = item.alias_from || item?.user_from?.natural_username || item.reply_username
        response = i18n[language].reply_on;
        question = item.task_reply;
        body = item.reply_body;
        usernameOwner = item.alias_to || item?.user_to?.natural_username || item.replied_username
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.active_user_id +'/' + item.activity_id +'/' +
          item.task_id +'/Now/?flag=true&itemId=' + item.id + '&itemtype=' + item.type :
          '/activities/' + communityId + '/activity/' + item.activity_id + '/social/reply/' + item.id
          + '/act_user/' + item.active_user_id + '/scroll/' + item.scroll_id + '/-1';
        break;
      case 'likeResponse':
        avatar = item.like_avatar === '' ? '/assets/user.png' : item.like_avatar;
        iconCard = 'heart';
        iconGender = item.like_gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.like_gender}>{setIconGender(item.like_gender)}</div></div>;
        const regionCountryLikeResponse = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.like_country) : getCountryName(item.like_country);
        country = (item.like_country !== '' && item.like_country) &&
          <div className="px-country-container" data-tooltip-content={regionCountryLikeResponse}>
            <span>{item.like_country}</span></div>;
        username = item.alias_from || item?.user_from?.natural_username || item.like_username
        response = i18n[language].liked;
        typeObj = i18n[language].like_response;
        question = item.task_title;
        usernameOwner = item.alias_to || item?.user_to?.natural_username || item.comment_username;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.active_user_id +'/' + item.activity_id +'/'+  item.task_id +
          '/Now/?flag=true' :
          '/activities/' + communityId + '/activity/' + item.activity_id + '/social/likeResponse/' + item.id + '/act_user/' +
          item.active_user_id + '/scroll/' + item.task_id + '/-1';
        break;
      case 'like':  // Likes to comments
        avatar = item.like_avatar === '' ? '/assets/user.png' : item.like_avatar;
        iconCard = 'heart';
        iconGender = item.like_gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.like_gender}>{setIconGender(item.like_gender)}</div></div>;
        const regionCountryLike = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.like_country) :
          getCountryName(item.like_country);
        country = (item.like_country !== '' && item.like_country) &&
          <div className="px-country-container" data-tooltip-content={regionCountryLike}>
            <span>{item.like_country}</span></div>;
        username = item.alias_from || item?.user_from?.natural_username || item.like_username;
        response = i18n[language].liked;
        typeObj = i18n[language].like_comment;
        question= item.task_title;
        usernameOwner = item.alias_to || item?.user_to?.natural_username || item.comment_username;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.active_user_id +'/' + item.activity_id +'/' +
          item.task_id +'/Now/?flag=true&itemId=' + item.comment_liked_id + '&itemtype=' + item.type :
          '/activities/' + communityId + '/activity/' + item.activity_id + '/social/like/' + item.id + '/act_user/' +
          item.active_user_id + '/scroll/' + item.scroll_id + '/' + item.comment_liked_id;
        break;
      case 'likeReply':
        avatar = item.like_avatar === '' ? '/assets/user.png' : item.like_avatar;
        iconCard = 'heart';
        iconGender = item.like_gender !== '' && <div className="px-genders-container">
          <div className="gender-container" data-tooltip-content={item.like_gender}>{setIconGender(item.like_gender)}</div></div>;
        const regionCountryLikeReply = item.state_country !== "" ?
          item.state_country + ', ' + getCountryName(item.like_country) :
          getCountryName(item.like_country);
        country = (item.like_country !== '' && item.like_country) &&
          <div className="px-country-container" data-tooltip-content={regionCountryLikeReply}><span>{item.like_country}</span></div>;
        username = item.alias_from ||  item?.user_from?.natural_username || item.like_username;
        response = i18n[language].liked;
        typeObj = i18n[language].like_reply;
        question = item.task_title;
        usernameOwner = item.alias_to || item?.user_to?.natural_username || item.comment_username;
        url = profile.role_1 === 'Collaborator' ?
          '/communities/' + communityId +'/transcript/'+ item.active_user_id +'/' + item.activity_id +'/' +
          item.task_id +'/Now/?flag=true&itemId=' + item.comment_liked_id + '&itemtype=' + item.type :
          '/activities/' + communityId + '/activity/' + item.activity_id + '/social/likeReply/' + item.id + '/act_user/' +
          item.active_user_id + '/scroll/' + item.task_id + '/' + item.comment_liked_id;
        break;
    }

    const urlSeeResponse = profile.role_1 === 'Collaborator'
      ? '/communities/'+ communityId +'/transcript/'+ item.user_id +'/' + item.activity_id +'/'+  item.task_id +'/Now' :
      '';

    this.setState({avatar, iconGender, country, iconCard, username, response, url, question, body, urlSeeResponse,
      usernameOwner})
  }
}
