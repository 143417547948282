import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Switch from "rc-switch";
import { Tooltip as ReactTooltip } from "react-tooltip"
import ReactModal from "react-modal";
import moment from "moment";

import ImageModal from "../MyProfile/ImageModal";
import AE_M_Image from "../Community/Activities/ActivityEditor/AE_Modal/AE_M_Image";
import CSMCloseCMTY from "../Community/Settings/CSGeneral/CSMCloseCMTY";
import CSMAllowance from "../Community/Settings/CSGeneral/CSMAllowance";

export default class CommunityCard extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    updateCropBlob: PropTypes.func.isRequired,
    updateActiveCommunityId: PropTypes.func.isRequired,
    onDropImage: PropTypes.func.isRequired,
    handleFavourite: PropTypes.func.isRequired,
    isCollaborator: PropTypes.bool.isRequired,
    counterActiveComm: PropTypes.number.isRequired,
    counterClosedComm: PropTypes.number.isRequired,
    papt: PropTypes.number.isRequired,
    maxPapt: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      cardId: 'myReveal_' + props.index,
      isToggle: true,
      flagTitle: true,
      validationTitle: '',
      isModalClose: false,
      switchOpenClose: false,
      isModal: false,
      showModal: false,
      showAllowanceModal: false
    }
  }

  render() {
    const {community, language, colors} = this.props;

    const i18n = {
      en: {
        participants: 'Participants', social: 'Social community'
      },
      es: {
        participants: 'Participantes', social: 'Comunidad social'
      }
    };

    let avatarOrig =  community.avatar !== ''  ? community.avatar : '/assets/community.png';
    const avatar = community.newAvatarPreview ? community.newAvatarPreview : avatarOrig;

    if(community.newAvatarBlob){
      const reader  = new FileReader();
      const preview = $('#divAvatar' + community.id);

      reader.onloadend = function () {
        const urlBlob = 'url(' + reader.result + ')';
        preview.css('background-image', urlBlob);
        preview.src = reader.result;
      };

      reader.readAsDataURL(community.newAvatarBlob);
    }

    const communityUrl = community.cu_is_welcome ? community.project_type !== 'AIChat' ? '/communities/' + community.id + '/ActAndPar' :
        '/community/' + community.id + '/ai/chat' : '/users/welcome/'+ community.id +'/Participant';
    const settingsCommunityUrl = '/communities/settings/'+ community.id + '/General';
    const dateA = (new Date(community.created_at).toDateString().substring(4)).split(' ');
    const createdFormat = dateA[0] + ' ' + dateA[1] + ', ' + dateA[2];
    const starIcon = community.role_2 === 'Owner' ?
      <FontAwesomeIcon onClick={() => this.props.handleFavourite(community.id)} className="px-icon-card-community px-fav"
        icon={[community.favourite ? 'fas' : 'fal', 'star']} style={{ cursor: 'pointer' }}
                       data-tooltip-content={[community.favourite ? '' : 'Mark as favourite']}/> :
      <FontAwesomeIcon className="px-icon-card-community px-fav" icon={[community.favourite ? 'fas' : 'fal',  'star']}/>;

    let quickActions = null;
    if(community.role_2 === 'Owner'){
      quickActions = <div className="overlay-card-menu" id="example-navbar-icons" ref="navBarQuickSettings">
        <a href={communityUrl} className="btn px-btn circle xl btn-white inverted mg-top-20">
          <FontAwesomeIcon icon={['fas',  'arrow-right']}/>
        </a>
        <div className="btn-group-overlay mg-top-15">
          <a onClick={(e) => this.props.updateShowModal(e, community.id)}
             className="btn px-btn circle lg btn-white inverted">
            <FontAwesomeIcon icon={['fas', 'image']}/>
          </a>
          {
            community.participants_end >= moment().format() &&
            <a onClick={(e) => this.onChangeSwitch(e)}
               className="btn px-btn circle lg btn-white inverted">
              <FontAwesomeIcon icon={['fas', community.state !== 'Closed' ? 'lock' : 'unlock']}/>
            </a>
          }
          <a href={settingsCommunityUrl} className="btn px-btn circle lg btn-white inverted">
            <FontAwesomeIcon icon={['fas', 'gear']}/>
          </a>
        </div>
      </div>;
    }

    let modalCommunityImage;
    if(this.state.isModal){
      modalCommunityImage =
        <ImageModal objectId={this.props.community.id}
                    url={avatar}
                    handleToggle={() => {}}
                    updateAvatar={() => {}}
                    action="/communities/update_avatar/"
                    language={'en'}
                    colors={colors}/>;
    }

    let modalClose;
    if(this.state.isModalClose) {
      modalClose = <CSMCloseCMTY communityId={community.id}
                                 title={community.title}
                                 state={community.state}
                                 updateShowModalClose={this.toggleModalClose}
                                 closeCommunity={this.closeCommunity}
                                 language={this.props.language}
                                 colors={colors}/>
    }

    let iconUsersList = community.state === 'Enabled' ?
      <a href={'/users_list/' + community.id + '/Participant/'} style={{ textDecoration: 'none' }}
         data-tooltip-content={i18n[language].participants}
         data-tooltip-id="my-tooltip"
      >
        <FontAwesomeIcon icon={['fas', 'users']}  className="px-icon-card-community"/>
        <span className="px-total-participants">{community.num_participants}</span>
      </a> :
      <div>
        <FontAwesomeIcon icon={['fas', 'users']}  className="px-icon-card-community"/>
        <span className="px-total-participants">{community.num_participants}</span>
      </div>;

    if(community.cu_is_welcome){
      if(community.social_flag){

      } else {
        /*if(community.show_part_list){

        } else {
          iconUsersList = null
        }*/
      }
    } else {
      iconUsersList = null
    }

    return (
      <div className="card px-card-community mg-bottom-15 px-card">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" multiline={true} place="top" />
        { modalClose }
        { modalCommunityImage }
        <div className="card-height-indicator"/>
        <div className="card-content" onClick={(e) => this._forwardUrl(e, communityUrl)}
             style={{cursor:'pointer'}}>
            <span style={{height: '40px', width: '40px', backgroundColor: '#ffffff', position: 'absolute', top: '20px', right: '20px', zIndex: '2', borderRadius: '50%', padding: '9px 8px', boxShadow: 'rgb(0 0 0) 2px 2px 20px 1px', textAlign: 'center'}}>
              { community.project_type === 'AIChat' ?
                <FontAwesomeIcon icon={['fas', 'scribble']} style={{ fontSize: '20px', color: '#45505D' }} className="px-icon-right"/> : <FontAwesomeIcon icon={['fas', 'rainbow']} style={{ fontSize: '20px', color: '#45505D' }} className="px-icon-right"/>
              }
            </span>
          <div id={'divAvatar' + community.id} className="card-image px-card-img"
               style={{backgroundImage: `url(${avatarOrig})`}}>
            { quickActions }
            {
              (community.state === 'Closed' || community.state === 'Archived') &&
              <span className="px-closed-warning">{community.state}</span>
            }
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <label className="px-card-category" style={{ color: colors.color4[3] }}> {community.category}</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {
                  (community.state === 'Enabled' || community.role_1 === 'Collaborator') ?
                    community.state === 'Closed' && community.role_2 === 'Recruiter' ?
                      <div className="px-card-title">{community.title}</div> :
                      <a className="title-container" data-tooltip-content={community.title} href={communityUrl}>
                        <div className="px-card-title">{community.title}</div>
                        {/*<FontAwesomeIcon icon={['fas', 'pencil']} className="px-icon-edit"/>*/}
                      </a>
                    :
                    <div className="px-card-title">{community.title}</div>
                }

                <div className="px-input-icon px-icon-edit" style={{display:'none'}}>
                  <input type="text" name="searchTerm" className="form-control icon-right" placeholder="Search"/>
                  <FontAwesomeIcon icon={['far', 'check']} className="px-icon-right color-blue-base"/>
                </div>
              </div>
            </div>
            <hr className="mg-bottom-10 mg-top-20"/>
            <div className="row">
              <div className="col-md-12">
                <p style={{marginBottom: '0'}} className="px-card-date">{createdFormat}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                { community.project_type !== 'AIChat' &&
                  <div className="detailsRow">
                  <div className="iconsLeft" style={{zIndex: '1000'}}>
                    {(community.social_flag || community.role_1 === 'Collaborator') && iconUsersList}
                    {
                        community.social_flag &&
                        <span className="px-social-flag" data-tooltip-content={i18n[language].social}>S</span>
                    }

                  </div>
                  <div className="iconsRight">
                    {/* community.role_2 === 'Owner' && starIcon */}
                  </div>
                </div> }
              </div>
            </div>
          </div>

          <div id={this.state.cardId} className="revealnav card">
            <div className="card-content">
              <div className="card-body">
                <button onClick={this.toggleRevealMenu} type="button" className="close"
                        style={{position: 'absolute', right: '10px', top: '10px', zIndex: '1'}}>
                  <i ref="iconClose" className="material-icons" style={{color: '#333'}}>clear</i>
                </button>

                <div className="col-md-12">
                  <div className="form-group label-floating">
                    <label className="control-label">Community title</label>
                    <input onBlur={this._validateTitle} ref="txtCommTitle" type="text" style={{paddingRight:'30px'}}
                           defaultValue={community.title} className="form-control" />
                    <button onClick={this.updateCommunity} className="btn btn-success btn-simple btn-xs"
                            style={{position: 'absolute', top: '-5px', right: '-10px'}}>
                      <i className="material-icons">done</i></button>
                    <span style={{color: '#f44336', fontSize: '11px'}}>{this.state.validationTitle}</span>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group" style={{margin: '0'}}>
                    <img ref="imgPreviewReveal" onClick={this.handleToggle} src={avatar} alt=""
                         className="img-rounded img-responsive" style={{width: '60px', cursor: 'pointer'}}/>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group" style={{margin: '0'}}>
                    <label>&nbsp;</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group" style={{marginTop: '10px'}}>
                    <label>Community status</label>
                    <div className="togglebutton">
                      <label>Closed&nbsp;&nbsp;
                        <Switch onChange={this.onChangeSwitch}
                                defaultChecked={this.state.stateFlag}
                                checked={this.state.stateFlag}/>&nbsp;&nbsp;Open
                      </label>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          community.showModal &&
          <ReactModal isOpen={community.showModal} contentLabel="Community Card"
                      shouldCloseOnOverlayClick={true}
                      onRequestClose={(e) => this.props.updateShowModal(e, community.id)}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <div className="modal-dialog" >
              <AE_M_Image updateShowModal={(e) => this.props.updateShowModal(e, community.id)}
                          language={'en'}
                          title={'Community avatar'}
                          updateCropBlob={this.props.updateCropBlob}
                          imageUrl={avatar}
                          onDropImage={this.props.onDropImage}
                          colors={colors}/>
            </div>
          </ReactModal>
        }
        {
          this.state.showAllowanceModal &&
          <ReactModal isOpen={this.state.showAllowanceModal} contentLabel="Community Card"
                      shouldCloseOnOverlayClick={true}
                      onRequestClose={this.updateShowAllowanceModal}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <CSMAllowance title={community.state === 'Enabled' ? 'You can\'t close' : 'You can\'t open'}
                          state={community.state}
                          updateShowModalClose={this.updateShowAllowanceModal}
                          language={this.props.language}
                          colors={colors}/>
          </ReactModal>
        }
      </div>
    );
  }

  updateShowAllowanceModal = () => {
    this.setState(state => ({showAllowanceModal: !state.showAllowanceModal}));
  };

  _forwardUrl = (e, communityUrl) => {
    e.stopPropagation();
    const {community} = this.props;
    //console.log('Forward to Community Page');
    if(community.state === 'Closed' && (community.role_2 === 'Recruiter' || community.role_1 === 'Participant')){
      //console.log('Do nothing');
    } else {
      window.location.href = communityUrl;
    }
  };

  closeCommunity = communityId => {
    $.ajax({
      url: '/communities/close/' + communityId,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
        this.toggleModalClose();
        window.location.href = '/users/dashboard';
      }
    });
  };

  updateShowModal = () => {
    this.props.updateActiveCommunityId(this.props.community.id);
    this.setState(prevState => ({showModal: !prevState.showModal, newAvatarPreview: null, newAvatarFile: null}));
  };

  toggleModalClose = () => {
    this.setState({isModalClose: !this.state.isModalClose});
  };

  onChangeSwitch = (e, value) => {
    e.stopPropagation();
    console.log(value);
    const {community, counterClosedComm, counterActiveComm, papt, maxPapt} = this.props;

    const nextPapt = community.num_all_participants + papt;
    console.log(nextPapt);

    if(community.state === 'Enabled'){
      if(counterClosedComm < 2){
        this.setState({
          switchOpenClose: !value,
          isModalClose: !this.state.isModalClose
        });
      } else {
        this.updateShowAllowanceModal();
      }
    } else if(community.state === 'Closed'){
      if(counterActiveComm < 3 && nextPapt <= maxPapt){
        this.setState({
          switchOpenClose: !value,
          isModalClose: !this.state.isModalClose
        });
      } else {
        this.updateShowAllowanceModal();
      }
    }
  };

  updateCommunity = () => {
    const title = this.refs.txtCommTitle.value;

    if(title !== '' && this.state.flagTitle){
      $.ajax({
        url: '/communities/update/' + this.props.community.id,
        method: 'PATCH',
        dataType: 'JSON',
        data: { community: { title: title } },
        success: function (community) {
          this.setState({title: community.title});
          $('#snackbar').addClass('show');
          this.toggleRevealMenu();
          setTimeout(function(){ $('#snackbar').removeClass('show'); }, 2000);
        }.bind(this)
      });
    } else {

    }
  };

  _validateTitle = () => {
    const title = this.refs.txtCommTitle.value;

    if(title !== ''){
      $.ajax({
        url: '/communities/title_validation',
        method: 'GET',
        dataType: 'JSON',
        data: { title: title },
        success: function (obj) {
          if(obj.msg){
            this.setState({flagTitle: false});
            this.setState({validationTitle: '* Title taken'});
          } else {
            this.setState({flagTitle: true});
            this.setState({validationTitle: ''});
          }
        }.bind(this)
      });
    } else {
      this.setState({flagTitle: false});
      $('#divModalAddCommunityTitle').removeClass('has-success'); // it doesn't appear
    }
  };

  toggleRevealMenu = () => {
    if(this.state.isToggle){
      document.getElementById(this.state.cardId).style.height = "100%";
      this.refs.navBarQuickSettings.style.zIndex = '0';
      this.refs.iconClose.style.display = 'block';
    } else {
      document.getElementById(this.state.cardId).style.height = "0";
      this.refs.navBarQuickSettings.style.zIndex = '1';
      this.refs.iconClose.style.display = 'none';
    }
    this.setState({isToggle: !this.state.isToggle});
  };
}
