import PropTypes from 'prop-types';
import React from 'react';
import * as d3 from "d3";
import v4 from "uuid";

export default class TRCBarChart extends React.Component {
  static propTypes = {
    maps: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
    usersId: PropTypes.array.isRequired,
    answersLength: PropTypes.number.isRequired,
    isVirtualized: PropTypes.bool.isRequired,
    responseId: PropTypes.number,
    taskAnswers: PropTypes.array,
    userAnswer: PropTypes.array,
    userAvatar: PropTypes.string,
    taskId: PropTypes.number,  // just for test in the life cycle of this component
    index: PropTypes.number,
    doRecomputeRowHeights: PropTypes.func,
    updateSVG: PropTypes.func,
    svg: PropTypes.string,
    colors: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    cmntyLanguage: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      id: v4(),
      uid: v4(),
      svgId: v4(),
      svg: null,
      isMobile: false
    }
  }

  componentDidMount(){
    const {taskType, maps, userAnswer, taskAnswers, userAvatar, taskId, svg} = this.props;
    //console.log('TRCBarChart.componentDidMount', taskId);
    //console.log(taskType, maps, userAnswer);
    //TO-DO try to move _prepareData to TResponse
    /*if(svg){
      this.setState({svg: <div style={{display: 'block', margin: 'auto'}} dangerouslySetInnerHTML={{ __html: svg }} />});
    } else {*/
      const barChartData = taskType === 'Open End' ? this._prepareData(maps) :
        this._prepareDataChoice(taskAnswers, maps, userAnswer, userAvatar);
      //console.log(barChartData);
      if(barChartData.length !== 0){
        this._drawBarChart(barChartData);
      }
    //}
    this.setState({isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log('TRCBarChart.componentDidUpdate');
  }

  render() {
    const {maps, usersId, answersLength, language} = this.props;
    const i18n = { en: { missing: 'missing' }, es: { missing: 'faltantes'}}

    return (
      <React.Fragment>
        <div id={this.state.id} style={{width: '100%', height: '320px', display: this.state.isMobile ? 'block' : 'flex',
          justifyContent:'center', alignItems:'center', overflowY: this.state.isMobile ? 'auto' : 'unset'}}>
          <svg id={this.state.uid} ref="d3BarChart" style={{width: '600px', height: '100%'}}/>
          {/* this.state.svg */}
        </div>
        <p style={{fontSize:'10px',margin:'10px 0 0 0',color:'#7c8593'}}>
          n = {usersId.length} ({usersId.length - answersLength} {i18n[language].missing})
        </p>
      </React.Fragment>
    );
  }

  _prepareDataChoice(taskAnswers, allAnswers, userAnswers, userAvatar){
    //console.log(taskAnswers, allAnswers, userAnswers);
    let choices = taskAnswers.map(answer => ({id: answer.id, name: answer.text, weight: 0}));

    choices.forEach(item => {
      let isFound = null;
      if(userAnswers !== undefined){
        isFound = userAnswers.find(ua => ua.id === item.id) !== undefined;
      }

      allAnswers.forEach(answer => {
        if(item.id === answer.id){
          choices = choices.map(choice => {
            if(choice.id === item.id){
              return {...choice, weight: ++choice.weight, image: isFound ?
                  userAvatar !== '' ? userAvatar : '/assets/user.png' : null};
            } else {
              return choice;
            }
          });
        }
      });
    });
    //console.log(items);
    return choices;
  }

  _prepareData(mapItems){
    //console.log(mapItems);
    //TO-DO try to move 'counts' to parent in order no data then no render the component
    //Or better, check out the required fields to the Task, if not Maps then no render this components
    let counts = [];
    const bars = [];

    if(mapItems.length !== 0){
      counts = mapItems.reduce((prev, nxt) => {
        prev[nxt] = (prev[nxt] + 1) || 1;
        return prev;
      }, {});
      //if(mapItems.join().match(/\w+/g)){
        /*mapItems.join().match(/\w+/g).forEach(w => {
          console.log(w);
          counts[w] = (counts[w] || 0) + 1;
        });*/
      //}
    }
    //console.log('mapItems:', mapItems, 'counts:', counts);

    for(let [key, value] of Object.entries(counts)) {
      bars.push({name: key, weight: value})
    }
    const sorted = bars.sort((a, b) => (a.weight < b.weight ? 1 : -1));
    //console.log(bars);

    return sorted.map(item => {
      const foundItem = this.props.type === 'single' ? this.props.userAnswer.find(map => map === item.name) : false;

      return {...item, image: foundItem ? this.props.userAvatar != '' ? this.props.userAvatar: '/assets/user.png' : null}
    });
  }

  _drawBarChart(data){
    //console.log('data:', data);
    // WHY DO I NEED HERE (WORD CLOUD ONLY) a SETSTATE???
    //this.setState({loading: false}, () => {
      //const svg = d3.select($('#' + this.state.uid));
      const colors = this.props.colors;
      const svg = d3.select(this.refs.d3BarChart);
      const margin = 40;
      const width = 520;
      const height = 200;

      const chart = svg.append('g')
        .attr('class', this.state.svgId)
        .attr('transform', `translate(${margin}, ${margin})`);

      const xScale = d3.scaleBand()
        .range([0, width])
        .domain(data.map(d => d.name))
        .padding(0.4);

      const yScale = d3.scaleLinear()
        .range([height, 0])
        .domain([0, (data[0].weight + 2)]);

      /*const makeYLines = () => d3.axisLeft()
        .scale(yScale);*/

      const wrap = function() {
        let self = d3.select(this),
          textLength = self.node().getComputedTextLength(),
          text = self.text();
        while (textLength > (50) && text.length > 0) {
          text = text.slice(0, -1);
          self.text(text + '...');
          textLength = self.node().getComputedTextLength();
        }
      };

    const tooltip = d3.select("body")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .style("background", "#fff")
      .text("a simple tooltip");

      chart.append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(xScale))
        .selectAll("text").style("text-anchor", "begin")
        .attr("dx", "3.2em")
        .attr("dy", ".30em")
        .attr("transform", function(d) {
          return "rotate(65)"
        })
        .each(wrap);

      chart.append('g')
        .call(d3.axisLeft(yScale)
          .ticks(data[0].weight + 2));

      /*chart.append('g')
        .attr('class', 'grid')
        .call(makeYLines()
          .tickSize(-width, 0, 0)
          .tickFormat('')
        );*/

      const barGroups = chart.selectAll()
        .data(data)
        .enter()
        .append('g');

      barGroups
        .append('rect')
        .attr('class', 'bar')
        .attr('style', 'fill:' + colors.color0[3])
        .attr('x', (g) => xScale(g.name))
        .attr('y', (g) => yScale(g.weight))
        .attr('height', (g) => height - yScale(g.weight))
        .attr('width', xScale.bandwidth())
        .on("mouseover", function(d){tooltip.text(d.name); return tooltip.style("visibility", "visible");})
        .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+10)+"px");})
        .on("mouseout", function(){return tooltip.style("visibility", "hidden");});

      /*barGroups
        .append('text')
        .attr('class', 'value')
        .attr('x', (a) => xScale(a.name) + xScale.bandwidth() / 2)
        .attr('y', (a) => yScale(a.weight) + 30)
        .attr('text-anchor', 'middle')
        .text((a) => a.weight);*/

      if(this.props.type === 'single'){
        const imgHW = xScale.bandwidth() > 24 ? 24 : xScale.bandwidth();

        const tooltipModal = d3.select(".modal-content")
          .append("div")
          .style("position", "relative")
          .style("z-index", "1050")
          .style("visibility", "hidden")
          .style("background", "#fff")
          .style("width", '100px')
          .text("a simple tooltip");

        barGroups
          .append('svg:image')
          .attr('xlink:href', d => d.image ? d.image : null)
          .attr('width', imgHW)
          .attr('height', imgHW)
          .attr('x', (a) => (xScale(a.name) + xScale.bandwidth() / 2) - 10)
          .attr('y', (a) => yScale(a.weight) + 5)
          .on("mouseover", function(d){tooltipModal.text(d.name); return tooltipModal.style("visibility", "visible");})
          .on("mousemove", function(d){
            const parentG = d3.select(this.parentNode);
            const rectX = parentG._groups[0][0].childNodes[0].getAttribute('x');
            const rectY = parentG._groups[0][0].childNodes[0].getAttribute('y');
            //console.log(parseInt(rectX) + 200, rectY);
            return tooltipModal.style("top", (rectY - 300) + "px").style("left", (parseInt(rectX) + 150) + "px")
          })
          .on("mouseout", function(){return tooltipModal.style("visibility", "hidden");});
      }

      //console.log('BarChart Done');
      if(this.props.isVirtualized){
        this.props.updateSVG(document.getElementById(this.state.id).innerHTML, 'barChart', this.props.responseId);
        //this.props.doRecomputeRowHeights(this.props.index);
      }
      //this.setState({loading: false});
    //});
  }
}
