import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";

export default class DMCreateFilter extends React.Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    createCustomFilter: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      name: ''
    }
  }

  handleChange = event => {
    this.setState({name: event.target.value});
  };

  render() {
    const { showModal, language, colors } = this.props;
    const I18n = {en:{saveFilter: 'Save as custom filter', filterNamePlaceholder: 'Name your filter', save: 'Save', cancel: 'Cancel'},
                  es: { saveFilter: 'Guardar filtro', filterNamePlaceholder: 'Nombra tu filtro', save: 'Guardar', cancel: 'Cancelar'}
                }

    return (
      <ReactModal isOpen={showModal} contentLabel="New Custom Filter"
                  shouldCloseOnOverlayClick={true} onRequestClose={this.props.updateShowModal}
                  className="px-modal-content" overlayClassName="px-modal-overlay">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                <i onClick={this.props.updateShowModal} className="material-icons">clear</i>
              </button>
            </div>

            <div className="modal-body px-modal-body-warning">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="px-title-warning-container">
                    <p className="px-title-warning">{I18n[language].saveFilter}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1">
                  <div className="form-group" style={{marginTop:'0'}}>
                    <input type="text" onChange={this.handleChange} value={this.state.name}
                      className="form-control" placeholder={I18n[language].filterNamePlaceholder}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer px-modal-footer-warning">
              <div className="px-footer-container-buttons column">
                <button onClick={() => this.props.createCustomFilter(this.state.name)} type="button"
                        className="btn px-btn sm btn-blue-base" disabled={this.state.name === ''}
                        style={{backgroundColor: colors.color1[3]}}>{I18n[language].save}</button>
                <button onClick={this.props.updateShowModal} type="button"
                  className="btn px-btn sm btn-gray-darker inverted">{I18n[language].cancel}</button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}
