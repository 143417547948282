import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import spanishStrings from "react-timeago/lib/language-strings/es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Notification from "./Notification";
import {notifications_i18n} from "../UI/Globals/PX_i18n";

export default class NotificationList extends React.Component {
  static propTypes = {
    notifications: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
    deleteNotification: PropTypes.func.isRequired,
    selectedCommId: PropTypes.number.isRequired,
    sltType: PropTypes.string.isRequired,
    updateRightDrawerType: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    getNotifications: PropTypes.func.isRequired,
    notificationsHasMore: PropTypes.bool.isRequired,
    updateOpenPMFromOutside: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {notifications, language, selectedCommId, sltType, colors, notificationsHasMore} = this.props;

    const formatter = language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);

    let todayNotifications = [];
    let oldNotifications = [];

    const allNotifications = notifications.map((notificationsArr, index) => {
      const notifications = notificationsArr.map((notification, index) => {
        if(selectedCommId === -1) {
          return (
            <Notification key={index}
                          notification={notification}
                          language={language}
                          formatter={formatter}
                          sltType={sltType}
                          updateRightDrawerType={this.props.updateRightDrawerType}
                          updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                          colors={colors}/>
          )
        } else {
          if(selectedCommId === notification.community_id) {
            return (
              <Notification key={index}
                            notification={notification}
                            language={language}
                            formatter={formatter}
                            sltType={sltType}
                            updateRightDrawerType={this.props.updateRightDrawerType}
                            updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                            colors={colors}/>
            )
          }
        }
      });

      const title = index === 0 ? notifications_i18n[language].today : notifications_i18n[language].old;

      return (
        <div key={index}>
          <div className="date-title">
            <span className="date" style={{color: colors.color1[3]}}>{title}</span>
            <FontAwesomeIcon onClick={() => this.props.deleteNotification(-1, 'all', index)}
                             icon={['fas', 'circle-xmark']} className="remove" style={{cursor: 'pointer'}}/>
          </div>
          {
            notifications.length === 0 ?
              <div className="no-notifications text-center">{notifications_i18n[language].no_new_notifications}</div> :
              <div>{notifications}</div>
          }
        </div>
      )
    });

    //console.log('this.props.notifications:', this.props.notifications);

    const notiToday = notifications[0].map((notification, index) => {
      //console.log('obj:', obj);
      return (
        <Notification key={index}
                      notification={notification}
                      language={language}
                      formatter={formatter}
                      sltType={sltType}
                      updateRightDrawerType={this.props.updateRightDrawerType}
                      updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                      colors={colors}/>
      )
    });

    const notiOld = notifications[1].map((notification, index) => {
      //console.log('obj:', obj);
      return (
        <Notification key={index}
                      notification={notification}
                      language={language}
                      formatter={formatter}
                      sltType={sltType}
                      updateRightDrawerType={this.props.updateRightDrawerType}
                      updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                      colors={colors}/>
      )
    });

    return (
      <div className="notifications-items-container">
        {/*<div className="notification-item">
          <div className="date-title">
            <span className="date" style={{color: colors.color1[3]}}>{notifications_i18n[language].today}</span>
            <FontAwesomeIcon onClick={() => this.props.deleteNotification(-1, 'all', -1)}
                             icon={['fas', 'circle-xmark']} className="remove" style={{cursor: 'pointer'}}/>
          </div>
        </div>*/}

        {/*<div className="notification-item" style={{height:'400px', overflowY:'scroll'}}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.props.getNotifications}
            hasMore={this.props.notificationsHasMore}
            loader={<div className="loader" key={0}>Loading ...</div>}
            useWindow={false}>
            { notiToday }
          </InfiniteScroll>
        </div>*/}
      </div>
    )
  }
}