import PropTypes from 'prop-types';
import React from 'react';

import AE_M_Image from "./AE_Modal/AE_M_Image";
import AE_M_Video from "./AE_Modal/AE_M_Video";
import AEMConfirmation from "./AE_Modal/AEMConfirmation";
import AEMSelect from "./AE_Modal/AEMSelect";
import AEMTaskPreview from "./AE_Modal/AEMTaskPreview";
import AEMValidations from "./AE_Modal/AEMValidations";
import AEMActivityPreview from "./AE_Modal/AEMActivityPreview";

export default class AE_Modal extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    onDropImage: PropTypes.func,
    thumbnail: PropTypes.string,
    from: PropTypes.string,
    activeTaskId: PropTypes.number,
    activityName: PropTypes.string,
    activityNameToDelete: PropTypes.string,
    updateConditionsDelActivity: PropTypes.func,
    deleteTask: PropTypes.func,
    questionId: PropTypes.number,
    questionName: PropTypes.string,
    deleteQuestion: PropTypes.func,
    updateCropBlob: PropTypes.func,
    updateVideo: PropTypes.func,
    delActC1: PropTypes.bool,
    delActC2: PropTypes.bool,
    delTaskC1: PropTypes.bool,
    gender_as_segment: PropTypes.bool,
    segments: PropTypes.array,
    task: PropTypes.object,
    updateArrayTask: PropTypes.func,
    updateArrayTaskFilters: PropTypes.func,
    tags: PropTypes.array,
    tasks: PropTypes.array,
    question: PropTypes.object,
    confirmUpdateLive: PropTypes.func,
    copyActC1: PropTypes.bool,
    copyActC2: PropTypes.bool,
    copyActivity: PropTypes.func,
    validations: PropTypes.array,
    changeChoiceTypeLive: PropTypes.func,
    activeUsers: PropTypes.array,
    actDates: PropTypes.object,
    updateStartExpireDatesModal: PropTypes.func,
    searchModal: PropTypes.func,
    communityLang: PropTypes.string,
    data_signature: PropTypes.object,
    updateModalAction: PropTypes.func,
    colors: PropTypes.object.isRequired,
    cmntyLanguage: PropTypes.string,
    cmntyAutoModeration: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {type, colors} = this.props;

    let content = null;
    switch(type){
      case 'image':
        content = <AE_M_Image updateShowModal={this.props.updateShowModal}
                              language={this.props.language}
                              title={this.props.title}
                              imageUrl={this.props.imageUrl}
                              onDropImage={this.props.onDropImage}
                              updateCropBlob={this.props.updateCropBlob}
                              colors={colors}/>;
        break;
      case 'imageAnswer':
        content = <AE_M_Image updateShowModal={this.props.updateShowModal}
                              language={this.props.language}
                              title={this.props.title}
                              imageUrl={this.props.imageUrl}
                              onDropImage={this.props.onDropImage}
                              updateCropBlob={this.props.updateCropBlob}
                              colors={colors}/>;
        break;
      case 'video':
        content = <AE_M_Video updateShowModal={this.props.updateShowModal}
                              language={this.props.language}
                              title={this.props.title}
                              from={'ActivityBuilder'}
                              updateVideo={this.props.updateVideo}
                              questionId={this.props.questionId}
                              //expiration={this.props.expiration}
                              data_signature={this.props.data_signature}
                              colors={colors}/>;
        break;
      case 'delete act':
        content = <AEMConfirmation updateShowModal={this.props.updateShowModal}
                                   title={this.props.title}
                                   from={this.props.from}
                                   activeTaskId={this.props.activeTaskId}
                                   activityName={this.props.activityName}
                                   activityNameToDelete={this.props.activityNameToDelete}
                                   updateConditionsDelActivity={this.props.updateConditionsDelActivity}
                                   deleteTask={this.props.deleteTask}
                                   delActC1={this.props.delActC1}
                                   delActC2={this.props.delActC2}
                                   language={this.props.language}
                                   colors={colors}/>;
        break;
      case 'copy act':
        content = <AEMConfirmation updateShowModal={this.props.updateShowModal}
                                   title={this.props.title}
                                   from={this.props.from}
                                   activityName={this.props.activityName}
                                   updateConditionsDelActivity={this.props.updateConditionsDelActivity}
                                   copyActC1={this.props.copyActC1}
                                   copyActC2={this.props.copyActC2}
                                   copyActivity={this.props.copyActivity}
                                   language={this.props.language}
                                   colors={colors}/>;
        break;
      case 'delete task':
        content = <AEMConfirmation updateShowModal={this.props.updateShowModal}
                                   title={this.props.title}
                                   from={this.props.from}
                                   questionId={this.props.questionId}
                                   questionName={this.props.questionName}
                                   updateConditionsDelActivity={this.props.updateConditionsDelActivity}
                                   deleteQuestion={this.props.deleteQuestion}
                                   delTaskC1={this.props.delTaskC1}
                                   language={this.props.language}
                                   colors={colors}/>;
        break;
      case 'segments':
        content = <AEMSelect updateShowModal={this.props.updateShowModal}
                             language={this.props.language}
                             title={this.props.title}
                             gender_as_segment={this.props.gender_as_segment}
                             segments={this.props.segments}
                             task={this.props.task}
                             updateArrayTask={this.props.updateArrayTask}
                             updateArrayTaskFilters={this.props.updateArrayTaskFilters}
                             activeUsers={this.props.activeUsers}
                             searchModal={this.props.searchModal}
                             updateModalAction={this.props.updateModalAction}
                             colors={colors}/>;
        break;
      case 'tag':
        content = <AEMSelect updateShowModal={this.props.updateShowModal}
                             title={this.props.title}
                             language={this.props.language}
                             tags={this.props.tags}
                             task={this.props.task}
                             tasks={this.props.tasks}
                             updateArrayTask={this.props.updateArrayTask}
                             updateArrayTaskFilters={this.props.updateArrayTaskFilters}
                             searchModal={this.props.searchModal}
                             updateModalAction={this.props.updateModalAction}
                             colors={colors}/>;
        break;
      case 'required':
        content = <AEMSelect updateShowModal={this.props.updateShowModal}
                             title={this.props.title}
                             language={this.props.language}
                             task={this.props.task}
                             tasks={this.props.tasks}
                             updateArrayTask={this.props.updateArrayTask}
                             updateArrayTaskFilters={this.props.updateArrayTaskFilters}
                             actDates={this.props.actDates}
                             handleChange={this.props.handleChange}
                             updateStartExpireDatesModal={this.props.updateStartExpireDatesModal}
                             searchModal={this.props.searchModal}
                             updateModalAction={this.props.updateModalAction}
                             colors={colors}/>;
        break;
      case 'preview task OE':
      case 'preview task CH':
      case 'preview task MCH':
        content = <AEMTaskPreview updateShowModal={this.props.updateShowModal}
                                  language={this.props.language}
                                  task={this.props.task}
                                  question={this.props.question}
                                  type={this.props.type}
                                  colors={colors}
                                  communityLang={this.props.communityLang}
                                  cmntyAutoModeration={this.props.cmntyAutoModeration}/>

        break;
      case 'preview Activity':
        content = <AEMActivityPreview updateShowModal={this.props.updateShowModal}
                                      language={this.props.language}
                                      task={this.props.task}
                                      colors={colors}
                                      communityLang={this.props.communityLang}
                                      cmntyAutoModeration={this.props.cmntyAutoModeration}/>

        break;
      case 'live validation':
        content = <AEMValidations updateShowModal={this.props.updateShowModal}
                                  language={this.props.language}
                                  title={this.props.title}
                                  task={this.props.task}
                                  colors={colors}/>;
        break;
      case 'live on':
        content = <AEMValidations updateShowModal={this.props.updateShowModal}
                                  language={this.props.language}
                                  title={this.props.title}
                                  task={this.props.task}
                                  confirmUpdateLive={this.props.confirmUpdateLive}
                                  tasks={this.props.tasks}
                                  colors={colors}/>;
        break;
      case 'task inst validation':
        content = <AEMValidations updateShowModal={this.props.updateShowModal}
                                  language={this.props.language}
                                  title={this.props.title}
                                  task={this.props.task}
                                  colors={colors}/>;
                                  break;
      case 'tasks validations':
        content = <AEMValidations updateShowModal={this.props.updateShowModal}
                                  language={this.props.language}
                                  title={this.props.title}
                                  task={this.props.task}
                                  validations={this.props.validations}
                                  colors={colors}/>;
                                  break;
      case 'choice live':
        content = <AEMValidations updateShowModal={this.props.updateShowModal}
                                  language={this.props.language}
                                  title={this.props.title}
                                  task={this.props.task}
                                  validations={this.props.validations}
                                  changeChoiceTypeLive={this.props.changeChoiceTypeLive}
                                  questionId={this.props.questionId}
                                  colors={colors}/>;
        break;
    }

    return (
      <div className="modal-dialog" style={{width:(type === 'preview task OE' || type === 'preview task CH'
          || type === 'preview Activity') ?
          '85%' : ''}}>
        { content }
      </div>
    );
  }
}
