import PropTypes from 'prop-types';
import React from 'react';
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import spanishStrings from "react-timeago/lib/language-strings/es";
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteScroll from 'react-infinite-scroller';
import Masonry from 'react-masonry-component';

import DFilters from "./DFilters";
import DNCard from "./Now/DNCard";
import Loading from "../../UI/Globals/Loading";

export default class DNow extends React.Component {
  static propTypes = {
    activeLink: PropTypes.string.isRequired,
    nowItems: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
    profile: PropTypes.object.isRequired,
    hasMoreItems: PropTypes.bool.isRequired,
    _getTasksNow: PropTypes.func.isRequired,
    objSearch: PropTypes.object.isRequired,
    nowFilter: PropTypes.string.isRequired,
    updateNowFilter: PropTypes.func.isRequired,
    updateFilterIsMin: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    updateArrayRdoBtnModal: PropTypes.func.isRequired,
    isLoadingNow: PropTypes.bool.isRequired,
    communityId: PropTypes.number.isRequired,
    viewProfile: PropTypes.func.isRequired,
    user_account: PropTypes.object.isRequired,
    executeUpdateModal: PropTypes.func.isRequired,
    updateUserCustomFilters: PropTypes.func.isRequired,
    updateCustomFilter: PropTypes.func.isRequired,
    userFilters: PropTypes.array.isRequired,
    handleChangeSltFilter: PropTypes.func.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    clearForwardUrl: PropTypes.func.isRequired,
    updateOpenPMFromOutside: PropTypes.func.isRequired,
    userActions: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {activeLink, nowItems, language, profile, hasMoreItems, objSearch, nowFilter, isLoadingNow,
      communityId, user_account, userFilters} = this.props;

    const i18n = {
      en: { dataNA: 'No activity in the community yet'},
      es: { dataNA: 'No hay actividad aún en la comunidad'}
    }

    const formatter = language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);

    // TO-DO try to test with v4() in order to see if it helps to improve because the cards will use Masonry (movement)
    const cards = nowItems.map((item, index) => {
      return(
        <DNCard key={index}
                item={item}
                language={language}
                profile={profile}
                formatter={formatter}
                communityId={communityId}
                viewProfile={this.props.viewProfile}
                user_account={user_account}
                userActions={this.props.userActions}
                clearForwardUrl={this.props.clearForwardUrl}
                updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}/>
      )
    });

    return (
      <div className="px-collaborator-dash-container" style={{display: activeLink === 'Now' ? 'block' : 'none'}}>
        <DFilters activeLink={activeLink}
                  nowFilter={nowFilter}
                  objSearch={objSearch}
                  from={'Now'}
                  updateNowFilter={this.props.updateNowFilter}
                  updateFilterIsMin={this.props.updateFilterIsMin}
                  updateShowModal={this.props.updateShowModal}
                  updateArrayRdoBtnModal={this.props.updateArrayRdoBtnModal}
                  executeUpdateModal={this.props.executeUpdateModal}
                  updateSearchTerm={() => {}}
                  searchTask={() => {}}
                  updateCustomFilter={this.props.updateCustomFilter}
                  userFilters={userFilters.filter(uf => uf.from === 'General')}
                  handleChangeSltFilter={this.props.handleChangeSltFilter}
                  updateUserCustomFilters={this.props.updateUserCustomFilters}
                  language={language}
                  colors={user_account.all_colors}/>
        {
          isLoadingNow ?
          <Loading/> :
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {
                  this.props.nowItems.length !== 0 ?
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={this.props._getTasksNow}
                      hasMore={hasMoreItems}
                      loader={<Loading key={0}/>}>
                      <Masonry>{cards}</Masonry>
                    </InfiniteScroll>
                    : <div className="px-empty-msg" style={{color: user_account.all_colors.color4[3]}}>{i18n[language].dataNA}</div>
                }
              </div>
            </div>
        }
      </div>
    );
  }
}
