import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class ModalUserListCard extends React.Component {
  static propTypes = {
    updateShowMobileModal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    setFollow: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    role_1: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    getPM: PropTypes.func,
    updateShowModalProfile: PropTypes.func,
    isSavingFollow: PropTypes.bool,
    userActions: PropTypes.func,
    communityId: PropTypes.number,
    modalShowPM: PropTypes.func,
    colors: PropTypes.object.isRequired,
    canSendEmail: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {user, role_1, role_2, language, isSavingFollow, colors} = this.props;

    const i18n = {
      en: {
        follow: 'Follow', unfollow: 'Unfollow', profile: 'Profile', lastResponse: 'Last Response', edit: 'Edit',
        remove: 'Remove', block: 'Block', unblock: 'Unblock', send: 'Invite', resend: 'Resend Invitation',
        pm: 'Private Message', sendMail: 'Send email', seeResponses: 'See responses'
      },
      es: {
        follow: 'Follow', unfollow: 'Unfollow', profile: 'Perfil', lastResponse: 'Last Respuesta', edit: 'Editar',
        remove: 'Remover', block: 'Bloquear', unblock: 'Desbloquear', send: 'Invitar',
        resend: 'Reinvitar', pm: 'Mensaje Privado', sendMail: 'Enviar email', seeResponses: 'Ver respuestas'
      }
    };


    const seeResponses = () => {
      if (role_1 !== 'Collaborator') {
        return
      }

      const {communityId, user} = this.props;
      window.location.href = '/communities/'+ communityId +'/transcript/'+ user.user_id +'/all/all/ActAndParCol';
    };

    const avatar = user.avatar !== '' ? user.avatar :'/assets/user.png';

    const btnStyle = { color: colors.color1[3], border: `1px solid ${colors.color1[3]}`, backgroundColor: '#ffffff' }

    const btnFollow = user.user_id !== user.current_user_id &&
      <button onClick={() => this.props.setFollow(user)} disabled={isSavingFollow}
              className='btn px-btn sm inverted'
              style={user.is_follow ? btnStyle : {color: '#7C8593', border: '1px solid #7C8593', backgroundColor: '#ffffff'}}>
        {!isSavingFollow ? user.is_follow ? i18n[language].unfollow : i18n[language].follow :
          <FontAwesomeIcon icon={['fas','spinner']} spin/>}
      </button>;
    const btnProfile = <button onClick={() => this.props.updateShowModalProfile(user.user_id)}
                               className="btn px-btn sm inverted"
                       style={btnStyle}>{i18n[language].profile}</button>;
    const btnLastResponse = <a onClick={() => this.props.userActions('last response', user)}
                               className="btn px-btn sm inverted"
                               style={btnStyle}>
      {i18n[language].lastResponse}
    </a>;
    const btnEdit = <button onClick={() => this.props.handleOpenModal('edit', user)} data-tooltip-content="Edit"
                            className="btn px-btn sm inverted"
                            style={btnStyle}>{i18n[language].edit}</button>;
    const btRemove = <button onClick={() => this.props.handleOpenModal('remove', user)} data-tooltip-content="Remove"
                             className="btn px-btn sm inverted"
                             style={btnStyle}>{i18n[language].remove}</button>;
    const btnBlock = <button onClick={() => this.props.handleOpenModal('block', user)} data-tooltip-content="Block"
                             className="btn px-btn sm inverted"
                             style={btnStyle}>{i18n[language].block}</button>;
    const btnUnblock = <button onClick={() => this.props.handleOpenModal('unblock', user)} data-tooltip-content="Unblock"
                               className="btn px-btn sm inverted"
                               style={btnStyle}>{i18n[language].unblock}</button>;
    const btnSend = <button onClick={() => this.props.handleOpenModal('send', user)} data-tooltip-content="Send Invitation"
                            className="btn px-btn sm inverted"
                            style={btnStyle}>{i18n[language].send}</button>;
    const btnReSend = <button onClick={() => this.props.handleOpenModal('reSend', user)} data-tooltip-content="Resend Invitation"
                              className="btn px-btn sm inverted"
                              style={btnStyle}>{i18n[language].resend}</button>;
    const btnDeleted = <button onClick={() => this.props.handleOpenModal('delete', user)} data-tooltip-content="Remove"
                            className="btn px-btn sm inverted"
                            style={btnStyle}>{i18n[language].remove}</button>;
    //const btnPM = null;
    const btnPM = user.user_id !== user.current_user_id &&
      <button onClick={() => this.props.modalShowPM('PM', user.username, user.user_id)}
              data-tooltip-content="Private Message" className="btn px-btn sm inverted"
              style={btnStyle}>
        {i18n[language].pm}
      </button>;
    const btnSendEmail = this.props.canSendEmail() ? <a className="btn px-btn sm inverted" href={'mailto:' + user.email}
                         style={btnStyle}>{i18n[language].sendMail}</a> : '';
    const btnResponses = <div onClick={() => seeResponses()}
                            className="btn px-btn sm inverted"
                            style={btnStyle}>{i18n[language].seeResponses}</div>;

    let email = <div className="email">{user.email}</div>;
    let inviteeAction = null;

    if (user.current_user_id === user.user_id){
      inviteeAction = <div className="px-quick-actions"> { btnProfile } </div>;
    } else {
      switch(role_2){
        case 'Owner':
          switch(user.state){
            case 'Draft':
              inviteeAction = <div className="px-quick-actions">{ btnSend } { btnEdit } { btnDeleted }</div>;
              break;
            case 'Invited':
              inviteeAction = <div className="px-quick-actions">{ btnSendEmail } { btnReSend } { btnEdit } { btnDeleted }</div>;
              break;
            case 'Enabled':
              if(user.is_welcome){
                if (user.role_1 === 'Participant') {
                  inviteeAction = 
                    <div className="px-quick-actions">
                      {btnProfile} {btnResponses} {btnFollow}  {btnPM} {btnSendEmail} {btnEdit} {btnBlock} {btRemove} 
                    </div>;
                } else if (user.role_2 === 'Observer' || user.role_2 === 'Recruiter') {
                  inviteeAction =
                    <div className="px-quick-actions">
                      {btnProfile} {btnPM} {btnSendEmail} {btnEdit} {btnBlock} {btRemove}
                    </div>;
                } else {
                  inviteeAction =
                    <div className="px-quick-actions">
                      {btnProfile} {btnFollow} {btnPM} {btnSendEmail} {btnEdit} {btnBlock} {btRemove}
                    </div>;
                }
              } else {
                inviteeAction = <div className="px-quick-actions">{ btnPM } { btnSendEmail } { btnEdit }{ btnBlock }
                { btRemove }</div>;
              }
              break;
            case 'Blocked':
              if (user.role_1 === 'Participant') {
                inviteeAction = <div className="px-quick-actions">{btnProfile} {btnResponses} {btnSendEmail} {btnEdit}
                {btnUnblock} {btRemove}</div>;
              } else {
                inviteeAction = <div className="px-quick-actions">{btnProfile} {btnSendEmail} {btnEdit} {btnUnblock}
                {btRemove}</div>;
              }
              break;
            case 'Declined':
              inviteeAction = <div className="px-quick-actions">{ btnDeleted }</div>;
              break;
          }
          break;
        case 'Moderator':
          if(user.role_2 === ''){
            switch(user.state){
              case 'Draft':
                inviteeAction = <div className="px-quick-actions">{ btnSend } { btnEdit } { btnDeleted }</div>;
                break;
              case 'Invited':
                inviteeAction = <div className="px-quick-actions">{ btnSendEmail } { btnReSend } { btnEdit } { btnDeleted }</div>;
                break;
              case 'Enabled':
                if(user.is_welcome){
                  inviteeAction = 
                  <div className="px-quick-actions">
                    { btnProfile } { btnResponses } { btnFollow } { btnPM } {btnSendEmail} { btnEdit } { btnBlock } { btRemove }
                    </div>;
                } else {
                  inviteeAction = <div className="px-quick-actions">{btnPM} {btnSendEmail} { btnEdit } { btnBlock } { btRemove }</div>;
                }
                break;
              case 'Blocked':
                inviteeAction = <div className="px-quick-actions">{btnProfile} {btnResponses} { btnSendEmail} { btnEdit }
                { btnUnblock } { btRemove }</div>;
                break;
              case 'Declined':
                inviteeAction = <div className="px-quick-actions">{ btnDeleted }</div>;
                break;
            }
          }

          if (user.role_2 === 'Moderator') {
            switch (user.state) {
              case 'Enabled':
                inviteeAction = <div className="px-quick-actions">{btnProfile} {btnFollow} {btnPM}</div>;
                break;
              case 'Blocked':
                 inviteeAction = <div className="px-quick-actions">{btnProfile}</div>;
                break;
            }
          }

          if(user.role_2 === 'Observer' || user.role_2 === 'Recruiter'){
            switch(user.state){
              case 'Draft':
                inviteeAction = <div className="px-quick-actions">{ btnSend } { btnEdit } { btnDeleted }</div>;
                break;
              case 'Invited':
                inviteeAction = <div className="px-quick-actions">{btnSendEmail} { btnReSend } { btnEdit } { btnDeleted }</div>;
                break;
              case 'Enabled':
                if(user.is_welcome){
                  inviteeAction = 
                  <div className="px-quick-actions">
                    {btnProfile} {btnPM} {btnEdit} {btnBlock} {btRemove}
                  </div>;
                } else {
                  inviteeAction = <div className="px-quick-actions">{btnPM} {btnSendEmail} { btnEdit }{ btnBlock }{ btRemove }</div>;
                }
                break;
              case 'Blocked':
                inviteeAction = <div className="px-quick-actions">{btnProfile}{ btnSendEmail } { btnEdit } { btnUnblock } { btRemove }</div>;
                break;
              case 'Declined':
                inviteeAction = <div className="px-quick-actions">{ btnDeleted }</div>;
                break;
            }
          } else if(user.role_2 === 'Owner'){
            inviteeAction = <div className="px-quick-actions">{btnProfile} {btnFollow} { btnPM }</div>;
          }
          break;
        case 'Observer':
          if(user.role_2 !== ''){
            inviteeAction = <div className="px-quick-actions">{btnProfile}{ btnPM }</div>;
            email = null;
          } else if (user.state === 'Enabled' && user.is_welcome) {
            inviteeAction = <div className="px-quick-actions">{btnProfile}{btnResponses} {btnFollow}</div>;
            email = null;
          }
          break;
        case 'Recruiter':
          if (user.role_2 === '') {
            switch (user.state) {
              case 'Draft':
                inviteeAction = <div className="px-quick-actions">{btnSend}{btnEdit}{btnDeleted}</div>;
                break;
              case 'Invited':
                inviteeAction = <div className="px-quick-actions">{btnSendEmail}{btnReSend}{btnEdit}{btnDeleted}</div>;
                break;
              case 'Enabled':
                if (user.is_welcome) {
                  inviteeAction = <div className="px-quick-actions">{btnProfile}{btnPM}{btnSendEmail}{btnEdit}</div>;
                } else {
                  inviteeAction = <div className="px-quick-actions">{btnPM}{btnSendEmail}{btnEdit}{btRemove}</div>;
                }
                break;
              case 'Blocked':
                inviteeAction = <div className="px-quick-actions">{btnProfile}{btnSendEmail}{btnEdit}</div>;
                break;
              case 'Declined':
                inviteeAction = <div className="px-quick-actions">{btnDeleted}</div>;
                break;
            }
          } else {
            if (user.state === 'Enabled' && user.is_welcome) {
              inviteeAction = <div className="px-quick-actions">{btnProfile}{btnPM}</div>;
              if (user.role_2 === 'Observer') {
                  email = null;
              }
            }
          }
          break;
          case '':
            if (user.current_user_id === user.user_id) {
              inviteeAction = <div className="px-quick-actions">{btnProfile} {btnResponses}</div>;
            } else {
              inviteeAction = <div className="px-quick-actions">{btnProfile} {btnLastResponse} {btnFollow}</div>;
              email = null;
            }
            break;
      }
    }

    return (
      <div className="modal-dialog" style={{width:'300px', margin:'30px auto 0 auto', minWidth:'300px'}}>
        <div className="modal-content">
          <div className="modal-header modal-delete-activity">
            <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                    onClick={(e) => this.props.updateShowMobileModal(e, false)}>
              <i className="material-icons">clear</i>
            </button>
          </div>

          <div className="modal-body">
            <div className="px-quick-actions-modal">
              <div className="px-user-cont">
                <img src={avatar} alt="" className="px-thumb-35 img-circle"/>
                <div className="px-user-data">
                  <div className="username">{user.username}</div>
                  { email }
                </div>
              </div>
              { inviteeAction }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
