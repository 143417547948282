import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimeAgo from 'react-timeago';
import englishStrings from 'react-timeago/lib/language-strings/en';
import spanishStrings from 'react-timeago/lib/language-strings/es';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import TextareaAutosize from 'react-textarea-autosize';
import { Tooltip as ReactTooltip } from "react-tooltip"

import Reply from "./Reply";
import {usernamesToDisplay} from "../shared_utils";

export default class Comment extends React.Component {
  static propTypes = {
    comment: PropTypes.object.isRequired,
    avatar: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    isReplyOpen: PropTypes.bool.isRequired,
    updateIsReplyOpen: PropTypes.func.isRequired,
    activeCommentIdToReply: PropTypes.number.isRequired,
    activeReplyIdToReply: PropTypes.number.isRequired,
    updateActiveCommentIdToReply: PropTypes.func.isRequired,
    updateActiveReplyIdToReply: PropTypes.func.isRequired,
    replyBody: PropTypes.string.isRequired,
    replyToReplyBody: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    saveReplyToComment: PropTypes.func.isRequired,
    saveLike: PropTypes.func.isRequired,
    showConfirm: PropTypes.bool.isRequired,
    updateShowConfirm: PropTypes.func.isRequired,
    entity: PropTypes.object,
    scrollFirstTime: PropTypes.bool,
    updateScrollFirstTime: PropTypes.func,
    updateShowComments: PropTypes.func,
    userId: PropTypes.number,
    questionId: PropTypes.number,
    from: PropTypes.string.isRequired,
    item_scroll: PropTypes.object,
    communityState: PropTypes.string,
    updateShowConfirmReply: PropTypes.func,
    role_1: PropTypes.string,
    role_2: PropTypes.string,
    isSavingReply: PropTypes.bool,
    isSavingLike: PropTypes.bool,
    onHeightChange: PropTypes.func,  // Maybe it's required
    colors: PropTypes.object.isRequired,
    company: PropTypes.object,
    response: PropTypes.object,
    aiModerationScheduled: PropTypes.bool,
    isFetchingModerationSchedule: PropTypes.bool,
    updateModerationScheduled: PropTypes.func,
    aiAgentName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isHovering: false
    }
  }

  componentDidMount(){
    // Useful for Collaborators Transcript View
    // TO-DO, maybe it has a bug, because after the scrolling, if you try to do any operation (i.e. open/close comments)
    // then the scrolling will be execute again
    const {scrollFirstTime, item_scroll} = this.props;
    //console.log('Comment.componentDidMount', scrollFirstTime, 'item_scroll:', item_scroll);
    if(scrollFirstTime && item_scroll){
      this.props.updateScrollFirstTime(false);
      if(item_scroll.type === 'comment'){
        const itemDivId = `#${item_scroll.type}_${item_scroll.id}`;
        //console.log('itemDivId:', $(itemDivId));

        if($(itemDivId).offset() !== undefined){
          const cardPosition = $(itemDivId).offset().top;
          //console.log(cardPosition);

          (window).scrollTo({top: cardPosition - 175, behavior: 'smooth'});
          $(itemDivId + '> .comment-item').first().css('background-color', this.props.colors.color1[6]);
        }
      } else if(item_scroll.type === 'like') {
        // Likes to Comment
        const itemDivId = `#comment_${item_scroll.id}`;
        //console.log(itemDivId);
        const cardPosition = $(itemDivId).offset().top;

        (window).scrollTo({top: cardPosition - 75, behavior: 'smooth'});
        $(itemDivId + '> .comment-item > .comment-section > .actions-comments').first().css('background-color',
          this.props.colors.color1[6]);
      }
    }
  }

  componentDidUpdate(prevProps){
    
    // Useful for Participants Transcript View
    const {item_scroll, scrollFirstTime} = this.props;
    //console.log('Comment.componentDidUpdate', prevProps.scrollFirstTime, scrollFirstTime);

    if(prevProps.scrollFirstTime !== scrollFirstTime){
      //console.log(item_scroll);
      if(item_scroll && item_scroll.type !== 'response'){
        this._scrolling(item_scroll);
      }
    }
  }

  handleMouseHover = (hovered) => {
    this.setState({isHovering: hovered});
  }

  render() {
    const {comment, avatar, from, item_scroll, communityState, role_1, role_2, language, colors, company} = this.props

    const i18n = {
      en: {
        leaveReply: 'Reply here', post: 'Post',
        hasNotSeenComment: "The participant hasn't seen this comment yet",
        ai: 'by Pixiebob AI',
        deleteComment: 'Delete comment',
        aiCommentScheduled: "Pixiebob AI will post a comment soon"
      },
      es: {
        leaveReply: 'Responde aquí', post: 'Enviar',
        hasNotSeenComment: 'El participante no ha visto este comentario aún',
        ai: 'por Pixiebob IA',
        deleteComment: 'Eliminar comentario',
        aiCommentScheduled: "Pixiebob IA enviará un comentario pronto"
      }
    };

    const formatter = language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);

    const replies = comment.replies.map((reply, index) => {
      const isReplyToReplyOpen = this.props.activeReplyIdToReply === reply.reply.id;

      return(
        <Reply key={index}
               reply={reply}
               language={language}
               activeReplyIdToReply={this.props.activeReplyIdToReply}
               isReplyToReplyOpen={isReplyToReplyOpen}
               replyToReplyBody={this.props.replyToReplyBody}
               handleChange={this.props.handleChange}
               updateActiveReplyIdToReply={this.props.updateActiveReplyIdToReply}
               saveReplyToComment={this.props.saveReplyToComment}
               commentId={comment.comment.id}
               currentUserId={comment.currentUserId}
               saveLike={this.props.saveLike}
               scrollFirstTime={this.props.scrollFirstTime}
               updateScrollFirstTime={this.props.updateScrollFirstTime}
               entity={this.props.entity}
               from={from}
               item_scroll={item_scroll}
               communityState={communityState}
               updateShowConfirmReply={this.props.updateShowConfirmReply}
               role_2={role_2}
               isSavingReply={this.props.isSavingReply}
               isSavingLike={this.props.isSavingLike}
               onHeightChange={this.props.onHeightChange}
               colors={colors}
               response={this.props.response}
               aiModerationScheduled={this.props.aiModerationScheduled}
               isFetchingModerationSchedule={this.props.isFetchingModerationSchedule}
               updateModerationScheduled={this.props.updateModerationScheduled}
               aiAgentName={this.props.aiAgentName}
               company={company}
        />
      )
    });

    const likeIcon = comment.isLike ? ['fas','heart'] : ['far','heart'];
    const likeBtn = communityState !== 'Closed' ? comment.comment.user_id === comment.currentUserId ?
      <React.Fragment><FontAwesomeIcon icon={likeIcon}/><div className="number">{comment.likes}</div></React.Fragment> :
      <div className="actions-comments" onClick={() => this.props.saveLike(comment.comment.id, 'Comment', !comment.isLike)}
           style={{cursor:'pointer'}}>
        <FontAwesomeIcon icon={likeIcon} />
        <div className="number" style={{cursor:'pointer'}}>{comment.likes}</div>
      </div> :
      <React.Fragment><FontAwesomeIcon icon={likeIcon}/><div className="number">{comment.likes}</div></React.Fragment>;

    const deleteBtn = communityState !== 'Closed' && comment.comment.user_id === comment.currentUserId &&
      <div onClick={() => this.props.updateShowConfirm(comment.comment.id)}
           style={{visibility: this.state.isHovering ? 'visible' : 'hidden'}}
           className="px-delete-comment"
           data-tooltip-content={i18n[language].deleteComment}
      >
        <FontAwesomeIcon className="icon" icon={['fas','trash']}/>
      </div>;

    return (
      <div id={'comment_' + comment.comment.id}
           onMouseEnter={() => this.handleMouseHover(true)}
           onMouseLeave={() => this.handleMouseHover(false)}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="comment-item">
          <img src={this.getAvatar(comment)} alt=""
               className="avatar-user px-thumb-25 img-circle"/>
          <div className="comment-section px-comment-body">
            <div className="user" style={{color: colors.color1[3]}}>
              <span>
                {
                  this.getUserName(comment)
                }
              </span>
              {
                (from === 'CTV' && role_2 !== 'Observer' && comment.comment.kind.from !== 'AI') &&
                <span className="email" style={{marginLeft: '5px'}}> {comment.email && `(${comment.email})` }</span>
              }
            </div>
            <div className="comment">
              <span className="text">{comment.comment.body} { deleteBtn }</span>
            </div>
            <div className="actions-comments">
              { role_2 !== 'Observer' ? !this.props.isSavingLike ? likeBtn :
                <FontAwesomeIcon icon={['fas','spinner']} spin/> : <FontAwesomeIcon icon={likeIcon}/>}
              {/*<div className="number">{comment.likes}</div>*/}
              {/*<FontAwesomeIcon icon={['fas','trophy']}/>*/}&nbsp;&nbsp;
              {
                role_2 !== 'Observer' &&
                <div onClick={communityState !== 'Closed' ?
                  () => this.props.updateActiveCommentIdToReply(comment.comment.id) : null} style={{cursor:'pointer'}}>
                  <FontAwesomeIcon icon={['fas','reply']}/>
                </div>
              }
              <div className="time">
                <TimeAgo date={comment.comment.created_at} formatter={formatter}/>
                {
                  (role_2 === 'Owner' || role_2 === 'Moderator') &&
                  comment.comment.alert === 'unseen' &&
                  <FontAwesomeIcon icon={['fas','eye-slash']}
                                   style={{color: colors.color1[3]}}
                                   data-tooltip-content={i18n[language].hasNotSeenComment}
                  />
                }
                {
                  (role_1 === 'Collaborator' && comment.comment.kind?.from === 'AI') &&
                  <FontAwesomeIcon icon={['fal','microchip-ai']}
                                   style={{color: colors.color1[3]}}
                                   data-tooltip-content={i18n[language].ai}
                  />
                }
              </div>
            </div>
            {
              this.props.isReplyOpen ?
                <div className="interaction-container" style={{marginTop:'5px'}}>
                  <div className="px-comments-input" style={{width:'400px'}}>
                    <TextareaAutosize name="replyBody" value={this.props.replyBody} onChange={this.props.handleChange}
                                      onHeightChange={rowHeight => this.props.onHeightChange(rowHeight)}
                                      minRows={1}
                                      placeholder={this.props.aiModerationScheduled ? i18n[language].aiCommentScheduled : i18n[language].leaveReply}
                                      style={{
                                        borderColor: this.props.aiModerationScheduled ? '#ffffff' : colors.color1[3],
                                        backgroundColor: this.props.aiModerationScheduled ? '#f6f6f6' : '#ffffff'
                                      }}
                                      disabled={this.props.aiModerationScheduled}

                    />
                    <div style={{cursor:'pointer'}}
                         onClick={
                           this.props.isSavingReply || this.props.isFetchingModerationSchedule ?
                             () => null :
                             this.props.aiModerationScheduled ?
                               () => {this.props.updateModerationScheduled(this.props.response?.id)} :
                               () => this.props.saveReplyToComment(comment.comment.id, -1)
                         }
                    >
                      { this.props.isSavingReply || this.props.isFetchingModerationSchedule ?
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin style={{ color: colors.color1[3]}}/> :
                        this.props.aiModerationScheduled ?
                          <FontAwesomeIcon icon={['far', 'timer']}
                                           style={{ cursor: 'pointer', color: colors.color1[3]}}
                                           data-tooltip-content={this.props.aiModerationScheduled ?
                                             i18n[language].aiCommentScheduled : ''}
                          /> :
                          <FontAwesomeIcon icon={['far','paper-plane']}
                                           style={{ cursor: 'pointer', color: colors.color1[3] }}/>

                      }
                    </div>
                  </div>
                </div> : null
            }
          </div>
        </div>

        { replies }
      </div>
    );
  }

  _scrolling(item_scroll){
    //console.log(item_scroll);
    const itemDivId = item_scroll.type === 'comment' ?
      `#${item_scroll.type}_${item_scroll.id}` : `#comment_${item_scroll.id}`;
    //console.log(itemDivId);
    if($(itemDivId).offset() !== undefined){
      const cardPosition = $(itemDivId).offset().top;

      switch(item_scroll.type){
        case 'comment':
          (window).scrollTo({top: cardPosition - 175, behavior: 'smooth'});
          $(itemDivId + '> .comment-item').first().css('background-color', this.props.colors.color1[6]);
          break;
        case 'like':
          (window).scrollTo({top: cardPosition - 75, behavior: 'smooth'});
          $(itemDivId + '> .comment-item > .comment-section > .actions-comments').first().css('background-color',
            this.props.colors.color1[6]);
          break;
      }
    }
  }

  getUserName (comment) {
    const i18n = {en: {you: 'You'}, es: {you: 'Tú'}}
    const {company, aiAgentName, language} = this.props;
    const isAIComment = comment.comment.kind.from === 'AI';
    const currentUserId = comment.currentUserId;
    const commentUserId = comment.comment.user_id;
    const isCommentFormCurrentUser = currentUserId === commentUserId;

    if (isAIComment) {
      const companyName = company?.name || '';
      const agentName = aiAgentName || '';
      return agentName || companyName || 'Moderator'
    } else {
      if (isCommentFormCurrentUser) {
        return i18n[language].you
      } else {
        const availableUsernames = usernamesToDisplay(comment, 'alias', 'all')
        return availableUsernames[0];
      }
    }
  }

  getAvatar (comment) {
    const {avatar, company} = this.props
    const isAIComment = comment.comment.kind.from === 'AI';

    if (isAIComment) {
      const companyLogo = company ? company.logo : null;
      return companyLogo || avatar;
    } else {
      return avatar
    }
  }


}
