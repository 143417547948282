import PropTypes from 'prop-types';
import React from 'react';

import Layout from "../../Layout";
import PCDashboard from "../../../containers/Community/Participant/PCDashboard";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";

export default class PCDashboardPage extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    sessionLang: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    outside: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      communitiesTop: [],
      notifications: [],
      rooms: [],
      activeUsers: [],
      toDoCounter: 0,
      openPMFromOutside: {username: '', userId: null, type: null, flag: false},
      notificationsCounter: 0,
      isBeaconInit: false //Keeps track of any instances of Head Scout Beacons initialised.
    }
  }

  componentDidMount(){
    const {outside} = this.props;

    this._getCommunitiesTop();  // New Relic sends some errors about it
    this._getNotificationsCounter();
    this._getActiveUsers(); // New Relic sends some errors about it

    if(outside){
      if(outside.isOpen === 'true'){
        this.updateOpenPMFromOutside(outside.type, outside.username, outside.userId);
      }
    }
  }

  render() {
    const {community, user_account, collaborators} = this.props;
    const language = user_account.profile.language ? user_account.profile.language : this.props.sessionLang;
    const hsBeaconSuggestions = {
      en: ['5f1fffdb2c7d3a10cbab68c2', '5f20076a04286306f8077d4d', '5f20087e2c7d3a10cbab691e', '5f200aa02c7d3a10cbab693a',
        '5f200d8e04286306f8077d91', '5f200f742c7d3a10cbab6960', '5f20105b04286306f8077dac', '5f20123204286306f8077dc3',
        '5f2014e004286306f8077dda', '5f2387aa04286306f807af6c'],
      es: ['5f20004504286306f8077cff', '5f2007652c7d3a10cbab6913', '5f20097404286306f8077d62', '5f200c4e2c7d3a10cbab694a',
        '5f200f2c2c7d3a10cbab695c', '5f200fa104286306f8077da2', '5f2010ac2c7d3a10cbab6973', '5f2012ac2c7d3a10cbab698e',
        '5f2014fc2c7d3a10cbab69aa', '5f2387fa2c7d3a10cbaba06f']
    };

    return (
      <Layout user_account={user_account}
              profile={user_account.profile}
              sessionLang={user_account.language}
              notifications={this.state.notifications}
              from={'ParticipantCommunityPage'}
              community={community}
              leftMenuActive={'Dashboard'}
              rooms={this.state.rooms}
              active_users={this.state.activeUsers}
              communities={this.state.communitiesTop}
              toDoCounter={this.state.toDoCounter}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              notificationsCounter={this.state.notificationsCounter}
              colors={user_account.all_colors}>

        {/*<HelpScoutBeacon beaconId='28ac6ede-d1f2-454e-af04-300953bd31db'*/}
        {/*                user_account={user_account}*/}
        {/*                colors={user_account.all_colors}*/}
        {/*                language={language}*/}
        {/*                isBeaconInit={this.state.isBeaconInit}*/}
        {/*                updateIsBeaconInit={this.updateIsBeaconInit}*/}
        {/*                suggestions={hsBeaconSuggestions}*/}
        {/*                hideFABOnDesktop={!this.props.user_account.profile.is_help}/>*/}

        <PCDashboard community={community}
                     profile={user_account.profile}
                     collaborators={collaborators}
                     language={language}
                     updateToDoCounter={this.updateToDoCounter}
                     user_account={user_account}
                     isBeaconInit={this.state.isBeaconInit}
                     updateIsBeaconInit={this.updateIsBeaconInit}
                     activeUsers={this.state.activeUsers}/>
      </Layout>
    );
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  _getActiveUsers = () => {
    $.ajax({
      url: '/communities/get_users_chat/' + this.props.community.id,
      method: 'GET',
      success: activeUsers => {
        // console.log('PCDashboardPage.activeUsers:', activeUsers);
        this.setState({activeUsers});
      }
    });
  };

  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log(communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };

  _getRooms = () => {
    $.ajax({
      url: '/communities/get_rooms/' + this.props.community.id,
      method: 'GET',
      success: rooms =>  {
        //console.log(rooms);
        this.setState({rooms});
      }
    });
  };

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  _getNotifications = () => {
    $.ajax({
      url: '/notifications/get_notifications/-1',
      method: 'GET',
      success: notifications =>  {
        this.setState({notifications});
      }
    });
  };

  updateOpenPMFromOutside = (type, username, userId) => {
    //console.log(type, username, userId);
    this.setState(state => ({openPMFromOutside: {username: username, userId: userId, type,
        flag: !state.openPMFromOutside.flag}}));
  };

  updateToDoCounter = (toDoCounter) => {
    this.setState({toDoCounter})
  };
}
