import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class R812TFilters extends React.Component {
  static propTypes = {
    objSearch: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    handleUserArrows: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    consolidated: PropTypes.string.isRequired,
    updateConsolidated: PropTypes.func.isRequired,
    updateShowAllVerbatim: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    showAllVerbatim: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {objSearch, users, consolidated, colors, showAllVerbatim} = this.props;

    let btnUserArrowLeft = null;
    let btnUserArrowRight = null;
    let imgUserAvatar = null;

    if(objSearch.idPars.length === 1){
      const user = users.find(user => user.id === objSearch.idPars[0]);

      btnUserArrowRight = <FontAwesomeIcon onClick={user !== undefined ? () => this.props.handleUserArrows('right') : null}
                                           icon={['fas','caret-right']} className="cursor right"/>;
      btnUserArrowLeft = <FontAwesomeIcon onClick={user !== undefined ? () => this.props.handleUserArrows('left') : null}
                                          icon={['fas','caret-left']} className="cursor left" />;
      imgUserAvatar = <img src={user !== undefined ? user.avatar !== '' ? user.avatar : '/assets/user.png' : '/assets/user.png'}
                           className="px-thumb-40 img-circle" alt=""/>;

    } else {
      imgUserAvatar = <div className="multiple-users" style={{backgroundColor: colors.color1[1]}}>
        <FontAwesomeIcon icon={['fas', 'user-group']} style={{ fontSize: '14px' }}/>
        <div className="counter-users" style={{backgroundColor: colors.color0[3]}}>
          <span>{objSearch.idPars.length}</span>
        </div>
      </div>;
    }

    const sumObjSearch = objSearch.idActs.length + objSearch.idPars.length + objSearch.idSegs.length +
      objSearch.idTags.length + objSearch.idTasks.length;
    //console.log('sumObjSearch:', sumObjSearch);

    return (
      <div className="px-resp-transc-filters" id="divFiltersMobile" style={{zIndex:'3'}}>
        <div className="users-container">
          { btnUserArrowLeft }
          { imgUserAvatar }
          { btnUserArrowRight }
        </div>

        <div className="px-filter-icons-container-master">
          <div onClick={this.props.updateShowModal} className="item filter">
            <FontAwesomeIcon icon={['fas', 'filter']} className="icon" style={{ color: sumObjSearch === 0 ? colors.color0[5] : colors.color0[3] }}/>
          </div>
          <div onClick={() => this.props.updateConsolidated('general')} className="item magic">
            <FontAwesomeIcon icon={['fas', 'layer-group']} style={{ color: consolidated === 'general' ? colors.color2[3] : colors.color2[5]  }}/>
          </div>
          <div onClick={() => this.props.updateConsolidated('images')} className="item magic">
            <FontAwesomeIcon icon={['fas', 'image']} style={{ color: consolidated === 'images' ? colors.color2[3] : colors.color2[5] }}/>
          </div>
          <div onClick={() => this.props.updateConsolidated('videos')} className="item magic">
            <FontAwesomeIcon icon={['fas', 'video']} style={{ color: consolidated === 'videos' ? colors.color2[3] : colors.color2[5]}}/>
          </div>
          <div onClick={this.props.updateShowAllVerbatim} className="item magic">
            <FontAwesomeIcon icon={['fas','quote-right']} className="icon"
              style={{ color: consolidated === '' && showAllVerbatim ? colors.color2[3] : colors.color2[5]}}/>
          </div>
          {/*<div className="item ">
            <div className="dropdown px-trans-resp-menu-dropdown">
              <FontAwesomeIcon icon={['fas','magic']} className="principal-icon dropdown-toggle" data-toggle="dropdown"
                               aria-expanded="false"/>
              <div className="dropdown-menu px-actions-transcript-menu">
                <div className="container-actions">
                  <FontAwesomeIcon onClick={this.props.updateShowAllVerbatim} icon={['fas','quote-right']} className="icon"/>
                  <FontAwesomeIcon icon={['fas','comment']} className="icon"/>
                  <FontAwesomeIcon icon={['fas','globe']} className="icon"/>
                </div>
              </div>
            </div>
          </div>*/}
          <div className="item last menu-chev-up-mobile">
            <FontAwesomeIcon icon={['far','circle-chevron-up']} />
          </div>
        </div>
      </div>
    );
  }
}
