import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"
import {DateRangePicker} from "react-dates";
import moment from 'moment';
import MediaQuery from "react-responsive";
import {numberFilterSegment} from "../../../UI/Globals/PX_Funs";
import {usernamesToDisplay} from "../../shared_utils";

export default class TFM_Advanced extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    objSearch: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    advancedActions: PropTypes.func.isRequired,
    updatePills: PropTypes.func.isRequired,
    allAvailableActs: PropTypes.array.isRequired,
    updateFiltersOpts: PropTypes.func.isRequired,
    segments: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    executeUpdateModal: PropTypes.func.isRequired,
    updateSltCalendar: PropTypes.func,
    updateStartExpireDates: PropTypes.func,
    gender_as_segment: PropTypes.bool,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    searchModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      q: '',
      filterAllNoneParticipants: false,
      filterAllNoneSegments: false,
      filterAllNoneActivities: false,
      filterAllNoneTags: false,
      filterAllNoneTagsLive: false,
      type: 'Participants',
      showCalendarOpts: false
    }
  }

  componentDidMount() {
    let btnColor = this.props.colors.color0[3];
    $(".nav-tabs > li.active > a").css({ "color": '#ffffff', 'background-color': btnColor, 'border-color': btnColor });
  }

  render() {
    const {objSearch, users, allAvailableActs, segments, tags, gender_as_segment, language, colors} = this.props
    const I18n = {
      en: {
        btn_save: 'Update', btn_cancel: 'Cancel', search: 'SEARCH', all: 'All', none: 'None', live: 'Live',
        available: 'Available', expired: 'Expired', creationDate: 'Creation Date',
        startDate: 'Start date', endDate: 'End date', tasks: 'tasks', participants: 'participants',
        activities: 'activities', segments: 'segments'
      },
      es: {
        btn_save: 'Actualizar', btn_cancel: 'Cancelar', search: 'BUSCAR', all: 'Todo', none: 'Nada', live: 'Live',
        available: 'Disponibles', expired: 'Expiradas', creationDate: 'Creación',
        startDate: 'Inicio', endDate: 'Expiración', tasks: 'tareas', participants: 'participantes',
        activities: 'actividades', segments: 'segmentos'
      }
    }
    const tabStyle = {
      active: { border: `1px solid ${colors.color0[3]}`, color: '#ffffff', backgroundColor: colors.color0[3] },
      inactive: { border: '1px solid #DADDE1', color: '#7C8593', backgroundColor: '#ffffff' }
    }

    let searchForm = <div className="form-group xs first" style={{width: '90%', flex: '0 0 auto'}}>
      <div className="px-input-icon">
        <input name="q" type="text" onChange={this.handleFilters} value={this.state.q}
               onKeyDown={(event) => this.keyPress(event, this.state.type)}
               className="form-control icon-right"
               placeholder={''} />
        <FontAwesomeIcon onClick={() => this.props.searchModal(this.state.type, this.state.q)}
                         icon={['fas', 'magnifying-glass']} className="px-icon-right color-gray-base"/>
      </div>
    </div>;

    let rdoAll = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="All"
            checked={this.state['filterAllNone' + this.state.type] === 'All'}/>
        <span className="checkmark radio"/>
        <span style={{fontSize: '10px'}}>{I18n[language].all}</span>
      </label>
    </div>;
    let rdoNone = <div className="px-check-cont rad">
      <label className="container-check radio">
        <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="None"
           checked={this.state['filterAllNone' + this.state.type] === 'None'}/>
        <span className="checkmark radio"/>
        <span style={{fontSize: '10px'}}>{I18n[language].none}</span>
      </label>
    </div>;
    let rdoLive = null;
    let rdoAvailable = <div className="px-check-cont rad">
      <label className="container-check radio">
      <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="Available"
            checked={this.state['filterAllNone' + this.state.type] === 'Available'}/>
      <span className="checkmark radio"/>
        <span style={{fontSize: '10px'}}>{I18n[language].available}</span>
      </label>
    </div>;
    let rdoExpired = <div className="px-check-cont rad"><label className="container-check radio">
      <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="Expired"
             checked={this.state['filterAllNone' + this.state.type] === 'Expired'}/>
      <span className="checkmark radio"/>
      <span style={{fontSize: '10px'}}>{I18n[language].expired}</span>
    </label></div>;
    let calendarIcon = <div className="px-check-cont rad">
      <FontAwesomeIcon icon={['fal', 'calendar-days']} onClick={this.updateShowCalendarOpts}
                       className='px-adv-filters-calendar'
                       style={{cursor: 'pointer'}}
      />
    </div>;

    let participants = null;
    let segmentsC = null;
    let activities = null;
    let tagsC = null;
    let calendarOptions = null;

    switch(this.state.type){
      case 'Participants':
        rdoAvailable = null;
        rdoExpired = null;
        calendarIcon = null;

        rdoAll = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="All"
                   checked={users.length === objSearch.idPars.length}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{I18n[language].all}</span>
          </label>
        </div>;
        rdoNone = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="None"
                   checked={objSearch.idPars.length === 0}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{I18n[language].none}</span>
          </label>
        </div>;

        participants = users.map(user => {
          const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
          const mainName = availableUsernames[0];
          const alternativeNames = availableUsernames.length > 1 && availableUsernames.slice(1).join(', ')

          const checked = objSearch.idPars.find(id => user.id === id) !== undefined

          if(user.isVis){
            return(
              <div key={user.id} className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

                <div className="px-check-cont">
                  <label className="container-check filters-modal">
                    <input type="checkbox" onChange={() => this.props.advancedActions(user.id, 'idPars')}
                           checked={checked}/>
                    <span className="checkmark filters-modal"/>
                    <img src={user.avatar !== '' ? user.avatar : '/assets/user.png'} className="img-circle px-thumb-20" alt=""/>
                    <div className="user-data" style={{overflow: 'hidden'}}>
                      <div style={{fontSize: '12px', color: "#7C8593"}}
                           data-tooltip-content={alternativeNames ? `a.k.a:  ${alternativeNames}` : '' }>
                        {mainName}</div>
                      {/*<div className="txt-email">{user.email}</div>*/}
                    </div>
                  </label>
                </div>
              </div>
            )
          }
        });
        break;
      case 'Segments':
        rdoAvailable = null;
        rdoExpired = null;
        calendarIcon = null;

        rdoAll = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="All"
                   checked={segments.length === objSearch.idSegs.length}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{I18n[language].all}</span>
          </label>
        </div>;
        rdoNone = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="None"
                   checked={objSearch.idSegs.length === 0}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{I18n[language].none}</span>
          </label>
        </div>;

        segmentsC = segments.map(segment => {
          const checked = objSearch.idSegs.find(id => segment.id === id) !== undefined;

          let icon;
          switch(segment.name){
            case 'Female':
              icon = ['fal', 'venus'];break;
            case 'Male':
              icon = ['fal', 'mars'];break;
            case 'Other Gender':
              icon = ['fal', 'transgender'];break;
            default:
              icon = ['fas', 'bookmark'];
          }

          if(segment.isVis){
            return(
              <div key={segment.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
                <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

                <div className="px-check-cont">
                  <label className="container-check filters-modal img">
                    <input type="checkbox" onChange={() => this.props.advancedActions(segment.id, 'idSegs')}
                           checked={checked}/>
                    <span className="checkmark filters-modal"/>
                    <FontAwesomeIcon icon={icon} className="tag icon" style={{color: segment.color}}/>

                    <div className="user-data" style={{overflow: 'hidden'}}>
                      <div style={{fontSize: '12px', color: "#7C8593"}}
                           data-tooltip-content={`${segment.name} - ${numberFilterSegment(segment.id, users, gender_as_segment)} ${I18n[language].participants}`}>
                        {segment.name}
                      </div>
                      {/*<div className="txt-email">*/}
                      {/*  {numberFilterSegment(segment.id, users, gender_as_segment)} {I18n[language].participants}*/}
                      {/*</div>*/}
                      {/*<div className="txt-email">{objSearch.idSegs.length} segments</div>*/}
                    </div>
                  </label>
                </div>
              </div>
            )
          }
        });
        break;
      case 'Activities':
        if(this.state.showCalendarOpts){
          calendarOptions = <div className="px-date-filters">
            <div className="px-select">
              <select name="slcActDates" value={objSearch.actDates.name} onChange={this.props.updateSltCalendar}>
                <option value="creation">{I18n[language].creationDate}</option>
                <option value="start">{I18n[language].startDate}</option>
                <option value="expiry">{I18n[language].endDate}</option>
              </select>
            </div>
            <div className="date-container">
              <DateRangePicker
                startDate={objSearch.actDates.startDate ? moment(objSearch.actDates.startDate) : null}
                startDateId="your_unique_start_date_id"
                endDate={objSearch.actDates.endDate ? moment(objSearch.actDates.endDate) : null}
                endDateId="your_unique_end_date_id"
                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate },
                  () => this.showDates())} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                minimumNights={0}
                displayFormat="DD/MM/YYYY"
                isOutsideRange={() => false}
                isDayHighlighted={ day => day.isSame( moment(), 'd' ) }
                hideKeyboardShortcutsPanel={true}
              />
              <FontAwesomeIcon icon={['far', 'xmark']} className="info-dialog"
                               onClick={this.clearDates} style={{cursor:'pointer'}}/>
            </div>
          </div>
        }

        rdoAll = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="All"
                   checked={allAvailableActs.length === objSearch.idActs.length}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{I18n[language].all}</span>
          </label>
        </div>;
        rdoNone = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="None"
                   checked={this.state.type !== 'Activities' ?
                     objSearch.idActs.length === 0 : this.state['filterAllNone' + this.state.type] === 'None'}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{I18n[language].none}</span>
          </label>
        </div>;
        rdoLive = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="Live"
                   checked={this.state['filterAllNone' + this.state.type] === 'Live'}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{I18n[language].live}</span>
          </label>
        </div>;

        activities = allAvailableActs.map(activity => {
          const checked = objSearch.idActs.find(id => id === activity.id) !== undefined

          if(activity.isVis){
            return(
              <div key={activity.id} className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
                <div className="px-check-cont">
                  <label className="container-check filters-modal">
                    <input type="checkbox" onChange={() => this.props.updatePills(activity.id, 'idActs')}
                           checked={checked}/>
                    <span className="checkmark filters-modal"/>
                    <div className="user-data" style={{overflow: 'hidden'}}>
                      <div style={{fontSize: '12px', color: "#7C8593"}}
                           data-tooltip-content={`${activity.title} - ${activity.questions.length} ${I18n[language].tasks}`}>
                        {activity.title}
                      </div>
                      {/*<div className="txt-email">{activity.questions.length} {I18n[language].tasks}</div>*/}
                    </div>
                  </label>
                </div>
              </div>
            )
          }
        });
        break;
      case 'Tags':
        rdoAvailable = null;
        rdoExpired = null;
        calendarIcon = null;

        rdoAll = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="All"
                   checked={tags.length === objSearch.idTags.length}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{I18n[language].all}</span>
          </label>
        </div>;
        rdoNone = <div className="px-check-cont rad">
          <label className="container-check radio">
            <input name={'filterAllNone' + this.state.type} type="radio" onChange={this.handleFilters} value="None"
                   checked={objSearch.idTags.length === 0}/>
            <span className="checkmark radio"/>
            <span style={{fontSize: '10px'}}>{I18n[language].none}</span>
          </label>
        </div>;

        tagsC = tags.map(tag => {
          const checked = objSearch.idTags.find(id => tag.id === id) !== undefined

          if(tag.isVis){
            return(
              <div key={tag.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
                <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
                <div className="px-check-cont">
                  <label className="container-check filters-modal img">
                    <input type="checkbox" onChange={() => this.props.advancedActions(tag.id, 'idTags')}
                           checked={checked}/>
                    <span className="checkmark filters-modal"/>
                    <FontAwesomeIcon icon={['fas','tag']} className="tag icon" style={{color: tag.color}}/>
                    <div className="user-data" style={{overflow: 'hidden'}}>
                      <div style={{fontSize: '12px', color: "#7C8593"}}
                           data-tooltip-content={`${tag.name} - ${this._numberFilterTag(tag.id, allAvailableActs)} ${I18n[language].activities}`}>
                        {tag.name}
                      </div>
                      {/*<div className="txt-email">*/}
                      {/*  {this._numberFilterTag(tag.id, allAvailableActs)} {I18n[language].activities}*/}
                      {/*</div>*/}
                      {/*<div className="txt-email">{objSearch.idTags.length} tags</div>*/}
                    </div>
                  </label>
                </div>
              </div>
            )
          }
        })
        break
    }

    return (
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                    onClick={() => this.props.updateShowModal(null, null)}>
              <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px'}} />
            </button>
          <h4 className="px-modal-title">{I18n[language].filterBy}</h4>
          </div>
          <div className="modal-body px-modal-body-adv-filters">
            <ul className="nav nav-tabs px-submenu-settings-navigation" role="tablist">
              <li className="active" onClick={() => this.setType('Participants')}>
                <a className="filters" href="#participants_invitations" role="tab" data-toggle="tab" 
                   style={ this.state.type === 'Participants' ? tabStyle.active : tabStyle.inactive } >
                  <MediaQuery minDeviceWidth={568}>
                    <span>{I18n[language].participants}</span>
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={567}>
                    <FontAwesomeIcon icon={['fas','user-group']} className=""/>
                  </MediaQuery>
                </a>
              </li>
              <li onClick={() => this.setType('Segments')}>
                <a className="filters" href="#participants_invitations" role="tab" data-toggle="tab" 
                  style={this.state.type === 'Segments' ? tabStyle.active : tabStyle.inactive }>
                  <MediaQuery minDeviceWidth={568}>
                    <span>{I18n[language].segments}</span>
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={567}>
                    <FontAwesomeIcon icon={['fas','bookmark']} className=""/>
                  </MediaQuery>
                </a>
              </li>
              <li onClick={() => this.setType('Activities')}>
                <a className="filters" href="#participants_invitations" role="tab" data-toggle="tab" 
                  style={this.state.type === 'Activities' ? tabStyle.active : tabStyle.inactive }>
                  <MediaQuery minDeviceWidth={568}>
                    <span>{I18n[language].activities}</span>
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={567}>
                    <FontAwesomeIcon icon={['fas', 'list-timeline']} />
                    {/* <i className="mdi mdi-format-list-bulleted-type"/> */}
                  </MediaQuery>
                </a>
              </li>
              <li onClick={() => this.setType('Tags')}>
                <a className="filters" href="#participants_invitations" role="tab" data-toggle="tab"
                  style={this.state.type === 'Tags' ? tabStyle.active : tabStyle.inactive}>
                  <MediaQuery minDeviceWidth={568}>
                    <span>TAGS</span>
                  </MediaQuery>
                  <MediaQuery maxDeviceWidth={567}>
                    <FontAwesomeIcon icon={['fas','tag']} className=""/>
                  </MediaQuery>
                </a>
              </li>
            </ul>
            <div className="px-filter-adv-filter-modal-cont mg-top-25">
              { searchForm }
              <div className="px-right-container" style={{flex: '1 1 auto', marginTop: '10px', flexWrap: 'wrap'}}>
                { rdoAll }
                { rdoNone }
                { rdoLive }
                { rdoAvailable }
                { rdoExpired }
                { calendarIcon }
              </div>
            </div>
            { calendarOptions }
            <div className="modal-scroll">
              <div className="row">
                { participants }
                { segmentsC }
                { activities }
                { tagsC }
              </div>
            </div>
          </div>
          <div className="modal-footer px-modal-footer-gray">
            <div className="px-footer-container-buttons row">
              <button type="button" onClick={this.props.executeUpdateModal} className="btn px-btn sm btn-blue-base"
                      style={{backgroundColor: colors.color1[3]}}>
                {I18n[language].btn_save}</button>
              <button type="button" onClick={() => this.props.updateShowModal(null, null)}
                className="btn px-btn sm btn-gray-darker inverted">{I18n[language].btn_cancel}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _numberFilterTag(tagId, tasks){
    let counter = 0;

    tasks.forEach(task => {
      if(task.tag_id){
        if(task.tag_id === tagId){
          ++counter;
        }
      }
    });

    return counter
  }

  updateShowCalendarOpts = () => {
    this.setState(state => ({showCalendarOpts: !state.showCalendarOpts}));
  };

  keyPress = (event, type) => {
    if(event.keyCode === 13){
      this.props.searchModal(type, this.state.q)
    }
  };

  handleFilters = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    //We need to setup the final rule for search, after that then delete this validation
    if(name !== 'q'){
      // console.log('name:', name, 'value:', value);
      this.setState({[name]: value}, () => {
        this.props.updateFiltersOpts(this.state.type, value);
      });
    } else {
      this.setState({[name]: value})
    }
  };

  setType = type => {
    this.setState({type});
  };

  clearDates = () => {
    this.props.updateStartExpireDates(null, null);
  }

  // Maybe onDatesChange just needs to send the dates, but this.state is to show dates in the calendar
  showDates = () => {
    const startDate = this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : null;
    const endDate = this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null;
    //console.log(startDate, endDate);
    this.props.updateStartExpireDates(startDate, endDate);
  };
}
