import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import v4 from "uuid";

import MBHRows from "./History/MBHRows";

export default class MBHistory extends React.Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    company: PropTypes.object.isRequired,
    invoices: PropTypes.array.isRequired,
    isLoadingInvoices: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired
  };

  render() {
    const {language, invoices, isLoadingInvoices, colors} = this.props;

    const i18n = {
      en: {
        cmty: 'Community', partAcc: 'Participants access', parts: 'Participants', cost: 'F Cost',
        subsSlot: 'Subscription slots used', flex: 'Flexdays', ble: 'Balance', flexEarn: 'Flexdays Earned', days: 'days',
        est: 'Estimated cost', cash: 'Cash', price: 'Current quotation', fDays: 'F Days', noData: 'No Data', terms: 'Terms',
        downloadSaas: 'Download SaaS agreement', downloadInvoice: 'Download Invoice',
        date: 'Date', payable: 'Payable', costPerDay: 'Cost per day', settledWsubs: 'P*D settled with subscription', settledWFlex: 'P*D settled with Flexdays',
        settledWCash: 'P*D settled with cash', costPerPD: 'Cost per Participant*Day', outstandingBalance: 'Outstanding balance', totalEstimatedCost: 'Estimated Total Cost',
        payableDesc: 'Only days with Live activities will be charged', estTotalCostDesc: 'Total Cost quoted to this date', 
        estCostDay: 'Average Daily Cost as per Total Cost quoted to this date', settledSubsDesc: 'Participant*Days settled with subscription plan',
        settledFlexDesc: 'Participant*Days settled with available Flexdays', settledCashDesc: 'Participant*Days to be settled with cash', 
        outstandingBalanceDesc: 'Cost per day to be paid at the end of the Community',
        yourProjects: 'Your Projects (excluding those closed over a year ago)'
      },
      es: {
        cmty: 'Comunidad', partAcc: 'Acceso a participantes', parts: 'Participantes', cost: 'Costo F',
        subsSlot: 'Partipantes de la subscripción', flex: 'Flexdays', ble: 'Balance', flexEarn: 'Flexdays Ganados',
        days: 'días', est: 'Costo estimado', cash: 'Cash', price: 'Cotización actual', fDays: 'Días F', noData: 'No hay datos',
        terms: 'Términos', downloadSaas: 'Descargar Contrato de servicio', downloadInvoice: 'Descargar Factura',
        date: 'Fecha', payable: 'Cobrable', costPerDay: 'Costo por día', settledWsubs: 'P*D  liquidados con subscripción', settledWFlex: 'P*D liquidados con Flexdays',
        settledWCash: 'P*D liquidados con efectivo', costPerPD: 'Costo por Día*Participante', outstandingBalance: 'Saldo a pagar', totalEstimatedCost: 'Costo Total estimado',
        payableDesc: "Solo los días con Actividades 'Live' serán cobrados", estTotalCostDesc: 'Costo Total cotizado a esta fecha',
        estCostDay: 'Costo Diario Promedio según el Costo Total cotizado a esta fecha', settledSubsDesc: 'Días*Participante liquidados con plan de subscripción',
        settledFlexDesc: 'Días*Participante liquidados con Flexdays disponibles', settledCashDesc: 'Días*Participante liquidados por pagar con efectivo',
        outstandingBalanceDesc: 'Días*Participante por pagar al terminar la Comunidad',
        yourProjects: 'Tus Proyectos (excluyendo aquellos que cerraron hace más de un año)'
      }
    };

    return(
      <div className="px-card px-card-settings" style={{margin:'0 2.5% 50px'}}>
        <div className="px-icon-settings">
          <FontAwesomeIcon icon={['fas','dollar-sign']}/>
        </div>
        <div className="panel-body panel-body px-body-padding-welcome">
          <div className="row">
            <div className="col-md-12"
                 style={{textAlign: 'center',
                   fontFamily: 'Sharp Sans Book',
                   marginBottom: '25px',
                   fontSize: '12px'
                 }}>
              {i18n[language].yourProjects}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12" style={{overflow: 'scroll'}} >
              <table className="table fold-table" style={{width: '100%'}}>
                <thead>
                  <tr className="label-sm" style={{ textAlign: 'center', color: colors.color1[2]}}>
                    <th>&nbsp;</th>
                    <th scope="col" style={{ fontSize: '8px', textAlign: 'center' }}>{i18n[language].cmty}</th>
                    <th scope="col" style={{ fontSize: '8px', textAlign: 'center'}}>{i18n[language].partAcc}</th>
                    <th scope="col" style={{ fontSize: '8px', textAlign: 'center'}}>{i18n[language].parts}</th>
                    <th scope="col" style={{ fontSize: '8px', textAlign: 'center', width: '11%'}}>{i18n[language].price}</th>
                    {/* <th scope="col" style={{ fontSize: '8px', textAlign: 'center', width: '11%'}}>{i18n[language].subsSlot}</th> */}
                    {/* <th scope="col" style={{ fontSize: '8px', textAlign: 'center'}}>{i18n[language].est}</th> */}
                    {/* <th scope="col" style={{ fontSize: '8px', textAlign: 'center', width: '14%'}}>{i18n[language].cost}</th> */}
                    <th scope="col" style={{ fontSize: '8px', textAlign: 'center'}}>{i18n[language].ble}</th>
                    <th scope="col" style={{ fontSize: '8px', textAlign: 'center'}}>{i18n[language].flexEarn}</th>
                </tr>
                </thead>
                <tbody>
                {
                  isLoadingInvoices ?
                    <tr>
                      <td colSpan="9" style={{textAlign:'center'}}>
                        <FontAwesomeIcon icon={['fas','spinner']} spin/>
                      </td>
                    </tr> :
                    invoices.length !== 0 ?
                      invoices.map(invoice => {
                        return(
                          <MBHRows key={v4()}
                                   language={language}
                                   i18n={i18n}
                                   invoice={invoice}
                                   colors={colors}/>
                        )
                      }) :
                      <tr>
                        <td colSpan="9" style={{textAlign:'center'}}>
                          <label className="px-empty-msg">{i18n[language].noData}</label>
                        </td>
                      </tr>
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}