import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import axios from 'axios';
import {getCSRF, showSuccessMsg} from "../../UI/Globals/PX_Funs";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const InvitationUrlRow = ({ invitationUrl, community, language,
                            updateInvitationUrl, usedParticipantSlots,
                            segments}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newQuota, setNewQuota] = useState(invitationUrl.quota);
  const [quotaReached, setQuotaReached] = useState(false);
  const [updatingInvitationUrl, setUpdatingInvitationUrl] = useState(false);


  useEffect(() => {
    const quotaReached = checkIfQuotaReached();
    setQuotaReached(quotaReached);
  }, [invitationUrl, usedParticipantSlots]);


  const checkIfQuotaReached = () => {
    invitationUrl.current_count >= invitationUrl.quota
  }


  const i18n ={
    en: {
      clickToCopy: 'Click to copy link', clickToEdit: 'Click to edit',
      disableLink: 'Disable link', enableLink: 'Enable link'
    },
    es: {
      clickToCopy: 'Click para copiar liga', clickToEdit: 'Click para editar',
      disableLink: 'Desactivar enlace', enableLink: 'Activar enlace'
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = date.toLocaleString('es-MX', { month: 'short' }).replace('.', '');
    const year = date.getUTCFullYear();
    return `${day}/${month.charAt(0).toUpperCase() + month.slice(1)}/${year}`;
  }

  const handleQuotaChange = (event) => {
    setNewQuota(event.target.value);
  };

  const handleQuotaSave = () => {
    const communityMaxParticipants = community.num_participants;
    const communityAvailableParticipantSlots = communityMaxParticipants - usedParticipantSlots;

    const newQuotaNotOverMaxParticipants = parseInt(newQuota, 10) <= communityAvailableParticipantSlots;

    const i18n = {
      en: { maxInviteesError: `This community has only ${communityAvailableParticipantSlots} more participant slots` },
      es: { maxInviteesError: `Esta comunidad tiene solo ${communityAvailableParticipantSlots} espacios disponibles para participantes.` }
    };

    if (newQuota !== invitationUrl.quota && newQuotaNotOverMaxParticipants) {
      axios.put(`/invitation_urls/${invitationUrl.id}`, {
        quota: newQuota
      }, getCSRF()).then(() => {
        updateInvitationUrl({ ...invitationUrl, quota: newQuota });
        setIsEditing(false);
        showSuccessMsg('Quota updated successfully');
      }).catch(() => {
        setNewQuota(invitationUrl.quota);  // Restore original quota on error
        setIsEditing(false);
        showSuccessMsg('Failed to update quota');
      });
    } else if (!newQuotaNotOverMaxParticipants) {
      setNewQuota(invitationUrl.quota);  // Restore original quota if invalid
      setIsEditing(false);
      showSuccessMsg(i18n[language].maxInviteesError);
    } else {
      setIsEditing(false);  // No change, exit editing mode
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleQuotaSave();
    }
  };

  const activateOrDeactivateUrl = () => {
    const communityMaxParticipants = community.num_participants;


    if (invitationUrl.status === 'Inactive') {
      if (!quotaReached) {
        handleInvitationUrlUpdate(invitationUrl,'status','Active')
      }
    } else if (invitationUrl.status === 'Active') {
      handleInvitationUrlUpdate(invitationUrl, 'status','Inactive')
    }
  }

  const handleInvitationUrlUpdate = (invitationUrl, attribute, value) => {
    setUpdatingInvitationUrl(true);
    axios.put(`/invitation_urls/${invitationUrl.id}`, {
      invitation_url: {
        [attribute]: value
      }
    }, getCSRF()).then((response) => {
      console.log('response', response)
      updateInvitationUrl({ ...invitationUrl, status: response.data.status });
      showSuccessMsg('Url updated successfully');
      setUpdatingInvitationUrl(false);
    }).catch(() => {
      showSuccessMsg('Failed to update Url');
      setUpdatingInvitationUrl(false);
    });
  }

  const rowStyle = {
    display: 'flex',
    padding: '8px',
    borderBottom: '1px solid #ccc',
    alignItems: 'center',
    fontSize: '12px'
  };

  const cellStyle = {
    padding: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const cellStyleForUrl = {
    padding: '8px',
    overflowX: 'scroll'
  };

  const relevantSegments = segments.filter(segment =>
    invitationUrl.segment_ids.map(id => parseInt(id, 10)).includes(segment.id)
  );

  return (
    <div style={{...rowStyle, textDecoration: invitationUrl.status === "Inactive" || quotaReached ? 'line-through' : 'unset'}}>
      <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip"/>
      <div style={{...cellStyle, flex: 1, maxWidth: '50px', cursor: updatingInvitationUrl ? 'default' : 'pointer', textAlign: 'center'}}
           data-tooltip-content={invitationUrl.status === 'Active' ? i18n[language].disableLink :
             quotaReached ? '' : i18n[language].enableLink}
      >
        <span onClick={ updatingInvitationUrl ? null : activateOrDeactivateUrl}>
          {updatingInvitationUrl ?
            <FontAwesomeIcon icon={['fas', 'spinner']} spin /> :
            <FontAwesomeIcon icon={invitationUrl.status === 'Inactive' || quotaReached ? ['fas', 'lock'] : ['fas', 'unlock']}
            />}
        </span>
      </div>
      <div style={{...cellStyle, flex: 1, maxWidth: '150px', cursor: invitationUrl.status === 'Inactive' ? 'default' : 'pointer', textAlign: 'center'}}
           onClick={invitationUrl.status === 'Inactive' ? null :
             () => navigator.clipboard.writeText(invitationUrl.url).then(() => showSuccessMsg('Copied to Clipboard'))
           }
      >
        {relevantSegments.length === 0 ?
          <span style={{fontSize: '10px', fontFamily: "'Sharp Sans ExtraBold', Helvetica, sans-serif"}}>
            GENERAL
          </span> :

        relevantSegments.map(segment => (
          <span key={segment.id} data-tooltip-content={segment.name}>
            <FontAwesomeIcon icon={['fas', 'bookmark']} color={segment.color} style={{ marginRight: '5px' }} />
          </span>
        ))}
      </div>
      <div style={{...cellStyleForUrl, flex: 3, maxWidth: '300px', cursor: invitationUrl.status === 'Inactive' ? 'default' : 'pointer', textAlign: 'center'}}
           onClick={invitationUrl.status === 'Inactive' ? null :
             () => navigator.clipboard.writeText(invitationUrl.url).then(() => showSuccessMsg('Copied to Clipboard'))
           }
           data-tooltip-content={i18n[language].clickToCopy}
      >
        {invitationUrl.url}
      </div>
      <div style={{
        ...cellStyle, flex: 1, maxWidth: '100px',
        cursor: 'pointer', textAlign: 'center',
        color: quotaReached && 'red'
      }}
           onClick={() => setIsEditing(true)}
           data-tooltip-content={i18n[language].clickToEdit}
      >
        {isEditing ? (
          <input
            type="number"
            value={newQuota}
            onChange={handleQuotaChange}
            onBlur={handleQuotaSave}
            onKeyDown={handleKeyDown}
            style={{width: '100%'}}
            autoFocus
          />
        ) : (
          newQuota
        )}
      </div>
      <div style={{...cellStyle, flex: 1, maxWidth: '100px', textAlign: 'center', color: quotaReached && 'red'}}
           data-tooltip-content={i18n[language].participantsJoinedWithLink}
      >
        {invitationUrl.current_count}
      </div>
      <div style={{...cellStyle, flex: 1, maxWidth: '150px', textAlign: 'center'}}>
        {formatDate(invitationUrl.expires_at || community.participants_end)}
      </div>
      <div style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100px',
        padding: '8px'
      }}>
        {/* QR Code component can be placed here */}
      </div>
    </div>
  );
};

export default InvitationUrlRow
