import PropTypes from 'prop-types';
import React from 'react';
import RSDashboards from "./Search/RSDashboards";
import RSAppDashboard from "./Search/RSAppDashboard";
import RSUserList from "./Search/RSUserList";
import RSActionBuilder from "./Search/RSActionBuilder";

export default class R568Search extends React.Component {
  static propTypes = {
    from: PropTypes.string.isRequired,
    updateSearchTerm: PropTypes.func,
    searchTask: PropTypes.func,
    type: PropTypes.string,
    updateShowModalMobile: PropTypes.func,
    objSearch: PropTypes.object,
    updateShowModal: PropTypes.func,
    executeUpdateModal: PropTypes.func,
    nowFilter: PropTypes.string,
    updateNowFilter: PropTypes.func,
    handleChange: PropTypes.func,
    cuState: PropTypes.string,
    searchTerm: PropTypes.string,
    handleSearch: PropTypes.func,
    role_2: PropTypes.string,
    sortBy: PropTypes.string,
    language: PropTypes.string.isRequired,
    handleChangeFilters: PropTypes.func,
    sort_coll: PropTypes.string,
    handleIsFilterLock: PropTypes.func,
    isFilterLock: PropTypes.bool,
    sort_part: PropTypes.string,
    activeLink: PropTypes.string,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {from, type, role_2, activeLink, language, colors} = this.props;

    let mainComp = null;

    switch(from){
      case 'App Dashboard':
        mainComp = <RSAppDashboard type={type}
                                   updateShowModalMobile={this.props.updateShowModalMobile}
                                   handleChange={this.props.handleChange}
                                   cuState={this.props.cuState}
                                   searchTerm={this.props.searchTerm}
                                   handleSearch={this.props.handleSearch}
                                   language={language}
                                   colors={colors}/>;
        break;
      case 'Activities':
      case 'Participants':
      case 'Now':
        mainComp = <RSDashboards from={from}
                                 activeLink={activeLink}
                                 updateSearchTerm={this.props.updateSearchTerm}
                                 searchTask={this.props.searchTask}
                                 updateShowModalMobile={this.props.updateShowModalMobile}
                                 objSearch={this.props.objSearch}
                                 updateShowModal={this.props.updateShowModal}
                                 executeUpdateModal={this.props.executeUpdateModal}
                                 nowFilter={this.props.nowFilter}
                                 updateNowFilter={this.props.updateNowFilter}
                                 language={language}
                                 colors={colors}/>;
        break;
      case 'Users List':
        mainComp = <RSUserList type={type}
                               role_2={role_2}
                               updateShowModalMobile={this.props.updateShowModalMobile}
                               handleChange={this.props.handleChange}
                               searchTerm={this.props.searchTerm}
                               handleSearch={this.props.handleSearch}
                               sortBy={this.props.sortBy}
                               language={language}
                               colors={colors}/>;
        break;
      case 'Activity Builder':
        mainComp = <RSActionBuilder updateShowModalMobile={this.props.updateShowModalMobile}
                                    handleChangeFilters={this.props.handleChangeFilters}
                                    searchTask={this.props.searchTask}
                                    sort_coll={this.props.sort_coll}
                                    handleIsFilterLock={this.props.handleIsFilterLock}
                                    isFilterLock={this.props.isFilterLock}
                                    sort_part={this.props.sort_part}
                                    language={language}
                                    colors={colors}/>;
        break;
    }

    return (
      <div className="modal-dialog">
        <div className="modal-content">
          { mainComp }
        </div>
      </div>
    );
  }
}
