import ReactOnRails from 'react-on-rails';

import AppDashboardPage from "../bundles/Pixiebob/components/Pages/AppDashboardPage";
import ManagePage from "../bundles/Pixiebob/components/Pages/ManagePage";
import DashboardPage from "../bundles/Pixiebob/components/Pages/Community/DashbordPage";
import UsersListPage from "../bundles/Pixiebob/components/Pages/Community/UsersListPage";
import SettingsPage from "../bundles/Pixiebob/components/Pages/Community/SettingsPage";
import ActivitiesPage from "../bundles/Pixiebob/components/Pages/Community/ActivitiesPage";
import TranscriptPage from "../bundles/Pixiebob/components/Pages/Community/TranscriptPage";
import InviteViaEmailPage from '../bundles/Pixiebob/components/Pages/Community/InviteViaEmailPage';
import WelcomePage from "../bundles/Pixiebob/components/Pages/Community/WelcomePage";

import PCDashboardPage from "../bundles/Pixiebob/components/Pages/Participant/PCDashboardPage";
import PCActivityPage from "../bundles/Pixiebob/components/Pages/Participant/PCActivityPage";
import PCActivitySocialPage from "../bundles/Pixiebob/components/Pages/Participant/PCActivitySocialPage";

import ToPdf from "../bundles/Pixiebob/components/Exports/ToPdf";
import LandingPDF from "../bundles/Pixiebob/components/Exports/LandingPDF";
import UIGuidelines from "../bundles/Pixiebob/components/Pages/UIGuidelines";
import AgoraChatPage from "../bundles/Pixiebob/components/Pages/AgoraChatPage";

import OpenAIPage from "../bundles/Pixiebob/components/Pages/Community/OpenAIPage";
import GenericInvitationLandingPage from "../bundles/Pixiebob/components/Pages/Community/GenericInvitationLandingPage";

// To-Review Why Do I need to put the PDFs in hello-world-bundle?
import InvoicePDF from "../bundles/Pixiebob/components/Invoice/InvoicePDF";
import TermsPDF from "../bundles/Pixiebob/components/Exports/Terms/TermsPDF";
import TDetailTable from "../bundles/Pixiebob/components/Exports/Terms/TDetailTable";
import TExecution from "../bundles/Pixiebob/components/Exports/Terms/TExecution";

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  AppDashboardPage, ManagePage, DashboardPage, UsersListPage, SettingsPage, ActivitiesPage, TranscriptPage,
  InviteViaEmailPage, WelcomePage, PCDashboardPage, PCActivityPage, PCActivitySocialPage, ToPdf, UIGuidelines,
  LandingPDF, InvoicePDF, TermsPDF, TDetailTable, TExecution, AgoraChatPage, OpenAIPage, GenericInvitationLandingPage
});