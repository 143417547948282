import PropTypes from 'prop-types';
import React from 'react';
import moment from "moment";

export default class TExecution extends React.Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired
  }

  render() {
    const {profile, company} = this.props;

    return(
      <React.Fragment>
        <div className="row">
          <div className="col-xs-12">
            <p style={{color:'#f3712e',fontWeight:'bolder'}}>EXECUTION</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <p>
              EXECUTED for and on behalf of <span style={{fontWeight:'bolder'}}>PIXIEBOB PTY LTD (ABN 56 618 250 785)</span>
              &nbsp;by a duly authorised representative:
            </p>

          </div>
          <div className="col-xs-6">
            <p>
              EXECUTED for and on behalf of <span style={{fontWeight:'bolder'}}>{company.billing_name}</span>
              &nbsp;by a duly authorised representative:
            </p>

          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <div style={{borderBottom:'1px solid black', width:'100%', marginTop:'60px'}}/>
            <p style={{marginBottom:'20px'}}>Signature</p>
            <p>Andres Inarritu Lopez</p>
            <div style={{borderBottom:'1px solid black', width:'100%'}}/>
            <p style={{marginBottom:'20px'}}>Name</p>
            <p>{moment().format('DD MMMM YYYY')}</p>
            <div style={{borderBottom:'1px solid black', width:'100%'}}/>
            <p>Date</p>
          </div>
          <div className="col-xs-6">
            <div style={{borderBottom:'1px solid black', width:'100%', marginTop:'60px'}}/>
            <p style={{marginBottom:'20px'}}>Signature</p>
            <p>{profile.first_name} {profile.last_name}</p>
            <div style={{borderBottom:'1px solid black', width:'100%'}}/>
            <p style={{marginBottom:'20px'}}>Name</p>
            <div style={{borderBottom:'1px solid black', width:'100%', marginTop:'60px'}}/>
            <p>Date</p>
          </div>
        </div>
      </React.Fragment>
    )
  }
}