import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactModal from "react-modal";

import {emailValidation, showSuccessMsg} from "../UI/Globals/PX_Funs";

export default class Account extends React.Component {
  static propTypes = {
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    saveAccount: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      currentUsername: props.username,
      username: props.username,
      currentEmail: props.email,
      email: props.email,
      flagUsername: false,
      flagEmail: false,
      newPassword: null,
      rePassword: null,
      flagChangePassword: false,
      showModal: false
    }
  }

  render() {
    const {language, colors} = this.props;

    const i18n = {
      en: {username: 'Username', username_validation: 'Username is ready taken or empty',
        new_password: 'Password (6 characters min.)', retype_password: 'Retype Password',
        email: 'Email', email_validation: 'Email is ready taken, empty or invalid', btn_save: 'Save',
        btn_delete: 'Delete Account...', emailWarning: 'Your email won’t be shared with other participants'},
      es: {username: 'Nombre de Usuario',  username_validation: 'Nombre de usuario ya existe o esta vacio',
        new_password: 'Contraseña (Min. 6 caracteres)', retype_password: 'Re-escriba su contraseña',
        email: 'Correo electrónico', email_validation: 'Email ya esta tomado, esta vacio o invalido', btn_save: 'Guardar',
        btn_delete: 'Borrar Cuenta...', emailWarning: 'Su correo electrónico no será compartido con otros participantes'}
    };

    return (
      <div className="px-settings-container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-card px-card-settings">
              <div className="px-icon-settings">
                <FontAwesomeIcon icon={['fas','user-gear']}/>
              </div>

              <div className="panel-body px-body-padding-settings">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group first">
                      <label htmlFor="username" className="label-sm" style={{color: colors.color1[2]}}>{i18n[language].username}</label>
                      <input name="username" onChange={this.handleChange} value={this.state.username} type="text"
                              className="form-control px-input-settings"/>
                      <span style={{display: this.state.flagUsername ? 'block' : 'none', color:'#f44336', fontSize:'11px'}}>
                        *{i18n[language].username_validation}</span>
                    </div>

                    <div className="form-group">
                      <label htmlFor="email" className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].email}</label>
                      <input name="email" onChange={this.handleChange} value={this.state.email} type="email"
                             className="form-control px-input-settings"/>
                      {
                        this.state.flagEmail ?
                          <span style={{ display: this.state.flagEmail ? 'block' : 'none', color: colors.color4[3], fontSize:'11px'}}>
                        *{i18n[language].email_validation}</span> :
                          <span className="px-label-information" style={{ color: colors.color2[3] }}>{i18n[language].emailWarning}</span>
                      }
                    </div>

                    <div className="form-group">
                      <label htmlFor="newPassword" className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].new_password}</label>
                      <input name="newPassword" id="password" onChange={this.handleInputPasswordChange} type="password"
                             className="form-control px-input-settings" placeholder=""/>
                    </div>

                    <div className="form-group">
                      <label htmlFor="rePassword" className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].retype_password}</label>
                      <input name="rePassword" id="retype_password" onChange={this.handleInputPasswordChange}
                             type="password" className="form-control px-input-settings"/>
                      <span ref="spnValPassword" style={{ display: 'none', color: colors.color4[3], fontSize: '11px'}}/>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-row-buttons-action welcome">
                        <button onClick={this.validation} className="btn px-btn lg btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>
                          {i18n[language].btn_save}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          this.state.showModal &&
          <ReactModal isOpen={this.state.showModal} contentLabel="Manage Modal" shouldCloseOnOverlayClick={true}
                      onRequestClose={this.updateShowModal}  className="px-modal-content" overlayClassName="px-modal-overlay">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header modal-delete-activity">
                  <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                    <i onClick={this.updateShowModal} className="material-icons">clear</i>
                  </button>
                </div>

                <div className="modal-body px-modal-body-warning">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                      <div className="px-title-warning-container">
                        <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning"/>
                        <p className="px-title-warning">Change <span className='warning'>Password?</span></p>
                        <label className="px-message-warning">
                          If you change your password you will must to log in again.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer px-modal-footer-warning">
                  <div className="px-footer-container-buttons column">
                    <button onClick={this.saveNewPassword} type="button"
                            className="btn px-btn sm btn-blue-base">Ok</button>
                    <button onClick={this.updateShowModal} type="button"
                            className="btn px-btn sm btn-gray-darker inverted">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        }
      </div>
    );
  }

  saveNewPassword = () => {
    console.log('Update password and logout');
    $.ajax({
      url: '/users/update_password',
      method: 'PATCH',
      dataType: 'JSON',
      data: { password: this.state.newPassword },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
        $.ajax({
          type: "DELETE",
          url: "/users/sign_out",
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: () => {
            window.location.replace('/users/sign_in');
          }
        })
      }
    });
  };

  updateShowModal = () => {
    this.setState(prevState => ({showModal: !prevState.showModal}));
  };

  handleInputPasswordChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const spnValPassword = this.refs.spnValPassword;

    this.setState({[name]: value}, () => {
      if(this.state.newPassword !== '' && this.state.newPassword.length >= 6) {
        if(this.state.newPassword === this.state.rePassword) {
          this.setState({flagChangePassword: true});
          spnValPassword.style.display = 'none';
        } else {
          this.setState({flagChangePassword: false});
          spnValPassword.textContent = '* Password mismatch';
          spnValPassword.style.display = 'block';
        }
      } else {
        spnValPassword.style.display = 'block';
        this.setState({flagChangePassword: false});
        if(this.state.newPassword === ''){
          spnValPassword.textContent = '* Password is Empty';
        } else {
          spnValPassword.textContent = '* 6 characters minimum';
        }
      }
    });
  };

  validation = () => {
    this.setState({flagUsername: false, flagEmail: false}, () => {
      if(this.state.username !== ''){
        if(this.state.username !== this.state.currentUsername){
          //console.log('Do the Username validation');
          this.validationSave('/users/username_validation', 'username', this.state.username,
            'flagUsername', 'saveUsername');
        } else {
          //console.log('same username');
        }
      } else {
        //console.log('empty username');
        this.setState({flagUsername: true});
      }

      if(this.state.email !== '' && emailValidation(this.state.email)){
        if(this.state.email !== this.state.currentEmail){
          //console.log('Save email');
          this.validationSave('/users/email_validation', 'email', this.state.email,
            'flagEmail', 'saveEmail');
        } else {
          //console.log('same email');
        }
      } else {
        //console.log('empty email');
        this.setState({flagEmail: true});
      }

      if(this.state.flagChangePassword){
        //console.log('Save Password');
        this.setState({showModal: true});
      }
    });
  };

  validationSave(url, name, value, flagName, action){
    //console.log('action:', action);
    $.ajax({
      url: url,
      method: 'GET',
      dataType: 'JSON',
      data: {[name]: value},
      success: obj => {
        //console.log(obj);
        if(!obj.msg) {
          this.setState({[flagName]: false});

          switch(action) {
            case 'saveUsername':
              $.ajax({
                url: '/users/update_username',
                method: 'PATCH',
                dataType: 'JSON',
                data: {username: this.state.username},
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                success: obj => {
                  this.setState({currentUsername: this.state.username});
                  $('#spnUsername').text(obj.username);
                  $('#spnLeftUsername').text(obj.username);

                  showSuccessMsg('Success');
                }
              });
              break;
            case 'saveEmail':
              $.ajax({
                url: '/users/update_email',
                method: 'PATCH',
                dataType: 'JSON',
                data: {email: this.state.email},
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                success: () => {
                  this.setState({currentEmail: this.state.email});
                  showSuccessMsg('Success');
                }
              });
              break;
          }
        } else {
          this.setState({[flagName]: true});
        }
      }
    });
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});
  };
}
