import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dotdotdot from "react-dotdotdot";
import v4 from "uuid";

export default class AEMValidations extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    task: PropTypes.object.isRequired,
    tasks: PropTypes.array,
    confirmUpdateLive: PropTypes.func,
    validations: PropTypes.array,
    questionId: PropTypes.number,
    changeChoiceTypeLive: PropTypes.func,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      dependentsTasks: []
    }
  }

  componentDidMount(){
    if(this.props.tasks){
      this.setState({dependentsTasks: this._showDependentsTasks(this.props.task, this.props.tasks)});
    }
  }

  render() {
    const {task, title, language, colors} = this.props;

    const i18n = {
      en: {
        loTitle1: 'Set ', loTitle2: ' LIVE?', loBody1: 'Participants will see this Activity in their TODO list ' +
          'once it goes LIVE.', loTitle3: 'Switch off \'LIVE\' status in', loBody2: 'Dependent activities:',
        loBody3: 'Participants won\'t see this Activity in their TODO or DONE list anymore.',
        btnYes: 'Yes', btnNo: 'No', cancel: 'Cancel', somethingMissing: 'Something is missing!',
        instructionsEmpty: 'Instructions are empty.', answersWithout: 'Choice answers need', 
        text: 'text.', changeChoice: 'Change choice type from', to: 'to',
        changeChoiceDisclaimer: 'Some participants may have already responded to this Task. If you continue, all existing responses will be deleted.',
        needsTasks: 'needs Tasks to go LIVE', uploadImage: 'Need to upload an image as a canvas'
      },
      es: {
        loTitle1: 'Poner ', loTitle2: ' LIVE?', loBody1: 'Los participantes verán esta Actividad en su lista PENDIENTES ' +
          'una vez que esté \'LIVE.\'', loTitle3: 'Desactivar el estado \'LIVE\' en',
        loBody2: 'Actividades dependientes:',
        loBody3: 'Los participantes no verán más esta Actividad en su lista PENDIENTES o LISTO.', 
        btnYes: 'Sí', btnNo: 'No', cancel: 'Cancelar', somethingMissing: '¡Algo falta!',
        instructionsEmpty: 'Todavía no has escrito las instrucciones.', answersWithout: 'Las opciones de respuesta necesitan',
        text: 'texto.', changeChoice: 'Cambiar el tipo de respuesta de', to: 'a',
        changeChoiceDisclaimer: 'Algunos participantes podrían haber ya respondido esta Tarea. Si haces el cambio, todas las respuestas existentes se borraran.',
        needsTasks: 'necesita tener Tareas para cambiarla a estado \'LIVE\'', uploadImage: 'Sube una imagen para que los participantes anoten en ella'
      }
    };

    let message = null;
    let label = null;
    let labelDepends = null;
    let btnYes = null;
    let btnNo = null;
    let titleValidation = null;

    switch(title){
      case 'choice live':
        const question = this.props.task.questions.find(question => question.id === this.props.questionId);
        const value = question.data.subtype === 'Single' ? 'Multiple' : 'Single';
        titleValidation = <p className="px-title-warning">{i18n[language].changeChoice} {question.data.subtype} {i18n[language].to} {value}?</p>;
        label = <label className="px-message-warning">
          <span>{i18n[language].changeChoiceDisclaimer}</span>
        </label>;
        btnYes = <button onClick={() => this.props.changeChoiceTypeLive(this.props.questionId, value)} type="button"
          className="btn px-btn sm btn-blue-base" style={{backgroundColor: colors.color1[3]}}>{i18n[language].btnYes}</button>;
        btnNo = <button onClick={() => this.props.updateShowModal(null, null)} type="button"
          className="btn px-btn sm btn-gray-darker inverted">{i18n[language].btnNo}</button>;
        break;
      case 'tasks validations':
        titleValidation = <p className="px-title-warning">{i18n[language].somethingMissing}</p>;
        message = this.props.validations.map(validation => {
          return(
            <div  key={v4()}>
              <label style={{ marginBottom: '0', color: '#000', fontSize: '12px' }}>
                {
                  validation.msg === 'Instructions are empty' ?
                  <span><span style={{ fontWeight: 'bold' }}>{i18n[language].instructionsEmpty} </span></span> :
                    validation.msg === 'You have answer(s) without text' ?
                     <span>{i18n[language].answersWithout} <span style={{ fontWeight: 'bold' }}>{i18n[language].text}</span></span> :
                        validation.msg === 'Upload an image' ? <span><span style={{ fontWeight: 'bold' }}>{i18n[language].uploadImage} </span></span> :
                            <span><span style={{ fontWeight: 'bold' }}>""</span></span>
                }
              </label><br/>
            </div>
          )
        });

        btnYes = <button onClick={() => this.props.updateShowModal(null, null)} type="button"
          className="btn px-btn sm btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>OK</button>;
        break;
      case 'taskInstValidation':
        titleValidation = <p className="px-title-warning">{i18n[language].instructionsEmpty}</p>;
        btnYes = <button onClick={() => this.props.updateShowModal(null, null)} type="button"
          className="btn px-btn sm btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>OK</button>;
        break;
      case 'liveValidation':
        titleValidation = <p className="px-title-warning"><span className='warning' style={{ color: colors.color4[3] }}>{task.title} </span>
          {i18n[language].needsTasks}</p>;
        btnYes = <button onClick={() => this.props.updateShowModal(null, null)} type="button"
          className="btn px-btn sm btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>OK</button>;
        break;
      case 'liveOn':
        if(task.visibility === 'disabled'){
          titleValidation = <p className="px-title-warning">{i18n[language].loTitle1}
            <b className="warning" style={{ color: colors.color4[3] }}>{task.title}</b>{i18n[language].loTitle2}</p>;
          label = <label className="px-message-warning">{i18n[language].loBody1}</label>;
        } else {
          titleValidation = <p className="px-title-warning">{i18n[language].loTitle3}
           <b className="warning" style={{color:colors.color4[3]}}> {task.title}</b></p>;
          labelDepends = this.state.dependentsTasks.length !== 0 &&
            <label className="px-message-warning" style={{marginBottom:'10px'}}>{i18n[language].loBody2}</label>;
          label = <label className="px-message-warning" style={{marginBottom: labelDepends && '15px'}}>{i18n[language].loBody3}</label>;
        }

        btnYes = <button onClick={this.props.confirmUpdateLive} type="button"
          className="btn px-btn sm btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{i18n[language].btnYes}</button>;
        btnNo = <button onClick={() => this.props.updateShowModal(null, null)} type="button"
                        className="btn px-btn sm btn-gray-darker inverted">{i18n[language].cancel}</button>;
        break;
    }

    return (
      <div className="modal-content">
        <div className="modal-header modal-delete-activity">
          <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal" onClick={() => this.props.updateShowModal(null, null)}>
            <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
          </button>
        </div>

        <div className="modal-body px-modal-body-warning">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
            <div className="px-title-warning-container">
              <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning" style={{color: colors.color4[3]}}/>            
                { titleValidation }
                { message }
                { label }
                { labelDepends }
            </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
              {
                this.state.dependentsTasks.length !== 0 &&
                  this.state.dependentsTasks.map(task => {
                    return(
                      <p key={task.id} style={{fontSize:'12px',fontWeight:'normal',marginBottom:'0',color:"#262337"}}>{task.title}</p>
                    )
                  })
              }
            </div>
          </div>
        </div>

        <div className="modal-footer px-modal-footer-warning">
          <div className="px-footer-container-buttons column">
            {btnYes}
            {btnNo}
          </div>
        </div>
      </div>
    );
  }

  _showDependentsTasks(taskR, tasks){
    let dependents = [];

    tasks.forEach(task => {
      if(task.required){
        if(task.required.value === taskR.id){
          dependents.push(task);
        }
      }
    });

    return dependents;
  }
}