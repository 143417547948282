import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from 'axios';
import {showSuccessMsg} from "../../UI/Globals/PX_Funs";
import EmptyDashImageModal from "../EmptyDashImageModal";
import { Tooltip as ReactTooltip } from "react-tooltip"


export default class CSParticipantDashboard extends React.Component {
    static propTypes = {
        community: PropTypes.object.isRequired,
        language: PropTypes.string.isRequired,
        colors: PropTypes.object.isRequired,
        handleChange: PropTypes.func.isRequired,
        saveCommunity: PropTypes.func.isRequired,
        updateEmptyDashboardImage: PropTypes.func.isRequired,
        removeEmptyDashboardImg: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isModal: false
        }
    }

    render() {
        const {language, colors, community} = this.props;
        const i18n = {
            en: {noActivityMsg: 'Empty dashboard message', noActivityImg: 'Image',
                save: 'Save', removeImage: 'remove image',
                placeholder: 'There\'s no activities available to complete at this time. You\'ll be notified when new activities become available',
                info: 'This message and image will be displayed when there are no activities available in the Participants Dashboard.'},
            es: {noActivityMsg: 'Mensaje para panel vacío', noActivityImg: 'Imagen',
                save: 'Guardar', removeImage: 'remover imagen',
                placeholder: 'No hay actividades disponibles para completar en este momento. Se te notificará cuando haya nuevas actividades disponibles.',
                info: 'Este mensaje e imagen se mostrarán cuando no haya actividades disponibles en el panel de los participantes.'}
        }

        let modalImage;
        if(this.state.isModal){
            modalImage = <EmptyDashImageModal objectId={this.props.community.id}
                                              url={this.props.community.empty_dashboard_img}
                                              handleToggle={this.handleToggle}
                                              updateEmptyDashboardImage={this.props.updateEmptyDashboardImage}
                                              colors={colors}
                                              language={language}/>;
        }

        return(
            <div id="divParticipants" className="manage">
                <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
                { modalImage }
                <div className="px-settings-container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="px-card px-card-settings">
                                <div className="px-icon-settings ">
                                    <FontAwesomeIcon icon={['fas','user-group']}/>
                                </div>
                                <div className="panel-body px-body-padding-settings">
                                    <div className="container-fluid">
                                        <div className="row px-container-general-settings">
                                            <div className="px-settings-inner-container">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="form-group first">
                                                            <span className="label-sm" style={{color: colors.color1[2]}}>{i18n[language].noActivityMsg}</span>
                                                            <FontAwesomeIcon icon={['fal','circle-info']} style={{fontSize: '12px', marginLeft: '10px'}} data-tooltip-content={i18n[language].info}/>
                                                            <textarea name={community.language === 'es' ? 'empty_dashboard_msg_es' : 'empty_dashboard_msg'} id="message" defaultValue={community.language === 'es' ? community.empty_dashboard_msg_es : community.empty_dashboard_msg}
                                                                  cols="30" rows="5" className="px-bio-text-area" onChange={this.props.handleChange} placeholder={community.language === 'es' ? i18n['es'].placeholder : i18n['en'].placeholder}/>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-image-container text-center">
                                                <div className="form-group first">
                                                    <span className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].noActivityImg}</span>
                                                    {
                                                        (community.empty_dashboard_img === ""  || community.empty_dashboard_img === null) ?
                                                            <span className="px-thumb-100 img-responsive"
                                                                  style={{fontSize: '10px', fontFamily: 'Sharp Sans ExtraBold', paddingTop: '30px'}}
                                                                  onClick={this.handleToggle}>
                                                                    NO IMAGE
                                                            </span> :
                                                            <img  src={community.empty_dashboard_img} onClick={this.handleToggle}
                                                                  className="px-thumb-100 img-responsive" alt=""/>
                                                    }
                                                    <p className="px-label-information"
                                                       style={{ cursor: 'pointer', color: 'rgb(175, 189, 193)', textAlign: 'center'}}
                                                       onClick={this.props.removeEmptyDashboardImg}>
                                                        {i18n[language].removeImage}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action general">
                                            <button className="btn px-btn lg btn-blue-base"
                                                    onClick={this.props.saveCommunity}
                                                    name={community.language === 'es' ? 'empty_dashboard_msg_es' : 'empty_dashboard_msg'}
                                                    style={{backgroundColor: colors.color1[3]}}>{i18n[language].save}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )

    }

    handleToggle = () => {
        this.setState(prevState => ({ isModal: !prevState.isModal }));
    };

}
