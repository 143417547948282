/*** TEMP SPECIAL Community > Settings > Notifications ***/
const settingsNotI18n = {
  es: {
    "About you": {
      "Private messages sent to you": "Mensajes privados que te han enviado",
      "Comments or replies that may be addressed to you": "Comentarios que pueden tener que ver contigo",
      "Comments or replies posted in threads you\'ve interacted with": "Comentarios publicados en hilos en los que has interactuado",
      "\"Likes\" to your responses": "Han dado \"Me gusta\" a tu respuesta",
      "\"Likes\" to your comments": "Han dado \"Me gusta\" a tu comentario",
      "Somebody viewed your profile": "Alguien vio tu perfil"
    },
    "About people you follow": {
      "Responded to an activity": "Respondió a una actividad",
      "\"Likes\" to their responses": "Han dado \"Me gusta\" a su respuesta",
      "\"Likes\" to their comments": "Han dado \"Me gusta\" a su comentario"
    },
    "About the community": {
      "A user has been invited": "Un usuario ha sido invitado",
      "A user has declined an invitation": "Un usuario ha rechazado una invitación",
      "A user has been blocked": "Un usuario ha sido bloqueado",
      "A user has been removed": "Un usuario ha sido removido",
      "A user has joined the community": "Un usuario se ha unido a la comunidad"
    },
    "About activities": {
      "An activity has become available": "Una Actividad nueva está disponible",
      "An activity is about to expire": "Una Actividad está a punto de expirar",
      "An activity has expired": "Una Actividad ha expirado",
      "A participant has completed an Activity": "Un participante ha completado una Actividad",
      "A participant has drafted a Task": "Un participante ha guardado una respuesta (borrador)"
    },
    "Community allowances": {
      "Participant /days almost over (25% left)": "Tu cuota de Días*Participante está cerca de acabarse (queda 25%)",
      "Participant /days limit has been reached": "Has llegado al límite de tu cuota de Días*Participante",
      "Video recording allowance almost over (25% left)": "Tu cuota de grabación de video está cerca de acabarse (queda 25%)",
      "Video recording limit has been reached": "Has llegado al límite de tu cuota de grabación de video"
    },
    "Messages": {
      "Private messages sent to you": "Mensajes privados que te han enviado"
    },
    "Notifications by email": {
      "Daily digest": "Resumen diario",
      "Comments or replies that may be addressed to you": "Comentarios que pueden tener que ver contigo",
      "Comments or replies posted in threads you\'ve interacted with": "Comentarios publicados en hilos en los que has interactuado",
      "A user has declined an invitation": "Un usuario ha rechazado una invitación",
      "An activity has become available": "Una Actividad nueva está disponible",
      "An activity is about to expire": "Una Actividad está a punto de expirar",
      "An activity has expired": "Una Actividad ha expirado",
      "A participant has completed an Activity": "Un participante ha completado una actividad",
      "Participant /days almost over (25% left)": "Tu cuota de Días*Participante está cerca de acabarse (queda 25%)",
      "Participant /days limit has been reached": "Has llegado al límite de tu cuota de Días*Participante",
      "Video recording allowance almost over (25% left)": "Tu cuota de grabación de video está cerca de acabarse (queda 25%)",
      "Video recording limit has been reached": "Has llegado al límite de tu cuota de grabación de video"
    }
  }
};

/*** Right Drawer > Notifications ***/
const notifications_i18n = {
  en: {
    lbl_notification: 'Notifications', today: 'Recent', old: 'Old', commented_on: 'commented on ',
    reply_on: 'replied a comment on ', sent_pm: 'sent you a private message ', updated_response: 'updated ',
    liked_01: 'liked a ', liked_02: ' on ', no_new_notifications: 'No notifications here. Check in ',
    user_invited: 'has been invited', user_joined: 'has joined!', user_declined: 'has declined an invitation',
    user_blocked: 'has been blocked', user_removed: 'has been removed', activity_available: 'New activity available!',
    activity_about_exp: 'An activity is about to expire!', activity_expired: 'An activity has expired!',
    task_drafted: 'drafted the task ', activity_completed: 'completed the activity',
    like_ur_response: 'liked your response to', like_ur_comment: 'liked your comment', saw_profile: 'saw your',
    follow_response: 'responded to', like_response_follow_1: 'liked', like_response_follow_2: 'response to',
    like_comment_follow: 'comment', you_have: 'You have', unread_comments: 'unread comments',
    multi_unread_msg: ' Follow the alerts for unread comments shown in your community dashboard.',
    moderator: 'Moderator', neverExpires: 'Never Expires', expires: 'Expires', profile: 'profile', expired: 'Expired'
  },
  es: {
    lbl_notification: 'Notificaciones', today: 'Recientes', old: 'Antiguas', commented_on: 'comentó en la tarea ',
    reply_on: 'respondió un comentario en ', sent_pm: 'te envió mensaje privado ', updated_response: 'actualizó ',
    liked_01: 'le gustó ', liked_02: ' en ', no_new_notifications: 'No hay notificaciones aquí. Revisa en ',
    user_invited: 'ha sido invitado', user_joined: 'se ha unido!', user_declined: 'ha declinado la invitación',
    user_blocked: 'ha sido bloqueado', user_removed: 'ha sido removido', activity_available: 'Nueva actividad disponible!',
    activity_about_exp: 'Una actividad está por expirar!', activity_expired: 'Una actividad ha expirado!',
    task_drafted: 'redactó la tarea ', activity_completed: 'completó la actividad',
    like_ur_response: 'le gustó tu respuesta a', like_ur_comment: 'le gustó tu comentario', saw_profile: 'vio tu',
    follow_response: 'respondió a', like_response_follow_1: 'le gustó', like_response_follow_2: 'respuesta a',
    like_comment_follow: 'comentario', you_have: 'Tienes', unread_comments: 'comentarios sin leer',
    multi_unread_msg: ' Sigue las alertas de comentarios no leídos en el dashboard de tu comunidad.',
    moderator: 'Moderador', neverExpires: 'Nunca Expira', expires: 'Expira', profile: 'perfil', expired: 'Expiró'
  }
};

/*** Modal > Video ***/
const modalVideoi18n = {
  en: {
    ok: 'Ok', cancel: 'Cancel', close: 'Close'
  },
  es: {
    ok: 'Ok', cancel: 'Cancelar', close: 'Cerrar'
  }
};

/*** Participant > Activity > Task ***/
const taskI18n = {
  en: {
    images_lbl: 'DROP IMAGES OR CLICK TO UPLOAD.', images_lbl_responsive:'UPLOAD IMAGE' ,video_lbl: '',
    save_btn: 'Save', complete_btn: 'Done!', complete_btn_social: 'Post!', edit_btn: 'Edit...', response_complete_01: 'You have',
    response_complete_02: 'answered', response_complete_03: 'this question', plc_editor_01: 'At least ',
    plc_editor_02: ' chars...', delete: 'Delete', add: 'Add',
    saveHelp1: 'Have you finished?', saveHelp2: "Consider clicking the 'Done!' button instead. It'll save your answer and notify us.",
    saveHelp2Social: "Consider clicking the 'Done!' button instead. It'll save your answer and let all the community know.",
    dontShowAgain: 'Don\'t show this message again'
  },
  es: {
    images_lbl: 'Arrastra o haz click para subir imágenes',images_lbl_responsive:'SUBIR IMAGEN', video_lbl: '',
    save_btn: 'Guardar', complete_btn: '¡Terminé!', complete_btn_social: 'Publicar!', edit_btn: 'Editar...', response_complete_01: 'Haz',
    response_complete_02: 'respondido', response_complete_03: 'esta tarea', plc_editor_01: 'Al menos ',
    plc_editor_02: ' caracteres', delete: 'Borrar', add: 'Agregar',
    saveHelp1: '¿Acabaste?', saveHelp2: "Considera dar clic al botón '¡Terminé!'. Guardará tu respuesta y nos avisará.",
    saveHelp2Social: "Considera dar clic al botón '¡Terminé!'. Guardará tu respuesta y avisará a toda la comunidad.",
    dontShowAgain: 'No volver a mostrar este mensaje'
  }
};

/** Globals > PX_Funs */
const miscI18n = {
  en: {
    expired: 'Expired', expires: 'Expires', neverExpires: 'Never expires'
  },
  es: {
    expired: 'Expiró', expires: 'Expira', neverExpires: 'Nunca expira'
  }
} 


export { notifications_i18n, modalVideoi18n, taskI18n, settingsNotI18n, miscI18n}