import PropTypes from 'prop-types';
import React from 'react';
import moment from "moment";
import axios from "axios";

import ImageModal from "../../MyProfile/ImageModal";
import ModalQuote from "./ModalQuote";

const maxNumParts = 101;

export default class ModalAddCommunity extends React.Component {
  static propTypes = {
    maxNumCommunities: PropTypes.number.isRequired,
    communitiesLength: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    profile: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    papt: PropTypes.number,
    subscription: PropTypes.object
  };

  constructor(props) {
    super(props);
    const defaultAvatar = 'dc'+ (Math.floor(Math.random() * 6) + 1) +'.png';

    this.state = {
      flagTitle: false,
      isModal: false,
      previewImage: '/assets/community/' + defaultAvatar,
      defaultAvatarUrl: 'https://dev-pixiebob.s3-ap-southeast-2.amazonaws.com/default/community/' + defaultAvatar,
      blob: null,
      subcategories: [],
      category: '',
      subcategory: '',
      flagCategory: false,
      blocking: false,
      cmtyLang: '',
      flagCmtyLang: false,
      showQuote: false,
      num_participants: 0,
      participants_start: null,
      participants_end: null,
      platform: '',
      title: '',
      subs: '',
      cash: '',
      sumSubs: 0,
      sumCash: 0,
      loading: false,
      estimated_cost: 0,
      priceUnitPD: 0,
      default_tz_flag: true,
      default_tz: '',
      flagNumParts: false,
      calculatorFlag: 0,
      aiPlan: ''
    };
  }

  componentDidMount() {
    this.setState({showQuote: false});
  }

  render() {
    /*
    Dictionary:

    totalPD: total participant/days (num_participants * days) in the new community being quoted
    estimated_cost: comes form the pricing table considering total_days (end_date - start_date) and num_participants
    papt: Participants slots, the number of Active, Invited and Blocked participants covered by a subscription meaning they are allowed to have for free at the same time, across all active communities.
    daysView: an array with where each item is a day of an active community or multiple active communities occurring in a date range.
              each object contains:
              sum_num_parts_date: the sum of all expected participants (across relevant communities) in that given day
              com_intersections_length: the number of enabled communities that overlap within that day.
    locked: suma de todos los participantes esperados para un día considerando todas las comunidades activas dicho día.
    num_participants: number of participants being quoted for a new community
    free: the number of participants PER DAY covered by a subscription -  can be accommodated in a day within a new community that's being quoted (this happens when the papt - locked days > num_participants)
          if a company doesn't have papt (i.e. no active subscription) this will always be zero
    cash: the number of participants PER DAY that are not covered by a subscription (num_participants - free)
    sumCash: This is essentially a P*D metric as it is the sum of all participants that are not covered by a subscription across all days of the new community being quoted
    sumSubs: This is essentially a P*D metric as it is the sum of all participant that will be covered by a subscription across all days of the new community being quoted
    initCashPD: all participant days for the quoted community minus the sumSubs (i.e. all participant days that are not covered by subscription)
    initFlexDaysPD: Days for the quoted community that will incur charges - all participant days not covered by a subscription minus all participant days covered by accumulated Flexdays in an account
    flexDaysPD: how many "flex days" are used for the new community being quoted, based on the participant days not covered by a subscription
    finalSumCashPD: the remaining participant days after using the available "flex days" in the subscription that will still incur charges.
    finalCashPD: all participant days not covered by a subs minus all flex days available in the subscription
    remainingCash: All participant days not covered by a subs multiplied by the price per day
    totalAiPrice: total price for AI plan considering all num_participants regardless how many are covered by flex or subs
     */


    const {language, papt, colors, companyId, subscription, company} = this.props;

    const i18n = {
      en: {
        setUp: 'Create your community', cmntyTitle: 'Community name', titleWarning: 'Participants will see this name.', 
        cmntyAvatar: 'Community avatar', avatarWarning: 'You can later adjust this information in the community settings.',
        category: 'Category', select: 'Select...', government: 'Government', media: 'Media', b2b: 'B2B', education: 'Education',
        foodNdrinks: 'Food & Drinks', utilities: 'Utilities', shopping: 'Shopping', hospitality: 'Hospitality', transport: 'Transport',
        fashion: 'Fashion', travel: 'Travel', healthNwellbeing: 'Health & Wellbeing', pets: 'Pets', technology: 'Technology',
        financial: 'Financial', sports: 'Sports', beautyNHigene: 'Beauty & Higene', lifestyle: 'Lifestyle', housing: 'Housing',
        childrenNparenting: 'Children and Parenting', other: 'Other', subCategory: 'Sub-Category', create: 'Create', close: 'Close',
        cmtyLanguage: 'Participants speak', optEn: 'English', optEs: 'Spanish', cmtyLangWarning: 'You must select a language',
        next: 'Next', localTiming: 'Use local Activity timing',
        localTimingInfo: 'Activities start and expire at participants\' local time.',
        activityTimeZoneInfo: 'Activities start and expire at the \'Activity ruling time zone\'.',
        activityTimeZone: 'Activity ruling time zone',
        activityTimeZoneWarning: "Time Zone used to time activities if 'Use Local Activity Timing' is off"
      },
      es: {
        setUp: 'Crea tu comunidad', cmntyTitle: 'Nombre de la comunidad', titleWarning: 'Los participantes verán este nombre.',
        cmntyAvatar: 'Avatar de la comunidad', avatarWarning: 'Podrás ajustar esta información, en cualquier momento, desde la configuración de la comunidad.',
        category: 'Categoría', select: 'Seleccionar...', government: 'Gobierno', media: 'Medios de comunicación', b2b: 'B2B',
        education: 'Educación', foodNdrinks: 'Alimentos & Bebidas', utilities: 'Servicios públicos', shopping: 'Experiencia de compra',
        hospitality: 'Hospitalidad', transport: 'Transporte', fashion: 'Moda', travel: 'Viajes', healthNwellbeing: 'Salud y Bienestar',
        pets: 'Mascotas', technology: 'Tecnología', financial: 'Finanzas', sports: 'Deporte', beautyNHigene: 'Higiene y Belleza',
        lifestyle: 'Estilo de vida', housing: 'Vivienda', childrenNparenting: 'Niñez y paternidad', other: 'Otro',
        subCategory: 'Subcategoría', create: 'Crear', close: 'Cerrar', cmtyLanguage: 'Los participantes hablan', optEn: 'Inglés',
        optEs: 'Español', cmtyLangWarning: 'Debes seleccionar un idioma', next: 'Siguiente',
        localTiming: 'Usar Zona horaria del participante',
        localTimingInfo: 'Las actividades se muestran usando la Zona Horaria de los participantes.',
        activityTimeZoneInfo: 'Las actividades se muestran usando el ajust \'Zona horaria para Actividades\'.',
        activityTimeZone: 'Zona horaria para Actividades',
        activityTimeZoneWarning: "Zona horaria usada para mostrar Actividades si 'Usar Zona horaria del participante' está apagado."
      }
    };

    let modalCommunityImage;
    if(this.state.isModal){
      modalCommunityImage = <ImageModal objectId={-1}
                                        url={this.state.defaultAvatarUrl}
                                        handleToggle={this.handleToggle}
                                        updateAvatar={this.updateAvatar}
                                        updateCommunityAvatar={this.updateCommunityAvatar}
                                        language={language}
                                        colors={colors}/>;
    }

    const subCategory = this.state.subcategories.map((sc, index) => { return <option key={index}>{sc}</option>});

    return (
      <div className="modal modal-activity fade" id="modalAddCommunity" tabIndex="-1" role="dialog"
           aria-labelledby="myModalLabel" aria-hidden="true">
        { modalCommunityImage }

        <div className="modal-dialog">
          {
            this.state.showQuote ?
              <ModalQuote addCommunity={this.addCommunity}
                          language={language}
                          updateShowQuote={this.updateShowQuote}
                          setFalseToShowQuote={this.setFalseToShowQuote}
                          num_participants={this.state.num_participants}
                          onBlur={this.onBlur}
                          getCost={this.getCost}
                          participants_start={this.state.participants_start}
                          participants_end={this.state.participants_end}
                          updateDates={this.updateDates}
                          platform={this.state.platform}
                          blocking={this.state.blocking}
                          subs={this.state.subs}
                          cash={this.state.cash}
                          papt={papt}
                          sumSubs={this.state.sumSubs}
                          sumCash={this.state.sumCash}
                          loading={this.state.loading}
                          estimated_cost={this.state.estimated_cost}
                          priceUnitPD={this.state.priceUnitPD}
                          colors={colors}
                          flagNumParts={this.state.flagNumParts}
                          subscription={subscription}
                          company={company}
                          default_tz_flag={this.state.default_tz_flag}
                          default_tz={this.state.default_tz}
                          createTermsPDF={this.createTermsPDF}
                          from={'AddCommunity'}
                          handleNumParts={this.handleNumParts}
                          calculatorFlag={this.state.calculatorFlag}
                          changeAiPlanState={this.changeAiPlanState}/> :
              <div className="modal-content">
                <div className="modal-header">
                  <button onClick={this.setFalseToShowQuote} type="button"
                          className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal" data-dismiss="modal"
                          aria-hidden="true">
                    <i className="material-icons">clear</i>
                  </button>
                  <h4 className="px-modal-title">{i18n[language].setUp}</h4>
                </div>
                <div className="modal-body px-modal-body-add-activity">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
                      <div id="divModalAddCommunityTitle" className="form-group first">
                        <label className="label-sm" style={{color: colors.color1[2]}}>{i18n[language].cmntyTitle}</label>
                        <input name="title" ref="txtCommunityTitle" onChange={this.handleChange} onBlur={this.validateTitle}
                               value={this.state.title} type="text" className="form-control"/>
                        <span className="form-control-feedback"><i className="material-icons">done</i></span>
                        <span id="spnModalAddTitle" className='px-label-information' style={{fontSize:'11px', color: colors.color2[3]}}>
                            {i18n[language].titleWarning}
                        </span>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].cmtyLanguage}</label>
                            <select name="cmtyLang" onChange={this.handleChange} value={this.state.cmtyLang} className="form-control">
                              <option value="">{i18n[language].select}</option>
                              <option value="en">{i18n[language].optEn}</option>
                              <option value="es">{i18n[language].optEs}</option>
                            </select>
                            {
                              !this.state.flagCmtyLang &&
                              <span id="spnCmtyLang" className='px-label-warning' style={{fontSize:'11px', color: colors.color4[3]}}>
                              {i18n[language].cmtyLangWarning}
                            </span>
                            }
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group first-level">
                            <label className="label-sm" style={{ color: colors.color1[2] }}>{this.state.default_tz_flag ?
                                i18n[language].localTiming : i18n[language].localTiming }</label>
                            <br/>
                            <label className="px-switch" htmlFor="timezoneFlag" style={{marginTop:'5px'}}>
                              <input name="default_tz_flag" id="timezoneFlag" type="checkbox"
                                     onChange={this.handleChange} checked={this.state.default_tz_flag}/>
                              <div className="slider round"
                                   style={this.state.default_tz_flag ? {backgroundColor: colors.color3[3]} : {}}/>
                            </label>
                            <p className="px-label-information">
                              {this.state.default_tz_flag ? i18n[language].localTimingInfo : i18n[language].activityTimeZoneInfo}
                            </p>
                          </div>
                        </div>
                        {
                          !this.state.default_tz_flag &&
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <span className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].activityTimeZone}</span>
                              <select name="default_tz" onChange={this.handleChange} value={this.state.default_tz || ''}
                                      className="form-control px-input-settings">
                                <option value=''>Select...</option>
                                <option value="(GMT-12:00) International Date Line West">(GMT-12:00) International Date Line West</option>
                                <option value="(GMT-11:00) Midway Island, Samoa">(GMT-11:00) Midway Island, Samoa</option>
                                <option value="(GMT-10:00) Hawaii">(GMT-10:00) Hawaii</option>
                                <option value="(GMT-09:00) Alaska">(GMT-09:00) Alaska</option>
                                <option value="(GMT-08:00) Pacific Time (US & Canada)">(GMT-08:00) Pacific Time (US & Canada)</option>
                                <option value="(GMT-08:00) Tijuana, Baja California">(GMT-08:00) Tijuana, Baja California</option>
                                <option value="(GMT-07:00) Arizona">(GMT-07:00) Arizona</option>
                                <option value="(GMT-07:00) Chihuahua, La Paz, Mazatlan">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                <option value="(GMT-07:00) Mountain Time (US & Canada)">(GMT-07:00) Mountain Time (US & Canada)</option>
                                <option value="(GMT-06:00) Central America">(GMT-06:00) Central America</option>
                                <option value="(GMT-06:00) Central Time (US & Canada)">(GMT-06:00) Central Time (US & Canada)</option>
                                <option value="(GMT-06:00) Guadalajara, Mexico City, Monterrey">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                <option value="(GMT-06:00) Saskatchewan">(GMT-06:00) Saskatchewan</option>
                                <option value="(GMT-05:00) Bogota, Quito, Rio Branco">(GMT-05:00) Bogota, Quito, Rio Branco</option>
                                <option value="(GMT-05:00) Lima">(GMT-05:00) Lima</option>
                                <option value="(GMT-05:00) Eastern Time (US & Canada)">(GMT-05:00) Eastern Time (US & Canada)</option>
                                <option value="(GMT-05:00) Indiana (East)">(GMT-05:00) Indiana (East)</option>
                                <option value="(GMT-04:00) Atlantic Time (Canada)">(GMT-04:00) Atlantic Time (Canada)</option>
                                <option value="(GMT-04:00) Caracas, La Paz">(GMT-04:00) Caracas, La Paz</option>
                                <option value="(GMT-04:00) Manaus">(GMT-04:00) Manaus</option>
                                <option value="(GMT-04:00) Santiago">(GMT-04:00) Santiago</option>
                                <option value="(GMT-03:30) Newfoundland">(GMT-03:30) Newfoundland</option>
                                <option value="(GMT-03:00) Brasilia">(GMT-03:00) Brasilia</option>
                                <option value="(GMT-03:00) Buenos Aires, Georgetown">(GMT-03:00) Buenos Aires, Georgetown</option>
                                <option value="(GMT-03:00) Greenland">(GMT-03:00) Greenland</option>
                                <option value="(GMT-03:00) Montevideo">(GMT-03:00) Montevideo</option>
                                <option value="(GMT-02:00) Mid-Atlantic">(GMT-02:00) Mid-Atlantic</option>
                                <option value="(GMT-01:00) Cape Verde Is.">(GMT-01:00) Cape Verde Is.</option>
                                <option value="(GMT-01:00) Azores">(GMT-01:00) Azores</option>
                                <option value="(GMT+00:00) Casablanca, Monrovia, Reykjavik">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                                <option value="(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                                <option value="(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                <option value="(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                <option value="(GMT+01:00) Brussels, Copenhagen, Madrid, Paris">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                <option value="(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                                <option value="(GMT+01:00) West Central Africa">(GMT+01:00) West Central Africa</option>
                                <option value="(GMT+02:00) Amman">(GMT+02:00) Amman</option>
                                <option value="(GMT+02:00) Athens, Bucharest, Istanbul">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                                <option value="(GMT+02:00) Beirut">(GMT+02:00) Beirut</option>
                                <option value="(GMT+02:00) Cairo">(GMT+02:00) Cairo</option>
                                <option value="(GMT+02:00) Harare, Pretoria">(GMT+02:00) Harare, Pretoria</option>
                                <option value="(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                                <option value="(GMT+02:00) Jerusalem">(GMT+02:00) Jerusalem</option>
                                <option value="(GMT+02:00) Minsk">(GMT+02:00) Minsk</option>
                                <option value="(GMT+02:00) Windhoek">(GMT+02:00) Windhoek</option>
                                <option value="(GMT+03:00) Kuwait, Riyadh, Baghdad">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                                <option value="(GMT+03:00) Moscow, St. Petersburg, Volgograd">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                <option value="(GMT+03:00) Nairobi">(GMT+03:00) Nairobi</option>
                                <option value="(GMT+03:00) Tbilisi">(GMT+03:00) Tbilisi</option>
                                <option value="(GMT+03:30) Tehran">(GMT+03:30) Tehran</option>
                                <option value="(GMT+04:00) Abu Dhabi, Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                                <option value="(GMT+04:00) Baku">(GMT+04:00) Baku</option>
                                <option value="(GMT+04:00) Yerevan">(GMT+04:00) Yerevan</option>
                                <option value="(GMT+04:30) Kabul">(GMT+04:30) Kabul</option>
                                <option value="(GMT+05:00) Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                                <option value="(GMT+05:00) Islamabad, Karachi, Tashkent">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                                <option value="(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                <option value="(GMT+05:30) Sri Jayawardenapura">(GMT+05:30) Sri Jayawardenapura</option>
                                <option value="(GMT+05:45) Kathmandu">(GMT+05:45) Kathmandu</option>
                                <option value="(GMT+06:00) Almaty, Novosibirsk">(GMT+06:00) Almaty, Novosibirsk</option>
                                <option value="(GMT+06:00) Astana, Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                <option value="(GMT+06:30) Yangon (Rangoon)">(GMT+06:30) Yangon (Rangoon)</option>
                                <option value="(GMT+07:00) Bangkok, Hanoi, Jakarta">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                <option value="(GMT+07:00) Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                <option value="(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                <option value="(GMT+08:00) Kuala Lumpur, Singapore">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                <option value="(GMT+08:00) Irkutsk, Ulaan Bataar">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                <option value="(GMT+08:00) Perth">(GMT+08:00) Perth</option>
                                <option value="(GMT+08:00) Taipei">(GMT+08:00) Taipei</option>
                                <option value="(GMT+09:00) Osaka, Sapporo, Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                <option value="(GMT+09:00) Seoul">(GMT+09:00) Seoul</option>
                                <option value="(GMT+09:00) Yakutsk">(GMT+09:00) Yakutsk</option>
                                <option value="(GMT+09:30) Adelaide">(GMT+09:30) Adelaide</option>
                                <option value="(GMT+09:30) Darwin">(GMT+09:30) Darwin</option>
                                <option value="(GMT+10:00) Brisbane">(GMT+10:00) Brisbane</option>
                                <option value="(GMT+10:00) Canberra, Melbourne">(GMT+10:00) Canberra, Melbourne</option>
                                <option value="(GMT+10:00) Hobart">(GMT+10:00) Hobart</option>
                                <option value="(GMT+10:00) Sydney">(GMT+10:00) Sydney</option>
                                <option value="(GMT+10:00) Guam, Port Moresby">(GMT+10:00) Guam, Port Moresby</option>
                                <option value="(GMT+10:00) Vladivostok">(GMT+10:00) Vladivostok</option>
                                <option value="(GMT+11:00) Magadan, Solomon Is., New Caledonia">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                                <option value="(GMT+12:00) Auckland, Wellington">(GMT+12:00) Auckland, Wellington</option>
                                <option value="(GMT+12:00) Fiji, Kamchatka, Marshall Is.">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                <option value="(GMT+13:00) Nuku'alofa">(GMT+13:00) Nuku'alofa</option>
                              </select>
                            </div>
                            <p className="px-label-information">{i18n[language].activityTimeZoneWarning} </p>
                          </div>
                        }
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                            <label className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].cmntyAvatar}</label>
                            <img onClick={this.handleToggle} src={this.state.previewImage} ref="imgPreview"
                                 className="img-rounded img-responsive" style={{cursor: 'pointer'}} alt=""/>
                          </div>
                          <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                            <div style={{display:'flex',justifyItems:'center',flexDirection:'column'}}>
                              {/* <label style={{marginTop: '30px', fontSize: '12px'}}>GIF, JPEG, PNG. 2Mb max.</label>
                            <label style={{ fontSize: '12px' }}>{i18n[language].avatarWarning}</label> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mg-bottom-15">
                          <div className="form-group" >
                            <label className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].category}</label>
                            <select name="category" onChange={this.handleCategory} value={this.state.category} className="form-control">
                              <option value="">{i18n[language].select}</option>
                              <option value="Government">{i18n[language].government}</option>
                              <option value="Media">{i18n[language].media}</option>
                              <option value="B2B">{i18n[language].b2b}</option>
                              <option value="Education">{i18n[language].education}</option>
                              <option value="Food & Drinks">{i18n[language].foodNdrinks}</option>
                              <option value="Utilities">{i18n[language].utilities}</option>
                              <option value="Shopping">{i18n[language].shopping}</option>
                              <option value="Hospitality">{i18n[language].hospitality}</option>
                              <option value="Transport">{i18n[language].transport}</option>
                              <option value="Fashion">{i18n[language].fashion}</option>
                              <option value="Travel">{i18n[language].travel}</option>
                              <option value="Health & Wellbeing">{i18n[language].healthNwellbeing}</option>
                              <option value="Pets">{i18n[language].pets}</option>
                              <option value="Technology">{i18n[language].technology}</option>
                              <option value="Financial">{i18n[language].financial}</option>
                              <option value="Sports">{i18n[language].sports}</option>
                              <option value="Beauty & Higene">{i18n[language].beautyNHigene}</option>
                              <option value="Lifestyle">{i18n[language].lifestyle}</option>
                              <option value="Housing">{i18n[language].housing}</option>
                              <option value="Children and Parenting">{i18n[language].childrenNparenting}</option>
                              <option value="Other">{i18n[language].other}</option>
                            </select>
                            <span ref="spnCategory" className='px-label-warning' style={{fontSize: '11px', color: colors.color4[3]}}/>
                          </div>
                        </div>
                        <div className="col-md-6 mg-bottom-15">
                          <div className="form-group">
                            <label className="label-sm" style={{ color: colors.color1[2] }}>{i18n[language].subCategory}</label>
                            <select name="subcategory" onChange={this.handleSubCategory} value={this.state.subcategory}
                                    className="form-control">
                              <option value="">{i18n[language].select}</option>
                              { subCategory }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer px-modal-footer-gray">
                  <div className="px-footer-container-buttons row">
                    <button ref="btnCreateCommunity" type="button" className="btn px-btn sm btn-blue-base"
                            onClick={this.updateShowQuote}
                            disabled={this.state.title === '' || !this.state.flagTitle || this.state.category === '' ||
                            this.state.cmtyLang === '' || (!this.state.default_tz_flag && this.state.default_tz === '')}
                            style={{ backgroundColor: colors.color1[3] }}>
                      {i18n[language].next}
                    </button>
                    <button onClick={this.setFalseToShowQuote} type="button" className="btn px-btn sm btn-gray-darker inverted"
                            data-dismiss="modal">{i18n[language].close}</button>
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    );
  }

  getCost = () => {
    this.setPlatformCost();
  };

  handleNumParts = event => {
    const name = event.target.name;
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    value = value !== '' ? value >= 0 ? parseInt(value) : 0 : 0;

    this.setState({[name]: value}, () => {
      const {num_participants} = this.state;
      //const maxNumParts = 66;

      this.setState({flagNumParts: !(num_participants >= 10 && num_participants < maxNumParts)});
    });
  };

  updateDates = (participants_start, participants_end) => {
    this.setState({participants_start, participants_end}, () => {
      //this.setPlatformCost();
    });
  };

  setPlatformCost = () => {
    const {companyId, subscription, company} = this.props;
    const {participants_start, participants_end, num_participants} = this.state;
    //const maxNumParts = 66;
    console.log(participants_start, participants_end, num_participants);

    if(!isNaN(num_participants)){
      if(num_participants >= 10 && num_participants < maxNumParts){
        if(participants_start && participants_end && (num_participants !== '' && num_participants !== 0)){
          const days = moment(participants_end).add(1, 'days').diff(moment(participants_start), 'days');
          //console.log('days:', days);
          const totalPD = num_participants * days;

          this.setState({
            loading: true,
            platform: num_participants + ' participants X ' +
              days  + ' days --- ' + totalPD + ' participants/days --- US$2,360'
          }, () => {
            const params = {participants_start, participants_end, num_participants, companyId, type: 'new', kind: 'update'};

            axios.get('/communities/get_cost_details', {params})
              .then(response =>  {
                //console.log('response:', response.data);
                this.setState({loading: false, sumSubs: 0, sumCash: 0, estimated_cost: 0, priceUnitPD: 0,
                  calculatorFlag: this.state.calculatorFlag + 1}, () => {
                  const daysView = response.data.days_view;
                  console.log('daysView:', daysView);
                  let sumSubs = 0;
                  let sumCash = 0;

                  daysView.forEach(obj => {
                    const locked = obj.sum_num_parts_date;
                    const papt = subscription.papt;
                    //console.log(company.max_num_com_active, obj.com_intersections_length, obj.com_intersections_length < company.max_num_com_active);
                    const free = obj.com_intersections_length < company.max_num_com_active ?
                      locked <= papt ? papt - locked > num_participants ? num_participants : papt - locked : 0 : 0;
                    const cash = num_participants - free;

                    sumSubs = sumSubs + free;
                    sumCash = sumCash + cash;
                  });
                  console.log('sumSubs:', sumSubs, 'sumCash:', sumCash);

                  if(sumCash > 0 && subscription.flex_days > 0){
                    //sumCash = sumCash - subscription.flex_days
                  }

                  let subs = '';
                  let cash = ''

                  if(sumSubs >= totalPD){
                    subs = num_participants + ' Subscription slots ('+ totalPD + '/' + totalPD +') --- US$1,880';
                    cash = 'Remaining balance to pay in cash: 0 p/d';
                  } else {
                    subs = num_participants + ' Subscription slots ('+ totalPD + '/' + sumSubs +') --- US$1,880';
                    cash = 'Remaining balance to pay in cash: ' + (totalPD - sumSubs) + ' p/d';
                  }

                  const estimated_cost = parseFloat(response.data.estimated_cost);
                  const priceUnitPD = estimated_cost / totalPD;
                  console.log('estimated_cost:', estimated_cost, 'totalPD:', totalPD, 'priceUnitPD:', priceUnitPD);

                  this.setState({subs, cash, sumSubs, sumCash, estimated_cost, priceUnitPD});
                });
              }).catch(error => {
              console.log(error);
            });
          });
        }
      }
    } else {
      this.setState({platform: ''});
    }
  };

  addCommunity = flexDaysPD => {
    const {language, communitiesLength, maxNumCommunities} = this.props;
    const i18n = {
      en: {
        titleError: 'Type a name for the community', maxCmntyError: 'You have reached the max. number of communities.',
        categoryError: 'You must select a category', titleWarning: 'Participants will see this name',
        maxNumCommunities: 'Maximum number of communities reached'
      },
      es: {
        titleError: 'Escribe un nombre para la comunidad', maxCmntyError: 'Haz llegado al número máximo de comunidades.',
        categoryError: 'Debes seleccionar una categoría', titleWarning: 'Los participantes verán este nombre',
        maxNumCommunities: 'Número máximo de comunidades alcanzadas'
      }
    }

    const title = this.state.title;

    this.setState(prevState => ({blocking: !prevState.blocking}), () => {
      console.log('maxNumCommunities:', maxNumCommunities);
      if(title !== '' && this.state.flagTitle && this.state.category !== '' && this.state.flagCmtyLang){
        $('#myBlockUI').css('display', 'block');
        //console.log('this.state.cmtyLang:', this.state.cmtyLang);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("avatar", this.state.blob);
        formData.append("category", this.state.category);
        formData.append("subcategory", this.state.subcategory);
        formData.append("language", this.state.cmtyLang);
        formData.append("num_participants", this.state.num_participants);
        formData.append("participants_start", this.state.participants_start);
        formData.append("participants_end", this.state.participants_end);
        formData.append("default_tz_flag", this.state.default_tz_flag);
        formData.append("default_tz", this.state.default_tz_flag ? '' : this.state.default_tz);
        formData.append("assigned_flex", flexDaysPD);
        formData.append("ai_plan", this.state.aiPlan);

        if(this.state.blob === null){
          formData.append('defaultAvatarUrl', this.state.defaultAvatarUrl);
        }

        $.ajax({
          url: '/communities/create',
          method: 'PUT',
          data: formData,
          processData: false,
          contentType: false,
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: obj => {
            console.log(obj);
            const newFlexDays = this.props.subscription.flex_days - flexDaysPD;
            const config = {headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}};
            axios.patch('/subscriptions/update/' + this.props.subscription.id, {flex_days: newFlexDays}, config).then(r => {
              console.log(r.data);
            }).catch(error => {
              console.log(error);
            });

            this.createTermsPDF('upload', obj.community.id, flexDaysPD);
            $('#myBlockUI').css('display', 'none');
            window.location.href = '/communities/' + obj.community.id + '/ActAndPar';
          }
        });
      } else {
        if(!this.state.flagTitle){
          if(title === ''){
            $('#spnModalAddTitle').text(i18n[language].titleError);
            $('#spnModalAddTitle').removeClass('px-label-information');
            $('#spnModalAddTitle').addClass('px-label-warning');
          }
        } else if(!this.state.flagCategory) {
          this.refs.spnCategory.innerHTML = i18n[language].categoryError;
        } else if(!this.state.flagCmtyLang) {

        } else {
          alert(i18n[language].maxNumCommunities);
        }
      }
    });
  };

  calculateAiPrice = (aiPlan, participants, days) => {
    if (!aiPlan) return '0.00';

    let totalAiPrice;
    let pd = participants * days
    if (aiPlan === 'aiPlan02') {
      totalAiPrice = pd * 2.2;
    } else if (aiPlan === 'aiPlan01') {
      totalAiPrice = pd * 1.2;
    } else if (aiPlan === 'aiPlan00') {
      totalAiPrice = pd * 0.4;
    } else {
      totalAiPrice = pd * 2.2;
    }
    return totalAiPrice.toFixed(2);
  }


  createTermsPDF = (type, communityId, flexDaysPD) => {
    const {company} = this.props;
    const {num_participants, participants_start, participants_end, sumSubs, sumCash, priceUnitPD, default_tz_flag,
      default_tz, aiPlan} = this.state;
    const days = moment(participants_end).add(1, 'days').diff(moment(participants_start), 'days');
    const finalCashPD = sumCash - flexDaysPD;
    const totalAiPrice = this.calculateAiPrice(aiPlan, num_participants, days);
    const remainingCash = (finalCashPD * priceUnitPD).toFixed(2);
    const remainingCashPlusAi = (parseFloat(totalAiPrice) + parseFloat(remainingCash)).toFixed(2);

    console.log(type, 'flexDaysPD:', flexDaysPD, 'sumCash:', sumCash, 'finalCashPD:', finalCashPD,
        'ai_cost', totalAiPrice, 'total_cost_w_ai', remainingCashPlusAi, 'num_participants', num_participants)
    const url = '/exports/show_terms.pdf?num_participants=' + num_participants + '&part_start='+ participants_start +
      '&part_end=' + participants_end + '&cash=' + remainingCash + '&country=' + company.billing_country +
      '&tz_flag=' + default_tz_flag + '&tz=' + default_tz + '&type=' + type + '&communityId=' + communityId +
      '&assigned_flex=' + flexDaysPD + '&subsPD=' + sumSubs + '&ai_plan=' + aiPlan +
      '&ai_cost=' + totalAiPrice + '&total_cost_w_ai=' + remainingCashPlusAi;

    if(type === 'view'){
      window.open(url, '_blank');
    } else {
      axios.get(url).then(response => {console.log(response.data);});
    }
  }

  updateShowQuote = () => {
    this.setState(prevState => ({showQuote: !prevState.showQuote}));
  };

  onBlur = event => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;
    console.log('value:', value);
    if(name === 'num_participants'){
      value = value !== '' ? value >= 0 ? parseInt(value) : 0 : 0;
    }

    this.setState({[name]: value}, () => {
      const {num_participants} = this.state;

      if(num_participants >= 10 && num_participants < maxNumParts){
        this.setState({flagNumParts: false}, () => {
          this.setPlatformCost();
        });
      } else {
        this.setState({flagNumParts: true});
      }
    });
  };

  handleChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;
    //console.log(name, value);

    this.setState({[name]: value}, () => {
      if(name === 'cmtyLang'){
        this.setState({flagCmtyLang: value !== ''});
      }
    });
  };

  validateTitle = () => {
    const language = this.props.language;
    const i18n = {
      en: {titleWarning: 'Participants will see this name.'},
      es: {titleWarning: 'Los participantes verán este nombre.'}
    }
    const title = this.refs.txtCommunityTitle.value;

    if(title !== ''){
      $.ajax({
        url: '/communities/title_validation',
        method: 'GET',
        dataType: 'JSON',
        data: { title: title },
        success: obj => {
          //console.log(obj);
          if(obj.msg){
            this.setState({flagTitle: false});
            $('#spnModalAddTitle').text('Title taken');
            $('#divModalAddCommunityTitle').removeClass('has-success');
          } else {
            this.setState({flagTitle: true});
            $('#spnModalAddTitle').text(i18n[language].titleWarning);
            $('#divModalAddCommunityTitle').addClass('has-success');
          }
        }
      });
    } else {
      this.setState({flagTitle: false});
      $('#divModalAddCommunityTitle').removeClass('has-success');
    }
  };

  handleToggle = () => {
    this.setState({ isModal: !this.state.isModal });
  };

  updateAvatar = (blob) => {
    this.setState({ blob: blob });

    const preview = this.refs.imgPreview;
    const reader  = new FileReader();

    reader.onloadend = function () {
      preview.src = reader.result;
    };

    reader.readAsDataURL(blob);
  };

  updateCommunityAvatar(){
  }

  handleCategory = (event) => {
    const category = event.target.value;
    let subs = [];
    const language = this.props.language;
    const i18n = {
      en: {
        entertainment: 'Entertainment', news: 'News', radio: 'Radio', beer: 'Beer',
        wineNspirits: 'Wine & Spirits', softdrinksNwater:'Softdrinks & Water', snacks:'Snacks',
        readyFood: 'Ready to Eat food', gas: 'Gas', electricity: 'Electricity', onlineShopping: 'Online shopping',
        brickShopping: 'Brick n Mortar shopping', restaurants: 'Restaurants', hotels: 'Hotels', autos: 'Automobiles',
        cycles: 'Cycles and motorcycles', publicTransport: 'Public transport', airlines: 'Airlines', cruiseships: 'Cruiseships',
        pharma: 'Pharma', wellbeing: 'Wellbeing', healthcarePros: 'Healthcare professionals', medicalServices: 'Medical Services',
        tvs: 'TVs', mobiles: 'Mobiles', computers: 'Computers', software: 'Software', telco: 'Telco', gaming: 'Gaming', cameras: 'Cameras',
        creditCards: 'Credit cards', savings: 'Savings', gymNexercise: 'Gym and exercise', relationships: 'Relationships', lgbt: 'LGBT',
        ageCare: 'Age care', toys: 'Toys', categoryError: 'You must select a category'
      },
      es: {
        entertainment: 'Entretenimiento', news: 'Noticias', radio: 'Radio', beer: 'Cerveza',
        wineNspirits: 'Vinos y licores', softdrinksNwater: 'Agua y refrescos', snacks: 'Botanas',
        readyFood: 'Comida lista para comer', gas: 'Gas', electricity: 'Electricidad', onlineShopping: 'Compras en línea',
        brickShopping: 'Compras en establecimientos', restaurants: 'Restaurantes', hotels: 'Hoteles', autos: 'Automóviles',
        cycles: 'Bicicletas y motocicletas', publicTransport: 'Transporte público', airlines: 'Aerolíneas', cruiseships: 'Cruceros',
        pharma: 'Farma', wellbeing: 'Bienestar', healthcarePros: 'Profesionales de la salud', medicalServices: 'Servicios médicos',
        tvs: 'TVs', mobiles: 'Celuláres', computers: 'Computadoras', software: 'Software', telco: 'Telco', gaming: 'Video juegos',
        cameras: 'Cámaras', creditCards: 'Tarjetas de crédito', savings: 'Ahorros', gymNexercise: 'Ejercicio y Gimnasios',
        relationships: 'Relaciones personales', lgbt: 'LGBT', ageCare: 'Servicios para la vejez', toys: 'Juguetes',
        categoryError: 'Debes seleccionar una categoría'
      }
    };

    switch(category){
      case 'Media':
        subs = [i18n[language].entertainment, i18n[language].news, i18n[language].radio];
        break;
      case 'Food & Drinks':
        subs = [i18n[language].beer, i18n[language].wineNspirits, i18n[language].softdrinksNwater, i18n[language].snacks, i18n[language].readyFood];
        break;
      case 'Utilities':
        subs = [i18n[language].gas, i18n[language].electricity];
        break;
      case 'Shopping':
        subs = [i18n[language].onlineShopping, i18n[language].brickShopping];
        break;
      case 'Hospitality':
        subs = [i18n[language].restaurants, i18n[language].hotels];
        break;
      case 'Transport':
        subs = [i18n[language].autos, i18n[language].cycles, i18n[language].publicTransport];
        break;
      case 'Travel':
        subs = [i18n[language].airlines, i18n[language].cruiseships];
        break;
      case 'Health & Wellbeing':
        subs = [i18n[language].pharma, i18n[language].wellbeing, i18n[language].healthcarePros, i18n[language].medicalServices];
        break;
      case 'Technology':
        subs = [i18n[language].tvs, i18n[language].mobiles, i18n[language].computers, i18n[language].software, i18n[language].telco, i18n[language].gaming, i18n[language].cameras];
        break;
      case 'Financial':
        subs = [i18n[language].creditCards, i18n[language].savings];
        break;
      case 'Lifestyle':
        subs = [i18n[language].gymNexercise, i18n[language].relationships, i18n[language].lgbt, i18n[language].ageCare];
        break;
      case 'Children and Parenting':
        subs = [i18n[language].toys];
        break;
    }

    if(category){
      this.refs.spnCategory.innerHTML = '';
      this.setState({flagCategory: true});
    } else {
      this.refs.spnCategory.innerHTML = i18n[language].categoryError;
      this.setState({flagCategory: false});
    }

    this.setState({
      subcategories: subs,
      category: category,
    });
  };

  handleSubCategory = (event) => {
    this.setState({subcategory: event.target.value});
  };

  setFalseToShowQuote = () => {
    this.setState({showQuote: false});
  }

  _getSubscription = companyId => {
    axios.get('/subscriptions/get_subscription/' + companyId, {})
      .then(r => {
        //console.log(r.data);
        this.setState({subscription: r.data.subscription})
      })
      .catch(error => {
        console.log(error);
      });
  }

  changeAiPlanState = (ai_plan) => {
    this.setState({aiPlan: ai_plan})
  }


}
