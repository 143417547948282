import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TBMEExport from "./ExportShare/TBMEExport";
import TBMEShare from "./ExportShare/TBMEShare";
import {showSuccessMsg} from "../../../UI/Globals/PX_Funs";
import axios from "axios";

export default class TBMExportShare extends React.Component {
  static propTypes = {
    userFilters: PropTypes.array.isRequired,
    allAvailableActs: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    segments: PropTypes.array.isRequired,
    genderAsSegment: PropTypes.bool.isRequired,
    tags: PropTypes.array.isRequired,
    showModalExportHandler: PropTypes.func.isRequired,
    objExport: PropTypes.object.isRequired,
    updateObjExportActions: PropTypes.func.isRequired,
    community: PropTypes.object.isRequired,
    collaborators: PropTypes.array.isRequired,
    updateCFId: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    updateFiltersOptsExp: PropTypes.func.isRequired,
    updateSltCalendarExp: PropTypes.func.isRequired,
    updateStartExpireDatesExp: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      chkText: false,
      chkComments: false,
      chkImages: false,
      chkMaps: false,
      chkVerbatims: false,
      type: 'Main',
      activeShare: false,
      url: '',
      filterExportTo: 'pdf',
      isCreatingPdf: false,
      pdfName: '',
      filterFocusOn: 'Participants'
    };
  }

  componentDidMount() {
    const {community, objExport} = this.props;

    const idPars = objExport.idPars.length === 1 ?
      objExport.idPars[0] : objExport.idPars.length !== 0 ? objExport.idPars.join() : 'all';
    const idActs = objExport.idActs.length === 1 ?
      objExport.idActs[0] : objExport.idActs.length !== 0 ? objExport.idActs.join() : 'all';
    const idTasks = objExport.idActs.length === 1 ?
      objExport.idTasks.length === 1 ? objExport.idTasks[0] : objExport.idTasks.join() : 'all';

    const url = window.location.protocol + '//' + window.location.hostname +':' + window.location.port +
      '/communities/'+ community.id + '/transcript/' + idPars + '/' + idActs + '/' + idTasks + '/Activities';

    this.setState({url});

    let btnColor = this.props.colors.color0[3];
    $(".nav-tabs > li.active > a").css({ "color": '#ffffff', 'background-color': btnColor, 'border-color': btnColor });
  }

  render() {
    const {userFilters, allAvailableActs, users, segments, genderAsSegment, tags, objExport, collaborators,
      language, colors, community} = this.props;

    const i18n = {
      en: {
        titleExport: 'Export', titleShare: 'Share', btnView: 'View PDF', btnDownload: 'Download PDF',
        btnShare: 'Copy', shareLink: 'Share this link', preview: 'Preview', btnDownloadXls: 'Download XLS'
      },
      es: {
        titleExport: 'Exportar', titleShare: 'Compartir', btnView: 'Ver PDF', btnDownload: 'Descargar PDF',
        btnShare: 'Copiar', shareLink: 'Compartir link', preview: 'Muestra', btnDownloadXls: 'Descargar XLS'
      }
    }

    const tabStyle = {
      active: {border: `1px solid ${colors.color0[3]}`, color: '#ffffff', backgroundColor: colors.color0[3], height:'30px'},
      inactive: {border: '1px solid #DADDE1', color: '#7C8593', backgroundColor: '#ffffff',height:'30px'}
    }

    let buttons;

    if(!this.state.activeShare){
      buttons = <div className="px-footer-container-buttons row">
        {this.state.filterExportTo !== 'excel' && this.state.filterExportTo !== 'excel-responses' &&
          <button onClick={this.showDebug}
                 className="btn px-btn sm btn-blue-base"
                 style={{backgroundColor: colors.color1[3]}}
        >
          {i18n[language].btnView}
        </button>}
        {
        this.state.filterExportTo !== 'excel' && this.state.filterExportTo !== 'excel-responses' && (this.state.filterExportTo === 'pdf' || this.state.filterExportTo === 'ai') &&
          <button type="button" onClick={() => this.toExport('view')}
                  className="btn px-btn sm btn-blue-base"
                  style={{backgroundColor: colors.color1[3]}}
                  disabled={this.state.isCreatingPdf}>
            {i18n[language].btnDownload}
          </button>
        }
        {(this.state.filterExportTo === 'excel' || this.state.filterExportTo === 'excel-responses') &&
          <button type="button" onClick={() => this.toExport('download')}
                 className="btn px-btn sm btn-blue-base" style={{backgroundColor: colors.color1[3]}}
                 disabled={this.state.isCreatingPdf}>
          {i18n[language].btnDownloadXls}
        </button>}
      </div>;
    } else {
      buttons = <div className="px-footer-container-buttons row">
        <button type="button" onClick={() => this.copyToClipboard(this.state.url)} className="btn px-btn sm btn-blue-base"
                style={{ backgroundColor: colors.color1[3] }}>
          {i18n[language].btnShare}</button>
      </div>;
    }

    return (
      <div className="modal-dialog">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className="modal-content">
          <div className="modal-header modal-delete-activity" >
            <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                    data-dismiss="modal" aria-hidden="true"
                    onClick={() => this.props.showModalExportHandler(null, null, null, null, null)}>
                    <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
            </button>
          </div>
          <div className="modal-body px-modal-body-adv-filters" style={{paddingBottom:'0'}}>
            {/* <div className="px-modal-title">Export data</div> */}
            {
              this.state.type === 'Main' &&
              <ul className="nav nav-tabs px-submenu-settings-navigation" role="tablist">
                <li className="active">
                  <a onClick={() => this.updateActiveShare('export')} href="#export"
                    role="tab" data-toggle="tab" style={this.state.activeShare ? tabStyle.inactive : tabStyle.active}>
                  {i18n[language].titleExport}</a>
                </li>
                <li>
                  <a onClick={() => this.updateActiveShare('share')} href="#share"
                    role="tab" data-toggle="tab" style={this.state.activeShare ? tabStyle.active : tabStyle.inactive}>
                    {i18n[language].titleShare}</a>
                </li>
              </ul>
            }
            <div className="tab-content active">
              <TBMEExport userFilters={userFilters}
                          allAvailableActs={allAvailableActs}
                          users={users}
                          segments={segments}
                          genderAsSegment={genderAsSegment}
                          tags={tags}
                          dataTypes={['text', 'comments', 'images', 'wordbook', 'verbatims']}
                          privacy={['include emails', 'include alias', 'anonymise username']}
                          objExport={objExport}
                          updateObjExportActions={this.props.updateObjExportActions}
                          updateCFId={this.props.updateCFId}
                          type={this.state.type}
                          updateType={this.updateType}
                          filterExportTo={this.state.filterExportTo}
                          handleInputChange={this.handleInputChange}
                          language={language}
                          updateFiltersOptsExp={this.props.updateFiltersOptsExp}
                          updateSltCalendarExp={this.props.updateSltCalendarExp}
                          updateStartExpireDatesExp={this.props.updateStartExpireDatesExp}
                          filterFocusOn={this.state.filterFocusOn}
                          updateFilterFocusOn={this.updateFilterFocusOn}
                          community={community}
              />
              <div className="tab-pane fade" id="share">
                <TBMEShare activeUsers={users}
                           collaborators={collaborators}
                           community={this.props.community}
                           activeShare={this.state.activeShare}
                           objExport={objExport}
                           language={language}
                           url={this.state.url}
                           copyToClipboard={this.copyToClipboard}
                           colors={colors}/>
              </div>
            </div>
          </div>
          {
            this.state.type === 'Main' &&
            <div className="modal-footer px-modal-footer-warning">
              { buttons }
            </div>
          }
        </div>
      </div>
    );
  }

  showDebug = () => {
    const { objExport } = this.props

    if(objExport.idActs.length > 0 && objExport.idPars.length > 0){
      const tasksId = objExport.idActs.length === 1 ? objExport.idTasks.map(id => id) : -1;
      const images = objExport.dataTypes.find(name => name === 'images') !== undefined;
      const maps = objExport.dataTypes.find(name => name === 'wordbook') !== undefined;
      const comments = objExport.dataTypes.find(name => name === 'comments') !== undefined;
      const verbatims = objExport.dataTypes.find(name => name === 'verbatims') !== undefined;
      const pEmail = objExport.privacy.find(name => name === 'include emails') !== undefined;
      const pAlias = objExport.privacy.find(name => name === 'include alias') !== undefined;
      const pAnn = objExport.privacy.find(name => name === 'anonymise username') !== undefined;


      this.setState({pdfName: Date.now() + '.pdf'}, () => {
        const params = 'activitiesId=['+ objExport.idActs.map(id => id) + ']' +
          '&tasksId=[' + tasksId + ']&usersId=['+ objExport.idPars.map(id => id) +']' +
          '&type=pdf' + '&images=' + images + '&moods=' + maps + '&comments=' + comments + '&verbatim=' + verbatims +
          '&focus=' + this.state.filterFocusOn + '&pEmail=' + pEmail + '&pAnn=' + pAnn + '&pAlias=' + pAlias +
          '&pdfName=' + this.state.pdfName + '&language=' + this.props.language + '&to=' + this.state.filterExportTo
        // console.log('params:', params);

        window.open('/exports/show_debug?' + params, '_blank')
      })
    }
  }

  updateFilterFocusOn = filterFocusOn => {
    //console.log('filterFocusOn:', filterFocusOn);
    this.setState({filterFocusOn});
  };

  copyToClipboard = (url) => {
    //console.log(url);
    navigator.clipboard.writeText(url);
    if(this.state.activeShare){
      showSuccessMsg('Copied to Clipboard');
    }
  };

  updateActiveShare = (type) => {
    this.setState({activeShare: type === 'share'});
  };

  toExport = type => {
    const { objExport } = this.props

    if(objExport.idActs.length > 0 && objExport.idPars.length > 0){
      const tasksId = objExport.idActs.length === 1 ? objExport.idTasks.map(id => id) : -1;
      const images = objExport.dataTypes.find(name => name === 'images') !== undefined;
      const maps = objExport.dataTypes.find(name => name === 'wordbook') !== undefined;
      const comments = objExport.dataTypes.find(name => name === 'comments') !== undefined;
      const verbatims = objExport.dataTypes.find(name => name === 'verbatims') !== undefined;
      const pEmail = objExport.privacy.find(name => name === 'include emails') !== undefined;
      const pAlias = objExport.privacy.find(name => name === 'include alias') !== undefined;
      const pAnn = objExport.privacy.find(name => name === 'anonymise username') !== undefined;

      if(this.state.filterExportTo === 'pdf' || this.state.filterExportTo === 'ai'){
        this.setState({pdfName: Date.now() + '.pdf'}, () => {
          const params = 'activitiesId=['+ objExport.idActs.map(id => id) + ']' +
            '&tasksId=[' + tasksId + ']&usersId=['+ objExport.idPars.map(id => id) +']' +
            '&type=' + type + '&images=' + images + '&moods=' + maps + '&comments=' + comments + '&verbatim=' + verbatims +
            '&focus=' + this.state.filterFocusOn + '&pEmail=' + pEmail + '&pAnn=' + pAnn + '&pAlias=' + pAlias +
            '&pdfName=' + this.state.pdfName + '&language=' + this.props.language + '&to=' + this.state.filterExportTo
          // console.log('params:', params);

          window.open('/exports/landing?' + params, '_blank')
        });
      } else {
        const url = '/exports/show_xls.xlsx?activitiesId=['+ objExport.idActs.map(id => id) + ']' +
          '&tasksId=[' + tasksId + ']&usersId=['+ objExport.idPars.map(id => id) +']' + '&focus=' +
          this.state.filterFocusOn + '&pEmail=' + pEmail + '&pAnn=' + pAnn + '&pAlias=' + pAlias +
          '&pExcelType=' + this.state.filterExportTo
        window.open(url, '_blank');
      }
    }
  };

  setIntervalFunc = () => {
    axios.get('/exports/get_pdf/' + this.state.pdfName)
      .then(response => {
        // console.log('public_url:', response.data);
        if(response.data !== ''){
          clearInterval(this.intervalID);
          this.setState({isCreatingPdf: false}, () => {
            window.open(response.data, '_blank');
          })
        }
      });
  };

  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    //console.log(name, value)
    this.setState({[name]: value}, () => {
      if(name === 'filterExportTo' && (value === 'excel' || value === 'excel-responses' || value === 'ai')){
        this.setState({filterFocusOn: 'Activities'});
      }
    });
  };

  updateType = type => {
    this.setState({type});
  };
}