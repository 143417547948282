import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class RSUserList extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    updateShowModalMobile: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {type, role_2, searchTerm, language, colors} = this.props;

    const i18n = {
      en: {
        search: 'Search', sortBy: 'Sort by', btnQI: 'Quick Invite', moderators: 'MODERATORS',
        actives: 'Active', invited: 'Invited', drafts: 'Drafts', declined: 'Declined', blocked: 'Blocked',
        status: 'Status', username: 'Username', role: 'Role', gender: 'Gender', location: 'Location', segment: 'Segment',
        multiInvite: 'Multi-invite', close: 'Close'
      },
      es: {
        search: 'Buscar', sortBy: 'Ordenar por', btnQI: 'Invitar', moderators: 'MODERADORES',
        actives: 'Activos', invited: 'Invitados', drafts: 'Drafts', declined: 'Declinados', blocked: 'Bloqueados',
        status: 'Estado', username: 'Nombre de usuario', role: 'Función', gender: 'Género', location: 'Ubicación',
        segment: 'Segmento', multiInvite: 'Invitar en grupo', close: 'Cerrar'
      }
    };

    let search = <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="px-drop-search-section">
          <div className="px-input-icon">
            <div className="form-group">
              <input type="text" name="searchTerm" onChange={this.props.handleChange} value={searchTerm}
                     className="form-control" placeholder={i18n[language].search}/>
            </div>
            <FontAwesomeIcon onClick={() => this.props.handleSearch(type)} icon={['fas','search']}
                             className="px-icon-right color-gray-base"/>
          </div>
        </div>
      </div>
    </div>;

    let sortBy = <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="mg-top-20 px-label mg-bottom-5">{i18n[language].sortBy}</div>
        <div className="px-drop-filters-container">
          <div className="px-item select">
            <div className="px-select">
              <select name="sortBy" onChange={this.props.handleChange} value={this.props.sortBy}>
                <option value="Status">{i18n[language].status}</option>
                <option value="Username">{i18n[language].username}</option>
                {role_2 !== 'Observer' && <option value="Email">Email</option>}
                {type === 'Collaborator' && <option value="Role">{i18n[language].role}</option>}
                {type === 'Participant' && <option value="Gender">{i18n[language].gender}</option>}
                {type === 'Participant' && <option value="Location">{i18n[language].location}</option>}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>;

    if(type === 'Participant' && role_2 === ''){
      sortBy = null;
    }

    return (
      <div className="modal-body px-modal-body-warning">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-drop-search-section">
              { search }
              <button onClick={this.props.updateShowModalMobile} className="btn px-btn sm btn-blue-base btn-update"
              style={{backgroundColor: colors.color1[3]}}>{i18n[language].close}</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            { sortBy }
          </div>
        </div>
      </div>
    );
  }
}
