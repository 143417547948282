import PropTypes from 'prop-types';
import React from 'react';
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import v4 from "uuid";

import CSGeneral from "../../components/Community/Settings/CSGeneral";
import CSBioColl from "../../components/Community/Settings/CSBioColl";
import CSBioPart from "../../components/Community/Settings/CSBioPart";
import CSNavigation from "../../components/Community/Settings/CSNavigation";
import CSWelcome from "../../components/Community/Settings/CSWelcome";
import CSInvitations from "../../components/Community/Settings/CSInvitations";
import CSSegmentsTags from "../../components/Community/Settings/CSSegmentsTags";
import CSMaps from "../../components/Community/Settings/CSMaps";
import CSQuickComments from "../../components/Community/Settings/CSQuickComments";
import CSSocial from "../../components/Community/Settings/CSSocial";
import CSNotifications from "../../components/Community/Settings/CSNotifications";
import CSMSocialCMTY from "../../components/Community/Settings/CSGeneral/CSMSocialCMTY";
import CSParticipantDashboard from "../../components/Community/Settings/CSParticipantDashboard";
import {getCSRF, showSuccessMsg} from "../../components/UI/Globals/PX_Funs";
import moment from "moment";
import axios from "axios";
import QuoteModal from "../../components/UI/Modals/QuoteModal";

export default class Settings extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    updateCommunity: PropTypes.func.isRequired,
    role_1: PropTypes.string.isRequired,
    cu: PropTypes.object.isRequired,
    handleLink: PropTypes.func.isRequired,
    communities: PropTypes.array.isRequired,
    papt: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    user_email: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    maxPapt: PropTypes.number,
    subscription: PropTypes.object,
    company: PropTypes.object,
    userId: PropTypes.number,
    helpStores: PropTypes.object
  };

  constructor(props) {
    super(props);
    const community = this._initCommunity(props.community);
    this.state = {
      community,
      cu: props.cu,
      showModalClose: false, showModalArchive: false, showModalUnarchive: false, showModalDelete: false,
      showModalSocial: false, showModalTimeZone: false, showPreviewWelcome: false, showModalQuote: false,
      typeWelcome: '',
      showPreviewInvitations: false,
      typeInvitations: '',
      isSavingSegments: false,
      isSavingTags: false,
      segmentsToDelete: [],
      tags: props.community.tags,
      tagsToDelete: [],
      segments: props.community.segments,
      maps: [], mapSelected: null, mapLanguage: props.community.language,
      alert: false,
      qcSelected: null,
      qcLanguage: 'en',
      communityAvatar: this.props.community.community_avatar,
      communityTitle: this.props.community.title,
      emptyDashboardImage: this.props.community.empty_dashboard_img,
      isValidCommunityEmail: true,
      initCommunityEmail: props.cu.community_email,
      saveFirstTimeCommEmail: props.cu.community_email === '' ? false : !props.cu.is_comm_email_confirmed,
      participants_start: props.community.participants_start, participants_end: props.community.participants_end,
      sumSubs: 0, sumCash: 0, estimated_cost: 0, priceUnitPD: 0, newAssignedFlex: 0, freeFlex: 0, totalPD: 0,
      cc: community,
      loading: false,
      showQuote: false,
      flagNumParts: false,
      calculatorFlag: 0
    };
  }

  componentDidMount(){
    const {community, cu} = this.props;

    this._initCommunityMap(community.maps);
    if(cu.role_2 === 'Owner'){
      if(moment(community.participants_end) > moment()){
        this.getCost();
      }
    }
  }

  render() {
    const {role_1, communities, papt, maxPapt, language, user_email, companyName, colors, subscription,
      company, userId, helpStores} = this.props;
    const {community, sumSubs, sumCash, estimated_cost, priceUnitPD, newAssignedFlex, totalPD} = this.state;

    const estimate = {totalPD, sumSubs, newAssignedFlex, sumCash, priceUnitPD, estimated_cost}

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 no-pd text-center">
            <CSNavigation handleLink={this.props.handleLink}
                          role_1={role_1}
                          language={language}
                          colors={colors}
                          projectType={this.props.community.project_type}
                          community={community}
                          userId={userId}
                          helpStores={helpStores}
            />
          </div>
        </div>
        <div className="row">
          {
            role_1 === 'Collaborator' ?                
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <CSGeneral community={this.state.community}
                           handleChange={this.handleChange}
                           saveCommunity={this.saveCommunity}
                           showModalClose={this.state.showModalClose}
                           updateShowModalClose={this.updateShowModalClose}
                           closeCommunity={this.closeCommunity}
                           showModalArchive={this.state.showModalArchive}
                           updateShowModalArchive={this.updateShowModalArchive}
                           archiveCommunity={this.archiveCommunity}
                           showModalDelete={this.state.showModalDelete}
                           updateShowModalDelete={this.updateShowModalDelete}
                           purgeCommunity={this.purgeCommunity}
                           showModalSocial={this.state.showModalSocial}
                           updateShowModalSocial={this.updateShowModalSocial}
                           socialCommunity={this.socialCommunity}
                           showModalTimeZone={this.state.showModalTimeZone}
                           updateShowModalTimeZone={this.updateShowModalTimeZone}
                           role_2={this.props.cu.role_2}
                           updateTimeZone={this.updateTimeZone}
                           updateCommunityAvatar={this.updateCommunityAvatar}
                           updateCommunityTitle={this.updateCommunityTitle}
                           updateCommunity={this.props.updateCommunity}
                           showModalUnarchive={this.state.showModalUnarchive}
                           updateShowModalUnarchive={this.updateShowModalUnarchive}
                           updateCommunityState={this.updateCommunityState}
                           communities={communities}
                           papt={papt}
                           maxPapt={maxPapt}
                           language={language}
                           colors={colors}
                           participants_start={this.state.participants_start}
                           participants_end={this.state.participants_end}
                           updateDates={this.updateDates}
                           getQuote={this.getQuote}
                           estimate={estimate}
                           loadingQuote={this.state.loading}
                           updateShowQuote={this.updateShowQuote}
                           updateShowModalQuote={this.updateShowModalQuote}
                           company={company}/>
                <CSBioColl community={this.state.community}
                           cu={this.state.cu}
                           user_email={user_email}
                           cuHandleChange={this.cuHandleChange}
                           saveBio={this.saveBio}
                           initCommunityEmail={this.state.initCommunityEmail}
                           isValidCommunityEmail={this.state.isValidCommunityEmail}
                           updateIsValidCommunityEmail={this.updateIsValidCommunityEmail}
                           language={language}
                           colors={colors}/>
                <CSWelcome community={this.state.community}
                           handleChange={this.handleChange}
                           saveCommunity={this.saveCommunity}
                           onEditorStateChange={this.onEditorStateChange}
                           showPreviewWelcome={this.state.showPreviewWelcome}
                           updateShowPreviewWelcome={this.updateShowPreviewWelcome}
                           typeWelcome={this.state.typeWelcome}
                           showModeratorsAvatar={this.showModeratorsAvatar}
                           language={language}
                           colors={colors}/>
                <CSInvitations community={this.state.community}
                               handleChange={this.handleChange}
                               onEditorStateChange={this.onEditorStateChange}
                               showPreviewInvitations={this.state.showPreviewInvitations}
                               updateShowPreviewInvitations={this.updateShowPreviewInvitations}
                               typeInvitations={this.state.typeInvitations}
                               saveCommunityInvitations={this.saveCommunityInvitations}
                               role_2={this.state.cu.role_2}
                               language={language}
                               companyName={companyName}
                               colors={colors}/>
                <CSSegmentsTags community={this.state.community}
                                handleChange={this.handleChange}
                                segments={this.state.segments}
                                addSegment={this.addSegment}
                                updateSegment={this.updateSegment}
                                updateColorSegment={this.updateColorSegment}
                                deleteSegment={this.deleteSegment}
                                isSavingSegments={this.state.isSavingSegments}
                                saveSegment={this.saveSegment}
                                saveSegments={this.saveSegments}
                                tags={this.state.tags}
                                addTag={this.addTag}
                                updateColorTag={this.updateColorTag}
                                updateTag={this.updateTag}
                                deleteTag={this.deleteTag}
                                saveTag={this.saveTag}
                                isSavingTags={this.state.isSavingTags}
                                saveTags={this.saveTags}
                                saveCommunity={this.saveCommunity}
                                language={language}
                                colors={colors}/>
                <CSMaps community={this.state.community}
                        maps={this.state.maps.filter(map => map.title['en'] !== 'pixiebob_qc')}
                        mapSelected={this.state.mapSelected}
                        alert={this.state.alert}
                        updateMapSelected={this.updateMapSelected}
                        mapLanguage={this.state.community.language}
                        //mapLanguage={this.state.mapLanguage}
                        updateMapLanguage={this.updateMapLanguage}
                        updateMap={this.updateMap}
                        updateMapTitle={this.updateMapTitle}
                        addMapItem={this.addMapItem}
                        deleteMapItem={this.deleteMapItem}
                        saveMap={this.saveMap}
                        restoreMap={this.restoreMap}
                        updateRandomMapItems={this.updateRandomMapItems}
                        language={language}
                        colors={colors}/>
                { this.state.community.project_type !== 'Mixed' &&
                    <CSQuickComments community={this.state.community}
                                  qcs={this.state.maps.filter(map => map.title['en'] === 'pixiebob_qc')}
                                  qcLanguage={this.state.community.language}
                                  //qcLanguage={this.state.qcLanguage}
                                  updateMapLanguage={this.updateMapLanguage}
                                  updateQC={this.updateQC}
                                  updateQCSelected={this.updateQCSelected}
                                  saveMap={this.saveMap}
                                  language={language}
                                  colors={colors}/>}
                <CSSocial community={this.state.community}
                          role_2={this.props.cu.role_2}
                          handleChange={this.handleChange}
                          saveCommunity={this.saveCommunity}
                          language={language}
                          colors={colors}/>
                <CSNotifications cu={this.state.cu}
                                 cuChangeNotification={this.cuChangeNotification}
                                 saveBio={this.saveBio}
                                 social_flag={true}
                                 user_email={user_email}
                                 cuHandleChange={this.cuHandleChange}
                                 isValidCommunityEmail={this.state.isValidCommunityEmail}
                                 updateIsValidCommunityEmail={this.updateIsValidCommunityEmail}
                                 saveFirstTimeCommEmail={this.state.saveFirstTimeCommEmail}
                                 language={language}
                                 colors={colors}/>
                <CSParticipantDashboard community={this.state.community}
                                        language={language}
                                        colors={colors}
                                        handleChange={this.handleChange}
                                        saveCommunity={this.saveCommunity}
                                        updateEmptyDashboardImage={this.updateEmptyDashboardImage}
                                        removeEmptyDashboardImg={this.removeEmptyDashboardImg}
                                        />
              </div> :
              <div className="col-md-12">
                <CSBioPart community={this.state.community}
                           cu={this.state.cu}
                           cuHandleChange={this.cuHandleChange}
                           saveBio={this.saveBio}
                           language={language}
                           colors={colors}/>
                <CSNotifications cu={this.state.cu}
                                 cuChangeNotification={this.cuChangeNotification}
                                 saveBio={this.saveBio}
                                 social_flag={this.props.community.social_flag}
                                 language={language}
                                 colors={colors}/>
              </div>
          }
          {
            this.state.showModalSocial &&
            <CSMSocialCMTY communityId={this.props.community.id}
                           title={this.state.community.title}
                           social_flag={this.state.community.social_flag}
                           updateShowModalSocial={this.updateShowModalSocial}
                           socialCommunity={this.socialCommunity}
                           language= {this.props.language}
                           colors={colors}/>
          }
          {
            this.state.showModalQuote &&
            <QuoteModal language={language}
                        colors={colors}
                        handleChange={this.handleChange}
                        num_participants={community.num_participants}
                        participants_start={community.participants_start}
                        participants_end={community.participants_end}
                        updateDates={this.updateDates}
                        getCost={this.getCost}
                        flagNumParts={this.state.flagNumParts}
                        loading={this.state.loading}
                        estimated_cost={this.state.estimated_cost}
                        subscription={subscription}
                        priceUnitPD={this.state.priceUnitPD}
                        sumSubs={this.state.sumSubs}
                        sumCash={this.state.sumCash}
                        createTermsPDF={this.createTermsPDF}
                        from={'CommunitySettings'}
                        save={this.saveCommunityEst}
                        assigned_flex={community.assigned_flex}
                        updateShowModalQuote={this.updateShowModalQuote}
                        calculatorFlag={this.state.calculatorFlag}/>
          }
        </div>
      </div>
    );
  }

  updateShowModalQuote = from => {
    // console.log('from:', from);
    this.setState(prevState => ({showModalQuote: !prevState.showModalQuote}), () => {
      if(from === 'close'){
        const {community} = this.props;
        // console.log('community.num_participants:', community.num_participants);
        this.setState({
          community: {
            ...this.state.community, num_participants: community.num_participants,
            participants_start: community.participants_start, participants_end: community.participants_end
          }
        });
      }
    });
  };

  getCost = () => {
    this.setPlatformCost();
  };

  setPlatformCost = () => {
    const {company, subscription} = this.props;
    const {community} = this.state;

    const num_participants = parseInt(community.num_participants);
    const participants_start = community.participants_start;
    const participants_end = community.participants_end
    // console.log(participants_start, participants_end, num_participants);

    if(num_participants >= 10 && num_participants < 101) {
      if(participants_start && participants_end) {
        const days = moment(participants_end).add(1, 'days').diff(moment(participants_start), 'days');
        const totalPD = num_participants * days;
        const allAvailableFlex = community.assigned_flex + subscription.flex_days;

        this.setState({loading: true}, () => {
          const params = {participants_start, participants_end, num_participants, companyId: company.id,
            community_id: community.id, type: 'update', kind: 'init'};

          axios.get('/communities/get_cost_details', {params})
            .then(response => {
              // console.log('days_view:', response.data.days_view);
              this.setState({loading: false, sumSubs: 0, sumCash: 0, estimated_cost: 0, priceUnitPD: 0,
                calculatorFlag: this.state.calculatorFlag + 1}, () => {
                const daysView = response.data.days_view;
                let sumSubs = 0;
                let sumCash = 0;

                daysView.forEach(obj => {
                  const locked = obj.sum_num_parts_date;
                  const papt = subscription.papt;
                  //console.log(company.max_num_com_active, obj.com_intersections_length, obj.com_intersections_length < company.max_num_com_active);
                  const free = obj.com_intersections_length < company.max_num_com_active ?
                    locked <= papt ? papt - locked > num_participants ? num_participants : papt - locked : 0 : 0;
                  const cash = num_participants - free;

                  sumSubs = sumSubs + free;
                  sumCash = sumCash + cash;
                });
                // console.log('sumSubs:', sumSubs, 'sumCash:', sumCash);

                let subs = '';
                let cash = ''
                const estimated_cost = parseFloat(response.data.estimated_cost);
                const priceUnitPD = estimated_cost / totalPD;
                const newAssignedFlex = allAvailableFlex - sumCash >= 0 ? sumCash : allAvailableFlex;
                const freeFlex = allAvailableFlex - sumCash >= 0 ? allAvailableFlex - sumCash : 0;
                // console.log('estimated_cost:', estimated_cost, 'totalPD:', totalPD, 'priceUnitPD:', priceUnitPD,
                //   'newAssignedFlex:', newAssignedFlex, 'freeFlex:', freeFlex);

                this.setState({subs, cash, sumSubs, sumCash, estimated_cost, priceUnitPD, newAssignedFlex, freeFlex});
              });
            }).catch(error => {
            console.log(error);
          });
        });
      }
    }
  }

  updateDates = (participants_start, participants_end) => {
    // console.log(participants_start, participants_end);
    this.setState({community: {...this.state.community, participants_start, participants_end}}, () => {
    });
  };

  updateShowQuote = () => {
    this.setState(prevState => ({showQuote: !prevState.showQuote}));
  };

  updateIsValidCommunityEmail = isValidCommunityEmail => {
    // console.log('isValidCommunityEmail:', isValidCommunityEmail);
    this.setState({isValidCommunityEmail})
  };

  showModeratorsAvatar = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    //console.log(name, value);
    const community = {...this.state.community};
    community[name] = value;
    this.setState({community}, () => {
      $.ajax({
        url: '/communities/show_moderators_avatar/' + this.props.community.id,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          show_coll_to_part: community.show_coll_to_part,
          show_coll_to_coll: community.show_coll_to_coll,
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: community => {
          if(name === 'show_coll_to_part'){
            this.setState({
              showModalSocial: false,
              community: this._initCommunity(
                {...this.state.community, show_coll_to_part: community.show_coll_to_part}
              )
            });
          } else {
            this.setState({
              showModalSocial: false,
              community: this._initCommunity(
                {...this.state.community, show_coll_to_coll: community.show_coll_to_coll}
              )
            });
          }

          showSuccessMsg('Success!');
        }
      });
    });
  };

  updateCommunityState = (state, communityId) => {
    $.ajax({
      url: '/communities/update_state/' + communityId + '/' + state,
      method: 'PATCH',
      dataType: 'JSON',
      success: community => {
        this.setState({community: this._initCommunity(community)});

        showSuccessMsg();
      }
    });
  };

  handleChange = event => {
    const community = {...this.state.community}
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    const name = event.target.name

    switch(name){
      case 'hide_participants_emails':
        community[name] = !value;
        this.setState({community});
        break;
      case 'default_tz_flag':
        this.updateShowModalTimeZone();
        break;
      case 'social_flag':
        this.updateShowModalSocial();
        break;
      case 'language':
        console.log('init editors');
        community[name] = value;
        this.setState({community}, () => {
          this.setState({community: this._initCommunity(community)});
        });
        break;
      case 'num_participants':
        const num_participants = value;

        this.setState({community: {...this.state.community, num_participants: num_participants}}, () => {
          const {community} = this.state;
          //console.log(community.num_participants, !(community.num_participants >= 10 && num_participants < 51));
          this.setState({flagNumParts: !(community.num_participants >= 10 && num_participants < 101)});
        });
        break;
      case 'aiModerationDelaySetting':
        let moderation_delay = 60;
        let moderation_delay_offset = 20;
        if (value === 'quick') {
          moderation_delay = 60;
          moderation_delay_offset = 20;
        } else if (value === 'balanced') {
          moderation_delay = 2700;
          moderation_delay_offset = 450;
        } else if (value === 'thoughtful') {
          moderation_delay = 5400;
          moderation_delay_offset = 800;
        }
        this.setState({community: {
          ...community,
          ai_moderation_delay: moderation_delay,
          ai_moderation_delay_offset: moderation_delay_offset
          }});
        break;
      case 'ai_auto_moderation_count':
        let valueInInt = parseInt(value, 10);
        if (valueInInt > 4) {
          showSuccessMsg('The value cannot be greater than 4');
        } else {
          community[name] = valueInInt;
          this.setState({community})
        }
        break;
      default:
        community[name] = value;
        this.setState({community}); // <- change to (prevState => ({}))
    }
  };

  cuHandleChange = (event) => {
    const cu = {...this.state.cu};
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;

    if(name === 'show_gender' || name === 'show_location' || name === 'community_email'){
      cu[name] = value;
      this.setState({cu});
    } else {
      if(value.length <= 240){
        cu[name] = value;
        this.setState({cu});
      }
    }
  };

  cuChangeNotification = (event, group) => {
    const cu = {...this.state.cu};
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    //console.log(name, value);
    if(name === 'use_community_email' || name === 'use_user_email'){
      cu[name] = value;
    } else {
      cu.notifications = cu.notifications.map(n => n.group === group && n.name === name ? {...n, value: value} : n);
    }

    this.setState({cu});
  };

  // ************ QUICK COMMENTS ************
  updateQC = (event, id) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    const maps = this.state.maps.map(map => {
      if(map.id === this.state.qcSelected.id){
        const items = map.items.map(item => item.id === id ? {...item, [this.state.community.language]: value} : item);
        //const items = map.items.map(item => item.id === id ? {...item, [this.state.qcLanguage]: value} : item);
        return {...map, items: items}
      } else {
        return map;
      }
    });

    this.setState({maps});
  };

  updateQCSelected = (qcSelected) => {
    this.setState({qcSelected})
  };

  // ************ MAPS ************
  updateRandomMapItems = (event, id) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const community = {...this.props.community};

    community[name] = value;

    $.ajax({
      url: '/communities/randomize_map/' + this.props.community.id,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        randomize_map: community.randomize_map
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: community => {
        this.setState({
          community: this._initCommunity(
            {...this.state.community, randomize_map: community.randomize_map}
          )
        });

        showSuccessMsg('Success!');
      }
    });
  };

  restoreMap = () => {
    $.ajax({
      url: '/maps/restore/' + this.state.mapSelected.id,
      method: "GET",
      success: map => {
        // console.log(map);
        const maps = this.state.maps.map(m => {
          if(m.id === this.state.mapSelected.id){
            // console.log(map, this.state.mapSelected.id);
            return {...m, title: map.title, items: map.items}
          } else {
            return m;
          }
        });
        this.setState({maps}, () => {
          //TO-DO Why?
          //this._getCommunityMaps();
        });
        showSuccessMsg('Success!');
      }
    });
  };

  saveMap = (type) => {
    let items_a = [];
    let title = '';

    if(type === 'map'){
      this.state.maps.forEach(map => {
        if(map.id === this.state.mapSelected.id){
          title = map.title[this.state.community.language];
          //title = map.title[this.state.mapLanguage];
          map.items.forEach(item => { items_a.push(item) });
        }
      });
    } else {
      this.state.maps.forEach(map => {
        if(map.id === this.state.qcSelected.id){
          title = 'pixiebob_qc';
          map.items.forEach(item => { items_a.push(item) });
        }
      });
    }

    const id = type === 'map' ? this.state.mapSelected.id : this.state.qcSelected.id;

    if(title !== ''){
      $.ajax({
        url: '/maps/' + id,
        method: "PATCH",
        dataType: "JSON",
        data: {
          items:items_a,
          title: title,
          language: type === 'map' ? this.state.community.language : this.state.qcLanguage
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: () => {
          this.saveCommunity();
          showSuccessMsg('Success!');
        }
      });
    } else {

    }
  };

  deleteMapItem = (id) => {
    const maps = this.state.maps.map(map => {
      if(map.id === this.state.mapSelected.id){
        const items = map.items.filter(item => item.id !== id);
        return {...map, items: items}
      } else {
        return map;
      }
    });

    this.setState({maps});
  };

  addMapItem = () => {
    const newItem = {
      id: v4(),
      en: '',
      es: '',
      color: '#' + Math.random().toString(16).slice(2, 8).toUpperCase(),
      isActive: false,
      state: 'Enabled'
    };

    const maps = this.state.maps.map(map => {
      if(map.id === this.state.mapSelected.id){
        const items = [...map.items];
        items.push(newItem);
        return {...map, items: items}
      } else {
        return map;
      }
    });

    this.setState({maps});
  };

  updateMapTitle = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    // console.log(value);
    const maps = this.state.maps.map(map => {
      if(map.id === this.state.mapSelected.id){
        // console.log('map:', map);
        return {
          ...map,
          title: {
            ...map.title,
            [this.state.community.language]: value
          }
        };
      } else {
        return map;
      }
    });

    this.setState({maps});
  };

  updateMap = (event, id) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const maps = this.state.maps.map(map => {
      if(map.id === this.state.mapSelected.id){
        const items = map.items.map(item => item.id === id ? {...item, [this.state.community.language]: value} : item);
        //const items = map.items.map(item => item.id === id ? {...item, [this.state.mapLanguage]: value} : item);
        return {...map, items: items}
      } else {
        return map;
      }
    });

    this.setState({maps});
  };

  updateMapLanguage = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value})
  };

  updateMapSelected = (mapSelected) => {
    this.setState({mapSelected})
  };

  // ************ TAGS ************
  saveTags = () => {
    let tagsToDB = this.state.tags.map(s => ({...s, id: isNaN(s.id) ? -1 : s.id}));
    tagsToDB = tagsToDB.filter(s => s.name !== '');
    // console.log(tagsToDB);
    this.setState({isSavingTags: true}, () => {
      $.ajax({
        url: '/tags/update/' + this.props.community.id,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          tags: JSON.stringify(tagsToDB),
          tagsToDelete: JSON.stringify(this.state.tagsToDelete.filter(t => !isNaN(t.id))),
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: tags => {
          //console.log(tags);
          showSuccessMsg('Success!');
          this.setState({tags, isSavingTags: false});
        }
      });
    });
  };

  saveTag = (tag) => {
    if(this.state.tagsToDelete.length !== 0){
      this.state.tagsToDelete.forEach(tag => {
        if(!isNaN(tag.id)){
          this.deleteTagDB(tag);
        }
      });
    }

    if(isNaN(tag.id)){
      tag.id = -1;
    }

    $.ajax({
      url: '/communities/create_tags/' + this.props.community.id,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        tag: tag
      },
      success: function (tags) {
        $('#snackbar').addClass('show');
        setTimeout(function(){$('#snackbar').removeClass('show');}.bind(this), 3000);

        this.setState({tags});
      }.bind(this)
    });
  };

  deleteTagDB = (tag) => {
    $.ajax({
      url: '/communities/delete_tag/' + tag.id + '/-1',
      method: 'PATCH',
      success: function (obj) {
        $('#snackbar').addClass('show');
        setTimeout(function(){ $('#snackbar').removeClass('show'); }, 3000);
      }.bind(this)
    });
  };

  deleteTag = (id) => {
    // console.log(id);
    const tags = this.state.tags.filter(tag => tag.id !== id);
    const tagsToDelete = [...this.state.tagsToDelete];

    tagsToDelete.push(this.state.tags.find(tag => tag.id === id));

    this.setState({tags, tagsToDelete});
  };

  updateColorTag = (id, color) => {
    const tags = this.state.tags.map(tag => tag.id === id ? {...tag, color} : tag );

    this.setState({tags});
  };

  updateTag = (event, id) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const tags = this.state.tags.map(tag => tag.id === id ? {...tag, [name]: value} : tag );

    this.setState({tags});
  };

  addTag = () => {
    const language = this.props.language;
    const colors = this.props.colors;
    const i18n = {
      en: {
        duplicate: 'Last Tag has a duplicate name', empty: 'Last Tag has an empty name'
      }, es: {
        duplicate: 'Tu último Tag tiene un nombre duplicado', empty: 'Tu último Tag no tiene nombre'
      }
    }
    const tags = [...this.state.tags];
    const tag = {
      id: v4(),
      name: '',
      color: '#' + Math.random().toString(16).slice(2, 8).toUpperCase()
    };

    if(tags.length === 0){
      tags.push(tag);
    } else {
      const lastTag = tags[tags.length - 1];

      if(lastTag.name !== ''){
        $('#spnTags').text('');
        let isDuplicate = false;
        for(let x = 0; tags.length - 1 > x; x++){
          if(tags[x].name === lastTag.name) {
            isDuplicate = true;
          }
        }
        if(!isDuplicate){
          $('#spnTags').text('');
          tags.push(tag);
        } else {
          $('#spnTags').text(i18n[language].duplicate);
        }
      } else {
        $('#spnTags').text(i18n[language].empty);
      }
    }
    $('#spnTags').css({ 'color': colors.color4[3] })
    this.setState({tags});
  };

  // ************ SEGMENTS ************
  saveSegments = () => {
    let segmentsToDB = this.state.segments.map(s => ({...s, id: isNaN(s.id) ? -1 : s.id}));
    segmentsToDB = segmentsToDB.filter(s => s.name !== '');
    // console.log(segmentsToDB);
    this.setState({isSavingSegments: true}, () => {
      $.ajax({
        url: '/segments/update/' + this.props.community.id,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          segments: JSON.stringify(segmentsToDB),
          segmentsToDelete: JSON.stringify(this.state.segmentsToDelete.filter(s => !isNaN(s.id))),
          gender_as_segment: this.state.community.gender_as_segment
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: segments => {
          // console.log(segments);
          this.setState({segments, isSavingSegments: false});
          this.saveCommunity();
        }
      });
    });
  };

  saveSegment = (segment) => {
    // console.log('saveSegment', segment);
    this.setState({isSaving: true});

    if(this.state.segmentsToDelete.length !== 0){
      this.state.segmentsToDelete.forEach(segment => {
        if(!isNaN(segment.id)){
          this.deleteSegmentDB(segment);
        }
      });
    }

    if(isNaN(segment.id)){
      segment.id = -1;
    }

    $.ajax({
      url: '/communities/create_segment/' + this.props.community.id,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        segment: segment
      },
      success: segments => {
        $('#snackbar').addClass('show');
        setTimeout(function(){$('#snackbar').removeClass('show');

        this.setState({isSaving: false});}.bind(this), 3000);
        // Try to optimize using $.when.$.ajax in the caller saveSegments()
        this.setState({segments});
      }
    });

    this.saveCommunity();
  };

  deleteSegment = (id) => {
    //console.log(id);
    const segments = this.state.segments.filter(segment => segment.id !== id);
    const segmentsToDelete = [...this.state.segmentsToDelete];

    segmentsToDelete.push(this.state.segments.find(segment => segment.id === id));

    this.setState({segments, segmentsToDelete});
  };

  deleteSegmentDB = (segment) => {
    $.ajax({
      url: '/communities/delete_segment/' + segment.id,
      method: 'PATCH',
      success: function (obj) {
        $('#snackbar').addClass('show');
        setTimeout(function(){ $('#snackbar').removeClass('show'); }, 3000);
      }.bind(this)
    });
  };

  updateColorSegment = (id, color) => {
    const segments = this.state.segments.map(segment => segment.id === id ? {...segment, color} : segment );

    this.setState({segments});
  };

  updateSegment = (event, id) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const segments = this.state.segments.map(segment => segment.id === id ? {...segment, [name]: value} : segment );

    this.setState({segments});
  };

  addSegment = () => {
    const language = this.props.language;
    const colors = this.props.colors;
    const i18n = {
      en: {
        duplicate: 'Last segment has a duplicate name', empty: 'Last segment has an empty name'
      }, es: {
        duplicate: 'Tu último segmento tiene un nombre duplicado', empty: 'Tu último segmento no tiene nombre'
      }
    }
    const segments = [...this.state.segments];
    const segment = {
      id: v4(),
      name: '',
      color: '#' + Math.random().toString(16).slice(2, 8).toUpperCase()
    };

    if(segments.length === 0){
      segments.push(segment);
    } else {
      const lastSegment = segments[segments.length - 1];

      if(lastSegment.name !== ''){
        $('#spnSegments').text('');
        let isDuplicate = false;
        for(let x = 0; segments.length - 1 > x; x++){
          if(segments[x].name === lastSegment.name) {
            isDuplicate = true;
          }
        }
        if(!isDuplicate){
          $('#spnSegments').text('');
          segments.push(segment);
        } else {
          $('#spnSegments').text(i18n[language].duplicate);
        }
      } else {
        $('#spnSegments').text(i18n[language].empty);
      }
      $('#spnSegments').css({ 'color': colors.color4[3] })
    }

    // it saves the gender_as_segments, TO-DO Change to one function to save ALL Segments,
    // because it will save many times as segments array length
    this.setState({segments});
  };

  // ************ INVITATIONS ************
  updateShowPreviewInvitations = (typeInvitations) => {
    this.setState(prevState => ({showPreviewInvitations: !prevState.showPreviewInvitations, typeInvitations}));
  };

  updateShowPreviewWelcome = (typeWelcome) => {
    this.setState(prevState => ({showPreviewWelcome: !prevState.showPreviewWelcome, typeWelcome}));
  };

  onEditorStateChange = (editor, editorName, name) =>{
    const community = {...this.state.community};

    community[name] = draftToHtml(convertToRaw(editor.getCurrentContent()));
    community[editorName] = editor;

    this.setState({community})
  };

  // ************ BIO ************
  // TO-DO Change name to SaveCU, because I use it in Notifications too
  saveBio = () => {
    $.ajax({
      url: '/communities_user/update_bio/' + this.props.cu.id,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: {
        community_users: {
          bio: this.state.cu.bio,
          show_gender: this.state.cu.show_gender,
          show_location: this.state.cu.show_location,
          show_media: this.state.cu.show_media,
          notifications: this.state.cu.notifications,
          community_email: (this.state.isValidCommunityEmail || this.state.cu.community_email === '') ?
            this.state.cu.community_email : this.state.initCommunityEmail,
          use_community_email: this.state.cu.use_community_email,
          use_user_email: this.state.cu.use_user_email
        }
      },
      success: cu => {
        let saveFirstTimeCommEmail = null;
        // console.log(this.state.initCommunityEmail, this.state.cu.community_email);
        if(this.state.initCommunityEmail !== this.state.cu.community_email){
          if(this.state.isValidCommunityEmail){
            if(this.state.cu.community_email === ''){
              saveFirstTimeCommEmail = this.state.saveFirstTimeCommEmail;
            } else {
              saveFirstTimeCommEmail = true;
            }
          } else {
            if(this.state.cu.community_email !== ''){
              saveFirstTimeCommEmail = this.state.saveFirstTimeCommEmail;
            }
          }
        } else {
          saveFirstTimeCommEmail = this.state.saveFirstTimeCommEmail;
        }

        this.setState({cu, saveFirstTimeCommEmail});

        showSuccessMsg('Success!');
      }
    });
  };

  updateTimeZone = () => {
    const community = {...this.state.community};
    community.default_tz_flag = !community.default_tz_flag;

    this.setState({showModalTimeZone: false, community});
  };

  updateShowModalTimeZone = () => {
    this.setState(prevState => ({showModalTimeZone: !prevState.showModalTimeZone}));
  };

  socialCommunity = (communityId) => {
    $.ajax({
      url: '/communities/social/' + communityId,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: community => {
        this.setState({showModalSocial: false, community: this._initCommunity(community)});

        showSuccessMsg('Success!');
      }
    });
  };

  updateShowModalSocial = () => {
    this.setState(prevState => ({showModalSocial: !prevState.showModalSocial}));
  };

  purgeCommunity = (communityId) => {
    $.ajax({
      url: '/communities/delete/' + communityId,
      method: 'PATCH',
      dataType: 'JSON',
      headers: getCSRF().headers,
      success: function () {
        this.setState({showModalDelete: false});
        window.location.href = '/users/dashboard';
      }.bind(this)
    });
  };

  updateShowModalDelete = () => {
    this.setState(prevState => ({showModalDelete: !prevState.showModalDelete}));
  };

  archiveCommunity = (communityId) => {
    $.ajax({
      url: '/communities/archive/' + communityId,
      method: 'PATCH',
      dataType: 'JSON',
      headers: getCSRF().headers,
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: community => {
        window.location.href = '/users/dashboard';

        showSuccessMsg('Success!');
      }
    });
  };

  closeCommunity = (communityId) => {
    $.ajax({
      url: '/communities/close/' + communityId,
      method: 'PATCH',
      dataType: 'JSON',
      headers: getCSRF().headers,
      success: community => {
        window.location.replace("/users/dashboard/?state=Closed");
        //this.setState({showModalClose: false, community: this._initCommunity(community)});
        showSuccessMsg();
      }
    });
  };

  updateShowModalClose = () => {
    this.setState(prevState => ({showModalClose: !prevState.showModalClose}));
  };

  saveCommunityInvitations = () => {
    const {community} = this.state;

    try {
      $.ajax({
        url: '/communities/update/' + this.props.community.id,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          community: {
            participants_invitation_subject: community.participants_invitation_subject,
            participants_invitation_subject_es: community.participants_invitation_subject_es,
            participants_invitation: community.participants_invitation,
            participants_invitation_es: community.participants_invitation_es,
            participants_invitation_signature: community.participants_invitation_signature,
            participants_invitation_signature_es: community.participants_invitation_signature_es,
            members_invitation_subject: community.members_invitation_subject,
            members_invitation_subject_es: community.members_invitation_subject_es,
            members_invitation: community.members_invitation,
            members_invitation_es: community.members_invitation_es,
            members_invitation_signature: community.members_invitation_signature,
            members_invitation_signature_es: community.members_invitation_signature_es
          }
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: community => {
          // console.log(community);
          this.updateCommunityTitle(community.title);
          this.setState({community: this._initCommunity(community)});

          showSuccessMsg('Success!');
        }
      });
    } catch(err){
      alert('You have added invalid characters');
    }
  };

  getQuote = () => {
    const {community, cc, participants_start, participants_end} = this.state;
    // console.log(cc.num_participants !== community.num_participants || participants_end !== community.participants_end
    //   || participants_start !== community.participants_start);

    if(community.num_participants >= 10 && community.num_participants < 51) {
      if(participants_start && participants_end) {
        if(cc.num_participants !== community.num_participants || participants_end !== community.participants_end
          || participants_start !== community.participants_start){
          this._getCost(community, this.props.subscription.company_id);
        }
      }
    }
  };

  saveCommunityEst = () => {
    const {community, cc} = this.state;

    $.ajax({
      url: '/communities/update/' + this.props.community.id,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        community: {
          num_participants: community.num_participants,
          participants_start: community.participants_start,
          participants_end: community.participants_end
        }
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: community => {
        // console.log('cc.num_participants:', cc.num_participants, 'community.num_participants:', community.num_participants);
        this.setState({community: this._initCommunity(community)}, () => {
          if(cc.num_participants !== community.num_participants || cc.participants_end !== community.participants_end
            || cc.participants_start !== community.participants_start){
            this.saveNewAssignedFlex();
          }
          this.updateShowModalQuote('save');
        });

        showSuccessMsg('Success!');
      }
    });
  };

  saveCommunity = () => {
    const {community, cc} = this.state

    $.ajax({
      url: '/communities/update/' + this.props.community.id,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        community: {
          title: community.title,
          category: community.category,
          subcategory: community.subcategory,
          sponsor: community.sponsor,
          default_tz: community.default_tz,
          default_tz_flag: community.default_tz_flag,
          social_flag: community.social_flag,
          participants_welcome_message: community.participants_welcome_message,
          participants_welcome_message_es: community.participants_welcome_message_es,
          members_welcome_message: community.members_welcome_message,
          members_welcome_message_es: community.members_welcome_message_es,
          show_coll_to_part: community.show_coll_to_part,
          show_coll_to_coll: community.show_coll_to_coll,
          participants_invitation_subject: community.participants_invitation_subject,
          participants_invitation: community.participants_invitation,
          participants_invitation_signature: community.participants_invitation_signature,
          members_invitation_subject: community.members_invitation_subject,
          members_invitation: community.members_invitation,
          members_invitation_signature: community.members_invitation_signature,
          gender_as_segment: community.gender_as_segment,
          randomize_map: community.randomize_map,
          show_part_list: community.show_part_list,
          language: community.language,
          num_participants: community.num_participants,
          participants_start: community.participants_start,
          participants_end: community.participants_end,
          empty_dashboard_img: community.empty_dashboard_img,
          empty_dashboard_msg: community.empty_dashboard_msg,
          empty_dashboard_msg_es: community.empty_dashboard_msg_es,
          ai_auto_moderation: community.ai_auto_moderation,
          ai_auto_moderation_count: community.ai_auto_moderation_count,
          ai_allow_pictures: community.ai_allow_pictures,
          ai_allow_videos: community.ai_allow_videos,
          ai_name: community.ai_name,
          ai_moderation_delay: community.ai_moderation_delay,
          ai_moderation_delay_offset: community.ai_moderation_delay_offset,
          hide_participants_emails: community.hide_participants_emails,
          allow_join_w_generic_link: community.allow_join_w_generic_link,
          video_transcript: community.video_transcript
        }
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: community => {
        // console.log('cc.num_participants:', cc.num_participants, 'community.num_participants:', community.num_participants);
        this.updateCommunityTitle(community.title);
        this.setState({community: this._initCommunity(community)}, () => {
          /*if(cc.num_participants !== community.num_participants || cc.participants_end !== community.participants_end
            || cc.participants_start !== community.participants_start){
            this.saveNewAssignedFlex();
          }
          this.updateShowModalQuote('save');*/
        });

        showSuccessMsg('Success!');
      }
    });
  };

  _getCost = (community, companyId) => {
    const {subscription} = this.props;

    const params = {
      participants_start: community.participants_start, participants_end: community.participants_end,
      num_participants: community.num_participants, companyId, type: 'update', community_id: community.id,
      kind: 'update'
    };

    this.setState({loadingQuote: true}, () => {
      axios.get('/communities/get_cost_details', {params})
        .then(response =>  {
          // console.log('days_view:', response.data.days_view);
          this.setState({sumSubs: 0, sumCash: 0, estimated_cost: 0, priceUnitPD: 0, loadingQuote: false}, () => {
            const daysView = response.data.days_view;
            const days = moment(community.participants_end).add(1, 'days')
              .diff(moment(community.participants_start), 'days');
            const totalPD = community.num_participants * days;
            let sumSubs = 0;
            let sumCash = 0;
            const papt = subscription.papt;
            const allAvailableFlex = community.assigned_flex + subscription.flex_days;
            // console.log('papt:', papt, 'allAvailableFlex:', allAvailableFlex);

            daysView.forEach(locked => {
              const free = locked <= papt ? papt - locked > community.num_participants ?
                community.num_participants : papt - locked : 0;
              const cash = community.num_participants - free;

              sumSubs = sumSubs + free;
              sumCash = sumCash + cash;
            });

            const newAssignedFlex = allAvailableFlex - sumCash >= 0 ? sumCash : allAvailableFlex;
            const freeFlex = allAvailableFlex - sumCash >= 0 ? allAvailableFlex - sumCash : 0;
            sumCash = sumCash - newAssignedFlex;
            // console.log('totalPD:', totalPD, 'sumSubsPD:', sumSubs, 'newAssignedFlex:', newAssignedFlex,
            //   'sumCashPD:', sumCash, 'freeFlex:', freeFlex);

            const estimated_cost = parseFloat(response.data.estimated_cost);
            const priceUnitPD = estimated_cost / totalPD;
            // console.log('estimated_cost:', estimated_cost, 'priceUnitPD:', priceUnitPD);

            this.setState({sumSubs, sumCash, estimated_cost, priceUnitPD, cc: community, newAssignedFlex,
              freeFlex, totalPD});
          });
        }).catch(error => {
        console.log(error);
      });
    });
  }

  createTermsPDF = (type, flexDaysPD) => {
    const {company} = this.props;
    const {community, sumSubs, sumCash, priceUnitPD} = this.state;

    const finalCashPD = sumCash - flexDaysPD;
    const remainingCash = (finalCashPD * priceUnitPD).toFixed(2);
    // console.log('sumCash:', sumCash, 'flexDaysPD', flexDaysPD, 'remainingCash:', remainingCash);

    const url = '/exports/show_terms.pdf?num_participants=' + community.num_participants + '&part_start='+
      community.participants_start + '&part_end=' + community.participants_end + '&cash=' + remainingCash +
      '&country=' + company.billing_country + '&tz_flag=' + community.default_tz_flag + '&tz=' + community.default_tz +
      '&type='+ type +'&communityId=' + community.id + '&assigned_flex=' + flexDaysPD + '&subsPD=' + sumSubs;

    if(type === 'view'){
      window.open(url, '_blank');
    } else {
      axios.get(url).then(response => {
        //console.log(response.data);
      });
    }
  }

  saveNewAssignedFlex = () => {
    const {subscription} = this.props;
    const {community, newAssignedFlex, freeFlex} = this.state;
    // console.log(newAssignedFlex, freeFlex);
    if(community.assigned_flex !== newAssignedFlex){
      const config = {headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}};
      const data = {newAssignedFlex, freeFlex, communityId: community.id};
      axios.patch('/subscriptions/update_flex_estimate/' + subscription.id, data, config).then(r => {
        // console.log(r.data);
        this.createTermsPDF('upload', newAssignedFlex);
      }).catch(error => {
        console.log(error);
      });
    } else {
      this.createTermsPDF('upload', community.assigned_flex);
    }
  };

  updateShowModalUnarchive = () => {
    this.setState(prevState => ({showModalUnarchive: !prevState.showModalUnarchive}));
  };

  updateShowModalArchive = () => {
    this.setState(prevState => ({showModalArchive: !prevState.showModalArchive}));
  };

  _getCommunitySegments(){
    //console.log('segments');
    $.ajax({
      url: '/communities/get_segments/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      success: segments => {
        this.setState({segments});
      }
    });
  }

  _getTags = () => {
    $.ajax({
      url: '/communities/get_tags/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      success: function (tags) {
        this.setState({tags});
      }.bind(this)
    });
  };

  _getCommunityMaps(){
    $.ajax({
      url: '/maps/get_maps/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      success: function (maps) {
        //console.log(maps);
        this.setState({
          maps: maps.maps_a,
          alert: maps.alert,
          mapSelected: maps.maps_a.length !== 0 ? maps.maps_a[0] : null,
          qcSelected: maps.maps_a.filter(map => map.title['en'] === 'pixiebob_qc')[0]
        });
      }.bind(this)
    });
  }

  _initCommunityMap = (maps) => {
    this.setState({
      maps: maps.maps_a,
      alert: maps.alert,
      mapSelected: maps.maps_a.length !== 0 ? maps.maps_a[0] : null,
      qcSelected: maps.maps_a.filter(map => map.title['en'] === 'pixiebob_qc')[0]
    });
  };

  updateCommunityAvatar = (communityAvatar) => {
    this.setState({communityAvatar});
  };

  updateEmptyDashboardImage = (emptyDashboardImage) => {
    const community = {...this.state.community};
    community.empty_dashboard_img = emptyDashboardImage;
    this.setState({emptyDashboardImage, community});
  };

  removeEmptyDashboardImg = () => {
    const community = {...this.state.community}
    community.empty_dashboard_img = ''
    this.setState({emptyDashboardImage: '', community})
  }

  updateCommunityTitle = (communityTitle) => {
    this.setState({communityTitle});
  };

  _initCommunity(community){
    return {
      ...community,
      editorWelcomePart: this._setEditorState(community.language === 'en' ?
        community.participants_welcome_message : community.participants_welcome_message_es),
      editorWelcomeColl: this._setEditorState(community.language === 'en' ?
        community.members_welcome_message : community.members_welcome_message_es),
      editorInvitationPart: this._setEditorState(community.language === 'en' ?
        community.participants_invitation : community.participants_invitation_es),
      editorInvitationColl: this._setEditorState(community.language === 'en' ?
        community.members_invitation : community.members_invitation_es),
      editorInvitationSignaturePart: this._setEditorState(community.language === 'en' ?
        community.participants_invitation_signature : community.participants_invitation_signature_es),
      editorInvitationSignatureColl: this._setEditorState(community.language === 'en' ?
        community.members_invitation_signature : community.members_invitation_signature_es)
    }
  }

  _setEditorState(body){
    let component;

    if(body !== ''){
      const contentBlock = htmlToDraft(body);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

      component = EditorState.createWithContent(contentState);
    } else {
      component = EditorState.createEmpty();
    }

    return component;
  }
}
