import PropTypes from 'prop-types';
import React from 'react';
import {Editor} from "react-draft-wysiwyg";
import embed from "embed-video";

export default class CSEditorWYSIWYG extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    editorName: PropTypes.string.isRequired,
    editorState: PropTypes.object.isRequired,
    onEditorStateChange: PropTypes.func.isRequired,
    questionId: PropTypes.number,
    maxLength: PropTypes.number,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      editorState: props.editorState
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.editorState !== this.props.editorState){
      this.setState({editorState: this.props.editorState})
    }
  }

  render() {
    const {editorName, editorState, name, questionId, maxLength, language} = this.props;
    const i18n = {
      en: {
        textPlaceholder: 'Type text here'
      },
      es: {
        textPlaceholder: 'Escribe tu texto aquí'
      }
    }

    let options = [];
    switch(name){
      case 'participants_welcome_message':
      case 'participants_welcome_message_es':
      case 'members_welcome_message':
      case 'members_welcome_message_es':
      case 'instructions':
      case 'title':
        options = ['inline'];
        break;
      case 'participants_invitation':
      case 'participants_invitation_es':
      case 'members_invitation':
      case 'members_invitation_es':
      case 'participants_invitation_signature':
      case 'participants_invitation_signature_es':
      case 'members_invitation_signature':
      case 'members_invitation_signature_es':
        options = ['inline', 'textAlign', 'colorPicker', 'link', 'emoji'];
        break;
    }

    return (
      <div className="px-oe-editor no-top-margin">
        <Editor editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                  options: options,
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                    bold: { className: 'bordered-option-classname' },
                    italic: { className: 'bordered-option-classname' },
                    underline: { className: 'bordered-option-classname' },
                  },
                  textAlign: {
                    options: ['left', 'center', 'right', 'justify']
                  },
                  emoji: {
                    icon: '/assets/custom_emoji.png'
                  },
                  embedded: {
                    icon: '/assets/you_tube.png',
                    popupClassName: 'px-embed-video-popup w-size',
                    embedCallback: link => {
                      const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                      return (detectedSrc && detectedSrc[1]) || link;
                    },
                    defaultSize: {
                      height: 'auto',
                      width: 'auto',
                    },
                  }
                }}
                placeholder={i18n[language].textPlaceholder}
                editorClassName="px-editor-tv-oe"
                handleBeforeInput={maxLength !== undefined && this._handleBeforeInput}
                handlePastedText={maxLength !== undefined && this._handlePastedText}
                onBlur={this.onBlur}
                stripPastedStyles={true}/>
      </div>
    );
  }

  onBlur = () => {
    const {editorName, name, questionId} = this.props;
    this.props.onEditorStateChange(this.state.editorState, editorName, name, questionId);
  };

  onEditorStateChange = (editorState) => {
    this.setState({editorState});
  };

  _handleBeforeInput = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if(currentContentLength - selectedTextLength > this.props.maxLength - 1) {
      return 'handled';
    }
  };

  _handlePastedText = (pastedText) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if(currentContentLength + pastedText.length - selectedTextLength > this.props.maxLength) {
      return 'handled';
    }
  };

  _getLengthOfSelectedText = () => {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if(!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);

      if(isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  }
}
