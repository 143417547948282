import PropTypes from 'prop-types';
import React from 'react';
import * as d3 from "d3";
import cloud from "d3-cloud";
import v4 from "uuid";

import { blWcEn, blWcEs } from "../../../../UI/Globals/BlackList";

let layout = null;

export default class TRCWordCloud extends React.Component {
  static propTypes = {
    answers: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    usersId: PropTypes.array.isRequired,
    isVirtualized: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired,
    responseId: PropTypes.number,
    userAnswer: PropTypes.string,
    username: PropTypes.string,
    index: PropTypes.number,
    doRecomputeRowHeights: PropTypes.func,
    updateSVG: PropTypes.func,
    svg: PropTypes.string,
    handleShowLoadingConsolidated: PropTypes.func,
    updateSVGMulti: PropTypes.func,
    task_id: PropTypes.number,
    setCurrentHeight: PropTypes.func,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      id: v4(),
      svg: null,
      isMobile: false
    };
  }

  componentDidMount() {
    const {answers, type, svg} = this.props;
    //console.log('TRCWordCloud.componentDidMount svg:', svg);
    if(svg){
      //console.log('put the saved svg');
      this.setState({svg: <div dangerouslySetInnerHTML={{ __html: this.props.svg }} />});
    } else {
      //console.log('draw svg for first time');
      this._prepareData(this._getCounts(answers), type);
    }

    this.setState({isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)})
  }

  render() {
    const {answers, type, usersId, username, svg, language} = this.props;
    const i18n = {
      en: { missing: 'missing', wordsBy: 'words by', rColoured: 'are colored' }, 
      es: { missing: 'faltantes', wordsBy: 'palabras de', rColoured: 'aparecen coloreadas' }
    }

    return (
      <React.Fragment>
        <div id={this.state.id} ref="divWordCloud" style={{display: this.state.isMobile ? 'block' : 'flex', justifyContent: 'center',
          alignItems:'center', margin: svg ? 'auto' : 'unset', overflowY: this.state.isMobile ? 'auto' : 'unset'}}/>
          <div style={{overflowY: 'auto'}}>
            { this.state.svg }
          </div>
        <React.Fragment>
          <p style={{fontSize:'10px',margin:'10px 0 0 0',color:'#7c8593'}}>
            n = {usersId.length} ({usersId.length - answers.length} {i18n[language].missing})
          </p>
          {
            type === 'single' &&
            <span style={{ fontSize: '10px', color: '#7c8593' }}>{`${i18n[language].wordsBy} ${username} ${i18n[language].rColoured}`}</span>
          }
        </React.Fragment>
      </React.Fragment> 
    );
  }

  drawWordCloud(items, type){
    //console.log('drawWordCloud', type, isDraw);
    //const id = this.props.userId + '_' + this.props.taskId;
    //$('#wc' + id).remove();
    //$('#wcParent' + id).prepend("<div id='wc"+ id +"'/>");

    this.setState({loading: false}, () => { // WHY DO I NEED THIS SETSTATE HERE ???
      d3.select(this.refs.divWordCloud)
        .append("svg")
        .attr('class', this.state.svgId)
        .style('display', 'block')
        .style('margin', 'auto')
        .attr("width", 650)
        .attr("height", 350)
        .append("g")
        // without the transform, words would get cutoff to the left and top, they would
        // appear outside of the SVG area
        .attr("transform", "translate(320,200)")
        .selectAll("text")
        .data(items)
        .enter().append("text")
        .style("font-size", function(d) { return d.size + "px"; })
        .style("fill", type === 'single' ?
          (d) => {
            if(d.flag){
              return this.props.colors.color0[3]
              //return '#433566';
            } else {
              return '#afbdc1';
            }
          } : '#afbdc1')
        .attr("text-anchor", "middle")
        .attr("transform", function(d) {
          return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
        })
        .text(function(d) { return d.text; });

      //console.log('WorcloudDone');
      //console.log('isVirtualized:', this.props.isVirtualized);
      if(this.props.isVirtualized){
        //TO-DO It would be call just one time after to check out if svg is not null
        this.props.updateSVG(document.getElementById(this.state.id).innerHTML, 'wordCloud', this.props.responseId);
        //this.props.handleShowLoadingConsolidated(this.props.responseId);
      } else {
        // For multi, it would be the same logic as updateSVC
        if(this.props.type === 'multi'){
          this.props.updateSVGMulti(document.getElementById(this.state.id).innerHTML, 'wordCloud', this.props.task_id);
        }
      }
    });
  }

  _prepareData(counts, type){
    const blackList = [...blWcEn, ...blWcEs];
    const items = [];
    let userAnswer =  null;
    //console.log(this.props.userAnswer)
    if(type === 'single' && this.props.userAnswer){
      userAnswer = this.props.userAnswer.split(' ');
    }
    //console.log(userAnswer);

    for(let [key, value] of Object.entries(counts)){
      if(value > 1 && key.length > 2){
        //console.log('key:', key, 'value:', value);
        //console.log(blackList.includes(key));
        const flag = blackList.includes(key);
        if(!flag){
          items.push({
            text: key,
            size: value,
            flag: type === 'single' ? userAnswer ? userAnswer.find(word => word === key) !== undefined : false : null
          });
        }
      }

    }
    //console.log(items);

    layout = cloud().size([600, 300])
      .words(items)
      .padding(10)
      .rotate(function () { return (~~(Math.random() * 2) * 360) || -90; })
      .fontSize(function(d) { return d.size * 5; })
      .on("end", () => this.drawWordCloud(items, type))
      .start();
  }

  _getCounts(answers){
    //console.log('answers:', answers);
    const answersNoEmoji = answers.map(answer => {
      //console.log('answer:', answer);
      if(answer){
        return answer.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
      }
    });
    //console.log('answersNoEmoji:', answersNoEmoji);
    const counts = [];

    if(answersNoEmoji.length !== 0){
      const allWords = answersNoEmoji.join(' ');
      //console.log(allWords.match(/\w+/g));
      if(allWords !== '' && allWords.match(/\w+/g)){
        allWords.match(/\w+/g).forEach(w => {
          counts[w] = (counts[w] || 0) + 1;
        });
      }
      //console.log('counts:', counts.sort((a, b) => b.index - a.index));
    }


    return counts;
  }
}
