import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";

export default class CSIPreview extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired,
    updateShowPreviewInvitations: PropTypes.func.isRequired,
    companyName: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { community, colors } = this.props;

    const subdomain = window.location.host.split('.')[0];

    const I18n = {
      en: {
        joinTitle: 'Join here ...', text1: 'Click "Accept" and you will be directed to ', btnAccept: 'Accept',
        btnNo: 'No Thanks', help: "If you have problems clicking the button 'ACCEPT', copy and paste this address into your browser:",
        cmntyUrl: "[a URL to the community will be displayed here]",
        important: "This mail was sent by Pixiebob. Pixiebob is an online platform that helps organizations to run opinion surveys. The organization that invited you to the study will be responsible for all communication with you. If you need help contact the administrator of the project or send an email to ",
        loginAfterJoin: "Once you have accepted the invitation, you will be able to access the community via:"
      },
      es: {
        joinTitle: 'Únete aquí...', text1: 'Haz clic en “Aceptar” y te dirigirás a ', btnAccept: 'Aceptar',
        btnNo: "No gracias", help: "Si tienes problemas haciendo clic al botón 'ACEPTAR', copia y pega esta dirección en tu navegador:", 
        cmntyUrl: "[aquí ira un URL a la comunidad]",
        important: "Este correo ha sido enviado a través de  Pixiebob. Pixiebob es un 'software' que ayuda a las organizaciones a realizar estudios de opinión. La organizacion que te han invitado a este estudio será responsable de toda comunicación contigo. Si tienes preguntas comunícate con el administrador del proyecto o envía un email a ",
        loginAfterJoin: "Una vez que hayas aceptado la invitación podrás acceder a la comunidad vía:"
      }
    };

    return (
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              <i onClick={() => this.props.updateShowPreviewInvitations('')} className="material-icons"
                 style={{color: '#333'}}>clear</i>
            </button>
            {/* <h4 className="modal-title">Preview</h4> */}
          </div>

          <div className="modal-body">
            <div className="col-md-12">
              <p><b>Subject: </b>{this.props.subject}</p>
              <p><b>From: </b>{`${subdomain}@pixiebob.app`}</p>
              <br/>
              <div dangerouslySetInnerHTML={{__html: this._formatBody(this.props.body)}} className="my-preview-invitation"/>
            </div>

            <div className="col-md-12" style={{marginBottom:'25px',textAlign:'center'}}>
              <div style={{border:'solid 1px #e5e5e5',padding:'20px'}}>
                <span style={{color:colors.color0[3]}}><strong>{I18n[community.language].joinTitle}</strong></span><br/>
                <span>{I18n[community.language].text1} "{this.props.community.title}"</span><br/>
                <button className="btn" style={{color: '#ffffff', borderColor: colors.color0[3], marginRight:'5px',
                  borderWidth: '10px 30px', display: 'inline-block', background: colors.color0[3], borderRadius: '4px',
                  fontWeight: 'bold', fontStyle: 'normal', lineHeight: '12px', width: 'auto', textAlign: 'center'}}>
                  {I18n[community.language].btnAccept}</button>
                <button className="btn" style={{color: '#7c8593', border: 'solid 1px #e5e5e5', marginLeft:'5px',
                  display: 'inline-block', background: '#ffffff', borderRadius: '4px',
                  fontWeight: 'bold', fontStyle: 'normal', lineHeight: '12px', width: 'auto', textAlign: 'center'}}>
                  {I18n[community.language].btnNo}</button>
              </div>
            </div>
            <div className="col-md-12">
              <p>
                {I18n[community.language].help}<br/>
                <span style={{ color: colors.color0[3] }}>{I18n[community.language].cmntyUrl}</span>
              </p>

              <p>
                {I18n[community.language].loginAfterJoin}<br/>
                <span style={{ color: colors.color0[3] }}>{I18n[community.language].cmntyUrl}</span>
              </p>
              <br/>
              <div dangerouslySetInnerHTML={{__html: this._formatSignature(this.props.signature)}}
                   className="my-preview-invitation"/>
            </div>
            <div className="col-md-12">
              <br/><br/><p style={{fontSize: '11px', lineHeight: '125%'}}>
              {I18n[community.language].important}<u>participants@pixiebob.app</u>
              </p>
            </div>
          </div>
          <div className="modal-footer">
            {/* <button onClick={() => this.props.updateShowPreviewInvitations('')} type="button"
                    className="btn btn-danger btn-simple">Close</button> */}
          </div>
        </div>
      </div>
    );
  }

  _formatSignature(text){
    const {community, companyName} = this.props;
    const mapObj = {
      '{{contact_email}}': community.owner_email,
      '{{contact_name}}': community.owner_name,
      '{{community_title}}': community.title,
    };

    return text.replace(/https:\/\/{{acceptance_url}}|{{acceptance_url}}|href="{{acceptance_url}}"|https:\/\/{{decline_url}}|{{decline_url}}|href="{{decline_url}}"|{{contact_email}}|{{contact_name}}|{{community_title}}/g,
      function (matched) { return mapObj[matched] });
  }

  _formatBody(text){
    console.log(text);
    const {community, companyName} = this.props;
    // Regex for acceptance/decline URL: 1.From DB, 2.Clean {{variable}}, 3.From Editor
    const mapObj = {
      'https://{{acceptance_url}}': '#',
      '{{acceptance_url}}': '<u>http://www.subdomain.pixiebob.app/accept?token=123456</u>',
      'href="{{acceptance_url}}"': 'href="#/"',
      'https://{{decline_url}}': '#',
      '{{decline_url}}': '<u>http://www.subdomain.pixiebob.app/decline?token=123456</u>',
      'href="{{decline_url}}"': 'href="#/"',
      '{{first_name}}': 'John',
      '{{last_name}}': 'Doe',
      '{{researcher}}': companyName,
      '{{researcher_email}}': '<u>'+ this.props.community.owner_email +'</u>',
      '{{community_title}}': community.title,
      '{{<strong>community_title</strong>}}': '<strong>' + community.title + '</strong>'
    };
    let f = text.replace(/https:\/\/{{acceptance_url}}|{{acceptance_url}}|href="{{acceptance_url}}"|https:\/\/{{decline_url}}|{{decline_url}}|href="{{decline_url}}"|{{first_name}}|{{last_name}}|{{researcher}}|{{researcher_email}}|{{community_title}}/g,
      function (matched) { return mapObj[matched] });
    f = f.replace(/https:\/\/{{acceptance_url}}|{{acceptance_url}}|href="{{acceptance_url}}"|https:\/\/{{decline_url}}|{{decline_url}}|href="{{decline_url}}"|{{first_name}}|{{last_name}}|{{researcher}}|{{researcher_email}}|{{community_title}}|{{<strong>community_title<\/strong>}}/g,
      function (matched) { return mapObj[matched] });

    /*f += '<br/><p style="font-size: 11px; line-height: 125%">' +
      '<b>Important disclaimer</b><br/>If your email is not <u>user_email@mail.com</u>, please disregard this email.<br/>' +
      'This email was sent to you by Pixiebob.io on behalf of '+ this.props.community.researcher +'. ' +
      'Pixiebob.io is only a platform that helps researchers to conduct online research. ' +
      'Therefore, '+ this.props.community.researcher +' accepts full responsibility of this research and any communication with you. ' +
      'For any queries regarding this research contact <u>'+ this.props.community.owner_email +'</u>.<br/> ' +
      'If '+ this.props.community.researcher +' are unable to help, feel free to send us an email to <u>participants@pixiebob.app</u><br/>' +
      'If you don\'t want to receive any more emails from Pixiebob.io <u>unsubscribe here</u>' +
      '</p>';*/
    return f;
  }
}
