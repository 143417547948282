import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactModal from "react-modal";
import { Tooltip as ReactTooltip } from "react-tooltip"
import TextareaAutosize from 'react-textarea-autosize';
import MediaQuery from "react-responsive";

import Comment from "../../components/Community/Comments/Comment";
import axios from "axios";
import {getCSRF} from "../../components/UI/Globals/PX_Funs";

export default class Comments extends React.Component {
  static propTypes = {
    comments: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,  // Response owner user id
    username: PropTypes.string.isRequired,  // Collaborators and AI use this prop to send quick messages
    questionId: PropTypes.number.isRequired,
    responseId: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    role_1: PropTypes.string,
    role_2: PropTypes.string,
    updateAUResponses: PropTypes.func,
    isFirstFFComment: PropTypes.object,
    updateResponseComments: PropTypes.func,
    entity: PropTypes.object,
    scrollFirstTime: PropTypes.bool,
    updateScrollFirstTime: PropTypes.func,
    updateShowComments: PropTypes.func,
    qc: PropTypes.array,
    item_scroll: PropTypes.object,
    communityState: PropTypes.string,
    cmntyLanguage: PropTypes.string,
    sendNow: PropTypes.bool,
    commentForceUpdateGrid: PropTypes.func,
    colors: PropTypes.object.isRequired,
    cmntyAutoModeration: PropTypes.bool,
    aiComment: PropTypes.object,
    index: PropTypes.number,
    recalculateRowHeight: PropTypes.func,
    company: PropTypes.object,
    taskType: PropTypes.string,
    addResponseEmbeddingAndModeration: PropTypes.func,
    projectType: PropTypes.string,
    response: PropTypes.object,
    user: PropTypes.object,
    task: PropTypes.object,
    community: PropTypes.object,
    setIsSendingFirstFF: PropTypes.func,
    aiAgentName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      body: '',
      replyBody: '',
      replyToReplyBody: '',
      activeCommentIdToReply: -1,
      activeReplyIdToReply: -1,
      isReplyOpen: false,       // Maybe useful for hide/show the input text when you click other icon to reply
      isReplyToReplyOpen: false, // The same as above,
      showConfirm: false,
      commentIdSelected: null,
      showConfirmReply: false,
      replyIdSelected: null,
      isSaving: false,
      isSavingReply: false,
      isSavingLike: false,
      typeUser: 'user',
      isLoadingQuickComment: false,
      aiModerationScheduled: false,
      isFetchingModerationSchedule: false
    }
  }

  componentDidMount = () => {
    if (this.props.response?.ai_moderation_status === "scheduled") {
      this.setState({aiModerationScheduled: true})
    }
  }

  componentDidUpdate(prevProps){
    const {cmntyAutoModeration, aiComment, questionId, username, comments, community} = this.props;

    // if(aiComment){
    //   if(questionId === aiComment.questionId){
    //     // console.log('Comments.componentDidUpdate cmntyAutoModeration:', cmntyAutoModeration,
    //     //   'prevProps.aiComment:', prevProps.aiComment, 'aiComment:', aiComment)
    //     if(cmntyAutoModeration){
    //       if(aiComment !== prevProps.aiComment){
    //         if(aiComment.comment !== ''){
    //           this.setState({body: aiComment.comment, typeUser: 'ai'}, () => {
    //             // console.log('aiComment.comment:', aiComment.comment)
    //             if(this.state.body !== 'satisfies'){
    //               this.saveComment(null)
    //             } else {
    //               this.sendRandomComment('thanks', username, comments, community)
    //             }
    //           })
    //         }
    //       }
    //     }
    //   }
    // }

    // TO-DO It could be better but it works!, maybe with the responseId -1
    //console.log(this.props.isFirstFFComment , prevProps.isFirstFFComment);
    if(this.props.isFirstFFComment !== prevProps.isFirstFFComment){
      //console.log(this.props.isFirstFFComment, prevProps.isFirstFFComment);
      const {isFirstFFComment, userId, questionId} = this.props;
      //console.log(isFirstFFComment, userId, questionId);
      if(isFirstFFComment.isFirstFFComment && isFirstFFComment.userId === userId &&
        isFirstFFComment.questionId === questionId){
        //console.log('just for userId: '+ userId +', and questionId(taskId): ' + questionId);
        this.sendRandomComment('forget', this.props.username, []);
      }
    }

    if (this.props.response?.ai_moderation_status !== prevProps.response?.ai_moderation_status) {
      if (this.props.response?.ai_moderation_status === "scheduled") {
        this.setState({aiModerationScheduled: true})
      } else {
        this.setState({aiModerationScheduled: false})
      }
    }
  }

  handleSetSendingFirstFFLoading = (isLoading) => {
    if (this.props.setIsSendingFirstFF) {
      this.props.setIsSendingFirstFF(true);
    }
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});
  };

  truncateString = (aString, maxLength) => {
    let truncatedString = null;
    if (aString.length > maxLength) {
      truncatedString = aString.substring(0, maxLength) + "..."
    } else {
      truncatedString = aString;
    }
    return truncatedString;    
  };

  isAiProbeQuickCommentsAllowed = (response) => {
    // console.log('response allow QC:', response )
    let allowAiProbeQC = false;

    const community = this.props.community;
    const allowAiProbeQCtext = community.project_type === 'Mixed' && community.ai_auto_moderation;
    const allowAiProbeQCimages = allowAiProbeQCtext && community.ai_allow_pictures;
    const task_type = response?.task?.task_type;
    // console.log('task type:', task_type )


    if (response) {
      if ((task_type === 'Canvas' || task_type === 'Multimedia') && allowAiProbeQCimages) {
        allowAiProbeQC = true;
      } else if (task_type === 'Open End') {
        const has_image_attachments = response?.attachments?.length > 0 || false
        const has_video = response.video_url || response.video_uuid || response.video_thumbnail
        // console.log('has_attachments:', has_image_attachments )

        if (!has_video && has_image_attachments && allowAiProbeQCimages) {
          allowAiProbeQC = true;
        } else if (!has_video && !has_image_attachments && allowAiProbeQCtext) {
          allowAiProbeQC = true;
        }

      } else if ((task_type === 'Choice' || task_type === 'MicroChat') && allowAiProbeQCtext) {
        allowAiProbeQC = true;
      }
    }
    return allowAiProbeQC;
  }

  updateModerationScheduled = (responseId) => {
    return new Promise((resolve, reject) => {
      this.setState({ isFetchingModerationSchedule: true });
      axios.get(`/responses/get/${responseId}`).then(
        responseDB => {
          let updatedAiModerationScheduled = responseDB?.data?.ai_moderation_status;
          this.setState({ aiModerationScheduled: updatedAiModerationScheduled === "scheduled" }, () => {
              this.setState({ isFetchingModerationSchedule: false });
              resolve(responseDB);
              return responseDB.data
          });
        }
      ).catch(error => {
        console.log(error);
        this.setState({ isFetchingModerationSchedule: false });
        reject(error);
      });
    });
  }


  render() {
    const { from, item_scroll, communityState, language, role_1, role_2, cmntyLanguage, colors, company,
            projectType, response, community} = this.props

    const i18n = {
      en: {
        leaveComment: 'Leave a comment', post: 'Post', deleteComment: 'Delete Comment',
        confirmDelete: 'Are you sure to delete your comment?', delete: 'Delete', cancel: 'Cancel',
        quickThanks: 'Quick Thanks message', quickFollowUp: 'Quick Follow-up message', quickHurryUp: 'Quick Hurry-up message',
        quickIllBeBack: "Quick I'll-be-back message",
        aiCommentScheduled: "Pixiebob AI will post a comment soon"
      },
      es: {
        leaveComment: 'Deja un comentario', post: 'Enviar', deleteComment: 'Eliminar Comentario',
        confirmDelete: 'Seguro quieres eliminar tu comentario?', delete: 'Eliminar', cancel: 'Cancelar',
        quickThanks: 'Mensaje de Gracias', quickFollowUp: 'Mensaje de seguimiento', quickHurryUp: 'Mensaje de apuro',
        quickIllBeBack: "Mensaje volveré pronto",
        aiCommentScheduled: "Pixiebob IA enviará un comentario pronto"
      }
    };
    // To review: the key index would be uuid
    const comments = this.props.comments.map((comment, index) => {
      //console.log(comment);
      const avatar = comment.avatar === '' ? '/assets/user.png' : comment.avatar;
      //const isReplyOpen = this.state.activeCommentIdToReply === comment.id;
      const isReplyOpen = this.state.activeCommentIdToReply === comment.comment.id;

      return(
        <Comment key={index}
                 comment={comment}
                 avatar={avatar}
                 language={language}
                 isReplyOpen={isReplyOpen}
                 updateIsReplyOpen={this.updateIsReplyOpen}
                 activeCommentIdToReply={this.state.activeCommentIdToReply}
                 activeReplyIdToReply={this.state.activeReplyIdToReply}
                 updateActiveCommentIdToReply={this.updateActiveCommentIdToReply}
                 updateActiveReplyIdToReply={this.updateActiveReplyIdToReply}
                 replyBody={this.state.replyBody}
                 replyToReplyBody={this.state.replyToReplyBody}
                 handleChange={this.handleChange}
                 saveReplyToComment={this.saveReplyToComment}
                 saveLike={this.saveLike}
                 showConfirm={this.state.showConfirm}
                 updateShowConfirm={this.updateShowConfirm}
                 entity={this.props.entity}
                 scrollFirstTime={this.props.scrollFirstTime}
                 updateScrollFirstTime={this.props.updateScrollFirstTime}
                 updateShowComments={this.props.updateShowComments}
                 userId={this.props.userId}
                 questionId={this.props.questionId}
                 from={from}
                 item_scroll={item_scroll}
                 communityState={communityState}
                 updateShowConfirmReply={this.updateShowConfirmReply}
                 role_1={role_1}
                 role_2={role_2}
                 isSavingReply={this.state.isSavingReply}
                 isSavingLike={this.state.isSavingLike}
                 commentForceUpdateGrid={this.props.commentForceUpdateGrid}
                 onHeightChange={this.onHeightChange}
                 colors={colors}
                 company={company}
                 response={this.props.response}
                 aiModerationScheduled={this.state.aiModerationScheduled}
                 isFetchingModerationSchedule={this.state.isFetchingModerationSchedule}
                 updateModerationScheduled={this.updateModerationScheduled}
                 aiAgentName={this.props.aiAgentName || this.props.community.ai_name}
        />
      )
    });

    let header = null;
    if(this.props.responseId !== -1 && this.props.role_1 === 'Collaborator' && (role_2 === 'Owner' || role_2 === 'Moderator')){
      let thanks = null;
      let replyLater = null;
      let quick = null;
      this.props.qc.forEach(q => {
        switch(q.kind){
          case 'Thanks':
            thanks = q.items.map((c, index) => {
              const msg = c[cmntyLanguage].search('{{username}}') === -1 ? c[cmntyLanguage] :
                c[cmntyLanguage].replace('{{username}}', this.props.username);
              return(
                <li key={index} data-tooltip-content={msg}>
                  <a onClick={() => this.autoComment(msg, {kind: q.kind, id: c.id})}>
                    {this.truncateString(msg, 30)}<div className="ripple-container"/>
                  </a>
                </li>
              )
            });
            break;
          case 'Reply Later':
            replyLater = q.items.map((c, index) => {
              const msg = c[cmntyLanguage].search('{{username}}') === -1 ? c[cmntyLanguage] :
                c[cmntyLanguage].replace('{{username}}', this.props.username);
              return(
                <li key={index} data-tooltip-content={msg}>
                  <a onClick={() => this.autoComment(msg)}>
                    {this.truncateString(msg, 30)}<div className="ripple-container"/></a>
                </li>
              )
            });
            break;
          case 'Quick Please':
            quick = q.items.map((c, index) => {
              const msg = c[cmntyLanguage].search('{{username}}') === -1 ? c[cmntyLanguage] :
                c[cmntyLanguage].replace('{{username}}', this.props.username);
              return(
                <li key={index} data-tooltip-content={msg}>
                  <a onClick={() => this.autoComment(msg)}>
                    {this.truncateString(msg, 30)}<div className="ripple-container"/></a>
                </li>
              )
            });
            break;
        }
      });

      header = <div className="row">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          {
            communityState !== 'Closed' &&
            <div className="interaction-container">
              <MediaQuery minDeviceWidth={480}>
                {matches =>
                  <div className="px-comments-input"
                       style={{width: matches ? '400px' : '100%'}}>
                    <TextareaAutosize name="body" onChange={this.handleChange} value={this.state.body}
                                      onHeightChange={rowHeight => this.onHeightChange(rowHeight)} minRows={1}
                                      className="px-text-area-inner"
                                      placeholder={this.state.aiModerationScheduled ? i18n[language].aiCommentScheduled : i18n[language].leaveComment}
                                      style={{fontSize:'14px',
                                        borderColor: this.state.aiModerationScheduled ? '#ffffff' : colors.color1[3],
                                        backgroundColor: this.state.aiModerationScheduled ? '#f6f6f6' : '#ffffff'
                                      }}
                                      disabled={this.state.aiModerationScheduled}
                    />
                    {
                      this.state.isSaving || this.state.isFetchingModerationSchedule ?
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin style={{ color: colors.color1[3]}}/> :
                          this.state.aiModerationScheduled ?
                            <FontAwesomeIcon icon={['far', 'timer']}
                                             onClick={() => {this.updateModerationScheduled(this.props.responseId)}}
                                             style={{ cursor: 'pointer', color: colors.color1[3]}}
                                             data-tooltip-content={this.state.aiModerationScheduled ?
                                               i18n[language].aiCommentScheduled : ''}
                            /> :
                            <FontAwesomeIcon icon={['far','paper-plane']}
                                             onClick={() => this.saveComment(null)}
                                             style={{ cursor: 'pointer', color: colors.color1[3] }}/>
                    }
                  </div>
                }
              </MediaQuery>
              <div className="action-container">
                { response?.state === 'Completed' || response?.state === 'Accepted'  ?
                    <span>
                      <div className="btn-group" role="group" aria-label="...">
                        <button type="button"
                                onClick={this.state.aiModerationScheduled ? ()=> null :
                                  () => this.sendRandomComment('thanks', this.props.username, this.props.comments)}
                                className="btn btn-icon btn-not-ch"
                                style={{ borderColor: colors.color1[3],
                                  borderTopRightRadius: projectType === 'Mixed' ? '3px' : '',
                                  borderBottomRightRadius: projectType === 'Mixed' ? '3px' : '',
                                  visibility: this.state.aiModerationScheduled ? 'hidden' : 'visible'
                                }}
                                data-tooltip-content={i18n[language].quickThanks}>
                          <FontAwesomeIcon
                              icon={this.state.isLoadingQuickComment ? ['fas','spinner'] : ['far','face-smile']}
                              spin={projectType === 'Mixed' && this.state.isLoadingQuickComment ? true : null}
                              style={{color: colors.color1[3]}}
                          />
                        </button>
                        { projectType !== 'Mixed' &&
                            <div className="dropdown" style={{display:'inline-block'}}>
                              <button className="dropdown-toggle btn-icon btn-not-ch btn-caret"
                                      style={{ listStyleType: 'none', marginLeft: '-1px', color: colors.color1[3], borderColor: colors.color1[3] }} data-toggle="dropdown" aria-expanded="false">
                                <b className="caret"/>
                              </button>

                              <ul className="dropdown-menu dropdown-menu-right dropdown-general-options drop-quick-comments first">
                                { thanks }
                              </ul>
                            </div>}
                      </div>
                      { (response?.task?.task_type === 'Open End' || response?.task?.task_type === 'Choice' ||
                        response?.task?.task_type === 'MicroChat' || response?.task?.task_type === 'Multimedia' ||
                        response?.task?.task_type === 'Canvas'
                        ) &&
                          <div className="btn-group" role="group" aria-label="...">
                            <button
                                onClick={this.state.aiModerationScheduled ? ()=> null :
                                  () => this.sendRandomComment(this.isAiProbeQuickCommentsAllowed(response) ? 'probe' : 'getBack', this.props.username, this.props.comments)}
                                type="button" className="btn btn-icon btn-not-ch"
                                style={{
                                  borderColor: colors.color1[3],
                                  borderTopRightRadius: projectType === 'Mixed' ? '3px' : '',
                                  borderBottomRightRadius: projectType === 'Mixed' ? '3px' : '',
                                  visibility: this.state.aiModerationScheduled ? 'hidden' : 'visible'
                                }}
                              data-tooltip-content={projectType === 'Mixed' ? i18n[language].quickFollowUp : i18n[language].quickIllBeBack}>
                              <FontAwesomeIcon icon={projectType === 'Mixed' ? this.state.isLoadingQuickComment ? ['fas','spinner'] : ['far', 'comment'] : ['far', 'clock']}
                                               spin={projectType === 'Mixed' && this.state.isLoadingQuickComment ? true : null}
                                               style={{color: colors.color1[3]}}/>
                            </button>
                              {projectType !== 'Mixed' &&
                              <div className="dropdown" style={{display: 'inline-block'}}>
                                <button className="dropdown-toggle btn-icon btn-not-ch btn-caret"
                                        style={{
                                          listStyleType: 'none',
                                          marginLeft: '-1px',
                                          color: colors.color1[3],
                                          borderColor: colors.color1[3]
                                        }} data-toggle="dropdown" aria-expanded="false">
                                  <b className="caret"/>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-right dropdown-general-options drop-quick-comments second">
                                  {replyLater}
                                </ul>
                              </div>
                            }
                          </div>
                      }
                    </span>
                    :
                    <div className="btn-group" role="group" aria-label="...">
                          <button onClick={this.state.aiModerationScheduled ? ()=> null :
                            () => this.sendRandomComment('forget', this.props.username, this.props.comments)}
                            type="button"
                            className="btn btn-icon btn-not-ch"
                            style={{ borderColor: colors.color1[3],
                              borderTopRightRadius: projectType === 'Mixed' ? '3px' : '',
                              borderBottomRightRadius: projectType === 'Mixed' ? '3px' : '',
                              visibility: this.state.aiModerationScheduled ? 'hidden' : 'visible'
                            }}
                            data-tooltip-content={i18n[language].quickHurryUp}>
                            <FontAwesomeIcon icon={this.state.isLoadingQuickComment ? ['fas','spinner']: ['far', 'forward-fast']}
                                         spin={projectType === 'Mixed' && this.state.isLoadingQuickComment ? true : null}
                                         style={{ color: colors.color1[3] }}/>
                          </button>
                      {projectType !== 'Mixed' &&
                          <div className="dropdown" style={{display:'inline-block'}}>
                            <button className="dropdown-toggle btn-icon btn-not-ch btn-caret"
                                    style={{
                                      listStyleType: 'none',
                                      marginLeft: '-1px',
                                      color: colors.color1[3],
                                      borderColor: colors.color1[3]
                                    }} data-toggle="dropdown" aria-expanded="false">
                              <b className="caret"/>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-right dropdown-general-options drop-quick-comments third">
                              { quick }
                            </ul>
                          </div>}
                    </div>
                }
              </div>
            </div>
          }
        </div>
      </div>;
    } else if(this.props.role_1 === 'Participant'){
      header = <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="response-text-area-container">
            <div className="text-tit-cont">
              <span className="title-text-area" style={{color: colors.color1[3]}}>{i18n[language].leaveComment}</span>
              <textarea name="body" onChange={this.handleChange} value={this.state.body}
                        className="response-text-area"/>
            </div>
            <button onClick={() => this.saveComment(null)} className="btn px-btn sm btn-post-comment"
              disabled={this.state.isSaving} style={{ backgroundColor: colors.color1[3] }}>
              {
                !this.state.isSaving ?
                  <div><span>{i18n[language].post}</span><FontAwesomeIcon icon={['fas', 'paper-plane']} /></div> :
                  <FontAwesomeIcon icon={['fas','spinner']} spin/>
              }
            </button>
          </div>
        </div>
      </div>;
    }

    return (
      <div>
        { header }
        {
          this.props.responseId !== -1 &&
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 ">
              <div className="px-comments-container" style={{ backgroundColor: window.innerWidth < 481 && colors.color1[6] }}>
                { comments }
              </div>
            </div>
          </div>
        }
        {
          this.state.showConfirm &&
          <ReactModal isOpen={this.state.showConfirm} contentLabel="Delete Comment"
                      shouldCloseOnOverlayClick={true} onRequestClose={() => this.updateShowConfirm(null)}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header modal-delete-activity">
                  <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                    <i onClick={this.updateShowConfirm} className="material-icons">clear</i>
                  </button>
                </div>
                <div className="modal-body px-modal-body-warning">
                  <div className="px-title-warning-container">
                    <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning"/>
                    <p className="px-title-warning">{i18n[language].deleteComment}</p>
                    <p className="px-message-warning">{i18n[language].confirmDelete}</p>
                  </div>
                </div>
                <div className="modal-footer px-modal-footer-warning">
                  <div className="px-footer-container-buttons column">
                    <button type="button" onClick={() => this.deleteComment(this.props.questionId)}
                      className="btn px-btn sm btn-blue-base">{i18n[language].delete}
                    </button>
                    <button onClick={() => this.updateShowConfirm(null)} type="button"
                      className="btn px-btn sm btn-gray-darker inverted">{i18n[language].cancel}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        }
        {
          this.state.showConfirmReply &&
          <ReactModal isOpen={this.state.showConfirmReply} contentLabel="Delete Comment"
                      shouldCloseOnOverlayClick={true} onRequestClose={() => this.updateShowConfirmReply(null)}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header modal-delete-activity">
                  <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                    <i onClick={this.updateShowConfirmReply} className="material-icons">clear</i>
                  </button>
                </div>
                <div className="modal-body px-modal-body-warning">
                  <div className="px-title-warning-container">
                    <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning"/>
                    <p className="px-title-warning">{i18n[language].deleteComment}</p>
                    <p className="px-message-warning">{i18n[language].confirmDelete}</p>
                  </div>
                </div>
                <div className="modal-footer px-modal-footer-warning">
                  <div className="px-footer-container-buttons column">
                    <button type="button" onClick={() => this.deleteReply(this.props.questionId)}
                            className="btn px-btn sm btn-blue-base">{i18n[language].delete}
                    </button>
                    <button onClick={() => this.updateShowConfirmReply(null)} type="button"
                      className="btn px-btn sm btn-gray-darker inverted">{i18n[language].cancel}
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        }
      </div>
    );
  }

  onHeightChange = rowHeight => {
    // console.log('rowHeight:', rowHeight, 'this.props.from:', this.props.from );
    if(this.props.from !== 'PAV' && this.props.from !== 'PSA' && rowHeight > 80){
      this.props.recalculateRowHeight(this.props.index)
      //this.props.commentForceUpdateGrid();
    }
  };

  deleteReply = () => {
    this.updateShowConfirmReply(null);

    $.ajax({
      url: '/reply/delete/'+ this.state.replyIdSelected  +'/' + this.props.responseId,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: comments => {
        // console.log(comments, 'this.props.from:', this.props.from);
        if(this.props.from === 'PAV' || this.props.from === 'PSA'){
          this.props.updateResponseComments(comments, this.props.responseId, this.props.questionId);
        } else {
          this.props.updateAUResponses(comments, this.props.responseId, false, this.props.userId, 'Replies.deleteComment');
        }
      }
    });
  };

  updateShowConfirmReply = replyId => {
    this.setState(prevState => ({
      showConfirmReply: !prevState.showConfirmReply,
      replyIdSelected: replyId
    }))
  };

  deleteComment = () => {
    this.updateShowConfirm(null);

    $.ajax({
      url: '/comment/delete/'+ this.state.commentIdSelected  +'/' + this.props.responseId,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: comments => {
        // console.log(comments);
        //this.props.commentForceUpdateGrid();
        if(this.props.from === 'PAV' || this.props.from === 'PAS'){
          this.props.updateResponseComments(comments, this.props.responseId, this.props.questionId);
        } else {
          this.props.updateAUResponses(comments, this.props.responseId, false, this.props.userId, 'Comments.deleteComment');
        }
      }
    });
  };

  updateShowConfirm = (commentId) => {
    this.setState(prevState => ({
      showConfirm: !prevState.showConfirm,
      commentIdSelected: commentId
    }))
  };

  // Save Like to Comments and Replies
  saveLike = (entityId, entityType, isLike) => {
    this.setState({isSavingLike: true}, () => {
      $.ajax({
        url: '/comment/like/'+ entityId +'/' + entityType + '/' + this.props.responseId,
        method: 'PATCH',
        dataType: 'JSON',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
          comment: {
            isLike: isLike
          },
          //taskId: this.props.taskId
        },
        success: obj => {
          // console.log(obj);
          this.setState({isSavingLike: false});
          /*if(this.props.from !== 'Part_OpenEnd'){
            this.props.updateActiveUsersResponses(obj.active_participants);
          }*/

          /*let likes = this.state.likes;
          if(obj.isLike === 'false'){
            this.setState({likes: --likes});
          } else {
            this.setState({likes: ++likes});
          }

          this.props.updateComments(obj.comments);*/
          if(this.props.from === 'PAV' || this.props.from === 'PAS' || this.props.role_1 === 'Participant'){
            if(this.props.role_1 === 'Participant'){
              this.props.updateResponseComments(obj.comments, this.props.responseId, this.props.questionId);
            } else {
              this.props.updateResponseComments(obj.comments, null);
            }
          } else {
            this.props.updateAUResponses(obj.comments, obj.comments[0].comment.response_id, false, this.props.userId,
              'Comments.saveLike');
          }
        }
      });
    });
  };

  autoComment = (body, kind) => {
    // console.log(kind);
    this.setState({body}, () => {
      //this.saveComment(kind);
    })
  };

  sendRandomComment = (type, username, comments) => {
    let community = this.props.community;
    this.handleSetSendingFirstFFLoading(true);
    if (this.props.projectType === 'Mixed' && community.ai_auto_moderation === true) {
      let natural_username = this.props.user?.cu_natural_username || this.props.user.username;
      let participant_language = this.props.user?.cu_profile_language;
      let ai_model = 'gpt-4o'
      let task_id = this.props.task ? this.props.task?.id : this.props.tasks?.id
      let task_type = this.props.task ? this.props.task?.task_type : this.props.tasks?.task_type
      let response_user_id = this.props.user?.id
      let response = ''
      let response_img_transcripts = ''
      let all_text_choices = ''
      let response_id = this.props.response ? this.props.response?.id : ''
      let question = this.props.task ? this.stripHtml(this.props.task?.title) : this.stripHtml(this.props.tasks?.title)
      let allow_img_auto_moderation = (community.ai_allow_pictures &&
        community.ai_auto_moderation &&
        community.ai_plan === "aiPlan02")

      // console.log ('natural_username' + natural_username,
      //   'participant_language: ' + participant_language,
      //   'ai_model: ', ai_model,
      //   'task_id: ', task_id,
      //   'task_type: ', task_type,
      //   'task_type: ', task_type,
      //   'response_user_id: ', response_user_id,
      //   'response: ', response,
      //   'response_id: ', response_id,
      //   'question: ', question,
      //   'allow_img_auto_moderation:', allow_img_auto_moderation
      // )

      switch (task_type) {
        case 'Open End' || 'Multimedia' || 'Canvas':
          response = this.props.response ? this.props.response.data?.answer : response;
          if (allow_img_auto_moderation && this.props.response && this.props.response.attachments) {
            let attachments_transcripts_array = []
            this.props.response.attachments.map((attachment, index) => (
              attachments_transcripts_array.push(attachment.transcript &&
                `Attached image ${index + 1}: ${attachment.transcript}`
              )
            ));
            response_img_transcripts = attachments_transcripts_array.join('; ');
          }
        break;
        case 'Choice':
          let responseObj = this.props.response ? this.props.response.data?.answer : response;
          if (this.props.response) {
            let responseWithHTML = this.extractSelectedTextsInChoiceQuestion(this.props.response);
            response = this.stripHtml(responseWithHTML);
            let taskAllChoicesWithHtml = this.extractAllChoicesInQuestion(responseObj)
            all_text_choices = this.stripHtml(taskAllChoicesWithHtml)
          }
        break;
        case 'MicroChat':
          if (this.props.response) {
            response = this.props.response?.data?.messages?.map(message => message.body)?.join('; ') ?? '';
          }
          break;
      }

      this.getQuickComment({
        quick_comment_type: type,
        response_id: response_id,
        response: response,
        task_id: task_id,
        task_type: task_type,
        response_user_id: response_user_id,
        question: question,
        natural_username: natural_username,
        language: participant_language,
        ai_model:ai_model,
        allow_img_auto_moderation: allow_img_auto_moderation || false,
        response_img_transcripts: response_img_transcripts,
        all_text_choices: all_text_choices
      })
          .then((quickComment) => {
            if (quickComment) {
              this.setState({body: quickComment} )
              // console.log('Quick Message OK:', quickComment);
              if(this.props.sendNow !== undefined && this.props.sendNow){
                  this.saveComment();
              }
            } else {
              // console.error('Quick Comment received but it was empty.');
            }
            this.handleSetSendingFirstFFLoading(false);
          })
          .catch((error) => {
            console.log('Quick Message Error:', error);
          });
    } else {
      const { cmntyLanguage, isFirstFFComment, aiComment } = this.props;
      let flag = true;
      const lastComment = comments.length !== 0 ? comments[0].comment.body : '';

      let thanks = [];
      let quick = [];
      let replyLater = [];

      this.props.qc.forEach(q => {
        switch(type){
          case 'forget':
            if(q.kind === 'Quick Please'){
              //console.log(q.kind);
              quick = q.items.map((c, index) => {
                //console.log('c:', c);
                //console.log('cmntyLanguage;', cmntyLanguage);
                return {
                  body: c[cmntyLanguage].search('{{username}}') === -1 ? c[cmntyLanguage] :
                      c[cmntyLanguage].replace('{{username}}', this.props.username),
                  id: c.id
                }
              });
              //console.log(quick);
              while(flag){
                let index = Math.floor((Math.random() * quick.length));
                let randomComment = quick[index];
                //console.log('lastComment:', lastComment, 'randomComment:', randomComment);
                if(lastComment !== randomComment.body){
                  flag = false;
                  //console.log(isFirstFFComment);
                  const body = isFirstFFComment !== undefined ?
                      isFirstFFComment.body === undefined ? randomComment.body : isFirstFFComment.body :
                      randomComment.body;
                  //console.log('body:', body, 'this.props.sendNow:', this.props.sendNow);
                  this.setState({body},
                      () => {
                        //console.log('this.props.sendNow:', this.props.sendNow);
                        if(this.props.sendNow !== undefined){
                          if(this.props.sendNow){
                            this.saveComment({kind: q.kind, id: randomComment.id});
                          }
                        }
                      });
                }
              }
            }
            break;
          case 'thanks':
            if(q.kind === 'Thanks'){
              thanks = q.items.map((c, index) => {
                return {
                  body: c[cmntyLanguage].search('{{username}}') === -1 ? c[cmntyLanguage] :
                      c[cmntyLanguage].replace('{{username}}', this.props.username),
                  id: c.id
                };
              });
              //console.log(thanks);

              while(flag){
                let index = Math.floor((Math.random() * thanks.length));
                //console.log('index:', index);
                let randomComment = thanks[index];
                //console.log(lastComment, 'randomComment:', randomComment);
                if(lastComment !== randomComment.body){
                  flag = false;
                  this.setState({body: randomComment.body}, () => {
                    if(aiComment && aiComment.comment === 'satisfies'){
                      this.saveComment(null)
                    }
                  });
                }
              }
            }
            break;
          case 'getBack':
            if(q.kind === 'Reply Later'){
              // console.log(q.kind);
              replyLater = q.items.map((c, index) => {
                return {
                  body: c[cmntyLanguage].search('{{username}}') === -1 ? c[cmntyLanguage] :
                      c[cmntyLanguage].replace('{{username}}', this.props.username),
                  id: c.id
                }
              });
              // console.log(replyLater);

              while(flag){
                let index = Math.floor((Math.random() * replyLater.length));
                let randomComment = replyLater[index];
                // console.log(lastComment, randomComment);
                if(lastComment !== randomComment.body){
                  flag = false;
                  this.setState({body: randomComment.body}, () => {
                    //this.saveComment({kind: q.kind, id: randomComment.id});
                  });
                }
              }
            }
            break;
        }
      });
    }

  };

  getQuickComment = async (data) => {
    const url =
        process.env.NODE_ENV === 'development'
            ? 'http://127.0.0.1:8000/createQuickComment/'
            : 'https://px-openai-api.herokuapp.com/createQuickComment/';

    const requestData = {data};

    this.setState({ isLoadingQuickComment: true });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify(requestData),
      });

      // On successful response, process the returned JSON
      if (response.ok) {
        const result = await response.json();
        const quickComment = result.quick_comment;
        // console.log('Quick Comment received by Python API:', quickComment);
        return quickComment;  // You can return it or set it to the state or do whatever processing you wish
      } else {
        console.error('Quick Comment request failed');
        throw new Error('Response not OK'); // Throw error to handle in the catch block
      }
    } catch (error) {
      console.error('Quick Comment request failed', error);
    } finally {
      this.setState({ isLoadingQuickComment: false });
    }
  }


  updateActiveReplyIdToReply = (activeReplyIdToReply) => {
    //console.log(activeReplyIdToReply);
    this.setState({activeReplyIdToReply});
  };

  updateActiveCommentIdToReply = (activeCommentIdToReply) => {
    //console.log(activeCommentIdToReply);
    this.setState({activeCommentIdToReply});
  };

  updateIsReplyOpen = () => {
    this.setState({isReplyOpen: !this.state.isReplyOpen});
  };

  autoModerationCommentReply = (responseId, savedCommentOrReply = {}) => {
    const postData = {
      response_id: responseId || this.props.responseId || savedCommentOrReply?.response_id,
      question_id: this.props.questionId,
      community_id: null,
      text_choices: null,
      all_text_choices: null,
      subdomain: null,
      moderation_type: 'comment'
    };

    axios.post(`/responses/post_process`, postData, getCSRF())
      .then(() => console.log("Post-processing job triggered"))
      .catch(error => console.error("Failed to trigger post-processing job:", error));
  }

  // We are using the same to save replies to Comments or Replies
  saveReplyToComment = async (commentId, replyId) => {
    const body = replyId === -1 ? this.state.replyBody : this.state.replyToReplyBody;
    // console.log('saving reply')

    try {
      let responseDbData = null
      if (this.props.responseId && this.props.responseId !== -1) {
        let responseDB = await this.updateModerationScheduled(this.props.responseId);
        responseDbData = responseDB?.data
        // console.log('responseDbData', responseDbData)
      }
      const responsePresent = !!responseDbData?.id
      const isParticipant = this.props.role_1 === 'Participant';
      const isCollaboratorWithNoModerationPending = this.props.role_1 === 'Collaborator' && this.state.aiModerationScheduled === false;
      const isReplyNotBlank = body !== '';

      if ( isReplyNotBlank && (isCollaboratorWithNoModerationPending || isParticipant)){
        this.setState({isSavingReply: true}, () => {
          $.ajax({
            url: '/reply/create/' + commentId + '/' + this.props.responseId + '/' + replyId,
            method: 'POST',
            dataType: 'JSON',
            beforeSend: function (xhr) {
              xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            },
            data: {
              reply: {
                body: body
              },
            },
            success: response => {
              // console.log("response:", response);
              const comments = response.comments;

              if (replyId === -1) {
                this.setState({replyBody: '', activeCommentIdToReply: -1, isSavingReply: false});
              } else {
                this.setState({replyToReplyBody: '', activeReplyIdToReply: -1, isSavingReply: false});
              }

              if (this.props.from === 'PAV' || this.props.from === 'PAS' || this.props.role_1 === 'Participant') {
                if (this.props.role_1 === 'Participant') {
                  this.props.updateResponseComments(comments, this.props.responseId, this.props.questionId);
                  this.autoModerationCommentReply(null, response.reply)
                } else {
                  this.props.updateResponseComments(comments, null);
                }
              } else {
                this.props.updateAUResponses(comments, comments[0].comment.response_id, false, this.props.userId,
                  'Comments.saveReplyToComment');
              }
            }
          });
        });
      }
    } catch (error) {
      console.error('Error in updateModerationScheduled:', error);
    }
  };

  saveComment = async kind => {
    // console.log('kind:', kind)
    try {
      if (this.props.responseId && this.props.responseId !== -1) {
        await this.updateModerationScheduled(this.props.responseId);
      }

      const isParticipant = this.props.role_1 === 'Participant';
      const isCollaboratorWithNoModerationPending = this.props.role_1 === 'Collaborator' && this.state.aiModerationScheduled === false;
      const isCommentNotBlank = this.state.body !== '';

      if ( isCommentNotBlank && (isCollaboratorWithNoModerationPending || isParticipant)) {
        // console.log('saving comment')
        this.setState({isSaving: true}, () => {
          $.ajax({
            url: '/comment/' + this.props.userId + '/' + this.props.questionId + '/' + this.props.responseId +
              '/' + this.state.typeUser,
            method: 'POST',
            dataType: 'JSON',
            beforeSend: function (xhr) {
              xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            },
            data: {
              comment: {body: this.state.body, kind},
              from: this.props.from,  // We don't need to send from to the server
              task_id: this.props.taskId //To get the active_participants and update the Dashboard - it's legacy
            },
            success: obj => {
              // console.log('save comment obj:', obj, 'from:', this.props.from);
              this.handleSetSendingFirstFFLoading(false);
              this.setState({body: '', isSaving: false, typeUser: 'user'}, () => {
                // After to set state body: '' the textarea resize get back to 1 line then the RESIZE function get trigger, this case
                // doesn't happen with 1 line, so the body: '' don't trigger the RESIZE of the textarea, for that reason we need
                // to force it here, to see more in details: Sometimes it would trigger several resizes when the infiniteList is scrolling,
                // it means a few jumps, it happens when you have around 9 comments.
                if (this.props.commentForceUpdateGrid) {
                  this.props.commentForceUpdateGrid();
                }
                //PAV = Participant Activity View, PAS = Participant Activity Social
                if (this.props.from === 'PAV' || this.props.from === 'PAS' || this.props.role_1 === 'Participant') {
                  // console.log('responseId:', this.props.responseId, 'role_1:', this.props.role_1);
                  if (this.props.role_1 === 'Participant') {
                    this.props.updateResponseComments(obj.comments, this.props.responseId === -1 ? obj.response :
                      this.props.responseId, this.props.questionId)
                    this.autoModerationCommentReply(obj.response?.id, obj?.comment)
                  } else {
                    this.props.updateResponseComments(obj.comments, this.props.responseId === -1 ? obj.response : null,
                      this.props.questionId);
                  }
                } else {
                  // TO-DO First comment from Coll needs to update the response (it include the response.id)
                  const {isFirstFFComment, responseId} = this.props;
                  // console.log(this.props.from, isFirstFFComment);
                  this.props.updateResponseComments(obj.comments, responseId === -1 ? obj.response : responseId,
                    isFirstFFComment ? isFirstFFComment.isFirstFFComment : false);
                }
              });
            }
          });
        });
      }

    } catch (error) {
      console.error('Error in updateModerationScheduled:', error);
    }
  };

  stripHtml = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  extractSelectedTextsInChoiceQuestion = (response) => {
    const selectedAnswers = response.data.answersId;
    const allAnswers = response.task.data.answers;

    const selectedTexts = selectedAnswers.map(answer => {
      const foundAnswer = allAnswers.find(a => a.id === answer.id);
      return foundAnswer ? foundAnswer.text : null;
    }).filter(Boolean);

    return selectedTexts.join('; ');
  }

  extractAllChoicesInQuestion = (response) => {
    if (response) {
      const allAnswers = response.task.data.answers;
      const allChoicesTextArray = allAnswers.map((answer, index) => `${index + 1}. ${answer.text}`);
      return allChoicesTextArray.join('; ');
    }
  };
}


