import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TimeAgo from "react-timeago";
import Dotdotdot from "react-dotdotdot";
import Truncate from 'react-truncate';
import axios from 'axios';

import {notifications_i18n} from "../UI/Globals/PX_i18n";
import {usernamesToDisplay} from "../Community/shared_utils";

export default class Notification extends React.Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    formatter: PropTypes.func.isRequired,
    sltType: PropTypes.string.isRequired,
    updateRightDrawerType: PropTypes.func.isRequired,
    updateOpenPMFromOutside: PropTypes.func,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isHighlight: props.notification.notification.is_highlight
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.notification.notification.is_highlight !== this.props.notification.notification.is_highlight){
      this.setState({isHighlight: this.props.notification.notification.is_highlight});
    }
  }

  render() {
    const {notification, language, formatter, sltType, colors} = this.props;

    let isShow = false;
    const notiType = notification.notification.entity_type;
    if(sltType === 'All'){
      isShow = true;
    }
    if(sltType === 'Responses' && (notiType === 'Response,Draft' || notiType === 'Response,Follow')){
      isShow = true;
    }
    if(sltType === 'Comments' && (notiType === 'Comment' || notiType === 'Reply')){
      isShow = true;
    }
    if(sltType === 'Likes' && (notiType === 'Like your Response' || notiType === 'Like your Comment')){
      isShow = true;
    }
    if(sltType === 'Community' && ( notiType === 'Profile' || notiType === 'Response,Follow' ||
      notiType === 'Like Response,Follow' || notiType === 'Like Comment,Follow' || notiType === 'CU,User invited' ||
      notiType === 'CU,User joined' || notiType === 'CU,User declined' || notiType === 'CU,User blocked' ||
      notiType === 'CU,User removed' || notiType === 'Task,Available' || notiType === 'Task,AboutExpire' ||
      notiType === 'Task,Expired' || notiType === 'Task,Completed')){
      isShow = true;
    }

    if(sltType === 'Messages' && (notiType === 'PM' || notiType === 'Broadcast')){
      isShow = true;
    }

    if(isShow){
      const avatar = notification.from_avatar === '' ? '/assets/user.png' : notification.from_avatar;
      const card = this._getCardData(notification, language, formatter);

      let userBadge = null;
      //console.log(notification);
      if(notification.user_from_roles !== null){
        if(notification.user_from_roles.role_1 === 'Collaborator'){
          switch(notification.user_from_roles.role_2){
            case 'Owner':
            case 'Moderator':
              userBadge = <div className="badge-moderator" style={{ backgroundColor: colors.color1[3] }}> <FontAwesomeIcon icon={['fas', 'gavel']}/></div>;
              break;
          }
        }
      }

      return (
        <React.Fragment>
          <div onClick={() => this.handleHighlight(notification.notification.id)}
               style={{width:'10px',height:'100px',backgroundColor:'rgba(0, 0, 0, 0)', position:'absolute',
                 float:'left',cursor:'pointer'}} data-tooltip-content={ this.state.isHighlight ? "unflag it" : "flag it"}/>
          <div className="notification-card" style={{ borderLeft: `solid 10px ${this.state.isHighlight ? colors.color0[3] : '#dadde1'}`}} >
            <div className="header-container">
              <div className="avatar-container text-center dropdown">
                <img src={avatar} alt="avatar" className="avatar" data-toggle="dropdown"/>
                {/*<div className="overlay-avatar" data-toggle="dropdown">
                  <FontAwesomeIcon icon={['fas','ellipsis-vertical']}/>
                </div>
                <ul className="dropdown-menu dropdown-general-options-no-hide">
                  <li><a href="#">profile</a></li>
                  <li><a href="#">see responses</a></li>
                  <li><a href="#">follow</a></li>
                  <li><a href="#">send email</a></li>
                </ul>*/}
                { userBadge }
              </div>
              <div className="info-container">
                { card.title }
                <div className="activity">
                </div>
              </div>
              <div className="icon-container">
                { card.icon }
              </div>
            </div>
            <div className="actions-container">
              <div className="left-container">
                { card.left }
              </div>
              <div className="right-container">
                { card.right }
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  handleHighlight = async (id) => {
    const config = {headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}};
    const response = await axios.patch(`/notifications/update_highlight/${id}`, {}, config);
    console.log(response.data);
    this.setState({isHighlight: response.data.is_highlight});
  };

  _getCardData(notification, language, formatter){
    let card = {};
    const timeAgo = <span className="date">
      <TimeAgo date={notification.notification.created_at} formatter={formatter}/></span>;
    let btnFollow = null;
    let url = null;
    const username = notification.alias_from === '' ? notification.from_username : notification.alias_from;
    const colors = this.props.colors;

    /*if(notification.user_from_roles !== null){
      if(notification.user_from_roles.role_1 === 'Collaborator'){
        switch(notification.user_from_roles.role_2){
          case 'Owner':
          case 'Moderator':
            btnFollow = 'Moderator';
            break;
          default:
            btnFollow = <button className={notification.is_follow ? 'btn btn-unfollow' : 'btn btn-follow'}>
              {notification.is_follow ? 'Follow' : 'Unfollow'}
            </button>;
            break;
        }
      } else {
        btnFollow = <button className={notification.is_follow ? 'btn btn-unfollow' : 'btn btn-follow'}>
          {notification.is_follow ? 'Unfollow' : 'Follow'}
        </button>;
      }
    }*/

    /*** About You ***/
    switch(notification.notification.entity_type){
      case 'PM':
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{ usernamesToDisplay(notification)[0]}</span>
          <span onClick={() => this.props.updateRightDrawerType('PM', notification.community_id, usernamesToDisplay(notification)[0],
            notification.notification.user_id_from)}
            className="action" style={{cursor: 'pointer'}}> {notifications_i18n[language].sent_pm}</span>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'envelope']} className="remove" style={{ color: colors.color1[3]}}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
      case 'Broadcast':
        card.title = <span>
          <span className="username" style={{color: colors.color1[3]}}>{username}</span>
          <span onClick={() => this.props.updateRightDrawerType('Broadcast', notification.community_id, notification.from_username, notification.notification.user_id_from)}
            className="action" style={{ cursor: 'pointer' }}> {notifications_i18n[language].sent_pm}</span>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'envelope']} className="remove" style={{color: colors.color1[3]}}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
      case 'Comment':
        url = notification.user_to_roles !== null && notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/'+ notification.response_owner_id + '/' +
          notification.task_id +'/' + notification.question.id +'/Activities/?flag=true' +
          '&itemId=' + notification.notification.entity_id + '&itemtype=comment' :
          '/activities/' + notification.community_id + '/activity/' + notification.task_id + '/social/comment/' +
          notification.notification.entity_id + '/act_user/' + notification.response_owner_id + '/scroll/' + notification.question.id + '/-1';
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{usernamesToDisplay(notification)[0]}</span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <span className="action"> {notifications_i18n[language].commented_on}</span>
            <Truncate lines={2} className="action">
              "{notification.question_title.replace(/(<([^>]+)>)/ig, "")}"
            </Truncate>
          </a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'message']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
      case 'CommentDigest':
        card.title = <span>
          <span className="action">{notifications_i18n[language].you_have} {notification.notification.entity_id} {notifications_i18n[language].unread_comments}.</span>
          <span className="action">{notifications_i18n[language].multi_unread_msg}</span>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'message']} className="remove" style={{color: colors.color1[3]}}/>;
        card.right = timeAgo;
        break;
      case 'Reply':
        url = notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/'+ notification.response_owner_id +'/' +
          notification.task_id +'/' + notification.question.id +'/Activities/?flag=true' +
          '&itemId=' + notification.notification.entity_id + '&itemtype=reply' :
          '/activities/' + notification.community_id + '/activity/' + notification.task_id + '/social/reply/' +
          notification.notification.entity_id + '/act_user/' + notification.response_owner_id + '/scroll/' +
          notification.question.id + '/-1';
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{usernamesToDisplay(notification)[0]}</span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <span className="action"> {notifications_i18n[language].reply_on}</span>
            <br/>
            <Truncate lines={2} className="action">
              "{notification.question_title.replace(/(<([^>]+)>)/ig, "")}"
            </Truncate>
            </a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'reply']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
      case 'Like your Response':
        //console.log('notification.user_to_roles:', notification.user_to_roles);
        url = notification.user_to_roles.role_1 === 'Participant' ?
          '/activities/' + notification.community_id + '/activity/' + notification.task_id + '/social/likeResponse/' +
          notification.notification.entity_id + '/act_user/' + notification.notification.user_id_to + '/scroll/' +
          notification.question.id + '/-1' : '';
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <span className="action"> {notifications_i18n[language].like_ur_response}</span>
            <br/>
            <Truncate lines={2} className="action">
              "{notification.question_title.replace(/(<([^>]+)>)/ig, "")}"
            </Truncate>
          </a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'heart']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
      case 'Like your Comment': // Likes to Comments and Replies
        if(notification.comment_obj.type === 'comment'){
          url = notification.user_to_roles.role_1 === 'Collaborator' ?
            '/communities/' + notification.community_id +'/transcript/'+ notification.response_owner_id + '/' +
            notification.task_id +'/' + notification.question.id +'/Activities/?flag=true' +
            '&itemId=' + notification.comment.id + '&itemtype=like' :
            '/activities/' + notification.community_id + '/activity/' + notification.task_id + '/social/like/' +
            notification.notification.entity_id + '/act_user/' + notification.response_owner_id + '/scroll/' +
            notification.question.id + '/' + notification.comment.id;
        } else {
          url = notification.user_to_roles.role_1 === 'Collaborator' ?
            '/communities/' + notification.community_id +'/transcript/'+ notification.response_owner_id +'/' +
            notification.task_id +'/' + notification.question.id +'/Activities/?flag=true' +
            '&itemId=' + notification.comment_obj.id + '&itemtype=likeReply' :
            '/activities/' + notification.community_id + '/activity/' + notification.task_id + '/social/likeReply/' +
            notification.notification.entity_id + '/act_user/' + notification.response_owner_id + '/scroll/' +
            notification.question.id + '/' + notification.comment_obj.id;
        }

        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <span className="action"> {notifications_i18n[language].like_ur_comment}</span>
            <br/>
            <Truncate lines={2} className="action">
              "{notification.comment_obj.body}"
            </Truncate>
          </a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'heart']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
      case 'Profile':
        card.title = <span className="username" style={{ color: colors.color1[3] }}>{username}
          <span className="action"> {notifications_i18n[language].saw_profile} {notifications_i18n[language].profile}</span></span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'eye']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
    }

    /*** About People You Follow ***/
    switch(notification.notification.entity_type){
      case 'Response,Follow':
        url = notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/'+ notification.notification.user_id_from +'/' +
          notification.task_id +'/'+  notification.question.id + '/Activities/?flag=true' :
          '/activities/' + notification.community_id + '/activity/' + notification.task_id + '/social/response/' +
          notification.notification.entity_id + '/act_user/' + notification.notification.user_id_from + '/scroll/' +
          notification.question.id + '/-1';
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <span className="action"> {notifications_i18n[language].follow_response}</span>
            <br/>
            <Truncate lines={2} className="action">
              "{notification.question.title.replace(/(<([^>]+)>)/ig, "")}"
            </Truncate>
          </a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'pen-clip']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
      case 'Like Response,Follow':
        url = notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/'+ notification.followed.active_user_id +'/' +
          notification.task_id +'/'+  notification.question.id + '/Activities/?flag=true' :
          '/activities/' + notification.community_id + '/activity/' + notification.task_id + '/social/response/' +
          notification.followed.response_id + '/act_user/' + notification.followed.active_user_id + '/scroll/' +
          notification.question.id + '/-1';
        /*url = notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/'+ notification.notification.user_id_from +'/' +
          notification.task_id +'/'+  notification.question.id + '/Activities/?flag=true' :
          '/community/' + notification.community_id + '/activity/' + notification.task_id + '/social/response/' +
          notification.followed.response_id + '/act_user/' + notification.followed.active_user_id + '/scroll/' +
          notification.question.id + '/-1';*/
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <span className="action"> {notifications_i18n[language].like_response_follow_1}</span>
          <span className="username" style={{ color: colors.color1[3] }}> {notification.followed.username}</span>
          <span className="action"> {notifications_i18n[language].like_response_follow_2}</span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <br/>
            <Truncate lines={2} className="action">
              "{notification.question.title.replace(/(<([^>]+)>)/ig, "")}"
            </Truncate>
          </a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'heart']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
      case 'Like Comment,Follow':
        url = notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/'+ notification.response_owner_id +'/' +
          notification.task_id +'/' + notification.question.id +'/Activities/?flag=true' +
          '&itemId=' + notification.comment_obj.id + '&itemtype=like' :
          '/activities/' + notification.community_id + '/activity/' + notification.task_id + '/social/like/' +
          notification.followed.like_id + '/act_user/' + notification.response_owner_id + '/scroll/' +
          notification.question.id + '/' + notification.comment_obj.id;
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <span className="action"> {notifications_i18n[language].like_response_follow_1}</span>
          <span className="username" style={{ color: colors.color1[3] }}> {notification.followed.username}</span>
          <span className="action"> {notifications_i18n[language].like_comment_follow}</span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <br/>
            <Truncate lines={2} className="action">
              "{notification.comment_obj.body}"
            </Truncate>
          </a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'heart']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
    }

    /*** About The Community ***/
    switch(notification.notification.entity_type){
      case 'CU,User invited':
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <span className="action"> {notifications_i18n[language].user_invited}</span>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'user-plus']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = null;
        card.right = timeAgo;
        break;
      case 'CU,User joined':
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{usernamesToDisplay(notification)[0]}</span>
          <span className="action"> {notifications_i18n[language].user_joined}</span>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'handshake-simple']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
      case 'CU,User declined':
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <span className="action"> {notifications_i18n[language].user_declined}</span>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'surprise']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = null;
        card.right = timeAgo;
        break;
      case 'CU,User blocked':
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <span className="action"> {notifications_i18n[language].user_blocked}</span>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'lock']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = null;
        card.right = timeAgo;
        break;
      case 'CU,User removed':
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <span className="action"> {notifications_i18n[language].user_removed}</span>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'trash']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = null;
        card.right = timeAgo;
        break;
    }

    /*** About Activities ***/
    switch(notification.notification.entity_type){
      case 'Task,Available':
        url = notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/all/' + notification.task_id +'/all/Activities' :
          '/activity/' + notification.task_id + '/' + notification.community_id;
        card.title = <span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <span className="username" style={{ color: colors.color1[3] }}>{notifications_i18n[language].activity_available}</span>
          <Dotdotdot clamp={2}><span className="action">{notification.task.title}</span></Dotdotdot></a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'tasks']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = <span>{notifications_i18n[language].moderator}</span>;
        card.right = notification.task.expire ? <label className="btn-notice" style={{ backgroundColor: colors.color1[3] }}>{notifications_i18n[language].expires}&nbsp;
          <TimeAgo date={notification.task.expire} formatter={formatter}/></label> :
          <label className="btn-notice" style={{ backgroundColor: colors.color1[3] }}>{notifications_i18n[language].neverExpires}</label>;
        break;
      case 'Task,AboutExpire':
        url = notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/all/' + notification.task_id +'/all/Activities' :
          '/activity/' + notification.task_id + '/' + notification.community_id;
        card.title = <span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <Dotdotdot clamp={1}><span className="username expire-text" style={{ color: colors.color4[3] }}>{notifications_i18n[language].activity_about_exp}</span></Dotdotdot>
          <Dotdotdot clamp={2}><span className="action">{notification.task.title}</span></Dotdotdot></a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'alarm-exclamation']} className="remove orange" style={{ color: colors.color4[3] }}/>;
        card.left = <span>{notifications_i18n[language].moderator}</span>;
        card.right = <label className="btn-notice orange" style={{ backgroundColor: colors.color4[3] }}>
          {notifications_i18n[language].expires}&nbsp;{notification.task.expire && <TimeAgo date={notification.task.expire} formatter={formatter}/>}
        </label>;
        break;
      case 'Task,Expired':
        url = notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/all/' + notification.task_id +'/all/Activities' :
          '/activity/' + notification.task_id + '/' + notification.community_id;
        card.title = <span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <Dotdotdot clamp={1}>
              <span className="username expire-text" style={{ color: colors.color4[3] }}>{notifications_i18n[language].activity_expired}</span>
            </Dotdotdot>
          <Dotdotdot clamp={2}><span className="action">{notification.task.title}</span></Dotdotdot>
          </a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'do-not-enter']} className="remove orange" style={{ color: colors.color4[3] }}/>;
        card.left = <span>{notifications_i18n[language].moderator}</span>;
        card.right = <label className="btn-notice orange" style={{ backgroundColor: colors.color4[3] }}>{notifications_i18n[language].expired}&nbsp;
          <TimeAgo date={notification.task.expire} formatter={formatter}/></label>;
        break;
      case 'Response,Draft':
        url = notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/'+ notification.notification.user_id_from +'/' +
          notification.task_id +'/'+  notification.question.id + '/Activities/?flag=true' :
          '';
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <span className="action"> {notifications_i18n[language].task_drafted}</span>
            <br/>
            <Truncate lines={2} className="action">
              "{notification.question_title.replace(/(<([^>]+)>)/ig, "")}"
            </Truncate>
          </a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'pencil']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
      case 'Task,Completed':
        url = notification.user_to_roles.role_1 === 'Collaborator' ?
          '/communities/' + notification.community_id +'/transcript/'+ notification.notification.user_id_from +'/' +
          notification.task_id +'/all/Activities/?flag=false' :
          '/activities/' + notification.community_id + '/activity/' + notification.task_id + '/social/task_completed/' +
          notification.task_id + '/act_user/' + notification.notification.user_id_from + '/scroll/' + -1 +
          '/-1';
        card.title = <span>
          <span className="username" style={{ color: colors.color1[3] }}>{username}</span>
          <a onClick={() => this._clearForwardUrl(url)} style={{cursor:'pointer'}}>
            <span className="action"> {notifications_i18n[language].activity_completed}</span>
            <Dotdotdot clamp={2}>
              <span className="action"> "{notification.task.title}"</span>
            </Dotdotdot>
          </a>
        </span>;
        card.icon = <FontAwesomeIcon icon={['fas', 'pen-clip']} className="remove" style={{ color: colors.color1[3] }}/>;
        card.left = btnFollow;
        card.right = timeAgo;
        break;
    }

    return card;
  }

  _clearForwardUrl(url){
    localStorage.clear();
    window.location.href = url;
  }
}
