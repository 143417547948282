import React, { useRef, useEffect } from "react"
import MessageCard from "./MessageCard";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter"
import spanishStrings from "react-timeago/lib/language-strings/es"
import englishStrings from "react-timeago/lib/language-strings/en"

const MessagesMain = ({ messages, currentUserId, language, avatar, colors, lastMsgId, setLastChatId,
                        getResponseEmbeddings, from, loadingResponseEmbeddings }) => {
  const formatter = language === 'es' ? buildFormatter(spanishStrings) :
    buildFormatter(englishStrings)
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth"})
  }

  useEffect(() => {
    scrollToBottom()
  }, [scrollToBottom, messages])

  return (
    <div className="messages-items-container">
      <div className="conversations-container">
        {
          messages.map(message =>
            <MessageCard key={message.id}
                         message={message}
                         currentUserId={currentUserId}
                         language={language}
                         avatar={avatar}
                         colors={colors}
                         lastMsgId={lastMsgId}
                         setLastChatId={setLastChatId}
                         formatter={formatter}
                         getResponseEmbeddings={getResponseEmbeddings}
                         from={from}
                         loadingResponseEmbeddings={loadingResponseEmbeddings}
            />
          )
        }
      </div>
      <div ref={messagesEndRef} />
    </div>
  )
}

export default MessagesMain