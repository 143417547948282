import PropTypes from 'prop-types';
import React from 'react';

import Slider from "react-slick"; //TO-DO maybe for the Transcript View I will need the css for this plugin

export default class DMenu extends React.Component {
  static propTypes = {
    activeLink: PropTypes.string.isRequired,
    updateActiveLink: PropTypes.func.isRequired,
    role_2: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    $('.item').css('border-color', this.props.colors.color0[4]);
  }

  render() {
    const {activeLink, role_2, language, isMobile} = this.props;

    const i18n = {
      en: { activities: "ACTIVITIES", participants: "PARTICIPANTS", panorama: "PANORAMA", now: "NOW!", chat: 'CHAT' },
      es: { activities: "ACTIVIDADES", participants: "PARTICIPANTES", panorama: "PANORAMA", now: "AHORA!", chat: 'CHAT' }
    };

    const sliderNavSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 3,
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 2560,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 812,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 568,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 no-pd text-center">
            <div className="px-slick-navigation-container">
              <Slider {...sliderNavSettings} className="px-slick-navigation-menu">
                {
                  !isMobile &&
                  <div onClick={() => this.props.updateActiveLink('Matrix')}
                       className={activeLink === 'Matrix' ? 'item selected' : 'item'}>{i18n[language].panorama}
                  </div>
                }
                <div onClick={() => this.props.updateActiveLink('Activities')}
                     className={activeLink === 'Activities' ? 'item selected' : 'item'}>{i18n[language].activities}
                </div>
                <div onClick={() => this.props.updateActiveLink('Participants')}
                  className={activeLink === 'Participants' ? 'item selected' : 'item'}>{i18n[language].participants}
                </div>
                {
                  role_2 === "Recruiter" || role_2 === "" ? "" :
                    <div onClick={() => this.props.updateActiveLink('Now')}
                         className={activeLink === 'Now' ? 'item selected' : 'item'}>{i18n[language].now}</div>
                }
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div className={className}
         style={{...style, display: 'block', marginLeft:'15px', borderRadius:'50%'}}
         onClick={onClick}/>
  );
}
function SamplePrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div className={className}
         style={{...style, display: 'block', marginRight:'15px', zIndex:'1' , borderRadius:'50%'}}
         onClick={onClick}/>
  );
}
