import PropTypes from 'prop-types';
import React from 'react';
import {getCountryName, setIconGender} from "../../../UI/Globals/PX_Funs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MUSeeNotes from "../../../UI/MUSeeNotes";
import downloadjs from "downloadjs";
import {usernamesToDisplay} from "../../shared_utils";

export default class TBUserCardBig extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    showModalExportHandler: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    isVirtualized: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    isMax: PropTypes.bool,
    toggleUserIsMax: PropTypes.func,
    role_2: PropTypes.string,
    getUrlsToDownload: PropTypes.func,
    colors: PropTypes.object.isRequired,
    doRecomputeRowHeights: PropTypes.func,
    index: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      isMax: false,
      showSeeNotes: false
    }
  }

  render() {
    const {user, from, isVirtualized, isMax, language, role_2, colors} = this.props;

    const regionCountry = user.state_country === '' ? getCountryName(user.country) :
      user.state_country + ', ' + getCountryName(user.country);

    let gender = user.gender !== '' && <div className="px-genders-container">
      <div className="gender-container" data-tooltip-content={user.gender}>{setIconGender(user.gender)}</div>
    </div>;
    let location = user.country && <div className="px-country-container" data-tooltip-content={regionCountry}>
      <span>{user.country}</span></div>;
    if(from === 'Participant'){
      gender = (user.gender !== '' && user.show_gender) && <div className="px-genders-container">
        <div className="gender-container">{setIconGender(user.gender)}</div></div>;
      location = (user.country && user.show_location) &&
        <div className="px-country-container" data-tooltip-content={regionCountry}><span>{user.country}</span></div>;
    }

    const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
    const mainName = availableUsernames[0];
    const alternativeNames = availableUsernames.length > 1 ? availableUsernames.slice(1).join(', ') : ''

    return (
      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-lg-offset-1 col-md-offset-1">
        <div className="panel px-card px-card-profile">
          <div className="panel-body">
            <img src={user.avatar !== '' ? user.avatar : '/assets/user.png'} className="img-profile img-circle" alt=""/>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <div className="title-text-container">
                      <div className="fixed-icons">
                        <p className="profile-name" style={{cursor: 'default'}} data-tooltip-content={alternativeNames ? `a.k.a:  ${alternativeNames}` : ''}>{mainName}</p>
                        {/*<div style={{fontSize: '12px'}}>*/}
                        {/*  {alternativeNames && `(a.k.a:  ${alternativeNames})`}*/}
                        {/*</div>*/}

                        <div>
                          {/*
                            from === 'Collaborator' &&
                            <FontAwesomeIcon onClick={() => this.downloadFiles('images', 'all', null)}
                                             className="fixed-contract" icon={['fas', 'download']} style={{ cursor: 'pointer' }} />
                          */}
                          <FontAwesomeIcon
                            onClick={isVirtualized ? () => this.toggleUserIsMaxRecalculatingHeight(user.id) : this.handleIsMax}
                            className={isVirtualized ? isMax ? 'fixed-contract active' : 'fixed-contract' :
                              isVirtualized ? 'fixed-contract active' : 'fixed-contract'}
                            icon={['fas', 'circle-info']}/>
                          {
                            from === 'Collaborator' &&
                            <FontAwesomeIcon onClick={this.updateShowSeeNotes}
                                             className="fixed-contract" icon={['fas', 'note-sticky']}
                                             style={{cursor: 'pointer'}}/>
                          }
                          {
                            from === 'Collaborator' &&
                            <FontAwesomeIcon onClick={() => this.props.showModalExportHandler(null, null, [user.id])}
                                             className="fixed-contract" icon={['fas', 'share']}
                                             style={{cursor: 'pointer'}}/>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
                    <div className="separator mg-bottom-10"/>
                    <div className="row px-profile-info-container">
                      <div className={isMax ? 'left-container' : 'left-container minimized '}>
                        <div className="add-container">
                          {
                            from === 'Collaborator' &&
                            <div className="px-tags-container">
                              {
                                user.segments.map(segment => {
                                  return(
                                    <FontAwesomeIcon key={segment.id} icon={['fas','bookmark']} className="tag"
                                                     style={{color: segment.color}} data-tooltip-content={segment.name}/>
                                  )
                                })
                              }
                            </div>
                          }
                          { gender }
                          { location }
                        </div>
                        {
                          (from === 'Collaborator' &&
                            (role_2 === 'Owner' || role_2 === 'Moderator' || role_2 === 'Recruiter')) &&
                          <p className="email">{user.email}</p>
                        }
                      </div>
                      <div className={isVirtualized ? isMax ? 'right-container' : 'right-container minimized' :
                        this.state.isMax ? 'right-container' : 'right-container minimized'}>
                        <p className="data-right">{user.cu_bio}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={isMax ? 'panel-footer' : 'panel-footer minimized'}>
            <div className="stats-container">
              <div className="stat-item" data-tooltip-content="Likes received">
                <span className="like-in"/>
                <div className="number">{'0'}</div>
              </div>
              <div className="stat-item" data-tooltip-content="Comments received">
                <span className="comment-in"/>
                <div className="number">{'0'}</div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showSeeNotes &&
          <MUSeeNotes showSeeNotes={this.state.showSeeNotes}
                      updateShowSeeNotes={this.updateShowSeeNotes}
                      user={user}
                      language={language}
                      colors={colors}/>
        }
      </div>
    );
  }

  downloadFiles = async (type, action, urlOne) => {
    // console.log(type, action);
    const {response, user, task} = this.props;

    this.props.getUrlsToDownload(user.id);


    /*this.setState({isSavingImages: true, savedImagesCounter: 0}, async() => {
      if(type === 'images'){
        if(action === 'one'){
          const blob = await fetch(decodeURIComponent(urlOne)).then(r => r.blob());
          console.log('blob:', blob);
          downloadjs(blob, Date.now(), blob.type);
          this.setState({isSavingImages: false});
        } else {
          const zip = new JSZip();

          for(const attachment of response.attachments){
            console.log(decodeURIComponent(attachment.url));
            const blob = await fetch(decodeURIComponent(attachment.url)).then(r => r.blob());
            console.log('blob:', blob);
            let extension = '';
            switch (blob.type) {
              case 'image/jpeg': extension = 'jpg';break;
              case 'image/png': extension = 'png';break;
              default: extension = 'jpg';
            }

            zip.file(Date.now() + '.' + extension, blob, {base64: true});
            this.setState(prevState => ({savedImagesCounter: prevState.savedImagesCounter + 1}));
          }

          if(response.video_url !== ''){
            const blob = await fetch(decodeURIComponent(response.video_url)).then(r => r.blob());
            console.log('blob:', blob);
            //download(blob, Date.now(), blob.type);
            const extension = blob.type === 'video/mp4' ? 'mp4' : '';
            zip.file(Date.now() + '.' + extension, blob, {base64: true});
            this.setState(prevState => ({savedImagesCounter: prevState.savedImagesCounter + 1}));
          }




          zip.generateAsync({type:"blob"}).then((content) => {
            const zipName = user.user_alias === '' ? user.username : user.user_alias.substr(1);
            const actTitle = task.title.replace(/(<([^>]+)>)/ig,"").substr(0, 8);

            saveAs(content, zipName + '('+ actTitle + ')' + '.zip');
            console.log('Ready');
            this.setState({isSavingImages: false});
          });
        }
      } else {
        // VIDEOS
      }
    });*/
  };

  updateShowSeeNotes = () => {
    this.setState(prevState => ({showSeeNotes : !prevState.showSeeNotes}));
  }

  handleIsMax = () => {
    this.setState(prevState => ({isMax: !prevState.isMax}));
  };

  toggleUserIsMaxRecalculatingHeight = (user_id) => {
    this.props.toggleUserIsMax(user_id)
    if (this.props.index) {
      this.props.doRecomputeRowHeights(this.props.index)
    }
  }
}