import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ModalLegend extends React.Component {
  static propTypes = {
    //name: PropTypes.string.isRequired, // this is passed from the Rails view
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="modal fade" id="modal-id-legend">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                      data-dismiss="modal" aria-hidden="true">
                <i className="material-icons" style={{color: '#333'}}>clear</i>
              </button>
              <div className='px-title-warning-container'>
                <h4 className="px-icon-warning" style={{ color:'#528FFF'}}><FontAwesomeIcon icon={['fal', 'circle-question']} /></h4>
              </div>
            </div>

            <div className="modal-body body-delete-activity text-center">
              <p>
                <strong>Click</strong> over circles
                <span style={{ color: '#45505d' }}>
                  <FontAwesomeIcon icon={['fas', 'circle']} />
                </span>, usernames, and activity titles to go to participant responses
              </p>
              {/* <p>
                <strong>Right click</strong> over circles
                <span style={{ color: '#45505d' }}>
                  <FontAwesomeIcon icon={['fas', 'circle']} />
                </span> to access more task options
              </p> */}
              <p>
                <strong>Click</strong> over ellipsis icons
                <span style={{ color: "#AFBDC1" }}>
                  <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                </span> to access more options
              </p>
              <p>
                <strong>Colors</strong> in circles show task status
              </p>
              <p>
                <strong>Red borders</strong>
                <span style={{ color: '#aafb9b' }}>
                  <FontAwesomeIcon icon={['fas', 'circle']} style={{ border: '1px solid #D95B2D', borderRadius: '50%' }} />
                </span> require your attention
              </p>
              <div className="row text-left" style={{ borderTop: "1px solid #DADDE1", marginTop: "25px", paddingTop: "20px"}}>
                <ul className="col-sm-6" style={{ listStyleType: 'none'}}>
                  <li><span style={{ color: "#aafb9b"}}><FontAwesomeIcon icon={['fas', 'circle']} /></span> A moderator accepted this response</li>
                  <li><span style={{ color: "#48e1cd" }}><FontAwesomeIcon icon={['fas', 'circle']} /></span> A participant completed this task</li>
                  <li><span style={{ color: "#45505d" }}><FontAwesomeIcon icon={['fas', 'circle']} /></span> A participant drafted this task</li>
                  <li><span style={{ color: "#afbdc1" }}><FontAwesomeIcon icon={['fas', 'circle']} /></span> A participant hasn’t answered this task</li>
                  <li><span style={{ color: "#ffffff" }}><FontAwesomeIcon icon={['fas', 'circle']} style={{ border: '1px solid #AFBDC1', borderRadius: '50%' }}/></span> A task is not available yet</li>
                  <li><span style={{ color: "#ffffff" }}><FontAwesomeIcon icon={['fas', 'circle']} style={{ border: '1px solid #D95B2D', borderRadius: '50%' }}/></span> Red borders require your attention. You may have unread comments or overdue responses</li>
                </ul>
                <ul className="col-sm-6" style={{ listStyleType: 'none' }}>
                  <li><span style={{ color: "#528fff" }}><FontAwesomeIcon icon={['fas', 'tag']} /></span> Activity tags help you categorize tags to keep things tidy</li>
                  <li><span style={{ color: "#AFBDC1" }}><FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} /></span> Triggers a menu with additional options</li>
                  <li><span style={{ color: "#AFBDC1" }}><i className="mdi mdi-arrow-expand-horizontal contract-icon"/></span> Contracts/ expands columns</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}