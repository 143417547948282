import PropTypes from 'prop-types';
import React from 'react';
import { Player, BigPlayButton, ControlBar, VolumeMenuButton } from 'video-react';
import {capitalizeSentences, isNullOrUndefinedOrEmptyObject, showSuccessMsg} from "../../../UI/Globals/PX_Funs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "video-react/dist/video-react.css";
import {Tooltip as ReactTooltip} from "react-tooltip"; // Import CSS for video-react

export default class TRModalVideo extends React.Component {
  static propTypes = {
    updateShowModalVideo: PropTypes.func.isRequired,
    thumbnail: PropTypes.string,
    url: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    response: PropTypes.object,
    colors: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    this.state = {
      searchTerm: '',
      currentMatchIndex: 0,
      matches: [],
      autoScroll: true,
      currentHighlightedIndex: null
    };
  }

  componentDidMount() {
    if (this.playerRef.current) {
      this.playerRef.current.subscribeToStateChange(this.handleStateChange);
    }
  }

  handleStateChange = (state) => {
    if (this.state.autoScroll && state.currentTime) {
      this.scrollTranscriptToCurrentTime(state.currentTime);
    }
  };

  handleWordClick = (startTime) => {
    if (this.playerRef.current) {
      this.playerRef.current.seek(startTime);
    }
  };

  handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchTerm }, this.highlightMatches);
  };

  highlightMatches = () => {
    const { response } = this.props;
    const { searchTerm } = this.state;

    if (!response || !response.video_transcript_data || !searchTerm) {
      this.setState({ matches: [], currentMatchIndex: 0 });
      return;
    }

    const transcriptData = response.video_transcript_data;
    const items = transcriptData.results.items;
    const matches = [];

    items.forEach((item, index) => {
      if (item.type === "pronunciation" && item.alternatives[0].content.toLowerCase().includes(searchTerm.toLowerCase())) {
        matches.push(index);
      }
    });

    this.setState({ matches, currentMatchIndex: 0 }, () => {
      if (matches.length > 0) {
        this.scrollToMatch(0);
      }
    });
  };

  handleSearchKeyDown = (e) => {
    const { matches, currentMatchIndex } = this.state;
    if (e.key === 'Enter' && matches.length > 0) {
      const nextIndex = (currentMatchIndex + 1) % matches.length;
      this.setState({ currentMatchIndex: nextIndex }, () => {
        this.scrollToMatch(nextIndex);
      });
    }
  };

  scrollToMatch = (index) => {
    const matchIndex = this.state.matches[index];
    if (matchIndex !== undefined) {
      const element = document.getElementById(`transcript-word-${matchIndex}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  copyTranscriptToClipboard = () => {
    const { response, language } = this.props;
    const i18n = {
      en: {transcriptCopied: 'Transcript copied to clipboard'},
      es: {transcriptCopied: 'Transcript copiado a portapapeles'}
    }

    if (response && response.video_transcript) {
      navigator.clipboard.writeText(response.video_transcript).then(() => {
        showSuccessMsg(i18n[language].transcriptCopied);
      });
    }
  };

  toggleAutoScroll = () => {
    this.setState((prevState) => ({ autoScroll: !prevState.autoScroll }));
  };

  scrollTranscriptToCurrentTime = (currentTime) => {
    const { response } = this.props;
    if (!response || isNullOrUndefinedOrEmptyObject(response.video_transcript_data)) return;

    const transcriptData = response.video_transcript_data;
    const items = transcriptData?.results?.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type === "pronunciation") {
        const startTime = parseFloat(item.start_time);
        const endTime = parseFloat(item.end_time);

        if (currentTime >= startTime && currentTime <= endTime) {
          this.setState({ currentHighlightedIndex: i });
          const element = document.getElementById(`transcript-word-${i}`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          break;
        }
      }
    }
  };

  renderTranscript() {
    const { response, colors } = this.props;
    const { searchTerm, matches, currentHighlightedIndex } = this.state;
    if (!response || isNullOrUndefinedOrEmptyObject(response.video_transcript_data)) return null;

    const transcriptData = response.video_transcript_data;
    const items = transcriptData?.results?.items;

    return items.map((item, index) => {
      if (item.type === "pronunciation") {
        const startTime = parseFloat(item.start_time);
        const isMatch = matches.includes(index);
        const isHighlighted = index === currentHighlightedIndex;
        const color = colors?.color1[3] || '#6c6eff'
        return (
          <span
            key={index}
            id={`transcript-word-${index}`}
            onClick={() => this.handleWordClick(startTime)}
            style={{
              cursor: 'pointer',
              margin: '0 2px',
              backgroundColor: isMatch ? 'yellow' : 'transparent',
              fontWeight: isHighlighted ? 'bold' : 'normal',
              textDecoration: isHighlighted ? 'underline' : 'none',
              color: isHighlighted ? color : 'inherit'
            }}
          >
            {item.alternatives[0].content}
          </span>
        );
      } else if (item.type === "punctuation") {
        return <span key={index}>{item.alternatives[0].content}</span>;
      }
      return null;
    });
  }

  render() {
    const { response, thumbnail, url, language, updateShowModalVideo } = this.props;
    const { searchTerm, matches, autoScroll } = this.state;
    const i18n = {
      en: {
        close: 'Close', searchTranscript: 'Search transcript...', autoScroll: 'Auto-scroll',
        copyTranscript: 'Copy transcript'
      },
      es: {
        close: 'Cerrar', searchTranscript: 'Buscar transcript...', autoScroll: 'Auto-scroll',
        copyTranscript: 'Copiar transcript'
      }
    };

    return (
      <div className="modal-dialog">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn px-btn circle xs btn-gray-lighter color-white px-close-modal">
              <i onClick={updateShowModalVideo} className="material-icons">clear</i>
            </button>
            <h4 className="px-modal-title">Video</h4>
          </div>

          <div className="modal-body px-modal-body-add-activity">
            <Player
              ref={this.playerRef}
              poster={thumbnail}
              fluid={false}
              width={'100%'}
              height={270}
              autoPlay
            >
              <source src={url} />
              <BigPlayButton position="center" />
              <ControlBar autoHide={false}>
                <VolumeMenuButton vertical />
              </ControlBar>
            </Player>

            {response && !isNullOrUndefinedOrEmptyObject(response.video_transcript_data) && (
              <div style={{ marginTop: '20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center'
              }}>
                <div style={{marginLeft: '20px'}}>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={this.handleSearchChange}
                    onKeyDown={this.handleSearchKeyDown}
                    placeholder={i18n[language].searchTranscript}
                    style={{ padding: '5px', borderRadius: '14px', border: '1px solid #ccc', outline: 'none' }}
                  />
                  {matches.length > 0 && (
                    <span style={{ marginLeft: '10px' }}>
                      {matches.length} match{matches.length > 1 ? 'es' : ''}
                    </span>
                  )}
                </div>
                <div data-tooltip-content={i18n[language].copyTranscript}>
                  <FontAwesomeIcon icon={['fas', 'copy']}
                                   style={{ cursor: 'pointer', marginLeft: '10px', fontSize: '20px', color: '#ccc' }}
                                   onClick={this.copyTranscriptToClipboard}
                  />
                </div>
                <div className='px-check-cont' style={{marginLeft: '20px'}}>
                  <label className="container-check">
                    <input
                      type="checkbox"
                      checked={autoScroll}
                      onChange={this.toggleAutoScroll}
                      style={{marginRight: '5px'}}
                    />
                    <span className="checkmark" style={{marginRight: '10px'}}/>
                    <span>{i18n[language].autoScroll}</span>
                  </label>
                </div>
              </div>
            )}

            {response && !isNullOrUndefinedOrEmptyObject(response.video_transcript_data) && (
              <div
                className='video-transcript'
                style={{ display: 'flex',
                  flexWrap: 'wrap',
                  maxHeight: '120px',
                  overflowY: 'scroll',
                  padding: '0 20px',
                  marginTop: '20px',
                  textAlign: "center" }}
              >
                {this.renderTranscript()}
              </div>
            )}
          </div>

          <div className="modal-footer px-modal-footer-gray">
            <div className="px-footer-container-buttons">
              <button
                onClick={updateShowModalVideo}
                type="button"
                className="btn px-btn sm btn-blue-base"
              >
                {i18n[language].close}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
