import PropTypes from 'prop-types';
import React from 'react';

import Layout from "../../Layout";
import UsersList from "../../../containers/Community/UsersList";
import Participants from "../../../containers/Community/Participants";
import Collaborators from "../../../containers/Community/Collaborators";
import {getParticipantToDoCounter} from "../../UI/Globals/PX_Funs";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";
import axios from "axios";

export default class UsersListPage extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    sessionLang: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    active_users: PropTypes.array.isRequired,
    rooms: PropTypes.array.isRequired,
    communities: PropTypes.array.isRequired,
    first_activity: PropTypes.object,
    papt: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      communitiesTop: [],
      notifications: [],
      rooms: [],
      activeUsers: [],  //This activeUsers only is useful for the New Conversations, I can improve it even more
      comPapt: 0,
      papt: 0,
      toDoCounter: 0,
      openPMFromOutside: {username: '', userId: null, flag: false},
      notificationsCounter: 0,
      isBeaconInit: false //Keeps track of any instances of Head Scout Beacons initialised.
    }
  }

  componentDidMount(){
    //TO-DO Maybe it would be better to call these data when the user clicks on the Notifications and PM icons
    // and inside Layout.jsx or TopMenu.jsx
    const {user_account, community} = this.props;

    if(user_account.role_1 === 'Collaborator'){
      this.getPaptCommunity(community.id);
      //this._getPapt();
      //this._getActiveUsers();
    }

    if(user_account.role_1 === 'Participant'){
      //this._getTasks();
    }

    this._getCommunitiesTop();
    this._getNotificationsCounter();
  }

  componentWillUnmount() {
  }

  render() {
    const {type, profile, sessionLang, first_activity, user_account} = this.props;
    const language = profile.language ? profile.language : sessionLang;
    const hsBeaconId = user_account.role_1 === 'Collaborator' ?
      '5d8de8f0-92db-4373-8993-71ec8a779c52' : 'db9657d0-300c-4919-af74-8497a18eeba8';
    const hsBeaconSuggestionsColls = {
      en: ["5f1fcb2504286306f8077b24", "5f2237e804286306f8079b7a", "5f22386704286306f8079b81", "5f2238bc2c7d3a10cbab8a88",
        "5f22395f2c7d3a10cbab8a92", "5f223a7a2c7d3a10cbab8a9b", "5f223b6e2c7d3a10cbab8aa5", "5f223c252c7d3a10cbab8aad",
        "5f223c7e04286306f8079bb6", "5f223ccd2c7d3a10cbab8ab3"]
    };
    const hsBeaconSuggestionsParts = {
      en: ["5f200d8e04286306f8077d91", "5f1eb42704286306f8076b9e"],
      es: ["5f200f2c2c7d3a10cbab695c", "5f1fac1c2c7d3a10cbab656b"] 
    };

    if(this.props.community.social_flag === false) {
      if(this.props.community.show_part_list === false) {
        hsBeaconSuggestionsParts.en.unshift("5f238bef2c7d3a10cbaba07f");
        hsBeaconSuggestionsParts.es.unshift("5f238c3c2c7d3a10cbaba081");
      } else {
        hsBeaconSuggestionsParts.en.unshift("5f238e3e04286306f807af8b", "5f23902e04286306f807af9b", "5f201fed2c7d3a10cbab6a40");
        hsBeaconSuggestionsParts.es.unshift("5f238f4e04286306f807af95", "5f23905b04286306f807af9e", "5f20207c2c7d3a10cbab6a4c");
      }
    } else {
      hsBeaconSuggestionsParts.en.unshift("5f23902e04286306f807af9b", "5f201fed2c7d3a10cbab6a40");
      hsBeaconSuggestionsParts.es.unshift("5f23905b04286306f807af9e", "5f20207c2c7d3a10cbab6a4c");
      hsBeaconSuggestionsParts.en.push("5f1eb6322c7d3a10cbab55c5", "5f2014e004286306f8077dda");
      hsBeaconSuggestionsParts.es.push("5f1fadbb2c7d3a10cbab6577", "5f2014fc2c7d3a10cbab69aa");
    }
    const hsBeaconSuggestions = user_account.role_1 === 'Collaborator' ? hsBeaconSuggestionsColls : hsBeaconSuggestionsParts

    return (
      <Layout user_account={this.props.user_account}
              profile={user_account.profile}
              sessionLang={user_account.language}
              notifications={this.state.notifications}
              from={user_account.role_1 === 'Collaborator' ? 'CommunityPage' : 'ParticipantCommunityPage'}
              community={this.props.community}
              leftMenuActive={type === 'Participant' ? 'Participants' : 'Collaborators'}
              active_users={this.state.activeUsers}
              rooms={this.state.rooms}
              communities={this.state.communitiesTop}
              toDoCounter={this.state.toDoCounter}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              notificationsCounter={this.state.notificationsCounter}
              colors={this.props.user_account.all_colors}>

        {/*<HelpScoutBeacon beaconId={hsBeaconId}*/}
        {/*                 user_account={user_account}*/}
        {/*                 colors={user_account.all_colors}*/}
        {/*                 language={language}*/}
        {/*                 isBeaconInit={this.state.isBeaconInit}*/}
        {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
        {/*                 suggestions={hsBeaconSuggestions}*/}
        {/*                 hideFABOnDesktop={!this.props.user_account.profile.is_help}/>*/}

        <UsersList community={this.props.community}
                   profile={user_account.profile}
                   type={type}
                   from={user_account.role_1 === 'Collaborator' ? 'CollaboratorUsersList' : 'ParticipantUsersList'}
                   language={user_account.language}
                   papt={this.state.comPapt}
                   updateActiveUsers={this.updateActiveUsers}
                   first_activity={first_activity}
                   user_account={user_account}
                   updateOpenPMFromOutside={this.updateOpenPMFromOutside}
                   colors={user_account.all_colors}
                   getPaptCommunity={this.getPaptCommunity}/>
      </Layout>
    );
  }

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  updateActiveUsers = activeUsers => {
    //console.log(activeUsers);
    this.setState({activeUsers});
  };

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  getPaptCommunity = communityId => {
    axios.get('/users/get_papt_community/' + communityId, {})
      .then(response =>  {
        const comPapt = response.data;
        this.setState({comPapt});
      }).catch(error => {console.log(error)});
  };

  _getPapt(){
    $.ajax({
      url: '/users/get_papt',
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: papt => {
        //console.log(papt);
        this.setState({papt});
      }
    });
  }

  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log(communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };

  _getRooms = () => {
    $.ajax({
      url: '/communities/get_rooms/' + this.props.community.id,
      method: 'GET',
      success: rooms =>  {
        //console.log(rooms);
        this.setState({rooms});
      }
    });
  };

  _getNotifications = () => {
    $.ajax({
      url: '/notifications/get_notifications/-1',
      method: 'GET',
      success: notifications =>  {
        this.setState({notifications});
      }
    });
  };

  updateOpenPMFromOutside = (type, username, userId) => {
    // console.log(type, username, userId);
    this.setState(state => ({openPMFromOutside: {username, userId, type, flag: !state.openPMFromOutside.flag}}));
  };

  /*updateOpenPMFromOutside_ORIG = (username, userId) => {
    console.log(username, userId);
    this.setState(state => ({openPMFromOutside: {username: username, userId: userId, flag: !state.openPMFromOutside.flag}}));
  };*/

  _getTasks = () => {
    $.ajax({
      url: '/tasks/get/' + this.props.community.id + '/' + this.props.profile.user_id,
      method: 'GET',
      dataType: 'JSON',
      success: function (tasksDB) {
        //console.log(tasksDB);
        this.setState({toDoCounter: getParticipantToDoCounter(tasksDB)});
      }.bind(this)
    });
  };
}