import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'moment';
import v4 from "uuid";

import {setIconGender} from "../../../../UI/Globals/PX_Funs";

export default class AEAttView01 extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    updateTaskAttributeMode: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let btnHoverColor = this.props.colors.color2[3];
    $(".px-menu-btn").mouseenter(function () {
      $(this).css({ 'background-color': btnHoverColor });
    });
    $(".px-menu-btn").mouseleave(function () {
      $(this).css({ 'background-color': '' });
    });
  }

  render() {
    const {task, language, colors} = this.props;

    const locale = language === 'en' ? 'en-au' : language;
    let start = null;
    let end = null;
    const i18n = {
      en: {
        availableFrom: 'Available From', expires: 'Expires', always: 'Always', never: 'Never',
        canAnswer: 'Can be answered even when expired', cannotAnswer: 'Can\'t be answered once expired',
        privacyTooltip1: 'Participants may see each other\'s responses and comments for this activity',
        privacyTooltip2: 'Participants won\'t be able to see each other\'s responses and comments for this activity',
        postedTo: 'Posted To', postedToTooltip: 'These will be the only participants able to see this Activity.', 
        required: 'Requisite', all: 'All', none: 'None', private: 'Private', public: 'Public',
        requiredTooltip: 'Activity that needs to be completed in order to have access to this one.'
      },
      es: {
        availableFrom: 'Disponible', expires: 'Expiración', always: 'Siempre', never: 'Nunca',
        canAnswer: 'Se podrá responder aún expirada', cannotAnswer: 'No se podrá responder cuando ha expirado',
        privacyTooltip1: 'Los participantes pueden ver las respuestas y comentarios de los demás para esta actividad.',
        privacyTooltip2: 'Los participantes no podrán ver las respuestas y comentarios de los demás para esta actividad',
        postedTo: 'Mostrar a', postedToTooltip: 'Estos serán los únicos participantes que verán esta Actividad',
        required: 'Requisito', all: 'Todos', none: 'Ninguno', private: 'Privada', public: 'Pública',
        requiredTooltip: 'Actividad que necesita completarse para acceder a ésta'
      }
    }

    if(task.start && task.expire){
      start = <div className="px-available-item"><label className="label-xs" style={{color: colors.color1[2]}}>{i18n[language].availableFrom}:</label>
        <span className="px-date">{moment(task.start).locale(locale).format("ll")}</span>
      </div>;
      end = <div className="px-available-item"><label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].expires}:</label>
        <span className="px-date">{moment(task.expire).locale(locale).format("ll")}</span>
      </div>;
    } else {
      if(!task.start && !task.expire){
        start = <div className="px-available-item"><label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].availableFrom}:</label>
          <span className="px-date">{i18n[language].always}</span>
        </div>;
        end = <div className="px-available-item"><label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].expires}:</label>
          <span className="px-date">{i18n[language].never}</span>
        </div>;
      }
      if(!task.start && task.expire){
        start = null;
        end = <div className="px-available-item"><label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].expires}:</label>
          <span className="px-date">{moment(task.expire).locale(locale).format("ll")}</span>
        </div>;
      }
      if(task.start && !task.expire){
        start = <div className="px-available-item"><label className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].availableFrom}:</label>
          <span className="px-date">{moment(task.start).locale(locale).format("ll")}</span>
        </div>;
        end = null;
      }
    }

    const aSegGender = task.segments.filter(s => isNaN(s.value));
    const aSegments =task.segments.filter(s => !isNaN(s.value));
    const sortedSegments = [...aSegGender, ...aSegments];

    let segments = [];
    if(sortedSegments.length < 5) {
      segments = sortedSegments.map((segment, index) => {
        if(isNaN(segment.value)){
          return(
            <div key={index} className="genders-container">
              <div className="gender-container" data-tooltip-content={segment.label}>{setIconGender(segment.label)}</div>
            </div>
          )
        } else {
          return(
            <FontAwesomeIcon key={index} icon={['fas', 'bookmark']} className="tag" style={{color:segment.color}}
                             data-tooltip-content={segment.label}/>
          )
        }
      })
    } else {
      segments = sortedSegments.splice(0, 3).map((segment, index) => {
        if(isNaN(segment.value)){
          return(
            <div key={index} className="genders-container">
              <div className="gender-container" data-tooltip-content={segment.label}>{setIconGender(segment.label)}</div>
            </div>
          )
        } else {
          // TO-DO after to select other activity and get back to it, then the tooltip doesn't work
          //console.log(sortedSegments);
          return(
            <FontAwesomeIcon key={index} icon={['fas', 'bookmark']} className="tag" style={{color:segment.color}}/>
          )
        }
      });
      segments.push(<FontAwesomeIcon key={v4()} icon={['fas', 'ellipsis']} className="ellipsis"
                                     data-tooltip-content={sortedSegments.map(s => ' ' + s.label)}/>);
    }

    return (
      <div className="px-activity-card-details" style={{borderLeftColor: colors.color2[3]}}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="panel-body">
          <div className="px-principal-cont">
            <div className="px-info-container">
              <div className="px-title-container">
                <span className="px-title" data-tooltip-content={task.title}>{task.title}</span>
                {
                  task.tag &&
                  <FontAwesomeIcon icon={['fas', 'tag']} style={{color:task.tag.color}} data-tooltip-content={task.tag.name}/>
                }
              </div>
              <div className="px-available-container">
                { start }
                { end }
              </div>
              <div className="px-answer-container">
                <span className="px-answer">{task.overdue ? i18n[language].cannotAnswer : i18n[language].canAnswer }</span>
              </div>
            </div>
            <div className="px-actions-container">
              <div className="px-action-item">
                <button className="btn px-btn sm btn-gray-darker inverted btn-action"><span>{task.social === "Private" ? i18n[language].private : i18n[language].public }</span></button>
                <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog" 
                  data-tooltip-content={[task.social === 'Public' ? i18n[language].privacyTooltip1 : i18n[language].privacyTooltip2]} />
              </div>
              <div className="px-action-item">
                <span className="px-posted-label">{i18n[language].postedTo}</span>
                {
                  task.segments.length !== 0 ?
                    <div className="tags-container tag-padding">
                      { segments }
                    </div> :
                    <button className="btn px-btn sm btn-gray-darker inverted btn-action">
                      <span>{i18n[language].all}</span>
                    </button>
                }
                <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog" data-tooltip-content={i18n[language].postedToTooltip}/>
              </div>
              <div className="px-action-item">
                <span className="px-posted-label">{i18n[language].required}</span>

                    <button className="btn px-btn sm btn-gray-darker inverted btn-action">
                    <span>{task.required ? task.required.label : i18n[language].none}</span>
                    </button>

                <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog" data-tooltip-content={i18n[language].requiredTooltip}/>
              </div>
            </div>
          </div>
        </div>

        <div className="px-actions-panel">
          <div className="px-actions-container">
            <button onClick={this.props.updateTaskAttributeMode} className="btn px-btn sm circle px-menu-btn">
              <FontAwesomeIcon icon={['fas', 'pencil']} className="px-icon-menu"/>
            </button>
            <div className="px-support-tooltip left is_hidden px-support-builder">
              <p>This is an summary view of an Activity, showing its key settings.</p>
              <p>Click on the pencil icon to configure the Activity the way you want participants to see it.</p>
              <i></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
