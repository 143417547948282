import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class DeclinedCard extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    updateStateInvitation: PropTypes.func.isRequired,
    language: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {community, language} = this.props;

    const I18n = {
      en: {msg1: "You have declined", msg2: "to join", undo: "Change your mind", hide: "Hide", unhide: "Unhide"},
      es: { msg1: "Has declinado la", msg2: "invitación a", undo: "Quiero participar", hide: "Ocultar", unhide: "Mostrar"}
    }

    return (
      <div className="card px-card-community declined mg-bottom-15">
        <div className="card-height-indicator"/>
        <div className="card-content">
          <div className="card-body full-body body-status">
            <FontAwesomeIcon icon={['fal','circle-exclamation']} className="px-icon exclamation"/>
            <span className="title" style={{ color: '#45505d'}}>{I18n[language].msg1} <br /> {I18n[language].msg2}</span>
            <span className="community">"{community.title}"</span>

            <div className="two-buttons">
              <div onClick={() => this.props.updateStateInvitation('change', community.id)} className="btn-group accept">
                <button type="button" className="btn btn-action change">
                  <FontAwesomeIcon icon={['far','rotate-left']}/>
                </button>
                <button type="button" className="btn px-btn change sm btn-gray-base">{I18n[language].undo}</button>
              </div>
              <div className="btn-group ">
                <button onClick={() => this.props.updateHide(community.id)} type="button"
                        className={community.cu_hide ? "btn px-btn hide-t lg-ct sm btn-gray-base" :
                          'btn px-btn hide-t lg-ct sm btn-gray-base inverted'}>
                  {community.cu_hide ? I18n[language].unhide : I18n[language].hide }</button>
              </div>
            </div>
            <FontAwesomeIcon icon={['fal','circle-info']} className="info-c"/>
          </div>
        </div>
      </div>
    );
  }
}
