import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"

import DMMatrix from "./Matrix/DMMatrix";
import DFilters from "./DFilters";

export default class DMatrix extends React.Component {
  static propTypes = {
    activeLink: PropTypes.string.isRequired,
    activeUsers: PropTypes.array.isRequired,
    activities: PropTypes.array.isRequired,
    user_account: PropTypes.object.isRequired,
    columnHeaderActions: PropTypes.func.isRequired,
    userActions: PropTypes.func.isRequired,
    objSearchMat: PropTypes.object.isRequired,
    updateFilterIsMin: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    executeUpdateModal: PropTypes.func.isRequired,
    updateCustomFilter: PropTypes.func.isRequired,
    userFilters: PropTypes.array.isRequired,
    handleChangeSltFilter: PropTypes.func.isRequired,
    activityActions: PropTypes.func.isRequired,
    viewProfile: PropTypes.func.isRequired,
    modalActiveTaskId: PropTypes.number.isRequired,
    updateModalActiveTaskId: PropTypes.func.isRequired,
    isLoadingUnseen: PropTypes.bool.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    updateUserCustomFilters: PropTypes.func.isRequired,
    clearForwardUrl: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    updateOpenPMFromOutside: PropTypes.func.isRequired,
    communityId: PropTypes.number.isRequired,
    hasMoreUsers: PropTypes.bool.isRequired,
    getResponses: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }


  render() {
    const {activeLink, activeUsers, activities, user_account, objSearchMat, userFilters, isLoadingUnseen,
      gender_as_segment, language, communityId, modalActiveTaskId, hasMoreUsers} = this.props;

    const i18n = {
      en: {
        seeResponses: 'See responses', hurry: 'Hurry (blank & overdue tasks)', acceptCompleted: 'Accept completed tasks',
        export: 'Export responses'
      },
      es: {
        seeResponses: 'Ver respuestas', hurry: 'Apresurar (tareas vacías y atrasadas)', acceptCompleted: 'Aceptar tareas completadas',
        export: 'Exportar respuestas'
      }
    };

    const role_2 = user_account.role_2;
    const liResponses = <li><a onClick={() => this.props.columnHeaderActions('see responses')}>{i18n[language].seeResponses}</a></li>;
    const liFaster = modalActiveTaskId !== - 1 &&
      <li><a onClick={() => this.props.updateShowModal(activeLink, 'header faster')}>{i18n[language].hurry}</a></li>;
    const liAccept = modalActiveTaskId !== - 1 &&
      <li><a onClick={() => this.props.updateShowModal(activeLink, 'header accept')}>{i18n[language].acceptCompleted}</a></li>;
    const liExport = <li><a onClick={() => this.props.columnHeaderActions('view pdf')}>{i18n[language].export}</a></li>;
    //const liExport = modalActiveTaskId !== - 1 &&
    //  <li><a onClick={() => this.props.columnHeaderActions('view pdf')}>{i18n[language].export}</a></li>;

    let dropdownAction = null;
    if(role_2 === "Owner" || role_2 === "Moderator") {
      dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">
        {liResponses}{/*liFaster*/}{liAccept}{/*liExport*/}
      </ul>
    } else if (role_2 === "Observer") {
      dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">
        {liResponses}
      </ul>
    }

    return (
      <div className="px-collaborator-dash-container matrix" style={{display: activeLink === 'Matrix' ? 'block' : 'none'}}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className="row matrix-dashboard-container">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <DFilters activeLink={activeLink}
                      objSearch={objSearchMat}
                      updateFilterIsMin={this.props.updateFilterIsMin}
                      from={activeLink}
                      updateShowModal={this.props.updateShowModal}
                      executeUpdateModal={this.props.executeUpdateModal}
                      updateCustomFilter={this.props.updateCustomFilter}
                      userFilters={userFilters.filter(uf => uf.from === 'General')}
                      handleChangeSltFilter={this.props.handleChangeSltFilter}
                      updateUserCustomFilters={this.props.updateUserCustomFilters}
                      language={language}
                      colors={user_account.all_colors}/>

            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="panel panel-default panel-table-matrix">
                  <div className="panel-body" style={{overflow:'auto'}}>
                    <DMMatrix activeUsers={activeUsers}
                              activities={activities}
                              user_account={user_account}
                              columnHeaderActions={this.props.columnHeaderActions}
                              userActions={this.props.userActions}
                              activityActions={this.props.activityActions}
                              viewProfile={this.props.viewProfile}
                              updateTaskHeaderMenu={this.updateTaskHeaderMenu}
                              updateShowModal={this.props.updateShowModal}
                              isLoadingUnseen={isLoadingUnseen}
                              gender_as_segment={gender_as_segment}
                              clearForwardUrl={this.props.clearForwardUrl}
                              language={language}
                              updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                              communityId={communityId}
                              colors={user_account.all_colors}
                              hasMoreUsers={hasMoreUsers}
                              getResponses={this.props.getResponses}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {
                activities.length !== 0 &&
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="panel panel-default panel-table-matrix">
                      <div className="panel-body">
                        <div className="dropdown ddEllipsis">
                          <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown"
                                  style={{display:'none'}}>&nbsp;</button>
                          { dropdownAction }
                        </div>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  updateTaskHeaderMenu = (taskId, divName, event) => {
    // console.log(taskId, divName, event.clientX, event.clientY, event.currentTarget.offsetTop);
    this.props.updateModalActiveTaskId(taskId);
    // currentTarget because svg doesn't have offset
    //console.log(event.currentTarget.offsetLeft, event.currentTarget.offsetLeft);
    $(divName).addClass('open');
    $(divName).css({
      position:'fixed',
      top: event.clientY - event.currentTarget.offsetTop + 15,
      left: taskId !== -1 ?
        event.clientX - event.currentTarget.offsetLeft + 100 : event.clientX - event.currentTarget.offsetLeft - 20,
      zIndex: 3
    })
  };
}
