import React from 'react'
import PropTypes from 'prop-types'

import TRMChatCard from "./TRMicroChat/TRMChatCard"

const TRMicroChat = ({ messages, currentUserId, avatar, from = 'transcript', blockerExpired, colors, language  }) => {

  const I18n = {
    en: {
      taskCompleted: 'You have completed this task',
      taskExpired: 'This task has expired and you can\'t edit it anymore'
    },
    es: {
      taskCompleted: 'Has completado esta tarea',
      taskExpired: 'Esta tarea ha expirado y no se puede editar'
    }
  }

  const blockerMessage = from === 'participant' &&
      <h3 className="panel-title" style={{ color: blockerExpired ? colors.color4[3] : '', backgroundColor: blockerExpired ? colors.color4[6] : colors.color3[5] }}>
          {blockerExpired ? I18n[language].taskExpired : I18n[language].taskCompleted}</h3>


  return (
    <div className="response" style={{padding: '5px'}}>
      {blockerMessage}
      <div className="container-answer">
        <div className="ai-conversations-col" style={{height:'100%', width: '100%'}}>
          <div className="px-notifications-drawer px-messages-drawer right-drawer-open drawer-ai">
            <div className="messages-container" style={{height:'100%'}}>
              <div className="messages-principal-container conversation ai-conversation" style={{ maxHeight: from === 'participant' ? '250px' : '300px' }}>
                <div className="messages-items-container" style={{backgroundColor: '#f6f6f6'}}>
                  <div className="conversations-container">
                  {
                    messages.map(chat => {
                      return (
                        <TRMChatCard key={chat.id}
                                     message={chat}
                                     avatar={avatar}
                                     currentUserId={currentUserId}/>
                      )
                    })
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TRMicroChat.propTypes = {
  messages: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  avatar: PropTypes.string.isRequired
}

export default TRMicroChat
