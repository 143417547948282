import PropTypes from 'prop-types';
import React from 'react';
import Slider from "react-slick/lib";

export default class MNavBar extends React.Component {
  static propTypes = {
    handleLink: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    getInvoice: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $('.item').css('border-color', this.props.colors.color0[4]);
  }

  componentDidUpdate() {
    $('.item').css('border-color', this.props.colors.color0[4]);
  }

  render() {
    const { language } = this.props;
    const i18n = {
      en: {
        account: 'ACCOUNT', profile: 'PROFILE', notifications: 'NOTIFICATIONS', allowances: 'ALLOWANCES',
        appearance: 'APPEARANCE', billing: 'BILLING'
      },
      es: {
        account: 'CUENTA', profile: 'PERFIL', notifications: 'NOTIFICACIONES', allowances: 'PERMISOS',
        appearance: 'APARIENCIA', billing: 'FACTURACIÓN'
      }
    };

    const {role} = this.props;

    const sliderNavSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 3,
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Slider {...sliderNavSettings} className="px-slick-navigation-menu">
        <div className="item selected" id="Account" onClick={() => this.props.handleLink('#Account', '#divAccount')}>
          {i18n[language].account}
        </div>
        <div className="item" id="Profile" onClick={() => this.props.handleLink('#Profile', '#divProfile')}>
          {i18n[language].profile}
        </div>
        {/* {
          role === 'Superadmin' &&
          <div className="item" id="Notifications" onClick={() => this.props.handleLink('#Notifications', '#divNotifications')}>
            {i18n[language].notifications}
          </div>
        } */}
        <div className="item" id="Appearance" onClick={() => this.props.handleLink('#Appearance', '#divAppearance')}>
          {i18n[language].appearance}
        </div>
        {
          role === 'Superadmin' &&
          <div className="item" id="Billing" onClick={() => this.props.handleLink('#Billing', '#divBilling')}>
            {i18n[language].billing}
          </div>
        }
        {
          role === 'Superadmin' &&
          <div className="item" id="Allowances" onClick={() => this.props.handleLink('#Allowances', '#divAllowances')}>
            {i18n[language].allowances}
          </div>
        }
      </Slider>
    );
  }
}

function SampleNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', marginLeft:'15px', borderRadius:'50%'}}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', marginRight:'15px', zIndex:'1' , borderRadius:'50%'}}
      onClick={onClick}
    />
  );
}
