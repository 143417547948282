import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Lightbox from "react-images";
import ReactModal from "react-modal";
import axios from "axios";
import downloadjs from 'downloadjs';
import * as mjslive from 'markerjs-live';
const JSZip = require("jszip");
import { saveAs } from 'file-saver';

import TRChoice from "../T_Response/TRChoice";
import TRModalVideo from "../TResponse/TRModalVideo";
import LoadingConsolidated from "../../../UI/Globals/LoadingConsolidated";
import TRCWordCloud from "../TResponse/TRConsolidated/TRCWordCloud";
import TRCBarChart from "../TResponse/TRConsolidated/TRCBarChart";
import TR_Verbatims from "../T_Response/TR_Verbatims";
import TR_PopupVerbatim from "../TR_PopupVerbatim";
import Backdrop from "../../../UI/Backdrop";
import TR_VerbatimsManage from "../T_Response/TR_VerbatimsManage";
import Comments from "../../../../containers/Community/Comments";
import MUSeeNotes from "../../../UI/MUSeeNotes";
import {getCountryName, getResponseStateLang, setIconGender, showSuccessMsg} from "../../../UI/Globals/PX_Funs";
import TRFileDownload from "../TResponse/TRFileDownload";
import download from "downloadjs";
import MediaQuery from 'react-responsive';
import T_ModalVideo from "../T_ModalVideo";
import TRMicroChat from "../T_Response/TRMicroChat";
import {usernamesToDisplay} from "../../shared_utils";

export default class ResponseT extends React.Component {
  static propTypes = {
    cardinal: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    usersId: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    role_1: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    communityState: PropTypes.string.isRequired,
    updateResponse: PropTypes.func.isRequired,
    saveResponseLike: PropTypes.func.isRequired,
    isSavingLikeResponse: PropTypes.bool.isRequired,
    doRecomputeRowHeights: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    toggleTaskIsMax: PropTypes.func.isRequired,
    getResponsesConsolidatedSingle: PropTypes.func.isRequired,
    taskMode: PropTypes.string.isRequired,
    updateSVG: PropTypes.func.isRequired,
    consolidated: PropTypes.string.isRequired,
    handleShowModalConsolidated: PropTypes.func.isRequired,
    showAllVerbatim: PropTypes.bool.isRequired,
    handleVerbatimVisibility: PropTypes.func.isRequired,
    updateSizeResponseSlider: PropTypes.func.isRequired,
    updateSizeResponse: PropTypes.func.isRequired,
    updateResponseKeywords: PropTypes.func.isRequired,
    autosizingRenderInput: PropTypes.func.isRequired,
    handleVerbatimShows: PropTypes.func.isRequired,
    regenerateResponseVerbatim: PropTypes.func.isRequired,
    updateShowComments: PropTypes.func.isRequired,
    updateResponseComments: PropTypes.func.isRequired,
    updateUserResponses: PropTypes.func.isRequired,
    scrollFirstTime: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired,
    item_scroll: PropTypes.object,
    response: PropTypes.object,
    recalculateRowHeight: PropTypes.func,
    objSearch: PropTypes.object,
    qc: PropTypes.array,
    showModalExportHandler: PropTypes.func.isRequired,
    cmntyLanguage: PropTypes.string.isRequired,
    commentForceUpdateGrid: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    projectType: PropTypes.string,
    community: PropTypes.object.isRequired,
    from: PropTypes.string
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      showModalVideo: false,
      showPopupVerbatim: false,
      // NEW
      showConsolidated: false,
      responsesConsolidated: [],
      userAnswerConsolidated: null,
      mapsConsolidated: [],
      userAnswerConsolidatedMap: null,
      showOriginal: false,
      showSeeNotes: false,
      isSavingImages: false,
      savedImagesCounter: 0,
      showModalTaskVideo: false,
      showTaskLB: false
    }
  }

  componentDidMount() {
    
    //console.log('ResponseT.componentDidMount, response:', this.props.response);
    const {user, response, index, consolidated, scrollFirstTime, item_scroll, task, language} = this.props;
    //console.log(scrollFirstTime, item_scroll);
    if(response.showConsolidated && response.isLoadingConsolidated){
      this.updateShowConsolidated(index, consolidated === 'general' ? 'outer' : 'inner');
    }

    if(scrollFirstTime){
      if(item_scroll){
        this.props.updateShowComments(response.id);
      } else {
        this.props.updateScrollFirstTime(false);
      }
    }

    this.onHeightChange();

    let btnColor = this.props.colors.color1[3];
    $(".btn-comments").on('mouseenter', function () {
      $(this).css({ "color": "#ffffff", 'background-color': btnColor, 'border-color': btnColor });
    });
    $(".btn-comments").on('mouseleave', function () {
      if ($(this).hasClass('inactive')) {
        $(this).css({ "color": btnColor, 'background-color': '#ffffff', 'border-color': btnColor });
      } 
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {response, user, usersId, task} = this.props;

    if(prevProps.response !== response){
      if(prevProps.response.showComments !== response.showComments){
        // console.log('Click on Comments Btn, recalculateRowHeight')
        this.props.recalculateRowHeight(this.props.index)
      }
      if(prevProps.response.comments !== response.comments){
        // console.log('response.comments changed, recalculateRowHeight')
        this.props.recalculateRowHeight(this.props.index)
      }

      //console.log('ResponseT.componentDidUpdate', response.id, response.isLoadingConsolidated, response.showConsolidated);
      //this.props.recalculateRowHeight(index);
      if(response.showConsolidated && response.isLoadingConsolidated){
        this.props.getResponsesConsolidatedSingle(response.id, [user.id], [task.id], usersId, task.task_type, user.id,
          response.showConsolidated, 'outer');
        //this.updateShowConsolidated(index, 'outer');
      }

      //console.log(prevProps.response.isLoadingConsolidated, response.isLoadingConsolidated);
      if(prevProps.response.isLoadingConsolidated !== response.isLoadingConsolidated){
        if(response.type === undefined){
          //console.log('ResponseT.componentDidUpdate isLoadingConsolidated', response.isLoadingConsolidated, 'do a recalculateRowHeight');
          this.props.recalculateRowHeight(this.props.index);
        }
      }
    }
  }

  render() {
    const {cardinal, index, usersId, user, isSavingLikeResponse, task, role_1, role_2, communityState, language,
      taskMode, response, showAllVerbatim, qc, item_scroll, colors, cmntyLanguage, company} = this.props
    
    const i18n = {
      en: { comments: 'Comments', options: 'OPTIONS', close: 'CLOSE', hidden: 'Hidden', verbatim: 'Verbatim' },
      es: { comments: 'Comentarios', options: 'OPCIONES', close: 'CERRAR', hidden: 'Ocultas', verbatim: 'Verbalizaciones' }
    }

    const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
    const mainName = availableUsernames[0];
    const alternativeNames = availableUsernames.length > 1 ? availableUsernames.slice(1).join(', ') : ''

    let isCanvasAndNotEmpty = task.task_type === 'Canvas' && (!response || response.state !== 'Empty');

    let imageThumbnailStyle = isCanvasAndNotEmpty ?
        {height:'200px', width: '200px', borderRadius:'4px', margin: '0 5px 15px', cursor: 'pointer' } :
        {height:'50px', width: '50px', borderRadius:'4px', margin: '0 5px 15px', cursor: 'pointer' }

    let consolidateIcon = false;
    let copyIcon = false;
    if (task.task_type === 'Choice' || task.task_type === 'Open End') {
      consolidateIcon = true;
    }

    const videoTranscriptAvailable = response.video_transcript
    const attachmentTranscriptsAvailable = response.attachments?.some(attachment => attachment.transcript) ?? false;
    const isTaskTypeEligible = task.task_type === 'Open End' || task.task_type === 'MicroChat';
    const isMultimediaOrCanvasWithTranscript = (task.task_type === 'Multimedia' || task.task_type === 'Canvas') &&
      (videoTranscriptAvailable || attachmentTranscriptsAvailable);

    if (isTaskTypeEligible || isMultimediaOrCanvasWithTranscript) {
      copyIcon = true;
    }

    let cardHeader = <div className="px-counter-task" style={{ backgroundColor: colors.color0[1] }}>
      <span className="number">{cardinal}</span></div>;

    let cardTitle = <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" id={`canvasImgContainer${response?.id}`}>
        <p dangerouslySetInnerHTML={{__html: task.title}} className="title"/>
        {
          task.url !== '' &&
            <img src={ this.imageToShowInTask(task, response) }
                 onClick={this.updateShowTaskLB}
                 style={imageThumbnailStyle}
                 alt=""
            />

        }
        {
          task.video_thumbnail !== '' &&
          <img onClick={this.updateShowModalTaskVideo} src={task.video_thumbnail}
               style={{height:'50px', width: '50px', borderRadius:'4px', margin: '0 5px 15px', cursor: 'pointer'}}
               alt=""/>
        }
      </div>
    </div>;
    let showHeaderSeparator = false;

    if(taskMode === 'nUsers1Task'){
      const avatar = user.avatar !== '' ? user.avatar : '/assets/user.png';
      const regionCountry = user.region === '' || user.region === undefined ?
        getCountryName(user.country) : user.region + ', ' + getCountryName(user.country);

      cardHeader = null;
      cardTitle = <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="title-with-profile">
            <img src={avatar} className="img-profile" alt=""/>
            <div className="title-right-container">
              <div className="text-container">
                <p className="profile-name">{mainName}</p>
                <p className="email">{user.email}</p>
              </div>
              <div className="icon-container">
                <div className="px-tags-container">
                  {
                    role_1 === 'Collaborator' &&
                    <div className="tags-container">
                      {
                        user.segments.map(segment => {
                          return(
                            <FontAwesomeIcon key={segment.id} icon={['fas','bookmark']} className="tag"
                                             style={{color: segment.color}} data-tooltip-content={segment.name}/>
                          )
                        })
                      }
                    </div>
                  }
                </div>
                <div className="add-container">
                  {
                    user.gender !== '' &&
                    <div className="px-genders-container">
                      <div className="gender-container" data-tooltip-content={user.gender}>
                        {setIconGender(user.gender)}
                      </div>
                    </div>
                  }
                  {
                    user.country &&
                    <div className="px-country-container" data-tooltip-content={regionCountry}>
                      <span>{user.country}</span>
                    </div>
                  }
                </div>
              </div>
              {
                task.url !== '' &&
                <img src={this.imageToShowInTask(task, response)}
                     onClick={this.updateShowTaskLB}
                     style={imageThumbnailStyle}
                     alt=""
                />

              }
              {
                task.video_thumbnail !== '' &&
                <img onClick={this.updateShowModalTaskVideo} src={task.video_thumbnail}
                     style={{height:'50px', width: '50px', borderRadius:'4px', margin: '0 5px 15px', cursor: 'pointer'}}
                     alt=""/>
              }
            </div>
          </div>
        </div>
      </div>;
      showHeaderSeparator = true;
    }

    if(taskMode === 'nUsersNTasks' || taskMode === 'nUsersNActs'){
      const avatar = user.avatar !== '' ? user.avatar : '/assets/user.png';
      const regionCountry = user.region === '' || user.region === undefined ?
        getCountryName(user.country) : user.region + ', ' + getCountryName(user.country);

      cardHeader = <div className="px-task-image-container">
        <div className="px-tooltip-user-container" style={{ position: 'relative' }}>
          <img src={avatar} alt="image" className="image" />
          <div className="px-user-tooltip">
            <div className="title">{mainName}</div>
            <div className='info-container'>
              {
                user.gender !== '' &&
                <div className="px-genders-container">
                  <div className="gender-container" data-tooltip-content={user.gender}>{setIconGender(user.gender)}</div>
                </div>
              }
              {
                user.country &&
                <div className="px-country-container" data-tooltip-content={regionCountry}>
                  <span>{user.country}</span>
                </div>
              }
              <div className='px-tags-container'>
                {
                  user.segments.map(segment => {
                    return (
                      <FontAwesomeIcon key={segment.id} icon={['fas', 'bookmark']} className="tag"
                                       style={{ color: segment.color }} data-tooltip-content={segment.name} />
                    )
                  })
                }
              </div>
            </div>
            {
              role_2 === "Owner" || role_2 === "Moderator" || role_2 === "Recruiter" ?
                <div style={{minWidth: '150px'}}>
                  <div className="email">{alternativeNames ? `a.k.a: ${alternativeNames}` : ''}</div>
                  <div className="email">{user.email}</div>
                </div> :
                ""
            }
          </div>
        </div>
        <div className="number" style={{backgroundColor: colors.color0[1]}}><span>{cardinal}</span></div>
      </div>;
    }

    let stateColor = null;
    let showAnswer = null;
    let imagesLB = [];
    let attachments = null;
    let videoThumbnail = null;
    let moods = null;
    let stateMessage = null;
    let likeIcon = null;
    let likeBtn = null;
    let btnComments = null;
    let wordCloudChart = null;
    let barChart = null;
    let responseStateLang = '';
    let downloadIcon = null;

    if(response){
      responseStateLang = getResponseStateLang(response.state, language);

      if(!response.showConsolidated){
        switch(task.task_type){
          case 'Open End':
          case 'Multimedia':
            if(showAllVerbatim){
              if(response.showOriginal){
                showAnswer = <div className="response">
                  <div className="container-answer">
                    <div className="answer" dangerouslySetInnerHTML={{__html: response.data.answerHTML}}/>
                  </div>
                  <div className="actions-verbatim">
                    <button onClick={() => this.props.handleVerbatimShows(response.id, 'showOriginal')}
                            className="btn btn-actions-verbatim">{i18n[language].verbatim}</button>
                  </div>
                </div>;
              } else {
                if(response.showHiddenVerbatims){
                  const manageVerbatims = response.verbatim && response.verbatim.map((verbatim, index) => {
                    const isVisible = verbatim.visibility === 'true' ? 'quote-right' : 'eye-slash';

                    return(
                      <div key={index}>
                        <TR_VerbatimsManage responseId={response.id}
                                            verbatim={verbatim}
                                            isVisible={isVisible}
                                            handleVerbatimVisibility={this.props.handleVerbatimVisibility}/>
                      </div>
                    )
                  });

                  showAnswer = <div className="response">
                    { manageVerbatims }
                    <div className="actions-verbatim">
                      <button onClick={() => this.props.handleVerbatimShows(response.id, 'showHiddenVerbatims')}
                              className="btn btn-actions-verbatim">{i18n[language].close}</button>
                    </div>
                  </div>;
                } else {
                  let hideCounter = 0;
                  const verbatims = response.verbatim && response.verbatim.map((verbatim, index) => {
                    if(verbatim.visibility === 'false'){
                      hideCounter += 1;
                    }
                    const isVisible = verbatim.visibility === 'true' ? 'quote-right' : 'eye-slash';
                    const verbatimComp = verbatim.visibility === 'true' &&
                      <TR_Verbatims responseId={response.id}
                                    verbatim={verbatim}
                                    isVisible={isVisible}
                                    handleVerbatimVisibility={this.props.handleVerbatimVisibility}/>;

                    return(
                      <div key={index}>
                        { verbatimComp }
                      </div>
                    )
                  });

                  const dropUpClass = this.state.showPopupVerbatim ?
                    'actions-verbatim dropup open' : 'actions-verbatim dropup';
                  showAnswer = <div className="response">
                    { verbatims  }
                    <div className={dropUpClass}>

                      <button onClick={this.handlePopupVerbatim} className="btn btn-actions-verbatim options">
                        <div className="plus-container">
                          <FontAwesomeIcon icon={['fal','plus']}/>
                        </div>
                        <span>{i18n[language].options}</span>
                      </button>
                      <TR_PopupVerbatim response={response}
                                        updateSizeResponseSlider={this.props.updateSizeResponseSlider}
                                        updateResponseKeywords={this.props.updateResponseKeywords}
                                        autosizingRenderInput={this.props.autosizingRenderInput}
                                        updateSizeResponse={this.props.updateSizeResponse}
                                        hideMenuAndRegenerate={() => this.hideMenuAndRegenerate(response.id)}
                                        colors={colors}
                                        language={language}
                                        handlePopupVerbatim={this.handlePopupVerbatim}/>
                      {
                        hideCounter !== 0 &&
                        <button onClick={() => this.props.handleVerbatimShows(response.id, 'showHiddenVerbatims')}
                                className="btn btn-actions-verbatim">{i18n[language].hidden}</button>
                      }
                      <button onClick={() => this.props.handleVerbatimShows(response.id, 'showOriginal')}
                              className="btn btn-actions-verbatim">Original</button>
                    </div>
                  </div>;
                }
              }
            } else {
              showAnswer = task.task_type === 'Open End' && <div className="response">
                <div className="container-answer">
                  <div dangerouslySetInnerHTML={{__html: response.data.answerHTML}} className="answer"/>
                </div>
              </div>
              attachments = response.attachments.map((attachment, index) => {
                imagesLB.push({src: attachment.url});
                return (
                  <div key={index} className="px-media-img">
                    <TRFileDownload type={'images'}
                                    action={'one'}
                                    url={attachment.url}/>
                    <div onClick={(e) => this.openLightbox(index, e)}
                         className="px-image-item" style={{cursor: 'pointer'}} data-tooltip-content={attachment?.transcript}>
                      <img src={attachment.url_thumbnail !== '' ? attachment.url_thumbnail : attachment.url} alt=""/>
                    </div>
                  </div>
                )
              });
              videoThumbnail = response.video_thumbnail &&
                <div className="px-video-item px-media-img" style={{cursor:'pointer'}}>
                  <TRFileDownload type={'videos'}
                                  action={'one'}
                                  url={response.video_url}/>
                  <img src={response.video_thumbnail} alt=""/>
                  <div className="overlay-video" onClick={this.updateShowModalVideo}>
                    <div className="play-icon">
                      <FontAwesomeIcon icon={['fas','play']}/>
                    </div>
                  </div>
                </div>;
              moods = response.participantMapItems.map((item, index) =>
                <li key={index} className="px-mood-list-item">
                  <span className="px-mood-item" data-tooltip-content={item[cmntyLanguage]} style={{backgroundColor: colors.color0[1]}}>{item[cmntyLanguage]}</span>
                </li>
              );
              downloadIcon = !this.state.isSavingImages ?
                (response.task.task_type === 'Open End' && (response.attachments.length !== 0 || response.video_url !== '')) &&
                <span style={{ color: '#AFBDC1' }}>
                  <FontAwesomeIcon onClick={() => this.downloadFiles('images', 'all', null)}
                    icon={['fas', 'download']} style={{ cursor: 'pointer' }} />
                </span> :
                <span style={{ color: '#AFBDC1' }}>
                  <FontAwesomeIcon icon={['fas', 'spinner']} spin />&nbsp;
                      {Math.round((this.state.savedImagesCounter * 100) / (response.attachments.length + 1))}%
                </span>;
            }
            break;
          case 'Choice':
            showAnswer = <TRChoice task={task}
                                   response={response}
                                   language={language}
                                   colors={colors}/>;
            break;
          case 'MicroChat':
            showAnswer = <TRMicroChat messages={response.data.messages}
                                      currentUserId={user.id}
                                      avatar={user.avatar !== '' ? user.avatar : '/assets/user.png'}/>
            break;
          case 'Canvas':
            //attachments = imagesLB.push({src: response.attachments[0].url})
          break;
        }
      } else {
        switch(task.task_type){
          case 'Open End':
            // TO-DO Save this charts (the whole component TRCWordCloud and so on) in the response object
            // because every time this component is mounted or updated then
            // this chart is generated from the ground again but with the same data, waste of time and performance
            if(response.graph.responsesConsolidated.length !== 0){
              wordCloudChart = <TRCWordCloud answers={response.graph.responsesConsolidated}
                                             type={'single'}
                                             userAnswer={response.graph.userAnswerConsolidated}
                                             usersId={usersId}
                                             username={user.user_alias === '' ? user.username : user.user_alias}
                                             index={index}
                                             doRecomputeRowHeights={this.props.doRecomputeRowHeights}
                                             isVirtualized={true}
                                             updateSVG={this.props.updateSVG}
                                             svg={response.graph.wordCloud}
                                             responseId={response.id}
                                             objSearch={this.props.objSearch}
                                             colors={colors}/>;
            }

            if(response.graph.mapsConsolidated.length !== 0){
              barChart = <TRCBarChart maps={response.graph.mapsConsolidated}
                                      type={'single'}
                                      taskType={task.task_type}
                                      usersId={usersId}
                                      userAnswer={response.graph.userAnswerConsolidatedMap}
                                      userAvatar={user.avatar}
                                      answersLength={response.graph.responsesConsolidated.length}
                                      index={index}
                                      doRecomputeRowHeights={this.props.doRecomputeRowHeights}
                                      isVirtualized={true}
                                      updateSVG={this.props.updateSVG}
                                      svg={response.graph.barChart}
                                      responseId={response.id}/>;
            }
            break;
          case 'Choice':
            if(response.graph.mapsConsolidated.length !== 0){
              //console.log(this.state.mapsConsolidated);
              barChart = <TRCBarChart maps={response.graph.mapsConsolidated}
                                      type={'single'}
                                      taskType={task.task_type}
                                      userAnswer={response.graph.userAnswerConsolidated.answersId}
                                      taskAnswers={task.data.answers}
                                      usersId={usersId}
                                      answersLength={response.graph.responsesConsolidated.length}
                                      userAvatar={user.avatar}
                                      index={index}
                                      doRecomputeRowHeights={this.props.doRecomputeRowHeights}
                                      isVirtualized={true}
                                      updateSVG={this.props.updateSVG}
                                      svg={response.graph.barChart}
                                      responseId={response.id}/>;
            }
            break;
        }
      }

      stateColor = this._getStateColor(role_1, response.state);
      stateMessage = this._getStateDropAction(response.state, response.task_id, response.pre_state, response.id);

      likeIcon = response.isLike ? ['fas','heart'] : ['far','heart'];
      likeBtn = communityState !== 'Closed' ? response.user_id === response.currentUserId ?
        <React.Fragment><FontAwesomeIcon icon={likeIcon}/><label>{response.likes}</label></React.Fragment> :
        <div onClick={() => this.props.saveResponseLike(response.id, response.activity_id, !response.isLike,
          user.id)}><FontAwesomeIcon icon={likeIcon} className="chevron"/>
          <label style={{cursor:'pointer'}}>{response.likes}</label>
        </div> :
        <React.Fragment><FontAwesomeIcon icon={likeIcon}/><label>{response.likes}</label></React.Fragment>;

      let sumAllCommentsReplies = 0;
      response.comments.forEach(c => {++sumAllCommentsReplies;c.replies.forEach(r => {++sumAllCommentsReplies})});

      btnComments = 
      <button onClick={() => this.props.updateShowComments(response.id, this.props.index)} type="button"
                            className={response.showComments ? 'btn btn-comments active' : 'btn btn-comments inactive'}
        style={ response.showComments ? 
          { color: '#ffffff', backgroundColor: colors.color1[3], borderColor: colors.color1[3] } : { color: colors.color1[3],
            backgroundColor: 'transparent', borderColor: colors.color1[3]} }>
        <div className="comments-container">
          {response.sum_unseen_col > 0 && <div className="unseen" style={{backgroundColor: colors.color4[3]}}/>}
          <FontAwesomeIcon icon={['fas', 'message']} className="icon-comments"/>
          <span className="txt-comment">{i18n[language].comments}</span>
          <div className="number-container">
            <span className="number">{sumAllCommentsReplies}{/*response.comments.length*/}</span>
          </div>
        </div>
      </button>
    }

    return (
      <React.Fragment>
        { cardHeader }
        <div id={response.id} className="panel px-card px-panel-task" style={{borderLeft: '10px solid' + stateColor}}>
          {
            role_1 === 'Collaborator' &&
            <React.Fragment>
                <span style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                  <FontAwesomeIcon onClick={this.updateShowSeeNotes} className="share-icon" icon={['fas','note-sticky']}
                                   style={{cursor:'pointer', marginRight: '10px', position: 'relative'}}/>
                  { consolidateIcon && <FontAwesomeIcon onClick={() => this.props.handleShowModalConsolidated(response)}
                                                        icon={['fas', 'layer-group']} className="share-icon"
                                                        style={{cursor: 'pointer', marginRight: '10px', position: 'relative'}}/>}
                  { copyIcon && <FontAwesomeIcon onClick={() => this.copyResponseToClipboard(response.id)} className="share-icon" icon={['fas', 'copy']}
                                    style={{cursor: 'pointer', marginRight: '10px', position: 'relative'}}/>}
                  <FontAwesomeIcon onClick={() => this.props.showModalExportHandler(task.activity_id, task.id, user.id, [], [])}
                                   icon={['fas', 'share']} className="share-icon" style={{cursor:'pointer', position: 'relative'}}/>
                  </span>

            </React.Fragment>
          }
          <div className="panel-body">
            {cardTitle}
            { showHeaderSeparator && <div className="" style={{marginTop:'15px'}}/> }
            <div className="minimized-content" style={{display: response.isMax ? 'block' : 'none'}}>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  {showAnswer}
                  {
                    response.isLoadingConsolidated ?
                      <LoadingConsolidated/> :
                      response.showConsolidated &&
                      <React.Fragment>
                        {wordCloudChart}
                        {barChart}
                      </React.Fragment>
                  }
                </div>
              </div>
              <div className="px-attachments-container ">
                { attachments }
                { videoThumbnail }
                <MediaQuery minDeviceWidth={481}>
                  { downloadIcon }
                </MediaQuery>
              </div>
              {
                (!response.showConsolidated && response.participantMapItems.length !== 0) &&
                <div className="px-mood-container">
                  {!showAllVerbatim && <span className="px-mood-text">{response.mapTitle[cmntyLanguage]}</span>}
                  <div className="master-mood-container">
                    <ul className="list-inline px-mood-list">
                      { moods }
                    </ul>
                  </div>
                </div>
              }
            </div>
            <div className="separator mg-top-bottom-15" />
            <div className="comments-option-container">
              <div style={{display:'flex', width:'100%'}}>
                {
                  (response.state !== 'Empty' && role_1 === 'Collaborator') &&
                  (role_2 === 'Owner' || role_2 === 'Moderator') ?
                    <div className="dropdown">
                      <div className="px-btn-dropdown-status dropdown-toggle"
                           data-toggle={communityState !== 'Closed' && "dropdown"}>
                        <div className="px-drop-donut mg-right-15" style={{backgroundColor: stateColor}}/>
                        <span className="status-text">{responseStateLang}</span>
                        <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
                      </div>
                      {
                        response.state !== 'Empty' &&
                        <ul className="dropdown-menu px-dropdown-status">
                          <li>{ stateMessage }</li>
                        </ul>
                      }
                    </div> :
                    <div className="px-btn-dropdown-status" style={{cursor: 'default'}}>
                      <div className="px-drop-donut mg-right-15" style={{backgroundColor: stateColor}}/>
                      <span className="status-text">{responseStateLang}</span>
                    </div>
                }
                {
                  response.state !== 'Empty' &&
                  <div className="px-comment-likes-container" style={{cursor: role_2 !== 'Observer' ? 'pointer' : 'default'}}>
                    { role_2 !== 'Observer' ? !isSavingLikeResponse ? likeBtn :
                      <FontAwesomeIcon icon={['fas','spinner']} spin/> : <FontAwesomeIcon icon={likeIcon}/>
                    }
                  </div>
                }
              </div>
              { btnComments }
            </div>
            <div className={response.showComments ? 'px-n-accordion-open' : 'px-n-accordion-close'}
              style={{ backgroundColor: window.innerWidth < 481 && colors.color1[6], borderBottomColor: colors.color1[6]}}>
              {
          
                response.showComments &&
                <Comments comments={response.comments}
                          language={language}
                          userId={response.user.id}
                          username={response.user.username}
                          questionId={response.task.id}
                          responseId={response.id}
                          from={'CTV'}
                          role_1={role_1}
                          role_2={role_2}
                          qc={qc}
                          updateShowComments={this.props.updateShowComments}
                          updateAUResponses={this.props.updateUserResponses}
                          updateResponseComments={this.props.updateResponseComments}
                          entity={this.props.entity}
                          scrollFirstTime={this.props.scrollFirstTime}
                          updateScrollFirstTime={this.props.updateScrollFirstTime}
                          item_scroll={item_scroll}
                          communityState={communityState}
                          cmntyLanguage={cmntyLanguage}
                          commentForceUpdateGrid={this.props.commentForceUpdateGrid}
                          colors={colors}
                          index={this.props.index}
                          recalculateRowHeight={this.props.recalculateRowHeight}
                          company={company}
                          projectType={this.props.projectType}
                          response={response}
                          taskType={task.task_type}
                          user={user}
                          task={task}
                          community={this.props.community}
                />
              }
            </div>



            {
              this.state.showTaskLB ?
              <Lightbox currentImage={0}
                        images={[{src: task.task_type === 'Canvas' ? response.attachments[0].url : task.url}]}
                        isOpen={this.state.lightboxIsOpen}
                        onClickPrev={undefined}
                        onClickNext={undefined}
                        onClickThumbnail={this.gotoImage}
                        onClose={this.closeLightbox}/> :
                <Lightbox currentImage={this.state.currentImage}
                          images={imagesLB}
                          isOpen={this.state.lightboxIsOpen}
                          onClickPrev={this.gotoPrevious}
                          onClickNext={this.gotoNext}
                          onClickThumbnail={this.gotoImage}
                          onClose={this.closeLightbox}/>
            }
            {
              response.video_thumbnail &&
              <ReactModal isOpen={this.state.showModalVideo} contentLabel="Response Video"
                          shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalVideo}
                          className="my-modal-content" overlayClassName="my-modal-overlay">
                <TRModalVideo updateShowModalVideo={this.updateShowModalVideo}
                              thumbnail={response.video_thumbnail}
                              url={response.video_url}
                              language={language}
                              response={response}
                              colors={colors}
                />
              </ReactModal>
            }
          </div>
          <div onClick={() => this.handleIsMax(response.isMax, response.id, index)} className="circle-bottom"
               style={{cursor:'pointer'}}>
            <FontAwesomeIcon icon={['far','up-down']} />
          </div>
        </div>
        {
          this.state.showSeeNotes &&
          <MUSeeNotes showSeeNotes={this.state.showSeeNotes}
                      updateShowSeeNotes={this.updateShowSeeNotes}
                      user={user}
                      language={language}
                      colors={colors}/>
        }
        {
          task.video_thumbnail &&
          <ReactModal isOpen={this.state.showModalTaskVideo} contentLabel="Task Video"
                      shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalTaskVideo}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <T_ModalVideo updateShowModalVideo={this.updateShowModalTaskVideo}
                          thumbnail={task.video_thumbnail}
                          url={task.video_url}
                          language={language}
                          colors={colors} />
          </ReactModal>
        }
      </React.Fragment>
    );
  }

  onHeightChange = (rowHeight = 38) => {
    // console.log('rowHeight:', rowHeight, 'this.props.from:', this.props.from );
    if(this.props.from !== 'PAV' && this.props.from !== 'PSA' && rowHeight > 38){
      this.props.recalculateRowHeight(this.props.index)
      //this.props.commentForceUpdateGrid();
    }
  };

  copyResponseToClipboard = (responseId) => {
    const { task, response, language } = this.props;
    const i18n = {
      en: { copyToClipboard: 'Copied to clipboard' },
      es: { copyToClipboard: 'Copiado a portapapeles' }
    };

    let copiedContent = '';

    // Handle MicroChat task type
    if (task.task_type === 'MicroChat' && response.id === responseId) {
      const conversationsContainer = document.getElementById(responseId).querySelector('.conversations-container');
      const senderContainers = conversationsContainer.querySelectorAll('.sender-container');

      copiedContent = Array.from(senderContainers)
        .map((senderContainer) => {
          const isAI = senderContainer.classList.contains('ai');
          const sender = isAI ? 'AI' : senderContainer.classList[1];
          const message = senderContainer.querySelector('.conversation-item').textContent.trim();
          return `${sender}\t${message}`;
        })
        .join('\n');
    }
    // Handle Open End, Multimedia, and Canvas task types
    else if ((task.task_type === 'Open End' || task.task_type === 'Multimedia' || task.task_type === 'Canvas') && response.id === responseId) {
      const attachments = response.attachments;
      let attachmentTranscriptsArray = [];

      if (attachments && attachments.length > 0) {
        attachmentTranscriptsArray = attachments.map((attachment, index) =>
          `[Image Transcript ${index + 1}]: ${attachment.transcript}`
        );
      }

      const attachmentTranscripts = attachmentTranscriptsArray.join(' ');
      const videoTranscript = response.video_transcript ? ` [Video Transcript]: ${response.video_transcript}` : '';

      if (task.task_type === 'Open End') {
        const answerContainer = document.getElementById(responseId).querySelector('.answer');
        copiedContent = `${answerContainer.textContent.trim()} ${attachmentTranscripts} ${videoTranscript}`;
      } else {
        copiedContent = `${attachmentTranscripts} ${videoTranscript}`;
      }
    }

    if (copiedContent) {
      const textarea = document.createElement('textarea');
      textarea.value = copiedContent;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      // Show the toast message
      showSuccessMsg(i18n[language].copyToClipboard);
    }
  };

  updateShowTaskLB = () => {
    this.setState(prevState => ({showTaskLB: !prevState.showTaskLB}), () => {
      this.setState({ lightboxIsOpen: true });
    });
  };

  updateShowModalTaskVideo = () => {
    this.setState(state => ({ showModalTaskVideo: !state.showModalTaskVideo }));
  };

  downloadFiles = async (type, action, urlOne) => {
    // console.log(type, action);
    const {response, user, task} = this.props;

    this.setState({isSavingImages: true, savedImagesCounter: 0}, async() => {
      if(type === 'images'){
        if(action === 'one'){
          const blob = await fetch(decodeURIComponent(urlOne)).then(r => r.blob());
          // console.log('blob:', blob);
          downloadjs(blob, Date.now(), blob.type);
          this.setState({isSavingImages: false});
        } else {
          const zip = new JSZip();

          for(const attachment of response.attachments){
            // console.log(decodeURIComponent(attachment.url));
            const blob = await fetch(decodeURIComponent(attachment.url)).then(r => r.blob());
            // console.log('blob:', blob);
            let extension = '';
            switch (blob.type) {
              case 'image/jpeg': extension = 'jpg';break;
              case 'image/png': extension = 'png';break;
              default: extension = 'jpg';
            }

            zip.file(Date.now() + '.' + extension, blob, {base64: true});
            this.setState(prevState => ({savedImagesCounter: prevState.savedImagesCounter + 1}));
          }

          if(response.video_url !== ''){
            const blob = await fetch(decodeURIComponent(response.video_url)).then(r => r.blob());
            // console.log('blob:', blob);
            //download(blob, Date.now(), blob.type);
            const extension = blob.type === 'video/mp4' ? 'mp4' : '';
            zip.file(Date.now() + '.' + extension, blob, {base64: true});
            this.setState(prevState => ({savedImagesCounter: prevState.savedImagesCounter + 1}));
          }




          zip.generateAsync({type:"blob"}).then((content) => {
            const zipName = user.user_alias === '' ? user.username : user.user_alias.substr(1);
            const actTitle = task.title.replace(/(<([^>]+)>)/ig,"").substr(0, 8);

            saveAs(content, zipName + '('+ actTitle + ')' + '.zip');
            // console.log('Ready');
            this.setState({isSavingImages: false});
          });
        }
      } else {
        // VIDEOS
      }
    });
  };

  updateShowSeeNotes = () => {
    this.setState(prevState => ({showSeeNotes : !prevState.showSeeNotes}));
  }

  handlePopupVerbatim = () => {
    this.setState(state => ({showPopupVerbatim: !state.showPopupVerbatim}));
  };

  updateShowOriginal = () => {
    this.setState(prevState => ({showOriginal: !prevState.showOriginal}), () => {
      this.props.doRecomputeRowHeights(this.props.index);
    });
  };

  hideMenuAndRegenerate = responseId => {
    this.setState({showPopupVerbatim: false});
    this.props.regenerateResponseVerbatim(responseId);
  };

  updateShowConsolidated = (index, type) => {
    const {response, user, task} = this.props;

    this.props.getResponsesConsolidatedSingle(response.id, [user.id], [task.id], this.props.usersId, task.task_type, user.id,
      response.showConsolidated, type);
    //TO-DO Try to move this info inside the response[] (Transcript container) 'cos when the component is out
    // of the viewport then the component just disappears and its states too.
    /*this.setState(prevState => ({
      showConsolidated: !prevState.showConsolidated,
    }), () => {
      if(this.state.showConsolidated){
        this.get_tv_responses_consolidated();
      } else {
        this.props.doRecomputeRowHeights(index);
      }
    });*/
  };

  get_tv_responses_consolidated = () => {
    const config = {
      params: {
        users_id: [this.props.user.id],
        tasks_id: [this.props.task.id],
        allUsersId: this.props.usersId,
        consolidated: 'general'
      }
    };

    this.setState(prevState => ({isLoadingConsolidated: !prevState.isLoadingConsolidated}), () => {
      axios.get('/responses/get_tv_responses_consolidated', config)
        .then(r =>  {
          // console.log('responses DB: ', r.data);
          let allMapsConsolidated = [];
          let mapsConsolidated = [];
          let userAnswerConsolidatedMap = [];

          switch(this.props.task.task_type){
            case 'Open End':
              allMapsConsolidated = r.data[0].responses.map(response => response.participantMapItems
                .map(item => item[this.props.language]));
              mapsConsolidated = [].concat.apply([], allMapsConsolidated);
              userAnswerConsolidatedMap = ((r.data[0].responses.find(response => response.user_id === this.props.user.id))
                .participantMapItems).map(item => item[this.props.language]);
              break;
            case 'Choice':
              allMapsConsolidated = r.data[0].responses.map(response => response.data.answersId.map(item => item));
              mapsConsolidated = [].concat.apply([], allMapsConsolidated);
              userAnswerConsolidatedMap = (r.data[0].responses.find(response => response.user_id === this.props.user.id))
                .data.answersId;
              break;
          }

          // console.log(mapsConsolidated);
          // console.log(allMapsConsolidated);
          // console.log(userAnswerConsolidatedMap);

          this.setState({
            responsesConsolidated: r.data[0].responses.map(response => response.data),
            userAnswerConsolidated: r.data[0].responses.find(response => response.user_id === this.props.user.id).data,
            mapsConsolidated: mapsConsolidated,
            userAnswerConsolidatedMap: userAnswerConsolidatedMap,
            isLoadingConsolidated: false
          })
        }).catch(error => {
        console.log(error);
        // TO-DO turnOff the loading
      });
    });
  };

  handleIsMax = (isMax, responseId, index) => {
    //this.setState(prevState => ({isMin: !prevState.isMin}), () => {
      //console.log(this.state.isMin);
      // TO-DO when this component is not complete on the viewport sometimes the setState
      // doesn't work and the doForceUpdateGrid delete and create a new component so the state is initializated
      const offset = this.props.hideTabFilters ? 100 : 350;

      if(isMax){
        const id = '#' + responseId;
        const cardPosition = $(id).offset().top;

        $(window).scrollTop(cardPosition - offset);
      }
      this.props.toggleTaskIsMax(responseId, index);
      //this.props.doRecomputeRowHeights(index);
    //});
  };

  updateShowModalVideo = () => {
    this.setState({showModalVideo: !this.state.showModalVideo});
  };

  _getStateDropAction(state, question_id, pre_state, responseId){
    const colors = this.props.colors;
    let message = '';
    switch(state){
      case 'Draft':
        message = <div onClick={() => this.props.updateResponse('Accepted', responseId, this.props.user.id)} className="px-item-drop">
          <div className="px-drop-donut mg-right-15" style={{backgroundColor: colors.colorStates[0]}}/>
          <span>{getResponseStateLang('Accepted', this.props.language)}</span>
        </div>;
        break;
      case 'Completed':
        message = <div onClick={() => this.props.updateResponse('Accepted', responseId, this.props.user.id)} className="px-item-drop">
          <div className="px-drop-donut mg-right-15" style={{ backgroundColor: colors.colorStates[0]}}/>
          <span>{getResponseStateLang('Accepted', this.props.language)}</span>
        </div>;
        break;
      case 'Accepted':
        const colorState = pre_state === 'Draft' ? '#47505c' : colors.colorStates[1];

        message = <div onClick={() => this.props.updateResponse('Rejected', responseId, this.props.user.id)} className="px-item-drop">
          <div className="px-drop-donut mg-right-15" style={{backgroundColor:colorState}}/>
          <span className="px-state-li">{getResponseStateLang(pre_state, this.props.language)}</span>
        </div>;
        break;
    }

    return message;
  }

  displayMarkers(target, annotations, question_id, clearPrevAnnotations = true) {
    if (clearPrevAnnotations) {
      this.removeMarkersFromImage(question_id);
    }
    if (annotations) {
      const markerView = new mjslive.MarkerView(target);
      markerView.targetRoot = document.getElementById(`canvasImgContainer${question_id}`);
      let mjsLive_key = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? process.env.mjsLive_license_key : process.env.REACT_APP_MJSLIVE_LICENSE_KEY;
      mjslive.Activator.addKey(mjsLive_key);
      markerView.show(JSON.parse(annotations));
    }
  }

  imageToShowInTask(task, response) {
    if (!task) {
      console.error("Task is undefined");
      return '';
    }

    if (!response) {
      return task.url_thumbnail || task.url;
    }

    if (task.task_type === 'Canvas' && response.attachments && response.attachments.length > 0) {
      const attachment = response.attachments.slice(-1)[0];
      if (attachment) {
        return attachment.url_thumbnail || attachment.url;
      }
    }

    return task.url_thumbnail || task.url;
  }


  _getStateColor(role_1, state){
    let color = '';
    const colors = this.props.colors;
    if(role_1 === 'Collaborator'){
      switch (state) {
        case 'Accepted':
          color = colors.colorStates[0];
          break;
        case 'Completed':
          color = colors.colorStates[1];
          break;
        case 'Draft':
          color = '#47505c';
          break;
        case 'Empty':
          color = '#B1BDC0';
      }
    } else {
      color = '#f6f6f6';
    }

    return color;
  }

  // TO-DO Move to a global script
  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
      showTaskLB: false
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };
  gotoPrevious = () => {
    this.setState({ currentImage: this.state.currentImage - 1 });
  };
  gotoNext = () => {
    this.setState({ currentImage: this.state.currentImage + 1 });
  };
  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    });
  };
}
