import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MBBilling from "./Billing/MBBilling";
import MBSubs from "./Billing/MBSubs";
import MBHistory from "./Billing/MBHistory";



export default class Billing extends React.Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    company: PropTypes.object.isRequired,
    saveBilling: PropTypes.func.isRequired,
    invoices: PropTypes.array.isRequired,
    isLoadingInvoices: PropTypes.bool.isRequired,
    subscription: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'billing'
    }
  }

  render(){
    const {language, company, invoices, isLoadingInvoices, subscription, colors} = this.props;

    const i18n = {
      en: {
        details: 'Billing details', subs: 'Subscription & Credits', history: 'History'
      },
      es: {
        details: 'Facturación', subs: 'Subscripcion & Créditos', history: 'Historial'
      }
    };

    const tabStyle = {
      active: { border: `1px solid ${colors.color0[3]}`, color: '#ffffff', backgroundColor: colors.color0[3] },
      inactive: { border: '1px solid #DADDE1', color: '#7C8593', backgroundColor: '#f0eff3' }
    };

    return (
      <div className="px-settings-container tabs">

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

            <ul className="nav nav-tabs px-submenu-settings-navigation" role="tablist">
              <li className="active">
                <a onClick={() => this.setActiveTab('billing')} href="#billing" className="three-opt" role="tab" data-toggle="tab"
                  style={this.state.activeTab === 'billing' ? tabStyle.active : tabStyle.inactive}>
                  <span className="msg-lg">{i18n[language].details}</span>
                  <span className="msg-sm" style={{fontSize: '18px'}}>{<FontAwesomeIcon icon={['fas', 'file-invoice-dollar']} />}</span>
                </a>
              </li>
              <li className="">
                <a onClick={() => this.setActiveTab('subscriptions')} href="#subs" className="three-opt" role="tab" data-toggle="tab"
                  style={this.state.activeTab === 'subscriptions' ? tabStyle.active : tabStyle.inactive}>
                  <span className="msg-lg">{i18n[language].subs}</span>
                  <span className="msg-sm" style={{ fontSize: '18px' }}>{<FontAwesomeIcon icon={['fas', 'user-tag']} />}</span>
                </a>
              </li>
              <li >
                <a onClick={() => this.setActiveTab('history')} href="#history" className="three-opt" role="tab" data-toggle="tab"
                  style={this.state.activeTab === 'history' ? tabStyle.active : tabStyle.inactive}>
                  <span className="msg-lg">{i18n[language].history}</span>
                  <span className="msg-sm" style={{ fontSize: '18px' }}>{<FontAwesomeIcon icon={['fas', 'receipt']} />}</span>
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div className="active tab-pane fade in" id="billing">
                <MBBilling language={language}
                           company={company}
                           saveBilling={this.props.saveBilling}
                           colors={colors}/>
                {/*<MBPayment language={language}
                            company={company}
                            saveBilling={this.props.saveBilling}/>*/}
              </div>
              <div className="active tab-pane fade" id="subs">
                <MBSubs language={language}
                        company={company}
                        subscription={subscription}
                        colors={colors}/>
              </div>
              <div className="active tab-pane fade" id="history">
                <MBHistory language={language}
                           company={company}
                           invoices={invoices}
                           isLoadingInvoices={isLoadingInvoices}
                           colors={colors}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  setActiveTab = (tabName) => {
    this.setState({ activeTab: tabName });
  }
}