import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";

export default class UIGModal02 extends React.Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showModal} contentLabel="Modal Test" shouldCloseOnOverlayClick={true}
                  onRequestClose={() => this.props.updateShowModal('sm01')} className="px-modal-content"
                  overlayClassName="px-modal-overlay">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                <i onClick={() => this.props.updateShowModal('sm01')} className="material-icons">clear</i>
              </button>
            </div>

            <div className="modal-body">
              
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}
