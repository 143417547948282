import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class CSBioPart extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    cu: PropTypes.object.isRequired,
    cuHandleChange: PropTypes.func.isRequired,
    saveBio: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {community, cu, language} = this.props;

    const I18n = {
      en: {
        bioLabel: 'IN 240 CHARACTERS, TELL THE COMMUNITY SOMETHING ABOUT YOU',
        charsLeft: 'chars. left', bioInfo: 'This Bio will only be visible in ',
        save: 'SAVE'
      },
      es: {
        bioLabel: 'EN 240 CARACTERES, DINOS ALGO SOBRE TI',
        charsLeft: 'chars. restantes', bioInfo: 'Esta bio solo será visible en ',
        save: 'GUARDAR'
      }
    };

    return (
      <div id="divBio" className="manage">
        <div className="px-settings-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-card px-card-settings">
                <div className="px-icon-settings">
                  <FontAwesomeIcon icon={['fas','pen-nib']}/>
                </div>
                <div className="panel-body px-body-padding-bio">
                  <div className="form-group">
                    <label className="label-sm">{I18n[language].bioLabel}</label>
                    <div className="bio-text-area-container">
                      <textarea name="bio" id="bio" onChange={this.props.cuHandleChange} value={cu.bio ? cu.bio : ''}
                                cols="30" rows="10" className="px-bio-text-area"/>
                      <span className="px-label-information char-remaining">{(240 - (cu.bio ? cu.bio.length : 0))} {I18n[language].charsLeft}</span>
                    </div>
                    <p className="px-label-information">
                    <FontAwesomeIcon icon={['fal','circle-info']} style={{color:'#7FEACE', fontSize:'12px',
                      marginRight:'5px'}}/>
                      {I18n[language].bioInfo} "{community.title}"
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                      <button onClick={this.props.saveBio} className="btn px-btn lg btn-blue-base">{I18n[language].save}</button>
                    </div>
                  </div>
                </div>
              </div>
              {/*
                community.social_flag &&
                <div className="px-card px-card-settings">
                  <div className="panel-body px-crit-options-body">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <label className="label-options mg-bottom-25">Privacy Options</label>
                      </div>
                    </div>
                    <div className="px-alert-item lg">
                        <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-alert"/>
                        <p className="px-label-warning text-left">Personal details in your profile, like your name and email address,
                          won’t be ever disclosed to other participants.</p>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-switch-item-container">
                        <div className="px-switch-item-conf">
                          <label className="label-sm text-center">DISCLOSE YOUR <br/>GENDER</label>
                          <label className="px-switch" htmlFor="discloseGenderFlag">
                          <input name="show_gender" type="checkbox" id="discloseGenderFlag"
                                    onChange={this.props.cuHandleChange} checked={cu.show_gender}/>
                            <div className="slider round"/>
                          </label>
                        </div>
                        
                        <div className="px-switch-item-conf">
                          <label className="label-sm text-center">DISCLOSE YOUR <br/> COUNTRY-STATE</label>
                          <label className="px-switch" htmlFor="discloseCityFlag">
                            <input name="show_location" type="checkbox" id="discloseCityFlag"
                                    onChange={this.props.cuHandleChange} checked={cu.show_location}/>
                            <div className="slider round"/>
                          </label>
                        </div>
                        <div className="px-switch-item-conf">
                          <label className="label-sm text-center">DISCLOSE YOUR <br/>SOCIAL MEDIA LINKS</label>
                          <label className="px-switch" htmlFor="discloseSocialFlag">
                            <input name="show_media" type="checkbox" id="discloseSocialFlag"
                                    onChange={this.props.cuHandleChange} checked={cu.show_media}/>
                            <div className="slider round"/>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
