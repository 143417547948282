import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class TB_ActivityCard extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    instructions: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    showModalExportHandler: PropTypes.func,
    role_2: PropTypes.string,
    participant_view: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isMax: props.from === 'Participant'
    }
  }

  handleIsMax = () => {
    this.setState(state => ({isMax: !state.isMax}));
  };

  render() {
    let instructions = this.props.instructions.replace(/(<([^>]+)>)/ig,"");
    instructions = instructions.replace(/\r?\n|\r/g, '');

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
          <div className={this.props.participant_view === 'One per screen' ?
            "px-card px-card-actv-instructions resp-part": "px-card px-card-actv-instructions"}>
            <div className="panel-body">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="title-container">
                    <span>{this.props.title}</span>
                  </div>
                </div>
              </div>
              {
                instructions !== '' &&
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <p dangerouslySetInnerHTML={{__html: this.props.instructions}}
                        className={this.state.isMax ? 'body-text' : 'body-text minimized'}>
                      </p>
                    </div>
                  </div>
              }
            </div>
            {
              instructions !== '' &&
                <div onClick={this.handleIsMax} className="circle-bottom" style={{cursor:'pointer'}}>
                  <FontAwesomeIcon icon={['far','up-down']} />
                </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
