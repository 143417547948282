import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import spanishStrings from "react-timeago/lib/language-strings/es";
import {notifications_i18n} from "../UI/Globals/PX_i18n";

import Notification from "./Notification";
import Loading from "../UI/Globals/Loading";

export default class NotificationList01 extends React.Component {
  static propTypes = {
    notifications: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
    getNotifications: PropTypes.func.isRequired,
    notificationsHasMore: PropTypes.bool.isRequired,
    sltType: PropTypes.string.isRequired,
    updateRightDrawerType: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    updateOpenPMFromOutside: PropTypes.func,
    selectedCommId: PropTypes.number.isRequired,
    updateNotificationsDate: PropTypes.func.isRequired,
    notificationsDate: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      today: []
    }
  }

  render() {
    const {language, sltType, colors, notifications, selectedCommId, isLoadingNotifications, notificationsDate} = this.props;

    const formatter = language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);
    let content;

    if(notifications.length === 0){
      content = isLoadingNotifications ? <Loading/> :
        <div className="notifications-items-container">
          <div className="notification-item">
            <div className="no-notifications text-center">
              {notifications_i18n[language].no_new_notifications}
              <span onClick={() => this.props.updateNotificationsDate(notificationsDate === 'today' ? 'old' : 'today')}
                    className="date"
                    style={{color: colors.color1[3], cursor:'pointer'}}>
                  {notificationsDate === 'today' ? notifications_i18n[language].old : notifications_i18n[language].today}
                </span>
            </div>
          </div>
        </div>;
    } else {
      content = <InfiniteScroll
        pageStart={0}
        loadMore={this.props.getNotifications}
        hasMore={this.props.notificationsHasMore}
        loader={<Loading key={0}/>}
        useWindow={false}>
        <div className="notifications-items-container">
          <div className="notification-item">
            { notifications.map((notification, index) => {
              if(selectedCommId === -1) {
                return(
                  <Notification key={index}
                                notification={notification}
                                language={language}
                                formatter={formatter}
                                sltType={sltType}
                                updateRightDrawerType={this.props.updateRightDrawerType}
                                updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                                colors={colors}/>
                )
              } else {
                if(selectedCommId === notification.community_id) {
                  return(
                    <Notification key={index}
                                  notification={notification}
                                  language={language}
                                  formatter={formatter}
                                  sltType={sltType}
                                  updateRightDrawerType={this.props.updateRightDrawerType}
                                  updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                                  colors={colors}/>
                  )
                }
              }
            }) }
          </div>
        </div>
      </InfiniteScroll>
    }

    return(
      <React.Fragment>
        {content}
      </React.Fragment>
    )
  }

  _getSortedNotifications = (notifications, type) => {
    let todayNotifications = [];
    let oldNotifications = [];
    let sortedNotifications = [];

    notifications.map(notification => {
      if(notification.user_to_roles){
        if(notification.user_to_roles.role_1 === 'Collaborator'){
          if(new Date(notification.notification.created_at).getDate() === new Date().getDate()){
            todayNotifications.push(notification);
          } else {
            oldNotifications.push(notification);
          }
        } else {
          if(notification.community_state === 'Enabled'){
            if(new Date(notification.notification.created_at).getDate() === new Date().getDate()){
              todayNotifications.push(notification);
            } else {
              oldNotifications.push(notification);
            }
          }
        }
      }
    });

    //sortedNotifications.push(todayNotifications);
    //sortedNotifications.push(oldNotifications);

    if(type === 'today'){
      return todayNotifications;
    } else {
      return oldNotifications;
    }
  };
}