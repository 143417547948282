import React, {useState, useEffect, useRef} from "react"
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import {numberFilterSegment} from "../../../UI/Globals/PX_Funs";

const i18n = {
  en: {
    acts: 'Activities', parts: 'Participants', segs: 'Segments', tags: 'Tags', data: 'Data to include',
    taskType: 'Task Types', privacy: 'Privacy', expTo: 'Export to', focus: 'Organize by',
    pickFilter: 'Pick one or more items', rawData: 'Raw Data Only', creationDate: 'Creation Date', startDate: 'Start date',
    endDate: 'End date', search: 'SEARCH', all: 'All', none: 'None', participants: 'participants', title: 'Title',
    docs: 'Documents'
  },
  es: {
    acts: 'Actividades', parts: 'Participantes', segs: 'Segmentos', tags: 'Tags', data: 'Datos a incluir',
    taskType: 'Tipos de Tareas', privacy: 'Privacidad', expTo: 'Exportar a', focus: 'Ordenar por',
    pickFilter: 'Selecciona una o más casillas', rawData: 'Solo Datos Brutos', creationDate: 'Creación', startDate: 'Inicio',
    endDate: 'Expiración', search: 'BUSCAR', all: 'Todo', none: 'Nada', participants: 'participantes', title: 'Titulo',
    docs: 'Documentos'
  }
}

const ChMMain = ({ type, updateType, language, colors, title, setTitle, objExport, allAvailableActs, users,
                   segments, updateObjExportActions, genderAsSegment, dataTypes, taskTypes, projectType,
                   documents, searchModal, updateObjExpAllNone }) => {
  const [q, setQ] = useState('')
  const [filterAllNone, setFilterAllNone] = useState({activities: 'All', participants: 'All', segments: 'All'})
  const isFirstRender = useRef(true)

  useEffect(() => {
    if(isFirstRender.current){
      isFirstRender.current = false
      return
    }
    // console.log('useEffect:', filterAllNone)
    updateObjExpAllNone(type, filterAllNone[type.toLowerCase()])
  }, [filterAllNone])

  const _formatSelected = (array, type) => {
    const i18n = {
      en: { select: 'Select', selectFilter: 'Select a custom filter' },
      es: { select: 'Selecciona', selectFilter: 'Selecciona un filtro'}
    }
    let selected = '';

    if(array !== undefined){
      if(type !== 'cfId'){
        if(array.length === 0){
          selected = `${i18n[language].select} ` + type;
        }

        if(array.length === 1 && array[0]){
          switch(type){
            case 'activities':
              //console.log(allAvailableActs, objExport);
              selected = allAvailableActs.find(a => a.id === objExport.idActs[0]).title; break;
            case 'participants':
              selected = users.find(u => u.id === objExport.idPars[0]).username; break;
            case 'segments':
              selected = segments.find(s => s.id === objExport.idSegs[0]).name; break;
            case 'tags':
              //selected = tags.find(t => t.id === objExport.idTags[0]).name; break;
            case 'data types':
              selected = objExport.dataTypes[0]; break
            case 'task types':
              selected = objExport.taskTypes[0]; break
            case 'privacy':
              selected = objExport.privacy[0]; break;
            case 'documents':
              selected = documents.find(d => d.id === objExport.idDocs[0]).name;break
          }
        }

        if(array.length > 1){
          switch(type){
            case 'activities':
              selected = _findAndFormat(allAvailableActs, objExport.idActs, type); break
            case 'participants':
              selected = _findAndFormat(users, objExport.idPars, type); break
            case 'segments':
              selected = _findAndFormat(segments, objExport.idSegs, type); break
            case 'tags':
              //selected = _findAndFormat(tags, objExport.idTags, type); break;
            case 'data types':
              selected = _findAndFormat(dataTypes, objExport.dataTypes, type); break
            case 'task types':
              selected = _findAndFormat(taskTypes, objExport.taskTypes, type); break
            case 'privacy':
              //selected = _findAndFormat(privacy, objExport.privacy, type); break;
            case 'documents':
              selected = _findAndFormat(documents, objExport.idDocs, type);break
          }
        }
      } else {
        if(objExport.cfId){
          //selected = userFilters.find(uf => uf.id === objExport.cfId).name;
        } else {
          selected = i18n[language].selectFilter;
        }
      }
    }
    return selected;
  }
  const _findAndFormat = (mainArray, array, type) => {
    //console.log(mainArray, array, type);
    let string = '';

    for(let i = 0; i < array.length ; i++) {
      if(i < 3){
        switch(type){
          case 'activities':
            const activity = mainArray.find(a => a.id === array[i]);
            string += activity.title + ', ';
            break;
          case 'participants':
            const user = mainArray.find(u => u.id === array[i]);
            string += user.username + ', ';
            break;
          case 'segments':
            const segment = mainArray.find(s => s.id === array[i]);
            string += segment.name + ', ';
            break;
          case 'tags':
            const tag = mainArray.find(t => t.id === array[i]);
            string += tag.name + ', ';
            break;
          case 'data types':
            const names = array[i];
            string += names + ', ';
            break;
          case 'task types':
            const namesT = array[i]
            string += namesT + ', '
            break
          case 'privacy':
            const namesP = array[i];
            string += namesP + ', ';
            break;
          case 'documents':
            const doc = mainArray.find(d => d.id === array[i])
            string += doc.name + ', '
            break
        }
      } else {
        break;
      }
    }
    string = string.slice(0, -2);

    if(array.length > 3){
      string = string + ' and ' + (array.length - 3) + ' others';
    }

    return string
  }

  const handleFilters = event => {
    const name = event.target.name
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value

    setFilterAllNone(filterAllNone => ({...filterAllNone, [name]: value}))
  }

  let activities = null
  let participants = null
  let segmentsC = null
  let dataTypesC = null
  let taskTypesC = null
  let documentsC = null

  let rdoAll = null
  let rdoNone = null

  switch(type){
    case 'Activities':
      rdoAll = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name={type.toLowerCase()} type="radio" onChange={handleFilters} value="All"
                 checked={allAvailableActs.length === objExport.idActs.length}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].all}</span>
        </label>
      </div>
      rdoNone = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name={type.toLowerCase()} type="radio" onChange={handleFilters} value="None"
                 checked={objExport.idActs.length === 0}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].none}</span>
        </label>
      </div>

      activities = allAvailableActs.map(activity => {
        const checked = objExport.idActs !== undefined ?
          objExport.idActs.find(id => id === activity.id) !== undefined : false

        if(activity.isVis){
          return(
            <div key={activity.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
              <div className="px-check-cont">
                <label className="container-check filters-modal">
                  <input type="checkbox" onChange={() => updateObjExportActions(activity.id, 'idActs')}
                         checked={checked}/>
                  <span className="checkmark filters-modal"/>
                  <div className="user-data">
                    <div className="username" data-tooltip-content={activity.title}>{activity.title}</div>
                    <div className="txt-email">{activity.questions.length} tasks</div>
                  </div>
                </label>
              </div>
            </div>
          )
        }
      })
      break
    case 'Participants':
      rdoAll = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name={type.toLowerCase()} type="radio" onChange={handleFilters} value="All"
                 checked={users.length === objExport.idPars.length}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].all}</span>
        </label>
      </div>
      rdoNone = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name={type.toLowerCase()} type="radio" onChange={handleFilters} value="None"
                 checked={objExport.idPars.length === 0}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].none}</span>
        </label>
      </div>

      participants = users.map(user => {
        const checked = objExport.idPars.find(id => user.id === id) !== undefined

        return(
          <div key={user.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
            <div className="px-check-cont">
              <label className="container-check filters-modal">
                <input type="checkbox" onChange={() => updateObjExportActions(user.id, 'idPars')} checked={checked}/>
                <span className="checkmark filters-modal"/>
                <div className="user-data">
                  <div className="username" data-tooltip-content={user.username}>{user.username}</div>
                  <div className="txt-email">{user.email}</div>
                </div>
              </label>
            </div>
          </div>
        )
      })
      break
    case 'Segments':
      rdoAll = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name={type.toLowerCase()} type="radio" onChange={handleFilters} value="All"
                 checked={segments.length === objExport.idSegs.length}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].all}</span>
        </label>
      </div>;
      rdoNone = <div className="px-check-cont rad">
        <label className="container-check radio">
          <input name={type.toLowerCase()} type="radio" onChange={handleFilters} value="None"
                 checked={objExport.idSegs.length === 0}/>
          <span className="checkmark radio"/>
          <span>{i18n[language].none}</span>
        </label>
      </div>

      segmentsC = segments.map(segment => {
        const checked = objExport.idSegs.find(id => segment.id === id) !== undefined
        let icon

        switch(segment.name){
          case 'Female':
            icon = ['fal', 'venus'];break
          case 'Male':
            icon = ['fal', 'mars'];break
          case 'Other Gender':
            icon = ['fal', 'transgender-alt'];break
          default:
            icon = ['fas', 'bookmark']
        }

        return(
          <div key={segment.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
            <div className="px-check-cont">
              <label className="container-check filters-modal img">
                <input type="checkbox" onChange={() => updateObjExportActions(segment.id, 'idSegs')} checked={checked}/>
                <span className="checkmark filters-modal"/>
                <FontAwesomeIcon icon={icon} className="tag icon" style={{color: segment.color}}/>
                <div className="user-data">
                  <div className="username" data-tooltip-content={segment.name}>{segment.name}</div>
                  <div className="txt-email">
                    {numberFilterSegment(segment.id, users, genderAsSegment)} {i18n[language].participants}
                  </div>
                </div>
              </label>
            </div>
          </div>
        )
      })
      break
    case "Data Types":
      dataTypesC = dataTypes.map((dataName, index) => {
        const checked = objExport.dataTypes.find(name => name === dataName) !== undefined;

        return(
          <div key={index} className="col-xs-6 col-sm-4 col- md-4 col-lg-4">
            <div className="px-check-cont">
              <label className="container-check filters-modal">
                <input type="checkbox" onChange={() => updateObjExportActions(dataName, 'dataTypes')}
                       checked={checked}/>
                <span className="checkmark filters-modal"/>
                <div className="user-data">
                  <div className="username">{dataName}</div>
                </div>
              </label>
            </div>
          </div>
        )
      });
      break
    case "Task Types":
      taskTypesC = taskTypes.map((typeName, index) => {
        const checked = objExport.taskTypes.find(name => name === typeName) !== undefined

        return(
          <div key={index} className="col-xs-6 col-sm-4 col- md-4 col-lg-4">
            <div className="px-check-cont">
              <label className="container-check filters-modal">
                <input type="checkbox" onChange={() => updateObjExportActions(typeName, 'taskTypes')}
                       checked={checked}/>
                <span className="checkmark filters-modal"/>
                <div className="user-data">
                  <div className="username">{typeName}</div>
                </div>
              </label>
            </div>
          </div>
        )
      })
      break
    case "Documents":
      documentsC = documents.map(d => {
        const checked = objExport.idDocs !== undefined ? objExport.idDocs.find(id => id === d.id) !== undefined : false
        return(
          <div key={d.id} className="col-xs-6 col-sm-4 col-md-4 col-lg-4">
            <div className="px-check-cont">
              <label className="container-check filters-modal">
                <input type="checkbox" onChange={() => updateObjExportActions(d.id, 'idDocs')}
                       checked={checked}/>
                <span className="checkmark filters-modal"/>
                <div className="user-data">
                  <div className="username">{d.name}</div>
                  <div className="txt-email">{d.role}</div>
                </div>
              </label>
            </div>
          </div>
        )
      })
      break
  }

  const keyPress = (event, type) => {
    if(event.keyCode === 13){
      searchModal(type, q)
    }
  }

  const searchForm = <div className="form-group xs first" style={{width: '90%', flex: '0 0 auto'}}>
    <div className="px-input-icon">
      <input name="q" type="text" onChange={handleFilters} value={q}
             onKeyDown={(e) => keyPress(e, type)}
             className="form-control icon-right" placeholder={i18n[language].search} />
      <FontAwesomeIcon onClick={() => searchModal(type, q)}
                       icon={['fas', 'magnifying-glass']}
                       className="px-icon-right color-gray-base" />
    </div>
  </div>

  return (
    <div className="active tab-pane fade in" id="export">
      {
        type !== 'Main' ?
          <React.Fragment>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="px-custom-filters-title-container">
                  <FontAwesomeIcon icon={['fas', 'circle-arrow-left']} onClick={() => updateType('Main')}
                                   style={{cursor:'pointer'}}/>
                  <span className="px-lbl-custom-filters">{i18n[language].pickFilter}</span>
                </div>
              </div>
            </div>
            <div className="modal-scroll">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-filter-adv-filter-modal-cont">
                    {/* searchForm */}
                    <div className="px-right-container" style={{flex: '1 1 auto', marginTop: '10px', flexWrap: 'wrap'}}>
                      { rdoAll }
                      { rdoNone }
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                { activities }
                { participants }
                { segmentsC }
                { dataTypesC }
                { taskTypesC }
                { documentsC }
              </div>
            </div>
          </React.Fragment> :
          <React.Fragment>
            <div className="resume-container">
              <div className="filter-element">
                <label className="label-sm" style={{color: colors.color1[2]}}>{i18n[language].title}</label>
                <input type="text" value={title} onChange={e => setTitle(e.target.value)}
                       className="form-control"/>
              </div>
              {
                projectType !== 'AIChat' ?
                  <React.Fragment>
                    <div className="filter-element">
                      <div className="header-element">
                        <span className="title">{i18n[language].acts}</span>
                        <FontAwesomeIcon onClick={() => updateType('Activities')} icon={['fas','pencil']} className="icon"
                                         style={{cursor:'pointer'}}/>
                      </div>
                      <p className="selected-filters">{_formatSelected(objExport.idActs, 'activities')}</p>
                    </div>
                    <div className="filter-element">
                      <div className="header-element">
                        <span className="title">{i18n[language].parts}</span>
                        <FontAwesomeIcon onClick={() => updateType('Participants')} icon={['fas','pencil']} className="icon"
                                         style={{cursor:'pointer'}}/>
                      </div>
                      <p className="selected-filters">{ _formatSelected(objExport.idPars, 'participants') }</p>
                    </div>
                    { (segments.length > 0 || genderAsSegment === true) &&
                      <div className="filter-element">
                      <div className="header-element">
                        <span className="title">{i18n[language].segs}</span>
                        <FontAwesomeIcon onClick={() => updateType('Segments')} icon={['fas', 'pencil']}
                                         className="icon"
                                         style={{cursor: 'pointer'}}/>
                      </div>
                      <p className="selected-filters">{_formatSelected(objExport.idSegs, 'segments')}</p>
                    </div>}
                    <div className="filter-element">
                      <div className="header-element">
                        <span className="title">{i18n[language].taskType}</span>
                        <FontAwesomeIcon onClick={() => updateType('Task Types')} icon={['fas','pencil']} className="icon"
                                         style={{cursor:'pointer'}}/>
                      </div>
                      <p className="selected-filters">{_formatSelected(objExport.taskTypes, 'task types')}</p>
                    </div>
                  </React.Fragment> :
                  <div className="filter-element">
                    <div className="header-element">
                      <span className="title">{i18n[language].docs}</span>
                      <FontAwesomeIcon onClick={() => updateType('Documents')} icon={['fas','pencil']} className="icon"
                                       style={{cursor:'pointer'}}/>
                    </div>
                    <p className="selected-filters">{_formatSelected(objExport.idDocs, 'documents')}</p>
                  </div>
              }
              <div className="filter-element">
                <div className="header-element">
                  <span className="title">{i18n[language].data}</span>
                  <FontAwesomeIcon onClick={() => updateType('Data Types')} icon={['fas','pencil']} className="icon"
                                   style={{cursor:'pointer'}}/>
                </div>
                <p className="selected-filters" style={{textTransform: "capitalize"}}>
                  {_formatSelected(objExport.dataTypes, 'data types')}
                </p>
              </div>
            </div>
          </React.Fragment>
      }
    </div>
  )
}

ChMMain.propTypes = {
  type: PropTypes.string.isRequired,
  updateType: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  colors: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
  objExport: PropTypes.object.isRequired,
  allAvailableActs: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  segments: PropTypes.array.isRequired,
  updateObjExportActions: PropTypes.func.isRequired,
  genderAsSegment: PropTypes.bool.isRequired,
  dataTypes: PropTypes.array.isRequired,
  projectType: PropTypes.string.isRequired,
  documents: PropTypes.array,
  room: PropTypes.object
}

export default ChMMain