import PropTypes from 'prop-types';
import React from 'react';
import v4 from "uuid";
import moment from "moment";
import axios from "axios"
import RCSlider from "rc-slider";
import Tooltip from "rc-tooltip";
import ReactModal from "react-modal";
import AutosizeInput from "react-input-autosize";

import TFilters from "../../components/Community/Transcript/TFilters";
import BodyT00 from "../../components/Community/Transcript/_test/BodyT00"
import Loading from "../../components/UI/Globals/Loading";
import TBModalConsolidated from "../../components/Community/Transcript/TBody/TBModalConsolidated";
import R812TFilters from "../../components/Responsive/812px/R812TFilters";
import TBMExportShare from "../../components/Community/Transcript/T_Body/TBMExportShare";
import {filterSetDates, getCSRF, showSuccessMsg} from "../../components/UI/Globals/PX_Funs";

const Handle = RCSlider.Handle;

export default class Transcript02 extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    active_users: PropTypes.array.isRequired,
    tasks_filter: PropTypes.array.isRequired,
    users_id: PropTypes.string.isRequired,
    activities_id: PropTypes.string.isRequired,
    tasks_id: PropTypes.string.isRequired,
    role_1: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    qc: PropTypes.array.isRequired,
    profile: PropTypes.object.isRequired,
    collaborators: PropTypes.array.isRequired,
    scroll: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    user_id: PropTypes.number.isRequired,
    item_scroll: PropTypes.object,
    user_account: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    //localStorage.clear();
    const keyIds = '_' + this.props.user_id + '_' + this.props.community.id;
    let objSearch;

    if(window.performance){
      if(performance.navigation.type === 0){
        //localStorage.clear();
        objSearch = this._initObjectsSearch(props.users_id, props.activities_id, props.tasks_id);
        localStorage.setItem('objSearch' + keyIds, JSON.stringify(objSearch));
      }

      if(performance.navigation.type === 1 || performance.navigation.type === 2){
        if(localStorage.getItem('objSearch' + keyIds)){
          objSearch = JSON.parse(localStorage.getItem('objSearch' + keyIds));
        } else {
          objSearch = this._initObjectsSearch(props.users_id, props.activities_id, props.tasks_id);
        }
      }
    }

    this.state = {
      objSearch: objSearch,
      objSearchScroll: {idPars: [], idActs: []},
      users: props.users.map(u => ({...u, isVis: true})),
      activities: props.tasks_filter.map(a => ({...a, isVis: true})),
      chkUsers: false,
      chkSegs: false,
      showModalExport: false,
      isFirstFFComment: {},
      showAllVerbatim: false,
      segments: this._initSegments(props.community),
      hideTabFilters: false,
      showOptionsBar: false,
      tags: props.community.tags.map(t => ({...t, isVis: true})),
      generalTags: [],
      size: 3,  // it looks no more in use
      generalSize: 3,
      checkComments: false, // Wizard Verbatim Bar, TO-DO try if it's better to move it to its component
      generalCheckComments: false,
      userFilters: [],
      objExport: {},
      consolidated: '',
      scrollFirstTime: true,
      userResponsesCounter: 0,
      isLoadingResponses: false,
      showModal: false,
      isSavingLikeResponse: false,
      // NEW
      filterUsers: [],
      filterActivities: [],
      responses: [],
      offset: 0,
      hasMoreItems: true,
      isLoadingConsolidated: false,
      responsesConsolidated: [],
      offsetConsolidated: 0,
      hasMoreItemsConsolidated: true,
      validationActivity: false,
      showModalConsolidated: false,
      selectedResponseModal: null,
      isLoadingResponsesConsolidated: false,
      mainIds: [],
      mainIdsOffset: 0,
      isLoadingResponsesWait: false,
      modalType: '',
      objSearchOrigModal: {},
      isBlocking: false,
      resShowCommentId: -1,
      headerShowUserId: -1,
      headerShowActId: -1,
      resMaxMinId: -1,
      lastDeleted: false,
      showVerOriginal: false
    };
  }

  componentDidMount(){
    this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
      this.getResponses();
      //this.setState({ users: this.props.users.map(u => ({...u, isVis: true})) })
    });

    //TO-DO move to vanilla js
    /*$(window).scroll(() => {
      if($(window).scrollTop() + $(window).height() == $(document).height()) {
        //this.updateObjSearchScroll();
      }
    });*/

    window.addEventListener("beforeunload", this.onRefresh)
  }

  componentDidUpdate(prevProps){
    const {users_id, activities_id, active_users, tasks_id} = this.props;

    if(prevProps.active_users !== active_users){
      // console.log('Transcript02.componentDidUpdate active_users changed', users_id);
      this.setState({
        users: this._initUsers(active_users),
        objSearch: this._initObjectsSearch(users_id, activities_id, tasks_id)
      }, () => {
        if(users_id !== '-1'){
          const objSearch = {...this.state.objSearch};
          const idPars = [objSearch.idPars[0]];
          const idActs = [objSearch.idActs[0]];
          this.setState({objSearchScroll: {idPars, idActs}},
            () => {
              //this.getResponsesSelectedUsers(this.state.objSearch);
            });
        }
      });
    }
  }

  updateObjSearchScroll = () => {
    // console.log(this.state.consolidated, this.state.objSearch.idPars.length);
    if(this.state.consolidated === '' || this.state.objSearch.idPars.length !== 1){
      // console.log(this.state.objSearch.idPars.length, this.state.objSearchScroll.idPars.length);
      if(this.state.objSearch.idPars.length !== this.state.objSearchScroll.idPars.length){
        let idPars = [...this.state.objSearchScroll.idPars];
        const partToPush = this.state.objSearch.idPars[idPars.length];
        if(partToPush !== undefined){
          idPars.push(partToPush);
        } else {
          idPars = this.state.objSearch.idPars;
        }
        //console.log(idPars);
        //if(idPars.length !== 0){
        this.setState({objSearchScroll: {...this.state.objSearchScroll, idPars: idPars}}, () => {
          // console.log(this.state.objSearch.idPars, this.state.objSearchScroll.idPars);
          const lastUserId = this.state.objSearch.idPars[idPars.length - 1];
          // console.log('lastUserId', lastUserId);
          const user = this.state.users.find(u => u.user_id === lastUserId);
          // console.log(user);
          const url = '/responses/get_participants_responses/' + user.user_id;
          let allQuestionsId = [];
          user.tasks.forEach(task => task.questions.forEach(question => allQuestionsId.push(question.id)));

          axios.get(url, { params: {questionsId: allQuestionsId} })
            .then(response => {
              //console.log(response.data);
              const users = this.state.users.map(u => u.user_id === user.user_id ?
                {...u, responses: response.data.map(response => ({
                    //this new fields should be handled by initUsers in question object
                    ...response, showHiddenVerbatims: false
                  }))} : u);
              //console.log(users);

              this.setState({users});
            });
        });
        //}
      }
    }
  };

  componentWillUnmount(){
    //TO-DO move to vanilla js
    $(window).unbind('scroll');

    //this.onRefresh()
    window.removeEventListener("beforeunload", this.onRefresh)
  }

  onRefresh = () => {
    const keyIds = '_' + this.props.user_id + '_' + this.props.community.id;

    localStorage.removeItem('objSearch' + keyIds);
    localStorage.setItem('objSearch' + keyIds, JSON.stringify(this.state.objSearch));
  }

  render() {
    const {community, users, tasks_filter, role_1, role_2, language, qc, collaborators, scroll, item_scroll,
      company} = this.props
    const colors = this.props.user_account.all_colors;
    const i18n = {
        en: {
          infoMsg: 'Select at least 1 participant'
        }, es: {
          infoMsg: 'Selecciona cuando menos 1 participante'
        }
      }

    return(
      <div ref="myscroll">
        <TFilters from='Collaborator'
                  objSearch={this.state.objSearch}
                  users={this.state.users}
                  allAvailableActs={this.state.activities}
                  updatePills={this.updatePills}
                  advancedActions={this.advancedActions}
                  chkUsers={this.state.chkUsers}
                  chkSegs={this.state.chkSegs}
                  handleInputChange={this.handleInputChange}
                  handleUserArrows={this.handleUserArrows}
                  segments={community.segments}
                  tags={this.state.tags}
                  genderAsSegment={community.gender_as_segment}
                  handleHideTabFilters={this.handleHideTabFilters}
                  consolidated={this.state.consolidated}
                  updateConsolidated={this.updateConsolidated}
                  updateFiltersOpts={this.updateFiltersOpts}
                  updateSltCalendar={this.updateSltCalendar}
                  updateStartExpireDates={this.updateStartExpireDates}
                  updateFromModal={this.updateFromModal}
                  showModal={this.state.showModal}
                  updateShowModal={this.updateShowModal}
                  filterUsers={this.state.filterUsers}
                  filterActivities={this.state.filterActivities}
                  communitySegments={this.state.segments}
                  executeUpdateModal={this.executeUpdateModal}
                  updateJustPills={this.updateJustPills}
                  advancedActionsJust={this.advancedActionsJust}
                  showAllVerbatim={this.state.showAllVerbatim}
                  updateShowAllVerbatim={this.updateShowAllVerbatim}
                  language={language}
                  updateResponseStates={this.updateResponseStates}
                  isLoadingResponsesConsolidated={this.state.isLoadingResponsesConsolidated}
                  showModalExportHandler={this.showModalExportHandler}
                  isBlocking={this.state.isBlocking}
                  colors={colors}
                  searchModal={this.searchModal}/>

        <R812TFilters objSearch={this.state.objSearch}
                      users={users}
                      handleUserArrows={this.handleUserArrows}
                      updateShowModal={this.updateShowModal}
                      updateConsolidated={this.updateConsolidated}
                      consolidated={this.state.consolidated}
                      updateShowAllVerbatim={this.updateShowAllVerbatim}
                      colors={colors}
                      showAllVerbatim={this.state.showAllVerbatim}/>

        {
          this.state.objSearch.idPars.length !== 0 ?
          (this.state.responses.length !== 0 || this.state.responsesConsolidated.length !== 0) ?
            <BodyT00 users={users}
                     community={this.props.community}
                     allAvailableActs={tasks_filter}
                     responses={this.state.responses}
                     updateResponse={this.updateResponse}
                     saveResponseLike={this.saveResponseLike}
                     isSavingLikeResponse={this.state.isSavingLikeResponse}
                     language={language}
                     role_1={role_1}
                     role_2={role_2}
                     consolidated={this.state.consolidated}
                     objSearch={this.state.objSearch}
                     responsesConsolidated={this.state.responsesConsolidated}
                     getResponsesSelectedUsersTEST={this.getResponsesSelectedUsersTEST}
                     toggleTaskIsMax={this.toggleTaskIsMax}
                     getResponsesConsolidatedSingle={this.getResponsesConsolidatedSingle}
                     toggleUserIsMax={this.toggleUserIsMax}
                     toggleActivityIsMax={this.toggleActivityIsMax}
                     hasMoreItems={this.state.hasMoreItems}
                     updateSVG={this.updateSVG}
                     handleShowLoadingConsolidated={this.handleShowLoadingConsolidated}
                     hasMoreItemsConsolidated={this.state.hasMoreItemsConsolidated}
                     getResponsesConsolidated_01={this.getResponsesConsolidated_01}
                     handleShowModalConsolidated={this.handleShowModalConsolidated}
                     updateSVGMulti={this.updateSVGMulti}
                     isLoadingResponsesConsolidated={this.state.isLoadingResponsesConsolidated}
                     showAllVerbatim={this.state.showAllVerbatim}
                     handleVerbatimVisibility={this.handleVerbatimVisibility}
                     updateSizeResponseSlider={this.updateSizeResponseSlider}
                     updateSizeResponse={this.updateSizeResponse}
                     updateResponseKeywords={this.updateResponseKeywords}
                     autosizingRenderInput={this.autosizingRenderInput}
                     handleVerbatimShows={this.handleVerbatimShows}
                     regenerateResponseVerbatim={this.regenerateResponseVerbatim}
                     updateShowComments={this.updateShowComments}
                     qc={qc}
                     updateResponseComments={this.updateResponseComments}
                     updateUserResponses={this.updateUserResponses}
                     scrollFirstTime={this.state.scrollFirstTime}
                     updateScrollFirstTime={this.updateScrollFirstTime}
                     item_scroll={item_scroll}
                     communityState={community.state}
                     isFirstFFComment={this.state.isFirstFFComment}
                     updateIsFirstFFComment={this.updateIsFirstFFComment}
                     getResponses={this.getResponses}
                     showModalExportHandler={this.showModalExportHandler}
                     communitySegments={this.state.segments}
                     gender_as_segment={community.gender_as_segment}
                     colors={colors}
                     cmntyLanguage={community.language}
                     getUrlsToDownload={this.getUrlsToDownload}
                     resShowCommentId = {this.state.resShowCommentId}
                     headerShowUserId={this.state.headerShowUserId}
                     headerShowActId={this.state.headerShowActId}
                     resetResizeIds={this.resetResizeIds}
                     resMaxMinId={this.state.resMaxMinId}
                     lastDeleted={this.state.lastDeleted}
                     updateLastDeleted={this.updateLastDeleted}
                     showVerOriginal={this.state.showVerOriginal}
                     updateShowVerOriginal={this.updateShowVerOriginal}
                     company={company}
                     projectType={community.project_type}/> :
            !this.state.validationLoading && <Loading/> :
            <div className="px-empty-msg" style={{marginTop:'15px', color: colors.color4[3]}}>{i18n[language].infoMsg}</div>
        }
        {
          this.state.showModalConsolidated &&
          <ReactModal isOpen={this.state.showModalConsolidated} contentLabel="TV Collaborator Modal"
                      shouldCloseOnOverlayClick={true} onRequestClose={() => this.handleShowModalConsolidated(null)}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <TBModalConsolidated handleShowModalConsolidated={() => this.handleShowModalConsolidated(null)}
                                 response={this.state.selectedResponseModal}
                                 objSearch={this.state.objSearch}
                                 allUsersId={users.map(u => u.id)}
                                 language={language}
                                 cmntyLanguage={community.language}
                                 index={0}
                                 colors={colors}/>
          </ReactModal>
        }
        {
          this.state.showModalExport &&
          <ReactModal isOpen={this.state.showModalExport} contentLabel="Modal Export"
                      shouldCloseOnOverlayClick={true} className="my-modal-content"
                      overlayClassName="my-modal-overlay"
                      onRequestClose={() => this.showModalExportHandler(null, null, null)}>
            <TBMExportShare userFilters={this.state.userFilters}
                            allAvailableActs={tasks_filter}
                            users={this.props.users}
                            segments={this.state.segments}
                            genderAsSegment={community.gender_as_segment}
                            tags={community.tags}
                            showModalExportHandler={this.showModalExportHandler}
                            objExport={this.state.objExport}
                            updateObjExportActions={this.updateObjExportActions}
                            community={community}
                            collaborators={collaborators}
                            updateCFId={this.updateCFId}
                            language={language}
                            colors={colors}
                            updateFiltersOptsExp={this.updateFiltersOptsExp}
                            updateSltCalendarExp={this.updateSltCalendarExp}
                            updateStartExpireDatesExp={this.updateStartExpireDatesExp}/>
          </ReactModal>
        }
      </div>
    );
  }

  searchModal = (modalType, q) => {
    // console.log(modalType, q)
    const re = new RegExp(q, 'i')

    switch(modalType){
      case 'Participants':
        let users
        if(q !== ''){
          users = this.state.users.map(user => (
            { ...user, isVis: user.username.match(re) !== null || user.email.match(re) !== null })
          )
        } else {
          users = this.state.users.map(user => ({ ...user, isVis: true }))
        }
        this.setState({users})
        break
      case 'Segments':
        const segments = this.state.segments.map(s => ({ ...s, isVis: q !== '' ? s.name.match(re) : true}))
        this.setState({segments})
        break
      case 'Activities':
        const activities = this.state.activities.map(a => ({ ...a, isVis: a !== '' ? a.title.match(re) : true}))
        this.setState({activities})
        break
      case 'Tags':
        const tags = this.state.tags.map(t => ({ ...t, isVis: t !== '' ? t.name.match(re) : true}))
        this.setState({tags})
        break
    }
  }

  updateShowVerOriginal = () => {
    this.setState({showVerOriginal: false});
  };

  updateLastDeleted = () => {
    this.setState({lastDeleted: false});
  };

  resetResizeIds = () => {
    this.setState({resShowCommentId: -1, headerShowUserId: -1, headerShowActId: -1, resMaxMinId: -1})
  };

  getUrlsToDownload = (userId) => {
    // console.log('userId:', userId);
  }

  // RESPONSE STATES
  updateResponseStates = name => {
    // console.log('name:', name);
    const idPars = [this.state.objSearch.idPars[0]];
    const idActs = [this.state.objSearch.idActs[0]];

    this.setState(prevState => ({
      objSearch: {
        ...prevState.objSearch,
        resStates: {...prevState.objSearch.resStates, [name]: !prevState.objSearch.resStates[name]}
      },
      chkUsers: false, objSearchScroll: {idPars, idActs}, offset: 0, responses: [],
      offsetConsolidated: 0, responsesConsolidated: [], hasMoreItems: true, hasMoreItemsConsolidated: true,
      mainIdsOffset: 0
    }), () => {
      if(this.state.objSearch.idPars.length !== 0 && this.state.objSearch.idActs.length !== 0
        && this.state.objSearch.idTasks.length !== 0) {
        this.setState({validationLoading: false});

        if(this.state.consolidated === ''){
          this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
            this.getResponses();
          });
        } else {
          this.getResponsesConsolidated_01();
        }
      } else {
        this.setState({validationLoading: true});
      }
    });
  }

  // SHARE
  updateCFId = cfId => {
    // console.log(cfId);
    const cf = this.state.userFilters.find(uf => uf.id === cfId);
    // console.log(cf);

    this.setState({objExport: {...this.state.objExport, cfId}}, () => {
      this.setState({objExport: {...this.state.objExport, acDates: cf.acDates, idActs: cf.idActs,
          idPars: cf.idPars, idSegs: cf.idSegs, idTags: cf.idTags}})
    });
  };

  // EXPORT
  updateObjExportActions = (id, type) => {
    //console.log(id, type);
    this.setState({objExport: this._updateObjExport(id, type)});
  };

  _updateObjExport(id, type){
    // console.log(id, type);
    let objExport;
    let idObjs = [];

    if(typeof id !== 'boolean'){
      if(this.state.objExport[type].find(objId => objId === id) !== undefined){
        objExport = {...this.state.objExport, [type]: this.state.objExport[type].filter(objId => objId !== id),
          cfId: null};
      } else {
        objExport = {...this.state.objExport, [type]: this.state.objExport[type].concat(id), cfId: null};
      }
    } else {
      if(type === 'idPars'){
        idObjs = id ? this.state.users.map(user => user.user_id) : [];
      } else {
        idObjs = id ? this.state.segments.map(segment => segment.id) : [];
      }

      objExport = {...this.state.objExport, [type]: idObjs, cfId: null};
    }

    // console.log(objExport);
    return objExport;
  }

  showModalExportHandler = (activitiesId, questionId, userId, idSegs, idTags) => {
    // console.log(activitiesId, questionId, userId, idSegs, idTags);
    let currentAct = null;

    if(questionId && userId){
      this.props.tasks_filter.forEach(activity => {
        activity.questions.forEach(task => {
          if(task.id === questionId){
            currentAct = activity
          }
        })
      });

      this.setState({
        objExport: this._initObjectExport(currentAct ? currentAct.id : activitiesId, questionId, userId,
          idSegs ? idSegs : [], idTags ? idTags : [])},
        () => { this.setState(prevState => ({showModalExport: !prevState.showModalExport}));
      });
    } else {
      this.setState({
          objExport: this._initObjectExport(this.state.objSearch.idActs, this.state.objSearch.idTasks, userId,
            idSegs ? idSegs : [], idTags ? idTags : [])
        }, () => {
        this.setState(prevState => ({showModalExport: !prevState.showModalExport}));
      });
    }
  };

  _initObjectExport = (id_Acts, id_Tasks, id_Pars, idSegs, idTags) => {
    // console.log('id_Acts:', id_Acts, 'id_Tasks:', id_Tasks, 'id_Pars:', id_Pars);
    const idPars = id_Pars === 'all' ? this.props.users.map(user => user.id) : JSON.parse("[" + id_Pars + "]");
    const idActs = id_Acts === 'all' ? this.props.tasks_filter.map(act => act.id) : JSON.parse("[" + id_Acts + "]");
    let idTasks = [];
    if(id_Tasks === 'all'){
      this.props.tasks_filter.forEach(task => {
        task.questions.forEach(question => {
          idTasks.push(question.id)
        })
      });
    } else {
      idTasks = JSON.parse("[" + id_Tasks + "]");
    }
    const dataTypes = ['text', 'comments', 'images'];
    const privacy = [];

    return {
      idPars, idActs, idTasks, idSegs, idTags, dataTypes, privacy, cfId: null,
      actDates: {name: 'creation', startDate: null, endDate: null}
    };
  };

  // NO RESPONSE, SEND FF
  updateIsFirstFFComment = (isFirstFFComment, userId, questionId, body) => {
    // console.log('isFirstFFComment:', isFirstFFComment, 'userId:', userId, 'questionId:', questionId, 'body:', body);
    this.setState({isFirstFFComment: {isFirstFFComment, userId, questionId, body}});
  };

  // SCROLL
  updateScrollFirstTime = (scrollFirstTime) => {
    this.setState({scrollFirstTime});
  };

  // REPLIES
  updateUserResponses = (comments, responseId, isFirstFFCommentBool, userId, from) => {
    //'from' is just only to track the operation, we can delete it after to test, don't forget to delete in Comments.jsx
    // console.log(comments, responseId, isFirstFFCommentBool, userId, from);
    if(isFirstFFCommentBool){
      // Maybe never reach
      //this.getAllUserResponses(this.state.activeUser);
      this.setState({isFirstFFComment: {}}); //Get back to false because other FF would be sent
    } else {
      const responses = this.state.responses.map(r => r.id === responseId ? {...r, comments} : r);

      this.setState(prevState =>({
        responses,
        lastDeleted: (from === 'Comments.deleteComment' || from === 'Replies.deleteComment') ? true : prevState.lastDeleted
      }));
    }
  };

  // COMMENTS
  updateResponseComments = (comments, responseId, isFirstFFCommentBool) => {
    // console.log('comments:', comments, 'responseId:', responseId, 'isFirstFFCommentBool:', isFirstFFCommentBool)
    if(isFirstFFCommentBool){
      const newResponseDB = responseId;
      // console.log('isFirstFFCommentBool is TRUE, responseId is the new response:', newResponseDB);
      this.setState({isFirstFFComment: {}}, () => {
        const responses = this.state.responses.map(r => {
          // console.log('r ITEM:', r);
          if(r.type === 'response' && r.state === 'Blank'){
            // console.log('r in this.state.responses:', r);
            if(r.task.id === newResponseDB.task_id && r.user.id === newResponseDB.user_id){
              // console.log('r found in state.responses:', r);
              return  {...r, ...newResponseDB, comments, showComments: true}
              //return  {...r, ...newResponseDB, showComments: true}
            } else {
              return r;
            }
          } else {
            return r;
          }
        });
        // console.log('ITEMS:', responses);
        this.setState({responses}, () => {
          // Expand the Comments component
          //this.updateShowComments(newResponseDB.id);
        });
      });
      //Get back to false because other FF would be sent
      //TO-DO Update comments after to save it (users)
    } else {
      // console.log('isFirstFFCommentBool is FALSE');
      const responses = this.state.responses.map(r => r.id === responseId ? {...r, comments} : r);

      this.setState({responses, resShowCommentId: responseId, headerShowUserId: -1, headerShowActId: -1, resMaxMinId: -1});
    }
  };

  updateShowComments = (responseId, index) => {
    // console.log('responseId:', responseId, 'index:', index);
    const responses = this.state.responses.map(r => r.id === responseId ?
      {...r, showComments: !r.showComments, sum_unseen_col: 0} : r);

    this.setState({responses, resShowCommentId: responseId, headerShowUserId: -1, headerShowActId: -1},
      () => {
      $.ajax({
        url: '/notifications/update_state_1/' + responseId,
        method: 'PATCH',
        dataType: 'JSON',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: () => {
          // OPTIMISTIC UI
        }
      });
    });
  };

  // VERBATIM
  regenerateResponseVerbatim = (responseId) => {
    // console.log(responseId);
    const responseTU = this.state.responses.find(r => r.id === responseId);
    // console.log(responseTU);
    if(responseTU.size !== ''){
      if(responseTU.size > 0 || responseTU.keywords.length !== 0){
        //console.log(responseToUpdate.response.size);
        $.ajax({
          url: '/responses/regenerate_verbatim/' + responseId,
          method: "POST",
          dataType: "JSON",
          data: {
            size: responseTU.size,
            keywords: responseTU.keywords ? responseTU.keywords : [],
            checkComments: responseTU.inc_comments
          },
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: verbatim => {
            // console.log(verbatim);
            const responses = this.state.responses.map(r => r.id === responseId ? {...r, verbatim} : r);

            this.setState({responses});
          }
        });
      }
    }
  };

  // Actually change the name 'cos it uses several time to update one key/value in responses
  handleVerbatimShows = (responseId, name) => {
    // console.log('name:', name);
    const responses = this.state.responses.map(r => r.id === responseId ? {...r, [name]: !r[name]} : r);

    this.setState({responses}, () => {
      //if(name === 'showOriginal'){
      //   console.log('resize');
        this.setState(prevState => ({showVerOriginal: !prevState.showVerOriginal}));
      //}
    });
  };

  _updateQuestionOneProp(users, name, userId, taskId, questionId){
    return users.map(user => {
      if(user.user_id === userId){
        const tasks = user.tasks.map(task => {
          if(task.id === taskId){
            const questions = task.questions.map(question => question.id === questionId ?
              {...question, [name]: !question[name]} : question);

            return {...task, questions};
          } else {
            return task;
          }
        });

        return {...user, tasks};
      } else {
        return user;
      }
    });
  }

  autosizingRenderInput = ({addTag, ...props}) => {
    let {onChange, value, ...other} = props;
    return (
      <AutosizeInput type='text' onChange={onChange} value={value} {...other} />
    )
  };

  updateSizeResponse = (event, responseId) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    // console.log(name, value);
    if(name === 'generalCheckComments'){
      this.setState({[name]: value});
    } else {
      const responses = this.state.responses.map(r => r.id === responseId ? {...r, inc_comments: value} : r);

      this.setState({responses});
    }
  };

  updateResponseKeywords = (tags, responseId) => {
    // console.log(tags);
    const responses = this.state.responses.map(r => r.id === responseId ? {...r, keywords: tags} : r);

    this.setState({responses});
  };

  updateSizeResponseSlider = (value, responseId) => {
    const responses = this.state.responses.map(r => r.id === responseId ? {...r, size: value} : r);

    this.setState({responses});
  };

  _updateResponseOneProp(users, name, value, userId, responseId){
    return users.map(user => {
      if(user.user_id === userId){
        const responses = user.responses.map(response => response.id === responseId ?
          {...response, response: {...response.response, [name]: value}} : response);

        return {...user, responses}
      } else {
        return user;
      }
    });
  }

  handleVerbatimVisibility = (verbatimId, visibility, responseId) => {
    // console.log(verbatimId, visibility, responseId);
    $.ajax({
      url: '/responses/verbatim_visibility/' + verbatimId,
      method: "PATCH",
      dataType: "JSON",
      data: {
        entity_id: responseId,
        visibility: visibility
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: verbatim => {
        // console.log(verbatim);
        const responses = this.state.responses.map(response => response.id === responseId ?
          {...response, verbatim} : response);

        this.setState({responses});
      }
    });
  };

  handleShowModalConsolidated = (response) => {
    //console.log(response);
    this.setState(prevState => ({showModalConsolidated: !prevState.showModalConsolidated}), () => {
      this.setState({selectedResponseModal: response});
    });
  };

  // WIZARD VERBATIM
  regenerateAllVerbatim = () => {
    const {objSearch, generalTags, generalSize, generalCheckComments} = this.state;
    const justResponsesOE = this.state.responses.filter(item => item.type === 'response' && item.task.task_type === 'Open End');
    // console.log(justResponsesOE);

    this.props.users.forEach(user => {
      if(objSearch.idPars.find(userId => userId === user.id) !== undefined){
        this.props.tasks_filter.forEach(activity => {
          if(objSearch.idActs.find(activityId => activity.id === activityId) !== undefined){
            activity.questions.forEach(task => {
              if(objSearch.idTasks.find(taskId => task.id === taskId) !== undefined){
                const responseOE = justResponsesOE.find(r => r.user_id === user.id && r.task_id === task.id);

                if(responseOE !== undefined){
                  // console.log(responseOE);
                  $.ajax({
                    url: '/responses/regenerate_verbatim/' + responseOE.id,
                    method: "POST",
                    dataType: "JSON",
                    data: {
                      size: generalSize,
                      keywords: generalTags,
                      checkComments: generalCheckComments
                    },
                    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                    success: verbatim => {
                      // console.log(verbatim);

                      const responses = this.state.responses.map(r => r.id === responseOE.id ?
                        {...r, verbatim, keywords: generalTags, size: generalSize, inc_comments: generalCheckComments} : r);

                      /*const users = this.state.users.map(u => {
                        if(u.user_id === user.user_id){
                          //console.log(user.username, response.response.id, generalTags);
                          const responses = u.responses.map(r => r.id === response.response.id ?
                            {...r, verbatim,
                              response: {
                                ...response.response,
                                keywords: generalTags, size: generalSize, inc_comments: generalCheckComments}
                            } : r
                          );

                          return {...u, responses}
                        } else {
                          return u;
                        }
                      });*/
                      //console.log(users);
                      this.setState({responses});
                    }
                  });
                }
              }
            });
          }
        });
      }
    });

    this.setState({generalSize: 3, generalTags: [], generalCheckComments: false});
  };

  updateGeneralTags = (generalTags) => {
    this.setState({generalTags})
  };

  handle = props => {
    const { value, dragging, index, ...restProps } = props;
    let valueWords = '';

    switch (value) {
      case 3: valueWords = 'A few';break;
      case 4: valueWords = 'A few';break;
      case 5: valueWords = 'A few';break;
      case 6: valueWords = 'Many';break;
      case 7: valueWords = 'Many';break;
      case 8: valueWords = 'A lot';break;
      case 9: valueWords = 'A lot';break;
    }

    return (
      <Tooltip prefixCls="rc-slider-tooltip"
               overlay={valueWords}
               visible={dragging}
               placement="top"
               key={index}>
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

  updateShowOptionsBar = () => {
    this.setState(prevState => ({showOptionsBar: !prevState.showOptionsBar}))
  };

  updateShowAllVerbatim = () => {
    this.setState(prevState => ({
      showAllVerbatim: !prevState.showAllVerbatim,
      consolidated: ''
    }));

    /*this.setState({showAllVerbatim: !this.state.showAllVerbatim}, () => {
      if(this.state.showAllVerbatim){
        const users = this.state.users.map(user => {
          const tasks = user.tasks.map(task => {
            const questions = task.questions.map(question => ({...question, showConsolidated: false}));

            return {...task, questions};
          });

          return {...user, tasks};
        });
        this.setState({consolidated: '', users});
      }
    });*/
  };

  // CONSOLIDATED
  getResponsesConsolidated = () => {
    const config = {
      params: {
        users_id: this.state.objSearch.idPars,
        tasks_id: this.state.objSearch.idTasks,
      }
    };
    axios.get('/responses/get_tv_responses_consolidated', config)
      .then(response =>  {
        // console.log(response.data);
      }).catch(error => {
      console.log(error);
    });
  };

  // RESPONSES
  toggleActivityIsMax = activityId => {
    // console.log('toggleActivityIsMax', activityId);
    const responses = this.state.responses.map(r => {
      if(r.type === 'activity'){
        if(r.activity.id === activityId){
          return {...r, isMax: !r.isMax}
        } else {
          return r;
        }
      } else {
        return r;
      }
    });

    this.setState({responses, headerShowActId: activityId, resShowCommentId: -1, headerShowUserId: -1, resMaxMinId: -1});
  };

  toggleUserIsMax = userId => {
    // console.log('toggleUserIsMax', userId);
    const responses = this.state.responses.map(r => {
      if(r.type === 'user'){
        if(r.user.id === userId){
          return {...r, isMax: !r.isMax}
        } else {
          return r;
        }
      } else {
        return r;
      }
    });

    this.setState({responses, headerShowUserId: userId, resShowCommentId: -1, headerShowActId: -1, resMaxMinId: -1});
  };

  toggleTaskIsMax = responseId => {
    // console.log('toggleTaskIsMax', responseId);
    const responses = this.state.responses.map(response => response.id === responseId ?
      {...response, isMax: !response.isMax} : response);

    this.setState({responses, resMaxMinId: responseId, headerShowUserId: -1, headerShowActId: -1, resShowCommentId: -1});
  };

  saveResponseLike = (entityId, activityId, isLike, userId) => {
    // console.log(entityId, activityId, isLike, userId);

    this.setState({isSavingLikeResponse: true}, () => {
      //this.setState({isSavingLikeResponse: false});
      const url = '/is_like/'+ entityId +'/Response/' + this.props.community.id + '/' + activityId;
      const data = {entity: {isLike: isLike}};

      axios.patch(url, data, getCSRF())
        .then((r) => {
          // console.log(r.data);
          const responses = this.state.responses.map(response => response.id === r.data.id ?
            {...response, isLike: isLike, likes: isLike ? ++response.likes : --response.likes} : response);

          // console.log(responses);
          this.setState({responses, isSavingLikeResponse: false});

          showSuccessMsg("Success!");
        }).catch(error => {
        console.log(error);
      });

      /*$.ajax({
        url: '/is_like/'+ entityId +'/Response/' + question_id,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          entity: {
            isLike: isLike
          }
        },
        success: responseDB => {
          console.log(responseDB);  //just response table from DB
          const users = this.state.users.map(user => {
            if(user => user.user_id === userId){
              const responses = user.responses.map(response => {
                if(response.id === responseDB.id){
                  return {...response, isLike: isLike, likes: isLike ? ++response.likes : --response.likes};
                } else {
                  return response;
                }
              });

              return {...user, responses};
            } else {
              return user;
            }
          });
          console.log(users);
          this.setState({isSavingLikeResponse: false, users});
        }
      });*/
    });
  };

  updateResponse = (state, responseId, userId) => {
    const data = {response: {state: state}};

    axios.patch('/responses/update_coll/' + responseId, data, getCSRF())
      .then((response) => {
        const responseDB = response.data;
        // console.log(responseDB);

        const responses = this.state.responses.map(response => {
          if(response.id === responseDB.id){
            return  {...response, state: responseDB.state, pre_state: responseDB.pre_state,
              updated_at: responseDB.updated_at}
          } else {
            return response;
          }
        });

        // console.log(responses);
        this.setState({responses});

        showSuccessMsg("Success!");
      }).catch(error => {
      console.log(error);
    });
  };

  // BODY
  handleShowLoadingConsolidated = (responseId) => {
    // console.log('handleShowLoadingConsolidated', responseId);
    const responses = this.state.responses.map(r => r.id === responseId ?
      {...r, showLoadingConsolidated: !r.showLoadingConsolidated} : r);
    // console.log(responses);
    this.setState({responses});
  };

  getResponsesSelectedUsers = objSearch => {
    // console.log('(getResponsesSelectedUsers)',objSearch, 'hasMoreItems:', this.state.hasMoreItems, 'offset:', this.state.offset);
    //In order to avoid calls after no more responses from DB, if I will fix the structure issue
    //(user card - act info - responses then it would be unnecessary
    // Try to test without user cards and act info, just responses
    if(this.state.hasMoreItems){
      const config = {
        params: {
          users_id: objSearch.idPars,
          activities_id: objSearch.idActs,
          tasks_id: objSearch.idTasks,
          offset: this.state.offset
        }
      };

      axios.get('/responses/get_tv_responses', config)
        .then(response =>  {
          // console.log('DB responses:', response.data);
          if(response.data.length !== 0){
            this.setState(prevState => ({
              offset: prevState.offset + 2,
              //responses: [...prevState.responses, ...response.data],
              responses: response.data  //no scroll
            }));
          } else {
            this.setState({
              isLoadingResponses: false,
              hasMoreItems: false
            })
          }
        }).catch(error => {
        console.log(error);
      });
    }
  };

  getResponsesSelectedUsersTEST = (objSearch, startIndex, stopIndex) => {
    /*console.log(objSearch.idPars, objSearch.idTasks);

    this.setState({mainIds: this._prepareMainIds(objSearch)}, () => {
      this.getResponses();
    });*/
  };

  getResponses = () => {
    // console.log('state.responses:', this.state.responses, 'state.mainIdsOffset:', this.state.mainIdsOffset);
    const mainIdsPatch = [...this.state.mainIds].slice(this.state.mainIdsOffset, this.state.mainIdsOffset + 10);
    // console.log('mainIdsPatch:', mainIdsPatch, 'state.mainIds.length:', this.state.mainIds.length,'state.responses.length:', this.state.responses.length)
    const config = {params: {ids_patch: mainIdsPatch}};
    //console.log('mainIdsPatch.length:', mainIdsPatch.length);
    if(mainIdsPatch.length !== 0){
      this.setState({isBlocking: true}, () => {
        axios.get('/responses/get_tv_responses', config)
          .then(response =>  {
            //console.log('DB responses:', response.data);
            this.setState({isBlocking: false});

            if(response.data.length !== 0){
              const filteredResponsesDB = this._filterResponsesState(response.data);
              //console.log('filteredResponsesDB:', filteredResponsesDB);

              if(filteredResponsesDB.length !== 0){
                this.setState(prevState => ({
                  mainIdsOffset: prevState.mainIdsOffset + response.data.length,
                  responses: [...prevState.responses, ...this._prepareResponseView(filteredResponsesDB)],
                  //responses: [...prevState.responses, ...this._prepareResponseView(response.data)],
                }));
              } else {
                this.setState(prevState => ({
                  mainIdsOffset: prevState.mainIdsOffset + response.data.length,
                  hasMoreItems: true,
                  //hasMoreItems: false,
                  validationLoading: true
                }), () => {
                  this.getResponses();
                });
              }
            } else {
              this.setState({
                hasMoreItems: false
              })
            }
          }).catch(error => {
          console.log(error);
        })
      });
    } else {
      this.setState({hasMoreItems: false, validationLoading: true})
    }
  }

  _prepareResponseView(responsesDB) {
    let itemsView = [];
    let counter = 0;

    this.props.users.forEach(user => {
      const foundResponseUser = responsesDB.find(response => response.user_id === user.id);

      if(!!foundResponseUser){
        const userActivity = this.props.tasks_filter.find(activity => activity.id === foundResponseUser.activity_id);
        const isUserInResponses = this.state.responses.filter(r => r.type === 'user')
          .find(ru => ru.user.id === foundResponseUser.user_id);
        //console.log('isUserInResponses', isUserInResponses);
        if(isUserInResponses === undefined){
          itemsView.push({user, activity: userActivity, type: 'user', isMax: false});
        }

        this.props.tasks_filter.forEach(activity => {
          const foundResponseActivity = responsesDB.find(rDB => rDB.activity_id === activity.id && rDB.user_id === user.id);
          //console.log('foundResponseActivity', foundResponseActivity);

          if(!!foundResponseActivity){
            //console.log('username:', user.username, 'activityTitle', activity.title);
            const isActivityInResponses = this.state.responses.filter(r => r.type === 'activity')
              .find(ra => ra.activity.id === foundResponseActivity.activity_id && ra.user_id === foundResponseActivity.user_id);
            //console.log('isActivityInResponses', isActivityInResponses, 'counter:', counter);
            if(isActivityInResponses === undefined) {
              itemsView.push({activity, user_id: user.id, type: 'activity', isMax: false});
            }

            activity.questions.forEach((task, index) => {
              //console.log(activity.start);
              this.state.objSearch.idTasks.forEach(idTask => {
                if(task.id === idTask){
                  responsesDB.forEach(response => {
                    if(response.user_id === user.id && response.activity_id === activity.id && response.task_id === task.id){
                      //++counter;
                      if(response.id !== undefined){
                        itemsView.push({
                          ...response, user, task, isMax: true,
                          showConsolidated: this.state.consolidated === 'general',
                          isLoadingConsolidated: this.state.consolidated === 'general',
                          showLoadingConsolidated: false,
                          graph: {
                            responsesConsolidated: [], userAnswerConsolidated: {}, mapsConsolidated: [], userAnswerConsolidatedMap: [],
                            wordCloud: null, barChart: null
                          },
                          cardinal: index + 1,
                          type: 'response',
                          showHiddenVerbatims: false,
                          showOriginal: false,
                          showComments: false
                        });
                      } else {
                        itemsView.push({
                          user, task, isMax: true,
                          showConsolidated: this.state.consolidated === 'general',
                          isLoadingConsolidated: this.state.consolidated === 'general',
                          showLoadingConsolidated: false,
                          type: 'response',
                          showHiddenVerbatims: false,
                          showOriginal: false,
                          showComments: false,
                          state: 'Blank',
                          activityStart: activity.start,
                          activitySegments: activity.segments
                        });
                      }
                    }
                  });
                  //const response = responsesDB.find(response => response.task_id === task.id && response.user_id === user.id);
                  //console.log(response);

                }
              });
            });
          }
        });
      }
    });
    //console.log('itemsView Pre idPars > 1 &&  idActs = 1:', itemsView);
    //console.log(itemsView.map(i => i.type));

    if(this.state.objSearch.idPars.length > 1){
      if(this.state.objSearch.idActs.length === 1){
        const activitiesIV = itemsView.filter(r => r.type === 'activity');
        const responses = itemsView.filter(r => r.type === 'response');
        //console.log('activitiesIV:', activitiesIV, 'responses iv:', responses);
        let newResponsesView;

        if(this.state.objSearch.idTasks.length === 1){
          if(activitiesIV.length !== 0){
            //console.log(activities[0].activity);
            const isFoundActivityItem = this.state.responses.filter(i => i.type === 'activity').find(i => i.d === activitiesIV[0].id);
            console.log('isFoundActivityItem:', isFoundActivityItem);
            if(isFoundActivityItem === undefined){
              const task = activitiesIV[0].activity.questions.find(task => task.id === this.state.objSearch.idTasks[0]);
              const taskTitle = {cardinal: 1, title: task.title, type: 'taskTitle'};
              newResponsesView = [activitiesIV[0], taskTitle, ...responses];
              console.log('newResponsesView:', newResponsesView);
              itemsView = newResponsesView;
            } else {
              itemsView = responses;
            }
          } else {
            itemsView = responses;
          }
        } else {
          const responsesMod = itemsView.filter(item => item.type === 'user' || item.type === 'response');
          //console.log('responsesMod users-responses iv:', responsesMod);

          if(activitiesIV.length !== 0){
            const isFoundActivityItem = this.state.responses.filter(i => i.type === 'activity').find(i => i.d === activitiesIV[0].id);
            //console.log('isFoundActivityItem:', isFoundActivityItem);
            if(isFoundActivityItem === undefined){
              newResponsesView = [activitiesIV[0], ...responsesMod];
              //console.log('newResponsesView:', newResponsesView);
              itemsView = newResponsesView;
            } else {
              itemsView = responsesMod;
            }
          } else {
            itemsView = responsesMod;
          }
        }
      }
    }

    //console.log('itemsView Final:', itemsView);
    return itemsView;
  }

  _prepareMainIds(objSearch){
    const sortedIdPars = [];
    const sortedIdActs = [];
    const sortedIdTasks = [];

    this.props.users.forEach(user => {
      const isFound = objSearch.idPars.find(idPar => idPar === user.id);

      if(isFound !== undefined){
        sortedIdPars.push(isFound)
      }
    });

    this.props.tasks_filter.forEach(activity => {
      const isFound = objSearch.idActs.find(idAct => idAct === activity.id);

      if(isFound !== undefined){
        sortedIdActs.push(isFound)
      }

      activity.questions.forEach(task => {
        const isFound = objSearch.idTasks.find(idTask => idTask === task.id);

        if(isFound !== undefined){
          sortedIdTasks.push(isFound)
        }
      });
    });

    //console.log(sortedIdPars, sortedIdActs, sortedIdTasks);

    const mainIds = [];

    if(objSearch.idActs.length === 1){
      sortedIdPars.forEach(idPar =>
        sortedIdActs.forEach(idAct =>
          sortedIdTasks.forEach(idTask => mainIds.push({user_id: idPar, activity_id: idAct, task_id: idTask}))
      ));
    } else {
      sortedIdPars.forEach(idPar => {
        sortedIdActs.forEach(idAct => {
          this.props.tasks_filter.forEach(activity => {
            if(idAct === activity.id){
              activity.questions.forEach(task => {
                mainIds.push({user_id: idPar, activity_id: idAct, task_id: task.id});
              })
            }
          });
        });
      });
    }

    /*sortedIdPars.forEach(idPar => sortedIdActs.forEach(idAct => sortedIdTasks.forEach(idTask =>
      mainIds.push({user_id: idPar, activity_id: idAct, task_id: idTask}))
    ));*/
    //console.log('mainIds:', mainIds);
    return mainIds;
  }

  _filterResponsesState = (responsesDB) => {
    let responses = [];
    const resStates = this.state.objSearch.resStates;
    //console.log('resStates:', resStates);
    responsesDB.forEach(response => {
      if(response.state !== undefined){
        const responseState = response.state[0].toLowerCase() + response.state.slice(1);
        //console.log('responseState:', responseState, 'resStates[responseState]:', resStates[responseState]);
        if(resStates[responseState]){
          responses.push(response);
        }
      } else {
        if(this.state.objSearch.resStates.empty){
          responses.push(response);
        }
      }
    });

    return responses;
  }

  getResponsesSelectedUsersTEST_ORIG = (objSearch, startIndex, stopIndex) => {
    console.log('getResponsesSelectedUsersTEST tasks:', objSearch.idTasks, 'startIndex:', startIndex, 'stopIndex:',stopIndex,
      'offset:', this.state.offset, 'hasMoreItems', this.state.hasMoreItems);

    if(this.state.hasMoreItems){
      const config = {
        params: {
          users_id: objSearch.idPars,
          activities_id: objSearch.idActs,
          tasks_id: objSearch.idTasks,
          offset: this.state.offset
        }
      };

      axios.get('/responses/get_tv_responses', config)
        .then(response =>  {
          console.log('DB responses:', response.data);
          if(response.data.length !== 0){
            this.setState(prevState => ({
              offset: prevState.offset + response.data.length,
              responses: [...prevState.responses, ...this._prepareResponseView(response.data)],
              //responses: this._prepareResponseView(response.data)  //no scroll
            }));
          } else {
            /*this.setState(prevState => ({
              responses: [...prevState.responses, ...this._prepareResponseViewAllNoResponses()],
              hasMoreItems: false
            }));*/
            this.setState({
              //isLoadingResponses: false,
              hasMoreItems: false
            })
          }
        }).catch(error => {
        console.log(error);
      });
    }
  };

  _prepareResponseView_ORIG_00(responsesDB){
    let itemsView = [];

    /*this.props.users.forEach(user => {
      const foundResponseUser = responsesDB.find(response => response.user_id === user.id);

      if(!!foundResponseUser) {
        const userActivity = this.props.tasks_filter.find(activity => activity.id === foundResponseUser.activity_id);
        const isUserInResponses = this.state.responses.filter(r => r.type === 'user').find(ru => ru.user.id === foundResponseUser.user_id);

        if(!isUserInResponses){
          itemsView.push({user, activity: userActivity, type: 'user', isMax: false});
        }

        this.props.tasks_filter.forEach(activity => {
          const foundResponseActivity = responsesDB.find(response => response.activity_id === activity.id);

          if(!!foundResponseActivity){
            const isActivityInResponses = this.state.responses.filter(r => r.type === 'activity')
              .find(ra => ra.activity.id === foundResponseActivity.activity_id);

            if(!isActivityInResponses){
              itemsView.push({activity, type: 'activity', isMax: false});
            }

            activity.questions.forEach((task, index) => {
              const foundResponse = responsesDB.find(response => response.task_id === task.id && response.user_id === user.id);
              const {consolidated} = this.state;

              if(!!foundResponse){
                itemsView.push({
                  ...foundResponse, user, task, isMax: true,
                  showConsolidated: consolidated === 'general',
                  isLoadingConsolidated: consolidated === 'general',
                  showLoadingConsolidated: false,
                  graph: {
                    responsesConsolidated: [], userAnswerConsolidated: {}, mapsConsolidated: [], userAnswerConsolidatedMap: [],
                    wordCloud: null, barChart: null
                  },
                  cardinal: index + 1,
                  type: 'response',
                  showHiddenVerbatims: false,
                  showOriginal: false,
                  showComments: false
                });
              } else {
                const isVisible = this.state.objSearch.idTasks.find(t => t === task.id);
                if(isVisible !== undefined){
                  itemsView.push({
                    user, task, isMax: true,
                    showConsolidated: consolidated === 'general',
                    isLoadingConsolidated: consolidated === 'general',
                    showLoadingConsolidated: false,
                    type: 'response',
                    showHiddenVerbatims: false,
                    showOriginal: false,
                    showComments: false,
                    state: 'Blank'
                  });
                }
              }
            });
          }

        });

      }
    });*/

    this.state.objSearch.idPars.forEach(idPar => {
      this.props.users.forEach(user => {

        if(user.id === idPar){
          const isUserInResponses = this.state.responses.filter(i => i.type === 'user').find(iu => iu.user.id === user.id);
          if(!isUserInResponses){
            //const userActivity = this.props.tasks_filter.find(act => act.id === foundResponseUser.activity_id);
            // TO-DO PUT THE ACTIVITY
            itemsView.push({user, activity: {}, type: 'user', isMax: false});
          }

          this.state.objSearch.idActs.forEach(idAct => {
            this.props.tasks_filter.forEach(activity => {

              if(activity.id === idAct){
                const isActivityInResponses = this.state.responses.filter(i => i.type === 'activity')
                  .find(ia => ia.activity.id === activity.id);
                if(!isActivityInResponses){
                    itemsView.push({activity, type: 'activity', isMax: false});
                }

                this.state.objSearch.idTasks.forEach((idTask, index) => {
                  activity.questions.forEach(task => {
                    if(task.id === idTask){
                      const isResponseFound = responsesDB.find(r => r.user_id === idPar && r.task_id === idTask);

                      if(isResponseFound !== undefined){
                        itemsView.push({
                          ...isResponseFound, user, task, isMax: true,
                          showConsolidated: this.state.consolidated === 'general',
                          isLoadingConsolidated: this.state.consolidated === 'general',
                          showLoadingConsolidated: false,
                          graph: {
                            responsesConsolidated: [], userAnswerConsolidated: {}, mapsConsolidated: [], userAnswerConsolidatedMap: [],
                            wordCloud: null, barChart: null
                          },
                          cardinal: index + 1,
                          type: 'response',
                          showHiddenVerbatims: false,
                          showOriginal: false,
                          showComments: false
                        });
                      } else {
                        if(responsesDB.length !== 10){
                          itemsView.push({
                            user, task, isMax: true,
                            showConsolidated: this.state.consolidated === 'general',
                            isLoadingConsolidated: this.state.consolidated === 'general',
                            showLoadingConsolidated: false,
                            type: 'response',
                            showHiddenVerbatims: false,
                            showOriginal: false,
                            showComments: false,
                            state: 'Blank'
                          });
                        }
                      }

                    }
                  });
                });

              }
            });
          });

        }
      });
    });

    console.log(itemsView);
    return itemsView;
  }

  _prepareResponseViewAllNoResponses(){
    let responsesView = [];

    this.props.users.forEach(user => {
      this.state.objSearch.idPars.forEach(userId => {
        if(user.id === userId){
          const isUserInResponses = this.state.responses.filter(r => r.type === 'user').find(ru => ru.user.id === userId);
          console.log(isUserInResponses);
          if(!isUserInResponses){
            let userActivity = null;
            //console.log(user);

            this.props.tasks_filter.forEach(activity => {
              this.state.objSearch.idActs.forEach(idAct => {
                if(activity.id === idAct){
                  userActivity = activity;
                }
              });
            });

            responsesView.push({user, activity: userActivity, type: 'user', isMax: false});
          }

          this.props.tasks_filter.forEach(activity => {
            this.state.objSearch.idActs.forEach(idAct => {
              if(activity.id === idAct){
                //console.log(activity);
                const isActivityInResponses = this.state.responses.filter(r => r.type === 'activity').find(ra => ra.activity.id === idAct);
                if(!isActivityInResponses){
                  responsesView.push({activity, type: 'activity', isMax: false});
                }

                activity.questions.forEach(task => {
                  this.state.objSearch.idTasks.forEach(idTask => {
                    if(task.id === idTask){
                      //console.log(task);
                      const isResponseInResponses = this.state.responses.filter(r => r.type === 'response')
                        .find(ra => ra.task.id === task.id && ra.user.id === user.id);
                      console.log(isResponseInResponses);
                      if(!isResponseInResponses){
                        responsesView.push({
                          user, task, isMax: true,
                          showConsolidated: this.state.consolidated === 'general',
                          isLoadingConsolidated: this.state.consolidated === 'general',
                          showLoadingConsolidated: false,
                          type: 'response',
                          showHiddenVerbatims: false,
                          showOriginal: false,
                          showComments: false,
                          state: 'Blank'
                        });
                      }
                    }
                  })
                });
              }
            })
          });
        }
      });
    });

    if(this.state.objSearch.idPars.length > 1){
      if(this.state.objSearch.idActs.length === 1){
        const activities = responsesView.filter(r => r.type === 'activity');
        const responses = responsesView.filter(r => r.type === 'response' && r.state !== 'Blank');
        console.log(activities, responses);
        let newResponsesView;

        if(this.state.objSearch.idTasks.length === 1){

        } else {
          const orderResponsesView = [];

          const activityRow = this.state.responses.filter(r => r.type === 'activity')
            .find(ra => ra.activity.id = this.state.objSearch.idActs[0]);
          console.log(this.state.objSearch.idPars, this.state.responses);
          this.state.objSearch.idPars.forEach(idPar => {
            const userRow = this.state.responses.filter(r => r.type === 'user').find(ra => ra.user.id = idPar);
            console.log(userRow);
            if(!!userRow){
              orderResponsesView.push(userRow);
            }

            this.state.objSearch.idTasks.forEach(idTask => {
            });
          });

          this.state.responses.forEach(row => {
            //orderResponsesView.push(row);
          });

          console.log(orderResponsesView);
        }
      }
    }

    console.log('responsesView - no response:', responsesView);
    return responsesView;
  }

  _prepareResponseView_ORIG(responsesDB){
    let responsesView = [];

    this.props.users.forEach(user => {
      const foundResponseUser = responsesDB.find(response => response.user_id === user.id);

      if(!!foundResponseUser){
        const userActivity = this.props.tasks_filter.find(activity => activity.id === foundResponseUser.activity_id);
        const isUserInResponses = this.state.responses.filter(r => r.type === 'user').find(ru => ru.user.id === foundResponseUser.user_id);
        //console.log('isUserInResponses', isUserInResponses);
        if(!isUserInResponses){
          responsesView.push({user, activity: userActivity, type: 'user', isMax: false});
        }

        this.props.tasks_filter.forEach(activity => {
          const foundResponseActivity = responsesDB.find(response => response.activity_id === activity.id);

          if(!!foundResponseActivity){
            //console.log('foundResponseActivity', foundResponseActivity);
            //console.log(user.username, activity.title);
            const isActivityInResponses = this.state.responses.filter(r => r.type === 'activity')
              .find(ra => ra.activity.id === foundResponseActivity.activity_id);
            //console.log('isActivityInResponses', isActivityInResponses);
            if(!isActivityInResponses){
              responsesView.push({activity, type: 'activity', isMax: false});
            }

            activity.questions.forEach((task, index) => {
              const foundResponse = responsesDB.find(response => response.task_id === task.id && response.user_id === user.id);
              const {consolidated} = this.state;

              if(!!foundResponse){
                responsesView.push({
                  ...foundResponse, user, task, isMax: true,
                  showConsolidated: consolidated === 'general',
                  isLoadingConsolidated: consolidated === 'general',
                  showLoadingConsolidated: false,
                  graph: {
                    responsesConsolidated: [], userAnswerConsolidated: {}, mapsConsolidated: [], userAnswerConsolidatedMap: [],
                    wordCloud: null, barChart: null
                  },
                  cardinal: index + 1,
                  type: 'response',
                  showHiddenVerbatims: false,
                  showOriginal: false,
                  showComments: false,
                  //state: 'Common'
                });
              } else {
                const isVisible = this.state.objSearch.idTasks.find(t => t === task.id);
                if(isVisible !== undefined){
                  responsesView.push({
                    user, task, isMax: true,
                    showConsolidated: consolidated === 'general',
                    isLoadingConsolidated: consolidated === 'general',
                    showLoadingConsolidated: false,
                    type: 'response',
                    showHiddenVerbatims: false,
                    showOriginal: false,
                    showComments: false,
                    state: 'Blank'
                  });
                }
              }
            });
          }
        });
      }
    });

    if(this.state.objSearch.idPars.length > 1){
      if(this.state.objSearch.idActs.length === 1){
        const activities = responsesView.filter(r => r.type === 'activity');
        const responses = responsesView.filter(r => r.type === 'response' && r.state !== 'Blank');
        console.log('activities r:', activities, 'responses r:', responses);
        let newResponsesView;

        if(this.state.objSearch.idTasks.length === 1){
          if(activities.length !== 0){
            //console.log(activities[0].activity);
            const task = activities[0].activity.questions.find(task => task.id === this.state.objSearch.idTasks[0]);
            const taskTitle = {cardinal: 1, title: task.title, type: 'taskTitle'};
            newResponsesView = [activities[0], taskTitle, ...responses];
            console.log(newResponsesView);
            responsesView = newResponsesView;
          } else {
            responsesView = responses;
          }
        } else {
          const responsesMod = responsesView.filter(r => ((r.type === 'user' || r.type === 'response') && r.state !== 'Blank'));

          if(activities.length !== 0){
            newResponsesView = [activities[0], ...responsesMod];
            console.log(newResponsesView);
            responsesView = newResponsesView;
          } else {
            responsesView = responsesMod;
          }
        }
      }
    }

    console.log('responsesView - response:', responsesView);
    return responsesView;
  }

  // FILTERS
  // TO-DO it would be inside advancedActions
  updateJustPills = (id, type) => {
    console.log('updateJustPills:', id, type);
    const objSearch = this._updateObjSearch(id, type);
    console.log('objSearch:', objSearch);
    this.setState({
      objSearch, offset: 0, hasMoreItems: true, responses: [], offsetConsolidated: 0, responsesConsolidated: [],
      hasMoreItemsConsolidated: true, mainIdsOffset: 0
    }, () => {
      if(this.state.objSearch.idActs.length !== 0){
        if(type === 'idActs'){
          /*let idTasks = [];

          this.props.tasks_filter.forEach(activity => {
            this.state.objSearch.idActs.forEach(idAct => {
              if(activity.id === idAct){
                idTasks = [...idTasks, ...activity.questions.map(task => task.id)];
              }
            });
          });
          console.log('idTasks.length:', idTasks.length);*/

          //this.setState({objSearch: {...this.state.objSearch, idTasks}, validationLoading : idTasks.length === 0},
          this.setState({validationLoading: this.state.objSearch.idTasks.length === 0},
            () => {
            if(this.state.consolidated === ''){
              //console.log(this.state.objSearch);
              this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
                this.getResponses();
              });
            } else {
              this.getResponsesConsolidated_01();
            }
          });

        } else {
          //console.log(this.state.objSearch);
          this.setState({validationLoading : this.state.objSearch.idTasks.length === 0});
          if(this.state.consolidated === ''){
            this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
              this.getResponses();
            });
            //this.getResponsesSelectedUsersTEST(this.state.objSearch);
          } else {
            this.getResponsesConsolidated_01();
          }
          //this.getResponsesSelectedUsersTEST(this.state.objSearch, 0, 9);
        }
      } else {
        this.setState({validationLoading : true});
      }
    });
  };

  advancedActionsJust = (id, type) => {
    //console.log('advancedActions', id, type);
    const objSearchNoSorted = this._updateObjSearch(id, type);
    const objSearch = {...objSearchNoSorted, idPars: this._sortIdPars(objSearchNoSorted.idPars)};
    const idPars = [objSearch.idPars[0]];
    const idActs = [objSearch.idActs[0]];

    //TO-DO maybe I need to put hasMoreItems the same way as here
    this.setState({objSearch, chkUsers: false, objSearchScroll: {idPars, idActs}, offset: 0, responses: [],
        offsetConsolidated: 0, responsesConsolidated: [], hasMoreItems: true, hasMoreItemsConsolidated: true, mainIdsOffset: 0}
      , () => {
        //console.log(this.state.objSearch, this.state.objSearchScroll);
        switch(type){
          case 'idPars':
            if(this.state.objSearch.idPars.length !== 0 && this.state.objSearch.idActs.length !== 0
              && this.state.objSearch.idTasks.length !== 0){
              this.setState({validationLoading: false});
              if(this.state.consolidated === ''){
                this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
                  this.getResponses();
                });
                //this.getResponsesSelectedUsersTEST(this.state.objSearch);
              } else {
                this.getResponsesConsolidated_01();
              }
              /*this.setState({chkSegs: false, objSearch: {...this.state.objSearch, idSegs: []}}, () => {
                console.log('READY to call responses, the above setState take a long time')
                //this.getResponsesSelectedUsers(this.state.objSearch);
              });*/
            } else {
              this.setState({validationLoading: true});
            }
            break;
          case 'idSegs':
            const idPars = [];

            this.props.users.forEach(user => {
              if(this.state.objSearch.idSegs.length !== 0){
                if(user.segments.length !== 0){
                  user.segments.forEach(segment => {
                    //console.log(user);
                    if(this.state.objSearch.idSegs.find(id => id === segment.id) !== undefined){
                      idPars.push(user.id)
                    }
                    if(this.props.community.gender_as_segment){
                      // TO-DO in Manage put 'Other Gender to Other'
                      const optMod = this.state.objSearch.idSegs.map(opt => opt === 'Other Gender' ? 'Other' : opt);
                      //console.log(user.username, user.gender, segment, optMod);
                      if(optMod.find(id => id === user.gender) !== undefined){
                        idPars.push(user.id);
                      }
                    }
                  })
                } else {
                  if(this.props.community.gender_as_segment){
                    // TO-DO in Manage put 'Other Gender to Other'
                    const optMod = this.state.objSearch.idSegs.map(opt => opt === 'Other Gender' ? 'Other' : opt);
                    //console.log(user.username, user.gender, optMod);
                    if(optMod.find(id => id === user.gender) !== undefined){
                      idPars.push(user.id);
                    }
                  }
                }

              } else {
                objSearch.idPars = [];
                //idPars.push(user.id);
              }
            });

            this.setState({objSearch: {...this.state.objSearch, idPars: [...new Set(idPars)]}}, () => {
              this.setState({
                  objSearchScroll: {idPars: [this.state.objSearch.idPars[0]], idActs: [this.state.objSearch.idActs[0]]}},
                () => {
                  if(this.state.objSearch.idPars.length !== 0 && this.state.objSearch.idActs.length !== 0
                    && this.state.objSearch.idTasks.length !== 0) {
                    this.setState({validationLoading: false});
                    if(this.state.consolidated === ''){
                      this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
                        this.getResponses();
                      });
                      //this.getResponsesSelectedUsersTEST(this.state.objSearch);
                    } else {
                      this.getResponsesConsolidated_01();
                    }
                    //this.getResponsesSelectedUsers(this.state.objSearch);
                  } else {
                    this.setState({validationLoading: true});
                  }
                });
            });
            break;
          case 'idTags':
            const idActs = [];

            this.props.tasks_filter.forEach(task => {
              if(this.state.objSearch.idTags.length !== 0){
                if(this.state.objSearch.idTags.find(id => id === task.tag_id) !== undefined){
                  idActs.push(task.id);
                }
              } else {
                idActs.push(task.id);
              }
            });
            const idTasks = this._getFilteredTasks(idActs);

            this.setState({objSearch: {...this.state.objSearch, idActs, idTasks}, offset: 0, responses: [],
              hasMoreItems: true}, () => {
              if(this.state.objSearch.idPars.length !== 0 && this.state.objSearch.idActs.length !== 0
                && this.state.objSearch.idTasks.length !== 0) {
                this.setState({validationLoading: false});
                if(this.state.consolidated === ''){
                  this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
                    this.getResponses();
                  });
                  //this.getResponsesSelectedUsersTEST(this.state.objSearch);
                } else {
                  this.getResponsesConsolidated_01();
                }

                //this.getResponsesSelectedUsersTEST(this.state.objSearch);
                //this.getResponsesSelectedUsers(this.state.objSearch);
              } else {
                this.setState({validationLoading: true});
              }
            });
            break;
        }
      });
  };

  updatePills = (id, modalType) => {
    console.log('updatePills:', id, modalType);
    this.setState({
      objSearch: this._updateObjSearch(id, modalType), modalType
      //offset: 0, hasMoreItems: true, responses: [],
      //offsetConsolidated: 0, responsesConsolidated: [], hasMoreItemsConsolidated: true, mainIdsOffset: 0
    });
  };

  advancedActions = (id, modalType) => {
    console.log('advancedActions', id, modalType);
    const objSearchNoSorted = this._updateObjSearch(id, modalType);
    const objSearch = {...objSearchNoSorted, idPars: this._sortIdPars(objSearchNoSorted.idPars)};

    this.setState({objSearch, modalType});
  };

  executeUpdateModal = () => {
    console.log('executeUpdateModal');
    this.setState(prevState => ({showModal: !prevState.showModal}));

    const idPars = [this.state.objSearch.idPars[0]];
    const idActs = [this.state.objSearch.idActs[0]];

    //TO-DO maybe I need to put hasMoreItems the same way as here
    this.setState({chkUsers: false, objSearchScroll: {idPars, idActs}, offset: 0, responses: [],
        offsetConsolidated: 0, responsesConsolidated: [], hasMoreItems: true, hasMoreItemsConsolidated: true,
        mainIdsOffset: 0},
      () => {
        console.log(this.state.modalType, this.state.objSearch, this.state.objSearchScroll);
        switch(this.state.modalType){
          case 'idPars':
            if(this.state.objSearch.idPars.length !== 0 && this.state.objSearch.idActs.length !== 0
              && this.state.objSearch.idTasks.length !== 0){
              this.setState({validationLoading: false});
              if(this.state.consolidated === ''){
                this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
                  this.getResponses();
                });
              } else {
                this.getResponsesConsolidated_01();
              }
            } else {
              this.setState({validationLoading: true});
            }
            break;
          case 'idSegs':
            const idPars = [];

            this.props.users.forEach(user => {
              if(this.state.objSearch.idSegs.length !== 0){
                if(user.segments.length !== 0){
                  user.segments.forEach(segment => {
                    //console.log(user);
                    if(this.state.objSearch.idSegs.find(id => id === segment.id) !== undefined){
                      idPars.push(user.id)
                    }
                    if(this.props.community.gender_as_segment){
                      // TO-DO in Manage put 'Other Gender to Other'
                      const optMod = this.state.objSearch.idSegs.map(opt => opt === 'Other Gender' ? 'Other' : opt);
                      //console.log(user.username, user.gender, segment, optMod);
                      if(optMod.find(id => id === user.gender) !== undefined){
                        idPars.push(user.id);
                      }
                    }
                  })
                } else {
                  if(this.props.community.gender_as_segment){
                    // TO-DO in Manage put 'Other Gender to Other'
                    const optMod = this.state.objSearch.idSegs.map(opt => opt === 'Other Gender' ? 'Other' : opt);
                    //console.log(user.username, user.gender, optMod);
                    if(optMod.find(id => id === user.gender) !== undefined){
                      idPars.push(user.id);
                    }
                  }
                }

              } else {
                this.state.objSearch.idPars = [];
                //idPars.push(user.id);
              }
            });

            this.setState({objSearch: {...this.state.objSearch, idPars: [...new Set(idPars)]}}, () => {
              this.setState({
                  objSearchScroll: {idPars: [this.state.objSearch.idPars[0]], idActs: [this.state.objSearch.idActs[0]]}},
                () => {
                  if(this.state.objSearch.idPars.length !== 0 && this.state.objSearch.idActs.length !== 0
                    && this.state.objSearch.idTasks.length !== 0) {
                    this.setState({validationLoading: false});
                    if(this.state.consolidated === ''){
                      this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
                        this.getResponses();
                      });
                      //this.getResponsesSelectedUsersTEST(this.state.objSearch);
                    } else {
                      this.getResponsesConsolidated_01();
                    }
                    //this.getResponsesSelectedUsers(this.state.objSearch);
                  } else {
                    this.setState({validationLoading: true});
                  }
                });
            });
            break;
          case 'idTags':
            const idActs = [];

            this.props.tasks_filter.forEach(task => {
              if(this.state.objSearch.idTags.length !== 0){
                if(this.state.objSearch.idTags.find(id => id === task.tag_id) !== undefined){
                  idActs.push(task.id);
                }
              } else {
                idActs.push(task.id);
              }
            });
            const idTasks = this._getFilteredTasks(idActs);

            this.setState({objSearch: {...this.state.objSearch, idActs, idTasks}, offset: 0, responses: [],
              hasMoreItems: true}, () => {
              if(this.state.objSearch.idPars.length !== 0 && this.state.objSearch.idActs.length !== 0
                && this.state.objSearch.idTasks.length !== 0) {
                this.setState({validationLoading: false});
                if(this.state.consolidated === ''){
                  this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
                    this.getResponses();
                  });
                  //this.getResponsesSelectedUsersTEST(this.state.objSearch);
                } else {
                  this.getResponsesConsolidated_01();
                }

                //this.getResponsesSelectedUsersTEST(this.state.objSearch);
                //this.getResponsesSelectedUsers(this.state.objSearch);
              } else {
                this.setState({validationLoading: true});
              }
            });
            break;
          case 'idActs':
            //console.log(this.state.objSearch.idActs.length, this.state.objSearch.idTasks.length);
            if(this.state.objSearch.idActs.length !== 0 && this.state.objSearch.idTasks.length !== 0){
              if(this.state.modalType === 'idActs'){
                let idTasks = [];

                this.props.tasks_filter.forEach(activity => {
                  this.state.objSearch.idActs.forEach(idAct => {
                    if(activity.id === idAct){
                      idTasks = [...idTasks, ...activity.questions.map(task => task.id)];
                    }
                  });
                });

                this.setState({objSearch: {...this.state.objSearch, idTasks}, validationLoading : false}, () => {
                  if(this.state.consolidated === ''){
                    console.log(this.state.objSearch);
                    this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
                      this.getResponses();
                    });
                    //this.getResponsesSelectedUsersTEST(this.state.objSearch);
                  } else {
                    this.getResponsesConsolidated_01();
                  }
                });
              } else {
                this.setState({validationLoading : false});
                if(this.state.consolidated === ''){
                  this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
                    this.getResponses();
                  });
                  //this.getResponsesSelectedUsersTEST(this.state.objSearch);
                } else {
                  this.getResponsesConsolidated_01();
                }

              }
            } else {
              this.setState({validationLoading : true});
            }
            break;
        }
      });
  };

  _sortIdPars(idPars){
    const arrayToSort = [...idPars];
    const usersId = this.state.users.map(user => user.user_id);
    //console.log(usersId);

    arrayToSort.sort((a, b) => {
      return usersId.indexOf(a) - usersId.indexOf(b);
    });

    return arrayToSort;
  }

  handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.advancedActions(value, name === 'chkUsers' ? 'idPars' : 'idSegs');

    this.setState({[name]: value});
  };

  handleUserArrows = direction => {
    const { users } = this.props;
    const { objSearch } = this.state;
    //console.log('objSearch:', objSearch, 'users:', users);

    const currentIndexOf = users.indexOf(users.find(user => user.id === objSearch.idPars[0]));
    let nextUser;

    if(direction === 'left'){
      nextUser = currentIndexOf !== 0 ? users[currentIndexOf - 1] : users[users.length - 1];
    } else {
      nextUser = currentIndexOf < users.length - 1 ? users[currentIndexOf + 1] : users[0];
    }

    this.setState({
      objSearch: {...objSearch, idPars: [nextUser.id]},
      //consolidated: this.state.consolidated === consolidated ? '' : consolidated,
      responsesConsolidated: [],
      offsetConsolidated: 0,
      hasMoreItemsConsolidated: true,
      isLoadingConsolidated: false,
      validationLoading: false,
      objSearchScroll: {idPars: [nextUser.id], idActs: [objSearch.idActs[0]]},
      offset: 0, responses: [], hasMoreItems: true, mainIdsOffset: 0
    }, () => {
      if(this.state.consolidated === ''){
        this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
          this.getResponses();
        });
      } else {
        if(this.state.objSearch.idPars.length !== 0){
          this.getResponsesConsolidated_01();
        }
      }
    });
  };

  handleHideTabFilters = () => {
    this.setState(prevState => ({hideTabFilters: !prevState.hideTabFilters}))
    if (this.state.hideTabFilters === true) {
      $('.px-tv-settings').delay(200).fadeIn();
    } else {
      $('.px-tv-settings').hide();
    }
  };

  updateConsolidated = consolidated => {
    //console.log('consolidated:', consolidated, 'this.state.consolidated:', this.state.consolidated);
    //TO-DO test if it's better to call the responses and after all to do the show of the results, after a loading gif
    this.setState({
      consolidated: this.state.consolidated === consolidated ? '' : consolidated,
      responsesConsolidated: [],
      offsetConsolidated: 0,
      hasMoreItemsConsolidated: true,
      isLoadingConsolidated: false,
      validationLoading: true
    }, () => {
      if(this.state.consolidated === ''){
        this.setState({mainIds: this._prepareMainIds(this.state.objSearch)}, () => {
          this.getResponses();
        });
        //this.getResponsesSelectedUsersTEST(this.state.objSearch);
      } else {
        if(this.state.objSearch.idPars.length !== 0){
          this.getResponsesConsolidated_01();
        }
      }
    });
  };

  getResponsesConsolidated_01 = () => {
    //console.log('this.state.consolidated:', this.state.consolidated, 'offsetConsolidated: ' + this.state.offsetConsolidated,
    //  'hasMoreItemsConsolidated: ' + this.state.hasMoreItemsConsolidated);

    const tasksId = this.state.consolidated === 'general' ?
      this.state.objSearch.idTasks.slice(this.state.offsetConsolidated, this.state.offsetConsolidated + 2) :
      this.state.objSearch.idTasks;
    //console.log('tasksId: ', tasksId);  //TO-DO Maybe I need to put this value in state in order TBody knows what exactly render again

    if(tasksId.length !== 0){
      switch(this.state.consolidated){
        case 'general': // its' better call it 'charts'
        case 'videos':
        case 'images':
          let users_id;
          if(this.state.consolidated === 'general'){
            users_id = this.state.objSearch.idPars.length === 1 ? this.props.users.map(u => u.id) : this.state.objSearch.idPars
          } else {
            users_id = this.state.objSearch.idPars;
          }

          const data = {
            users_id: users_id,
            tasks_id: tasksId,
            allUsersId: this.props.users.map(user => user.id),
            offset: this.state.offsetConsolidated, // TO-DO try to use offsetConsolidated to prevent a call when there is no more tasksId
            consolidated: this.state.consolidated
          };
          const config = {
            headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}
          };

          this.setState({isLoadingResponsesConsolidated: true}, () => {
            axios.post('/responses/get_tv_responses_consolidated', data, config)
              .then(r =>  {
                console.log('responses from DB: ', r.data);
                if(r.data.length !== 0){
                  const dataDBMod = r.data.map(r => ({...r, graph: {wordCloud: null, barChart: null}, type: 'response'}));
                  //console.log('dataDBMod:', dataDBMod);
                  const rc = this._prepareItemsConsolidatedMulti(dataDBMod);
                  console.log('responsesConsolidated:', rc);
                  this.setState(prevState => ({
                    responsesConsolidated: [...prevState.responsesConsolidated, ...rc],
                    isLoadingConsolidated: false,
                    offsetConsolidated: prevState.offsetConsolidated + 2,
                    isLoadingResponsesConsolidated: false,
                    hasMoreItemsConsolidated: this.state.consolidated === 'general' ? this.state.hasMoreItemsConsolidated : false
                  }));
                } else {
                  this.setState({
                    hasMoreItemsConsolidated: false,
                    isLoadingResponsesConsolidated: false
                  })
                }
              }).catch(error => {
              this.setState({isLoadingResponsesConsolidated: false});
              console.log(error);
            });
            /*axios.get('/responses/get_tv_responses_consolidated', config)
              .then(r =>  {
                console.log('responses from DB: ', r.data);
                if(r.data.length !== 0){
                  const dataDBMod = r.data.map(r => ({...r, graph: {wordCloud: null, barChart: null}, type: 'response'}));
                  console.log('dataDBMod:', dataDBMod);
                  const rc = this._prepareItemsConsolidatedMulti(dataDBMod);
                  console.log('responsesConsolidated:', rc);
                  this.setState(prevState => ({
                    responsesConsolidated: [...prevState.responsesConsolidated, ...rc],
                    isLoadingConsolidated: false,
                    offsetConsolidated: prevState.offsetConsolidated + 2,
                    isLoadingResponsesConsolidated: false,
                    hasMoreItemsConsolidated: this.state.consolidated === 'general' ? this.state.hasMoreItemsConsolidated : false
                  }));
                } else {
                  this.setState({
                    hasMoreItemsConsolidated: false,
                    isLoadingResponsesConsolidated: false
                  })
                }
              }).catch(error => {
              this.setState({isLoadingResponsesConsolidated: false});
              console.log(error);
            });*/
          });
          break;
        default:
          this.setState({isLoadingResponses: false});
          break;
      }
    } else {
      this.setState({ hasMoreItemsConsolidated : false })
    }
  };

  getResponsesConsolidatedSingle = (responseId, users_id, tasks_id, allUsersId, task_type, userId, showConsolidated, type) => {
    console.log(responseId, users_id, tasks_id, task_type, userId, 'showConsolidated:', showConsolidated, type);
    const config = {
      params: {
        users_id: this.state.objSearch.idPars,
        tasks_id: tasks_id,
        allUsersId: allUsersId,
        consolidated: 'general'
      }
    };

    let responses;
    if(type === 'inner'){
      responses = this.state.responses.map(r => r.id === responseId ?
        {...r,
          showConsolidated: !r.showConsolidated,
          isLoadingConsolidated: !showConsolidated ? !r.isLoadingConsolidated : false,
          showLoadingConsolidated: !r.showLoadingConsolidated
        } : r);
      /*console.log('responses to put on this.state', responses.map(r => ({id: r.id, showConsolidated: r.showConsolidated,
        isLoadingConsolidated: r.isLoadingConsolidated})));*/
      this.setState({responses}, () => {
        if(!showConsolidated){
          //this.getTVResponsesConsolidated(config, task_type, userId, responseId);
        }
      });
    } else {
      //responses = this.state.responses;
      if(showConsolidated){
        this.getTVResponsesConsolidated(config, task_type, userId, responseId);
      }
    }
  };

  getTVResponsesConsolidated = (config, task_type, userId, responseId) => {
    console.log('config:', config);
    axios.get('/responses/get_tv_responses_consolidated', config)
      .then(r =>  {
        console.log('consolidated responses DB: ', r.data);
        let allMapsConsolidated = [];
        let mapsConsolidated = [];
        let userAnswerConsolidatedMap = [];

        switch(task_type){
          case 'Open End':
            allMapsConsolidated = r.data[0].responses.map(response => response.participantMapItems
              .map(item => item[this.props.language]));
            mapsConsolidated = [].concat.apply([], allMapsConsolidated);
            userAnswerConsolidatedMap = ((r.data[0].responses.find(response => response.user_id === userId))
              .participantMapItems).map(item => item[this.props.language]);
            break;
          case 'Choice':
            allMapsConsolidated = r.data[0].responses.map(response => response.data.answersId.map(item => item));
            mapsConsolidated = [].concat.apply([], allMapsConsolidated);
            userAnswerConsolidatedMap = (r.data[0].responses.find(response => response.user_id === userId))
              .data.answersId;
            break;
        }

        //console.log(mapsConsolidated);
        //console.log(allMapsConsolidated);
        //console.log(userAnswerConsolidatedMap);

        const graph = {
          responsesConsolidated: r.data[0].responses.map(response => response.data),
          userAnswerConsolidated: r.data[0].responses.find(response => response.user_id === userId).data,
          mapsConsolidated: mapsConsolidated,
          userAnswerConsolidatedMap: userAnswerConsolidatedMap,
          wordCloud: null,
          barChart: null
        };

        const responses = this.state.responses.map(r => r.id === responseId ?
          {...r, isLoadingConsolidated: !r.isLoadingConsolidated, graph: graph} : r);
        console.log('responses', responses.map(r => ({id: r.id, isLoadingConsolidated: r.isLoadingConsolidated,
          showConsolidated: r.showConsolidated, showLoadingConsolidated: r.showLoadingConsolidated})));
        this.setState({responses});

      }).catch(error => {
      console.log(error);
      // TO-DO turnOff the loading
    });
  };

  updateSVGMulti = (svg, type, task_id) => {
    //console.log(type,task_id);
    const responsesConsolidated = this.state.responsesConsolidated.map(r => r.task_id === task_id ?
      {...r, graph: {...r.graph, [type]: svg}} : r);
    //console.log(responsesConsolidated);
    this.setState({responsesConsolidated});
  };

  updateSVG = (svg, type, responseId) => {
    console.log(svg,type,responseId);
    const responses = this.state.responses.map(r => r.id === responseId ?
      {...r, graph: {...r.graph, [type]: svg}} : r);
    console.log(responses);
    this.setState({responses});
  };

  _prepareItemsConsolidatedMulti(responses){
    //console.log('responses:', responses);
    const items = [];

    this.props.tasks_filter.forEach(activity => {
      this.state.objSearch.idActs.forEach(idAct => {
        let activityItem = null;

        if(activity.id === idAct){
          activity.questions.forEach((task, index) => {
            responses.forEach(response => {
              if(task.id === response.task_id){
                const itemActivities = this.state.responsesConsolidated.filter(r => r.type === 'activity');
                //console.log('itemActivities:', itemActivities);
                const activityFound = itemActivities.find(r => r.id === activity.id);
                //console.log('activityFound:', activityFound);
                if(activityItem === null && activityFound === undefined){
                  activityItem = {...activity, type: 'activity'};
                  items.push(activityItem);
                }
                const filteredResponses = this._filterResponsesState(response.responses);
                //console.log('response.responses:', response.responses, 'filteredResponses:', filteredResponses);
                items.push({...response, responses: filteredResponses, task, cardinal: index + 1});
              }
            })
          });
        }
      });
    });

    return items;
  }

  updateFiltersOptsExp = (type, filter) => {
    console.log(type, filter);
    const objExport = {...this.state.objExport};

    switch(type){
      case 'Activities':
        const idTasks = [];

        switch(filter){
          case 'All':
            objExport.idActs = this.props.tasks_filter.map(task => task.id);
            this.props.tasks_filter.forEach(task => {
              task.questions.forEach(question => { idTasks.push(question.id) })
            });
            objExport.idTasks = idTasks;
            break;
          case 'None':
            objExport.idActs = [];
            objExport.idTasks = [];
            break;
          case 'Available':
            objExport.idActs = [...new Set(filterSetDates('Available', this.props.tasks_filter).map(task => task.id))];
            objExport.idTasks = this._getFilteredTasks(objExport.idActs);
            break;
          case 'Expired':
            objExport.idActs = filterSetDates('Expired', this.props.tasks_filter).map(task => task.id);
            if(objExport.idActs.length !== 0){
              objExport.idTasks = this._getFilteredTasks(objExport.idActs);
            }
            break;
          case 'Dates':
            const startDate = moment(objExport.actDates.startDate).format('YYYY-MM-DD');
            const endDate = moment(objExport.actDates.endDate).format('YYYY-MM-DD');

            switch(objExport.actDates.name){
              case 'creation':
                this.props.tasks_filter.forEach(task => {
                  const createdAt = moment(task.created_at).format('YYYY-MM-DD');
                  //console.log(createdAt, startDate, endDate);
                  if(createdAt >= startDate && createdAt <= endDate) {
                    objExport.idActs.push(task.id);
                  }
                });
                if(objExport.idActs.length !== 0){
                  objExport.idTasks = this._getFilteredTasks(objExport.idActs);
                }
                break;
              case 'start':
                this.props.tasks_filter.forEach(task => {
                  if(task.start){
                    const taskStart = moment(task.start).format('YYYY-MM-DD');
                    if(taskStart >= startDate && taskStart <= endDate) {
                      objExport.idActs.push(task.id);
                    }
                  }
                });
                if(objExport.idActs.length !== 0){
                  objExport.idTasks = this._getFilteredTasks(objExport.idActs);
                }
                break;
              case 'expiry':
                this.props.tasks_filter.forEach(task => {
                  if(task.expire){
                    const expireDate = moment(task.expire).format('YYYY-MM-DD');
                    if(expireDate >= startDate && expireDate <= endDate){
                      objExport.idActs.push(task.id);
                    }
                  }
                });
                if(objExport.idActs.length !== 0){
                  objExport.idTasks = this._getFilteredTasks(objExport.idActs);
                }
                break;
            }
            break;
        }
        break;
      case 'Participants':
        objExport.idPars = filter === 'All' ? this.props.users.map(user => user.id) : [];
        break;
      case 'Segments':
        const segments = this.props.community.segments.map(s => ({...s, modalVisibility: true}));
        if(this.props.community.gender_as_segment){
          segments.push({id: 'Female', name: 'Female', color: '#000'}, {id: 'Male', name: 'Male', color: '#000'},
            {id: 'Other Gender', name: 'Other Gender', color: '#000'});
        }

        objExport.idSegs = filter === 'All' ? segments.map(segment => segment.id) : [];
        break;
      case 'Tags':
        if(filter === 'All'){
          objExport.idTags = this.props.community.tags.map(tag => tag.id);
          objExport.idActs = this._filterActsByAllTags(objExport.idTags);
          objExport.idTasks = this._getFilteredTasks(objExport.idActs);
        } else {
          objExport.idTags = [];
          objExport.idActs = [];
        }
        break;
      case 'Data Types':
        objExport.dataTypes = filter === 'All' ? ['text', 'comments', 'wordbook', 'images', 'verbatims'] : [];
        break;
      case 'Privacy':
        objExport.privacy = filter === 'All' ? ["include emails", "include alias", "anonymise username"] : [];
        break;
    }

    this.setState({objExport});
  };

  updateFiltersOpts = (type, filter) => {
    console.log('type:', type, 'filter:', filter);
    const objSearch = {...this.state.objSearch};
    let modalType = '';

    switch(type){
      case 'Participants':
        objSearch.idPars = filter === 'All' ? this.props.users.map(user => user.id) : [];
        modalType = 'idPars';
        break;
      case 'Segments':
        const segments = this.props.community.segments.map(s => ({...s, modalVisibility: true}));
        if(this.props.community.gender_as_segment){
          segments.push({id: 'Female', name: 'Female', color: '#000'}, {id: 'Male', name: 'Male', color: '#000'},
              {id: 'Other Gender', name: 'Other Gender', color: '#000'});
        }

        objSearch.idSegs = filter === 'All' ? segments.map(segment => segment.id) : [];
        modalType = 'idSegs';
        break;
      case 'Activities':
        const idTasks = [];

        switch(filter){
          case 'All':
            objSearch.idActs = this.props.tasks_filter.map(task => task.id);
            this.props.tasks_filter.forEach(task => {
              task.questions.forEach(question => { idTasks.push(question.id) })
            });
            objSearch.idTasks = idTasks;
            break;
          case 'None':
            objSearch.idActs = [];
            objSearch.idTasks = [];
            break;
          case 'Live':
            objSearch.idActs = this.props.tasks_filter.filter(a => a.visibility === 'enabled').map(a => a.id);
            this.props.tasks_filter.forEach(activity => {
              if(activity.visibility === 'enabled'){
                activity.questions.forEach(question => { idTasks.push(question.id) })
              }
            });
            objSearch.idTasks = idTasks;
            break;
          case 'Available':
            objSearch.idActs = filterSetDates('Available', this.props.tasks_filter).map(task => task.id);
            objSearch.idTasks = this._getFilteredTasks(objSearch.idActs);
            break;
          case 'Expired':
            objSearch.idActs = filterSetDates('Expired', this.props.tasks_filter).map(task => task.id);
            if(objSearch.idActs.length !== 0){
              objSearch.idTasks = this._getFilteredTasks(objSearch.idActs);
            }
            break;
          case 'Dates':
            const startDate = moment(objSearch.actDates.startDate).format('YYYY-MM-DD');
            const endDate = moment(objSearch.actDates.endDate).format('YYYY-MM-DD');

            switch(objSearch.actDates.name){
              case 'creation':
                this.props.tasks_filter.forEach(task => {
                  const createdAt = moment(task.created_at).format('YYYY-MM-DD');
                  //console.log(createdAt, startDate, endDate);
                  if(createdAt >= startDate && createdAt <= endDate) {
                    objSearch.idActs.push(task.id);
                  }
                });
                //console.log('objSearch:', objSearch);
                if(objSearch.idActs.length !== 0){
                  objSearch.idTasks = this._getFilteredTasks(objSearch.idActs);
                }
                break;
              case 'start':
                this.props.tasks_filter.forEach(task => {
                  if(task.start){
                    const taskStart = moment(task.start).format('YYYY-MM-DD');
                    if(taskStart >= startDate && taskStart <= endDate) {
                      objSearch.idActs.push(task.id);
                    }
                  }
                });
                if(objSearch.idActs.length !== 0){
                  objSearch.idTasks = this._getFilteredTasks(objSearch.idActs);
                }
                break;
              case 'expiry':
                this.props.tasks_filter.forEach(task => {
                  if(task.expire){
                    const expireDate = moment(task.expire).format('YYYY-MM-DD');
                    if(expireDate >= startDate && expireDate <= endDate){
                      objSearch.idActs.push(task.id);
                    }
                  }
                });
                if(objSearch.idActs.length !== 0){
                  objSearch.idTasks = this._getFilteredTasks(objSearch.idActs);
                }
                break;
            }
            break;
        }
        modalType = 'idActs';
        break;
      case 'Tags':
        if(filter === 'All'){
          objSearch.idTags = this.props.community.tags.map(tag => tag.id);
          objSearch.idActs = this._filterActsByAllTags(objSearch.idTags);
          objSearch.idTasks = this._getFilteredTasks(objSearch.idActs);
        } else {
          objSearch.idTags = [];
          objSearch.idActs = [];
          //objSearch.idTasks = [];
        }
        modalType = 'idTags';
        //objSearch.idTags = filter === 'All' ? this.state.tags.map(tag => tag.id) : [];
        break;
    }
    console.log('objSearch:', objSearch);

    const idPars = filter === 'None' ? [] : [objSearch.idPars[0]];
    const idActs = [objSearch.idActs[0]];

    this.setState({ objSearch, objSearchScroll: {idPars, idActs}, modalType});
  };

  _getFilteredTasks(idActs){
    const filteredActs = [];
    const idTasks = [];

    this.props.tasks_filter.forEach(task => {
      idActs.forEach(taskId => {
        if(task.id === taskId){
          filteredActs.push(task);
        }
      });
    });

    filteredActs.forEach(task => {
      task.questions.forEach(question => { idTasks.push(question.id) })
    });

    return idTasks;
  }

  _filterActsByAllTags(idTags){
    const idActs = [];
    console.log(idTags);
    this.props.tasks_filter.forEach(task => {
      if(idTags.length !== 0){
        if(idTags.find(id => id === task.tag_id) !== undefined){
          idActs.push(task.id);
        }
      } else {
        idActs.push(task.id);
      }
    });

    return idActs;
  }

  updateSltCalendarExp = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log(name, value);
    const objExport = {...this.state.objExport};
    objExport.actDates.name = value;

    this.setState({objExport}, () => {
      if(objExport.actDates.startDate && objExport.actDates.endDate) {
        this.setState({objExport: {...objExport, idActs: []}}, () => {
          this.updateFiltersOptsExp('Activities', 'Dates');
        });
      }
    });
  };

  updateSltCalendar = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log(name, value);
    const objSearch = {...this.state.objSearch};
    objSearch.actDates.name = value;

    this.setState({objSearch}, () => {
      if(objSearch.actDates.startDate && objSearch.actDates.endDate) {
        this.setState({objSearch: {...objSearch, idActs: []}}, () => {
          this.updateFiltersOpts('Activities', 'Dates');
        });
      }
    });
  };

  updateStartExpireDatesExp = (startDate, endDate) => {
    //console.log(startDate, endDate);
    const objExport = {...this.state.objExport};
    objExport.actDates.startDate = startDate;
    objExport.actDates.endDate = endDate;

    this.setState({objExport}, () => {
      if(objExport.actDates.startDate && objExport.actDates.endDate) {
        this.setState({objExport: {...objExport, idActs: []}}, () => {
          this.updateFiltersOptsExp('Activities', 'Dates');
        });
      }
    });
  };

  updateStartExpireDates = (startDate, endDate) => {
    //console.log(startDate, endDate);
    const objSearch = {...this.state.objSearch};
    objSearch.actDates.startDate = startDate;
    objSearch.actDates.endDate = endDate;

    this.setState({objSearch}, () => {
      if(objSearch.actDates.startDate && objSearch.actDates.endDate) {
        this.setState({objSearch: {...objSearch, idActs: []}}, () => {
          this.updateFiltersOpts('Activities', 'Dates');
        });
      }
    });
  };

  updateFromModal = (type) => {

  };

  updateShowModal = () => {
    this.setState(state => ({showModal: !state.showModal}),
      () => {
        console.log(this.state.showModal, this.state.objSearchOrigModal);
        if(this.state.showModal){
          this.setState({objSearchOrigModal: {...this.state.objSearch} });
        } else {
          this.setState({ objSearch: {...this.state.objSearchOrigModal} });
        }
      }
    );
  };

  _updateObjSearch(id, type){
    console.log('id:', id, 'type:', type);
    let objSearch = null;
    let idObjs = [];
    console.log('this.state.objSearch['+type+']:', this.state.objSearch[type]);
    if(typeof id !== 'boolean'){
      if(this.state.objSearch[type].find(objId => objId === id) !== undefined){
        objSearch = {...this.state.objSearch, [type]: this.state.objSearch[type].filter(objId => objId !== id)};

        if(type === 'idActs'){
          const actTasksId = this.props.tasks_filter.find(activity => activity.id === id).questions.map(t => t.id);
          console.log('actTasksId:', actTasksId);
          console.log('idTasks:', objSearch.idTasks.filter(i => !actTasksId.includes(i)));
          objSearch.idTasks = objSearch.idTasks.filter(i => !actTasksId.includes(i));
        }

        let actTask = null;
        if(type === 'idTasks'){
          this.props.tasks_filter.forEach(activity => {
            activity.questions.forEach(task => {
              if(task.id === id){
                actTask = activity;
              }
            });
          });
          //console.log('objSearch.idActs:', objSearch.idActs, 'actTask:', actTask);
          const isActFound = objSearch.idActs.find(actId => actId === actTask.id) !== undefined;
          console.log('isActFound:', isActFound);
          if(isActFound){
            const actTasksId = actTask.questions.map(task => task.id);
            const tasksLength = objSearch.idTasks.filter(id =>actTasksId.includes(id));
            console.log('tasksLength:', tasksLength);
            if(tasksLength.length === 0){
              console.log('objSearch.idActs:', objSearch.idActs);
              objSearch.idActs = objSearch.idActs.filter(id => id !== actTask.id);
            }
          }
        }
      } else {
        objSearch = {...this.state.objSearch, [type]: this.state.objSearch[type].concat(id)};

        if(type === 'idActs'){
          let idTasks = [];

          this.props.tasks_filter.forEach(activity => {
            if(activity.id === id){
              idTasks = [...idTasks, ...activity.questions.map(task => task.id)];
            }
          });
          console.log('idTasks:', idTasks);

          objSearch.idTasks = objSearch.idTasks.concat(idTasks);
        }

        let actTask = null;
        if(type === 'idTasks'){
          this.props.tasks_filter.forEach(activity => {
            activity.questions.forEach(task => {
              if(task.id === id){
                actTask = activity;
              }
            });
          });
          console.log('objSearch.idActs:', objSearch.idActs, 'actTask:', actTask);
          const isActFound = objSearch.idActs.find(actId => actId === actTask.id) !== undefined;
          console.log('isActFound:', isActFound);
          if(!isActFound){
            objSearch.idActs = objSearch.idActs.concat(actTask.id);
          } else {
            const actTasksId = actTask.questions.map(task => task.id);
            console.log(objSearch.idTasks.filter(id =>actTasksId.includes(id)));
          }
        }
      }
    } else {
      if(type === 'idPars'){
        idObjs = id ? this.props.users.map(user => user.id) : [];
      } else {
        idObjs = id ? this.props.community.segments.map(segment => segment.id) : [];
      }

      objSearch = {...this.state.objSearch, [type]: idObjs};
    }

    //console.log(objSearch);
    return objSearch;
  }

  // INITS
  _initSegments = community => {
    const segments = community.segments.map(s => ({...s, isVis: true}))
    if(community.gender_as_segment){
      segments.push({id: 'Female', name: 'Female', color: '#000'}, {id: 'Male', name: 'Male', color: '#000'},
        {id: 'Other Gender', name: 'Other Gender', color: '#000'})
    }

    return segments
  }

  _initUsers(active_users){
    console.log(active_users);
    // TO-DO Why uuid here?
    return active_users.map(user => {
      const tasks = user.tasks.map(task => {
        const questions = task.questions.map(question => {
          // TO-REVIEW The user.responses have showComments too, try to find out what is necessary,
          // it looks it would be better to have that variables here
          return {...question, uuid: v4(), isTaskMinimized: false, showComments: false,
            showOriginal: false, showHiddenVerbatims: false, showConsolidated: false};
        });

        return {...task, uuid: v4(), questions}
      });

      return {...user, tasks}
    });
  }

  _initObjectsSearch = (id_Pars, id_Acts, id_Tasks) => {
    //console.log(id_Pars, id_Acts, id_Tasks);
    let idPars = [];
    let idActs =  [];
    let idTasks = [];

    if(id_Pars !== '-1'){
      idPars = id_Pars === 'all' ? this.props.users.map(user => user.id) : JSON.parse("[" + id_Pars + "]");
      idActs = id_Acts === 'all' ? this.props.tasks_filter.map(task => task.id) : JSON.parse("[" + id_Acts + "]");
      if(id_Tasks === 'all'){
        this.props.tasks_filter.forEach(activity => {
          idActs.forEach(idAct => {
            if(activity.id === idAct){
              activity.questions.forEach(task => {
                idTasks.push(task.id)
              })
            }
          });
        });
      } else {
        idTasks = JSON.parse("[" + id_Tasks + "]");
      }
    }

    //console.log('idPars:', idPars, 'idActs:', idActs, 'idTasks:', idTasks);
    return {
      idPars, idActs, idTasks, idSegs: [], idTags: [], actDates: {name: 'creation', startDate: null, endDate: null},
      resStates: {accepted: true, completed: true, draft: true, empty: true}
    };
  };
}
