import PropTypes from 'prop-types';
import React from 'react';
import Dotdotdot from "react-dotdotdot";
import TimeAgo from "react-timeago";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HelpTip from "../../../HelpTip";

export default class AL_ActivityCard01 extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    activeTaskId: PropTypes.number,
    updateActiveTaskId: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    formatter: PropTypes.func.isRequired,
    sort_coll: PropTypes.string.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    cUsers: PropTypes.number.isRequired,
    updateLive: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    helpStores: PropTypes.object,
    hasLiveActivities: PropTypes.bool,
    hasTasks: PropTypes.bool,
    communityId: PropTypes.number,
    userId: PropTypes.number,
    isFirstItem: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      helpStores: this.props.helpStores
    }
  }

  render() {
    const {task, activeTaskId, sort_coll, locale, formatter, cUsers, language, colors,
      communityId, userId, isFirstItem} = this.props;

    const i18n = {
      en: {
        liveTooltip: 'Participants can see this Activity', liveOffTooltip: 'Participants cannot see this Activity yet',
        participants: ' participants can see this Activity', tasks: ' tasks in this Activity',
        display01: 'Participants may see each other\'s responses and comments for this Activity',
        display02: 'Participants won\'t be able to see each other\'s responses and comments for this Activity',
        deleteActivity: 'Delete Activity', cloneActivity: 'Clone Activity', expired: 'Expired', neverExpires: 'Never Expires',
        expires: 'Expires ', liveActivitiesTip: 'Remember to click LIVE to make it visible to participants.'
      },
      es: {
        liveTooltip: 'Los participantes pueden ver esta Actividad',
        liveOffTooltip: 'Los participantes todavía no pueden ver esta Actividad',
        participants: ' participantes pueden ver esta Actividad', tasks: ' tareas en esta Actividad',
        display01: 'Los participantes pueden ver las respuestas y comentarios de los demás para esta Actividad',
        display02: 'Los participantes no podrán ver las respuestas y comentarios de los demás para esta Actividad',
        deleteActivity: 'Eliminar Actividad', cloneActivity: 'Clonar Actividad', expired: 'Expirada', neverExpires: 'Nunca Expira',
        expires: 'Expira ', liveActivitiesTip: 'Recuerda hacer clic en LIVE para mostrar a los participantes.'
      }
    }

    const cardColor = activeTaskId === task.id ? 'complete' : '';
    let cardExpire;
    //console.log('sort_coll:', sort_coll);
    if(sort_coll !== 'start date'){
      if(task.expire){
        //console.log('Custom');
        if(moment().format('YYYY-MM-DD') > task.expire){
          //console.log('Expired', task.expire, task.title);
          cardExpire = i18n[language].expired; 
        } else {
          const nowPlus7DD = moment().add(7, 'days');

          if(task.expire > nowPlus7DD.format('YYYY-MM-DD')){
            //console.log('Show Date:', task.expire, nowPlus7DD.format('YYYY-MM-DD'), task.title);
            cardExpire = i18n[language].expires + moment(task.expire).locale(locale).format("ll");
          } else {
            const expireDate = moment(task.expire, 'YYYY-MM-DD').add(1, 'days');
            //console.log('Show days to expire', task.expire, nowPlus7DD.format('YYYY-MM-DD'), task.title);
            cardExpire = <TimeAgo date={expireDate} formatter={formatter}/>;
          }
        }
      } else {
        //console.log('Never Expires', task.title);
        cardExpire = i18n[language].neverExpires;
      }
    } else {
      if(task.start){
        if(moment().format('YYYY-MM-DD') > task.start){
          //console.log('Expired', task.start, task.title);
          cardExpire = i18n[language].started;
        } else {
          const nowPlus7DD = moment().add(7, 'days');
          if(task.start > nowPlus7DD.format('YYYY-MM-DD')){
            //console.log('Show Date', task.expire, nowPlus7DD.format('YYYY-MM-DD'), task.title);
            cardExpire = i18n[language].starts + moment(task.start).locale(locale).format("ll");
          } else {
            const startDate = moment(task.start, 'YYYY-MM-DD').add(1, 'days');
            //console.log('Show days to expire', task.expire, nowPlus7DD.format('YYYY-MM-DD'), task.title);
            cardExpire = <TimeAgo date={startDate} formatter={formatter}/>;
          }
        }
      } else {
        cardExpire = i18n[language].noStart;
      }
    }

    return (
      <div className={"px-activity-card "+ cardColor} onClick={() => this.props.updateActiveTaskId(task.id)}
        style={{ borderLeftColor: cardColor === 'complete' ? colors.color2[3] : '#dadde1'}}>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
           <div className="px-actv-title" data-tooltip-content={task.title}>{task.title}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-actv-expire">{ cardExpire }</div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <FontAwesomeIcon icon={['fas', task.social === 'Public' ? 'eye' : 'eye-slash']} className="px-actv-icon highlight" 
              data-tooltip-content={[task.social === 'Public' ? i18n[language].display01 : i18n[language].display02]} />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-actv-stats-row">
              <div className="px-item-info" data-tooltip-content={task.questions.length + i18n[language].tasks}>
                <FontAwesomeIcon icon={['fas', 'image']} className="px-actv-icon"/>
                <span className="px-number">{task.questions.length}</span>
              </div>
              <div className="px-item-info" data-tooltip-content={cUsers + i18n[language].participants} >
                <FontAwesomeIcon icon={['fas', 'users']} className="px-actv-icon"/>
                <span className="px-number">{cUsers}</span>
                <HelpTip
                  showRequisites={isFirstItem && !this.props.hasLiveActivities}
                  tipKey="liveActivitiesTip"
                  text={i18n[language].liveActivitiesTip}
                  colors={colors}
                  communityId={communityId}
                  userId={userId}
                  initialHelpStores={this.state.helpStores}
                  arrowDirection={'up'}
                  placement={''}
                  bottom={'-80px'}
                  right={'-22px'}
                  arrowOffset={'70px'}
                />
              </div>
              <button onClick={() => this.props.updateLive(task.id)} className={task.visibility === 'enabled' ?
                "btn px-btn xs btn-orange-base" : "btn px-btn xs btn-gray-base"} 
                style={task.visibility === 'enabled' ? { backgroundColor: colors.color4[3] } : { backgroundColor:'#afbdc1'} }
                data-tooltip-content={task.visibility === 'enabled' ? i18n[language].liveTooltip : i18n[language].liveOffTooltip}>
                <span>LIVE</span>
              </button>
            </div>
          </div>
        </div>
        <div className="actions-container" style={{height: '50px'}}>
          <FontAwesomeIcon style={{height: 'auto', fontSize:'16px'}}
                           onClick={() => this.props.updateShowModal('delete act', -1)}
                           icon={['fas', 'trash']}
                           data-tooltip-content={i18n[language].deleteActivity} />
          <FontAwesomeIcon style={{height: 'auto', fontSize:'16px'}}
                           onClick={() => this.props.updateShowModal('copy act', -1)}
                           icon={['fas', 'copy']}
                           data-tooltip-content={i18n[language].cloneActivity}
                           className="clone"/>
        </div>
      </div>
    );
  }
}