import PropTypes from 'prop-types';
import React from 'react';
import Lightbox from "react-images";
import ReactModal from "react-modal";
import T_ModalVideo from "../../../Transcript/T_ModalVideo";
import {taskI18n} from "../../../../UI/Globals/PX_i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AVTChAnswer from "./AVTChAnswer";
import Comments from "../../../../../containers/Community/Comments";
import AVTModal from "./AVTModal";

export default class AVTChoice extends React.Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    cardinal: PropTypes.number.isRequired,
    participants_view: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    questionsLength: PropTypes.number.isRequired,
    blockerExpired: PropTypes.bool.isRequired,
    updateChoiceItems: PropTypes.func.isRequired,
    saveResponse: PropTypes.func.isRequired,
    updateShowComments: PropTypes.func.isRequired,
    updateResponseComments: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    scrollFirstTime: PropTypes.bool.isRequired,
    updateScrollFirstTime: PropTypes.func.isRequired,
    response: PropTypes.object,
    scroll: PropTypes.string,
    item_scroll: PropTypes.object,
    from: PropTypes.string,
    isSavingResponse: PropTypes.bool,
    helpStores: PropTypes.object.isRequired,
    updateHelpStore: PropTypes.func.isRequired,
    updateBackdrop: PropTypes.func,
    backdropVisibility: PropTypes.bool,
    comunitySocial: PropTypes.bool,
    taskSocialStatus: PropTypes.string.isRequired,
    company: PropTypes.object,
    aiAgentName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      showModalQuestionVideo: false,
      showModalValidation: false,
      validationResults: [],
      lBIsOpenR: false,
      currentImageR: 0,
      showModalVideo: false,
      ansNoImages: null,
      showLBAnswers: false,
      showSaveHelpPopup: false 
    };
  }

  componentDidMount(){
    const justImg = this.props.question.data.answers.map(answer => answer.img !== undefined ?
      answer.blobPreview ? answer.blobPreview : answer.img : '');
    //console.log('componentDidMount', justImg);
    this.setState({ansNoImages: justImg.filter(item => item !== '').length === 0})
  }

  componentDidUpdate(prevProps){
    //console.log('AVTChoice.componentDidUpdate');
    const {item_scroll, userId, response, scrollFirstTime, participants_view, question} = this.props;
    (this.props.backdropVisibility === false && this.state.showSaveHelpPopup === true) && this.setState({ showSaveHelpPopup: false});
    
    if(prevProps.response !== response){
      if(scrollFirstTime && item_scroll){
        const scrollAnchor = $('#' + userId + '_' + item_scroll.id);
        this.props.updateScrollFirstTime(false);

        if(scrollAnchor.offset() !== undefined && item_scroll.type === 'response' || item_scroll.type === 'likeResponse'){
          const cardPosition = scrollAnchor.offset().top;
          const offset = participants_view === 'As a list' ? 50 : 75;
          (window).scrollTo({top: cardPosition - offset, behavior: 'smooth'});
        } else {
          //console.log(question.id, item_scroll.taskId);
          if(question.id === item_scroll.taskId){
            this.props.updateShowComments(question.id)
          }
        }
      }
    }
  };

  render() {
    const {question, cardinal, participants_view, userId, language, questionsLength, blockerExpired,
      response, scrollFirstTime, item_scroll, from, isSavingResponse, colors, comunitySocial,
      taskSocialStatus, company} = this.props;
    //console.log(response);
    const i18n = {
      en: {
        taskPrivate: {
          saveHelpTooltip: "Saving will preserve your drafted response.",
          doneHelpTooltip: "Clicking here will save your task and notify moderators that you have finished.",
          commentsHelpTooltip: "Anything to say to the moderators about this tasks? Post a comment here any time."
        },
        taskPublic: {
          saveHelpTooltip: "Saving will preserve your drafted response but other participants won't be able to see it.",
          doneHelpTooltip: "Clicking here will notify moderators that you have finished and other participants may be able to see your response.",
          commentsHelpTooltip: "Anything to say to the moderators or other participants about this tasks? Post a comment here any time."
        },
        taskCompleted: 'You have completed this task', taskExpired: 'This task has expired and you can\'t edit it anymore',
        othersOption: "Other, specify here", comments: "COMMENTS",
        savingResponse: 'Wait, saving responses'
      },
      es: {
        taskPrivate: {
          saveHelpTooltip: "Recuerda guardar tu avance dando click aquí.",
          doneHelpTooltip: "Cuando estés listo da click aquí para notificar a los moderadores que has terminado esta tarea.",
          commentsHelpTooltip: "Algo que decir a los moderadores sobre esta tarea? Commenta aquí."
        },
        taskPublic: {
          saveHelpTooltip: "Guarda tu avance aquí tomando en cuenta que mientras no completes la respuesta otros participantes no podrán verla.",
          doneHelpTooltip: "Cuando estés listo da click aquí para notificar a los moderadores y a otros participantes que has completado esta tarea.",
          commentsHelpTooltip: "Algo que decir a los moderadores o a otros participantes sobre esta tarea? Commenta aquí."
        },
        taskCompleted: 'Has completado esta tarea', taskExpired: 'Esta tarea ha expirado y no se puede editar',
        othersOption: "Otra opción, escribe aquí", comments: "COMENTARIOS",
        savingResponse: 'Espera, guardando respuestas'
      }
    };

    // let saveHelpTooltip = null;
    let saveHelpPopupClass = this.state.showSaveHelpPopup === true && 'open';
    let saveHelpMessage = comunitySocial && taskSocialStatus === 'Public' ? taskI18n[language].saveHelp2Social : taskI18n[language].saveHelp2
    // let doneHelpTooltip = null;
    let commentsHelpTooltip = null;
    let responseButtons = 
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-buttons-response">

    {!isSavingResponse && <div className={`dropup ${saveHelpPopupClass}`} style={{display: 'inline'}}>
        <button onClick={from !== 'AB Preview' ? () => this.saveAndNotify('Draft', question.id) : () => {
        }}
                className="btn px-btn sm btn-save-test" disabled={isSavingResponse}>
          {!isSavingResponse ? taskI18n[language].save_btn : <FontAwesomeIcon icon={['fas', 'spinner']} spin/>}
          <div className="ripple-container"/>
          {/* <div className="px-support-tooltip top is_hidden px-support-response"><p>{saveHelpTooltip}</p><i/></div> */}
        </button>
        <div className="dropdown-menu dropdown-menu px-drop-menu btn-save-popup">
          {/* <FontAwesomeIcon onClick={console.log('click')} className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal" icon={['fas', 'xmark']} style={{ height: '15px', width: '15px' }} />    */}
          <span style={{color: colors.color4[3]}}>{taskI18n[language].saveHelp1} </span>{saveHelpMessage}
          <div className="clickable" style={{color: colors.color1[3]}}><span
            onClick={() => this.stopSaveHelpPopup()}>{taskI18n[language].dontShowAgain}</span></div>
        </div>
      </div>}
      
    <button onClick={from !== 'AB Preview' ? this.validationCompleted : () => {}}
            className={`btn px-btn sm btn-turquoise-base ${isSavingResponse ? '' : 'mg-left-15'}`}
          style={this.state.showSaveHelpPopup === true ? { backgroundColor: colors.color2[3], position: 'absolute', zIndex: '1019' } : { backgroundColor: colors.color2[3] } }
          disabled={isSavingResponse}>{!isSavingResponse && taskI18n[language].complete_btn}
          { !isSavingResponse ? cardinal < questionsLength && 
            <FontAwesomeIcon icon={['fas', 'circle-chevron-right']} style={{ marginLeft: '5px'}}/> :
            <span style={{color: '#000000'}}>{i18n[language].savingResponse}<FontAwesomeIcon icon={['fas', 'spinner']} spin style={{ marginLeft: '5px' }}/></span>
          }
      <div className="ripple-container"/>
      {/* <div className="px-support-tooltip right is_hidden px-support-response" style={{top:'-30px', left:'100px'}}>
        <p>{doneHelpTooltip}</p>
        <i/>
      </div> */}
    </button>
    </div>;
    let btnComments = null;
    let commentsComp = null;
    let responseLikes = null;

    //console.log(question.data);
    const answers = question.data.answers.map((answer, index) => {
      //const image = answer.img !== '' ? answer.img : '/assets/placeholder.png';
      if(question.data.focusOn === 'Text'){
        if(question.data.subtype === 'Single'){
          if(answer.img !== undefined){
            let checked = false;
            if(response){
              if(response.selectedItemsId[0]){
                if(response.selectedItemsId[0].id === answer.id){
                  checked = true;
                }
              }
            }

            return(
              <AVTChAnswer key={answer.id}
                           type={'Single'}
                           question={question}
                           answer={answer}
                           updateChoiceItems={this.props.updateChoiceItems}
                           checked={checked}
                           ansNoImages={this.state.ansNoImages}
                           responseState={response && response.response ? response.response.state  : ''}
                           showBlocker={response ? response.response ?
                             blockerExpired ? blockerExpired : response.showBlocker : blockerExpired : false}
                           index={index}
                           openLightBox={this.openLightBox}
                           isCompleted={response ? response.response ? response.response.state : "0" : '0'}
                           colors={colors}/>
            )
          } else {
            let other = '';
            let checked = false;
            //console.log('Other Single Text', response);
            if(response !== undefined){
              if(response.selectedItemsId[0]){
                other = response.selectedItemsId[0].other;
                if(response.selectedItemsId[0].id === answer.id){
                  checked = other !== '';
                }
              }
            }
            const optEditRdo = <div className="px-check-cont text-focus">
              <input onChange={(event) => this.props.updateChoiceItems(event, question.id, question.data.subtype,
                answer.id)} value={other} type="text" placeholder={i18n[language].othersOption} name="other"
                className="input-choice text-focus" style={{ marginLeft: 0, width: '90%', fontSize: '14px', color: '#262e37', letterSpacing: '1px'}}/>
              <label className="container-check choice radio">
                <input type="radio" name={"answer_item_rdo" + answer.id} onChange={(event) =>
                  this.props.updateChoiceItems(event, question.id, question.data.subtype, answer.id)}
                       checked={checked}/>
                <span className="checkmark radio choice"/>
              </label>
            </div>;
            const optBlockedRdo = <div className="px-check-cont text-focus">
              <input value={other} type="text" placeholder={i18n[language].othersOption} readOnly={true}
                className="input-choice text-focus" style={{ backgroundColor: 'transparent', border: 'none', borderRadius: '0', fontSize: '14px', color: '#262e37', letterSpacing: '1px' }}/>
              <label className="container-check choice" style={{cursor:'default'}}>
                <input type="checkbox" defaultChecked={checked} disabled={true}/>
                <span className="checkmark choice"/>
              </label>
            </div>;

            if(response){
              if(response.response){
                if((response.response.state === 'Completed' || response.response.state !== 'Empty') && response.showBlocker){
                  return(
                    <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                      style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
                      { checked ? optBlockedRdo : '' }
                    </div>
                  )
                } else {
                  //console.log('Text Single, response Draft');
                  return(
                    <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                      style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
                      { blockerExpired ? optBlockedRdo : optEditRdo }
                    </div>
                  )
                }
              } else {
                return(
                  <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                    style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
                    { blockerExpired ? checked && optBlockedRdo : optEditRdo }
                  </div>
                )
              }
            } else {
              return(
                <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                  style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
                  { optEditRdo }
                </div>
              )
            }
          }
        } else {
          // Text - Multiple
          if(answer.img !== undefined){
            let checked = false;
            if(response){
              checked = response.selectedItemsId.find(item => item.id === answer.id) !== undefined;
            }
            return(
              <AVTChAnswer key={answer.id}
                           type={'Multiple'}
                           question={question}
                           answer={answer}
                           updateChoiceItems={this.props.updateChoiceItems}
                           checked={checked}
                           ansNoImages={this.state.ansNoImages}
                           responseState={response && response.response ? response.response.state  : ''}
                           showBlocker={response ? response.response ?
                             blockerExpired ? blockerExpired : response.showBlocker : blockerExpired : false}
                           index={index}
                           openLightBox={this.openLightBox}
                           isCompleted={response ? response.response ? response.response.state : "0" : '0'}
                           colors={colors}/>
            )
          } else {
            let other = '';
            let checked = false;
            if(response){
              const item = response.selectedItemsId.find(item => item.id === answer.id);
              other = item !== undefined ? item.other : '';
              checked = item !== undefined ? item.other !== '' : false;
            }

            const optEditChk = <div className="px-check-cont text-focus">
              <input onChange={(event) => this.props.updateChoiceItems(event, question.id, question.data.subtype,
                answer.id)} value={other} type="text" placeholder={i18n[language].othersOption} name="other"
                className="input-choice text-focus" style={{ fontSize: '14px', color: '#262e37', letterSpacing: '1px' }}/>
              <label className="container-check choice ">
                <input type="checkbox" onChange={(event) => this.props.updateChoiceItems(event, question.id,
                  question.data.subtype, answer.id)} name="answer_item" checked={checked}/>
                <span className="checkmark choice"/>
              </label>
            </div>;
            const optBlockedChk = <div className="px-check-cont text-focus">
              <input value={other} type="text" placeholder={i18n[language].othersOption} readOnly={true}
                className="input-choice text-focus" style={{ backgroundColor: 'transparent', border: 'none', borderRadius: '0', fontSize: '14px', color: '#262e37', letterSpacing: '1px' }}/>
              <label className="container-check choice" style={{cursor:'default'}}>
                <input type="checkbox" defaultChecked={checked} disabled={true}/>
                <span className="checkmark choice"/>
              </label>
            </div>;

            if(response){
              if(response.response){
                if((response.response.state === 'Completed' || response.response.state !== 'Empty') && response.showBlocker){
                  return(
                    <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                      style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
                      { checked && optBlockedChk }
                    </div>
                  )
                } else {
                  return(
                    <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                      style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
                      { blockerExpired ? checked && optBlockedChk : optEditChk }
                    </div>
                  )
                }
              } else {
                return(
                  <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                    style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
                    { blockerExpired ? checked && optBlockedChk : optEditChk }
                  </div>
                )
              }
            } else {
              return(
                <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                  style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
                  { optEditChk }
                </div>
              )
            }
          }
        }
      } else {
        // FocusOn IMAGES
        if(question.data.subtype === 'Single'){
          if(answer.img !== undefined){
            let checked = false;

            if(response){
              if(response.selectedItemsId[0]){
                if(response.selectedItemsId[0].id === answer.id){
                  checked = true;
                }
              }
            }

            return(
              <AVTChAnswer key={answer.id}
                           type={'Single'}
                           question={question}
                           answer={answer}
                           updateChoiceItems={this.props.updateChoiceItems}
                           checked={checked}
                           ansNoImages={this.state.ansNoImages}
                           responseState={response && response.response ? response.response.state  : ''}
                           showBlocker={response ? response.response ?
                             blockerExpired ? blockerExpired : response.showBlocker : blockerExpired : false}
                           index={index}
                           openLightBox={this.openLightBox}
                           isCompleted={response ? response.response ? response.response.state : "0" : '0'}
                           colors={colors}/>
            )
          } else {
            let other = '';
            let checked = false;
            if(response){
              if(response.selectedItemsId[0]){
                other = response.selectedItemsId[0].other;
                if(response.selectedItemsId[0].id === answer.id){
                  checked = true;
                }
              }
            }

            const optEditImgRdo = <div className={checked ? 'choice-item active' : "choice-item"}>
              <div className="img-container">
                <img src={'/assets/placeholder.png'} alt="" className="" />
                <div className="check-container">
                  <div className="px-check-cont choice-img">
                    <label className="container-check radio choice-img">
                      <input onChange={(event) => this.props.updateChoiceItems(event, question.id, question.data.subtype,
                        answer.id)} type="radio" checked={checked}/>
                      <span className="checkmark radio choice-img"/>
                    </label>
                  </div>
                </div>
              </div>
              <textarea onChange={(event) => this.props.updateChoiceItems(event, question.id, question.data.subtype,
                answer.id)} value={other} placeholder={i18n[language].othersOption} name="other" className="input-choice"
                style={{ marginTop: '10px', fontSize: '14px', color: '#262e37', letterSpacing: '1px'}}/>
            </div>;
            const optBlockedImgRdo = <div className="px-check-cont text-focus">
              <input value={other} type="text" placeholder={i18n[language].othersOption} readOnly={true}
                className="input-choice text-focus" style={{ backgroundColor: 'transparent', border: 'none', borderRadius: '0', fontSize: '14px', color: '#262e37', letterSpacing: '1px' }}/>
              <label className="container-check choice" style={{cursor:'default'}}>
                <input type="checkbox" defaultChecked={checked} disabled={true}/>
                <span className="checkmark choice"/>
              </label>
            </div>;

            if(response){
              if(response.response) {
                if((response.response.state === 'Completed' || response.response.state !== 'Empty') && response.showBlocker) {
                  return(
                    <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                      style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
                      { checked && optBlockedImgRdo }
                    </div>
                  )
                } else {
                  return(
                    <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}>
                      { blockerExpired ? checked && optBlockedImgRdo : optEditImgRdo }
                    </div>
                  )
                }
              } else {
                return(
                  <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}>
                    { blockerExpired ? checked && optBlockedImgRdo : optEditImgRdo }
                  </div>
                )
              }
            }else {
              return(
                <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}>
                  { optEditImgRdo }
                </div>
              )
            }
          }
        } else {
          // Images - Multiple
          if(answer.img !== undefined){
            let checked = false;
            if(response){
              checked = response.selectedItemsId.find(item => item.id === answer.id) !== undefined;
            }
            return(
              <AVTChAnswer key={answer.id}
                           type={'Multiple'}
                           question={question}
                           answer={answer}
                           updateChoiceItems={this.props.updateChoiceItems}
                           checked={checked}
                           ansNoImages={this.state.ansNoImages}
                           responseState={response && response.response ? response.response.state  : ''}
                           showBlocker={response ? response.response ?
                             blockerExpired ? blockerExpired : response.showBlocker : blockerExpired : false}
                           index={index}
                           openLightBox={this.openLightBox}
                           isCompleted={response ? response.response ? response.response.state : "0" : '0'}
                           colors={colors}/>
            )
          } else {
            let other = '';
            let checked = false;
            if(response){
              const item = response.selectedItemsId.find(item => item.id === answer.id);
              other = item !== undefined ? item.other : '';
              checked = item !== undefined ? item.other !== '' : false;
            }

            const optEditImgChk = <div className={checked ? 'choice-item active' : "choice-item"}>
              <div className="img-container">
                <img src={'/assets/placeholder.png'} alt="" className="" />
                <div className="check-container">
                  <div className="px-check-cont choice-img">
                    <label className="container-check radio choice-img">
                      <input onChange={(event) => this.props.updateChoiceItems(event, question.id, question.data.subtype,
                        answer.id)} type="radio" checked={checked}/>
                      <span className="checkmark radio choice-img"/>
                    </label>
                  </div>
                </div>
              </div>

              <textarea onChange={(event) => this.props.updateChoiceItems(event, question.id, question.data.subtype,
                answer.id)} value={other} placeholder={i18n[language].othersOption} name="other"
                className="input-choice" style={{ marginTop: '10px', fontSize: '14px', color: '#262e37', letterSpacing: '1px'}}/>
            </div>;
            const optBlockedImgChk = <div className="px-check-cont text-focus">
              <input value={other} type="text" placeholder={i18n[language].othersOption} readOnly={true}
                className="input-choice text-focus" style={{ backgroundColor: 'transparent', border: 'none', borderRadius: '0', fontSize: '14px', color: '#262e37', letterSpacing: '1px' }}/>
              <label className="container-check choice" style={{cursor:'default'}}>
                <input type="checkbox" defaultChecked={checked} disabled={true}/>
                <span className="checkmark choice"/>
              </label>
            </div>;

            if(response){
              if(response.response) {
                if((response.response.state === 'Completed' || response.response.state !== 'Empty') && response.showBlocker) {
                  return(
                    <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}
                      style={{ backgroundColor: (checked || this.state.active) && colors.color0[6] }}>
                      { checked && optBlockedImgChk }
                    </div>
                  )
                } else {
                  return(
                    <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}>
                      { blockerExpired ? checked && optBlockedImgChk : optEditImgChk }
                    </div>
                  )
                }
              } else {
                return(
                  <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}>
                    { blockerExpired ? checked && optBlockedImgChk : optEditImgChk }
                  </div>
                )
              }
            } else{
              return(
                <div key={answer.id} className={(checked || this.state.active) ? 'px-option-item active' : 'px-option-item'}>
                  { optEditImgChk }
                </div>
              )
            }
          }
        }
      }
    });

    let answerComp = <div className={question.data.focusOn === 'Text' ? "px-options-container wd-100" : 'px-choice-image-cont'}>
      { answers }
    </div>;

    if(response !== undefined){
      let sumAllCommentsReplies = 0;
      response.comments ?
        response.comments.forEach(c => {++sumAllCommentsReplies;c.replies.forEach(r => {++sumAllCommentsReplies})}) : 0;

      btnComments = <button onClick={from !== 'AB Preview' ? () => this.props.updateShowComments(question.id) : () => {}}
        type="button" className="btn btn-comments" 
        style={ response.showComments ?
          { color: '#ffffff', borderColor: colors.color1[3], backgroundColor: colors.color1[3] } : 
          { color: colors.color1[3], borderColor: colors.color1[3], backgroundColor: '#ffffff' } }>
        <div className="comments-container">
          {response.sum_unseen > 0 && <div className="unseen" style={{ backgroundColor: colors.color4[3] }} />}
          <FontAwesomeIcon icon={['fas', 'message']} className="icon-comments" style={{ fontSize: '10px', color: response.showComments ? '#ffffff' : colors.color1[3]}}/>
          <span className="txt-comment" style={{marginLeft:'0'}}>{i18n[language].comments}</span>
          <div className="number-container">
            <span className="number">{sumAllCommentsReplies}{/*response.comments ? response.comments.length : 0*/}</span>
          </div>
        </div>
        <div className="px-support-tooltip top is_hidden px-support-response" style={{}}>
          <p>{commentsHelpTooltip}</p><i/>
        </div>
      </button>;
      commentsComp = <div className={response.showComments ? 'px-n-accordion-open' : 'px-n-accordion-close'}
                          style={{paddingBottom:'10px'}}>  
        <Comments comments={response.comments ? response.comments : []}
                  language={language}
                  userId={userId}
                  username={''}
                  questionId={response.question_id}
                  responseId={response.response ? response.response.id : -1}
                  role_1={'Participant'}
                  updateResponseComments={this.props.updateResponseComments}
                  from={'PAV'}
                  item_scroll={item_scroll}
                  scrollFirstTime={scrollFirstTime}
                  updateScrollFirstTime={this.props.updateScrollFirstTime}
                  colors={colors}
                  aiAgentName={this.props.aiAgentName}
                  company={company}
        />
      </div>;

      // RESPONSE COMPLETED
      if(response.response){
        //console.log('response.response.state:', response.response.state, 'response.showBlocker:', response.showBlocker);
        if((response.response.state === 'Completed' || response.response.state !== 'Empty' ) && response.showBlocker){
          responseButtons = <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <button onClick={() => this.props.updateShowBlocker(question.id)}
              className="btn px-btn sm btn-blue-base" style={{ width: '120px', backgroundColor: colors.color1[3]}}>{taskI18n[language].edit_btn}
              <div className="ripple-container"/></button>
          </div>;

          answerComp = <div className="panel activity-response-completed-container" style={{marginBottom:'20px'}}>
            {
              !blockerExpired &&
              <div className="panel-heading" style={{ backgroundColor: colors.color3[5] }}>
                <h3 className="panel-title">{i18n[language].taskCompleted}</h3>
              </div>
            }
            <div className="panel-body">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className={"px-options-container wd-100"} style={{borderTop: '0'}}>
                    { answers }
                  </div>
                </div>
              </div>
            </div>
          </div>
        } else {
          if(blockerExpired){
            answerComp = <div className="panel activity-response-completed-container" style={{marginBottom:'20px'}}>
              <div className="panel-body">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className={"px-options-container wd-100"}>
                      { answers }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        }
      } else {
        if(blockerExpired){
          answerComp = <div className="panel activity-response-completed-container" style={{marginBottom:'20px'}}>
            <div className="panel-body">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className={"px-options-container wd-100"} style={{borderTop: '0'}}>
                    { answers }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      }

      responseLikes = response.likes;
    }

    const justImages = question.data.answers.filter(answer => answer.img !== undefined);
    //console.log(response);
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
        {
          participants_view === 'As a list' &&
          <div className="px-counter-task" style={{ backgroundColor: colors.color0[1] }}><span className="number">{cardinal}</span></div>
        }
        <div id={userId + '_' + question.id} className="panel px-card px-panel-task response">
          <div className="panel-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <div dangerouslySetInnerHTML={{__html: question.title}} className="title"/>
              </div>
            </div>
            {
              (question.attachment.length !== 0 || question.video_thumbnail !== '' || question.blobPreview) &&
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-answer-media-container">
                    {
                      question.blobPreview ?
                        <div className="px-media-img">
                          <FontAwesomeIcon onClick={() => this.setState({lightboxIsOpen: true})} icon={['far','up-right-and-down-left-from-center']}
                                           size='lg' style={{position:'absolute', left:'102px', top: '5px',
                            backgroundColor:'white',padding: '0 2px', borderRadius: '4px', cursor: 'pointer'}}/>
                          <img onClick={() => this.setState({lightboxIsOpen: true})} src={question.blobPreview}
                               style={{cursor:'pointer'}} alt=""/>
                        </div> :
                        question.attachment.length !== 0 &&
                        <div className="px-media-img">
                          <FontAwesomeIcon onClick={() => this.setState({lightboxIsOpen: true})} icon={['far','up-right-and-down-left-from-center']}
                                           size='lg' style={{position:'absolute', left:'102px', top: '5px',
                            backgroundColor:'white',padding: '0 2px', borderRadius: '4px', cursor: 'pointer'}}/>
                          <img onClick={() => this.setState({lightboxIsOpen: true})} src={question.attachment[0].url}
                               style={{cursor:'pointer'}} alt=""/>
                        </div>
                    }
                    {
                      question.video_thumbnail !== '' &&
                      <div className="px-media-img">
                        <FontAwesomeIcon onClick={this.updateShowModalQuestionVideo} icon={['far','up-right-and-down-left-from-center']}
                                         size='lg' style={{position:'absolute', left:'102px', top: '5px',
                          backgroundColor:'white',padding: '0 2px', borderRadius: '4px', cursor: 'pointer'}}/>
                        <img onClick={this.updateShowModalQuestionVideo} src={question.video_thumbnail}
                             style={{cursor:'pointer'}} alt=""/>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {
                  blockerExpired ?
                    <div className="panel activity-response-completed-container" style={{marginBottom:'0'}}>
                      <div className="panel-heading" style={{ backgroundColor: blockerExpired ? colors.color4[6] : colors.color3[5] }}>
                        <h3 className="panel-title" style={{ color: blockerExpired ? colors.color4[3] : ''}}>
                          {blockerExpired ? i18n[language].taskExpired : i18n[language].taskCompleted}</h3>
                      </div>
                      { answerComp }
                    </div> :
                  answerComp
                }
              </div>
            </div>

            <div className="row">
              { !blockerExpired && responseButtons }
            </div>
            <div className="separator separator-response" />
            <div className="comments-option-container">
              { btnComments }
            </div>
            { commentsComp }
          </div>
        </div>
        {
          question.blobPreview ?
            <Lightbox currentImage={this.state.currentImage}
                      images={[{src: question.blobPreview}]}
                      isOpen={this.state.lightboxIsOpen}
                      onClose={() => this.closeLightbox('Question')}/> :
            question.attachment.length !== 0 &&
            <Lightbox currentImage={this.state.currentImage}
                      images={[{src: question.attachment.length !== 0 && question.attachment[0].url}]}
                      isOpen={this.state.lightboxIsOpen}
                      onClose={() => this.closeLightbox('Question')}/>
        }
        {
          question.data.answers.length !== 0 &&
          <Lightbox currentImage={this.state.currentImageR}
                    images={justImages.map(answer => ({src: answer.blobPreview ?
                        answer.blobPreview :answer.img !== '' ? answer.img : '/assets/placeholder.png'}))}
                    isOpen={this.state.lBIsOpenR}
                    onClickPrev={this.gotoPrevious}
                    onClickNext={this.gotoNext}
                    onClickThumbnail={this.gotoImage}
                    onClose={() => this.closeLightbox('Question Answers')}/>
        }
        {
          question.video_thumbnail !== '' &&
          <ReactModal isOpen={this.state.showModalQuestionVideo} contentLabel="Response Video"
                      shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalQuestionVideo}
                      className="my-modal-content" overlayClassName="my-modal-overlay">
            <T_ModalVideo updateShowModalVideo={this.updateShowModalQuestionVideo}
                          thumbnail={question.video_thumbnail}
                          url={question.video_url}
                          language={language}
                          colors={colors}/>
          </ReactModal>
        }
        {
          this.state.showModalValidation &&
          <ReactModal isOpen={this.state.showModalValidation} contentLabel="Activity Validation Modal"
                      shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalValidation}
                      className="my-modal-content" overlayClassName="my-modal-overlay">
            <AVTModal updateShowModal={this.updateShowModalValidation}
                      required_options={[]}
                      minChar={''}
                      validationResults={this.state.validationResults}
                      type={question.task_type}
                      language={language}/>
          </ReactModal>
        }
      </div>
    );
  }

  saveAndNotify = (state, question_id, user_id, cardinal) => {
    this.props.saveResponse(state, question_id);
    if (this.props.helpStores.showSaveHelp === true) {
      this.props.updateBackdrop(true);
      this.setState({ showSaveHelpPopup: true });
    }
  }

  stopSaveHelpPopup = () => {
    this.setState({ showSaveHelpPopup: false });
    this.props.updateBackdrop(false);
    this.props.updateHelpStore('showSaveHelp', false);
  }

  validationCompleted = () => {
    this.props.updateBackdrop(false);
    const {question, response} = this.props;
    //console.log(response.selectedItemsId);
    if(question.required) {
      if(this.props.from !== 'AB Preview') {
        if(response.selectedItemsId.length !== 0) {
          if(question.data.subtype === 'Single') {
            const item = response.selectedItemsId[0];
            const answer = question.data.answers.find(ans => ans.id === item.id);
            // console.log(item, answer);
            if(answer.img === undefined) {
              if (item.other === '') {
                this.updateShowModalValidation();
              } else {
                this.props.saveResponse('Completed', this.props.question.id, this.props.userId, this.props.cardinal);
              }
            } else {
              this.props.saveResponse('Completed', this.props.question.id, this.props.userId, this.props.cardinal);
            }
          } else {
            if(response.selectedItemsId.length === 1) {
              const item = response.selectedItemsId[0];
              const answer = question.data.answers.find(ans => ans.id === item.id);
              // console.log(item, answer);
              if (answer.img === undefined) {
                if (item.other === '') {
                  this.updateShowModalValidation();
                } else {
                  this.props.saveResponse('Completed', this.props.question.id, this.props.userId, this.props.cardinal);
                }
              } else {
                this.props.saveResponse('Completed', this.props.question.id, this.props.userId, this.props.cardinal);
              }
            } else {
              this.props.saveResponse('Completed', this.props.question.id, this.props.userId, this.props.cardinal);
            }
          }
        } else {
          this.updateShowModalValidation();
        }
      }
    } else {
      //console.log('FREE to validate');
      if(this.props.from !== 'AB Preview'){
        this.props.saveResponse('Completed', this.props.question.id, this.props.userId, this.props.cardinal);
      }
    }
  };

  updateShowModalValidation = () => {
    this.setState(state => ({showModalValidation: !state.showModalValidation}));
  };

  openLightBox = (index) => {
    // console.log('index', index);
    this.setState({currentImageR: index, lBIsOpenR: true});
  };
  closeLightbox = (type) => {
    if(type === 'Question'){
      this.setState({currentImage: 0, lightboxIsOpen: false});
    } else {
      this.setState({currentImageR: 0, lBIsOpenR: false});
    }
  };
  gotoPrevious = () => {
    this.setState({currentImageR: this.state.currentImageR - 1});
  };
  gotoNext = () => {
    this.setState({currentImageR: this.state.currentImageR + 1});
  };
  gotoImage = (index) => {
    this.setState({currentImageR: index});
  };

  updateShowModalQuestionVideo = () => {
    this.setState(state => ({showModalQuestionVideo: !state.showModalQuestionVideo}));
  };
}
