import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"
import {getCountryName, setIconGender} from "../../../../UI/Globals/PX_Funs";

export default class TB_PC_Big extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    showModalExportHandler: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    role_2: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isMax: false
    }
  }

  handleIsMax = () => {
    this.setState(state => ({isMax: !state.isMax}));
  };

  render() {
    const {user, from} = this.props;

    const regionCountry = user.state_country === '' ? getCountryName(user.country) :
      user.state_country + ', ' + getCountryName(user.country);

    let gender = user.gender !== '' && <div className="px-genders-container"><div className="gender-container" data-tooltip-content={user.gender}>
      {setIconGender(user.gender)}</div>
    </div>;
    let location = user.country !== '' && <div className="px-country-container" data-tooltip-content={regionCountry}>
        <span>{user.country}</span></div>;
    if(from === 'Participant'){
      gender = (user.gender !== '' && user.show_gender) && <div className="px-genders-container">
        <div className="gender-container">{setIconGender(user.gender)}</div></div>;
      location = (user.country !== '' && user.show_location) &&
        <div className="px-country-container" data-tooltip-content={regionCountry}><span>{user.country}</span></div>;
    }

    return (
      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-lg-offset-1 col-md-offset-1">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className="panel px-card px-card-profile">
          <div className="panel-body">
            <img src={user.avatar !== '' ? user.avatar : '/assets/user.png'} className="img-profile img-circle"/>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <div className="title-text-container">
                      <div className="fixed-icons">
                        <p className="profile-name">{user.username}</p>
                        {
                          from === 'Collaborator' &&
                          <FontAwesomeIcon onClick={this.props.showModalExportHandler} className="fixed-share"
                                           icon={['fas','share']} style={{cursor:'pointer'}}/>
                        }
                        <FontAwesomeIcon onClick={this.handleIsMax} className={this.state.isMax ?
                          'fixed-contract active' : 'fixed-contract'} icon={['far','circle-info']}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
                    <div className="separator mg-bottom-10"/>
                    <div className="row px-profile-info-container">
                      <div className={this.state.isMax ? 'left-container' : 'left-container minimized '}>
                        <div className="add-container">
                          {
                            from === 'Collaborator' &&
                            <div className="px-tags-container">
                              {
                                user.segments.map(segment => {
                                  return(
                                    <FontAwesomeIcon key={segment.id} icon={['fas','bookmark']} className="tag"
                                                    style={{color: segment.color}} data-tooltip-content={segment.name}/>
                                  )
                                })
                              }
                            </div>
                          }
                          { gender }
                          { location }
                        </div>
                        {
                          from === 'Collaborator' &&
                          <p className="email">{user.email}</p>
                        }
                      </div>
                      <div className={this.state.isMax ? 'right-container'
                        : 'right-container minimized'}>
                        <p className="data-right">{user.cu_bio}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={this.state.isMax ? 'panel-footer' : 'panel-footer minimized'}>
            {/*<div className="button-container">
              <button className="btn-footer">
                <FontAwesomeIcon icon={['fas','paint-brush']} />
              </button>
              <button className="btn-footer">
                <FontAwesomeIcon icon={['fas','bed']} />
              </button>
              <button className="btn-footer">
                <FontAwesomeIcon icon={['far','face-smile']} />
              </button>
              <button className="btn-footer">
                <FontAwesomeIcon icon={['fas','flag-checkered']} />
              </button>
            </div>*/}

            <div className="stats-container">
              <div className="stat-item" data-tooltip-content="Likes received">
                <span className="like-in"/>
                <div className="number">{user.receivedObjs.counter_likes}</div>
              </div>
              {/*<div className="stat-item">
                <span className="star-in"/>
                <div className="number">100</div>
              </div>*/}
              <div className="stat-item" data-tooltip-content="Comments received">
                <span className="comment-in"/>
                <div className="number">{user.receivedObjs.counter_comments_replies}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
