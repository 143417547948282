import PropTypes from 'prop-types';
import React from 'react';
import TimeAgo from 'react-timeago';
import englishStrings from 'react-timeago/lib/language-strings/en';
import spanishStrings from 'react-timeago/lib/language-strings/es';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'underscore';
import Dotdotdot from "react-dotdotdot";

import Loading from "./UI/Globals/Loading";
import NotificationList from "./Notifications/NotificationList";
import {showSuccessMsg} from "./UI/Globals/PX_Funs";
import InfiniteScroll from "react-infinite-scroller";
import NotificationList01 from "./Notifications/NotificationList01";
import {notifications_i18n} from "./UI/Globals/PX_i18n";

export default class Notifications extends React.Component {
  static propTypes = {
    notifications: PropTypes.array.isRequired,
    language: PropTypes.string,
    sessionLang: PropTypes.string,
    rightDrawerToggleHandler: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    updateRightDrawerType: PropTypes.func.isRequired,
    isLoadingNotifications: PropTypes.bool.isRequired,
    getNotifications: PropTypes.func.isRequired,
    notificationsHasMore: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired,
    notificationsDate: PropTypes.string.isRequired,
    updateNotificationsDate: PropTypes.func.isRequired,
    communities: PropTypes.array,
    currentUser: PropTypes.object,
    communityId: PropTypes.number,
    updateOpenPMFromOutside: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      notifications: props.notifications,
      sortedNotifications: [],  //[0] Today notifications, [1] Old notifications
      language: props.language ? props.language : props.sessionLang,   //Why not just props?
      selectedCommId: props.communityId === undefined ? -1 : props.communityId,
      sltType: 'All',
      dateType: 'today'
    };
  }

  componentDidMount(){
    //this.setState({sortedNotifications: this._getSortedNotifications(this.props.notifications)})
  }

  componentDidUpdate(prevProps) {
    if(prevProps.notifications !== this.props.notifications) {
      //this.setState({sortedNotifications: this._getSortedNotifications(this.props.notifications)})
    }
  }

  render() {
    const {communities, isLoadingNotifications, language, colors, notificationsHasMore, notificationsDate} = this.props;

    const i18n = {
      en: {
        lbl_notification: 'Notifications', today: 'Today', old: 'Old', commented_on: 'commented on task ',
        reply_on: 'reply on task ', sent_pm: 'sent you a private message ', updated_response: 'updated response on ',
        liked_01: 'liked a ', liked_02: ' on ', no_new_notifications: 'No notifications', all: 'All', responses: 'Responses',
        comments: 'Comments', likes: 'Likes', community: 'Community', messages: 'Messages'
      },
      es: {
        lbl_notification: 'Notificaciones', today: 'Hoy', old: 'Antiguas', commented_on: 'comentó en la tarea ',
        reply_on: 'respondió en la tarea ', sent_pm: 'te envió mensaje privado ', updated_response: 'actualizó su respuesta en ',
        liked_01: 'le gustó ', liked_02: ' en ', no_new_notifications: 'No hay notificaciones', all: 'Todo', responses: 'Respuestas',
        comments: 'Comentarios', likes: 'Likes', community: 'Comunidad', messages: 'Mensajes'
      }
    };

    let communityOptions = null;
    if(communities !== undefined){
      communityOptions = communities.map(community => {
        if(community.cu_is_welcome && community.state === 'Enabled'){
          return(
            <option key={community.id} value={community.id}>{community.title}</option>
          )
        }
      });
    }

    return (
      <React.Fragment>
        <div className="px-upper-container">
          <div className="notifications-header" style={{backgroundColor: colors.color0[1]}}>
            <div className="notifications-title">
              <span>{i18n[this.state.language].lbl_notification}</span>
            </div>
            <div onClick={this.props.rightDrawerToggleHandler} className="notifications-close"
                 style={{cursor:'pointer', backgroundColor: colors.color0[3]}}>
              <FontAwesomeIcon icon={['far', 'xmark']} className="iconClose"/>
            </div>
          </div>
          <div className="notifications-filters">
            <div className="filter-community">
              <div className="custom-select-filters">
                <select name="selectedCommId" onChange={this.handleInputChange} value={this.state.selectedCommId}>
                  <option value={-1}>{i18n[language].all}</option>
                  { communityOptions }
                </select>
                <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
              </div>
            </div>
            <div className="filter-type">
              <div className="custom-select-filters">
                <select name="sltType" onChange={this.handleInputChange} value={this.state.sltType}>
                  <option value="All">{i18n[language].all}</option>
                  <option value="Responses">{i18n[language].responses}</option>
                  <option value="Comments">{i18n[language].comments}</option>
                  <option value="Likes">{i18n[language].likes}</option>
                  <option value="Community">{i18n[language].community}</option>
                  <option value="Messages">{i18n[language].messages}</option>
                </select>
                <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron"/>
              </div>
            </div>
            {
              this.state.selectedCommId !== -1 &&
              <div className="filter-configuration">
                <a href={'/communities/settings/'+ this.state.selectedCommId +'/Notifications'}>
                  <FontAwesomeIcon icon={['fas', 'gear']} className="chevron"/>
                </a>
              </div>
            }
          </div>

          <div className="notifications-items-container">
            <div className="notification-item">
              <div className="date-title" style={{marginBottom:'0'}}>
                <span onClick={() => this.props.updateNotificationsDate('today')} className="date"
                      style={{color: colors.color1[3], cursor:'pointer'}}>
                  {notifications_i18n[language].today}
                </span>
                {
                  notificationsDate === 'today' &&
                  <FontAwesomeIcon onClick={() => this.deleteNotification(-1, 'all', 0)}
                                   icon={['fas', 'circle-xmark']} className="remove"
                                   style={{cursor: 'pointer', marginLeft: 'unset'}}/>
                }
                <span onClick={() => this.props.updateNotificationsDate('old')} className="date"
                      style={{color: colors.color1[3], marginLeft:'25px', cursor:'pointer'}}>
                  {notifications_i18n[language].old}
                </span>
                {
                  notificationsDate === 'old' &&
                  <FontAwesomeIcon onClick={() => this.deleteNotification(-1, 'all', 0)}
                                   icon={['fas', 'circle-xmark']} className="remove"
                                   style={{cursor: 'pointer', marginLeft: 'unset'}}/>
                }
              </div>
            </div>
          </div>
        </div>
        {
          isLoadingNotifications ?
            <Loading/> :
            <NotificationList01 notifications={this.props.notifications}
                                getNotifications={this.props.getNotifications}
                                notificationsHasMore={notificationsHasMore}
                                sltType={this.state.sltType}
                                updateRightDrawerType={this.props.updateRightDrawerType}
                                updateOpenPMFromOutside={this.props.updateOpenPMFromOutside}
                                colors={colors}
                                language={this.state.language}
                                selectedCommId={parseInt(this.state.selectedCommId)}
                                isLoadingNotifications={isLoadingNotifications}
                                updateNotificationsDate={this.props.updateNotificationsDate}
                                notificationsDate={this.props.notificationsDate}/>
        }
      </React.Fragment>
    );
  }

  setFollow = user => {
    $.ajax({
      url: '/follow/update/'+ user.user_id +'/' + this.props.communityId,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        role_1: user.role_1
      },
      success: users => {
        this.updateCommunityUsers(users, this.props.type === 'Participant' ? 'participants' : 'collaborators');
        showSuccessMsg();
      }
    });
  };

  handleInputChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;
    console.log(name, value);

    this.setState({[name]: name === 'selectedCommId' ?  parseInt(value) : value}, () => {
      /*if(name === 'selectedCommId'){
        $.ajax({
          url: '/notifications/get_notifications/0/today',
          method: 'GET',
          dataType: 'JSON',
          success: notifications => {
            console.log(notifications);
            this.setState({
              sortedNotifications: this._getSortedNotifications(notifications),
            });
          }
        });
      } else {
        this.setState({
          sortedNotifications : this._getSortedNotifications([...this.state.sortedNotifications[0],
            ...this.state.sortedNotifications[1]])
        });
      }*/
    });
  };

  deleteNotification = (notificationId, type, group) =>{
    let notifId = -1;
    let notificationsId = [];

    if(type === 'single'){
      notifId = notificationId;
    } else {
      const justNotifications = this.props.notifications.map(obj => obj.notification);
      //const justNotifications = _.pluck(this.state.sortedNotifications[group], 'notification');
      console.log('justNotifications:', justNotifications);
      notificationsId = type === 'all' ? justNotifications.map(n => n.id) : [];
      //notificationsId = type === 'all' ? _.pluck(justNotifications, 'id') : [];
      console.log('notificationsId: ', notificationsId);
    }

    if(notificationsId.length !== 0){
      $.ajax({
        url: '/notifications/update_visibility/'+ notifId +'/' + type,
        method: 'POST',
        dataType: 'JSON',
        data: {
          notificationsId: notificationsId
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: notifications => {
          console.log(notifications);
          this.props.updateNotificationsDate(this.props.notificationsDate);
          /*this.setState({
            sortedNotifications: this._getSortedNotifications(notifications),
          });*/
        }
      });
    }
  };

  _getSortedNotifications = notifications => {
    let todayNotifications = [];
    let oldNotifications = [];
    let sortedNotifications = [];

    notifications.map(notification => {
      if(notification.user_to_roles){
        if(notification.user_to_roles.role_1 === 'Collaborator'){
          if(new Date(notification.notification.created_at).getDate() === new Date().getDate()){
            todayNotifications.push(notification);
          } else {
            oldNotifications.push(notification);
          }
        } else {
          if(notification.community_state === 'Enabled'){
            if(new Date(notification.notification.created_at).getDate() === new Date().getDate()){
              todayNotifications.push(notification);
            } else {
              oldNotifications.push(notification);
            }
          }
        }
      } else {
        // Only profile card should be here, TO-DO show it in Main Dashboard too
        /*console.log(notification);
        if(notification.notification.entity_type === 'Profile'){
          console.log('Add Profile Notification:', notification);
          if(new Date(notification.notification.created_at).getDate() === new Date().getDate()){
            todayNotifications.push(notification);
          } else {
            oldNotifications.push(notification);
          }
        }*/
      }
    });

    sortedNotifications.push(todayNotifications);
    sortedNotifications.push(oldNotifications);

    return sortedNotifications;
  };
}
