import PropTypes from 'prop-types';
import React from 'react';

import Cropper from "react-cropper";
import Dropzone from "react-dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showSuccessMsg} from "../../../../UI/Globals/PX_Funs";

const i18n = {
  en: {
    btn_save: 'Save', btn_cancel: 'Cancel', size: 'Image file too large. Maximum file size 12 MB',
    allow: 'Format not allowed. Only JPEG, JPG, PNG files allowed'
  },
  es: {
    btn_save: 'Guardar', btn_cancel: 'Cancelar', size: 'Archivo demasiado grande. Tamaño máximo 12 Mb',
    allow: 'Formato no permitido. Solo se permiten archivos JPG, JPEG, PNG'
  }
};

export default class AE_M_Image extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    onDropImage: PropTypes.func,
    updateCropBlob: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {title, colors, language} = this.props;

    return (
      <div className="modal-content">
        <div className="modal-header" style={{margin:'0'}}>
          <button type="button" className="btn px-btn circle xs btn-gray-lighter color-white px-close-modal"
                  onClick={() => this.props.updateShowModal(null, null)}>
            <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
          </button>
          <h4 className="px-modal-title">{title}</h4>
        </div>
        <div className="modal-body px-modal-body-add-activity">
          <Cropper ref='cropper'
                   src={this.props.imageUrl}
                   style={{height: 200, width: '100%'}}
                   aspectRatio={1}
                   guides={false}
                   crop={this._crop}/>
          <Dropzone onDrop={(file) => this.props.onDropImage(file, title === 'Image ' && 'answer')}
                    minSize={0} maxSize={12582912}
                    style={{border:'0 solid', marginTop:'20px'}} accept={'image/jpeg, image/png'}
                    onDropRejected={this.onDropRejected}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="btn px-btn circle lg btn-turquoise-base"
                     style={{margin:'10px auto', backgroundColor: colors.color2[3]}}>
                  <FontAwesomeIcon icon={['fas', 'camera']} style={{ fontSize: '16px' }} />
                </div>
              </div>
            )}
          </Dropzone>
        </div>

        <div className="modal-footer px-modal-footer-gray">
          <div className="px-footer-container-buttons row">
            <button onClick={this.handleImage} type="button"
                    className="btn px-btn sm btn-blue-base" style={{backgroundColor: colors.color1[3]}}>
              {i18n[language].btn_save}
            </button>
            <button onClick={() => this.props.updateShowModal(null, null)} type="button"
                    className="btn px-btn sm btn-gray-darker inverted">{i18n[language].btn_cancel}</button>
          </div>
        </div>
      </div>
    );
  }

  onDropRejected = (e) => {
    console.log('onDropRejected', e[0]);
    let msg = '';

    if(e[0].size > 12582912){
      msg = i18n[this.props.language].size
    } else {
      if(e[0].type !== 'image/jpeg' || e[0].type !== 'image/png'){
        msg = i18n[this.props.language].allow
      }
    }

    showSuccessMsg(msg);
  }

  handleImage = () => {
    const blobPreview = this.refs.cropper.getCroppedCanvas().toDataURL();
    //console.log('AE_M_Image.handleImage');
    this.refs.cropper.getCroppedCanvas().toBlob(function (blob) {
      this.props.updateCropBlob(blob, blobPreview, this.props.title === 'Image ' && 'answer');
    }.bind(this), 'image/jpeg', 0.5);
  }
}