import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {formatExpireDate} from "../../../UI/Globals/PX_Funs";

export default class DMHeader extends React.Component {
  static propTypes = {
    activity: PropTypes.object.isRequired,
    isMin: PropTypes.bool.isRequired,
    role_2: PropTypes.string.isRequired,
    columnHeaderActions: PropTypes.func.isRequired,
    available: PropTypes.bool.isRequired,
    x: PropTypes.number.isRequired,
    updateColsX: PropTypes.func.isRequired,
    updateTaskHeaderMenu: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {activity, isMin, x, role_2, available, language, colors} = this.props;
    const i18n = {
      en: {
        liveTooltip: 'Participants can see this activity', expand: 'Expand',
        collapse: 'Collapse column', menu: 'Click for more options'
      },
      es: {
        liveTooltip: 'Los participantes pueden ver esta actividad', expand: 'Expandir',
        collapse: 'Contraer columna', menu: 'Clic para más opciones'
      }
    }

    return (
      <div className="px-header-container">
        <div className="upper-container">
          {
            activity.visibility === 'enabled' && !isMin &&
            <span className='live-tag' style={{color: colors.color4[3], marginRight: '5px'}}  data-tooltip-content={i18n[language].liveTooltip}>
              LIVE
            </span>
          }
          {
            activity.tag && !isMin &&
            <FontAwesomeIcon className="tag" icon={['fas','tag']} style={{color: activity.tag.color}}
                             data-tooltip-content={activity.tag.name}/>
          }

          <div className="icons-container">
            {
              role_2 !== "Recruiter" && role_2 !== "" && available &&
              <div onClick={(event) => this.props.updateTaskHeaderMenu(activity.id, '.ddEllipsis', event)}
                   className="ellipsis-icon" style={{cursor:'pointer', display: isMin ? 'none' : 'flex'}}
                   data-tooltip-content={i18n[language].menu}>
                <FontAwesomeIcon icon={['fas','circle-plus']} style={{fontSize: '16px'}}/>
              </div>
            }
            <div onClick={() => this.handleColWidth(x)} className="contract" style={{cursor:'pointer', backgroundColor: '#ffffff'}}
                 data-tooltip-content={isMin ? `${i18n[language].expand} "${activity.title}"` : `${i18n[language].collapse}`}>
              <FontAwesomeIcon icon={isMin ? ['fas','up-right-and-down-left-from-center'] : ['fas','down-left-and-up-right-to-center']} style={{fontSize: '11px'}}/>
            </div>
          </div>
        </div>

        <div className="title-container" style={{display: isMin ? 'none' : 'flex'}}>
          {
            role_2 === "Recruiter" || role_2 === "" ?
              <span className="title" style={{ cursor: "default" }} data-tooltip-content={activity.title}>{activity.title}</span> :
              <a onClick={available ? () => this.props.columnHeaderActions('see responses', activity.id) : null}
                 style={{ textDecoration: 'none', cursor: 'pointer' }}>
                <span className="title" data-tooltip-content={activity.title}>{activity.title}</span>
              </a>
          }
          <span className="expires">{formatExpireDate(activity.expire, language)}</span>
        </div>
      </div>
    );
  }

  handleColWidth = (x) => {
    this.props.updateColsX(x);
  };
}
