import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Lightbox from "react-images";
import ReactModal from "react-modal";
import T_ModalVideo from "../../../../Transcript/T_ModalVideo";

export default class ChView extends React.Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    cardinal: PropTypes.number.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    updateQuestionMode: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      showModalVideo: false
    }
  }


  componentDidMount() {
    let btnHoverColor = this.props.colors.color2[3];
    $(".action-button, .btn-draggable").mouseenter(function () {
      $(this).css({ 'background-color': btnHoverColor });
    });
    $(".action-button, .btn-draggable").mouseleave(function () {
      $(this).css({ 'background-color': '' });
    });
  }

  render() {
    const { question, cardinal, language, communitySocial, colors} = this.props;
    const i18n = {
      en: {
        single: 'Single', multiple: 'Multiple',
        singleTooltip: 'Participants can choose only one answer.',
        multipleTooltip: 'Participants can choose multiple answers.',
        randomize: 'Randomize', noRandomize: 'No Randomize',
        randomizeTooltip: 'Participants will see choices in a random order.',
        noRandomizeTooltip: 'Participants will see choices in the order you established.',
        mandatory: 'Mandatory', noMandatory: 'Optional',
        mandatoryTooltip: 'Participants are forced to respond the Task in order to complete the Activity.',
        noMandatoryTooltip: 'Participants are not forced to respond the Task in order to complete the Activity.',
        aiAutoModTT: 'AI moderated'

      },
      es: {
        single: 'Única', multiple: 'Múltiple',
        singleTooltip: 'Los participants pueden elegir solo una opción como respuesta.',
        multipleTooltip: 'Los participants pueden elegir múltiples opciones como respuestas.',
        randomize: 'Aleatorio', noRandomize: 'No aleatorio',
        randomizeTooltip: 'Los participantes veran las opciones ordenadas aleatoriamente.',
        noRandomizeTooltip: 'Los participantes veran las opciones en el orden que tú hayas establecido.',
        mandatory: 'Obligatorio', noMandatory: 'Opcional',
        mandatoryTooltip: 'Los participantes están obligados a responder la Tarea para completar la Actividad.',
        noMandatoryTooltip: 'Los participantes no están obligados a responder la Tarea para completar la Actividad.',
        aiAutoModTT: 'Moderada por AI'
      }
    }

    let imgUrl = '';
    let imgStyle = null;
    let images = [];

    if(typeof question.blobPreview !== 'undefined'){
      imgUrl = question.blobPreview;
      imgStyle = {display: 'block',cursor:'pointer'};
      images.push({src: imgUrl});
    } else {
      if(question.attachment.length !== 0){
        imgUrl = question.attachment[0].url;
        imgStyle = {display: 'block',cursor:'pointer'};
        images.push({src: imgUrl});
      }
    }

    let videoThumbnail = null;
    if(question.videoState){
      videoThumbnail = question.videoState === 'processed' ? question.video_thumbnail : '/assets/loader_200x200.gif';
    } else {
      videoThumbnail = question.video_thumbnail;
    }

    return (
      <div className="panel panel-activity-builder-details">
        <div className="px-support-tooltip top is_hidden px-support-builder">
          <p>This is a summary view of a Task. It shows, broadly, what participants are required to post in order to complete it.
            Click on the pencil icon to edit its settings.</p>
          <i/>
        </div>
        <div className="panel-body">
          <div className="info-container">
            <div className="title-task-container">
              <div className="task-number" style={{ backgroundColor: colors.color0[1] }}><span>{cardinal}</span></div>
              <span className="title-task">{question.task_type}</span>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="details-answer">{question.title.replace(/(<([^>]+)>)/ig,"")}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="media-container">
                  {
                    imgUrl !== '' &&
                    <img onClick={() => this.setState({lightboxIsOpen: true})} ref='imgPreviewChView' src={imgUrl}
                         className="media-item" alt="" style={imgStyle}/>
                  }
                  {
                    question.video_thumbnail !== '' &&
                    <div className="media-item video">
                      <div className="overlay-video" onClick={this.updateShowModalVideo}>
                        <div className="play-icon">
                          <FontAwesomeIcon icon={['fas','play']}/>
                        </div>
                      </div>
                      <img src={videoThumbnail} alt="" className="" style={{cursor: 'pointer'}}/>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="actions-container">
            <div className="action-item">
              <span className="btn-action left" style={{cursor:'auto'}}>
                <span>{question.data.subtype === 'Multiple' ? i18n[language].multiple : i18n[language].single }</span>
              </span>
              <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                      data-tooltip-content={[question.data.subtype === 'Multiple' ? i18n[language].multipleTooltip :
                        i18n[language].singleTooltip]}/>
            </div>
            {/*
              communitySocial &&
              <div className="action-item">
              <span className="btn-action left" style={{cursor:'auto'}}>
                <span>{question.show ? 'Answer first' : ' No answer first'}</span>
              </span>
                <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                 data-tooltip-content={[question.show ? "Participants have to answer this task to see other " +
                                   "participants' responses." : "Participants can see other participants' responses " +
                                   "even if they haven't completed this task."]}/>
              </div>
            */}
            <div className="action-item">
              <span className="btn-action left" style={{cursor:'auto'}}>
                <span>{question.data.random ? i18n[language].randomize : i18n[language].noRandomize}</span>
              </span>
              <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                data-tooltip-content={[question.data.random ? i18n[language].randomizeTooltip :
                          i18n[language].noRandomizeTooltip]}/>
            </div>
            <div className="action-item">
              <span className="btn-action left" style={{cursor:'auto'}}>
                <span>{question.required ? i18n[language].mandatory : i18n[language].noMandatory}</span>
              </span>
              <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                data-tooltip-content={[question.required ? i18n[language].mandatoryTooltip : i18n[language].noMandatoryTooltip]} />
            </div>
            { question.ai_auto_moderation &&
              <div className="action-item"
                   style={{textTransform: 'uppercase',
                     color: colors.color2[3],
                     fontSize: '8px',
                     fontFamily: "'Sharp Sans ExtraBold',Helvetica, sans-serif",
                     letterSpacing: '2.4px'
                   }}>
                <span style={{marginRight: '5px'}}><FontAwesomeIcon icon={['fas','bolt']}/></span> <span>{i18n[language].aiAutoModTT}</span>
              </div> }
          </div>
        </div>

        <div className="actions-panel">
          <div className="actions-container">
            <button onClick={() => this.props.updateQuestionMode(question.id)} className="action-button">
              <FontAwesomeIcon icon={['fas', 'pencil']}/>
            </button>
            <button onClick={() => this.props.updateShowModal('delete task', question.id)} className="action-button">
              <FontAwesomeIcon icon={['fas', 'trash']}/>
            </button>
          </div>
        </div>

        <Lightbox currentImage={this.state.currentImage}
                  images={images}
                  isOpen={this.state.lightboxIsOpen}
                  onClose={this.closeLightbox}/>
        {
          question.video_thumbnail &&
          <ReactModal isOpen={this.state.showModalVideo} contentLabel="Response Video"
                      shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalVideo}
                      className="my-modal-content" overlayClassName="my-modal-overlay">
            <T_ModalVideo updateShowModalVideo={this.updateShowModalVideo}
                          thumbnail={question.video_thumbnail}
                          url={question.video_url}
                          language={language}
                          colors={colors}/>
          </ReactModal>
        }
      </div>
    );
  }

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  updateShowModalVideo = () => {
    this.setState(state => ({showModalVideo: !state.showModalVideo}));
  };
}
