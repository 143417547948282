import PropTypes from 'prop-types';
import React from 'react';

export default class HelpScoutBeacon extends React.Component {
  static propTypes = {
    beaconId: PropTypes.string.isRequired,
    user_account: PropTypes.object,
    colors: PropTypes.object,
    language: PropTypes.string,
    suggestions: PropTypes.object, 
    displayPosition: PropTypes.string,
    hideFABOnMobile: PropTypes.bool,
    hideFABOnDesktop: PropTypes.bool,
    showMessageAfterLoad: PropTypes.string,
    signature: PropTypes.string,
    isBeaconInit: PropTypes.bool.isRequired,
    updateIsBeaconInit: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    Beacon('init', this.props.beaconId);
    this._configBeacon();
    Beacon('on', 'ready', () => {
      this.props.updateIsBeaconInit(true);
      this._shouldHideFABOnDesktop(this.props.hideFABOnDesktop);
    });

    if(this.props.showMessageAfterLoad !== undefined) {
      Beacon('on', 'ready', () => {
        Beacon('show-message', this.props.showMessageAfterLoad, { force: true });
      });
    }
  }

  componentDidUpdate(prevProps) {
    //console.log('componentDidUpdate:', this.props.beaconId, 'this.props.isBeaconInit:', this.props.isBeaconInit);
    if(prevProps.beaconId !== this.props.beaconId){
      if(this.props.isBeaconInit === true){
        const divBeacon = document.getElementsByClassName("Beacon");
        //console.log('divBeacon:', divBeacon);
        if(divBeacon){
          //console.log('Destroy and create Beacon');
          Beacon('destroy');
          this._shouldHideFABOnDesktop(this.props.hideFABOnDesktop);
          Beacon('init', this.props.beaconId);
          this._configBeacon();
        }
      }
    }
  }

  componentWillUnmount() {
    //console.log('componentWillUnmount:', this.props.beaconId, 'this.props.isBeaconInit:', this.props.isBeaconInit);
    if(this.props.isBeaconInit) {
      //console.log('componentWillUnmount:', Beacon);
      const divBeacon = document.getElementsByClassName("Beacon");

      //console.log('divBeacon:', divBeacon.length);
      if(divBeacon.length >= 1){
        Beacon('destroy');
        this.props.updateIsBeaconInit(false);
      }
    }
  }

  render() {
    return (
      <div/>
    )
  }

  _configBeacon = () => {
    const {user_account} = this.props;
    //console.log(user_account.profile);
    const displayPosition = this.props.displayPosition ? this.props.displayPosition : 'right';
    const hideFABOnMobile = (this.props.hideFABOnMobile === true || this.props.hideFABOnMobile === undefined) ? true : false;
    const color = (this.props.colors !== undefined && this.props.colors.color3[3] !== undefined) ? this.props.colors.color3[3] : '';
    const language = this.props.language ? this.props.language : 'en';
    const suggestions_en = this.props.suggestions ? this.props.suggestions.en : null;
    const suggestions_es = this.props.suggestions ? this.props.suggestions.es : null;
    // debugger;
    Beacon('config', {
      color: color,
      enableFabAnimation: false,
      hideFABOnMobile: hideFABOnMobile,
      display: {
        position: displayPosition
      }
    });

    if (language === 'es') {
      Beacon('config',
        {
          labels: {
            suggestedForYou: 'Respuestas para ti',
            you: 'Tú'
          }
        });
    }

    if (language === 'es' && suggestions_es !== undefined) {
      Beacon('suggest', suggestions_es);
    } else if (suggestions_en !== undefined) {
      Beacon('suggest', suggestions_en);
    }

    if (user_account !== undefined) {
      Beacon('identify', {
        username: user_account.username,
        userEmail: user_account.email,
        avatar: user_account.profile.avatar !== '' ?
        user_account.profile.avatar : 'https://pixiebob.s3-ap-southeast-2.amazonaws.com/_helpScout/user.png',
        companyName: user_account.subdomain,
        language: user_account.language,
        accountRole: user_account.profile.role,
        communityRole1: user_account.role_1,
        communityRole2: user_account.role_2,
        userSince: user_account.profile.created_at,
        signature: this.props.signature
      });
    }
  }

  _shouldHideFABOnDesktop = boolean_value => {
    //console.log('_shouldHideFABOnDesktop.boolean_value:', boolean_value);
    if(boolean_value){
      const beaconFab = $('.BeaconFabButtonFrame')[0];
      //console.log('beaconFab:', beaconFab);
      if(beaconFab !== undefined){
        //console.log('HIDE BEACON');
        beaconFab.style.visibility = 'hidden'
        //Beacon('on', 'ready', () => { beaconFab.style.visibility = 'hidden' });
      }
    }
  };

}