import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class TR_VerbatimsManage extends React.Component {
  static propTypes = {
    responseId: PropTypes.number.isRequired,
    verbatim: PropTypes.object.isRequired,
    isVisible: PropTypes.string.isRequired,
    handleVerbatimVisibility: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      hoverV: false,
      hoverVM: false
    };
  }

  hoverOn = () => { this.setState({hoverV : true}) };

  hoverOff = () => { this.setState({hoverV : false}) };

  hoverOnVM = () => { this.setState({hoverVM : true}) };

  hoverOffVM = () => { this.setState({hoverVM : false}) };

  render() {
    const {verbatim, responseId, isVisible} = this.props;
    const {hoverV, hoverVM} = this.state;

    if(verbatim.visibility === 'true'){
      return (
        <div className="container-verbatim-mode">
          <p className="verbatim">{verbatim.verbatim}</p>
          <div onClick={() => this.props.handleVerbatimVisibility(verbatim.id, verbatim.visibility, responseId)}
               onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}
               className={hoverV ? 'verbatim-icon active' : 'verbatim-icon'} style={{cursor:'pointer'}}>
            <FontAwesomeIcon icon={['fas', hoverV ? 'eye-slash' : isVisible]}/>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-verbatim-mode">
          <p className="verbatim">{verbatim.verbatim}</p>
          <div onClick={() => this.props.handleVerbatimVisibility(verbatim.id, verbatim.visibility, responseId)}
               onMouseEnter={this.hoverOnVM} onMouseLeave={this.hoverOffVM}
               className={hoverVM ? 'verbatim-icon' : 'verbatim-icon active'} style={{cursor:'pointer'}}>
            <FontAwesomeIcon icon={['fas', hoverVM ? 'quote-right' : isVisible]}/>
          </div>
        </div>
      );
    }
  }
}
