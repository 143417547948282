import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lightbox from 'react-images';
import ReactModal from 'react-modal';
import TagsInput from "react-tagsinput";
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import AutosizeInput from 'react-input-autosize';

import Comments from "../../../containers/Community/Comments";
import T_ModalVideo from "./T_ModalVideo";
import v4 from "uuid";
import TR_PopupVerbatim from "./TR_PopupVerbatim";
import Backdrop from "../../UI/Backdrop";
import TR_Verbatims from "./T_Response/TR_Verbatims";
import TR_VerbatimsManage from "./T_Response/TR_VerbatimsManage";
import { Tooltip as ReactTooltip } from "react-tooltip"
import TRChoice from "./T_Response/TRChoice";

export default class T_Response extends React.Component {
  static propTypes = {
    cardinal: PropTypes.number.isRequired,
    taskTitle: PropTypes.string.isRequired,
    taskId: PropTypes.number.isRequired,
    task: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    userGender: PropTypes.string,
    show_gender: PropTypes.bool,
    language: PropTypes.string.isRequired,
    cmntyLanguage: PropTypes.string.isRequired,
    isFirstFFComment: PropTypes.object.isRequired,
    updateIsFirstFFComment: PropTypes.func.isRequired,
    hideTabFilters: PropTypes.bool.isRequired,
    userAvatar: PropTypes.string,
    userEmail: PropTypes.string,
    tasks_filter: PropTypes.array,
    response: PropTypes.object,
    updateResponse: PropTypes.func,
    updateAUResponses: PropTypes.func,
    showComments: PropTypes.bool,
    updateShowComments: PropTypes.func,
    taskUuid: PropTypes.string,
    isTaskMinimized: PropTypes.bool,
    updateIsTaskMinimized: PropTypes.func,
    activityId: PropTypes.number,
    showAllVerbatim: PropTypes.bool.isRequired,
    role_1: PropTypes.string.isRequired,
    updateSizeResponse: PropTypes.func,
    updateResponseKeywords: PropTypes.func,
    autosizingRenderInput: PropTypes.func,
    regenerateResponseVerbatim: PropTypes.func,
    handleVerbatimVisibility: PropTypes.func,
    handleShowHiddenVerbatims: PropTypes.func,
    handleShowOriginal: PropTypes.func,
    updateSizeResponseSlider: PropTypes.func,
    countersLayout: PropTypes.object,
    updateResponseComments: PropTypes.func,
    from: PropTypes.string, // TO-DO search the component that triggers the warning if I put this as isRequired
    scrollFirstTime: PropTypes.bool,
    updateScrollFirstTime: PropTypes.func,
    scroll_id: PropTypes.number,
    entity: PropTypes.object,
    updateShowCommentsScroll: PropTypes.func,
    showModalExportHandler: PropTypes.func,
    userCountry: PropTypes.string,
    show_location: PropTypes.bool,
    userStateCountry: PropTypes.string,
    qc: PropTypes.array,
    saveResponseLike: PropTypes.func,
    item_scroll: PropTypes.object,
    isSavingLikeResponse: PropTypes.bool,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      showModalVideo: false,
      showPopupVerbatim: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { response, taskId, scroll_id, scrollFirstTime, item_scroll, userId } = this.props;

    if(prevProps.response !== response) {
      if(scrollFirstTime && item_scroll) {
        const scrollAnchor = $('#' + userId + '_' + item_scroll.id);
        this.props.updateScrollFirstTime(false);

        if(scrollAnchor.offset() !== undefined && (item_scroll.type === 'response' || item_scroll.type === 'likeResponse')) {
          const cardPosition = scrollAnchor.offset().top;
          (window).scrollTo({ top: cardPosition - 50, behavior: 'smooth' });
        } else {
          //console.log(question.id);
          if(taskId === item_scroll.taskId) {
            this.props.updateShowCommentsScroll(userId, scroll_id);
          }
        }
      }
      /*if(taskId === scroll_id && scrollFirstTime){
        switch(this.props.entity.type){
          case 'response':
            const cardPosition = $('#' + this.props.userId + '_' + this.props.scroll_id).offset().top;
            (window).scrollTo({top: cardPosition - 75, behavior: 'smooth'});
            this.props.updateScrollFirstTime(false);
            break;
          case 'comment':
            if(this.props.scrollFirstTime){
              this.props.updateShowCommentsScroll(this.props.userId, this.props.scroll_id);
            }
            break;
          case 'reply':
            console.log('open Reply', this.props.scrollFirstTime);
            this.props.updateShowCommentsScroll(this.props.userId, this.props.scroll_id);
            break;
          case 'like':
            //console.log('open Like', this.props.scrollFirstTime);
            if(this.props.scrollFirstTime){
              this.props.updateShowCommentsScroll(this.props.userId, this.props.scroll_id);
            }
            break;
        }
      }*/
    }
  }

  render() {
    const { cardinal, taskTitle, response, countersLayout, role_1, userGender, show_gender, userCountry,
      show_location, userId, userStateCountry, from, task, item_scroll, language, cmntyLanguage, colors } = this.props;
    const i18n = {
     en: {
        comments: 'Comments', hasntAnswered: 'hasn\'t answered this task yet'
     }, es: {
        comments: 'Comentarios', hasntAnswered: 'no ha contestado aún'
     }
    }
    const { hoverMV } = this.state;

    let cardHeader = <div className="px-counter-task" style={{backgroundColor: colors.color0[1]}}>
      <span className="number">{cardinal}</span>
    </div>;
    let cardTitle = <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
        <p dangerouslySetInnerHTML={{ __html: taskTitle }} className="title" />
      </div>
    </div>;
    let showHeaderSeparator = false;

    if (role_1 === 'Collaborator') {
      const cUsers = countersLayout.users;
      const cActivities = countersLayout.activities;
      const cTasks = countersLayout.tasks;

      if (cUsers === 1) {
        if (cActivities === 1) {
          if (cTasks > 1) {
          } else {
          }
        } else {
        }
      } else {
        if (cActivities === 1) {
          if (cTasks > 1) {
            cardHeader = <div className="counter-task-image">
              <img src={this.props.userAvatar} alt="image" className="image" />
              <div className="number">
                <span>{this.props.cardinal}</span>
              </div>
            </div>;
          } else {
            //III
            cardHeader = null;
            const regionCountry = userStateCountry === '' ? getCountryName(userCountry) :
              userStateCountry + ', ' + getCountryName(userCountry);
            cardTitle = <div className="row">
              <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="title-with-profile">
                  <img src={this.props.userAvatar}
                    className="img-profile" />
                  <p className="profile-name">{this.props.username}</p>
                  <div className="tags-container">
                    <FontAwesomeIcon icon={['fas', 'tag']} className="tag tag-male" />
                    <FontAwesomeIcon icon={['fas', 'tag']} className="tag tag-female" />
                  </div>
                  <div className="add-container">
                    {
                      userGender !== '' &&
                      <div className="genders-container">
                        <div className="gender-container">
                          {this._setIconGender(userGender)}
                        </div>
                      </div>
                    }
                    {
                      userCountry !== '' &&
                      <div className="country-container" data-tooltip-content={regionCountry}>
                        <span>{userCountry}</span>
                      </div>
                    }
                  </div>
                  <p className="email">{this.props.userEmail}</p>
                </div>
              </div>
            </div>;
            showHeaderSeparator = true;
          }
        } else {
          cardHeader = <div className="counter-task-image">
            <img src={this.props.userAvatar} alt="image" className="image" />

            <div className="px-support-tooltip top px-pop-up" style={{ left: '30%' }}>
              <h3></h3>
              <div className='info-container'>
                <div className='icons-container'>
                  <FontAwesomeIcon icon={['fas', 'bookmark']} className="segment" />
                  <FontAwesomeIcon icon={['fas', 'bookmark']} className="segment" />
                </div>
                <div className="gender-container">
                  <FontAwesomeIcon icon={['far', 'mars']} className="gender" />
                </div>
                <div className="country-container">
                  <div className="country"></div>
                </div>
              </div>
              <p></p>
              <i />
            </div>

            <div className="number">
              <span>{this.props.cardinal}</span>
            </div>
          </div>;
        }
      }
    }

    if (response !== undefined) {
      //console.log(auResponse.response.data.answer);
      //TO-DO Some responses have data.answer = '' with state EMPTY, check what other actions are creating
      //responses with EMPTY state and data.answer = '', because in comment the data.answer is undefined
      //maybe the actions are Moods, Video or Upload Files
      //It was 'No Answer (But there are other data), Response object exists'
      const answer = response.response.data.answerHTML !== undefined ? response.response.data.answerHTML :
        '';
      let images = [];
      const attachments = response.attachments.map((attachment, index) => {
        images.push({ src: attachment.url });
        return (
          <div key={index} onClick={(e) => this.openLightbox(index, e)} className="px-image-item"
            style={{ cursor: 'pointer' }}>
            <img src={attachment.url} />
          </div>
        )
      });
      //TO-DO video resources are inside response.response, it would change to response
      const videoThumbnail = response.response.video_thumbnail ?
        <div className="videos-container">
          <div className="px-video-item" style={{ cursor: 'pointer' }}>
            <img src={response.response.video_thumbnail} />
            <div className="overlay-video" onClick={this.updateShowModalVideo}>
              <div className="play-icon">
                <FontAwesomeIcon icon={['fas', 'play']} />
              </div>
            </div>
          </div>
        </div> : null;

      const moods = response.participantMapItems.map((item, index) =>
        <li key={index} className="px-mood-list-item">
          <span className="px-mood-item " data-tooltip-content={item[cmntyLanguage]} style={{backgroundColor: colors.color0[1]}}>
            {item[cmntyLanguage]}
          </span>
        </li>
      );
      const stateColor = this._getStateColor(response.response.state);
      let stateMessage = this._getStateDropAction(response.response.state, response.response.question_id,
        response.response.pre_state, response.response.id);
      const classComments = this.props.showComments ? 'px-n-accordion-open' : 'px-n-accordion-close';
      //const taskPanelHeight = this.props.isTaskMinimized ? '150px' : '100%';

      let hideCounter = 0;
      const verbatims = response.verbatims && response.verbatims.map((verbatim, index) => {
        if (verbatim.visibility === 'false') {
          hideCounter += 1;
        }
        const isVisible = verbatim.visibility === 'true' ? 'quote-right' : 'eye-slash';
        const verbatimComp = verbatim.visibility === 'true' ?
          <TR_Verbatims responseId={response.id}
            verbatim={verbatim}
            isVisible={isVisible}
            handleVerbatimVisibility={this.props.handleVerbatimVisibility} /> : null;

        return (
          <div key={index}>
            {verbatimComp}
          </div>
        )
      });

      const manageVerbatims = response.verbatims && response.verbatims.map((verbatim, index) => {
        const isVisible = verbatim.visibility === 'true' ? 'quote-right' : 'eye-slash';

        return (
          <div key={index}>
            <TR_VerbatimsManage responseId={response.id}
              verbatim={verbatim}
              isVisible={isVisible}
              handleVerbatimVisibility={this.props.handleVerbatimVisibility} />
          </div>
        )
      });

      let showAnswer = null;
      //console.log(task);
      switch (task.question_type) {
        case 'Open End':
          if (this.props.showAllVerbatim) {
            if (response.showOriginal) {
              showAnswer = <div className="response">
                <div className="container-answer">
                  <div className="answer" dangerouslySetInnerHTML={{ __html: answer }}>
                  </div>
                </div>
                <div className="actions-verbatim">
                  <button onClick={() => this.props.handleShowOriginal(response.id)}
                    className="btn btn-actions-verbatim">VERBATIM</button>
                </div>
              </div>;
            } else {
              if (response.showHiddenVerbatims) {
                showAnswer = <div className="response">
                  {manageVerbatims}
                  <div className="actions-verbatim">
                    <button onClick={() => this.props.handleShowHiddenVerbatims(response.id)}
                      className="btn btn-actions-verbatim">CLOSE</button>
                  </div>
                </div>;
              } else {
                const dropupClass = this.state.showPopupVerbatim ? 'dropup open' : 'dropup';
                showAnswer = <div className="response">
                  {verbatims}
                  <div className="actions-verbatim">
                    <div className={dropupClass} style={{ listStyleType: 'none', padding: '18px 0 0 15px' }}>
                      <button onClick={this.handlePopupVerbatim} className="btn btn-actions-verbatim options dropdown-toggle">
                        <div className="plus-container">
                          <FontAwesomeIcon icon={['fal', 'plus']} />
                        </div>
                        <span>OPTIONS</span>
                      </button>
                      <TR_PopupVerbatim response={response}
                        updateSizeResponseSlider={this.props.updateSizeResponseSlider}
                        updateResponseKeywords={this.props.updateResponseKeywords}
                        autosizingRenderInput={this.props.autosizingRenderInput}
                        updateSizeResponse={this.props.updateSizeResponse}
                        hideMenuAndRegenerate={this.hideMenuAndRegenerate} />
                    </div>
                    {
                      hideCounter !== 0 && <button onClick={() => this.props.handleShowHiddenVerbatims(response.id)}
                        className="btn btn-actions-verbatim">HIDDEN VERBATIM</button>
                    }
                    <button onClick={() => this.props.handleShowOriginal(response.id)}
                      className="btn btn-actions-verbatim">ORIGINAL TEXT</button>
                  </div>
                </div>;
              }
            }
          } else {
            showAnswer = <div className="response">
              <div className="container-answer">
                <div dangerouslySetInnerHTML={{ __html: answer }} className="answer">
                </div>
              </div>
            </div>;
          }
          break;
        case 'Choice':
          showAnswer = <TRChoice task={task}
            response={response}
            language={language}
            colors={colors} />;
          break;
      }

      const panelId = this.props.from === 'PSA' ?
        this.props.userId + '_' + this.props.taskId : this.props.userId + '_' + this.props.cardinal;

      const likeIcon = response.isLike ? ['fas', 'heart'] : ['far', 'heart'];
      const likeBtn = response.user.id === response.currentUserId ? <React.Fragment>
            <FontAwesomeIcon icon={likeIcon} /><label>{response.likes}</label></React.Fragment> :
          <div onClick={() => this.props.saveResponseLike(response.id, response.question_id, !response.isLike,
            userId)}><FontAwesomeIcon icon={likeIcon} className="chevron" />
            <label style={{cursor:'pointer'}}>{response.likes}</label>
          </div>;

      return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <Backdrop show={this.state.showPopupVerbatim}
            clicked={this.handlePopupVerbatim} />
          {cardHeader}
          <div id={panelId} className="panel px-card px-panel-task" style={{ borderLeft: '10px solid' + stateColor }}>
            {
              from === 'CTV' &&
              <FontAwesomeIcon onClick={this.props.showModalExportHandler} icon={['fas', 'share']}
                className="share-icon" style={{ cursor: 'pointer' }} />
            }

            <div className="panel-body">
              {cardTitle}
              {
                showHeaderSeparator &&
                <div className="separator" style={{ marginTop: '35px', marginBottom: '30px' }} />
              }
              <div className="minimized-content" style={{ display: this.props.isTaskMinimized ? 'none' : 'block' }}>
                {
                  (response.response.state === 'Completed' || response.response.state === 'Accepted') ?
                    <div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          {showAnswer}
                        </div>
                      </div>
                      <div className="px-attachments-container ">

                        {attachments}
                        {videoThumbnail}
                      </div>
                      <Lightbox currentImage={this.state.currentImage}
                        images={images}
                        isOpen={this.state.lightboxIsOpen}
                        onClickPrev={this.gotoPrevious}
                        onClickNext={this.gotoNext}
                        onClickThumbnail={this.gotoImage}
                        onClose={this.closeLightbox} />
                      {/* MODAL OVERLAY CLICK doesn't work fine, if you click just below the modal it doesn't close*/}
                      {
                        response.response.video_thumbnail ?
                          <ReactModal isOpen={this.state.showModalVideo} contentLabel="Response Video"
                            shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalVideo}
                            className="my-modal-content" overlayClassName="my-modal-overlay">
                            <T_ModalVideo updateShowModalVideo={this.updateShowModalVideo}
                              thumbnail={response.response.video_thumbnail}
                              url={response.response.video_url}
                              language={language}
                              colors={colors}/>
                          </ReactModal> : null
                      }
                      {
                        response.participantMapItems.length !== 0 &&
                        <div className="px-mood-container">
                          <span className="px-mood-text">{response.mapTitle[cmntyLanguage]}</span>
                          <div className="master-mood-container">
                            <ul className="list-inline px-mood-list">
                              {moods}
                            </ul>
                          </div>
                        </div>
                      }
                      <div className="separator mg-top-bottom-15" />
                      <div className="comments-option-container">
                        <div style={{ display: 'flex', width: '100%' }}>
                          {
                            (response.response.state !== 'Empty' && role_1 === 'Collaborator') &&
                            <div className="dropdown">
                              <div className="px-btn-dropdown-status dropdown-toggle" data-toggle="dropdown">
                                <div className="px-drop-donut mg-right-15" style={{ backgroundColor: stateColor }} />
                                <span className="status-text">{this.props.response.response.state}</span>
                                <FontAwesomeIcon icon={['fas', 'circle-chevron-down']} className="chevron" />
                              </div>
                              {
                                this.props.response.response.state !== 'Empty' &&
                                <ul className="dropdown-menu">
                                  <li>{stateMessage}</li>
                                </ul>
                              }
                            </div>
                          }
                          <div className="px-comment-likes-container">
                            {!this.props.isSavingLikeResponse ? likeBtn : <FontAwesomeIcon icon={['fas','spinner']} spin/>}

                          </div>
                        </div>
                        <button className={'btn btn-comments'} 
                          style={ classComments === 'px-n-accordion-open' ?
                            { color: '#ffffff', borderColor: colors.color1[3], backgroundColor: colors.color1[3] } :
                            { color: colors.color1[3], borderColor: colors.color1[3], backgroundColor: '#ffffff' }} 
                          onClick={() => this.props.updateShowComments(this.props.userId, response.question_id)}>
                          <div className="comments-container">
                            <FontAwesomeIcon icon={['fas', 'message']} className="icon-comments"/>
                            <span className="txt-comment">{i18n[language].comments}</span>
                            <div className="number-container">
                              <span className="number">{response.comments.length}</span>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div> :
                    <div className="profile-name text-center no-answer-container">
                      <div className="no-answer">{this.props.username} {i18n[language].hasntAnswered}</div>
                    </div>
                }

                <div className={classComments}>
                  <Comments comments={response.comments}
                            language={this.props.language}
                            userId={response.user.id}
                            username={this.props.username}
                            questionId={response.question_id}
                            responseId={response.response.id}
                            from={this.props.from}
                            updateAUResponses={this.props.updateAUResponses}
                            role_1={this.props.role_1}
                            updateResponseComments={this.props.updateResponseComments}
                            entity={this.props.entity}
                            scrollFirstTime={this.props.scrollFirstTime}
                            updateScrollFirstTime={this.props.updateScrollFirstTime}
                            updateShowComments={this.props.updateShowComments}
                            qc={this.props.qc}
                            item_scroll={item_scroll}
                            colors={colors} />
                </div>
              </div>

            </div>
            <div onClick={() => this.minimizeCard(response.question_id)} className="circle-bottom"
              style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={['far', 'up-down']} />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {cardHeader}
              <div className="panel px-card px-panel-task" style={{ borderLeft: '10px solid #f6f6f6'}}>
                <div className="panel-body">
                  {cardTitle}
                  {
                    showHeaderSeparator &&
                    <div className="separator" style={{ marginTop: '5px', marginBottom: '20px' }} />
                  }
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                      <div className="profile-name text-center no-answer-container">
                        <div className="no-answer">{this.props.username} {i18n[language].hasntAnswered}</div>
                        {
                          role_1 === 'Collaborator' &&
                          <span className="fa-layers fa-fw"
                            onClick={() => this.props.updateIsFirstFFComment(true, this.props.userId,
                              this.props.taskId)}>
                            <FontAwesomeIcon icon="message" className="icon" />
                            <FontAwesomeIcon icon="forward" color="white" className="inner-icon" transform="shrink-6" />
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <Comments comments={[]}
              updateAUResponses={this.props.updateAUResponses}
              updateResponseComments={this.props.updateResponseComments}
              language={this.props.language}
              userId={this.props.userId}
              username={this.props.username}
              questionId={this.props.taskId}
              responseId={-1}
              from={from}
              isFirstFFComment={this.props.isFirstFFComment}
              qc={[]}
              colors={colors} />
          </div>
        </div>
      )
    }
  }

  minimizeCard = (questionId) => {
    this.props.updateIsTaskMinimized(this.props.userId, questionId);

    const offset = this.props.hideTabFilters ? 100 : 350;
    if (!this.props.isTaskMinimized) {
      const id = '#' + this.props.userId + '_' + this.props.cardinal;
      const cardPosition = $(id).offset().top;
      //$(window.scrollTo({top: cardPosition - offset, behavior: 'smooth'}));
      $(window).scrollTop(cardPosition - offset);
    }
  };

  hideMenuAndRegenerate = (responseId) => {
    this.setState({ showPopupVerbatim: false });
    this.props.regenerateResponseVerbatim(responseId);
  };

  handlePopupVerbatim = () => {
    this.setState(prevSate => ({ showPopupVerbatim: !prevSate.showPopupVerbatim }));
  };

  updateShowModalVideo = () => {
    this.setState({ showModalVideo: !this.state.showModalVideo });
  };

  openLightbox = (index, event) => {
    //console.log('openLightbox', index);
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };
  gotoPrevious = () => {
    this.setState({ currentImage: this.state.currentImage - 1 });
  };
  gotoNext = () => {
    //console.log('gotoNext', this.state.currentImage);
    this.setState({ currentImage: this.state.currentImage + 1 });
  };
  gotoImage = (index) => {
    //console.log('gotoImage', index);
    this.setState({
      currentImage: index,
    });
  };

  _setResponseId(response) {
    console.log(response);
    let responseId = -1;
    if (response) {
      /*switch(this.props.from) {
        case 'Part_OpenEnd':
        case 'Coll_OP_ST_Questions':
          responseId = typeof response !== 'undefined' ? response.response.id : -1;
          break;
        case 'Coll_OA_ST_Response':
          responseId = response.id ? response.id : -1;
          break;
      }*/
    }
    //console.log('_setResponseId:', responseId);
    return responseId;
  }

  _getStateDropAction(state, question_id, pre_state, responseId) {
    let message = '';
    switch (state) {
      case 'Draft':
        message = <div onClick={() => this.props.updateResponse('Accepted', responseId)}
          style={{ display: 'flex', alignItems: 'center' }}>
          <div className="px-drop-donut" style={{ backgroundColor: '#BCF8A5' }} />
          <span className="px-state-li">Accepted</span>
        </div>;
        break;
      case 'Completed':
        message = <div onClick={() => this.props.updateResponse('Accepted', responseId)}
          style={{ display: 'flex', alignItems: 'center' }}>
          <div className="px-drop-donut" style={{ backgroundColor: '#BCF8A5' }} />
          <span className="px-state-li">Accepted</span>
        </div>;
        break;
      case 'Accepted':
        const colorState = pre_state === 'Draft' ? '#47505C' : '$turquoise-dark';
        message = <div onClick={() => this.props.updateResponse('Rejected', responseId)}
          style={{ display: 'flex', alignItems: 'center' }}>
          <div className="px-drop-donut" style={{ backgroundColor: colorState }} />
          <span className="px-state-li">{pre_state}</span>
        </div>;
        break;
    }

    return message;
  }

  _getStateColor(state) {
    let color = '';

    if (this.props.role_1 === 'Collaborator') {
      switch (state) {
        case 'Accepted':
          color = '#AAFB9B';
          break;
        case 'Completed':
          color = '#2FC8B7';
          break;
        case 'Draft':
          color = '#47505C';
          break;
        case 'Empty':
          color = '#B1BDC0';
      }
    } else {
      color = '#f6f6f6';
    }

    return color;
  }

  _setIconGender(gender) {
    let icon = null;

    switch (gender) {
      case 'Female':
        icon = 'venus'; break;
      case 'Male':
        icon = 'mars'; break;
      case 'Other':
        icon = 'transgender-alt'; break;
    }

    return icon && <FontAwesomeIcon icon={['fal', icon]} />;
  }
}

const isoCountries = {
  'AF': 'Afghanistan',
  'AX': 'Aland Islands',
  'AL': 'Albania',
  'DZ': 'Algeria',
  'AS': 'American Samoa',
  'AD': 'Andorra',
  'AO': 'Angola',
  'AI': 'Anguilla',
  'AQ': 'Antarctica',
  'AG': 'Antigua And Barbuda',
  'AR': 'Argentina',
  'AM': 'Armenia',
  'AW': 'Aruba',
  'AU': 'Australia',
  'AT': 'Austria',
  'AZ': 'Azerbaijan',
  'BS': 'Bahamas',
  'BH': 'Bahrain',
  'BD': 'Bangladesh',
  'BB': 'Barbados',
  'BY': 'Belarus',
  'BE': 'Belgium',
  'BZ': 'Belize',
  'BJ': 'Benin',
  'BM': 'Bermuda',
  'BT': 'Bhutan',
  'BO': 'Bolivia',
  'BA': 'Bosnia And Herzegovina',
  'BW': 'Botswana',
  'BV': 'Bouvet Island',
  'BR': 'Brazil',
  'IO': 'British Indian Ocean Territory',
  'BN': 'Brunei Darussalam',
  'BG': 'Bulgaria',
  'BF': 'Burkina Faso',
  'BI': 'Burundi',
  'KH': 'Cambodia',
  'CM': 'Cameroon',
  'CA': 'Canada',
  'CV': 'Cape Verde',
  'KY': 'Cayman Islands',
  'CF': 'Central African Republic',
  'TD': 'Chad',
  'CL': 'Chile',
  'CN': 'China',
  'CX': 'Christmas Island',
  'CC': 'Cocos (Keeling) Islands',
  'CO': 'Colombia',
  'KM': 'Comoros',
  'CG': 'Congo',
  'CD': 'Congo, Democratic Republic',
  'CK': 'Cook Islands',
  'CR': 'Costa Rica',
  'CI': 'Cote D\'Ivoire',
  'HR': 'Croatia',
  'CU': 'Cuba',
  'CY': 'Cyprus',
  'CZ': 'Czech Republic',
  'DK': 'Denmark',
  'DJ': 'Djibouti',
  'DM': 'Dominica',
  'DO': 'Dominican Republic',
  'EC': 'Ecuador',
  'EG': 'Egypt',
  'SV': 'El Salvador',
  'GQ': 'Equatorial Guinea',
  'ER': 'Eritrea',
  'EE': 'Estonia',
  'ET': 'Ethiopia',
  'FK': 'Falkland Islands (Malvinas)',
  'FO': 'Faroe Islands',
  'FJ': 'Fiji',
  'FI': 'Finland',
  'FR': 'France',
  'GF': 'French Guiana',
  'PF': 'French Polynesia',
  'TF': 'French Southern Territories',
  'GA': 'Gabon',
  'GM': 'Gambia',
  'GE': 'Georgia',
  'DE': 'Germany',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GR': 'Greece',
  'GL': 'Greenland',
  'GD': 'Grenada',
  'GP': 'Guadeloupe',
  'GU': 'Guam',
  'GT': 'Guatemala',
  'GG': 'Guernsey',
  'GN': 'Guinea',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HT': 'Haiti',
  'HM': 'Heard Island & Mcdonald Islands',
  'VA': 'Holy See (Vatican City State)',
  'HN': 'Honduras',
  'HK': 'Hong Kong',
  'HU': 'Hungary',
  'IS': 'Iceland',
  'IN': 'India',
  'ID': 'Indonesia',
  'IR': 'Iran, Islamic Republic Of',
  'IQ': 'Iraq',
  'IE': 'Ireland',
  'IM': 'Isle Of Man',
  'IL': 'Israel',
  'IT': 'Italy',
  'JM': 'Jamaica',
  'JP': 'Japan',
  'JE': 'Jersey',
  'JO': 'Jordan',
  'KZ': 'Kazakhstan',
  'KE': 'Kenya',
  'KI': 'Kiribati',
  'KR': 'Korea',
  'KW': 'Kuwait',
  'KG': 'Kyrgyzstan',
  'LA': 'Lao People\'s Democratic Republic',
  'LV': 'Latvia',
  'LB': 'Lebanon',
  'LS': 'Lesotho',
  'LR': 'Liberia',
  'LY': 'Libyan Arab Jamahiriya',
  'LI': 'Liechtenstein',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'MO': 'Macao',
  'MK': 'Macedonia',
  'MG': 'Madagascar',
  'MW': 'Malawi',
  'MY': 'Malaysia',
  'MV': 'Maldives',
  'ML': 'Mali',
  'MT': 'Malta',
  'MH': 'Marshall Islands',
  'MQ': 'Martinique',
  'MR': 'Mauritania',
  'MU': 'Mauritius',
  'YT': 'Mayotte',
  'MX': 'Mexico',
  'FM': 'Micronesia, Federated States Of',
  'MD': 'Moldova',
  'MC': 'Monaco',
  'MN': 'Mongolia',
  'ME': 'Montenegro',
  'MS': 'Montserrat',
  'MA': 'Morocco',
  'MZ': 'Mozambique',
  'MM': 'Myanmar',
  'NA': 'Namibia',
  'NR': 'Nauru',
  'NP': 'Nepal',
  'NL': 'Netherlands',
  'AN': 'Netherlands Antilles',
  'NC': 'New Caledonia',
  'NZ': 'New Zealand',
  'NI': 'Nicaragua',
  'NE': 'Niger',
  'NG': 'Nigeria',
  'NU': 'Niue',
  'NF': 'Norfolk Island',
  'MP': 'Northern Mariana Islands',
  'NO': 'Norway',
  'OM': 'Oman',
  'PK': 'Pakistan',
  'PW': 'Palau',
  'PS': 'Palestinian Territory, Occupied',
  'PA': 'Panama',
  'PG': 'Papua New Guinea',
  'PY': 'Paraguay',
  'PE': 'Peru',
  'PH': 'Philippines',
  'PN': 'Pitcairn',
  'PL': 'Poland',
  'PT': 'Portugal',
  'PR': 'Puerto Rico',
  'QA': 'Qatar',
  'RE': 'Reunion',
  'RO': 'Romania',
  'RU': 'Russian Federation',
  'RW': 'Rwanda',
  'BL': 'Saint Barthelemy',
  'SH': 'Saint Helena',
  'KN': 'Saint Kitts And Nevis',
  'LC': 'Saint Lucia',
  'MF': 'Saint Martin',
  'PM': 'Saint Pierre And Miquelon',
  'VC': 'Saint Vincent And Grenadines',
  'WS': 'Samoa',
  'SM': 'San Marino',
  'ST': 'Sao Tome And Principe',
  'SA': 'Saudi Arabia',
  'SN': 'Senegal',
  'RS': 'Serbia',
  'SC': 'Seychelles',
  'SL': 'Sierra Leone',
  'SG': 'Singapore',
  'SK': 'Slovakia',
  'SI': 'Slovenia',
  'SB': 'Solomon Islands',
  'SO': 'Somalia',
  'ZA': 'South Africa',
  'GS': 'South Georgia And Sandwich Isl.',
  'ES': 'Spain',
  'LK': 'Sri Lanka',
  'SD': 'Sudan',
  'SR': 'Suriname',
  'SJ': 'Svalbard And Jan Mayen',
  'SZ': 'Swaziland',
  'SE': 'Sweden',
  'CH': 'Switzerland',
  'SY': 'Syrian Arab Republic',
  'TW': 'Taiwan',
  'TJ': 'Tajikistan',
  'TZ': 'Tanzania',
  'TH': 'Thailand',
  'TL': 'Timor-Leste',
  'TG': 'Togo',
  'TK': 'Tokelau',
  'TO': 'Tonga',
  'TT': 'Trinidad And Tobago',
  'TN': 'Tunisia',
  'TR': 'Turkey',
  'TM': 'Turkmenistan',
  'TC': 'Turks And Caicos Islands',
  'TV': 'Tuvalu',
  'UG': 'Uganda',
  'UA': 'Ukraine',
  'AE': 'United Arab Emirates',
  'GB': 'United Kingdom',
  'US': 'United States',
  'UM': 'United States Outlying Islands',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VU': 'Vanuatu',
  'VE': 'Venezuela',
  'VN': 'Viet Nam',
  'VG': 'Virgin Islands, British',
  'VI': 'Virgin Islands, U.S.',
  'WF': 'Wallis And Futuna',
  'EH': 'Western Sahara',
  'YE': 'Yemen',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe'
};

function getCountryName(countryCode) {
  if (isoCountries.hasOwnProperty(countryCode)) {
    return isoCountries[countryCode];
  } else {
    return countryCode;
  }
}