import React from "react"
import PropTypes from 'prop-types'
import MessagesMain from "../MessagesMain"

const ChatPanel = ({ messages, currentUserId, language, avatar, colors, lastChatId, setLastChatId,
                     getResponseEmbeddings, setQuery, query, setIsChatApi, isChatApi, getAnswer, timerOn,
                     activeChatRoomId, btnMsg, setTemperature, setPanel, from, loadingResponseEmbeddings }) => {
  return (
    <MessagesMain messages={messages}
                  currentUserId={currentUserId}
                  language={language}
                  avatar={avatar}
                  colors={colors}
                  lastMsgId={lastChatId}
                  setLastChatId={setLastChatId}
                  getResponseEmbeddings={getResponseEmbeddings}
                  from={from}
                  loadingResponseEmbeddings={loadingResponseEmbeddings}/>
  )
}

ChatPanel.propTypes = {
  messages: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  colors: PropTypes.object.isRequired,
  lastChatId: PropTypes.number.isRequired,
  setLastChatId: PropTypes.func.isRequired,
  getResponseEmbeddings: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  setIsChatApi: PropTypes.func.isRequired,
  isChatApi: PropTypes.bool.isRequired,
  getAnswer: PropTypes.func.isRequired,
  timerOn: PropTypes.bool.isRequired,
  activeChatRoomId: PropTypes.number,
  btnMsg: PropTypes.object.isRequired,
  setTemperature: PropTypes.func.isRequired,
  setPanel: PropTypes.func.isRequired
}

export default ChatPanel