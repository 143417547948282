import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateRangePicker} from "react-dates";
import moment from 'moment';
import v4 from "uuid";

import CSEditorWYSIWYG from "../../../Settings/Utils/CSEditorWYSIWYG";
import {setIconGender} from "../../../../UI/Globals/PX_Funs";

export default class AEAttrEdit01 extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    updateTaskAttributeMode: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    cancelEditTaskAttributes: PropTypes.func.isRequired,
    updateTaskAttributes: PropTypes.func.isRequired,
    updateStartExpireDates: PropTypes.func.isRequired,
    onEditorStateChange: PropTypes.func.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    communityPartStart: PropTypes.string.isRequired,
    communityPartEnd: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      modalTitle: '',
      title: props.task.title
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {task} = this.props;

    if(prevProps.task.title !== task.title){
      this.setState({title: task.title})
    }
  }

  componentDidMount() {
    let btnHoverColor = this.props.colors.color2[3];
    $(".px-menu-btn").mouseenter(function () {
      $(this).css({ 'background-color': btnHoverColor });
    });
    $(".px-menu-btn").mouseleave(function () {
      $(this).css({ 'background-color': '' });
    });
  }

  render() {
    const {task, communitySocial, language, colors, communityPartStart, communityPartEnd} = this.props;

    const i18n = {
      en: {
        activityTitle: 'Activity Title', availableFromTo: 'Available from / to', cannotAnswer: 'Can\'t be answered once expired',
        activityInstructions: 'Activity instructions (optional)', displayMode: 'Display mode', private: 'Private', public: 'Public',
        tasksShown: 'Tasks shown', asAlist: 'As a list', onePerScreen: 'One per screen', otherOptions: 'Other options',
        onlyAskTo: 'Only ask to', tagIt: 'Tag it: ', requires: 'Requires', preview: 'Preview', cancel: 'Cancel', save: 'Save',
        privacyTooltip1: 'Participants may see each other\'s responses and comments for this activity',
        privacyTooltip2: 'Participants won\'t be able to see each other\'s responses and comments for this activity',
        asAlistTootlip: 'Participants see tasks all together listed in a screen.', 
        onePerScreenTooltip: 'Participants see each task in a different screen.',
        tagItTooltip: "Organize and sort your activities assigning them a custom tag. < br > This won't affect how participants see activities.",
        requiresTooltip: "Participants can respond this activity only after completing the selected activity.",
        onlyAskToTooltip: 'When active, only the selected segments will see this activity.'

      },
      es: {
        activityTitle: 'Título de la actividad', availableFromTo: 'Disponibilidad (inicio/ fin)', cannotAnswer: 'No se puede responder cuando ha expirado',
        activityInstructions: 'Instrucciones de la actividad (opcional)', displayMode: 'Modo', private: 'Privado',
        public: 'Público', tasksShown: 'Mostrar tareas', asAlist: 'En lista', onePerScreen: 'Una por pantalla',
        otherOptions: 'Otras opciones', onlyAskTo: 'Mostrar a: ',
        tagIt: 'Tags: ', requires: 'Requerimiento', preview: 'Muestra', cancel: 'Cancelar', save: 'Guardar',
        privacyTooltip1: 'Los participantes pueden ver las respuestas y comentarios de los demás para esta actividad.',
        privacyTooltip2: 'Los participantes no podrán ver las respuestas y comentarios de los demás para esta actividad.',
        asAlistTootlip: 'Los participantes ven las Tareas listadas en una misma pantalla.',
        onePerScreenTooltip: 'Los participantes ven las Tareas separadas, una por pantalla.',
        tagItTooltip: "Organiza tus Actividades asignándoles 'Tags'. <br> Esto no afecta como los participantes ven las Actividades",
        requiresTooltip: "Los participates solo pueden responder esta Actividad una vez que han completado las Actividades seleccionadas",
        onlyAskToTooltip: 'Solo los segmentos seleccionados podrán ver esta Actividad'
      }
    }

    const aSegGender = task.segments.filter(s => isNaN(s.value));
    const aSegments =task.segments.filter(s => !isNaN(s.value));
    const sortedSegments = [...aSegGender, ...aSegments];

    const justLabels = sortedSegments.map(segment => (segment.label));
    const segmentsTooltip = i18n[language].onlyAskTo + justLabels.join(', ');

    let segments = [];
    if(sortedSegments.length < 5) {
      segments = sortedSegments.map((segment, index) => {
        if(isNaN(segment.value)){
          return(
            <div key={index} className="genders-container">
              <div className="gender-container">{setIconGender(segment.label)}</div>
            </div>
          )
        } else {
          return(
            <FontAwesomeIcon key={index} icon={['fas', 'bookmark']} className="tag" style={{color:segment.color}}/>
          )
        }
      })
    } else {
      segments = sortedSegments.splice(0, 3).map((segment, index) => {
        if(isNaN(segment.value)){
          return(
            <div key={index} className="genders-container">
              <div className="gender-container">{setIconGender(segment.label)}</div>
            </div>
          )
        } else {
          // TO-DO after to select other activity and get back to it, then the tooltip doesn't work
          //console.log(sortedSegments); It looks doesn't happen in Edit mode
          return(
            <FontAwesomeIcon key={index} icon={['fas', 'bookmark']} className="tag" style={{color:segment.color}}/>
          )
        }
      });
      segments.push(<FontAwesomeIcon key={v4()} icon={['fas', 'ellipsis']} className="ellipsis"
                                     data-tooltip-content={sortedSegments.map(s => ' ' + s.label)}/>);
    }

    let tourPoint = <div className = 'px-tour-point top left clickable' style={{backgroundColor: colors.color1[2]}}/>;

    const partStart = moment(communityPartStart);
    const partEnd = moment(communityPartEnd).add(1, 'd');
    //console.log(partStart, partEnd)

    return (
      <div className="panel px-activity-card-details edit-mode">
        <div className="px-support-tooltip top is_hidden px-support-builder">
          <p>Customize your activity here, by setting when should it expire, who can see it and more.</p>
        </div>
        <div className="top-border"/>
        <div className="bottom-border"/>
        <div className="left-border"/>
        <div className="right-border"/>

        <div className="panel-body">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row px-actv-edit-container">
              <div className="px-left-actv-container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group first">
                      <label htmlFor="activity_name" className="label-xs" style={{color: colors.color1[2]}}>
                        {i18n[language].activityTitle}
                      </label>
                      <input name="title" onChange={this.handleChange} value={this.state.title}
                             onBlur={this.props.handleChange} type="text" className="form-control"/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="dates-container">
                      <div className="date-item">
                        <label htmlFor="startDate" className="label-xs" style={{ color: colors.color1[2] }}>
                          {i18n[language].availableFromTo}
                        </label>
                        <br/>
                        <DateRangePicker
                          startDate={task.start ? moment(task.start) : null} // momentPropTypes.momentObj or null,
                          startDateId="your_unique_start_date_id"
                          endDate={task.expire ? moment(task.expire) : null} // momentPropTypes.momentObj or null,
                          endDateId="your_unique_end_date_id"
                          onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate },
                            () => this.showDates())} // PropTypes.func.isRequired,
                          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                          minimumNights={0}
                          displayFormat="DD/MM/YYYY"
                          isOutsideRange={date => date.isBefore(partStart) || date.isAfter(partEnd)}
                          isDayHighlighted={ day => day.isSame( moment(), 'd' ) }
                          numberOfMonths={1}
                          daySize={27}
                          hideKeyboardShortcutsPanel={true}
                          minDate={partStart}
                          maxDate={partEnd}
                        />
                        <FontAwesomeIcon icon={['far', 'xmark']} className="info-dialog"
                                         onClick={this.clearDates} style={{cursor:'pointer'}}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="px-check-cont mg-top-25 mg-bottom-15" >
                      <label className="container-check">
                          <input type="checkbox" name="overdue" onChange={this.props.handleChange} checked={task.overdue}/>
                          <span className="checkmark"/>
                          <span>{i18n[language].cannotAnswer}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row" style={{marginTop: '10px'}}>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <label htmlFor="instructions" className="label-xs" style={{paddingBottom: '10px', color: colors.color1[2]}}>
                      {i18n[language].activityInstructions}
                    </label>
                    <CSEditorWYSIWYG name="instructions"
                                    editorName="editorTaskInstructions"
                                    editorState={task.editorTaskInstructions}
                                    onEditorStateChange={this.props.onEditorStateChange}
                                    language={language}/>
                  </div>
                </div>
              </div>
              <div className="px-right-actv-container">
                {
                  communitySocial &&
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col- md-12 col-lg-12">
                      <label htmlFor="" className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].displayMode}</label>
                      <div className="px-check-cont rad">
                        <label className="container-check radio">
                            <input type="radio" name="social" onChange={this.props.handleChange}
                                checked={task.social === 'Private'} className="radio-btn" value="Private"/>
                            <span className="checkmark radio"/>
                          <span>{i18n[language].private}</span>
                            <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                              data-tooltip-content={i18n[language].privacyTooltip2}/>
                        </label>
                      </div>

                      <div className="px-check-cont rad">
                        <label className="container-check radio">
                          <input type="radio" name="social" onChange={this.props.handleChange}
                                checked={task.social === 'Public'} className="radio-btn" value="Public"/>
                          <span className="checkmark radio"/>
                          <span>{i18n[language].public}</span>
                          <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                           data-tooltip-content={i18n[language].privacyTooltip1}/>
                        </label>
                      </div>
                    </div>
                  </div>
                }

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col- md-12 col-lg-12">
                    <label htmlFor="" className="label-xs" style={{ color: colors.color1[2] }}>{i18n[language].tasksShown}</label>
                    <div className="px-check-cont rad">
                      <label className="container-check radio">
                        <input type="radio" name="participants_view" onChange={this.props.handleChange}
                              checked={task.participants_view === 'As a list'} className="radio-btn" value="As a list"/>
                        <span className="checkmark radio"/>
                        <span>{i18n[language].asAlist}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                         data-tooltip-content={i18n[language].asAlistTootlip}/>
                      </label>
                    </div>

                    <div className="px-check-cont rad">
                      <label className="container-check radio">
                        <input type="radio" name="participants_view" onChange={this.props.handleChange}
                              checked={task.participants_view === 'One per screen'} className="radio-btn"
                              value="One per screen"/>
                        <span className="checkmark radio"/>
                        <span>{i18n[language].onePerScreen}</span>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                              data-tooltip-content={i18n[language].onePerScreenTooltip}/>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col- md-12 col-lg-12">
                    <label htmlFor="" className="label-xs mg-bottom-10" style={{ color: colors.color1[2] }}>
                      {i18n[language].otherOptions}
                    </label>
                    <div className="px-actions-container">
                      <div className="px-action-item">
                          {
                            task.segments.length !== 0 ?
                              <button onClick={() => this.props.updateShowModal('segments', -1)}
                                      className="btn px-btn sm btn-gray-darker inverted btn-action"
                                      data-tooltip-content={segmentsTooltip}>
                              <div className="tags-container" style={{border:'none',cursor:'pointer'}}>
                                { segments }
                              </div>
                              </button> :
                              <button onClick={() => this.props.updateShowModal('segments', -1)}
                                      className="btn px-btn sm btn-gray-darker inverted btn-action">
                                <FontAwesomeIcon icon={['fas', 'filter']} className=""/>
                              <span>{i18n[language].onlyAskTo}</span>
                              </button>
                          }
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                         data-tooltip-content={i18n[language].onlyAskToTooltip}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col- md-12 col-lg-12">
                    <div className="px-actions-container">
                      <div className="px-action-item">
                          {
                            task.tag ?
                              <button onClick={() => this.props.updateShowModal('tag', -1)}
                                      className="btn px-btn sm btn-gray-darker inverted btn-action"
                                      data-tooltip-content={i18n[language].tagIt + task.tag.name}>
                                <FontAwesomeIcon icon={['fas', 'tag']} style={{color:task.tag.color}}/>
                              </button> :
                              <button onClick={() => this.props.updateShowModal('tag', -1)}
                                      className="btn px-btn sm btn-gray-darker inverted btn-action">
                                <FontAwesomeIcon icon={['fas', 'tag']} className=""/>
                              <span>{i18n[language].tagIt}</span>
                              </button>
                          }
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                          data-tooltip-content={i18n[language].tagItTooltip}/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col- md-12 col-lg-12">
                    <div className="px-actions-container" style={{flexDirection:'unset'}}>
                      <div className="px-action-item">
                        <button onClick={() => this.props.updateShowModal('required', -1)}
                                className="btn px-btn sm btn-gray-darker inverted btn-action">
                          <FontAwesomeIcon icon={['fas', 'square-check']} className=""/>
                          <span>{task.required ? task.required.label : i18n[language].requires}</span>
                        </button>
                        <FontAwesomeIcon icon={['far', 'circle-info']} className="info-dialog"
                                         data-tooltip-content={i18n[language].requiresTooltip}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-save-row">
                <button onClick={() => this.props.updateShowModal('preview Activity', -1)}
                    className="btn-preview" disabled={task.questions.length === 0} style={{position: 'relative'}}>
                      {i18n[language].preview}
                      {task.questions.length > 0 && tourPoint}
                </button>
                <div className="action-button-container">
                  <button onClick={this.props.updateTaskAttributes} className="btn-save" disabled={this.state.title === ''}
                    style={{backgroundColor: colors.color1[3]}}>
                    {i18n[language].save}
                  </button>
                  <button onClick={this.props.cancelEditTaskAttributes} className="btn-cancel">{i18n[language].cancel}</button>
                </div>
              </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="px-actions-panel">
          <div className="px-actions-container">
            <button onClick={this.props.updateTaskAttributes} className="btn px-btn sm circle px-menu-btn edit">
              <FontAwesomeIcon icon={['far', 'check']} className="px-icon-menu"/>
            </button>
            <button onClick={this.props.cancelEditTaskAttributes} className="btn px-btn sm circle px-menu-btn edit">
              <FontAwesomeIcon icon={['far', 'xmark']} className="px-icon-menu"/>
            </button>
          </div>
        </div>
      </div>
    );
  }

  clearDates = () => {
    //this.setState({ startDate: null, endDate: null });
    this.props.updateStartExpireDates(null, null);
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value});
  };

  // Maybe onDatesChange just needs to send the dates, but this.state is to show dates in the calendar
  showDates = () => {
    const startDate = this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : null;
    const endDate = this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null;

    this.props.updateStartExpireDates(startDate, endDate);
  };

  updateModalTitle = (title) => {
    this.setState({modalTitle: title}, () => {
      this.props.updateShowModalSelect();
    });
  }
}
