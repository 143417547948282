
export function usernamesToDisplay (user, preference='alias', include = ["all"], deliver='array') {
  let namesToDisplay = [];
  let includeAllNameTypes = include === "all" || include.includes("all");
  let includeNaturalUsername = includeAllNameTypes || include.includes("natural");
  let includeAlias = includeAllNameTypes || include.includes("alias");
  let includeUsername = includeAllNameTypes || include.includes("username");

  if (user) {
    const natural_username = includeNaturalUsername &&
      (user.natural_username || user.cu_natural_username || user.user_natural_username || user.from_natural_username) || null;
    const user_alias = includeAlias &&
      (user.user_alias || user.alias_from) || null;
    const username = includeUsername &&
      (user.username || user.user_username || user.from_username ) || null;

    switch (preference) {
      case 'alias':
        user_alias && namesToDisplay.push(user_alias)
        natural_username && namesToDisplay.push(natural_username)
        username && namesToDisplay.push(username)
        break;
      case 'natural_username':
        natural_username && namesToDisplay.push(natural_username)
        user_alias && namesToDisplay.push(user_alias)
        username && namesToDisplay.push(username)
        break;
      default:
        username && namesToDisplay.push(username)
        user_alias && namesToDisplay.push(user_alias)
        natural_username && namesToDisplay.push(natural_username)
    }
  }

  if (deliver === 'string') {
    return namesToDisplay.join(', ')
  } else {
    return namesToDisplay
  }
}


export function formatComments(comments) {
  if (!comments || comments.length === 0) {
    return "";
  }

  return comments.map((comment) => {
    const commentUsername = comment.user_alias || comment.natural_username || comment.username;
    const replies = comment.replies.map((reply) => {
      const replyUsername = reply.user_alias || reply.natural_username || reply.username;
      return `**Reply by ${replyUsername}**: ${reply.reply_text}`;
    }).join(" // ");
    return `**Comment by ${commentUsername}**: ${comment.comment_text}\n${replies}`;
  }).join(" // ");
}