import React, {useEffect, useRef, useState} from "react"
import PropTypes from 'prop-types'
import v4 from "uuid"
import LinesEllipsis from "react-lines-ellipsis"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Lightbox from "react-images"
import { Tooltip as ReactTooltip } from "react-tooltip"
import {formatComments, usernamesToDisplay} from "../../../../Community/shared_utils"
import {getCountryName, setIconGender, showSuccessMsg} from "../../../../UI/Globals/PX_Funs"

const LPRow = ({ response, language, colors, rowIndex, activeOptionsMenu, setActiveOptionsMenu, activeChildOptionsMenu,
                 setActiveChildOptionsMenu }) => {
  const [collapse, setCollapse] = useState(false)
  const [showUserTooltip, setShowUserTooltip] = useState(false);
  const [lb, setLB] = useState({ lightboxIsOpen: false, currentImage: 0, showModalVideo: false })
  const [showEmotionList, setShowEmotionList] = useState(false);
  const didMountRef = useRef(false)
  let sentimentColor = null
  let sentimentTooltip = null
  const images = []

  const I18n = {
    en: {more: 'More', less: 'Less', copy: 'Copy', options: 'Options', relevance: 'Relevance', copiedToClipboard: 'Copied to Clipboard',
      whatsRelevance: 'The closer to 100% the more it explains your query', hide: 'Hide', noEmotion: 'No emotion', task: 'Task'},
    es: {more: 'Más', less: 'Less', copy: 'Copiar', options: 'Opciones', relevance: 'Relevancia', copiedToClipboard: 'Copiado al portapapeles',
      whatsRelevance: 'Cuanto más cerca del 100%, mejor explica tu consulta', hide: 'Esconder', noEmotion: 'Sin emoción', task: 'Tarea'}
  }

  useEffect(() => {
    if(didMountRef.current){

    } else didMountRef.current = true
  })

  switch(response.sentiment){
    case 1:
      sentimentColor = '#52C952FF';
      sentimentTooltip = {en: 'Positive', es: 'Positivo'}
      break
    case 0:
      sentimentColor = '#afbdc1';
      sentimentTooltip = {en: 'Neutral', es: 'Neutral'}
      break
    case -1:
      sentimentColor = '#b61b0e';
      sentimentTooltip = {en: 'Negative', es: 'Negativo'}
      break
    default:
      sentimentColor = '#000000';
      sentimentTooltip = {en: 'N/A', es: 'ND'}
  }

  useEffect(() => {
    const handleClick = (event) => {
      if (!event.target.closest('.emoji-emotion')) {
        setShowEmotionList(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const emotionIcons = {
    joy: { en: "Joy", es: "Alegría", icon: "😊", order: "primary" },
    trust: { en: "Trust", es: "Confianza", icon: "🤝", order: "primary" },
    fear: { en: "Fear", es: "Miedo", icon: "😨", order: "primary" },
    surprise: { en: "Surprise", es: "Sorpresa", icon: "😮", order: "primary" },
    sadness: { en: "Sadness", es: "Tristeza", icon: "😢", order: "primary" },
    disgust: { en: "Disgust", es: "Asco", icon: "🤢", order: "primary" },
    anger: { en: "Anger", es: "Enojo", icon: "😠", order: "primary" },
    anticipation: { en: "Anticipation", es: "Anticipación", icon: "🤔", order: "primary" },
    love: { en: "Love", es: "Amor", icon: "❤️", order: "secondary" },
    submission: { en: "Submission", es: "Sumisión", icon: "🙇", order: "secondary" },
    amazement: { en: "Amazement", es: "Asombro", icon: "😯", order: "secondary" },
    disapproval: { en: "Disapproval", es: "Desaprobación", icon: "👎", order: "secondary" },
    remorse: { en: "Remorse", es: "Remordimiento", icon: "😔", order: "secondary" },
    contempt: { en: "Contempt", es: "Desprecio", icon: "😒", order: "secondary" },
    aggressiveness: { en: "Aggressiveness", es: "Agresividad", icon: "💥", order: "secondary" },
    optimism: { en: "Optimism", es: "Optimismo", icon: "🌞", order: "secondary" },
    serenity: { en: "Serenity", es: "Serenidad", icon: "😌", order: "tertiary" },
    acceptance: { en: "Acceptance", es: "Aceptación", icon: "🤗", order: "tertiary" },
    worry: { en: "Worry", es: "Preocupación", icon: "😟", order: "tertiary" },
    distracted: { en: "Distracted", es: "Distraído", icon: "😵", order: "tertiary" },
    thoughtful: { en: "Thoughtful", es: "Pensativo", icon: "🧐", order: "tertiary" },
    boredom: { en: "Boredom", es: "Aburrimiento", icon: "😐", order: "tertiary" },
    irritation: { en: "Irritation", es: "Irritación", icon: "😖", order: "tertiary" },
    interest: { en: "Interest", es: "Interés", icon: "👀", order: "tertiary" },
    vigilance: { en: "Vigilance", es: "Vigilancia", icon: "🚨", order: "tertiary" },
    ecstasy: { en: "Ecstasy", es: "Éxtasis", icon: "🤩", order: "tertiary" },
    admiration: { en: "Admiration", es: "Admiración", icon: "👏", order: "tertiary" },
    terror: { en: "Terror", es: "Terror", icon: "😱", order: "tertiary" },
    pain: { en: "Pain", es: "Dolor", icon: "😣", order: "tertiary" },
    pessimism: { en: "Pessimism", es: "Pesimismo", icon: "🌧️", order: "tertiary" },
    solemnity: { en: "Solemnity", es: "Solemnidad", icon: "🕯️", order: "tertiary" },
    satisfaction: { en: "Satisfaction", es: "Satisfacción", icon: "😌", order: "tertiary" },
    envy: { en: "Envy", es: "Envidia", icon: "😒", order: "tertiary" },
    torment: { en: "Torment", es: "Tormento", icon: "😰", order: "tertiary" },
    rapture: { en: "Rapture", es: "Arrebato", icon: "🕺", order: "tertiary" },
    guilt: { en: "Guilt", es: "Culpa", icon: "😓", order: "tertiary" },
    curiosity: { en: "Curiosity", es: "Curiosidad", icon: "🔍", order: "tertiary" },
    anxiety: { en: "Anxiety", es: "Ansiedad", icon: "😥", order: "tertiary" },
    jubilation: { en: "Jubilation", es: "Júbilo", icon: "🎉", order: "tertiary" },
    identification: { en: "Identification", es: "Identificación", icon: "🔗", order: "tertiary"},
    none: { en: "None", es: "Ninguno", icon: "😶", order: "none"}
  }

  const emotions = (response.emotions || []).map(emotion => emotion.toLowerCase());
  const firstEmotion = emotions ? emotions[0] : null;

  const firstEmotionIcon = (firstEmotion && firstEmotion !== 'none' && emotionIcons[`${firstEmotion}`])
      ? <span className='emoji-emotion' data-tooltip-content={emotionIcons[`${firstEmotion}`]?.[language]} onClick={() => setShowEmotionList(!showEmotionList)}>{emotionIcons[`${firstEmotion}`]?.['icon']}</span> :
      <span className='emoji-emotion' style={{cursor: firstEmotion && firstEmotion !== 'none' && emotionIcons[`${firstEmotion}`] ? "pointer" : "default"}} data-tooltip-content={emotionIcons['none']?.[language]}>{emotionIcons['none']?.['icon']}</span>;

  const allEmotions = emotions ? emotions : [];
  const allEmotionsList = allEmotions.length > 0 ? (
      <ul className='ai-dropdown-menu alt-options-menu emoji-emotion-list' style={{ position: 'absolute', display: showEmotionList ? 'block' : 'none' }}>
        {allEmotions.map((emotion, index) => (
            <li key={index} className='emoji-emotion-name' data-tooltip-content={emotionIcons[`${emotion}`]?.[language]}>
              <span><span className='emoticon'>{`${emotionIcons[`${emotion}`]?.['icon']}`}</span>{emotionIcons[`${emotion}`]?.[language]}</span>
            </li>
        ))}
      </ul>
  ) : null;

  //console.log('sentimentColor:', sentimentColor)

  const openLightbox = (index, event) => {
    event.preventDefault();
    setLB({...lb, currentImage: index, lightboxIsOpen: true});
  };
  const closeLightbox = () => {setLB({...lb, currentImage: 0, lightboxIsOpen: false})}
  const gotoPrevious = () => {setLB({...lb, currentImage: lb.currentImage - 1 })}
  const gotoNext = () => {setLB({...lb, currentImage: lb.currentImage + 1 })}
  const gotoImage = index => {setLB({...lb, currentImage: index})}

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
        () => {
          showSuccessMsg('Copied to Clipboard');
        },
        (err) => {
          console.error("Could not copy text: ", err);
        }
    );
  };

  const ellipsisValue = <span onClick={(event) => {
    event.stopPropagation();
    setCollapse(!collapse);
  }} className='expand-ellipsis'
                              style={{color: colors.color2[3]}}>  ...{I18n[language].more}</span>

  const regionCountry = response.user.region === '' || response.user.region === undefined ?
      getCountryName(response.user.country) : response.user.region + ', ' + getCountryName(response.user.country);

  const availableUsernames = usernamesToDisplay(response?.user, "alias");
  const mainUsername = availableUsernames[0];
  const alternativeUsernames = availableUsernames.length > 1 ? availableUsernames.slice(1).join(', ') : '';

  const formattedComments = formatComments(response.comments);
  const responseText = response.response ? response.response : "";
  const responseTextAndComments = formattedComments ? `${responseText} // ${formattedComments}` : responseText;

  return (
      <div className="lp-row">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="lp-row-col user-container">
          <div className="px-user-container" style={{ minWidth: "100%", padding: "0" }}>
            <div className="left-container" style={{position: "relative"}} onMouseEnter={() => setShowUserTooltip(true)} onMouseLeave={() => setShowUserTooltip(false)}>
              <div className="px-user-tooltip" style={{display: showUserTooltip ? 'block' : 'none'}}>
                <div className="title">{mainUsername}</div>
                { alternativeUsernames &&
                    <div style={{fontSize: '12px'}}>
                      <span><strong>aliases:</strong> <span style={{fontStyle: 'italic'}}>{alternativeUsernames}</span></span>
                    </div>
                }
                <div className='info-container'>
                  {
                    response.user.gender && response.user.gender !== '' &&
                    <div className="px-genders-container">
                      <div className="gender-container"
                           data-tooltip-content={response.user.gender}>{setIconGender(response.user.gender)}</div>
                    </div>
                  }
                  {
                    response.user.country && response.user.country !== '' &&
                    <div className="px-country-container" data-tooltip-content={regionCountry}>
                      <span>{response.user.country}</span>
                    </div>
                  }
                  <div className='px-tags-container'>
                    {
                      response.user.segments?.map(segment => {
                        return (
                          <FontAwesomeIcon key={segment.id} icon={['fas', 'bookmark']} className="tag"
                                           style={{color: segment.color}} data-tooltip-content={segment.name}/>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div style={{position: "relative"}}>
              <div className="react-contextmenu-wrapper">
                  <img src={response.user?.avatar} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lp-row-col response-container">
          {!collapse ? (
            <React.Fragment>
              <LinesEllipsis
                text={responseTextAndComments}
                maxLine="2"
                ellipsis={ellipsisValue}
                trimRight
                basedOn="letters"

              />
              {
                response.response_children.length !== 0 &&
                <div className="px-item-info" style={{cursor: 'pointer', color:'#afbdc1fa'}}>
                  <FontAwesomeIcon onClick={() => setCollapse(prevCollapse => !prevCollapse)} icon={["fas", "image"]}/>
                </div>
              }
            </React.Fragment>
          ) : (
              <span>{responseTextAndComments}
              <span onClick={() => setCollapse(!collapse)} className='expand-ellipsis'
                    style={{color: colors.color2[3]}}>  ...{I18n[language].less}</span>
              </span>
          )}
          <div>
            {collapse &&
                response.response_children?.map((attachment, index) => {
                  images.push({ src: attachment.attachment_url });

                  let attSentimentColor = "";
                  let attSentimentTooltip = "";
                  switch (attachment.sentiment) {
                    case 1:
                      attSentimentColor = '#52C952FF';
                      attSentimentTooltip = {en: 'Positive', es: 'Positivo'}
                      break;
                    case 0:
                      attSentimentColor = '#afbdc1';
                      attSentimentTooltip = {en: 'Neutral', es: 'Neutral'}
                      break;
                    case -1:
                      attSentimentColor = '#b61b0e';
                      attSentimentTooltip = {en: 'Negative', es: 'Negativo'}
                      break;
                    default:
                      attSentimentColor = '#000000';
                      attSentimentTooltip = {en: 'N/A', es: 'ND'}
                  }

                  const isLastItem = index === response.response_children.length - 1;
                  const attachmentRowStyle = isLastItem
                      ? {}
                      : { borderBottom: "1px solid #DADDE1" };

                  //console.log('attSentimentColor:', attSentimentColor)
                  return (
                      <div key={v4()} className="attachment-row" style={attachmentRowStyle}>
                        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
                        <div className="attachment-row-col">
                          <div className="px-attachments-container">
                            <div className="px-media-img">
                              <div className="px-image-item">
                                <img
                                    onClick={(e) => openLightbox(index, e)}
                                    src={attachment.attachment_thumbnail}
                                    style={{ marginRight: "10px", cursor: "pointer" }}
                                    alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="attachment-row-col text-container">{attachment.response}</div>
                        <div className="attachment-row-col sentiment-container">
                          <div className="status-item"
                               style={{fontSize: '16px', marginRight: '5px', color: attSentimentColor}}
                               data-tooltip-content={attSentimentTooltip[language]}>
                                <FontAwesomeIcon icon={['fas','circle']} />
                          </div>
                          <div id="copy-children"
                              className="status-item"
                              style={{ fontSize: "16px", marginRight: "5px", color: "#DADDE1", cursor: "pointer" }}
                              // data-tooltip-content={I18n[language].copy}
                              onClick={() => copyToClipboard(attachment.response)}>
                              <FontAwesomeIcon icon={["fas", "copy"]} />
                          </div>
                          <div id='alt-menu-item' onClick={(event) => {
                            event.stopPropagation();
                            setActiveChildOptionsMenu(activeChildOptionsMenu === `${rowIndex}-${index}` ? -1 : `${rowIndex}-${index}`);
                          }}
                               className="status-item alt-options" style={{fontSize: '20px', marginRight: '5px', marginLeft: '5px', color: '#DADDE1', cursor: 'pointer'}}>
                            <FontAwesomeIcon icon={['fas','ellipsis-vertical']} />
                            <ul className='ai-dropdown-menu alt-options-menu' style={{ display: activeChildOptionsMenu === `${rowIndex}-${index}` ? "block" : "none" }}>
                              <li style={{cursor: 'default'}}><div data-tooltip-content={I18n[language].whatsRelevance}><span>{attachment.percentage}</span><span>{I18n[language].relevance}</span></div></li>
                              <li><div><span><FontAwesomeIcon icon={['fas','trash']} /></span><span>{I18n[language].hide}</span></div></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                  );
                })}
          </div>
        </div>
        <div className="lp-row-col percentage-container">
          <div className="status-item" style={{fontSize: '19px', marginRight: '5px', color: sentimentColor}}
               data-tooltip-content={sentimentTooltip[language]} >
            <FontAwesomeIcon icon={['fas','circle']} />
          </div>
          <div className="status-item" style={{fontSize: '16px', marginRight: '5px', position: "relative"}}
               data-tooltip-content={firstEmotion}>
            {firstEmotionIcon}
            {allEmotionsList}
          </div>
          <div id="copy-main"
               className="status-item"
               style={{ fontSize: "16px", marginRight: "5px", color: "#DADDE1", cursor: "pointer" }}
               // data-tooltip-content={I18n[language].copy}
               onClick={() => copyToClipboard(responseTextAndComments)}>
                <FontAwesomeIcon icon={["fas", "copy"]} />
          </div>
          <div className="status-item" id='menu-item'
               style={{fontSize: '18px', marginRight: '5px', marginLeft: '5px', color: '#DADDE1', cursor: 'pointer', position: 'relative'}}
               // data-tooltip-content={I18n[language].options}
               onClick={(event) => {
                 event.stopPropagation();
                 setActiveOptionsMenu(activeOptionsMenu === rowIndex ? -1 : rowIndex);
               }}
            >
            <FontAwesomeIcon icon={['fas','ellipsis-vertical']} />
            <ul className='ai-dropdown-menu options-menu' style={{display: activeOptionsMenu === rowIndex ? "block" : "none" }} >
              <li style={{cursor: 'default'}}>
                <div data-tooltip-content={I18n[language].whatsRelevance}>
                  <span>{response.percentage}</span><span>{I18n[language].relevance}</span>
                </div>
              </li>
              <li>
                <div><span><FontAwesomeIcon icon={['fas','trash']} /></span><span>{I18n[language].hide}</span></div>
              </li>
              <li>
                <div data-tooltip-content={response.task_title}>
                  <span>
                    <FontAwesomeIcon icon={['fas','circle-info']} /></span><span>{I18n[language].task}
                </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <Lightbox
            currentImage={lb.currentImage}
            images={images}
            isOpen={lb.lightboxIsOpen}
            onClose={closeLightbox}
            onClickPrev={gotoPrevious}
            onClickNext={gotoNext}
            onClickThumbnail={gotoImage}
        />
      </div>
  );
};

LPRow.propTypes = {
  response: PropTypes.object.isRequired
}

export default LPRow