import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';

const HelpTip = (props) => {
  const {
    showRequisites = false,
    tipKey = '',
    text = '',
    colors = {},
    communityId,
    userId,
    initialHelpStores = {},
    fontSize = '14px',
    showArrow = true,
    arrowDirection = 'left',
    arrowSize = '10px',
    placement = '',
    top, left, right, bottom,
    arrowOffset = ''
  } = props;

  const [helpStores, setHelpStores] = useState(initialHelpStores);

  const helpStoreItem = tipKey ? `show${tipKey.charAt(0).toUpperCase()}${tipKey.slice(1)}` : '';
  const displayHelpTip = showRequisites && helpStores && helpStores[helpStoreItem];
  const arrowIcon = `arrow-${arrowDirection}`;
  const borderColor = (colors && colors.color2 && colors.color2[2]) ? colors.color2[2] : '#000'; // default to black if undefined

  const handleClickHelpTip = async (e, item) => {
    e.preventDefault();
    let value = false;

    try {
      const response = await fetch(`/community_users/help_stores/${communityId}/${userId}/${item}/${value}`, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      });

      if (response.ok) {
        const data = await response.json();
        setHelpStores(data);
      } else {
        console.error("Error updating help store", response.statusText);
      }
    } catch (error) {
      console.error("Error updating help store", error);
    }
  };

  return (
    <CSSTransition
      in={displayHelpTip}
      timeout={1000}
      classNames="fade"
      unmountOnExit
    >
      <div
        className={`px-support-tooltip ${placement} px-support-participant`}
        style={{
          position: 'absolute',
          backgroundColor: "#ffffff",
          color: "#7C8593",
          border: '2px solid',
          borderColor: borderColor,
          borderRadius: '8px',
          fontSize: fontSize,
          padding: '5px 10px',
          width: '200px',
          textAlign: 'center',
          top: top,
          left: left,
          right: right,
          bottom: bottom,
          opacity: helpStores[helpStoreItem] === true ? 1 : 0,
          transition: '1s opacity ease'
        }}
      >
        <p style={{
          display: 'flex',
          flexDirection: (arrowDirection === 'up' || arrowDirection === 'down') ? 'column' : 'row',
          justifyContent: arrowDirection === 'up' || arrowDirection === 'down' ? 'center' : arrowDirection === 'right' ? 'flex-end' : 'flex-start',
          alignItems: 'center',
          alignContent: 'center'
        }}>
          {(arrowDirection !== 'right' && arrowDirection !== 'down') &&
              <FontAwesomeIcon
                style={{
                  fontSize: arrowSize,
                  marginRight: (arrowDirection === 'left' || arrowDirection === 'right') ? '3px' : '0',
                  marginLeft: arrowOffset,
                  marginBottom: arrowDirection === 'up' ? '3px' : '0',
                  display: showArrow ? 'inline-block' : 'none'
                }}
                icon={['fas', arrowIcon]}
              />
            }
            {text}
            {(arrowDirection === 'right' || arrowDirection === 'down') &&
              <FontAwesomeIcon
                style={{
                  fontSize: arrowSize,
                  marginLeft: arrowDirection === 'right' ? '3px' : '0',
                  marginTop: arrowDirection === 'down' ? '3px' : '0',
                  marginRight: arrowOffset,
                  display: showArrow ? 'inline-block' : 'none'
                }}
                icon={['fas', arrowIcon]}
              />
            }
          </p>
        <div
          style={{
            position: "absolute",
            top: '-5px',
            right: arrowDirection === 'right' ? 'auto' : '-5px',
            left: arrowDirection === 'right' ? '-5px' : 'auto',
            bottom: 'auto',
            fontSize: '20px',
            color: colors.color2[2],
            cursor: "pointer"
          }}
          onClick={(e) => handleClickHelpTip(e, helpStoreItem)}
        >
          <FontAwesomeIcon icon={['fas', 'circle-xmark']} />
        </div>
      </div>
    </CSSTransition>
  );
};

export default HelpTip;