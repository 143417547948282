import PropTypes from 'prop-types';
import React from 'react';
import TimeAgo from 'react-timeago';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dotdotdot from "react-dotdotdot";

export default class PrivateMessages extends React.Component {
  static propTypes = {
    pmIsOpen: PropTypes.bool.isRequired,
    updatePMIsOpen: PropTypes.func.isRequired,
    objPM: PropTypes.object.isRequired,
    rooms: PropTypes.array,
    from: PropTypes.string.isRequired,
    rightDrawerToggleHandler: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      pmIsOpen: props.pmIsOpen,
      objPM: props.objPM,
      body: '',
      isRoomList : props.from === 'Community_Menu',
      messages: [],
      currentUserId: -1,
      roomUsername: '',
      roomId: -1
    };
  }

  componentWillReceiveProps(nextProps){
    //console.log(nextProps);
    if(this.props !== nextProps){
      this.setState({
        pmIsOpen: nextProps.pmIsOpen,
        objPM: nextProps.objPM,
        isRoomList : nextProps.from === 'Community_Menu'
      })
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  render() {
    const width = this.state.pmIsOpen ? '300px' : '0';

    let style =  this.props.from === 'Community_Menu' ? {width:width,backgroundColor:'#f6f6f6',color:'#434e5e'} :
      {width:width,backgroundColor:'#f6f6f6',color:'#434e5e'};
    const roomsArray = this.props.from === 'Community_Menu' ? this.props.rooms : this.state.objPM.rooms;
    const  rooms = roomsArray.map((room, index) => {
      const avatar = room.user_avatar !== '' ? room.user_avatar : '/assets/user.png';
      const timeAgo = room.last_msg.length !== 0 ?
        <TimeAgo date={room.last_msg[0].created_at} style={{position:'absolute',right:'20px',fontSize:'10px'}}/> : '';

      return(
        <div key={index} onClick={() => this.getPM(room.id, room.user_username)} className="row"
             style={{padding:'10px 15px',cursor:'pointer'}}>
          <div style={{display:'flex'}}>
            <div className="col-md-1" style={{width:'55px'}}>
              <img src={avatar} style={{width:'30px',borderRadius:'50%',border:'2px #433566 solid'}}/>
            </div>
            <div className="col-md-10" style={{paddingLeft:'5px'}}>
              { timeAgo }
              <p style={{marginBottom:'0',fontSize:'18px'}}>{room.user_username}</p>
              <p style={{marginBottom:'0',fontSize:'10px',marginTop:'-5px',color:'#c1c1c1'}}>{room.user_email}</p>
              <p style={{marginBottom:'0',fontSize:'12px'}}>{room.last_msg[0] ? room.last_msg[0].body : ''}</p>
            </div>
          </div>
          <hr style={{margin:'15px 0 0 0'}}/>
        </div>
      )
    });

    const messagesArray = this.state.objPM.messages ? this.state.objPM.messages : this.state.messages;
    const messages = messagesArray.map((message, index) => {
      const currentUserId = this.props.from === 'Community_Menu' ? this.state.currentUserId : this.state.objPM.current_user_id;
        const bgColor = message.user_id === currentUserId ? '#cff8c8' : '#fff';
        const txtAlign = message.user_id === currentUserId ? 'right' : 'left';

        return(
          <div key={index} className="row" style={{marginBottom:'10px',paddingLeft:'15px',paddingRight:'15px'}}>
            <div className="col-md-12">
              <div style={{border:'1px #434e5e solid',borderRadius:'6px',padding:'5px 15px',backgroundColor:bgColor,textAlign:txtAlign}}>
                <p style={{marginBottom:'0',overflowX:'hidden'}}>{message.body}</p>
                <p style={{marginBottom:'0',fontSize:'10px'}}><TimeAgo date={message.created_at}/></p>
              </div>
            </div>
          </div>
        )
      });

    const roomUsername = this.props.from === 'Community_Menu' ? this.state.roomUsername : this.state.objPM.from_username;

    const showDiv = this.state.isRoomList ?
      <div>
        <div className="text-center">
          <i onClick={this.handleIsOpen} className="material-icons"
             style={{float: 'right', cursor: 'pointer', marginRight: '15px'}}>clear</i>
          <h4 ref="h4MenuTitle" style={{marginTop: '135px'}}>Participant</h4>
        </div>

        { rooms }

      </div> :

      <div style={{marginTop: '170px'}}>
        <div className="text-center" style={{backgroundColor:'#fff',height:'50px',padding:'15px',marginBottom:'15px',
          zIndex:'1',position:'fixed',width:'300px',marginTop:'-63px'}}>
          <i onClick={this.handleIsOpen} className="material-icons"
             style={{float: 'right', cursor: 'pointer', marginRight: '15px'}}>clear</i>
          <div onClick={this.handleIsRoomList} style={{cursor:'pointer',position:'absolute',left:'20px'}}>
            <FontAwesomeIcon icon={['fal', 'arrow-left']}/>
            <span style={{marginLeft:'50px',fontSize:'16px'}}>{roomUsername}</span>
          </div>
        </div>

        { messages }

        <hr/>
        <div className="row" style={{paddingLeft:'25px',position:'fixed',width:'300px',bottom:'0',backgroundColor:'#fff'}}>
          <div className="col-md-8">
            <div className="form-group" style={{marginTop:'0'}}>
              <input name="body" onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} value={this.state.body}
                     type="text" className="form-control" placeholder="your message here..."/>
            </div>
          </div>
          <div className="col-md-1" style={{marginTop:'-10px', paddingLeft:'0'}}>
            <button onClick={this.savePrivateMessage} className="btn btn-info btn-round btn-sm"
                    style={{backgroundColor:'#433566',marginTop:'20px'}}>
              <i className="material-icons">send</i><div className="ripple-container"/>
            </button>
          </div>
        </div>

      </div>;


    return (
      <div className="sidebar-wrapper" style={{padding:'0',paddingBottom:'29px'}}>

        <div className="messages-container">
          <div className="messages-header">
            <div className="messages-title">
              <span>Send messages to </span>
            </div>
            <div onClick={this.props.rightDrawerToggleHandler} className="messages-close" style={{cursor:'pointer'}}>
              <FontAwesomeIcon icon={['far', 'xmark']} className="iconClose"/>
            </div>
          </div>
          <div className="messages-search">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="input-element">
                  <div className="form-group">
                    <input type="text" className="form-control"/>
                    <FontAwesomeIcon icon={['fal', 'magnifying-glass']} className="icon-search"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="separator"/>
          <div className="messages-items-container">
            <div className="message-item">
              <div className="message-card">
                <div className="avatar-container">
                  <img src="https://picsum.photos/200" alt="" className="avatar"/>
                </div>
                <div className="info-container">
                <span className="username">
                  John
                </span>
                  <div className="mail">
                    prueba@prueba.com
                  </div>
                  <div className="activity">
                    <Dotdotdot clamp={2}>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci architecto aspernatur at debitis delectus dignissimos dolor eius et, hic illum itaque laudantium optio placeat porro quisquam tempore totam voluptatum. Itaque?
                    </Dotdotdot>
                  </div>
                </div>
                <div className="actions-container">
                  <span className="date">10 hours ago</span>
                  <button className="btn-follow">Follow</button>
                </div>
              </div>
            </div>
            <div className="separator"/>
          </div>
        </div>

        <div className="messages-container">
          <div className="messages-send">
            <div className="form-group">
              <input type="text" className="form-control"/>
            </div>
            <label className="send-to">Send to</label>
            <ul className="list-inline users-send-container">
              <li className="user-item">
                John
              </li>
              <li className="user-item">
                Mary
              </li>
              <li className="user-item">
                Eduardo
                <div className="delete-user">
                  <FontAwesomeIcon icon={['far','xmark']}/>
                </div>
              </li>
              <li className="add-user-container">
                <div className="add-user">
                  <span>Add</span>
                  <FontAwesomeIcon icon={['fas','circle-plus']}/>
                </div>
              </li>
            </ul>
            <div className="message-container">
              <div className="form-group">
                <textarea className="message-textarea" placeholder="Ingresa tu texto" name="" id="" cols="30" rows="10"/>
              </div>
              <div className="button-send-container">
                <button className="send-button">Send</button>
              </div>
            </div>
          </div>
        </div>

        <div className="conversations-container">
          <div className="sender-container">
            <img src="https://picsum.photos/200" className="img-sender" alt=""/>
            <div className="conversation-sender-container">
              <div className="conversation-item">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium assumenda expedita harum iusto maiores nesciunt nobis, officiis repellendus repudiandae tempore. Beatae eaque harum inventore natus quos rerum soluta ut voluptatum.
              </div>
              <span className="sender-date">3:12 pm</span>
            </div>
          </div>

          <div className="receiver-container">
            <img src="https://picsum.photos/200" className="img-receiver" alt=""/>
            <div className="conversation-receiver-container">
              <div className="conversation-item">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium assumenda expedita harum iusto maiores nesciunt nobis, officiis repellendus repudiandae tempore. Beatae eaque harum inventore natus quos rerum soluta ut voluptatum.
              </div>
              <span className="receiver-date">3:12 pm</span>
            </div>
          </div>

          <div className="message-container">
            <div className="form-group">
              <textarea className="message-textarea" placeholder="Ingresa tu texto" name="" id="" cols="30" rows="3"/>
            </div>
            <div className="button-send-container">
              <button className="send-button">Send</button>
            </div>
          </div>
        </div>

        { showDiv }
      </div>
    );
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.savePrivateMessage();
    }
  };

  getPM = (roomId, roomUsername) => {
    $.ajax({
      url: '/pm/get_messages/' + roomId,
      method: 'GET',
      dataType: 'JSON',
      success: function(obj) {
        console.log(obj);

        if(this.props.from === 'Community_Menu'){
          this.setState({
            isRoomList: false,
            messages: obj.messages,
            currentUserId: obj.currentUserId,
            roomUsername: roomUsername,
            roomId: roomId
          });
        } else {
          this.state.objPM.messages = obj.messages;
          this.state.objPM.from_username = roomUsername;
          this.state.objPM.room.id = roomId;
          this.setState({
            isRoomList: false
          })
        }


      }.bind(this)
    });
  };

  savePrivateMessage = () => {
    const roomId = this.props.from === 'Community_Menu' ? this.state.roomId : this.state.objPM.room.id;
    //console.log(roomId)
    if(this.state.body !== ''){
      $.ajax({
        url: '/pm/create/' + roomId,
        method: 'POST',
        dataType: 'JSON',
        data: {
          message: {
            body: this.state.body
          }
        },
        success: function(messages) {
          //console.log(messages);
          if(this.props.from === 'Community_Menu'){
            this.setState({
              body: '',
              messages
            });
          } else {
            this.state.objPM.messages = messages;
            this.setState({
              body: ''
            });
          }
        }.bind(this)
      });
    }
  };

  handleIsRoomList = () => {
    this.setState({isRoomList: true})
  };

  handleIsOpen = () =>{
    if(this.props.from === 'Community_Menu'){
      document.getElementById("mySidenav").style.width = "0";
      this.setState({pmIsOpen: !this.state.pmIsOpen})
    } else {
      this.props.updatePMIsOpen();
    }


  }
}
