import PropTypes from 'prop-types';
import React from 'react';
import ParticipantList from "../../components/Community/Invitations/ParticipantList";
import UsersList from "../../components/Community/UsersList";

export default class Participants extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      communityUsers: [],
      segments: [],
      type: 'Participant',
      counter: [],
      flagInvited: false,
      flagDraft: false,

    };
  }

  componentDidMount(){
    this.setCommunityUsers();
    this.setCommunitySegments();
  };

  render() {
    const cEnabled = this.state.communityUsers.length;

    return (
      <div>
        <ParticipantList community={this.props.community}
                         community_users={this.state.communityUsers}
                         counter={[cEnabled]}
                         type={this.state.type}
                         segments={this.state.segments}
                         role2={this.props.profile.role_2}/>
      </div>
    );
  }

  setCommunityUsers = () => {
    $.ajax({
      url: '/communities/get_community_users/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      data: {type: this.state.type},
      success: function (communityUsers) {
        console.log(communityUsers);
        this.setState({
          communityUsers,
          counter: this._calculateCounter(communityUsers)
        });
      }.bind(this)
    });
  };

  setCommunitySegments = () => {
    $.ajax({
      url: '/communities/get_segments/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      success: function (segments) {
        this.setState({segments});
      }.bind(this)
    });
  };

  updateCommunityUsers = (communityUsers) => {
    this.setState({communityUsers})
  };

  updateSegments = (segments) => {
    this.setState({segments});
  };

  _calculateCounter(cu){
    let cE = 0, cI = 0, cDr = 0, cD = 0, cB = 0;

    cu.forEach(user => {
      if(user.state === 'Enabled')
        cE++;
      if(user.state === 'Invited')
        cI++;
      if(user.state === 'Draft')
        cDr++;
      if(user.state === 'Declined')
        cD++;
      if(user.state === 'Blocked')
        cB++;
    });

    /*_.each(cu, function (user) {
      if(user.state === 'Enabled')
        cE++;
      if(user.state === 'Invited')
        cI++;
      if(user.state === 'Draft')
        cDr++;
      if(user.state === 'Declined')
        cD++;
      if(user.state === 'Blocked')
        cB++;
    });*/

    return [cE, cI, cDr, cD, cB];
  }
}