import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'moment';

export default class PModal extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    clickedTask: PropTypes.object,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {type, clickedTask, language, colors} = this.props;

    const i18n = {
      en: {
        first: 'Please answer first,', goToAct: 'Go to activity', not_available: 'Activity not open yet',
        to_dashboard: 'Go Back', hasExpired: 'This activity has expired but you can still read and post comments',
        hasExpiredButRespond: 'This activity has expired but you can still respond',
        hasExpired2: "Click 'Your Response' button to read and post comments and see your submitted response.",
        hasExpired3: 'The moderators have given an extended time so you can still complete this Activity. Access may be revoked soon.',
        seeMyResponse: 'Your response', contactModerator: 'Contact the community moderators',
        notAvailableRequiste:'You need to complete another activity before starting this one. However, the required activity is not available to you. Please contact the community moderators for their guidance.',
        close: 'Close'
      },
      es: {
        first: 'Responde primero,', goToAct: 'Ir a activitidad', not_available: 'La actividad no está abierta aún',
        to_dashboard: 'Regresar', hasExpired: 'La actividad ha expirado, pero aún puedes leer y publicar comentarios',
        hasExpiredButRespond: 'La actividad ha expirado, pero todavía puedes responder.',
        hasExpired2: "Clic el botón 'Tu Respuesta' para leer y publicar comentarios, así como ver tu respuesta publicada.",
        hasExpired3: 'Los moderadores han dado más tiempo para que respondas. Apresúrate, pronto podrían cerrar la actividad.',
        seeMyResponse: 'Tu respuesta', contactModerator: 'Contacta a los moderadores de la comunidad',
        notAvailableRequiste: 'Esta actividad tiene otra como requisito. Sin embargo, la actividad requerida no está disponible para ti. Contacta a los moderadores del estudio para que te guíen.',
        close: 'Cerrar'
      }
    };

    const now = moment().format('YYYY-MM-DD');

    let title = null;
    let label = null;
    let btnAction = null;
    let btnCancel = null;

    if(clickedTask !== undefined){
      //console.log('language:', language);
      switch(type){
        case 'required':
          let available = true;

          if(clickedTask.start){
            const startDate = moment(clickedTask.start).format('YYYY-MM-DD');
            console.log('startDate:', startDate);

            available = startDate <= now;
          }
          //console.log(available);
          title = <p className="px-title-warning">{i18n[language].first}&nbsp;
            <b className="warning" style={{color: colors.color4[3]}}>{clickedTask.title}</b></p>;
          // TEMPORAL, We must to put the logic for several required activities from other activities
          btnAction = <button onClick={() => this.props.updateShowModal(null, null)} type="button"
                              className="btn px-btn sm btn-gray-darker inverted">{i18n[language].close}</button>
          /*btnAction = <button onClick={available ? this.forwardTask : () => this.props.updateShowModal(null, null)}
                              className="btn px-btn sm btn-blue-base" style={{width:'185px', backgroundColor: colors.color1[3]}}
                              type="button">{available ? i18n[language].goToAct : i18n[language].to_dashboard}
          </button>;*/
          break;
        case 'No yet start':
          title = <p className="px-title-warning">{i18n[language].not_available}</p>;
          btnAction = <button onClick={() => this.props.updateShowModal(null, null)} type="button"
            className="btn px-btn sm btn-blue-base" style={{backgroundColor: colors.color1[3]}}>{i18n[language].to_dashboard}</button>;
          break;
        case 'Expired':
          title = <p className="px-title-warning">{clickedTask.overdue ? i18n[language].hasExpired :
            i18n[language].hasExpiredButRespond}</p>;
          label = <label className="px-message-warning text-center">{clickedTask.overdue ?
            i18n[language].hasExpired2 : i18n[language].hasExpired3 }</label>;

          if(clickedTask.overdue){
            btnAction = <button onClick={() => this.props.updateShowModal(null, null)} type="button"
              className="btn px-btn sm btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{i18n[language].to_dashboard}</button>;
            btnCancel = <button onClick={this.forwardTask} type="button"
                                className="btn px-btn sm btn-gray-darker inverted">{i18n[language].seeMyResponse}</button>;
          } else {
            btnAction = <button onClick={this.forwardTask} type="button"
              className="btn px-btn sm btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{i18n[language].goToAct}</button>;
          }
          break;
      }
    } else {
      title = <p className="px-title-warning">{i18n[language].contactModerator}</p>;
      label = <label className="px-message-warning text-center">{i18n[language].notAvailableRequiste}</label>;
    }

    return (
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header modal-delete-activity">
            <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
              <i onClick={() => this.props.updateShowModal(null, null)} className="material-icons">clear</i>
            </button>
          </div>

          <div className="modal-body px-modal-body-warning">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="px-title-warning-container">
                  <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning" style={{color: colors.color4[3]}}/>
                  { title }
                  { label }
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer px-modal-footer-warning">
            <div className="px-footer-container-buttons column">
              { btnCancel}
              { btnAction }
            </div>
          </div>
        </div>
      </div>
    );
  }

  forwardTask = () => {
    //console.log('Hey');
    window.location.href = '/activity/' + this.props.clickedTask.id + '/' + this.props.clickedTask.community_id;
  };
}