import React from "react";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class SocialStats extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    expiredStyleCommentAlert: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }

  render() {
    const {task, i18n, language, colors, expiredStyleCommentAlert, color, type} = this.props;

    return(
      <div className="social-stats-container">
        <div className="item" data-tooltip-content={i18n[language].likes}>
          <div className="icon-container">
            <FontAwesomeIcon icon={['fas', 'heart']}/>
          </div>
          <div className="counter">{task.sum_all_likes}</div>
        </div>
        <div className={type === 'ToDo' ? 'item ' + expiredStyleCommentAlert : 'item'}
             data-tooltip-content={task.sum_comments_replies_unseen > 0 ? i18n[language].unread_comments : i18n[language].comments }>
          <div className="icon-container">
            {task.sum_comments_replies_unseen > 0 && <div className="unseen" style={{ backgroundColor: colors.color4[3]}}/>}
            <FontAwesomeIcon icon={['fas', 'message']}/>
          </div>
          <div className="counter">{task.sum_all_comments_replies}</div>
        </div>
        <div className="item">
          <div className="icon-container">
            {
              type === 'ToDo' ?
                <FontAwesomeIcon icon={['fas', task.social === 'Public' ? 'eye' : 'eye-slash']} color={color}
                                 data-tooltip-content={task.social === 'Public' ? i18n[language].display02 : i18n[language].display01}/> :
                <FontAwesomeIcon icon={['fas', task.social === 'Public' ? 'eye' : 'eye-slash']}
                                 data-tooltip-content={task.social === 'Public' ? i18n[language].display02 : i18n[language].display01}/>
            }
          </div>
        </div>
      </div>
    )
  }
}
