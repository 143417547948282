import PropTypes from 'prop-types';
import React from 'react';
import Layout from "../../Layout";
import Chat from "../../Community/Chat/Chat";
import HelpScoutBeacon from "../../../components/HelpScoutBeacon.jsx";

export default class OpenAIPage extends React.Component {
  static propTypes = {
    participants: PropTypes.array.isRequired,
    user_account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    sessionLang: PropTypes.string.isRequired,
    notifications: PropTypes.array.isRequired,
    community: PropTypes.object.isRequired,
    activities_filter: PropTypes.array.isRequired,
    users_id: PropTypes.string.isRequired,
    activities_id: PropTypes.string.isRequired,
    tasks_id: PropTypes.string.isRequired,
    documents: PropTypes.array.isRequired,
    outside: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      notificationsCounter: 0,
      notifications: [],
      communitiesTop: [],
      isBeaconInit: false, //Keeps track of any instances of Head Scout Beacons initialised.
      openPMFromOutside: {username: '', userId: null, type: null, flag: false}
    }
  }

  componentDidMount(){
    console.log('participants:', this.props.participants)
    const {outside} = this.props;
    this._getNotificationsCounter();
    this._getCommunitiesTop();

    if(outside){
      if(outside.isOpen === 'true'){
        this.updateOpenPMFromOutside(outside.type, outside.username, outside.userId);
      }
    }

  }

  render() {
    const {user_account, community, participants, origin, tasks_filter, users_id, activities_id, tasks_id, collaborators,
      scroll, item_scroll, activities_filter, documents} = this.props;
    const hsBeaconID = '9abff3ac-994f-412b-8bd1-be9f6b561b99';
    let hsBeaconSuggestions = null;

    return (
      <Layout user_account={user_account}
              profile={user_account.profile}
              sessionLang={user_account.language}
              notifications={this.state.notifications}
              from='CollChatPage'
              colors={user_account.all_colors}
              community={community}
              notificationsCounter={this.state.notificationsCounter}
              communities={this.state.communitiesTop}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}>

        {/*<HelpScoutBeacon beaconId={hsBeaconID}*/}
        {/*                 user_account={user_account}*/}
        {/*                 colors={user_account.all_colors}*/}
        {/*                 language={user_account.language}*/}
        {/*                 isBeaconInit={this.state.isBeaconInit}*/}
        {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
        {/*                 suggestions={hsBeaconSuggestions}*/}
        {/*                 hideFABOnDesktop={!this.props.user_account.profile.is_help}/>*/}

        <Chat username={user_account.username}
              currentUserId={user_account.id}
              language={user_account.language}
              avatar={user_account.profile.avatar}
              colors={user_account.all_colors}
              community_id={community.id}
              activities_filter={activities_filter}
              users_id={users_id}
              activities_id={activities_id}
              tasks_id={tasks_id}
              users={participants}
              community={community}
              documents={documents}
        />
      </Layout>
    )
  }

  updateOpenPMFromOutside = (type, username, userId) => {
    console.log(type, username, userId);
    this.setState(state => ({openPMFromOutside: {username, userId, type, flag: !state.openPMFromOutside.flag}}));
  };

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  _getCommunitiesTop = () => {
    $.ajax({
      url: '/communities/get_communities_top_menu/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communitiesTop =>  {
        //console.log(communitiesTop);
        this.setState({communitiesTop});
      }
    });
  };
}