import PropTypes from 'prop-types';
import React from 'react';
import v4 from "uuid";
import moment from "moment";

export default class TDetailTable extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render() {
    const {data} = this.props;

    return(
      <React.Fragment>
        <div className="row">
          <div className="col-xs-12">
            <p style={{color:'#f3712e',fontWeight:'bolder'}}>DETAILS BY DAY</p>
          </div>
        </div>
        <div className="row" style={{marginBottom:'25px'}}>
          <div className="col-xs-12">
            <table className="table table-bordered table-hover px-details">
              <thead>
              <tr className="label-sm">
                <th>Date</th>
                <th>Participants</th>
                {/* <th>Est. Cost</th> */}
                <th>Cost per day</th>
                <th>P*D Subs</th>
                <th>P*D Flexdays</th>
                <th>P*D Cash</th>
                {/* <th>Cost 1 PD</th> */}
                <th>Fees due</th>
              </tr>
              </thead>
              <tbody>
              {
                data.details.map(d => {
                  return(
                    <tr key={v4()}>
                      <td>{moment(d.date).format('DD/MM/YYYY')}</td>
                      <td>{d.num_participants_day}</td>
                      {/* <td>US$ {parseFloat(d.est_price_day).toFixed(2)}</td> */}
                      <td>US$ {parseFloat(d.cost_per_day).toFixed(2)}</td>
                      <td>{d.subs_day}</td>
                      <td>{d.flex_pd_day}</td>
                      <td>{d.cash_pd_day}</td>
                      {/* <td>US$ {parseFloat(d.pd_cost_day).toFixed(2)}</td> */}
                      <td>US$ {parseFloat(d.cash_day).toFixed(2)}</td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
        </div>
        <div className="row" style={{marginTop: '20px'}}>
          <div className="col-xs-12">
            <p style={{ fontWeight: 'bolder' }}>Cost per day:<span style={{ fontWeight: 'normal' }}> Quoted cost per day according to the number of participants and number of total days of the community.</span></p>
            <p style={{ fontWeight: 'bolder' }}>P*D Subs:<span style={{ fontWeight: 'normal' }}> Participant*Days that will be offset by an active subscription</span></p>
            <p style={{ fontWeight: 'bolder' }}>P*D Flexdays:<span style={{ fontWeight: 'normal' }}> Participant*Days that will be offset by available Flexdays</span></p>
            <p style={{ fontWeight: 'bolder' }}>P*D Cash:<span style={{ fontWeight: 'normal' }}> Participant*Days to be paid by cash</span></p>
          </div>
        </div>
      </React.Fragment>
    )
  }
}