import React from "react"
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"


const ChDelete = ({ toggleModal, colors, language, deleteChatRoom }) => {
  const i18n = {
    en: {delete: 'Delete', task: 'Chat Room', notUndone: 'This action cannot be undone', yes: 'Yes', no: 'Cancel'},
    es: {delete: '¿Eliminar ', task: 'Chat Room', notUndone: 'Esta acción es irreversible', yes: 'Sí', no: 'Cancelar'}
  }

  return (
    <div className="modal-dialog" style={{width:'35%'}}>
      <div className="modal-content">
        <div className="modal-header modal-delete-activity">
          <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                  onClick={toggleModal}>
            <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
          </button>
        </div>
        <div className="modal-body px-modal-body-warning">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="px-title-warning-container">
                <FontAwesomeIcon icon={['fal', 'triangle-exclamation']} className="px-icon-warning" style={{ color: colors.color4[3] }}/>
                <p className="px-title-warning" style={{ marginBottom: '0' }}>{i18n[language].delete}&nbsp;
                  <b className="warning" style={{ color: colors.color4[3] }}>{i18n[language].task}?</b>
                </p>
                <label className="px-message-warning">{i18n[language].notUndone}</label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer px-modal-footer-warning">
          <div className="px-footer-container-buttons column">
            <button type="button" onClick={deleteChatRoom}
                    className="btn px-btn sm btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>
              {i18n[language].yes}
            </button>
            <button onClick={toggleModal} type="button"
                    className="btn px-btn sm btn-gray-darker inverted">{i18n[language].no}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

ChDelete.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  deleteChatRoom: PropTypes.func.isRequired
}

export default ChDelete