import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeAgo from "react-timeago";
import Dotdotdot from "react-dotdotdot";
import Truncate from 'react-truncate';
import axios from 'axios';

export default class NotificationList extends React.Component {
  static propTypes = {
    newsFlash: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    formatter: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    newsLastVisit: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { newsFlash, language, formatter, sltType, colors, newsLastVisit } = this.props;

    if (newsFlash.visibility === 'published') {
      const isHighlighted = newsLastVisit < newsFlash.launched_on ? true : false;
      const timeAgo = <span className="date">
        <TimeAgo date={newsFlash.launched_on} formatter={formatter} /></span>;
      const labelAndColor = this._getCardLabelandColor(newsFlash, isHighlighted);
      const icon = this._getCardIcon(newsFlash, labelAndColor[1], isHighlighted);
      

      return (
        <React.Fragment>
          <div className="notification-card newsflash" style={{ marginBottom: '15px', borderLeft: `solid 10px ${isHighlighted ? colors.color5[3] : '#dadde1'}` }} >
           <div className="header-container">
              {labelAndColor[0]}
              <div className="info-container">
              </div>
              <div className="icon-container">
                {icon}
              </div>
           </div>
           <div className="middle-container">
              <div className="title" style={{ color: isHighlighted === true ? colors.color5[2] : '#7C8593' }}>{newsFlash.title}</div>
                {
                (newsFlash.image_blob !== null && newsFlash.image_blob !== '') ?
                <div className="image-container">
                  <img src={newsFlash.image_blob} alt="" />
                </div> : ''
                }
                <div className="content"> {newsFlash.body}</div>
           </div>
            <div className="actions-container">
              <div className="left-container">
              </div>
              <div className="right-container">
                {timeAgo}
              </div>
            </div>
          </div>
        </React.Fragment>

      );

    } else {
      return null;
    }

  }

  _getCardLabelandColor(newsFlash, isHighlighted) {
    let cardLabel = [];
    let cardLabelText = null;
    let headerColor = null;
    const colors = this.props.colors;
    switch (newsFlash.category) {
      case 'improvement':
        headerColor = colors.color5[2]
        cardLabelText = 'IMPROVEMENT';
        break;
      case 'new_feature':
        headerColor = colors.color5[2];
        cardLabelText = 'NEW FEATURE';
        break;
    }
    if (isHighlighted === false) {
      headerColor = '#7C8593';
    }
    cardLabel = [<span className="tag-label" style={{ backgroundColor: headerColor  }}><div>{cardLabelText}</div></span>, headerColor]; 
    return cardLabel
  }

  _getCardIcon(newsFlash, color, isHighlighted) {
    let cardIcon = null;
    let iconName = null;
    let inlineStyle = { color: isHighlighted === true ? color : '#7C8593'}
    switch(newsFlash.tag){
      case 'participants':
        iconName = 'users'
        break;
      case 'transcript':
        iconName = 'scroll'
        break;
      case 'activities':
        iconName = 'list'
        break;
      case 'notifications':
        iconName = 'bell'
        break;
      case 'users':
        iconName = 'users'
        break;
      case 'support':
        iconName = 'circle-question'
        break;
      case 'exports':
        iconName = 'share'
        break;
      case 'comments':
        iconName = 'comment'
        break;
      case 'account':
        iconName = 'circle-user'
        break;
    }
    return cardIcon = <FontAwesomeIcon icon={['fas', iconName]} className="remove" style={inlineStyle} />;
  }

}