import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimeAgo from 'react-timeago';
import englishStrings from 'react-timeago/lib/language-strings/en';
import spanishStrings from 'react-timeago/lib/language-strings/es';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import TextareaAutosize from 'react-textarea-autosize';
import {usernamesToDisplay} from "../shared_utils";

export default class Reply extends React.Component {
  static propTypes = {
    reply: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    activeReplyIdToReply: PropTypes.number.isRequired,
    isReplyToReplyOpen: PropTypes.bool.isRequired,
    replyToReplyBody: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    updateActiveReplyIdToReply: PropTypes.func.isRequired,
    saveReplyToComment: PropTypes.func.isRequired,
    commentId: PropTypes.number.isRequired,
    currentUserId: PropTypes.number.isRequired,
    saveLike: PropTypes.func.isRequired,
    scrollFirstTime: PropTypes.bool,
    updateScrollFirstTime: PropTypes.func,
    entity: PropTypes.object,
    from: PropTypes.string.isRequired,
    item_scroll: PropTypes.object,
    communityState: PropTypes.string,
    updateShowConfirmReply: PropTypes.func,
    role_2: PropTypes.string,
    isSavingReply: PropTypes.bool,
    isSavingLike: PropTypes.bool,
    onHeightChange: PropTypes.func,
    colors: PropTypes.object.isRequired,
    response: PropTypes.object,
    aiModerationScheduled: PropTypes.bool,
    isFetchingModerationSchedule: PropTypes.bool,
    updateModerationScheduled: PropTypes.func,
    aiAgentName: PropTypes.string,
    company: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const {item_scroll, scrollFirstTime} = this.props;
    //console.log('Reply.componentDidMount', item_scroll);

    if(scrollFirstTime && item_scroll){
      if(item_scroll.type === 'reply'){
        const itemDivId = `#${item_scroll.type}_${item_scroll.id}`;

        if($(itemDivId).offset() !== undefined){
          const cardPosition = $(itemDivId).offset().top;

          (window).scrollTo({top: cardPosition - 75, behavior: 'smooth'});
          $(itemDivId).css('background-color', 'aliceblue');
        }
      } else if(item_scroll.type === 'likeReply'){
        //Likes to Reply
        const itemDivId = `#reply_${item_scroll.id}`;
        const cardPosition = $(itemDivId).offset().top;
        //console.log('scroll to likeReply', cardPosition);
        (window).scrollTo({top: cardPosition - 75, behavior: 'smooth'});
        $(itemDivId + '> .comment-section > .actions-comments').css('background-color', 'aliceblue');
      }
    }
  }

  componentDidUpdate(prevProps){
    const {item_scroll, scrollFirstTime} = this.props;
    //console.log('Reply.componentDidUpdate', prevProps.scrollFirstTime, scrollFirstTime);

    if(prevProps.scrollFirstTime !== scrollFirstTime){
      //console.log(item_scroll);
      if(item_scroll && item_scroll.type !== 'response'){
        this._scrolling(item_scroll);
      }
    }

    /*if(typeof this.props.entity !== 'undefined') {
      if (this.props.reply.reply.id === this.props.entity.id && this.props.scrollFirstTime) {
        console.log('Comment componentDidUpdate', this.props.scrollFirstTime);

        const cardPosition = $('#reply_' + this.props.entity.id).offset().top;
        (window).scrollTo({top: cardPosition - 75, behavior: 'smooth'});
        $('#reply_' + this.props.entity.id).css('background-color', 'aliceblue');

        this.props.updateScrollFirstTime(false);
      }
    }*/
  }

  render() {
    const {reply, from, communityState, currentUserId, role_2, language, colors} = this.props;

    const i18n = {
      en: {leaveReply: 'Reply here', post: 'Post',
        hasNotSeenComment: "The participant hasn't seen this comment yet",
        aiCommentScheduled: "Pixiebob AI will post a comment soon"
      },
      es: {leaveReply: 'Responde aquí', post: 'Enviar',
        hasNotSeenComment: 'El participante no ha visto este comentario aún',
        aiCommentScheduled: "Pixiebob IA enviará un comentario pronto"
      }
    };

    const formatter = language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);

    const likeIcon = reply.isLike ? ['fas','heart'] : ['far','heart'];
    const likeBtn = communityState !== 'Closed' ? reply.reply.user_id === currentUserId ?
      <React.Fragment><FontAwesomeIcon icon={likeIcon}/><div className="number">{reply.likes}</div></React.Fragment> :
      <div className="actions-comments" onClick={() => this.props.saveLike(reply.reply.id, 'Reply', !reply.isLike)}
           style={{cursor:'pointer'}}>
        <FontAwesomeIcon icon={likeIcon}/>
        <div className="number" style={{cursor:'pointer'}}>{reply.likes}</div>
      </div> :
      <React.Fragment><FontAwesomeIcon icon={likeIcon}/><div className="number">{reply.likes}</div></React.Fragment>;

    const deleteBtn = communityState !== 'Closed' && reply.reply.user_id === currentUserId &&
      <div onClick={() => this.props.updateShowConfirmReply(reply.reply.id)} className="px-delete-comment"
           style={{cursor: 'pointer'}}>
      <FontAwesomeIcon icon={['fas', 'trash']} style={{ color: "#dadde1"}}/>
      </div>;

    return (
      <div id={'reply_' + this.props.reply.reply.id} className="comment-item second-level">
          <img src={reply.avatar !== '' ? reply.avatar : '/assets/user.png'} alt=""
               className="avatar-user px-thumb-25 img-circle"/>
          <div className="comment-section">
            <div className="user" style={{color: colors.color1[3]}}>
              <span>{this.getUserName(reply)}</span>
              { (from === 'CTV' && role_2 !== 'Observer') &&
                <span className="email" style={{marginLeft: '5px'}}>{reply.email && `(${reply.email})`}</span> }
            </div>
            <div className="comment">
              <span className="text">{reply.reply.body} { deleteBtn }</span>
            </div>
            <div className="actions-comments">
              { role_2 !== 'Observer' ? !this.props.isSavingLike ? likeBtn :
                <FontAwesomeIcon icon={['fas','spinner']} spin/> : <FontAwesomeIcon icon={likeIcon}/>
              }
              {/*<FontAwesomeIcon icon={['fas','trophy']}/>*/}&nbsp;&nbsp;
              {
                role_2 !== 'Observer' &&
                <div
                  onClick={communityState !== 'Closed' ? () => this.props.updateActiveReplyIdToReply(reply.reply.id) : null}
                  style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon icon={['fas', 'reply']}/>
                </div>
              }
              <div className="time">
                <TimeAgo date={reply.reply.created_at} formatter={formatter}/>
                {
                  (role_2 === 'Owner' || role_2 === 'Moderator') &&
                  reply.reply.alert === 'unseen' && <FontAwesomeIcon icon={['fas','eye-slash']} data-tooltip-content={i18n[language].hasNotSeenComment}/>
                }
              </div>
            </div>
            {
              this.props.isReplyToReplyOpen &&
                <div className="interaction-container" style={{marginTop:'5px'}}>
                  <div className="px-comments-input">
                    {/* It doesn't need to send the event as event => function(event) */}
                    <TextareaAutosize name="replyToReplyBody" minRows={1}
                                      placeholder={this.props.aiModerationScheduled ? i18n[language].aiCommentScheduled : i18n[language].leaveReply}
                                      onHeightChange={rowHeight => this.props.onHeightChange(rowHeight)}
                                      value={this.props.replyToReplyBody} onChange={this.props.handleChange}
                                      style={{
                                        borderColor: this.props.aiModerationScheduled ? '#ffffff' : colors.color1[3],
                                        backgroundColor: this.props.aiModerationScheduled ? '#f6f6f6' : '#ffffff'
                                      }}
                                      disabled={this.props.aiModerationScheduled}
                    />
                    {this.props.isSavingReply || this.props.isFetchingModerationSchedule ?
                      <FontAwesomeIcon icon={['fas', 'spinner']} spin style={{color: colors.color1[3]}}/> :
                      this.props.aiModerationScheduled ?
                        <div onClick={() => {
                              this.props.updateModerationScheduled(this.props.response?.id)
                             }}
                             style={{cursor: 'pointer'}}>
                          <FontAwesomeIcon icon={['far', 'timer']}
                                           style={{color: colors.color1[3]}}
                                           data-tooltip-content={this.props.aiModerationScheduled ?
                                             i18n[language].aiCommentScheduled : ''}
                          />
                        </div> :
                        <div onClick={() => {
                              this.props.saveReplyToComment(this.props.commentId, reply.reply.id)
                            }}
                             style={{cursor: 'pointer'}}>
                          <FontAwesomeIcon icon={['far','paper-plane']}
                                           style={{ color: colors.color1[3] }}/>
                        </div>
                    }
                  </div>
                </div>
            }
          </div>
      </div>
    );
  }

  _scrolling(item_scroll) {
    //console.log(item_scroll);
    const itemDivId = item_scroll.type === 'reply' ? `#${item_scroll.type}_${item_scroll.id}` :
      `#reply_${item_scroll.id}`;
    // console.log(itemDivId);
    if ($(itemDivId).offset() !== undefined) {
      const cardPosition = $(itemDivId).offset().top;

      switch (item_scroll.type) {
        case 'reply':
          (window).scrollTo({top: cardPosition - 75, behavior: 'smooth'});
          $(itemDivId).css('background-color', 'aliceblue');
          break;
        case 'likeReply':
          //console.log(cardPosition);
          (window).scrollTo({top: cardPosition + 300 - 75, behavior: 'smooth'});
          $(itemDivId + '> .comment-section > .actions-comments').css('background-color', 'aliceblue');
          break;
      }
    }
  }

  getUserName (reply) {
    const i18n = {en: {you: 'You'}, es: {you: 'Tú'}}
    const {language} = this.props;
    const currentUserId = reply.currentUserId;
    const replyUserId = reply.reply.user_id;
    const isReplyFormCurrentUser = currentUserId === replyUserId;

    if (isReplyFormCurrentUser) {
      return i18n[language].you
    } else {
      const availableUsernames = usernamesToDisplay(reply, 'alias', 'all')
      return availableUsernames[0];
    }
  }

}
