import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class TFTaskPicker extends React.Component {
  static propTypes = {
    objSearch: PropTypes.object.isRequired,
    updateShowTaskPicker: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    genderAsSegment: PropTypes.bool.isRequired,
    allAvailableActs: PropTypes.array.isRequired,
    updateJustPills: PropTypes.func.isRequired,
    executeUpdateModal: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    isBlocking: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired
  };
    
  constructor(props) {
      super(props);
  }

  componentDidMount() {
    $(".card-profile, .px-tv-settings").hide();
    $(".px-nav-transcript-container").addClass("animated fadeIn");
    $(".px-nav-transcript-container").addClass("px-task-picker-container");
    $(".px-nav-transcript").addClass("px-task-picker");
  }

  componentWillUnmount() {
    $(".card-profile, .px-tv-settings").show();
    $(".px-nav-transcript-container").removeClass("animated fadeIn");
    $(".px-nav-transcript-container").removeClass("px-task-picker-container");
    $(".px-nav-transcript").removeClass("px-task-picker");
  }


  render(){
    const {objSearch, language, allAvailableActs, isBlocking, colors} = this.props;

    const i18n = {
      en: { update: 'update', back: 'Back' },
      es: { update: 'actualizar', back: 'Regresar'}
    };

    let activities = null;
    activities = allAvailableActs.map(activity => {
      const checked = objSearch.idActs.find(id => id === activity.id) !== undefined;
     
      return (
        <div key={activity.id} className='col-activity col-xs-2' >
          <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

          <div className="px-check-cont">
            <label className="container-check activity">
              <input type="checkbox" onChange={() => this.props.updateJustPills(activity.id, 'idActs')}
                checked={checked} />
              <span className="checkmark " />
              <div className="user-data">
                <div className="txt-email" data-tooltip-content={activity.title.replace(/(<([^>]+)>)/ig, "").substring(0, 100)}>
                  {activity.title.replace(/(<([^>]+)>)/ig, "").substring(0, 20)}
                </div>
              </div>
            </label>
          </div>

          {
            activity.questions.map(question => {                
              const checkedTask = objSearch.idTasks.find(id => id === question.id) !== undefined;

              return(
                <div key={question.id} >
                  <div className="px-check-cont">
                    <label className="container-check task" style={{ color: '#AFBDC1'}}>
                      <input type="checkbox"
                             onChange={isBlocking ? null : () => this.props.updateJustPills(question.id, 'idTasks')}
                             checked={checkedTask} />
                      <span className="checkmark" />
                      <div className="user-data">
                        <div className="txt-email" data-tooltip-content={question.title.replace(/(<([^>]+)>)/ig, "").substring(0, 100)}>
                          {question.title.replace(/(<([^>]+)>)/ig, "").substring(0, 20)}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              )
            })
          }
        </div>
      )
    });

    return(
      <div className='cols-container'>
        <div className='left-container row-fluid col-xs-12' style={{minHeight:'141px', overflow: 'scroll'}}>
          {
            isBlocking ?
              <div style={{textAlign: 'center'}}>
                <img src="/assets/loader_200x200.gif"
                  style={{ width: '8%' }} />
              </div> :
              <React.Fragment>
                <div className='col-activity col-buttons col-xs-1' >
                  <div>
                    <FontAwesomeIcon onClick={this.props.updateShowTaskPicker} icon={['fad', 'rotate-left']} id="card-filter-icon"
                                     data-tooltip-content={i18n[language].back}/>
                  </div>
                </div>
                { activities }
              </React.Fragment>
          }
        </div>
      </div>
    )
  }
}