import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import axios from 'axios';
import draftToHtml from "draftjs-to-html";
import {showSuccessMsg} from "./Globals/PX_Funs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {usernamesToDisplay} from "../Community/shared_utils";

export default class MUSeeNotes extends React.Component {
  static propTypes = {
    showSeeNotes: PropTypes.bool.isRequired,
    updateShowSeeNotes: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      note: null,
      editorState: null
    }
  }

  componentDidMount() {
    this.getNote(this.props.user.cu_id);
  }

  render(){
    const {showSeeNotes, user, language, colors} = this.props;

    const i18n = {
      en: {create: 'Create', update: 'Update', close: 'Close'},
      es: {create: 'Crear', update: 'Grabar', close: 'Cerrar'}
    }

    const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
    const mainName = availableUsernames[0];
    const alternativeNames = availableUsernames.length > 1 && availableUsernames.slice(1).join(', ')

    return (
      <ReactModal isOpen={showSeeNotes} contentLabel="User Profile" shouldCloseOnOverlayClick={true}
                  onRequestClose={() => this.props.updateShowModal(null)} className="px-modal-content"
                  overlayClassName="px-modal-overlay">
        <div className="modal-dialog img">
          <div className="modal-content px-modal-content profile">
            <div className="modal-header">
              <img src={user.avatar !== '' ? user.avatar : '/assets/user.png'} alt="" className="img-circle px-thumb-social" />
              <button onClick={() => this.props.updateShowSeeNotes(null)} type="button"
                      className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
              </button>
            </div>
            <div className="modal-body px-body-profile">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="px-modal-title">
                    {mainName}
                  </div>
                  <div style={{fontSize: '12px'}}>
                    {alternativeNames && `(a.k.a:  ${alternativeNames})`}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="px-oe-editor no-top-margin">
                    <Editor editorState={this.state.editorState}
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={{
                              options: ['inline'],
                              inline: {
                                options: ['bold', 'italic', 'underline'],
                                bold: { className: 'bordered-option-classname' },
                                italic: { className: 'bordered-option-classname' },
                                underline: { className: 'bordered-option-classname' },
                              },
                              textAlign: {
                                options: ['left', 'center', 'right', 'justify']
                              }
                            }}
                            placeholder={'Type text here'}
                            editorClassName="px-editor-tv-oe"
                            stripPastedStyles={true}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer px-modal-footer-gray">
              <button onClick={this.saveNote} type="button" className="btn px-btn sm btn-blue-base" style={{backgroundColor: colors.color1[3]}}>
                { this.state.note ? i18n[language].update : i18n[language].create }
              </button>
              <button onClick={this.props.updateShowSeeNotes} type="button"
                      className="btn px-btn sm btn-gray-darker inverted">{i18n[language].close}</button>
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }

  saveNote = () => {
    const note = {
      community_users_id: this.props.user.cu_id,
      body: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    };
    // console.log(note);
    const config = {
      headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}
    };

    if(this.state.note){
      axios.post(`/notes/update/${this.state.note.id}`, {note}, config)
        .then(response => {
          showSuccessMsg('Success!');
        });
    } else {
      axios.put('/notes/create/', {note}, config)
        .then(response => {
          this.setState({note: response.data});
          showSuccessMsg('Success!');
        });
    }
  }

  getNote = cu_id => {
    axios.get(`/notes/get_note/${cu_id}`)
      .then(response => {
        // console.log(response);
        this.setState({note: response.data}, () => {
          this._setEditorState(response.data ? this.state.note.body : null);
        })
      });
  }

  onEditorStateChange = editorState => {
    this.setState({editorState});
  };

  _setEditorState(text){
    // console.log(text);
    let component = null;

    if(text){
      const contentBlock = htmlToDraft(text);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

      component = EditorState.createWithContent(contentState);
    } else {
      component = EditorState.createEmpty();
    }

    this.setState({editorState: component});
  }
}