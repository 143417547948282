import PropTypes from 'prop-types';
import React from 'react';

import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showSuccessMsg} from "../UI/Globals/PX_Funs";

const i18n = {
  en: {
    btn_save: 'Save', btn_cancel: 'Cancel', yourAvatar: 'Avatar', size: 'Image file too large. Maximum file size 2 MB',
    allow: 'Format not allowed. Only JPEG, JPG, PNG files allowed'
  },
  es: {
    btn_save: 'Guardar', btn_cancel: 'Cancelar', yourAvatar: 'Avatar', size: 'Archivo demasiado grande. Tamaño máximo 2 Mb',
    allow: 'Formato no permitido. Solo se permiten archivos JPG, JPEG, PNG'
  }
};

const maxSize = 2097152;

export default class ImageModal extends React.Component {
  static propTypes = {
    objectId: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    handleToggle: PropTypes.func.isRequired,
    updateAvatar: PropTypes.func.isRequired,
    action: PropTypes.string,
    language: PropTypes.string,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      image: this.props.url,
      croppedImage: null,
      language: props.language,
      isSaving: false
    }
  }

  render() {
    let avatar = this.state.image;
    if(avatar === '') {
      avatar = '/assets/community.png';
    }

    const colors = this.props.colors;

    return (
      <div className="my-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" style={{margin:'0'}}>
            <button type="button" className="btn px-btn circle xs btn-gray-lighter color-white px-close-modal"
                    data-dismiss="modal" aria-hidden="true" onClick={this.props.handleToggle}>
                <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px', float: 'right', cursor: 'pointer' }} />
            </button>
              <h4 className="px-modal-title">{i18n[this.state.language].yourAvatar}</h4>
            </div>
            <div className=" px-modal-body-add-activity">
              <Cropper ref='cropper'
                       src={avatar}
                       style={{height: 200, width: '100%'}}
                       aspectRatio={1}
                       guides={true}
                       crop={this._crop.bind(this)} />

              <Dropzone onDrop={this.onDrop} minSize={0} maxSize={maxSize} style={{border:'0 solid', marginTop:'20px'}}
                        accept={'image/jpeg, image/png'} onDropRejected={this.onDropRejected}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="btn px-btn circle lg btn-turquoise-base"
                         style={{margin:'10px auto', backgroundColor: colors.color2[3]}}>
                      <FontAwesomeIcon icon={['fas', 'camera']} style={{ fontSize: '16px' }} />
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            <div className=" px-modal-footer-gray">
              <div className="px-footer-container-buttons row">
                <button onClick={this.handleImage} type="button" className="btn px-btn sm btn-blue-base"
                        style={{ backgroundColor: colors.color1[3] }}
                        disabled={this.state.isSaving}>{!this.state.isSaving && i18n[this.state.language].btn_save}
                  {this.state.isSaving && <FontAwesomeIcon icon={['fas','spinner']} spin/>}
                </button>
                <button onClick={this.props.handleToggle} type="button" className="btn px-btn sm btn-gray-darker inverted">
                  {i18n[this.state.language].btn_cancel}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onDrop = acceptedFiles => {
    const file = acceptedFiles[0];

    if(file !== undefined){
      Object.assign(acceptedFiles[0], {preview: URL.createObjectURL(acceptedFiles[0])});
      this.setState({image: file.preview});
    }
  };

  onDropRejected = (e) => {
    console.log('onDropRejected', e[0]);
    let msg = '';

    if(e[0].size > maxSize){
      msg = i18n[this.props.language].size
    } else {
      if(e[0].type !== 'image/jpeg' || e[0].type !== 'image/png'){
        msg = i18n[this.props.language].allow
      }
    }

    showSuccessMsg(msg);
  }

  handleImage = () => {
    this.setState({isSaving: true}, () => {
      this.refs.cropper.getCroppedCanvas({
        fillColor: '#fff',
      }).toBlob(function (blob) {
        const formData = new FormData();
        formData.append("file", blob);
        $('#imgLoading').css('display', 'inline');

        if(this.props.objectId !== -1){
          $.ajax({
            url: this.props.action + this.props.objectId,
            method:'PATCH',
            data: formData,
            processData: false,
            contentType: false,
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            success: (obj) => {
              console.log(obj.avatar);
              this.setState({isSaving: false});
              // To Review, plugin can't change after mount
              this.props.handleToggle();
              if(this.props.action === '/profiles/update_avatar/'){
                this.props.updateAvatar(blob);
                $('#imgLoading').css('display', 'none');
              } else {
                this.props.updateAvatar(blob);
              }
            }
          });
        } else {
          this.setState({isSaving: false});
          // No save to AWS, just show on Modal
          this.props.handleToggle();
          this.props.updateAvatar(blob);
        }

      }.bind(this), 'image/jpeg', 0.5);
    });
  };

  _crop(){
    // image in dataUrl
    // Just for deployment test
  };

}
