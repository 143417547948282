import PropTypes from 'prop-types';
import React from 'react';
import IVERow from "./IVERow";

export default class IVEList extends React.Component {
  static propTypes = {
    invitees: PropTypes.array.isRequired,
    community: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleCountryRegion: PropTypes.func.isRequired,
    role_2: PropTypes.string.isRequired,
    segments: PropTypes.array.isRequired,
    handleSegmentChange: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {community, invitees, type, role_2, segments, language, colors} = this.props;
    const i18n = {
      en: {
        gender: 'Gender', country: 'Country', region: 'Region', segments: 'Segments',
        roles: 'Roles'
      },
      es: {
        gender: 'Género', country: 'País', region: 'Región', segments: 'Segmentos',
        roles: 'Roles'
      }
    }

    const emails = invitees.map((invitee, index) => {
      return(
        <IVERow key={index}
                invitee={invitee}
                community={community}
                handleInputChange={this.props.handleInputChange}
                handleCountryRegion={this.props.handleCountryRegion}
                type={this.props.type}
                role_2={role_2}
                segments={segments}
                handleSegmentChange={this.props.handleSegmentChange}
                language={language}
                colors={colors}/>
      )
    });

    return (
      <div>
        <table className="table table-emails">
          <thead>
          <tr>
            <th style={{width:'15%'}}>Email</th>
            {/*<th style={{width:'10%'}}>First name</th>*/}
            {/*<th style={{width:'10%'}}>Last name</th>*/}
            <th style={{width:'8%'}}>{i18n[language].gender}</th>
            <th style={{ width: '15%' }}>{i18n[language].country}</th>
            <th style={{ width: '15%' }}>{i18n[language].region}</th>
            {/*<th style={{width: '12%', textAlign: 'center'}}>DoB</th>*/}
              {type === 'Participant' ? <th style={{ width: '15%' }}>{i18n[language].segments}</th> : <th style={{ width: '15%' }}>{i18n[language].roles}</th> }
          </tr>
          </thead>

          <tbody>
          { emails }
          </tbody>
        </table>
      </div>
    );
  }
}
