import PropTypes from 'prop-types';
import React from 'react';
import axios from "axios";

import Layout from "../Layout";
import AppDashboard from "../../containers/AppDashboard";
import {showSuccessMsg} from "../UI/Globals/PX_Funs";
import HelpScoutBeacon from '../../components/HelpScoutBeacon';

export default class AppDashboardPage extends React.Component {
  static propTypes = {
    user_account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    notifications: PropTypes.array.isRequired,
    sessionLang: PropTypes.string.isRequired,
    communities: PropTypes.array.isRequired,
    papt: PropTypes.number.isRequired
  };

  constructor(props) {
    // Rebuild ALL component after Beta
    super(props);

    const roles = this._isCollAndPart();
    const filter1Communities = this._setCommunities(props.communities, roles.isCollAndPart, roles.isCollaborator,
      roles.isParticipant, 'Active', 'All');

    this.state = {
      commState: 'Active',
      filter1Communities: filter1Communities,
      communities : filter1Communities,
      origCommunities : props.communities,
      isCollAndPart: roles.isCollAndPart,
      isCollaborator: roles.isCollAndPart,
      isParticipant: false,
      searchTerm: '',
      cuState: 'All',
      showModal: false,
      activeCommunityId: null,
      openPMFromOutside: {type: null, username: '', userId: null, flag: false, communityId: -1, role_1: null, role_2: null},
      showModalDelete: false,
      notifications: [],
      papt: 0,
      notificationsCounter: 0,
      isBeaconInit: false //Keeps track of any instances of Head Scout Beacons initialised.
    }
  }

  componentDidMount(){
    //console.log('AppDashboardPage.componentDidMount');
    const url = new URL(window.location.href);
    const state = url.searchParams.get("state");
    if(state === 'Closed'){
      this.setState({commState: state}, () => {
        this.updateSelectCommunities(state);
      });
    }

    const communities = this.state.communities.map(community => ({...community, newAvatarFile: null,
      newAvatarPreview: null, newAvatarBlob: null, showModal: false}));

    this.setState({communities});
    //this._getNotifications();
    this._getNotificationsCounter();
    if(this.props.profile.role === 'Superadmin'){
      this._getPapt();
    }

    this.updateSelectCommunities('All');
  }

  componentWillUnmount() {
  }

  render() {
    const {profile, sessionLang, user_account} = this.props;
    const language = profile.language ? profile.language : sessionLang;
    const hsBeaconId = profile.role === 'Superadmin' || profile.role === 'Admin' ?
      '216d90b9-d5fb-45f5-aa4b-5a6e667ff8ad' : 'f239f878-05b5-4978-8bee-24a299e122e8';
    const hsBeaconSuggestionsOthers = {  
      en: ['5f1fb82a04286306f8077a8e', '5f1eb5b32c7d3a10cbab55bf', '5f1ec5a52c7d3a10cbab56c3', '5f1eb7cf04286306f8076bce',
        '5f1eb8d02c7d3a10cbab55fa', '5f1eb42704286306f8076b9e', '5f1eb9fd04286306f8076bf8', '5f1eb5452c7d3a10cbab55b6',
        '5f1eb6322c7d3a10cbab55c5', '5f1fadea2c7d3a10cbab6579' ],
      es: ['5f1fc39404286306f8077aed', '5f1fae5604286306f8077a22', '5f1fab532c7d3a10cbab6569', '5f1fac852c7d3a10cbab656d',
        '5f1ebca52c7d3a10cbab5621', '5f1fac1c2c7d3a10cbab656b', '5f1ebc1304286306f8076c15', '5f1faf0304286306f8077a29',
        '5f1fadbb2c7d3a10cbab6577', '5f227e702c7d3a10cbab8d2f']
        };
    const hsBeaconSuggestionsAdmins = {
      en: ['5f1ec3442c7d3a10cbab568c', '5f1fb82a04286306f8077a8e', '5f1ec53f04286306f8076c84', '5f1eb5b32c7d3a10cbab55bf',
        '5f1ec5a52c7d3a10cbab56c3', '5f1eb7cf04286306f8076bce', '5f1ec3f804286306f8076c6f', '5f1eb8d02c7d3a10cbab55fa',
        '5f1eb42704286306f8076b9e', '5f1eb9fd04286306f8076bf8']
      };

    const hsBeaconSuggestions = profile.role === 'Superadmin' || profile.role === 'Admin' ?
      hsBeaconSuggestionsAdmins : hsBeaconSuggestionsOthers;

    return (
      <Layout user_account={user_account}
              profile={profile}
              sessionLang={sessionLang}
              notifications={this.state.notifications}
              from='Dashboard'
              communities={this.props.communities}
              community={{id: this.state.openPMFromOutside.communityId}}
              commState={this.state.commState}
              updateSelectCommunities={this.updateSelectCommunities}
              openPMFromOutside={this.state.openPMFromOutside}
              updateOpenPMFromOutside={this.updateOpenPMFromOutside}
              notificationsCounter={this.state.notificationsCounter}
              colors={user_account.all_colors}>

          {/*<HelpScoutBeacon beaconId={hsBeaconId}*/}
          {/*                 user_account={this.props.user_account}*/}
          {/*                 colors={this.props.user_account.all_colors}*/}
          {/*                 language={language}*/}
          {/*                 isBeaconInit={this.state.isBeaconInit}*/}
          {/*                 updateIsBeaconInit={this.updateIsBeaconInit}*/}
          {/*                 suggestions={hsBeaconSuggestions}*/}
          {/*                 hideFABOnDesktop={!this.props.user_account.profile.is_help} />*/}

        {/*<button onClick={this.viewInvoiceTEST}>TEST INVOICE PDF</button>*/}

        <AppDashboard user_account={this.props.user_account}
                      profile={this.props.profile}
                      sessionLang={this.props.sessionLang}
                      notifications={this.state.notifications}
                      communities={this.state.commState !== 'Hidden' ? this.state.communities :
                        this.state.origCommunities.filter(c => c.cu_hide)}
                      isCollAndPart={this.state.isCollAndPart}
                      isCollaborator={this.state.isCollaborator}
                      isParticipant={this.state.isParticipant}
                      updateIsCollIsPart={this.updateIsCollIsPart}
                      searchTerm={this.state.searchTerm}
                      handleChange={this.handleChange}
                      handleSearch={this.handleSearch}
                      cuState={this.state.cuState}
                      updateStateInvitation={this.updateStateInvitation}
                      updateCropBlob={this.updateCropBlob}
                      updateActiveCommunityId={this.updateActiveCommunityId}
                      updateShowModal={this.updateShowModal}
                      onDropImage={this.onDropImage}
                      handleFavourite={this.handleFavourite}
                      updateHide={this.updateHide}
                      commState={this.state.commState}
                      updateCommunityState={this.updateCommunityState}
                      showModalDelete={this.state.showModalDelete}
                      updateShowModalDelete={this.updateShowModalDelete}
                      purgeCommunity={this.purgeCommunity}
                      origCommunities={this.state.origCommunities}
                      papt={this.state.papt}
                      language={language}
                      isBeaconInit={this.state.isBeaconInit}
                      updateIsBeaconInit={this.updateIsBeaconInit}/>
      </Layout>
    );
  }

  viewInvoiceTEST = () => {
    window.open('/invoices/show/35.pdf', '_blank');
  }

  _beaconInit = hsBeaconId => {
    Beacon('init', hsBeaconId);
    Beacon('on', 'ready', () => {
      this.updateIsBeaconInit(true);
    });
  };

  updateIsBeaconInit = (is_init) => {
    this.setState({ isBeaconInit: is_init });
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    console.log(name, value);
    this.setState({[name]: value}, () => {
      if(name === 'cuState'){
        this.setState({
          communities: this._setCommunities(this.props.communities, this.state.isCollAndPart, this.state.isCollaborator,
            this.state.isParticipant, this.state.commState, this.state.cuState)
        });
      }
    });
  };

  _getPapt(){
    $.ajax({
      url: '/users/get_papt',
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: papt => {
        //console.log(papt);
        this.setState({papt});
      }
    });
  }

  _getNotificationsCounter = () => {
    $.ajax({
      url: '/notifications/get_notifications_counter/',
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: notificationsCounter =>  {
        //console.log(notificationsCounter);
        this.setState({notificationsCounter});
      }
    });
  };

  _getNotifications = () => {
    $.ajax({
      url: '/notifications/get_notifications/-1',
      method: 'GET',
      success: notifications =>  {
        this.setState({notifications});
      }
    });
  };

  purgeCommunity = communityId => {
    $.ajax({
      url: '/communities/delete/' + communityId,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () =>  {
        this.setState({showModalDelete: false});
        axios.get('/communities/purge/' + communityId)
          .then(response => {
            //console.log(response.data);
          });

        window.location.href = '/users/dashboard';
      }
    });
  };

  updateShowModalDelete = () => {
    this.setState(prevState => ({showModalDelete: !prevState.showModalDelete}));
  };

  updateCommunityState = (state, communityId) => {
    console.log(state, communityId);
    //const roles = this._isCollAndPart();

    $.ajax({
      url: '/communities/update_state/' + communityId + '/' + state,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communities => {
        /*console.log(communities);
        this.setState({communities: this._setCommunities(communities, roles.isCollAndPart, roles.isCollaborator,
            roles.isParticipant, 'Active', 'All')});*/
        showSuccessMsg('Success!');
        window.location.href = '/users/dashboard/';
      }
    });
  };

  updateHide = (communityId) => {
    console.log(communityId);
    $.ajax({
      url: '/communities_user/update_hide/' + communityId,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: communities => {
        location.reload();
        /*console.log(communities);
        const counter = communities.filter(c => c.cu_hide);
        console.log(counter.length);
        $('#spnHiddenCounter').text(counter.length);
        const roles = this._isCollAndPart();

        this.setState({
          origCommunities: communities,
          communities: this._setCommunities(communities, roles.isCollAndPart, roles.isCollaborator,
            roles.isParticipant, 'Active', 'All')
        });
        showSuccessMsg();*/
      }
    });
  };

  updateOpenPMFromOutside = (type, username, userId, communityId) => {
    // Just in this case it needs the communityId because by default it doesn't have a selected Community
    console.log(type, username, userId, communityId);
    if(!this.state.openPMFromOutside.role_1){
      $.ajax({
        url: '/communities_user/get_cu/' + communityId,
        method: 'GET',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: cu =>  {
          console.log(cu);
          this.setState(state => ({
            openPMFromOutside: {
              type, username, userId, flag: !state.openPMFromOutside.flag, communityId,
              role_1: cu.role_1, role_2: cu.role_2
            }
          }));
        }
      });
    }

    if(type === undefined && username === undefined && userId === undefined && communityId === undefined){
      this.setState(prevState => ({
        openPMFromOutside: {communityId: -1, flag: false, role_1: null, role_2: null, type: null, userId: null, username:""}
      }));
    }
  };

  //TO-DO Improve to have one state.communities because if you change image or favourite and switch I'm a Participant
  //and get back to I'm a Collaborator then the change just in the UI is lost
  handleFavourite = (e, communityId) => {
    e.stopPropagation();
    $.ajax({
      url: '/communities/favourite/' + communityId,
      method: 'PATCH',
      dataType: 'JSON',
      success: response => {
        if(response){
          const communities = this.state.communities.map(community => community.id === communityId ?
            {...community, favourite: !community.favourite} : community);

          this.setState({communities: this.sortByFavourite(communities)});

        } else {
          console.log('Error from Server');
        }
      }
    });
  };

  sortByFavourite(communities){
    communities.sort((x, y) => {
      return (x.favourite === y.favourite) ? new Date(y.created_at) - new Date(x.created_at) : x.favourite ? -1 : 1;
    });
    console.log(communities);

    return communities;
  }

  onDropImage = (acceptedFiles) => {
    if(acceptedFiles.length !== 0){
      Object.assign(acceptedFiles[0], {preview: URL.createObjectURL(acceptedFiles[0])});

      const communityId = this.state.activeCommunityId;
      const communities = this.state.communities.map(community => community.id === communityId ?
        {...community, newAvatarFile: acceptedFiles[0], newAvatarPreview: acceptedFiles[0].preview} : community);

      this.setState({communities});
    }
  };

  updateShowModal = (e, communityId) => {
    if(e){
      e.stopPropagation();
    }
    console.log('updateShowModal');
    const communities = this.state.communities.map(community => {
      if(community.id === communityId){
        this.setState({activeCommunityId: !community.showModal ?  communityId : null});
        return {...community, showModal: !community.showModal, newAvatarFile: null, newAvatarPreview: null};
      } else {
        return community;
      }
    });

    this.setState({communities});
  };

  updateActiveCommunityId = (activeCommunityId) => {
    this.setState({activeCommunityId});
  };

  // Is newAvatarFile useful? Because at the end I will send a blob file modified by Crop tool
  updateCropBlob = (blob) => {
    //console.log(blob);
    const formData = new FormData;
    formData.append("file", blob);
    const communityId = this.state.activeCommunityId;

    const communities = this.state.communities.map(community => community.id === communityId ?
      {...community, showModal: false, newAvatarBlob: blob} : community);

    this.setState({communities});

    $.ajax({
      url: '/communities/update_avatar/' + communityId,
      method:'PATCH',
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: obj => {
        console.log(obj);
        const communities = this.state.communities.map(community => {
          if(community.id === communityId){
            this.setState({activeCommunityId: null});
            return {...community, avatar: obj.avatar, newAvatarFile: null, newAvatarPreview: null};
          } else {
            return community;
          }
        });

        this.setState({communities})
      }
    });
  };

  updateStateInvitation = (type, communityId) => {
    console.log(type);
    if(type === 'accept'){
      window.location.href = '/users/welcome/'+ communityId +'/Participant';
    } else {
      const roles = this._isCollAndPart();

      $.ajax({
       url: '/communities/part_update_invitation/',
       method: 'PATCH',
       dataType: 'JSON',
       data: {
         id: communityId,
         type: type
       },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: () => {
         location.reload();
         //console.log(communities);
         /*if(communities.length === 0){
           window.location.href = '/users/welcome/'+ communityId +'/Participant';
         } else {
           this.setState({communities: this._setCommunities(communities, roles.isCollAndPart, roles.isCollaborator,
               roles.isParticipant, 'Active', 'All')});
           showSuccessMsg();
         }*/
       }
     });
    }
  };

  handleSearch = (event) => {
    event.preventDefault(); // why it doesn't behave like the other buttons, maybe 'cos it's inside a <form>
    let communities = null;
    if(this.state.searchTerm !== ''){
      const q = new RegExp(this.state.searchTerm, 'i');
      communities = this.state.communities.filter(community => community.title.match(q));
    } else {
      //communities = this.state.filter1Communities;
      communities = this._setCommunities(this.props.communities, this.state.isCollAndPart, this.state.isCollaborator,
        this.state.isParticipant, this.state.commState, this.state.cuState)

    }
    //console.log(communities);
    this.setState({communities});
  };

  updateIsCollIsPart = (name) => {
    if(name === 'isCollaborator'){
      this.setState({
        isCollaborator: true,
        isParticipant: false,
        communities: this._setCommunities(this.props.communities, true, true, false, this.state.commState, this.state.cuState),
        searchTerm: ''
      })
    } else {
      this.setState({
        isCollaborator: false,
        isParticipant: true,
        communities: this._setCommunities(this.props.communities, true, false, true, this.state.commState, this.state.cuState),
        searchTerm: ''
      })
    }
  };

  updateSelectCommunities = commState => {
    //console.log(commState);
    const filter1Communities = this._setCommunities(this.props.communities, this.state.isCollAndPart,
      this.state.isCollaborator, this.state.isParticipant, commState, this.state.cuState);
    //console.log(filter1Communities);
    this.setState({
      commState,
      filter1Communities,
      communities: filter1Communities,
      searchTerm: ''
    });
  };

  // TO-DO when the user change from Coll or Part, it doesn't load the state.communities, it would be or the argument
  // would be communities, remember we need to change to state.communities and test it
  _setCommunities(communities, isCollAndPart, isCollaborator, isParticipant, commState, cuState){
    //console.log(communities);
    let initCommunities = null;
    let filteredCommunities = null;

    if(isCollAndPart){
      if(isCollaborator){
        initCommunities = communities.filter(community => community.role_1 === 'Collaborator');
      } else {
        initCommunities = communities.filter(community => community.role_1 === 'Participant');
      }
    } else {
      initCommunities = communities;
    }
    //console.log(initCommunities);

    switch(commState){
      case 'Active':
        filteredCommunities = initCommunities.filter(community => community.state === 'Enabled');
        break;
      case 'Closed':
        filteredCommunities = initCommunities.filter(community => community.state === 'Closed');
        break;
      case 'Archived':
        filteredCommunities = initCommunities.filter(community => community.state === 'Archived');
        break;
      case 'All':
        filteredCommunities = initCommunities;
        break;
      case 'Hidden':
        filteredCommunities = initCommunities.filter(community => community.cu_hide);
        break;
    }
    //console.log(filteredCommunities);
    //console.log(this._sortByCU(filteredCommunities, cuState, commState));
    return this._sortByCU(filteredCommunities, cuState, commState);
  }

  _sortByCU(communitiesArray, cuState, commState){
    let invited = [];
    let active = [];
    let removed = [];
    let declined = [];

    //if(commState !== 'Hidden'){
      if(cuState === 'All'){
        invited = communitiesArray.filter(c => c.cu_state === 'Invited');
        active = communitiesArray.filter(c => c.cu_state === 'Enabled');
        removed = communitiesArray.filter(c => c.cu_state === 'Blocked' || c.cu_state === 'Removed');
        declined = communitiesArray.filter(c => c.cu_state === 'Declined');
      } else {
        switch(cuState){
          case 'Invited':
            invited = communitiesArray.filter(c => c.cu_state === 'Invited'); break;
          case 'Active':
            active = communitiesArray.filter(c => c.cu_state === 'Enabled'); break;
          case 'Blocked':
          case 'Removed':
            removed = communitiesArray.filter(c => c.cu_state === 'Blocked' || c.cu_state === 'Removed'); break;
          case 'Declined':
            declined = communitiesArray.filter(c => c.cu_state === 'Declined');
        }
      }
    /*} else {
      invited = communitiesArray
    }*/

    //console.log([...invited, ...active, ...removed, ...declined]);
    return [...invited, ...active, ...removed, ...declined];
  }

  _isCollAndPart(){
    let isPart = false;
    let isColl = false;

    this.props.communities.forEach(community => {
      if(community.role_1 === 'Participant' ){
        isPart = true
      }
      if(community.role_1 === 'Collaborator' ){
        isColl = true
      }
    });

    return {isCollAndPart: isColl && isPart, isCollaborator: isColl, isParticipant: isPart}
  }
}
