import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";

export default class MBBilling extends React.Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    company: PropTypes.object.isRequired,
    saveBilling: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      company: props.company
    }
  }

  render() {
    const {language, colors} = this.props;

    const i18n = {
      en: {
        title: 'Billing details', billing_name: 'Company Name', billing_address_1: 'Address line 1',
        billing_address_2: 'Address line 2', billing_country: 'Country', billing_province: 'State/Province',
        billing_postal_code: 'Postal Code', billing_other: 'Other', btn_save: 'Save', sltCountry: 'Select Country',
        sltRegion: 'Select Region', telephone: 'Telephone'
      },
      es: {
        title: 'Detalles de facturación', billing_name: 'Nombre de empresa', billing_address_1: 'Dirección 1',
        billing_address_2: 'Dirección 2', billing_country: 'País', billing_province: 'Estado/Provincia',
        billing_postal_code: 'Código postal', billing_other: 'Otro', btn_save: 'Guardar', sltCountry: 'Seleccionar País',
        sltRegion: 'Seleccionar Región', telephone: 'Teléfono'
      }
    };

    return(
      <div className="px-card px-card-settings">
        <div className="px-icon-settings">
          <FontAwesomeIcon icon={['fas','dollar-sign']}/>
        </div>
        <div className="panel-body px-body-padding-settings">
          <div className="container-fluid">
            <div className="row px-container-general-settings">
              <div className="px-settings-inner-container" style={{width: '100%'}}>
                <div className="row" style={{textAlign: 'center'}}>
                  <div className="col-md-12">
                    {/* <span className="px-allw-title" style={{fontSize: '18px'}}>{i18n[language].title}</span> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group first">
                      <label className="label-sm" htmlFor="billing_name" style={{ color: colors.color1[2]}}>{i18n[language].billing_name}</label>
                      <input name="billing_name" onChange={this.handleChange} value={this.state.company.billing_name || ''}
                             type="text" className="form-control px-input-settings" placeholder=""/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="label-sm" htmlFor="billing_address_1" style={{ color: colors.color1[2] }}>{i18n[language].billing_address_1}</label>
                      <input name="billing_address_1" onChange={this.handleChange} value={this.state.company.billing_address_1 || ''}
                             type="text" className="form-control px-input-settings" placeholder=""/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="label-sm" htmlFor="billing_address_2" style={{ color: colors.color1[2] }}>{i18n[language].billing_address_2}</label>
                      <input name="billing_address_2" onChange={this.handleChange} value={this.state.company.billing_address_2 || ''}
                             type="text" className="form-control px-input-settings" placeholder=""/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label-sm" htmlFor="billing_country" style={{ color: colors.color1[2] }}>{i18n[language].billing_country}</label>
                      <CountryDropdown value={this.state.company.billing_country || ''}
                                       onChange={(val) => this.selectCountry(val)}
                                       valueType="short"
                                       classes="form-control"
                                       defaultOptionLabel={i18n[language].sltCountry}/>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label-sm" htmlFor="billing_province" style={{ color: colors.color1[2] }}>{i18n[language].billing_province}</label>
                      <RegionDropdown country={this.state.company.billing_country || ''}
                                      value={this.state.company.billing_province || ''}
                                      onChange={(val) => this.selectRegion(val)}
                                      countryValueType="short"
                                      classes="form-control"
                                      defaultOptionLabel={i18n[language].sltRegion}/>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="label-sm" htmlFor="billing_postal_code" style={{ color: colors.color1[2] }}>{i18n[language].billing_postal_code}</label>
                      <input name="billing_postal_code" onChange={this.handleChange}
                             value={this.state.company.billing_postal_code || ''}
                             type="text" className="form-control px-input-settings" placeholder=""/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="label-sm" htmlFor="billing_telephone" style={{ color: colors.color1[2] }}>{i18n[language].telephone}</label>
                      <input name="billing_telephone" onChange={this.handleChange} value={this.state.company.billing_telephone || ''}
                             type="text" className="form-control px-input-settings" placeholder=""/>
                    </div>
                  </div>
                </div>
                {/*<div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="label-sm" htmlFor="billing_other"style={{ color: colors.color1[2]}}>{i18n[language].billing_other}</label>
                      <input name="billing_other" onChange={this.handleChange} value={this.state.company.billing_other || ''}
                             type="text" className="form-control px-input-settings" placeholder=""/>
                    </div>
                  </div>
                </div>*/}
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-row-buttons-action welcome">
                    <button onClick={() => this.props.saveBilling(this.state.company)} className="btn px-btn lg btn-blue-base"
                      style={{ backgroundColor: colors.color1[3] }}>
                      {i18n[language].btn_save}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({company: {...this.state.company, [name]: value}});
  };

  selectCountry = val => {
    this.setState({company: {...this.state.company, billing_country: val} });
  }

  selectRegion = val => {
    this.setState({company: {...this.state.company, billing_province: val} });
  }
}