import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"
import moment from 'moment';
import moment_tz from 'moment-timezone';
import Dotdotdot from "react-dotdotdot";
import SocialStats from "./PActCard/SocialStats";

export default class P_TaskCard extends React.Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    forwardTask: PropTypes.func.isRequired,
    updateShowTask: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    now: PropTypes.string.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    language: PropTypes.string,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isMax: props.task.sum_comments_replies_unseen > 0
    }
  }


  render() {
    const {task, from, language, now, colors} = this.props;

    const dateLoc = language === 'en' ? 'en-GB' : 'es-ES';
    const i18n = {
      en: {
        expires: 'expires', tonight: 'tonight', never: 'never', expired: 'expired', available: 'available',
        first: 'Please answer first,', tasks: 'tasks', display01: 'Other participants cannot see your responses or comments',
        display02: 'Other participants can see your responses or comments', comments: 'Comments posted in your response',
        likes: 'Likes to your response', expand: 'Expand panel for details',
        collapse: 'Collapse panel to hide details', unread_comments: 'You have unread comments'
      },
      es: {
        expires: 'expira', tonight:  'esta noche', never: 'nunca', expired: 'expiró', available: 'disponible',
        first: 'Responde primero,', tasks: 'tareas', display01: 'Otros participantes no pueden ver sus respuestas o comentarios',
        display02: 'Otros participantes pueden ver sus respuestas o comentarios', comments: 'Comentarios publicados en tu respuesta',
        likes: '"Likes" a tu respuesta', expand: 'Expanda el panel para más detalles',
        collapse: 'Contraer panel para ocultar detalles', unread_comments: 'Tienes comentarios no leídos'
      }
    };

    let expireCard = '';
    let color = 'complete';
    let displayOverlay = 'none';
    let message = null;
    let iconMaxMin = <FontAwesomeIcon icon={this.state.isMax ? ['fas','down-left-and-up-right-to-center'] : ['fas','up-right-and-down-left-from-center']}
                                      onClick={this.updateIsMax}
                                      className="icon-collapse"
                                      style={{fontSize: '16px', display:'block', zIndex:'1000 !important'}}
                                      data-tooltip-content={this.state.isMax ? i18n[language].collapse : i18n[language].expand}/>;

    let expiredStyleCommentAlert = '';

    if(task.expire){
      const expireDate = moment(task.expire).format('YYYY-MM-DD');
      //console.log('task.title:', task.title, 'now:', now);
      if(now === expireDate){
        expireCard = i18n[language].expires + ' ' + i18n[language].tonight;
        color = 'draft';
      } else {
        //console.log('task.title:', task.title, 'now:', now, 'expireDate:', expireDate);
        if(now > expireDate){
          //console.log('Expired:', task.title);
          expireCard = i18n[language].expires + ' ' + i18n[language].expired;
          color = 'open';
          displayOverlay = 'flex';
          message = <span className="card-msg-expired" style={{color: colors.color4[3]}}>{i18n[language].expired}</span>;
          expiredStyleCommentAlert = 'unread-alert-expire';
          iconMaxMin = null;
        } else {
          expireCard = i18n[language].expires + ' ' + moment(task.expire).locale(dateLoc).format('DD MMMM YYYY');
        }
      }
    } else {
      expireCard = i18n[language].never + ' ' + i18n[language].expires;
    }
    //console.log('title:', task.title, 'displayOverlay:', displayOverlay);

    let isLiveAvailable = true;

    if(task.start){
      const startDate = moment(task.start).format('YYYY-MM-DD');

      if(now < startDate){
        expireCard = i18n[language].available + ' ' + moment(task.start).locale(dateLoc).format('DD MMMM YYYY');
        color = 'open';
        iconMaxMin = null;
        isLiveAvailable = false;
      }
    }
    //console.log('title:', task.title, 'displayOverlay:', displayOverlay);

    if(task.required && isLiveAvailable){
      if(task.isRequiredDone){
        //displayOverlay = 'none';
      } else {
        color = 'complete';
        displayOverlay = 'flex';

        message = <div className="card-msg-required">{i18n[language].first}<br/>
        <Dotdotdot clamp={1}>"{task.required && task.required.label}"</Dotdotdot></div>;

        iconMaxMin = null;
      }
    }
    //console.log('title:', task.title, 'displayOverlay:', displayOverlay);

    let taskCard = '';
    if(from === 'TODO'){
      taskCard = <div className="col-md-12 pd-left-0">
        <div onClick={this._setActive} className={"px-dash-part-question " + color }
             style={{height: !this.state.isMax && '75px', borderLeftColor: colors.colorParticipants[0]}}>
          <div style={{display: displayOverlay}} className="px-actv-overlay">{ message }</div>
          { iconMaxMin }
          <p className="expires complete" style={{color: colors.colorParticipants[0]}}>{expireCard}</p>
          <p className="title-activity" data-tooltip-content={task.title}>{task.title}</p>
          {this.state.isMax && <p className="task-number">{task.questions.length} {i18n[language].tasks}</p>}
          {
            this.state.isMax &&
            <SocialStats task={task}
                         i18n={i18n}
                         language={language}
                         colors={colors}
                         expiredStyleCommentAlert={expiredStyleCommentAlert}
                         color={color} type='ToDo'/>
          }
        </div>
      </div>
    } else {
      taskCard = <div className=" col-md-12 pd-left-0">
        <div onClick={this._setActive} className="px-dash-part-question done"
             style={{ borderLeftColor: colors.colorParticipants[3], backgroundColor: colors.colorParticipants[4]}}>
          {/*<div className="unseen responsive"/>*/}
          <p className="title-activity" data-tooltip-content={task.title}>{task.title}</p>
          <SocialStats task={task}
                       i18n={i18n}
                       language={language}
                       colors={colors}
                       expiredStyleCommentAlert={expiredStyleCommentAlert}
                       color={color} type='Done'/>
        </div>
      </div>
    }

    return (
      <React.Fragment>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        { taskCard }
      </React.Fragment>
    )
  }

  updateIsMax = (event) => {
    event.stopPropagation();
    this.setState(state => ({isMax: !state.isMax}));
  };

  _setActive = () => {
    const {task, now} = this.props;
    //const now = moment().format('YYYY-MM-DD');

    if(task.required){
      if(task.isRequiredDone){
        if(task.start){
          const startDate = moment(task.start).format('YYYY-MM-DD');

          if(task.expire){
            const expireDate = moment(task.expire).format('YYYY-MM-DD');

            if(now < startDate){
              this.props.updateShowModal(task.id, 'No yet start');
            } else {
              if(now <= expireDate){
                this.props.forwardTask(task.id);
              } else {
                this.props.updateShowModal(task.id, 'Expired');
              }
            }
          } else {
            if(now < startDate){
              this.props.updateShowModal(task.id, 'No yet start');
            } else {
              this.props.forwardTask(task.id);
            }
          }
        } else {
          if(task.expire){
            const expireDate = moment(task.expire).format('YYYY-MM-DD');

            if(now <= expireDate){
              this.props.forwardTask(task.id);
            } else {
              this.props.updateShowModal(task.id, 'Expired');
            }
          } else {
            this.props.forwardTask(task.id);
          }
        }


        //this.props.forwardTask(task.id);
      } else {
        this.props.updateShowModal(task.required.value, 'required', task.isRequiredDone);
      }
    } else {
      if(task.start){
        const startDate = moment(task.start).format('YYYY-MM-DD');

        if(task.expire){
          const expireDate = moment(task.expire).format('YYYY-MM-DD');

          if(now < startDate){
            this.props.updateShowModal(task.id, 'No yet start');
          } else {
            if(now <= expireDate){
              this.props.forwardTask(task.id);
            } else {
              this.props.updateShowModal(task.id, 'Expired');
            }
          }
        } else {
          if(now < startDate){
            this.props.updateShowModal(task.id, 'No yet start');
          } else {
            this.props.forwardTask(task.id);
          }
        }
      } else {
        if(task.expire){
          const expireDate = moment(task.expire).format('YYYY-MM-DD');

          if(now <= expireDate){
            this.props.forwardTask(task.id);
          } else {
            this.props.updateShowModal(task.id, 'Expired');
          }
        } else {
          this.props.forwardTask(task.id);
        }
      }
    }
  }
}
