import PropTypes from 'prop-types';
import React from 'react';

import TRCWordCloud from "./TRConsolidated/TRCWordCloud";
import TRCBarChart from "./TRConsolidated/TRCBarChart";

export default class TRGeneral extends React.Component {
  static propTypes = {
    cardinal: PropTypes.number.isRequired,
    usersId: PropTypes.array.isRequired,
    responsesConsolidated: PropTypes.array.isRequired,
    taskTile: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
    responses: PropTypes.array.isRequired,
    isVirtualized: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired,
    language: PropTypes.string,
    cmntyLanguage: PropTypes.string.isRequired,
    taskChoices: PropTypes.array,
    taskId: PropTypes.number,
    graph: PropTypes.object,
    updateSVGMulti: PropTypes.func,
    task_id: PropTypes.number,
    setCurrentHeight: PropTypes.func,
    resConsolidated: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //console.log('TRGeneral.componentDidMount');
  }

  render() {
    const { cardinal, usersId, taskTile, taskType, responses, language, cmntyLanguage, taskChoices, taskId, isVirtualized,
      graph, task_id, colors, resConsolidated} = this.props
    const i18n = {
      en: { noWordcloud: 'Not enough text to create a WordCloud',
            noChart: 'Not enough data to create a chart',
            noHeatmap: 'Cannot create a Heatmap at the moment'
          },
      es: { noWordcloud: 'No hay suficiente texto para crear un Wordcloud',
            noChart: 'No hay suficientes datos para crear una gráfica',
            noHeatmap: 'No se puede crear un Heatmap por ahora'
        }
    }

    const cardHeader = <div className="px-counter-task" style={{backgroundColor: colors.color0[1]}}>
      <span className="number">{cardinal}</span></div>
    const cardTitle = <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
        <p dangerouslySetInnerHTML={{__html: taskTile}} className="title"/>
      </div>
    </div>;

    let wordCloud = null;
    let barChart = null;
    let heatmap = null;

    switch(taskType) {
      case 'Open End':
        //console.log(responses);
        const answers = responses.map(response => response.data);
        //console.log(answers);
        const allMapsOE = responses.map(response => response.participantMapItems.map(item => item[cmntyLanguage]));
        //console.log(allMapsOE);
        const maps = [].concat.apply([], allMapsOE);
        //console.log(maps);
        const uniqueAnswers = [...new Set(answers)];
        //console.log('uniqueAnswers:', uniqueAnswers);
        if (answers.length > 1 && answers.join().split('').length > 200){
        //if(answers.length !== 0 && uniqueAnswers[0] !== ''){
          wordCloud = <TRCWordCloud answers={answers}
                                    type={'multi'}
                                    usersId={usersId}
                                    isVirtualized={isVirtualized}
                                    svg={graph.wordCloud}
                                    updateSVGMulti={this.props.updateSVGMulti}
                                    task_id={task_id}
                                    setCurrentHeight={this.props.setCurrentHeight}
                                    colors={colors}
                                    language={language}/>
        } else {
          wordCloud = <h3 className='px-empty-msg' style={{ margin: '20px 0', color: colors.color4[3]}}>{i18n[language].noWordcloud}</h3>
        }

        if(maps.length !== 0){
          //console.log('graph:', graph.responsesConsolidated.length);
          barChart = <TRCBarChart maps={maps}
                                  type={'multi'}
                                  usersId={usersId}
                                  taskType={taskType}
                                  answersLength={resConsolidated.responses.length}
                                  //answersLength={usersId.length}
                                  taskId={taskId}
                                  isVirtualized={isVirtualized}
                                  colors={colors}
                                  language={language}/>
        } else {
          barChart = <h3 className='px-empty-msg' style={{ margin: '20px 0', color: colors.color4[3] }}>{i18n[language].noChart}</h3>
        }
        break;
      case 'Choice':
        //console.log('resConsolidated:', resConsolidated);
        //const allMapsCh = responses.map(response => response.data.answersId.map(item => item));
        //console.log(responses);
        const mapsCh = [].concat.apply([], responses);
        //console.log(mapsCh);
        if(mapsCh.length !== 0){
          console.log(mapsCh);
          barChart = <TRCBarChart maps={mapsCh}
                                  type={'multi'}
                                  usersId={usersId}
                                  taskType={taskType}
                                  answersLength={resConsolidated.responses.length}
                                  //answersLength={usersId.length}
                                  taskAnswers={taskChoices}
                                  taskId={taskId}
                                  isVirtualized={isVirtualized}
                                  colors={colors}
                                  language={language}/>
        } else {
          barChart = <h3 className='px-empty-msg' style={{ margin: '20px 0', color: colors.color4[3] }}>{i18n[language].noChart}</h3>
        }
        break;
      case 'MicroChat':
        barChart = <h3 className='px-empty-msg' style={{ margin: '20px 0', color: colors.color4[3] }}>{i18n[language].noChart}</h3>
        break
      case 'Multimedia':
        barChart = <h3 className='px-empty-msg' style={{ margin: '20px 0', color: colors.color4[3] }}>{i18n[language].noChart}</h3>
        break
      case 'Canvas':
        heatmap = <h3 className='px-empty-msg' style={{ margin: '20px 0', color: colors.color4[3]}}>{i18n[language].noHeatmap}</h3>
        break
    }

    return (
      <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
        { cardHeader }
        <div className="panel px-card px-panel-task">
          <div className="panel-body">
            { cardTitle }
            { wordCloud }
            { barChart }
            { heatmap }
          </div>
        </div>
      </div>
    );
  }
}
