import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateRangePicker} from "react-dates";
import moment from "moment";

import {isInclusivelyAfterDay} from "../Globals/PX_Funs";

export default class QuoteModal extends React.Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    num_participants: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    getCost: PropTypes.func.isRequired,
    updateDates: PropTypes.func.isRequired,
    flagNumParts: PropTypes.bool.isRequired,
    participants_start: PropTypes.string.isRequired,
    participants_end: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    estimated_cost: PropTypes.number.isRequired,
    sumSubs: PropTypes.number.isRequired,
    priceUnitPD: PropTypes.number.isRequired,
    sumCash: PropTypes.number.isRequired,
    subscription: PropTypes.object.isRequired,
    createTermsPDF: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    save: PropTypes.func.isRequired,
    assigned_flex: PropTypes.number.isRequired,
    updateShowModalQuote: PropTypes.func.isRequired,
    calculatorFlag: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      chk01: true, chk02: true, chk03: true, chk04: false,
      disabledPickers: false
    }
  }

  componentDidMount() {
    let disabledPickers;
    const now = moment(moment().format('YYYY-MM-DD'));

    if(now > moment(this.props.participants_start) && now > moment(this.props.participants_end)){
      disabledPickers = true;
    } else {
      if(now > moment(this.props.participants_start)){
        disabledPickers = 'startDate'
      }
    }

    this.setState({disabledPickers});
  }

  render() {
    const {language, colors, num_participants, flagNumParts, participants_start, participants_end, loading,
      estimated_cost, sumSubs, priceUnitPD, sumCash, subscription, from, assigned_flex, calculatorFlag} = this.props;

    const i18n = {
      en: {
        quoteTitle: 'Estimated cost', create: 'Create', close: 'Close', numParts: 'Participants',
        partsAccess: "Participants access", maxParts: '50 participants max. Contact support.',
        platform: 'Platform license estimate', settle: 'Settled with', payment: 'Balance to pay',
        partsFor: ' Participants ', days: ' days', priceFor: 'Total Cost for ', subsSlot: ' subscription slots per day',
        flex: ' Flexdays', remaining: ' Balance to pay',
        read01: 'I\'ve read and accept the terms and conditions of the ', read02: ' SAAS Agreement',
        numPartsWarn: 'Enter a number between 10 and 100', save: 'Save',
        participantDays: 'Participant*days'
      },
      es: {
        quoteTitle: 'Costo estimado', create: 'Crear', close: 'Cancelar', numParts: 'Participantes',
        partsAccess: 'Acceso a participantes', maxParts: '50 participantes máx. Contactar Soporte.',
        platform: 'Estimación de licencia de plataforma', settle: 'Liquidado con', payment: 'Balance a pagar',
        partsFor: ' Participantes ', days: ' días', priceFor: 'Costo Total para ', subsSlot: ' slots de suscripción por día',
        flex: ' Flexdays', remaining: ' Saldo a pagar',
        read01: 'He leído y acepto los términos y condiciones del ', read02: ' Contrato',
        numPartsWarn: 'Ingresar número entre 10 y 100', save: 'Confirmar',
        participantDays: 'Días*participante'
      }
    };

    let days = 0;
    let flexDaysPD = 0;
    let finalSumCashPD = sumCash;

    if(participants_start && participants_end && num_participants !== '') {
      days = moment(participants_end).add(1, 'days').diff(moment(participants_start), 'days');
    }
    const totalPD = num_participants * days;

    if(sumCash > 0 && (assigned_flex + subscription.flex_days) > 0){
      const initCashPD = totalPD - sumSubs;
      const initFlexDaysPD = initCashPD - (assigned_flex + subscription.flex_days) ;

      flexDaysPD = initFlexDaysPD < 0 ? initCashPD : (assigned_flex +subscription.flex_days);
      finalSumCashPD = sumCash - (assigned_flex + subscription.flex_days) < 0 ? 0 : sumCash - (assigned_flex + subscription.flex_days);
    }

    const calSubsPrice = (estimated_cost - (sumSubs * priceUnitPD) - (flexDaysPD * priceUnitPD)).toFixed(2);
    const subsPrice = calSubsPrice > 0 ? calSubsPrice : 0.00;
    const flagTerms = num_participants === 0 || num_participants === '' || participants_start === null ||
      participants_end === null || !(calculatorFlag >= 2);
    const flagCreate = num_participants === 0 || num_participants === '' || participants_start === null ||
      participants_end === null || !this.state.chk04 || !(calculatorFlag >= 2);

    return (
      <div className="my-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="close" onClick={() => this.props.updateShowModalQuote('close')}>
                <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
              </button>
              <h4 className="px-modal-title">{i18n[language].quoteTitle}</h4>
            </div>
            <div className="modal-body px-modal-body-add-activity">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
                  <div className="row">
                    <div className="col-xs-4 col-sm-4 col-md-3 mg-bottom-15">
                      <div className="form-group">
                        <label className="label-xs" style={{ color: colors.color0[3]}}>{i18n[language].numParts}</label>
                        <input name="num_participants" onChange={this.props.handleChange} value={num_participants || ''}
                               type="number" className="form-control"/>
                        {
                          flagNumParts &&
                          <span className='px-label-warning' style={{fontSize:'11px', color: colors.color4[3]}}>
                        {i18n[language].numPartsWarn}
                      </span>
                        }
                      </div>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-7 mg-bottom-15">
                      <div className="form-group">
                        <label className="label-xs" style={{ color: colors.color0[3] }}>{i18n[language].partsAccess}</label>
                        <DateRangePicker
                          startDate={participants_start ? moment(participants_start) : null} // momentPropTypes.momentObj or null,
                          startDateId="quote_modal_start_date_id"
                          endDate={participants_end ? moment(participants_end) : null} // momentPropTypes.momentObj or null,
                          endDateId="quote_modal_end_date_id"
                          onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate },
                            () => this.showDates())} // PropTypes.func.isRequired,
                          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                          minimumNights={0}
                          displayFormat="DD/MM/YYYY"
                          isOutsideRange={day => !isInclusivelyAfterDay(day, moment())}
                          isDayHighlighted={ day => day.isSame( moment(), 'd' ) }
                          numberOfMonths={1}
                          daySize={27}
                          hideKeyboardShortcutsPanel={true}
                          startDatePlaceholderText={'From'}
                          endDatePlaceholderText={'To'}
                          disabled={this.state.disabledPickers}
                        />
                      </div>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 mg-bottom-15" style={{paddingTop:'25px',textAlign:'center'}}>
                      <div className="form-group">
                        <span type="button" onClick={this.props.getCost} disabled={loading} style={{fontSize: '26px', cursor: 'pointer', color: colors.color1[3]}}>
                          <FontAwesomeIcon icon={['fas','calculator']}/>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{marginTop: '15px'}}>
                    <div className="col-xs-12 col-sm-12 col-md-12 mg-bottom-15" style={{ backgroundColor: colors.color0[3], borderRadius: '10px', paddingLeft: '0', paddingRight: '0', paddingBottom: '10px', paddingTop: '10px'}}>
                      <div className="col-xs-6 col-sm-6 col-md-6 px-col-price" style={{textAlign: 'center', paddingLeft: '0', paddingRight: '0'}}>
                        <p>{i18n[language].payment}</p>
                        {/* <p>
                          <span style={{textDecoration: 'underline'}}>{num_participants}</span>{i18n[language].partsFor}
                          <span style={{textDecoration: 'underline'}}>{days}</span>{i18n[language].days}
                        </p> */}
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-6 px-col-price" style={{ textAlign: 'center', paddingLeft: '0', paddingRight: '0' }}>
                        <p style={{fontSize: '20px', textAlign: 'center', marginBottom: '0'}}>
                          { loading ? <FontAwesomeIcon icon={['fas','spinner']} spin/> : `US$ ${subsPrice}` }
                        </p>
                        {/* <p style={{fontSize: '10px', textAlign: 'center'}}>
                          ({loading ? <FontAwesomeIcon icon={['fas', 'spinner']} spin /> : `${totalPD} ${i18n[language].participantDays}` })
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-6">
                      <p style={{color: colors.color0[3], textTransform: 'uppercase', fontWeight: 'bold'}}>
                        {i18n[language].priceFor} <span style={{ textDecoration: 'underline' }}>{num_participants}</span>{i18n[language].partsFor} X <span style={{ textDecoration: 'underline' }}>{days}</span>{i18n[language].days}
                      </p>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6">
                      <p style={{ color: colors.color0[3],fontWeight:'bold',marginBottom:'0',fontSize:'18px',textAlign:'center'}}>
                        { loading ? <FontAwesomeIcon icon={['fas','spinner']} spin/> : `US$ ${estimated_cost.toFixed(2)}` }
                      </p>
                      <p style={{fontSize: '10px',textAlign:'center',textTransform:'uppercase',fontWeight:'bold',marginBottom:'0'}}>
                        <span style={{ color: '#909b9e' }}>{totalPD} {i18n[language].participantDays}</span>
                      </p>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label-xs" style={{ color: colors.color0[3], marginBottom: '15px' }}>{i18n[language].settle}:</label>
                      {/* <p style={{color:'#656df7', textTransform: 'uppercase', fontWeight: 'bold'}}>{i18n[language].settle}</p> */}
                    </div>
                  </div>
                  {
                    subscription.name !== 'No Subs' &&
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-xs-7 col-sm-7 col-md-7" style={{paddingLeft: '0', paddingRight: '0', fontSize: '12px'}}>
                          <input name="chk01" type="checkbox" checked={sumSubs !== 0} onChange={this.handleChange}
                                 disabled/>&nbsp;{subscription.papt}
                          <span className="checkmark filters-modal"/>{i18n[language].subsSlot}
                        </div>
                        <div className="col-xs-5 col-sm-5 col-md-5" style={{ textAlign: 'center'}}>
                          <p style={{fontWeight: 'bold', marginBottom: '0'}}>
                            (US$ {(sumSubs * priceUnitPD).toFixed(2)})
                          </p>
                          <p style={{ fontSize: '10px', textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '0', color: '#909b9e'}}>
                            {loading ? <FontAwesomeIcon icon={['fas', 'spinner']} spin /> : `${sumSubs} ${i18n[language].participantDays}` }
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-xs-7 col-sm-7 col-md-7" style={{ paddingLeft: '0', paddingRight: '0', fontSize: '12px'}}>
                        <input name="chk02" type="checkbox" checked={flexDaysPD !== 0}
                               onChange={this.handleChange} disabled/>&nbsp;{assigned_flex + subscription.flex_days}
                        <span className="checkmark filters-modal"/>{i18n[language].flex}
                      </div>
                      <div className="col-xs-5 col-sm-5 col-md-5" style={{ textAlign: 'center' }}>
                        <p style={{fontWeight: 'bold', marginBottom: '0'}}>
                          (US$ {(flexDaysPD * priceUnitPD).toFixed(2)})
                        </p>
                        <p style={{ fontSize: '10px', textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '0', color: '#909b9e'}}>
                          {loading ? <FontAwesomeIcon icon={['fas', 'spinner']} spin /> : `${flexDaysPD} ${i18n[language].participantDays}` }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-xs-7 col-sm-7 col-md-7" style={{ paddingLeft: '0', paddingRight: '0', fontSize: '12px' }}>
                        <input name="chk03" type="checkbox" checked={parseInt(subsPrice) !== 0} onChange={this.handleChange}
                               disabled/>
                        <span className="checkmark filters-modal"/>{i18n[language].remaining}
                      </div>
                      <div className="col-xs-5 col-sm-5 col-md-5" style={{ textAlign: 'center' }}>
                        <p style={{fontWeight: 'bold', marginBottom: '0', textAlign: 'center'}}>
                          US$ {subsPrice}
                        </p>
                        <p style={{ fontSize: '10px', textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '0', color: '#909b9e'}}>
                          {loading ? <FontAwesomeIcon icon={['fas', 'spinner']} spin /> : `${finalSumCashPD} ${i18n[language].participantDays}` }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-md-2"/>
                      <div className="col-md-8" style={{textTransform:'uppercase',fontWeight:'bold',fontSize:'9px',textAlign:'center', marginTop: '10px'}}>
                        <input name="chk04" type="checkbox" checked={this.state.chk04} onChange={this.handleChange}
                               style={{ cursor: 'pointer', verticalAlign: 'top'}}/>&nbsp;{i18n[language].read01}
                        <span onClick={flagTerms ? () => {} : () => this.props.createTermsPDF('view', flexDaysPD)}
                              style={{color: flagTerms ? '#333' : colors.color1[3], cursor: flagTerms ? 'default':'pointer'}}>
                      {i18n[language].read02}
                    </span>
                      </div>
                      <div className="col-md-2"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer px-modal-footer-gray">
              <div className="px-footer-container-buttons row">
                {
                  from === 'CommunitySettings' ?
                    <React.Fragment>
                      <div className="row">
                        <button ref="btnCreateCommunity" type="button" className="col-xs-12 col-sm-12 col-md-6 btn px-btn sm btn-blue-base"
                          onClick={this.props.save} disabled={flagCreate} style={{ backgroundColor: colors.color1[3] }}>
                          {i18n[language].save}
                        </button>
                        <button type="button" onClick={() => this.props.updateShowModalQuote('close')}
                          className="col-xs-12 col-sm-12 col-md-6 btn px-btn sm btn-gray-darker inverted">
                          {i18n[language].close}
                        </button>
                      </div>
                    </React.Fragment> : ''
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  showDates = () => {
    const startDate = this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : null;
    const endDate = this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null;

    this.props.updateDates(startDate, endDate);
  };

  handleChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;
    //console.log(name, value);
    this.setState({[name]: value})
  }
}