import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"

import DFilters from "./DFilters";
import DPCard from "./Participants/DPCard";
import Loading from "../../UI/Globals/Loading";
import InfiniteScroll from "react-infinite-scroller";

export default class DParticipants extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    activeLink: PropTypes.string.isRequired,
    activeUsers: PropTypes.array.isRequired,
    activities: PropTypes.array.isRequired,
    updateActiveUsers: PropTypes.func.isRequired,
    updateResponseState: PropTypes.func.isRequired,
    role_2: PropTypes.string.isRequired,
    objSearchPar: PropTypes.object.isRequired,
    updateFilterIsMin: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    updateSearchTerm: PropTypes.func.isRequired,
    searchTask: PropTypes.func.isRequired,
    executeUpdateModal: PropTypes.func.isRequired,
    updateCustomFilter: PropTypes.func.isRequired,
    userFilters: PropTypes.array.isRequired,
    handleChangeSltFilter: PropTypes.func.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    updateUserCustomFilters: PropTypes.func.isRequired,
    clearForwardUrl: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    hasMoreUsers: PropTypes.bool.isRequired,
    getUsersRender: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {communityId, activeLink, activeUsers, activities, userFilters, role_2, objSearchPar, gender_as_segment,
      language, colors, hasMoreUsers} = this.props;

    const i18n = {
      en: {usersNA: 'No active participants yet'},
      es: {usersNA: 'No hay participantes activos'}
    };

    const resultsFlags = [];

    const users = activeUsers.map(user => {
      if(user.isVisPar){
        resultsFlags.push(true);

        return(
          <DPCard key={user.id}
                  user={user}
                  language={language}
                  colors={colors}
                  activities={activities}
                  communityId={communityId}
                  role_2={role_2}
                  clearForwardUrl={this.props.clearForwardUrl}
                  updateActiveUsers={this.props.updateActiveUsers}
                  gender_as_segment={gender_as_segment}
                  updateResponseState={this.props.updateResponseState}/>
        )
      } else {
        resultsFlags.push(false);
      }
    });
    const resultFlag = resultsFlags.filter(user => user === false).length === activeUsers.length;

    return (
      <div className="px-collaborator-dash-container" style={{display: activeLink === 'Participants' ? 'block' : 'none'}}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <DFilters activeLink={activeLink}
                  objSearch={objSearchPar}
                  updateFilterIsMin={this.props.updateFilterIsMin}
                  from={'Participants'}
                  updateShowModal={this.props.updateShowModal}
                  updateSearchTerm={this.props.updateSearchTerm}
                  searchTask={this.props.searchTask}
                  executeUpdateModal={this.props.executeUpdateModal}
                  updateCustomFilter={this.props.updateCustomFilter}
                  userFilters={userFilters.filter(uf => uf.from === 'General')}
                  handleChangeSltFilter={this.props.handleChangeSltFilter}
                  updateUserCustomFilters={this.props.updateUserCustomFilters}
                  language={language}
                  colors={colors}/>
        {
          !resultFlag ?
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={() => this.props.getUsersRender(false)}
              hasMore={hasMoreUsers}
              loader={<Loading key={0}/>}>
              { users }
            </InfiniteScroll>:
            <div className="px-empty-msg" style={{color: colors.color4[3]}}>{i18n[language].usersNA}</div>
        }
      </div>
    );
  }
}
