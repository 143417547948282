import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dotdotdot from "react-dotdotdot";

export default class CSMTimeZoneCMTY extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    default_tz: PropTypes.string.isRequired,
    default_tz_flag: PropTypes.bool.isRequired,
    updateShowModalTimeZone: PropTypes.func.isRequired,
    updateTimeZone: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      isCheck01: false,
      isCheck02: false,
      isBtnSave: true
    }
  }

  handleInputChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;

    this.setState({[name]: value}, () => {
      if(this.props.default_tz_flag){
        this.setState({isBtnSave: !(this.state.isCheck01)});
      }else {
        this.setState({isBtnSave: !(this.state.isCheck01 && this.state.isCheck02)});
      }

    });
  };

  render() {
    const {default_tz, default_tz_flag, language, colors} = this.props;
    const I18n = {
      en: {
            participantTz: 'Set activity timing to participant\'s Time Zone', fixedTz: 'Set activity timing to a fixed Time Zone',
            participantTzInfo: 'Activities will become available and expire considering participants\' local time zone.',
            fixedTzInfo: 'Activities will become available and expire considering the following set Time Zone: ',
            timeZoneWarning: 'Because of time zone differences some participantes may be unable to access activities that become available today.',
            yes: 'Yes'
           },
      es: {
            participantTz: 'Ajustar actividades a zona horaria del participante', fixedTz: 'Ajustar actividades a una zona horaria fija',
            participantTzInfo: 'Las actividades se harán disponibles y expirarán considerando la zona horaria de cada participante.',
            fixedTzInfo: 'Las actividades se harán disponibles y expirarán considerando la siguiente zona horaria: ',
            timeZoneWarning: 'Dadas las diferencias horarias, algunos participantes podrían tener problemas para acceder a actividades que se hicieron disponibles hoy mismo.',
            yes: 'Sí'
          }
    }

    return (
      <div className="my-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal" onClick={this.props.updateShowModalTimeZone}>
                <FontAwesomeIcon icon={['fal', 'xmark']} style={{ fontSize: '16px' }} />
              </button>
            </div>
            <div className="modal-body px-modal-body-warning">              
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="px-title-warning-container">
                    <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning" style={{color: colors.color4[3]}}/>
                    <p className="px-title-warning">
                      
                      {default_tz_flag ? I18n[language].fixedTz : I18n[language].participantTz }?
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xs-offset-1 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
                  <div className="px-check-cont px-checkbox-modal-warning">
                    <label className="container-check">
                      <input type="checkbox" name="isCheck01" onChange={this.handleInputChange}value={this.state.isCheck01}/>
                      <span className="checkmark"/>
                      <span>
                        {
                          default_tz_flag ? I18n[language].fixedTzInfo + default_tz : I18n[language].participantTzInfo
                        }
                      </span>
                    </label>
                  </div>
                  {
                    !default_tz_flag &&
                    <div className="px-check-cont px-checkbox-modal-warning">
                      <label className="container-check">
                        <input type="checkbox" name="isCheck02" onChange={this.handleInputChange} value={this.state.isCheck02}/>
                        <span className="checkmark"/>
                        <span>{I18n[language].timeZoneWarning}</span>
                      </label>
                    </div>
                    }
                </div>
              </div>
            </div>
            <div className="modal-footer px-modal-footer-warning">
              <div className="px-footer-container-buttons column">
                <button onClick={this.validCloseCommunity} type="button" className="btn px-btn sm btn-blue-base"
                        disabled={this.state.isBtnSave} style={{backgroundColor: colors.color1[3]}}>{I18n[language].yes}</button>
                <button onClick={this.props.updateShowModalTimeZone} type="button"
                        className="btn px-btn sm btn-gray-darker inverted">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  validCloseCommunity = () => {
    if(this.props.default_tz_flag){
      if(this.state.isCheck01){
        this.props.updateTimeZone(this.props.communityId);
      }
    } else {
      if(this.state.isCheck01 && this.state.isCheck02){
        this.props.updateTimeZone();
      }
    }

  }
}
