import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import v4 from "uuid";

import MUserProfile from "../../../UI/MUserProfile";
import MUSeeNotes from "../../../UI/MUSeeNotes";
import {setIconGender, getCountryName} from "../../../UI/Globals/PX_Funs";

export default class DMUser extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    role_1: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    userActions: PropTypes.func.isRequired,
    handleZIndex: PropTypes.func.isRequired,
    indexY: PropTypes.number.isRequired,
    viewProfile: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    updateOpenPMFromOutside: PropTypes.func.isRequired,
    current_user_id: PropTypes.number.isRequired,
    communityId: PropTypes.number.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showModalProfile: false,
      showSeeNotes: false
    }
  }

  render() {
    const {user, role_1, role_2, indexY, language, current_user_id, communityId, colors} = this.props;

    const I18n = {
      en: {
        seeResponses: 'See responses', hurry: 'Hurry (blank & overdue tasks)', acceptCompleted: 'Accept completed tasks',
        export: 'Export responses', sendMsg: 'Send message', sendEmail: 'Send email', seeNotes: 'See notes', profile: 'Profile'
      },
      es: {
        seeResponses: 'Ver respuestas', hurry: 'Apresurar (tareas vacías y atrasadas)', acceptCompleted: 'Aceptar tareas completadas',
        export: 'Exportar respuestas', sendMsg: 'Enviar mensaje', sendEmail: 'Enviar email', seeNotes: 'Ver notas', profile: 'Perfil'
      }
    }

    const displayUserName = user.user_alias || user.natural_username || user.username
    const regionCountry = user.state_country === '' ? getCountryName(user.country) :
      user.state_country + ', ' + getCountryName(user.country);
    const menuId = v4();
    const liProfile = <li><a onClick={() => this.updateShowModal(user.id)}>{I18n[language].profile}</a></li>;
    const liResponses = <li><a onClick={() => this.props.userActions('see responses', user)}>{I18n[language].seeResponses}</a></li>;
    const liFaster = <li><a onClick={() => this.props.updateShowModal('Matrix', 'user faster', user)}>{I18n[language].hurry}</a></li>;
    const liAccept = <li><a onClick={() => this.props.updateShowModal('Matrix', 'user accept', user)}>{I18n[language].acceptCompleted}</a></li>;
    const liExport = <li><a onClick={() => this.props.userActions('view pdf', user)}>{I18n[language].export}</a></li>;
    const liMessage = <li><a onClick={() => this.props.userActions('send pm', user)}>{I18n[language].sendMsg}</a></li>;
    const liEmail = <li><a href={'mailto:' + user.email}>{I18n[language].sendEmail}</a></li>;
    const seeNotes = <li><a onClick={() => this.updateShowSeeNotes(user.user_id)}>{I18n[language].seeNotes}</a></li>;

    let dropdownAction = null;
    if( role_2 === "Owner" || role_2 === "Moderator" ) {
      dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide" style={{ display: 'block', zIndex: '1020' }}>
        {liResponses}{seeNotes}{liProfile}{/*liFaster*/}{liAccept}{/*liExport*/}{/*liMessage*/}{liEmail}
      </ul>
    } else if (role_2 === "Recruiter") {
      dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide" style={{ display: 'block', zIndex: '1020' }}>
        {liProfile}{seeNotes}{/*liMessage*/}{liEmail}
      </ul>
    } else if (role_2 === "Observer") {
      dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide" style={{ display: 'block', zIndex: '1020' }}>
        {liResponses}{liProfile}{seeNotes}
      </ul>
    }

    return (
      <div className="px-user-container">
        <div className="left-container">
          <div style={{position:'relative'}}>
            <ContextMenuTrigger id={menuId} holdToDisplay={1}>
              <img src={user.avatar !== '' ? user.avatar : '/assets/user.png'} alt="" data-toggle="dropdown"/>
              <div className="overlay-avatar">
                <FontAwesomeIcon icon={['fas','ellipsis-vertical']} data-toggle="dropdown"/>
              </div>
            </ContextMenuTrigger>

            <ContextMenu id={menuId} onShow={() =>this.props.handleZIndex('show', indexY)}
                         onHide={() =>this.props.handleZIndex('hide', indexY)} style={{zIndex:'2'}}>
              {dropdownAction}
            </ContextMenu>
          </div>
        </div>

        <div className="right-container">
          <div className="user" style={{cursor: role_2 === "Recruiter" || role_2 === "" ? "default": "pointer"}}
               onClick={role_2 === "Recruiter" || role_2 === "" ? ()=>null :
                 () => this.props.userActions('see responses', user)}>
            {displayUserName}
          </div>
          <div className="social-container">
            {
              user.gender !== '' &&
              <div className="px-genders-container">
                <div className="gender-container" data-tooltip-content={user.gender}>
                  {setIconGender(user.gender)}
                </div>
              </div>
            }
            {
              user.country &&
              <div className="px-country-container" data-tooltip-content={regionCountry}>
                <span>{user.country}</span>
              </div>
            }
            <div className="px-tags-container">
              {
                user.segments.map(segment => {
                  return(
                    <FontAwesomeIcon key={segment.id} icon={['fas', 'bookmark']} className="tag"
                                     style={{color:segment.color}} data-tooltip-content={segment.name}/>
                  )
                })
              }
            </div>
          </div>
        </div>
        {
          this.state.showModalProfile &&
            <MUserProfile showModal={this.state.showModalProfile}
                          updateShowModal={this.updateShowModal}
                          user={user}
                          role_1={role_1}
                          role_2={role_2}
                          userActions={this.props.userActions}
                          modalShowPM={this.modalShowPM}
                          current_user_id={current_user_id}
                          communityId={communityId}
                          language={language}
                          colors={colors}/>

        }
        {
          this.state.showSeeNotes &&
          <MUSeeNotes showSeeNotes={this.state.showSeeNotes}
                      updateShowSeeNotes={this.updateShowSeeNotes}
                      user={{...user, id: user.user_id}}
                      language={language}
                      colors={colors}/>
        }
      </div>
    );
  }

  modalShowPM = (type, username, user_id) => {
    this.setState({showModalProfile: false}, () => {
      this.props.updateOpenPMFromOutside(type, username, user_id);
    })
  };

  updateShowSeeNotes = () => {
    this.setState(prevState => ({showSeeNotes : !prevState.showSeeNotes}));
  }

  updateShowModal = (user_id) => {
    // console.log(user_id);
    this.setState(state => ({showModalProfile: !state.showModalProfile}), () => {
      if(user_id){
        this.props.viewProfile(user_id);
      }
    });
  };
}
