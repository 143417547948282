import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaQuery from 'react-responsive';
import HelpTip from "../HelpTip";

export default class LeftMenu extends React.Component {
  static propTypes = {
    from: PropTypes.string.isRequired,
    leftMenuActive: PropTypes.string,
    setLeftMenuActive: PropTypes.func.isRequired,
    communityId: PropTypes.number.isRequired,
    projectType: PropTypes.string,
    showLeftDrawer: PropTypes.bool.isRequired,
    leftDrawerToggleHandler: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    origin: PropTypes.string,
    role_1: PropTypes.string,
    role_2: PropTypes.string,
    toDoCounter: PropTypes.number,
    socialFlag: PropTypes.bool,
    showPartList: PropTypes.bool,
    activeLink: PropTypes.string,
    updateActiveLink: PropTypes.func,
    currentMenu: PropTypes.string,
    handleLink: PropTypes.func,
    role: PropTypes.string,
    subdomain: PropTypes.string,
    colors: PropTypes.object.isRequired,
    hasParticipants: PropTypes.bool,
    hasCollaborators: PropTypes.bool,
    hasTasks: PropTypes.bool,
    helpStores: PropTypes.object,
    userId: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      isDelay: true,
      showLevel: false,
      helpStores: this.props.helpStores,
      showHelpTip: false
    }
  }

  componentDidMount() {
    let iconHoverColor = this.props.colors.color0[4];
    $(".px-main-list .px-li > a").mouseenter(function () {
      $(this).css({ "color": iconHoverColor, 'background-color': '#ffffff' });
    });
    $(".px-main-list .px-li > a").mouseleave(function () {
      $(this).css({ "color": '', 'background-color': '' });
    });

    this.timer = setTimeout(() => {
      this.setState({ showHelpTip: true });
    }, 2000);  // 2000 milliseconds = 2 seconds
  }

  componentWillUnmount() {
    // Cleanup function to clear the timeout when the component unmounts
    clearTimeout(this.timer);
  }

  render() {
    const {from, communityId, leftMenuActive, socialFlag, showPartList, activeLink, currentMenu, role_2, role,
      isMobile, language, colors, subdomain, projectType, hasParticipants, hasCollaborators, hasTasks, userId
    } = this.props;
    const { showHelpTip, helpStores } = this.state;

    const i18n = {
      en: {
        more: 'More Options', not: 'Notifications', addParticipantsTip: 'Invite participants here',
        addCollaboratorsTip: 'Invite moderators or clients here', addTasksTip: 'Create tasks here',
        addSetupTip: 'Edit welcome message, invitations and add segments'
      },
      es: {
        more: 'Más Opciones', not: 'Notifications', addParticipantsTip: 'Invita participantes aquí',
        addCollaboratorsTip: 'Invita moderadores o clientes aquí', addTasksTip: 'Crea tareas aquí',
        addSetupTip: 'Edita mensajes de bienvenida, invitaciones y añade segmentos'
      }
    };

    let classDashboard = 'px-dropdown';
    let classActivities = 'px-dropdown';
    let classUsers = 'px-dropdown-delay';
    let classColls = 'px-dropdown';
    let classSettings = 'px-dropdown';
    let classConfiguration = 'px-dropdown';
    let classTranscript = 'px-dropdown';

    switch(leftMenuActive){
      case 'Dashboard':
        classDashboard = 'px-dropdown left-menu-active dashboard';
        classActivities = 'px-dropdown';classUsers = 'px-dropdown-delay';classColls = 'px-dropdown';
        classSettings = 'px-dropdown';classConfiguration = 'px-dropdown';
        break;
      case 'Activities':
        classActivities = 'px-dropdown left-menu-active';
        classDashboard = 'px-dropdown';classUsers = 'px-dropdown-delay';classColls = 'px-dropdown';
        classSettings = 'px-dropdown';classConfiguration = 'px-dropdown';
        break;
      case 'Participants':
        classUsers = 'px-dropdown-delay left-menu-active';
        classDashboard = 'px-dropdown';classActivities = 'px-dropdown';classColls = 'px-dropdown';
        classSettings = 'px-dropdown';classConfiguration = 'px-dropdown';
        classTranscript = 'px-dropdown';
        break;
      case 'Collaborators':
        classColls = 'px-dropdown left-menu-active';
        classDashboard = 'px-dropdown';classActivities = 'px-dropdown';classUsers = 'px-dropdown-delay';
        classSettings = 'px-dropdown';classConfiguration = 'px-dropdown';
        break;
      case 'Analysis':
        classSettings = 'px-dropdown left-menu-active';
        classDashboard = 'px-dropdown';classActivities = 'px-dropdown';classUsers = 'px-dropdown-delay';
        classColls = 'px-dropdown';classConfiguration = 'px-dropdown';
        break;
      case 'Settings':
        classConfiguration = 'px-dropdown left-menu-active';
        classDashboard = 'px-dropdown';classActivities = 'px-dropdown';classUsers = 'px-dropdown-delay';
        classColls = 'px-dropdown';classSettings = 'px-dropdown';
        break;
      case 'Transcript':
        classUsers = 'px-dropdown-delay';
        classDashboard = 'px-dropdown';classActivities = 'px-dropdown';classColls = 'px-dropdown';
        classSettings = 'px-dropdown';classConfiguration = 'px-dropdown';
        classTranscript = 'px-dropdown left-menu-active';
        break;
    }

    const linksTopMenu = <nav className="navbar navbar-default px-nav-base"><ul className="px-icon-container"><li>
      <a className="home" href="/users/dashboard" style={{backgroundColor: colors.color1[1]}}>
        <FontAwesomeIcon icon={['fas', 'house']} style={{ fontSize: '24px', color: colors.color0[0]}} className="home"/>
      </a>
    </li>
      <li id={"question-navbar"}>
        <a className="help" onClick={toggleHeadScoutBeacon} style={{ backgroundColor: colors.color1[1], color: colors.color0[0] }}>
          <FontAwesomeIcon icon={['fas','circle-question']} style={{fontSize:'24px'}}/>
          {/* <div className="px-support-tooltip right is_hidden px-support-all">
            <p>Need more information?</p>
            <p id='link-to-help' onClick={openSupportTab}>Go to support pages here.</p>
            <i/>
          </div> */}
        </a>
      </li>
    </ul></nav>;

    const url = this.props.origin === undefined ? 'Activities' : this.props.origin;
    const linkDashboard = <li className={classDashboard + ' px-li'}>
      <a href={'/communities/' + communityId + '/' + url} onClick={() => this.props.setLeftMenuActive('Dashboard')}>
        <FontAwesomeIcon icon={['fas', 'grid']} />
      </a>
      </li>;
    const iconActivities = <FontAwesomeIcon icon={['fas', 'list-timeline']} />;
    const linkConfigurations = <li className={classConfiguration + ' px-li'}>
      <a href={'/communities/settings/' + communityId} onClick={() => this.props.setLeftMenuActive('Configuration')}>
        <FontAwesomeIcon icon={['fas', 'gear']} />
      </a></li>;
    const linkDashboardMobile = <li className={`px-li dropup ${!this.state.showLevel ? 'first' : 'second'}-level`}>
      <a href="#" className="dropdown-toggle px-icon-cont-resp" data-toggle="dropdown" role="button"
         aria-expanded="false">
        <FontAwesomeIcon icon={['fas', 'grid']} />
      </a>
      <ul className="dropdown-menu px-dropdown-navigate">
        <li className="px-header">
          <a className="px-head">More Options</a>
        </li>
        <li className={activeLink === 'Activities' ? 'active' : undefined}>
          <a onClick={() => this.props.updateActiveLink('Activities')} className="">Activities</a>
        </li>
        <li className={activeLink === 'Participants' ? 'active' : undefined}>
          <a onClick={() => this.props.updateActiveLink('Participants')} className="">Participants</a>
        </li>
        <li className={activeLink === 'Now' ? 'active' : undefined}>
          <a onClick={() => this.props.updateActiveLink('Now')} className="">Now</a>
        </li>
        {
          !isMobile &&
          <li className={activeLink === 'Matrix' ? 'active' : undefined}>
            <a onClick={() => this.props.updateActiveLink('Matrix')} className="">Panorama</a>
          </li>
        }
      </ul>
    </li>;

    const linkDashboardMobileOutside = <li className={`px-li dropup ${!this.state.showLevel ? 'first' : 'second'}-level`}>
      <a href="#" className="dropdown-toggle px-icon-cont-resp" data-toggle="dropdown" role="button"
         aria-expanded="false">
        <FontAwesomeIcon icon={['fas', 'grid']} />
      </a>
      <ul className="dropdown-menu px-dropdown-navigate">
        <li className="px-header">
          <a className="px-head">More Options</a>
        </li>
        <li className={activeLink === 'Activities' ? 'active' : undefined}>
          <a href={'/communities/' + communityId + '/Activities'} className="">Activities</a>
        </li>
        <li className={activeLink === 'Participants' ? 'active' : undefined}>
          <a href={'/communities/' + communityId + '/Participants'} className="">Participants</a>
        </li>
        <li className={activeLink === 'Now' ? 'active' : undefined}>
          <a href={'/communities/' + communityId + '/Now'} className="">Now</a>
        </li>
        {
          !isMobile &&
          <li className={activeLink === 'Matrix' ? 'active' : undefined}>
            <a href={'/communities/' + communityId + '/Matrix'} className="">Panorama</a>
          </li>
        }
      </ul>
    </li>;

    const originSettings = this.props.origin === undefined ? 'General' : this.props.origin;
    const linkSettingsMobile = <li className="px-li dropup px-right">
      <a href="#" className="dropdown-toggle px-icon-cont-resp" data-toggle="dropdown" role="button"
         aria-expanded="false">
        <FontAwesomeIcon icon={['fas', 'gear']} />
      </a>
      <ul className="dropdown-menu px-dropdown-navigate">
        <li className="px-header"><a className="px-head">More Options</a></li>
        <li onClick={() => this.props.handleLink('#General', '#divGeneral')}
            className={currentMenu === '#General' ? 'active' : undefined}>
          <a className="">General</a>
        </li>
        {
          projectType !== 'AIChat' &&
              <li onClick={() => this.props.handleLink('#Bio', '#divBio')} className={currentMenu === '#Bio' ? 'active' : undefined}>
                <a className="">Bio</a>
              </li>
        }
        <li onClick={() => this.props.handleLink('#Welcome', '#divWelcome')}
            className={currentMenu === '#Welcome' ? 'active' : undefined}>
          <a className="">Welcome</a>
        </li>
        <li onClick={() => this.props.handleLink('#Invitations', '#divInvitations')}
            className={currentMenu === '#Invitations' ? 'active' : undefined}>
          <a className="">Invitations</a>
        </li>
        {
          projectType !== 'AIChat' &&
              <span>
                <li onClick={() => this.props.handleLink('#SegsAndTags', '#divSegsAndTags')}
                    className={currentMenu === '#SegsAndTags' ? 'active' : undefined}>
                  <a className="">Segments & Tags</a>
                </li>
                <li onClick={() => this.props.handleLink('#Maps', '#divMaps')}
                    className={currentMenu === '#Maps' ? 'active' : undefined}>
                  <a className="">Wordbooks</a>
                </li>
                <li onClick={() => this.props.handleLink('#QuickComments', '#divQuickComments')}
                className={currentMenu === '#QuickComments' ? 'active' : undefined}>
                  <a className="">Quick Comments</a>
                </li>
                <li onClick={() => this.props.handleLink('#Social', '#divSocial')}
                className={currentMenu === '#Social' ? 'active' : undefined}>
                  <a className="">Social</a>
                </li>
                <li onClick={() => this.props.handleLink('#Notifications', '#divNotifications')}
                className={currentMenu === '#Notifications' ? 'active' : undefined}>
                  <a className="">Notifications</a>
                </li>
                <li onClick={() => this.props.handleLink('#Participants', '#divParticipants')}
                className={currentMenu === '#Participants' ? 'active' : undefined}>
                 <a className="">Participants</a>
                </li>
              </span>
        }
      </ul>
    </li>;

    const linkSettingsMobileOutside = <li className="px-li dropup px-right">
      <a href="#" className="dropdown-toggle px-icon-cont-resp" data-toggle="dropdown" role="button"
         aria-expanded="false">
        <FontAwesomeIcon icon={['fas', 'gear']} />
      </a>
      <ul className="dropdown-menu px-dropdown-navigate">
        <li className="px-header"><a className="px-head">More Options</a></li>
        <li className={currentMenu === '#General' ? 'active' : undefined}>
          <a href={'/communities/settings/' + communityId + '/General'} className="">General</a>
        </li>
        {projectType !== 'AIChat' && <li className={currentMenu === '#Bio' ? 'active' : undefined}>
          <a href={'/communities/settings/' + communityId + '/Bio'} className="">Bio</a>
        </li>}
        <li className={currentMenu === '#Welcome' ? 'active' : undefined}>
          <a href={'/communities/settings/' + communityId + '/Welcome'} className="">Welcome</a>
        </li>
        <li className={currentMenu === '#Invitations' ? 'active' : undefined}>
          <a href={'/communities/settings/' + communityId + '/Invitations'} className="">Invitations</a>
        </li>
        { projectType !== 'AIChat' &&
            <span>
              <li className={currentMenu === '#SegsAndTags' ? 'active' : undefined}>
                <a href={'/communities/settings/' + communityId + '/SegsAndTags'} className="">Segments & Tags</a>
              </li>
              <li className={currentMenu === '#Maps' ? 'active' : undefined}>
                <a href={'/communities/settings/' + communityId + '/Maps'} className="">Wordbooks</a>
              </li>
              <li className={currentMenu === '#QuickComments' ? 'active' : undefined}>
                <a href={'/communities/settings/' + communityId + '/QuickComments'} className="">Quick Comments</a>
              </li>
              <li className={currentMenu === '#Social' ? 'active' : undefined}>
                <a href={'/communities/settings/' + communityId + '/Social'} className="">Social</a>
              </li>
              <li className={currentMenu === '#Notifications' ? 'active' : undefined}>
                <a href={'/communities/settings/' + communityId + '/Notifications'} className="">Notifications</a>
              </li>
              <li className={currentMenu === '#Participants' ? 'active' : undefined}>
               <a href={'/communities/settings/' + communityId + '/Participants'} className="">Participants</a>
              </li>
            </span>}
      </ul>
    </li>;

    const mHome = <li className="px-li first-level">
      <a className="home" href="/users/dashboard" style={{ backgroundColor: colors.color1[1] }}>
        <FontAwesomeIcon icon={['fas', 'house']} style={{ color: colors.color0[0] }}/></a></li>;
    const mQuestion = <li className="px-li first-level" >
      <a className="home" onClick={toggleHeadScoutBeacon}
      style={{ backgroundColor: colors.color1[1] }}>
        <FontAwesomeIcon icon={['fas', 'circle-question']} style={{ fontSize: '24px', color: colors.color0[0] }} /></a></li>;

    let linksList = null;
    if(from === 'CommunityPage' || from === 'CollTranscriptPage' || from === 'CollChatPage'){
      linksList =
        <ul className="nav px-main-list">
          <MediaQuery minDeviceWidth={481}>
            { linksTopMenu }
            { projectType !== 'AIChat' && linkDashboard }
          </MediaQuery>
          <MediaQuery maxDeviceWidth={480}>
            <li className="px-li first-level">
              <a className="home" href="/users/dashboard" style={{ backgroundColor: colors.color1[1] }}>
                <FontAwesomeIcon icon={['fas', 'house']} className="home" style={{ color: colors.color0[0] }}/>
              </a>
            </li>
            {mQuestion}
          </MediaQuery>

          <MediaQuery maxDeviceWidth={480}>
              { projectType !== 'AIChat' ? leftMenuActive === 'Dashboard' ? linkDashboardMobile : linkDashboardMobileOutside : '' }
          </MediaQuery>
          {
            role_2 === "Recruiter" || role_2 === "" ?
              "" :
              <React.Fragment>
                {
                  projectType !== 'AIChat' &&
                  <li className={classTranscript + ` px-li ${!this.state.showLevel ? 'first' : 'second'}-level`}>
                  <a href={'/communities/' + communityId + '/transcript/-1/all/all/LeftMenu'}>
                    <FontAwesomeIcon icon={['fas', 'map']} rotation={90} />
                  </a>
                </li>
                }
                <MediaQuery minDeviceWidth={481}>
                    {projectType === 'AIChat' || projectType === 'Mixed' ?
                        <li className='px-li'>
                          <a href={'/community/' + communityId + '/ai/chat'}>
                            <FontAwesomeIcon icon={['fas', 'wand-magic-sparkles']}/>
                          </a>
                        </li> : ''}
                </MediaQuery>
              </React.Fragment>
          }
          {
            this.props.role_1 === 'Collaborator' &&
            <MediaQuery minDeviceWidth={481}>
              <li className="px-second-list-container px-li">
                <ul className="px-second-list">
                  { projectType !== 'AIChat' &&
                      <li className={classUsers + ' px-li'}>
                        <a href={'/users_list/' + communityId + '/Participant/'}
                          onClick={() => this.props.setLeftMenuActive('Participants')}>
                          <FontAwesomeIcon icon={['fas', 'user-group']}/>
                          <HelpTip
                            showRequisites={role_2 !== 'Observer' && showHelpTip &&  !hasParticipants && leftMenuActive !== 'Participants'}
                            tipKey="addParticipantsTip"
                            text={i18n[language].addParticipantsTip}
                            colors={colors}
                            communityId={communityId}
                            userId={userId}
                            initialHelpStores={helpStores}
                            arrowDirection={'left'}
                            placement={'right'}
                          />
                        </a>
                    </li> }
                  <li className={classColls + ' px-li'}>
                    <a href={'/users_list/'+ communityId + '/Collaborator/'}
                       onClick={() => this.props.setLeftMenuActive('Collaborators')}>
                     <FontAwesomeIcon icon={['fas', 'shapes']} />
                        <HelpTip
                          showRequisites={showHelpTip && !hasCollaborators && leftMenuActive !== 'Collaborators'}
                          tipKey="addCollaboratorsTip"
                          text={i18n[language].addCollaboratorsTip}
                          colors={colors}
                          communityId={communityId}
                          userId={userId}
                          initialHelpStores={helpStores}
                          arrowDirection={'left'}
                          placement={'right'}
                        />
                    </a>
                  </li>
                  {
                    (role_2 === "Owner" || role_2 === "Moderator") && projectType !== 'AIChat' ?
                      <li className={classActivities + ' px-li'} style={{ cursor: 'pointer', textAlign: 'center' }}>
                        <a href={'/activity_builder/' + communityId}
                        onClick={() => this.props.setLeftMenuActive('Activities')}>
                          {iconActivities}
                            <HelpTip
                              showRequisites={showHelpTip && !hasTasks && leftMenuActive !== 'Activities'}
                              tipKey="addTasksTip"
                              text={i18n[language].addTasksTip}
                              colors={colors}
                              communityId={communityId}
                              userId={userId}
                              initialHelpStores={helpStores}
                              arrowDirection={'left'}
                              placement={'right'}
                            />
                        </a>
                      </li>
                    : ""
                  }
                  {
                    (role_2 === "Owner" || role_2 === "Moderator") &&
                    <li className={classConfiguration + ' px-li'} style={{cursor:'pointer',textAlign:'center'}}>
                      <a href={'/communities/settings/' + communityId + '/General'}
                        onClick={() => this.props.setLeftMenuActive('Configuration')}>
                      <FontAwesomeIcon icon={['fas', 'gear']} />
                          <HelpTip
                            showRequisites={showHelpTip && (!hasTasks || !hasParticipants || !hasCollaborators) && leftMenuActive !== 'Settings'}
                            tipKey="addSetupTip"
                            text={i18n[language].addSetupTip}
                            colors={colors}
                            communityId={communityId}
                            userId={userId}
                            initialHelpStores={helpStores}
                            arrowDirection={'left'}
                            placement={'right'}
                          />
                      </a>
                    </li>
                  }
                </ul>
              </li>
            </MediaQuery>
          }
          {
              this.props.role_1 === 'Collaborator' && (projectType === 'AIChat' || projectType === 'Mixed')  &&
              <MediaQuery maxDeviceWidth={480}>
                <li className={classUsers + ` px-li ${this.state.showLevel ? projectType === 'Mixed' ? 'first' : 'second' : projectType === 'Mixed' ? 'second' : 'first'}-level`}>
                  <a href={'/community/' + communityId + '/ai/chat'}>
                    <FontAwesomeIcon icon={['fas', 'wand-magic-sparkles']}/>
                  </a>
                </li>
              </MediaQuery>
          }
          {
            this.props.role_1 === 'Collaborator' && projectType !== 'AIChat' &&
            <MediaQuery maxDeviceWidth={480}>
              <li className={classUsers + ` px-li ${!this.state.showLevel ? 'first' : 'second'}-level`}>
                <a href={'/users_list/'+ communityId + '/Participant/'} onClick={() => this.props.setLeftMenuActive('Participants')}>
                <FontAwesomeIcon icon={['fas', 'user-group']} />
                </a>
              </li>
            </MediaQuery>
          }
          {
            this.props.role_1 === 'Collaborator' &&
            <MediaQuery maxDeviceWidth={480}>
              <li className={classColls + ` px-li ${this.state.showLevel ? projectType !== 'AIChat' ? 'first' : 'second' : projectType !== 'AIChat' ? 'second' : 'first'}-level`}>
                <a href={'/users_list/'+ communityId + '/Collaborator/'} onClick={() => this.props.setLeftMenuActive('Collaborators')}>
                <FontAwesomeIcon icon={['fas', 'shapes']} />
                  <div className="px-support-tooltip right is_hidden px-support-nav">
                    <p>Invite collaborators here.</p>
                  </div>
                </a>
              </li>
            </MediaQuery>
          }
          {
            (role_2 === "Owner" || role_2 === "Moderator") && projectType !== 'AIChat' &&
            <MediaQuery maxDeviceWidth={480}>
              <li className={classActivities + ` px-li ${this.state.showLevel ? 'first' : 'second'}-level`}
                  style={{cursor:'pointer',textAlign:'center'}}>
                <a href={'/activity_builder/' + communityId}
                   onClick={() => this.props.setLeftMenuActive('Activities')}>
                  { iconActivities }
                </a>
              </li>
            </MediaQuery>
          }
          {
            (this.props.role_1 === 'Collaborator' && (role_2 === "Owner" || role_2 === "Moderator")) &&
            <MediaQuery maxDeviceWidth={480}>
              { leftMenuActive === 'Settings' ? linkSettingsMobile : linkSettingsMobileOutside }
            </MediaQuery>
          }

          <MediaQuery maxDeviceWidth={480}>
            { projectType !== 'AIChat' &&
              <li onClick={this.updateShowLevel} className="px-li">
              <a><FontAwesomeIcon icon={this.state.showLevel ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']}/></a>
            </li>}
          </MediaQuery>
        </ul>;
        {/*<ul className="nav px-main-list">
          {
            from === 'CollTranscriptPage' &&
              <div>
                <MediaQuery minDeviceWidth={480}>
                  { linkDashboard }
                </MediaQuery>
                <MediaQuery maxDeviceWidth={480}>
                  { leftMenuActive === 'Dashboard' ? linkDashboardMobile : linkDashboardMobileOutside }
                </MediaQuery>
              </div>
          }
          { role_2 === "Recruiter" || role_2 === "" ? "" :
          <li className={classTranscript+' px-li'}>
            <a href={'/communities/'+ communityId +'/transcript/-1/all/all/LeftMenu'}>
              <FontAwesomeIcon icon={['fas' , 'scroll']}/>
            </a>
          </li>
          }
          <li className="px-second-list-container px-li">
            <ul className="px-second-list">
              <li className={classUsers+' px-li'}>
                <a href={'/Participant/' + communityId} onClick={() => this.props.setLeftMenuActive('Participants')}>
                  <i className="material-icons">people</i>
                </a>
              </li>
              <li className={classColls+' px-li'}>
                <a href={'/users_list/'+ communityId + '/Collaborator/'} onClick={() => this.props.setLeftMenuActive('Collaborators')}>
                  <i className="material-icons">bubble_chart</i>
                </a>
              </li>
            { role_2 === "Owner" || role_2 === "Moderator" ?
              <li className={classActivities+' px-li'}>
                <a href={'/activity_builder/' + communityId}
                   onClick={() => this.props.setLeftMenuActive('Activities')}>
                  { iconActivities }
                </a>
              </li>
              : ""
            }
              <MediaQuery minDeviceWidth={1224}>
                <li className={classConfiguration + ' px-li'}>
                  <a href={'/communities/settings/' + communityId + '/General'}
                     onClick={() => this.props.setLeftMenuActive('Configuration')}>
                    <i className="material-icons">settings</i>
                  </a>
                </li>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={1224}>
                { leftMenuActive === 'Settings' ? linkSettingsMobile : linkSettingsMobileOutside }
              </MediaQuery>
            </ul>
          </li>
        </ul>*/}
    } else {
      const linkPartDashboard = <li className={classDashboard +' px-li'}>
          <a href={'/communities/' + communityId + '/p'} onClick={() => this.props.setLeftMenuActive('Dashboard')}>
          <FontAwesomeIcon icon={['fas', 'list-check']} />
          {
            this.props.toDoCounter > 0 &&
            <div className="counter-activities" style={{backgroundColor: colors.color4[3]}}>
              <span>{this.props.toDoCounter}</span>
            </div>
          }
          </a>
        </li>;

      let linkPartParticipants = null;
      if(socialFlag){
        linkPartParticipants = <li className={classUsers+' px-li'}>
          <a href={'/users_list/'+ communityId + '/Participant/'} onClick={() => this.props.setLeftMenuActive('Participants')}>
            <FontAwesomeIcon icon={['fas', 'user-group']} />
            <div className="px-support-tooltip right is_hidden px-support-participant" style={{}}>
              <p>See who else is participating in the community and get to know them here.</p>
            </div>
          </a>
        </li>;
      } else {
        //if(showPartList){
          linkPartParticipants = <li className={classUsers+' px-li'}>
            <a href={'/users_list/'+ communityId + '/Participant/'} onClick={() => this.props.setLeftMenuActive('Participants')}>
              <FontAwesomeIcon icon={['fas', 'user-group']} />
            </a>
          </li>;
        //}
      }

      const linkPartSettings = <li className="px-second-list-container px-li " style={{marginLeft:'auto'}}>
        <ul className="px-second-list">
          <li className={classConfiguration+' px-li'} style={{cursor:'pointer',textAlign:'center'}}>
            <a href={'/communities/settings/' + communityId + '/Bio'}
               onClick={() => this.props.setLeftMenuActive('Configuration')}>
              <FontAwesomeIcon icon={['fas', 'gear']} />
            </a>
          </li>
        </ul>
      </li>;
      //console.log('currentMenu:', currentMenu);
      const linkPartSettingsMobile = <li className="px-li dropup px-right">
        <a href="#" className="dropdown-toggle px-icon-cont-resp" data-toggle="dropdown" role="button"
           aria-expanded="false">
          <FontAwesomeIcon icon={['fas', 'gear']} />
        </a>
        <ul className="dropdown-menu px-dropdown-navigate">
          <li className="px-header"><a className="px-head">{i18n[language].more}</a></li>
          <li className={currentMenu === '#Bio' ? 'active' : undefined}>
            <a href={'/communities/settings/' + communityId + '/Bio'} className="">Bio</a>
          </li>
          <li className={currentMenu === '#Notifications' ? 'active' : undefined}>
            <a href={'/communities/settings/' + communityId + '/Notifications'} className="">{i18n[language].not}</a>
          </li>
        </ul>
      </li>;

      linksList = from  === 'ParticipantCommunityPage' || this.props.from  === 'ParticipantActivityPage' ?
        <ul className="nav px-main-list">
          <MediaQuery maxDeviceWidth={480}>
            { mHome }
            {mQuestion}
          </MediaQuery>
          <MediaQuery minDeviceWidth={481}>
            { linksTopMenu }
          </MediaQuery>
          { linkPartDashboard }
          { linkPartParticipants }
          <MediaQuery minDeviceWidth={481}>
            { linkPartSettings }
          </MediaQuery>
          <MediaQuery maxDeviceWidth={480}>
            { linkPartSettingsMobile }
          </MediaQuery>
        </ul> :
        <ul className="nav px-main-list">
        </ul>;
    }

    if(from === 'ManagePage'){
      let activeMobClass;
      const mManage = <React.Fragment>
        {/*<li className="px-li dropup">
          <a href="#" className="dropdown-toggle px-icon-cont-resp" data-toggle="dropdown" role="button"
             aria-expanded="false">
            <FontAwesomeIcon icon={['fas','gears']} className="home"/>
          </a>
          <ul className="dropdown-menu px-dropdown-navigate">
            <li className="px-header"><a className="px-head">More Options</a></li>
            <li onClick={() => this.props.handleLink('#Account', '#divAccount')}
              className={currentMenu === '#Account' && 'active'}>
              <a className="">Account</a>
          </li>
          <li onClick={() => this.props.handleLink('#Profile', '#divProfile')}
              className={currentMenu === '#Profile' ? 'active' : ''}>
            <a className="">Profile</a>
          </li>
          {
            this.props.role === 'Superadmin' &&
              <div>
                { <li onClick={() => this.props.handleLink('#Allowances', '#divAllowances')}
                    className={currentMenu === '#Allowances' && 'active'}>
                  <a className="">Allowances</a>
                </li>
                <li onClick={() => this.props.handleLink('#Notifications', '#divNotifications')}
                    className={currentMenu === '#Notifications' && 'active'}>
                  <a className="">Notifications</a>
                </li>
                <li onClick={() => this.props.handleLink('#Appeareance', '#divAppeareance')}
                    className={currentMenu === '#Appeareance' && 'active'}>
                  <a className="">Appearance</a>
                </li> }
              </div>
          }
          </ul>
        </li>*/}
        <li onClick={() => this.props.handleLink('#Account', '#divAccount')}
            className={`px-li first-level ${currentMenu === '#Account' ? 'left-menu-active' : ''}`}>
          <a><FontAwesomeIcon icon={['fas','circle-user']} style={{fontSize:'24px'}}/></a>
        </li>
        <li onClick={() => this.props.handleLink('#Profile', '#divProfile')}
            className={`px-li first-level ${currentMenu === '#Profile' ? 'left-menu-active' : ''}`}>
          <a><FontAwesomeIcon icon={['fas','id-badge']} style={{fontSize:'24px'}}/></a>
        </li>
        {/* <li  onClick={() => this.props.handleLink('#Notifications', '#divNotifications')}
             className={`px-li first-level ${currentMenu === '#Notifications' ? 'left-menu-active' : ''}`}>
          <a><FontAwesomeIcon icon={['fas','bell']} style={{fontSize:'24px'}}/></a>
        </li> */}
        {
          role === 'Superadmin' &&
          <li onClick={() => this.props.handleLink('#Allowances', '#divAllowances')}
              className={`px-li first-level ${currentMenu === '#Allowances' ? 'left-menu-active' : ''}`}>
            <a><FontAwesomeIcon icon={['fas','key']} style={{fontSize:'24px'}}/></a>
          </li>
        }
        <li onClick={() => this.props.handleLink('#Appeareance', '#divAppearance')}
            className={`px-li first-level ${currentMenu === '#Appeareance' ? 'left-menu-active' : ''}`}>
          <a><FontAwesomeIcon icon={['fas','palette']} style={{fontSize:'24px'}}/></a>
        </li>
        { role === 'Superadmin' &&
          <li onClick={() => this.props.handleLink('#Billing', '#divBilling')}
            className={`px-li first-level ${currentMenu === '#Billing' ? 'left-menu-active' : ''}`}>
            <a><FontAwesomeIcon icon={['fas', 'file-invoice-dollar']} style={{ fontSize: '24px' }} /></a>
          </li>
        }
      </React.Fragment>;

      linksList = <ul className="nav px-main-list">
        <MediaQuery minDeviceWidth={481}>
          { linksTopMenu }
        </MediaQuery>
        <MediaQuery maxDeviceWidth={480}>
          {mHome}
          {mQuestion}
          { mManage }
          
        </MediaQuery>
      </ul>;
    }

    if(from === 'Dashboard' || from === 'WelcomePage'){
      linksList = <ul className="nav px-main-list">
        <MediaQuery minDeviceWidth={481}>
          { linksTopMenu }
        </MediaQuery>
        <MediaQuery maxDeviceWidth={480}>
          { mHome }
          {mQuestion}
        </MediaQuery>
      </ul>;
    }

    return (
      <div className="px-left-navbar" style={{backgroundColor: colors.color0[4]}}>
        { linksList }
      </div>
    );
  }

  updateShowLevel = () => {
    this.setState(prevState => ({showLevel: !prevState.showLevel}))
  };
}

// detects section where user is currently at
function whereAmI(){
  const path = window.location.pathname.split('/');
  switch (path[1]) {
    case "users":
      return path[2];
    case "communities":
      if (path[2] === "settings")
        return "settings";
      else if (path[2] === "participant")
        return "participant";
      else if (path[3] === "transcript")
        return "transcript";
      else
        return "comm-dashboard";
    case "participant":
      return "participant";
    case "collaborators":
      return "collaborators";
    case "activities_builder":
      return "builder";
    case "activity":
      return "response";
    default:
      return "nav";
  }
}

function displaySupportTooltip(e) {
  e.preventDefault();
  let location = whereAmI();
  let targetClass = ".px-support-" + location + ", .px-support-all";
  let elements = document.querySelectorAll(targetClass);
  let hiddenTooltips = [];
  // let backDrop = document.getElementsByClassName("backdrop");

  for (let item of elements) {
    if (item.matches('.is_hidden')) {
      hiddenTooltips.push('hidden');
    }
  }

  if (elements.length === hiddenTooltips.length) {
    for (let item of elements) {
      item.classList.toggle('is_hidden');
    }
  } else {
    for (let item of elements) {
      item.classList.add('is_hidden');
    }
  }
}

function toggleHeadScoutBeacon() {
  Beacon('toggle');
}

function openSupportTab(){
  window.open('/help', '_blank');
}
