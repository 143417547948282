import PropTypes from 'prop-types'
import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import TFUsers from "./TFilters/TFUsers"
import TFAdvanced from "./TFilters/TFAdvanced"
import TFTaskPicker from './TFilters/TFTaskPicker'

export default class TFilters extends React.Component {
  static propTypes = {
    from: PropTypes.string.isRequired,
    objSearch: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    allAvailableActs: PropTypes.array.isRequired,
    updatePills: PropTypes.func.isRequired,
    advancedActions: PropTypes.func.isRequired,
    chkUsers: PropTypes.bool.isRequired,
    chkSegs: PropTypes.bool.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleUserArrows: PropTypes.func.isRequired,
    segments: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    genderAsSegment: PropTypes.bool.isRequired,
    handleHideTabFilters: PropTypes.func.isRequired,
    consolidated: PropTypes.string.isRequired,
    updateConsolidated: PropTypes.func.isRequired,
    updateFiltersOpts: PropTypes.func.isRequired,
    updateSltCalendar: PropTypes.func.isRequired,
    updateStartExpireDates: PropTypes.func.isRequired,
    updateFromModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    //NEW
    filterUsers: PropTypes.array.isRequired,
    filterActivities: PropTypes.array.isRequired,
    communitySegments: PropTypes.array.isRequired,
    executeUpdateModal: PropTypes.func.isRequired,
    updateJustPills: PropTypes.func,
    advancedActionsJust: PropTypes.func,
    showAllVerbatim: PropTypes.bool.isRequired,
    updateShowAllVerbatim: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    updateResponseStates: PropTypes.func.isRequired,
    isLoadingResponsesConsolidated: PropTypes.bool.isRequired,
    showModalExportHandler: PropTypes.func.isRequired,
    isBlocking: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired,
    searchModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
      // showPills: true
      showTaskPicker: true,
      showTvSettings: false
    };
  }

  render() {
    const {from, objSearch, users, allAvailableActs, segments, tags, genderAsSegment, chkUsers, chkSegs,
      consolidated, communitySegments, isLoadingResponsesConsolidated, isBlocking, colors} = this.props

    return (
      <div className="row px-nav-transcript-container" style={{zIndex:3}}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{padding:'0'}}>
          <div className="px-nav-transcript collapse in" id={"demo"} aria-expanded="true">
            <div className="px-nav-trans-body">
              <TFUsers from={from}
                       objSearch={objSearch}
                       users={users}
                       updateShowPills={this.updateShowPills}
                       handleUserArrows={this.props.handleUserArrows}
                       advancedActions={this.props.advancedActions}
                       updatePills={this.props.updatePills}
                       allAvailableActs={allAvailableActs}
                       segments={communitySegments}
                       tags={tags}
                       updateFiltersOpts={this.props.updateFiltersOpts}
                       updateSltCalendar={this.props.updateSltCalendar}
                       updateStartExpireDates={this.props.updateStartExpireDates}
                       updateFromModal={this.props.updateFromModal}
                       showModal={this.props.showModal}
                       updateShowModal={this.props.updateShowModal}
                       gender_as_segment={genderAsSegment}
                       executeUpdateModal={this.props.executeUpdateModal}
                       updateShowTaskPicker={this.updateShowTaskPicker}
                       language={this.props.language}
                       isLoadingResponsesConsolidated={isLoadingResponsesConsolidated}
                       colors={colors}
                       isBlocking={isBlocking}
                       searchModal={this.props.searchModal}/>
              {
                this.state.showTaskPicker ?
                  <TFAdvanced users={users}
                              objSearch={objSearch}
                              advancedActions={this.props.advancedActionsJust}
                              //advancedActions={this.props.advancedActions}
                              updateShowPills={this.updateShowPills}
                              chkUsers={chkUsers}
                              chkSegs={chkSegs}
                              handleInputChange={this.props.handleInputChange}
                              segments={segments}
                              genderAsSegment={genderAsSegment}
                              consolidated={consolidated}
                              updateConsolidated={this.props.updateConsolidated}
                              updateShowModal={this.props.updateShowModal}
                              showAllVerbatim={this.props.showAllVerbatim}
                              updateShowAllVerbatim={this.props.updateShowAllVerbatim}
                              language={this.props.language}
                              updateResponseStates={this.props.updateResponseStates}
                              showModalExportHandler={this.props.showModalExportHandler}
                              isBlocking={isBlocking}
                              colors={colors}/> :
                  <TFTaskPicker from={from}
                                users={users}
                                genderAsSegment={genderAsSegment}
                                objSearch={objSearch}
                                updateShowTaskPicker={this.updateShowTaskPicker}
                                allAvailableActs={allAvailableActs}
                                updateJustPills={this.props.updateJustPills}
                                executeUpdateModal={this.props.executeUpdateModal}
                                language={this.props.language}
                                isBlocking={isBlocking}
                                colors={colors}/>
              }
            </div>
          </div>

          {
            from === 'Collaborator' &&
            <div className="px-nav-trans-footer">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div onClick={this.props.handleHideTabFilters}
                       className="accordion-menu-filters" data-toggle="collapse" data-target="#demo">
                    <FontAwesomeIcon  icon={['fas','circle-chevron-up']} className="chev-toggle"/>
                    <span className="text">PLAY</span>
                  </div>
                  <div className="menu-chev-up" >
                    <FontAwesomeIcon  icon={['fas','circle-arrow-up']} className="chev-up"/>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  // updateShowPills = () => {
  //   this.setState(state => ({showPills: !state.showPills}));
  // }

  updateShowTaskPicker = () => {
    this.setState(state => ({ showTaskPicker: !state.showTaskPicker }));
  }

  toggleTvSettings = () => {
    this.setState(prevState => ({ showTvSettings: !prevState.showTvSettings }));
  }

}
