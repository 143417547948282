import PropTypes from 'prop-types';
import React from 'react';

import AVTOpenEnd01 from "../../ActivityView/AVTask/AVTOpenEnd01";
import htmlToDraft from "html-to-draftjs";
import {ContentState, EditorState} from "draft-js";
import AVTChoice from "../../ActivityView/AVTask/AVTChoice";
import AVTMicroChat from "../../ActivityView/AVTask/AVTMicroChat";

export default class AEMTaskPreview extends React.Component {
  static propTypes = {
    updateShowModal: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    task: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    communityLang: PropTypes.string.isRequired,
    cmntyAutoModeration: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {task, question, language, type, colors, communityLang, cmntyAutoModeration} = this.props;

    const response = {
      editorState: this._setEditorState(false),
      percentage: 0,
      showComments: false,
      camera: null, //Why 2 camera and video?
      video: null,
      newVideoThumbnail: null,
      selectedItemsId: [],
      showBlocker: false,
      attachments: [],
      attachmentsToDelIds: [],
      question_id: question.id,
      response: { id: -1, chats: [{id: 1, body: 'Hello, nice to meet you!', kind: 'AI'}] }
    };

    let previewComp
    switch(type){
      case 'preview task OE':
        previewComp = <AVTOpenEnd01 question={question}
                                    cardinal={0}
                                    userId={1}
                                    language={language}
                                    participants_view={'As a list'}
                                    response={response}
                                    onEditorStateChange={() => {}}
                                    saveResponse={() => {}}
                                    questionsLength={1}
                                    updateShowBlocker={() => {}}
                                    onDrop={() => {}}
                                    deleteResponseImage={() => {}}
                                    updateShowModal={() => {}}
                                    deleteVideo={() => {}}
                                    updateResponseMapsId={() => {}}
                                    blockerExpired={false}
                                    updateShowComments={() => {}}
                                    updateResponseComments={() => {}}
                                    taskSocialStatus={task.social}
                                    scrollFirstTime={false}
                                    updateScrollFirstTime={() => {}}
                                    setActiveTaskId={() => {}}
                                    from={'AB Preview'}
                                    colors={colors}
                                    updateOETextAndSave={() => {}}
                                    cmntyLanguage={communityLang}
                                    helpStores={{'showSaveHelp':true}}
                                    updateHelpStore={()=>{}}
                                    cmntyAutoModeration={cmntyAutoModeration}
                                    username={''}/>
        break
      case 'preview task CH':
        previewComp = <AVTChoice question={question}
                                 cardinal={0}
                                 participants_view={'As a list'}
                                 userId={1}
                                 language={language}
                                 questionsLength={0}
                                 blockerExpired={false}
                                 updateChoiceItems={() => {}}
                                 saveResponse={() => {}}
                                 updateShowComments={() => {}}
                                 updateResponseComments={() => {}}
                                 scrollFirstTime={false}
                                 updateScrollFirstTime={() => {}}
                                 from={'AB Preview'}
                                 colors={colors}
                                 helpStores={{'showSaveHelp':true}}
                                 comunitySocial={false}
                                 taskSocialStatus={"Private"}
                                 updateHelpStore={()=>{}}/>
        break
      case 'preview task MCH':
        previewComp = <AVTMicroChat question={question}
                                    cardinal={0}
                                    from={'AB Preview'}
                                    userId={1}
                                    language={language}
                                    colors={colors}
                                    response={response}
                                    avatar=''
                                    username=''
                                    formatter={() => {}}
                                    updateActiveMicroId={() => {}}/>
        break
    }

    return (
      <div className="modal-content modal-preview" style={{ background: '#f0eff3' }}>
        <div className="modal-header">
          <button type="button" className="close">
            <i onClick={() => this.props.updateShowModal(null, null)} className="material-icons"
               style={{color: '#333'}}>clear</i>
          </button>
        </div>

        <div className="modal-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {previewComp}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _setEditorState(response){
    let component

    if(response){
      const answer = response.data.answerHTML ? response.data.answerHTML : ''
      const contentBlock = htmlToDraft(answer)
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)

      component = EditorState.createWithContent(contentState)
    } else {
      component = EditorState.createEmpty()
    }

    return component
  }
}
