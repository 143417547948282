import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"

import DFilters from "./DFilters";
import DACard from "./Activities/DACard";

export default class DActivities extends React.Component {
  static propTypes = {
    activeLink: PropTypes.string.isRequired,
    activities: PropTypes.array.isRequired,
    activeUsers: PropTypes.array.isRequired,
    updateActivities: PropTypes.func.isRequired,
    activeUsersCount: PropTypes.number.isRequired,
    objSearchAct: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    updateFilterIsMin: PropTypes.func.isRequired,
    updateSearchTerm: PropTypes.func.isRequired,
    searchTask: PropTypes.func.isRequired,
    executeUpdateModal: PropTypes.func.isRequired,
    updateCustomFilter: PropTypes.func.isRequired,
    userFilters: PropTypes.array.isRequired,
    handleChangeSltFilter: PropTypes.func.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    user_account: PropTypes.object,
    colors: PropTypes.object.isRequired,
    updateUserCustomFilters: PropTypes.func.isRequired,
    clearForwardUrl: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    getResponsesActivity: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }


  render() {
    const {activeLink, activities, activeUsersCount, objSearchAct, userFilters, role_2, gender_as_segment,
      activeUsers, language, colors} = this.props;

    const i18n = {
      en: { activitiesNA: 'No live activities yet' },
      es: { activitiesNA: 'No hay actividades publicadas' }
    };

    const actsFlags = [];

    const acts = activities.map((activity, index) => {
      if(activity.isVisAct){
        actsFlags.push(true);

        return(
          <DACard key={index}
                  activity={activity}
                  language={language}
                  colors={colors}
                  role_2={role_2}
                  activeUsers={activeUsers}
                  gender_as_segment={gender_as_segment}
                  objSearchAct={objSearchAct}
                  clearForwardUrl={this.props.clearForwardUrl}
                  updateActivities={this.props.updateActivities}
                  activeUsersCount={activeUsersCount}
                  getResponsesActivity={this.props.getResponsesActivity}/>
        )

      } else {
        actsFlags.push(false);
      }
    });

    const resultFlag = actsFlags.filter(a => a === false).length === activities.length;
    //console.log(resultFlag, acts);

    return (
      <div className="px-collaborator-dash-container" style={{display: activeLink === 'Activities' ? 'block' : 'none'}}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <DFilters activeLink={activeLink}
                  objSearch={objSearchAct}
                  from={'Activities'}
                  updateFilterIsMin={this.props.updateFilterIsMin}
                  showModal={this.props.showModal}
                  updateShowModal={this.props.updateShowModal}
                  updateSearchTerm={this.props.updateSearchTerm}
                  searchTask={this.props.searchTask}
                  executeUpdateModal={this.props.executeUpdateModal}
                  updateCustomFilter={this.props.updateCustomFilter}
                  userFilters={userFilters.filter(uf => uf.from === 'General')}
                  handleChangeSltFilter={this.props.handleChangeSltFilter}
                  updateUserCustomFilters={this.props.updateUserCustomFilters}
                  language={language}
                  colors={colors}/>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            { !resultFlag ? acts :  <div className="px-empty-msg" style={{color: colors.color4[3]}}>{i18n[language].activitiesNA}</div> }
          </div>
        </div>
      </div>
    );
  }

  _calculatedResponsesTask_ORIG_01(task){
    const responsesPerTask = [];
    let accepted = 0;
    let completed = 0;
    let draft = 0;
    let blank = 0;  // Blank is not a state, it's a sum with Empty state to show the swim lane

    const acceptedUserIds = [];
    const completedUserIds = [];
    const draftUserIds = [];
    const blankUserIds = [];

    task.responses.map(response => {
      //console.log(response);
      switch(response.state){
        case 'Accepted':
          accepted = accepted + 1;
          acceptedUserIds.push(response.user_id);
          break;
        case 'Completed':
          completed = completed + 1;
          completedUserIds.push(response.user_id);
          break;
        case 'Draft':
          draft = draft + 1;
          draftUserIds.push(response.user_id);
          break;
        case 'Blank':
          //Blank means no response in DB
          blank = blank + 1;
          blankUserIds.push(response.user_id);
          break;
        case 'Empty':
          //Empty means an Open End response in DB without text, it can have pics, moods or video. ASK for the rule and enable in Back-server
          //blank = blank + 1;
          //blankUserIds.push(response.user_id);
          break;
      }
    });

    const obj = {
      task_id: task.id,
      accepted: accepted,
      completed: completed,
      draft: draft,
      blank: blank,
      total: accepted + completed + draft + blank,
      acceptedUserIds, completedUserIds, draftUserIds, blankUserIds
    };
    //console.log(obj);
    responsesPerTask.push(obj);

    return responsesPerTask;
  }

  _calculatedResponsesTask_ORIG(task, activeUsersCount){
    const responsesPerTask = [];
    let accepted = 0;
    let completed = 0;
    let draft = 0;
    let blank = activeUsersCount - task.responses.length;  // Blank is not a state, it's a sum with Empty state to show the swim lane

    const acceptedUserIds = [];
    const completedUserIds = [];
    const draftUserIds = [];
    const blankUserIds = [];

    task.responses.map(response => {
      console.log(response.state);
      switch(response.state){
        case 'Accepted':
          accepted = accepted + 1;
          acceptedUserIds.push(response.user_id);
          break;
        case 'Completed':
          completed = completed + 1;
          completedUserIds.push(response.user_id);
          break;
        case 'Draft':
          draft = draft + 1;
          draftUserIds.push(response.user_id);
          break;
        case 'Blank':
          //Blank means no response in DB
          //blank = blank + 1;
          blankUserIds.push(response.user_id);
          break;
        case 'Empty':
          //Empty means an Open End response in DB without text, it can have pics, moods or video.
          blank = blank + 1;
          //blankUserIds.push(response.user_id);
          break;
      }
    });

    const obj = {
      task_id: task.id,
      accepted: accepted,
      completed: completed,
      draft: draft,
      blank: blank,
      total: accepted + completed + draft + blank,
      acceptedUserIds, completedUserIds, draftUserIds, blankUserIds
    };
    //console.log(obj);
    responsesPerTask.push(obj);

    return responsesPerTask;
  }

  _calculatedResponsesQuestion_OLD(activity) {
    const {active_users, objSearchAct} = this.props;
    const responsesPerTask = [];

    activity.tasks.map(task => {
      let accepted = 0;
      let completed = 0;
      let draft = 0;
      let blank = 0;  // Blank is not a state, it's a sum with Empty state to show the swim lane

      const acceptedUserIds = [];
      const completedUserIds = [];
      const draftUserIds = [];
      const blankUserIds = [];

      let filterUsers = [];

      filterUsers = [];

      filterUsers.forEach(active_user_f => {
        active_user_f.responses.forEach(response => {
          if(task.id === response.question.id){
            switch(response.response.state){
              case 'Accepted':
                accepted = accepted + 1;
                acceptedUserIds.push(active_user_f.user_id);
                break;
              case 'Completed':
                completed = completed + 1;
                completedUserIds.push(active_user_f.user_id);
                break;
              case 'Draft':
                draft = draft + 1;
                draftUserIds.push(active_user_f.user_id);
                break;
              case 'Blank':
                //Blank means no response in DB
                blank = blank + 1;
                blankUserIds.push(active_user_f.user_id);
                break;
              case 'Empty':
                //Empty means an Open End response in DB without text, it can have pics, moods or video.
                blank = blank + 1;
                blankUserIds.push(active_user_f.user_id);
                break;
            }
          }
        });
      });
    });

    return responsesPerTask;
  }
}
