import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip"
import UL_Filters from "./UsersList/UL_Filters";

export default class UsersList extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    community_users: PropTypes.object.isRequired,
    counter: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    segments: PropTypes.array.isRequired,
    role2: PropTypes.string.isRequired,
    flagInvited: PropTypes.bool.isRequired,
    flagDraft: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="col-part-container">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <UL_Filters role_2={this.props.role2}
                    type={this.props.type}
                    counter={this.props.counter}
                    flagInvited={this.props.flagInvited}
                    flagDraft={this.props.flagDraft}
                    collaborators={this.props.community_users.collaborators}
                    colors={colors}/>
      </div>
    );
  }
}
