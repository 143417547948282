import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'

import MessageCard from "../../../Chat/MessageCard"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showSuccessMsg} from "../../../../UI/Globals/PX_Funs";

const AVTMChPanel = ({ messages, currentUserId, language, avatar, colors, lastMsgId, setLastChatId, formatter,
                       getResponseEmbeddings, query, setQuery, textAreaPlaceHolder, handleKeyDown, timerOn,
                       getAnswerMicro, response, username, startMicroChat, from, activeMicroId, updateActiveMicroId,
                       isSmallScreen, aiName, responseButtons, blockerExpired, counter }) => {

  const messagesEndRef = useRef(null)
  const conversationContainerRef = useRef(null)
  const [activeOptionsMenu, setActiveOptionsMenu] = useState(-1)

  const scrollToBottom = () => {
    //console.log('scrollToBottom', messages.length, messagesEndRef.current)
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
  }

  useEffect(() => {
    //console.log(activeMicroId === response.id, activeMicroId, response.id)
    if(activeMicroId === response.id){
      scrollToBottom()
    }
  }, [scrollToBottom, messages])

  const handleConversationScroll = () => {
    setActiveOptionsMenu(-1);
  }

  const btnMsg = timerOn ? <><FontAwesomeIcon icon={['fas','spinner']} spin size="lg"/></> :
    <FontAwesomeIcon icon={['fas','paper-plane']} size="lg"/>

  const I18n = {
    en: {
      isTyping: 'is Typing', typing: 'Typing', typing00: 'It is taking a bit to type, please be patient',
      typing01: 'It is taking more than expected. You can wait here or do other activity and come back later',
      isTyping00: 'is taking a bit to type, please be patient with him',
      isTyping01: 'is taking more than expected. You can wait here or do other activity and come back later',
      msgPlaceholderTimerOn: 'Please wait...'
    },
    es: {
      isTyping: 'está escribiendo', typing: 'Escribiendo',
      typing00: 'Se está tardando un poco en escribir, por favor ten paciencia',
      typing01: 'Se está demorando. Puedes esperar aquí o hacer otra actividad y regresar más tarde',
      isTyping00: 'está tardando un poco en escribir, por favor tenle paciencia',
      isTyping01: 'está demorándose. Puedes esperar aquí o hacer otra actividad y regresar más tarde',
      msgPlaceholderTimerOn: 'Por favor espera...'
    }
  }

  // const typingMessage = aiName ? `${aiName} ${I18n[language].isTyping}` : I18n[language].typing
  let typingMessage
  if(counter < 7){
    typingMessage = aiName ? `${aiName} ${I18n[language].isTyping}` : I18n[language].typing
  } else if(counter > 6 && counter < 17){
    typingMessage = aiName ? `${aiName} ${I18n[language].isTyping00}` : I18n[language].typing00
  } else {
    typingMessage = aiName ? `${aiName} ${I18n[language].isTyping01}` : I18n[language].typing01
  }

  const handleKeyDownInChPanel = event => {
    const enterPressed = event.key === 'Enter'
    const ctrlOrCmdPressed = event.ctrlKey || event.metaKey
    const shiftPressed = event.shiftKey
    const altPressed = event.altKey

    if(enterPressed && (ctrlOrCmdPressed || shiftPressed || altPressed)) {
      event.preventDefault();
      setQuery(query + '\n')
    } else if (enterPressed && !ctrlOrCmdPressed && !shiftPressed && !altPressed) {
      event.preventDefault(); // Prevent line breaks when Enter is pressed
      if(!timerOn){
        if(response.response.id !== -1) {
          getAnswerMicro(query, false, response.id, username, messages[messages.length - 1].id);
        }
      } else if (timerOn){
        showSuccessMsg(I18n[language].msgPlaceholderTimerOn);
      } else {
        // showSuccessMsg('');
      }
    }
  }

  return (
      <div className={ `micro-chat px-messages-drawer${isSmallScreen ? ' small-screen' : ''}` }>
        <div className='conversations-container'>
          <div className='messages'>
            {messages.map(message =>
                <MessageCard
                    key={message.id}
                    message={message}
                    currentUserId={currentUserId}
                    language={language}
                    avatar={avatar}
                    colors={colors}
                    lastMsgId={lastMsgId}
                    setLastChatId={setLastChatId}
                    formatter={formatter}
                    getResponseEmbeddings={getResponseEmbeddings}
                    from={from}
                    getAnswerMicro={getAnswerMicro}
                    username={username}
                    response_id={response.id}
                />
            )
            }
            {
                timerOn &&
                <div style={{fontFamily: 'Sharp Sans Book', fontSize: '14px', color: '#45505d', marginLeft: '5px' }}>
                  <span className="loading-ellipsis">{typingMessage}&nbsp;</span>
                </div>
            }
          </div>
          <div ref={messagesEndRef} />
        </div>
        <div className='message-container'>
          <div className="input-controls form-group">
            <textarea
                value={query}
                onChange={e => setQuery(e.target.value)}
                onKeyDown={handleKeyDownInChPanel}
                onClick={() => updateActiveMicroId(response.id)}
                name="body"
                className="message-textarea"
                placeholder={textAreaPlaceHolder}
            />
            <div className="button-send-container">
              <button
                  onClick={response.response.id !== -1 ?
                    () => getAnswerMicro(query, false, response.id, username, messages[messages.length - 1].id) : () => {}}
                  disabled={timerOn}
                  className="btn send-button"
                  style={{ backgroundColor: colors.color1[3] }}>
                {btnMsg}
              </button>
            </div>
          </div>
        </div>
        {/*{ (isSmallScreen && !blockerExpired && response && response.response) && responseButtons }*/}
      </div>
  )
}

AVTMChPanel.propTypes = {
  messages: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  colors: PropTypes.object.isRequired,
  lastMsgId: PropTypes.number.isRequired,
  setLastChatId: PropTypes.func.isRequired,
  formatter: PropTypes.func.isRequired,
  getResponseEmbeddings: PropTypes.func.isRequired,
  counter: PropTypes.number.isRequired,
  aiName: PropTypes.string.isRequired
}

export default AVTMChPanel