import PropTypes from 'prop-types';
import React from 'react';
import NewsFlash from "./News/NewsFlash";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import spanishStrings from "react-timeago/lib/language-strings/es";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'underscore';

import Loading from "./UI/Globals/Loading";
import { showSuccessMsg } from "./UI/Globals/PX_Funs";

export default class News extends React.Component {
  static propTypes = {
    news: PropTypes.array,
    language: PropTypes.string,
    rightDrawerToggleHandler: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    isLoadingNews: PropTypes.bool.isRequired,
    newsLastVisit: PropTypes.string.isRequired,
    setLastNewsVisit: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { language, colors, news, isLoadingNews, newsLastVisit } = this.props;
    const formatter = language === 'en' ? buildFormatter(englishStrings) : buildFormatter(spanishStrings);

    const allNews = []
    news.forEach((newsFlash, index)=>{
      allNews.push(
        <NewsFlash 
        key={index}
        newsFlash={newsFlash}
        language={language}
        formatter={formatter}
        colors={colors}
        newsLastVisit={newsLastVisit}
        />
      );
    })

    return(
      <React.Fragment>
        <div className="px-upper-container">
          <div className="notifications-header" style={{ backgroundColor: colors.color5[3] }}>
            <div className="notifications-title">
              <span>What's new</span>
            </div>
            <div onClick={this.close_panel} className="notifications-close" style={{ cursor: 'pointer', backgroundColor: colors.color5[0] }}>
              <FontAwesomeIcon icon={['far', 'xmark']} className="iconClose" />
            </div>
          </div>
        </div>
        { 
          isLoadingNews ?
            <Loading /> :
            <div className="notifications-items-container">
              <div className="notification-item">
                {allNews}
              </div>
            </div>
        }
      </React.Fragment>
    );
  }

  close_panel = () => {
    this.props.rightDrawerToggleHandler();
    this.props.setLastNewsVisit();
  }
}