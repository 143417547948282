import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import _ from "underscore";
import moment from 'moment';
import moment_tz from "moment-timezone";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import TimeAgo from "react-timeago";
import { miscI18n } from "./PX_i18n";
import isBeforeDay from "react-dates/esm/utils/isBeforeDay";

function isInclusivelyAfterDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
}

function getResponseStateLang(state, language){
  let stateLang = '';

  if(language === 'en'){
    stateLang = state;
  } else {
    switch(state){
      case 'Accepted':
        stateLang = 'Aceptado';
        break;
      case 'Completed':
        stateLang = 'Completado';
        break;
      case 'Draft':
        stateLang = 'Borrador';
        break;
      case 'Empty':
        stateLang = 'En Blanco';
        break;
    }
  }

  return stateLang;
}

function truncateString (aString, maxLength) {
  let truncatedString = null;
  if (aString.length > maxLength) {
    truncatedString = aString.substring(0, maxLength) + "..."
  } else {
    truncatedString = aString;
  }
  return truncatedString;
}

// Return the Now (time) based in the community default time zone
function getNowUTC(default_tz){
  const browserTZ = moment_tz.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('ZZ');
  const communityTZ = moment_tz().tz(default_tz).format('ZZ');
  const hours = (communityTZ - browserTZ) / 100;

  const now = moment().add(hours, "hour").format('YYYY-MM-DD');
  //console.log(browserTZ, communityTZ, hours, now);

  return now;
}

function getCSRF(){
  return {headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').last().attr('content')}};
}

// Get the number of users per segment - used in some modals
function numberFilterSegment (segmentId, activeUsers, gender_as_segment){
  //console.log(segmentId, activeUsers);
  let counter = 0;

  activeUsers.forEach(user => {
    user.segments.forEach(segment => {
      if(segmentId === segment.id){
        ++counter;
      }
    });

    if(gender_as_segment){
      if(segmentId === 'Other Gender'){
        if('Other' === user.gender){
          ++counter;
        }
      } else {
        if(segmentId === user.gender){
          ++counter;
        }
      }
    }
  });

  return counter;
}

//Used in Collaborator Dashboards
function formatExpireDate(expireDate, language){
  const locale = 'en-au';
  const formatter = buildFormatter(englishStrings);
  let cardExpire;

  if(expireDate){
    if(moment().format('YYYY-MM-DD') > expireDate){
      cardExpire = miscI18n[language].expired;
    } else {
      const nowPlus7DD = moment().add(7, 'days');

      if(expireDate > nowPlus7DD.format('YYYY-MM-DD')){
        cardExpire = `${miscI18n[language].expires} ${moment(expireDate).locale(locale).format("ll")}`;
      } else {
        const expireDateM = moment(expireDate, 'YYYY-MM-DD').add(1, 'days');
        cardExpire = <TimeAgo date={expireDateM} formatter={formatter}/>;
      }
    }
  } else {
    cardExpire = miscI18n[language].neverExpires;
  }

  return cardExpire;
}

function sortCollTasks(tasks, sort) {
  let sorted = null;
  switch(sort){
    case 'custom':
      sorted = tasks.sort(function (a, b) {return a.position - b.position});
      break;
    case 'end date':
      sorted = tasks.sort(function (a, b) {return new Date(a.expire) - new Date(b.expire)});
      break;
    case 'start date':
      sorted = tasks.sort(function (a, b) {return new Date(a.start) - new Date(b.start)});
      break;
    case 'creation date':
      sorted = tasks.sort(function (a, b) {return new Date(a.created_at) - new Date(b.created_at)});
      break;
    case 'tag':
      const tags = _.groupBy(tasks, 'tag_name');
      const setsIndex = Object.keys(tags).sort();
      const sortedTags = [];

      setsIndex.push(setsIndex.shift());
      //console.log(tags, setsIndex);
      setsIndex.forEach(function (tag_name) {
        const arr = tags[tag_name];
        if(typeof arr !== 'undefined'){
          const arrSortedDate = arr.sort(function (a, b) {
            return new Date(a.created_at) - new Date(b.created_at)
          });
          arrSortedDate.forEach(function (task) {
            sortedTags.push(task);
          });
        }
      });
      //console.log(sortedTags);
      sorted = sortedTags;
      break;
    case 'title':
      sorted = tasks.sort(function (a, b) {return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);});
      break;
  }
  //console.log('sorted:', sorted);
  return sorted
}

function setIconGender(gender){
  let icon = null;

  switch(gender){
    case 'Female':
      icon = 'venus';break;
    case 'Male':
      icon = 'mars';break;
      // TO-DO: define in DB and all the App what would be: 'Other' or 'Other Gender'
    case 'Other Gender':
    case 'Other':
      icon = 'transgender-alt';break;
  }

  return <FontAwesomeIcon icon={['fal', icon]} className='tag'/>;
}

function emailValidation(value) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
}

function showSuccessMsg(msg){
  $('#spnSnackbar').text(msg);
  $('#snackbar').addClass('show');
  setTimeout(function(){ $('#snackbar').removeClass('show'); }, 3000);
}
// Args blob: blob, preview: react refs
function setBlobToPreview(blob, preview){
  //console.log(preview);
  const reader  = new FileReader();

  reader.onloadend = function () {
    preview.src = reader.result;
  };

  reader.readAsDataURL(blob);
}

function calcUsersSegments(activeUsers, gender_as_segment, task){
  let counterUsers = 0;

  if(task.segments.length !== 0){
    const matching = [];
    task.segments.forEach(ts => {
      activeUsers.forEach(user => {
        if(gender_as_segment){
          const newUserSegments = [...user.segments];
          newUserSegments.push({
            id: user.gender === 'Other' ? 'Other Gender' : user.gender,
            name: user.gender === 'Other' ? 'Other Gender' : user.gender
          });

          newUserSegments.forEach(us => {
            if(ts.value === us.id){
              matching.push(user.user_id);
            }
          })
        } else {
          user.segments.forEach(us => {
            if(ts.value === us.id){
              matching.push(user.user_id);
            }
          });
        }
      });

    });
    counterUsers = [...new Set(matching)].length;

  } else {
    counterUsers = activeUsers.length;
  }

  return counterUsers;
}

function getParticipantToDoCounter(tasks){
  let counter  = 0;

  tasks.forEach(task => {
    if(task.visibility === 'enabled' && !task.isTaskDone){
      //console.log(task.title);
      if(task.required){
        if(task.isRequiredDone){
          // All rules
          counter = _getCounterStartEnd(task, counter);
        }
      } else {
        // All rules
        counter = _getCounterStartEnd(task, counter);
      }
    }
  });
  //console.log(counter);
  return counter < 0 ? 0 : counter;
}

function _getCounterStartEnd(task, counter){
  const now = moment().format('YYYY-MM-DD');

  if(task.expire) {
    const expireDate = moment(task.expire).format('YYYY-MM-DD');

    if(now <= expireDate){
      if(task.start){
        const startDate = moment(task.start).format('YYYY-MM-DD');

        if(now < startDate){

        }  else {
          counter++;
          //console.log('At time:', task.title);
        }

      } else {
        if(now <= expireDate){
          counter++;
          //console.log('At time:', task.title);
        }
      }
    }
  } else {
    if(task.start){
      const startDate = moment(task.start).format('YYYY-MM-DD');

      if(now < startDate){

      } else {
        counter++;
        //console.log('Yes Start-No End:', task.title);
      }
    } else {
      counter++;
      //console.log('No Start-No End:', task.title);
    }
  }

  return counter;
}

// Sort tasks by Tonight - Normal - Expired
function sortSortedTasks(sortedTasks){
  const now = moment().format('YYYY-MM-DD');

  const tonight = sortedTasks.filter(activity => {
    if(activity.expire){
      const expireDate = moment(activity.expire).format('YYYY-MM-DD');
      //console.log(now, expireDate, activity.title);
      if(now === expireDate){
        return activity;
      }
    }
  });

  const expired = sortedTasks.filter(task => {
    if(task.expire){
      const expireDate = moment(task.expire).format('YYYY-MM-DD');

      if(now > expireDate){
        return task;
      }
    }
  });

  const sort = [...tonight, ...expired];

  let middle = sortedTasks;
  sort.forEach(t => {
    middle = middle.filter(task => task.id !== t.id);
  });

  const liveNotAvailable = middle.filter(activity => {
    if(activity.start){
      const startDate = moment(activity.start).format('YYYY-MM-DD');

      if(startDate > now){
        return activity;
      }
    }
  });

  const middleNoExpDate = middle.filter(a => a.expire === null);
  const middleExpDate = middle.filter(a => a.expire);
  //console.log(middleNoExpDate, middleExpDate);

  const liveAvailable = middleExpDate.filter(activity => {
    if(activity.start){
      const startDate = moment(activity.start).format('YYYY-MM-DD');

      if(startDate <= now){
        return activity;
      }
    } else {
      return activity;
    }
  });

  //console.log(tonight, liveAvailable, liveNotAvailable, expired);
  return [...tonight, ...liveAvailable, ...middleNoExpDate, ...liveNotAvailable, ...expired];
  //return [...tonight, ...middle, ...expired];
}

// OPT NOW
function sort_now_01(nowItems, nowFilter){
  //console.log(nowItems, nowFilter);
  let nowItemsA = [];

  switch(nowFilter){
    case 'All':
      nowItemsA = nowItems;
      break;
  }

  return nowItemsA;
}

// Sort NOW with filter and DESC by creation date
function sort_now (tasks, nowFilter, followed){
  let nowItems = [];

  switch(nowFilter){
    case 'Followed':
      tasks.map((task) => {
        task.likesNow.map((like) => {
          nowItems.push(like)
        });
        task.commentsNow.map((comment) => {
          nowItems.push(comment)
        });
        task.responsesNow.map((response) => {
          nowItems.push(response)
        });
        task.repliesNow.map((reply) => {
          nowItems.push(reply)
        });
        task.likesResponseNow.map((likeResponse) => {
          nowItems.push(likeResponse)
        });
        task.likesReplyNow.map((likeReply) => {
          nowItems.push(likeReply)
        });
      });

      const followedItems = nowItems;
      nowItems = [];
      followedItems.forEach(item => {
        const isFound = followed.find(follow => follow.followed_user_id === item.user_id);
        if(isFound !== undefined){
          nowItems.push(item);
        }
      });
      break;
    case 'All':
      tasks.map((task) => {
        /*task.likesNow.map((like) => {
          nowItems.push(like)
        });
        task.commentsNow.map((comment) => {
          nowItems.push(comment)
        });*/
        task.responsesNow.map((response) => {
          nowItems.push(response)
        });
        /*task.repliesNow.map((reply) => {
          nowItems.push(reply)
        });
        task.likesResponseNow.map((likeResponse) => {
          nowItems.push(likeResponse)
        });
        task.likesReplyNow.map((likeReply) => {
          nowItems.push(likeReply)
        });*/
      });
      break;
    case 'Responses':
      tasks.map((task) => {
        task.responsesNow.map((response) => {
          nowItems.push(response)
        });
      });
      break;
    case 'Comments':
      tasks.map((task) => {
        task.commentsNow.map((comment) => {
          nowItems.push(comment)
        });
      });
      break;
    case 'Likes':
      tasks.map((task) => {
        task.likesNow.map((like) => { nowItems.push(like) });
        task.likesResponseNow.map((likeResponse) => { nowItems.push(likeResponse) });
        task.likesReplyNow.map((likeReply) => { nowItems.push(likeReply) });
      });
      break;
    case 'Replies':
      tasks.map((task) => { task.repliesNow.map((reply) => { nowItems.push(reply)}) });
      break;
  }

  nowItems.sort(function (a, b) {
    return new Date(b.created_at) - new Date(a.created_at)
  });
  //console.log(nowItems);
  return nowItems;
}

const isoCountries = {
  'AF' : 'Afghanistan',
  'AX' : 'Aland Islands',
  'AL' : 'Albania',
  'DZ' : 'Algeria',
  'AS' : 'American Samoa',
  'AD' : 'Andorra',
  'AO' : 'Angola',
  'AI' : 'Anguilla',
  'AQ' : 'Antarctica',
  'AG' : 'Antigua And Barbuda',
  'AR' : 'Argentina',
  'AM' : 'Armenia',
  'AW' : 'Aruba',
  'AU' : 'Australia',
  'AT' : 'Austria',
  'AZ' : 'Azerbaijan',
  'BS' : 'Bahamas',
  'BH' : 'Bahrain',
  'BD' : 'Bangladesh',
  'BB' : 'Barbados',
  'BY' : 'Belarus',
  'BE' : 'Belgium',
  'BZ' : 'Belize',
  'BJ' : 'Benin',
  'BM' : 'Bermuda',
  'BT' : 'Bhutan',
  'BO' : 'Bolivia',
  'BA' : 'Bosnia And Herzegovina',
  'BW' : 'Botswana',
  'BV' : 'Bouvet Island',
  'BR' : 'Brazil',
  'IO' : 'British Indian Ocean Territory',
  'BN' : 'Brunei Darussalam',
  'BG' : 'Bulgaria',
  'BF' : 'Burkina Faso',
  'BI' : 'Burundi',
  'KH' : 'Cambodia',
  'CM' : 'Cameroon',
  'CA' : 'Canada',
  'CV' : 'Cape Verde',
  'KY' : 'Cayman Islands',
  'CF' : 'Central African Republic',
  'TD' : 'Chad',
  'CL' : 'Chile',
  'CN' : 'China',
  'CX' : 'Christmas Island',
  'CC' : 'Cocos (Keeling) Islands',
  'CO' : 'Colombia',
  'KM' : 'Comoros',
  'CG' : 'Congo',
  'CD' : 'Congo, Democratic Republic',
  'CK' : 'Cook Islands',
  'CR' : 'Costa Rica',
  'CI' : 'Cote D\'Ivoire',
  'HR' : 'Croatia',
  'CU' : 'Cuba',
  'CY' : 'Cyprus',
  'CZ' : 'Czech Republic',
  'DK' : 'Denmark',
  'DJ' : 'Djibouti',
  'DM' : 'Dominica',
  'DO' : 'Dominican Republic',
  'EC' : 'Ecuador',
  'EG' : 'Egypt',
  'SV' : 'El Salvador',
  'GQ' : 'Equatorial Guinea',
  'ER' : 'Eritrea',
  'EE' : 'Estonia',
  'ET' : 'Ethiopia',
  'FK' : 'Falkland Islands (Malvinas)',
  'FO' : 'Faroe Islands',
  'FJ' : 'Fiji',
  'FI' : 'Finland',
  'FR' : 'France',
  'GF' : 'French Guiana',
  'PF' : 'French Polynesia',
  'TF' : 'French Southern Territories',
  'GA' : 'Gabon',
  'GM' : 'Gambia',
  'GE' : 'Georgia',
  'DE' : 'Germany',
  'GH' : 'Ghana',
  'GI' : 'Gibraltar',
  'GR' : 'Greece',
  'GL' : 'Greenland',
  'GD' : 'Grenada',
  'GP' : 'Guadeloupe',
  'GU' : 'Guam',
  'GT' : 'Guatemala',
  'GG' : 'Guernsey',
  'GN' : 'Guinea',
  'GW' : 'Guinea-Bissau',
  'GY' : 'Guyana',
  'HT' : 'Haiti',
  'HM' : 'Heard Island & Mcdonald Islands',
  'VA' : 'Holy See (Vatican City State)',
  'HN' : 'Honduras',
  'HK' : 'Hong Kong',
  'HU' : 'Hungary',
  'IS' : 'Iceland',
  'IN' : 'India',
  'ID' : 'Indonesia',
  'IR' : 'Iran, Islamic Republic Of',
  'IQ' : 'Iraq',
  'IE' : 'Ireland',
  'IM' : 'Isle Of Man',
  'IL' : 'Israel',
  'IT' : 'Italy',
  'JM' : 'Jamaica',
  'JP' : 'Japan',
  'JE' : 'Jersey',
  'JO' : 'Jordan',
  'KZ' : 'Kazakhstan',
  'KE' : 'Kenya',
  'KI' : 'Kiribati',
  'KR' : 'Korea',
  'KW' : 'Kuwait',
  'KG' : 'Kyrgyzstan',
  'LA' : 'Lao People\'s Democratic Republic',
  'LV' : 'Latvia',
  'LB' : 'Lebanon',
  'LS' : 'Lesotho',
  'LR' : 'Liberia',
  'LY' : 'Libyan Arab Jamahiriya',
  'LI' : 'Liechtenstein',
  'LT' : 'Lithuania',
  'LU' : 'Luxembourg',
  'MO' : 'Macao',
  'MK' : 'Macedonia',
  'MG' : 'Madagascar',
  'MW' : 'Malawi',
  'MY' : 'Malaysia',
  'MV' : 'Maldives',
  'ML' : 'Mali',
  'MT' : 'Malta',
  'MH' : 'Marshall Islands',
  'MQ' : 'Martinique',
  'MR' : 'Mauritania',
  'MU' : 'Mauritius',
  'YT' : 'Mayotte',
  'MX' : 'Mexico',
  'FM' : 'Micronesia, Federated States Of',
  'MD' : 'Moldova',
  'MC' : 'Monaco',
  'MN' : 'Mongolia',
  'ME' : 'Montenegro',
  'MS' : 'Montserrat',
  'MA' : 'Morocco',
  'MZ' : 'Mozambique',
  'MM' : 'Myanmar',
  'NA' : 'Namibia',
  'NR' : 'Nauru',
  'NP' : 'Nepal',
  'NL' : 'Netherlands',
  'AN' : 'Netherlands Antilles',
  'NC' : 'New Caledonia',
  'NZ' : 'New Zealand',
  'NI' : 'Nicaragua',
  'NE' : 'Niger',
  'NG' : 'Nigeria',
  'NU' : 'Niue',
  'NF' : 'Norfolk Island',
  'MP' : 'Northern Mariana Islands',
  'NO' : 'Norway',
  'OM' : 'Oman',
  'PK' : 'Pakistan',
  'PW' : 'Palau',
  'PS' : 'Palestinian Territory, Occupied',
  'PA' : 'Panama',
  'PG' : 'Papua New Guinea',
  'PY' : 'Paraguay',
  'PE' : 'Peru',
  'PH' : 'Philippines',
  'PN' : 'Pitcairn',
  'PL' : 'Poland',
  'PT' : 'Portugal',
  'PR' : 'Puerto Rico',
  'QA' : 'Qatar',
  'RE' : 'Reunion',
  'RO' : 'Romania',
  'RU' : 'Russian Federation',
  'RW' : 'Rwanda',
  'BL' : 'Saint Barthelemy',
  'SH' : 'Saint Helena',
  'KN' : 'Saint Kitts And Nevis',
  'LC' : 'Saint Lucia',
  'MF' : 'Saint Martin',
  'PM' : 'Saint Pierre And Miquelon',
  'VC' : 'Saint Vincent And Grenadines',
  'WS' : 'Samoa',
  'SM' : 'San Marino',
  'ST' : 'Sao Tome And Principe',
  'SA' : 'Saudi Arabia',
  'SN' : 'Senegal',
  'RS' : 'Serbia',
  'SC' : 'Seychelles',
  'SL' : 'Sierra Leone',
  'SG' : 'Singapore',
  'SK' : 'Slovakia',
  'SI' : 'Slovenia',
  'SB' : 'Solomon Islands',
  'SO' : 'Somalia',
  'ZA' : 'South Africa',
  'GS' : 'South Georgia And Sandwich Isl.',
  'ES' : 'Spain',
  'LK' : 'Sri Lanka',
  'SD' : 'Sudan',
  'SR' : 'Suriname',
  'SJ' : 'Svalbard And Jan Mayen',
  'SZ' : 'Swaziland',
  'SE' : 'Sweden',
  'CH' : 'Switzerland',
  'SY' : 'Syrian Arab Republic',
  'TW' : 'Taiwan',
  'TJ' : 'Tajikistan',
  'TZ' : 'Tanzania',
  'TH' : 'Thailand',
  'TL' : 'Timor-Leste',
  'TG' : 'Togo',
  'TK' : 'Tokelau',
  'TO' : 'Tonga',
  'TT' : 'Trinidad And Tobago',
  'TN' : 'Tunisia',
  'TR' : 'Turkey',
  'TM' : 'Turkmenistan',
  'TC' : 'Turks And Caicos Islands',
  'TV' : 'Tuvalu',
  'UG' : 'Uganda',
  'UA' : 'Ukraine',
  'AE' : 'United Arab Emirates',
  'GB' : 'United Kingdom',
  'US' : 'United States',
  'UM' : 'United States Outlying Islands',
  'UY' : 'Uruguay',
  'UZ' : 'Uzbekistan',
  'VU' : 'Vanuatu',
  'VE' : 'Venezuela',
  'VN' : 'Viet Nam',
  'VG' : 'Virgin Islands, British',
  'VI' : 'Virgin Islands, U.S.',
  'WF' : 'Wallis And Futuna',
  'EH' : 'Western Sahara',
  'YE' : 'Yemen',
  'ZM' : 'Zambia',
  'ZW' : 'Zimbabwe'
};

function getCountryName (countryCode) {
  if (isoCountries.hasOwnProperty(countryCode)) {
    return isoCountries[countryCode];
  } else {
    return countryCode;
  }
}

function getIsTaskAlert(activity, response){
  //console.log(activity, response);
  let result = false;

  if(activity.expire){
    const now = moment().format('YYYY-MM-DD');
    const expireDate = moment(activity.expire).format('YYYY-MM-DD');
    //console.log(now, expireDate);
    if(now === expireDate){
      if(response.state === 'Draft' || response.state === 'Empty' || response.state === 'Blank'){
        result = true;
      }
    }

    if(now > expireDate && !activity.overdue){
      if(response.state === 'Draft' || response.state === 'Empty' || response.state === 'Blank'){
        result = true;
      }
    }
  }

  //Unseen comments or replies
  if(response.sum_unseen_col > 0){
    result = true;
  }

  return result;
}

function getRandomFasterCommentMatrix (type, username, comments, collId, questionId, responseId, quickComments, language, digest) {
  //console.log(type, username, comments, collId, questionId, responseId, quickComments, language, digest);
  let forget = [];
  let randomComment = '';

  quickComments.forEach(q => {
    if(q.kind === 'Quick Please'){
      forget = q.items.map((c, index) => {
          return c[language].search('{{username}}') === -1 ? c[language] : c[language].replace('{{username}}', username);
        });
    }
  });

  let flag = true;
  const lastComment = comments.length !== 0 ? comments[0].body : '';

  if(type === 'forget'){
    while(flag){
      let index = Math.floor((Math.random() * forget.length));
      randomComment = forget[index];

      if(lastComment !== randomComment){
        flag = false;
        //console.log(randomComment);
      }
    }
  }

  return randomComment;
}

function sendRandomCommentMatrix (type, username, comments, collId, questionId, responseId, quickComments, language, digest) {
  console.log(type, username, comments, collId, questionId, responseId, quickComments, language, digest);

  let thanks = [];
  let forget = [];
  let getBack = [];

  quickComments.forEach(q => {
    //console.log(q.kind);
    switch(q.kind){
      case 'Thanks':
        thanks = q.items.map((c, index) => {
          return c[language].search('{{username}}') === -1 ? c[language] : c[language].replace('{{username}}', username);
          //thanks.push(msg);
        });
        break;
      case 'Reply Later':
        getBack = q.items.map((c, index) => {
          return c[language].search('{{username}}') === -1 ? c[language] : c[language].replace('{{username}}', username);
        });
        break;
      case 'Quick Please':
        forget = q.items.map((c, index) => {
          return c[language].search('{{username}}') === -1 ? c[language] : c[language].replace('{{username}}', username);
        });
        break;
    }
  });

  //console.log('forget:', forget);

  /*const thanks = ['Well done! Thanks.', 'Great Answer, very creative.', 'I get it, thanks for sharing',
    'Thanks for sharing', 'Thanks ' + username];*/
  /*const getBack = ['Thanks, I\'ll get back once I read your answer',
    'Please, allow me some time to read your answer and I\'ll get back to you',
    'Thanks, I\'ll follow up your answer as soon as possible', username + ', I\'ll be back to you soon'];*/
  /*const forget = [username + ', please don\'t forget to answer this task as soon as possible. Thanks.',
    'Hi there, please don\'t forget to answer.', 'A friendly reminder in case you miss to answer this task.',
    'Please don\'t forget to complete this task.'];*/
  let flag = true;
  const lastComment = comments.length !== 0 ? comments[0].body : '';
  //console.log(username, lastComment);
  // TO-DO move to a function the set random comment and send
  switch(type){
    case 'forget':
      while(flag){
        let index = Math.floor((Math.random() * forget.length));
        let randomComment = forget[index];
        //console.log(lastComment, randomComment);
        if(lastComment !== randomComment){
          flag = false;
          console.log(randomComment);
          saveComment(randomComment, collId, questionId, responseId, digest);
        }
      }
      break;
    case 'thanks':
      while(flag){
        let index = Math.floor((Math.random() * thanks.length));
        let randomComment = thanks[index];
        //console.log(lastComment, randomComment);
        if(lastComment !== randomComment){
          flag = false;
          this.setState({body: randomComment}, () => {
            //saveComment(randomComment, collId, questionId, responseId, digest);
          });
        }
      }
      break;
    case 'getBack':
      while(flag){
        let index = Math.floor((Math.random() * getBack.length));
        let randomComment = getBack[index];
        //console.log(lastComment, randomComment);
        if(lastComment !== randomComment){
          flag = false;
          this.setState({body: randomComment}, () => {
            //saveComment(randomComment, collId, questionId, responseId, digest);
          });
        }
      }
      break;
  }
}

function saveComment(randomComment, collId, questionId, responseId, digest){
  console.log(randomComment, collId, questionId, responseId, digest);
  if(randomComment !== ''){
    $.ajax({
      url: '/comment/'+ collId +'/'+ questionId +'/' + responseId,
      method: 'POST',
      dataType: 'JSON',
      data: {
        comment: {
          body: randomComment
        },
        digest, // To know if send normal notification/email or not
        from: '',  // We don't need to send from to the server
        task_id: -1 //To get the active_participants and update the Dashboard - it's legacy
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: obj => {
        //console.log(obj);
      }
    });
  }
}

// TO-DO: Fix Available logic
function filterSetDates(type, tasks){
  const filtered = [];

  const now = moment().format('YYYY-MM-DD');

  if(type === 'Available'){
    tasks.forEach(task => {
      if(task.expire) {
        const expireDate = moment(task.expire).format('YYYY-MM-DD');

        if(now <= expireDate){
          filtered.push(task);
        }
      } else {
        filtered.push(task);
      }

      if(task.start){
        const startDate = moment(task.start).format('YYYY-MM-DD');

        if(now >= startDate){
          //TO-DO improve it, it selects available without to know start date
          //filtered.push(task);
        }
      } else {
        filtered.push(task);
      }
    });
  } else {
    tasks.forEach(task => {
      if(task.expire) {
        const expireDate = moment(task.expire).format('YYYY-MM-DD');

        if(now > expireDate){
          filtered.push(task);
        }
      } else {
        //filtered.push(task);
      }
    });
  }
  console.log('filtered:', filtered);
  return filtered;
}

function parseMarkdownTable(markdownString) {
  const lines = markdownString.trim().split("\n");
  if (lines.length < 3) return null;

  const headers = lines[0]
    .split("|")
    .map((header) => header.trim())
    .filter((header) => header !== "");
  const separator = lines[1]
    .split("|")
    .map((separator) => separator.trim())
    .filter((separator) => separator !== "");
  if (
    separator.some((item) => !item.match(/^[-:]+$/)) ||
    !markdownString.includes("---")
  )
    return null;

  const rows = lines
    .slice(2)
    .map((line) =>
      line.split("|").map((cell) => cell.trim()).filter((cell) => cell !== "")
    );

  return { headers, rows };
}

function capitalizeSentences(text) {
  return text
    .trim() // Removes extra spaces at the start and end
    .toLowerCase() // Converts everything to lowercase initially
    .replace(/(^\w|\.\s*\w)/g, function(match) {
      return match.toUpperCase();
    });
}

function isNullOrUndefinedOrEmptyObject(variable) {
  // Check if the variable is null or undefined
  if (variable === null || variable === undefined) {
    return true;
  }

  // Check if the variable is an empty object
  if (typeof variable === 'object' && Object.keys(variable).length === 0 && variable.constructor === Object) {
    return true;
  }

  return false;
}

export {formatExpireDate, sortCollTasks, setIconGender, showSuccessMsg, setBlobToPreview, calcUsersSegments,
  getCountryName, getParticipantToDoCounter, sortSortedTasks, sort_now, sort_now_01, getIsTaskAlert,
  sendRandomCommentMatrix, filterSetDates, emailValidation, getCSRF, numberFilterSegment, getNowUTC,
  getRandomFasterCommentMatrix, truncateString, getResponseStateLang, isInclusivelyAfterDay,
  parseMarkdownTable, capitalizeSentences, isNullOrUndefinedOrEmptyObject}