import PropTypes from 'prop-types';
import React from 'react';
import Slider from "react-slick/lib";
import Dotdotdot from "react-dotdotdot";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"

export default class TF_Participants extends React.Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    showFiltersHandler: PropTypes.func.isRequired,
    handleFilterUsers: PropTypes.func.isRequired,
    selectAllUsers: PropTypes.bool.isRequired,
    handleAllUsers: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    $(".users-container-master").addClass("animated fadeIn");
  }

  render() {
    let counter = 0;
    const users = this.props.users.map(user => {
      const userStyle = user.active ? {opacity: '1', cursor:'pointer'} : {cursor:'pointer'};
      user.active && ++counter;

      return(
        <div key={user.user_id} data-tooltip-content={user.username} style={{width:'100px'}}>
          <div className="participant-item">
            <img onClick={() => this.props.handleFilterUsers(user.user_id)}
                 src={user.avatar !== '' ? user.avatar : '/assets/user.png'} alt="" className="user-img"
                 style={userStyle}/>
            <Dotdotdot clamp={1}>
              <span className="name">{user.username}</span>
            </Dotdotdot>
          </div>
        </div>
      )
    });

    const sliderUsersTasks = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: <SampleNextArrowUsers />,
      prevArrow: <SamplePrevArrowUsers />,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const sliderUsers = <Slider {...sliderUsersTasks} className="participants-container">
      { users }
    </Slider>;

    return (

      <div className="row matrix-filter-container">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="row filter-container collapse in" id={"demo"}>
            <div className="col-md-12">
              <div className="users-container-master">
                <div className="card-profile users">
                  <div className="users-container">
                    <div className="multiple-users">
                      <FontAwesomeIcon icon={['fal', 'users']}/>
                      <div className="counter-users">
                        <span>{counter}</span>
                      </div>
                    </div>
                  </div>
                  <i onClick={this.props.showFiltersHandler} className="mdi mdi-dots-horizontal-circle icon-circle"/>
                </div>

                <div style={{display:'flex',flexDirection:'column',width:'calc(100% - 100px)'}} className="master-participants-container">
                  <div style={{position:'relative',margin:' 0 30px 0 40px', display: 'flex', flexDirection:'row', alignItems:'center', borderBottom:'1px solid lightgray', paddingBottom:'8.7px'}}>
                    <div className="blur-filter-users" />
                    <label className="container-check">All Participants
                      <input onClick={this.props.handleAllUsers} type="checkbox" name="required" value="on"
                             checked={this.props.selectAllUsers}/>
                      <span className="checkmark"/>
                    </label>
                    {sliderUsers}
                  </div>
                  <div className="participants-data-container">
                    <div className="left-container">
                      <label className="container-check" style={{marginRight:'10px'}}>All Segments
                        <input type="checkbox" name="required" value="on"/>
                        <span className="checkmark"/>
                      </label>
                      <div className="tags-container">
                        <FontAwesomeIcon icon={['fas','tag']} className="tag tag-blue" />
                        <FontAwesomeIcon icon={['fas','tag']} className="tag tag-pink" />
                        <FontAwesomeIcon icon={['fas','tag']} className="tag tag-green" />
                      </div>
                    </div>
                    <div className="right-container">
                      <div className="separator"/>
                      <i className="mdi mdi-layers icon-right "/>
                      <FontAwesomeIcon icon={['fas','image']} className="icon-right"/>
                      <FontAwesomeIcon icon={['fas','video']} className="icon-right"/>
                      <button onClick={this.props.showFiltersHandler} className="btn-actions cancel">
                        <FontAwesomeIcon icon={['far', 'xmark']}/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="accordion-menu-filters" data-toggle="collapse" data-target="#demo">
                <FontAwesomeIcon  icon={['fas','circle-chevron-up']} className="chev-toggle"/>
                <span className="text">FILTERS</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function SampleNextArrowUsers(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', borderRadius:'50%', right:'-20px'}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrowUsers(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', zIndex:'1' , borderRadius:'50%', left:'0'}}
      onClick={onClick}
    />
  );
}