import PropTypes from 'prop-types';
import React from 'react';
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class LandingPDF extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    pdf_name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isCreatingPdf: false,
      seconds: 0,
      publicUrl: ''
    }
  };

  componentDidMount() {
    if(this.props.url !== ''){
      this.intervalClock = setInterval(() => this.tick(), 1000);
      this.createPDF(this.props.url);
    }
  }

  componentWillUnmount() {
    //console.log('stop interval'); // Maybe it is not necessary
    clearInterval(this.intervalClock);
  }

  render(){
    const {language, type} = this.props;

    const i18n = {
      en: {
        creating: 'It could take a few minutes, you can return to the app while we are preparing your PDF document',
        red: 'redirecting', ready: 'Ready!', dow: 'downloading'
      } ,
      es: {
        creating: 'Podría tomar unos cuantos minutos, usted puede regresar a la app mientras nosotros estamos ' +
          'preparamos su documento PDF', red: 'redireccionando', ready: 'Listo!', dow: 'descargando'
      }
    };

    return(
      <div className="container-fluid px-transcript-container false mg-top-50">
        <div className='row'>
          <div className="col-md-3">&nbsp;</div>
          <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
            <div className="panel px-card px-card-profile">
              <div className="panel-body">
                {
                  this.state.isCreatingPdf ?
                    <span>
                      {i18n[language].creating} <FontAwesomeIcon icon={['fas','spinner']} spin/>&nbsp;
                      (Seconds: {this.state.seconds})
                    </span> :
                    <span>{i18n[language].ready}&nbsp;
                      {
                        type === 'view' ? `${i18n[language].red}...` : `${i18n[language].dow}...`
                      }</span>
                }
              </div>
            </div>
          </div>
          <div className="col-md-3">&nbsp;</div>
        </div>
      </div>
    )
  }

  createPDF = url => {
    console.log('url:', url);
    if(!this.state.isCreatingPdf){
      this.setState({isCreatingPdf: true}, () => {
        axios.get(url)
          .then(response => {
            console.log(response.data);

            this.intervalID = setInterval(this.setIntervalFunc, 5000);
            console.log('this.intervalID:', this.intervalID);
          });
      })
    }
  };

  setIntervalFunc = () => {
    axios.get('/exports/get_pdf/' + this.props.pdf_name)
      .then(response => {
        const publicUrl = response.data;
        console.log('publicUrl:', publicUrl);
        if(publicUrl !== ''){
          clearInterval(this.intervalClock);
          clearInterval(this.intervalID);

          this.setState({isCreatingPdf: false, publicUrl}, () => {
            if(this.props.type === 'view'){
              window.open(publicUrl, '_self');
            } else {
              window.open('/exports/get_pdf_download?url=' + publicUrl, '_self');
            }
          })
        }
      });
  };

  tick() {
    this.setState(state => ({
      seconds: state.seconds + 1
    }));
  }
}
