import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeAgo from "react-timeago";
import englishStrings from 'react-timeago/lib/language-strings/en';
import spanishStrings from 'react-timeago/lib/language-strings/es';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

export default class Broadcast extends React.Component {
  static propTypes = {
    broadcast: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isContracted: this.props.broadcast.body.replace(/\s+/g, '').length > 200 ? true : false
    }
  }

  render() {
    const { broadcast, language, colors } = this.props;
    const formatter = language === 'es' ? buildFormatter(spanishStrings) : buildFormatter(englishStrings);

    const I18n = {
      en: { more: 'see more' },
      es: { more: 'ver más' }
    };
    const helperObj = this.historyBroadcastHelper(broadcast.recipients);
    return (
      <div className={`broadcast-item broadcast-history-item ${this.state.isContracted && 'contract' }`}>

        <div className="bullhorn-container">
          <img src={broadcast.sender_avatar} alt="avatar" className="avatar" data-tooltip-content={broadcast.sender_username}></img>
          <span data-multiline={true} data-tooltip-content={helperObj.tooltip}>
            {helperObj.iconLibrary === 'material' ?
              <i className="material-icons bullhorn" style={{ color: colors.color1[3], cursor: 'default' }}>{helperObj.iconClass}</i> :
              <FontAwesomeIcon icon={['fas', helperObj.iconClass]} className="bullhorn" style={{ color: colors.color1[3], cursor: 'default' }} />
            }
          </span>
          <div className="timestamp" style={{ color: colors.color1[3], pointer: 'default' }}>
            <TimeAgo date={broadcast.created_at} formatter={formatter} title=''/>
          </div>
        </div>
        <div className="text">{broadcast.body}</div>
        <div className="expand" onClick={this.handleExpand} style={{ color: colors.color1[3], display: this.state.isContracted && 'block' }}>{I18n[language].more}</div>
      </div>
    );
  }

  handleExpand = () => {
    this.setState(prevState => ({ isContracted: !prevState.isContracted }));
  }

  historyBroadcastHelper = (recipients) => {
    let tooltip = '';
    let iconClass = 'bullhorn';
    let iconLibrary = 'fas';

    if (recipients === null) {
      tooltip = 'Not available';
    } else {
      switch (recipients[0]) {
        case 'all':
          tooltip = 'All users';
          break;
        case 'participants':
          tooltip = 'All participants';
          iconClass = 'people';
          iconLibrary = 'material';
          break;
        case 'collaborators':
          tooltip = 'All collaborators';
          iconClass = 'bubble_chart';
          iconLibrary = 'material';
          if (recipients[1].length > 0) {
            tooltip += `<br/>${recipients[1].join("<br/> ")}`;
            iconClass = 'bookmarks';
          }
          break;
        case 'null':
          if (recipients[1].length === 1) {
            tooltip = recipients[1];
            iconClass = 'bookmark';
          } else if (recipients[1].length > 1) {
            tooltip = recipients[1].join("<br/> ");
            iconClass = 'bookmarks';
            iconLibrary = 'material';
          }
          break;
      }
    }

    return { tooltip: tooltip, iconClass: iconClass, iconLibrary: iconLibrary }

  }


}
