import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class RSActionBuilder extends React.Component {
  static propTypes = {
    updateShowModalMobile: PropTypes.func.isRequired,
    handleChangeFilters: PropTypes.func.isRequired,
    searchTask: PropTypes.func.isRequired,
    sort_coll: PropTypes.string.isRequired,
    handleIsFilterLock: PropTypes.func.isRequired,
    isFilterLock: PropTypes.bool.isRequired,
    sort_part: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {sort_coll, isFilterLock, sort_part, language, colors} = this.props;
    const i18n = {
      en: {
        search: 'Search', close: 'Close', sortBy: 'Sort by', participantsBy: 'Participants by',
        custom: 'your order', endDate: 'expiry date', startDate: 'available date', creationDate: 'creation date', title: 'title',
        lockTooltip: 'When locked, you and participants see activities in the same order'
      },
      es: {
        search: 'Buscar', close: 'Cerrar', sortBy: 'Ordenar por', participantsBy: 'Participantes por', 
        custom: 'tu orden', endDate: 'expiración', startDate: 'disponibilidad', creationDate: 'fecha de creación', title: 'título',
        lockTooltip: 'Al bloquearlo, tú y tus participantes verán las actividades en el mismo orden'
      }
    }

    return (
      <div className="modal-body px-modal-body-warning">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-drop-search-section">
              <div className="px-input-icon">
                <div className="form-group">
                  <input name="q" type="text" onChange={this.props.handleChangeFilters} className="form-control"
                         placeholder={i18n[language].search}/>
                  <FontAwesomeIcon icon={['fas', 'magnifying-glass']} onClick={this.props.searchTask}
                                   className="px-icon-right color-gray-base"/>
                </div>
              </div>
              <button onClick={this.props.updateShowModalMobile} className="btn px-btn sm btn-blue-base btn-update"
              style={{backgroundColor: colors.color1[3]}}>{i18n[language].close}</button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-drop-filters-container">
              <div className="px-item">
                <span className="label-xs responsive" style={{ color: colors.color1[3] }}>{i18n[language].sortBy}</span>
                <div className="px-select">
                  <select name="sort_coll" onChange={this.props.handleChangeFilters} value={sort_coll}>
                    <option value="custom">{i18n[language].custom}</option>
                    <option value="end date">{i18n[language].endDate}</option>
                    <option value="start date">{i18n[language].startDate}</option>
                    <option value="creation date">{i18n[language].creationDate}</option>
                    <option value="tag">tag</option>
                    <option value="title">{i18n[language].title}</option>
                  </select>
                </div>
              </div>
              <div className="px-item">
                <button onClick={this.props.handleIsFilterLock}
                        className={`mg-left-right-10 btn-lock ${isFilterLock ? 'color-on' : 'color-off'}`} >
                  <span className="arrow-left" />
                  <FontAwesomeIcon icon={['fas', isFilterLock ? 'lock' : 'unlock']} className="chevron"/>
                  <span className="arrow-right" />
                </button>
              </div>
              <div className="px-item">
                <span className="label-xs responsive" style={{ color: colors.color1[3] }}>{i18n[language].participantsBy}</span>
                <div className="px-select">
                  <select name="sort_part" onChange={this.props.handleChangeFilters} value={sort_part}>
                    <option value="custom">{i18n[language].custom}</option>
                    <option value="end date">{i18n[language].endDate}</option>
                    <option value="start date">{i18n[language].startDate}</option>
                    <option value="creation date">{i18n[language].creationDate}</option>
                    <option value="tag">tag</option>
                    <option value="title">{i18n[language].title}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
