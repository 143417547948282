import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";
import moment from 'moment';
import _ from "lodash";

import ModalUserListCard from "../../Responsive/768px/ModalUserListCard";
import MUserProfile from "../../UI/MUserProfile";
import MUSeeNotes from "../../UI/MUSeeNotes";
import {getCountryName, setIconGender, showSuccessMsg} from "../../UI/Globals/PX_Funs";
import {usernamesToDisplay} from "../shared_utils";

export default class UL_User extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    communityId: PropTypes.number.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    communityShowPartList: PropTypes.bool.isRequired,
    updateCommunityUsers: PropTypes.func.isRequired,
    communitySegments: PropTypes.array.isRequired,
    role_1: PropTypes.string.isRequired,
    role_2: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    setFollow: PropTypes.func.isRequired,
    communityState: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    updateOpenPMFromOutside: PropTypes.func.isRequired,
    from: PropTypes.string,
    actionUser: PropTypes.func,
    editUser: PropTypes.func,
    first_activity: PropTypes.object,
    viewProfile: PropTypes.func,
    isSavingFollow: PropTypes.bool,
    userActions: PropTypes.func,
    followUserId: PropTypes.number,
    colors: PropTypes.object.isRequired,
    updateShowBackdrop: PropTypes.func.isRequired,
    showBackdrop: PropTypes.bool.isRequired,
    reloadUserCards: PropTypes.bool.isRequired,
    handleReloadUserCards: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showMobileModal: false,
      showModal: false,
      action: '',
      showModalProfile: false,
      receivedObjs: {counter_comments_replies: 0, counter_likes: 0},
      showSeeNotes: false,
      showInvitationTracking: false,
      invitationTrackingLoading: false,
      invitationTracking: {} 
    }
  }

  componentDidMount() {
    if(this.props.role_1 === 'Participant'){
      // TEST performance in Community.social_flag = true for Participants > User List, maybe for
      // more than 50 Participants it would be better to setup the Infinite
      this._getReceivedObjs();
    }
  }

  componentDidUpdate(prevProps) {
    //console.log(_(prevProps.user.segments).xorWith(this.props.user.segments, _.isEqual).isEmpty());
    if(!_(prevProps.user.segments).xorWith(this.props.user.segments, _.isEqual).isEmpty()){
      //console.log('rebuild for:', this.props.user)
      
    }

    if (this.props.showBackdrop === false && this.state.showInvitationTracking === true) {
      this.setState({ showInvitationTracking: false});
    }

    if (this.props.reloadUserCards === true) {
      this._getInvitationTracking();
      this.props.handleReloadUserCards(false);
    }
  }

  render() {
    const {user, type, from, communitySegments, communitySocial, communityShowPartList, communityState, role_1, role_2,
      language, communityId, first_activity, isSavingFollow, followUserId, colors} = this.props;
    const {receivedObjs} = this.state;
    moment.locale(language);

    const i18n = {
      en: {
        logged: 'Logged', notLogged: 'Not yet logged', lResponse: 'Last Response',
        commentsIn: 'Generated Comments', likesIn: 'Generated Likes', profile: 'Profile',
        seeResponses: 'See responses', sendMsg: 'Send message', sendMail: 'Send email',
        active: 'Active', invited: 'Invited', blocked: 'Blocked', declined: 'Declined',
        sendInvite: 'Invite', resendInvite: 'Resend invitation', edit: 'Edit', remove: 'Remove',
        block: 'Block', unblock: 'Unblock', follow: 'Follow', unfollow: 'Unfollow', lastResponse: 'Last Response',
        seeNotes: 'See Notes', thisIsMe: 'This is me', copyLink: 'Copy invitation link',
        copied: 'Copied', attempt: 'Attempt', invitationAttempts: 'Invitation attempts', 
        invitationTracking: 'Invitation tracking for'
      },
      es: {
        logged: 'Conectado', notLogged: 'Nunca conectado', lResponse: 'Última Respuesta',
        commentsIn: 'Comentarios generados', likesIn: 'Likes generados', profile: 'Perfil',
        seeResponses: 'Ver respuestas', sendMsg: 'Enviar mensaje', sendMail: 'Enviar email',
        active: 'Activo', invited: 'Invitado', blocked: 'Bloqueado', declined: 'Declinó',
        sendInvite: 'Invitar', resendInvite: 'Reenviar invitación', edit: 'Editar', remove: 'Remover',
        block: 'Bloquear', unblock: 'Desbloquear', follow: 'Follow', unfollow: 'Unfollow',
        lastResponse: 'Última Respuesta', seeNotes: 'Ver Notas', thisIsMe: 'Soy yo',
        copyLink: 'Copiar link de invitación', copied: 'Copiado', attempt: 'Intento',
        invitationAttempts: 'Invitaciones enviadas', invitationTracking: 'Detalles de la invitación para'
      }
    };

    const availableUsernames = usernamesToDisplay(user, 'alias', 'all');
    const mainName = availableUsernames[0];
    const alternativeNames = availableUsernames.length > 1 ? availableUsernames.slice(1).join(', ') : ''

    const avatar = user.avatar !== '' ? user.avatar :'/assets/user.png';
    const mailTo = 'mailto:' + user.email;
    const btnFollow = user.is_follow ? 'btn btn-unfollow' : 'btn btn-follow';
    const btnFollowMsg = user.is_follow ? i18n[language].unfollow : i18n[language].follow;

    const follow = user.user_id !== user.current_user_id ?
      <li className="btn-follow-cont">
        <button onClick={() => this.props.setFollow(user)} className={btnFollow}
                disabled={followUserId && followUserId === user.user_id} style={{
                  color: btnFollow === 'btn btn-unfollow' && colors.color1[3],
                  borderColor: btnFollow === 'btn btn-unfollow' && colors.color1[3]}}>
          {
            !isSavingFollow ? btnFollowMsg :
              followUserId && followUserId === user.user_id ? <FontAwesomeIcon icon={['fas','spinner']} spin/> : btnFollowMsg
          }
         <div className="ripple-container"/>
      </button>
      </li> : null;
    const email = user.email && <li><a href={mailTo}  data-tooltip-content={i18n[language].sendMail}>
      <FontAwesomeIcon icon={['fas','envelope']} /></a></li>;
    const send = <li><a onClick={() => this.props.handleOpenModal('send', user)} data-tooltip-content={i18n[language].sendInvite}>
      <FontAwesomeIcon icon={['fas','share']} /></a></li>;
    const reSend = <li><a onClick={() => this.props.handleOpenModal('reSend', user)} data-tooltip-content={i18n[language].resendInvite}>
      <FontAwesomeIcon icon={['fas','repeat']} /></a></li>;
    const edit = <li><a onClick={() => this.props.handleOpenModal('edit', user)} data-tooltip-content={i18n[language].edit}>
      <FontAwesomeIcon icon={['fas','pencil']} /></a></li>;

    /*const edit = <li><a onClick={() => this.updateAction('edit')} data-tooltip-content={i18n[language].edit}>
      <FontAwesomeIcon icon={['fas','pencil']} /></a></li>;*/
    const deleted = <li><a onClick={() => this.props.handleOpenModal('delete', user)} data-tooltip-content={i18n[language].remove}>
      <FontAwesomeIcon icon={['fas','trash']} /></a></li>;
    const privateMessage = user.user_id !== user.current_user_id ?
      <li>
        <a onClick={() => this.props.updateOpenPMFromOutside('PM', user.username, user.user_id)}
           data-tooltip-content={i18n[language].sendMsg}><FontAwesomeIcon icon={['fas','envelope']} /></a>
      </li> : '';
    const block = <li><a onClick={() => this.props.handleOpenModal('block', user)} data-tooltip-content={i18n[language].block}>
      <FontAwesomeIcon icon={['fas','lock']} /></a></li>;
    const unblock = <li><a onClick={() => this.props.handleOpenModal('unblock', user)} data-tooltip-content={i18n[language].unblock}>
      <FontAwesomeIcon icon={['fas','unlock']} /></a></li>;
    const remove = <li><a onClick={() => this.props.handleOpenModal('remove', user)} data-tooltip-content={i18n[language].remove}>
      <FontAwesomeIcon icon={['fas', 'trash']}/></a></li>;
    const profileOption = <li><a onClick={() => this.updateShowModalProfile(user.user_id)}>{i18n[language].profile}</a></li>;

    const seeResponsesOption = <li>
      {
        role_1 === 'Collaborator' ?
          <a onClick={this.forwardSeeResponses}>{i18n[language].seeResponses}</a> :
          <a onClick={() => this.props.userActions('last response', user)} style={{ color: colors.color1[3] }}>
            {i18n[language].lastResponse}</a>
      }
    </li>;
    const sendMessageOption = <li>
      <a onClick={() => this.props.updateOpenPMFromOutside('PM', user.username, user.user_id)}
         data-tooltip-content={i18n[language].sendMsg}>{i18n[language].sendMsg}</a>
    </li>;
    //const sendMessageOption = <li><a href="#">{i18n[language].sendMsg}</a></li>;
    const sendMailOption = this.canSendEmail() ?<li><a href={mailTo}>{i18n[language].sendMail}</a></li> : '';

    let lastResponse = <div className="last-response-container">
      <a onClick={() => this.props.userActions('last response', user)} data-tooltip-content={i18n[language].lastResponse}
         style={{cursor:'pointer'}}>
        <span className="response-text" style={{ color: colors.color1[3] }}>{i18n[language].lastResponse}</span>
      </a>
      <a onClick={() => this.props.userActions('last response', user)} className="last-response-responsive"
         data-tooltip-content={i18n[language].lastResponse} >
        <FontAwesomeIcon icon={['fas', 'book-open']} style={{ color: colors.color1[3] }}/>
      </a>
    </div>;

    const seeNotes = role_1 === 'Collaborator' ?
      <li><a onClick={() => this.updateShowSeeNotes(user.user_id)}>{i18n[language].seeNotes}</a></li> : null;

    const invitationUrl = user.userState === 'Invited' ?
      user.invite_link + '&u_state=' + user.state + '&cid='+ communityId +'&uid=' + user.user_id :
      window.location.protocol + '//' + window.location.host + '/users/sign_in';
    const copyLink = <li><a onClick={() => this.copyToClipboard(invitationUrl, i18n, language)}>{i18n[language].copyLink}</a></li>;

    let collRole = null;
    if(type === 'Collaborator'){
      if(user.role_1 === 'Collaborator') {
        switch (user.role_2) {
          case 'Owner':
            collRole = <div className="role-container Owner"><FontAwesomeIcon icon={['fas','crown']} />
              <span>{user.role_2}</span></div>;
            break;
          case 'Moderator':
            collRole = <div className="role-container Moderator"><FontAwesomeIcon icon={['fas','gavel']} />
              <span>{user.role_2}</span></div>;
            break;
          case 'Observer':
            collRole = <div className="role-container Observer"><FontAwesomeIcon icon={['fas','eye']} />
              <span>{user.role_2}</span></div>;
            break;
          case 'Recruiter':
            collRole = <div className="role-container Recruiter"><FontAwesomeIcon icon={['fas','handshake']} />
              <span>{user.role_2}</span></div>;
            break;
        }
      }
    }

    let userState = user.state === 'Enabled' && user.is_welcome ? 'Active' : user.state;
    if(user.state === 'Enabled' && !user.is_welcome){
      userState = 'Invited'
    } 

    let userStateMobile = user.state === 'Enabled' && user.is_welcome ? 'A' : user.state;
    if(user.state === 'Invited' && !user.is_welcome){
      userStateMobile = 'I'
    }

    switch(userState) {
      case 'Active':
        userState = i18n[language].active;
        break;
      case 'Invited':
        userState = i18n[language].invited;
        break;
      case 'Blocked':
        userState = i18n[language].blocked;
        break;
      case 'Declined':
        userState = i18n[language].declined;
        break;
    }
    let dropdownAction = null;
    let inviteeAction = null;
    let emailAddress = null;
    
    if(user.current_user_id === user.user_id){
      lastResponse = null;
      inviteeAction = <ul className="list-inline last-response-container">
        <span className="response-text" style={{ color: colors.color1[3] }}>{i18n[language].thisIsMe}</span></ul>;
      dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption}</ul>;
      emailAddress = user.email ? <a href={mailTo}>{user.email}</a> : ''
    } else {
      switch(role_2){
        case 'Owner':
          lastResponse = null;
          emailAddress = this.emailToShow();

          switch(user.state){
            case 'Draft':
              inviteeAction = <ul className="list-inline">{ send } { edit } { deleted }</ul>;
              break;
            case 'Invited':
              inviteeAction = <ul className="list-inline">{ email } { reSend } { edit } { deleted }</ul>;
              dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{sendMailOption}{copyLink}</ul>;
              break;
            case 'Enabled':
              if(user.is_welcome){
                if(user.role_1 === 'Participant') {
                    inviteeAction = <ul className="list-inline">{follow}{privateMessage} {edit} {block}{remove}</ul>;
                    dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">
                      {profileOption} {seeResponsesOption} {sendMessageOption} {sendMailOption} {seeNotes}</ul>
                  } else if (user.role_2 === 'Observer' || user.role_2 === 'Recruiter' ) {
                  inviteeAction = <ul className="list-inline">{privateMessage} {edit} {block}{remove}</ul>;
                  dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">
                    {profileOption} {sendMessageOption} {sendMailOption}
                  </ul>
                } else {
                  inviteeAction = <ul className="list-inline">{follow}{privateMessage} {edit} {block}{remove}</ul>;
                  dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption} {sendMessageOption}
                  {sendMailOption} </ul>
                }
              } else {
                inviteeAction = <ul className="list-inline">{ edit }{ block }{ remove }</ul>;
                dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">
                  {sendMessageOption} {sendMailOption}</ul>
              }
              break;
            case 'Blocked':
              inviteeAction = <ul className="list-inline"> { email } { edit } { unblock } { remove }</ul>;
              if (user.role_1 === 'Participant') {
                dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption} {seeResponsesOption}
                {sendMailOption}</ul>
              } else {
                dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption} {sendMailOption}</ul>
              }
              break;
            case 'Declined':
              inviteeAction = <ul className="list-inline">{ deleted }</ul>;
              break;
          }
          break;
        case 'Moderator':
          emailAddress = this.emailToShow();
          lastResponse = null;
          if(user.role_2 === ''){
            switch(user.state){
              case 'Draft':
                inviteeAction = <ul className="list-inline">{ send } { edit } { deleted }</ul>;
                break;
              case 'Invited':
                inviteeAction = <ul className="list-inline">{ email } { reSend } { edit } { deleted }</ul>;
                dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{sendMailOption}{copyLink}</ul>;
                break;
              case 'Enabled':
                if(user.is_welcome){
                  inviteeAction = <ul className="list-inline">{ follow } { privateMessage } { edit } { block }{ remove }</ul>;
                  dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption}
                  {seeResponsesOption} {sendMessageOption} {sendMailOption} {seeNotes}</ul>
                } else {
                  inviteeAction = <ul className="list-inline">{ edit } { block }{ remove }</ul>;
                  dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{sendMessageOption}
                  {sendMailOption}</ul>
                }
                break;
              case 'Blocked':
                inviteeAction = <ul className="list-inline">{email} { edit } { unblock } { remove }</ul>;
                dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption}
                {seeResponsesOption} {sendMailOption}</ul>
                break;
              case 'Declined':
                inviteeAction = <ul className="list-inline">{ deleted }</ul>;
                break;
            }
          }

          if(user.role_2 === 'Moderator') {
            switch(user.state){
              // case 'Draft':
              //   break;
              // case 'Invited':
              //   break;
              case 'Enabled':
                if (user.is_welcome) {
                  inviteeAction = <ul className="list-inline">{follow} {privateMessage} </ul>;
                  dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption}
                  {sendMessageOption} </ul>
                }
                break;
              case 'Blocked':
                dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide"> {profileOption} </ul>;
                break;
              // case 'Declined':
              //   break;
            }
          } 

          if(user.role_2 === 'Observer' || user.role_2 === 'Recruiter'){
            switch(user.state){
              case 'Draft':
                inviteeAction = <ul className="list-inline">{ send } { edit } { deleted }</ul>;
                break;
              case 'Invited':
                inviteeAction = <ul className="list-inline">{email} {reSend} {edit} {deleted}</ul>;
                dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{sendMailOption}{copyLink}</ul>;
                break;
              case 'Enabled':
                if(user.is_welcome){
                  inviteeAction = <ul className="list-inline"> {privateMessage} {edit} {block} {remove}</ul>;
                  dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption}
                  {sendMessageOption} {sendMailOption}</ul>
                } else {
                  inviteeAction = <ul className="list-inline">{ edit } { block } { remove }</ul>;
                  dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{sendMessageOption}
                  {sendMailOption}</ul>
                }
                break;
              case 'Blocked':
                inviteeAction = <ul className="list-inline">{email } { edit } { unblock } { remove }</ul>;
                dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide"> {profileOption}
                {sendMailOption}</ul>;
                break;
              case 'Declined':
                inviteeAction = <ul className="list-inline">{ deleted }</ul>;
                break;
            }
          } else if(user.role_2 === 'Owner'){
            inviteeAction = <ul className="list-inline">{ follow }{ privateMessage }</ul>;
            dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption}
            {sendMessageOption} </ul>
          }
          break;
        case 'Observer':
          lastResponse = null;
          if(user.role_2 !== ''){
            inviteeAction = <ul className="list-inline">{ privateMessage }</ul>;
            dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">
              {profileOption} {sendMessageOption}</ul>
          } else if (user.state === 'Enabled' && user.is_welcome) {
            inviteeAction = <ul className="list-inline">{ follow }</ul>;
            dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">
              {profileOption} {seeResponsesOption} {seeNotes}</ul>
          }
          break;
        case 'Recruiter':
          lastResponse = null;
          if(user.role_2 === '') {
            emailAddress = this.emailToShow();
            switch(user.state){
              case 'Draft':
                inviteeAction = <ul className="list-inline">{ send } { edit } { deleted }</ul>;
                break;
              case 'Invited':
                inviteeAction = <ul className="list-inline">{ email } { reSend } { edit } { deleted }</ul>;
                dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{sendMailOption}{copyLink}</ul>;
                break;
              case 'Enabled':
                if(user.is_welcome){
                  inviteeAction = <ul className="list-inline"> {privateMessage} {edit} </ul>;
                  dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption} {sendMessageOption}
                  {sendMailOption} {seeNotes}</ul>
                }
                else {
                  inviteeAction = <ul className="list-inline">{edit} {remove}</ul>;
                  dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{sendMessageOption} {sendMailOption}</ul>
                }
                break;
              case 'Blocked':
                inviteeAction = <ul className="list-inline">{ email } { edit }</ul>;
                dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide"> {profileOption} {sendMailOption}</ul>;
                break;
              case 'Declined':
                inviteeAction = <ul className="list-inline">{ deleted }</ul>;
                break;
            }
          }
          else {
            if(user.state === 'Enabled' && user.is_welcome) {
              inviteeAction = <ul className="list-inline"> {privateMessage} </ul>;
              dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption} {sendMessageOption}</ul>;
              if(user.role_2 !== 'Observer') {
                emailAddress = user.email ? <a href={mailTo}>{user.email}</a> : ''
              }
            }
          }
          break;
        // PARTICIPANTS
        case '':
          if (user.current_user_id === user.user_id) {
            inviteeAction = <ul className="list-inline last-response-container"><span className="response-text" style={{color: colors.color1[3]}}>{i18n[language].thisIsMe}</span></ul>; 
            dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">{profileOption} {seeResponsesOption}</ul>;
            lastResponse = null;
          } else {
            inviteeAction = <ul className="list-inline"> {lastResponse} {follow} </ul>;
            dropdownAction = <ul className="dropdown-menu dropdown-general-options-no-hide">
              {profileOption} {seeResponsesOption} {seeNotes}</ul>;
          }
          break;
      }
    }

    const regionCountry = user.state_country === '' ? getCountryName(user.country) :
      user.state_country + ', ' + getCountryName(user.country);

    let gender = user.gender !== '' && <div className="px-genders-container">
      <div className="gender-container" data-tooltip-content={user.gender}>{setIconGender(user.gender)}</div></div>;
    let location = (user.country !== '' && user.country) && <div className="px-country-container" data-tooltip-content={regionCountry}>
      <span>{user.country}</span></div>;
    if(from === 'ParticipantUsersList'){
      gender = (user.gender !== '' && user.show_gender) && <div className="px-genders-container" data-tooltip-content={user.gender}>
        <div className="gender-container">{setIconGender(user.gender)}</div></div>;
      location = ((user.country !== '' && user.country) && user.show_location) &&
        <div className="px-country-container" data-tooltip-content={regionCountry}><span>{user.country}</span></div>;
    }

    const segments = user.segments.map(segment => {
      return(
        <FontAwesomeIcon key={segment.value} icon={['fas','bookmark']} className="tag" style={{color:segment.color}}
                         data-tooltip-content={segment.label}/>
      )
    });

    if(!communitySocial && communityShowPartList && from === 'ParticipantUsersList'){
      inviteeAction = null;
      lastResponse = null;
    }

    let responsiveContentInfo = from === 'ParticipantUsersList' ? 'contact-info-container participants' :
      'contact-info-container';
    const connectedTip = user.last_sign_in_at ?
      i18n[language].logged + ' ' + moment(user.last_sign_in_at).fromNow() : i18n[language].notLogged;

    return (
      
      <div className="px-coll-list-user-card">
        <div className="image-container avatar-container text-center dropdown">
          <img src={!communitySocial && communityShowPartList && from === 'ParticipantUsersList' ?
            '/assets/spy_avatar.png' : avatar} alt=""/>
          {
            !communitySocial && communityShowPartList && from === 'ParticipantUsersList' ? null :
              <div className="overlay-avatar" data-toggle="dropdown">
                <FontAwesomeIcon icon={['fas','ellipsis-vertical']}/>
              </div>
          }
          {
            !communitySocial && communityShowPartList && from === 'ParticipantUsersList' ? null :  dropdownAction 
          }
        </div>

        <div className={responsiveContentInfo}>
          <div className="username" data-tooltip-content={user.user_alias !== '' ? user.username : ''}>
            {!communitySocial && communityShowPartList && from === 'ParticipantUsersList' ?
              this._setPseudoRandom(user.user_id) : mainName}
          </div>
          <div className="info-container">
            { collRole }
            { gender }
            { location }
            {
              (type === 'Participant' && from === 'CollaboratorUsersList') &&
              <div className="px-tags-container">
                { segments }
              </div>
            }
          </div>
        </div>

        <div className="contact-info-responsive">
          <div className={ from === 'ParticipantUsersList' ? 'contact-info-container-responsive participants' :
            'contact-info-container-responsive'}>
            <div className="username">
              {!communitySocial && communityShowPartList && from === 'ParticipantUsersList' ?
                this._setPseudoRandom(user.user_id) : mainName}
            </div>
            {
              from === 'CollaboratorUsersList' &&
              <div className={userState.charAt(0) === 'A' ? 'btn status-item active' : 'btn status-item'}
                style={{ backgroundColor: userState.charAt(0) === 'A' && colors.color4[3]}}>
                <span className="txt-normal">{ userState }</span>
                <span className="txt-responsive" data-tooltip-content={userState}>{ userState.charAt(0) }</span>
              </div>
            }
            { lastResponse }
          </div>
  
              <div className="email-container" data-tooltip-content={user.email}>
               { emailAddress }
              </div>
          
          {
            from === 'CollaboratorUsersList' && 
            <div className="info-container">
              { collRole }
              { gender }
              { location }
              {
                (type === 'Participant' && from === 'CollaboratorUsersList') &&
                <div className="px-tags-container">
                  { segments }
                </div>
              }
              <FontAwesomeIcon className="color-gray-base" icon={['fal','square-plus']}
                               onClick={(e) => this.updateShowMobileModal(e, true)}
                               style={{marginLeft:'auto', fontSize:"22px"}}/>
            </div>
          }
          {
            from === 'ParticipantUsersList' &&
            <div className="info-container">
              { gender }
              { location }
            </div>
          }
          {
            from === 'ParticipantUsersList' &&
            <div className="participants-data-responsive">
              <div className="participants-responsive-badge-cont">
                <div className="px-stat-container">
                  <div className="content">
                    <span className="comment-in icon" data-tooltip-content={i18n[language].commentsIn } />
                    <div className="counter">{receivedObjs.counter_comments_replies}</div>
                  </div>
                  <div className="content">
                    <span className="like-in icon" data-tooltip-content={i18n[language].likesIn} />
                    <div className="counter">{receivedObjs.counter_likes}</div>
                  </div>
                </div>
              </div>
              <FontAwesomeIcon className="color-gray-base" icon={['fal','square-plus']}
                               onClick={(e) => this.updateShowMobileModal(e, true)}
                               style={{marginLeft:'auto', fontSize:"22px"}}/>
            </div> 
          }
        </div>

        {
          from === 'ParticipantUsersList' &&
          <div className="participants-data-container">
            <div className="px-stat-container">
              <div className="content">
                <span className="comment-in icon" data-tooltip-content={i18n[language].commentsIn}/>
                <div className="counter">{receivedObjs.counter_comments_replies}</div>
              </div>
              <div className="content">
                <span className="like-in icon" data-tooltip-content={ i18n[language].likesIn}/>
                <div className="counter">{receivedObjs.counter_likes}</div>
              </div>
            </div>
          </div> 
        }
        {
          from === 'CollaboratorUsersList' &&
          <div className="status-general-container">
            <div className="status-container" style={{ cursor: 'pointer' }} onClick={this.handleInvitationTracking}>
              <div className={userState.charAt(0) === 'A' ? 'btn status-item active' : 'btn status-item'}
                style={{ cursor: 'pointer', backgroundColor: userState.charAt(0) === 'A' && colors.color4[3] }} >
                <span>{ userState }</span>
              </div>
              <div className="logged-info" >
                {this.connectionInfoHelper(user, this.state.invitationTracking.current_status)}
                <div className='px-popup' style={{ cursor: 'default', display: this.state.showInvitationTracking && 'block' }}>
                  {this.state.invitationTrackingLoading ? <img src='https://dev-pixiebob.s3-ap-southeast-2.amazonaws.com/loader_infinity_original.gif' />:
                    <span>
                      <div className='title'>
                        {i18n[language].invitationTracking}
                        <div>{mainName}</div>
                      </div>
                      <div className='data'>
                        {i18n[language].invitationAttempts}: {this.state.invitationTracking.attempts}
                        {
                          this.state.invitationTracking.histories &&
                          this.state.invitationTracking.histories.map((item, index) =>
                            <div key={index} className='info-item'>
                              <span className='index' style={{ color: colors.color1[3] }}>{`${i18n[language].attempt} ${item.attempt} -  `}</span><span className='main'>{`${this.invitationTrackinglabelHelper(item.status)} ${this.invitationTrackingDateHelper(item)}`}</span>
                              <div className='description' style={{ color: colors.color4[3] }}>{item.description}</div>
                            </div>
                          )
                        }
                      </div>
                    </span>
                  }
                </div>
              </div>
            </div>
            <div className="email-container" data-tooltip-content={user.email}>
              { emailAddress }
            </div>
          </div>
        }
        {
          from === 'CollaboratorUsersList' ?
            <div className="actions-container">
              {communityState !== 'Closed' && inviteeAction}
            </div>:
            <div className="actions-container">
              {communityState !== 'Closed' && inviteeAction}
            </div>
        }
        <FontAwesomeIcon className="color-gray-base px-icon-md-modal-responsive" icon={['fal','square-plus']}
                         onClick={(e) => this.updateShowMobileModal(e, true)}/>
        {
          this.state.showMobileModal &&
          <ReactModal isOpen={this.state.showMobileModal} contentLabel="Mobile Modal"
                      shouldCloseOnOverlayClick={true}
                      onRequestClose={(e) => this.updateShowMobileModal(e,false)}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <ModalUserListCard updateShowMobileModal={this.updateShowMobileModal}
                               user={user}
                               setFollow={this.props.setFollow}
                               handleOpenModal={this.props.handleOpenModal}
                               role_1={role_1}
                               role_2={role_2}
                               getPM={this.getPM}
                               language={language}
                               updateShowModalProfile={this.updateShowModalProfile}
                               isSavingFollow={this.props.isSavingFollow}
                               userActions={this.props.userActions}
                               modalShowPM={this.modalShowPM}
                               colors={colors}
                               canSendEmail={this.canSendEmail}
                               communityId={communityId}
            />
          </ReactModal>
        }
        {
          this.state.showModalProfile &&
          <MUserProfile showModal={this.state.showModalProfile}
                        updateShowModal={this.updateShowModalProfile}
                        avatar={user.avatar}
                        username={user.username}
                        gender={user.gender}
                        country={user.country}
                        regionCountry={user.state_country}
                        email={user.email}
                        user={{...user, id: user.user_id}}
                        role_1={role_1}
                        role_2={role_2}
                        userActions={this.props.userActions}
                        modalShowPM={this.modalShowPM}
                        current_user_id={user.current_user_id}
                        language={language}
                        communityId={communityId}
                        colors={colors}/>
        }
        {
          this.state.showSeeNotes &&
            <MUSeeNotes showSeeNotes={this.state.showSeeNotes}
                        updateShowSeeNotes={this.updateShowSeeNotes}
                        user={{...user, id: user.user_id}}
                        language={language}
                        colors={colors}/>
        }
      </div>
    );
  }

  forwardSeeResponses = () => {
    const {communityId, user} = this.props;
    window.location.href = '/communities/'+ communityId +'/transcript/'+ user.user_id +'/all/all/ActAndParCol';
  };

  connectionInfoHelper = (user, invitationStatus) => {
    const language = this.props.language;
    const colors = this.props.colors;
    moment.locale(this.props.language);
    const i18n = {
      en: { loggedNoTAccepted: 'Not accepted yet', loggedNoTAcceptedTooltip: 'User has signed-up but hasn\'t accepted to participate yet.',
        accepted: 'Accepted to join', lastSignIn: 'Last sign in', sent: 'Sent', delivered: 'Delivered', bounced: 'Bounced',
        spam: 'Spam', opened: 'Seen', clicked: 'Clicked link', notAvailable: 'Info not available', notAvailableTooltip: 'Details about the invitation of this user are not available.',
        clickForMore: 'Click for details.',
        bouncedTooltip: 'The invitation couldn\'t be delivered.', deliveredTooltip: 'The invitation has been delivered.', openedTooltip: 'The invitation has been seen by the user.',
        clickedTooltip: 'The user clicked the link in the invitation.', sentTooltip: 'The invitation has been sent.',
        draftTooltip: 'The user hasn\'t been invited yet'
      },
      es: { loggedNoTAccepted: 'No ha aceptado aún', loggedNoTAcceptedTooltip: 'El usuario se ha registrado en la aplicación, pero no ha aceptado participar aún.',
        accepted: 'Aceptó participar', lastSignIn: 'Última conexión', sent: 'Enviada', delivered: 'Entregada', bounced: 'Rechazada',
        spam: 'No deseado', opened: 'Vista', clicked: 'Hizo clic en liga', notAvailable: 'Info no disponible', notAvailableTooltip: 'Los detalles sobre la invitación de este usuario no están disponibles.',
        clickForMore: 'Click para más detalles.',
        bouncedTooltip: 'La invitación no pudo ser entregada.', deliveredTooltip: 'La invitación ha sido entregada.', openedTooltip: 'La invitación ha sido vista por el usuario.',
        clickedTooltip: 'El usuario ha hecho clic en la liga de la invitación.', sentTooltip: 'La invitación ha sido enviada.',
        draftTooltip: 'El usuario no ha sido invitado aún.'
      }
    }
    let label = null;
    let tooltip = null;
    let iconClassName = null;
    let iconColor = '#AFBDC1';
    let icon = null;
    let iStatus = null;

    if (user.last_sign_in_at) {
      if (user.is_welcome === false) {
        // iconClassName = 'triangle-exclamation';
        // iconColor = colors.color4[3];
        // tooltip = `${i18n[language].loggedNoTAcceptedTooltip} ${i18n[language].clickForMore}`;
        // icon = <FontAwesomeIcon icon={['far', iconClassName]} style={{ color: iconColor, fontSize: '12px' }} />;

        // Above commented lines used to render a warning info message when the user was logged in
        // but hadn't accepted ToS in Welcome. This caused confusions with users that had participated
        // in previous communities. From now, we won't show that warning anymore and instead the standard indicator below:

        iconClassName = 'circle-info';
        tooltip = `${i18n[language].lastSignIn} ${moment(user.last_sign_in_at).fromNow()}. ${i18n[language].clickForMore}`;
        icon = <FontAwesomeIcon icon={['far', iconClassName]} style={{ color: iconColor, fontSize: '12px' }} />;

      }
      else {
        iconClassName = 'circle-info';
        tooltip = `${i18n[language].lastSignIn} ${moment(user.last_sign_in_at).fromNow()}. ${i18n[language].clickForMore}`;
        icon = <FontAwesomeIcon icon={['far', iconClassName]} style={{ color: iconColor, fontSize: '12px' }} />; 
      }
    } else {
      if (user.state === 'Draft') {
        iStatus = 'Draft';
      } else if (invitationStatus) {
        iStatus = invitationStatus;
      } else {
        iStatus = user.invitationStatus;
      }

      switch (iStatus) {
        case "Processed":
          iconClassName = 'check';
          tooltip = `${i18n[language].sentTooltip} ${i18n[language].clickForMore}`;
          icon = <FontAwesomeIcon icon={['far', iconClassName]} style={{ color: iconColor, fontSize: '12px' }} />; 
          break;
        case "Bounce":
        case "SpamComplaint":
          iconClassName = 'xmark';
          iconColor = colors.color4[3];
          tooltip = `${i18n[language].bouncedTooltip} ${i18n[language].clickForMore}`;
          icon = <FontAwesomeIcon icon={['far', iconClassName]} style={{ color: iconColor, fontSize: '12px' }} />; 
          break;
        case "Delivery":
          iconClassName = 'check';
          tooltip = `${i18n[language].deliveredTooltip} ${i18n[language].clickForMore}`;
          icon = <FontAwesomeIcon icon={['far', iconClassName]} style={{ color: iconColor, fontSize: '12px' }} />;
          label = <span>{icon}</span>;
          break;
        case "Open":
          iconClassName = 'check';
          iconColor = colors.colorStates[1];
          tooltip = `${i18n[language].openedTooltip} ${i18n[language].clickForMore}`;
          icon = <FontAwesomeIcon icon={['far', iconClassName]} style={{ color: iconColor, fontSize: '12px' }} />;
          label = <span>{icon}</span>
          break;
        case "Click":
          iconClassName = 'check';
          iconColor = colors.colorStates[1];
          tooltip = `${i18n[language].clickedTooltip} ${i18n[language].clickForMore}`;
          icon = <FontAwesomeIcon icon={['far', iconClassName]} style={{ color: iconColor, fontSize: '12px' }} />;
          label = <span>{icon}</span>
          break;
        case "Draft":
          iconClassName = 'circle-info';
          tooltip = `${i18n[language].draftTooltip}`;
          icon = <FontAwesomeIcon icon={['far', iconClassName]} style={{ color: iconColor, fontSize: '12px' }} />; 
          break;
        default:
          iconClassName = 'circle-info';
          tooltip = i18n[language].notAvailableTooltip;
          icon = <FontAwesomeIcon icon={['far', iconClassName]} style={{ color: iconColor, fontSize: '12px' }} />; 
      }
  
    }
    return (
      <div style={{ pointer: 'default' }} data-tooltip-content={tooltip}>{icon}{label}</div>
    )
  }

  invitationTrackinglabelHelper = (invitationStatus) => {
    const language = this.props.language;
    let label = null;
    const i18n = {
      en: {
        sent: 'Sent', delivered: 'Delivered', bounced: 'Bounced', spam: 'Spam', opened: 'Seen', clicked: 'Clicked link',
        notAvailable: 'Info not available'
      },
      es: {
        sent: 'Enviada', delivered: 'Entregada', bounced: 'Rechazada', spam: 'No deseado', opened: 'Vista', clicked: 'Hizo clic en liga', 
        notAvailable: 'Info no disponible'
      }
    }
    switch (invitationStatus) {
      case "Processed":
        label = i18n[language].sent;
        break;
      case "Bounce":
        label = i18n[language].bounced;
        break;
      case "SpamComplaint":
        label = i18n[language].spam;
        break;
      case "Delivery":
        label = i18n[language].delivered;
        break;
      case "Open":
        label = i18n[language].opened;
        break;
      case "Click":
        label = i18n[language].clicked;
        break;
      default:
        label = i18n[language].notAvailable;
    }
    return label;
  }

  invitationTrackingDateHelper = (item) => {
    moment.locale(this.props.language);
    let timestamp = null;

    switch(item.status) {
      case "Processed":
        timestamp = moment(item.processed_at).fromNow(); 
        break;
      case "Bounce":
        timestamp = moment(item.bounced_at).fromNow();
        break;
      case "SpamComplaint":
        timestamp = moment(item.bounced_at).fromNow();
        break;
      case "Delivery":
        timestamp = moment(item.delivered_at).fromNow();
        break;
      case "Open":
        timestamp = moment(item.received_at).fromNow();
        break;
      case "Click":
        timestamp = moment(item.clicked_at).fromNow();
        break;
    }
    return timestamp;
  }

  handleInvitationTracking = () => {
    this.setState({showInvitationTracking: true});
    this.props.updateShowBackdrop();
    this.setState({ invitationTrackingLoading: true});
    this._getInvitationTracking();
  }

  _getInvitationTracking = () => {
    $.ajax({
      url: '/email_history/' + this.props.user.cu_id,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: (invitationTracking) => {
        this.setState({ invitationTracking });
        this.setState({ invitationTrackingLoading: false });
      }
    });
  }

  copyToClipboard = (url, i18n, language) => {
    // console.log(url);
    navigator.clipboard.writeText(url);
    showSuccessMsg(i18n[language].copied);
  };

  modalShowPM = (type, username, user_id) => {
    this.setState({showMobileModal: false, showModalProfile: false}, () => {
      this.props.updateOpenPMFromOutside(type, username, user_id);
    })
  };

  updateShowSeeNotes = () => {
    this.setState(prevState => ({showSeeNotes : !prevState.showSeeNotes}));
  }

  lastReply = userId => {
    $.ajax({
      url: '/responses/get_last_response/' + this.props.communityId + '/' + userId,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: (response) => {
        // console.log(response);
        if(response){
          window.location.href = '/activities/'+ response.community_id + '/activity/'+ response.activity_id +'/social/' +
            'response/'+ response.id +'/act_user/'+ response.user_id +'/scroll/'+ response.task_id +'/-1';
        } else {

        }
      }
    });
  };

  updateShowModalProfile = user_id => {
    // console.log(user_id);
    this.setState(state => ({showModalProfile: !state.showModalProfile}), () => {
      if(user_id){
        this.props.viewProfile(user_id);
      }
    });
  };

  updateAction = action => {
    this.setState({action}, () => {
      this.setState({showModal: true});
    });
  };

  handleCloseModal = () => {
    this.setState({showModal: false});
  };


  updateShowMobileModal = (e, showMobileModal) => {
    e.preventDefault();
    this.setState({ showMobileModal });
  };

  _setPseudoRandom(userId){
    return (userId * 9301 + 49297) % 233280;
  }

  _getReceivedObjs = () => {
    $.ajax({
      url: '/users/get_received_objs/' + this.props.user.user_id + '/' + this.props.communityId,
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: receivedObjs =>  {
        //console.log(receivedObjs);
        this.setState({receivedObjs});
      }
    });
  };

  canSendEmail = () => {
    const { user, role_2: viewerRole2 } = this.props;
    const { role_1: userListedRole1, email: userListedEmail, cu_invitation_method } = user;

    if (userListedRole1 === 'Participant') {
      if (viewerRole2 === 'Observer' || viewerRole2 === '') {
        return false;
      }
      return userListedEmail && cu_invitation_method !== 'generic_link';
    }

    return true;
  }

  emailToShow() {
    const language = this.props.language
    const i18n = {
      en: {joinedByLink: 'Invited via generic link', joinedByEmail: 'Invited via email'},
      es: {joinedByLink: 'Invitado por link genérico', joinedByEmail: 'Invitado por email'}
    }
    const userListed = this.props.user;
    const userListedRole1 = userListed.role_1;
    const userListedRole2 = userListed.role_2;
    const userListedEmail = userListed.email;
    const userListedEmailLinkElement =
      <a href={'mailto:' + userListedEmail}>{userListedEmail}</a>
    const userJoinedByLinkElement = <span style={{color: '#7c8593', fontSize: '11px', overflow: 'hidden',
      textOverflow: "ellipsis", lineHeight: '14px', height: '14px'}}>
              {i18n[language].joinedByLink}
          </span>
    const userJoinedByEmailElement = <span style={{color: '#7c8593', fontSize: '11px', overflow: 'hidden',
      textOverflow: "ellipsis", lineHeight: '14px', height: '14px'}}>
              {i18n[language].joinedByEmail}
          </span>
    const userListedJoinedByLink = userListed.cu_invitation_method === 'generic_link'
    const viewerRole2 = this.props.role_2;

    if (userListedRole1 === 'Participant') {
      if (viewerRole2 !== 'Observer' || viewerRole2 !== '') {
        if (userListedEmail && !userListedJoinedByLink) {
          return userListedEmailLinkElement;
        } else if (userListedJoinedByLink) {
          return userJoinedByLinkElement;
        } else return userJoinedByEmailElement;
      } else {
        return ''
      }
    } else if (userListedRole2 === 'Observer') {
      if (viewerRole2 === 'Recruiter' || viewerRole2 === '' ) {
       return ''
      } else return userListedEmailLinkElement;

    } else return userListedEmailLinkElement;
  }


}
