import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dotdotdot from "react-dotdotdot";

export default class CSMArchiveCMTY extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    updateShowModalArchive: PropTypes.func.isRequired,
    archiveCommunity: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      isCheck01: false,
      isBtnSave: true
    }
  }

  render() {
    const {title, language, colors} = this.props;
    const i18n = {
      en: {
          archive: 'Archive', 
          archiveInfo: 'All data gathered in this community will be preserved but won’\t be accessible while the community is archived.',
          type: 'Type "', toConfirm: '" here to confirm', yes: 'Yes'
        },
      es: {
        archive: 'Archivar',
        archiveInfo: 'La información recolectada en esta comunidad se preservará, pero ningún usuario podrá acceder a ésta mientras la comunidad permanezca archivada.',
        type: 'Escribe "', toConfirm: '" aquí para confirmar', yes: 'Sí'
      }
    }

    return (
      <div className="my-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal"
                      onClick={this.props.updateShowModalArchive}>
                <FontAwesomeIcon icon={['fal', 'xmark']} style={{fontSize: '16px'}}/>
              </button>
            </div>
            <div className="modal-body px-modal-body-warning">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-title-warning-container">
                    <FontAwesomeIcon icon={['fal','triangle-exclamation']} className="px-icon-warning" style={{color: colors.color4[3]}}/>
                    <p className="px-title-warning">
                      {i18n[language].archive}&nbsp;<b className="warning" style={{color: colors.color4[3]}}>{title}</b>?
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xs-offset-1 col-sm-offset-1 col-md-offset-1 col-lg-offset-1"
                     style={{padding:'0'}}>
                  <div className="px-check-cont px-checkbox-modal-warning">
                    <label className="container-check">
                      <input name="isCheck01" type="checkbox" onChange={this.handleInputChange}
                             value={this.state.isCheck01}/>
                      <span className="checkmark"/>
                      <span>{i18n[language].archiveInfo}</span>
                    </label>
                  </div>
                  <input name="title" onChange={this.handleInputChange} type="text" className="form-control px-input-confirmation"
                    placeholder={i18n[language].type + title + i18n[language].toConfirm}/>
                </div>
              </div>
            </div>
            <div className="modal-footer px-modal-footer-warning">
              <div className="px-footer-container-buttons column">
                <button onClick={this.validArchiveCommunity} type="button" className="btn px-btn sm btn-blue-base"
                        disabled={this.state.isBtnSave} style={{color: colors.color1[3]}}>{i18n[language].yes}</button>
                <button onClick={this.props.updateShowModalArchive} type="button"
                        className="btn px-btn sm btn-gray-darker inverted">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleInputChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const name = event.target.name;

    this.setState({[name]: value}, () => {
      this.setState({isBtnSave: !(this.props.title === this.state.title && this.state.isCheck01)});
    });
  };

  validArchiveCommunity = () => {
    if(this.props.title === this.state.title && this.state.isCheck01){
      this.props.archiveCommunity(this.props.communityId);
    }
  }
}
