import PropTypes from 'prop-types';
import React from 'react';
import Slider from "react-slick/lib";
import Dotdotdot from "react-dotdotdot";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Truncate from 'react-truncate';

import TF_Participants from "./TF_Participants";

export default class T_Filters extends React.Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    tasks_filter: PropTypes.array.isRequired,
    showFilters: PropTypes.bool.isRequired,
    showFiltersHandler: PropTypes.func.isRequired,
    handleFilterUsers: PropTypes.func.isRequired,
    selectAllUsers: PropTypes.bool.isRequired,
    handleAllUsers: PropTypes.func.isRequired,
    setNewActiveUser: PropTypes.func.isRequired,
    toggleActivity: PropTypes.func.isRequired,
    toggleTask: PropTypes.func.isRequired,
    handleHideTabFilters: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    currentUserId: PropTypes.number.isRequired,
    objSearch: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    $(".normal-filters").addClass("animated fadeIn");
  }

  render() {
    const {users, tasks_filter, currentUserId, objSearch, colors} = this.props;

    const currentUser = users.find(user => user.user_id === currentUserId);
    //console.log(currentUser);

    let activeActivities = [];
    //console.log(tasks_filter);
    let activities = [];
    if(currentUser !== undefined){
      activities = tasks_filter.map(task => {
        const segsFound = [];
        //console.log(task);
        if(task.segments.length === 0){
          //console.log(task.title, 'no segments, show everywhere');
          segsFound.push(true);
        } else {
          //console.log(task.title, 'Have Segments');
          task.segments.forEach(ts => {
            if(currentUser.segments.length === 0){
              segsFound.push(false);
            } else {
              segsFound.push(currentUser.segments.find(us => us.id === ts.id) !== undefined);
            }
          });
        }

        const trueValidation = segsFound.filter(sv => sv);

        if(trueValidation.length !== 0){
          if(task.active){
            activeActivities.push(task);
          }

          let taskTitleForTooltip = task.title.length > 140 ? task.title.substring(0, 140) + "..." :
            task.title.substring(0, 140);

          return (
            <div key={task.id}>
              <div onClick={() => this.props.toggleActivity(task.id)}
                   style={{backgroundColor: task.active ? colors.color0[3] : 'white', color: task.active ?
                       'white' : '#7C8593', border: task.active ? 'none' : '0.59px solid #DADDE1',width:'200px'}}
                   data-tooltip-content={taskTitleForTooltip} className="item">
                <Dotdotdot clamp={1} className="text">{task.title}</Dotdotdot>
              </div>
            </div>
          )
        }
      });
    }

    const selectedUsers = users.filter(user => user.active);
    //console.log(selectedUsers);
    const user = users.find(user => user.user_id === objSearch.idPars);
    //console.log(user);
    const btnUserArrowLeft = <FontAwesomeIcon onClick={() => this.props.setNewActiveUser('left')} icon={['fas','caret-left']}
                                        className="cursor left" />;
    const btnUserArrowRight = <FontAwesomeIcon onClick={() => this.props.setNewActiveUser('right')} icon={['fas','caret-right']}
                                         className="cursor right"/>;
    const imgUserAvatar = <img src={user.avatar !== '' ? user.avatar : '/assets/user.png'} className="px-thumb-45 img-circle" alt=""/>;

    const sliderSettingsActivities = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: tasks_filter.length >= 3 ? 3 : tasks_filter.length,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: tasks_filter.length >= 3 ? 3 : tasks_filter.length,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="row px-nav-transcript-container">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{padding:'0'}}>
          <div className="px-nav-transcript collapse in" id={"demo01"}>
            <div className="px-nav-trans-body">
              
                <div className="card-profile">
                  <div className="users-container" style={{padding: selectedUsers.length !== 1 && '0 15px'}}>
                    { btnUserArrowLeft }
                    { imgUserAvatar }
                    { btnUserArrowRight }
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'column',width:'90%'}}>
                  <div style={{position:'relative'}}>
                    <Slider {...sliderSettingsActivities} className="task-container">
                      { activities }
                    </Slider>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function SampleNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', marginLeft:'15px', borderRadius:'50%'}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: 'block', marginRight:'15px', zIndex:'1' , borderRadius:'50%'}}
      onClick={onClick}
    />
  );
}
