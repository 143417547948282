import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { SwatchesPicker } from 'react-color';

export default class ColorPicker extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    color: PropTypes.string.isRequired,
    updateColor: PropTypes.func.isRequired,
    icon: PropTypes.string,
    colorSet: PropTypes.array,
    colorSetIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    }
  }

  render(){
    const {id, color, icon, colorSet, colorSetIndex} = this.props;

    const popover = {position: 'absolute', zIndex: '2', top: '0px', right: '0px'};
    const cover = {position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'};

    return(
      <React.Fragment>
        <FontAwesomeIcon onClick={this.handleShowModal} icon={['fas', icon]} className="tag-icon"
                         style={{color: color, cursor: 'pointer'}}/>
        {
          this.state.showModal &&
          <div style={ popover }>
            <div style={ cover } onClick={this.handleShowModal}/>
            <SwatchesPicker color={color}
                          onChange={(color) => this.props.updateColor(id, color.hex, colorSet, colorSetIndex)}
                          disableAlpha={true} width={220}/>
          </div>
        }
      </React.Fragment>
    )
  }

  handleShowModal = () => {
    this.setState(prevState => ({showModal: !prevState.showModal}));
  }
}