import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import ChatPanel from "./ChMainPanel/ChatPanel"
import ListPanel from "./ChMainPanel/ListPanel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showSuccessMsg} from "../../UI/Globals/PX_Funs";
import { Tooltip as ReactTooltip } from "react-tooltip"

const ChMainPanel = ({ messages, currentUserId, language, avatar, colors,
                       lastChatId, setLastChatId, getResponseEmbeddings, setQuery, query,
                       setIsChatApi, isChatApi, getAnswer, timerOn, activeChatRoomId, btnMsg,
                       setTemperature, setPanel, panel, responseEmbeddings, setAiModel,
                       ai_max_level, loadingResponseEmbeddings }) => {

  const [aiLevelsShow, setAiLevelsShow] = useState(false);
  const [currentAiLevel, setCurrentAiLevel] = useState('level2');
  const [activeOptionsMenu, setActiveOptionsMenu] = useState(-1);
  const [activeChildOptionsMenu, setActiveChildOptionsMenu] = useState(-1);

  const aiLevelsRef = useRef(null);
  const spanRef = useRef(null);

  useEffect(() => {
    if (aiLevelsShow) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [aiLevelsShow]);

  const I18n = {
    en: {
      msgPlaceholder: 'What can I do for you?...',
      msgPlaceholderTimerOn: 'Please wait...',
      noAiChatPlaceholder: 'Select a Chat Room before submitting your request',
      noAiChatWarning: 'Select or Create a Chat Room on your left',
      cantSendMessage: 'Cannot submit query right now',
      ai_level_na: 'This AI is not available, please contact support',
      backToChat: 'to chat'
    },
    es: {
      msgPlaceholder: 'Qué puedo hacer por ti?...',
      msgPlaceholderTimerOn: 'Por favor espera...',
      noAiChatPlaceholder: 'Selecciona un Chat antes de enviar tu solicitud',
      noAiChatWarning: 'Seleccione o cree una sala de chat a su izquierda',
      cantSendMessage: 'No se puede enviar la solicitud en este momento',
      ai_level_na: 'Esta AI no está disponible, por favor contacte a soporte técnico',
      backToChat: 'al chat'
    }
  }

  const AiLevelOnClickHandler = (level) => {
    setChatApi(level);
    setCurrentAiLevel(level);
    hideAiLevels();
  };

  const setChatApi = (level) => {
    if (level === 'level1') {
      setAiModel('text-davinci-003')
      setIsChatApi(false)
    } else if (level === 'level2') {
      setAiModel('gpt-4o-mini')
      setIsChatApi(false)
    } else if (level === 'level3') {
      // when level3 is available add code here
      setAiModel('gpt-4o')
    } else {
      setIsChatApi(false)
    }
  };

  const hideAiLevels = () => {
    setAiLevelsShow(false);
  };

  const handleClickOutside = (event) => {
    if (
        aiLevelsRef.current &&
        !aiLevelsRef.current.contains(event.target) &&
        spanRef.current &&
        !spanRef.current.contains(event.target)
    ) {
      setAiLevelsShow(false);
    }
  };

  const conversationContainerRef = useRef(null);

  useEffect(() => {
    const container = conversationContainerRef.current;

    if (container) {
      container.addEventListener("scroll", handleConversationScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleConversationScroll);
      }
    };
  }, []);

  const handleConversationScroll = () => {
    setActiveOptionsMenu(-1);
  };
  let textAreaPlaceHolder = null;
  if (activeChatRoomId) {
    if (timerOn) {
      textAreaPlaceHolder = I18n[language].msgPlaceholderTimerOn
    } else {
      textAreaPlaceHolder = I18n[language].msgPlaceholder
    }
  } else {
    textAreaPlaceHolder = I18n[language].noAiChatPlaceholder
  }

  const handleKeyDown = (event) => {
    const enterPressed = event.key === 'Enter';
    const ctrlOrCmdPressed = event.ctrlKey || event.metaKey;
    const shiftPressed = event.shiftKey;
    const altPressed = event.altKey;

    if (enterPressed && (ctrlOrCmdPressed || shiftPressed || altPressed)) {
      event.preventDefault();
      setQuery(query + '\n');
    } else if (enterPressed && !ctrlOrCmdPressed && !shiftPressed && !altPressed) {
      event.preventDefault(); // Prevent line breaks when Enter is pressed
      if (!timerOn && activeChatRoomId) {
        getAnswer();
      } else if (timerOn) {
        showSuccessMsg(I18n[language].msgPlaceholderTimerOn);
      } else {
        showSuccessMsg(I18n[language].noAiChatPlaceholder);
      }
    }
  };

  const parseTemperature = value => {
    const temp = parseFloat(value)
    setTemperature(isNaN(temp) ? 0 : temp)
  }

  return (
    <div className="ai-conversations-col" style={{height:'100%', width: '100%'}}>
      <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
      <div className="px-notifications-drawer px-messages-drawer right-drawer-open drawer-ai">
        <div className="messages-container" style={{height:'100%'}}>
          <div ref={conversationContainerRef}
               className="messages-principal-container conversation ai-conversation"
               style={{height: panel === 'list' && '100%', paddingBottom: '60px'}}
               onScroll={handleConversationScroll}
          >
            {!activeChatRoomId && (
                <div
                    style={{
                      position: 'absolute',
                      top: '30%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      textAlign: 'center',
                      zIndex: 2,
                    }}
                >
                  <div className='ai-error-show' style={{color: colors['color4'][2]}}>
                    <span className='icon'><FontAwesomeIcon icon={['fas', 'circle-arrow-left']} /></span>
                    <span className='text'>{I18n[language].noAiChatWarning}</span>
                  </div>
                </div>
            )}

            {
              panel === 'chat' ?
                <ChatPanel getAnswer={getAnswer}
                           setQuery={setQuery}
                           btnMsg={btnMsg}
                           avatar={avatar}
                           lastChatId={lastChatId}
                           getResponseEmbeddings={getResponseEmbeddings}
                           setLastChatId={setLastChatId}
                           colors={colors}
                           messages={messages}
                           setTemperature={setTemperature}
                           language={language}
                           isChatApi={isChatApi}
                           setIsChatApi={setIsChatApi}
                           currentUserId={currentUserId}
                           timerOn={timerOn}
                           query={query}
                           activeChatRoomId={activeChatRoomId}
                           setPanel={setPanel}
                           from={'wonder'}
                           loadingResponseEmbeddings={loadingResponseEmbeddings}
                /> :
                <ListPanel responseEmbeddings={responseEmbeddings}
                           colors={colors}
                           language={language}
                           onScroll={handleConversationScroll}
                           activeOptionsMenu={activeOptionsMenu}
                           setActiveOptionsMenu={setActiveOptionsMenu}
                           activeChildOptionsMenu={activeChildOptionsMenu}
                           setActiveChildOptionsMenu={setActiveChildOptionsMenu}
                           setPanel={setPanel}
                />
            }
            <div className="message-container"
                 style={{width:'95%',
                   position: 'absolute',
                   bottom: '0',
                   display: !activeChatRoomId || panel === 'list' ? 'none' : 'block',
                   background: panel === 'list' && 'transparent'
            }}

            >
              <div className='ai-chat-tab-container'>
                {/*<span className='ai-chat-tab'*/}
                {/*      style={{color: panel === 'chat' ? colors.color2[3] : '#b1b1b1'}}*/}
                {/*      onClick={() => setPanel('chat')}>*/}
                {/*  <FontAwesomeIcon icon={['fas','arrow-left']} style={{marginRight: '10px' }}/>{I18n[language].backToChat}*/}
                {/*</span>*/}
                {/*<span className='ai-chat-tab' style={{color: panel === 'list' ? colors.color2[3] : '#b1b1b1'}}*/}
                {/*      onClick={() => setPanel('list')}>*/}
                {/*  <FontAwesomeIcon icon={['fas','magnifying-glass']} style={{marginRight: '10px'}}/>SEARCH*/}
                {/*</span>*/}
              </div>
              <div className="form-group" style={{display: panel === 'list' ? 'none' : 'block'}}>
                <textarea onChange={e => setQuery(e.target.value)}
                          onKeyDown={handleKeyDown}
                          value={query}
                          name="body"
                          className="message-textarea"
                          placeholder={textAreaPlaceHolder}
                />
                <div className="button-send-container" style={{marginTop: '5px', gap: '15px' }}>
                  <span className='ai-levels' onClick={() => setAiLevelsShow(!aiLevelsShow)} ref={spanRef}>
                    <FontAwesomeIcon
                        icon={['fas', 'bolt']}
                        style={{
                          display: currentAiLevel === 'level1' || currentAiLevel === 'level2' || currentAiLevel === 'level3' ? 'inline-block' : 'none',
                          color: colors.color2[3],
                          marginRight: '2px'
                        }}
                    />
                    <FontAwesomeIcon
                        icon={['fas', 'bolt']}
                        style={{
                          display: currentAiLevel === 'level2' || currentAiLevel === 'level3' ? 'inline-block' : 'none',
                          color: colors.color2[3],
                          marginRight: '2px'
                        }}
                    />
                    <FontAwesomeIcon
                        icon={['fas', 'bolt']}
                        style={{
                          display: currentAiLevel === 'level3' ? 'inline-block' : 'none',
                          color: colors.color2[3],
                          marginRight: '2px'
                        }}
                    />
                    <ul className='ai-dropdown-menu' style={{display: aiLevelsShow ? 'block' : 'none' }} ref={aiLevelsRef}>
                      <li onClick={() => AiLevelOnClickHandler('level1')}>
                        <div style={{color: currentAiLevel === 'level1' && colors.color2[3] }}>
                          <span>
                            <FontAwesomeIcon icon={['fas','bolt']} />
                          </span><span>POWER</span>
                        </div>
                      </li>
                      <li onClick={() => AiLevelOnClickHandler('level2')}>
                        <div style={{color: currentAiLevel === 'level2' && colors.color2[3] }}>
                          <span>
                            <FontAwesomeIcon icon={['fas','bolt']} /><FontAwesomeIcon icon={['fas','bolt']} />
                          </span><span>SUPER POWER</span>
                        </div>
                      </li>
                      { ai_max_level === 'level3' ?
                      <li onClick={() => AiLevelOnClickHandler('level3')}>
                        <div style={{color: currentAiLevel === 'level3' && colors.color2[3], cursor: 'not-allowed'}}>
                          <span>
                            <FontAwesomeIcon icon={['fas', 'bolt']} />
                            <FontAwesomeIcon icon={['fas', 'bolt']} />
                            <FontAwesomeIcon icon={['fas', 'bolt']} />
                          </span>
                          <span>NITRO</span>
                        </div>
                      </li> :
                      <li data-tooltip-content={I18n[language].ai_level_na}>
                        <div style={{opacity: 0.3, cursor: 'not-allowed'}}>
                      <span>
                        <FontAwesomeIcon icon={['fas', 'bolt']} />
                        <FontAwesomeIcon icon={['fas', 'bolt']} />
                        <FontAwesomeIcon icon={['fas', 'bolt']} />
                      </span>
                          <span>NITRO</span>
                        </div>
                      </li>
                      }
                    </ul>
                  </span>
                  <span style={{color: '#AFBDC1', border: '1px solid #AFBDC1', borderRadius: '8px', padding: '2px'}}>
                              <FontAwesomeIcon icon={['fas','temperature-low']} style={{marginRight: '2px'}}/>
                              <input type="number" onChange={e => parseTemperature(e.target.value)}
                                     style={{maxWidth:'30px', border: '0', textAlign: 'center', height: '25px',
                                       backgroundColor: '#f6f6f6'}}
                                     placeholder='0.0'/>
                            </span>
                  <button onClick={getAnswer} disabled={timerOn || !activeChatRoomId}
                          className="btn send-button" style={{backgroundColor: colors.color2[3]}}>
                    {btnMsg}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ChMainPanel.propTypes = {
  messages: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  colors: PropTypes.object.isRequired,
  lastChatId: PropTypes.number.isRequired,
  setLastChatId: PropTypes.func.isRequired,
  getResponseEmbeddings: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  setIsChatApi: PropTypes.func.isRequired,
  isChatApi: PropTypes.bool.isRequired,
  getAnswer: PropTypes.func.isRequired,
  timerOn: PropTypes.bool.isRequired,
  activeChatRoomId: PropTypes.number,
  btnMsg: PropTypes.object.isRequired,
  setTemperature: PropTypes.func.isRequired,
  setPanel: PropTypes.func.isRequired,
  panel: PropTypes.string.isRequired,
  responseEmbeddings: PropTypes.object.isRequired
}

export default ChMainPanel