import PropTypes from 'prop-types';
import React from 'react';
import Lightbox from "react-images";
import ReactModal from "react-modal";
import { Editor } from "react-draft-wysiwyg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MediaQuery from "react-responsive";
import Dropzone from "react-dropzone";

import T_ModalVideo from "../../../Transcript/T_ModalVideo";
import AVTModal from "./AVTModal";
import AVTMaps01 from "./AVTMaps01";
import Comments from "../../../../../containers/Community/Comments";
import EditorWYSIWYGState from "../../../Settings/Utils/EditorWYSIWYGState";
import ParticipantEditor from "../../../../UI/WYSIWYG/ParticipantEditor";
import { taskI18n } from "../../../../UI/Globals/PX_i18n";
import {showSuccessMsg} from "../../../../UI/Globals/PX_Funs";

export default class AVTOpenEnd01 extends React.Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    cardinal: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    cmntyLanguage: PropTypes.string.isRequired,
    participants_view: PropTypes.string.isRequired,
    response: PropTypes.object,
    onEditorStateChange: PropTypes.func.isRequired,
    saveResponse: PropTypes.func.isRequired,
    questionsLength: PropTypes.number.isRequired,
    updateShowBlocker: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    deleteResponseImage: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    deleteVideo: PropTypes.func.isRequired,
    updateResponseMapsId: PropTypes.func.isRequired,
    blockerExpired: PropTypes.bool.isRequired,
    updateShowComments: PropTypes.func.isRequired,
    updateResponseComments: PropTypes.func.isRequired,
    taskSocialStatus: PropTypes.string.isRequired,
    comunitySocial: PropTypes.bool,
    scrollFirstTime: PropTypes.bool.isRequired,
    updateScrollFirstTime: PropTypes.func.isRequired,
    setActiveTaskId: PropTypes.func.isRequired,
    updateOETextAndSave: PropTypes.func.isRequired,
    colors: PropTypes.object.isRequired,
    scroll: PropTypes.string,
    type: PropTypes.string,
    item_scroll: PropTypes.object,
    from: PropTypes.string,
    isSavingResponse: PropTypes.bool,
    updateIsSavingResponse: PropTypes.func,
    helpStores: PropTypes.object.isRequired,
    updateHelpStore: PropTypes.func.isRequired,
    updateBackdrop: PropTypes.func,
    backdropVisibility: PropTypes.bool,
    cmntyAutoModeration: PropTypes.bool.isRequired,
    aiComment: PropTypes.object,
    username: PropTypes.string,
    qc: PropTypes.array,
    company: PropTypes.object.isRequired,
    addResponseEmbeddingAndModeration: PropTypes.func,
    aiAgentName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      showModalQuestionVideo: false,
      showModalValidation: false,
      validationResults: [],
      lBIsOpenR: false,
      currentImageR: 0,
      showModalVideo: false,
      // NEW
      editorState: props.response !== undefined ? props.response.editorState : null,
      percentage: 0,
      showSaveHelpPopup: false
    }
  }

  componentDidUpdate(prevProps) {
    //console.log('AVTOpenEnd01.componentDidUpdate');
    const { response, scrollFirstTime, participants_view, item_scroll, userId, question} = this.props;
    (this.props.backdropVisibility === false && this.state.showSaveHelpPopup === true) &&
    this.setState({ showSaveHelpPopup: false });

    if(prevProps.response !== response) {
      //console.log(response);
      if(scrollFirstTime && item_scroll) {
        const scrollAnchor = $('#' + userId + '_' + item_scroll.id);
        this.props.updateScrollFirstTime(false);

        if(scrollAnchor.offset() !== undefined && (item_scroll.type === 'response' || item_scroll.type === 'likeResponse')) {
          const cardPosition = scrollAnchor.offset().top;
          const offset = participants_view === 'As a list' ? 50 : 75;
          (window).scrollTo({ top: cardPosition - offset, behavior: 'smooth' });
        } else {
          //console.log(question.id);
          if(question.id === item_scroll.taskId) {
            this.props.updateShowComments(question.id)
          }
        }
      }

      //console.log('response:', response);
      if(response !== undefined){
        this.setState({
          percentage: response.percentage
        });
      }
    }
  };

  render() {
    const {question, cardinal, userId, language, cmntyLanguage, participants_view, response, questionsLength,
      blockerExpired, taskSocialStatus, comunitySocial, item_scroll, scrollFirstTime, from, isSavingResponse,
      colors, cmntyAutoModeration, aiComment, username, qc, company} = this.props
    //console.log(question.video_thumbnail, response);

    const i18n = {
      en: {
        taskPrivate: {
          saveHelpTooltip: "Saving will preserve your drafted response.",
          doneHelpTooltip: "Clicking here will save your task and notify moderators that you have finished.",
          commentsHelpTooltip: "Anything to say to the moderators about this tasks? Post a comment here any time."
        },
        taskPublic: {
          saveHelpTooltip: "Saving will preserve your drafted response but other participants won't be able to see it.",
          doneHelpTooltip: "Clicking here will notify moderators that you have finished and other participants may be able to see your response.",
          commentsHelpTooltip: "Anything to say to the moderators or other participants about this tasks? Post a comment here any time."
        },
        taskCompleted: 'You have completed this task', taskExpired: 'This task has expired and you can\'t edit it anymore',
        comments: 'Comments',
        savingResponse: 'Wait, saving responses'
      },
      es: {
        taskPrivate: {
          saveHelpTooltip: "Recuerda guardar tu avance dando click aquí.",
          doneHelpTooltip: "Cuando estés listo da click aquí para notificar a los moderadores que has terminado esta tarea.",
          commentsHelpTooltip: "Algo que decir a los moderadores sobre esta tarea? Commenta aquí."
        },
        taskPublic: {
          saveHelpTooltip: "Guarda tu avance aquí tomando en cuenta que mientras no completes la respuesta otros participantes no podrán verla.",
          doneHelpTooltip: "Cuando estés listo da click aquí para notificar a los moderadores y a otros participantes que has completado esta tarea.",
          commentsHelpTooltip: "Algo que decir a los moderadores o a otros participantes sobre esta tarea? Commenta aquí."
        },
        taskCompleted: 'Has completado esta tarea', taskExpired: 'Esta tarea ha expirado y no se puede editar',
        comments: 'Comentarios',
        savingResponse: 'Espera, guardando respuestas'
      }
    };

    let saveHelpTooltip = null;
    let saveHelpPopupClass = this.state.showSaveHelpPopup === true && 'open';
    let saveHelpMessage = comunitySocial && taskSocialStatus === 'Public' ? taskI18n[language].saveHelp2Social :
      taskI18n[language].saveHelp2
    let doneHelpTooltip = null;
    let commentsHelpTooltip = null;

    if(taskSocialStatus === "Public") {
      saveHelpTooltip = language === 'es' ? i18n.es.taskPublic.saveHelpTooltip : i18n.en.taskPublic.saveHelpTooltip;
      doneHelpTooltip = language === 'es' ? i18n.es.taskPublic.doneHelpTooltip : i18n.en.taskPublic.doneHelpTooltip;
      commentsHelpTooltip = language === 'es' ? i18n.es.taskPublic.commentsHelpTooltip : i18n.en.taskPublic.commentsHelpTooltip
    } else {
      saveHelpTooltip = language === 'es' ? i18n.es.taskPrivate.saveHelpTooltip : i18n.en.taskPrivate.saveHelpTooltip;
      doneHelpTooltip = language === 'es' ? i18n.es.taskPrivate.doneHelpTooltip : i18n.en.taskPrivate.doneHelpTooltip;
      commentsHelpTooltip = language === 'es' ? i18n.es.taskPrivate.commentsHelpTooltip : i18n.en.taskPrivate.commentsHelpTooltip
    }

    let editor = null;
    let percentageBar = null;
    let responseButtons = 
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-buttons-response">
      {/*<button onClick={from !== 'AB Preview' ?
        () => this.props.updateOETextAndSave(this.state.editorState, question.id, this.state.percentage, 'Draft') : () => { }}
              className="btn px-btn sm btn-blue-base" style={{ width: '120px' }} disabled={isSavingResponse}>
        {!isSavingResponse ? taskI18n[language].save_btn : <FontAwesomeIcon icon={['fas','spinner']} spin/>}
        <div className="ripple-container" />
        <div className="px-support-tooltip top is_hidden px-support-response"><p>{saveHelpTooltip}</p><i /></div>
      </button>*/}
      {!isSavingResponse && <div className={`dropup ${saveHelpPopupClass}`} style={{display: 'inline'}}>
        <button onClick={from !== 'AB Preview' ? () => this.saveAndNotify('Draft', question.id) : () => {
        }}
                className="btn px-btn sm btn-save-test" disabled={isSavingResponse}>
          {!isSavingResponse ? taskI18n[language].save_btn : <FontAwesomeIcon icon={['fas', 'spinner']} spin style={{fontSize: '12px'}}/>}
          <div className="ripple-container"/>
          {/* <div className="px-support-tooltip top is_hidden px-support-response"><p>{saveHelpTooltip}</p><i /></div> */}
        </button>
        <div className="dropdown-menu dropdown-menu px-drop-menu btn-save-popup">
          {/* <FontAwesomeIcon onClick={console.log('click')} className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal" icon={['fas', 'xmark']} style={{ height: '15px', width: '15px' }} />    */}
          <span style={{color: colors.color4[3]}}>{taskI18n[language].saveHelp1} </span>{saveHelpMessage}
          <div className="clickable" style={{color: colors.color1[3]}}><span
            onClick={() => this.stopSaveHelpPopup()}>{taskI18n[language].dontShowAgain}</span></div>
        </div>
      </div>
      }
      <button onClick={from !== 'AB Preview' ? this.validationCompleted : () => {}}
              className={`btn px-btn sm btn-turquoise-base ${isSavingResponse ? '' : 'mg-left-15'}`}
              style={this.state.showSaveHelpPopup === true ?
                {
                  backgroundColor: colors.color2[3],
                  position: 'absolute',
                  zIndex: '1019'
                } : { backgroundColor: colors.color2[3]}
              }
              disabled={isSavingResponse}>{!isSavingResponse && taskI18n[language].complete_btn}
        {
          !isSavingResponse ? cardinal < questionsLength &&
            <FontAwesomeIcon icon={['fas', 'circle-chevron-right']} className="mg-left-10"
                             style={{marginLeft: '5px'}}/> :
            <span style={{color: '#000000'}}>{i18n[language].savingResponse}
              <FontAwesomeIcon icon={['fas', 'spinner']} spin
                               style={{marginLeft: '5px'}}/>
            </span>
      }
      <div className="ripple-container" />
        {/* <div className="px-support-tooltip right is_hidden px-support-response" style={{ top: '-30px', left: '100px' }}>
          <p>{doneHelpTooltip}</p>
          <i />
        </div> */}
      </button>
    </div>;

    let editImages = null;
    let divImages = null;
    let dropbox = null;
    let videoComp = null;
    let mapsComp = null;
    let btnComments = null;
    let commentsComp = null;
    let responseLBImages = null;
    let responseLikes = null;

    if(response !== undefined) {
      const minChar = parseInt(question.data.minChar);
      const barWidth = this.state.percentage + '%';
      const barBGC = this.state.percentage > 80 ? '#AAFB9B' : '#e47b93';
      //const barWidth = response.percentage + '%';
      //const barBGC = response.percentage > 80 ? '#AAFB9B' : '#e47b93';

      percentageBar = <div className="my-progress-bar">
        <div className="my-progress-bar-color" style={{ width: barWidth, backgroundColor: barBGC }} />
      </div>;

      if(blockerExpired) {
        editor = <div className="panel activity-response-completed-container" style={{ marginBottom: '0' }}>
          <div className="panel-heading text-center" style={{ backgroundColor: blockerExpired ? colors.color4[6] : colors.color3[5] }}>
            <h3 className="panel-title" style={{ color: blockerExpired ? colors.color4[3] : '' }}>
              {blockerExpired ? i18n[language].taskExpired : i18n[language].taskCompleted}</h3>
          </div>
          <div dangerouslySetInnerHTML={{ __html: response.response && response.response.data.answerHTML }}
            className="panel-body">
          </div>
        </div>;
        percentageBar = null;
      } else {
        let placeholder = '';

        if(question.required) {
          if(question.required_options.find(i => i === 'Text') !== undefined) {
            if(minChar !== 0) {
              placeholder = taskI18n[language].plc_editor_01 + minChar + taskI18n[language].plc_editor_02;
            }
          }
        }
        //console.log(this.props.companyColors);
        editor = <ParticipantEditor name='participants_response_oe'
                                    editorName={''}
                                    editorState={response.editorState}
                                    onEditorStateChange={this.props.updateOETextAndSave}
                                    questionId={question.id}
                                    percentage={response.percentage}
                                    minChar={parseInt(question.data.minChar)}
                                    colors={this.props.colors}
                                    language={ this.props.language}/>
      }

      let videoThumbnail = null;
      let thumbnailImageClass = null;
      let videoBtns = null;
      if(response.videoState !== undefined) {
        videoThumbnail = response.videoState === 'processed' ?
          response.video.medias.thumb : '/assets/loader_143x100.gif';
        thumbnailImageClass = response.videoState === 'processed' ? 'video-thumbnail' : 'video-processing';
        if(response.videoState === 'processed') {
          videoBtns = <div className="px-div-overlay">
            <button onClick={this.updateShowModalVideo} className="px-btn-overlay">Play</button>
            {
              !blockerExpired &&
              <button onClick={() => this.props.deleteVideo(question.id)} className="px-btn-overlay"
                style={{ top: 'calc(50% - 1px) !important' }}>Delete</button>
            }
          </div>;
        }
        videoComp = <div className="px-video-comp">
          <div className="video-response-container">
            <img src={videoThumbnail} alt="" className={thumbnailImageClass} />
            {videoBtns}
          </div>
        </div>;
      } else {
        videoThumbnail = response.response ?
          response.response.video_thumbnail !== '' ? response.response.video_thumbnail : null :
          null;
        thumbnailImageClass = videoThumbnail !== null ? 'video-thumbnail' : '';
        // console.log(videoThumbnail);
        if(videoThumbnail) {
          videoBtns = <div className="px-div-overlay">
            <button onClick={this.updateShowModalVideo} className="px-btn-overlay">Play</button>
            {
              !blockerExpired &&
              <button onClick={() => this.props.deleteVideo(question.id)} className="px-btn-overlay">Delete</button>
            }

          </div>;
          videoComp = <div className="px-video-comp">
            <div className="video-response-container">
              <img src={videoThumbnail} alt="" className={thumbnailImageClass} />
              {videoBtns}
            </div>
          </div>;
        } else {
          videoComp = (!blockerExpired) &&
            <div onClick={from !== 'AB Preview' ? () => this.props.updateShowModal('video', question.id) : null}
              className="px-video-comp">
              <div className="video-response-container" style={{cursor:'pointer'}}>
                {
                  videoThumbnail ?
                    <img src={videoThumbnail} alt="" className={thumbnailImageClass} /> :
                    <div className="circle-video-icon">
                      <FontAwesomeIcon icon={['fas', 'circle']} className="circle" style={{ color: '#AFBDC1' }} />
                      <FontAwesomeIcon icon={['fas', 'video']} className="video" style={{ color: '#ffffff' }} />
                    </div>
                }
              </div>
            </div>;
        }
      }

      divImages = response.attachments.map((attachment, index) => {
        const preview = attachment.attachment !== undefined ?
          attachment.attachment.preview : attachment.url_thumbnail !== '' ? attachment.url_thumbnail : attachment.url;
        const attachmentId = attachment.id !== undefined ? attachment.id : -1;

        return (
          <div className="overlay-slick-container" key={attachment.id || attachment.uuid}>
            {
              preview !== '' ?
                <img src={preview} className='my-slider-img' alt=""/> :
                <span className="px-empty-msg">Processing image.</span>
            }
            <div className="overlay-slick">
              {
                !blockerExpired &&
                <button onClick={() => this.props.deleteResponseImage(index, attachmentId, question.id)}
                  className="btn my-btn-delete">{taskI18n[language].delete}</button>
              }
            </div>
          </div>
        )
      });

      dropbox = <Dropzone onDrop={from !== 'AB Preview' ? (event) => this.props.onDrop(event, question.id) : null}
                          minSize={0} maxSize={12582912} multiple={true}
                          style={{border:'0 solid', marginTop:'20px'}} 
                          accept={'image/jpeg, image/png'}
                          onDropRejected={this.onDropRejected}>
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()} className="open-end-dropzone">
            <input {...getInputProps()} />
            <div style={{backgroundColor: '#afbdc1', borderRadius: '50%', height: '50px', width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            data-tooltip-content={taskI18n[language].images_lbl}>
              <FontAwesomeIcon icon={['fas','camera']} style={{color: '#ffffff', fontSize: '16px'}}/>
            </div>
          </div>
        )}
      </Dropzone>;

      editImages = <div className="image-container-view">
        {divImages}
        {(!blockerExpired && question.media_options.find(opt => opt === 'Images') !== undefined) && dropbox}
        {question.media_options.find(opt => opt === 'Video') !== undefined && videoComp}
      </div>;

      if(question.maps_state) {
        mapsComp = <AVTMaps01
          response={response}
          language={language}
          cmntyLanguage={cmntyLanguage}
          questionId={question.id}
          questionMap={{ title: question.map_title, items: question.map_items }}
          updateResponseMapsId={from !== 'AB Preview' ? this.props.updateResponseMapsId : () => { }}
          blockerExpired={blockerExpired}
          colors={colors} />;
      }

      let sumAllCommentsReplies = 0;
      response.comments ?
        response.comments.forEach(c => {
          ++sumAllCommentsReplies;
          c.replies.forEach(r => {++sumAllCommentsReplies})}) : 0;

      btnComments = <button onClick={from !== 'AB Preview' ? () => this.props.updateShowComments(question.id) : () => { }}
        type="button" className="btn btn-comments" 
        style={ response.showComments ?
          { color: '#ffffff', borderColor: colors.color1[3], backgroundColor: colors.color1[3] } :
          { color: colors.color1[3], borderColor: colors.color1[3], backgroundColor: '#ffffff' } }>
        <div className="comments-container">
          {response.sum_unseen > 0 && <div className="unseen" style={{backgroundColor: colors.color4[3]}}/>}
          <FontAwesomeIcon icon={['fas', 'message']} className="icon-comments" style={{ color: response.showComments ? '#ffffff' : colors.color1[3]}}/>
          <span className="txt-comment" style={{ marginLeft: '0' }}>{i18n[language].comments}</span>
          <div className="number-container">
            <span className="number">
              {sumAllCommentsReplies}
            </span>
          </div>
        </div>
        <div className="px-support-tooltip top is_hidden px-support-response" style={{}}>
          <p>{commentsHelpTooltip}</p><i />
        </div>
      </button>;
      commentsComp = <div className={response.showComments ? 'px-n-accordion-open' : 'px-n-accordion-close'}>
        <Comments comments={response.comments ? response.comments : []}
                  language={language}
                  userId={userId}
                  username={username}
                  cmntyLanguage={cmntyLanguage}
                  questionId={response.question_id}
                  responseId={response.response ? response.response.id : -1}
                  role_1={'Participant'}
                  updateResponseComments={this.props.updateResponseComments}
                  from={'PAV'}
                  item_scroll={item_scroll}
                  updateScrollFirstTime={this.props.updateScrollFirstTime}
                  scrollFirstTime={scrollFirstTime}
                  colors={this.props.colors}
                  cmntyAutoModeration={cmntyAutoModeration}
                  aiComment={aiComment}
                  qc={qc} company={company}
                  taskType={question.task_type}
                  addResponseEmbeddingAndModeration={this.props.addResponseEmbeddingAndModeration}
                  aiAgentName={this.props.aiAgentName}
        />
      </div>

      // RESPONSE COMPLETED OR ACCEPTED
      if(response.response) {
        if((response.response.state === 'Completed' || response.response.state === 'Accepted') && response.showBlocker) {
          responseButtons = <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mg-top-15">
            <button onClick={() => this.props.updateShowBlocker(question.id)}
              className="btn px-btn sm btn-blue-base" style={{ width: '120px', backgroundColor: colors.color1[3] }}>
              {taskI18n[language].edit_btn}
              <div className="ripple-container" />
            </button>
          </div>;
          editor = <div className="panel activity-response-completed-container" style={{ marginBottom: '0' }}>
            <div className="panel-heading" style={{ backgroundColor: blockerExpired ? colors.color4[6] : colors.color3[5]}}>
              <h3 className="panel-title" style={{ color: blockerExpired ? colors.color4[3] : '' }}>
                {blockerExpired ? i18n[language].taskExpired : i18n[language].taskCompleted}</h3>
            </div>
            <div dangerouslySetInnerHTML={{ __html: response.response.data.answerHTML }} className="panel-body">
            </div>
          </div>;
          percentageBar = null;

          const responseImages = response.attachments.map((attachment, index) => {
            //console.log(attachment);
            const preview = attachment.attachment === undefined ?
              attachment.url_thumbnail !== '' ? attachment.url_thumbnail : attachment.url :
              attachment.attachment.preview;
            if(preview !== '') {
              return (
                <img onClick={(e) => this.openLightbox(index, e)} key={index} src={preview}
                  className="img-no-edit" alt="" style={{ marginLeft: '0', cursor: 'pointer' }} />
              )
            } else {
              // Maybe Collaborators should see the same message in their Transcript View
              return (
                <span key={index} className="img-no-edit">
                  <span className="px-empty-msg">Processing image.</span></span>
              )
            }
          });

          let videoThumbnail = null;

          if(response.videoState !== undefined) {
            videoThumbnail = response.videoState === 'processed' ?
              response.video.medias.thumb : '/assets/loader_143x100.gif';
            videoComp = <div onClick={from !== 'AB Preview' ? this.updateShowModalVideo : null}
              className="px-video-comp">
              <div className="video-response-container" >
                <img src={videoThumbnail} alt="" className="video-thumbnail" />
                <div className="px-div-overlay">
                  <div className="circle-video-icon">
                    <FontAwesomeIcon icon={['fas', 'circle']} className="circle" style={{ color: '#AFBDC1' }} />
                    <FontAwesomeIcon icon={['fas', 'video']} className="video" style={{ color: '#ffffff' }} />
                  </div>
                </div>
              </div>
            </div>;
          } else {
            if (response.response.video_thumbnail !== '') {
              videoComp = <div onClick={from !== 'AB Preview' ? this.updateShowModalVideo : null}
                className="px-video-comp view-mode">
                <div className="video-response-container" >
                  <img src={response.response.video_thumbnail} alt="" className="video-thumbnail" />
                  <div className="px-div-overlay">
                    <div className="circle-video-icon">
                      <FontAwesomeIcon icon={['fas', 'circle']} className="circle" style={{ color: '#AFBDC1' }} />
                      <FontAwesomeIcon icon={['fas', 'video']} className="video" style={{ color: '#ffffff' }} />
                    </div>
                  </div>
                </div>
              </div>;
            } else {
              videoComp = null;
            }
          }

          //console.log(question.media_options.find(opt => opt === 'Video') !== undefined);

          editImages = <div className="media-container-view">
            <div className="image-container-view">
              {responseImages}
              {question.media_options.find(opt => opt === 'Video') !== undefined && videoComp}
            </div>
          </div>;

          // TO-DO Investigate why in DB sometimes the values for selectedItem is NaN
          if(question.maps_state) {
            if(response.selectedItemsId !== undefined){ // Why this field don't came from DB
              if(response.selectedItemsId.length !== 0) {
                if(response.selectedItemsId.length === 1) {
                  if(isNaN(response.selectedItemsId[0])) {
                    mapsComp = null;
                  } else {
                    mapsComp = <AVTMaps01 response={response}
                                          language={language}
                                          cmntyLanguage={cmntyLanguage}
                                          questionId={question.id}
                                          questionMap={{ title: question.map_title, items: question.map_items }}
                                          updateResponseMapsId={this.props.updateResponseMapsId}
                                          blockerExpired={blockerExpired}
                                          colors={colors} />;
                  }
                } else {
                  mapsComp = <AVTMaps01 response={response}
                                        language={language}
                                        cmntyLanguage={cmntyLanguage}
                                        questionId={question.id}
                                        questionMap={{ title: question.map_title, items: question.map_items }}
                                        updateResponseMapsId={this.props.updateResponseMapsId}
                                        blockerExpired={blockerExpired}
                                        colors={colors} />;
                }
              } else {
                mapsComp = null;
              }
            }
          }
        }
      }

      responseLBImages = response.attachments.map(attachment => {
        //console.log(attachment);
        if (attachment.id === undefined) {
          return { src: attachment.attachment.preview };
        } else {
          if (attachment.url === undefined) {
            return { src: attachment.attachment.preview };
          } else {
            return { src: attachment.url }
          }
        }
      });

      responseLikes = response.likes;
    }

    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" ref='myMainDiv' tabIndex="1" >
        {
          participants_view === 'As a list' &&
          <div className="px-counter-task" style={{backgroundColor: colors.color0[1]}}>
            <span className="number">{cardinal}</span>
          </div>
        }
        <div id={userId + '_' + question.id} className="panel px-card px-panel-task response">
          <div className="panel-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <div dangerouslySetInnerHTML={{ __html: question.title }} className="title" />
              </div>
            </div>
            {
              // TO-DO review when question.video_thumbnail !== '' when the Participant saves their responses, what is this value by default in DB
              (question.attachment.length !== 0 || question.video_thumbnail || question.blobPreview) &&
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-answer-media-container">
                    {
                      question.blobPreview ?
                        <div className="px-media-img">
                          <FontAwesomeIcon onClick={() => this.setState({ lightboxIsOpen: true })} icon={['far','up-right-and-down-left-from-center']}
                                           size='lg' style={{position:'absolute', left:'102px', top: '5px',
                            backgroundColor:'white',padding: '0 2px', borderRadius: '4px', cursor: 'pointer'}}/>
                          <img onClick={() => this.setState({ lightboxIsOpen: true })} src={question.blobPreview}
                               style={{ cursor: 'pointer' }} alt=""/>
                        </div> :
                        question.attachment.length !== 0 &&
                        <div className="px-media-img">
                          <FontAwesomeIcon onClick={() => this.setState({ lightboxIsOpen: true })} icon={['far','up-right-and-down-left-from-center']}
                                           size='lg' style={{position:'absolute', left:'102px', top: '5px',
                            backgroundColor:'white',padding: '0 2px', borderRadius: '4px', cursor: 'pointer'}}/>
                          <img onClick={() => this.setState({ lightboxIsOpen: true })} src={question.attachment[0].url}
                               style={{ cursor: 'pointer' }} alt=""/>
                        </div>
                    }
                    {
                      question.video_thumbnail !== '' &&
                      <div className="px-media-img">
                        <FontAwesomeIcon onClick={this.updateShowModalQuestionVideo} icon={['far','up-right-and-down-left-from-center']}
                                         size='lg' style={{position:'absolute', left:'102px', top: '5px',
                          backgroundColor:'white',padding: '0 2px', borderRadius: '4px', cursor: 'pointer'}}/>
                        <img onClick={this.updateShowModalQuestionVideo}
                             src={question.video_thumbnail} style={{ cursor: 'pointer' }} alt=""/>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {question.task_type === 'Open End' &&  editor }
              </div>
            </div>
            {
              question.media &&
              <div className="row px-cont-upload">
                <div className="col-xs-12 col-sm-12 col-md-12" style={{ paddingRight: '0' }}>
                  { editImages }
                </div>
              </div>
            }

            { mapsComp }

            <div className="row">
              { !blockerExpired && responseButtons }
            </div>

            <div className="separator separator-response" />

            <div className="comments-option-container">
              { btnComments }
            </div>

            { commentsComp }
          </div>
        </div>
        {
          question.blobPreview ?
            <Lightbox currentImage={this.state.currentImage}
              images={[{ src: question.blobPreview }]}
              isOpen={this.state.lightboxIsOpen}
              onClose={() => this.closeLightbox('Question')} /> :
            question.attachment.length !== 0 &&
            <Lightbox currentImage={this.state.currentImage}
              images={[{ src: question.attachment.length !== 0 && question.attachment[0].url }]}
              isOpen={this.state.lightboxIsOpen}
              onClose={() => this.closeLightbox('Question')} />
        }
        {
          response !== undefined &&
          <Lightbox currentImage={this.state.currentImageR}
            images={responseLBImages}
            isOpen={this.state.lBIsOpenR}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            onClickThumbnail={this.gotoImage}
            onClose={() => this.closeLightbox('Response')} />
        }
        {
          question.video_thumbnail &&
          <ReactModal isOpen={this.state.showModalQuestionVideo} contentLabel="Response Video"
            shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalQuestionVideo}
            className="px-modal-content" overlayClassName="px-modal-overlay">
            <T_ModalVideo updateShowModalVideo={this.updateShowModalQuestionVideo}
              thumbnail={question.video_thumbnail}
              url={question.video_url}
              language={language}
              colors={colors} />
          </ReactModal>
        }
        {
          this.state.showModalValidation &&
          <ReactModal isOpen={this.state.showModalValidation} contentLabel="Activity Validation Modal"
            shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalValidation}
            className="px-modal-content" overlayClassName="px-modal-overlay">
            <AVTModal updateShowModal={this.updateShowModalValidation}
                      required_options={question.required_options}
                      minChar={question.data.minChar}
                      validationResults={this.state.validationResults}
                      type={question.task_type}
                      language={language}
                      mapTitle={question.map_title} />
          </ReactModal>
        }
        {
          this.state.showModalVideo &&
          <ReactModal isOpen={this.state.showModalVideo} contentLabel="Response Video"
            shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalVideo}
            className="px-modal-content" overlayClassName="px-modal-overlay">
            <T_ModalVideo updateShowModalVideo={this.updateShowModalVideo}
              thumbnail={response.video ? response.video.medias.thumb : response.response.video_thumbnail}
              url={response.video ? response.video.medias.mp4 : response.response.video_url}
              language={language}
              colors={colors} />
          </ReactModal>
        }
      </div>
    );
  }

  saveAndNotify = (state, question_id, user_id, cardinal) => {
    this.props.saveResponse(state, question_id);

    if(this.props.helpStores.showSaveHelp === true){
      this.props.updateBackdrop(true);
      this.setState({ showSaveHelpPopup: true });
    }
  }

  stopSaveHelpPopup = () => {
    this.setState({ showSaveHelpPopup: false });
    this.props.updateBackdrop(false);
    this.props.updateHelpStore('showSaveHelp', false);
  }

  onBlur = () => {
    // console.log('onBlur');
    this.props.updateOETextAndSave(this.state.editorState, this.props.question.id, this.state.percentage)
  };

  onEditorStateChange = editorState => {
    const body = editorState.getCurrentContent().getPlainText();  //It counts 'Enter' key
    const minChar = parseInt(this.props.question.data.minChar);
    const percentage = (100 * body.length) / parseInt(minChar);
    //console.log( body.length, minChar, percentage);

    this.setState({
      editorState,
      percentage: percentage <= 100 ? percentage : 100
    });
  };

  updateShowModalVideo = () => {
    this.setState(state => ({ showModalVideo: !state.showModalVideo }));
  };

  validationCompleted = () => {
    // console.log('this.props.question.required:', this.props.question.required);
    this.props.updateBackdrop(false);
    if(this.props.question.required){
      let results = [];
      // console.log('this.props.question.required_options:', this.props.question.required_options);
      this.props.question.required_options.forEach(option => {
        results.push(this._getValidation(option));
      });
      // console.log('results:', results);
      const resultValues = results.map(result => result.value);
      // console.log('resultValues:', resultValues);
      this.setState({ validationResults: results }, () => {
        if([...new Set(resultValues)].length === 1) {
          if([...new Set(resultValues)][0]) {
            this.props.saveResponse('Completed', this.props.question.id, this.props.userId, this.props.cardinal);
          } else {
            this.updateShowModalValidation();
          }
        } else {
          this.updateShowModalValidation();
        }
      });
    } else {
      if(this.props.from !== 'AB Preview') {
        //this.props.updateOETextAndSave(this.state.editorState, this.props.question.id, this.state.percentage,
        //  'Completed', this.props.userId, this.props.cardinal)
        this.props.saveResponse('Completed', this.props.question.id, this.props.userId, this.props.cardinal);
      }
    }
  };

  onDropRejected = (e) => {
    // console.log('onDropRejected', e[0]);
    let msg = '';
    if(e[0].size > 12582912){
      msg = 'Image file too large. Maximum file size 12 Mb'
    } else {
      if(e[0].type !== 'image/jpeg' || e[0].type !== 'image/png'){
        msg = 'Format not allowed. Only JPEG, JPG, GIF files allowed'
      }
    }
    showSuccessMsg(msg);
  }

  _getValidation = (type) => {
    const { response } = this.props;
    let result = null;

    switch(type) {
      case 'Text':
        const body = response.editorState.getCurrentContent().getPlainText();  //It counts 'Enter' key
        //const body = response.editorState.getCurrentContent().getPlainText();  //It counts 'Enter' key
        result = { name: 'Text', value: body.length !== 0 };
        break;
      case 'Characters':
        const body1 = response.editorState.getCurrentContent().getPlainText();  //It counts 'Enter' key
        //const body1 = this.props.response.editorState.getCurrentContent().getPlainText();  //It counts 'Enter' key
        result = { name: 'Characters', value: body1.length >= this.props.question.data.minChar };
        break;
      case 'Images':
        result = { name: 'Images', value: this.props.response.attachments.length >= 1 };
        break;
      case 'Video':
        let value = null;

        if (this.props.response.video !== null) {
          value = true;
        } else {
          // console.log(this.props.response.response);
          if(this.props.response.response !== null) {
            if(this.props.response.response.video_thumbnail !== '') {
              value = true;
            }
          } else {
            value = false;
          }
        }
        result = { name: 'Video', value: value };
        break;
      case 'Maps':
        // console.log('response.selectedItemsId:', response.selectedItemsId);
        const selectedItemsId = [...response.selectedItemsId].filter(i => i !== '');
        //const selectedItemsId = [...response.selectedItemsId].filter(item => !isNaN(item));
        // console.log('selectedItemsId:', selectedItemsId);
        result = { name: 'Maps', value: selectedItemsId.length >= 1 };
        break;
    }
    // console.log('result:', result);
    return result;
  };

  updateShowModalValidation = () => {
    this.setState(state => ({ showModalValidation: !state.showModalValidation }));
  };

  updateShowModalQuestionVideo = () => {
    this.setState(state => ({ showModalQuestionVideo: !state.showModalQuestionVideo }));
  };

  closeLightbox = (type) => {
    if (type === 'Question') {
      this.setState({ currentImage: 0, lightboxIsOpen: false });
    } else {
      this.setState({ currentImageR: 0, lBIsOpenR: false });
    }
  };
  openLightbox = (index, event) => {
    this.setState({ currentImageR: index, lBIsOpenR: true, });
  };
  gotoPrevious = () => {
    this.setState({ currentImageR: this.state.currentImageR - 1, });
  };
  gotoNext = () => {
    this.setState({ currentImageR: this.state.currentImageR + 1, });
  };
  gotoImage = (index) => {
    this.setState({ currentImageR: index, });
  };
}
