import PropTypes from 'prop-types';
import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageModal from "../MyProfile/ImageModal";
import ReactModal from "react-modal";
import AE_M_Image from "../Community/Activities/ActivityEditor/AE_Modal/AE_M_Image";

export default class Profile extends React.Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    isModalAvatar: PropTypes.bool.isRequired,
    isModalAvatarToggle: PropTypes.func.isRequired,
    avatar: PropTypes.string.isRequired,
    updateAvatar: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    handleCountry: PropTypes.func.isRequired,
    region: PropTypes.string.isRequired,
    handleRegion: PropTypes.func.isRequired,
    gender: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    saveProfile: PropTypes.func.isRequired,
    updateIsModalAvatar: PropTypes.func.isRequired,
    twitter: PropTypes.string.isRequired,
    facebook: PropTypes.string.isRequired,
    instagram: PropTypes.string.isRequired,
    snapchat: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    user_account: PropTypes.object.isRequired,
    company: PropTypes.object,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {user_account, language, profile, colors} = this.props;

    const i18n = {
      en: {
        first_name: 'First Name', last_name: 'Last Name', company: 'Company', gender: 'Gender',
        dob: 'Date of Birth', role: 'Your Role', profile_img: 'Profile Image', country: 'Country', region: 'Region',
        postcode: 'Post Code', language: 'Language', btn_save: 'Save', gender_01: 'Female', gender_02: 'Male',
        gender_03: 'Other', language_01: 'English', language_02: 'Spanish', social_media: 'YOUR SOCIAL MEDIA',
        selectCountry: 'Select country', selectRegion: 'Select region', select: 'Select', datePlaceHolder: 'dd/mm/yyyy'
      },
      es: {
        first_name: 'Nombre', last_name: 'Apellido', company: 'Compañía', gender: 'Género',
        dob: 'Fecha Nacimiento', role: 'Tu Función', profile_img: 'Imagen de Perfil', country: 'País', region: 'Región',
        postcode: 'Código Postal', language: 'Idioma', btn_save: 'Guardar', gender_01: 'Mujer', gender_02: 'Hombre',
        gender_03: 'Otro', language_01: 'Inglés', language_02: 'Español', social_media: 'TUS REDES SOCIALES',
        selectCountry: 'Selecciona país', selectRegion: 'Selecciona región', select: 'Seleccionar', datePlaceHolder: 'dd/mm/aaaa'
      }
    };

    const modalAvatar = this.props.isModalAvatar ?
      <ImageModal objectId={this.props.profile.id}
                  url={this.props.avatar}
                  handleToggle={this.props.isModalAvatarToggle}
                  updateAvatar={this.props.updateAvatar}
                  action='/profiles/update_avatar/'
                  language={this.props.language}
                  colors={colors}/> : null;

    return (
      <div className="px-settings-container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="px-card px-card-settings">
              <div className="px-icon-settings">
                <FontAwesomeIcon icon={['fas','id-card-clip']}/>
              </div>
              <div className="panel-body px-body-padding-settings">
                <div className="container-fluid">
                  <div className="row px-container-general-settings">
                    <div className="px-settings-inner-container">
                      <div className="row">
                          {
                            profile.role === 'Superadmin' &&
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group first">
                              <label className="label-sm" htmlFor="firstName"
                                     style={{ color: colors.color1[2] }}>{i18n[language].first_name}</label>
                                <input name="firstName" onChange={this.props.handleChange} value={this.props.firstName}
                                       type="text" className="form-control px-input-settings" placeholder=""/>
                              </div>
                            </div>
                          }
                          {
                            profile.role === 'Superadmin' &&
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group first">
                              <label className="label-sm" htmlFor="lastName"
                                     style={{ color: colors.color1[2] }}>{i18n[language].last_name}</label>
                                <input name="lastName" onChange={this.props.handleChange} value={this.props.lastName}
                                       type="text" className="form-control px-input-settings" placeholder=""/>
                              </div>
                            </div>
                          }
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="label-sm" htmlFor="" style={{ color: colors.color1[2] }}>{i18n[language].gender}</label>
                            <select name="gender" onChange={this.props.handleChange} value={this.props.gender}
                                    className="form-control px-input-settings">
                              <option value="">{i18n[language].select}</option>
                              <option value="Female">{i18n[language].gender_01}</option>
                              <option value="Male">{i18n[language].gender_02}</option>
                              <option value="Other">{i18n[language].gender_03}</option>
                            </select>
                          </div>
                        </div>
                        {
                          profile.role === 'Superadmin' &&
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <label className="label-sm" htmlFor="" style={{ color: colors.color1[2] }}>{i18n[language].dob}</label>
                              <InputMask name="dob" mask="99/99/9999" onChange={this.props.handleChange}
                                         defaultValue={this.props.dob} maskChar=" " className="form-control px-input-settings"
                                         placeholder={i18n[language].datePlaceHolder}/>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="label-sm" htmlFor="" style={{ color: colors.color1[2] }}>{i18n[language].country}</label>
                            <CountryDropdown
                              value={this.props.country}
                              valueType="short"
                              onChange={(val) => this.props.handleCountry(val)}
                              classes="form-control px-input-settings"
                              defaultOptionLabel={i18n[language].select}/>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="label-sm" htmlFor="" style={{ color: colors.color1[2] }}>{i18n[language].region}</label>
                            <RegionDropdown
                              country={this.props.country}
                              value={this.props.region}
                              onChange={(val) => this.props.handleRegion(val)}
                              classes="form-control px-input-settings"
                              blankOptionLabel={this.props.region}
                              countryValueType="short"
                              defaultOptionLabel={i18n[language].select}/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {
                          profile.role === 'Superadmin' &&
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                              <label className="label-sm" htmlFor="" style={{ color: colors.color1[2] }}>{i18n[language].postcode}</label>
                              <input name="postcode" type="text" onChange={this.props.handleChange}
                                     value={this.props.postcode} className="form-control px-input-settings"/>
                            </div>
                          </div>
                        }
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label className="label-sm" htmlFor="" style={{ color: colors.color1[2] }}>{i18n[language].language}</label>
                            <select name="language" onChange={this.props.handleChange} value={language}
                                    className="form-control px-input-settings">
                              {/*<option value="">Select</option>*/}
                              <option value="en">{i18n[language].language_01}</option>
                              <option value="es">{i18n[language].language_02}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {
                        user_account.profile.role === 'Superadmin' &&
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                              <label className="label-sm" htmlFor="" style={{ color: colors.color1[2] }}>{i18n[language].company}</label>
                              <input name="company" type="text" defaultValue={this.props.company.name}
                                     className="form-control px-input-settings" disabled/>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="panel-networks">
                            <div className="title" style={{ color: colors.color1[2] }}>{i18n[language].social_media}</div>
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="px-input-icon">
                                  <i className="fab fa-twitter px-icon-left social"/>
                                  <div className="form-group">
                                    <input name="twitter" type="text" onChange={this.props.handleChange}
                                           value={this.props.twitter} className="form-control icon-left"
                                           placeholder="Twitter URL"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="px-input-icon">
                                  <i className="fab fa-facebook px-icon-left social"/>
                                  <div className="form-group">
                                    <input name="facebook" type="text" onChange={this.props.handleChange}
                                           value={this.props.facebook} className="form-control icon-left"
                                           placeholder="Facebook URL"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="px-input-icon">
                                  <i className="fab fa-instagram px-icon-left social"/>
                                  <div className="form-group">
                                    <input name="instagram" type="text" onChange={this.props.handleChange}
                                           value={this.props.instagram} className="form-control icon-left"
                                           placeholder="Instagram URL"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="px-input-icon">
                                  <i className="fab fa-snapchat-ghost px-icon-left social"/>
                                  <div className="form-group">
                                    <input name="snapchat" type="text" onChange={this.props.handleChange}
                                           value={this.props.snapchat} className="form-control icon-left"
                                           placeholder="Snapchat URL"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="px-input-icon">
                                  <i className="fab fa-linkedin px-icon-left social"/>
                                  <div className="form-group">
                                    <input name="linkedin" type="text" onChange={this.props.handleChange}
                                           value={this.props.linkedin} className="form-control icon-left"
                                           placeholder="Linkedin URL"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="px-image-container text-center">
                      <div className="form-group" style={{display:"flex",flexDirection:'column',alignItems:'center',
                        justifyContent:'center'}}>
                        <label className="label-sm" style={{ marginTop: "5px", color: colors.color1[2]}}>Avatar</label>
                        <img id="imgPreviewMyProfile" onClick={this.props.isModalAvatarToggle} src={this.props.avatar}
                             className="px-thumb-65 img-circle" style={{cursor:'pointer'}} alt=""/>
                      </div>
                    </div>
                  </div>                  
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-row-buttons-action welcome">
                    <button onClick={this.props.saveProfile} className="btn px-btn lg btn-blue-base"
                            style={{ backgroundColor: colors.color1[3] }}>{i18n[language].btn_save}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          /*isModalAvatar &&
          <ReactModal isOpen={isModalAvatar} contentLabel="Community Card" shouldCloseOnOverlayClick={true}
                      onRequestClose={this.props.updateIsModalAvatar} className="px-modal-content"
                      overlayClassName="px-modal-overlay">
            <AE_M_Image updateShowModal={this.props.updateIsModalAvatar}
                        language={language}
                        title={'Your Avatar'}
                        updateCropBlob={this.props.updateCropBlob}
                        imageUrl={avatar}
                        onDropImage={this.props.onDropImage}/>
          </ReactModal>*/
        }
        { modalAvatar }
      </div>
    );
  }
}
