import PropTypes from 'prop-types';
import React from 'react';
import v4 from "uuid";
import ReactModal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"

import {taskI18n} from "../../../../UI/Globals/PX_i18n";

export default class AVTMaps01 extends React.Component {
  static propTypes = {
    questionId: PropTypes.number.isRequired,
    questionMap: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    cmntyLanguage: PropTypes.string.isRequired,
    response: PropTypes.object,
    updateResponseMapsId: PropTypes.func.isRequired,
    blockerExpired: PropTypes.bool.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showMap: false
    };
  }


  render() {
    const { questionId, questionMap, language, cmntyLanguage, response, blockerExpired, colors} = this.props;
    let itemsModal = [];
    let itemsSelected = [];
    let lblMaps = <span className="px-mood-text">{questionMap.title[cmntyLanguage]}</span>;
    let tourPoint = response.selectedItemsId.length === 0 ? <div className='px-tour-point top right clickable'
                                                                 style={{ backgroundColor: colors.color1[2] }}/> : null;
    let addBtn = !blockerExpired && <div className="px-mood-item add" onClick={this.updateShowMap}>
      <span>{taskI18n[language].add}</span>
      <FontAwesomeIcon icon={['fas','circle-plus']}/>
      { tourPoint }
    </div>;

    if(response !== undefined){
      //console.log(response);
      const selectedItemsId = response.selectedItemsId ? response.selectedItemsId : [];
      //const selectedItemsId = response !== undefined ? response.selectedItemsId : [];

      itemsModal = questionMap.items.map((item, index) => {
        let bgColor = null, color = null, focusedItemId = null;

        selectedItemsId.forEach(selectedItemId => {
          if(item.id === selectedItemId){
            bgColor = item.color; color = '#fff'; focusedItemId = selectedItemId;
            itemsSelected.push(
              <li key={v4()} className="px-mood-list-item">
              <span className="px-mood-item " data-tooltip-content={item[cmntyLanguage]} style={{ backgroundColor: colors.color0[1] }}>
                {item[cmntyLanguage]}
              </span>
                {
                  (!response.showBlocker && !blockerExpired) &&
                  <div onClick={() => this.props.updateResponseMapsId(item.id, this.props.questionId)}
                        className="btn-delete" style={{cursor:'pointer'}}>
                    <FontAwesomeIcon icon={['fas','xmark']}/>
                  </div>
                }
              </li>
            );
          }
        });

        return (
          <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <button onClick={() => this.props.updateResponseMapsId(item.id, questionId)}
                    className="btn btn-mood"
                    style={{width:'100%', backgroundColor:bgColor,
                      color:color, border: item.id === focusedItemId ? "solid 1px " + bgColor : "solid 1px #7C8593"}}
                    data-tooltip-content={item[cmntyLanguage]}>
                     {item[cmntyLanguage]}
            </button>
          </div>
        )
      });

      if(response.response){
        if((response.response.state === 'Completed' || response.response.state === 'Empty' || response.response.state === 'Accepted') && response.showBlocker){
          if(response.selectedItemsId !== undefined){
            if(response.selectedItemsId.length !== 0){
              if(response.selectedItemsId.length === 1){
                //console.log(!isNaN(response.selectedItemsId[0]));
                if(isNaN(response.selectedItemsId[0])){
                  lblMaps = null;
                } else {
                  console.log(response.selectedItemsId[0] === '');
                  lblMaps = response.selectedItemsId[0] === '' ? '' :
                    <span className="px-mood-text">{questionMap.title[cmntyLanguage]}</span>;
                }
              } else {
                lblMaps = <span className="px-mood-text">{questionMap.title[cmntyLanguage]}</span>;
              }

            } else {
              lblMaps = null;
            }
          }
          addBtn = null;
        }
      }
    }

    return (
      <div className="row">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="px-mood-container">
            { !blockerExpired && lblMaps }
            <div className="master-mood-container">
              <ul className="list-inline px-mood-list">
                { itemsSelected }
                <li className="px-mood-list-item ">{ addBtn }</li>
              </ul>
            </div>
          </div>
        </div>

        {
          this.state.showMap &&
          <ReactModal isOpen={this.state.showMap} contentLabel="Activity Map Modal"
                      shouldCloseOnOverlayClick={true}
                      onRequestClose={this.updateShowMap}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="btn px-btn circle xs btn-gray-lighter color-white px-close-modal">
                    <i onClick={this.updateShowMap} className="material-icons">clear</i>
                  </button>
                  <h4 className="px-modal-title">{questionMap.title[cmntyLanguage]}</h4>
                </div>

                <div className="modal-body maps-body">
                  <div className="row mood-modal-container">
                    { itemsModal }
                  </div>
                </div>

                <div className="separator"/>
                <div className="modal-footer px-modal-footer-gray">
                  <div className="px-footer-container-buttons">
                    <button onClick={this.updateShowMap} type="button" className="btn px-btn sm btn-blue-base"
                            style={{backgroundColor: colors.color1[3]}}>Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        }
      </div>
    );
  }

  updateShowMap = () => {
    this.setState(prevState => ({showMap: !prevState.showMap}));
  };
}