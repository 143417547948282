import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CSEditorWYSIWYG from "./Utils/CSEditorWYSIWYG";
import ReactModal from "react-modal";
import Welcome from "../../../containers/Community/Welcome";

export default class CSWelcome extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    saveCommunity: PropTypes.func.isRequired,
    onEditorStateChange: PropTypes.func.isRequired,
    showPreviewWelcome: PropTypes.bool.isRequired,
    updateShowPreviewWelcome: PropTypes.func.isRequired,
    typeWelcome: PropTypes.string.isRequired,
    showModeratorsAvatar: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      moderators: [],
      activeTab: this.props.community.project_type !== 'AIChat' ? 'participants' : 'collaborators'
    }
  }

  componentDidMount(){
    let btnColor = this.props.colors.color0[3];
    $(".nav-tabs > li.active > a").css({ "color": '#ffffff', 'background-color': btnColor, 'border-color': btnColor });
    this._getCollaborators();
  }

  render() {
    const {community, showPreviewWelcome, typeWelcome, language, colors} = this.props;

    const I18n = {
      en: { participants: 'PARTICIPANTS', collaborators: 'COLLABORATORS', welcomeTitleParticipants: 'PARTICIPANTS WELCOME MESSAGE',
        welcomeTitleCollaborators: 'COLLABORATORS WELCOME MESSAGE', charsLeft: 'chars. left', 
        welcomeInfoParticipants: 'This message will be shown to participants just before joining the community.',
        welcomeInfoCollaborators: 'This message will be shown to your collaborators just before joining the community.',
        preview: 'PREVIEW', save: 'SAVE', showModerators: 'Show Moderators',
        showModeratorsInfo: 'Moderator avatars will be shown in the welcome page.'},
      es: {
        participants: 'PARTICIPANTES', collaborators: 'COLABORADORES', welcomeTitleParticipants: 'BIENVENIDA A PARTICIPANTES',
        welcomeTitleCollaborators: 'BIENVENIDA A COLABORADORES', charsLeft: 'chars. restantes',
        welcomeInfoParticipants: 'Este mensaje será mostrado a los participantes justo antes de unirse a la comunidad.',
        welcomeInfoCollaborators: 'Este mensaje será mostrado a tus colaboradores justo antes de unirse a la comunidad.',
        preview: 'MUESTRA', save: 'GUARDAR', showModerators: 'Mostrar Moderadores',
        showModeratorsInfo: 'Los avatars de los moderadores se mostrarán en la página de bienvenida.'}
      };

    const tabStyle = {
      active: { border: `1px solid ${colors.color0[3]}`, color: '#ffffff', backgroundColor: colors.color0[3] },
      inactive: { border: '1px solid #DADDE1', color: '#7C8593', backgroundColor: '#f0eff3' }
    };

    return (
      <div id="divWelcome" className="manage" style={{display:'none'}}>
        <div className="px-settings-container tabs">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {community.project_type !== 'AIChat' && <ul className="nav nav-tabs px-submenu-settings-navigation" role="tablist">
                <li className="active"><a onClick={() => this.setActiveTab('participants')} href="#participants"
                                          role="tab" data-toggle="tab"
                                          style={this.state.activeTab === 'participants' ? tabStyle.active : tabStyle.inactive}>{I18n[language].participants}</a>
                </li>
                <li><a onClick={() => this.setActiveTab('collaborators')} href="#collaborators" role="tab"
                       data-toggle="tab"
                       style={this.state.activeTab === 'collaborators' ? tabStyle.active : tabStyle.inactive}>{I18n[language].collaborators}</a>
                </li>
              </ul>}
              <div className="tab-content" >
                <div className={`tab-pane fade in ${community.project_type !== 'AIChat' && 'active'}`} id="participants">
                  <div className="px-card px-card-settings">
                    <div className="px-icon-settings">
                      <FontAwesomeIcon icon={['fas','handshake']}/>
                    </div>
                    <div className="panel-body px-body-padding-welcome">
                      <div className="form-group first">
                        <label className="label-sm" style={{color: colors.color1[2]}}>{I18n[language].welcomeTitleParticipants}</label>
                        <div className="textarea-bio-container" style={{position:'relative'}}>
                          <CSEditorWYSIWYG name={community.language === 'en' ?
                            'participants_welcome_message' : 'participants_welcome_message_es'}
                                           editorName="editorWelcomePart"
                                           editorState={community.editorWelcomePart}
                                           onEditorStateChange={this.props.onEditorStateChange}
                                           maxLength={5000}
                                           language={language}/>
                          <span className="px-label-information char-remaining" style={{ color: colors.color2[3] }}>
                            {5000 - community.editorWelcomePart.getCurrentContent().getPlainText().length} { I18n[language].charsLeft }
                          </span>
                        </div>
                        
                        <p className="px-label-information" style={{ color: colors.color2[3] }}>
                          {I18n[language].welcomeInfoParticipants}
                        </p>

                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action welcome">
                          <button onClick={() => this.props.updateShowPreviewWelcome('Participant')}
                            className="btn px-btn lg btn-gray-base">{I18n[language].preview}</button>
                          <button onClick={this.props.saveCommunity} className="btn px-btn lg btn-blue-base"
                                  style={{backgroundColor: colors.color1[3]}}>
                            {I18n[language].save}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {community.project_type !== 'AIChat' && <div className="px-card px-card-settings">
                    <div className="panel-body social-settings">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                          <div className="px-switch-item-container">
                            <div className="px-switch-item-conf">
                              <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].showModerators}</label>
                              <label className="px-switch" htmlFor="avatarsCollToPart">
                                <input name="show_coll_to_part" type="checkbox" id="avatarsCollToPart"
                                      onChange={this.props.showModeratorsAvatar}
                                       checked={community.show_coll_to_part}/>
                                <div className="slider round" style={community.show_coll_to_part ? { backgroundColor: colors.color3[3] } : {}}/>
                              </label>
                            </div>
                          </div>
                          <p className="px-label-information" style={{ color: colors.color2[3] }}>
                            {I18n[language].showModeratorsInfo}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>

                <div className={`tab-pane fade ${community.project_type === 'AIChat' && 'in active'}`} id="collaborators">
                  <div className="px-card px-card-settings">
                    <div className="px-icon-settings">
                      <FontAwesomeIcon icon={['fas','handshake']}/>
                    </div>
                    <div className="panel-body px-body-padding-welcome">
                      <div className="form-group first">
                        <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].welcomeTitleCollaborators}</label>
                        <div className="textarea-bio-container" style={{position:'relative'}}>
                          <CSEditorWYSIWYG name={community.language === 'en' ?
                            'members_welcome_message' : 'members_welcome_message_es'}
                                           editorName="editorWelcomeColl"
                                           editorState={community.editorWelcomeColl}
                                           onEditorStateChange={this.props.onEditorStateChange}
                                           maxLength={5000}
                                           language={language}/>
                          <span className="px-label-information char-remaining" style={{ color: colors.color2[3] }}>
                            {5000 - community.editorWelcomeColl.getCurrentContent().getPlainText().length} {I18n[language].charsLeft}</span>
                        </div>

                        <p className="px-label-information" style={{ color: colors.color2[3] }}>
                          {I18n[language].welcomeInfoCollaborators}
                        </p>

                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action welcome">
                            <button  onClick={() => this.props.updateShowPreviewWelcome('Collaborator')}
                              className="btn px-btn lg btn-gray-base">{I18n[language].preview}</button>
                            <button onClick={this.props.saveCommunity} className="btn px-btn lg btn-blue-base" style={{backgroundColor: colors.color1[3]}}>
                              {I18n[language].save}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {community.project_type !== 'AIChat' && <div className="px-card px-card-settings">
                    <div className="panel-body social-settings">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                          <div className="px-switch-item-container">
                            <div className="px-switch-item-conf">
                              <label className="label-sm text-center" style={{ color: colors.color1[2] }}>{I18n[language].showModerators}</label>
                              <label className="px-switch" htmlFor="avatarsParticipantsFlag">
                                <input name="show_coll_to_coll" type="checkbox" id="avatarsParticipantsFlag"
                                      onChange={this.props.showModeratorsAvatar} checked={community.show_coll_to_coll}/>
                                <div className="slider round" style={community.show_coll_to_coll ? { backgroundColor: colors.color3[3] } : {}}/>
                              </label>
                            </div>
                          </div>
                          <p className="px-label-information" style={{color: colors.color2[3]}}>{I18n[language].showModeratorsInfo}</p>
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          showPreviewWelcome &&
          <ReactModal isOpen={showPreviewWelcome} contentLabel="Preview" shouldCloseOnOverlayClick={true}
                      className="my-modal-content" overlayClassName="my-modal-overlay">
            <div className="modal-dialog" style={{width:'85%'}}>
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <i onClick={() => this.props.updateShowPreviewWelcome('')} className="material-icons"
                       style={{color: '#333'}}>clear</i>
                  </button>
                </div>

                <div className="modal-body">
                  <Welcome isPreview={true}
                           communityId={community.id}
                           communityTitle={community.title}
                           message={
                             typeWelcome === 'Participant' ?
                               community.language === 'en' ? community.participants_welcome_message : community.participants_welcome_message_es :
                               community.language === 'en' ? community.members_welcome_message : community.members_welcome_message_es
                           }
                           showCollaborators={typeWelcome === 'Participant' ?
                             community.show_coll_to_part : community.show_coll_to_coll}
                           collaborators={[]}
                           moderators={this.state.moderators}
                           socialFlag={community.social_flag}
                           role_1={typeWelcome}
                           mailto={''}
                           language={language}
                           profileId={-1}
                           updateOpenPMFromOutside={() => {}}
                           userAvatar={'/assets/user.png'}
                           userUsername={'User'}
                           colors={colors}/>
                </div>

                {/*<div className="modal-footer">
                  <button onClick={() => this.props.updateShowPreviewWelcome('')} type="button"
                          className="btn btn-success btn-simple">Ok</button>
                </div>*/}
              </div>
            </div>
          </ReactModal>
        }
      </div>
    );
  }

  _getCollaborators = () => {
    $.ajax({
      url: '/communities/get_community_users/' + this.props.community.id,
      method: 'GET',
      dataType: 'JSON',
      data: {type: 'Collaborator'},
      success: communityUsers => {
        //console.log(communityUsers);
        this.setState({moderators: communityUsers.filter(user =>
            (user.role_2 === 'Moderator' || user.role_2 === 'Owner') && user.is_welcome)})
      }
    });
  };

  setActiveTab = (tabName) => {
    this.setState({activeTab: tabName});
  }
}
