import PropTypes from 'prop-types';
import React from 'react';

export default class DPCStats extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    userIsCollapsedPar: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      receivedObjs: {counter_likes: 0, likes_sent: 0, counter_comments_replies: 0, comments_replies_sent: 0},
    }
  }

  componentDidMount(){
    if(!this.props.userIsCollapsedPar){
      this._getReceivedObjs();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.userIsCollapsedPar !== this.props.userIsCollapsedPar){
      //console.log(this.props.userIsCollapsedPar);
      if(!this.props.userIsCollapsedPar){
        this._getReceivedObjs();
      }
    }
  }

  render() {
    const {language} = this.props;

    const i18n = {
      en: {
        pctOfCompleted: '% of tasks Accepted or Completed', likesIn:'Likes received', likesOut: 'Likes given',
        commentsIn: 'Comments received', commentsOut: 'Posted comments', usersNA: 'No active participants yet'
      },
      es: {
        pctOfCompleted: '% de tareas Aceptadas o Completadas', likesIn: 'Likes recibidos', likesOut: 'Likes dados',
        commentsIn: 'Comentarios recibidos', commentsOut: 'Comentarios publicados', usersNA: 'No hay participantes activos'
      }
    }

    return(
      <div className="px-social-stats-container ">
        <div className="likes-container" data-tooltip-content={i18n[language].likesIn}>
          <span className="like-in"/>
          <div className="counter">{this.state.receivedObjs.counter_likes}</div>
        </div>
        <div className="likes-container" data-tooltip-content={i18n[language].likesOut}>
          <span className="like-out"/>
          <div className="counter">{this.state.receivedObjs.likes_sent}</div>
        </div>
        <div className="message-container" data-tooltip-content={i18n[language].commentsIn}>
          <span className="comment-in"/>
          <div className="counter">{this.state.receivedObjs.counter_comments_replies}</div>
        </div>
        <div className="message-container" data-tooltip-content={i18n[language].commentsOut}>
          <span className="comment-out"/>
          <div className="counter">{this.state.receivedObjs.comments_replies_sent}</div>
        </div>
      </div>
    )
  }

  _getReceivedObjs = () => {
    $.ajax({
      url: '/users/get_received_objs/' + this.props.userId + '/' + this.props.communityId,
      method: 'GET',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: receivedObjs =>  {
        //console.log(receivedObjs);
        this.setState({receivedObjs});
      }
    });
  };
}