import PropTypes from 'prop-types';
import React from 'react';
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import AE_AddTask from "./AE_AddTask";
import OEEdit01 from "./AE_TaskList/AE_T_OpenEnd/OEEdit01";
import OEView01 from "./AE_TaskList/AE_T_OpenEnd/OEView01";
import BackdropTransparent from "../../../UI/BackdropTransparent";
import ChEdit from "./AE_TaskList/AEChoice/ChEdit";
import ChView from "./AE_TaskList/AEChoice/ChView";
import CanvasEdit from "./AE_TaskList/AECanvas/CanvasEdit";
import CanvasView from "./AE_TaskList/AECanvas/CanvasView";
import MicroChatEdit from "./AE_TaskList/AEMicroChat/MicroChatEdit";
import MicroChatView from "./AE_TaskList/AEMicroChat/MicroChatView";

export default class AETaskList01 extends React.Component {
  static propTypes = {
    questions: PropTypes.array.isRequired,
    addQuestion: PropTypes.func.isRequired,
    mapsSelect: PropTypes.array.isRequired,
    handleMapsSelected: PropTypes.func.isRequired,
    cancelEditQuestion: PropTypes.func.isRequired,
    updateQuestionMode: PropTypes.func.isRequired,
    onSortEndQuestion: PropTypes.func.isRequired,
    handleChangeQuestion: PropTypes.func.isRequired,
    updateShowModal: PropTypes.func.isRequired,
    updateQuestion: PropTypes.func.isRequired,
    onEditorStateChangeQuestion: PropTypes.func.isRequired,
    deleteMedia: PropTypes.func.isRequired,
    handleRequired: PropTypes.func.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    showAddTaskTop: PropTypes.bool.isRequired,
    showAddTaskMiddle: PropTypes.bool.isRequired,
    handleShowAddTask: PropTypes.func.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    deleteAnswer: PropTypes.func.isRequired,
    updateObjective: PropTypes.func.isRequired,
    deleteObjective: PropTypes.func.isRequired,
    addObjective: PropTypes.func.isRequired,
    addAnswer: PropTypes.func.isRequired,
    onSortEndAnswerList: PropTypes.func.isRequired,
    handleCanAttachMedia: PropTypes.func.isRequired,
    data_signature: PropTypes.object.isRequired,
    communityLang: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    handleQuestionColorsChange: PropTypes.func,
    communityProjectType: PropTypes.string.isRequired,
    cmntyTaskTypeLevel: PropTypes.string,
    cmntyMaxMicroChats: PropTypes.number,
    microChatCount: PropTypes.number,
    cmntyAiAutoModeration: PropTypes.bool,
    cmntyAllowImgs: PropTypes.bool,
    cmntyAiPlan: PropTypes.string,
    isSavingTask: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddTask: false,
      selectedQuestionId: null
    };
  }

  handleShowAddTaskQuestionId = (selectedQuestionId) => {
    //console.log(selectedQuestionId);
    this.setState(state => ({showAddTask: !state.showAddTask, selectedQuestionId}), () => {
      this.props.handleShowAddTask('showAddTaskMiddle');
    });
  };

  render() {
    const {questions, mapsSelect, communitySocial, showAddTaskTop, showAddTaskMiddle, data_signature,
      communityLang, language, colors, communityProjectType, cmntyTaskTypeLevel, cmntyMaxMicroChats,
      microChatCount, cmntyAiAutoModeration, cmntyAllowImgs, cmntyAiPlan, isSavingTask } = this.props

    const dropUpClassTop = showAddTaskTop ? 'add-activity-inter dropup open': 'add-activity-inter dropup';

    const i18n = {
      en: {
        addTask: 'ADD NEW TASK'
      },
      es: {
        addTask: 'NUEVA TAREA'
      }
    }

    return (
      <div>
        {
          questions.length !== 0 &&
          <div className={dropUpClassTop}>
            <div onClick={() => this.props.handleShowAddTask('showAddTaskTop')} className="message dropdown-toggle">
              <div className="separator"/>
              <span className="text">{i18n[language].addTask}</span>
              <div className="separator"/>
            </div>
            <AE_AddTask addQuestion={this.props.addQuestion}
                        position={0}
                        language={language}
                        colors={colors}
                        cmntyTaskTypeLevel={cmntyTaskTypeLevel}
                        cmntyMaxMicroChats={cmntyMaxMicroChats}
                        microChatCount={microChatCount}/>
          </div>
        }

        <SortableList questions={questions}
                      updateQuestionMode={this.props.updateQuestionMode}
                      handleChangeQuestion={this.props.handleChangeQuestion}
                      updateQuestion={this.props.updateQuestion}
                      updateShowModal={this.props.updateShowModal}
                      cancelEditQuestion={this.props.cancelEditQuestion}
                      addQuestion={this.props.addQuestion}
                      mapsSelect={mapsSelect}
                      handleMapsSelected={this.props.handleMapsSelected}
                      onEditorStateChangeQuestion={this.props.onEditorStateChangeQuestion}
                      deleteMedia={this.props.deleteMedia}
                      handleRequired={this.props.handleRequired}
                      communitySocial={communitySocial}
                      showAddTask={this.state.showAddTask}
                      showAddTaskMiddle={showAddTaskMiddle}
                      handleShowAddTaskQuestionId={this.handleShowAddTaskQuestionId}
                      handleShowAddTask={this.props.handleShowAddTask}
                      selectedQuestionId={this.state.selectedQuestionId}
                      updateAnswer={this.props.updateAnswer}
                      deleteAnswer={this.props.deleteAnswer}
                      updateObjective={this.props.updateObjective}
                      deleteObjective={this.props.deleteObjective}
                      addObjective={this.props.addObjective}
                      addAnswer={this.props.addAnswer}
                      onSortEndAnswerList={this.props.onSortEndAnswerList}
                      onSortEnd={this.props.onSortEndQuestion}
                      handleCanAttachMedia={this.props.handleCanAttachMedia}
                      data_signature={data_signature}
                      useDragHandle={true}
                      lockAxis="y"
                      useWindowAsScrollContainer={true}
                      communityLang={communityLang}
                      language={language}
                      colors={colors}
                      communityProjectType={communityProjectType}
                      cmntyTaskTypeLevel={cmntyTaskTypeLevel}
                      cmntyMaxMicroChats={cmntyMaxMicroChats}
                      microChatCount={microChatCount}
                      handleQuestionColorsChange={this.props.handleQuestionColorsChange}
                      cmntyAiAutoModeration={cmntyAiAutoModeration}
                      cmntyAllowImgs={cmntyAllowImgs}
                      cmntyAiPlan={cmntyAiPlan}
                      isSavingTask={isSavingTask}
        />
      </div>
    );
  }
}

const SortableList = SortableContainer(({questions, updateQuestionMode, handleChangeQuestion, handleCanAttachMedia,
                                          updateQuestion, updateShowModal, cancelEditQuestion, addQuestion,
                                          mapsSelect, handleMapsSelected, onEditorStateChangeQuestion, deleteMedia,
                                          handleRequired, communitySocial, showAddTask, handleShowAddTask,
                                          showAddTaskMiddle, selectedQuestionId, handleShowAddTaskQuestionId,
                                          updateAnswer, deleteAnswer, addAnswer, updateObjective, deleteObjective, addObjective,
                                          onSortEndAnswerList, data_signature, communityLang, language, colors,
                                          communityProjectType,cmntyTaskTypeLevel, cmntyMaxMicroChats, microChatCount, handleQuestionColorsChange,
                                          cmntyAiAutoModeration, cmntyAllowImgs, cmntyAiPlan, isSavingTask
                                        }) => {
  return(
    <div>
      {questions.map((question, index) => (
        <SortableItem key={`itemQ-${index}`}
                      index={index}
                      question={question}
                      cardinal={index + 1}
                      updateQuestionMode={updateQuestionMode}
                      handleChangeQuestion={handleChangeQuestion}
                      updateQuestion={updateQuestion}
                      updateShowModal={updateShowModal}
                      cancelEditQuestion={cancelEditQuestion}
                      addQuestion={addQuestion}
                      mapsSelect={mapsSelect}
                      handleMapsSelected={handleMapsSelected}
                      questionsLength={questions.length}
                      onEditorStateChangeQuestion={onEditorStateChangeQuestion}
                      deleteMedia={deleteMedia}
                      handleRequired={handleRequired}
                      communitySocial={communitySocial}
                      showAddTask={showAddTask}
                      showAddTaskMiddle={showAddTaskMiddle}
                      handleShowAddTask={handleShowAddTask}
                      selectedQuestionId={selectedQuestionId}
                      handleShowAddTaskQuestionId={handleShowAddTaskQuestionId}
                      updateAnswer={updateAnswer}
                      deleteAnswer={deleteAnswer}
                      updateObjective={updateObjective}
                      deleteObjective={deleteObjective}
                      addObjective={addObjective}
                      addAnswer={addAnswer}
                      onSortEndAnswerList={onSortEndAnswerList}
                      handleCanAttachMedia={handleCanAttachMedia}
                      data_signature={data_signature}
                      communityLang={communityLang}
                      language={language}
                      colors={colors}
                      communityProjectType={communityProjectType}
                      cmntyTaskTypeLevel={cmntyTaskTypeLevel}
                      cmntyMaxMicroChats={cmntyMaxMicroChats}
                      microChatCount={microChatCount}
                      handleQuestionColorsChange={handleQuestionColorsChange}
                      cmntyAiAutoModeration={cmntyAiAutoModeration}
                      cmntyAllowImgs={cmntyAllowImgs}
                      cmntyAiPlan={cmntyAiPlan}
                      isSavingTask={isSavingTask}
        />
      ))}
    </div>
  );
});

const DragHandle = SortableHandle(() => <button style={{cursor:'drag'}} className="btn-draggable">
  <FontAwesomeIcon icon={['far', 'bars']}/>
</button>);

const SortableItem = SortableElement(({question, cardinal, updateQuestionMode, handleChangeQuestion,
                                        handleCanAttachMedia, updateQuestion, updateShowModal, cancelEditQuestion,
                                        addQuestion, mapsSelect, handleMapsSelected, questionsLength,
                                        onEditorStateChangeQuestion, deleteMedia, handleRequired, communitySocial,
                                        showAddTask, showAddTaskMiddle, handleShowAddTask, selectedQuestionId,
                                        handleShowAddTaskQuestionId, updateAnswer, deleteAnswer, addAnswer,
                                        updateObjective, deleteObjective, addObjective, onSortEndAnswerList,
                                        data_signature, communityLang, language, colors, communityProjectType,
                                        cmntyTaskTypeLevel,cmntyMaxMicroChats, microChatCount, handleQuestionColorsChange,
                                        cmntyAiAutoModeration, cmntyAllowImgs, cmntyAiPlan, isSavingTask
                                      }) => {
  let questionCard = null;
  //console.log(question);
  switch(question.task_type){
    case 'Open End':
    case 'Multimedia':
      questionCard = question.isQuestionEdit ?
        <OEEdit01 question={question}
                  cardinal={cardinal}
                  cancelEditQuestion={cancelEditQuestion}
                  handleChangeQuestion={handleChangeQuestion}
                  updateQuestion={updateQuestion}
                  updateShowModal={updateShowModal}
                  onEditorStateChangeQuestion={onEditorStateChangeQuestion}
                  mapsSelect={mapsSelect}
                  handleMapsSelected={handleMapsSelected}
                  deleteMedia={deleteMedia}
                  handleRequired={handleRequired}
                  communitySocial={communitySocial}
                  handleCanAttachMedia={handleCanAttachMedia}
                  data_signature={data_signature}
                  communityLang={communityLang}
                  language={language}
                  colors={colors}
                  communityProjectType={communityProjectType}
                  cmntyAiAutoModeration={cmntyAiAutoModeration}
                  cmntyAllowImgs={cmntyAllowImgs}
                  cmntyAiPlan={cmntyAiPlan}
                  isSavingTask={isSavingTask}
        /> :
        <OEView01 question={question}
                  cardinal={cardinal}
                  updateQuestionMode={updateQuestionMode}
                  updateShowModal={updateShowModal}
                  mapsSelect={mapsSelect}
                  communitySocial={communitySocial}
                  language={language}
                  colors={colors}
                  communityLang={communityLang}/>;
      break;
    case 'Choice':
      questionCard = question.isQuestionEdit ?
        <ChEdit question={question}
                cardinal={cardinal}
                communitySocial={communitySocial}
                updateQuestion={updateQuestion}
                cancelEditQuestion={cancelEditQuestion}
                onEditorStateChangeQuestion={onEditorStateChangeQuestion}
                handleChangeQuestion={handleChangeQuestion}
                updateShowModal={updateShowModal}
                deleteMedia={deleteMedia}
                updateAnswer={updateAnswer}
                deleteAnswer={deleteAnswer}
                addAnswer={addAnswer}
                onSortEndAnswerList={onSortEndAnswerList}
                language={language}
                colors={colors}
                communityProjectType={communityProjectType}
                cmntyAiAutoModeration={cmntyAiAutoModeration}
                cmntyAllowImgs={cmntyAllowImgs}
                cmntyAiPlan={cmntyAiPlan}
                isSavingTask={isSavingTask}
        /> :
        <ChView question={question}
                cardinal={cardinal}
                communitySocial={communitySocial}
                updateQuestionMode={updateQuestionMode}
                updateShowModal={updateShowModal}
                language={language}
                colors={colors}/>;
      break;
    case 'Canvas':
      questionCard = question.isQuestionEdit ?
          <CanvasEdit question={question}
                      cardinal={cardinal}
                      cancelEditQuestion={cancelEditQuestion}
                      handleChangeQuestion={handleChangeQuestion}
                      updateQuestion={updateQuestion}
                      updateShowModal={updateShowModal}
                      onEditorStateChangeQuestion={onEditorStateChangeQuestion}
                      mapsSelect={mapsSelect}
                      handleMapsSelected={handleMapsSelected}
                      deleteMedia={deleteMedia}
                      handleRequired={handleRequired}
                      communitySocial={communitySocial}
                      handleCanAttachMedia={handleCanAttachMedia}
                      data_signature={data_signature}
                      communityLang={communityLang}
                      language={language}
                      colors={colors}
                      handleQuestionColorsChange={handleQuestionColorsChange}
                      communityProjectType={communityProjectType}
                      cmntyAiAutoModeration={cmntyAiAutoModeration}
                      cmntyAllowImgs={cmntyAllowImgs}
                      cmntyAiPlan={cmntyAiPlan}
                      isSavingTask={isSavingTask}
          />
          :
          <CanvasView question={question}
                      cardinal={cardinal}
                      updateQuestionMode={updateQuestionMode}
                      updateShowModal={updateShowModal}
                      mapsSelect={mapsSelect}
                      communitySocial={communitySocial}
                      language={language}
                      colors={colors}
                      communityLang={communityLang}/>;
      break;
    case 'MicroChat':
      questionCard = question.isQuestionEdit ?
          <MicroChatEdit question={question}
                         cardinal={cardinal}
                      cancelEditQuestion={cancelEditQuestion}
                      handleChangeQuestion={handleChangeQuestion}
                      updateQuestion={updateQuestion}
                      updateShowModal={updateShowModal}
                      onEditorStateChangeQuestion={onEditorStateChangeQuestion}
                      handleMapsSelected={handleMapsSelected}
                      deleteMedia={deleteMedia}
                      handleRequired={handleRequired}
                      communitySocial={communitySocial}
                      handleCanAttachMedia={handleCanAttachMedia}
                      data_signature={data_signature}
                      communityLang={communityLang}
                      language={language}
                      colors={colors}
                      addObjective={addObjective}
                      updateObjective={updateObjective}
                      deleteObjective={deleteObjective}
                      onSortEndAnswerList={onSortEndAnswerList}/> :
          <MicroChatView question={question}
                         cardinal={cardinal}
                         updateQuestionMode={updateQuestionMode}
                         updateShowModal={updateShowModal}
                         communitySocial={communitySocial}
                         language={language}
                         colors={colors}
                         communityLang={communityLang}/>;
      break;
  }

  const flag = showAddTaskMiddle && selectedQuestionId === question.id;
  //console.log(flag);
  const dropUpClassMiddle = flag ? 'add-activity-inter dropup open': 'add-activity-inter dropup';

  return (
    <div style={{position:'relative'}}>
      {flag ? <BackdropTransparent show={flag} clicked={() => handleShowAddTask('showAddTaskMiddle')}/> : null}
      <DragHandle/>
      { questionCard }
      {
        cardinal !== questionsLength &&
        <div className={dropUpClassMiddle}>
          <div onClick={() => handleShowAddTaskQuestionId(question.id)} className="message dropdown-toggle">
            <div className="separator"/>
            <span className="text">{language === 'es' ? 'NUEVA TAREA' : 'ADD NEW TASK'}</span>
            <div className="separator"/>
          </div>
          <AE_AddTask addQuestion={addQuestion}
                      position={cardinal}
                      language={language}
                      colors={colors}
                      cmntyTaskTypeLevel={cmntyTaskTypeLevel}
                      cmntyMaxMicroChats={cmntyMaxMicroChats}
                      microChatCount={microChatCount}/>
        </div>
      }
    </div>
  )
});