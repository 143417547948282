import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ICTwoButtons = props => {
  const {community, colors, i18n, language} = props;

  return (
    <React.Fragment>
      <div onClick={() => props.updateStateInvitation('accept', community.id)} className="btn-group accept">
        <button type="button" className="btn btn-action accept" style={{backgroundColor: colors.color2[1]}}>
          <FontAwesomeIcon icon={['far','check']}/>
        </button>
        <button type="button" className="btn px-btn sm btn-turquoise-darkest"
                style={{backgroundColor: colors.color2[0]}}>{i18n[language].enter}</button>
      </div>
      <div onClick={() => props.updateStateInvitation('decline', community.id)} className="btn-group">
        <button type="button" className="btn btn-action decline">
          <FontAwesomeIcon icon={['far','xmark']}/>
        </button>
        <button type="button" className="btn px-btn sm btn-white inverted">
          {i18n[language].decline}
        </button>
      </div>
    </React.Fragment>
  )
};

ICTwoButtons.propTypes = {
  community: PropTypes.object.isRequired,
  updateStateInvitation: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
}

export default ICTwoButtons;