import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

import { showSuccessMsg } from "../../UI/Globals/PX_Funs";
import {settingsNotI18n } from "../../UI/Globals/PX_i18n";

export default class CSNotifications extends React.Component {
  static propTypes = {
    cu: PropTypes.object.isRequired,
    cuChangeNotification: PropTypes.func.isRequired,
    saveBio: PropTypes.func.isRequired,
    social_flag: PropTypes.bool.isRequired,
    user_email: PropTypes.string,
    cuHandleChange: PropTypes.func,
    updateIsValidCommunityEmail: PropTypes.func,
    isValidCommunityEmail: PropTypes.bool,
    saveFirstTimeCommEmail: PropTypes.bool,
    language: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'notifications'
    }
  }

  componentDidMount() {
    let btnColor = this.props.colors.color0[3];
    $(".nav-tabs > li.active > a").css({ "color": '#ffffff', 'background-color': btnColor, 'border-color': btnColor });
  }

  render() {
    const { cu, social_flag, user_email, isValidCommunityEmail, saveFirstTimeCommEmail, language, colors } = this.props;

    const I18n = {
      en: {
        save: 'Save', notifications: 'In-App', emails: 'By Email', aboutYou: 'About you', aboutFollow: 'About people you follow',
        aboutCmnty: 'About the community', aboutActivities: 'About activities', cmntyAllowance: 'Community allowances',
        receivingAddress: 'Your email addresses', autoBcc: 'Auto Bcc', bccAddress: 'Type Bcc address',
        bccAddressWarning: 'Check your email and confirm this address.', resendConfirm: 'Resend confirmation email.',
        messages: 'Mesages', otherNotifications: 'Other email notifications', addressWarning: '* Wrong email format',
        received: 'Receive notifications at'
      },
      es: {
        save: 'Guardar', notifications: 'En la App', emails: 'Por Email', aboutYou: 'Sobre ti', aboutFollow: 'Sobre los usarios que sigues',
        aboutCmnty: 'Sobre la comunidad', aboutActivities: 'Sobre las actividades', cmntyAllowance: 'Cuotas de la comunidad',
        receivingAddress: 'Tus direcciones de correo', autoBcc: 'Auto Bcc', bccAddress: 'Escribe una dirección Bcc aquí',
        bccAddressWarning: 'Revisa tu correo y confirma esta dirección.', resendConfirm: 'Reenviar confirmación por email.',
        messages: 'Mensajes', otherNotifications: 'Otras notificaciones por email', addressWarning: 'Formato de email incorrecto',
        received: 'Reciba notificaciones en'
      }
    };

    const tabStyle = {
      active: { border: `1px solid ${colors.color0[3]}`, color: '#ffffff', backgroundColor: colors.color0[3] },
      inactive: { border: '1px solid #DADDE1', color: '#7C8593', backgroundColor: '#f0eff3' }
    };

    const aboutYou = cu.notifications.map((n, i) => {
      if(n.group === 'About you') {
        return (
          <div key={n.group + i} className="notifications-item">
            <span className="title-header">{ language === 'en' ? n.name : settingsNotI18n[language][n.group][n.name] }</span>
            <div className="switch-container">
              <label className="px-switch" htmlFor={n.group + i}>
                <input name={n.name} onChange={(event) => this.props.cuChangeNotification(event, n.group)}
                  type="checkbox" id={n.group + i} checked={n.value} />
                <div className="slider round" style={n.value ? { backgroundColor: colors.color3[3] } : {}}/>
              </label>
            </div>
          </div>
        )
      }
    });

    const aboutPeople = cu.notifications.map((n, i) => {
      if(n.group === 'About people you follow') {
        return (
          <div key={n.group + i} className="notifications-item">
            <span className="title-header">{ language === 'en' ? n.name : settingsNotI18n[language][n.group][n.name] }</span>
            <div className="switch-container">
              <label className="px-switch" htmlFor={n.group + i}>
                <input name={n.name} onChange={(event) => this.props.cuChangeNotification(event, n.group)}
                  type="checkbox" id={n.group + i} checked={n.value} />
                <div className="slider round" style={n.value ? { backgroundColor: colors.color3[3] } : {}}/>
              </label>
            </div>
          </div>
        )
      }
    });

    const aboutCommunity = cu.notifications.map((n, i) => {
      if(n.group === 'About the community') {
        if(social_flag) {  //It works 'cos Participants can see just ONE switch in this group
          return (
            <div key={n.group + i} className="notifications-item">
              <span className="title-header">{ language === 'en' ? n.name : settingsNotI18n[language][n.group][n.name] }</span>
              <div className="switch-container">
                <label className="px-switch" htmlFor={n.group + i}>
                  <input name={n.name} onChange={(event) => this.props.cuChangeNotification(event, n.group)}
                    type="checkbox" id={n.group + i} checked={n.value} />
                  <div className="slider round" style={n.value ? { backgroundColor: colors.color3[3] } : {}}/>
                </label>
              </div>
            </div>
          )
        }
      }
    });

    const aboutActivities = cu.notifications.map((n, i) => {
      if(n.group === 'About activities') {
        if(n.name !== 'A participant has completed an Activity') {
          return (
            <div key={n.group + i} className="notifications-item">
              <span className="title-header">{ language === 'en' ? n.name : settingsNotI18n[language][n.group][n.name] }</span>
              <div className="switch-container">
                <label className="px-switch" htmlFor={n.group + i}>
                  <input name={n.name} onChange={(event) => this.props.cuChangeNotification(event, n.group)}
                    type="checkbox" id={n.group + i} checked={n.value} />
                  <div className="slider round" style={n.value ? { backgroundColor: colors.color3[3] } : {}}/>
                </label>
              </div>
            </div>
          )
        } else {
          if(social_flag) {
            return (
              <div key={n.group + i} className="notifications-item">
                <span className="title-header">{ language === 'en' ? n.name : settingsNotI18n[language][n.group][n.name] }</span>
                <div className="switch-container">
                  <label className="px-switch" htmlFor={n.group + i}>
                    <input name={n.name} onChange={(event) => this.props.cuChangeNotification(event, n.group)}
                      type="checkbox" id={n.group + i} checked={n.value} />
                    <div className="slider round" style={n.value ? { backgroundColor: colors.color3[3] } : {}} />
                  </label>
                </div>
              </div>
            )
          }
        }

      }
    });

    const cmtyAllowance = cu.notifications.map((n, i) => {
      if(n.group === 'Community allowances') {
        return (
          <div key={n.group + i} className="notifications-item">
            <span className="title-header">{ language === 'en' ? n.name : settingsNotI18n[language][n.group][n.name] }</span>
            <div className="switch-container">
              <label className="px-switch" htmlFor={n.group + i}>
                <input name={n.name} onChange={(event) => this.props.cuChangeNotification(event, n.group)}
                  type="checkbox" id={n.group + i} checked={n.value} />
                <div className="slider round" style={n.value ? { backgroundColor: colors.color3[3] } : {}}/>
              </label>
            </div>
          </div>
        )
      }
    });

    const messages = cu.notifications.map((n, i) => {
      if (n.group === 'Messages') {
        return (
          <div key={n.group + i} className="notifications-item">
            <span className="title-header">{ language === 'en' ? n.name : settingsNotI18n[language][n.group][n.name] }</span>
            <div className="switch-container">
              <label className="px-switch" htmlFor={n.group + i}>
                <input name={n.name} onChange={(event) => this.props.cuChangeNotification(event, n.group)}
                  type="checkbox" id={n.group + i} checked={n.value} />
                <div className="slider round" style={n.value ? { backgroundColor: colors.color3[3] } : {}}/>
              </label>
            </div>
          </div>
        )
      }
    });

    const byEmail = cu.notifications.map((n, i) => {
      //console.log(n.name);
      if (n.group === 'Notifications by email') {
        if (n.name !== 'Daily digest') {
          return (
            <div key={n.group + i} className="notifications-item">
              <span className="title-header">{ language === 'en' ? n.name : settingsNotI18n[language][n.group][n.name] }</span>
              <div className="switch-container">
                <label className="px-switch" htmlFor={n.group + i}>
                  <input name={n.name} onChange={(event) => this.props.cuChangeNotification(event, n.group)}
                    type="checkbox" id={n.group + i} checked={n.value} />
                  <div className="slider round" style={n.value ? { backgroundColor: colors.color3[3] } : {}}/>
                </label>
              </div>
            </div>
          )
        }
      }
    });

    return (
      <div id="divNotifications" className="manage" style={{ display: 'none' }}>
        <div className="px-settings-container tabs">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ul className="nav nav-tabs px-submenu-settings-navigation" role="tablist">
                <li className="active">
                  <a onClick={() => this.setActiveTab('notifications')} href="#notifications" role="tab" data-toggle="tab"
                    style={this.state.activeTab === 'notifications' ? tabStyle.active : tabStyle.inactive}>
                      {I18n[language].notifications}
                  </a>
                </li>
                <li className="">
                  <a onClick={() => this.setActiveTab('emails')} href="#emails" role="tab" data-toggle="tab"
                    style={this.state.activeTab === 'emails' ? tabStyle.active : tabStyle.inactive}>
                    {I18n[language].emails}
                  </a>
                </li>
              </ul>

              <div className="tab-content" >
                <div className="active tab-pane fade in" id="notifications">
                  <div className="px-card px-card-settings">
                    <div className="px-icon-settings">
                      <FontAwesomeIcon icon={['fas', 'bell']} />
                    </div>
                    <div className="panel-body px-body-padding-welcome">
                      <div className="row">
                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
                          {
                            aboutYou.filter(n => n !== undefined).length !== 0 &&
                            <div className="notifications-title">
                              <label className="label-sm" style={{color: colors.color1[2]}}>{I18n[language].aboutYou}</label>
                            </div>
                          }
                          <div className="px-notifications-container">
                            {aboutYou}
                          </div>
                          {
                            aboutPeople.filter(n => n !== undefined).length !== 0 &&
                            <div className="notifications-title">
                              <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].aboutFollow}</label>
                            </div>
                          }
                          <div className="px-notifications-container">
                            {aboutPeople}
                          </div>
                          {
                            aboutCommunity.filter(n => n !== undefined).length !== 0 &&
                            <div className="notifications-title">
                              <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].aboutCmnty}</label>
                            </div>
                          }
                          <div className="px-notifications-container">
                            {aboutCommunity}
                          </div>
                          {
                            aboutActivities.filter(n => n !== undefined).length !== 0 &&
                            <div className="notifications-title">
                              <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].aboutActivities}</label>
                            </div>
                          }
                          <div className="px-notifications-container">
                            {aboutActivities}
                          </div>
                          {
                            cmtyAllowance.filter(n => n !== undefined).length !== 0 &&
                            <div className="notifications-title">
                              <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].cmntyAllowance}</label>
                            </div>
                          }
                          <div className="px-notifications-container">
                            {cmtyAllowance}
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action welcome">
                          <button onClick={this.props.saveBio} className="btn px-btn lg btn-blue-base" style={{ backgroundColor: colors.color1[3] }}>{I18n[language].save}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="emails">
                  <div className="px-card px-card-settings">
                    <div className="px-icon-settings">
                      <FontAwesomeIcon icon={['fas', 'envelope']} />
                    </div>
                    <div className="panel-body px-body-padding-welcome">
                      <div className="row">
                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
                          {
                            (cu.role_2 === 'Owner' || cu.role_2 === 'Moderator' || cu.role_2 === 'Recruiter') &&
                            <React.Fragment>
                              <div className="notifications-title">
                                <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].receivingAddress}</label>
                              </div>
                              <div className="px-notifications-container">
                                <div className="notifications-item">
                                  <span className="title-header">{I18n[language].received} {user_email}</span>
                                  <div className="switch-container">
                                    <label className="px-switch" htmlFor="use_user_email">
                                      <input onChange={(event) => this.props.cuChangeNotification(event, null)}
                                        name="use_user_email" type="checkbox" id="use_user_email" checked={cu.use_user_email} />
                                      <div className="slider round" style={cu.use_user_email ? {backgroundColor: colors.color3[3]} : {}} />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="px-notifications-container">
                                <div className="notifications-item" style={{ marginBottom: '-25px' }}>
                                  <span className="title-header">{I18n[language].received}</span>
                                  <div className="switch-container">
                                    <label className="px-switch" htmlFor="use_community_email">
                                      <input onChange={(event) => this.props.cuChangeNotification(event, null)}
                                        name="use_community_email" type="checkbox" id="use_community_email"
                                        checked={cu.use_community_email} disabled={!cu.is_comm_email_confirmed} />
                                      <div className="slider round" style={cu.use_community_email ? { backgroundColor: colors.color3[3] } : {}}/>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="px-notifications-container" style={{ marginBottom: '5px' }}>
                                <div className="notifications-item" style={{ marginBottom: '0' }}>
                                  <div className="form-group" style={{ width: '80%', margin: '0 !important' }}>
                                    <input name="community_email" onChange={this.props.cuHandleChange} value={cu.community_email}
                                      type="text" className="form-control px-input-settings"
                                      onBlur={() => this._validateEmailFormat(cu.community_email)}
                                      placeholder={I18n[language].bccAddress} />
                                    {
                                      cu.community_email !== '' &&
                                      <span className="px-label-warning" style={{ fontSize: '11px', display: 'block' }}>
                                        {
                                          isValidCommunityEmail ?
                                            saveFirstTimeCommEmail &&
                                            <React.Fragment>
                                              <span>{I18n[language].bccAddressWarning} </span>
                                              <span onClick={this.resendConfirmationEmail}
                                                style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                                {I18n[language].resendConfirm}
                                              </span>
                                            </React.Fragment> :
                                            I18n[language].addressWarning
                                        }
                                      </span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          }
                          <div className="notifications-title">
                            <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].messages}</label>
                          </div>
                          <div className="px-notifications-container">
                            {messages}
                          </div>

                          <div className="notifications-title">
                            <label className="label-sm" style={{ color: colors.color1[2] }}>{I18n[language].otherNotifications}</label>
                          </div>
                          <div className="px-notifications-container">
                            {byEmail}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center px-row-buttons-action welcome">
                          <button onClick={this.props.saveBio} className="btn px-btn lg btn-blue-base" style={{backgroundColor: colors.color1[3]}}>{I18n[language].save}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  resendConfirmationEmail = async () => {
    await axios.get(`/communities_user/resend_community_email/${this.props.cu.id}`);
    showSuccessMsg('Success!');
  };

  _validateEmailFormat(value) {
    if (value !== '') {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //console.log(re.test(value))
      this.props.updateIsValidCommunityEmail(re.test(value));
    } else {
      this.props.updateIsValidCommunityEmail(true);
    }

  }
  
  setActiveTab = (tabName) => {
    this.setState({ activeTab: tabName });
  }
}
