import PropTypes from 'prop-types';
import React from 'react';
import v4 from "uuid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"
import {arrayMove} from "react-sortable-hoc";
import ReactModal from "react-modal";
import _ from "underscore";
import MediaQuery from 'react-responsive';
import moment from 'moment';
import axios from "axios";

import ActivityFilter from "../../components/Community/Activities/ActivityFilter";
import ActivityList01 from "../../components/Community/Activities/ActivityList01";
import AE_Modal from "../../components/Community/Activities/ActivityEditor/AE_Modal";
import ActivityEditor01 from "../../components/Community/Activities/ActivityEditor01";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import ActivityGrid01 from "../../components/Community/Activities/ActivityGrid01";
import Loading from "../../components/UI/Globals/Loading";
import {getCSRF, showSuccessMsg, sortCollTasks} from "../../components/UI/Globals/PX_Funs";
import translate from "../../components/UI/Globals/Translations";

export default class ActivityBuilder01 extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    communityState: PropTypes.string.isRequired,
    communitySocial: PropTypes.bool.isRequired,
    communityMaps: PropTypes.array.isRequired,
    communitySegs: PropTypes.array.isRequired,
    communityTags: PropTypes.array.isRequired,
    communityPartStart: PropTypes.string.isRequired,
    communityPartEnd: PropTypes.string.isRequired,
    communityProjectType: PropTypes.string.isRequired,
    gender_as_segment: PropTypes.bool.isRequired,
    sort_coll: PropTypes.string.isRequired,
    sort_part: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    active_users: PropTypes.array.isRequired,
    data_signature: PropTypes.object.isRequired,
    user_account: PropTypes.object.isRequired,
    communityLang: PropTypes.string.isRequired,
    cmntyAutoModeration: PropTypes.bool.isRequired,
    cmntyTaskTypeLevel: PropTypes.string,
    cmntyMaxMicroChats: PropTypes.number,
    cmntyAiAutoModeration: PropTypes.bool,
    hasLiveActivities: PropTypes.bool,
    hasTasks: PropTypes.bool,
    cmntyAllowImgs: PropTypes.bool,
    cmntyAiPlan: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      tasksOrig: [],
      tasks: [],
      microChatCount: 0,
      sort_coll: props.sort_coll,
      sort_part: props.sort_part,
      q: '',
      isGrid: false,
      activeTaskId: null,
      showModal: false,
      modalType: null,
      modalQuestionId: null,
      modalAnswerId: null,
      activityNameToDelete: '',
      delActC1: false,
      delActC2: false,
      mapsSelect: [], // Maps for Tasks
      segments: props.communitySegs,
      //segments: [],
      tags: props.communityTags,
      //tags: [],
      isFilterLock: false,
      delTaskC1: false,
      copyActC1: true,
      copyActC2: true,
      showAddTaskTop: false,
      showAddTaskBottom: false,
      showAddTaskMiddle: false,
      validations: [],
      choiceAnsBlob: 0,
      choiceAnsUrl: [],
      actDates: {name: 'creation', startDate: null, endDate: null},
      previewMapTitle: {en: 'Mood', es:''},
      isLoading: true,
      isSavingNewAct: false,
      isSavingTask: null
    };
  }

  componentDidMount(){
    this._getCommunityTasks();
    //this._getCommunityMaps();
    //this._getCommunitySegments();
    //this._getCommunityTags();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tasks !== this.state.tasks) {
      this.setState({ microChatCount: this.countMicroChatTasks() });
    }
  }

  render() {
    const {language, sort_coll, active_users, communitySocial, gender_as_segment, data_signature,
      communityMaps, communityLang, communityPartStart, communityPartEnd, communityProjectType,
      user_account, cmntyTaskTypeLevel, cmntyMaxMicroChats} = this.props
    const colors = user_account.all_colors

    const i18n = {
      en: { newActivity: 'New', pleaseSelectAct: 'Select an activity', seeGrid: 'See activities in a grid',
        seeList: 'See activities in a list'
      },
      es: { newActivity: 'Nueva', pleaseSelectAct: 'Selecciona una actividad', seeGrid: 'Ver actividades en cuadrícula',
        seeList: 'Ver actividades en lista'
      }
    };

    return (
      <div className="px-main-actv-build-cont">
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />

        <ActivityFilter language={language}
                        searchTask={this.searchTask}
                        sort_coll={this.state.sort_coll}
                        sort_part={this.state.sort_part}
                        handleChangeFilters={this.handleChangeFilters}
                        isFilterLock={this.state.isFilterLock}
                        handleIsFilterLock={this.handleIsFilterLock}
                        colors={colors}/>

        <MediaQuery minDeviceWidth={920}>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className={ this.state.isGrid ? 'new-activity-container grid' : 'new-activity-container list' }>
                <button onClick={this.addTask} className="btn px-btn lg btn-turquoise-base"
                        style={{minWidth: language === 'en' ? '207px' : '229px', backgroundColor: colors.color2[3]}}
                        disabled={this.state.isSavingNewAct}>
                  {
                    this.state.isSavingNewAct ? <FontAwesomeIcon icon={['fas', 'spinner']} size="2x" spin/> :
                      i18n[language].newActivity
                  }
                </button>
                <FontAwesomeIcon onClick={this.handleIsGrid} icon={['fas', this.state.isGrid ? 'list' : 'grid-2']}
                  className="grid-icon" data-tooltip-content={[this.state.isGrid ? i18n[language].seeList :
                    i18n[language].seeGrid]}/>
              </div>
            </div>
          </div>
        </MediaQuery>
        {
          this.state.isLoading ?
            <Loading/> :
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="px-act-build-cont">
                  <MediaQuery minDeviceWidth={920}>
                    <ActivityList01 isGrid={this.state.isGrid}
                                    tasks={this.state.tasks}
                                    activeTaskId={this.state.activeTaskId}
                                    updateActiveTaskId={this.updateActiveTaskId}
                                    language={language}
                                    active_users={active_users}
                                    gender_as_segment={gender_as_segment}
                                    sort_coll={sort_coll}
                                    onSortEndTask={this.onSortEndTask}
                                    updateShowModal={this.updateShowModal}
                                    updateLive={this.updateLive}
                                    colors={colors}
                                    helpStores={user_account.help_stores}
                                    hasLiveActivities={this.props.hasLiveActivities}
                                    hasTasks={this.props.hasTasks}
                                    communityId={this.props.communityId}
                                    userId={user_account.profile.user_id}
                    />
                  </MediaQuery>
                  <MediaQuery minDeviceWidth={920}>
                    {
                      this.state.activeTaskId ?
                        <ActivityEditor01 task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                                          language={language}
                                          updateTaskAttributeMode={this.updateTaskAttributeMode}
                                          handleChange={this.handleChange}
                                          cancelEditTaskAttributes={this.cancelEditTaskAttributes}
                                          updateTaskAttributes={this.updateTaskAttributes}
                                          updateStartExpireDates={this.updateStartExpireDates}
                                          addQuestion={this.addQuestion}
                                          mapsSelect={communityMaps}
                                          handleMapsSelected={this.handleMapsSelected}
                                          cancelEditQuestion={this.cancelEditQuestion}
                                          updateQuestionMode={this.updateQuestionMode}
                                          onSortEndQuestion={this.onSortEndQuestion}
                                          handleChangeQuestion={this.handleChangeQuestion}
                                          updateShowModal={this.updateShowModal}
                                          updateQuestion={this.updateQuestion}
                                          onEditorStateChange={this.onEditorStateChange}
                                          communitySocial={communitySocial}
                                          onEditorStateChangeQuestion={this.onEditorStateChangeQuestion}
                                          isGrid={this.state.isGrid}
                                          deleteMedia={this.deleteMedia}
                                          handleRequired={this.handleRequired}
                                          showAddTaskBottom={this.state.showAddTaskBottom}
                                          showAddTaskTop={this.state.showAddTaskTop}
                                          showAddTaskMiddle={this.state.showAddTaskMiddle}
                                          handleShowAddTask={this.handleShowAddTask}
                                          updateAnswer={this.updateAnswer}
                                          deleteAnswer={this.deleteAnswer}
                                          addAnswer={this.addAnswer}
                                          updateObjective={this.updateObjective}
                                          deleteObjective={this.deleteObjective}
                                          addObjective={this.addObjective}
                                          onSortEndAnswerList={this.onSortEndAnswerList}
                                          handleCanAttachMedia={this.handleCanAttachMedia}
                                          data_signature={data_signature}
                                          communityLang={communityLang}
                                          colors={colors}
                                          communityPartStart={communityPartStart}
                                          communityPartEnd={communityPartEnd}
                                          cmntyTaskTypeLevel={cmntyTaskTypeLevel}
                                          communityProjectType={communityProjectType}
                                          cmntyMaxMicroChats={cmntyMaxMicroChats}
                                          microChatCount={this.state.microChatCount}
                                          handleQuestionColorsChange={this.handleQuestionColorsChange}
                                          cmntyAiAutoModeration ={this.props.cmntyAutoModeration}
                                          cmntyAllowImgs={this.props.cmntyAllowImgs}
                                          cmntyAiPlan={this.props.cmntyAiPlan}
                                          isSavingTask={this.state.isSavingTask}
                        /> :
                        <div className="px-details-actv-cont" style={{display: this.state.isGrid && 'none'}}>
                          <div className="px-empty-msg" style={{color: colors.color4[3]}}>
                            { this.state.tasks.length !== 0 && i18n[language].pleaseSelectAct }
                          </div>
                        </div>
                    }
                  </MediaQuery>
                  {
                    // Try to reuse components from List to Grid
                    !this.state.isGrid &&
                    <MediaQuery maxDeviceWidth={920}>
                      <ActivityGrid01 isGrid={true}
                                      tasks={this.state.tasks}
                                      activeTaskId={this.state.activeTaskId}
                                      updateActiveTaskId={this.updateActiveTaskId}
                                      language={language}
                                      active_users={active_users}
                                      gender_as_segment={gender_as_segment}
                                      sort_coll={sort_coll}
                                      onSortEndTask={this.onSortEndTask}
                                      updateShowModal={this.updateShowModal}
                                      updateLive={this.updateLive}
                                      colors={colors}/>
                    </MediaQuery>
                  }
                </div>
              </div>
            </div>
        }
        <div>
          {
            this.state.isGrid &&
              <ActivityGrid01 isGrid={this.state.isGrid}
                tasks={this.state.tasks}
                activeTaskId={this.state.activeTaskId}
                updateActiveTaskId={this.updateActiveTaskId}
                language={language}
                active_users={active_users}
                gender_as_segment={gender_as_segment}
                sort_coll={sort_coll}
                onSortEndTask={this.onSortEndTask}
                updateShowModal={this.updateShowModal}
                updateLive={this.updateLive}
                colors={colors}/>
          }
        </div>
        {
          this.state.modalType &&
          <ReactModal isOpen={this.state.showModal} contentLabel="Activity Editor Modal"
                      shouldCloseOnOverlayClick={this.state.modalType !== 'video'}
                      onRequestClose={() => this.updateShowModal(null, null, null)}
                      className="px-modal-content" overlayClassName="px-modal-overlay">
            {this._setModalBody()}
          </ReactModal>
        }
      </div>
    );
  }

  searchModal = (q, type) => {
    // console.log(q, type);
    let re = null;

    switch(type) {
      case 'segments':
        let segments = null;
        if(q !== '') {
          re = new RegExp(q, 'i');
          segments = this.state.segments.map(s => s.name.match(re) ?
            {...s, modalVisibility: true} : {...s, modalVisibility: false});
        } else {
          segments = this.state.segments.map(s => ({...s, modalVisibility: true}));
        }

        this.setState({segments});
        break;
      case 'tags':
        let tags = null;
        if(q !== '') {
          re = new RegExp(q, 'i');
          tags = this.state.tags.map(t => t.name.match(re) ?
            {...t, modalVisibility: true} : {...t, modalVisibility: false});
        } else {
          tags = this.state.tags.map(t => ({...t, modalVisibility: true}));
        }

        this.setState({tags});
        break;
      case 'tasks':
        let tasks = null;
        if(q !== ''){
          re = new RegExp(q, 'i');
          tasks = this.state.tasks.map(task => task.title.match(re) ?
            {...task, modalVisibility: true} : {...task, modalVisibility: false});
        } else {
          tasks = this.state.tasks.map(task =>  ({...task, modalVisibility: true}));
        }

        this.setState({tasks});
        break;
    }
  };

  updateStartExpireDatesModal = (startDate, endDate) => {
    // console.log(startDate, endDate);
    const actDates = {...this.state.actDates};
    actDates.startDate = startDate;
    actDates.endDate = endDate;

    this.setState({actDates}, () => {
      if(actDates.startDate && actDates.endDate){
        this.setState({tasks: this.state.tasks.map(task => ({...task, required: null}))}, () => {
          this.updateArrayTaskFilters('tasks', 'Dates');
        });
        /*this.setState({[this._getObjectSearchName(this.state.from)]: {...objSearch, idActs: []}}, () => {
          this.updateArrayTaskFilters('tasks', 'Dates');
        });*/
      }
    });
  };

  changeChoiceTypeLive = (questionId, value) => {
    this.updateShowModal(null, null);
    // console.log(questionId, value);
    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        if(question.id === questionId) {
          return {...question, data: {...question.data, subtype: value}, changeSubtype: true};
        } else {
          return question;
        }
      });
      return(
        {...task, questions: questions}
      )
    });

    this.setState({tasks});
  };

  onSortEndAnswerList = (oldIndex, newIndex, questionId, type) => {
    // console.log(oldIndex, newIndex, questionId, type)
    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        if(question.id === questionId) {
          const dataAnswers = type === 'choice' ? [...question.data.answers] : [...question.data.secondary_objectives]
          //console.log(dataAnswers);
          const sortedAnswers = arrayMove(dataAnswers, oldIndex, newIndex);
          // console.log(sortedAnswers);
          const answers = sortedAnswers.map((item, index) => item.img !== undefined ?  {...item, pos: index} : item);
          // console.log(answers);
          if(type === 'choice'){
            return {...question, data: {...question.data, answers: answers}}
          } else {
            return {...question, data: {...question.data, secondary_objectives: answers}}
          }

        } else {
          return question;
        }
      });
      return {...task, questions}
    });
    //console.log(tasks);
    this.setState({tasks});
  };

  addAnswer = (questionId, type) => {
    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        if(question.id === questionId) {
          const dataAnswers = [];
          // Try to convert it in the Init after to load the tasks
          // console.log(question.data.answers);
          Object.keys(question.data.answers).forEach(key => {dataAnswers.push(question.data.answers[key])});

          if(dataAnswers.length < 15){
            if(type === 'normal'){
              dataAnswers.push({id: v4(), text: '', img: '', pos: dataAnswers.length});
            } else {
              const isFound = dataAnswers.filter(answer => answer.img === undefined);
              if(isFound.length === 0){
                dataAnswers.push({id: v4(), text: 'Other Option', pos: 100});
              }
            }
          }
          //console.log(dataAnswers);
          dataAnswers.sort((a, b) => (parseInt(a.pos) > parseInt(b.pos) ? 1 : -1));
          // console.log(dataAnswers);

          return {...question, data: {...question.data, answers: dataAnswers}};
        } else {
          return question;
        }
      });
      return {...task, questions}
    });

    this.setState({tasks});
  };

  deleteAnswer = (questionId, answerId) => {
    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        if(question.id === questionId) {
          const dataAnswers = [];
          // Try to convert it in the Init after to load the tasks
          Object.keys(question.data.answers).forEach(key => {dataAnswers.push(question.data.answers[key]);});

          const answers = dataAnswers.filter(answer => answer.id !== answerId);

          for(let x = 0; x < answers.length; x++){
            if(answers[x].img !== undefined){
              answers[x] = {...answers[x], pos: x};
            }
          }

          if(answers.length < 2){
            answers.push({id: v4(), text: '', img: '', pos: answers.length});
          }

          answers.sort((a, b) => (a.pos > b.pos ? 1 : -1));

          return {...question, data: {...question.data, answers: answers}};
        } else {
          return question;
        }
      });
      return {...task, questions}
    });

    this.setState({tasks});
  };

  updateAnswer = (event, questionId, name, answerId) => {
    let value = null;
    if(name !== 'text'){
      value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    } else {
      value = event;
    }
    //console.log(value);

    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        if(question.id === questionId) {
          const dataAnswers = [];
          // Try to convert it in the Init after to load the tasks
          Object.keys(question.data.answers).forEach(key => {
            dataAnswers.push(question.data.answers[key]);
          });

          const answers = dataAnswers.map(answer => {
            if(answer.id === answerId){
              return {...answer, [name]: value}
            } else {
              return answer
            }
          });
          // Maybe is better to do the sort in the ChEdit
          //answers.sort((a, b) => (parseInt(a.pos) > parseInt(b.pos) ? 1 : -1));

          return {...question, data: {...question.data, answers: answers}};
        } else {
          return question;
        }
      });
      return {...task, questions}
    });

    this.setState({tasks});
  };

  updateObjective = (event, questionId, name, objectiveId, type) => {
    // console.log('updateObjective' + event, questionId, name, objectiveId, type);
    let value = null
    if(name !== 'text'){
      value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    } else {
      value = event;
    }

    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        if(question.id === questionId) {
          if(type === 'secondary_objectives'){
            const dataSecondaryObjectives = []
            Object.keys(question.data.secondary_objectives).forEach(key => {
              dataSecondaryObjectives.push(question.data.secondary_objectives[key])
            })
            const secondaryObjectives = dataSecondaryObjectives.map(objective => {
              if (objective.id === objectiveId) {
                return {...objective, [name]: value};
              } else {
                return objective;
              }
            })

            return {...question, data: {...question.data, secondary_objectives: secondaryObjectives}};
          } else {
            return {...question, data: {...question.data, [type]: value}}
          }
        } else {
          return question;
        }
      });
      return {...task, questions};
    });

    this.setState({tasks});
  };

  deleteObjective = (questionId, answerId) => {
    // console.log('deleteObjective', questionId, answerId);
    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        if(question.id === questionId) {
          const secObjs = [];
          // Try to convert it in the Init after to load the tasks
          Object.keys(question.data.secondary_objectives).forEach(key => {secObjs.push(question.data.secondary_objectives[key])})
          // console.log('secObjs:', secObjs);

          const answers = secObjs.filter(answer => answer.id !== answerId)
          // console.log('answers:', answers);

          if(answers.length < 2){
            answers.push({id: v4(), text: '', pos: answers.length})
          }

          answers.sort((a, b) => (a.pos > b.pos ? 1 : -1))

          return {...question, data: {...question.data, secondary_objectives: answers}}
        } else {
          return question;
        }
      });
      return {...task, questions}
    });

    this.setState({tasks});
  }

  addObjective = (questionId, type) => {
    // console.log('addObjective', questionId, type);
    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        if(question.id === questionId) {
          const secObjs = [];
          // Try to convert it in the Init after to load the tasks
          // console.log('question.data.secondary_objectives:', question.data.secondary_objectives);
          Object.keys(question.data.secondary_objectives).forEach(key => {secObjs.push(question.data.secondary_objectives[key])})

          if(secObjs.length < 15){
            secObjs.push({id: v4(), text: '', pos: secObjs.length});
          }
          //console.log(dataAnswers);
          secObjs.sort((a, b) => (parseInt(a.pos) > parseInt(b.pos) ? 1 : -1));
          // console.log('secObjs:', secObjs);

          return {...question, data: {...question.data, secondary_objectives: secObjs}}
        } else {
          return question;
        }
      });
      return {...task, questions}
    });

    this.setState({tasks});
  }

  /**** ACTIVITY EDITOR - QUESTIONS ****/
  handleShowAddTask = (name) => {
    // console.log(name);
    this.setState(state => ({[name]: !state[name]}), () => {
      // console.log(this.state[name]);
    });
  };

  deleteMedia = (questionId, type, answerId) => {
    //console.log(questionId, type, answerId);
    let tasks =  null;
    if(type === 'image'){
      tasks = this.state.tasks.map(task => {
        const questions = task.questions.map(question => {
          if(question.id === questionId) {
            if(!answerId){
              return {...question, attachment: [], blob: undefined, blobPreview: undefined, previewImg: undefined};
            } else {
              const dataAnswers = [];
              Object.keys(question.data.answers).forEach(key => {
                dataAnswers.push(question.data.answers[key]);
              });
              const answers = dataAnswers.map(answer => {
                if(answer.id === answerId){
                  return {...answer, img: '', blob: undefined, blobPreview: undefined, previewImg: undefined}
                } else {
                  return answer;
                }
              });
              // console.log(answers);
              return {...question, data: {...question.data, answers}}
            }
          } else {
            return {...question}
          }
        });
        return(
          {...task, questions: questions}
        )
      });
    } else {
      tasks = this.state.tasks.map(task => {
        const questions = task.questions.map(question => {
          if(question.id === questionId) {
            return {...question, videoState: null, video_thumbnail: '', video_url: '', video_uuid: ''};
          } else {
            return {...question}
          }
        });
        return(
          {...task, questions: questions}
        )
      });
    }

    this.setState({tasks});
  };

  handleCanAttachMedia = (val, questionId) => {
    if(val){
      const tasks = this.state.tasks.map(task => {
        const questions = task.questions.map(question => {
          if(question.id === questionId){
            let media_options = val.map(x => x.value);
            media_options = question.media ? media_options.length !== 0 ? media_options : ['Images', 'Video'] : [];
            //console.log(question.media_options, media_options);
            //console.log(question.required_options);
            let required_options = [...question.required_options];
            if(media_options.length === 1){
              if(media_options[0] === 'Video'){
                required_options = required_options.filter(opt => opt !== 'Images');
              } else {
                required_options = required_options.filter(opt => opt !== 'Video');
              }
            }
            //console.log(question.required_options.filter(function(item) {return !media_options.includes(item);}));
            return {
              ...question,
              media_options: media_options,
              requiredOptsView: [...question.requiredOptsView
                .filter(opt => opt !== 'Images' && opt !== 'Video'), ...media_options],
              required_options: required_options
            };
          } else {
            return question;
          }
        });

        return(
          {...task, questions: questions}
        )
      });

      this.setState({tasks});
    }
  };

  handleRequired = (val, questionId) => {
    // console.log(val, questionId);
    if (val){
      const tasks = this.state.tasks.map(task => {
        /*const questions = task.questions.map(question => question.id === questionId ?
          {...question, required_options: question.required_options.length === 0 ? ['Text'] : val.map(x => x.value)} :
          {...question});*/
        const questions = task.questions.map(question => {
          if(question.id === questionId){
            //console.log(question.required_options.length);

            return {...question, required_options: val.map(x => x.value)};
          } else {
            return {...question};
          }
        });

        return(
          {...task, questions: questions}
        )
      });

      this.setState({tasks});
    }
  };

  handleMapsSelected = (val, questionId) => {
    // console.log(val.target.value, questionId);
    const map = this.props.communityMaps.find(m => m.id === parseInt(val.target.value));
    // console.log(map);
    const tasks = this.state.tasks.map(task => {
      // console.log(task);
      const questions = task.questions.map(question => question.id === questionId ?
        {...question, maps_id: [val.target.value], map_title: map.title, map_items: map.items} : {...question});
      return(
        {...task, questions: questions}
      )
    });

    this.setState({tasks});
  };

  onEditorStateChangeQuestion = (editor, editorName, name, questionId) => {
    //console.log(editor, editorName, name, questionId);
    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        if(question.id === questionId) {
          //console.log(draftToHtml(convertToRaw(editor.getCurrentContent())).replace(/&nbsp;/gi, ''));
          return {
            ...question,
            title: draftToHtml(convertToRaw(editor.getCurrentContent())).replace(/&nbsp;/gi, ''),
            editorQuestion: editor
          };
        } else {
          return {...question}
        }
      });
      return(
        {...task, questions: questions}
      )
    });

    this.setState({tasks});
  };

  updateVideo = (video, type, questionId) => {
    //console.log('questionId', questionId, 'type', type);
    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        return question.id === questionId ?
          {...question,
            videoState: type,
            video_uuid: video.uuid,
            video_thumbnail: video.medias.thumb,
            //video_thumbnail: type === 'processed' ? video.medias.thumb : '/assets/ellipsis.gif',
            video_url: video.medias.mp4} : {...question};
      });

      return(
        {...task, questions: questions}
      )
    });

    this.setState({tasks});
  };

  updateCropBlob = (blob, blobPreview, type) => {
    //console.log('updateCropBlob', blob, blobPreview, type);
    if(!type){
      this.setState({tasks: this._updateQuestionsTasks('blob', blob, this.state.modalQuestionId)}, () => {
        this.setState({tasks: this._updateQuestionsTasks('blobPreview', blobPreview, this.state.modalQuestionId)});
      });
    } else {
      const tasks = this.state.tasks.map(task => {
        const questions = task.questions.map(question => {
          if(question.id === this.state.modalQuestionId) {
            const dataAnswers = [];
            Object.keys(question.data.answers).forEach(key => {
              dataAnswers.push(question.data.answers[key]);
            });
            const answers = dataAnswers.map(answer => {
              if(answer.id === this.state.modalAnswerId){
                return {...answer, blob, blobPreview}
              } else {
                return answer;
              }
            });
            //console.log(answers);
            return {...question, data: {...question.data, answers}};

          } else {
            return question;
          }
        });
        return(
          {...task, questions: questions}
        )
      });

      this.setState({tasks});
    }

    this.updateShowModal(null, null, null);
  };

  onDropImage = (acceptedFiles, type) => {
    // console.log(acceptedFiles[0], type);
    if(acceptedFiles[0] !== undefined){
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      });
      // console.log(acceptedFiles[0]);
      if(!type){
        this.setState({
          tasks: this._updateQuestionsTasks('previewImg', acceptedFiles[0].preview, this.state.modalQuestionId)
        });
      } else {
        const tasks = this.state.tasks.map(task => {
          // console.log(task);
          const questions = task.questions.map(question => {
            if(question.id === this.state.modalQuestionId) {
              const dataAnswers = [];
              Object.keys(question.data.answers).forEach(key => {
                dataAnswers.push(question.data.answers[key]);
              });
              const answers = dataAnswers.map(answer => {
                if(answer.id === this.state.modalAnswerId){
                  return {...answer, previewImg: acceptedFiles[0].preview}
                } else {
                  return answer;
                }
              });
              //console.log(answers);
              return {...question, data: {...question.data, answers}};

            } else {
              return question;
            }
          });
          return(
            {...task, questions: questions}
          )
        });

        this.setState({tasks});
      }
    } else {
      //showSuccessMsg('Image file too large. Maximum file size 12 Mb');
    }
  };

  updateQuestion = async (questionId) => {
    // console.log('updateQuestion', questionId);
    this.setState({isSavingTask: questionId});
    const task = this.state.tasks.find(task => task.id === this.state.activeTaskId);
    const question = task.questions.find(question => question.id === questionId);
    // console.log('task in UQ:', question)
    const mapsId = question.maps_id.length !== 0 ? question.maps_id : question.maps_id.toString();
    //const textLength = question.editorQuestion.getCurrentContent().getPlainText().length; //It counts 'Enter' key

    const validations = this._validation(question.task_type, question);

    if(validations.length === 0){

      if(typeof question.blob !== 'undefined'){
        await this.saveQuestionImageToDB(question.blob, question.id);
      }

      if ('blob' in question && question.blob === undefined && question.attachment.length === 0) {
        const taskOrig = this.state.tasksOrig.find(task => task.id === this.state.activeTaskId);
        const questionOrig = taskOrig.tasks.find(question => question.id === questionId);

        if (questionOrig.attachment[0]) {
          try {
            await this.deleteImageQuestion(questionOrig.attachment[0].id, questionId );
          } catch (err) {
            console.error('Failed to delete image:', err);
            return;
          }
        }
      }

      const tasksOrig = this.state.tasksOrig.map(taskOrig => {
        // console.log('ActOrig:',taskOrig)
        const questionsOrig = taskOrig.tasks.map(questionOrig => questionOrig.id === questionId ?
          {...question} : questionOrig);
        return {...taskOrig, questions: questionsOrig};
      });

      this.setState({tasksOrig});
      // TO-DO find a way to update several fields at time
      this.setState({tasks: this._updateQuestionsTasks('isQuestionEdit', false, questionId)}, () => {
        //this.setState({tasks: this._updateQuestionsTasks('videoState', null, questionId)})
      });

      let data = null;
      switch(question.task_type){
        case 'Open End':
          data = {
            minChar: question.required ? question.required_options.find(i => i === 'Text') !== undefined ?
                (question.data.minChar === '0' || question.data.minChar === '') ? 1 : question.data.minChar :
                question.data.minChar : question.data.minChar
          }
          break;
        case 'Choice':
          let answers = null;
          // console.log(question.data.answers);
          answers = question.data.answers.map(a => a.img !== undefined ?
            { id: a.id, text: a.text, img: a.img, pos: a.pos, blobPreview: a.blobPreview } : { id: a.id, text: a.text, pos: a.pos, blobPreview: a.blobPreview });
          //answers = question.data.answers.map(a => ({id: a.id, img: a.img, pos: a.pos, text: a.text}));
          // console.log('answers:', answers)
          data = {...question.data, answers};
          break;
        case 'MicroChat':
          data = {...question.data}
          break;
        case 'Canvas':
          data = question.data;
          let minAnnotations = question.required ?
              question.required_options.find(i => i === 'minAnnotations') !== undefined ?
                  (question.data.minAnnotations === '0' || question.data.minAnnotations === '') ?
                      1 : question.data.minAnnotations : question.data.minAnnotations :
              question.data.minAnnotations;
          data.minAnnotations = minAnnotations;
        break;
      }
      // console.log('data:', data)
      const required_options = question.required_options.map(opt => opt !== 'Wordbooks' ? opt : 'Maps');
      //console.log(required_options);

      const indexOf = question.title.indexOf('></iframe');
      const title = indexOf !== -1 ? question.title.slice(0, indexOf) +
        ' allowfullscreen webkitallowfullscreen mozallowfullscreen' + question.title.slice(indexOf) : question.title;
      const video_uuid = question.video_uuid;
      const video_url = question.video_url;
      const video_thumbnail = question.video_thumbnail;
      const s3Url = 'https://pixiebob-videos.s3-ap-southeast-2.amazonaws.com/purge/a-96413510-77a7-0135-1986-0a76e15fb04a/' +
        video_uuid;
      const video_url_purge = s3Url + '_mp4.mp4';
      const video_thumb_purge = s3Url + '_thumb.jpg';
      const question_image_preview = Boolean(question.blobPreview || question.blob);

      $.ajax({
        url: '/tasks/update/' + this.state.activeTaskId,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          task: {
            id: questionId,
            title: title,
            required: question.required,
            required_options: question.required_options.map(opt => opt !== 'Wordbooks' ? opt : 'Maps'),
            show: question.show,
            maps_state: question.maps_state,
            data: data,
            video_uuid, video_url, video_thumbnail, video_url_purge, video_thumb_purge, question_image_preview,
            media: question.media,
            media_options: question.media_options,
            ai_auto_moderation: question.ai_auto_moderation
          },
          mapsId: mapsId
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: () => {
          // Optimistic UI
          if(!Array.isArray(question.data.answers)){
          } else {
            let blobCount = 0;
            question.data.answers.forEach(answer => {
              if(answer.blob !== undefined){
                ++blobCount;
              }
            });

            this.setState({choiceAnsBlob: blobCount}, () => {
              question.data.answers.forEach(answer => {
                if(answer.blob !== undefined){
                  this.saveQuestionAnsImageToDB(answer.blob, question.id, answer.id);
                }
              })
            });
          }
          // console.log(question.changeSubtype);
          if(question.changeSubtype !== undefined){
            if(question.changeSubtype){
              this.choiceResetAnswers(question.id);
            }
          }

          this.setState({isSavingTask: null});
          showSuccessMsg('Success!');

          translate(question.title, 'clean', {entity_type: 'task', entity_id: question.id,
            entity_name: 'title', ai_model: ''})
              .then(() => {
                // console.log('Translation OK');
              })
              .catch((error) => {
                console.log('Translation Error: ' + error);
              });
        },
        error: (err) => {
          showSuccessMsg('There was an error saving the task');
          this.setState({isSavingTask: null});
        }
      });
    } else {
      this.setState({validations}, () => {
        this.updateShowModal('tasks validations', -1);
        this.setState({isSavingTask: null});
      });
    }
  };

  choiceResetAnswers(questionId){
    $.ajax({
      url: '/responses/reset_answers/' + questionId,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {

      }
    });
  }

  _validation = (type, question) => {
    const validations = [];

    const textLength = question.editorQuestion.getCurrentContent().getPlainText().length; //It counts 'Enter' key
    const instructionsVal  = textLength === 0;
    if(instructionsVal){
      validations.push({bool: false, msg: 'Instructions are empty'})
    }

    switch(type){
      case 'Open End':
        break;
      case 'Choice':
        const answers = question.data.answers.filter(item => item.img !== undefined);
        // console.log('answers:', answers)
        const answersVal  = (answers.filter(item => item.text === '')).length !== 0;
        if(answersVal){
          validations.push({bool: false, msg: 'You have answer(s) without text'});
        }
        break;
      case 'MicroChat':
        break;
      case 'Canvas':
        if ( !(question.attachment.length > 0 || question.previewImg !== undefined) ) {
                  validations.push({bool: false, msg: 'Upload an image'})
                }
        break;
    }

    //console.log(validations);
    return validations;
  };

  deleteImageQuestion = (attachmentId, questionId = null) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/tasks/delete_image/' + attachmentId,
        method: 'PATCH',
        dataType: 'JSON',
        data: {question_id: questionId},
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: () => {
          // Optimistic UI
          showSuccessMsg('Success!');
          this.setState(prevState => ({
            tasks: this._updateQuestionsTasks('attachment', [], questionId, false, prevState.tasks),
            tasksOrig: this._updateQuestionsTasksOrig('attachment', [], questionId, false, prevState.tasksOrig)
          }), resolve);
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  };

  saveQuestionImageToDB = (blob, questionId) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", blob);
      formData.append("taskId", this.state.activeTaskId);

      $.ajax({
        url: '/tasks/add_attachment/' + questionId,
        method: 'PATCH',
        data: formData,
        processData: false,
        contentType: false,
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        },
        success: (obj) => {
          // console.log('Type of obj:', typeof obj);
          // if (typeof obj === 'string') {
          //   obj = JSON.parse(obj);
          // }
          this.setState(prevState => ({
            tasks: this._updateQuestionsTasks('attachment', [obj], questionId, false, prevState.tasks),
            tasksOrig: this._updateQuestionsTasksOrig('attachment', [obj], questionId, false, prevState.tasksOrig)
          }), resolve);
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  };

  saveQuestionAnsImageToDB = (blob, questionId, answerId) => {
    // console.log(questionId, answerId);
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("answerId", answerId);

    $.ajax({
      url: '/tasks/add_answer_attachment/' + questionId,
      method:'PATCH',
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: obj => {
        // console.log(obj.url);
        const choiceAnsUrl = [...this.state.choiceAnsUrl];
        choiceAnsUrl.push({answerId, url: obj.url});
        this.setState({choiceAnsUrl}, () => {
          // console.log(this.state.choiceAnsBlob, this.state.choiceAnsUrl.length);
          if(this.state.choiceAnsBlob === this.state.choiceAnsUrl.length){
            // console.log(this.state.choiceAnsUrl, 'save to db');
            $.ajax({
              url: '/tasks/update_choice_data/' + questionId,
              method: 'PATCH',
              dataType: 'JSON',
              data: {
                //task: {
                  urls: this.state.choiceAnsUrl,
                //},
              },
              beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
              success: () => {
                this.setState({choiceAnsUrl: []})
              }
            });
          }
        })
      }
    });
  };

  handleQuestionColorsChange = (id, color, colorSet, colorSetIndex) => {
    // substitute a color from a given color set
    for (let index = 0; index < colorSet.length; index++) {
          if (index === colorSetIndex) {
            colorSet[index] = color;
            break;
          }
    }
    // updates the question's state with latest colorSet
    this.setState({tasks: this._updateQuestionsTasks('markerColors', colorSet, id, true)})
  }

  handleChangeQuestion = (event, questionId, dataFlag) => { //dataFlag should be true when the key/value pair should be stored in the data column
    let nonModifiableTargetNames = ['minChar', 'minAnnotations', 'highlightMarker', 'freeHandMarker', 'arrowsMarker', 'shapesMarker',
      'calloutsMarker', 'textMarker', 'commentsOnMarkers' ]
    let name = event.target.name;
    name = nonModifiableTargetNames.includes(name) ? name : (dataFlag && name !== 'random') ?
      name.slice(0, - (questionId.toString().length)) : name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const activeTask = this.state.tasks.find(task => task.id === this.state.activeTaskId);
    //console.log(activeTask.visibility);
    // TO-DO Improve duplicate this.setState or move to one function
    if(activeTask.visibility !== 'enabled'){
      this.setState({tasks: this._updateQuestionsTasks(name, value, questionId, dataFlag)}, () => {
        if(name === 'media' || name === 'maps_state'){
          this.setState({tasks: this._setRequiredOptionsV(questionId)}, () => {
            if(name === 'media'){
              if(value){
                this.setState({tasks: this._initMediaOpts(questionId)});
              } else {
                this.setState({tasks: this._deletedRequiredOpts(name, questionId)});
              }
            }

            if(name === 'maps_state' && !value){
              this.setState({tasks: this._deletedRequiredOpts(name, questionId)});
            }
          });
        }
      });
    } else {
      if(name !== 'subtype'){
        //console.log('Ready to');
        this.setState({tasks: this._updateQuestionsTasks(name, value, questionId, dataFlag)}, () => {
          //console.log('Ok');
          if(name === 'media' || name === 'maps_state'){
            this.setState({tasks: this._setRequiredOptionsV(questionId)}, () => {
              if(name === 'media'){
                if(value){
                  this.setState({tasks: this._initMediaOpts(questionId)});
                } else {
                  this.setState({tasks: this._deletedRequiredOpts(name, questionId)});
                }
              }
              // console.log(name === 'maps_state' && !value);
              if(name === 'maps_state' && !value){
                this.setState({tasks: this._deletedRequiredOpts(name, questionId)});
              }
            });
          }
        });
      } else {
        this.updateShowModal('choice live', questionId);
      }
    }
  };

  _initMediaOpts = (questionId) => {
    return this.state.tasks.map(task => {
      const questions = task.questions.map(q => {
        if(q.id === questionId){
          return {...q, media_options: ['Images', 'Video']
          };
        } else {
          return q
        }
      });
      return(
        {...task, questions: questions}
      )
    });
  };

  _deletedRequiredOpts = (type, questionId) => {
    // console.log('type:', type);
    return this.state.tasks.map(task => {
      const questions = task.questions.map(q => {
        if(q.id === questionId){
          // console.log('q.required_options:', q.required_options);
          return {
            ...q,
            required_options: type === 'media' ?
              q.required_options.filter(x => !x.toLowerCase().includes('i')) :
              q.required_options.filter(x => x !== 'Wordbooks'),
            //media_options: []
          };
        } else {
          return q
        }
      });
      return(
        {...task, questions: questions}
      )
    });
  };

  _setRequiredOptionsV(questionId){
    return this.state.tasks.map(task => {
      const questions = task.questions.map(question => {
        if(question.id === questionId) {
          return {...question, requiredOptsView: this._setRequiredOptionsView(question, 'update')};
        } else {
          return {...question}
        }
      });
      return(
        {...task, questions: questions}
      )
    });
  }

  onSortEndQuestion = ({oldIndex, newIndex}) => {
    // One time, 1 blob was duplicate when I drag and move questions in Edit mode
    // But after several test I can't duplicate the error, it just happened once
    // When you move questions, A and B => B and A, then you will see for a moment the last position image, but
    // it is immediately replaced by the correct, maybe the OEEdit componentDidMount
    // UPDATE QUESTION looks the error - Possible FIX: I'm adding question.blobPreview
    // console.log('onSortEndQuestion', oldIndex, newIndex);
    const tasks = this.state.tasks.map(task => task.id === this.state.activeTaskId ?
      {...task, questions: arrayMove(task.questions, oldIndex, newIndex)} : task);

    this.setState({tasks}, () => {
      this.saveQuestionsPosition();
    });
  };

  updateQuestionMode = (questionId) => {
    //console.log('updateQuestionMode', questionId);
    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => question.id === questionId ?
        {...question, isQuestionEdit: !question.isQuestionEdit} : {...question});
      return(
        {...task, questions: questions}
      )
    });

    this.setState({tasks});
  };

  cancelEditQuestion = (questionId) => {
    //console.log('cancelEditQuestion', questionId);
    const taskOrig = this.state.tasksOrig.find(task => task.id === this.state.activeTaskId);
    //console.log(taskOrig);
    const questionOrig = taskOrig.tasks.find(question => question.id === questionId);
    //console.log(questionOrig);
    const tasks = this.state.tasks.map(task => {
      const questions = task.questions.map(question => question.id === questionId ?
        {...questionOrig, isQuestionEdit: false,
          editorQuestion: this._setEditorState(questionOrig.title),
          requiredOptsView: this.taskNeedRequiredOptions(questionOrig.task_type) &&
            this._setRequiredOptionsView(questionOrig, 'update')} : {...question});

      return({...task, questions: questions})
    });

    this.setState({tasks});
  };

  addQuestion = (questionType, position) => {
    // console.log(questionType, position);
    const newQuestion = {...this._defaultQuestionAttributes(questionType, position), uuid: v4()};
    // console.log('newQuestion:', newQuestion)

    $.ajax({
      url: '/tasks/create/' + this.state.activeTaskId,
      method: "POST",
      dataType: "JSON",
      data: {
        task: {
          title: newQuestion.title,
          task_type: newQuestion.question_type,
          position: newQuestion.position,
          data: newQuestion.data,
          required: newQuestion.required,
          required_options: newQuestion.required_options,
          show: newQuestion.show,
          media: newQuestion.media,
          media_options: newQuestion.media_options,
          maps_state: newQuestion.maps_state,
          maps_id: newQuestion.maps_id,
          favorite: newQuestion.favorite,
          video_uuid: newQuestion.video_uuid,
          video_url: newQuestion.video_url,
          video_thumbnail: newQuestion.video_thumbnail,
          ai_auto_moderation: newQuestion.ai_auto_moderation
        }
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: newQuestionDB => {
        // console.log('newQuestionDB:', newQuestionDB)
        const newQuestionMerged = {...newQuestionDB,
          isQuestionEdit: true,
          editorQuestion: this._setEditorState(null),
          requiredOptsView: this.taskNeedRequiredOptions(newQuestionDB.task_type) &&
            this._setRequiredOptionsView(newQuestionDB, 'init'),
          videoState: null
        };
        //console.log(newQuestionMerged);
        const tasks = this.state.tasks.map(task => {
          if(task.id === this.state.activeTaskId){
            const taskEditToUpdate = {...task};
            const taskQuestions = [...taskEditToUpdate.questions];
            taskQuestions.push(newQuestionMerged);
            //console.log('taskQuestions:', taskQuestions);
            const questionsSorted = arrayMove(taskQuestions, taskQuestions.length - 1, position);
            //console.log('questionsSorted:', questionsSorted);
            taskEditToUpdate.questions = questionsSorted.map((question, index) => ({...question, position: index}));
            //console.log(taskEditToUpdate.questions);
            return {...taskEditToUpdate}
          } else {
            return {...task};
          }
        });
        //console.log('tasks:', tasks);
        this.setState({tasks, showAddTaskTop: false, showAddTaskBottom: false, showAddTaskMiddle: false}, () => {
          this.saveQuestionsPosition();
          const task = this.state.tasks.find(task => task.id === this.state.activeTaskId);  // BE CAREFUL it has tasks and questions
          // but here only questions have the right number of tasks
          //console.log(task);
          const questionToAdd = task.questions.find(question => question.id === newQuestionDB.id);
          //console.log(questionToAdd);
          // tasksOrig has only tasks
          const tasksOrig = this.state.tasksOrig.map(taskOrig => {
            //console.log(taskOrig);
            if(taskOrig.id === this.state.activeTaskId){
              const questions = [...taskOrig.tasks];
              questions.push(questionToAdd);
              taskOrig.tasks = questions;
              //console.log(taskOrig);
              return taskOrig;
            } else {
              return taskOrig;
            }
          });

          this.setState({tasksOrig});
        });
      }
    });
  };

  saveQuestionsPosition(){
    const task = this.state.tasks.find(task => task.id === this.state.activeTaskId);
    // console.log(task);
    if(task.questions.length !== 1){
      // Maybe to create a new controller function just to update positions, don't get back anything (optimization)
      $.ajax({
        url: '/tasks/update/' + this.state.activeTaskId,
        method: 'PATCH',
        dataType: 'JSON',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
          tasksPositions: task.questions.map(question => question.id)
        },
        success: () => {
          // Optimistic UI
        }
      });
    }
  }

  deleteQuestion = (questionId) => {
    //console.log(questionId);
    this.setState({showModal: false});

    const tasks = this.state.tasks.map(task => {
      if(task.id === this.state.activeTaskId){
        const questions = task.questions.filter(question => question.id !== questionId);

        return {...task, questions: questions}
      } else {
        return task;
      }
    });

    const tasksOrig = this.state.tasksOrig.map(task => {
      if(task.id === this.state.activeTaskId){
        // console.log(task);
        const questions = task.tasks.filter(question => question.id !== questionId);

        return {...task, questions: questions}
      } else {
        return task;
      }
    });

    this.setState({tasks, tasksOrig, delTaskC1: false});

    $.ajax({
      url: '/tasks/delete/' + questionId,
      method: 'PATCH',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
        //Optimistic UI
        showSuccessMsg('Success!');
        this.saveQuestionsPosition();

        axios.patch('/tasks/purge_media_task/' + questionId, {}, getCSRF())
          .then((r) => {
            // console.log(r.data);
          }).catch(error => {
          // console.log(error);
        });
      }
    })
  };

  /****
  /**** ACTIVITY EDITOR - ATTRIBUTES ****/
  updateArrayTaskFilters = (type, filter) => {
    // console.log(type, filter);
    let tasks = [...this.state.tasks];
    const indexOf = tasks.findIndex(task => task.id === this.state.activeTaskId);
    const taskToUpdate = {...tasks[indexOf]};

    switch(type){
      case 'segments':
        switch(filter){
          case 'All':
            taskToUpdate.segments = this.state.segments.map(s => ({value: s.id, label: s.name, color: s.color}));
            break;
          case 'None':
            taskToUpdate.segments = [];
            break;
        }
        break;
      case 'tags':
        switch(filter){
          case 'None':
            taskToUpdate.tag = null;
            break;
        }
        break;
      case 'tasks':
        switch(filter){
          case 'None':
            taskToUpdate.required = null;
            break;
          case 'Dates':
            const actDates = {...this.state.actDates};

            if(actDates.startDate && actDates.endDate) {
              const startDate = moment(actDates.startDate).format('YYYY-MM-DD');
              const endDate = moment(actDates.endDate).format('YYYY-MM-DD');
              //console.log(startDate, endDate);
              switch(actDates.name){
                case 'creation':
                  tasks = this.state.tasks.map(task => {
                    const createdAt = moment(task.created_at).format('YYYY-MM-DD');
                    // console.log(createdAt, startDate, endDate);
                    if(createdAt >= startDate && createdAt <= endDate) {
                      return {...task, modalVisibility: true}
                    } else {
                      return {...task, modalVisibility: false}
                    }
                  });
                  break;
                case 'start':
                  tasks = this.state.tasks.map(task => {
                    if(task.start){
                      const taskStart = moment(task.start).format('YYYY-MM-DD');
                      // console.log(taskStart, startDate, endDate);
                      if(taskStart >= startDate && taskStart <= endDate) {
                        return {...task, modalVisibility: true}
                      } else {
                        return {...task, modalVisibility: false}
                      }
                    } else {
                      return {...task, modalVisibility: true}
                    }
                  });
                  break;
                case 'expiry':
                  tasks = this.state.tasks.map(task => {
                    if(task.expire){
                      const expireDate = moment(task.expire).format('YYYY-MM-DD');
                      // console.log(expireDate, startDate, endDate);
                      if(expireDate >= startDate && expireDate <= endDate){
                        return {...task, modalVisibility: true}
                      } else {
                        return {...task, modalVisibility: false}
                      }
                    } else {
                      return {...task, modalVisibility: true}
                    }
                  });
                  //console.log(tasks);
                  break;
              }
            }
            break;
        }
        break;
    }

    tasks[indexOf] = taskToUpdate;

    this.setState({tasks});
  };

  updateArrayTask = (obj, type) => {
    // console.log(obj, type);
    const tasks = [...this.state.tasks];
    const indexOf = tasks.findIndex(task => task.id === this.state.activeTaskId);
    const taskToUpdate = {...tasks[indexOf]};

    switch(type){
      case 'segment':
        const isFound = taskToUpdate.segments.find(segment => segment.value === obj.id);
        if(isFound === undefined){
          taskToUpdate.segments.push({value: obj.id, label: obj.name, color: obj.color});
        } else {
          taskToUpdate.segments = taskToUpdate.segments.filter(segment => segment.value !== obj.id)
        }
        break;
      case 'tag':
        taskToUpdate.tag = obj;
        break;
      case 'task':
        taskToUpdate.required = {label: obj.title, value: obj.id};
        break;
    }

    tasks[indexOf] = taskToUpdate;

    this.setState({tasks});
  };

  updateShowModalSelect = () => {
    this.setState(state => ({showModalSelect: !state.showModalSelect}));
  };

  onEditorStateChange = (editor, editorName, name) => {
    //console.log(name);
    const tasks = [...this.state.tasks];
    const indexOf = tasks.findIndex(task => task.id === this.state.activeTaskId);
    const taskToUpdate = {...tasks[indexOf]};

    taskToUpdate[name] = draftToHtml(convertToRaw(editor.getCurrentContent()));
    taskToUpdate[editorName] = editor;
    tasks[indexOf] = taskToUpdate;

    this.setState({tasks});
  };

  updateStartExpireDates = (start, expire) => {
    this.setState({tasks: this._updateTasks('start', start)}, () => {
      //if(expire){
        this.setState({tasks: this._updateTasks('expire', expire)});
      //}
    });
  };

  updateTaskAttributes = () => {
    const tasks = [...this.state.tasks];
    const tasksOrig = [...this.state.tasksOrig];
    const task = tasks.find(task => task.id === this.state.activeTaskId);
    const indexOf = tasksOrig.findIndex(task => task.id === this.state.activeTaskId);
    const taskOrigToUpdate = {...tasksOrig[indexOf]};

    tasksOrig[indexOf] = this._mergeBtoA(taskOrigToUpdate, task);

    this.setState({tasks: this._updateTasks('isAttrEdit', null), tasksOrig});

    $.ajax({
      url: '/activities/update/' + this.props.communityId,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        id: task.id,
        title: task.title,
        instructions: task.instructions,
        start: task.start,
        expire: task.expire,
        social: task.social,
        period: task.period,
        overdue: task.overdue,
        participants_view: task.participants_view,
        segments: task.segments.map(segment => segment.value),
        tag_id: task.tag ? task.tag.id : null,
        required: task.required ? task.required.value : null
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: tasksDB => {
        //Optimistic UI
        //console.log(tasksDB);
        showSuccessMsg('Success!');
      }
    });
  };

  cancelEditTaskAttributes = () => {
    const tasks = [...this.state.tasks];
    const tasksOrig = [...this.state.tasksOrig];

    const taskOrig = tasksOrig.find(task => task.id === this.state.activeTaskId);
    const indexOf = tasks.findIndex(task => task.id === this.state.activeTaskId);
    const taskToUpdate = {...tasks[indexOf]};

    taskToUpdate.isAttrEdit = false;
    tasks[indexOf] = this._mergeBtoA(taskToUpdate, taskOrig);

    this.setState({tasks});
  };

  updateTaskAttributeMode = () => {
    this.setState({tasks: this._updateTasks('isAttrEdit', null)});
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    // console.log(name, value);
    if(name !== 'slcActDates'){
      this.setState({tasks: this._updateTasks(name, value)});
    } else {
      this.setState({actDates: {...this.state.actDates, name: value}}, () => {
        if(this.state.actDates.startDate && this.state.actDates.endDate){
          this.setState({tasks: this.state.tasks.map(task => ({...task, required: null}))}, () => {
            this.updateArrayTaskFilters('tasks', 'Dates');
          });
        }
      });
    }
  };

  /****
  /**** ACTIVITY LIST ****/
  copyActivity = (copyActC1, copyActC2) => {
    // console.log(copyActC1, copyActC2);
    $.ajax({
      url: '/activities/duplicate/' + this.state.activeTaskId,
      method: 'POST',
      dataType: 'JSON',
      data: {
        copyActC1: copyActC1,
        copyActC2: copyActC2,
        position: this.state.tasks.length
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: tasksDB => {
        // console.log(tasksDB);
        const taskDB = tasksDB[tasksDB.length - 1];
        // console.log(taskDB);
        const newTask = {...taskDB, uuid: v4(), isAttrEdit: true, filterVisibility: true,
          editorTaskInstructions: this._setEditorState(taskDB.instructions),
          segments: taskDB.segments.map(s => ({label: s.name, value: s.id, color: s.color})),
          questions: taskDB.tasks.map(question => ({...question, isQuestionEdit: false,
            editorQuestion: this._setEditorState(question.title),
            requiredOptsView: this._setRequiredOptionsView(question, 'init')}))
        };
        // console.log(newTask);
        const tasks = [...this.state.tasks];
        const tasksOrig = [...this.state.tasksOrig];

        tasks.push(newTask);
        tasksOrig.push(newTask);
        this.setState({tasks, tasksOrig}, () => {
          this.updateActiveTaskId(newTask.id);
        });

        // TO-DO if we accept to move scroll down then put this feature
        // after the new task is added (setState) in a callback, because right now it would be fails to calculate
        // the right height of the container
        const div = document.getElementById('divCardsContainer1');
        div.scrollTop = div.scrollHeight;
      }
    });

    this.setState({showModal: false, copyActC1: true, copyActC2: true});
  };

  updateLive = (activeTaskId) => {
    this.setState({activeTaskId}, () => {
      const task = this.state.tasks.find(task => task.id === this.state.activeTaskId);

      if(this.props.communityState !== 'Closed'){
        if(task.questions.length !== 0){
          this.updateShowModal('live on', -1);
        } else {
          this.updateShowModal('live validation', -1);
        }
      } else {
        alert('This community is Closed');
      }
    });
  };

  confirmUpdateLive = () => {
    const task = this.state.tasks.find(task => task.id === this.state.activeTaskId);
    const visibility = task.visibility === 'disabled' ? 'enabled' : 'disabled';
    //console.log(task.title);
    this.updateShowModal(null, null);

    const tasksOrig = [...this.state.tasksOrig];
    //const task = tasks.find(task => task.id === this.state.activeTaskId);
    const indexOf = tasksOrig.findIndex(task => task.id === this.state.activeTaskId);
    const taskOrigToUpdate = {...tasksOrig[indexOf]};

    tasksOrig[indexOf] = this._mergeBtoA(taskOrigToUpdate, task);

    this.setState({tasks: this._updateTasks('visibility',
        task.visibility === 'enabled' ? 'disabled' : 'enabled'), tasksOrig});

    let is_sent_available = task.is_sent_available;
    if(task.expire){
      const now = moment().format('YYYY-MM-DD');
      const expire = moment(task.expire).format('YYYY-MM-DD');
      // console.log('now:', now, 'expire:', expire);
      if(now === expire){
        // console.log('Send Emails/Noti');
        is_sent_available = true;
      }
    }

    $.ajax({
      url: '/activities/update_visibility/' + this.props.communityId + '/' + this.state.activeTaskId,
      method: 'PATCH',
      dataType: 'JSON',
      data: { visibility: visibility, is_sent_available },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: tasksDB => {
        // TO-DO TEST Optimistic UI in order to have the same Optimistic UI as the others
        //console.log(tasksDB);
        /*const taskDB = _.find(tasksDB, task => task.id === this.state.activeTaskId);
        const tasks = [...this.state.tasks];
        const taskEdit = tasks.find(task => task.id === this.state.activeTaskId);
        const updatedTask = {...taskEdit, ...taskDB,
          segments: taskDB.segments.map(s => ({label: s.name, value: s.id, color: s.color}))};

        const indexOf = tasks.findIndex(task => task.id === this.state.activeTaskId);
        tasks[indexOf] = updatedTask;

        //this.updateTasks(tasks);
        this.setState({tasks})*/
      }
    });
  };

  updateShowModal = (modalType, modalQuestionId, modalAnswerId) => {
    //console.log(modalType, modalQuestionId, modalAnswerId);
    this.setState(state => ({showModal: !state.showModal, modalType, modalQuestionId, modalAnswerId,
      tasks: state.tasks.map(task => ({...task, modalVisibility: true})),
      actDates: {name: 'creation', startDate: null, endDate: null},
      segments: this.state.segments.map(s => ({...s, modalVisibility: true})),
      tags: this.state.tags.map(t => ({...t, modalVisibility: true}))}), () => {
      if(!this.state.showModal){
        this.setState({activityNameToDelete: '', delActC1: false, delActC2: false, delTaskC1: false,
          copyActC1: true, copyActC2: true});
      }
    });
  };

  updateModalAction = (modalType, modalQuestionId, modalAnswerId, action, activityId, objs) => {
    // console.log(modalType, modalQuestionId, modalAnswerId, action, activityId, objs);
    this.setState(prevState => ({showModal: !prevState.showModal, modalType, modalQuestionId, modalAnswerId}), () => {
      let tasks = [];

      switch(action){
        case 'updateActSegments':
          tasks = this.state.tasks.map(act => act.id === activityId ? {...act, segments: objs} : act);
          break;
        case 'updateActTag':
          tasks = this.state.tasks.map(act => act.id === activityId ? {...act, tag: objs} : act);
          break;
        case 'updateActRequired':
          tasks = this.state.tasks.map(act => act.id === activityId ? {...act, required: objs} : act);
          break;
      }

      this.setState({tasks});
    });
  };

  onSortEndTask = ({oldIndex, newIndex}) => {
    const tasks = arrayMove(this.state.tasks, oldIndex, newIndex);

    this.setState({tasks}, () => {
      this.saveTasksPositions();
    });
  };

  saveTasksPositions = () => {
    if(this.state.tasks.length !== 1){
      this.setState({sort_coll: 'custom'}, () => { this.updateTaskSort() });

      $.ajax({
        url: '/activities/update/' + this.props.communityId,
        method: 'PATCH',
        dataType: 'JSON',
        data: {
          tasksPositions: _.pluck(this.state.tasks, 'id')
        },
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        success: () => {
          // Optimistic UI
        }
      });
    }
  };

  handleIsGrid = () => {
    this.setState(state => ({isGrid: !state.isGrid}));
  };

  updateActiveTaskId = (activeTaskId) => {
    //console.log(activeTaskId);
    this.setState({activeTaskId, isGrid: false});
  };

  addTask = () => {
    this.setState({isSavingNewAct: true})
    $.ajax({
      url: '/activities/create/' + this.props.communityId,
      method: 'POST',
      dataType: 'JSON',
      data: {
        position: this.state.tasks.length
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: tasksDB => {
        // console.log(tasksDB);
        const newTask = {...tasksDB[tasksDB.length - 1], uuid: v4(), isAttrEdit: true, filterVisibility: true,
          editorTaskInstructions: this._setEditorState(null), questions: []};  //Not yet using uuid, questions is temp
        //console.log(newTask);
        const tasks = [...this.state.tasks];
        const tasksOrig = [...this.state.tasksOrig];

        tasks.push(newTask);
        tasksOrig.push(newTask);
        this.setState({tasks, tasksOrig, isSavingNewAct: false}, () => {
          this.updateActiveTaskId(newTask.id);
          // TO-DO if we accept to move scroll down then put this feature
          // after the new task is added (setState) in a callback, because right now it would be fails to calculate
          // the right height of the container
          const div = document.getElementById('divCardsContainer1');
          div.scrollTop = div.scrollHeight;
        });
      }
    });
  };

  searchTask = () => {
    let tasks = null;
    if(this.state.q !== ''){
      const q = new RegExp(this.state.q, 'i');
      tasks = this.state.tasks.map(task =>  ({...task, filterVisibility: task.title.match(q)}));
    } else {
      tasks = this.state.tasks.map(task => ({...task, filterVisibility: true}));
    }

    this.setState({tasks});
  };

  handleIsFilterLock = () => {
    // console.log('handleIsFilterLock');
    this.setState(state => ({isFilterLock: !state.isFilterLock}), () => {
      // console.log(this.state.isFilterLock);
      if(this.state.isFilterLock){
        this.setState({sort_part: this.state.sort_coll}, () => {
          this.updateTaskSort();
        });
      }
    });
  };

  handleChangeFilters = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value}, () => {
      if(name !== 'q'){
        if(name === 'sort_coll'){
          this.setState({tasks: sortCollTasks(this.state.tasks, value)});
        }

        this.updateTaskSort();
      }

      if(this.state.isFilterLock){
        if(name === 'sort_coll'){
          this.setState({sort_part: this.state.sort_coll}, () => {
            this.updateTaskSort();
          })
        } else {
          this.setState({sort_coll: this.state.sort_part}, () => {
            this.updateTaskSort();
          })
        }

      }
    });
  };

  updateTaskSort(){
    $.ajax({
      url: '/communities/update_task_sort/' + this.props.communityId,
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        sort_coll: this.state.sort_coll,
        sort_part: this.state.sort_part
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
      }
    });
  }

  deleteTask = (taskId) => {
    // console.log(taskId);
    const tasks1 = this.state.tasks.filter(task => task.id !== taskId);
    const tasksOrig1 = this.state.tasksOrig.filter(task => task.id !== taskId);

    const tasks = tasks1.map(task => {
      if(task.required){
        if(task.required.value === taskId){
          return {...task, required: ''}
        } else {
          return task;
        }
      } else {
        return task;
      }
    });

    const tasksOrig = tasksOrig1.map(task => {
      if(task.required){
        if(task.required.value === taskId){
          return {...task, required: ''}
        } else {
          return task;
        }
      } else {
        return task;
      }
    });


    this.setState({showModal: false, activeTaskId: null, activityNameToDelete: '', delActC1: false, delActC2: false,
      tasks, tasksOrig});

    $.ajax({
      url: '/activities/delete/' + this.props.communityId,
      method: 'PATCH',
      dataType: 'JSON',
      data: { id: taskId },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: () => {
        // Optimistic UI
        this.saveTasksPositions();
        showSuccessMsg('Success!');
      }
    });
  };

  /**** UTILS ****/
  _updateQuestionsTasks(name, value, questionId, dataFlag, tasks = this.state.tasks) {
    // console.log('Updating Questions Tasks');
    return tasks.map(task => {
      const questions = task.questions.map(question => {
        if (question.id === questionId) {
          if (dataFlag) {
            return { ...question, data: { ...question.data, [name]: value } };
          } else {
            // console.log(`Updated Question Task, NAME: ${name} VALUE: ${JSON.stringify(value, null, 2)}`);
            return { ...question, [name]: value };
          }
        } else {
          return question;
        }
      });
      return { ...task, questions: questions };
    });
  }

  _updateQuestionsTasksOrig(name, value, questionId, dataFlag, tasksOrig = this.state.tasksOrig) {
    // console.log('Updating Questions TasksOrig');
    return tasksOrig.map(task => {
      const tasks = task.tasks.map(subtask => {
        if (subtask.id === questionId) {
          if (dataFlag) {
            return { ...subtask, data: { ...subtask.data, [name]: value } };
          } else {
            // console.log(`Updated Question TaskOrig, NAME: ${name} VALUE: ${JSON.stringify(value, null, 2)}`);
            return { ...subtask, [name]: value };
          }
        } else {
          return subtask;
        }
      });
      return { ...task, tasks: tasks };
    });
  }

  _mergeBtoA(taskA, taskB){
    // TO-DO Why when I use the {...a, ...b} technique to merge 2 objects, and after update tasksOrig,
    // the tasks is updated too?, For that reason I'm creating a new object with names values, I think is for
    // the immutable rule
    //console.log(taskB.segments);
    return {
      ...taskA,
      title: taskB.title,
      start: taskB.start,
      expire: taskB.expire,
      overdue: taskB.overdue,
      instructions: taskB.instructions,
      editorTaskInstructions: this._setEditorState(taskB.instructions),
      social: taskB.social,
      participants_view: taskB.participants_view,
      segments: taskB.segments.map(segment => ({
        value: segment.id === undefined ? segment.value : segment.id,
        label: segment.name === undefined ? segment.label : segment.name,
        color: segment.color})),
      tag: taskB.tag,
      required: taskB.required
    }
  }

  _defaultQuestionAttributes(questionType, position){
    // Add the new maps items and if necessary the other fields of a question from DB as attachment[]
    let attributes = {
      title: "",
      question_type: questionType,
      position: position,
      data: {},
      required: false,
      show: true,
      favorite: false,
      attachment: [],
      video_uuid: '',
      video_url: '',
      video_thumbnail: '',
      ai_auto_moderation: false
    };

    switch(questionType){
      case 'Open End':
      case 'Multimedia':
        const media = questionType !== 'Open End'
        const required_options = questionType === 'Open End' ? ['Text'] : ['Images']

        attributes = {
          ...attributes,
          data: {minChar: 1},
          required_options,
          media,
          media_options: ['Images', 'Video'],
          maps_state: false,
          maps_id: [this.props.communityMaps[0].id],
        };
        break;
      case 'Choice':
        const answers = [];
        for(let x = 0; x < 2; x++){
          answers.push({id: v4(), text: '', img: '', pos: x});
        }

        attributes = {
          ...attributes,
          data: {subtype: 'Single', focusOn: 'Text', random: false, answers}
        };
        break;
      case 'Canvas':
        attributes = {
          ...attributes,
          data: {minAnnotations: 1, subtype: 'freeAnnotations', highlightMarker: true,
            freeHandMarker: false, arrowsMarker: false, shapesMarker: false,
            calloutsMarker: false, textMarker: false,
            markerColors: ['#ffff00', '#008000', '#ff0000', '#000000', '#0000ff'],
            commentsOnMarkers: false
          },
          required_options: ['minAnnotations'],
          };
        break;
      case 'MicroChat':
        const secondary_objectives = [{id: v4(), text: '', pos: 0}]
        //console.log('secondary_objectives:', secondary_objectives)
        attributes = {
          ...attributes,
          required_options: ['Text'],
          data: { main_objective: '', secondary_objectives, role: '', interviewees: '',
            name: '', avatar: '', conversation_style: '', language: '' }
        }
        break;
    }

    return attributes
  }

  _updateTasks(name, value){
    const tasks = [...this.state.tasks];
    const indexOf = tasks.findIndex(task => task.id === this.state.activeTaskId);
    const taskToUpdate = {...tasks[indexOf]};

    if(name !== 'isAttrEdit'){
      taskToUpdate[name] = value;
    } else {
      taskToUpdate[name] = !taskToUpdate.isAttrEdit;
    }
    //console.log('taskToUpdate:', taskToUpdate.title);
    tasks[indexOf] = taskToUpdate;
    //console.log('tasks:', tasks);
    return tasks;
  }

  updateConditionsDelActivity = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({[name]: value});
  };

  _getCommunityTasks = () => {
    $.ajax({
      url: '/activities/get/' + this.props.communityId,
      method: 'GET',
      dataType: 'JSON',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: tasksDB => {
        //console.log(tasksDB);
        const tasks = tasksDB.map(activity => {
          //console.log('activity.segments:', activity.segments);
          const questions = activity.tasks.map(task => {
            //console.log(task);
            return{
            ...task,
            isQuestionEdit: false,
            editorQuestion: this._setEditorState(task.title),
            requiredOptsView: this._setRequiredOptionsView(task, 'init'),
            videoState: null,
            required_options: task.required_options ?
              task.required_options.map(req => req === 'Maps' ? 'Wordbooks' : req) : []
          }});

          return {...activity, uuid: v4(), isAttrEdit: false, questions: questions, filterVisibility: true,
            modalVisibility: true, editorTaskInstructions: this._setEditorState(activity.instructions),
            segments: activity.segments.map(s => ({label: s.name, value: s.id, color: s.color}))
          };
        });

        this.setState({tasksOrig: tasksDB, tasks: sortCollTasks(tasks, this.state.sort_coll), isLoading: false})
      }
    });
  };

  _getCommunitySegments(){
    $.ajax({
      url: '/communities/get_segments/' + this.props.communityId,
      method: 'GET',
      dataType: 'JSON',
      success: segments => {
        if(this.props.gender_as_segment){
          segments.push({id: 'Female', name: 'Female', color: '#000'}, {id: 'Male', name: 'Male', color: '#000'},
            {id: 'Other Gender', name: 'Other Gender', color: '#000'});
        }
        this.setState({segments: segments.map(s => ({...s, modalVisibility: true}))});
      }
    });
  }

  _getCommunityTags = () => {
    $.ajax({
      url: '/communities/get_tags/' + this.props.communityId,
      method: 'GET',
      dataType: 'JSON',
      success: tags => {
        this.setState({tags: tags.map(t => ({...t, modalVisibility: true}))});
      }
    });
  };

  _getCommunityMaps(){
    $.ajax({
      url: '/maps/get_maps/' + this.props.communityId,
      method: 'GET',
      dataType: 'JSON',
      success: maps => {
        // console.log(maps);
        this.setState({mapsSelect: maps.maps_a.map(map => map)});
        //this.setState({mapsSelect: maps.maps_a.map(map => ({value: map.id, label: map.title['en']}))});
      }
    });
  }

  _setEditorState(text){
    let component = null;
    if(text){
      const contentBlock = htmlToDraft(text);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

      component = EditorState.createWithContent(contentState);
    } else {
      component = EditorState.createEmpty();
    }

    return component;
  }

  _setRequiredOptionsView = (question, type) => {
    let options = [];

    switch (question.task_type) {
      case 'Open End':
        options.push('Text', 'Characters');
      // Fall through to handle media and map state as in the default case
      case 'Multimedia':
        if (type === 'init') {
          if (question.media) {
            options.push('Images', 'Video');
          }
          if (question.maps_state) {
            options.push('Wordbooks');
          }
        } else {
          if (question.media) {
            const found = options.filter(x => x.toLowerCase().includes('i'));
            if (found.length === 0) {
              options.push('Images', 'Video');
            }
          }
          if (question.maps_state) {
            const found = options.filter(x => x.includes('Wordbooks'));
            if (found.length === 0) {
              options.push('Wordbooks');
            }
          }
        }
        break;

      case 'Canvas':
        options.push('minAnnotations');
        break;

      default:
        break;
    }
    return options;
  }

  taskNeedRequiredOptions = (question_type) => {
    return (question_type === 'Open End' || question_type === 'Canvas' ||  question_type === 'Multimedia')
  }

  // ANTI-PATTERN FOR MULTI MODAL
  _setModalBody(){
    let comp = null;
    //Opt when close the modal, don't call this again
    if(this.state.modalQuestionId && this.state.activeTaskId){
      const task = this.state.tasks.find(task => task.id === this.state.activeTaskId);
      const questionEdit = task.questions.find(question => question.id === this.state.modalQuestionId);
      //console.log(questionEdit.attachment[0]);
      // Maybe AE_Modal would be included directly here, it looks repetitive
      switch(this.state.modalType){
        case 'image':
          let imageUrl = null;
          if(typeof questionEdit.previewImg !== 'undefined'){
            imageUrl = questionEdit.previewImg;
          } else {
            imageUrl = questionEdit.attachment.length !== 0 ? questionEdit.attachment[0].url :
              '/assets/placeholder.png';
          }
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Image'}
                           type={this.state.modalType}
                           imageUrl={imageUrl}
                           onDropImage={this.onDropImage}
                           updateCropBlob={this.updateCropBlob}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'imageAnswer':
          const dataAnswers = [];
          Object.keys(questionEdit.data.answers).forEach(key => {
            dataAnswers.push(questionEdit.data.answers[key]);
          });
          const answer = dataAnswers.find(answer => answer.id === this.state.modalAnswerId);
          let imageUrlAnswer = null;
          if(answer.previewImg !== undefined){
            imageUrlAnswer = answer.previewImg;
          } else {
            imageUrlAnswer = answer.img !== '' ? answer.img : '/assets/placeholder.png';
          }
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Image '}
                           type={this.state.modalType}
                           imageUrl={imageUrlAnswer}
                           onDropImage={this.onDropImage}
                           updateCropBlob={this.updateCropBlob}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'video':
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Video'}
                           type={this.state.modalType}
                           updateVideo={this.updateVideo}
                           questionId={questionEdit.id}
                           data_signature={this.props.data_signature}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'delete act':
          const task = this.state.tasks.find(task => task.id === this.state.activeTaskId);
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Delete Activity'}
                           type={this.state.modalType}
                           from={'Activity'}
                           activeTaskId={this.state.activeTaskId}
                           activityName={task.title}
                           activityNameToDelete={this.state.activityNameToDelete}
                           updateConditionsDelActivity={this.updateConditionsDelActivity}
                           deleteTask={this.deleteTask}
                           delActC1={this.state.delActC1}
                           delActC2={this.state.delActC2}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'copy act':
          const task2 = this.state.tasks.find(task => task.id === this.state.activeTaskId);
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Copy Activity'}
                           type={this.state.modalType}
                           from={'Copy Activity'}
                           activityName={task2.title}
                           updateConditionsDelActivity={this.updateConditionsDelActivity}
                           copyActC1={this.state.copyActC1}
                           copyActC2={this.state.copyActC2}
                           copyActivity={this.copyActivity}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'delete task':
          const task1 = this.state.tasks.find(task => task.id === this.state.activeTaskId);
          const question = task1.questions.find(question => question.id === this.state.modalQuestionId);
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Delete Task'}
                           type={this.state.modalType}
                           from={'Task'}
                           questionId={typeof question !== 'undefined' ? question.id : -1}
                           questionName={typeof question !== 'undefined' ? question.title : ''}
                           updateConditionsDelActivity={this.updateConditionsDelActivity}
                           deleteQuestion={this.deleteQuestion}
                           delTaskC1={this.state.delTaskC1}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'segments':
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Pick Segments'}
                           type={this.state.modalType}
                           gender_as_segment={this.props.gender_as_segment}
                           segments={this.state.segments}
                           task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                           updateArrayTask={this.updateArrayTask}
                           updateArrayTaskFilters={this.updateArrayTaskFilters}
                           activeUsers={this.props.active_users}
                           searchModal={this.searchModal}
                           updateModalAction={this.updateModalAction}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'tag':
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Pick Activity Tags'}
                           type={this.state.modalType}
                           tags={this.state.tags}
                           task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                           updateArrayTask={this.updateArrayTask}
                           updateArrayTaskFilters={this.updateArrayTaskFilters}
                           tasks={this.state.tasks}
                           searchModal={this.searchModal}
                           updateModalAction={this.updateModalAction}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'required':
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Pick Required Activity'}
                           type={this.state.modalType}
                           task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                           tasks={this.state.tasks}
                           //tasks={this.state.tasks.filter(activity => activity.visibility === 'enabled')}
                           updateArrayTask={this.updateArrayTask}
                           updateArrayTaskFilters={this.updateArrayTaskFilters}
                           actDates={this.state.actDates}
                           handleChange={this.handleChange}
                           updateStartExpireDatesModal={this.updateStartExpireDatesModal}
                           searchModal={this.searchModal}
                           updateModalAction={this.updateModalAction}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'preview task OE':
        case 'preview task CH':
        case 'preview task MCH':
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Task preview'}
                           type={this.state.modalType}
                           task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                           question={questionEdit}
                           colors={this.props.user_account.all_colors}
                           communityLang={this.props.communityLang}
                           cmntyAutoModeration={this.props.cmntyAutoModeration}/>

          break;
        case 'preview Activity':
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'Activity Preview'}
                           type={this.state.modalType}
                           task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                           colors={this.props.user_account.all_colors}
                           communityLang={this.props.communityLang}
                           cmntyAutoModeration={this.props.cmntyAutoModeration}/>

          break;
        case 'live validation':
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'liveValidation'}
                           type={this.state.modalType}
                           task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'live on':
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'liveOn'}
                           type={this.state.modalType}
                           task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                           confirmUpdateLive={this.confirmUpdateLive}
                           tasks={this.state.tasks}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'task inst validation':
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                    language={this.props.language}
                    title={'taskInstValidation'}
                    type={this.state.modalType}
                    task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                    colors={this.props.user_account.all_colors}/>;
          break;
        case 'tasks validations':
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'tasks validations'}
                           type={this.state.modalType}
                           task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                           validations={this.state.validations}
                           colors={this.props.user_account.all_colors}/>;
          break;
        case 'choice live':
          const task3 = this.state.tasks.find(task => task.id === this.state.activeTaskId);
          const question1 = task3.questions.find(question => question.id === this.state.modalQuestionId);
          comp = <AE_Modal updateShowModal={this.updateShowModal}
                           language={this.props.language}
                           title={'choice live'}
                           type={this.state.modalType}
                           task={this.state.tasks.find(task => task.id === this.state.activeTaskId)}
                           validations={this.state.validations}
                           questionId={question1 !== undefined ? question1.id : -1}
                           changeChoiceTypeLive={this.changeChoiceTypeLive}
                           colors={this.props.user_account.all_colors}/>;
          break;
      }
    }

    return comp;
  };

  countMicroChatTasks = () => {
    let count = 0;

    this.state.tasks.forEach(task => {
      if (task.questions) {
        task.questions.forEach(question => {
          if (question.task_type === 'MicroChat') {
            count++;
          }
        });
      }
    });

    return count;
  };

}
