import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactModal from 'react-modal';
import v4 from 'uuid';
import {BigPlayButton, ControlBar, Player, VolumeMenuButton} from "video-react";

export default class AVVideo extends React.Component {
  static propTypes = {
    response: PropTypes.object,
    updateCameraVideo: PropTypes.func.isRequired,
    updateNewVideoThumbnail: PropTypes.func.isRequired,
    questionId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showModalVideo: false,
      isCamReady: false,
      cameraUuid: v4(),
      //processedThumbnail: null
    }
  }

  render() {
    const {response} = this.props;

    let videoResponse = null;
    let videoUUID = '';

    //console.log('processedThumbnail: ' + this.state.processedThumbnail);
    if(response.newVideoThumbnail){
      console.log('response.newVideoThumbnail: ', response.newVideoThumbnail);
      videoResponse = <img src={response.newVideoThumbnail} style={{width:'100%'}}/>
    } else {
      if(response.response){
        if(response.response.video_thumbnail){
          videoResponse =
            <div className="video-response-container">
              <div>
                <img src={response.response.video_thumbnail} style={{width:'100%'}}/>
                <div className="video-overlay">
                  <button className="btn-delete">DELETE</button>
                  <button className="btn-replace">REPLACE</button>
                </div>
              </div>
            </div>
        } else {
          videoResponse =
            <div className="video-response-container no-image">
              <FontAwesomeIcon icon={['fas', 'video']} style={{fontSize:'40px'}}/>
            </div>
        }
      } else {
        videoResponse =
          <div className="video-response-container no-image">
            <FontAwesomeIcon icon={['fas', 'video']} style={{fontSize:'40px'}}/>
          </div>
      }
    }

    const camera = <camera id={this.state.cameraUuid} data-app-id='a-96413510-77a7-0135-1986-0a76e15fb04a'
                           data-poll-for-processed='true' style={{width:'320px', height:'270px'}}/>;

    return (
      <div onClick={this.updateShowModalVideo} className="px-video-comp">
        { videoResponse }

        <ReactModal isOpen={this.state.showModalVideo} contentLabel="Response Video"
                    shouldCloseOnOverlayClick={true} onRequestClose={this.updateShowModalVideo}
                    onAfterOpen={this.setupCameraTag}
                    className="my-modal-content" overlayClassName="my-modal-overlay">
          <div className="modal-dialog" style={{width:'700px'}}>
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close">
                  <i onClick={this.updateShowModalVideo} className="material-icons" style={{color: '#333'}}>clear</i>
                </button>
                <h4 className="modal-title">Video</h4>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    { camera }
                    { this.state.isCamReady ? null : <h3>Loading Camera...</h3> }
                  </div>
                  {
                    response.response &&
                    <div className="col-md-6">
                      <Player poster={response.response.video_thumbnail}
                              fluid={false} width={'100%'} height={270}>
                        <source src={response.response.video_url}/>
                        <BigPlayButton position="center" />
                        <ControlBar autoHide={false} >
                          <VolumeMenuButton vertical />
                        </ControlBar>
                      </Player>
                    </div>
                  }
                </div>
              </div>

              <div className="separator"/>
              <div className="modal-footer">
                <button onClick={this.updateShowModalVideo} type="button"
                        className="btn btn-danger btn-simple">Cancel</button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }

  setupCameraTag = () => {
    CameraTag.setup();

    let myCamera = null;
    let video = null;

    CameraTag.observe(this.state.cameraUuid, "initialized", () => {
      console.log('initialized!');
      this.setState({isCamReady: true});
    });

    CameraTag.observe(this.state.cameraUuid, "published", () => {
      console.log('published!');
      myCamera = CameraTag.cameras[this.state.cameraUuid];
      console.log('myCamera', myCamera);
      video = myCamera.getVideo();
      console.log('video', video);
      this.props.updateCameraVideo(myCamera, video, this.props.questionId);
    });

    CameraTag.observe(this.state.cameraUuid, 'processed', () => {
      console.log('processed');
      console.log('processed, video', video.medias.thumb);
      /*this.setState({processedThumbnail: video.medias.thumb}, () => {
        console.log('processed after setState.processedThumbnail', video.medias.thumb);
      });*/
      this.props.updateNewVideoThumbnail(video.medias.thumb, this.props.questionId);
      console.log('this video has been processed!');
    });
  };

  updateShowModalVideo = () => {
    console.log('updateShowModalVideo');
    this.setState(prevState => ({
      showModalVideo: !prevState.showModalVideo,
      //isCamReady: !prevState.isCamReady
    }));
  };
}