import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from "react-modal";

export default class UIGModal01 extends React.Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    updateShowModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showModal} contentLabel="Modal Test" shouldCloseOnOverlayClick={true}
                  onRequestClose={() => this.props.updateShowModal('sm00')} className="px-modal-content"
                  overlayClassName="px-modal-overlay">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header modal-delete-activity">
              <button type="button" className="btn px-btn circle xs btn-gray-lighter color-black px-close-modal">
                <i onClick={() => this.props.updateShowModal('sm00')} className="material-icons">clear</i>
              </button>
            </div>
            <div className="modal-body px-modal-body-warning">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="px-title-warning-container">
                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="exclamation-triangle" className="svg-inline--fa fa-exclamation-triangle fa-w-18 px-icon-warning" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                      <path fill="currentColor" d="M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2 0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3 0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z"></path>
                      </svg>
                      <p className="px-title-warning">
                        Remove <b className="warning">real</b>
                      </p>
                      <label className="px-message-warning">This action cannot be undone</label>
                  </div>
                </div>
              </div>

              
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
                  <div className="px-check-cont px-checkbox-modal-warning">
                    <label className="container-check">
                        <input type="checkbox"/>
                        <span className="checkmark"></span>
                        <span> <b>Lorem ipsum dolor</b> sit amet consectetur, adipisicing elit. Nihil illo in fuga aliquid ullam cupiditate eum porro laboriosam quas rerum, consequatur velit, praesentium sunt aperiam? Harum quis fuga nulla libero?</span>
                    </label>
                  </div>
                  <input type="text" name="confirmEmail" className="form-control px-input-confirmation" placeholder="Type &quot;real&quot; to confirm"/>
                </div>
              </div>
              
            </div>
            <div className="modal-footer px-modal-footer-warning">
              <div className="px-footer-container-buttons column">
                <button className="btn px-btn sm btn-blue-base">Yes, delete it</button>
                <button type="button" className="btn px-btn sm btn-gray-darker inverted">Close</button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}
