import PropTypes from 'prop-types';
import React from 'react';
import ModalQuickInvite from "./ModalQuickInvite";
import ListFilters from "./ParticipantList/ListFilters";
import PrivateMessages from "../../PrivateMessages";

const _ = require('underscore');

export default class ParticipantList extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    community_users: PropTypes.array.isRequired,
    counter: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    segments: PropTypes.array.isRequired,
    role2: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      communityUsers: this.props.community_users,
      usersFiltered: this.props.community_users,
      checkEnabled: false,
      checkInvited: false,
      checkPending: false,
      checkDeclined: false,
      checkBlocked: false,
      counter: this._calculateCounter(this.props.community_users),
      toggleFilter: false,
      pmIsOpen: false,
      objPM: {rooms: []}
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props.community_users !== nextProps.community_users){
      this.setState({
        communityUsers: nextProps.community_users,
        usersFiltered: nextProps.community_users,
        counter: this._calculateCounter(nextProps.community_users)
      });

      if(this.props.type === 'Participant'){
        this.setState({toggleFilter: true});
      } else {
        this.setState({toggleFilter: false});
      }
    }
  };

  render(){
    // Try format in DB, it takes the complete row in DB
    const segments = this.props.segments.map((segment) => {
      const obj = {
        value: segment.id,
        label: segment.name,
        color: segment.color
      };
      return obj
    });

    const modalQuickInvite = <ModalQuickInvite community={this.props.community}
                                               updateCommunityUsers={this.updateCommunityUsers}
                                               type={this.props.type}
                                               segments={segments}
                                               role2={this.props.role2}/>;

    {/*const pm = <PrivateMessages pmIsOpen={this.state.pmIsOpen}
                                updatePMIsOpen={this.updatePMIsOpen}
                                objPM={this.state.objPM}
                                from='Participant_List'/>;*/}

    return(
      <div id="divParticipantList" className="col-part-container">
        { modalQuickInvite }
        <ListFilters communityUsers={this.state.communityUsers}
                     usersFiltered={this.state.usersFiltered}
                     toggleFilter={this.state.toggleFilter}
                     updateUsersFiltered={this.updateUsersFiltered}
                     updateCommunityUsers={this.updateCommunityUsers}
                     segments={this.props.segments}
                     communityId={this.props.community.id}
                     role2={this.props.role2}
                     updatePM={this.updatePM}/>
        <ListFilters communityUsers={this.state.communityUsers}
                     usersFiltered={this.state.usersFiltered}
                     toggleFilter={!this.state.toggleFilter}
                     updateUsersFiltered={this.updateUsersFiltered}
                     updateCommunityUsers={this.updateCommunityUsers}
                     segments={this.props.segments}
                     communityId={this.props.community.id}
                     role2={this.props.role2}
                     updatePM={this.updatePM}/>

        {/* pm */}

      </div>
    )
  }

  updatePM = (user_2) => {
    this.setState({pmIsOpen: !this.state.pmIsOpen}, function () {
      if(this.state.pmIsOpen){
        $.ajax({
          url: '/pm/show/' + user_2 + '/' + this.props.community.id,
          method: 'GET',
          dataType: 'JSON',
          success: function (obj) {
            console.log(obj);
            this.setState({objPM: obj})
          }.bind(this)
        });
      }
    });
  };

  updatePMIsOpen = () => {
    this.setState({pmIsOpen: !this.state.pmIsOpen})
  };

  searchByStatus = () => {
    const users = this.state.communityUsers;
    let filteredUsers = [];

    const enabled = this.state.checkEnabled ? 'Enabled' : 'All';
    const invited = this.state.checkInvited ? 'Invited' : 'All';
    const draft = this.state.checkPending ? 'Draft' : 'All';
    const declined = this.state.checkDeclined ? 'Declined' : 'All';
    const blocked = this.state.checkBlocked ? 'Blocked' : 'All';

    _.each(users, function (user) {
      if(user.state === enabled || user.state === invited || user.state === draft || user.state === declined || user.state === blocked) {
        filteredUsers.push(user);
      }
    }.bind(this));

    if(enabled === 'All' && invited === 'All' && draft === 'All' && declined === 'All' && blocked === 'All'){
      this.setState({usersFiltered: users});
    } else {
      this.setState({usersFiltered: filteredUsers});
    }
  };

  updateCommunityUsers = (communityUsers) => {
    //console.log(communityUsers);
    this.setState({
      communityUsers: communityUsers,
      usersFiltered: communityUsers,
      counter: this._calculateCounter(communityUsers)
    });
    //Maybe this searchByStatus is redundant, try it!
    this.searchByStatus();
  };

  updateUsersFiltered = (usersFiltered) => {
    this.setState({usersFiltered: usersFiltered})
  };

  _calculateCounter(cu){
    let cE = 0, cI = 0, cDr = 0, cD = 0, cB = 0;

    _.each(cu, function (user) {
      if(user.state === 'Enabled')
        cE++;
      if(user.state === 'Invited')
        cI++;
      if(user.state === 'Draft')
        cDr++;
      if(user.state === 'Declined')
        cD++;
      if(user.state === 'Blocked')
        cB++;
    });

    return [cE, cI, cDr, cD, cB];
  }
}