import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Tooltip as ReactTooltip } from "react-tooltip"
import moment from "moment";
import ICTwoButtons from "./InvitedCard/ICTwoButtons";

export default class InvitedCard extends React.Component {
  static propTypes = {
    community: PropTypes.object.isRequired,
    updateStateInvitation: PropTypes.func.isRequired,
    language: PropTypes.string,
    colors: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {community, language, colors} = this.props;

    const i18n = {
      en:{
        msg1:'You have been', msg2: 'invited to', enter: 'Enter', decline: 'Decline', info: '',
        range: 'This project will start soon. Sign in again on '
      },
      es:{
        msg1: 'Has sido', msg2: 'invitado a', enter: 'Entrar', decline: 'Declinar', info: '',
        range: 'Este proyecto iniciará pronto. Vuelva a ingresar a la plataforma el '
      }
    }

    const twoButtons = <ICTwoButtons community={community} colors={colors} language={language} i18n={i18n}
                                     updateStateInvitation={this.props.updateStateInvitation}/>;

    return (
      <div className="card px-card-community invite mg-bottom-15" style={{backgroundColor: colors.color2[3]}}>
        <ReactTooltip anchorSelect="[data-tooltip-content]" className="px-tooltip" />
        <div className="card-height-indicator"/>
        <div className="card-content">
          <div className="card-body full-body body-status">
            <FontAwesomeIcon icon={['fal','face-smile']} className="px-icon smile" style={{color: colors.color2[1]}}/>
            <div className="text-cont">
              <span className="title white">{i18n[language].msg1} <br/> {i18n[language].msg2}</span>
              <span className="community invite">{community.title}</span>
            </div>

            <div className="two-buttons">
              {
                community.role_1 === 'Collaborator' ?
                  twoButtons :
                  (community.is_in_range && community.state !== 'Closed') ?
                    twoButtons :
                    community.state !== 'Closed' ?
                      <span style={{color: colors.color4[2], fontFamily: 'Sharp Sans Semibold', fontSize: '16px'}}>
                        {i18n[language].range} {moment(community.participants_start).format('DD/MM/YYYY')}
                      </span> :
                      <span className="px-closed-warning">Closed</span>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
